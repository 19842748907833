<div [formGroup]=mainContactForm>
        <div class="card">
            <div class="card-header">
                <label class="">Main Contact</label>
            </div>
            <div class="card-body">
                <div class="form-group row " formGroupName='xMainContact'>
                    <div class="col-md-12 col-sm-12">
                        <label for="phone">Phone: <small *ngIf="addressContactValidate" class="text-danger">*</small></label>
                        <small *ngIf="!this.serverErrors['xMainContact.xPhone'] && mainContactForm.controls.xMainContact?.get('xPhone').hasError('required') &&  mainContactForm.controls.xMainContact?.get('xPhone').touched" 
                        class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Phone is required" placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i> 
                    </small>
                        <small *ngIf="this.serverErrors['xMainContact.xPhone']" class="inline-error"> 
                                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="{{formErrors.xMainContact.xPhone}}" placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>     
                        </small>
                        <!--<input type="text" class="form-control form-control-sm" id="person_phone" formControlName="xPhone" [ngClass]="{'has-error': formErrors.xMainContact.xPhone}"
                            [textMask]={mask:mask} validateOnBlur>-->
                        <input type="text" class="form-control form-control-sm" id="phone" formControlName="xPhone" (keypress)="onKeypress($event)" (paste)="onPaste($event)"
                        [ngClass]="{'has-error': ( mainContactForm.controls.xMainContact?.get('xPhone').hasError('required') &&  mainContactForm.controls.xMainContact?.get('xPhone').touched)|| this.serverErrors['xMainContact.xPhone']}"
                            (focus)="clearErrors('xMainContact','xPhone')" validateOnBlur>
                        <!--<input type="text" class="form-control form-control-sm" id="phone" formControlName="xPhone" [ngClass]="{'has-error': formErrors.xMainContact.xPhone}"
                            [textMask]={mask:mask} validateOnBlur>-->
                    </div>
                </div>
                <div class="form-group row no-margin" formGroupName='xMainContact'>
                    <div class="col-md-12 col-sm-12">
                        <label for="email">Email: <small *ngIf="addressContactValidate" class="text-danger">*</small></label>
                        <small *ngIf="!this.serverErrors['xMainContact.xEmail'] && mainContactForm.controls.xMainContact?.get('xEmail').hasError('required') &&  mainContactForm.controls.xMainContact?.get('xEmail').touched" 
                        class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Email is required" placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i> 
                    </small>
                        <small *ngIf="this.serverErrors['xMainContact.xEmail']" class="inline-error">
                                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="formErrors.xMainContact.xEmail" placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i> 
                        </small>
                        <small *ngIf="!this.serverErrors['xMainContact.xEmail'] &&  mainContactForm.get('xMainContact').get('xEmail').hasError('incorrectMailFormat') && mainContactForm.get('xMainContact').get('xEmail').touched" class="inline-error"> 
                            <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Email format is invalid." placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                        </small>
                        <input type="email" class="form-control form-control-sm" id="person_email" formControlName="xEmail" 
                        [ngClass]="{'has-error': ( (mainContactForm.controls.xMainContact?.get('xEmail').hasError('incorrectMailFormat') || mainContactForm.controls.xMainContact?.get('xEmail').hasError('required')) &&  mainContactForm.controls.xMainContact?.get('xEmail').touched)|| this.serverErrors['xMainContact.xEmail']}"
                        (focus)="clearErrors('xMainContact','xEmail')" validateOnBlur>
                    </div>
                </div>
            </div>
        </div>
</div>

