import {
    Component,
    OnInit,
    AfterViewInit,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ElementRef
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { TAGSCONSTS } from "../../../../../constants";
import {
    HttpService,
    BusinessService,
    UserHandlerService,
    TransactionActionService,
    // SummaryReportService
} from "../../../../../shared/services";
import {
    TransactionsStore,
    SettleSuccessTransactionsListStore,
    SettleFailedTransactionsListStore
} from "../../../../../store";

@Component({
    selector: "app-txn-batch-response-modal",
    templateUrl: "./txn-batch-response-modal.component.html",
    styleUrls: ["./txn-batch-response-modal.component.scss"]
})
export class TxnBatchResponseModalComponent implements OnInit, AfterViewInit {
    @Output() onCloseModal = new EventEmitter<boolean>();
    @Input() responseForModal: any; // catches array of roleIDs as Input
    @Input() type: String = "";
    @Input() storeObject: any;
    @Input() txnBatchType = "";
    @Input() queueId = "";
    @ViewChild("txnBatchRespModal", {static: true}) public txnBatchRespModal: ModalDirective;
    @ViewChild("try_printing_this", {static: true}) public tryPrintingThis: ElementRef;

    public failedObjArray: any[] = [];
    public successObjArray: any[] = [];
    loadingSuccess: boolean = true;
    loadingFailed: boolean = true;
    showSuccessTable: boolean = true;
    showFailedTable: boolean = true;
    printSummary: boolean = true;
    printDetail: boolean = false;
    showTxn = false;
    showBatch = false;
    hideBatchTxns = true;
    // TxnBatchType: string = "txn";
    successEmpty: String = "";
    failedEmpty: String = "";
    actionID: String = "";
    txnAction: any[] = [];

    // successStoreObject:any;
    // failStoreObject:any;

    successIDArray: any = [];
    failedIDArray: any = [];
    // modelIDs: any;
    body: HTMLElement;
    printDetailLoader: boolean = false;
    printSummaryLoader: boolean = false;
    fetchByTxnNumber: boolean = false;
    fetchByActionID: boolean = false;
    generatePDFPayload: any={};
    routeToPageList: boolean = true;

    // public sbatchTxnAttributes: any[] = [];
    // public sbatchTxnStoreValue: string = "";
    // public sbatchTxnParameters: any = {};
    // public fbatchTxnAttributes: any[] = [];
    // public fbatchTxnStoreValue: string = "";
    // public fbatchTxnParameters: any = {};

    constructor(
        public transactionStore: TransactionsStore,
        public transactionActionService: TransactionActionService,
        private httpService: HttpService,
        private businessService: BusinessService,
        private userHandlerService: UserHandlerService,
        private settleSuccessTransactionsListStore: SettleSuccessTransactionsListStore,
        private settleFailedTransactionsListStore: SettleFailedTransactionsListStore,

    ) {}

    ngOnInit() {
        console.log("this.responseForModal", this.responseForModal);
        console.log("this.type", this.type);
        console.log("this.storeObject", this.storeObject);
        console.log("this.txnBatchType", this.txnBatchType);

        if (this.type === "Settle" || this.type === "Clean") {
            console.log("here in settle case",this.responseForModal);
            if (this.txnBatchType === "txn") {
                this.showTxn = true;
                if (
                    this.responseForModal.hasOwnProperty("successNumbers") &&
                    this.responseForModal.hasOwnProperty("failedNumbers")
                ) {
                    console.log("Settle case===>", this.responseForModal);
                    if (this.queueId.length) {
                        console.log("SettleALL case");
                        this.fetchByActionID = true;
                        this.actionID = this.queueId;
                        this.generatePDFPayload = {
                            bizID: this.businessService.businessID.getValue(),
                            storeType: "txn",
                            queue_id: this.queueId
                        };
                    } else {
                        this.fetchByTxnNumber = true;
                        this.actionID = "";
                        this.fetchByActionID = false;
                        this.generatePDFPayload = {
                            bizID: this.businessService.businessID.getValue(),
                            failedRecordNums: this.responseForModal.hasOwnProperty(
                                "failedNumbers"
                            )
                                ? this.responseForModal["failedNumbers"]
                                : [],
                            storeType: "txn",
                            successRecordNums: this.responseForModal.hasOwnProperty(
                                "successNumbers"
                            )
                                ? this.responseForModal["successNumbers"]
                                : []
                        };
                    }
                    if (this.responseForModal["successNumbers"].length) {
                        this.storeObject = this.settleSuccessTransactionsListStore;
                        this.successIDArray = this.responseForModal[
                            "successNumbers"
                        ];
                        this.loadingSuccess = false;
                        this.showSuccessTable = false;
                    } else {
                        // if (this.type.endsWith("Batch")) {
                        //     this.successEmpty = "No Batch Actions Successful";
                        // } else {
                        this.successEmpty = "No Transaction Actions Successful";
                        // }
                    }
                    if (this.responseForModal["failedNumbers"].length) {
                        this.storeObject = this.settleFailedTransactionsListStore;
                        this.failedIDArray = this.responseForModal[
                            "failedNumbers"
                        ];
                        this.loadingFailed = false;
                        this.showFailedTable = false;
                    } else {
                        // if (this.type.endsWith("Batch")) {
                        //     this.successEmpty = "No Batch Actions Failed";
                        // } else {
                        this.failedEmpty = "No Transaction Actions Failed";
                        // }
                    }
                }
            } else if (this.txnBatchType === "batch") {
                this.showBatch = true;
                this.generatePDFPayload = {
                    bizID: this.businessService.businessID.getValue(),
                    storeType: "batch"
                    // batchID: "5aec39a3803dc973a07d2d85",
                    // batchActionStatus: "fail"
                };
                if (this.responseForModal[0].hasOwnProperty("succeededAPI")) {
                    if (this.responseForModal[0]["succeededAPI"].length) {
                        let successArray = [];

                        console.log(
                            "here in settle success case",
                            this.responseForModal[0]["succeededAPI"]
                        );
                        this.responseForModal[0]["succeededAPI"].map(
                            successRes => {
                                console.log("map");
                                if (
                                    successRes.hasOwnProperty(
                                        "modelResponseData"
                                    ) &&
                                    successRes["modelResponseData"].length
                                ) {
                                    successRes["modelResponseData"].map(obj => {
                                        successArray.push(obj.modelID);
                                        this.generatePDFPayload["batchID"] =
                                            obj.modelID;
                                        this.generatePDFPayload[
                                            "batchActionStatus"
                                        ] =
                                            "success";
                                    });
                                }
                            }
                        );
                        this.successIDArray = successArray.map(id => id);
                        this.loadingSuccess = false;
                        this.showSuccessTable = false;
                    } else {
                        if (this.type.endsWith("Batch")) {
                            this.successEmpty = "No Batch Actions Successful";
                        } else {
                            this.successEmpty =
                                "No Transaction Actions Successful";
                        }
                    }
                }
                if (this.responseForModal[0].hasOwnProperty("failedAPI")) {
                    if (this.responseForModal[0]["failedAPI"].length) {
                        console.log("here in settle failed case");
                        let failedArray = [];
                        console.log(
                            "here in settle failed case",
                            this.responseForModal[0]
                        );
                        this.responseForModal[0]["failedAPI"].map(failedRes => {
                            if (
                                failedRes.hasOwnProperty("modelResponseData") &&
                                failedRes["modelResponseData"].length
                            ) {
                                failedRes["modelResponseData"].map(obj => {
                                    failedArray.push(obj.modelID);
                                    this.generatePDFPayload["batchID"] =
                                        obj.modelID;
                                    this.generatePDFPayload[
                                        "batchActionStatus"
                                    ] =
                                        "fail";
                                });
                            }
                        });
                        this.failedIDArray = failedArray.map(id => id);
                        this.loadingFailed = false;
                        this.showFailedTable = false;
                    } else {
                        if (this.type.endsWith("Batch")) {
                            this.failedEmpty = "No Batch Actions Failed";
                        } else {
                            this.failedEmpty = "No Transaction Actions Failed";
                        }
                    }
                }
            }

            if(this.type === "Settle"){
                this.httpService
                .store("generatePdfs", this.generatePDFPayload)
                .subscribe(
                    (response)=>{
                        console.log("mail sent", response);
                    },
                    (error)=>{
                        console.log("mail not sent", error);
                    }
                )
            }


        } else {
            this.fetchByTxnNumber = false;
            let txnType = {};
            txnType[TAGSCONSTS.transactionActionTags.txnActionItemTags.sale] =
                "Sale";
            txnType[TAGSCONSTS.transactionActionTags.txnActionItemTags.return] =
                "Refund";
            txnType[TAGSCONSTS.transactionActionTags.txnActionItemTags.auth] =
                "Auth";
            txnType[
                TAGSCONSTS.transactionActionTags.txnActionItemTags.inquiry
            ] =
                "Inquiry";
            this.showSuccessTable = true;
            this.showFailedTable = true;
            if (this.responseForModal.length) {
                if (this.responseForModal[0].hasOwnProperty("succeededAPI")) {
                    // console.log("1.1 T");
                    if (this.responseForModal[0]["succeededAPI"].length) {
                        // console.log("1.1.1 T");
                        this.responseForModal[0]["succeededAPI"].map(
                            successRes => {
                                // console.log("map");
                                if (
                                    successRes.hasOwnProperty(
                                        "modelResponseData"
                                    ) &&
                                    successRes["modelResponseData"].length === 1
                                ) {
                                    // console.log("map.1 T");
                                    let acctNum =
                                        successRes &&
                                        successRes.hasOwnProperty(
                                            "modelResponseData"
                                        ) &&
                                        successRes.modelResponseData.length &&
                                        successRes.modelResponseData[0].hasOwnProperty(
                                            "maskedAcctNum"
                                        ) &&
                                        successRes.modelResponseData[0]
                                            .maskedAcctNum.length
                                            ? successRes.modelResponseData[0].maskedAcctNum.substring(
                                                  0,
                                                  6
                                              ) +
                                              "..." +
                                              successRes.modelResponseData[0].maskedAcctNum.substring(
                                                  successRes
                                                      .modelResponseData[0]
                                                      .maskedAcctNum.length - 4
                                              )
                                            : "-";
                                    successRes.modelResponseData[0].maskedAcctNum = acctNum;
                                    this.successObjArray.push({
                                        // id: successRes.modelID[0],
                                        num: successRes.modelResponseData[0].hasOwnProperty(
                                            "txnNum"
                                        )
                                            ? successRes.modelResponseData[0]
                                                  .txnNum
                                            : "-",
                                        refNum: successRes.modelResponseData[0].hasOwnProperty(
                                            "modelNum"
                                        )
                                            ? successRes.modelResponseData[0]
                                                  .modelNum
                                            : "-",
                                        maskedAcctNum:
                                            successRes.modelResponseData[0]
                                                .maskedAcctNum,
                                        amt:
                                            successRes.modelResponseData[0].hasOwnProperty(
                                                "mainAmount"
                                            ) &&
                                            successRes.modelResponseData[0]
                                                .mainAmount
                                                ? (
                                                      parseInt(
                                                          successRes
                                                              .modelResponseData[0]
                                                              .mainAmount
                                                      ) / 100
                                                  ).toFixed(2)
                                                : "0.00",
                                        tipAmt:
                                            successRes.modelResponseData[0].hasOwnProperty(
                                                "tipAmount"
                                            ) &&
                                            successRes.modelResponseData[0]
                                                .tipAmount
                                                ? (
                                                      parseInt(
                                                          successRes
                                                              .modelResponseData[0]
                                                              .tipAmount
                                                      ) / 100
                                                  ).toFixed(2)
                                                : "0.00",
                                        totAmt:
                                            successRes.modelResponseData[0].hasOwnProperty(
                                                "totalAmount"
                                            ) &&
                                            successRes.modelResponseData[0]
                                                .totalAmount
                                                ? (
                                                      parseInt(
                                                          successRes
                                                              .modelResponseData[0]
                                                              .totalAmount
                                                      ) / 100
                                                  ).toFixed(2)
                                                : "0.00",
                                        type:
                                            successRes.modelResponseData[0].hasOwnProperty(
                                                "txnType"
                                            ) &&
                                            txnType[
                                                successRes.modelResponseData[0]
                                                    .txnType
                                            ]
                                                ? txnType[
                                                      successRes
                                                          .modelResponseData[0]
                                                          .txnType
                                                  ]
                                                : "undefined",
                                        status: "Success"
                                        // message:
                                    });
                                } else if (
                                    successRes.hasOwnProperty(
                                        "modelResponseData"
                                    ) &&
                                    successRes["modelResponseData"].length > 1
                                ) {
                                    // console.log("map.1 F");
                                    successRes["modelResponseData"].map(obj => {
                                        let acctNum =
                                            obj.hasOwnProperty(
                                                "maskedAcctNum"
                                            ) &&
                                            obj.maskedAcctNum.hasOwnProperty(
                                                "maskedAcctNum"
                                            ) &&
                                            obj.maskedAcctNum.maskedAcctNum
                                                .length
                                                ? obj.maskedAcctNum.maskedAcctNum.substring(
                                                      0,
                                                      6
                                                  ) +
                                                  "..." +
                                                  obj.maskedAcctNum.maskedAcctNum.substring(
                                                      obj.maskedAcctNum
                                                          .maskedAcctNum
                                                          .length - 4
                                                  )
                                                : "-";
                                        obj.maskedAcctNum.maskedAcctNum = acctNum;
                                        this.successObjArray.push({
                                            // id: id,
                                            num: obj.hasOwnProperty("txnNum")
                                                ? obj.txnNum
                                                : "-",
                                            refNum: obj.hasOwnProperty(
                                                "modelNum"
                                            )
                                                ? obj.modelNum
                                                : "-",
                                            maskedAcctNum: obj.maskedAcctNum,
                                            amt:
                                                obj.hasOwnProperty(
                                                    "mainAmount"
                                                ) && obj.mainAmount
                                                    ? (
                                                          parseInt(
                                                              obj.mainAmount
                                                          ) / 100
                                                      ).toFixed(2)
                                                    : "0.00",
                                            tipAmt:
                                                obj.hasOwnProperty(
                                                    "tipAmount"
                                                ) && obj.tipAmount
                                                    ? (
                                                          parseInt(
                                                              obj.tipAmount
                                                          ) / 100
                                                      ).toFixed(2)
                                                    : "0.00",
                                            totAmt:
                                                obj.hasOwnProperty(
                                                    "totalAmount"
                                                ) && obj.totalAmount
                                                    ? (
                                                          parseInt(
                                                              obj.totalAmount
                                                          ) / 100
                                                      ).toFixed(2)
                                                    : "0.00",
                                            type:
                                                txnType.hasOwnProperty(
                                                    obj.txnType
                                                ) && txnType[obj.txnType]
                                                    ? txnType[obj.txnType]
                                                    : "undefined",
                                            status: "Success"
                                            // message:
                                        });
                                    });
                                }
                            }
                        );
                    } else {
                        // console.log("1.1.1 F");
                        if (this.type.endsWith("Batch")) {
                            this.successEmpty = "No Batch Actions Successful";
                        } else {
                            this.successEmpty =
                                "No Transaction Actions Successful";
                        }
                    }
                    console.log(
                        "this.suc",
                        JSON.parse(JSON.stringify(this.successObjArray))
                    );
                    this.loadingSuccess = false;
                }

                if (this.responseForModal[0].hasOwnProperty("failedAPI")) {
                    // console.log("2.1 T");
                    if (this.responseForModal[0]["failedAPI"].length) {
                        console.log("2.1.1 T", this.responseForModal[0]['failedAPI']);
                        this.responseForModal[0]["failedAPI"].map(failedRes => {
                            // console.log("map2");
                            // if(failedRes.hasOwnProperty('error') && failedRes.error && failedRes.hasOwnProperty('response') && failedRes.response.length === 0){
                            //     this.updateFailedObjArray(failedRes, txnType, true);
                            // }
                            // else{
                            //     this.updateFailedObjArray(failedRes, txnType, false);
                            // }
                            console.log("failed Response=====>", failedRes);
                            this.updateFailedObjArray(failedRes, txnType);
                            
                        });
                    } else {
                        // console.log("2.1.1 F")
                        if (this.type.endsWith("Batch")) {
                            this.successEmpty = "No Batch Actions Failed";
                        } else {
                            this.successEmpty = "No Transaction Actions Failed";
                        }
                    }
                    console.log(
                        "this.fail",
                        JSON.parse(JSON.stringify(this.failedObjArray))
                    );
                    this.loadingFailed = false;
                }
            }
        }
    }

    // updateFailedObjArray(failedRes, txnType, inconsistentError){
    updateFailedObjArray(failedRes, txnType){
        console.log("map2.1 T", failedRes);
        if (
            failedRes.hasOwnProperty("modelResponseData") &&
            failedRes["modelResponseData"].length === 1
        ) {
            let acctNum = failedRes.modelResponseData[0]
                .maskedAcctNum.length
                ? failedRes.modelResponseData[0].maskedAcctNum.substring(
                      0,
                      6
                  ) +
                  "..." +
                  failedRes.modelResponseData[0].maskedAcctNum.substring(
                      failedRes.modelResponseData[0]
                          .maskedAcctNum.length - 4
                  )
                : "-";
            failedRes.modelResponseData[0].maskedAcctNum = acctNum;
            let failedObj= {
                num: failedRes.modelResponseData[0].hasOwnProperty(
                    "txnNum"
                )
                    ? failedRes.modelResponseData[0]
                          .txnNum
                    : "-",
                refNum: failedRes.modelResponseData[0].hasOwnProperty(
                    "modelNum"
                )
                    ? failedRes.modelResponseData[0]
                          .modelNum
                    : "-",
                maskedAcctNum:
                    failedRes.modelResponseData[0]
                        .maskedAcctNum,
                amt:
                    failedRes.modelResponseData[0].hasOwnProperty(
                        "mainAmount"
                    ) &&
                    failedRes.modelResponseData[0]
                        .mainAmount
                        ? (
                              parseInt(
                                  failedRes
                                      .modelResponseData[0]
                                      .mainAmount
                              ) / 100
                          ).toFixed(2)
                        : "0.00",
                tipAmt:
                    failedRes.modelResponseData[0].hasOwnProperty(
                        "tipAmount"
                    ) &&
                    failedRes.modelResponseData[0].tipAmount
                        ? (
                              parseInt(
                                  failedRes
                                      .modelResponseData[0]
                                      .tipAmount
                              ) / 100
                          ).toFixed(2)
                        : "0.00",
                totAmt:
                    failedRes.modelResponseData[0].hasOwnProperty(
                        "totalAmount"
                    ) &&
                    failedRes.modelResponseData[0]
                        .totalAmount
                        ? (
                              parseInt(
                                  failedRes
                                      .modelResponseData[0]
                                      .totalAmount
                              ) / 100
                          ).toFixed(2)
                        : "0.00",

                // type: txnType[failedRes.modelResponseData[0].txnType],
                type:
                    failedRes.modelResponseData[0].hasOwnProperty(
                        "txnType"
                    ) &&
                    txnType[
                        failedRes.modelResponseData[0]
                            .txnType
                    ]
                        ? txnType[
                              failedRes.modelResponseData[0]
                                  .txnType
                          ]
                        : "undefined",
                status: "Failed",
                message: failedRes.hasOwnProperty('message')
                ? failedRes.message
                : "Something went wrong."
            };
            // if(inconsistentError){
            //     failedObj['message']= failedRes.hasOwnProperty('message')
            //     ? failedRes.message
            //     : "Something went wrong."
            // }else{
            //     failedObj['message']= failedRes.response.ResponseText
            //     ? failedRes.response.ResponseText
            //     : "Something went wrong."
            // }
            console.log("failedObj", failedObj);
            this.failedObjArray.push(failedObj);
        } else if (
            failedRes.hasOwnProperty("modelResponseData") &&
            failedRes["modelResponseData"].length > 1
        ) {
            // console.log("map2.1 F")
            failedRes["modelResponseData"].map(obj => {
                let acctNum = obj.maskedAcctNum
                    .maskedAcctNum.length
                    ? obj.maskedAcctNum.maskedAcctNum.substring(
                          0,
                          6
                      ) +
                      "..." +
                      obj.maskedAcctNum.maskedAcctNum.substring(
                          obj.maskedAcctNum.maskedAcctNum
                              .length - 4
                      )
                    : "-";
                obj.maskedAcctNum.maskedAcctNum = acctNum;
                let failedObj= {
                    num: obj.hasOwnProperty("txnNum")
                            ? obj.txnNum
                            : "-",
                    refNum: obj.hasOwnProperty("modelNum")
                        ? obj.modelNum
                        : "-",
                    maskedAcctNum: obj.maskedAcctNum,
                    amt:
                        obj.hasOwnProperty("mainAmount") &&
                        obj.mainAmount
                            ? (
                                  parseInt(obj.mainAmount) /
                                  100
                              ).toFixed(2)
                            : "0.00",
                    tipAmt:
                        obj.hasOwnProperty("tipAmount") &&
                        obj.tipAmount
                            ? (
                                  parseInt(obj.tipAmount) /
                                  100
                              ).toFixed(2)
                            : "0.00",
                    totAmt:
                        obj.hasOwnProperty("totalAmount") &&
                        obj.totalAmount
                            ? (
                                  parseInt(
                                      obj.totalAmount
                                  ) / 100
                              ).toFixed(2)
                            : "0.00",

                    type:
                        obj.hasOwnProperty("txnType") &&
                        txnType[obj.txnType]
                            ? txnType[obj.txnType]
                            : "undefined",
                    status: "Failed",
                    message: obj.hasOwnProperty('message')
                        ? obj.message
                        : "Something went wrong."
                };
                // if(inconsistentError){
                //     failedObj['message']= failedRes.hasOwnProperty('message')
                //     ? failedRes.message
                //     : "Something went wrong."
                // }else{
                //     failedObj['message']= failedRes.response.ResponseText
                //     ? failedRes.response.ResponseText
                //     : "Something went wrong."
                // }
                this.failedObjArray.push(failedObj);
            });
        }
    }

    ngAfterViewInit() {
        this.txnBatchRespModal.show();
    }

    printDetails(body) {
        this.printDetailLoader = true;

        this.httpService
            .store("generateHTMLs", this.generatePDFPayload)
            .subscribe(
                res => {
                    console.log("123 in summary report==>", res);
                    // this.printElement(res.htmlString)
                    let $printSection = document.getElementById("printSection");
                    if (!$printSection) {
                        $printSection = document.createElement("div");
                        $printSection.id = "printSection";
                        document.body.appendChild($printSection);
                    }
                    $printSection.innerHTML = res.htmlString;
                    print();
                    this.printSummary = true;
                    this.printDetail = false;
                    this.printDetailLoader = false;
                    this.printSummaryLoader = false;
                },
                err => {
                    console.log("1`12", err);
                }
            );
    }

    printElement(element) {
        let domClone = element.cloneNode(true);

        let $printSection = document.getElementById("printSection");

        // if($printSection)
        // $printSection.remove();

        if (!$printSection) {
            console.log("Always here!!");
            $printSection = document.createElement("div");
            $printSection.id = "printSection";
            document.body.appendChild($printSection);
        }

        $printSection.innerHTML = "";
        $printSection.appendChild(domClone);
        console.log("new div --->", $printSection);
        print();
        this.printSummary = true;
        this.printDetail = false;
        this.printDetailLoader = false;
        this.printSummaryLoader = false;
        // if(this.txnBatchType === 'txn' ){
        //     this.txnBatchRespModal.hide();
        // }
    }

    printSummaryReport(body) {
        this.printSummary = false;
        this.printSummaryLoader = true;

        if (
            document.getElementById("printSummarySuccess") &&
            document.getElementById("printSummarySuccess").lastChild
        ) {
            while (
                document.getElementById("printSummarySuccess").childNodes
                    .length > 0
            ) {
                document
                    .getElementById("printSummarySuccess")
                    .removeChild(
                        document.getElementById("printSummarySuccess").lastChild
                    );
            }
            let cN = document.getElementById("successAlertDiv").cloneNode(true);
            document.getElementById("printSummarySuccess").appendChild(cN);
            let batchInfoNode = document.getElementById("batchInfo").cloneNode(true);
            document.getElementById("printSummarySuccess").appendChild(batchInfoNode);
            let clonedNode = document
                .getElementById("summaryAccordionPrintsucc")
                .cloneNode(true);
            document
                .getElementById("printSummarySuccess")
                .appendChild(clonedNode);
        }
        if (document.getElementById("printSummaryFailed")) {
            while (
                document.getElementById("printSummaryFailed").childNodes
                    .length > 0
            ) {
                document
                    .getElementById("printSummaryFailed")
                    .removeChild(
                        document.getElementById("printSummaryFailed").lastChild
                    );
            }
            let cN = document.getElementById("failedAlertDiv").cloneNode(true);
            document.getElementById("printSummaryFailed").appendChild(cN);
            let batchInfoNode = document.getElementById("batchInfo").cloneNode(true);
            document.getElementById("printSummaryFailed").appendChild(batchInfoNode);
            let clonedNode = document
                .getElementById("summaryAccordionPrintfail")
                .cloneNode(true);
            document
                .getElementById("printSummaryFailed")
                .appendChild(clonedNode);
        }
        setTimeout(() => {
            this.printElement(body);
        }, 100);
    }

    onHideModal() {
        // if(!this.transactionActionService.paginationModal){
        //     this.routeToPageList=false;
        // }
        // else{
        //     this.routeToPageList=true;
        // }
        this.fetchByTxnNumber=false;
        this.transactionActionService.settleSuccess=false;
        this.transactionActionService.settleFailed=false;
        this.onCloseModal.emit(this.routeToPageList);
    }

    // onRouteBackOptionClick(event){
    //     console.log('routeToPageList -->', this.routeToPageList);
    //     this.fetchByTxnNumber = false;
    //     this.transactionActionService.settleSuccess = false;
    //     this.transactionActionService.settleFailed = false;
    //     // this.onCloseModal.emit();
    //     // this.onHideModal();
    // }
}
