import { Injectable } from '@angular/core';
 
import { HttpService } from '../shared/services/index';
import { map } from 'rxjs/operators';
const endpoint = '';

@Injectable()
export class SetupPreviewStore {
    static customerData: any[];
    static roleIdForPreview: string;

    static toggle(element: any): void {
        console.log('Inside SetupPreviewStore toggle function', element);
    }

    static getRoleId(): string {
        return SetupPreviewStore.roleIdForPreview;
    }

    static setRoleId(id: string): void {
        SetupPreviewStore.roleIdForPreview = id;
    }

    constructor(protected httpService: HttpService) {}

    getAll(params: any): any {
        if (params.businessID) {
            delete params.businessID;
        }
        return this.httpService.getAll(endpoint, params)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    store(element: any): any {
        return this.httpService.store(endpoint, element)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    update(elementID: string, newElement: any): any {
        return this.httpService.update(endpoint, elementID, newElement)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    get(elementID: any): any {
        return this.httpService.get(endpoint, elementID)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    destroy(elementID: any): any {
        return this.httpService.destroy(endpoint, elementID)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }
}
