import { Component, OnInit } from '@angular/core';
import { SetupBusinessInformationStore } from '../../../../../store';
import { TreeService, BusinessService, UrlStoreService, TabStructureService } from '../../../../../shared/services';
import { Router } from '@angular/router';

@Component({
	selector: 'app-setup-structure-information-list',
	templateUrl: './setup-structure-information-list.component.html',
	styleUrls: ['./setup-structure-information-list.component.scss']
})
export class SetupStructureInformationListComponent implements OnInit {
	// public attributes: any[] = [];
	// public storeValue: string = '';
	// public storeObject: any = {};
	// public action: any[] = [];
	// public parameters: any = {
	//     // noGrid: true
	//     // xTypeTag: 'struct.chain'
	// };
	// public noBiz: boolean = true;

	// public detailVisible: boolean = true;
	public statusID: string = '';
	public transactionType: string = 'all';
	// fetchByStore: boolean = true;

	// public viewDetails: any[] = [
	//   { header: 'Status', mapTo: 'xStatus' },
	//   { header: 'Instance Type ', mapTo: 'xInstTypeTag' },
	//   { header: 'Timestamp', mapTo: 'xTimestamp', type: 'date', format: DATEFORMAT },
	//   { header: 'Status Tag', mapTo: 'xStsTag' },
	//   { header: 'Current Value', mapTo: 'xStrVal' },
	//   { header: 'Current Value (Number)', mapTo: 'xNumVal' }
	// ];

	constructor(
		private router: Router,
		private setupBusinessInformationStore: SetupBusinessInformationStore,
		public treeService: TreeService,
		private businessService: BusinessService,
		public urlStoreService: UrlStoreService,
		private tabStructureService: TabStructureService
	) {
		// this.urlStoreService.previousUrl = this.urlStoreService.currentUrl;
		// console.log("current url biz info struct list", router.url);
		// this.urlStoreService.currentUrl = router.url;
		//this.urlStoreService.swapUrl(router.url);
	}

	ngOnInit() {
		this.treeService.isParent = false;
		//For self------>
		this.tabStructureService.checkAndSetTabNameToDef(
			this.tabStructureService.tabMode,
			this.tabStructureService.isCancelClick,
			this.tabStructureService.isSubmitClick
		);
		// console.log('This is the biz id', this.treeService.businessID.getValue());
		if (this.treeService.businessID.getValue()) {
			// this.treeService.isParent=true;
			console.log('This is the biz id in tree Service', this.treeService.businessID.getValue());
			this.router.navigate(['/dashboard/setup/business/structure/information', this.treeService.businessID.getValue()]);
		} else if (this.businessService.businessID.getValue()) {
			console.log('This is the biz id in biz service', this.businessService.businessID.getValue());
			this.router.navigate(['/dashboard/setup/business/structure/information', this.businessService.businessID.getValue()]);
		}
		// End for self------->
		// this.setGridProperties();
	}

	setGridProperties() {
		// this.storeValue = 'SetupBusinessInformationStore';
		// this.storeObject = this.setupBusinessInformationStore;
		// this.attributes = [
		//     { header: 'Active', mapTo: 'xStatus', width: '10%' },
		//     { header: 'Name', mapTo: 'xName', width: '15%' },
		//     { header: 'Sub Name', mapTo: 'xSubName', width: '15%' },
		//     { header: 'City', mapTo: 'xAddr.xCity', width: '15%' },
		//     // { header: 'Medium', mapTo: 'xMdmTag', width: '10%' },
		//     // { header: 'SoP', mapTo: 'xStrVal', width: '10%' },
		//     { header: 'Main Phone', mapTo: 'xMainContact.xPhone', width: '10%' }
		// ];
		// this.action = [
		//     {
		//         type: 'view',
		//         visible: true,
		//         text: 'Details',
		//         icon: 'fa fa-eye',
		//         action: 'emit'
		//     }
		//     // { type: 'other', visible: true, text: 'Settle', icon: 'fa fa-info', action: 'func' }
		// ];
	}

	// onActionRequest(obj: any) {
	//     if (obj.act.type === 'view') {
	//         console.log("I'm here bro", obj);
	//         this.statusID = obj.id;
	//         // this.detailVisible = true;
	//     }
	// }

	// viewClosed(visible: boolean) {
	//   if (!visible) {
	//     this.statusID = '';
	//   }

	// }
}
