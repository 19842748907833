export const TAGSCONSTS = {
    /*----------------  xbaConsts  ----------------*/
    companyTags: {
        companyType: 'comptype',
        vendorComp: 'comptype.ven',
        varComp: 'comptype.var',
        supportComp: 'comptype.sup',
        compItemTags: {},
    },
    representativeTags: {
        representativeType: 'reptype',
        vendorRep: 'reptype.ven',
        varRep: 'reptype.var',
        supportRep: 'reptype.sup',
        repItemTags: {},
    },
    buisnessTags: {
        buisnessType: 'biztype',
        regularBiz: 'biztype.reg',
        companyBiz: 'biztype.iscomp',
        vendorBiz: 'biztype.isven', //TODO-: verify with client
        bizItemTags: {},
    },
    structTags: {
        structType: 'strutype',
        chainTags: 'strutype.chain',
        regionTags: 'strutype.region',
        propertyTags: 'strutype.prop',
        structItemTags: {},
    },
    storeTags: {
        storeType: 'stotype',
        regularStore: 'stotype.reg',
        companyBizStore: 'stotype.iscomp',
        storeItemTags: {},
    },
    profitCenterTags: {
        profitType: 'pctrtype',
        regularPCtr: 'pctrtype.reg',
        pCtrItemTags: {},
    },
    employeeTags: {
        employeeType: 'emptype',
        regularEmp: 'emptype.reg',
        empItemTags: {},
    },
    customerTags: {
        customerType: 'custtype',
        regularCust: 'custtype.reg',
        businessCust: 'custtype.isbiz',
        companyCust: 'custtype.iscomp', //TODO-: verify with client
        custItemTags: {},
    },
    contactTags: {
        contactType: 'conttype',
        regularContact: 'conttype.reg',
        contactItemTags: {},
    },
    licenseTags: {
        licenseType: 'lcstype',
        regularLicense: 'lcstype.reg',
        testLicense: 'lcstype.tst',
        licenseItemTags: {},
    },
    emailTags: {
        emailType: 'emailtype',
        workEmail: 'emailtype.work',
        custServiceEmail: 'emailtype.cs',
        emailItemTags: {},
    },
    phoneTags: {
        phoneType: 'phonetype',
        workPhone: 'phonetype.work',
        custServicePhone: 'phonetype.cs',
        phoneItemTags: {},
    },
    /*---------------------------------------------*/


    /*----------------  xpaConsts  ----------------*/
        lookupTags: {
            lookupType: 'luptype',
            ruleLookup: 'luptype.rule',
            matrixLookup: 'luptype.mtx',
            lookupItemTags: {},

        },
        processorTags: {
            procType: 'proctype',
            regularProcessor: 'proctype.reg',
            procItemTags: {
                procItem: 'proc',
            },
            procCategoryTags: {
                worldpay: 'proc.worldpay',
                hps: 'proc.hps',
                usat: 'proc.usat',
                tsys: 'proc.tsys',
                elavon: 'proc.elavon',
                globalpayments: 'proc.globalpayments',
                paymentech: 'proc.paymentech',
                rapidconnect: 'proc.rapidconnect',
                vantiv: 'proc.vantiv',
            },
            procMidTags: {

            },
            procPntTags: {

            },
        },
        merchantAccountTags: {
            merAcctType: 'meraccttype',
            regularMerAcct: 'meraccttype.reg',
            merAcctItemTags: {},

        },
        terminalTags: {
            terminalType: 'trmltype',
            regularTerminal: 'trmltype.reg',
            terminalItemTags: {},

        },
        peripheralTags: {
            periphType: 'peritype',
            regularPeripheral: 'peritype.reg',
            periphItemTags: {},

        },
        paymentTags: {
            paymentType: 'paymethtype',
            regularPayment: 'paymethtype.reg',
            paymentItemTags: {},

        },
        vaultTags: {
            vaultType: 'vaulttype',
            regularVault: 'vaulttype.reg',
            vaultItemTags: {},

        },
        safeTags: {
            safeType: 'safetype',
            regularSafe: 'safetype.reg',
            safeItemTags: {},
        },
        batchTags: {
            batchType: 'batchtype',
            regularBatch: 'batchtype.reg',
            batchItemTags: {},
        },
        transactionTags: {
            txnType: 'txntype',
            regularTransaction: 'txntype.reg',
            txnItemTags: {},
        },
        parameterTags: {
            paramType: 'paramtype',
            regularParameter: 'paramtype.reg',
            paramItemTags: {},
        },
        deviceModelTags: {
            devModelType: 'modeltype',
            regularDevice: 'modeltype.reg',
            devModelItemTags: {},
        },
        accountCDTags: {
            accountCDType: 'acctcdtype',
            regularAccountCD: 'acctcdtype.reg',
            accountCDItemTags: {
                accountCDItem: 'acctcd',
                creditToAccount: 'acctcd.credit',
                debitFromAccount: 'acctcd.debit',
            },
            accountCDMidTags: {
                creditToAccount: null,
                debitFromAccount: null,
            },
            accountCDPntTags: {
                creditToAccount: 'acctcd',
                debitFromAccount: 'acctcd',
            },
        },
        transactionActionTags: {
            txnActionType: 'txnacttype',
            regularTransaction: 'txnacttype.reg',
            txnActionItemTags: {
                // txnActionItem: 'txnact',
                inquiry: 'txnact.inquiry',
                auth: 'txnact.auth',
                sale: 'txnact.purchase',
                return: 'txnact.return',
                verify: 'txnact.verify',
            },
            txnActionMidTags: {
                inquiry: null,
                auth: null,
                purchase: null,
                return: null,
                verify: null,
            },
            txnActionPntTags: {
                inquiry: 'txnact',
                auth: 'txnact',
                purchase: 'txnact',
                return: 'txnact',
                verify: 'txnact',
            },
        },
        mediumTags: {
            mdmType: 'mdmtype',
            regularMedium: 'mdmtype.reg',
            mdmItemTags: {
                // mdmItem: 'mdm',
                cash: 'mdm.cs',
                creditCard: 'mdm.cc',
                debitCard: 'mdm.dc',
                giftCard: 'mdm.gc',
                prepaidCard: 'mdm.pc',
                loyaltyCard: 'mdm.lc',
                ebtCard: 'mdm.eb',
                electronicCheck: 'mdm.ec',
            },
            mdmMidTags: {
                creditCard: 'cc',
                debitCard: 'dc',
                giftCard: 'gc',
                prepaidCard: 'pc',
                loyaltyCard: 'lc',
                ebtCard: 'eb',
                electronicCheck: 'ec',
            },
            mdmPntTags: {
                creditCard: 'mdm',
                debitCard: 'mdm',
                giftCard: 'mdm',
                prepaidCard: 'mdm',
                loyaltyCard: 'mdm',
                ebtCard: 'mdm',
                electronicCheck: 'mdm',
            },
        },
        brandTags: {
            brandType: 'brandtype',
            regularBrand: 'brandtype.reg',
            genericBrand: 'brandtype.gen',
            brandItemTags: {
                // brandItem: 'brand',
                cash:'brand.cs',
                genericCreditCard: 'brand.cc',
                visa: 'brand.cc.vs',
                masterCard: 'brand.cc.mc',
                americanExpress: 'brand.cc.ax',
                discover: 'brand.cc.ds',
                dinersClub: 'brand.cc.dn',
                carteBlanche: 'brand.cc.cb',
                enRoute: 'brand.cc.en',
                jcb: 'brand.cc.jc',
                jal: 'brand.cc.ja',
                cup: 'brand.cc.up',
                maestroCard: 'brand.cc.ma',
                genericDebitCard: 'brand.dc',
                genericGiftCard: 'brand.gc',
                genericPrepaidCard: 'brand.pc',
                genericLoyaltyCard: 'brand.lc',
                genericEBTCard: 'brand.eb',
                foodStampCard: 'brand.eb.fs',
                cashBenefitCard: 'brand.eb.cb',
                genericElectronicCheck: 'brand.ec',
            },
            brandMidTags: {
                genericCreditCard: 'cc',
                visa: 'cc',
                masterCard: 'cc',
                americanExpress: 'cc',
                discover: 'cc',
                dinersClub: 'cc',
                carteBlanche: 'cc',
                enRoute: 'cc',
                jcb: 'cc',
                jal: 'cc',
                cup: 'cc',
                genericDebitCard: 'dc',
                maestroCard: 'dc',
                genericGiftCard: 'gc',
                genericPrepaidCard: 'pc',
                genericLoyaltyCard: 'lc',
                genericEBTCard: 'eb',
                foodStampCard: 'eb',
                cashBenefitCard: 'eb',
                genericElectronicCheck: 'ec',
            },
            brandPntTags: {
                genericCreditCard: 'brand',
                visa: 'brand',
                masterCard: 'brand',
                americanExpress: 'brand',
                discover: 'brand',
                dinersClub: 'brand',
                carteBlanche: 'brand',
                enRoute: 'brand',
                jcb: 'brand',
                jal: 'brand',
                cup: 'brand',
                genericDebitCard: 'brand',
                maestroCard: 'brand',
                genericGiftCard: 'brand',
                genericPrepaidCard: 'brand',
                genericLoyaltyCard: 'brand',
                genericEBTCard: 'brand',
                foodStampCard: 'brand',
                cashBenefitCard: 'brand',
                genericElectronicCheck: 'brand',
            },
        },
        industryTags: {
            industryType: 'indsttype',
            regularIndustry: 'indsttype.reg',
            industryItemTags: {
                industryItem: 'indst',
                retail: 'indst.rtl',
                eComm: 'indst.ecm',
                restaurant: 'indst.rst',
                hotel: 'indst.htl',
                rental: 'indst.ren',
                gas: 'indst.gas',
                moto: 'indst.mot',
            },
            industryMidTags: {
                retail: null,
                eComm: null,
                restaurant: null,
                hotel: null,
                rental: null,
                gas: null,
                moto: null,
            },
            industryPntTags: {
                retail: 'indst',
                eComm: 'indst',
                restaurant: 'indst',
                hotel: 'indst',
                rental: 'indst',
                gas: 'indst',
                moto: 'indst',
            },
        },
        accountEntryTags: {
            acctEntType: 'acctenttype',
            regularAccountEntry: 'acctenttype.reg',
            acctEntItemTags: {
                // acctEntItem: 'acctent',
                none: 'acctent.none',
                manual: 'acctent.man',
                swipe: 'acctent.sw',
                emvMan: 'acctent.em',
                emvSwipe: 'acctent.es',
                emvCles: 'acctent.el',
                emvcontact: 'acctent.ec',
                msr: 'acctent.msr',
                msrTrack1: 'acctent.msr.trk1',
                msrTrack2: 'acctent.msr.trk2',
                msrTrack3: 'acctent.msr.trk3',
                icc: 'acctent.icc',
                contactless: 'acctent.cles',
                micr: 'acctent.micr',
                applePay:   'acctent.applepay',
                googlePay:   'acctent.googlepay',
            },
            acctEntMidTags: {
                none: null,
                manual: null,
                swipe: null,
                emvMan: null,
                emvSwipe: null,
                emvCles: null,
                emvcontact: null,
                msr: null,
                msrTrack1: null,
                msrTrack2: null,
                msrTrack3: null,
                icc: null,
                contactless: null,
                micr: null,
                applePay:  null,
                googlePay:   null,
            },
            acctEntPntTags: {
                none: 'acctent',
                manual: 'acctent',
                swipe: 'acctent',
                emvMan: 'acctent',
                emvSwipe: 'acctent',
                emvCles: 'acctent',
                emvcontact: 'acctent',
                msr: 'acctent',
                msrTrack1: 'acctent',
                msrTrack2: 'acctent',
                msrTrack3: 'acctent',
                icc: 'acctent',
                contactless: 'acctent',
                micr: 'acctent',
                applePay:  'acctent',
                googlePay:   'acctent',
            },
        },
        pinEntryTags: {
            pinEntType: 'pinenttype',
            regularPinEntry: 'pinenttype.reg',
            pinEntItemTags: {
                pinEntItem: 'pinent',
                none: 'pinent.none',
                manual: 'pinent.man',
            },
            pinEntMidTags: {
                none: null,
                manual: null,
            },
            pinEntPntTags: {
                none: 'pinent',
                manual: 'pinent',
            },
        },
        signatureEntryTags: {
            sigEntType: 'sigenttype',
            regularSigEntry: 'sigenttype.reg',
            sigEntItemTags: {
                sigEntItem: 'sigent',
                none: 'sigent.none',
                slip: 'sigent.slip',
                tablet: 'sigent.tab',
            },
            sigEntMidTags: {
                none: null,
                slip: null,
                tablet: null,
            },
            sigEntPntTags: {
                none: 'sigent',
                slip: 'sigent',
                tablet: 'sigent',
            },
        },
        attendanceTags: {
            attnType: 'attntype',
            regularAttendance: 'attntype.reg',
            attnItemTags: {
                attnItem: 'attn',
                none: 'attn.none',
                attended: 'attn.attnded',
                video: 'attn.video',
            },
            attnMidTags: {
                none: null,
                attended: null,
                video: null,
            },
            attnPntTags: {
                none: 'attn',
                attended: 'attn',
                video: 'attn',
            },
        },
        presenceTags: {
            presType: 'prestype',
            regularPresence: 'prestype.reg',
            presItemTags: {
                presItem: 'pres',
                none: 'pres.none',
                inStore: 'pres.store',
                atStand: 'pres.stand',
                viaMail: 'pres.mail',
                viaPhone: 'pres.phone',
                viaWeb: 'pres.web',
            },
            presMidTags: {
                none: null,
                inStore: null,
                atStand: null,
                viaMail: null,
                viaPhone: null,
                viaWeb: null,
            },
            presPntTags: {
                none: 'pres',
                inStore: 'pres',
                atStand: 'pres',
                viaMail: 'pres',
                viaPhone: 'pres',
                viaWeb: 'pres',
            },
        },
        recurrenceTags: {
            recurType: 'recurtype',
            regularRecurrence: 'recurtype.reg',
            recurItemTags: {
                recurItem: 'recur',
                none: 'recur.none',
                recurring: 'recur.recur',
                installment: 'recur.instl',
            },
            recurMidTags: {
                none: null,
                recurring: null,
                installment: null,
            },
            recurPntTags: {
                none: 'recur',
                recurring: 'recur',
                installment: 'recur',
            },
        },
        certificateTags: {
            certType: 'certtype',
            regularCertificate: 'certtype.reg',
            certItemTags: {
                certItem: 'cert',
                none: 'cert.none',
                merchantCert: 'cert.merc',
                customerCert: 'cert.cust',

            },
            certMidTags: {
                none: null,
                merchantCert: null,
                customerCert: null,
            },
            certPntTags: {
                none: 'cert',
                merchantCert: 'cert',
                customerCert: 'cert',
            },
        },
        encryptionTags: {
            encType: 'enctype',
            regularEncryption: 'enctype.reg',
            encItemTags: {
                encItem: 'enc',
                none: 'enc.none',
                channel: 'enc.chn',
                'set': 'enc.set',

            },
            encMidTags: {
                none: null,
                channel: null,
                'set': null,
            },
            encPntTags: {
                none: 'enc',
                channel: 'enc',
                'set': 'enc',
            },
        },
        purchasingCardTags: {
            pcardType: 'pcardtype',
            regularPurchasingCard: 'pcardtype.reg',
            pcardItemTags: {
                pcardItem: 'pcard',
                unknown: 'pcard.unk',
                notPurchasingCard: 'pcard.no',
                purchasingCard: 'pcard.pur',
                businessCard: 'pcard.biz',
                corporateCard: 'pcard.corp',

            },
            pcardMidTags: {
                unknown: null,
                notPurchasingCard: null,
                purchasingCard: null,
                businessCard: null,
                corporateCard: null,
            },
            pcardPntTags: {
                unknown: 'pcard',
                notPurchasingCard: 'pcard',
                purchasingCard: 'pcard',
                businessCard: 'pcard',
                corporateCard: 'pcard',
            },
        },
        captureTags: {
            captureType: 'capttype',
            regularCapture: 'capttype.reg',
            captureItemTags: {
                // captureItem: 'capt',
                hostCapture: 'capt.host',
                terminalCapture: 'capt.terml',
            },
            captureMidTags: {
                hostCapture: null,
                terminalCapture: null,
            },
            capturePntTags: {
                hostCapture: 'capt',
                terminalCapture: 'capt',
            },
        },
        taxIndicatorTags: {
            taxIndicatorType: 'taxtype',
            regularTaxIndicator: 'taxtype.reg',
            taxIndicatorItemTags: {
                taxIndicatorItem: 'tax',
                taxNotProvided: 'tax.np',
                taxProvided: 'tax.prvd',
                taxExempt: 'tax.exmp',
            },
            taxIndicatorMidTags: {
                taxNotProvided: null,
                taxProvided: null,
                taxExempt: null,
            },
            taxIndicatorPntTags: {
                taxNotProvided: 'tax',
                taxProvided: 'tax',
                taxExempt: 'tax',
            },
        },
        misclaneousTags: {
            miscType: 'misctype',
            regularMisc: 'misctype.reg',
            miscItemTags: {

                devModelItem: 'model',
                devModelType1: 'model.type-1',
                devModelType2: 'model.type-2',
                devModelType3: 'model.type-3',

                devTypeItem: 'dev',
                devType1: 'dev.type-1',
                devType2: 'dev.type-2',
                devType3: 'dev.type-3',

                devFamilyItem: 'family',
                devFamilyType1: 'family.type-1',
                devFamilyType2: 'family.type-2',
                devFamilyType3: 'family.type-3',
            },
        },

    /*---------------------------------------------*/


    /*----------------  xsaConsts  ----------------*/
    defaultTags: {
        defaultType: 'deftype',
        booleanDefault: 'deftype.bln',
        defaultItemTags: {
            defaultItem: 'def',
            none: 'def.none',
        },
        defaultMidTags: {
            none: null, //TODO-: check with client
        },
        defaultPntTags: {
            none: 'def',
        },
    },
    tenantTags: {
        tenantType: 'tnttype',
        regularTenant: 'tnttype.reg',
        tenantItemTags: {},
    },
    groupTags: {
        grpType: 'grptype',
        regularGroup: 'grptype.reg',
        grpItemTags: {
            groupItem: 'grp',
        },
    },
    relationshipTags: {
        relType: 'reltype',
        // relationshipItem: 'rel',
        regularRelationship: 'reltype.reg',
        relItemTags: {
            relationshipItem: 'rel',
        },
    },
    userTags: {
        userType: 'usrtype',
        regularUser: 'usrtype.reg',
        adminUser: 'usrtype.adm',
        guestUser: 'usrtype.gst',
        userItemTags: {},
    },
    roleTags: {
        roleType: 'roletype',
        regularRole: 'roletype.reg',
        roleItemTags: {
            vendorRole: 'role.ven',
            representativeRole: 'role.rep',
            employeeRole: 'role.emp',
            customerRole: 'role.cust',
        },
    },
    moduleTags: {
        moduleType: 'mdutype',
        // moduleItem: 'mdu',
        regularModule: 'mdutype.reg',
        moduleItemTags: {
            moduleItem: 'mdu',
            systemModule: 'mdu.sys',
            setupBilling: 'mdu.setup.billing',
            setup: 'mdu.setup',
			txn: 'mdu.txn',
			roles: 'mdu.roles',
			cust: 'mdu.cust',
			emp: 'mdu.emp',
			rep: 'mdu.rep',
			safes: 'mdu.safes',
            company: 'mdu.company',
            business: 'mdu.business',
            importTxns: 'mdu.importTxns',
            batch: 'mdu.batch',
            billing: 'mdu.billing',
        },
        moduleMidTags: {
            setupModule: 'setup',
            sysModule: 'sys'
        },
        modulePntTags: {
            sysModule: 'mdu.sys',
            setupModule: 'mdu.setup',
        },
    },
    functionTags: {
        funcType: 'functype',
        // functionItem: 'func',
        regularFunction: 'functype.reg',
        funcItemTags: {
            functionItem: 'func',
            systemLogin: 'func.sys.login',
            viewCompleteBusinessList: 'func.setup.business.viewBusiness.businessList.viewList',
            viewSelfBusinessInfo: 'func.setup.business.viewBusiness.businessList.viewSelf',
            editCompleteBusinessList: 'func.setup.business.editBusiness.businessList.viewList',
            viewCompleteCompanyList: 'func.setup.company.viewCompany.companyList.viewList',
            viewSelfCompanyInfo: 'func.setup.company.viewCompany.companyList.viewSelf',
            editCompleteCompanyList: 'func.setup.company.editCompany.companyList.viewList',
            viewCompleteCompanyRepList: 'func.setup.company.viewRep.companyList.viewList',
            viewSelfCompanyRepInfo: 'func.setup.company.viewRep.companyList.viewSelf',
            //setup strucutre [business, store, pctr etc] tags
			createSetup: 'func.setup.create',
			viewSetup: 'func.setup.view',
			editSetup: 'func.setup.edit',
			deleteSetup: 'func.setup.delete',
			//dealer tags
			createDealer: 'func.rep.create',
			viewDealer: 'func.rep.view',
			editDealer: 'func.rep.edit',
			deleteDealer: 'func.rep.delete',
			//customer tags
			createCustomer: 'func.cust.create',
			viewCustomer: 'func.cust.view',
			editCustomer: 'func.cust.edit',
			deleteCustomer: 'func.cust.delete',
            viewCustTxns: 'func.cust.viewTxn',
            //employee tags
			createEmployee: 'func.emp.create',
			viewEmployee: 'func.emp.view',
			editEmployee: 'func.emp.edit',
			deleteEmployee: 'func.emp.delete',
			//safes tags
			createSafes: 'func.safes.create',
			viewSafes: 'func.safes.view',
			editSafes: 'func.safes.edit',
			deleteSafes: 'func.safes.delete',
            payNow: 'func.safes.paynow',
			//role tags
			createRole: 'func.roles.create',
			viewRole: 'func.roles.view',
			editRole: 'func.roles.edit',
			deleteRole: 'func.roles.delete',
            //business tags
			createBusiness: 'func.business.create',
			viewBusiness: 'func.business.view',
			editBusiness: 'func.business.edit',
			deleteBusiness: 'func.business.delete',
			//Company tags
			createCompany: 'func.company.create',
			viewCompany: 'func.company.view',
			editCompany: 'func.company.edit',
			deleteCompany: 'func.company.delete',
            viewStructure: 'func.company.viewStructure',
			viewImportResponse: 'func.company.viewImportResponse',
			//txn tags
			settleTxn: 'func.txn.settle',
			voidTxn: 'func.txn.void',
			redoTxn: 'func.txn.redo',
			reverseTxn: 'func.txn.reverse',
			finalizeTxn: 'func.txn.finalize',
			editTxn: 'func.txn.edit',
            authTxn: 'func.txn.auth',
			printTxn: 'func.txn.print',
			reportTxn: 'func.txn.report',
			reprintTxn: 'func.txn.reprint',
            viewTxnDetails: 'func.txn.viewDetails',
            createTxn: 'func.txn.create',
			//improt txns
			createImport: 'func.importTxns.create',
            viewImport: 'func.importTxns.view',
			//batch tags
			settleBatch: 'func.batch.settle',
			viewBatchTxns: 'func.batch.viewBatchTxns',
			cleanup: 'func.batch.cleanup',
			resync: 'func.batch.resync',
            viewBatchDetails: 'func.batch.view'
        },
        funcMidTags: {
            setupModule: 'setup',
            sysModule: 'sys',
        },
        funcPntTags: {
            sysModule: 'func.sys',
            setupBizModule: 'func.setup.business',
            setupCompModule: 'func.setup.company',
        },
    },
    permissionTags: {
        permType: 'permtype',
        // permissionItem: 'perm',
        regularPermission: 'permtype.reg',
        permItemTags: {
            permissionItem: 'perm',
            accessPermission: 'perm.acc',
            readPermission: 'perm.rd',
            writePermission: 'perm.wt',
            execPermission: 'perm.ex',
        },
        permMidTags: {
            access: null,
            read: null,
            write: null,
            exec: null,
        },
        permPntTags: {
            access: 'perm',
            read: 'perm',
            write: 'perm',
            exec: 'perm',
        },
    },
    accessTags: {
        accessType: 'acctype',
        // accessItem: 'acc',
        regularAccess: 'acctype.reg',
        accessItemTags: {
            accessItem: 'acc',
        },
    },
    attributeTags: {
        attrType: 'attrtype',
        // attributeItem: 'attr',
        regularAttribute: 'attrtype.reg',
        attrItemTags: {
            attributesItem: 'attr',
        },
    },
    configurationTags: {
        cnfgType: 'cfgtype',
        // configurationItem: 'cfg',
        regularConfiguration: 'cfgtype.reg',
        cnfgItemTags: {
            configurationItem: 'cfg',
        },
    },
    counterTags: {
        counterType: 'cntertype',
        // counterItem: 'cnter',
        regularCounter: 'cntertype.reg',
        counterItemTags: {
            counterItem: 'cnter',
            batchNumberCounter: 'cnter.batch.num',
            transactionNumberCounter: 'cnter.txn.num',
            businessBalanceCounter: 'cnter.biz.bal',
        },
        counterMidTags: {
            batch: 'batch',
            txn: 'txn',
            biz: 'biz',
        },
        counterPntTags: {
            batch: 'cnter.batch',
            txn: 'cnter.txn',
            biz: 'cnter.biz',
        },
    },
    pointerTags: {
        pointerType: 'ptrtype',
        // pointerItem: 'ptr',
        regularPointer: 'ptrtype.reg',
        pointerItemTags: {
            pointerItem: 'ptr',
            batchIDPointer: 'ptr.batch.id',
        },
        pointerMidTags: {
            batchID: 'batch',
        },
        pointerPntTags: {
            batchID: 'ptr.batch',
        },
    },
    statusTags: {
        statusType: 'ststype',
        // statusItem: 'sts',
        regularStatus: 'ststype.reg',
        statusItemTags: {
            statusItem: 'sts',
            deviceOnlineStatus: 'sts.dev.on',
            deviceOfflineStatus: 'sts.dev.off',
            userChangePasswordStatus: 'sts.usr.chgpwd',
        },
        statusMidTags: {
            devOnline: 'dev',
            devOffline: 'dev',
            usrChangePwd: 'usr',
        },
        statusPntTags: {
            devOnline: 'sts.dev',
            devOffline: 'sts.dev',
            usrChangePwd: 'sts.usr',
        },
    },
    statisticsTags: {
        statType: 'stattype',
        // statisticsItem: 'stat',
        regularStatistics: 'stattype.reg',
        statItemTags: {
            statisticsItem: 'stat',
            deviceRebootStatistics: 'stat.dev.reboot',
        },
        statMidTags: {
            devRebbot: 'dev',
        },
        statPntTags: {
            devRebbot: 'stat.dev',
        },
    },
    scheduleTags: {
        schType: 'schtype',
        // scheduleItem: 'sch',
        regularSchedule: 'schtype.reg',
        schItemTags: {
            scheduleItem: 'sch',
        },
    },
    logTags: {
        logType: 'logtype',
        // logItem: 'log',
        regularLog: 'logtype.reg',
        logItemTags: {
            logItem: 'log',
        },
    },
    countryTags: {
        countryType: 'cntytype',
        regularCountry: 'cntytype.reg',
        countryItemTags: {
            countryItem: 'cnty',
            unitedStates: 'cnty.us',
            canada: 'cnty.ca',
        },
        countryMidTags: {
            //TODO-: clarify with client
            us: 'us',
            canada: 'ca',
        },
        countryPntTags: {
            //TODO-: clarify with client
            us: 'cnty.us',
            canada: 'cnty.ca',
        },
    },
    timeZoneTags: {
        timeZoneType: 'tztype',
        regularTimeZone: 'tztype.reg',
        timeZoneItemTags: {
            timeZoneItem: 'tz',
            america_NewYork_EST: 'tz.America/New_York',
            america_Chicago_CST: 'tz.America/Chicago',
            america_Denver_MST: 'tz.America/Denver',
            america_LosAngeles_PST: 'tz.America/Los_Angeles',
            america_Honolulu_HST: 'tz.America/Honolulu',
            america_Anchorage_AST: 'tz.America/Anchorage',
        },
        timeZoneMidTags: {
            america: 'America',
        },
        timeZonePntTags: {
            america: 'tz.America',
        },
    },
    currencyTags: {
        currencyType: 'ccytype',
        regularCurrency: 'ccytype.reg',
        currencyItemTags: {
            currencyItem: 'ccy',
            usDollar: 'ccy.usd',
            canadianDollar: 'ccy.cad',
            chineseYuan: 'ccy.cny',
        },
        currencyMidTags: {
            usDollar: null,
            canadianDollar: null,
            chineseYuan: null,
        },
        currencyPntTags: {
            usDollar: 'ccy',
            canadianDollar: 'ccy',
            chineseYuan: 'ccy',
        },
    },
    dataSourceTags: {
        dataSrcType: 'datasrctype',
        reguluarDataSrc: 'datasrctype.reg',
        dataSrcItemTags: {
            dataSrcItem: 'datasrc',
            self: 'datasrc.self',
            external: 'datasrc.ext',
        },
        dataSrcMidTags: {
            self: null,
            external: null,
        },
        dataSrcPntTags: {
            self: 'datasrc',
            external: 'datasrc',
        },
    },
    dataOwnerTags: {
        dataOwnerType: 'dataownertype',
        reguluarDataOwner: 'dataownertype.reg',
        dataOwnerItemTags: {
            dataOwnerItem: 'dataowner',
            self: 'dataowner.self',
            external: 'dataowner.ext',
            toExternal: 'dataowner.toext',
        },
        dataOwnerMidTags: {
            self: null,
            external: null,
            toExternal: null,
        },
        dataOwnerPntTags: {
            self: 'dataowner',
            external: 'dataowner',
            toExternal: 'dataowner',
        },
    },
    /*---------------------------------------------*/

    /*----------------  xiaConsts  ----------------*/
    invoiceTags: {
        invoiceType: 'invtype',
        regularInvoice: 'invtype.reg',
    },
    termsTags: {
        termsType: 'terms',
        // regularTerms: 'terms.reg',
        termsItemTags: {
            termsItem: 'terms',
            paymentInAdvanceTermIt: 'terms.pia',
            endOfMonth: 'terms.eom',
            net: 'terms.net',
        },
    },
    /*---------------------------------------------*/
    };