import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';

@Injectable()
export class ApiConfig{
	constructor(private http: HttpClient){}

	private API_HOST_ENV:string = '';
	private API_HOST_REGISTER_DEVICE:string = '';

	public getConfig(){
		return new Promise((resolve,reject)=>{
			this.http.get('./../../../assets/config/api-config.json')
			.subscribe((response:any)=>{
				console.log('getConfig: response',response);
				this.API_HOST_ENV = response.API_HOST_LOCAL;
				this.API_HOST_REGISTER_DEVICE = response.API_HOST_LOCAL_REGISTER
				if(environment){
					if(environment.production){
						this.API_HOST_ENV = response.API_HOST_PRODUCTION; 
						this.API_HOST_REGISTER_DEVICE = response.API_HOST_PRODUCTION_REGISTER;
					}
					
					else if(environment.staging){
						this.API_HOST_ENV = response.API_HOST_STAGING;
						this.API_HOST_REGISTER_DEVICE = response.API_HOST_STAGING_REGISTER;
					}else if(environment.hmr){
						this.API_HOST_ENV = response.API_HOST_QA;
						this.API_HOST_REGISTER_DEVICE = response.API_HOST_QA_REGISTER;
					}
				}
				console.log("API_HOST:", this.API_HOST_ENV);
				console.log("API_REGISTER_TERMINAL:", this.API_HOST_REGISTER_DEVICE);
				return resolve(response);
			})
		})
	}

	get API_HOST():string{
		return this.API_HOST_ENV;
	}
	get API_REGISTER_TERMINAL():string {
		return this.API_HOST_REGISTER_DEVICE;
	}
}
