import {
    Component,
    OnInit,
    OnDestroy,
    /*Input,*/ Output,
    EventEmitter
} from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { forkJoin } from "rxjs";
import { takeWhile, skip } from "rxjs/operators";
import { TAGSCONSTS } from "../../../../../../constants/tags.constants";
import {
    SetupBusinessInformationStore,
    SetupBusinessStructureStore,
    ConstantStore /*, BusinessTreeStore*/
} from "../../../../../../store";
import {
    BreadcrumbService,
    NotificationService,
    BusinessService,
    GlobalValidator,
    TreeService,
    UrlStoreService,
    TabStructureService,
    UserHandlerService
} from "../../../../../../shared/services";
import { Region } from "../../../../../../models";
import { NO_PERMISSION_MSG } from "../../../../../../constants";

@Component({
    selector: "add-edit",
    templateUrl: "./add-edit.component.html",
    styleUrls: ["./add-edit.component.scss"]
})
export class SetupStructureRegionComponent implements OnInit, OnDestroy {
    @Output()
    formSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
    nodePath: any[] = [];
    bizID: string = "";
    submitting: boolean = false; //For handling form submission
    apiLoading: boolean = false; //For handling API Calls
    edit: boolean = false; //For maintaining if edit mode or not
    regionSetupModel: any = {};
    form: FormGroup; //Required for reactive form validation
    errors: any; //Stores errors returned by server
    ifRegionExtendsChain: boolean = false; //Maintains toggle for indicating if region extends chain
    noRegion: boolean = false;
    chainName: string;
    alive: boolean = true;
    serverErrors: any = {}; //Stores errors returned by server
    formErrors = {
        xName: "",
        // xPhone: '',
        xSubName: "",
        xTag: "",
        xRefNum: "",
        xStructNum: ""
    };
    numberKey: string = "xStructNum";
    parentMode: boolean = false;
    addressContactValidate: boolean = false;
    bizChangeEvent: boolean = false;
    loading: boolean = false;
    canEdit = true;
    canAdd = true;
    noPermMsg = NO_PERMISSION_MSG;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private notificationService: NotificationService,
        public businessService: BusinessService,
        private setupBusinessStructureStore: SetupBusinessStructureStore,
        private constantStore: ConstantStore,
        private fb: FormBuilder,
        private treeService: TreeService,
        public urlStoreService: UrlStoreService,
        private tabStructureService: TabStructureService,
        private setupBusinessInformationStore: SetupBusinessInformationStore,
        public userHandlerService: UserHandlerService
    ) {}

    ngOnInit() {
        this.setupPerms();
        this.tabStructureService.checkAndSetTabNameToDef(
            this.tabStructureService.tabMode,
            this.tabStructureService.isCancelClick,
            this.tabStructureService.isSubmitClick
        );
        this.urlStoreService.submitAction = false;
        this.setBreadCrumbValue();
        this.nodePath = this.setupBusinessStructureStore.getNodePath();
        this.bizID = this.businessService.businessID.getValue();
        this.route.params.forEach((params: Params) => {
            this.edit = false;
            this.toFormGroup(new Region());
            this.apiLoading = true;
            //CHECK FOR EDIT MODE
            if (params.hasOwnProperty("Id")) {
                this.edit = true;
                if (params.Id === "null") {
                    //Check whether the region exists for selected node or not (upward hierarchy in business structure)
                    this.noRegion = true;
                } else {
                    //Edit Mode
                    this.loading = true;
                    if (
                        !this.treeService.regionID.getValue() &&
                        !this.treeService.isParent
                    ) {
                        console.log("Inside If");
                        this.treeService.setId(params.Id, "region");
                    }
                    this.fetchRegionData(params["Id"]);
                }
            }
        });
        if (this.tabStructureService.tabMode) {
            this.checkParentMode();
        }
        this.setTabNameNewRegion();

        this.setupBusinessStructureStore.watchNodePath$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe((nodePath: any[]) => {
                console.log(
                    "This is updated node path region",
                    this.urlStoreService.currentUrl.includes("region")
                );
                this.nodePath = nodePath;
                this.addModeActions();
                this.parentMode = false;
                // this.setTabNameNewRegion();
            });
        this.addModeActions();
        this.tabStructureService.watchParentFetched$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(res => {
                console.log("Here in watcher of parent fetched", res);
                if (res) {
                    console.log("Here in watcher of parent fetched res true");
                    this.setTabNameNewRegion();
                }
            });
        this.businessService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizId => {
                if (bizId) {
                    this.bizChangeEvent = true;
                } else {
                    this.router.navigate([
                        "/dashboard/setup/business/structure/information"
                    ]);
                }
            });
    }

    // setParentAddress(observable){
    //     Observable.forkJoin([observable])
    //             .subscribe((res: any)=>{
    //                 console.log("res", JSON.parse(JSON.stringify(res[0].data)));
    //                 this.parentAddress=res[0].data.xAddr;
    //                 this.parentContact=res[0].data.xMainContact;
    //                 console.log("this.form", JSON.parse(JSON.stringify(this.form.value)));
    //                 // if(this.form.controls.xAddr){
    //                 //     this.form.controls.xAddr.setValue=this.parentAddress;
    //                 // }
    //             })
    // }

    checkParentMode() {
        if (this.tabStructureService.tabLabelItems.length) {
            this.tabStructureService.tabLabelItems.map(labelItem => {
                if (labelItem.label.includes("Region")) {
                    if (
                        labelItem.hasOwnProperty("type") &&
                        labelItem["type"] === "Parent"
                    ) {
                        this.parentMode = true;
                    } else {
                        this.parentMode = false;
                    }
                }
            });
        }
    }

    setTabNameNewRegion() {
        if (!this.edit) {
            console.log(
                "%c Add mode hai k??",
                "background: blue; color: white"
            );
            if (this.tabStructureService.tabLabelItems.length) {
                console.log(
                    "%c Add mode hai k??",
                    "background: pink; color: white"
                );
                this.tabStructureService.tabLabelItems.map(labelItem => {
                    console.log(
                        "%c Add mode hai k??",
                        "background: grey; color: black",
                        labelItem
                    );
                    if (labelItem.label.includes("Region")) {
                        if (labelItem.hasOwnProperty("type")) {
                            labelItem["label"] = "Region";
                            labelItem["type"] = "New";
                            labelItem["name"] = "";
                        }
                    }
                });
                console.log(
                    "%c Region Add mode hai k??",
                    "background: grey; color: white",
                    this.tabStructureService.tabLabelItems
                );
                this.tabStructureService.setTabStructureNames(
                    this.tabStructureService.tabLabelItems
                );
            }
        }
    }

    fetchRegionData(id: string) {
        forkJoin(
            this.setupBusinessStructureStore.get(id)
        ).subscribe(
            (response: any) => {
                this.loading = false;
                this.regionSetupModel = response[0].data;
                this.form.patchValue(new Region(this.regionSetupModel));
                this.checkRegionHierarchy();
            },
            (error: any) => {
                this.handleErrorResponse(error);
            },
            () => {
                this.apiLoading = false;
            }
        );
    }

    addModeActions() {
        console.log("this.edit", this.edit);
        // this.tabStructureService.setTabNameToDefault();
        if (!this.edit) {
            this.bizID = this.businessService.businessID.getValue();
            this.ifRegionExtendsChain = this.setChainId();
            this.setBusinessID();
            // if(this.ifRegionExtendsChain){
            //     this.setParentAddress(this.setupBusinessStructureStore.get(this.chainID.value).map((res: Response) => res));
            // }
            // else{
            //     this.setParentAddress(this.setupBusinessInformationStore.get(this.bizID).map((res: Response) => res));
            // }
        }
    }

    toFormGroup(data: Region): void {
        this.form = this.fb.group({
            xTntTag: [data.xTntTag || "test", []],
            xWall1EntyID: [data.xWall1EntyID || "", []],
            xWall2EntyID: [data.xWall2EntyID || this.bizID, []],
            xTag: [data.xTag || "struct", []],
            xStatus: [data.xStatus || "1", []],
            xName: [
                data.xName || "",
                [Validators.required, GlobalValidator.validateName]
            ],
            xTypeTag: [
                data.xTypeTag || TAGSCONSTS.structTags.regionTags,
                [
                    /*Validators.required*/
                ]
            ],
            xNotes: [data.xNotes || "", []],
            xRefNum: [
                data.xRefNum || "",
                [
                    /*Validators.required*/
                ]
            ],
            xTZTag: [
                data.xTZTag || "",
                [
                    /*Validators.required*/
                ]
            ],
            xSubName: [
                data.xSubName || "",
                [
                    /*Validators.required*/
                ]
            ],
            // xAddr: this.fb.group(new Address(data.xAddr)),
            // xSocial: this.fb.group(new Social(data.xSocial)),
            // xMainContact: this.fb.group(new MainContact(data.xMainContact)),
            xBusinessID: [data.xBusinessID || this.bizID],
            xChainID: [data.xChainID || ""],
            xStructNum: [data.xStructNum || "", [Validators.required]]
        });
    }

    // checkRegionHierarchy() {
    //   // check for region hierarchy[region extending chain] in edit mode
    //   // console.log('this.chainID', this.chainID.value);
    //   const chainID: string = this.chainID.value;
    //   this.ifRegionExtendsChain = chainID.length ? true : false;
    //   if (chainID.length) {
    //     // console.log('Node path', this.setupBusinessStructureStore.getNodePath());
    //     this.nodePath = this.setupBusinessStructureStore.getNodePath();
    //     this.ifRegionExtendsChain = this.setChainId();
    //   }
    //   let tempObj={
    //     business: this.businessService.businessData.xName,
    //     chain: this.ifRegionExtendsChain ? this.chainName: "No Name",
    //     region: 'current',
    //     property: 'list',
    //     store: 'list',
    //     pctr: 'list',
    //     meracct: 'list',
    //     terminal: 'list',
    //     periph: 'list',
    //   }
    //   this.businessService.setTabStructureNames(tempObj);
    // }

    checkRegionHierarchy() {
        // check for region hierarchy[region extending chain] in edit mode
        // console.log('this.chainID', this.chainID.value);
        // if(this.treeService.nodeClick){
        const chainID: string = this.chainID.value;
        this.ifRegionExtendsChain = chainID.length ? true : false;
        if (chainID.length) {
            // console.log('Node path', this.setupBusinessStructureStore.getNodePath());
            this.nodePath = this.setupBusinessStructureStore.getNodePath();
            this.ifRegionExtendsChain = this.setChainId();
        }
    }

    setChainId(): boolean {
        const chainObj: any = this.nodePath.filter(
            node => node.type === "chain"
        );
        if (chainObj.length) {
            this.chainID.setValue(chainObj[0].id);
            this.chainName = chainObj[0].name;
            return true;
        } else {
            if (this.treeService.chainID.getValue()) {
                this.chainID.setValue(this.treeService.chainID.getValue());
                this.chainName = this.tabStructureService.currentNodeName;
                console.log("HEREEEEEEE", this.chainName);
                return true;
            } else {
                this.chainID.setValue("");
                this.chainName = "";
                return false;
            }
        }
    }

    setBusinessID() {
        const bizObj: any = this.nodePath.filter(
            node => node.type === "business"
        );
        if (bizObj.length) {
            if (this.bizID === bizObj[0].id) {
                this.businessID.setValue(this.bizID);
            } else {
                if (this.bizID) {
                    this.businessID.setValue(this.bizID);
                } else {
                    this.notificationService.error(
                        "Cannot modify this business",
                        "ERROR"
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            }
        } else {
            this.businessID.setValue(this.bizID);
        }
    }

    get chainID(): any {
        return this.form.get("xChainID");
    }

    get businessID(): any {
        return this.form.get("xBusinessID");
    }

    setBreadCrumbValue(): void {
        const breadCrumb: any = {};
        breadCrumb.root = "Setup";
        breadCrumb.child = "Business";
        breadCrumb.subChild = "Structure";
        breadCrumb.subChildLink = "setup/business/structure/information";
        breadCrumb.grandChild = "Region-Setup";
        breadCrumb.rootList = [];
        this.breadcrumbService.setBreadCrumb(breadCrumb);
    }

    onRegionSubmit(form: FormGroup): void {
        this.urlStoreService.submitAction = true;
        this.tabStructureService.isSubmitClick = true;
        this.submitting = true;
        this.errors = {}; //Reset Errors
        const regionModel = form.getRawValue();
        console.log("region model", regionModel);
        if (!this.ifRegionExtendsChain) {
            delete regionModel.xChainID;
        }

        if (this.edit) {
            //Update region
            this.setupBusinessStructureStore
                .update(this.regionSetupModel._id, regionModel)
                .subscribe(
                    (response: any) => {
                        this.handleSuccessResponse(response.data, regionModel);
                    },
                    (error: any) => {
                        console.log("Error received", error);
                        this.serverErrors = error.errors;
                        this.handleError(error);
                    },
                    () => {}
                );
        } else {
            //Add New region
            this.setupBusinessStructureStore.store(regionModel).subscribe(
                (response: any) => {
                    this.handleSuccessResponse(response.data, regionModel);
                },
                (error: any) => {
                    console.log("Error received", error);
                    this.serverErrors = error.errors;
                    this.handleError(error);
                },
                () => {}
            );
        }
    }

    handleSuccessResponse(data?: any, regionModel?: any): void {
        this.submitting = false;
        this.formSubmitted.emit(true);
        if (this.edit) {
            this.notificationService.success(
                "Record Successfully Updated.",
                "Success!"
            );
        } else {
            this.notificationService.success(
                "Record Successfully Added.",
                "Success!"
            );
        }
        this.businessService.toggleRefreshTree();
        // Proper routing depending upon nodeClick or any other click
        if (this.treeService.nodeClick) {
            if (this.edit) {
                this.fetchRegionData(data.id);
            } else {
                this.tabStructureService.currentNodeName = regionModel.xName;
                this.urlStoreService.routeToCurrentUrl(data, "region", "add");
            }
        } else {
            // // this.treeService.setId(this.businessService.businessID.getValue(), "business");
            // this.urlStoreService.routeBackToPreviousUrl();
            console.log(
                "this.urlStoreService.previousUrl",
                this.urlStoreService.previousUrl
            );
            console.log(
                "this.urlStoreService.currentUrl",
                this.urlStoreService.currentUrl
            );
            if (this.tabStructureService.tabMode) {
                this.urlStoreService.routeBackToPreviousUrl();
            } else {
                if (this.tabStructureService.addNodeBtnClick) {
                    this.tabStructureService.currentNodeName =
                        regionModel.xName;
                    this.urlStoreService.routeToCurrentUrl(
                        data,
                        "region",
                        "add"
                    );
                } else {
                    this.treeService.setId(
                        this.businessService.businessID.getValue(),
                        "business"
                    );
                    console.log(
                        "onCancel  chain",
                        this.treeService.businessID.getValue(),
                        this.treeService.chainID.getValue()
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            }
            // this.treeService.setId(
            //     this.businessService.businessID.getValue(),
            //     "business"
            // );
            // this.urlStoreService.routeBackToPreviousUrl();
        }
    }

    handleErrorResponse(error: any): void {
        this.submitting = false;
        this.errors = JSON.parse(error._body).errors;
        this.notificationService.error(
            JSON.parse(error._body).name === "BadRequest"
                ? "Record Not Added"
                : JSON.parse(error._body).name,
            JSON.parse(error._body).message === "Invalid data"
                ? "Please fill all Required Details"
                : JSON.parse(error._body).message
        );
    }

    handleError(error: any): void {
        this.submitting = false;
        this.formSubmitted.emit(true);
        console.log("error inside handler", error);
        for (const field of Object.keys(error.errors)) {
            if (this.formErrors.hasOwnProperty(field)) {
                // this.formErrors[field] = "Field is not allowed to be empty";
                if (field === "xStructNum") {
                    console.log("Inside handle error", error);
                    if (error.name === "Conflict") {
                        let err = error.errors.xStructNum.replace(
                            /struct/g,
                            "Region"
                        );
                        this.notificationService.error(err, "Conflict");
                        this.clearErrors("xStructNum");
                    } else {
                        this.formErrors[field] =
                            "Field is not allowed to be empty";
                    }
                } else {
                    this.formErrors[field] = "Field is not allowed to be empty";
                }
            }
            if (field === "xRefNum") {
                console.log("Inside reference number handle error", error);
                if (error.name === "Conflict") {
                    let err = error.errors.xRefNum.replace(/struct/g, "Region");
                    this.notificationService.error(err, "Conflict");
                    this.clearErrors("xRefNum");
                }
            }
        }
    }

    // routeBackToPreviousPage(){
    //   console.log("I'm inside routing back function");
    //   this.urlStoreService.routeBackToPreviousUrl();
    // }

    // routeBackToBusinessStructure(): void {
    //   this.treeService.setId(this.businessService.businessID.getValue(),'business');
    //   this.treeService.isParent=false;
    //   console.log("this.treeService.businessID.getValue()",this.treeService.businessID.getValue());
    //   const link = ["/dashboard/setup/business/structure/information/"];
    //   this.router.navigate(link); //Re-Route to setup-business-structure page
    // }

    clearErrors(errorName: string): void {
        if (this.serverErrors[errorName]) {
            delete this.serverErrors[errorName];
        }
    }

    onKeypress(event: any) {
        GlobalValidator.phoneNumberFormat(event);
    }

    onPaste(event: any) {
        GlobalValidator.phoneNumberFormat(event);
    }

    onCancel(event: Event) {
        event.preventDefault();
        if (this.tabStructureService.tabMode) {
            console.log("1");
            if (!this.edit) {
                this.urlStoreService.routeBackToPreviousUrl();
            } else {
                this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
                this.urlStoreService.routeToSecondLastUrl();
            }
        } else {
            if (this.edit) {
                console.log("no tabmode and edit");
                if (!this.tabStructureService.editMode) {
                    console.log("no tabmode and edit and editmode");
                    this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
                    if (
                        this.urlStoreService.previousUrl &&
                        !this.urlStoreService.previousUrl.includes("add-edit")
                    ) {
                        this.treeService.setId(
                            this.treeService.previousSelectedNode.id,
                            this.treeService.previousSelectedNode.type
                        );
                    }
                    this.urlStoreService.routeBackToPreviousUrl();
                    // }
                } else {
                    console.log("no tabmode and edit and no editmode");
                    if (this.treeService.nodeClick) {
                        this.urlStoreService.routeToSecondLastUrl();
                    } else {
                        this.treeService.setId(
                            this.treeService.previousSelectedNode.id,
                            this.treeService.previousSelectedNode.type
                        );
                        this.urlStoreService.routeBackToPreviousUrl();
                    }
                }
            } else {
                console.log("no tabmode and no edit");
                this.urlStoreService.routeBackToPreviousUrl();
            }
        }
    }

    onEditClick(event: Event) {
        console.log(
            "Here onEditClick function in biz===>",
            this.parentMode,
            JSON.parse(JSON.stringify(this.regionSetupModel))
        );
        this.tabStructureService.previousTabStructureLabel = JSON.parse(
            JSON.stringify(this.tabStructureService.defaultTabStructureLabel)
        );
        this.treeService.isParent = false;
        this.parentMode = false;
        this.edit = true;
        this.treeService.setId(this.regionSetupModel._id, "region");
        // this.router.navigate(['dashboard/setup/business/structure/information'])
    }

    setupPerms(){
        this.canAdd = this.userHandlerService.userPerms.canCreateSetup;
        this.canEdit = this.userHandlerService.userPerms.canEditSetup;
    }

    ngOnDestroy() {
        this.alive = false;
    }
}
