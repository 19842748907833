export const IMPORT_FILE_KEY: string = 'importFile';
//TODO: move to db files key after merge
export const PAYLOAD_KEYS: any = {
	xName: 'xName',
	xGTID: 'xGTID',
	_id: '_id',
	xGMPW: 'xGMPW',
	xTerminalID: 'xTerminalID',
	xBusinessID: 'xBusinessID',
	xPCtrID: 'xPCtrID',
	xStoreID: 'xStoreID',
	xGMID: 'xGMID'
};
