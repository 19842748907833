import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { SharedPipesModule, StatModule, UserLoggedGuard } from '../shared';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import {
	GlobalValidator,
	// HttpService,
	NotificationService,
} from '../shared/services';
import { TimerFormatPipe } from 'app/shared/pipes/timer-format.pipe';
import { XpebillingService } from 'app/shared/services/xpebilling.service';

@NgModule({
	imports: [
		CommonModule,
		LoginRoutingModule,
		StatModule,
		FormsModule,
		TooltipModule.forRoot(),
		ModalModule,
		RecaptchaModule,
		RecaptchaFormsModule,
		SharedPipesModule,
	],
	declarations: [LoginComponent, ResetPasswordComponent, ForgotPasswordComponent],
	providers: [
		UserLoggedGuard,
		XpebillingService,
		GlobalValidator,
		// HttpService,
		NotificationService,
		TimerFormatPipe,
	],
})
export class LoginModule {}
