export const MESSAGES = {
	xEmail: {
		required: 'Email is Required',
		incorrectMailFormat: 'Email format is not valid'
	},
	xPhone: {
		required: 'Phone is Required',
		pattern: 'Invalid Format(e.g. 561-331-0755)',
		maxlength: 'Phone Numbers cannot be greater than 15 digits',
		minlength: 'Phone Numbers cannot be smaller than 5 digits'
	},
	xWebsite: {
		required: 'Website is Required',
		incorrectWebsiteFormat: 'Website format is not valid'
	},
	xFacebook: {
		required: 'Facebook is Required'
	},
	xTwitter: {
		required: 'Twitter is Required'
	},
	xLinkedIn: {
		required: 'Linkedin is Required'
	},
	xLine1: {
		required: 'Line1 is required'
	},
	xLine2: {
		required: 'Line2 is required'
	},
	xSuite: {
		required: 'Suite is required'
	},
	xCity: {
		required: 'City is required'
	},
	xSOP: {
		required: 'State/Province is required'
	},
	xPostCode: {
		required: 'PostCode is required'
	},
	xCountry: {
		required: 'Country is required'
	},
	xFirstName: {
		required: 'FirstName is required.'
	},
	xLastName: {
		required: 'LastName is required.'
	},
	xName: {
		required: 'Name is Required'
	},
	xTypeTag: {
		required: 'TypeTag is Required'
	},
	xRefNum: {
		required: 'Reference Number is Required'
	},
	xDBAName: {
		required: 'DBAName is Required'
	},
	xAKAName: {
		required: 'AKAName is Required'
	},
	409: {
		name: 'Conflict',
		message: 'Username already exists.Please choose different username '
	},
	general: {
		name: 'Error',
		message: 'Something went wrong.Please try again.'
	},
	noRecord: {
		name: 'Not Found',
		message: 'No such record exists'
	},
	invalidData: {
		name: 'Record Not Updated',
		message: 'Please fill all Required Details'
	},
	notAuthenticated: {
		name: 'Not Authenticated',
		message: 'Invalid username or password '
	},
	InvalidSafesData: {
		nameError: {
			name: 'Invalid Data',
			message: 'Name is required in the account tab'
		},
		xPCtrIDError: {
			name: 'Invalid Data',
			message: 'Profit Center is required'
		}
	},
	MissingCredentials: {
		name: 'Error',
		message: 'Please fill the username and password fields'
	},
	ConnectionError: {
		name: 'Error',
		message: 'Unable to establish connection'
	},
	IncorrectFileUpload: {
		name: 'Error',
		message: 'Incorrect File Upload'
	},
	FileUploadFailure: {
		name: 'Error',
		message: 'File Upload Failed'
	},
	BusinessImportSuccess: {
		name: 'Success',
		message: 'Business import is complete'
	},
	IncorrectNumberOfFileUpload: {
		name: 'Error',
		message: 'Maximum of 2 files can be uploaded'
	}, 
	InvalidPasswordFormat: {
		name: 'Error',
		message: 'Invalid Password format. Please choose a valid Password.'
	},
	FillAllRequiredFields: {
		name: 'Error',
		message: 'Please fill all the required details.'
	}
};

export const AUTH_MESSAGES = {
	EMAIL_SENT_MESSAGE: 'Verification code has been sent to your email address. ',
	OTP_VERIFICATION_FAILED_MSG: 'Failed to verify code. Please try again.',
 	OTP_VERIFICATION_SUCCESSFUL_MSG: 'Verified OTP successfully.',
 	OTP_IS_REQUIRED_ERR_MSG: 'Please enter the verification code.',
	NO_OF_ATTEMPTS_REMAINING: 'No of attempts remaining is: ',
 	OTP_EXPIRED_ERR_MSG: 'Did not receive OTP? Please generate a new request.',
	LOGIN_AGAIN_MSG: 'User authentication failed. Please login again.',
	TOO_MANY_REQUESTS: 'Too many invalid attempts. Please try again in 1 hour.',
	FAILED_TO_GENERATE_OTP: 'Failed to generate OTP. Please try again.'
}

export const NO_PERMISSION_MSG = `You don't have permission for this action.`;