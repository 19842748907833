import { Injectable } from '@angular/core';

@Injectable()
export class FilterService {
    modifyFilterValue: boolean;

    constructor() {
        this.modifyFilterValue = false;
    }

    setFilterValue(modifyFilterValue: boolean): void {
        // console.log('**** Inside FilterService setFilterValue function ****', modifyFilterValue);
        this.modifyFilterValue = modifyFilterValue;
    }

    getFilterValue(): boolean {
        console.log('**** Inside FilterService getFilterValue function ****', this.modifyFilterValue);
        return this.modifyFilterValue;
    }
}
