<section class="padder">
    <form class="form-horizontal">
        <div class="grid-add-wrap">
<grid-cmp
    [attributes]="attributes"
    [storeValue]="storeValue"
    [storeObj]="storeObject"
    [action]="action"
    [refreshGrid]="refreshGrid"
    [parameters]="params"
    (emitCsvHistoryStructure)="showViewModal($event)"
    (emitCsvHistoryImportResponse)="showImportResponseModal($event)"
    (refresh)="refreshRequest($event)"
></grid-cmp>
</div>
</form>
</section>

<div bsModal #bizImportReportModal="bs-modal" class="modal fade">
    <div class="modal-dialog modal-lg" [class.large-modal]="!isConflict">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Business Pre-Import Report</h4>
                <button type="button" class="close" (click)="bizImportReportModalClose()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="padding-bottom: 15px;">
                <div *ngIf="isConflict">
                    <div class="alert alert-warning">
                        <h5>Conflicting Node Found</h5>
                        <p class="lead no-margin">Conflict while importing Business.Verify CSV file before importing Business</p>
                    </div>
                    <div class="padder">
                        <div class="row">
                            <div class="col-sm-4">
                                <b>Name:</b>
                                <p class="lead no-margin">{{conflictObj.name}}</p>
                            </div>
                            <div class="col-sm-4">
                                <b>Type:</b>
                                <p class="lead no-margin">{{conflictObj.type}}</p>
                            </div>
                            <div class="col-sm-4">
                                <b>Tag:</b>
                                <p class="lead no-margin">{{conflictObj.csv_tag}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isConflict">
                    <accordion [closeOthers]="true">
                        <!-- <virtual-scroll [items]="businessGmidSet" (update)="viewPortItems=$event"> -->
                        <div *ngFor="let biz of businessGmidSet">
                            <accordion-group>
                                <h4 class="no-margin" accordion-heading>
                                    <span class="float-left">{{biz.name}}</span>
                                    <span class="float-right">
                                        <span *ngIf="biz.doExist" class="badge badge-warning">Existing</span>
                                        <span *ngIf="!biz.doExist" class="badge badge-success">New</span>
                                        <label class="label label-default">
                                            <i class="fa fa-angle-down"></i>
                                        </label>
                                    </span>
                                </h4>
                                <div class="accordion-group-item">
                                    <!-- <div *ngIf="biz.doExist">
                                        <h4>
                                            <b>Business Exists:</b>
                                            <b>{{ biz.name }}</b>
                                        </h4>
                                    </div>
                                    <div *ngIf="!biz.doExist">
                                        <h4>
                                            <b>New Business:</b>
                                            <b>{{ biz.name }}</b>
                                        </h4>
                                    </div> -->
                                    <!-- <div virtualScroll [items]="businessGmidSet[i].gmidPath" (update)="viewPortItems=$event"> -->
                                    <!-- <div virtualScroll [items]="biz.gmidPath" (update)="viewPortItems=$event"> -->
                                    <div #scrollingBlock>
                                        <virtual-scroll class="scroll-div-virtual-scroll" [items]="biz.gmidPath" [bufferAmount]="200" [childHeight]="200" [parentScroll]="scrollingBlock.nativeElement" (update)="viewPortItems=$event">
                                            <!-- <virtual-scroll [items]="businessGmidSet[i].gmidPath" (update)="viewPortItems=$event"> -->
                                            <div *ngFor="let item of biz.gmidPath">
                                                <div class="p-b-10"><b>GMID:</b> {{ item.gmid }}</div>
                                                <!-- <b>Structure Path:</b>
                                                <span *ngFor="let hierarchy of item.parentHierarchy">-> {{ hierarchy.name }}({{ hierarchy.type }})
                                                </span> -->
                                                <div>
                                                    <div>
                                                        <table class="table table-collapsed table-sm table-striped no-margin table-grid vertical-content table_main table-layout-fixed">
                                                            <thead class="table_header">
                                                                <tr>
                                                                    <th>Type</th>
                                                                    <th>Name</th>
                                                                    <th>Refnum</th>
                                                                    <th class="text-right">Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <ng-container *ngFor="let item of item.display.creationArr">
                                                                    <tr>
                                                                        <td>{{ item.obj.type }}</td>
                                                                        <td>{{ item.obj.name }}</td>
                                                                        <td>{{ item.obj.refNum }}</td>
                                                                        <td class="text-right">
                                                                            <span class="badge badge-success" *ngIf="item.creation && !item.obj.isPresent">New</span>
                                                                            <span class="badge badge-warning" *ngIf="!item.creation || item.obj.isPresent">Existing</span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-container>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <!-- <div>
                                                    <span>
                                                        <b>Existing Created Node:</b>
                                                    </span>
                                                    <div *ngIf="!biz.doExist">
                                                        <ng-container *ngIf="item.display.common.length">
                                                            <span *ngFor="
                                            let common of item.display.common
                                        ">
                                                                {{ common.type }}: {{ common.name }}
                                                            </span>
                                                        </ng-container>
                                                        <ng-container *ngIf="!item.display.common.length">
                                                            <span>
                                                                No Existing Nodes
                                                            </span>
                                                        </ng-container>
                                                    </div>
                                                    <div *ngIf="biz.doExist">
                                                        <span *ngFor="
                                            let common of item.display.common
                                        ">
                                                            {{ common.type }}: {{ common.name }}
                                                        </span>
                                                        <ng-container *ngFor="
                                            let diffElement of item.display.diff
                                        ">
                                                            <span *ngIf="diffElement.isPresent">
                                                                {{ diffElement.type }}: {{ diffElement.name }}
                                                            </span>
                                                        </ng-container>
                                                    </div>
                                                    </div> -->
                                                    <!-- <div>
                                                        <span>
                                                            <b>Newly Created Node:</b>
                                                        </span>
                                                        <table *ngIf="item.display.diff.length" class="table table-collapsed table-sm table-striped no-margin table-grid vertical-content table_main">
                                                            <thead class="table_header">
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Type</th>
                                                                    <th>Refnum</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <ng-container *ngFor="let item of item.display.diff">
                                                                    <tr *ngIf="(biz.doExist && !item.isPresent) || (!biz.doExist) ">
                                                                        <td>{{ item.name }}</td>
                                                                        <td>{{ item.type }}</td>
                                                                        <td>{{ item.refNum }}</td>
                                                                    </tr>
                                                                </ng-container>
                                                                <tr *ngIf="item.allExist">
                                                                    <td colspan="3">No New Nodes Created</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div> -->
                                                </div>
                                                <hr>
                                            </div>
                                        </virtual-scroll>
                                    </div>
                                </div>
                            </accordion-group>
                        </div>
                        <!-- </virtual-scroll> -->
                    </accordion>
                </div>
            </div>
            <div class="modal-footer" style="padding-bottom: 0px;">
                <button type="button" class="btn btn-sm btn-secondary" style="margin-right: .25rem !important;" (click)="bizImportReportModalClose()">
                    <span>Close</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div bsModal #bizImportModal="bs-modal" class="modal fade">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Business Import</h4>
                <button type="button" class="close" (click)="bizImportModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div>
                <div class="modal-body" style="padding-bottom: 15px;">
                    <div *ngIf="actionResponseSuccess.length !== 0" style="padding-bottom: 10px;">
                    <div class="successAlertDiv">
                        <div class="alert alert-success">
                            <h6 class="modal-title">
                                Business Import Successful:
                            </h6>
                        </div>
                    </div>
                    <h4 *ngIf="actionResponseSuccess.length === 0">None</h4>
                    <div *ngIf="actionResponseSuccess.length !== 0">
                        <div class="scroll" style="overflow-y:scroll;max-height: 150px;">
                            <table id="main-table" class="table table-collapsed table-sm table-striped no-margin table-grid vertical-content table_main table-layout-fixed">
                                <thead class="table_header">
                                    <tr>
                                        <th>Tag</th>
                                        <th>Business Name</th>
                                        <th>Store</th>
                                        <th>GMID</th>
                                        <th>GTID</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="
                                            let item of actionResponseSuccess
                                        ">
                                        <td>{{ item.tag }}</td>
                                        <td>{{ item.bizName }}</td>
                                        <td>{{ item.storeName }}</td>
                                        <td>{{ item.gmid }}</td>
                                        <td>{{ item.gtid }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                    <div *ngIf="actionResponseFail.length !== 0">
                    <div class="failedAlertDiv">
                        <div class="alert alert-danger">
                            <h6 class="modal-title">Business Import Failed:</h6>
                        </div>
                    </div>
                    <h4 *ngIf="actionResponseFail.length === 0">None</h4>
                    <div *ngIf="actionResponseFail.length !== 0">
                        <div class="scroll" style="overflow-y:scroll;max-height: 150px;">
                            <table id="main-table" class="table table-collapsed table-sm table-striped no-margin table-grid vertical-content table_main table-layout-fixed"
                                [ngClass]="showFilter ? 'filter-open' : ''">
                                <thead class="table_header">
                                    <tr>
                                        <th>Business Name</th>
                                        <th>Error</th>
                                        <th>Error Message</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of actionResponseFail">
                                        <td>{{ item.businessData.xName }}</td>
                                        <td>{{item.errorMessage}}</td>
                                        <td>{{item.errorString ? item.errorString : '-'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                    <div class="modal-footer" style="padding-bottom: 0px;">
                        <button type="button" class="btn btn-sm btn-primary" (click)="bizImportModal.hide()">
                            <span>Okay</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>