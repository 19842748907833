import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeletePctrModalComponent } from './delete-pctr-modal/delete-pctr-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
	imports: [CommonModule, ModalModule],
	declarations: [DeletePctrModalComponent],
	exports: [DeletePctrModalComponent]
})
export class DeleteModalModule {}
