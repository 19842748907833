import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { ApiConfig } from '../config/api.config';
import { LIMIT, TRANSACTIONLIMIT, BATCHLIMIT, MESSAGES, ERR_NAMES } from '../../constants';
import { NotificationService } from './notification-service';
import { validateFor } from '../../common-functions';
const DOWNLOAD_FILE_KEY = 'downloadFile';

const FORBIDDEN = {
	name : 'Forbidden',
	code : 403
};
const ERROR_KEY: string = 'error';
const IMPORT_TXN_ERR_MESSAGES = ['Field missing:', 'No file available.'];

const DB_XGMID_KEY: string = 'xGMID';
const BE_TERMINAL_DATA_KEY: string = 'terminalData';
const BE_TERMINAL_NUM_KEY: string = 'terminalNum';

const DELEAR_INCORRECT_LOGIN = 'Incorrect Login, Please use standard login.';
const CUSTOMER_INCORRECT_LOGIN = 'Customer is not registered under this Store. Please contact Dealer.';
const FORBIDDEN_MESSAGES = [DELEAR_INCORRECT_LOGIN, CUSTOMER_INCORRECT_LOGIN];
const INVALID_SIGNUP_PCTR_ID = 'invalidSignupPCtrID';

// @DuplicateRequestsFilter()
//TODO Use authenticated http service instead of normal http
@Injectable()
export class HttpService {
	API_HOST: string = '';
	API_REGISTER: string = '';
	service: any = {};
	authHttp: any = {};
	authenticatedAPI: boolean = true; //checks for authenticated http request (authHttp) or non-authenticated http request (http)
	authFail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); //if error name is notAuthenticated, emit true
	watchAuthFail$ = this.authFail.asObservable(); // Watch if authFail is changed
	constructor(
		protected http: HttpClient,
		private apiConfig: ApiConfig,
		protected notificationService: NotificationService,
		private router: Router,
	) {
		// this.authHttp = new AuthHttp(new AuthConfig(), http);
		this.API_HOST = apiConfig.API_HOST;
		this.API_REGISTER = apiConfig.API_REGISTER_TERMINAL;
		console.log('httpService: API_HOST', this.API_HOST);
	}

	buildUrl(parameters): string {
		let qs = '';
		for (const key of Object.keys(parameters)) {
			const value = parameters[key];
			// if (value) {
			qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
			// }
		}
		if (qs.length > 0) {
			qs = qs.substring(0, qs.length - 1); //chop off last "&"
		}
		return qs;
	}

	get(url: string, elementID: any, headers: any = null): Observable<any> {
		console.log('Inside http service', url, elementID, headers);
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		let importTxnFileDownloadParam = {};
		if(elementID && elementID.includes(DOWNLOAD_FILE_KEY)){
			importTxnFileDownloadParam = {
				'responseType': 'blob'
			};
		}
		return this.service.get(`${this.API_HOST}/api/${url}/` + elementID, importTxnFileDownloadParam)
			.pipe(
				map((response: Response) => {
					// console.log("HTTP RESPONSE: ", response)
					return response;
				}),
				catchError((err) => {
					console.log(err);
					return this.handleErrorResponse(err);
				})
			);
	}

	getAll(url: string, params: any, noGrid: boolean = false): Observable<any> {
		let query = '';
		let limit;
		console.log('params in http service', JSON.parse(JSON.stringify(params)));
		if (url.indexOf('Txns') >= 0) {
			limit = !params.limit && params.limit !== 0 ? TRANSACTIONLIMIT : params.limit;
		} else if (url.indexOf('Batches') >= 0) {
			limit = !params.limit && params.limit !== 0 ? BATCHLIMIT : params.limit;
		} else {
			limit = !params.limit && params.limit !== 0 ? LIMIT : params.limit;
		}
		const page = params.page ? params.page : 1;
		const keyword = params.keyword ? params.keyword : 'null';
		const skip = limit * (page - 1);
		const gridQuery = '$limit=' + limit + '&$skip=' + skip + '&keyword=' + keyword;

		delete params.limit;
		delete params.skip;
		delete params.keyword;
		delete params.page;

		if (noGrid || params.noGrid) {
			delete params.noGrid;
			query = this.buildUrl(params);
		} else {
			delete params.noGrid;
			query = gridQuery + '&' + this.buildUrl(params);
		}

		console.log('query--->', query);
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		return this.service
			.get(`${this.API_HOST}/api/${url}/?` + query)
			.pipe(
				map((response: Response) => {
					return response;
				}),
				catchError((err) => {
					console.log(err);
					return this.handleErrorResponse(err);
				})
			);
	}

	store(url: string, element: any, modHeader: boolean = false): Observable<any> {
		console.log('http store : element -->', element);
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		let final_url;
		/// THIS FINAL URL TREATMENT SHOULD BE REMOVED AFTER TEST;
		// console.log(' this.service', this.service);
		if ( url.includes(this.API_REGISTER)) {
			final_url = url
			// console.log(" HERE IS TRUE")
		} else {
			final_url = `${this.API_HOST}/api/${url}/`
		}
		console.log("store request url:", final_url);
		return this.service
			.post(final_url, element)
			.pipe(
				map((response: Response) => {
					return response;
				}),
				catchError((err) => {
					console.log(err);
					return this.handleErrorResponse(err);
				})
			);
	}

	formDataStore(url: string, file1: File, file2: File): Observable<any> {
		let formData = new FormData();
		formData.append('businessCSVFile', file1);

		if (file2) {
			formData.append('resultCSVFile', file2);
		}
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		return this.service
			.post(`${this.API_HOST}/api/${url}/`, formData)
			.pipe(
				map((response: Response) => {
					return response;
				}),
				catchError((err) => {
					console.log(err);
					return this.handleErrorResponse(err);
				})
			);
	}

	update(url: string, elementID: string, newElement: any): Observable<any> {
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		return this.service
			.put(`${this.API_HOST}/api/${url}/` + elementID, newElement)
			.pipe(
				map((response: Response) => {
					return response;
				}),
				catchError((err) => {
					console.log(err);
					return this.handleErrorResponse(err);
				})
			);
	}

	destroy(url: string, elementID: any): Observable<any> {
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		// return Observable.throw('error');
		return this.service
			.delete(`${this.API_HOST}/api/${url}/` + elementID)
			.pipe(
				map((response: Response) => {
					return response;
				}),
				catchError((err) => {
					console.log(err);
					return this.handleErrorResponse(err);
				})
			);
	}

	patch(url: string, elementID: string, updatedElement: any): Observable<any> {
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		return this.service
			.patch(`${this.API_HOST}/api/${url}/` + elementID, updatedElement)
			.pipe(
				map((response: Response) => {
					return response;
				}),
				catchError((err) => {
					console.log(err);
					return this.handleErrorResponse(err);
				})
			);
	}

	getConstants(endPoint: string, tag: string): Observable<any> {
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		console.log('this.service', this.service);
		const query = `tag=${tag}`;
		return this.service
			.get(`${this.API_HOST}/api/${endPoint}/?${query}`)
			.pipe(
				map((response: Response) => {
					return response;
				}),
				catchError((err) => {
					console.log(err);
					return this.handleErrorResponse(err);
				})
			);
	}

	handleErrorResponse(error: any): Observable<any> {
		console.log('error inside universal error handler', error);
		let errorMessage = '';
		let errorName = '';
		try {
			console.log('ye hai error', error);
			const errorObject = validateFor(ERROR_KEY, error) ? error[ERROR_KEY]: error;
			console.log('ye hai error object', errorObject);
			if (errorObject.type && errorObject.type === 'error') {
				if (errorObject.code === 504 || errorObject.code === '504') {
					errorMessage = 'Request Timeout';
					errorName = 'Error';
				} else {
					errorMessage = MESSAGES['ConnectionError'].message;
					errorName = MESSAGES['ConnectionError'].name;
				}
				this.router.navigate(['dashboard/home']);
			} else {
				if (errorObject && errorObject.code === 409) {
					if (errorObject.hasOwnProperty('errors') && errorObject.errors.hasOwnProperty('xMainLaneNum')) {
						errorMessage = errorObject.errors.xMainLaneNum;
						errorName = 'Conflict';
					} else if (errorObject.hasOwnProperty('errors') && errorObject.errors.hasOwnProperty('xShortCode')) {
						errorMessage = errorObject.errors.xShortCode;
						errorName = 'Conflict';
					} else if (errorObject.hasOwnProperty('errors') && errorObject.errors.hasOwnProperty('xStructNum')) {
						// errorMessage= errorObject.errors.xStructNum;
						// errorName = 'Conflict';
						return throwError(errorObject);
					} else if (errorObject.hasOwnProperty('errors') && errorObject.errors.hasOwnProperty('xStoreNum')) {
						// errorMessage= errorObject.errors.xStructNum;
						// errorName = 'Conflict';
						return throwError(errorObject);
					} else if (errorObject.hasOwnProperty('errors') && errorObject.errors.hasOwnProperty('xPCtrNum')) {
						// errorMessage= errorObject.errors.xStructNum;
						// errorName = 'Conflict';
						return throwError(errorObject);
					} else if (errorObject.hasOwnProperty('errors') && errorObject.errors.hasOwnProperty('xMerAcctNum')) {
						// errorMessage= errorObject.errors.xStructNum;
						// errorName = 'Conflict';
						return throwError(errorObject);
					} else if (errorObject.hasOwnProperty('errors') && errorObject.errors.hasOwnProperty('xTerminalNum')) {
						// errorMessage= errorObject.errors.xStructNum;
						// errorName = 'Conflict';
						return throwError(errorObject);
					} else if (errorObject.hasOwnProperty('errors') && errorObject.errors.hasOwnProperty('xPeriphNum')) {
						// errorMessage= errorObject.errors.xStructNum;
						// errorName = 'Conflict';
						return throwError(errorObject);
					} else if (errorObject.hasOwnProperty('errors') && errorObject.errors.hasOwnProperty('xEmployeeNum')) {
						// errorMessage= errorObject.errors.xStructNum;
						// errorName = 'Conflict';
						return throwError(errorObject);
					} else if (errorObject.hasOwnProperty('errors') && errorObject.errors.hasOwnProperty('xCustomerNum')) {
						// errorMessage= errorObject.errors.xStructNum;
						// errorName = 'Conflict';
						return throwError(errorObject);
					} else if (errorObject.hasOwnProperty('errors') && errorObject.errors.hasOwnProperty('xRepNum')) {
						// errorMessage= errorObject.errors.xStructNum;
						// errorName = 'Conflict';
						return throwError(errorObject);
					} else if (errorObject.hasOwnProperty('errors') && errorObject.errors.hasOwnProperty('xRefNum')) {
						console.log('******************* HERE *******************');
						// errorMessage= errorObject.errors.xStructNum;
						// errorName = 'Conflict';
						return throwError(errorObject);
					} else {
						errorMessage = MESSAGES[409].message;
						errorName = errorObject.name ? errorObject.name : MESSAGES[409].name;
					}
					// return Observable.throw(errorObject);
				}

				if (errorObject.message && errorObject.message.includes('Cast to ObjectId failed')) {
					errorMessage = MESSAGES['noRecord'].message;
					errorName = MESSAGES['noRecord'].name;
				}

				if (errorObject.name === 'BadRequest' || errorObject.message === 'Invalid data') {
					console.log('error message: http-service', errorObject.message.includes('xPCtrID: Path `xPCtrID` is required'));
					if (errorObject.message && errorObject.message.includes('xPCtrID: Path `xPCtrID` is required')) {
						errorMessage = MESSAGES['InvalidSafesData']['xPCtrIDError'].message;
						errorName = MESSAGES['InvalidSafesData']['xPCtrIDError'].name;
					} else if (
						errorObject.message &&
						errorObject.message.includes('xpcSafes validation failed') &&
						!errorObject.message.includes('xPCtrID: Path `xPCtrID` is required')
					) {
						errorMessage = MESSAGES['InvalidSafesData']['nameError'].message;
						errorName = MESSAGES['InvalidSafesData']['nameError'].name;
					} else if (errorObject.message && errorObject.message.includes('Missing credentials')) {
						// errorMessage=MESSAGES["MissingCredentials"].message;
						// errorName=MESSAGES["MissingCredentials"].name;
						return throwError(errorObject);
					} else {
						if (errorObject.hasOwnProperty('errors') && Object.keys(errorObject.errors).length > 0) {
							errorMessage = MESSAGES['invalidData'].message;
							errorName = MESSAGES['invalidData'].name;
						} else if(errorObject.message.includes('BusinessID')) {
							errorMessage = errorObject.message;
							errorName = errorObject.name;
						} else {
							return throwError(errorObject);
						}
					}
				}

				if (errorObject.name === 'NotAuthenticated' && errorObject.message === 'Error') {
					// errorMessage = MESSAGES["notAuthenticated"].message;
					// errorName = MESSAGES["notAuthenticated"].name;
					return throwError(errorObject);
				}
				if (errorObject.name === 'Forbidden' && errorObject.errors === 'Delete action not allowed.') {
					// errorMessage = MESSAGES["notAuthenticated"].message;
					// errorName = MESSAGES["notAuthenticated"].name;
					return throwError(errorObject);
				}

				if (
					errorObject.name === 'Forbidden' &&
					(errorObject.errors.hasOwnProperty(DB_XGMID_KEY) ||
						errorObject.errors.hasOwnProperty(BE_TERMINAL_DATA_KEY) ||
						errorObject.errors.hasOwnProperty(BE_TERMINAL_NUM_KEY))
				) {
					return throwError(errorObject);
				}

				if (errorObject.name === 'Forbidden' && FORBIDDEN_MESSAGES.includes(errorObject.message)) {
					return throwError(errorObject);
				}

				if (errorObject.name === 'MethodNotAllowed' && errorObject.errors === 'Delete action not allowed.') {
					// errorMessage = MESSAGES["notAuthenticated"].message;
					// errorName = MESSAGES["notAuthenticated"].name;
					return throwError(errorObject);
				}

				if (errorObject.name === FORBIDDEN.name 
					&& errorObject.hasOwnProperty("errors") 
					&& errorObject.errors.hasOwnProperty('settleBatchFail')){
						return throwError(errorObject);
				}

				//This is required to avoid displaying double error notifications in import txns modal submit action
				if (errorObject.name === FORBIDDEN.name 
					&& IMPORT_TXN_ERR_MESSAGES.find(msg => msg.indexOf(errorObject.message) >= 0)){
						return throwError(errorObject);
				}
				/* If user IP expires, validateUser check results in 
				* Not authenticated error. Logout based on role. 
				* watcher for this is set in top nav, where logout action takes place.
				*/
				if(errorObject.name === ERR_NAMES.not_authenticated){
					this.authFail.next(true); 
				}
			}
			this.notificationService.error(errorMessage ? errorMessage : errorObject.message, errorName ? errorName : errorObject.name);
			return throwError(errorObject);
		} catch (err) {
			console.log('Ye aya hai catch me', err);
			this.notificationService.error(MESSAGES['general'].message, MESSAGES['general'].name);
			return throwError({});
		}
	}
}
