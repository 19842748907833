import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class BreadcrumbService {
    breadcrumbValue: any;
    breadCrumbData: BehaviorSubject<string> = new BehaviorSubject<any>(null);
    watchbreadCrumbData$ = this.breadCrumbData.asObservable();

    constructor() {
        this.breadcrumbValue = {};
    }

    setBreadCrumb(breadCrumbValue: any): void {
        // console.log('**** Inside BreadcrumbService setBreadcrumb function ****', breadCrumbValue);
        this.breadcrumbValue = breadCrumbValue;
        this.breadCrumbData.next(breadCrumbValue);
    }

    getBreadCrumb(): any[] {
        // console.log('**** Inside BreadcrumbService getBreadcrumb function ****', this.breadcrumbValue);
        return this.breadcrumbValue;
    }

    setBusinessID(breadCrumbData: string): void {
        this.breadCrumbData.next(breadCrumbData);
    }
}
