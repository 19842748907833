import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
    selector: 'customer-advanced-search',
    templateUrl: './customer-advanced-search.component.html',
    styleUrls: ['./customer-advanced-search.component.scss']
})
export class CustomerAdvancedSearchComponent implements OnInit, OnChanges {
    @Input() resetFilterForm: boolean = false;
    @Input() advancedFilterFormData: any = {};
    @Output() advanceSearchEvent = new EventEmitter<any>();
    searchParams: FormGroup;
    loading: boolean = true;

    constructor(public fb: FormBuilder) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('resetFilterForm')) {
            if (this.resetFilterForm) {
                this.advancedFilterFormData = {};
                this.generateForm({});
            }
        }
    }

    ngOnInit() {
        if (Object.keys(this.advancedFilterFormData).length) {
            this.generateForm(this.advancedFilterFormData);
        } else {
            this.generateForm({});
        }
    }

    generateForm(data: any) {
        console.log(data, 'data:generateForm:  ');
        this.searchParams = this.fb.group({
            customerNum: [data.customerNum || '', []]
        });
        this.loading = false;
    }

    onSubmit(data) {
        if (data.value) {
            console.log('data', JSON.parse(JSON.stringify(data.value)));
            this.advanceSearchEvent.emit(data.value);
        }
    }
}
