<section *ngIf="businessService?.businessID?.getValue()">
	<div class="grid-filter merch-info">
		<div class="row">
			<div class="col-sm-5">
				<div class="row" style="max-height: 31px">
					<div class="merch_type col-sm-4">
						<!-- this needs to be discussed a bit more -->
						<select class="form-control form-control-sm select-days" [(ngModel)]="transactionRange" (change)="fetchTransaction()">
							<option value="one_day">
								<label class="radio1"> <span></span>Last 24 Hours </label>
							</option>
							<option value="thisWeek">
								<label class="radio1"> <span></span>Last 7 Days </label>
							</option>
							<option value="thisMon">
								<label class="radio1"> <span></span>Last 30 Days </label>
							</option>

							<option value="last3Mon">
								<label class="radio1"> <span></span>Last 3 Months </label>
							</option>

							<option value="one_year">
								<label class="radio1"> <span></span>Last 12 Months </label>
							</option>
						</select>
						<span> <i class="fa fa-angle-down"></i> </span>
					</div>

					<div class="col-sm-2">
						<div class="btn-group btn-group-toggle" ngbRadioGroup name="logRadio">
							<label
								ngbButtonLabel
								class="btn btn-sm no-margin"
								style="margin-bottom: 0px !important"
								[class.btn-secondary]="displayData !== 'amount'"
								[class.btn-primary]="displayData === 'amount'"
								>Transaction Amount
								<input
									style="margin-bottom: 0"
									ngbButton
									class="log-radio"
									type="radio"
									name="logRadio"
									value="amount"
									[disabled]="displayData === 'amount'"
									[(ngModel)]="displayData"
									(click)="setDisplayData('amount')"
								/>
							</label>
							<label
								style="margin-bottom: 0 !important"
								ngbButtonLabel
								class="btn btn-sm no-margin"
								[class.btn-secondary]="displayData !== 'number'"
								[class.btn-primary]="displayData === 'number'"
								>Transaction Count
								<input
									ngbButton
									class="log-radio"
									type="radio"
									name="logRadio"
									value="number"
									[disabled]="displayData === 'number'"
									[(ngModel)]="displayData"
									(click)="setDisplayData('number')"
								/>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="container-fluid">
		<div *ngIf="loading">
			<div style="text-align: center">
				<!--
                    <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                    <span class="sr-only">Loading...</span>
                -->
				<div class="grid-spinner">
					<div class="bounce1"></div>
					<div class="bounce2"></div>
					<div class="bounce3"></div>
				</div>
				<span style="color: #3b5877">Loading</span>
			</div>
		</div>
		<div *ngIf="!loading" class="row">
			<div class="col-sm-10 offset-sm-1 m-t">
				<!-- if need small chart-->
				<!-- <div class="col-sm-11 chart-align"> -->
				<!-- if need big chart-->
				<div class="card">
					<!--
                        <fusioncharts
                            [id]="id"
                            [width]="width"
                            [height]="height"
                            [type]="type"
                            [dataFormat]="dataFormat"
                            [dataSource]="dataSource"
                        >
                        </fusioncharts>
                    -->
					<div class="clearfix">
						<div class="pull-right padder merch-info">
							<div class="merch_type" *ngIf="transactionRange === 'last3Mon'">
								<!-- this needs to be discussed a bit more -->
								<select
									class="form-control form-control-sm select-days select-primary"
									[(ngModel)]="selectMonth"
									(change)="selectionOfLast3Months()"
								>
									<option value="0">
										<label class="radio1"> <span></span>{{ last3MonthsList[0].label }} </label>
									</option>
									<option value="1">
										<label class="radio1"> <span></span>{{ last3MonthsList[1].label }} </label>
									</option>
									<option value="2">
										<label class="radio1"> <span></span>{{ last3MonthsList[2].label }} </label>
									</option>
								</select>
								<span style="color: #fff; z-index: 3"> <i class="fa fa-angle-down"></i> </span>
							</div>
						</div>
						<!--
                            <app-bar-chart [dataSet]="dataSource"></app-bar-chart>
                        -->
						<div class="chart-container" style="position: relative">
							<canvas
								baseChart
								[datasets]="barChartData"
								[labels]="barChartLabels"
								[options]="barChartOptions"
								[legend]="barChartLegend"
								[chartType]="barChartType"
								[colors]="lineChartColors"
								(chartHover)="chartHovered($event)"
								(chartClick)="chartClicked($event)"
							></canvas>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

