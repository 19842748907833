import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { filter, takeWhile } from 'rxjs/operators';
// import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {

    private viewContainerRef: ViewContainerRef;
    private alive: boolean = true;

    constructor(
        viewContainerRef: ViewContainerRef,
        private router: Router
        // , private translate: TranslateService
    ) {
        // this.toastr.setRootViewContainerRef(viewContainerRef);
        this.viewContainerRef = viewContainerRef;


        // translate.addLangs(['en', 'fr', 'ur']);
        // translate.setDefaultLang('en');

        // const browserLang = translate.getBrowserLang();
        // translate.use(browserLang.match(/en|fr|ur/) ? browserLang : 'en');
    }
    ngOnInit(): void {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                takeWhile(() => this.alive)
            )
            .subscribe((val: any) => {
                $('html, body').css('cursor', 'wait');
            });
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeWhile(() => this.alive)
            )
            .subscribe((val: any) => {
                $('html, body').css('cursor', 'auto');
            });
    }

    ngOnDestroy() {
        this.alive = false;
    }

}

