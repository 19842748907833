import { Injectable } from '@angular/core';
import { HttpService } from '../shared/services/index';
import { map } from 'rxjs/operators';
const endPoint = 'structure';

@Injectable()
export class BusinessTreeStore {
    static businessStructure: any[];
    refreshTree: boolean = false;

    static toggle(element: any): void {
        // console.log('Inside businessTreeStore toggle function', element);
    }

    constructor(protected httpService: HttpService) {}

    getStructure(params: any): any {
        let noParams: boolean = false;
        if (!params.id) {
            noParams = true;
        }
        return this.httpService
            .getAll(endPoint, params, noParams)
            .pipe(
				map((response: Response) => {
					return response;
				})
			);
    }
}
