import { Component, OnInit, OnDestroy, /*Input,*/ Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { takeWhile, skip } from 'rxjs/operators';
// import {SHORTCODES} from '../../../../../../constants/shortCodes.constants';
import { TAGSCONSTS } from '../../../../../../constants/tags.constants';
import { SetupBusinessStructureStore, SetupBusinessStructureStoreSetupStore, ConstantStore } from '../../../../../../store';
import {
	BreadcrumbService,
	NotificationService,
	BusinessService,
	GlobalValidator,
	HttpService,
	TreeService,
	UrlStoreService,
	TabStructureService,
	UserHandlerService
} from '../../../../../../shared/services';
import { DBAudit } from '../../../../../../models';
// import { AccountsComponent } from '../../../../../../shared/modules/accounts/accounts.component';
import { MemberAccountStore } from 'app/store/member-account-store';
import { customSwal, NO_PERMISSION_MSG, SHORT_MASKED_CARD_TOKEN } from '../../../../../../constants/index'

const storeAndSafeEndpoint = 'xbcStores';
// const safeEndpoint = 'xpcSafes';

const ROUTE_TO_STORE_LIST = 'storeList';
const ROUTE_TO_MEMBER_ACCOUNTS = 'memberAccounts';
// const GlobalValidator = GlobalValidator.mailFormat();

@Component({
	selector: 'add-edit',
	templateUrl: './add-edit.component.html',
	styleUrls: ['./add-edit.component.scss']
})
export class SetupStructureStoreComponent implements OnInit, OnDestroy, AfterViewInit {
	@Output()
	formSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output()
	parentNames: EventEmitter<any> = new EventEmitter<any>();

	nodePath: any[] = [];
	bizID: string = ''; // To store buisnessID under which store is to created
	structCapibilities: any = {}; //To hold structs capabilities
	submitting: boolean = false; //For handling form submission
	apiLoading: boolean = false; //For handling API Calls
	edit: boolean = false; //For maintaining if edit mode or not
	l1IncorrectNotifyMailFormat: boolean = false; //notification level 1 email validation
	l2IncorrectNotifyMailFormat: boolean = false; //notification level 2 email validation
	l3IncorrectNotifyMailFormat: boolean = false; //notification level 3 email validation
	// static l1IncorrectNotifyMailFormat: boolean= false; //notification level 1 email validation
	// static l2IncorrectNotifyMailFormat: boolean= false; //notification level 2 email validation
	// static l3IncorrectNotifyMailFormat: boolean= false; //notification level 3 email validation
	// public static l1URLs: string[]= []; //Level 1 URL
	// public static l2URLs: string[]= []; //Level 2 URL
	// public static l3URLs: string[]= []; //Level 3 URL
	storeSetupModel: any = {};
	form: FormGroup; //Required for reactive form validation
	errors: any; //Stores errors returned by server
	// accountList: Safe[] = [];
	noStore: boolean = false;
	chainName: string;
	regionName: string;
	propName: string;
	parentNameObj: any;
	alive: boolean = true;
	serverErrors: any = {}; //Stores errors returned by server
	formErrors = {
		acctxName: '',
		xName: '',
		xSvcPhone: '',
		xSubName: '',
		xTag: '',
		xRefNum: '',
		xStructNum: ''
	};
	xRefNumEditMode: string = '';
	numberKey: string = 'xStoreNum';
	parentMode: boolean = false;
	name: string = '';
	dontSubmit: boolean = false;

	loading: boolean = false;
	tag: string[] = [];
	that = this;
	bizChangeEvent: boolean = false;
	canEdit = true;
	canAdd = true;
	noPermMsg = NO_PERMISSION_MSG;
	//Deposit-account grid variables
	public gridAttributes: any[] = []; //Values to be shown in grid and mapping
	public gridParams: any = {}; //Paramters to fetch deposit-acct data
	public memberAccountStoreObj: any = {};
	public memberAccountStoreValue: string = '';
	public fetchByStore: boolean = true;
	public isDepositAccountEdit: boolean = true; //To manage breadcrumb in member-accounts list-page
	public tooltipMessage: string = 'Add/Edit Deposit Accounts';
	public ROUTE_TO_STORE_LIST: string = ROUTE_TO_STORE_LIST;

	public disableRouteGuard: boolean = false; //If true disable route-guard (Used when store is saved from manage-deposit-acct button)

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private setupBusinessStructureStore: SetupBusinessStructureStore,
		private constantStore: ConstantStore,
		private setupBusinessStructureStoreSetupStore: SetupBusinessStructureStoreSetupStore,
		private breadcrumbService: BreadcrumbService,
		private notificationService: NotificationService,
		public businessService: BusinessService,
		private httpService: HttpService,
		private fb: FormBuilder,
		private treeService: TreeService,
		public urlStoreService: UrlStoreService,
		private tabStructureService: TabStructureService,
		public userHandlerService: UserHandlerService,
		public memberAccountsStore: MemberAccountStore
	) {
		this.errors = {};
		//this.urlStoreService.swapUrl(router.url);
		// this.router.routeReuseStrategy.shouldReuseRoute = function() {
		// 	return false;
		// };
	}

	ngOnInit() {
		console.log('ngOnInit called: store-add-edit');
		this.setupPerms();
		this.memberAccountStoreObj = this.memberAccountsStore;
		this.memberAccountStoreValue = 'memberAccountStore';

		this.tabStructureService.checkAndSetTabNameToDef(
			this.tabStructureService.tabMode,
			this.tabStructureService.isCancelClick,
			this.tabStructureService.isSubmitClick
		);

		this.urlStoreService.submitAction = false;
		this.setBreadCrumbValue();
		this.nodePath = this.setupBusinessStructureStore.getNodePath();
		this.route.params.forEach((params: Params) => {
			this.initValidation();
			//CHECK FOR EDIT MODE
			if (params.hasOwnProperty('Id')) {
				this.edit = true;
				if (params.Id === 'null') {
					//Check whether the region exists for selected node or not (upward hierarchy in business structure)
					this.noStore = true;
				} else {
					console.log('got here: inside if');
					this.loading = true;
					this.apiLoading = true;
					console.log('params: onInit----->', params);
					console.log('params includes member-accounts', params['Id'].includes('/member-accounts'));

					this.setGridParams(params);
					this.setGridAttributes();
					if (!this.treeService.storeID.getValue() && !this.treeService.isParent) {
						// console.log("here: else: onInit")
						console.log('Inside If');
						this.treeService.setId(params.Id, 'store');
						// this.gridParams['storeID'] = params.Id;
						// this.gridParams['businessID'] = this.businessService.businessID.getValue()
					}
					this.fetchStoreAndSafeData(params['Id']);
				}
			}
			// else{
			//     this.businessID.setValue(this.bizID);
			// }
		});
		if (this.tabStructureService.tabMode) {
			this.checkParentMode();
		}
		this.setTabNameNewStore();
		this.setupBusinessStructureStore.watchNodePath$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((nodePath: any[]) => {
				this.nodePath = nodePath;
				this.addModeActions();
				// if (this.treeService.storeID.getValue()) {
				//     // this.edit=false;
				//     // this.treeService.setId(this.businessService.businessID.getValue(), 'business');
				// }
				this.parentMode = false;
				// this.checkParentMode();
				// this.setTabNameNewStore();
			});
		this.addModeActions();
		this.tabStructureService.watchParentFetched$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe(res => {
				console.log('Here in watcher of parent fetched', res);
				if (res) {
					console.log('Here in watcher of parent fetched res true');
					this.setTabNameNewStore();
				}
			});
		this.businessService.watchBusinessID$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe(bizId => {
				if (bizId) {
					console.log('STORE ADD EDIT: BusinessID watcher: business changed: bizID -->', bizId);
					this.bizChangeEvent = true;
				} else {
					this.router.navigate(['/dashboard/setup/business/structure/information']);
				}
			});
	}

	//Open and enable tree
	ngAfterViewInit() {
		Promise.resolve(null).then(() => {
			this.treeService.setEnable(true);
		});
	}

	checkParentMode() {
		if (this.tabStructureService.tabLabelItems.length) {
			this.tabStructureService.tabLabelItems.map(labelItem => {
				if (labelItem.label.includes('Store')) {
					if (labelItem.hasOwnProperty('type') && labelItem['type'] === 'Parent') {
						this.parentMode = true;
					} else {
						this.parentMode = false;
					}
				}
			});
		}
	}

	setTabNameNewStore() {
		if (!this.edit) {
			console.log('%c Add mode hai k??', 'background: blue; color: white');
			if (this.tabStructureService.tabLabelItems.length) {
				console.log('%c Add mode hai k??', 'background: pink; color: white');
				this.tabStructureService.tabLabelItems.map(labelItem => {
					console.log('%c Add mode hai k??', 'background: grey; color: black', labelItem);
					if (labelItem.label.includes('Store')) {
						if (labelItem.hasOwnProperty('type')) {
							labelItem['label'] = 'Store';
							labelItem['type'] = 'New';
							labelItem['name'] = '';
						}
					}
				});
				console.log('%c Store Add mode hai k nodePath??', 'background: grey; color: black', this.tabStructureService.tabLabelItems);
				this.tabStructureService.setTabStructureNames(this.tabStructureService.tabLabelItems);
			}
		}
	}

	fetchStoreAndSafeData(id: string) {
		forkJoin(
			this.setupBusinessStructureStoreSetupStore.get(id)
			// this.httpService.getAll(safeEndpoint, { storeID: id }, true).map((res: Response) => res)
		).subscribe(
			(response: any) => {
				console.log('store details', response);
				// this.loading = false;
				this.storeSetupModel = response[0].data;
				console.log('fetchStoreAndSafeData: this.storeSetupModel',this.storeSetupModel.xName);
				// this.getAdditionalData
				// this.accountList = response[1].data.length > 0 ? response[1].data : [new Safe()];
				// console.log('Account List', this.accountList);
				// console.log('store setup model', this.storeSetupModel);
				// console.log('previous url: add-edit', this.urlStoreService.previousURL);
				// console.log('includes member-accounts', this.urlStoreService.previousURL.includes('/member-accounts'));
				this.setFormValues(this.storeSetupModel);
				this.loading = false;
				// this.chainID.setValue(this.storeSetupModel.xChainID);
				// this.chainID.setValue(this.storeSetupModel.xChainID);
				// this.chainID.setValue(this.storeSetupModel.xChainID);
				const structObj: any = {
					xChainID:
						this.storeSetupModel.hasOwnProperty('xStructs') && this.storeSetupModel.xStructs.hasOwnProperty('xChainID')
							? this.storeSetupModel.xStructs.xChainID
							: '',
					xRegionID:
						this.storeSetupModel.hasOwnProperty('xStructs') && this.storeSetupModel.xStructs.hasOwnProperty('xRegionID')
							? this.storeSetupModel.xStructs.xRegionID
							: '',
					xPropID:
						this.storeSetupModel.hasOwnProperty('xStructs') && this.storeSetupModel.xStructs.hasOwnProperty('xPropID')
							? this.storeSetupModel.xStructs.xPropID
							: ''
				};
				this.checkStoreHierarchy(structObj);
			},
			(error: any) => {
				this.handleErrorResponse(error);
			},
			() => {
				this.apiLoading = false;
			}
		);
	}

	addModeActions() {
		console.log('bizId', this.bizID);
		if (!this.edit) {
			// console.log('addModeActions');
			this.bizID = this.businessService.businessID.getValue();
			this.structCapibilities = this.setStructs();
			this.setBusinessID();
			// this.accountList = [new Safe()];
		}
	}

	initValidation(): void {
		this.form = this.fb.group({
			xTntTag: ['test', []],
			xWall1EntyID: ['', []],
			xWall2EntyID: [this.bizID, []],
			xStoreNum: ['', [Validators.required]],
			xTag: [/*{ value: 'optional_user_tag', disabled: true }*/ '', []],
			xStatus: ['1', []],
			// xAccCustomers: this.fb.group({
			//     xStatus: ['0', []],
			//     xCustomerID: ['', []]
			// }),
			xName: ['', [Validators.required, GlobalValidator.validateName]],
			xSvcPhone: ['', [Validators.required]],
			xTypeTag: [
				TAGSCONSTS.storeTags.regularStore,
				[
					/*Validators.required*/
				]
			],
			xNotes: ['', []],
			xRefNum: [
				'',
				[
					/*Validators.required*/
				]
			],
			xSubName: [
				[
					''
					/*Validators.required*/
				]
			],
			xMfrRefNum: [
				'',
				[
					/*Validators.required*/
				]
			],
			xSvcRefNum: [
				'',
				[
					/*Validators.required*/
				]
			],
			// xAddr: this.fb.group(new Address()),
			// xSocial: this.fb.group(new Social()),
			// xMainContact: this.fb.group(new MainContact()),
			xBusinessID: [''],
			xStructs: this.fb.group({
				xChainID: [
					'',
					[
						/*Validators.required*/
					]
				],
				xRegionID: [
					'',
					[
						/*Validators.required*/
					]
				],
				xPropID: [
					'',
					[
						/*Validators.required*/
					]
				]
			}),
			// xPCtrs: this.fb.array([
			//     this.fb.group({
			//         xStatus: [''],
			//         xPCtrID: ['']
			//     })
			// ]),
			xAccCustomers: this.fb.array([
				this.fb.group({
					xStatus: ['0'],
					xCustomerID: ['']
				})
			]),
			xTZTag: [
				'TZTag',
				[
					/*Validators.required*/
				]
			],
			xMainCcyTag: ['', []],
			xGSID: [
				'',
				[
					/*Validators.required*/
				]
			],
			xGSPW: [
				'',
				[
					/*Validators.required*/
				]
			],
			xDpsAccts: this.fb.array([
				// this.fb.group({
				//     xStatus: ["0"],
				//     xSafeID: [""]
				// })
			]),
			xNotify: this.fb.group({
				xL1URLs: [[], []],
				xL2URLs: [[], []],
				xL3URLs: [[], []]
			}),
			xDBAudit: this.fb.group(
				new DBAudit({
					xDataSrc: TAGSCONSTS.dataSourceTags.dataSrcItemTags.self
				})
			),
			image: ['']
		});
	}

	onItemAddedEditedOrRemoved(val) {
		console.log('here 2', val);
		let currNotifyObj = this.form.getRawValue().xNotify;
		const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
		Object.keys(currNotifyObj).map(key => {
			if (currNotifyObj[key].length > 0) {
				let incorrectFormat = false;
				currNotifyObj[key].map(obj => {
					console.log('OBJ--->', obj);
					let val = obj.value;
					if (val !== '' && (val.length <= 5 || !EMAIL_REGEXP.test(val))) {
						// this.modifyUrlLevelMailFormats(key, true);
						incorrectFormat = true;
					}
				});
				this.modifyUrlLevelMailFormats(key, incorrectFormat);
			} else {
				this.modifyUrlLevelMailFormats(key, false);
			}
		});
	}

	onItemEdited(val, level) {
		const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
		if (val.value !== '' && (val.value.length <= 5 || !EMAIL_REGEXP.test(val.value))) {
			this.modifyUrlLevelMailFormats(level, true);
		} else {
			this.modifyUrlLevelMailFormats(level, false);
		}
	}

	modifyUrlLevelMailFormats(key, value) {
		switch (key) {
			case 'xL1URLs':
				console.log('case1');
				this.l1IncorrectNotifyMailFormat = value;
				break;
			case 'xL2URLs':
				console.log('case2');
				this.l2IncorrectNotifyMailFormat = value;
				break;
			case 'xL3URLs':
				console.log('case3');
				this.l3IncorrectNotifyMailFormat = value;
				break;
		}
		if (this.l1IncorrectNotifyMailFormat || this.l2IncorrectNotifyMailFormat || this.l3IncorrectNotifyMailFormat) {
			this.dontSubmit = true;
		} else {
			this.dontSubmit = false;
		}
	}

	// onItemRemoved(val) {
	//     console.log('here 1', val);
	//     let currNotifyObj= this.form.getRawValue().xNotify;
	//     const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
	//     Object.keys(currNotifyObj).map(key=>{
	//         if(currNotifyObj[key].length > 0){
	//             let incorrectFormat=false;
	//             currNotifyObj[key].map(obj=>{
	//                 console.log("OBJ--->", obj);
	//                 let val= obj.value;
	//                 if (val !== '' && (val.length <= 5 || !EMAIL_REGEXP.test(val))) {
	//                     // this.modifyUrlLevelMailFormats(key, true);
	//                     incorrectFormat=true;
	//                 }
	//             })
	//             this.modifyUrlLevelMailFormats(key, incorrectFormat);
	//         }
	//         else{
	//             this.modifyUrlLevelMailFormats(key, false);
	//         }
	//     })
	// }

	// getL1IncorrectNotifyMailFormat() {
	//     return SetupStructureStoreComponent.l1IncorrectNotifyMailFormat;
	// }
	// getL2IncorrectNotifyMailFormat() {
	//     return SetupStructureStoreComponent.l2IncorrectNotifyMailFormat;
	// }
	// getL3IncorrectNotifyMailFormat() {
	//     return SetupStructureStoreComponent.l3IncorrectNotifyMailFormat;
	// }

	// validateL1BeforeAdding(val):Observable<string>{
	//     console.log("Val=>", val);
	//     const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
	//     if (val && val !== '' && (val.length <= 5 || !EMAIL_REGEXP.test(val))) {
	//         SetupStructureStoreComponent.l1IncorrectNotifyMailFormat=true;
	//         return Observable
	//                 .of(val);
	//     }
	//     else{
	//         SetupStructureStoreComponent.l1IncorrectNotifyMailFormat=false;
	//         return Observable
	//                 .of(val);
	//     }
	// }
	// validateL2BeforeAdding(val):Observable<string>{
	//     console.log("Val=>", val);
	//     const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
	//     if (val && val !== '' && (val.length <= 5 || !EMAIL_REGEXP.test(val))) {
	//         SetupStructureStoreComponent.l2IncorrectNotifyMailFormat=true;
	//         return Observable
	//                 .of(val);
	//     }
	//     else{
	//         SetupStructureStoreComponent.l2IncorrectNotifyMailFormat=false;
	//         return Observable
	//                 .of(val);
	//     }
	// }
	// validateL3BeforeAdding(val):Observable<string>{
	//     console.log("Val=>", val);
	//     const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
	//     if (val && val !== '' && (val.length <= 5 || !EMAIL_REGEXP.test(val))) {
	//         SetupStructureStoreComponent.l3IncorrectNotifyMailFormat=true;
	//         return Observable
	//                 .of(val);
	//     }
	//     else{
	//         SetupStructureStoreComponent.l3IncorrectNotifyMailFormat=false;
	//         return Observable
	//                 .of(val);
	//     }
	// }

	onTagEdited(val) {
		console.log('here 1', this.notify.value);
		console.log('here 2', this.notify);
		console.log('here 3', val);
	}

	// check for store hierarchy[store extending chain, store extending region, store extending property] in edit mode
	checkStoreHierarchy(structObj?: any) {
		console.log('structObj: checkStoreHierarchy: add-edit store', structObj);
		// const chainID: string = this.chainID.value;
		// const regionID: string = this.regionID.value;
		// const propID: string = this.propID.value;
		// this.structCapibilities.ifStoreExtendsChain = chainID.length ? true : false;
		// this.structCapibilities.ifStoreExtendsRegion = regionID.length ? true : false;
		// this.structCapibilities.ifStoreExtendsProp = propID.length ? true : false;
		if (structObj) {
			this.structs.setValue(structObj);
			this.chainName = '';
			this.regionName = '';
			this.propName = '';
			this.tabStructureService.defaultTabStructureLabel.map(singleLabel => {
				if (
					structObj.xChainID &&
					structObj.xChainID.length &&
					singleLabel &&
					singleLabel.hasOwnProperty('label') &&
					singleLabel.hasOwnProperty('name') &&
					singleLabel.label === 'Chain'
				) {
					this.chainName = singleLabel.name.substring(3, singleLabel.name.length);
				}
				if (
					structObj.xRegionID &&
					structObj.xRegionID.length &&
					singleLabel &&
					singleLabel.hasOwnProperty('label') &&
					singleLabel.hasOwnProperty('name') &&
					singleLabel.label.includes('Region')
				) {
					this.regionName = singleLabel.name.substring(3, singleLabel.name.length);
				}
				if (
					structObj.xPropID &&
					structObj.xPropID.length &&
					singleLabel &&
					singleLabel.hasOwnProperty('label') &&
					singleLabel.hasOwnProperty('name') &&
					singleLabel.label.includes('Propert')
				) {
					this.propName = singleLabel.name.substring(3, singleLabel.name.length);
				}
			});
			// console.log("Struct",this.form);
			this.structCapibilities = {
				ifStoreExtendsChain: structObj.xChainID && structObj.xChainID.length ? true : false,
				ifStoreExtendsRegion: structObj.xRegionID && structObj.xRegionID.length ? true : false,
				ifStoreExtendsProp: structObj.xPropID && structObj.xPropID.length ? true : false
			};

			console.log('this.structCapibilities', this.structCapibilities);

			// return structCaps;
		} else {
			console.log('got into else:checkStoreHierarchy: add-edit');
			this.nodePath = this.setupBusinessStructureStore.getNodePath();
			this.structCapibilities = this.setStructs();
		}
	}

	setStructs(): any {
		const chainObj: any = this.nodePath.filter(node => node.type === 'chain');
		const regionObj: any = this.nodePath.filter(node => node.type === 'region');
		const propObj: any = this.nodePath.filter(node => node.type === 'property');

		const structObj: any = {
			xChainID: chainObj.length ? chainObj[0].id : '',
			xRegionID: regionObj.length ? regionObj[0].id : '',
			xPropID: propObj.length ? propObj[0].id : ''
		};

		this.chainName = chainObj.length ? chainObj[0].name : '';
		this.regionName = regionObj.length ? regionObj[0].name : '';
		this.propName = propObj.length ? propObj[0].name : '';

		if (!structObj.xChainID.length) {
			if (this.treeService.chainID.getValue()) {
				structObj.xChainID = this.treeService.chainID.getValue();
				this.chainName = this.tabStructureService.currentNodeName;
			} else {
				structObj.xChainID = '';
				this.chainName = '';
			}
		}
		if (!structObj.xRegionID.length) {
			if (this.treeService.regionID.getValue()) {
				structObj.xRegionID = this.treeService.regionID.getValue();
				this.regionName = this.tabStructureService.currentNodeName;
			} else {
				structObj.xRegionID = '';
				this.regionName = '';
			}
		}
		if (!structObj.xPropID.length) {
			if (this.treeService.propertyID.getValue()) {
				structObj.xPropID = this.treeService.propertyID.getValue();
				this.propName = this.tabStructureService.currentNodeName;
			} else {
				structObj.xPropID = '';
				this.propName = '';
			}
		}
		// if(!structObj.xChainID.length){
		//     if(this.treeService.chainID.getValue()){
		//         structObj.xChainID= this.treeService.chainID.getValue();
		//         this.chainName = this.tabStructureService.currentNodeName;
		//     }
		//     else{
		//         structObj.xChainID= "";
		//         this.chainName = "";
		//     }
		// }
		// console.log(this.chainName, this.regionName, this.propName);
		// console.log("structObj",structObj);
		this.structs.setValue(structObj);
		// console.log("Struct",this.form);
		const structCaps: any = {
			ifStoreExtendsChain: structObj.xChainID.length ? true : false,
			ifStoreExtendsRegion: structObj.xRegionID.length ? true : false,
			ifStoreExtendsProp: structObj.xPropID.length ? true : false
		};
		// this.httpService.getAll('parentNodeNames', {
		//     nodeId: this.storeSetupModel._id
		// })
		return structCaps;
	}

	setBusinessID() {
		const bizObj: any = this.nodePath.filter(node => node.type === 'business');
		if (bizObj.length) {
			// console.log('***** setBusinessID *****');
			if (this.bizID === bizObj[0].id) {
				this.businessID.setValue(this.bizID);
			} else {
				// console.log('setBusinessID: IDs DO NOT match');
				if (this.bizID) {
					this.businessID.setValue(this.bizID);
				} else {
					this.notificationService.error('Cannot modify this business', 'ERROR');
					this.urlStoreService.routeBackToPreviousUrl();
				}
			}
		} else {
			this.businessID.setValue(this.bizID);
		}
	}

	get structs(): any {
		return this.form.get('xStructs');
	}
	get notify(): any {
		return this.form.get('xNotify');
	}

	get chainID(): any {
		return this.form.get('xStructs.xChainID');
	}

	get regionID(): any {
		return this.form.get('xStructs.xRegionID');
	}

	get propID(): any {
		return this.form.get('xStructs.xPropID');
	}

	get businessID(): any {
		return this.form.get('xBusinessID');
	}

	get depositArray(): FormArray {
		return this.form.get('xDpsAccts') as FormArray;
	}

	//Set bread crumb value and links
	setBreadCrumbValue(): void {
		const breadCrumb: any = {};
		breadCrumb.root = 'Setup';
		breadCrumb.child = 'Business';
		breadCrumb.subChild = 'Structure';
		breadCrumb.subChildLink = 'setup/business/structure/information';
		breadCrumb.grandChild = 'Store-Setup';
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	//Add/update store and route to member-accounts/store list
	//paramters: form(FormGroup) and where to route on creating/updating(to deposit-acct or store-list)
	onStoreSubmit(form: FormGroup, routeTo: string): void {
		this.urlStoreService.submitAction = true;
		this.tabStructureService.isSubmitClick = true;
		this.submitting = true;
		this.errors = {}; //Reset Errors
		const storeModel = form.getRawValue();
		console.log('Store model BEFORE', JSON.parse(JSON.stringify(storeModel)));
		if (this.edit) {
			storeModel['xRefNum'] = this.xRefNumEditMode;
		}
		if (storeModel.hasOwnProperty('xNotify')) {
			Object.keys(storeModel['xNotify']).map(key => {
				let tempArr = [];
				storeModel['xNotify'][key].map(val => {
					tempArr.push(val.value);
				});
				console.log(JSON.parse(JSON.stringify(tempArr)));
				storeModel['xNotify'][key] = [];
				console.log(JSON.parse(JSON.stringify(storeModel['xNotify'])));
				tempArr.map(finalEmail => {
					storeModel['xNotify'][key].push(finalEmail);
				});
				storeModel['xNotify'][key] = storeModel['xNotify'][key].toString();
			});
		}

		if (storeModel && storeModel.hasOwnProperty('safe')) {
			storeModel.safe.forEach(element => {
				element.xTypeTag = TAGSCONSTS.safeTags.regularSafe;
			});
		}
		this.name = storeModel.xName;
		console.log('Store model', JSON.parse(JSON.stringify(storeModel)));
		if (!this.structCapibilities.ifStoreExtendsChain) {
			// console.log("HEre del chain");
			delete storeModel.xStructs.xChainID;
		}
		if (!this.structCapibilities.ifStoreExtendsRegion) {
			// console.log("HEre del region");
			delete storeModel.xStructs.xRegionID;
		}
		if (!this.structCapibilities.ifStoreExtendsProp) {
			// console.log("HEre del property");
			delete storeModel.xStructs.xPropID;
		}

		// storeModel.xTag = 'optional_user_tag'; //TODO-: Remove this when xTag is implemented properly

		if (this.edit) {
			// console.log("Inside Store edit mode");
			this.updateStore(storeAndSafeEndpoint, this.storeSetupModel._id, storeModel, routeTo);

			// this.httpService.update(storeAndSafeEndpoint, this.storeSetupModel._id, storeModel).subscribe(
			// 	(response: any) => {
			// 		// console.log("Inside Store edit got success response",response);
			// 		this.handleSuccessResponse(response.data, storeModel);
			// 	},
			// 	(error: any) => {
			// 		// console.log("Inside Store edit got error response",JSON.parse(JSON.stringify(error)));
			// 		this.serverErrors = error.errors;
			// 		// this.handleErrorResponse(this.errors);
			// 		this.handleError(error);
			// 	},
			// 	() => {}
			// );
		} else {
			console.log('Store model to be sent to the store', JSON.parse(JSON.stringify(storeModel)));
			this.submitting = true;
			this.httpService.store(storeAndSafeEndpoint, storeModel).subscribe(
				(response: any) => {
					this.submitting = false;
					this.handleSuccessResponse(response.data, routeTo, storeModel);
				},
				(error: any) => {
					console.log('Inside Store add got error response', JSON.parse(JSON.stringify(error)));
					this.serverErrors = error.errors;
					this.submitting = false;
					// this.handleErrorResponse(this.errors);
					this.handleError(error);
				},
				() => {}
			);
		}
	}

	setFormValues(data: any): void {
		this.form.patchValue({
			xTntTag: data.xTntTag,
			xWall1EntyID: data.xWall1EntyID,
			xWall2EntyID: data.xWall2EntyID,
			xStoreNum: data.xStoreNum,
			xTag: data.xTag,
			xStatus: data.xStatus,
			xAccCustomers: [
				{
					xStatus: data.xAccCustomers ? data.xAccCustomers.xStatus : '',
					xCustomerID: data.xAccCustomers ? data.xAccCustomers.xCustomerID : ''
				}
			],
			xName: data.xName,
			xSvcPhone: data.xSvcPhone,
			xTypeTag: data.xTypeTag,
			xNotes: data.xNotes,
			xRefNum: data.xRefNum,
			xTZTag: data.xTZTag ? data.xTZTag : '',
			xMainCcyTag: data.xMainCcyTag ? data.xMainCcyTag : '',
			xMfrRefNum: data.xMfrRefNum,
			xSvcRefNum: data.xSvcRefNum,
			xSubName: data.xSubName ? data.xSubName : '',
			// xNotify: {
			//     xL1URLs:[
			//         {
			//             value:  data.xNotify && data.xNotify.xL1URLs
			//                     ? data.xNotify.xL1URLs
			//                     : "",
			//         }
			//     ]
			// }
			// xAddr: this.fb.group(new Address(data.xAddr)),
			// xSocial: this.fb.group(new Social(data.xSocial)),
			// xMainContact: this.fb.group(new MainContact(data.xMainContact)),
			xBusinessID: data.xBusinessID,
			xStructs: {
				xChainID: data.xStructs && data.xStructs.xChainID ? data.xStructs.xChainID : '',
				xRegionID: data.xStructs && data.xStructs.xRegionID ? data.xStructs.xRegionID : '',
				xPropID: data.xStructs && data.xStructs.xPropID ? data.xStructs.xPropID : ''
			},
			xGSID: data.xGSID,
			xGSPW: data.xGSPW,
			xDBAudit: data.xDBAudit ? data.xDBAudit : TAGSCONSTS.dataSourceTags.dataSrcItemTags.self,
			image: data && data.hasOwnProperty('image') && data.image ? data.image : ''
		});
		console.log('this.form 1', JSON.parse(JSON.stringify(this.form.getRawValue())));

		if (data.hasOwnProperty('xNotify') && Object.keys(data['xNotify']).length > 0) {
			Object.keys(data['xNotify']).map(key => {
				this.form.controls['xNotify'].get(key).setValue([]);
				let urlArr = data['xNotify'][key].split(',');
				console.log('urlArr', urlArr);
				urlArr.map(url => {
					if (url.length > 0) {
						this.form.controls['xNotify'].get(key).value.push({ value: url, display: url });
					}
					// return ;
				});
				// })
			});
		}

		console.log('this.form 2', JSON.parse(JSON.stringify(this.form.getRawValue())));

		if (data.xDpsAccts && data.xDpsAccts.constructor === Array) {
			console.log('deposit accnt details====>', data.xDpsAccts);
			this.form.controls['xDpsAccts'] = this.fb.array([]);
			console.log('setFormValue: depositArray', this.form.controls['xDpsAccts'].value);
			data.xDpsAccts.forEach(item => {
				console.log('setFormValue: item', item);
				this.depositArray.push(this.fb.group(item));
			});
		}
		if (this.edit) {
			this.form.controls['xRefNum'].disable();
			this.xRefNumEditMode = data.xRefNum;
		} else {
			this.form.controls['xRefNum'].enable();
		}
		console.log('setFormValue: form value end', this.form.getRawValue());
	}

	onKeypress(event: any) {
		GlobalValidator.phoneNumberFormat(event);
	}

	onPaste(event: any) {
		GlobalValidator.phoneNumberFormat(event);
	}

	handleSuccessResponse(data: any, routeTo: string, storeModel?: any): void {
		console.log('storeModel: handleSuccessResponse: add-edit', storeModel);
		console.log(data, 'data:handleSuccessResponse: add-edit');
		this.submitting = false;
		this.formSubmitted.emit(true);
		if (this.edit) {
			this.notificationService.success('Record Successfully Updated.', 'Success!');
		} else {
			this.notificationService.success('Record Successfully Added.', 'Success!');
		}
		this.businessService.toggleRefreshTree();
		this.tabStructureService.currentNodeName = this.name;
		// Proper routing depending upon nodeClick or any other click
		if (this.treeService.nodeClick) {
			if (this.edit) {
				this.fetchStoreAndSafeData(data.id);
			} else {
				this.tabStructureService.currentNodeName = storeModel.xName;
				this.urlStoreService.routeToCurrentUrl(data, 'store', 'add');
			}
		} else {
			if (this.tabStructureService.tabMode) {
				this.urlStoreService.routeBackToPreviousUrl();
			} else {
				if (this.tabStructureService.addNodeBtnClick) {
					this.tabStructureService.currentNodeName = storeModel.xName;
					this.urlStoreService.routeToCurrentUrl(data, 'store', 'add');
				} else {
					this.treeService.setId(this.businessService.businessID.getValue(), 'business');
					console.log(
						'onsuccess no tab mode and node click  store',
						this.treeService.businessID.getValue(),
						this.treeService.chainID.getValue()
					);
					this.tabStructureService.currentNodeName = storeModel.xName;

					console.log('previous url includes member-accounts', this.urlStoreService.previousURL.includes('/member-accounts'));
					if (this.urlStoreService.previousURL.includes('/member-accounts')) {
						this.urlStoreService.previousURL = '/dashboard/setup/business/structure/store';
					}
					this.urlStoreService.routeBackToPreviousUrl();
				}
			}
		}
		if (routeTo === ROUTE_TO_STORE_LIST) {
			this.urlStoreService.previousURL = '/dashboard/setup/business/structure/store';
			this.urlStoreService.routeBackToPreviousUrl();
		} else if (routeTo === ROUTE_TO_MEMBER_ACCOUNTS) {
			console.log('route to memberAccounts: else if: handleSuccess');
			this.routeToMemberAccounts(data['id']);
		}
	}

	handleError(error: any): void {
		this.submitting = false;
		this.formSubmitted.emit(true);
		console.log('Error inside error handler', error);
		for (const field of Object.keys(error.errors)) {
			if (field === 'xName') {
				console.log('This.form', this.form);
				if (this.form.value.xName) {
					console.log('Inside true case');
					delete this.serverErrors['xName'];
					this.formErrors['acctxName'] = 'Field is not allowed to be empty';
				} else if (this.form.value.hasOwnProperty('safe') && this.form.value.safe.length && this.form.value.safe[0].xName) {
					this.formErrors[field] = 'Field is not allowed to be empty';
				} else {
					this.formErrors[field] = 'Field is not allowed to be empty';
					this.formErrors['acctxName'] = 'Field is not allowed to be empty';
				}
			}
			if (field === 'xStoreNum') {
				console.log('Inside handle error', error);
				if (error.name === 'Conflict') {
					let err = error.errors.xStoreNum.replace(/store/g, 'Store');
					this.notificationService.error(err, 'Conflict');
					this.clearErrors('xStoreNum');
				} else {
					this.formErrors[field] = 'Field is not allowed to be empty';
				}
			} else {
				if (this.formErrors.hasOwnProperty(field)) {
					this.formErrors[field] = 'Field is not allowed to be empty';
				}
			}
			if (field === 'xRefNum') {
				console.log('Inside reference number handle error', error);
				if (error.name === 'Conflict') {
					let err = error.errors.xRefNum.replace(/store/g, 'Store');
					this.notificationService.error(err, 'Conflict');
					this.clearErrors('xRefNum');
				}
			}
		}
	}

	handleErrorResponse(error: any): void {
		this.submitting = false;
		this.errors = JSON.parse(error._body).errors;
		this.notificationService.error(
			JSON.parse(error._body).name === 'BadRequest' ? 'Record Not Added' : JSON.parse(error._body).name,
			JSON.parse(error._body).message === 'Invalid data' ? 'Please fill all Required Details' : JSON.parse(error._body).message
		);
	}

	clearErrors(errorName: string): void {
		if (this.serverErrors[errorName]) {
			delete this.serverErrors[errorName];
		}
	}

	/**
	* called when imageString is emitted from image uploader component
	* add image string to form that is to be submitted
	*
	* @params : `event` : the imagestring that is emitted by image uploader component
	*/
	handleImageStringEvent(event){
		console.log('handleImageStringEvent: event',event);
		console.log('handleImageStringEvent: pre-adding image formdata',JSON.parse(JSON.stringify(this.form.getRawValue())));
		this.form.controls['image'].setValue(event ? event : '');
		console.log('handleImageStringEvent: post-adding image formdata',JSON.parse(JSON.stringify(this.form.getRawValue())));
	}

	onCancel(event: Event) {
		event.preventDefault();

		if (this.tabStructureService.tabMode) {
			if (!this.edit) {
				console.log('tabmode edit true');

				this.urlStoreService.routeBackToPreviousUrl();
			} else {
				console.log('tabmode false');
				this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
				this.urlStoreService.routeToSecondLastUrl();
			}
		} else {
			if (this.edit) {
				console.log('no tabmode and edit');
				if (!this.tabStructureService.editMode) {
					console.log('no tabmode and edit and editmode');
					this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
					if (this.urlStoreService.previousUrl && !this.urlStoreService.previousUrl.includes('add-edit')) {
						this.treeService.setId(this.treeService.previousSelectedNode.id, this.treeService.previousSelectedNode.type);
					}
					console.log('previous url: on cancel: add-edit', this.urlStoreService.previousURL);
					console.log('current url: on cancel: add-edit', this.urlStoreService.currentURL);
					console.log('second last url: on cancel: add-edit', this.urlStoreService.secondLastURL);
					// this.urlStoreService.previousURL = this.urlStoreService.secondLastURL;
					if (this.urlStoreService.previousURL.includes('member-accounts')) {
						// this.router.navigate([`/dashboard/setup/business/structure/information`]);
						this.routeToInitialPage();
					} else {
						this.urlStoreService.routeBackToPreviousUrl();
					}
					// }
				} else {
					console.log('no tabmode and edit and no editmode');
					if (this.treeService.nodeClick) {
						console.log('eror form if');
						if (this.urlStoreService.previousURL.includes('member-accounts')) {
							this.routeToInitialPage();
							// this.router.navigate([`/dashboard/setup/business/structure/information`]);
						} else {
							this.urlStoreService.routeToSecondLastUrl();
						}
					} else {
						console.log('error from else');
						console.log(
							'previous selected node id',
							this.treeService.previousSelectedNode.id,
							'previous selected node type',
							this.treeService.previousSelectedNode.type
						);
						console.log('url includes member-accounts: onCancel', this.urlStoreService.previousURL.includes('member-accounts'));
						if (this.urlStoreService.previousURL.includes('member-accounts')) {
							// this.router.navigate([`/dashboard/setup/business/structure/information`]);
							this.routeToInitialPage();
						} else {
							this.treeService.setId(this.treeService.previousSelectedNode.id, this.treeService.previousSelectedNode.type);
							console.log('previous url: on cancel: add-edit', this.urlStoreService.previousURL);
							console.log('current url: on cancel: add-edit', this.urlStoreService.currentURL);
							console.log('second last url: on cancel: add-edit', this.urlStoreService.secondLastURL);
							// this.urlStoreService.previousURL = this.urlStoreService.secondLastURL;
							// this.urlStoreService.previousURL = `dashboard/setup/business/structure/store`;
							this.urlStoreService.routeBackToPreviousUrl();
						}
					}
				}
			} else {
				console.log('no tabmode and no edit');
				// this.urlStoreService.previousURL = `dashboard/setup/business/structure/store`;
				this.urlStoreService.routeBackToPreviousUrl();
			}
		}
	}

	//If Store edit-mode is opened from breadcrumb of member-accounts, route to Initial page
	routeToInitialPage() {
		this.router.navigate([`/dashboard/setup/business/structure/information`]);
	}

	onEditClick(event: Event) {
		console.log('Here onEditClick function in biz===>', this.parentMode, JSON.parse(JSON.stringify(this.storeSetupModel)));
		this.tabStructureService.previousTabStructureLabel = JSON.parse(JSON.stringify(this.tabStructureService.defaultTabStructureLabel));
		this.treeService.isParent = false;
		this.parentMode = false;
		this.edit = true;
		this.treeService.setId(this.storeSetupModel._id, 'store');
		// this.router.navigate(['dashboard/setup/business/structure/information'])
	}

	//Set member-account grid-values that are to be displayed in edit mode of store
	setGridAttributes() {
		this.gridAttributes = [
			{ header: 'Account Num', mapTo: 'acctNum', width: '22%' },
			{
				header: 'Acct Token',
				mapTo: SHORT_MASKED_CARD_TOKEN,
				width: '22%'
			},
			{ header: 'Routing', mapTo: 'xRtgNum', width: '22%' },
			{
				header: 'Name',
				mapTo: 'xName',
				width: '34%'
			}
		];
	}

	//Paramters to fetch data of member-accounts to set grid
	setGridParams(params: any) {
		console.log('setGridPrams: reached here');
		if (this.businessService.businessID.getValue()) {
			this.gridParams['xBusinessID'] = this.businessService.businessID.getValue();
		}
		if (params.hasOwnProperty('Id')) {
			this.gridParams['xStoreID'] = params['Id'];
			console.log('memberaccount params', this.gridParams);
		} else {
			this.gridParams['xStoreID'] = '';
		}

		console.log('grid params: setGridParams: add-edit store', this.gridParams);
	}

	//If from value is altered in add or edit mode, save and redirect to member-accounts if 'Manage Deposit Accounts' is clicked
	//If form is not altered in add mode, show 'Can't Proceed' message
	//If form is not altered in edit mode, directly redirect to member-accounts
	onAddNewPaymentmethodClick() {
		console.log(this.form.dirty, 'is form dirty: onAddNewPaymentmethodClick');
		if (this.form.dirty) {
			customSwal.fire({
				title: 'This Store will be saved!',
				text: 'Click Yes to continue, No to decline',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				reverseButtons: true
			}).then((result) => {
				//set watcher for member accounts
				this.disableRouteGuard = true;
				let routeTo = ROUTE_TO_MEMBER_ACCOUNTS;
				this.tabStructureService.setHideTab(true);
				if (result.value) {
					let shouldRedirect = true;
					if (this.edit) {
						this.updateStore(storeAndSafeEndpoint, this.storeSetupModel._id, this.form.getRawValue(), routeTo);
					} else {
						this.onStoreSubmit(this.form, routeTo);
					}
				} else {
					this.tabStructureService.setHideTab(false);
				}
			})
		} else if (this.edit) {
			this.disableRouteGuard = true;
			let storeID =
				this.gridParams && this.gridParams.hasOwnProperty('xStoreID') && this.gridParams.xStoreID
					? this.gridParams['xStoreID']
					: null;
			console.log('stor id: add-edit store: else if', storeID);
			this.routeToMemberAccounts(storeID);
		} else {
			customSwal.fire({
				title: "Can't Proceed",
				text: 'Please complete Store-Setup to proceed for Deposit Accounts.',
				icon: 'error',
				confirmButtonText: 'Okay'
			});
		}
	}

	//Navigate to member-accounts
	//Parameter: string
	routeToMemberAccounts(storeID) {
		this.router.navigate(['/dashboard/setup/business/structure/store/member-accounts', { storeID: storeID }]);
	}

	//Update Store
	//Paramters: URL endpoint(string), storeID(string), store data(form data ie object) and where should be routed after editing is done(string)
	updateStore(storeAndSafeEndpoint, id, storeModel, routeTo) {
		// console.log(this.setupBillingCustomerModel.xCustomerNum, 'setupBillingCustomerModel: inside update function');
		console.log(storeAndSafeEndpoint, 'id', id, storeModel, 'routeTo', routeTo);
		this.submitting = true;

		this.httpService.update(storeAndSafeEndpoint, id, storeModel).subscribe(
			(response: any) => {
				console.log(response, 'edit: store');
				this.submitting = false;
				this.handleSuccessResponse(response.data, routeTo, storeModel);
			},
			(error: any) => {
				// this.handleErrorResponse(error);
				this.submitting = false;
				this.serverErrors = error.errors;
				this.handleError(error);
				console.log('this.serverErrors', this.serverErrors);
			}
		);
	}

	setupPerms(){
		this.canAdd = this.userHandlerService.userPerms.canCreateSetup;
        this.canEdit = this.userHandlerService.userPerms.canEditSetup;
    }

	ngOnDestroy() {
		this.disableRouteGuard = false;
		this.alive = false;
	}
}
