import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { TAGSCONSTS } from './../../constants/index';
import { HttpService } from './http-service';
import { UserHandlerService } from './user-handler-service';
import { NotificationService } from './notification-service';
import { validateFor } from '../../common-functions';
import { CompanySuspendedService } from 'app/pages/company-suspended/company-suspended.service';

const loginEndpoint = 'login';
const TWO_FA_DATA_KEY: string = 'twoFAData';
const TOKEN_KEY: string = 'token';
const ACCESS_TOKEN_KEY: string = 'accessToken';
@Injectable()
export class AuthenticationService {
	// store the URL so we can redirect after logging in
	redirectUrl: string;
	rememberMe: boolean;
	isLoading: boolean = false;
	twoFAToken: string = '';
	accessToken: string = '';
	constructor(
		protected httpService: HttpService,
		protected userHandlerService: UserHandlerService,
		protected router: Router,
		protected notificationService: NotificationService,
		private companySuspendService: CompanySuspendedService
	) {
		this.rememberMe = localStorage.getItem('rememberMe') === 'true' ? true : false;
	}

	login(username: string, password: string, terminalNumbersObj: any = {}, enableTwoFA: boolean): Promise<any> {
		return new Promise((resolve, reject) => {
			console.log('authentication-service: login: terminalNum:', terminalNumbersObj);
			let payload;
			if (Object.keys(terminalNumbersObj).length) {
				payload = {
					username: username,
					password: password,
					requestTimeStamp: new Date(),
					loginType: TAGSCONSTS.roleTags.roleItemTags.customerRole,
					terminalNumbersObj: terminalNumbersObj,
				};
			} else {
				payload = {
					username: username,
					password: password,
					requestTimeStamp: new Date(),
				};
			}
			if (enableTwoFA !== undefined && enableTwoFA !== null) {
				payload = Object.assign({}, payload, { enableTwoFA: enableTwoFA });
			}
			this.isLoading = true;
			this.httpService.authenticatedAPI = false;
			this.rememberMe = localStorage.getItem('rememberMe') === 'true' ? true : false;
			//API call for authentication
			this.httpService.store(loginEndpoint, payload).subscribe(
				(response: any) => {
					console.log('auth service: auth response', response);
					if (response) {
						this.isLoading = false;
						this.handleTwoFAToken(response);
						this.handleAccessToken(response);
						const res = {
							accessToken: this.accessToken,
							twoFAToken: this.twoFAToken,
							user: response.user,
						};
						return resolve(res);
					}
				},
				(error: any) => {
					this.isLoading = false;
					return reject(error);
					//Error handling is added in http-service.
				},
				() => {}
			);
		});
	}

	/**
	 * Store 2FA token
	 * @param response Two factor authentication response
	 */
	handleTwoFAToken(response: any) {
		// console.log('data: handle2FAToken', response[TWO_FA_DATA_KEY]);
		const twoFAData = validateFor(TWO_FA_DATA_KEY, response) ? response[TWO_FA_DATA_KEY] : '';
		const token = validateFor(TOKEN_KEY, twoFAData) ? twoFAData[TOKEN_KEY] : '';
		this.twoFAToken = token;
	}

	/**
	 * Store authentication token
	 * @param response authentication token
	 */
	handleAccessToken(response: any) {
		// console.log('ACCESS_TOKEN_KEY: handleAccessToken', response[ACCESS_TOKEN_KEY]);
		this.accessToken = validateFor(ACCESS_TOKEN_KEY, response) ? response[ACCESS_TOKEN_KEY] : '';
	}

	/**
	 * Store access token in local storage
	 */
	storeAccessToken() {
		this.rememberMe ? localStorage.setItem('token', this.accessToken) : sessionStorage.setItem('token', this.accessToken);
	}

	loggedIn() {
		const token = localStorage.getItem('token') || sessionStorage.getItem('token');
		if (token && token.length) {
			const helper = new JwtHelperService();
			console.log('loggedIn:', helper.isTokenExpired(token));
			return !helper.isTokenExpired(token);
			// return tokenNotExpired('token');
		} else {
			return false;
		}
	}

	logout(logOutUrl: string = ''): void {
		console.log('logout: authservice: logout url', logOutUrl);
		this.redirectUrl = '';
		this.rememberMe ? localStorage.removeItem('token') : sessionStorage.removeItem('token');
		localStorage.removeItem('rememberMe');
		this.userHandlerService.resetUserData();
		this.companySuspendService.isSuspend = false;
		this.companySuspendService.amount.next(0);
		this.companySuspendService.isSuspended.next(false);
		this.companySuspendService.dueBillingDate.next('');
		this.companySuspendService.role.next('');
		// this.router.navigate([logOutUrl]);
		this.router.navigateByUrl(logOutUrl);
	}
}
