import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable()
export class NotificationService {
    constructor(public toastr: ToastrService) {}

    success(msg: string, header: string): void {
        this.toastr.success(msg,header,{ closeButton:true });
    }

    error(msg: string, header: string): void {
        this.toastr.error(msg,header,{ closeButton:true });
    }

    warning(msg: string, header: string): void {
        this.toastr.warning(msg,header,{ closeButton:true });
    }

    info(msg: string): void {
        this.toastr.info(msg,'Info',{ closeButton:true });
    }

    custom(msg: string): void {
        this.toastr.success(msg,null,{ enableHtml: true, closeButton: true });
    }
}
