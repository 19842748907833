import { MainContact } from './main-contact.model';
import { Social } from './social.model';
import { Address } from './address.model';

export class SetupStore {
    xName: String;
    xSvcPhone: String;
    xTypeTag: String;
    xNotes: String;
    xRefNum: String;
    xTntTag: String;
    xTag: String;
    xStatus: Number;
    xWall1EntyID: String;
    xWall2EntyID: String;
    xStoreNum: String;
    xSubName: String;
    xMfrRefNum: String;
    xSvcRefNum: String;
    xCntyTag: String;
    xTZTag: String;
    xMainCcyTag: String;
    xAddr: Address;
    xSocial: Social;
    xMainContact: MainContact;
    xBusinessID: String;
    xStructs: {
        xChainID: String ;
        xRegionID: String ;
        xPropID: String ;
    };
    xAccCustomers: [{
        xStatus: Number;
        xCustomerID: String
    }];
    xGSID: String;
    xGSPW: String;
    xSWLcsTag: String;
    xDpsAccts: [{
        xStatus: Number;
        xSafeID: String
    }
    ];
    xDevCfgInfoStr: String;
    xCfgTplInfoStr: String;
    // xNotify: xNotify;
    // xDBAudit: xDBAudit
    
    constructor(obj?: any) {
        this.xName=(obj && obj.xName) || '';
        this.xTag=(obj && obj.xTag) || '';
        this.xRefNum=(obj && obj.xRefNum) || '';
        this.xNotes=(obj && obj.xNotes) || '';
        this.xTypeTag=(obj && obj.xTypeTag) || '';
        // this.xAddr = (obj && new Address(obj.xAddr)) || new Address();
        // this.xSocial = (obj && new Social(obj.xSocial)) || new Social();
        // this.xMainContact = (obj && new MainContact(obj.xMainContact)) || new MainContact();
        this.xStatus=(obj && obj.xStatus) || '';
        this.xTntTag=(obj && obj.xTntTag) || '';
        this.xWall1EntyID=(obj && obj.xWall1EntyID) || '';
        this.xWall2EntyID=(obj && obj.xWall2EntyID) || '';
        this.xStoreNum=(obj && obj.xStoreNum) || '';
        this.xSubName=(obj && obj.xSubName) || '';
        this.xMfrRefNum=(obj && obj.xMfrRefNum) || '';
        this.xSvcRefNum=(obj && obj.xSvcRefNum) || '';
        this.xCntyTag=(obj && obj.xCntyTag) || '';
        this.xTZTag=(obj && obj.xTZTag) || '';
        this.xMainCcyTag=(obj && obj.xMainCcyTag) || '';
        this.xAddr=(obj && new Address(obj.xAddr)) || new Address();
        this.xSvcPhone=(obj && obj.xSvcPhone) || '';
        this.xSocial=(obj && new Social(obj.xSocial)) || new Social();
        this.xMainContact=(obj && new MainContact(obj.xMainContact)) || new MainContact();
        this.xBusinessID=(obj && obj.xBusinessID) || '';
        this.xStructs =(obj && obj.xStructs) || {
            xChainID: '' ,
            xRegionID: '',
            xPropID: ''
        };
        this.xAccCustomers=(obj && obj.xAccCustomers) || [];
        this.xGSID=(obj && obj.xGSID) || '';
        this.xGSPW=(obj && obj.xGSPW) || '';
        this.xSWLcsTag=(obj && obj.xSWLcsTag) || '';
        this.xDpsAccts=(obj && obj.xDpsAccts) || [];
        this.xDevCfgInfoStr=(obj && obj.xDevCfgInfoStr) || '';
        this.xCfgTplInfoStr=(obj && obj.xCfgTplInfoStr) || '';
        // this.// xNotify: xNotify;
        // this.// xDBAudit: xDBAudit
    }
}
