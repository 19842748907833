<div
	bsModal
	#deletePctrModal="bs-modal"
	class="modal fade edit-perm-modal settle-action no-border hide-vertical-overflow"
	[config]="{ ignoreBackdropClick: true }"
	tabindex="-1"
	role="dialog"
	aria-labelledby="myLargeModalLabel"
	aria-hidden="true"
    id="xyz"
>
	<div class="modal-dialog modal-lg large-modal">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" (click)="closeDeleteModal()" class="close" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">Delete</h4>
			</div>
			<div class="modal-body">
				<div class="alert alert-info text-center font-weight-550">
					<p *ngIf="pCtrToBeDeleted" class="text-center mb-0">You are deleting <b>{{pCtrToBeDeleted}}.</b></p>
					<p>{{ NODES_WILL_BE_DELETED_WARNING_MESSAGE }}</p>
				</div>
				<!-- tab buttons beginning -->
				<div class="d-flex flex-row justify-content-between mt-0">
					<div
						(click)="onNodeClick(MERCHANT_ACCOUNT_KEY)"
						[ngClass]="activeNode === MERCHANT_ACCOUNT_KEY ? 'btn btn-primary' : 'btn btn-secondary'"
						class="text-center w-100"
					>
						<span class="badge badge-warning pull-right">
							{{ totalMerchantAccounts }}
						</span>
						{{ NODE_NAME_MERCHANT_ACCOUNT }}
					</div>
					<div
						(click)="onNodeClick(TERMINAL_KEY)"
						[ngClass]="activeNode === TERMINAL_KEY ? 'btn btn-primary' : 'btn btn-secondary'"
						class="text-center w-100"
					>
						<span class="badge badge-warning pull-right">
							{{ totalTerminals }}
						</span>
						{{ NODE_NAME_TERMINAL }}
					</div>
					<div
						(click)="onNodeClick(PERIPHERAL_KEY)"
						[ngClass]="activeNode === PERIPHERAL_KEY ? 'btn btn-primary' : 'btn btn-secondary'"
						class="text-center w-100"
					>
						<span class="badge badge-warning pull-right">
							{{ totalPeriphs }}
						</span>
						{{ NODE_NAME_PERIPHERALS }}
					</div>
					<div
						(click)="onNodeClick(DEPOSIT_ACCOUNT_KEY)"
						[ngClass]="activeNode === DEPOSIT_ACCOUNT_KEY ? 'btn btn-primary' : 'btn btn-secondary'"
						class="text-center w-100"
					>
						<span class="badge badge-warning pull-right">{{ totalDepositAccounts }}</span>
						{{ NODE_NAME_DEPOSIT_ACCOUNT }}
					</div>
					<div
						(click)="onNodeClick(PAYMENT_ACCOUNT_KEY)"
						[ngClass]="activeNode === PAYMENT_ACCOUNT_KEY ? 'btn btn-primary' : 'btn btn-secondary'"
						class="text-center w-100"
					>
						<span class="badge badge-warning pull-right">{{ totalPaymentAccounts }}</span>
						{{ NODE_NAME_PAYMENT_ACCOUNT }}
					</div>
				</div>
				<!-- tab buttons ending -->
				<!-- current node name beginning -->
				<div class="mt-2" *ngIf="activeNode !== DEPOSIT_ACCOUNT_KEY && activeNode !== PAYMENT_ACCOUNT_KEY && tableBody.length">
					<label for="node_name" class="font-weight-500">
						{{ currentNodeName }}
					</label>
				</div>
				<!-- current node name ending -->
				<div class="scroll" [class.delete-pctr-modal-safes-scroll]='activeNode === PAYMENT_ACCOUNT_KEY'>
                    <div *ngIf="!tableBody.length" class="no-associated-nodes">
                        <label for="no_data"><h4>{{ noDependenciesMsg }}</h4></label>
					</div>
					<!-- table for nodes other than member-accounts -->
					<table
						*ngIf="activeNode !== DEPOSIT_ACCOUNT_KEY && activeNode !== PAYMENT_ACCOUNT_KEY && tableBody.length"
						class="table table-sm table-striped table-grid vertical-content table_main"
					>
						<thead>
							<th *ngFor="let tableHeader of tableHeaders">
								<tr>
									{{
										tableHeader
									}}
								</tr>
							</th>
						</thead>
						<tbody class="delete-pctr-modal-general-tbody">
								<!-- style='max-height:50vh' -->
							<ng-container *ngIf="activeNode === TERMINAL_KEY">
								<tr *ngFor="let tableData of tableBody">
									<td>
										{{ tableData?.xName ? tableData.xName : '-' }}
									</td>
									<td>
										{{ tableData?.xGTID ? tableData.xGTID : '-' }}
									</td>
								</tr>
							</ng-container>
							<ng-container *ngIf="activeNode === MERCHANT_ACCOUNT_KEY">
								<tr *ngFor="let tableData of tableBody">
									<td>
										{{ tableData?.xName ? tableData.xName : '-' }}
									</td>
									<td class="overflow-nextline">
										{{ tableData?.xRefNum ? tableData.xRefNum: '-' }}
									</td>
								</tr>
							</ng-container>
							<ng-container *ngIf="activeNode === PERIPHERAL_KEY">
								<tr *ngFor="let tableData of tableBody">
									<td>
										{{ tableData?.xName ? tableData.xName: '-'  }}
									</td>
									<td class="overflow-nextline">
										{{ tableData?.xPeriphNum ? tableData.xPeriphNum: '-' }}
									</td>
								</tr>
							</ng-container>
						</tbody>
					</table>
					<!-- table for nodes other than member-accounts -->
					<!-- table for member-accounts beginning -->
					<ng-container *ngIf="activeNode === DEPOSIT_ACCOUNT_KEY || activeNode === PAYMENT_ACCOUNT_KEY">
						<table
							*ngFor="let tableData of tableBody"
							class="table table-sm table-striped table-grid vertical-content table_main"
						>
							<div class="mt-2">
								<label for="safe_parent_name" class="font-weight-500">
									<span *ngIf="activeNode === DEPOSIT_ACCOUNT_KEY">
										Store:
									</span>
									<span *ngIf="activeNode === PAYMENT_ACCOUNT_KEY">
										Customer:
									</span>
									{{ tableData?.xName ? tableData.xName: '-' }}
								</label>
							</div>
							<thead>
								<th *ngFor="let tableHeader of tableHeaders">
									<tr>
										{{
											tableHeader
										}}
									</tr>
								</th>
							</thead>
							<tbody [class.delete-pctr-modal-general-tbody]='activeNode === DEPOSIT_ACCOUNT_KEY'>
								<ng-container>
									<tr *ngFor="let safe of tableData?.list_of_safes">
										<td>
											{{ maskCardToken(safe.xAcctTok) ? maskCardToken(safe.xAcctTok) : '-' }}
										</td>
										<td>
											{{ BRAND_TAGS_MAPPING[safe.xBrandTag] ? BRAND_TAGS_MAPPING[safe.xBrandTag] : '-' }}
										</td>
										<td>
											{{ MEDIUM_TAGS_MAPPING[safe.xMdmTag]  ? MEDIUM_TAGS_MAPPING[safe.xMdmTag] : '-' }}
										</td>
									</tr>
								</ng-container>
							</tbody>
						</table>
					</ng-container>
					<!-- table for member-accounts ending -->
				</div>
			</div>
			<div class="modal-footer">
				<div class="row">
					<div class="clearfix">
						<button type="button" class="btn btn btn-primary pull-right" (click)="onProceedToDeletePctrClick()">
							Proceed
							<i class="fa fa-angle-double-right" aria-hidden="true"> </i>
						</button>
						<button type="button" class="btn btn btn-secondary mr-2 pull-right" (click)="closeDeleteModal()">Cancel</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
