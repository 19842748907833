import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { map,catchError } from 'rxjs/operators';
// import * as apiConfig from '../config/api.config';
import { ApiConfig } from './../config/api.config';

@Injectable()
export class GmNumberService {
    authHttp:any={};
    constructor(
        protected http:HttpClient,
        private apiConfig:ApiConfig
    ) {
        // this.authHttp=new AuthHttp(new AuthConfig, http);
    }

    fetchGMData(url:string, params:any):Observable<any>{
        

        return this.http
            .post(`${this.apiConfig.API_HOST}/api/${url}/`, params)
            .pipe(
				map((response: Response) => {
					return response;
				}),
				catchError((err) => {
					console.log(err);
					return err;
				})
			);
    }
    // fetchGMPW(url:string, params:any):Observable<any>{
    //     return this.authHttp.post(`${apiConfig.API_HOST}/api/${url}/`, params)
    //         .map(
    //             (response:Response)=>{
    //                 console.log('response inside service fetchGMPW method', response);
    //                 return response.json();
    //             }
    //         )
    //         .catch(
    //             (err) => {
    //             console.log(err);
    //             console.log(err.json());
    //             return err;
    //         });
    // }

   
}
