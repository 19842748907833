import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BusinessService } from './business-service';
import { HttpService } from './http-service';

const DB_XHAS_REGION_KEY = 'xHasRegions';
const DB_XHAS_PROPS_KEY = 'xHasProps';
const DB_XHAS_CHAINS_KEY = 'xHasChains';

@Injectable()
export class TabStructureService {
	hideTabStructure: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	tabStructureLabels: BehaviorSubject<any> = new BehaviorSubject<any>({});
	parentFetched: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	watchTabStructureLabels$ = this.tabStructureLabels.asObservable(); // Watch if new business is added or removed
	watchParentFetched$ = this.parentFetched.asObservable(); // Watch if new business is added or removed
	watchHideTabStructure$ = this.hideTabStructure.asObservable(); //watch if tab structure should be hided

	labelNames = {
		biz: 'Business',
		chain: 'Chain',
		region: 'Region',
		prop: 'Property',
		store: 'Store',
		pCtr: 'Profit Center',
		merAcct: 'Merchant Account',
		terminal: 'Terminal',
		periph: 'Peripheral'
	};

	tabLabelItems = [
		{
			label: 'Business',
			link: ['/dashboard/setup/business/structure/information']
		},
		{
			label: 'Chain',
			link: ['/dashboard/setup/business/structure/chain']
		},
		{
			label: 'Region',
			link: ['/dashboard/setup/business/structure/region']
		},
		{
			label: 'Property',
			link: ['/dashboard/setup/business/structure/property']
		},
		{
			label: 'Store',
			link: ['/dashboard/setup/business/structure/store']
		},
		{
			label: 'Profit Center',
			link: ['/dashboard/setup/business/structure/profit-center']
		},
		{
			label: 'Merchant Account',
			link: ['/dashboard/setup/business/structure/merchant-account']
		},
		{
			label: 'Terminal',
			link: ['/dashboard/setup/business/structure/terminal']
		},
		{
			label: 'Peripheral',
			link: ['/dashboard/setup/business/structure/peripheral-list']
		}
	];

	public storeDefTabStruct: boolean = true;
	public selectedEntityDetail: any;
	_defaultTabStructureLabel: any = {};
	_previousTabStructureLabel: any = {};
	_editMode: boolean = false;
	_tabMode: boolean = false;
	_isCancelClick: boolean = false;
	_isSubmitClick: boolean = false;
	_addNodeBtnClick: boolean = false;
	private _currentNodeName: string;

	constructor(private httpService: HttpService, private businessService: BusinessService) {}

	setHideTab(hide: boolean): void {
		// this._hideTabStructure = hide;
		this.hideTabStructure.next(hide);
	}

	setTabStructureNames(tabStructName: any): void {
		console.log('%c setTabStructureNames??', 'background: grey; color: black', tabStructName);
		this.tabStructureLabels.next(tabStructName);
	}

	setParentsFetched(parentFetched: boolean): void {
		// console.log('%c setTabStructureNames??', 'background: grey; color: black', tabStructName);
		this.parentFetched.next(parentFetched);
	}

	getTabStructureNames(endPoint: string, params: any): Promise<any> {
		return new Promise((resolve, reject) => {
			console.log('endPoint, params', endPoint, params);
			this.httpService.getAll(endPoint, params, true).subscribe(
				(response: Response | any) => {
					console.log('Response inside getTabStructureNames function', response);
					return resolve(response);
				},
				(err: any) => {
					console.log('Error inside getTabStructureNames function', err);
					return reject(err);
				},
				() => {}
			);
		});
	}

	generateTabLabels(tabStructName: any) {
		this.setTabNameToInitial();
		// console.log('%c Inside tab struct name watcher', 'background: blue; color: white', res);
		this.tabLabelItems.map(item => {
			console.log('%c item', 'background: black; color: white', item, this.currentNodeName);
			Object.keys(this.labelNames).map(key => {
				// console.log('%c labelname item.label', 'background: blue; color: white', this.labelNames[key], item.label);
				if (this.labelNames[key] === item.label) {
					tabStructName.map(obj => {
						// console.log('%c obj key', 'background: blue; color: white', obj, key);
						if (obj.type === key) {
							if (obj['value'] === 'new') {
								item.label = this.labelNames[key];
								item['type'] = 'New';
								item['name'] = '';
							} else if (obj['value'] === 'none') {
								item.label = this.labelNames[key];
								item['type'] = 'Parent';
								item['name'] = '(None)';
							} else if (obj['value'] === 'list') {
								let suffixTag = '';
								if (key === 'prop') item.label = 'Properties';
								else {
									suffixTag = 's';
									item.label = this.labelNames[key] + suffixTag;
								}
								item['type'] = 'List of';
								item['name'] = '';
							} else if (obj['value'] === 'current') {
								item.label = this.labelNames[key];
								item['type'] = 'Current';
								if (item.label.includes('Business')) {
									console.log('this.selectedEntityDetail', this.selectedEntityDetail);
									if (this.selectedEntityDetail) {
										item['name'] =
											'-' + (this.selectedEntityDetail && this.selectedEntityDetail.hasOwnProperty('xName'))
												? this.selectedEntityDetail.xName
												: '';
									}
								} else {
									item['name'] = '-' + this.currentNodeName;
								}
							} else {
								item.label = this.labelNames[key];
								item['type'] = 'Parent';
								item['name'] = ' - ' + obj['value'];
							}
						}
					});
				}
			});
		});
		// let storeDef=this.storeDefTabStruct;
		console.log('this.defa 0', this.storeDefTabStruct);
		if ((this.storeDefTabStruct && this.tabLabelItems.length) || this.isSubmitClick) {
			console.log('this.defa 1', JSON.parse(JSON.stringify(this.defaultTabStructureLabel)));
			this.previousTabStructureLabel = JSON.parse(JSON.stringify(this.defaultTabStructureLabel));
			this.defaultTabStructureLabel = JSON.parse(JSON.stringify(this.tabLabelItems));
			console.log('this.defa 2', JSON.parse(JSON.stringify(this.defaultTabStructureLabel)));
			this.storeDefTabStruct = false;
		} else {
			if (this.editMode && !this.tabMode) {
				this.defaultTabStructureLabel = JSON.parse(JSON.stringify(this.tabLabelItems));
			}
		}
		// if(!this.addNodeBtnClick){
		this.setTabStructureNames(JSON.parse(JSON.stringify(this.tabLabelItems)));
		this.setParentsFetched(true);
		// }
	}

	set currentNodeName(value: string) {
		this._currentNodeName = value;
	}

	get currentNodeName(): string {
		return this._currentNodeName;
	}

	set editMode(eMode: boolean) {
		this._editMode = eMode;
	}

	get editMode(): boolean {
		return this._editMode;
	}
	set tabMode(tMode: boolean) {
		this._tabMode = tMode;
	}

	get tabMode(): boolean {
		return this._tabMode;
	}
	set isCancelClick(cancel: boolean) {
		this._isCancelClick = cancel;
	}

	get isCancelClick(): boolean {
		return this._isCancelClick;
	}
	set isSubmitClick(submit: boolean) {
		this._isSubmitClick = submit;
	}

	get isSubmitClick(): boolean {
		return this._isSubmitClick;
	}
	set addNodeBtnClick(addBtn: boolean) {
		this._addNodeBtnClick = addBtn;
	}

	get addNodeBtnClick(): boolean {
		return this._addNodeBtnClick;
	}

	set defaultTabStructureLabel(tStructLabel: any) {
		console.log('%c Here inside default tab structure label functn', 'background: yellow; color: black', tStructLabel);
		this._defaultTabStructureLabel = tStructLabel;
	}

	get defaultTabStructureLabel(): any {
		return this._defaultTabStructureLabel;
	}
	set previousTabStructureLabel(tStructLabel: any) {
		console.log('%c Here inside revious tab structure label functn', 'background: yellow; color: black', tStructLabel);
		this._previousTabStructureLabel = tStructLabel;
	}

	get previousTabStructureLabel(): any {
		return this._previousTabStructureLabel;
	}

	setTabNameToDefault() {
		console.log('%c setTabNameToDefault', 'background: pink; color: white', this.defaultTabStructureLabel);
		if (Object.keys(this.defaultTabStructureLabel).length) {
			this.tabLabelItems = JSON.parse(JSON.stringify(this.defaultTabStructureLabel));
			this.tabStructureLabels.next(this.defaultTabStructureLabel);
		}
	}

	checkAndSetTabNameToDef(tabMode: boolean, cancelClick: boolean, submitClick: boolean) {
		console.log('checkAndSetTabNameToDef');
		if (this.businessService.businessID.getValue()) {
			if (!tabMode) {
				console.log('%c not tabmode', 'background: purple; color: white');
				this.setTabNameToDefault();
			} else {
				console.log('%c tabMode', 'background: lime; color: black');
				if (!cancelClick) {
					console.log('%c tabMode cancel', 'background: olive; color: black');
					this.setTabNameToDefault();
					this.isCancelClick = false;
				} else if (!submitClick) {
					console.log('%c tabMode submit', 'background: grey; color: blue');
					this.setTabNameToDefault();
					this.isSubmitClick = false;
				} else if (this.editMode) {
					console.log('%c tabMode editMode', 'background: lime; color: black');
				}
			}
		} else {
			this.tabStructureLabels.next(this.tabLabelItems);
		}
	}

	setTabNameToInitial() {
		console.log('Inside setTabNameToInitial()');
		this.tabLabelItems = [
			{
				label: 'Business',
				link: ['/dashboard/setup/business/structure/information']
			},
			{
				label: 'Chain',
				link: ['/dashboard/setup/business/structure/chain']
			},
			{
				label: 'Region',
				link: ['/dashboard/setup/business/structure/region']
			},
			{
				label: 'Property',
				link: ['/dashboard/setup/business/structure/property']
			},
			{
				label: 'Store',
				link: ['/dashboard/setup/business/structure/store']
			},
			{
				label: 'Profit Center',
				link: ['/dashboard/setup/business/structure/profit-center']
			},
			{
				label: 'Merchant Account',
				link: ['/dashboard/setup/business/structure/merchant-account']
			},
			{
				label: 'Terminal',
				link: ['/dashboard/setup/business/structure/terminal']
			},
			{
				label: 'Peripheral',
				link: ['/dashboard/setup/business/structure/peripheral-list']
			}
		];

		//Check if the selected business in topnav has child chain, region and property and update the tab accordingly.
		if (this.selectedEntityDetail && this.selectedEntityDetail.hasOwnProperty('xStructCaps')) {
			if (
				this.selectedEntityDetail.xStructCaps.hasOwnProperty(DB_XHAS_CHAINS_KEY) &&
				!this.selectedEntityDetail.xStructCaps.xHasChains
			) {
				this.tabLabelItems.splice(1, 1);
				if (
					this.selectedEntityDetail.xStructCaps.hasOwnProperty(DB_XHAS_REGION_KEY) &&
					!this.selectedEntityDetail.xStructCaps.xHasRegions
				) {
					this.tabLabelItems.splice(1, 1);
					if (
						this.selectedEntityDetail.xStructCaps.hasOwnProperty(DB_XHAS_PROPS_KEY) &&
						!this.selectedEntityDetail.xStructCaps.xHasProps
					) {
						this.tabLabelItems.splice(1, 1);
					}
				} else if (
					this.selectedEntityDetail.xStructCaps.hasOwnProperty(DB_XHAS_PROPS_KEY) &&
					!this.selectedEntityDetail.xStructCaps.xHasProps
				) {
					this.tabLabelItems.splice(2, 1);
				}
			} else if (
				this.selectedEntityDetail.xStructCaps.hasOwnProperty(DB_XHAS_REGION_KEY) &&
				!this.selectedEntityDetail.xStructCaps.xHasRegions
			) {
				this.tabLabelItems.splice(2, 1);
				if (
					this.selectedEntityDetail.xStructCaps.hasOwnProperty(DB_XHAS_PROPS_KEY) &&
					!this.selectedEntityDetail.xStructCaps.xHasProps
				) {
					this.tabLabelItems.splice(2, 1);
				}
			} else if (
				this.selectedEntityDetail.xStructCaps.hasOwnProperty(DB_XHAS_PROPS_KEY) &&
				!this.selectedEntityDetail.xStructCaps.xHasProps
			) {
				this.tabLabelItems.splice(3, 1);
			}
		}
	}
}
