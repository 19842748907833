import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CompanySuspendedService {
	role: BehaviorSubject<string> = new BehaviorSubject<string>('');
	role$: Observable<string> = this.role.asObservable();
	
	create_role: BehaviorSubject<string> = new BehaviorSubject<string>('');
	create_role$: Observable<string> = this.create_role.asObservable();

	isSuspended: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isSuspended$: Observable<boolean> = this.isSuspended.asObservable();

	amount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	amount$: Observable<number> = this.amount.asObservable();

	dueBillingDate: BehaviorSubject<Date | string> = new BehaviorSubject<Date | string>('');
	dueBillingDate$: Observable<Date | string> = this.dueBillingDate.asObservable();

	vendorTerminalID: BehaviorSubject<string> = new BehaviorSubject<string>('');
	vendorTerminalID$: Observable<string> = this.vendorTerminalID.asObservable();

	customerBusinessName: BehaviorSubject<string> = new BehaviorSubject<string>('');
	customerBusinessName$: Observable<string> = this.customerBusinessName.asObservable();

	customerBusinessID: BehaviorSubject<string> = new BehaviorSubject<string>('');
	customerBusinessID$: Observable<string> = this.customerBusinessID.asObservable();

	showLoginModal: boolean = false;
	isSuspend: boolean = false;

	constructor() {}

	setLoginModal(value: boolean) {
		this.showLoginModal = value;
	}
}
