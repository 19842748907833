import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { UserHandlerService, LoginStoreService, HttpService, NotificationService, PaymentService } from '../../../../shared/services/index';

const brandTypeTags = {
    'brand.cc':'genericCreditCard',
    'brand.cc.vs':'Visa',
    'brand.cc.mc':'MasterCard',
    'brand.cc.ax':'American Express',
    'brand.cc.ds':'Discover',
    'brand.cc.dn':'DinersClub',
    'brand.cc.cb':'CarteBlanche',
    'brand.cc.en':'enRoute',
    'brand.cc.jc':'jcb',
    'brand.cc.ja':'jal',
    'brand.cc.up':'cup',
    'brand.cc.ma':'MaestroCard',
    'brand.dc':'genericDebitCard',
    'brand.gc':'genericGiftCard',
    'brand.pc':'genericPrepaidCard',
    'brand.lc':'genericLoyaltyCard',
    'brand.eb':'genericEBTCard',
    'brand.eb.fs':'Food Stamp Card',
    'brand.eb.cb':'Cash Benefit Card',
    'brand.ec':'genericElectronicCheck',
}
const mdmTags = {
    'mdm.cc':'Credit Card',
    'mdm.dc':'Debit Card',
    'mdm.gc':'Gift Card',
    'mdm.pc':'Prepaid Card',
    'mdm.lc':'Loyalty Card',
    'mdm.eb':'EBT Card',
    'mdm.ec':'Electronic Check',
}


@Component({
    selector: 'app-select-payment-method',
    templateUrl: './select-payment-method.component.html',
    styleUrls: ['./select-payment-method.component.scss']
})
export class SelectPaymentMethodComponent implements OnInit {
    public paymentMethod: FormGroup;
    public paymentMethodOptions: any = [];
    public currentPaymentMethod: any = {};
    public lastPaymentMethod: any = {};
    public entityData : any = {};

    // placeholder data
    // public placeholderPaymentData: any = [
    //     { cardType: 'Visa', cardNumber: '4111111111111111', cardName: 'asdfghjkl' },
    //     { cardType: 'MasterCard', cardNumber: '4111111111111122', cardName: 'qwertyuiop' }
    // ];

    constructor(
        private userHandlerService: UserHandlerService,
        private loginStoreService: LoginStoreService,
        private httpService: HttpService,
        public formBuilder: FormBuilder,
        public notificationService: NotificationService,
        private router: Router,
        private paymentService: PaymentService
    ) {}

    ngOnInit() {
        // this.lastPaymentMethod = this.placeholderPaymentData[0];
        this.lastPaymentMethod = this.paymentService.lastUsedPaymentMethod;
        // this.paymentMethodOptions = this.placeholderPaymentData;
        // this.paymentMethodOptions = this.paymentService.fetchPaymentMethodOptions();
        this.entityData = Object.keys(this.userHandlerService.userType).length
            ? this.userHandlerService.userType
            : {};
        console.log('select-payment: init: entity data', this.entityData);
        let params = {
            customerID: this.entityData._id ? this.entityData._id : ''
        };
        console.log('select-payment: init: params', params);
        this.paymentService.fetchPaymentMethodOptions(params)
            .subscribe(response=>{
                let responseData = response && response.hasOwnProperty('data') && response['data']
                    ? response['data']
                    : {};
                console.log('select-payment: init: payment method response',response)
                console.log('select-payment: init: currentpayment method',this.currentPaymentMethod);
                console.log('select-payment: init: currentpayment method',this.lastPaymentMethod);
                this.paymentMethodOptions = responseData;
                this.paymentMethodOptions.map(obj=>{
                    obj.brand = brandTypeTags[obj.xBrandTag];
                    obj.medium = mdmTags[obj.xMdmTag];
                })
                console.log('select-payment: init: methods',this.paymentMethodOptions)
            },error=>{
                console.log('select-payment: init: payment method error',error);
                this.notificationService.error('Failed to fetch payment method information.','Error');
            })
        this.currentPaymentMethod = this.paymentService.paymentMethod;
        this.initValidation();
        console.log('selectPayment init: currentpaymentmethod',this.currentPaymentMethod)
    }

    // select payment form validation
    initValidation() {
        this.paymentMethod = this.formBuilder.group({
            paymentOption: [
                Object.keys(this.currentPaymentMethod).length 
                    ? this.currentPaymentMethod 
                    : '', 
                [Validators.required]]
        });
    }

    // called when payment type is selected in modal
    paymentMethodSelected(form: FormGroup) {
        let formData = form.getRawValue();
        console.log('paymentMEthodSelected: formData',formData)
        this.currentPaymentMethod =
            Object.keys(formData).length && formData.hasOwnProperty('paymentOption') && formData['paymentOption']
                ? formData['paymentOption']
                : {};
        this.paymentService.paymentMethod = this.currentPaymentMethod;
        console.log('paymentMethodSelected: payment service data', this.paymentService.paymentData);
        console.log('paymentMethodSelected: payment service Method', this.paymentService.paymentMethod);
        // form.reset();
        console.log('paymentMethodChanged: form', formData);
        // this.confirmPayment();
        this.routeToPaymentForm();
    }

    // route to payment form
    routeToPaymentForm() {
        this.router.navigate(['/dashboard/customer/pay-now']);
    }

    // route to create payment form
    openCreatePaymentFromSelectPayment() {
        this.router.navigate(['/dashboard/customer/pay-now/create-payment-method']);
    }

    // route to confirm payment page
    confirmPayment() {
        this.router.navigate(['/dashboard/customer/pay-now/confirm-payment']);
    }
}
