import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginStoreService } from '../services/login-store.service';
import { HttpService } from '../services/http-service';
import { NotificationService } from '../services/notification-service';
import { SetupBusinessStructureStoreSetupStore } from '../../store/setup-business-structure-store_setup-store';
// import { Location } from '@angular/common';

@Injectable()
export class LoginResolverService implements Resolve<any> {
	constructor(
		private loginStoreService: LoginStoreService,
		private httpService: HttpService,
		private setupBusinessStructureStoreSetupStore: SetupBusinessStructureStoreSetupStore,
		private notificationService: NotificationService,
		private router: Router
	) // private location: Location
	{}

	resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): any {
		console.log('login resolver activated route', route.params, 'state snapshot', rstate);
		if (this.loginStoreService.storeObj.hasOwnProperty('id') && route.params.hasOwnProperty('Id')) {
			return true;
		} else if (!this.loginStoreService.storeObj.hasOwnProperty('id') && route.params.hasOwnProperty('Id')) {
			// this.location.back();
			// return true;
			return new Promise((resolve, reject) => {
				this.httpService.authenticatedAPI = false;
				this.setupBusinessStructureStoreSetupStore.get(route.params.Id).subscribe(
					response => {
						console.log('response in loginservice', response);
						this.loginStoreService.storeData = response;
						this.loginStoreService.storeObj = response;
						this.httpService.authenticatedAPI = true;
						return resolve(true);
					},
					error => {
						this.notificationService.error('Store Data Unavailable', 'Error');
						this.router.navigate(['login']);
						return reject(false);
					}
				);
			});
		} else {
			this.notificationService.error('Store Data Unavailable', 'Error');
			this.router.navigate(['login']);
			return false;
		}
	}
}
