import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { NotificationService, ConstantService } from '../../../../../../shared/services';
import { SetupBillingCustomerStore } from '../../../../../../store';

@Component({
	selector: 'app-customer-details',
	templateUrl: './customer-details.component.html',
	styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit, OnChanges {
	setupBillingCustomer: any; //For displaying values
	operationType: string;

	@Output() onViewClose = new EventEmitter<boolean>();
	@Input() mode: string;
	@Input() customer: any;

	constructor(
		private setupBillingCustomerStore: SetupBillingCustomerStore,
		private notificationService: NotificationService,
		private constantService:ConstantService
	) {
		this.setupBillingCustomer = {};
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('customer')) {
			this.setupBillingCustomer = this.constantService.updateDetailsData("SetupBillingCustomerStore", this.customer);
			console.log("this.setupBillingCustomer ngchange", this.setupBillingCustomer);
		}
	}

	ngOnInit() {
		console.log("this.setupBillingCustomer init", this.setupBillingCustomer);
		if (this.mode === 'view') {
			this.operationType = 'View';
		}
	}

	hideDetail() {
		this.onViewClose.emit(false);
	}
}
