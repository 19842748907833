export const API_END_POINT = {
    xpcSafes: 'xpcSafes',
    xbcCompanies: 'xbcCompanies',
    xscUsers: 'xscUsers',
    xpcTerminals: 'xpcTerminals',
    xpcMerAccts: 'xpcMerAccts',
    action_worker_queues: 'action_worker_queues',
    action_scheduler_queues: 'action_scheduler_queues',
    deleteSafes: 'deleteSafes',
    terminalDeletionCheck: 'terminal-deletion-checks',
    vterm_info: 'vTerm_info',
    vtermEncrypt: 'vTerm/encrypt',
    transactionAmount: 'transactionAmount',
    importTxnDefinition: 'importTransactionDefinition',
    importTransaction: 'importTransaction',
    verifyOTP: 'verify-otp',
    xbcBusiness: 'xbcBusinesses',
    xbcStores: 'xbcStores',
    xbcPCtrs: 'xbcPCtrs',
    xbcCustomers: 'xbcCustomers',
    billingSummary: 'billing-summary',
    xpeInvoice: "xpeInvoice",
    xpeBilling: "xpeBilling"
};
