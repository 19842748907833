import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    ViewChild,
    ElementRef,
    Output,
    EventEmitter
} from "@angular/core";
import { HttpService } from "../../../../../shared/services";
import {
    TransactionActionService,
    NotificationService
} from "../../../../../shared/services";
import { takeWhile, skip } from 'rxjs/operators';

@Component({
    selector: "app-re-print",
    templateUrl: "./re-print.component.html",
    styleUrls: ["./re-print.component.scss"]
})
export class RePrintComponent implements OnInit, OnDestroy {
    @ViewChild("print_this") printContent: ElementRef;

    @Input() transactionStoreData: any[];
    transactionStoreDatalocal = [];
    // @Input() storeData: any[];
    @Output() reprintComplete= new EventEmitter<boolean>();;
    alive: boolean = true;
    show: boolean = true;

    constructor(
        private httpService: HttpService,
        private transactionActionService: TransactionActionService,
        private notificationService: NotificationService
    ) {}

    ngOnInit() {
        console.log("Init of RePrint Component", this.transactionStoreData);
	this.transactionStoreDatalocal = [];
        this.transactionStoreData.map(data=>{
            let newData = data;
            let txnData =
            data.hasOwnProperty('txnData') && data.txnData
              ? data.txnData
              : {};
              newData['txnData']['date'] =
              txnData && txnData.hasOwnProperty('date') && txnData.date ? this.updateDate(txnData.date) : '-';
              this.transactionStoreDatalocal.push(newData);
        });
        this.transactionActionService.watchPrint$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe((print: boolean) => {
                if (print) {
                    console.log("Print");
                    let id = document.getElementById("printThis");
                    this.printElement(id).then(res => {
                        this.transactionActionService.setDoRePrint(false);
                        this.reprintComplete.emit(true);
                        // this.notificationService.success(
                        //     "Print successfull",
                        //     "Success"
                        // );
                    });
                } else {
                    this.show = false;
                }
            });
    }
    ngOnDestroy() {
        this.alive = false;
    }

    printElement(element): Promise<any> {
        return new Promise((resolve, reject) => {
            let domClone = element.cloneNode(true);
            let $printSection = document.getElementById("printSection");
            if($printSection){
                console.log("Always here!!");
                $printSection.remove();
            }
            $printSection = document.createElement("div");
            $printSection.id = "printSection";
            document.body.appendChild($printSection);

            $printSection.innerHTML = "";
            $printSection.appendChild(domClone);
            console.log("new div --->", $printSection);
            print();
            this.show = false;
            return resolve(true);
        });
    }

    updateDate(value: string) {
        let modifiedDate = value.toString();
        if (!modifiedDate || modifiedDate.length < 8) {
          return '-';
        }
	return modifiedDate.split('/')[1]  + '/' + modifiedDate.split('/')[0] + '/' + modifiedDate.split('/')[2];
    }
}
