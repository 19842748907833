import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { BusinessService,
	NotificationService,
	TabStructureService,
	HttpService,
	UserHandlerService,
	VtermTokenService
} from 'app/shared/services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MemberAccountStore } from 'app/store/member-account-store';
import { NO_PERMISSION_MSG, TAGSCONSTS, XPCTERMINALS } from 'app/constants';
// import { ActivatedRoute } from '@angular/router';
import { SetupStructureProfitCenterStore, SetupStructureTerminalStore, SetupBillingCustomerStore } from 'app/store';
import { BRAND_TAGS_MAPPING } from 'app/constants/mapping.constants';
import { validateFor, maskCardToken } from 'app/common-functions';
import { VtermConfig } from '../../../../shared/config/vTerm.config';

const PGCOM_RESPONSE_KEY: string = 'pgComResponse';
const BE_ERROR_KEY: string = 'error';
const DB_MONGO_ID_KEY: string = '_id';
const PARAM_URL_FIELD = '?param=';

const SOURCE = 'webApp';
const MEMBER_ACCOUNT_PARENT_MODE_STORE = 'Store';
const MEMBER_ACCOUNT_PARENT_MODE_CUSTOMER = 'Customer';
const MODAL_MODE_ADD = 'add';
const MODAL_MODE_EDIT = 'edit';
const PCTR_ERROR = 'Profit Center is Required.';
const MEDIUM_CREDIT = 'Credit';
const BRAND_ECHECK = BRAND_TAGS_MAPPING['brand.ec'];
const MEDIUM_ECHECK = BRAND_ECHECK;
const NO_MEMBER_ACCOUNT_FOUND_MESSAGE = 'No Member-Account Found.';
const NO_DATA_FOUND_MESSAGE = 'No Data Found or Incorrect Account-Token response';
const GWKEY_STATUS = 'Status'; //!< Str: Status; from GW to POS
const GWVAL_STATUS_OK = 'success'; //!< Status: OK
const PGCOMM_MEDIUM_KEY = 'Medium'; //Medium
const RECORD_NOT_ADDED = 'Record Not Added.';
const RECORD_NOT_UPDATED = 'Record Not Updated.';
const GTID_UNAVAILABLE_MESSAGE: string = 'GTID is not available.';
const PCTR_ID_UNAVAILABLE_MESSAGE: string = 'Profit-Center ID is unavailable.';
const PCTR_UNAVAILABLE_MESSAGE = 'There is no Profit Center under this store. Please setup a Profit Center to continue.';
const TERMINAL_UNAVAILABLE_MESSAGE = 'There is no Terminal present. Please set up Terminal to continue.';
const COULD_NOT_SET_GMID_ERR_MSG = 'Could not set GMID. Please try again.';
const COULD_NOT_SET_GTID_ERR_MSG = 'Could not set GTID. Please try again';
const COULD_NOT_SET_GMPW_ERR_MSG = 'Could not set GMPW. Please try again';

const ERROR_KEY: string = 'Error';
const PARAMTER_NOT_FOUND: string = ' is Not Found. Please try again.';
const MONTHS_ARRAY = [
	{ value: 1, name: 'Jan' },
	{ value: 2, name: 'Feb' },
	{ value: 3, name: 'Mar' },
	{ value: 4, name: 'Apr' },
	{ value: 5, name: 'May' },
	{ value: 6, name: 'Jun' },
	{ value: 7, name: 'Jul' },
	{ value: 8, name: 'Aug' },
	{ value: 9, name: 'Sept' },
	{ value: 10, name: 'Oct' },
	{ value: 11, name: 'Nov' },
	{ value: 12, name: 'Dec' }
];

//TODO: Import from db-keys file after merge
//DB keys
const DB_XSIGNUP_PCTR_ID_KEY: string = 'xSignupPCtrID';
const DB_XREAL_ACCT_NUM_KEY: string = 'xRealAcctNum';
const DB_XRTG_NUM_KEY: string = 'xRtgNum';
const DB_XACCT_NAME_KEY: string = 'xAcctName';
const PG_COM_ACCT_TYPE_KEY: string = 'AccountType';
const DB_GMID_KEY: string = 'xGMID';
const DB_GMPW_KEY: string = 'xGMPW';

const BE_DATA_KEY: string = 'data';
const TERMINAL_ID_KEY: string = 'xTerminalID';
const TERMINALS_KEY: string = 'terminals';

const SELECT_PCTR_MSG = 'Please select profit center.'
const SELECT_TERM_MSG = 'Please select terminal.'
const FAILED_VTERM_INFO_MSG = 'Failed to fetch required data.'

@Component({
	selector: 'app-add-payment',
	templateUrl: './add-payment.component.html',
	styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit, AfterViewInit {
	//Interacting variables
	@ViewChild('addPaymentMethodModal', {static: true})
	public addPaymentMethodModal: ModalDirective;
	@Input()
	public modalMode: string = MODAL_MODE_ADD; //Default mode is add, if edit, value will be reicieved from member-acccounts grid
	@Input()
	public member_account_parentMode: string = ''; //Value will be reicieved from member-acccounts grid, maintains the parent mode of corresponding member account
	@Input()
	public memberAccountID: string = ''; //Recieved from member-accounts list when edit is clicked
	@Input()
	public member_account_parentID: string = ''; //Recieved from member-accounts list, stores parentID of corresponding member account
	// @Input()
	// public signupStoreID: string = ''; //To get pctrs sent from member-acct list
	// @Output()
	// public onHide = new EventEmitter<boolean>(); //Send values to member-acct list on modal-hide
	@Output()
	public sendStateObj = new EventEmitter<boolean>();
	public hasNoData: boolean = false;
	//Component specific variables
	public MODAL_MODE_EDIT = MODAL_MODE_EDIT;
	public MODAL_MODE_ADD = MODAL_MODE_ADD;
	public FAILURE_MESSAGE: string = '';
	public memberAccount: any = {}; //Member-Acct object (used for mapping in UI)
	public accountForm: FormGroup;
	public stateObj: any = {}; //To send values to grid based on grid actions
	public serverErrors: any = {}; //Stores errors returned by server
	public submitting: boolean = false;
	private errors: any = '';
	public formErrors: any = {};
	public MEDIUM_CREDIT = MEDIUM_CREDIT;

	//Payment-type variables
	public showExpDate: boolean = false; //Show/Hide Exp-Date based on payment-mode
	public showEcheck: boolean = false; //Show/hide Echeck depending on payment-type
	public expiryDate: Date[];
	public yearObjArray: any[] = []; //Store 10 years from currnet year
	public months: any[] = []; //Store months
	public acctTypes: any[] = [
		{ value: 'savings', type: 'Savings' },
		{ value: 'checking', type: 'Checking' }
	]; //temporary account types
	private setDepositAcct: boolean = false; //Set value of storeID if true
	private setPaymentAcct: boolean = false; //Set id of customerID if true

	//Variables related to pctr
	public pctrs: any[] = []; //Profit-centers fetched from Store-ID to choose while creating member-account
	public xGMID: string = '';
	public xGMPW: string = '';
	public xGTID: string = '';
	public xTerminalID: string = '';
	public defaultPCTR: string = 'Select A Profit Center';
	public defaultTerminal: string = 'Select A Terminal';
	public terminalsArray: any[] = [];
	public PCTR_UNAVAILABLE_MESSAGE = PCTR_UNAVAILABLE_MESSAGE;

	public canAdd = true; //boolean controller for enable/disabling proceed btn
	public canEdit = true; //boolean controller for enable/disabling save btn

	//Customer specific variables
	private customerNum: string = '';
	private customerName: string = '';
	private customerData: any = {};
	public modalLoader: boolean = true;
	public proceedLoader: boolean = false;
	noPermMsg = NO_PERMISSION_MSG;
	private VTERM_HOST: string = ''; // contains vterm host url

	constructor(
		private fb: FormBuilder,
		private businessService: BusinessService,
		private notificationService: NotificationService,
		private memberAccountStore: MemberAccountStore,
		private setupStructureProfitCenterStore: SetupStructureProfitCenterStore,
		private setupStructureTerminalStore: SetupStructureTerminalStore,
		private setupBillingCustomerStore: SetupBillingCustomerStore,
		private tabStructureService: TabStructureService,
		private httpService: HttpService,
		private vtermConfig: VtermConfig,
		private userHandlerService: UserHandlerService,
		private vtermTokenService: VtermTokenService
	) {
		this.stateObj = {
			refreshGrid: false,
			showModal: false
		};
		this.VTERM_HOST = vtermConfig.VTERM_HOST;
		console.log('vtermConfig: host', this.VTERM_HOST);
	}

	async ngOnInit() {
		console.log('add-payment: onInit: First statement');
		this.memberAccount.medium = MEDIUM_ECHECK;
		this.memberAccount.brand = BRAND_ECHECK;
		this.setupPerms();
		await this.checkModeAndSetBaseParams();
		//If mode is add show ACH variables and set acct-name value to default
		if (this.modalMode === MODAL_MODE_ADD) {
			console.log('GOT HERE');
			this.showEcheck = true;
		}
		console.log(this.modalMode, 'mode: onInit: add-payment');
		if (this.modalMode === MODAL_MODE_EDIT) {
			this.editCaseHandler();
		}
	}

	//Edit Member-Account
	editCaseHandler(): void {
		console.log('im here in add-payment-accounts ngOnInit');
		this.memberAccountStore.get(this.memberAccountID).subscribe(
			(response: any) => {
				console.log('has data', !response.hasOwnProperty('data'), !response.data);
				if (!response.hasOwnProperty('data') || !response.data) {
					console.log('*************here in no data*********');
					this.modalLoader = false;
					this.hasNoData = true;
					this.FAILURE_MESSAGE = NO_MEMBER_ACCOUNT_FOUND_MESSAGE;
					return;
				}

				let xpcSafesResponseData = response.data;
				console.log('data: get member-account', xpcSafesResponseData);

				let pgcomResponse = this.getPgComData(xpcSafesResponseData);

				let ispgComSuccess = this.checkIfPgcomSuccess(pgcomResponse);
				console.log('ispgComSuccess--->', ispgComSuccess);
				if (!ispgComSuccess) {
					console.log('Came here : ispgComSuccess --->', ispgComSuccess);
					this.modalLoader = false;
					this.hasNoData = true;
					this.FAILURE_MESSAGE = NO_DATA_FOUND_MESSAGE;
					return;
				}
				this.hasNoData = false;

				this.memberAccount.medium = pgcomResponse.hasOwnProperty('Medium') && pgcomResponse.Medium ? pgcomResponse.Medium : '-';
				this.memberAccount.accountToken =
					xpcSafesResponseData.hasOwnProperty('xAcctTok') && xpcSafesResponseData.xAcctTok ? xpcSafesResponseData.xAcctTok : '-';
				this.memberAccount.maskedAccountToken =
					xpcSafesResponseData.hasOwnProperty('xAcctTok') && xpcSafesResponseData.xAcctTok
						? maskCardToken(xpcSafesResponseData.xAcctTok)
						: '-';
				this.memberAccount.accountNumber =
					pgcomResponse.hasOwnProperty('AccountNum') && pgcomResponse.AccountNum ? pgcomResponse.AccountNum : '-';
				this.memberAccount.brand =
					validateFor('xBrandTag', xpcSafesResponseData) && BRAND_TAGS_MAPPING[xpcSafesResponseData.xBrandTag]
						? BRAND_TAGS_MAPPING[xpcSafesResponseData.xBrandTag]
						: '-';

				this.setCommonFormValues(xpcSafesResponseData);
				this.setModalData(this.memberAccount.medium, xpcSafesResponseData);
				this.setPctrDisplayData(xpcSafesResponseData);
			},
			(error: any) => {
				this.onHideModal();
				this.handleErrorResponse(error);
			}
		);
	}

	//Check success field of pgcom data, if false, show no member-acct found message
	//return type: boolean
	//parameter: pgcomData(object)
	checkIfPgcomSuccess(pgComData: any): boolean {
		console.log('checkIfPgcomSuccess: response', pgComData);
		if (
			Object.keys(pgComData).length &&
			pgComData.hasOwnProperty(GWKEY_STATUS) &&
			pgComData[GWKEY_STATUS] &&
			pgComData[GWKEY_STATUS] === GWVAL_STATUS_OK &&
			pgComData.hasOwnProperty(PGCOMM_MEDIUM_KEY) &&
			pgComData[PGCOMM_MEDIUM_KEY]
		)
			return true;
		return false;
	}

	/**
	 * Extract pgComData from xpcSafesResponseData
	 * @param xpcSafesResponseData : `object` : response from  xpcSafes
	 * @return pgComData: `object`: pgComData extracted from xpcSafesResponseData
	 */
	getPgComData(xpcSafesResponseData) {
		let pgcomResponse =
			validateFor('pgcomResponse', xpcSafesResponseData) && validateFor('data', xpcSafesResponseData.pgcomResponse)
				? xpcSafesResponseData.pgcomResponse.data
				: {};
		console.log('pgcomResponse: getPgComData--->', pgcomResponse);
		return pgcomResponse;
	}

	//Set form fields and payload by checking medium.
	setModalData(medium: string, xpcSafesResponseData: any): void {
		let pgcomResponse = this.getPgComData(xpcSafesResponseData);
		switch (medium) {
			case MEDIUM_CREDIT:
				//if edit for card type
				this.fetchYears();
				this.fetchMonths();
				this.showEcheck = false;
				this.showExpDate = true;
				this.accountForm.addControl('xExpYear', new FormControl('', Validators.required));
				this.accountForm.addControl('xExpMonth', new FormControl('', Validators.required));
				this.setFormValuesForCard(pgcomResponse);
				break;
			case MEDIUM_ECHECK:
				this.showEcheck = true;
				this.showExpDate = false;
				this.memberAccount.routingNumber =
					pgcomResponse.hasOwnProperty('EleCheckRoutingNumber') && pgcomResponse.EleCheckRoutingNumber
						? pgcomResponse.EleCheckRoutingNumber
						: '';
				this.setFormValuesForACH(xpcSafesResponseData);
				break;
			case '-':
				//TODO-: ADD PROPER ERROR HANDLING with message.
				console.log('ERROR CASE -: NO MEDIUM');
				this.modalLoader = false;
				this.hasNoData = true;
				this.FAILURE_MESSAGE = NO_DATA_FOUND_MESSAGE;
				this.notificationService.error(NO_DATA_FOUND_MESSAGE, 'Error');
		}
	}

	/**
	 * Show pctr related data in edit mode.
	 * @param xpcSafesResponseData: object: safes Data
	 */
	setPctrDisplayData(xpcSafesResponseData: any = {}) {
		if (validateFor(DB_XSIGNUP_PCTR_ID_KEY, xpcSafesResponseData)) {
			let signupPCtrID = xpcSafesResponseData.xSignupPCtrID;
			let pctrObj = this.pctrs.find(pctr => pctr._id === signupPCtrID);
			this.memberAccount.pctrName = pctrObj.xName;
			this.setGMID(pctrObj.xGMID);
			this.setGMPW(pctrObj.xGMPW);
			this.accountForm.patchValue({ xSignupPCtrID: signupPCtrID });
			this.getTerminals([signupPCtrID]);
			console.log('setModalData: pctrName--->', this.memberAccount.pctrName);
		}
	}

	//Set type of account i.e deposit-acct or payment, if payment:- Set customer-name and customer-number. If Deposit account, fetch profit-center
	checkModeAndSetBaseParams(): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			let pctr_fetch_response;
			// try catch
			// proper error handling
			// USE loaders
			switch (this.member_account_parentMode) {
				case MEMBER_ACCOUNT_PARENT_MODE_CUSTOMER:
					console.log('.... CUSTOMER CASE ......');
					try {
						let signupStoreID = await this.setPaymentAccountBaseParams();
						if (!signupStoreID) {
							console.log('error: no signup store id');
							throw {
								_body: { message: 'Inconsistent Data. Can not add Member-Account.' }
							};
						}
						try {
							let pctrData = await this.getPCtr(signupStoreID);
							let pctrIDs = pctrData.map(pctr => pctr._id);
							if(this.modalMode === MODAL_MODE_ADD){
								this.getTerminals(pctrIDs);
							}
						} catch (err) {
							// this.notificationService.error(err, 'Error');
							this.handleErrorResponse(err);
							this.hasNoData = true;
							this.FAILURE_MESSAGE = PCTR_UNAVAILABLE_MESSAGE;
							console.log('error: no pctr: MEMBER_ACCOUNT_PARENT_MODE_CUSTOMER', err); //to be handled
						}
					} catch (err) {
						// this.notificationService.error('Error',err);
						this.handleErrorResponse(err);
						console.log('error: MEMBER_ACCOUNT_PARENT_MODE_CUSTOMER', err); //to be handled
					}
					console.log('CUSTOMER CASE HERE......');
					break;
				case MEMBER_ACCOUNT_PARENT_MODE_STORE:
					console.log('.... STORE CASE ......');
					this.setDepositAccountBaseParams();
					try {
						let pctrData = await this.getPCtr(this.member_account_parentID);
						let pctrIDs = pctrData.map(pctr => pctr._id);
						if(this.modalMode === MODAL_MODE_ADD){
							this.getTerminals(pctrIDs);
						}
					} catch (err) {
						// this.notificationService.error('Error',err);
						this.handleErrorResponse(err);
						this.hasNoData = true;
						this.FAILURE_MESSAGE = PCTR_UNAVAILABLE_MESSAGE;
						console.log('error: no pctr:MEMBER_ACCOUNT_PARENT_MODE_STORE ', err); //to be handled
					}
					console.log('STORE CASE HERE......');
					break;
				default:
					console.log('checkModeAndSetBaseParams: INVALID MEMBER_ACCOUNT_PARENT_MODE');
					break;
			}
			console.log('WHY HERE......');

			this.toFormGroup();
			// this.modalLoader = false;
			return resolve(true);
		});
	}

	//Get parent customer details
	//return type: Promise<string>(signupStoreID)
	setPaymentAccountBaseParams(): Promise<string> {
		return new Promise((resolve, reject) => {
			this.setPaymentAcct = true;
			let signupStoreID = '';
			this.setupBillingCustomerStore.get(this.member_account_parentID).subscribe(
				(customer: any) => {
					console.log('customer: onInit: add-payment', customer);
					let customerData =
						customer.hasOwnProperty('data') && customer.data && Object.keys(customer.data).length ? customer.data : {};
					this.customerData = customerData;
					this.customerName = customerData.hasOwnProperty('xName') && customerData.xName ? customerData.xName : '';
					this.customerNum =
						customerData.hasOwnProperty('xCustomerNum') && customerData.xCustomerNum ? customerData.xCustomerNum : '';
					signupStoreID =
						customerData.hasOwnProperty('xSignupStoreID') && customerData.xSignupStoreID ? customerData.xSignupStoreID : '';
					return resolve(signupStoreID);
				},
				err => reject(err)
			);
		});
	}

	//Set deposit accout mode
	setDepositAccountBaseParams(): void {
		console.log('inside else if: has storeID');
		console.log('storeID: onInit: add-payment', this.member_account_parentID);
		this.setDepositAcct = true;
	}

	//Hide tree and open modal
	ngAfterViewInit() {
		// console.log(this.addPaymentMethodModal, 'add payment method modal');
		//If tab is hidden, tree will be hidden. In tab watcher, showTree is set to false.
		Promise.resolve(null).then(() => {
			this.tabStructureService.setHideTab(true);
		});
		if (this.addPaymentMethodModal) {
			this.addPaymentMethodModal.show();
		}
	}

	//Fetch PCtr
	//Paramter: string
	//Return type: boolean(has pctr or not)
	getPCtr(id: string): Promise<any> {
		return new Promise((resolve, reject) => {
			console.log('getPCtr REACHED');
			let selectKey = 'xGMID,xGMPW,xName'; //Fields required from pctr to set in payload of member-accounts
			this.setupStructureProfitCenterStore.getAll({ xStoreID: id, selectKey: selectKey }).subscribe(
				(pctrs: any) => {
					console.log('getPCtr RESPONSE -:', pctrs);
					// this.modalLoader = false;
					if (pctrs.hasOwnProperty('data') && pctrs.data && pctrs.data.length) {
						console.log('pctrs: onInit: member-accounts', pctrs);
						this.pctrs = pctrs.data;
						return resolve(this.pctrs);
					} else {
						return reject(false);
					}
				},
				err => {
					console.log('err: member-accounts', err);
					// this.handleErrorResponse(err);
					return reject(err);
				}
			);
		});
	}

	//Set form controls
	toFormGroup() {
		this.accountForm = this.fb.group({
			xName: ['', [Validators.required]],
			xBusinessID: this.businessService.businessID.getValue(),
			xBrandTag: [TAGSCONSTS.brandTags.brandItemTags.genericElectronicCheck],
			xMdmTag: [TAGSCONSTS.mediumTags.mdmItemTags.electronicCheck],
			xSignupPCtrID: ['', [Validators.required]],
			xTerminalID: ['', [Validators.required]],
			GMID: [''],
			GMPW: [''],
			GTID: [''],
			zipCode: [''],
			customerState: [''],
			customerCity: [''],
			address: [''],
			xMaskedAcctNum: [''],
			source: SOURCE,
			xStatus: 1
		});
		if(this.modalMode === MODAL_MODE_ADD){
			//In add mode only form-control is set without value
			this.addACHModeFormControls('', '', '');
		}
		//If member-account of stores, set form with storeID else with customerID
		if (this.setDepositAcct) {
			console.log('setting with store');
			this.accountForm.addControl('xStoreID', new FormControl(this.member_account_parentID));
			console.log(this.accountForm.getRawValue(), 'form fields: patchValue');
		} else if (this.setPaymentAcct) {
			console.log('setting with cust');
			console.log('customer name', this.customerName, 'customer num', this.customerNum);
			this.accountForm.addControl('xCustomerID', new FormControl(this.member_account_parentID));
			this.accountForm.addControl('customerNum', new FormControl(this.customerNum));
			// this.accountForm.addControl('customerName', new FormControl(this.customerName));
			this.accountForm.addControl('customerName', new FormControl(''));
			console.log(this.accountForm.getRawValue(), 'form fields: patchValue');
		}
	}

	//Submit form values
	onSubmit() {
		this.submitting = true;
		// console.log('modal: onSubmit---->', modal);
		if (!this.checkFormValidity()) {
			this.submitting = false;
			return;
		}
		console.log('now should be allowed to submit');

		let formValues = Object.assign({}, this.accountForm.getRawValue());
		delete formValues.xTerminalID;
		if (this.modalMode === MODAL_MODE_EDIT) {
			this.editCaseSubmitHandler(formValues);
		} else {
			this.addCaseSubmitHandler();
		}
	}

	//Modify fields when form is submitted in edit mode
	//Parameters: form
	editCaseSubmitHandler(formValues: any): void {
		console.log('showEcheck true or false----->', this.showEcheck);

		//If pctr is not selected, throw error
		if (!this.xGMID.length) {
			this.submitting = false;
			this.serverErrors['xSignupPCtrID'] = PCTR_ERROR;
			return;
		}

		//Only in edit mode, send token value
		formValues['xAcctTok'] = this.memberAccount.accountToken;

		//If parent mode is customer, set customer-name from form
		if (this.setPaymentAcct) {
			formValues['customerName'] = formValues['xName'];
		}
		console.log('from values in edit mode: onSubmit: add-payment', JSON.parse(JSON.stringify(formValues)));
		this.submitting = true;
		let safeID = validateFor(DB_MONGO_ID_KEY, formValues) ? formValues[DB_MONGO_ID_KEY] : '';
		if (!safeID) {
			this.notificationService.error(RECORD_NOT_UPDATED + DB_MONGO_ID_KEY + PARAMTER_NOT_FOUND, ERROR_KEY);
			this.submitting = false;
			return;
		}
		this.memberAccountStore.update(safeID, formValues)
			.subscribe(safesUpdateResponse => {
			this.submitting = false;
				console.log('safesUpdateResponse: editCaseSubmitHandler', safesUpdateResponse);
				let pgComResponse = validateFor(PGCOM_RESPONSE_KEY, safesUpdateResponse) ? safesUpdateResponse[PGCOM_RESPONSE_KEY] : null;
				if (validateFor(BE_ERROR_KEY, safesUpdateResponse) && safesUpdateResponse[BE_ERROR_KEY]) {
					this.addEditFailureCaseHandler(pgComResponse, MODAL_MODE_EDIT);
					return;
				}
				this.stateObj['refreshGrid'] = true;
				this.sendStateObj.emit(this.stateObj);
				this.handleSuccessResponse();
			},
			safesUpdateError => {
				this.submitting = false;
				this.serverErrors = safesUpdateError.errors;
				this.handleErrorResponse(safesUpdateError);
				console.log('safesUpdateError: editCaseSubmitHandler', safesUpdateError);
		})
	}

	//Create new Member-Account
	addCaseSubmitHandler(): void {
		console.log('this.setPaymentAcct', this.setPaymentAcct);
		console.log('customer name', this.customerName, 'customer num', this.customerNum);
		console.log('form fields: add mode: on submit', this.accountForm.getRawValue());
		let formValues: any = {};
		formValues = this.accountForm.getRawValue();
		//If parent mode is customer, set customer-name from form
		if (this.setPaymentAcct) {
			formValues['customerName'] = formValues['xName'];
		}
		this.memberAccountStore.store(formValues).subscribe(
			(response: any) => {
				this.submitting = false;
				let pgComResponse = response.hasOwnProperty('pgComResponse') && response.pgComResponse ? response.pgComResponse : null;
				console.log('pgComResponse:addCaseSubmitHandler----> ', pgComResponse);
				if (response && response.hasOwnProperty('error') && response.error) {
					this.addEditFailureCaseHandler(pgComResponse, MODAL_MODE_ADD);
					return;
				}
				this.stateObj['refreshGrid'] = true;
				this.sendStateObj.emit(this.stateObj);
				console.log('success: onSubmit: add-payment', response);
				this.handleSuccessResponse();
			},
			(error: any) => {
				console.log(error, 'error: onSubmit: add-payment');
				this.serverErrors = error.errors;
				this.handleErrorResponse(error);
			}
		);
	}

	//Show Error if add/edit is failed
	addEditFailureCaseHandler(pgComResponse: any, mode: string): void {
		this.modalLoader = false;
		let errorText = pgComResponse.hasOwnProperty('ErrorText') && pgComResponse.ErrorText ? pgComResponse.ErrorText : '';
		console.log('FAILURE_MESSAGE:addCaseSubmitHandler----> ', this.FAILURE_MESSAGE);
		let message = mode === MODAL_MODE_ADD ? RECORD_NOT_ADDED : mode === MODAL_MODE_EDIT ? RECORD_NOT_UPDATED : '';
		this.notificationService.error(`${message}${errorText}.`, 'Error');
	}

	/**
	 * Set form values irrespective of payment mode which are common in both ACH and card
	 * @param xpcSafesResponseData: object: api response from xpcSafes
	 */
	setCommonFormValues(xpcSafesResponseData: any): void {
		console.log(JSON.parse(JSON.stringify(xpcSafesResponseData)), 'data: setCommonFormValues: add-payment');
		let pgcomResponse = this.getPgComData(xpcSafesResponseData);
		console.log('pgcomResponse:setCommonFormValues ', pgcomResponse);
		this.accountForm.addControl('_id', new FormControl(xpcSafesResponseData._id));
		this.accountForm.patchValue({
			xName: xpcSafesResponseData.hasOwnProperty('xName') && xpcSafesResponseData.xName ? xpcSafesResponseData.xName : '',
			xBrandTag:
				xpcSafesResponseData.hasOwnProperty('xBrandTag') && xpcSafesResponseData.xBrandTag ? xpcSafesResponseData.xBrandTag : '',
			xMdmTag: xpcSafesResponseData.hasOwnProperty('xMdmTag') && xpcSafesResponseData.xMdmTag ? xpcSafesResponseData.xMdmTag : '',
			zipCode: pgcomResponse.hasOwnProperty('CustomerZipCode') && pgcomResponse.CustomerZipCode ? pgcomResponse.CustomerZipCode : '',
			customerCity: pgcomResponse.hasOwnProperty('CustomerCity') && pgcomResponse.CustomerCity ? pgcomResponse.CustomerCity : '',
			customerState: pgcomResponse.hasOwnProperty('CustomerState') && pgcomResponse.CustomerState ? pgcomResponse.CustomerState : '',
			address: pgcomResponse.hasOwnProperty('CustomerAddress') && pgcomResponse.CustomerAddress ? pgcomResponse.CustomerAddress : ''
		});
	}

	/**
	 * If paymen method is ACH, set account-name, real-acct-number and routing-number
	 * @param xpcSafesResponseData: object: api response from xpcSafes
	 */
	setFormValuesForACH(xpcSafesResponseData: any): void {
		let pgcomResponse = this.getPgComData(xpcSafesResponseData);
		console.log(pgcomResponse, 'data: setFormValues: add-payment');
		let xRtgNum = validateFor(DB_XRTG_NUM_KEY, xpcSafesResponseData) ? xpcSafesResponseData[DB_XRTG_NUM_KEY]: '';
		let xRealAcctNum = validateFor(DB_XREAL_ACCT_NUM_KEY, xpcSafesResponseData) ? xpcSafesResponseData[DB_XREAL_ACCT_NUM_KEY]: '';
		let xAcctName = validateFor(PG_COM_ACCT_TYPE_KEY, pgcomResponse) ? pgcomResponse[PG_COM_ACCT_TYPE_KEY] : '';
		this.addACHModeFormControls(xRtgNum, xRealAcctNum, xAcctName);
	}

	/**
	 * If paymen method is CARD, set expiry year, month and Masked-acct-num in edit mode
	 * @param xpcSafesResponseData: object: api response from xpcSafes
	 */
	setFormValuesForCard(pgcomResponse: any) {
		console.log(pgcomResponse, 'data: setFormValuesForCard');
		let month = 12;
		let year = parseInt(
			new Date()
				.getFullYear()
				.toString()
				.substr(-2)
		);
		let hasDate = pgcomResponse.hasOwnProperty('ExpDate') && pgcomResponse.ExpDate;
		if (hasDate) {
			let expDate = pgcomResponse.ExpDate;
			expDate = pgcomResponse.ExpDate.length === 3 ? '0' + pgcomResponse.ExpDate : pgcomResponse.ExpDate;
			month = parseInt(expDate.substring(0, 2));
			year = parseInt(expDate.substring(2));
		}

		console.log('month', month, 'year', year);
		this.accountForm.patchValue({
			xExpMonth: month || 12,
			xExpYear: year || parseInt(JSON.stringify(new Date().getFullYear()).substring(2)),
			xMaskedAcctNum: pgcomResponse.hasOwnProperty('AccountNum') && pgcomResponse.AccountNum ? pgcomResponse.AccountNum : ''
		});
	}

	//Set years for card edit type from current year upto 20 years
	fetchYears() {
		let currYear = new Date().getFullYear();
		let maxYear = currYear + 20;
		while (currYear <= maxYear) {
			this.yearObjArray.push({
				year: JSON.stringify(currYear),
				value: parseInt(currYear.toString().substr(-2))
			});
			currYear = currYear + 1;
		}
		console.log('yearObjArray===>', JSON.parse(JSON.stringify(this.yearObjArray)));
	}

	//Set months for card edit type
	fetchMonths() {
		this.months = MONTHS_ARRAY;
	}

	//When payment method is canceled, close modal
	onCancel(event: Event) {
		event.preventDefault();
		// this.addPaymentMethodModal.hide();
		this.onHideModal();
	}

	//Delete Errors when user selectes from drop-down or enters form value
	clearErrors(errorName: string): void {
		console.log('errorName: clearErrors: add-payment', errorName);
		// console.log('clearError: form status', this.accountForm)
		console.log(
			'has required error---->',
			this.accountForm.get('xName').hasError('required'),
			'is touched---->',
			this.accountForm.get('xName').touched
		);
		// this.accountForm.controls[errorName].markAsUntouched(); //This was implemented to remove error when focus event is triggered.
		if (this.serverErrors[errorName]) {
			delete this.serverErrors[errorName];
		}
	}

	//Set and send StateObj which is sent to member-accounts list component
	onHideModal() {
		// console.log('onHideModal: event -->', event);
		this.addPaymentMethodModal.hide();
		this.stateObj['refreshGrid'] = false;
		this.stateObj['showModal'] = false;
		this.sendStateObj.emit(this.stateObj);
	}

	//Fetch terminal of selected PCtr and set GMID, GTID and GMPW
	//Parameter: form event
	onChangePCtr(event: any) {
		this.clearErrors('xSignupPCtrID');
		//Reset terminal if pctr is changed
		this.accountForm.patchValue({ GTID: '' });
		this.accountForm.patchValue({ xTerminalID: '' });
		this.xGTID = '';
		if (!event['target'] || !event['target']['value']) {
			return;
		}
		let selectedPCtr = this.pctrs.find(pctr => pctr._id === event['target']['value']);
		if (!selectedPCtr.hasOwnProperty('_id') || !selectedPCtr._id) {
			return;
		}
		let GMID = validateFor(DB_GMID_KEY, selectedPCtr) ? selectedPCtr[DB_GMID_KEY] : '';
		let GMPW = validateFor(DB_GMPW_KEY, selectedPCtr)? selectedPCtr[DB_GMPW_KEY] : '';
		this.setGMID(GMID);
		this.setGMPW(GMPW);
		this.createTerminalDropDown(selectedPCtr[TERMINALS_KEY]);
		// console.log(event, 'event with default');
		// console.log('event: onchange: add-payment', event);
		// console.log('value: onchange: add-payment', event['target']['value']);
		// console.log('selected pctr:onChangePCtr--->', selectedPCtr);
		// console.log('pctr value: onChangePCtr', this.accountForm.get('xSignupPCtrID').value);
		// console.log('gmid', this.xGMID, 'gmpw', this.xGMPW, 'filtered pctr', selectedPCtr);
	}

	/**
	 * Set GMID for UI and form
	 * @param {string} GMID
	 * @memberof AddPaymentComponent
	 */
	setGMID(GMID: string){
		if(!GMID){
			this.onHideModal();
			this.notificationService.error(COULD_NOT_SET_GMID_ERR_MSG, ERROR_KEY);
			return;
		}
		this.xGMID = GMID;
		this.accountForm.patchValue({ GMID: this.xGMID });
	}

	/**
	 * Set GTID for UI and form
	 * @param {string} GTID
	 * @memberof AddPaymentComponent
	 */
	setGTID(GTID: string){
		if(!GTID){
			this.onHideModal();
			this.notificationService.error(COULD_NOT_SET_GTID_ERR_MSG, ERROR_KEY);
			return;
		}
		this.xGTID = GTID;
		this.accountForm.patchValue({ GTID: this.xGTID });
	}

	/**
	 * Set GMPW for UI and form
	 * @param {string} GMPW
	 * @memberof AddPaymentComponent
	 */
	setGMPW(GMPW: string){
		if(!GMPW){
			this.onHideModal();
			this.notificationService.error(COULD_NOT_SET_GMPW_ERR_MSG, ERROR_KEY);
			return;
		}
		this.xGMPW = GMPW;
		this.accountForm.patchValue({ GMPW: this.xGMPW });
	}

	/**
	 * Add ACH mode required form-controls to form
	 * @param `xRtgNum`: string: Routing Number of member-account in ACH mode 
	 * @param `xRealAcctNum`: string: Acct Number of member-account in ACH mode 
	 * @param `xAcctName`: string: Acct Name (Savings or Checking)
	 */
	addACHModeFormControls(xRtgNum: string, xRealAcctNum: string, xAcctName: string){
		this.accountForm.addControl(DB_XRTG_NUM_KEY, new FormControl(xRtgNum, Validators.required));
		this.accountForm.addControl(DB_XREAL_ACCT_NUM_KEY, new FormControl(xRealAcctNum, Validators.required));
		this.accountForm.addControl(DB_XACCT_NAME_KEY, new FormControl(xAcctName));
	}

	//Set account type
	//Parameter: selection event
	onChangeAcctType(event: any) {
		this.clearErrors('xAcctName');
		let xAcctName = event['target']['value'];
		this.accountForm.patchValue({ xAcctName: xAcctName });
	}

	//Check for valid form before API call, set errors if any error exists
	checkFormValidity() {
		let hasError = false;
		Object.keys(this.accountForm.getRawValue()).map(key => {
			console.log('key: account form', key);
			console.log('key', key, 'hasError', this.accountForm.get(key).hasError('required'));
			if (this.accountForm.get(key).hasError('required')) {
				console.log('has error required');
				this.accountForm.get(key).markAsTouched();
				hasError = true;
			} 
		});
		console.log('has error: checkFormValidity--->', hasError);
		if (hasError) {
			let message = this.modalMode === MODAL_MODE_EDIT ? 'Updated' : 'Added';
			this.notificationService.error(`Record Not ${message}`, 'Please fill all the required Details');
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Set terminal array for dropdown
	 * @param `terminalsArray`: Array of terminal objects
	 */
	createTerminalDropDown(terminalsArray: any[] = []) {
		console.log('createTerminalDropDown: terminalArray--->', terminalsArray);
		if(!terminalsArray.length){
			this.onHideModal();
			this.notificationService.error(TERMINAL_UNAVAILABLE_MESSAGE, ERROR_KEY);
			return;
		}
		this.terminalsArray = terminalsArray;
		let terminalID = 
			validateFor(DB_MONGO_ID_KEY, terminalsArray[0])
			? terminalsArray[0][DB_MONGO_ID_KEY]
			: '';
		if(!terminalID){
			this.onHideModal();
			this.notificationService.error(TERMINAL_UNAVAILABLE_MESSAGE, ERROR_KEY);
			return;
		}
		this.accountForm.patchValue({ xTerminalID: terminalID });
		let GTID = 
			validateFor(XPCTERMINALS.xGTID, terminalsArray[0]) 
			? terminalsArray[0][XPCTERMINALS.xGTID]
			: '';
		this.setGTID(GTID);
		this.modalLoader = false;
		console.log('loader false from createTerminalDropDown');
	}

	/**
	 * Get terminals under a profit-center
	 * @param {array} pctrIDs: mongoIDs of pctr
	 */
	getTerminals(pctrIDs: string[]) {
		if (!pctrIDs.length) {
			console.log('getTerminals: no pctrIDs');
			this.modalLoader = false;
			this.notificationService.error(PCTR_ID_UNAVAILABLE_MESSAGE, ERROR_KEY);
			return;
		}
		console.log('pctrIDs: getTerminalData', pctrIDs);
		let terminalQuery = {
			noGrid: true,
			pctrIDs: pctrIDs,
			selectKey: `${XPCTERMINALS.xName},${XPCTERMINALS.xGTID},${XPCTERMINALS.xMainPCtrID}`
		}
		this.setupStructureTerminalStore.getAll(terminalQuery).subscribe(terminalsResp => {
			console.log('terminalsResp: getTerminalData', terminalsResp);
			let terminalsData = 
				validateFor(BE_DATA_KEY, terminalsResp) && terminalsResp.data.length 
				? terminalsResp.data
				: [];
			this.updatePCtrData(terminalsData);
		}, terminalsErr => {
			console.log('err: onChange: add-payment', terminalsErr);
			this.modalLoader = false;
			this.handleErrorResponse(terminalsErr);
		})
	}

	/**
	 * Modify pctr data to include terminals under it.
	 * If there is no terminal under a pctr, dont add it to pctr array.
	 * @param {any[]} terminalsData Terminals array
	 * @memberof AddPaymentComponent
	 */
	updatePCtrData(terminalsData: any[]){
		let finalPCtrArr = [];
		this.pctrs.forEach(pctr => {
			pctr.terminals = 
				terminalsData.filter(terminal => pctr._id === terminal.xMainPCtrID);
			if(pctr.terminals.length){
				finalPCtrArr.push(pctr);
			}
		})
		if(!finalPCtrArr || !finalPCtrArr.length){
			this.onHideModal();
			//Show terminal unavailabel message as pctrs were not added to array because
			//no terminal was present under it
			this.notificationService.error(TERMINAL_UNAVAILABLE_MESSAGE, ERROR_KEY);
			return;
		}
		this.pctrs = finalPCtrArr;
		let pctrID = 
			validateFor(DB_MONGO_ID_KEY, this.pctrs[0])
			? this.pctrs[0][DB_MONGO_ID_KEY]
			: '';
		if(!pctrID){
			this.onHideModal();
			this.notificationService.error(PCTR_ID_UNAVAILABLE_MESSAGE, ERROR_KEY);
			return;
		}
		this.accountForm.patchValue({ xSignupPCtrID: pctrID });
		let GMID = 
			validateFor(DB_GMID_KEY, this.pctrs[0]) 
			? this.pctrs[0][DB_GMID_KEY]
			: '';		
		this.setGMID(GMID);
		let GMPW = 
			validateFor(DB_GMPW_KEY, this.pctrs[0]) 
			? this.pctrs[0][DB_GMPW_KEY]
			: '';		
		this.setGMPW(GMPW);
		this.createTerminalDropDown(this.pctrs[0].terminals);
	}

	/**
	 * Set GTID in form data if GTID is available, else reset terminalID and show error
	 * @param `event`: form event
	 */
	onChangeTerminalDropdown(event: Event) {
		this.clearErrors(TERMINAL_ID_KEY);
		if (!(<HTMLInputElement>event.target).value) {
			this.accountForm.patchValue({ GTID: '' });
			return;
		}
		let selectedTerminalObj = this.terminalsArray.find(terminal => terminal._id === (<HTMLInputElement>event.target).value);
		if (!validateFor(XPCTERMINALS.xGTID, selectedTerminalObj)) {
			this.accountForm.patchValue({ xTerminalID: '' });
			this.notificationService.error(GTID_UNAVAILABLE_MESSAGE, ERROR_KEY);
			return;
		}
		let GTID = selectedTerminalObj.xGTID;
		this.setGTID(GTID);
		console.log('event: onChangeTerminalDropdown--->', event);
		console.log('value: onChangeTerminalDropdown--->', (<HTMLInputElement>event.target).value);
		console.log('terminals: onChangeTerminalDropdown--->', this.terminalsArray);
		console.log('selected terminal: onChangeTerminalDropdown--->', selectedTerminalObj);
		console.log('account form: onChangeTerminalDropdown--->', this.accountForm.getRawValue());
	}

	//If error exists, show notification
	//Paramter: Eror object
	handleErrorResponse(error: any): void {
		console.log('error: handleErrorResponse--->', error);
		this.submitting = false;
		this.modalLoader = false;
		// this.FAILURE_MESSAGE = NO_DATA_FOUND_MESSAGE;
		if (error._body) {
			this.errors = JSON.parse(error._body).errors;
			this.notificationService.error(
				JSON.parse(error._body).message === 'Invalid data' ? 'Please fill all Required Details' : JSON.parse(error._body).message,
				JSON.parse(error._body).name === 'BadRequest' ? RECORD_NOT_ADDED : JSON.parse(error._body).name
			);
		}
	}

	//Show success notification and close modal
	//Parameter: Modal directive
	handleSuccessResponse(): void {
		if (this.modalMode === MODAL_MODE_EDIT) {
			this.notificationService.success('Record Successfully Updated.', 'Success!');
		} else {
			this.notificationService.success('Record Successfully Added.', 'Success!');
		}
		// this.addPaymentMethodModal.hide();
		this.onHideModal();
	}

	onProceed(){
		this.proceedLoader = true;
		console.log('onProceed:form.value',this.accountForm.getRawValue());
		const formValue = this.accountForm.getRawValue();
		if(!formValue.xSignupPCtrID){
			this.proceedLoader = false;
			this.notificationService.error(SELECT_PCTR_MSG,'Error');
			return;
		}
		if(!formValue.xTerminalID){
			this.proceedLoader = false;
			this.notificationService.error(SELECT_TERM_MSG,'Error');
			return;
		}
		this.vtermTokenService.generateVtermToken(formValue.xTerminalID,this.customerData)
			.then(tokenResponse => {
				console.log('onProceed:tokenResponse',tokenResponse);
				let url = this.VTERM_HOST + PARAM_URL_FIELD + tokenResponse;
				console.log('proceedToVterm: url', url);
				document.location.href = url;

			})
			.catch(tokenError => {
				console.log('onProceed:promiseError',tokenError);
				this.proceedLoader = false;
				this.notificationService.error(FAILED_VTERM_INFO_MSG,'Error');
			})
	}

	setupPerms(){
        this.canEdit = this.userHandlerService.userPerms.canEditSafe;
        this.canAdd = this.userHandlerService.userPerms.canCreateSafe;
    }
}