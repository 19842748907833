import { Injectable } from '@angular/core';
 
import { HttpService } from '../shared/services/index';

const endPoint = 'xscRoles';

@Injectable()
export class VaultStore {
    static VaultData: any[];
    protected gridProps: any = {};

    static toggle(element: any): void {}

    constructor(protected httpService: HttpService) {}

    getAll(params: any): any {
        return this.httpService.getAll(endPoint, params);
    }

    store(element: any): any {
        return this.httpService.store(endPoint, element);
    }

    update(elementID: string, newElement: any): any {
        return this.httpService.update(endPoint, elementID, newElement);
    }

    get(elementID: any): any {
        return this.httpService.get(endPoint, elementID);
    }

    destroy(elementID: any): any {
        return this.httpService.destroy(endPoint, elementID);
    }
    getViewGridProps(): any {
        const editLink = '';
        const viewLink = '';
        this.gridProps.action = [
            { type: 'edit', visible: false, text: 'Edit', icon: 'fa fa-edit', action: 'url', onClick: editLink },
            { type: 'delete', visible: false, text: 'Delete', icon: 'fa fa-trash', action: 'func', onClick: 'destroy' },
            { type: 'toggle', visible: false, text: 'Activate/De-Activate', icon: 'fa fa-toggle-on', action: 'func', onClick: 'toggle' },
            { type: 'view', visible: false, text: 'Details', icon: 'fa fa-info-circle', action: 'func', onClick: viewLink }
        ];
        this.gridProps.storeValue = 'TransactionsViewStore';
        this.gridProps.attributes = [
            { header: 'First', mapTo: 'First' },
            { header: 'Last', mapTo: 'Last' },
            { header: 'Company', mapTo: 'Company' },
            { header: 'Card Mask', mapTo: 'Card_Mask' },
            { header: 'Exp Date', mapTo: 'Exp_Date' },
            { header: 'Store Date', mapTo: 'Store_Date' },
            { header: 'Mod Date', mapTo: 'Mod_Date' }
            //, {'header': 'Actions'       , 'mapTo': '' }
        ];
        return this.gridProps;
    }
}
