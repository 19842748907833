<div [formGroup]=generalContactForm>
    <div class="card">
        <div class="card-header">
            <label class="">Social</label>
        </div>
        <div class="card-body" formGroupName="xSocial">
            <!--<div class="form-group row">

            </div>-->
            <div class="form-group row">
                <!--<div class="col-md-6 col-sm-12">
                    <label for="phone">Phone <small class="text-danger">*</small></label>
                    <small *ngIf="formErrors.xSocial.xPhone" class="inline-error"> {{ formErrors.xSocial.xPhone }}</small>
                    <div class="input-group">
                        <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-mobile fa-fw" aria-hidden="true"></i></span>
                        <!--<input type="text" class="form-control form-control-sm" id="phone" formControlName="xPhone" [textMask]={mask:mask} [ngClass]="{'has-error': formErrors.xSocial.xPhone}" validateOnBlur>-->
                <!--<input type="text" class="form-control form-control-sm" id="phone" formControlName="xPhone" (keypress)="onKeypress($event)"
                            (paste)="onPaste($event)" [ngClass]="{'has-error': formErrors.xSocial.xPhone}" validateOnBlur>
                    </div>
                </div>-->
                <div class="col-md-6 col-sm-12">
                    <label for="website">Website: <!-- <small class="text-danger">*</small> --></label>
                    <small *ngIf="formErrors.xSocial.xWebsite" class="inline-error"> <i class="fa fa-exclamation-triangle inline-error" tooltip=" Website is required" placement="top" container="body" containerClass="tooltip-custom inline-error" aria-hidden="true"></i></small>
                    <div class="input-group">
                        <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-desktop fa-fw" aria-hidden="true"></i></span>
                        <input type="text" class="form-control form-control-sm" id="website" formControlName="xWebsite" [ngClass]="{'has-error': formErrors.xSocial.xWebsite}" validateOnBlur>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <label for="facebook">Facebook: </label>
                    <small *ngIf="formErrors.xSocial.xFacebook" class="inline-error"> <i class="fa fa-exclamation-triangle inline-error" tooltip=" Facebook is required" placement="top" container="body" containerClass="tooltip-custom inline-error" aria-hidden="true"></i></small>
                    <div class="input-group">
                        <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-facebook fa-fw" aria-hidden="true"></i></span>
                        <input type="email" class="form-control form-control-sm" id="facebook" formControlName="xFacebook" [ngClass]="{'has-error': formErrors.xSocial.xFacebook}" validateOnBlur>
                    </div>
                </div>
            </div>
            <div class="form-group row no-margin">
                <div class="col-md-6 col-sm-12">
                    <label for="twitter">Twitter: </label>
                    <small *ngIf="formErrors.xSocial.xTwitter" class="inline-error"> <i class="fa fa-exclamation-triangle inline-error" tooltip=" Twitter is required" placement="top" container="body" containerClass="tooltip-custom inline-error" aria-hidden="true"></i></small>
                    <div class="input-group">
                        <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-twitter fa-fw" aria-hidden="true"></i></span>
                        <input type="text" class="form-control form-control-sm" id="twitter" formControlName="xTwitter" placeholder="" [ngClass]="{'has-error': formErrors.xSocial.xTwitter}" validateOnBlur>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <label for="website">LinkedIn: </label>
                    <small *ngIf="formErrors.xSocial.xLinkedIn" class="inline-error"> <i class="fa fa-exclamation-triangle inline-error" tooltip="Linkedin is required" placement="top" container="body" containerClass="tooltip-custom inline-error" aria-hidden="true"></i></small>
                    <div class="input-group">
                        <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-linkedin
                        fa-fw" aria-hidden="true"></i></span>
                        <input type="text" class="form-control form-control-sm" id="website" formControlName="xLinkedIn" placeholder="" [ngClass]="{'has-error': formErrors.xSocial.xLinkedIn}" validateOnBlur>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>