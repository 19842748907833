import { Component, OnInit, Input } from '@angular/core';
import { LoginStoreService } from '../../../../shared/services';
import { skip, takeWhile } from 'rxjs/operators';

@Component({
    selector: 'app-store-info',
    templateUrl: './store-info.component.html',
    styleUrls: ['./store-info.component.scss']
})
export class StoreInfoComponent implements OnInit {
    storeObj: any = {};
    alive: boolean = true;
    fetchingData: boolean = false;

    constructor(private loginStoreService: LoginStoreService) {}

    ngOnInit() {
        this.loginStoreService.watchFetchedRelevantData$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(val => {
                console.log('store-info:init: val', val);
                console.log('store-info:init: store', this.loginStoreService.storeObj);
                if (val) {
                    this.storeObj = this.loginStoreService.storeObj;
                }
            });
    }

    ngOnDestroy() {
        this.alive = false;
    }
}
