<div class="user-detail card">
    <!-- <div class="card-header">
        <h4 class="card-title">
            <i class="fa fa-user" aria-hidden="true"></i>
            {{setupBillingCustomer?.xName | uppercase}}
        </h4>
        <button class="btn btn-secondary pull-xs-right" (click)="hideDetail()"> <i class="fa fa-times-circle" aria-hidden="true"></i></button>
    </div> -->
    <div class="detail-content">
        <div class="content-left">
            Name
        </div>
        <div class="content-right">
            {{setupBillingCustomer?.xName}}
        </div>
    </div>
     <div class="detail-content">
        <div class="content-left">
            Type
        </div>
        <div class="content-right">
            {{setupBillingCustomer?.xTypeTag}}
        </div>
    </div>
    <div class="detail-content">
        <div class="content-left">
            State
        </div>
        <div class="content-right">
            {{setupBillingCustomer?.xAddr?.xSOP}}
        </div>
    </div>
    <div class="detail-content">
        <div class="content-left">
            City
        </div>
        <div class="content-right">
            {{setupBillingCustomer?.xAddr?.xCity}}
        </div>
    </div>
    <div class="detail-content detail-content-last">
        <div class="content-left">
            Balance
        </div>
        <div class="content-right">
            {{setupBillingCustomer?.xBalance}}
        </div>
    </div>
</div>