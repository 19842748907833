import { Address } from './address.model';
import { Social } from './social.model';
import { MainContact } from './main-contact.model';

export class Chain {
    xTntTag: string;
    xWall1EntyID: string;
    xWall2EntyID: string;
    xTag: string;
    xStatus: number;
    xName: string;
    // xPhone: string;
    xTypeTag: string;
    xNotes: string;
    xRefNum: string;
    xTZTag: string;
    xSubName: string;
    xAddr: Address;
    xSocial: Social;
    xMainContact: MainContact;
    xBusinessID: string;
    xStructNum: string;

    constructor(obj?: any) {
        this.xTntTag = (obj && obj.xTntTag) || '';
        this.xWall1EntyID = (obj && obj.xWall1EntyID) || '';
        this.xWall2EntyID = (obj && obj.xWall2EntyID) || '';
        this.xTag = (obj && obj.xTag) || '';
        this.xStatus = (obj && obj.xStatus) || 1;
        this.xName = (obj && obj.xName) || '';
        // this.xPhone = (obj && obj.xPhone) || '';
        this.xTypeTag = (obj && obj.xTypeTag) || '';
        this.xNotes = (obj && obj.xNotes) || '';
        this.xRefNum = (obj && obj.xRefNum) || '';
        this.xTZTag = (obj && obj.xTZTag) || '';
        this.xSubName = (obj && obj.xSubName) || '';
        this.xAddr = (obj && new Address(obj.xAddr)) || new Address();
        this.xSocial = (obj && new Social(obj.xSocial)) || new Social();
        this.xMainContact = (obj && new MainContact(obj.xMainContact)) || new MainContact();
        this.xBusinessID = (obj && obj.xBusinessID) || '';
        this.xStructNum = (obj && obj.xStructNum) || '';
    }
}
