import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import * as apiConfig from '../config/api.config';
import { ApiConfig } from '../config/api.config';
import { map } from 'rxjs/operators';

@Injectable()
export class XpaConstsService {
    authHttp:any={};
    constructor(protected http: HttpClient,private apiConfig:ApiConfig) {
        // this.authHttp=new AuthHttp(new AuthConfig, http);
    }

    getXpaItems(tag: string): Observable<any> {
        const query = `item=${tag}`;
        return this.http
            .get(`${this.apiConfig.API_HOST}/api/xpaConsts/?${query}`)
            .pipe(
				map((response: Response) => {
					return response;
				})
			);
    }
}
