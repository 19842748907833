export class ProfitCenter {
    xTntTag: string;
    xWall1EntyID: string;
    xWall2EntyID: string;
    xTag: string;
    xStatus: number;
    xName: string;
    xTypeTag: string;
    xNotes: string;
    xRefNum: string;
    xBusinessID: string;
    xStoreID: string;
    xGMID:string;
    xGMPW:string;
    xIndstTag: string;
    xAttnTag: string;
    xPresTag: string;
    xPCtrNum: string;
    xShortCode: string;
    xEnableServiceChargeAmount: number;

    constructor(obj?: any) {
        this.xTntTag = (obj && obj.xTntTag) || ''; //Dummy
        this.xWall1EntyID = (obj && obj.xWall1EntyID) || ''; //Dummy
        this.xWall2EntyID = (obj && obj.xWall2EntyID) || '';
        this.xTag = (obj && obj.xTag) || 'PCtr';
        this.xStatus = (obj && obj.xStatus) || 1;
        this.xName = (obj && obj.xName) || '';
        this.xTypeTag = (obj && obj.xTypeTag) || ''; //Dummy
        this.xNotes = (obj && obj.xNotes) || '';
        this.xRefNum = (obj && obj.xRefNum) || '';
        this.xBusinessID = (obj && obj.xBusinessID) || '';
        this.xStoreID = (obj && obj.xStoreID) || '';
        this.xGMID = (obj && obj.xGMID) || '';
        this.xGMPW = (obj && obj.xGMPW) || '';
        this.xIndstTag = (obj && obj.xIndstTag) || '';
        this.xAttnTag = (obj && obj.xAttnTag) || '';
        this.xPresTag = (obj && obj.xPresTag) || '';
        this.xPCtrNum = (obj && obj.xPCtrNum) || '';
        this.xShortCode = (obj && obj.xShortCode) || '';
        this.xEnableServiceChargeAmount = (obj && obj.xEnableServiceChargeAmount) || 0;
    }
}
