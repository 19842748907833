import { Injectable } from '@angular/core';
import { HttpService } from './http-service';
import { validateFor } from '../../common-functions/index';
import { ACTION_WORKER } from '../modules/grid/grid.constants';

const ACTION_WORKER_ENDPOINT = 'action_worker_queues';
const ACTION_WORKER_PROGRESS_COMPLETE = 100;
const ACTION_WORKER_STATUS_FAIL = 'Failed';
const GENERAL_FALL_BACK_MSG = 'Something went wrong. Could not perform selected action. Please contact support.';
const MAX_FAIL_COUNT = 6;
const MAX_PENDING_FAIL_COUNT = 4;
const MAX_WAIT_TIME = 3000; //3 secs
const SAFE_POLLING_INTERVAL = 500; // interval for polling on action worker for safe deletion

@Injectable()
export class ActionWorkerService {
	constructor(private httpService: HttpService) {}

	/**
	 * poll action worker to check if action worker status is complete
	 *
	 * @params : `jobID` : string : action worker id on which to be polled
	 * @return : `Promise`
	 */
	pollActionWorker(jobID) {
		return new Promise((resolve, reject) => {
			console.log('pollActionWorker: jobID', jobID);
			let apiPending;
			let firstPollingAPICall = true;
			let failCount = 0;
			let pendingTime = 0;
			let pendingCount = 0;
			let queueTimer = setInterval(() => {
				if (validateFor('closed', apiPending) || firstPollingAPICall) {
					firstPollingAPICall = false;
					apiPending = this.httpService.get(ACTION_WORKER_ENDPOINT, jobID).subscribe(
						queueResponse => {
							console.log('pollActionWorker:queueResponse', queueResponse);
							failCount = 0;
							pendingTime = 0;
							pendingCount = 0;
							let actionProgress = validateFor(ACTION_WORKER.action_progress, queueResponse) ? queueResponse[ACTION_WORKER.action_progress] : '';
							let actionOutput = validateFor(ACTION_WORKER.action_output, queueResponse) ? queueResponse[ACTION_WORKER.action_output] : {};
							let actionMessage = validateFor(ACTION_WORKER.message, actionOutput) ? actionOutput[ACTION_WORKER.message] : '';
							let actionStatus = validateFor(ACTION_WORKER.status, queueResponse) ? queueResponse[ACTION_WORKER.status] : '';
							let actionResponseObj = validateFor(ACTION_WORKER.responseObj, actionOutput) ? actionOutput[ACTION_WORKER.responseObj] : {};
							let actionResponseMessage = validateFor(ACTION_WORKER.message, actionResponseObj) ? actionResponseObj[ACTION_WORKER.message] : {};
							console.log('pollActionWorker: actionProgress', actionProgress);
							console.log('pollActionWorker: actionOutput', actionOutput);
							console.log('pollActionWorker: actionMessage', actionMessage);
							console.log('pollActionWorker: actionStatus', actionStatus);
							console.log('pollActionWorker: actionResponseObj', actionResponseObj);
							console.log('pollActionWorker: actionResponseMessage', actionResponseMessage);
							if (actionProgress >= ACTION_WORKER_PROGRESS_COMPLETE && actionMessage) {
								let actionWorkerSuccess = true;
								clearInterval(queueTimer);
								if (actionStatus === ACTION_WORKER_STATUS_FAIL) {
									let errorMessage = actionMessage + '\n' + actionResponseMessage;
									if (!errorMessage) {
										errorMessage = GENERAL_FALL_BACK_MSG;
									}
									// this.notificationService.error(errorMessage, 'Error');
									actionWorkerSuccess = false;
								}
								let finalOutput = { responseObj: actionResponseObj, success: actionWorkerSuccess, message: actionMessage };
								return resolve(finalOutput);
							}
						},
						queueError => {
							console.log('pollActionWorker:queueError', queueError);
							let error = {
								message: GENERAL_FALL_BACK_MSG
							};
							failCount++;
							pendingTime = 0;
							pendingCount = 0;
							console.log('failcount', failCount);
							if (failCount >= MAX_FAIL_COUNT) {
								console.log('exit polling', failCount);
								clearInterval(queueTimer);
								return reject(error);
							}
						}
					);
				} else {
					console.log('pollActionWorker: skip call');
					let error = {
						message: GENERAL_FALL_BACK_MSG
					};
					pendingTime += SAFE_POLLING_INTERVAL;
					if (pendingCount >= MAX_PENDING_FAIL_COUNT) {
						console.log('API left in pending more than max allowed times [4]. Abort Polling', pendingCount);
						clearInterval(queueTimer);
						return reject(error);
					}
					if (pendingTime >= MAX_WAIT_TIME) {
						// MAX_WAIT_TIME = 3000
						console.log('API left in pending', pendingCount);
						apiPending.unsubscribe();
						pendingTime = 0;
						pendingCount++;
					}
					// return resolve(true);
				}
			}, SAFE_POLLING_INTERVAL);
		});
	}
}
