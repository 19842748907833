import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TransactionActionService {
    // doSettle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    doFinalize: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    doVoid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    doClose: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    doEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    doReverse: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    doSettle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    doRePrint: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    doRedo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    print:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);
    printDetail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    refreshData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // continuousFetch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    actionPerformed: BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);
    resetFilter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // txnConsts:any={};
    // brandConsts:any[]=[];
    // acctEntryConsts:any[]=[];



    // watchDoSettle$ = this.doSettle.asObservable(); //Watch settle
    watchDoFinalize$ = this.doFinalize.asObservable(); //Watch Finalize
    watchDoVoid$ = this.doVoid.asObservable(); //Watch Void
    watchDoClose$ = this.doClose.asObservable(); //Watch Close
    watchDoEdit$ = this.doEdit.asObservable(); //Watch Edit
    watchDoReverse$ = this.doReverse.asObservable(); //Watch Reverse

    watchDoSettle$ = this.doSettle.asObservable(); //Watch Settle
    watchDoRePrint$ = this.doRePrint.asObservable(); //Watch Re-Print
    watchDoRedo$ = this.doRedo.asObservable(); //Watch Re Auth
    watchPrint$ = this.print.asObservable(); //Watch Print

    watchPrintDetail$ = this.printDetail.asObservable(); //Watch PrintDetail
    watchRefreshData$ = this.refreshData.asObservable(); //Watch RefreshData
    // watchContinuousFetch$ = this.continuousFetch.asObservable(); //Watch continuous fetching of txn data
    watchActionPerformed$ = this.actionPerformed.asObservable(); //Watch whether action is performed or not, to show in grid.
    watchResetFilter$ = this.resetFilter.asObservable(); // Watch whether filters need to be reset.

    _settleSuccess: boolean=false;
    _settleFailed: boolean=false;
    _paginationModal:boolean=false;

    constructor() {}

    // setDoSettle(doSettle: boolean): void {
    //     this.doSettle.next(doSettle);
    // }

    set settleSuccess(settleSuccess:boolean){
        this._settleSuccess= settleSuccess;
    }

    get settleSuccess(){
        return this._settleSuccess;
    }

    set settleFailed(settleFailed:boolean){
        this._settleFailed= settleFailed;
    }

    get settleFailed(){
        return this._settleFailed;
    }

    get paginationModal(){
        return this._paginationModal;
    }

    set paginationModal(paginationModal: boolean){
        this._paginationModal= paginationModal;
    }
    
    setDoFinalize(doFinalize: boolean): void {
        this.doFinalize.next(doFinalize);
        // this.setContinuousFetch(false);
    }

    setDoVoid(doVoid: boolean): void {
        this.doVoid.next(doVoid);
        // this.setContinuousFetch(false);
    }

    setDoClose(doClose: boolean): void {
        this.doClose.next(doClose);
        // this.setContinuousFetch(false);
    }

    setDoEdit(doEdit: boolean): void {
        this.doEdit.next(doEdit);
        // this.setContinuousFetch(false);
    }

    setDoReverse(doReverse: boolean): void {
        this.doReverse.next(doReverse);
        // this.setContinuousFetch(false);
    }


    setDoSettle(doSettle: boolean): void {
        this.doSettle.next(doSettle);
        // this.setContinuousFetch(false);
    }

    setDoRePrint(doRePrint: boolean): void {
        this.doRePrint.next(doRePrint);
        // this.setContinuousFetch(false);
    }

    setDoRedo(doRedo: boolean): void {
        this.doRedo.next(doRedo);
        // this.setContinuousFetch(false);
    }

    setPrint(print: boolean): void {
        this.print.next(print);
    }
    
    setPrintDetail(printDetail: boolean): void {
        this.printDetail.next(printDetail);
    }

    setRefreshData(refreshData: boolean): void {
        console.log("refreshData==>", refreshData);
        this.refreshData.next(refreshData);
        // this.setContinuousFetch(false);
    }

    /**
    * update reset filter watcher with provided value
    *
    * @params : `reset` : boolean : value to be set
    */
    setResetFilter(reset: boolean): void{
        console.log('setResetFilter: reset',reset);
        this.resetFilter.next(reset);
    }

    // setContinuousFetch(continuousFetch: boolean): void {
    //     this.continuousFetch.next(continuousFetch);
    // }

    setActionPerformed(actionPerformed: boolean): void {
        console.log("HERE___>>", actionPerformed);
        this.actionPerformed.next(actionPerformed);
    }

    // fetchTxnConsts():Promise<any>{ // Fetches transaction items and card brand items

    //     return new Promise((resolve,reject)=>{

    //         Observable.forkJoin(
    //             this.xpaConstsService.getXpaItems('txnact'),
    //             this.xpaConstsService.getXpaItems('brand'),
    //             this.xpaConstsService.getXpaItems('acctent')
    //         )
    //         .subscribe(
    //             (res)=>{
    //                 console.log("Response of txn consts--------->", res);
    //                 let shortCodeArr =  Object.keys(SHORTCODES['txn']).map((ele)=>SHORTCODES['txn'][ele]);
    //                 res[0].data.filter((txnConst)=>shortCodeArr.includes(txnConst.xShortCode))
    //                 .map(
    //                     (ele)=>{
    //                         this.txnConsts[ele.xShortCode] = ele.xTag;
    //                     }
    //                 );
    //                 res[1].data.map((brandEle)=>{
    //                     let tempBrandContsObj:any={};
    //                     tempBrandContsObj[brandEle.xTag]=brandEle.xName;
    //                     this.brandConsts.push(tempBrandContsObj);
    //                 });
    //                 res[2].data.map((acctEntr)=>{
    //                     // console.log('acct entry obj', acctEntr);
    //                     let tempAcctEntrObj:any={};
    //                     tempAcctEntrObj[acctEntr.xTag]=acctEntr.xName;
    //                     this.acctEntryConsts.push(tempAcctEntrObj);
    //                 })
    //                 return resolve(res);
    //             },
    //             (err)=>{
    //                 this.notificationService.error('Unable to fetch transaction constants. Try reloading this page', 'Error');
    //                 return reject(err);
    //             }
    //         )
    //     });
    // }
}
