import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BatchActionService {
    doSettle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    watchDoSettle$ = this.doSettle.asObservable(); //Watch settle
    // watchDoFinalize$ = this.doFinalize.asObservable(); //Watch Finalize
    // watchDoVoid$ = this.doVoid.asObservable(); //Watch Void

    constructor() {}

    setDoSettle(doSettle: boolean): void {
        this.doSettle.next(doSettle);
    }
}
