import { Component, OnInit } from '@angular/core';
import { SetupStructurePeripheralStore } from '../../../../../store';
import {
	TreeService,
	UrlStoreService,
	BusinessService,
	TabStructureService,
	UserHandlerService,
	BreadcrumbService
} from '../../../../../shared/services';
import { Router } from '@angular/router';

@Component({
	selector: 'app-setup-structure-peripherals-list',
	templateUrl: './setup-structure-peripherals-list.component.html',
	styleUrls: ['./setup-structure-peripherals-list.component.scss']
})
export class SetupStructurePeripheralsListComponent implements OnInit {
	public attributes: any[] = [];
	public storeValue: string = '';
	public storeObject: any = {};
	public action: any[] = [];
	public parameters: any = {
		// noGrid: true
	};
	fetchByStore: boolean = true;
	// public detailVisible: boolean = true;
	public periphID: string = '';
	public transactionType: string = 'all';
	showDetails: boolean = false;
	setBizId: boolean = true;
	addBtn: boolean = false;
	tooltipMessage: string = 'Add Peripheral';

	public viewDetails: any[] = [
		{ header: 'Status', mapTo: 'xStatus' },
		{ header: 'Name ', mapTo: 'xName' },
		{ header: 'Type', mapTo: 'xTypeTag' },
		{ header: 'Tag', mapTo: 'xTag' },
		{ header: 'Notes', mapTo: 'xNotes' },
		{ header: 'ID', mapTo: '_id' }
		// { header: 'Current Value (Number)', mapTo: 'xNumVal' }
	];

	constructor(
		private router: Router,
		private setupStructurePeripheralStore: SetupStructurePeripheralStore,
		public treeService: TreeService,
		public urlStoreService: UrlStoreService,
		public businessService: BusinessService,
		private tabStructureService: TabStructureService,
		private userHandlerService: UserHandlerService,
		private breadcrumbService: BreadcrumbService
	) {
		//this.urlStoreService.swapUrl(router.url);
	}

	ngOnInit() {
		this.setBreadCrumbValue();
		this.treeService.isParent = false;
		this.tabStructureService.checkAndSetTabNameToDef(
			this.tabStructureService.tabMode,
			this.tabStructureService.isCancelClick,
			this.tabStructureService.isSubmitClick
		);
		//For self------>
		if (this.treeService.periphID.getValue()) {
			this.setBizId = false;
			this.router.navigate(['/dashboard/setup/business/structure/peripheral-list', this.treeService.periphID.getValue()]);
		}
		// End for self------->
		//For downward hierarchy------->
		if (this.treeService.businessID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Terminal to add Peripheral';
			console.log('here');
			// let tempObj = {
			//     business: "current",
			//     chain: "list",
			//     region: "list",
			//     property: "list",
			//     store: "list",
			//     pctr: "list",
			//     meracct: 'list',
			//     terminal: 'list',
			//     periph: 'list',
			// }
			// this.businessService.setTabStructureNames(tempObj);
		}
		if (this.treeService.chainID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Terminal to add Peripheral';
			this.parameters = {
				// xTypeTag: 'struct.property',
				struct_type: 'chain',
				structID: this.treeService.chainID.getValue()
			};
		}
		if (this.treeService.regionID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Terminal to add Peripheral';
			this.parameters = {
				// xTypeTag: 'struct.property',
				struct_type: 'region',
				structID: this.treeService.regionID.getValue()
			};
		}
		if (this.treeService.propertyID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Terminal to add Peripheral';
			this.parameters = {
				// xTypeTag: 'struct.property',
				struct_type: 'property',
				structID: this.treeService.propertyID.getValue()
			};
		}
		if (this.treeService.storeID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Terminal to add Peripheral';
			this.parameters = {
				// xTypeTag: 'struct.property',
				xStoreID: this.treeService.storeID.getValue()
			};
		}
		if (this.treeService.profitCenterID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Terminal to add Peripheral';
			this.parameters = {
				// xTypeTag: 'struct.property',
				pCtrID: this.treeService.profitCenterID.getValue()
			};
		}
		if (this.treeService.merchantAccID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Terminal to add Peripheral';
			this.parameters = {
				// xTypeTag: 'struct.property',
				merAcctID: this.treeService.merchantAccID.getValue()
			};
		}
		if (this.treeService.terminalID.getValue()) {
			this.setBizId = false;
			this.addBtn = true;
			this.tooltipMessage = 'Add Peripheral';
			this.parameters = {
				// xTypeTag: 'struct.property',
				xTerminalID: this.treeService.terminalID.getValue()
			};
		} else {
			if (this.setBizId) {
				this.addBtn = false;
				this.tooltipMessage = 'Please select a Terminal to add Peripheral';
				this.treeService.setId(this.businessService.businessID.getValue(), 'business');
			}
		}
		//End downward hierarchy------->

		this.setGridProperties();
	}

	setBreadCrumbValue(): void {
		const breadCrumb: any = {};
		breadCrumb.root = 'Setup';
		breadCrumb.child = 'Structure';
		breadCrumb.subChild = 'Peripheral';
		breadCrumb.subChildLink = null;
		breadCrumb.grandChild = 'Peripherals';
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	setGridProperties() {
		this.storeValue = 'SetupStructurePeripheralStore';
		this.storeObject = this.setupStructurePeripheralStore;
		// this.attributes = [
		//     { header: "Active", mapTo: "xStatus", width: "10%" },
		//     { header: "Name", mapTo: "xName", width: "20%" },
		//     { header: "Tag", mapTo: "xTag", width: "20%" },
		//     { header: "Type Tag", mapTo: "xTypeTag", width: "20%" },
		//     // { header: 'Medium', mapTo: 'xMdmTag', width: '10%' },
		//     // { header: 'SoP', mapTo: 'xStrVal', width: '10%' },
		//     { header: "Notes", mapTo: "xNotes", width: "20%" },
		//     { header: "Actions", mapTo: "", width: "10%" }
		// ];
		const editLink = '/dashboard/setup/business/structure/peripheral-list';
		this.action = [
			{
				type: 'view',
				visible: true,
				text: 'Details',
				icon: 'fa fa-eye',
				action: 'emit'
			}
			// { type: 'edit', visible: true, text: 'Edit', icon: 'fa fa-edit', action: 'url', onClick: editLink },
			// { type: 'delete', visible: true, text: 'Delete', icon: 'fa fa-trash', action: 'func', onClick: 'destroy'}
		];
		if (!this.userHandlerService.userRoles.isBusinessEmployee) {
			this.attributes = [
				{ header: 'Active', mapTo: 'xStatus', width: '10%' },
				{ header: 'Name', mapTo: 'xName', width: '20%' },
				{ header: 'Tag', mapTo: 'xTag', width: '20%' },
				{ header: 'Type Tag', mapTo: 'xTypeTag', width: '20%' },
				// { header: 'Medium', mapTo: 'xMdmTag', width: '10%' },
				// { header: 'SoP', mapTo: 'xStrVal', width: '10%' },
				{ header: 'Notes', mapTo: 'xNotes', width: '20%' },
				{ header: 'Actions', mapTo: '', width: '10%' }
			];
			this.action.push(
				{
					type: 'edit',
					visible: true,
					text: 'Edit',
					icon: 'fa fa-edit',
					action: 'url',
					onClick: editLink
				},
				{
					type: 'delete',
					visible: true,
					text: 'Delete',
					icon: 'fa fa-trash',
					action: 'func',
					onClick: 'destroy',
					disabled: !this.userHandlerService.userPerms.canDeleteSetup
				}
			);
		} else if (this.userHandlerService.userRoles.isBusinessEmployee) {
			this.attributes = [
				{ header: 'Active', mapTo: 'xStatus', width: '10%' },
				{ header: 'Name', mapTo: 'xName', width: '25%' },
				{ header: 'Tag', mapTo: 'xTag', width: '20%' },
				{ header: 'Type Tag', mapTo: 'xTypeTag', width: '20%' },
				// { header: 'Medium', mapTo: 'xMdmTag', width: '10%' },
				// { header: 'SoP', mapTo: 'xStrVal', width: '10%' },
				{ header: 'Notes', mapTo: 'xNotes', width: '25%' }
			];
		}
	}

	onActionRequest(obj: any) {
		if (obj.act.type === 'view') {
			console.log("I'm here bro", obj);
			this.periphID = obj.id;
			// this.detailVisible = true;
		}
	}

	onShowDetails(obj: any) {
		this.showDetails = obj;
	}

	viewClosed(visible: boolean) {
		if (!visible) {
			this.periphID = '';
			this.showDetails = false;
		}
	}
}
