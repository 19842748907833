<div class="modal fade" bsModal #configModal="bs-modal" [config]="{backdrop: 'true'}"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Configuration Data</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="configModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="config-data">
          <pre>{{configData}}</pre>
        </div>
      </div>
      <div class="modal-footer">
          <a href="#" class="btn btn-sm btn-primary" id="link" (click)="download($event)">
              <span> Download <i class="fa fa-download" aria-hidden="true"></i> </span>
          </a>
      </div>
    </div>
  </div>
</div>