import { environment } from '../../../environments/environment';
let Google_Key = {
	"GOOGLE_RECAPTCHA_SITE_KEY_HOST_LOCAL": "6Led86saAAAAACQBqATV14E2uBHbQz8polHiLyVU",
	"GOOGLE_RECAPTCHA_SITE_KEY_HOST_QA": "6Led86saAAAAACQBqATV14E2uBHbQz8polHiLyVU",
	"GOOGLE_RECAPTCHA_SITE_KEY_HOST_STAGING": "6Led86saAAAAACQBqATV14E2uBHbQz8polHiLyVU",
	"GOOGLE_RECAPTCHA_SITE_KEY_HOST_PRODUCTION": "6Lfe7uwaAAAAACmzUm8WCOreVUu2HzxPzWa2y2Jf"
}

let GoogleKey = Google_Key.GOOGLE_RECAPTCHA_SITE_KEY_HOST_LOCAL;
console.log('current Environment ---->', environment);
if (environment) {
    if(environment.production){
        GoogleKey = Google_Key.GOOGLE_RECAPTCHA_SITE_KEY_HOST_PRODUCTION;; 
    }
    else if(environment.staging){
        GoogleKey = Google_Key.GOOGLE_RECAPTCHA_SITE_KEY_HOST_STAGING;;
    }else if(environment.hmr){
        GoogleKey = Google_Key.GOOGLE_RECAPTCHA_SITE_KEY_HOST_QA;;
    }
}

// console.log("GOOGLE_RECAPTCHA_KEY:", GoogleKey);


export const GOOGLE_RECAPTCHA_KEY = GoogleKey;

