import { Component, OnInit /*, AfterContentChecked*/ } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import {
    BreadcrumbService,
    FilterService,
    UserHandlerService,
    UrlStoreService,
    LoginStoreService,
    HttpService,
    NotificationService
} from '../shared/services';
import { skip } from 'rxjs/operators';

// import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
/**
 *	This class represents the lazy loaded DashboardComponent.
 */

const customerRegistrationEndPoint = 'customer_context';

@Component({
    selector: 'dashboard-cmp',
    templateUrl: 'dashboard.component.html'
})
export class DashboardComponent implements OnInit /*, AfterContentChecked*/ {
    breadCrumbValue: any;
    modifyFilter: boolean;
    isCustomer: boolean;
    constructor(
        private router: Router,
        private breadcrumbService: BreadcrumbService,
        private filterService: FilterService,
        private userHandlerService: UserHandlerService,
        public urlStoreService: UrlStoreService, // private route: ActivatedRoute
        public loginStoreService: LoginStoreService,
        private httpService: HttpService,
        public notificationService: NotificationService
    ) {
        // console.log("Dashboard ran");
        this.breadCrumbValue = {};

        // router.events.filter(event => event instanceof NavigationEnd).subscribe((val: any) => {
        // 	//Executes on Route Change Event
        // 	this.breadCrumbValue = this.breadcrumbService.getBreadCrumb();
        // 	this.modifyFilter = this.filterService.getFilterValue();
        // 	console.log('Route Changed, New Breadcrumb Value is ========>', this.breadCrumbValue);
        // });

        this.breadcrumbService.watchbreadCrumbData$
            .pipe(
                skip(1)
            )
            .subscribe((value: any) => {
            this.breadCrumbValue = value;
        });
        // router.events
        // // .filter(event => event instanceof NavigationEnd)
        // .subscribe(e => {
        //   console.log('prev:', e);
        //   // this.previousUrl = e.url;
        // });
        // this.urlStoreService.previousUrl = this.urlStoreService.currentUrl;
        // console.log("current url dash", router.url);
        // this.urlStoreService.currentUrl = router.url;
    }

    ngOnInit() {
        this.isCustomer = this.userHandlerService.userRoles.isCustomer;
        console.log('isCustomer', this.isCustomer);
        this.breadcrumbService.setBreadCrumb({});
        this.filterService.setFilterValue(false);
        this.breadCrumbValue = this.breadcrumbService.getBreadCrumb();
        this.modifyFilter = this.filterService.getFilterValue();
    }
}
