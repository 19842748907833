import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
	setCreatePaymentResponse,
	setPaymentResponse,
	PAYMENT_MODE_PAYNOW,
	PAYMENT_MODE_CREATE_PAYMENT,
	transactionType,
	SUCCESS
} from '../../../../common-functions/process-token-data.function';
import { NotificationService } from 'app/shared/services';
import { validateFor } from '../../../../common-functions';
const INVALID_TOKEN_MESSAGE = 'Invalid Token.';
const MODAL_TITLE = {
	paymentResponse: 'Payment',
	createPaymentMethodResponse: 'Payment Method Creation'
}
@Component({
	selector: 'payment-token-response-modal',
	templateUrl: './token-response.component.html',
	styleUrls: ['./token-response.component.scss']
})
export class TokenResponseComponent implements OnInit {
	@ViewChild('tokenResponseModal')
	public tokenResponseModal: ModalDirective;
	@Input()
	public tokenData: any = {};
	@Output()
	public closeModalEvent = new EventEmitter<boolean>(); // event emitted when modal is closed

	//UI constants
	public SUCCESS: string = SUCCESS;
	public transactionType: any = transactionType;
	//UI variables
	public paymentResponse: any = {}; //Trasaction info
	public createPaymentResponse: any = {}; //Payment-Method registration info
	public showPaymentResponse: boolean = false;
	public showCreatePaymentResponse: boolean = false;
	public modalTitle: string = '';
	constructor(private notificationService: NotificationService) {}
	ngOnInit() {
		console.log('tokenResponse-init:tokenData', this.tokenData);
		let paymentMode =
			validateFor('requestPayload', this.tokenData) && validateFor('paymentMode', this.tokenData.requestPayload)
				? this.tokenData.requestPayload.paymentMode
				: null;

		switch (paymentMode) {
			case PAYMENT_MODE_PAYNOW:
				this.paymentResponse = setPaymentResponse(this.tokenData);
				this.showPaymentResponse = true;
				this.modalTitle = MODAL_TITLE.paymentResponse
				break;
			case PAYMENT_MODE_CREATE_PAYMENT:
				this.createPaymentResponse = setCreatePaymentResponse(this.tokenData);
				this.showCreatePaymentResponse = true;
				this.modalTitle = MODAL_TITLE.createPaymentMethodResponse;
				break;
			case null:
				this.notificationService.error(INVALID_TOKEN_MESSAGE, 'Error');
		}

		console.log('paymentMode: ngOnInit--->', paymentMode);
		console.log('paymentResponse: ngOnInit--->', this.paymentResponse);
		console.log('createPaymentResponse: ngOnInit--->', this.createPaymentResponse);
	}

	ngAfterViewInit() {
		this.tokenResponseModal.show();
	}

	/**
	 * close modal and emit closeModalEvent to inform parent of the same
	 *
	 */
	closeModal() {
		console.log('closePayNowModal');
		this.tokenResponseModal.hide();
		this.closeModalEvent.emit(true);
	}
}
