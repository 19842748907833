import { Component, OnInit } from '@angular/core';
import { UserHandlerService, LoginStoreService, HttpService, NotificationService, BusinessService } from '../../../../shared/services/index';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

const createPaymentMethodEndPoint = 'xpcSafes'
const NEW_PAYMENT_SUCCESS_MESSAGE = 'New payment method created.'; 
const NEW_PAYMENT_FAIL_MESSAGE = 'Failed to create new payment method.'; 

@Component({
    selector: 'app-create-new-payment-type',
    templateUrl: './create-new-payment-type.component.html',
    styleUrls: ['./create-new-payment-type.component.scss']
})
export class CreateNewPaymentTypeComponent implements OnInit {
    public createPayment: FormGroup;
    public yearArr: number[] = [];
    public entityData: any = {};

    constructor(
        private userHandlerService: UserHandlerService,
        private loginStoreService: LoginStoreService,
        private httpService: HttpService,
        public formBuilder: FormBuilder,
        public notificationService: NotificationService,
        private router: Router
    ) {}

    ngOnInit() {
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        for (let index = currentYear - 10; index < currentYear + 10; index++) {
            this.yearArr.push(index);
        }
        this.entityData = Object.keys(this.userHandlerService.userType).length ? this.userHandlerService.userType : {};
        this.initValidation();
    }

    // payment method form validation
    initValidation() {
        this.createPayment = this.formBuilder.group({
            creditCardNumber: ['', [Validators.required]],
            creditCardName: ['', [Validators.required]],
            expYear: ['', [Validators.required]],
            expMonth: ['', [Validators.required]]
        });
    }

    // called on new payment creation submit
    createNewPaymentModeSubmit(form: FormGroup) {
        console.log('createNewPaymentModeSubmit: submit called');
        console.log('createNewPaymentModeSubmit: storeID', this.loginStoreService.storeData._id);
        console.log('createNewPaymentModeSubmit: customer id', this.entityData._id);
        console.log('createNewPaymentModeSubmit: customer name', this.entityData.xName);
        console.log('createNewPaymentModeSubmit: businessID', this.loginStoreService.storeData.xBusinessID);
        console.log('createNewPaymentModeSubmit: storeObj', this.loginStoreService.storeObj);
        let formData = form.getRawValue();
        form.controls['creditCardNumber'].markAsTouched();
        form.controls['creditCardName'].markAsTouched();
        form.controls['expYear'].markAsTouched();
        form.controls['expMonth'].markAsTouched();
        console.log('createNewPaymentModeSubmit: formData', formData);
        if (!form.invalid) {
            let payload = {
                xName: this.entityData.xName 
                    ? this.entityData.xName
                    : '',
                xBusinessID: this.loginStoreService.storeData.xBusinessID 
                    ? this.loginStoreService.storeData.xBusinessID 
                    : '',
                xStoreID: this.loginStoreService.storeData._id 
                    ? this.loginStoreService.storeData._id
                    : '',
                xCustomerID: this.entityData._id
                    ? this.entityData._id
                    : '',
                xMdmTag: 'mdm.cc',
                xBrandTag: 'brand.cc.vs',
                xBankName: 'Placeholder_Bank',
                xAcctName: 'savings',
                xHolderName: formData.creditCardName
                    ? formData.creditCardName
                    : '',
                xRealAcctNum: formData.creditCardNumber
                    ? formData.creditCardNumber
                    : '',
                xExpYear: formData.expYear
                    ? formData.expYear
                    : 0,
                xExpMonth: formData.expMonth 
                    ? formData.expMonth
                    : 0 
            }
            console.log('createNewPaymentModeSubmit: payload',payload)
            form.disable();
            this.httpService.store(createPaymentMethodEndPoint,payload).subscribe(response=>{
                console.log('createNewPaymentModeSubmit: response',response);
                let responseData = response 
                    && response.hasOwnProperty('data') 
                    && response['data']
                    ? response['data']
                    : {};
                if(Object.keys(responseData).length && responseData.hasOwnProperty('success') && responseData['success']){
                    this.notificationService.success(NEW_PAYMENT_SUCCESS_MESSAGE,'Success')
                    form.enable();
                    this.routeToSelectPayment();
                } else{
                    this.notificationService.error(NEW_PAYMENT_FAIL_MESSAGE,'Error');
                    form.enable();    
                }
            },error=>{
                console.log('createNewPaymentModeSubmit: response',error);
                this.notificationService.error(NEW_PAYMENT_FAIL_MESSAGE,'Error');
                form.enable();
            })
            // let resetLoading = setTimeout(() => {
            //     console.log('createNewPaymentModeSubmit: reset loading');
            //     form.enable();
            //     this.routeToSelectPayment();
            // }, 5000);
            // ------------------------ uncomment these lines when using with api and remove above line ------------------------------------------------
            // form.reset();
        }
    }

    // called when user clicks cancel
    routeToPaymentForm() {
        this.router.navigate(['/dashboard/customer/pay-now']);
    }

    // called when user clicks save
    routeToSelectPayment() {
        this.router.navigate(['/dashboard/customer/pay-now/select-payment-method']);
    }
}
