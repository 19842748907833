import { SecCaps } from './sec-caps.model';
import { SysInfo } from './system-info.model';
import { ConnCaps } from './connection-caps.model';
import { EntrCaps } from './entry-caps.model';
import { OutpCaps } from './outout-caps.model';

export class Peripheral {
    xTntTag: string;
    xWall1EntyID: string;
    xWall2EntyID: string;
    xTag: string;
    xStatus: number;
    xName: string;
    xTypeTag: string;
    xNotes: string;
    xRefNum: string;
    xPeriphNum: string;
    xShortCode: string;
    xBusinessID: string;
    xStoreID: string;
    xTerminalID: string;
    // xDeviceID: string;
    xConnUrl: string;
    // xCertTag: string;
    // xEncTag: string;
    // xCertNum: string;
    xSecCaps: SecCaps;
    xHWSysInfo: SysInfo;
    xSWSysInfo: SysInfo;
    xConnCaps: ConnCaps;
    xEntrCaps: EntrCaps;
    xOutpCaps: OutpCaps;

    constructor(obj?: any) {
        this.xTntTag = (obj && obj.xTntTag) || 'tenantTag';
        this.xWall1EntyID = (obj && obj.xWall1EntyID) || '';
        this.xWall2EntyID = (obj && obj.xWall2EntyID) || '';
        this.xTag = (obj && obj.xTag) || '';
        this.xStatus = (obj && obj.xStatus) || '1';
        this.xName = (obj && obj.xName) || '';
        this.xTypeTag = (obj && obj.xTypeTag) || '';
        this.xNotes = (obj && obj.xNotes) || '';
        this.xRefNum = (obj && obj.xRefNum) || '';
        this.xPeriphNum = (obj && obj.xPeriphNum) || '';
        this.xShortCode = (obj && obj.xShortCode) || '';
        this.xBusinessID = (obj && obj.xBusinessID) || '';
        this.xStoreID = (obj && obj.xStoreID) || '';
        this.xTerminalID = (obj && obj.xTerminalID) || '';
        // this.xDeviceID = (obj && obj.xDeviceID) || '';
        this.xConnUrl = (obj && obj.xConnUrl) || '';
        this.xSecCaps = (obj && obj.xSecCaps) || new SecCaps();
        // this.xCertTag = (obj && obj.xCertTag) || '';
        // this.xEncTag = (obj && obj.xEncTag) || '';
        // this.xCertNum = (obj && obj.xCertNum) || '';
        this.xHWSysInfo= (obj && obj.xHWSysInfo) || new SysInfo(),
        this.xSWSysInfo= (obj && obj.xSWSysInfo) || new SysInfo(),
        this.xConnCaps = (obj && obj.xConnCaps) || new ConnCaps();
        this.xEntrCaps = (obj && obj.xEntrCaps) || new EntrCaps();
        this.xOutpCaps = (obj && obj.xOutpCaps) || new OutpCaps();
    }
}
