import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TransactionActionService } from 'app/shared/services';

@Component({
    selector: 'app-advanced-search',
    templateUrl: './advanced-search.component.html',
    styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {
    @Input() resetFilterForm: boolean = false;
    @Input() advancedFilterFormData: any = {};
    @Output() advanceSearchEvent = new EventEmitter<any>();
    @Input() storeValue: string = '';

    constructor(public transactionService: TransactionActionService) {}

    ngOnInit() {}

    onAdvanceSearchEvent($event) {
        this.advanceSearchEvent.emit($event);
        console.log('event: onAdvanceSearchEvent: advanced-search.component ', $event);
    }
}
