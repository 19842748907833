<section>
    <form class="form-horizontal customer-tabbed">
        <div class="grid-add-wrap overflow-hidden">
            <span class="pull-right tooltip-wrap-span" container="body" containerClass="tooltip-global" placement="auto"
                [tooltip]="tooltipMessage">
                <button class="btn btn-info btn-sm pull-right m-b-5 add-btn" (click)="onAddClick()" [disabled]="!isStoreSelected">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i> Add
                </button>
            </span>
            <grid-cmp
                [attributes]="attributes"
                [storeValue]="storeValue"
                [parameters]="parameters"
                [storeObj]="storeObject"
                [action]="action"
                [fetchByStore]="fetchByStore"
                (actionRequest)="onActionRequest($event)"
                (detailVisible)="onShowDetails($event)"
            ></grid-cmp>
            <ng-sidebar
                sidebarClass="customer-sidebar"
                #NgxAsidePanelRight 
                animate=true 
                position='right' 
                closeOnClickOutside='true' 
                (onClosed)='viewClosed()'
            >
                <div class="card h-100 mb-0" >
                    <div class='sidebar-header'>
                        <span>Customer Info</span>
                        <span class='pull-right'>
                            <a closeSidebar>
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </a>
                        </span>
                    </div>
                    <div class="card-header">
                        <h4 class="card-title">
                            <i class="fa fa-user" aria-hidden="true"></i> {{ customer?.xName | uppercase }}
                        </h4>
                    </div>
                    <div>
                        <app-customer-details [mode]=mode [customer]=customer></app-customer-details>
                    </div>
                </div>
            </ng-sidebar>
        </div>
    </form>
</section>
