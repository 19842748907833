import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { takeWhile, skip } from 'rxjs/operators';
import { forkJoin } from "rxjs";
import {
	NotificationService,
	BusinessService,
	TreeService,
	UserHandlerService,
	CompanyService,
	TransactionActionService,
	BatchActionService,
	StoreService,
	SummaryReportService,
	ConstantService,
	TabStructureService,
	HttpService,
	UrlStoreService,
} from '../../services';

import { LIMIT, TXN_MODE, BE_KEY, customSwal, STORE_NAMES, IMPORT_TXN, TYPES, NO_PERMISSION_MSG } from '../../../constants';

import { SetupSecurityUsersStore } from './../../../store/setup-security-users-store';
import { validateFor } from '../../../common-functions';
import { SetupBillingCustomerStore, SetupCompanyStore, SetupStructureTerminalStore } from 'app/store';
import { ActionWorkerService } from 'app/shared/services/action-worker.service';
import { API_END_POINT } from 'app/constants/api-end-points.constants';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {
	ACTION_WORKER,
	CHECK_TXN_BATCH_KEY_CLASS,
	CHECK_TXN_BATCH_CLASS,
	IMPORT_FILE_PROCESSING_STATUS_COMPLETED,
	REFRESH_GRID_STORES,
	GRID_ACTIONS,
	HIDE_SORT_KEYS,
	IMPORT_TXN_FILE_PORCESSING_STATUS_KEY,
	IMPORT_TXN_BTN_TOOLTIP,
	SWAL_TITLE_ARE_YOU_SURE,
	PCTR_DELETE_SWAL_TEXT,
	SWAL_WARNING_TEXT,
	SWAL_WARNING_INFO,
	OVERLAY_PERFORMING_DELETE_ACTION_TEXT,
	ASSOCIATED_TXN_CHECK_FAILED_MESSAGE,
	SWAL_OPERATION_FAILED,
	OVERLAY_CHECKING_ASSOCIATED_TXN_TEXT,
	OVERLAY_CHECKING_ASSOCIATED_CUSTOMER_TEXT,
	OVERLAY_FETCHING_REQUIRED_INFO_TEXT,
	SWAL_SAFE_FAIL_MSG,
	ROLE_DELETION_FAILED_MSG,
	ROLE_DELETION_NOT_ALLOWED,
	ROLES_LIST_URL,
	IS_PAID_WARNING,
	IS_SUSPENDED,
	IS_UNSUSPENDED,
	IS_DISABLE_BILLING,
	OVERLAY_PERFORMING_DISABLE,
	OVERLAY_PERFORMING_RUNNOW, 
	OVERLAY_PERFORMING_SUSPENDED,
	OVERLAY_PERFORMING_UNSUSPENDED,
	OVERLAY_PERFORMING_PAID,
} from './grid.constants';
import { setPermissions } from './helper-functions';

const IS_VENDOR = 'isVendor';
const IS_CUSTOMER = 'isCustomer';
const IS_DEALER = 'isDealer';
const IS_EMP = 'isBusinessEmployee';
const TRANSACTION_ACTION_SETTLE = 'settle';
const SWAL_DISPLAY_DELAY = 250;
const TRANSACTION_ACTION_RESPONSE_STATUS_FAIL = 'fail';
const TRANSACTION_ACTION_SETTLE_ERROR_TEXT_TYPE_AUTH = 'Auth';
const TRANSACTION_ACTION_SETTLE_ERROR_TEXT_TYPE_BAD = 'no good';
const DB_XACCT_TOKEN_KEY = 'xAcctTok';
const PARAM_PCTRID: string = 'xPCtrID';

const ERROR: string = 'Error';

const TRANSACTION_TAG_VERIFY_DISALLOWED_ACTIONS = ['finalize', 'edit', 'reverse', 'redo', 'void'];
const CAN_NOT_DELETE_NODE_MESSAGE_FIRST_PART: string = 'There are customers associated with this ';
const CAN_NOT_DELETE_NODE_MESSAGE_SECOND_PART: string = ' Please delete customers before deleting this ';
const CUSTOMER_CHECK_FAILED_MESSAGE: string = 'Delete failed. Could not validate associated customer. Please contact support.';
const UNABLE_TO_PERFORM_DELETE_ACTION_MSG: string = 'Unable to perform delete action. Please try again.';
const NO_ACCOUNT_TOKEN_PRESENT_ERROR_MSG: string = 'No Account Token present. Could not perform delete action.';

const CHECK_TXN_BATCH_FAILED_ERROR_MESSAGE: string = 'Could not perform delete action. Check for open Transactions and Batches failed. Please contact support.';
const ACTION_WORKER_FAIL_MSG = 'Unable to get status from action worker. Please contact support.';

//Store names
const SETUP_BUSINESS_STRUCTURE_STORE: string = 'SetupBusinessStructureStore';
const SETUP_BUSINESS_INFO_STORE: string = 'SetupBusinessInformationStore';
const SETUP_BUSINESS_STRUCTURE_STORE_SETUP_STORE: string = 'SetupBusinessStructureStoreSetupStore';
const SETUP_COMPANY_STORE: string = 'SetupCompanyStore';
const SETUP_STRUCUTRE_PCTR_STORE: string = 'SetupStructureProfitCenterStore';
const SETUP_PERMISSION_STORE: string = 'SetupPermissionsStore';
const MEMBER_ACCOUNT_STORE: string = 'memberAccountStore';
const TXNS_STORE: string = 'TransactionsStore'; //outstanding txns
const SETUP_SECURITY_ROLES_STORE = 'SetupSecurityRolesStore';
const SETUP_BILLING_STORE = STORE_NAMES.billingStore;
const SETUP_INVOICE_STORE = STORE_NAMES.invoiceStore;

//API end points
const DELETE_REPERCUSSION_LIST_END_POINT: string = 'delete-repercussion-lists';
const CHECK_TXN_BATCHES_END_POINT: string = 'checkTxnBatches';

const DB_KEY_XNAME: string = 'xName';

const STATE_FIELD_EXCEPTIONS = ['SetupCompanyStore', 'SetupBillingCustomerStore', 'SetupBusinessInformationStore'];

const IS_ROLE_IN_USE_KEY = 'isRoleInUse';
const FETCH_DEALERS_KEY = 'fetchDealers';
const COMPANY_CHANGE_URLS = [ROLES_LIST_URL]; //Set of URLs for which companyID is added in params

@Component({
	selector: 'grid-cmp',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild('selAllCheckbox')
	selAllCheckbox: any;
	@Input()
	attributes: any[] = [];
	@Input()
	storeValue: string;
	@Input()
	storeObj: any = {};
	@Input()
	action: any[] = [];
	@Input()
	report: boolean = false;
	@Input()
	billingReport: boolean = false;
	@Input()
	billingNew: boolean = false;
	@Input()
	tree_filter: string = null;
	// @Input() optional_filter: string = null;
	@Input()
	refreshGrid: boolean = false;
	@Input()
	resetFilters: boolean = false; // manages when all filters and sorts are to be reset
	// @Input() modifiedAction: boolean = false;
	@Input()
	billingIDForReport: string = '';
	@Input()
	fetchByStore: boolean = false;
	// @Input() fetchByCompany: boolean = false;
	@Input()
	checkValue: boolean = false;

	@Input()
	hasActive: boolean = false;

	@Input()
	parameters: any = {};
	@Input()
	filterType: string = '';
	@Input()
	sorting: boolean = true;
	@Input()
	isPaymentAccountEdit: boolean = false; //to reduce accounts grid size in customer add/edit page
	@Input()
	isDepositAccountEdit: boolean = false; //to reduce accounts grid size in store add/edit page
	// @Input() showLoader: boolean = false;
	@Input()
	showSearchBar: boolean = true; //To manage display of search-bar section and toggle gridView with normalView(hieght not fixed)
	@Output()
	actionRequest = new EventEmitter<any>();

	@Output()
	emitChecked = new EventEmitter<any>();
	@Output()
	detailVisible = new EventEmitter<any>();
	@Output()
	viewSummary = new EventEmitter<any>();
	@Output()
	newClick = new EventEmitter<any>();
	@Output()
	refresh = new EventEmitter<any>();
	@Output()
	reSync = new EventEmitter<any>();
	@Output()
	selectedTxnEvent = new EventEmitter<any>();
	@Output()
	dataIsLoaded = new EventEmitter<boolean>();
	@Output()
	actionIsCancelled = new EventEmitter<boolean>();
	@Output()
	emitCsvHistoryStructure = new EventEmitter();
	@Output()
	emitCsvHistoryImportResponse = new EventEmitter();
	@Output()
	pctrListEvent = new EventEmitter<any>();
	// @Output() overLayVisible=new EventEmitter<any>();

	@Input()
	txn_mode: string = TXN_MODE.NONE;

	@Input()
	showDetails: boolean = true;

	@Input()
	showDisabled: boolean = false;
	@Output()
	disabledVisible = new EventEmitter<any>();	
	disabledChecked: boolean = true;

	@Input()
	sumReportDataObj:any = {};
	
	sumReportDataInItFlag: boolean = false;

	public STRLENGTH: number = 30;
	// public topLevel: boolean= false;
	pFloat = parseFloat; //To use parseFloat() in html.
	data: any[] = [];
	originalData: any[] = [];
	hashedData: any[] = [];
	originalHashedData: any[] = [];
	metaData: any = {};
	originalMetaData: any = {};
	public loading: boolean = true;
	public showOverLay: boolean = false;
	keyword: string = '';
	params: any = {};
	originalParams: any = {};
	error: boolean = false;
	flag: boolean = false;
	errMsg: string = '';
	record: any = {};
	modifiedActionObj: any = {};
	obj: any = {};
	paginateId: string = '';
	enableDetails: boolean = false;
	resetAdvancedFilterForm: boolean = false;
	advancedFilterFormData: any = {};
	advancedFilterFormDataToBeReused: any = {};
	// enableRoute: boolean = false;
	// selectedRowId: string[] = [];
	alive: boolean = true;
	gridMessage: string = 'No Record Found';
	public actionPerformed: boolean = false;
	public actionIsPending: boolean = false;
	order: string[];
	disableViewBtn: boolean = true;
	disableReportBtn: boolean = true;
	disableRefreshButton: boolean = true;
	showAdvancedSearch: boolean = false;
	fetchedTxnConsts: boolean = false;
	timer: number = 0;
	removeLoading: boolean = false;
	searchParams: any[] = [];
	totalTxn: number = 0;
	totalTxnAmt: string = '$0.00';
	totalMerAmt: string = '$0.00';
	selTxn: number = 0;
	selTxnAmt: number = 0.0;
	selMerAmt: number = 0.0;
	totTxnSelTxnObj: any = {};
	originalTotTxnSelTxnObj: any = {};
	public viewPortItems: any[] = [];
	pendingRequest: any; //To store Pending Requests.
	refreshClick: boolean = false; //To determine whether to reset few states or not.
	sortedBy: string = '';
	sortInOrder: string = '';

	completeLoad: boolean = false; //for infinite scroll
	lastCheckedID: string = ''; 
	checkedItem: string[] = []; // contains the id of the transactions which are checked
	unCheckedItem: string[] = []; // contains the id of the transactions which are unchecked
	// _checkedItem: Observable<string[]> = Observable.of(this.checkedItem);
	txnPctrIds: string[] = []; //constains pcts ids of txns at respective location in checkedItem
	txnNumbers: string[] = []; //constains txnNumbers of txns at respective location in checkedItem chec
	amtsObjArr: any[] = []; //constains Amounts (base, tip and tax) of txns at respective location in checkedItem
	types: any[] = []; //contains the type of transaction or batch. Used in report generation
	checkedObj: any = {}; // contains the id array (checkedItem) and the action (settle,finalize,etc)
	checkedArray: string[] = [];
	updatedCheckedArray: string[] = [];
	// checkState: any[] = []; // state of each checkbox(mapped with id) for select all and deselect all
	saveCheckedArray: string[] = []; // state of each checkbox for select all and deselect all
	sel: string = 'sel_all'; // used to implement select all and deselect all
	selAll: boolean = false;
	desel: boolean = false;
	currentBizID: string = '';
	currentCompID: string = '';
	showFilter: boolean = false;
	showSelected: boolean = false;
	showSelectedCheckbox: boolean = false;
	showSelectedClicked: boolean = false;
	pctrList: any[] = [];

	singleSelectMode: boolean = true;
	isCheckAction: boolean = false;
	isPreviousActionSettle: boolean = false;

	showPctrDeleteModal: boolean = false; //Should or not show delete modal when delete icon is clicked
	deleteRepercussionData: any = {}; //Data to be displayed in delete modal
	pctrID: string = ''; //mongoID of pctr. Required when proceed is clicked from delete modal.
	STATE_FIELD_EXCEPTIONS: string[] = STATE_FIELD_EXCEPTIONS;
	noPermMsg = NO_PERMISSION_MSG;
	overlayText: string = '';
	enableImportTxns: boolean = false;
	showImportTxnModal: boolean = false;
	showImportBtn: boolean = false; //show import button to dealers only
	pCtrToBeDeleted: string = '';
	isCustomer: boolean = false; //to manage css class in customer login when show selected is clicked
	gridBtnsController = {};
	IMPORT_FILE_PROCESSING_STATUS_COMPLETED: string = IMPORT_FILE_PROCESSING_STATUS_COMPLETED;
	REFRESH_GRID_STORES = REFRESH_GRID_STORES;
	HIDE_SORT_KEYS = HIDE_SORT_KEYS;
	STORE_NAMES = STORE_NAMES;
	IMPORT_TXN_BTN_TOOLTIP: any = IMPORT_TXN_BTN_TOOLTIP;
	// typeInErrorText: string = 'Auth';
	// singleCheckedItemIndex: number = 0;

	disableExportSummary: boolean = false;

	constructor(
		private router: Router,
		public notificationService: NotificationService,
		public businessService: BusinessService,
		public companyService: CompanyService,
		public treeService: TreeService,
		public userHandlerService: UserHandlerService,
		private transactionActionService: TransactionActionService,
		private batchActionService: BatchActionService,
		private storeService: StoreService,
		private summaryReportService: SummaryReportService,
		private constantService: ConstantService,
		// public timerService: TimerService,
		private tabStructureService: TabStructureService,
		private httpService: HttpService,
		private setupSecurityUsersStore: SetupSecurityUsersStore,
		private setupBillingCustomerStore: SetupBillingCustomerStore,
		private terminalStore: SetupStructureTerminalStore,
		private actionWorkerService: ActionWorkerService,
		private setupCompanyStore: SetupCompanyStore,
		private urlStoreService: UrlStoreService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		console.log("changes I'm here in grid ngonchanges", changes);
		// console.log(changes.hasOwnProperty('billingIDForReport'), this.billingIDForReport)
		this.resetParams();
		this.toggleImportTxnsBtn();
		const roles = this.userHandlerService.userRoles;
		if(changes.hasOwnProperty('sumReportDataObj') && changes.sumReportDataObj.firstChange && changes.sumReportDataObj.currentValue.length > 0 && changes.sumReportDataObj.currentValue[0].hasOwnProperty('_id')){
			this.sumReportDataInItFlag = true;
		}
		if (changes.hasOwnProperty('refreshGrid') && !changes.hasOwnProperty('storeValue')) {
			// console.log("here changes.refreshgrid");
			if (changes['refreshGrid'].currentValue) {
				// this.data=[];
				// this.loading = true;
				this.setDefaultParams();
				console.log('GRID REFRESH EVENT, params -->', Object.assign({}, this.params));
				// console.log("here 9", this.refreshGrid);
				this.displayGrid(JSON.parse(JSON.stringify(this.params)));
				this.flag = true;
				// changes['refreshGrid'].currentValue=false;
			}
		}
		if (changes.hasOwnProperty('attributes') && changes.hasOwnProperty('action') && changes.hasOwnProperty('storeValue')) {
			if (changes.hasOwnProperty('fetchByStore')) {
				if (changes.fetchByStore.currentValue) this.params.xBusinessID = this.businessService.businessID.getValue();
				if (this.treeService.storeID.getValue()) {
					if (
						this.storeValue === 'BatchStore' ||
						this.storeValue === 'SettledBatchStore' ||
						this.storeValue === 'TransactionsStore' ||
						this.storeValue === 'SettledTransactionsStore' ||
						this.storeValue === 'SetupBusinessStructureStore' ||
						this.storeValue === 'SetupPaymentProcessingMerchantAccountStore' ||
						this.storeValue === 'SetupStructureTerminalStore' ||
						this.storeValue === 'SetupStructureProfitCenterStore' ||
						this.storeValue === 'SetupStructurePeripheralStore' ||
						this.storeValue === 'SetupBusinessStructureStoreSetupStore'
					) {
						// this.transactionActionService.fetchTxnConsts();
						// this.transactionActionService.fetchTxnConsts().then(res=>{
						//     // console.log("Response aaya hai1", JSON.parse(JSON.stringify(this.transactionActionService.txnConsts)),  JSON.parse(JSON.stringify(this.transactionActionService.acctEntryConsts)),  JSON.parse(JSON.stringify(this.transactionActionService.brandConsts)))
						//     // this.fetchedTxnConsts=true;
						//     this.displayGrid(JSON.parse(JSON.stringify(this.params)));
						// })
						// .catch(err=>{
						//     console.log("Error aaya hai");
						// });
						//TODO-: Temporarily added to remove tree_filter from params of transactions and batches. In future it will be required
					} else {
						// console.log("I'm here :D", this.storeValue);
						// this.params.tree_filter = this.treeService.storeID.getValue();
					}
					// this.params.tree_filter = this.treeService.storeID.getValue();
				}
				this.updateParam(); //For tab change in transaction and batches
			}

			// if (changes.hasOwnProperty('fetchByCompany')) {
			// 	this.params.companyID = this.companyService.companyID.getValue();
			// 	// this.params.tree_filter = this.treeService.storeID.getValue()? this.treeService.storeID.getValue(): null;
			// }
			// if (changes.hasOwnProperty('tree_filter')) {
			// 	if (this.tree_filter) {
			// 		this.params.tree_filter = this.tree_filter;
			// 	}
			// }
			// this.setDefaultParams();
			// console.log("here 10");
			this.displayGrid(JSON.parse(JSON.stringify(this.params)));
			this.flag = true;
		}
		// if (changes.hasOwnProperty('tree_filter') && !changes.hasOwnProperty('storeValue')) {
		// 	this.params.tree_filter = this.tree_filter;
		// 	this.setDefaultParams();
		// 	this.displayGrid(JSON.parse(JSON.stringify(this.params)));
		// 	this.flag = true;
		// }

		if (changes.hasOwnProperty('checkValue') && !changes.checkValue.firstChange) {
			this.setDefaultParams();
			if (this.checkValue) {
				this.params.xStatus = 1;
			}
			// console.log("here 11");
			this.displayGrid(JSON.parse(JSON.stringify(this.params)));
		}

		if (changes.hasOwnProperty('parameters') && !changes.parameters.firstChange) {
			// console.log("this.transactionActionService.actionPerformed.getValue here", this.transactionActionService.actionPerformed.getValue());
			if (!this.transactionActionService.actionPerformed.getValue()) this.resetShowSelectedWindowStates();
			console.log('Parameters changed', changes.parameters.currentValue, JSON.parse(JSON.stringify(this.parameters)));
			// this.setDefaultParams();
			// this.displayGrid(JSON.parse(JSON.stringify(this.params)));
			if (this.parameters && this.parameters.hasOwnProperty('refreshGrid')) {
				// console.log("Yahan aa bhi rha hai??");
				delete this.parameters.refreshGrid;
			}
			// console.log("this.params before", JSON.parse(JSON.stringify(this.params)));
			this.updateParam();
			// console.log("this.params after", JSON.parse(JSON.stringify(this.params)));
			this.displayGrid(JSON.parse(JSON.stringify(this.params)));
			this.flag = true;
		}
	}

	resetShowSelectedWindowStates() {
		console.log('***** business changed, re-setting states *****');
		this.showSelected = false;
		this.showSelectedCheckbox = false;
		this.checkedItem = [];
		this.unCheckedItem = [];
		this.singleSelectMode = true;
		this.showFilter = false;
		this.showAdvancedSearch = false;
		this.completeLoad = false;
		this.totalTxn = 0.0;
		this.totalMerAmt = '$0.00';
		this.totalTxnAmt = '$0.00';
		this.selTxn = 0.0;
		this.selTxnAmt = 0.0;
		this.selMerAmt = 0.0;
		this.checkedObj = {};
		this.checkedArray = [];
		this.summaryReportService.setCheckedObj([]);
		this.checkAll('desel_all');
	}

	setDefaultParams() {
		this.params.page = this.params.page ? this.params.page : 1;
		this.params.keyword = this.params.keyword ? this.params.keyword : null;
		this.params.limit = this.params.limit ? this.params.limit : LIMIT;
		this.singleSelectMode = true;
	}

	resetParams() {
		this.data = [];
		this.hashedData = [];
		this.completeLoad = false;
		this.params = {};
		this.params.page = this.params.page ? this.params.page : 1;
		this.params.keyword = this.params.keyword ? this.params.keyword : null;
		this.params.limit = this.params.limit ? this.params.limit : LIMIT;
		console.log('indside reset params -->', this.params);
	}

	setSortOrderToInitial(checkPreviousSorting: boolean = false) {
		if (!checkPreviousSorting) {
			// if (this.attributes.length > 1) {
			this.order = new Array(this.attributes.length).fill('none');
			// } else {
			//     this.order = new Array(this.attributes.length).fill("none");
			// }
		} else {
			console.log('Check this-->', this.attributes, this.sortedBy, this.sortInOrder);
			this.attributes.map((singleAttribute, index) => {
				if (singleAttribute.mapTo === this.sortedBy) {
					this.order = new Array(this.attributes.length).fill('none');
					this.order[index] = this.sortInOrder;
				}
			});
		}
	}
	//initiateBackgroundPolling

	ngOnInit() {
		console.log('this.userHandlerService.userData', JSON.parse(JSON.stringify(this.userHandlerService.userData)));
		this.gridBtnsController = setPermissions(this.storeValue, this.userHandlerService.userPerms, this.userHandlerService.userRoles);
		this.setSortOrderToInitial();
		const roles = this.userHandlerService.userRoles;
		this.showImportBtn = roles.isDealer || roles.isBusinessEmployee;
		this.isCustomer = roles.isCustomer;
		this.setWatchers(roles);

		for (let i = 0; i < this.action.length; i++) {
			switch (this.action[i].type) {
				case 'edit':
					this.action[i].icon = this.action[i].icon ? this.action[i].icon : 'fa fa-edit';
					break;
				case 'delete':
				case 'disable':
					this.action[i].icon = this.action[i].icon ? this.action[i].icon : 'fa fa-trash';
					break;
				case 'suspend':
					this.action[i].icon = this.action[i].icon ? this.action[i].icon : 'fa fa-ban';
					//@cary 
				case 'unsuspend':
					this.action[i].icon = this.action[i].icon ? this.action[i].icon : 'fa fa-unlock';
				case 'runnow':
					this.action[i].icon = this.action[i].icon ? this.action[i].icon : 'fa fa-angle-double-right';
				case 'notifyCustomer':
					this.action[i].icon = this.action[i].icon ? this.action[i].icon : 'fa fa-envelope';
				case 'payNow':
				case 'payInvoiceNow':
					this.action[i].icon = this.action[i].icon ? this.action[i].icon : 'fa fa-usd';
				case 'isPaid':
					this.action[i].icon = this.action[i].icon ? this.action[i].icon : 'fa fa-money';
				case 'toggle':
					this.action[i].icon_on = this.action[i].icon_on ? this.action[i].icon_on : 'fa fa-toggle-on';
					this.action[i].icon_off = this.action[i].icon_off ? this.action[i].icon_off : 'fa fa-toggle-off';
					break;
				case 'view':
					this.action[i].icon = this.action[i].icon ? this.action[i].icon : 'fa fa-info-circle';
					if (this.action[i].visible) {
						this.enableDetails = true;
					}
					break;
				// case 'route':
				// 	this.action[i].icon = this.action[i].icon ? this.action[i].icon : 'fa fa-info-circle';
				// 	if (this.action[i].visible) {
				// 		this.enableRoute = true;
				// 	}
				// 	break;
				default:
					this.action[i].icon = this.action[i].icon;
					break;
			}
		}

		for (let i = 0; i < this.attributes.length; ++i) {
			if (this.attributes[i].hasOwnProperty('mapTo')) {
				if (this.attributes[i].mapTo.split('.').length > 1) {
					this.attributes[i].nestedKey = true;
				} else {
					this.attributes[i].nestedKey = false;
				}
			}

			if (this.attributes[i].hasOwnProperty('header') && this.attributes[i].header === 'Sel. All') this.isCheckAction = true;
		}

		if (!this.flag) {
			console.log('flag is not set');
			this.setDefaultParams();
			// this.params.optional_filter = this.optional_filter;
			console.log(this.isPaymentAccountEdit, 'isPaymentAccountEdit: grid');
			console.log(this.isDepositAccountEdit, 'deposit account edit: grid');
			// if(!this.isAccountsEdit){
			this.params.xBusinessID = this.businessService.businessID.getValue();
			// }
			this.updateParam(); //For tab change in transaction and batches
			// console.log("here 4");
			this.displayGrid(JSON.parse(JSON.stringify(this.params)));
		} else {
			this.flag = false;
		}
	}

	/*
	 * set watchers based on roles
	 *
	 * @params: roles : Object : has isVendor,isBusinessEmployee,isCustomer, isDealer boolean values
	 */
	setWatchers(roles) {
		console.log('setWatchers: roles', roles);
		//When user type is vendor watch for company change
		let role = '';
		if (roles.hasOwnProperty('isVendor') && roles.isVendor) {
			role = IS_VENDOR;
		}
		if (roles.hasOwnProperty('isCustomer') && roles.isCustomer) {
			role = IS_CUSTOMER;
		}
		if (roles.hasOwnProperty('isDealer') && roles.isDealer) {
			role = IS_DEALER;
		}
		if (roles.hasOwnProperty('isBusinessEmployee') && roles.isBusinessEmployee) {
			role = IS_EMP;
		}

		switch (role) {
			case IS_EMP:
			case IS_DEALER:
				this.commonWatchersForEmpDealer();
			case IS_CUSTOMER:
				this.commonWatchersForCustEmpDealer();
				break;
			case IS_VENDOR:
				this.watchersForVendor();
				break;
			default:
				console.log('setWatchers: default case: role', role);
		}

		// if (roles.isVendor) {
		//     this.watchersForVendor();
		// }

		// if (roles.isCustomer || roles.isDealer || roles.isBusinessEmployee) {
		//     this.commonWatchersForCustEmpDealer();
		// }
		// //When user type is dealer watch for business change and set store as null
		// if (roles.isDealer || roles.isBusinessEmployee) {
		//     this.commonWatchersForEmpDealer();
		// }
		this.treeService.watchBusinessID$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((bizId: string) => {
				console.log('WATCH Business CALLED');
				if (!bizId) {
					// console.log("I'm here babe return");
					return;
				}
				//Import txns check is needed to avoid closing of tree on change of business
				if (this.params && this.params.xBusinessID && this.params.xBusinessID != bizId && this.storeValue != STORE_NAMES.importTransactionsStore) {
					this.resetShowSelectedWindowStates();
					this.treeService.setShow(true);
					this.toggleEnableTree(true);
				}
			});
	}

	/*
	 * set watchers for vendor role
	 */
	watchersForVendor() {
		console.log('watchersForVendor');
		this.currentCompID = this.companyService.companyID.getValue();
		this.companyService.watchCompanyID$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((compId: string) => {
				if (compId) {
					this.resetParams();
					// console.log('HERE COMPANY CHANGE EVENT', this.urlStoreService.currentUrl);
					this.currentCompID = compId;
					//Add companyID in params only when logged in as vendor and company is changed
					//This watcher runs for company add/edit as well and companyID should not be added in that case
					//for reps list its handled in displayGrid function
					if (COMPANY_CHANGE_URLS.includes(this.urlStoreService.currentUrl)) {
						this.params = Object.assign({}, this.params, { xCompanyID: this.currentCompID });
					}
					this.displayGrid(JSON.parse(JSON.stringify(this.params)));
				}
			});
	}

	/*
	 * common watchers for customer,emp,dealer
	 */
	commonWatchersForCustEmpDealer() {
		console.log('commonWatchersForCustEmpDealer');
		this.transactionActionService.watchPrintDetail$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((printDetail: boolean) => {
				this.isPreviousActionSettle = false;
				this.refreshClick = false;
				this.summaryReportService.print = false;
				if (printDetail) {
					// console.log("here 1");
					if (this.selAll) {
						if (this.unCheckedItem.length >= 0) this.checkedObj.ids = this.unCheckedItem;
						this.checkedObj['selAll'] = true;
						this.checkedObj['parameters'] = JSON.parse(JSON.stringify(this.params));
						this.checkedObj['totalRecords'] = JSON.parse(JSON.stringify(this.originalMetaData.totalRecords));
						this.checkedObj['action'] = 'printDetail';
					} else {
						this.checkedObj = {};
						this.checkedArray = [];
						this.checkedObj.ids = JSON.parse(JSON.stringify(this.checkedItem));
						this.checkedObj['txnNums'] = [];
						this.checkedObj['refNums'] = [];
						this.originalData.map((singleData) => {
							if (this.checkedObj.ids.length && this.checkedObj.ids.indexOf(singleData._id) >= 0) {
								this.checkedObj.txnNums.push(singleData.xTxnNum);
								this.checkedObj.refNums.push(singleData.xRefNum);
							}
						});
						this.checkedObj.action = 'printDetail';
						this.setCheckedObjInSummaryReportService();
						this.actionIsPending = true;
					}
					this.emitChecked.emit(this.checkedObj);
				} else {
					this.actionIsPending = false;
					if (this.types && this.types.length) this.summaryReportService.setCheckedObj(this.types);
				}
			});
		this.transactionActionService.watchRefreshData$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((refresh: boolean) => {
				this.isPreviousActionSettle = false;
				this.summaryReportService.print = false;
				console.log('Refresh data is called multiple times==>', refresh);
				if (refresh) {
					this.loading = true;
					this.data = [];
					this.hashedData = [];
					this.showSelected = false;
					this.completeLoad = false;
					if (!this.refreshClick) {
						this.onResetSelectionClick();
					}
					console.log('Original store and params', this.storeValue, this.storeObj, this.originalParams);
					this.params = JSON.parse(JSON.stringify(this.originalParams));
					this.params.page = 1;
					if (this.params.hasOwnProperty('txnIds')) delete this.params.txnIds;
					console.log('here in refresh watcher', this.params);
					this.displayGrid(JSON.parse(JSON.stringify(this.params)));
					this.flag = true;
				}
			});
		this.transactionActionService.watchActionPerformed$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((performAct: boolean) => {
				this.actionPerformed = performAct;
				console.log('HEREEEEEEE=>', performAct, this.checkedItem.length);
				if (this.actionPerformed) {
					this.checkedItem = [];
					this.showSelectedCheckbox = false;
				}
			});
		this.transactionActionService.watchResetFilter$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((reset: boolean) => {
				console.log('commonWatchersForCustEmpDealer: reset', reset);
				if (reset) {
					this.onCompleteResetClick(false);
				}
			});
	}

	/*
	 * common watchers for dealer, emp
	 */
	commonWatchersForEmpDealer() {
		console.log('commonWatchersForEmpDealer');
		this.transactionActionService.watchDoFinalize$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((doFinalize: boolean) => {
				this.isPreviousActionSettle = false;
				this.refreshClick = false;
				this.summaryReportService.print = false;
				if (doFinalize) {
					this.checkedObj.action = 'finalize';
					this.actionIsPending = true;
					if (this.selAll) {
						if (this.unCheckedItem.length >= 0) this.checkedObj.ids = this.unCheckedItem;
						this.checkedObj['selAll'] = true;
						this.checkedObj['parameters'] = JSON.parse(JSON.stringify(this.params));
						this.checkedObj['totalRecords'] = JSON.parse(JSON.stringify(this.originalMetaData.totalRecords));
						this.emitChecked.emit(this.checkedObj);
					} else {
						this.actionPerformed = false;
						// this.checkedObj = {};
						this.checkedObj = {
							ids: [],
							txnPctrIds: [],
							number: [],
							amount: [],
							action: 'finalize',
						};
						this.data.map((singleData) => {
							if (this.checkedItem.indexOf(singleData._id) >= 0) {
								const amtObj = {
									id: singleData._id,
									txnNum: singleData.xTxnNum,
									pCtrID: singleData.xPCtrID,
									baseAmt: singleData.xMainAmt,
									tipAmt: singleData.xTipAmt,
									taxAmt: singleData.xIncTaxAmt,
								};
								this.checkedObj.ids = JSON.parse(JSON.stringify(this.checkedItem));
								this.checkedObj.txnPctrIds.push(singleData.xPCtrID);
								this.checkedObj.amount.push(amtObj);
							}
						});
						this.checkedArray = [];
						this.checkedArray = this.checkedObj.ids;
						this.saveCheckedArray = JSON.parse(JSON.stringify(this.checkedObj.ids));
						console.log('finalize action', this.checkedArray, JSON.parse(JSON.stringify(this.amtsObjArr)));

						if (!this.isPendingItemSelected(this.checkedObj.ids)) this.emitChecked.emit(this.checkedObj);
					}
				} else {
					this.actionIsPending = false;
				}
			});
		this.transactionActionService.watchDoSettle$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((doSettle: boolean) => {
				this.refreshClick = false;
				this.summaryReportService.print = false;
				if (doSettle) {
					this.isPreviousActionSettle = true;
					this.checkedObj = {};
					this.checkedObj.action = 'settle';
					this.actionIsPending = true;
					if (this.selAll) {
						if (this.unCheckedItem.length >= 0) this.checkedObj.ids = this.unCheckedItem;
						this.checkedObj['selAll'] = true;
						this.checkedObj['parameters'] = JSON.parse(JSON.stringify(this.originalParams));
						if (this.checkedObj && this.checkedObj.hasOwnProperty('parameters')) {
							if (this.checkedObj['parameters'].hasOwnProperty('limit')) delete this.checkedObj['parameters'].limit;
							if (this.checkedObj['parameters'].hasOwnProperty('page')) delete this.checkedObj['parameters'].page;
						}
						this.checkedObj['totalRecords'] = JSON.parse(JSON.stringify(this.originalMetaData.totalRecords));
						this.emitChecked.emit(this.checkedObj);
					} else {
						this.actionPerformed = false;
						this.checkedArray = [];
						this.checkedObj.ids = JSON.parse(JSON.stringify(this.checkedItem));
						this.checkedArray = JSON.parse(JSON.stringify(this.checkedObj.ids));
						console.log('Settle Action??', JSON.parse(JSON.stringify(this.checkedArray)));
						this.saveCheckedArray = JSON.parse(JSON.stringify(this.checkedObj.ids));
						if (!this.isPendingItemSelected(this.checkedObj.ids)) this.emitChecked.emit(this.checkedObj);
					}
				} else {
					console.log('doSEttle', doSettle);
					this.actionIsPending = false;
				}
			});
		this.transactionActionService.watchDoVoid$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((doVoid: boolean) => {
				this.isPreviousActionSettle = false;
				this.refreshClick = false;
				this.summaryReportService.print = false;
				if (doVoid) {
					this.checkedObj = {};
					this.actionPerformed = false;
					this.checkedObj['action'] = 'void';
					if (this.selAll) {
						if (this.unCheckedItem.length >= 0) this.checkedObj.uncheckedIds = this.unCheckedItem;
						this.checkedObj['selAll'] = true;
						this.checkedObj['ids'] = this.data
							.filter((singleData) => this.unCheckedItem.indexOf(singleData._id) === -1)
							.map((singleData) => singleData._id);
						this.checkedObj['hashedData'] = this.hashedData.filter(
							(singleHashedData) => this.unCheckedItem.indexOf(singleHashedData.record_id) === -1
						);
						this.checkedObj['totalRecords'] = JSON.parse(JSON.stringify(this.originalMetaData.totalRecords));
						this.emitChecked.emit(this.checkedObj);
					} else {
						this.checkedArray = [];
						this.checkedObj.ids = JSON.parse(JSON.stringify(this.checkedItem));
						this.checkedObj['hashedData'] = this.hashedData.filter(
							(singleHashedData) => this.checkedObj.ids.indexOf(singleHashedData.record_id) >= 0
						);
						this.checkedArray = JSON.parse(JSON.stringify(this.checkedObj.ids));
						this.saveCheckedArray = JSON.parse(JSON.stringify(this.checkedObj.ids));
						console.log('Void Action', JSON.parse(JSON.stringify(this.checkedObj)));
						this.actionIsPending = true;

						if (!this.isPendingItemSelected(this.checkedObj.ids)) this.emitChecked.emit(this.checkedObj);
					}
				} else {
					if (doVoid === null) {
						console.log('void action null case');
						this.actionIsPending = false;
					} else {
						console.log('void action false case');
						this.actionIsPending = false;
					}
				}
			});
		//@cary close transactions	
		this.transactionActionService.watchDoClose$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((doClose: boolean) => {
				this.isPreviousActionSettle = false;
				this.refreshClick = false;
				this.summaryReportService.print = false;
				if (doClose) {
					this.checkedObj = {};
					this.actionPerformed = false;
					this.checkedObj['action'] = 'close';
					if (this.selAll) {
						if (this.unCheckedItem.length >= 0) this.checkedObj.uncheckedIds = this.unCheckedItem;
						this.checkedObj['selAll'] = true;
						this.checkedObj['ids'] = this.data
							.filter((singleData) => this.unCheckedItem.indexOf(singleData._id) === -1)
							.map((singleData) => singleData._id);
						this.checkedObj['hashedData'] = this.hashedData.filter(
							(singleHashedData) => this.unCheckedItem.indexOf(singleHashedData.record_id) === -1
						);
						this.checkedObj['totalRecords'] = JSON.parse(JSON.stringify(this.originalMetaData.totalRecords));
						this.emitChecked.emit(this.checkedObj);
					} else {
						this.checkedArray = [];
						this.checkedObj.ids = JSON.parse(JSON.stringify(this.checkedItem));
						this.checkedObj['hashedData'] = this.hashedData.filter(
							(singleHashedData) => this.checkedObj.ids.indexOf(singleHashedData.record_id) >= 0
						);
						this.checkedArray = JSON.parse(JSON.stringify(this.checkedObj.ids));
						this.saveCheckedArray = JSON.parse(JSON.stringify(this.checkedObj.ids));
						console.log('close Action', JSON.parse(JSON.stringify(this.checkedObj)));
						this.actionIsPending = true;

						if (this.isAllowedClose(this.checkedObj.ids) && !this.isPendingItemSelected(this.checkedObj.ids)) { this.emitChecked.emit(this.checkedObj); }
					}
				} else {
					if (doClose === null) {
						console.log('close action null case');
						this.actionIsPending = false;
					} else {
						console.log('close action false case');
						this.actionIsPending = false;
					}
				}
			});			
		this.transactionActionService.watchDoRePrint$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((doRePrint: boolean) => {
				this.isPreviousActionSettle = false;
				this.refreshClick = false;
				this.summaryReportService.print = false;
				if (doRePrint) {
					if (this.selAll) {
						if (this.unCheckedItem.length >= 0) this.checkedObj.ids = this.unCheckedItem;
						this.checkedObj['selAll'] = true;
						this.checkedObj['parameters'] = JSON.parse(JSON.stringify(this.params));
						this.checkedObj['totalRecords'] = JSON.parse(JSON.stringify(this.originalMetaData.totalRecords));
						this.checkedObj['action'] = 'reprint';
					} else {
						this.checkedObj = {};
						this.checkedArray = [];
						this.checkedObj.ids = JSON.parse(JSON.stringify(this.checkedItem));
						this.checkedObj.data = [];
						this.data.map((element) => {
							if (this.checkedObj.ids.indexOf(element._id) > -1) {
								this.checkedObj.data.push(element);
							}
						});
						this.checkedObj.action = 'reprint';
						console.log('rePrint Action', JSON.parse(JSON.stringify(this.checkedObj)));
						this.actionIsPending = true;
					}
					this.emitChecked.emit(this.checkedObj);
				} else {
					this.actionIsPending = false;
					if (this.types && this.types.length) this.summaryReportService.setCheckedObj(this.types);
				}
			});
		this.transactionActionService.watchDoEdit$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((doEdit: boolean) => {
				this.isPreviousActionSettle = false;
				this.refreshClick = false;
				this.summaryReportService.print = false;
				if (doEdit) {
					this.actionPerformed = false;
					this.checkedObj.action = 'edit';
					if (this.selAll) {
						if (this.unCheckedItem.length >= 0) this.checkedObj.ids = this.unCheckedItem;
						this.checkedObj['selAll'] = true;
						this.checkedObj['parameters'] = JSON.parse(JSON.stringify(this.params));
						this.checkedObj['totalRecords'] = JSON.parse(JSON.stringify(this.originalMetaData.totalRecords));
						this.emitChecked.emit(this.checkedObj);
					} else {
						this.checkedObj = {
							ids: [],
							txnPctrIds: [],
							number: [],
							editObjArr: [],
							action: 'edit',
						};
						this.data.map((singleData) => {
							const editObj = {
								id: singleData._id,
								txnNum: singleData.xTxnNum,
								pCtrID: singleData.xPCtrID,
								baseAmt: singleData.xMainAmt,
								tipAmt: singleData.xTipAmt,
								taxAmt: singleData.xIncTaxAmt,
							};
							if (this.checkedItem.indexOf(singleData._id) >= 0) {
								this.checkedObj.ids = JSON.parse(JSON.stringify(this.checkedItem));
								this.checkedObj.txnPctrIds.push(singleData.xPCtrID);
								this.checkedObj.editObjArr.push(editObj);
							}
						});
						this.checkedArray = [];
						this.checkedArray = this.checkedObj.ids;
						this.saveCheckedArray = JSON.parse(JSON.stringify(this.checkedObj.ids));
						this.actionIsPending = true;
						console.log('edit action', this.checkedArray);
						if (!this.isPendingItemSelected(this.checkedObj.ids)) this.emitChecked.emit(this.checkedObj);
					}
				} else {
					// console.log("THESE things are heppening");
					// this.checkAll("desel_all");
					this.actionIsPending = false;
				}
			});
		this.transactionActionService.watchDoReverse$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((doReverse: boolean) => {
				this.isPreviousActionSettle = false;
				this.refreshClick = false;
				this.summaryReportService.print = false;
				if (doReverse) {
					this.checkedObj = {};
					this.actionPerformed = false;
					this.checkedObj.action = 'reverse';
					if (this.selAll) {
						if (this.unCheckedItem.length >= 0) this.checkedObj.uncheckedIds = this.unCheckedItem;
						this.checkedObj['selAll'] = true;
						this.checkedObj['ids'] = this.data
							.filter((singleData) => this.unCheckedItem.indexOf(singleData._id) === -1)
							.map((singleData) => singleData._id);
						this.checkedObj['hashedData'] = this.hashedData.filter(
							(singleHashedData) => this.unCheckedItem.indexOf(singleHashedData.record_id) === -1
						);
						// this.checkedObj['parameters']= JSON.parse(JSON.stringify(this.params));
						this.checkedObj['totalRecords'] = JSON.parse(JSON.stringify(this.originalMetaData.totalRecords));
						this.emitChecked.emit(this.checkedObj);
					} else {
						this.checkedArray = [];
						this.checkedObj.ids = JSON.parse(JSON.stringify(this.checkedItem));
						this.checkedObj.action = 'reverse';
						this.checkedArray = JSON.parse(JSON.stringify(this.checkedObj.ids));
						console.log('Reverse action', JSON.parse(JSON.stringify(this.checkedArray)));
						this.saveCheckedArray = JSON.parse(JSON.stringify(this.checkedObj.ids));
						this.checkedObj['hashedData'] = this.hashedData.filter(
							(singleHashedData) => this.checkedObj.ids.indexOf(singleHashedData.record_id) >= 0
						);
						this.actionIsPending = true;
						if (!this.isPendingItemSelected(this.checkedObj.ids)) this.emitChecked.emit(this.checkedObj);
					}
				} else {
					this.actionIsPending = false;
				}
			});
		this.transactionActionService.watchDoRedo$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((doRedo: boolean) => {
				this.isPreviousActionSettle = false;
				this.refreshClick = false;
				this.summaryReportService.print = false;
				if (doRedo) {
					this.checkedObj = {};
					this.actionPerformed = false;
					this.checkedObj.action = 'redo';
					if (this.selAll) {
						if (this.unCheckedItem.length >= 0) this.checkedObj.uncheckedIds = this.unCheckedItem;
						this.checkedObj['selAll'] = true;
						this.checkedObj['ids'] = this.data
							.filter((singleData) => this.unCheckedItem.indexOf(singleData._id) === -1)
							.map((singleData) => singleData._id);
						this.checkedObj['hashedData'] = this.hashedData.filter(
							(singleHashedData) => this.unCheckedItem.indexOf(singleHashedData.record_id) === -1
						);
						this.checkedObj['totalRecords'] = JSON.parse(JSON.stringify(this.originalMetaData.totalRecords));
						this.emitChecked.emit(this.checkedObj);
					} else {
						this.checkedArray = [];
						this.checkedObj.ids = JSON.parse(JSON.stringify(this.checkedItem));
						this.checkedArray = JSON.parse(JSON.stringify(this.checkedObj.ids));
						console.log('Redo action', JSON.parse(JSON.stringify(this.checkedArray)));
						this.saveCheckedArray = JSON.parse(JSON.stringify(this.checkedObj.ids));
						this.checkedObj['hashedData'] = this.hashedData.filter(
							(singleHashedData) => this.checkedObj.ids.indexOf(singleHashedData.record_id) >= 0
						);
						this.actionIsPending = true;
						if (!this.isPendingItemSelected(this.checkedObj.ids)) this.emitChecked.emit(this.checkedObj);
					}
				} else {
					this.actionIsPending = false;
				}
			});
		//set business in top-nav as default selected business
		this.businessService.watchBusinessID$.pipe(takeWhile(() => this.alive)).subscribe((bizId: string) => {
			if (bizId) {
				console.log('bizID: grid-component: businessService----->', bizId);
				this.currentBizID = bizId;
			}
		});
	}

	// constructDataForCheckedObj(element){
	//     const obj = {
	//         id: element._id,
	//         type: element.xTypeTag
	//     };
	//     const amtObj = {
	//         id: element._id,
	//         baseAmt: element.xMainAmt,
	//         tipAmt: element.xTipAmt,
	//         taxAmt: element.xIncTaxAmt
	//     };

	//     this.txnPctrIds.push(element.xPCtrID);
	//     this.txnNumbers.push(element.xTxnNum);
	//     this.amtsObjArr.push(amtObj);
	//     this.types.push(obj);
	// }

	isAllowedClose(selectedIds){
		if (!selectedIds.length) return true;
		let count = 0;
		selectedIds.map((selectedID) => {
			this.data
				.filter((obj) => obj._id === selectedID && (obj.xTxnActTag==='Sale' || obj.xTxnActTag==='Auth' || obj.xTxnActTag==='Refund')
				&& obj.xRspStatus === 'success' && obj.xIsVoided == false && obj.xTtlAmt !== '$0.00')
				.map((obj) => {
					count++;
				});
		});

		if (count > 0) {
			customSwal
				.fire({
					title: "Can't Proceed",
					text: "Approved Auth/Sale/Refund transactions can not be closed",
					icon: 'error',
					confirmButtonText: 'Okay',
				})
				.then((result) => {
					if (this.showSelected) {
						this.actionIsCancelled.emit(false);
					} else {
						this.actionIsCancelled.emit(true);
					}
				});
			return false;
		}
		return true;
	}

	isPendingItemSelected(selectedIds) {
		let invalidSelections = {
			count: 0,
			invalidSelectionIDs: [], // No Longer Used: Was used for storing specific ids while skipping settle action for auth transactions
			invalidSelectionModelNums: [], // No Longer Used: Was used for storing specific modelNums [txn/batch] while skipping settle action for auth transactions
		};

		let typeInErrorText = TRANSACTION_ACTION_SETTLE_ERROR_TEXT_TYPE_BAD;
		let stopActionForVerifyActTag = false;

		// if(!this.checkedObj.hasOwnProperty('ids') || !this.checkedObj.ids.length)
		//     return false;

		if (!selectedIds.length) return false;

		selectedIds.map((selectedID) => {
			this.data
				.filter((obj) => obj._id === selectedID && obj.xPullSyncInfo && obj.xPullSyncInfo.xHasPendingSync)
				.map((obj) => {
					invalidSelections.count++;
				});
		});

		if (!invalidSelections.count && TRANSACTION_TAG_VERIFY_DISALLOWED_ACTIONS.includes(this.checkedObj.action)) {
			if (this.checkFor_txnActTag_Verify(selectedIds)) {
				invalidSelections.count++;
				stopActionForVerifyActTag = true;
			}
		}

		if (!invalidSelections.count) {
			console.log('No pending data');
			selectedIds.map((selectedID) => {
				this.data
					.filter((obj) => {
						return (
							obj._id === selectedID &&
							this.checkedObj.action === TRANSACTION_ACTION_SETTLE &&
							obj.xTxnActTag === TYPES.txnActionItem['auth'] &&
							!obj.xIsVoided &&
							obj['xRspStatus'] !== TRANSACTION_ACTION_RESPONSE_STATUS_FAIL
						);
					})
					.map((obj) => {
						typeInErrorText = TRANSACTION_ACTION_SETTLE_ERROR_TEXT_TYPE_AUTH;
						invalidSelections.count++;
					});
			});
			// let goodTransactionsCount = 0;
			let goodTransaction = {};

			if (!invalidSelections.count && this.checkedObj.action === TRANSACTION_ACTION_SETTLE) {
				for (let i = 0; i < selectedIds.length; i++) {
					goodTransaction = this.data.find((obj) => obj._id === selectedIds[i] && obj.xTxnActTag === TYPES.txnActionItem['auth']);

					console.log('keys: good transaction-------->', goodTransaction);

					if (goodTransaction && Object.keys(goodTransaction).length) {
						break;
					} else {
						goodTransaction = this.data.find(
							(obj) =>
								obj._id === selectedIds[i] &&
								!obj.xIsVoided &&
								obj['xRspStatus'] !== TRANSACTION_ACTION_RESPONSE_STATUS_FAIL &&
								obj.xTxnActTag !== TYPES.txnActionItem['verify']
						);

						if (goodTransaction && Object.keys(goodTransaction).length) break;
					}
				}

				// selectedIds.map(selectedID => {

				//     goodTransaction = this.data
				//         .find(obj=> (obj._id === selectedID && obj.xTxnActTag === TYPES.txnActionItem["auth"]));
				//     console.log("keys: good transaction-------->",goodTransaction)
				//     if(goodTransaction && Object.keys(goodTransaction).length){
				//         return false;
				//     }else{
				//         goodTransaction = this.data
				//             .find(obj => (obj._id === selectedID && !obj.xIsVoided && obj["xRspStatus"] !== "fail"));
				//     }
				//     // .map(obj => {
				//     //     goodTransactionsCount++;
				//     // })
				// })

				if (goodTransaction && Object.keys(goodTransaction).length) {
					return false;
				} else {
					typeInErrorText = TRANSACTION_ACTION_SETTLE_ERROR_TEXT_TYPE_BAD;
					// this.typeInErrorText = 'Not good'
				}
			}

			if (
				(this.checkedObj.action === TRANSACTION_ACTION_SETTLE && !invalidSelections.count && goodTransaction && Object.keys(goodTransaction).length) ||
				(this.checkedObj.action !== TRANSACTION_ACTION_SETTLE && !invalidSelections.count)
			)
				return false;
		}

		let selectedItemType = '';
		if (this.storeValue === 'BatchStore' || this.storeValue === 'SettledBatchStore') selectedItemType = 'batch';

		if (this.storeValue === 'TransactionsStore' || this.storeValue === 'SettledTransactionsStore') selectedItemType = 'transaction';

		let errorText = 'An action is already in process upon ' + selectedItemType + `\nor\nInvalid (${typeInErrorText}) transactions are selected`;

		if (stopActionForVerifyActTag) {
			errorText = this.checkedObj.action + ' action is not allowed for verify-type transactions.';
		}
		//      +
		//     " with " +
		//     selectedItemType +
		//     "-number -: ";
		// errorText = errorText.concat(
		//     invalidSelections.invalidSelectionModelNums
		//         ? invalidSelections.invalidSelectionModelNums.toString()
		//         : ""
		// );
		errorText = errorText.concat('\nPlease de-select such transactions to proceed!!');

		customSwal
			.fire({
				title: "Can't Proceed",
				text: errorText,
				icon: 'error',
				confirmButtonText: 'Okay',
			})
			.then((result) => {
				if (this.showSelected) {
					this.actionIsCancelled.emit(false);
				} else {
					this.actionIsCancelled.emit(true);
				}
			});

		return true;
	}

	checkFor_txnActTag_Verify(selectedIds): boolean {
		// let verify_txnActTag_does_exist = false;
		let verifyTransaction = {};
		for (let i = 0; i < selectedIds.length; i++) {
			verifyTransaction = this.data.find((obj) => obj._id === selectedIds[i] && obj.xTxnActTag === TYPES.txnActionItem['verify']);

			console.log('keys: verify Action Tag transaction-------->', verifyTransaction);

			if (verifyTransaction && Object.keys(verifyTransaction).length) {
				break;
			}
		}

		if (verifyTransaction && Object.keys(verifyTransaction).length /*&& TRANSACTION_TAG_VERIFY_DISALLOWED_ACTIONS.includes(this.checkedObj.action)*/) {
			return true;
		} else {
			return false;
		}
	}

	updateParam() {
		if (!isEmpty(this.parameters)) {
			console.log('in here I guess', this.parameters);
			Object.keys(this.parameters).map((objKey) => {
				this.params[objKey] = this.parameters[objKey];
			});
			if (!this.params.hasOwnProperty('xBusinessID') && this.fetchByStore /*&& !(this.businessService.businessID.getValue() === null)*/) {
				this.params['xBusinessID'] = this.businessService.businessID.getValue();
			}
		} else if (!this.params.hasOwnProperty('xBusinessID') && this.fetchByStore /*&& !(this.businessService.businessID.getValue() === null)*/) {
			// console.log("this.businessService.businessID.getValue();",JSON.parse(JSON.stringify(this.parameters)), JSON.parse(JSON.stringify(this.params)),JSON.parse(JSON.stringify(this.businessService.businessID.getValue())));
			this.params['xBusinessID'] = this.businessService.businessID.getValue();
		}
		// if (
		//     !this.params.hasOwnProperty("xBizId") &&
		//     this.fetchByStore /*&& !(this.businessService.businessID.getValue() === null)*/
		// ) {
		//     console.log("this.businessService.businessID.getValue();",JSON.parse(JSON.stringify(this.parameters)), JSON.parse(JSON.stringify(this.params)),JSON.parse(JSON.stringify(this.businessService.businessID.getValue())));
		//     this.params['xBizId'] = this.businessService.businessID.getValue();
		// }
		//roles is not associated to business
		if (this.storeValue === SETUP_SECURITY_ROLES_STORE) {
			delete this.params.xBusinessID;
		}
		console.log('indside update params -->', JSON.parse(JSON.stringify(this.params)));
	}

	performViewAction(elementID: string, element: any,event?: any) {
		// console.log("here in performViewAction");
		if (this.storeValue === 'TransactionsStore' || this.storeValue === 'SettledTransactionsStore') {
			if (this.singleSelectMode) {
				this.checkItem(
					// elementID,
					element,
					false,
					event
				);
				let newArr = this.action.filter((act: any) => act.type === 'view');
				this.processAction(newArr[0], elementID);
			} else {
				this.checkItem(
					// elementID,
					element,
					true,
					event
				);
			}
		} else {
			// this.selectedRowId=[];
			// this.selectedRowId.push(elementID);
			this.data.map((singleElement) => {
				if (singleElement.hasOwnProperty('isChecked') && singleElement['isChecked']) {
					singleElement['isChecked'] = false;
				}
			});
			element['isChecked'] = true;
			this.disableViewBtn = false;
			let newArr = this.action.filter((act: any) => act.type === 'view');
			this.processAction(newArr[0], elementID);
		}
	}

	processDisplayGridRequestData(response:any, params:any, isScroll:boolean, sort:boolean){
		console.log("processDisplayGridRequestData:", response);
		this.resetAdvancedFilterForm = false;
		const roles = this.userHandlerService.userRoles;
		if (isScroll) {
			console.log('onScroll true', JSON.parse(JSON.stringify(this.data)));
			/*==================================================================================
				=            No need to emit bottom totals in case of data-scroll event            =
				==================================================================================*/
			// if(!this.totalTxn || (response.meta && response.meta.totalRecords && this.totalTxn !== response.meta.totalRecords))
			//     this.totalTxn = response.meta.totalRecords? response.meta.totalRecords: 0;
			// if(this.totalTxnAmt === "$0.00" || (response.meta && response.meta.totalAmount && this.totalTxnAmt !== "$" + (parseInt(response.meta.totalAmount?response.meta.totalAmount:0) / 100).toFixed(2)))
			//     this.totalTxnAmt = "$" + (parseInt(response.meta.totalAmount?response.meta.totalAmount:0) / 100).toFixed(2);
			// this.totTxnSelTxnObj={
			//     totTxn: this.totalTxn,
			//     totTxnAmt: this.totalTxnAmt,
			//     selTxn: this.selTxn,
			//     selTxnAmt: this.getAmtInDollars(this.selTxnAmt)
			// }
			// this.selectedTxnEvent.emit(this.totTxnSelTxnObj);
			/*=====  End of No need to emit bottom totals in case of data-scroll event  ======*/

			// this.constantService.updateData(
			//     this.storeValue,
			//     response.data
			// );
			// let data= JSON.parse(JSON.stringify(response['data']));
			// let finalResponse= JSON.parse(JSON.stringify(this.constantService.updateData(this.storeValue, response.data)));
			this.constantService
				.updateData(this.storeValue, response.data)
				.then((updatedData) => {
					console.log('HERE 1', JSON.parse(JSON.stringify(updatedData)));
					// console.log("this.checkedItem is scroll", this.checkedItem);
					updatedData.map((singleData) => {
						if (this.selAll) {
							if (this.unCheckedItem.indexOf(singleData._id) === -1) {
								singleData.isChecked = true;
							} else {
								singleData.isChecked = false;
							}
						} else {
							if (this.checkedItem.indexOf(singleData._id) >= 0) {
								singleData.isChecked = true;
								// if(!this.showSelected){  Code for settle action maintining selection Bug-: Selections of page 2 won't persist
								//     this.updatedCheckedArray.push(singleData._id);
								//     // if(!this.refreshClick){

								//         this.selTxn+= 1;
								//         switch (singleData.xTxnActTag) {
								//             case TYPES.txnActionItem['sale']:
								//                 this.selTxnAmt=this.selTxnAmt + this.getAmtInCents(singleData.xTtlAmt);
								//                 break;
								//             case TYPES.txnActionItem['return']:
								//                 this.selTxnAmt=this.selTxnAmt - this.getAmtInCents(singleData.xTtlAmt);
								//                 break;

								//             default:
								//                 // code...
								//                 break;
								//         }
								//     // }
								// }
							} else {
								singleData['isChecked'] = false;
							}
						}
					});

					this.data = JSON.parse(JSON.stringify(this.data.concat(updatedData)));
					console.log('HERE 2', JSON.parse(JSON.stringify(this.data)));

					if (!this.showSelected)
						this.hashedData =
							response && response.hasOwnProperty('meta') && response.meta.hasOwnProperty('hashedData')
								? JSON.parse(JSON.stringify(this.hashedData.concat(response.meta.hashedData)))
								: [];
					this.metaData = response.meta;
					if (this.metaData.totalRecords === this.data.length) {
						this.completeLoad = true;
					}
					this.saveOriginalDataAndParams(response, params);

					if (this.showSelected) this.showSelectedClicked = false;
					console.log('this.update 2', this.updatedCheckedArray, this.selTxn, this.selTxnAmt);
					// if(!this.showSelected) Code for settle action maintining selection Bug-: Selections of page 2 won't persist
					//     this.checkedArray= this.updatedCheckedArray.map(singleCheckedID=> singleCheckedID);
					// this.data = this.data.concat(this.constantService.updateData(this.storeValue, response.data));
				})
				.catch((err) => {
					console.log('catch of display grid', err);
				});

			if (this.userHandlerService.userRoles.isBusinessEmployee) {
				// console.log("onScroll true emp true", JSON.parse(JSON.stringify(this.data)));
				this.StructureDataFilterForEmpScroll(response.data);
				console.log('FinalData after filtering emp true', JSON.parse(JSON.stringify(this.data)));
			}
		} else {
			// console.log("this.checkedItem", this.checkedItem);
			// this.resetStates(); //To set grid states when new/refreshed data is displayed.
			// console.log("this.checkedItem", this.checkedItem);
			if (this.isPreviousActionSettle) {
				this.selTxn = 0;
				this.selTxnAmt = 0.0;
				this.selMerAmt = 0.0;
				this.onResetSelectionClick();
			}
			response.data.map((singleData) => {
				// console.log(singleData);
				if (this.selAll) {
					if (this.unCheckedItem.indexOf(singleData._id) === -1) {
						singleData.isChecked = true;
					} else {
						singleData.isChecked = false;
					}
				} else {
					if (this.checkedItem.indexOf(singleData._id) >= 0) {
						singleData['isChecked'] = true;
						// if(!this.showSelected){ Code for settle action maintining selection Bug-: Selections of page 2 won't persist
						//     this.updatedCheckedArray.push(singleData._id);
						//     // if(!this.refreshClick){

						//         this.selTxn+= 1;
						//         switch (singleData.xTxnActTag) {
						//             case TAGSCONSTS.transactionActionTags.txnActionItemTags['sale']:
						//                 this.selTxnAmt=this.selTxnAmt + this.getAmtInCents("$" + (parseInt(singleData.xTtlAmt) / 100).toFixed(2));
						//                 break;
						//             case TAGSCONSTS.transactionActionTags.txnActionItemTags['return']:
						//                 this.selTxnAmt=this.selTxnAmt - this.getAmtInCents("$" + (parseInt(singleData.xTtlAmt) / 100).toFixed(2));
						//                 break;

						//             default:
						//                 // code...
						//                 break;
						//         }
						//     // }
						// }
					} else {
						singleData['isChecked'] = false;
					}
				}
			});
			this.data = response.data;
			if (!this.showSelected)
				this.hashedData =
					response && response.hasOwnProperty('meta') && response.meta.hasOwnProperty('hashedData') ? response.meta.hashedData : [];
			this.constantService.updateData(this.storeValue, this.data);
			this.metaData = response.meta;
			console.log('this.meta 1', this.metaData.totalRecords, this.data.length, this.originalData.length, this.originalMetaData.totalRecords);

			if (this.metaData.totalRecords === this.data.length) {
				this.completeLoad = true;
			}
			this.saveOriginalDataAndParams(response, params);
			// if(!this.showSelected) Code for settle action maintining selection Bug-: Selections of page 2 won't persist
			//     this.checkedItem= this.updatedCheckedArray.map(singleCheckedID=> singleCheckedID);
			this.totalTxn = response.meta.totalRecords ? response.meta.totalRecords : 0;
			//     console.log("CHECK MAADI====>",this.totalTxnAmt, this.getAmtInDollars(response.meta.totalAmount? response.meta.totalAmount: 0), this.totalTxnAmt === "$0.00" || (response.meta &&response.meta.totalAmount &&this.totalTxnAmt !== "$" + this.getAmtInDollars(response.meta.totalAmount)));
			// if (
			//     this.totalTxnAmt === "$0.00" ||
			if (this.storeValue === 'TransactionsStore' || this.storeValue === 'SettledTransactionsStore') {
				let amtParams = Object.assign({}, params);
				delete amtParams.limit;
				delete amtParams.page;
				delete amtParams.hashData;
				amtParams.dataStore = this.storeValue;
				// console.log('amtParams:',amtParams)
				this.httpService.getAll(API_END_POINT.transactionAmount, amtParams).subscribe((amtRes) => {
					// console.log('amtRes:',amtRes);
					const totalAmt = amtRes.totalAmount ? amtRes.totalAmount : 0;
					const totalMerchantAmt = amtRes.merchantAmt ? amtRes.merchantAmt : 0;
					this.originalMetaData.totalAmount = totalAmt;
					this.originalMetaData.totalMerAmount = totalMerchantAmt;
					this.totalTxnAmt = '$' + this.getAmtInDollars(totalAmt);
					this.totalMerAmt = '$' + this.getAmtInDollars(totalMerchantAmt);
					// console.log('this.totalTxnAmt',this.totalTxnAmt);
					if (!this.showSelected) {
						this.totTxnSelTxnObj = {
							totTxn: this.totalTxn,
							totTxnAmt: this.totalTxnAmt,
							totalMerAmt: this.totalMerAmt,
							selTxn: this.selTxn,
							selTxnAmt: '$' + this.getAmtInDollars(this.selTxnAmt),
							selMerAmt: '$' + this.getAmtInDollars(this.selMerAmt),
						};
						this.originalTotTxnSelTxnObj = JSON.parse(JSON.stringify(this.totTxnSelTxnObj));
					} else {
						this.totTxnSelTxnObj['totTxn'] = 0;
						this.totTxnSelTxnObj['totTxnAmt'] = 0;
						this.totTxnSelTxnObj['totalMerAmt'] = 0;
						// if(this.showSelected)
						this.showSelectedClicked = false;
					}
					// console.log('this.totTxnSe', this.totTxnSelTxnObj);
					this.selectedTxnEvent.emit(this.totTxnSelTxnObj);
				});
			}
			if (this.userHandlerService.userRoles.isBusinessEmployee) {
				this.StructureDataFilterForEmpNoScroll();
				// console.log("FinalData after filtering", this.data);
			}
			if (this.storeValue === 'TransactionsStore' || this.storeValue === 'SettledTransactionsStore') {
				this.STRLENGTH = 0;
			}
		}
		// ------------------------- gmid in breadcrumbs txn ------------------------------
		const unique = (value, index, self) => {
			return self.indexOf(value) === index;
		};
		if (
			(this.storeValue === 'TransactionsStore' ||
				this.storeValue === 'BatchStore' ||
				this.storeValue === 'SettledTransactionsStore' ||
				this.storeValue === 'SettledBatchStore') &&
			!roles.isCustomer
		) {
			console.log('pctrlist response', this.data);
			this.data.map((item) => {
				this.pctrList.push(item.xPCtrID);
			});
			this.pctrList = this.pctrList.filter(unique);
			this.pctrListEvent.emit(this.pctrList);
			console.log('pctr list', this.pctrList);
			this.pctrList = [];
			console.log('pctr list cleaned', this.pctrList);
		}
		// ------------------------- gmid in breadcrumbs txn end ------------------------------
		// console.log('this.removeLoading -->', this.removeLoading, JSON.parse(JSON.stringify(params)), this.removeLoading, JSON.parse(JSON.stringify(this.params)));
		// if (!this.removeLoading) {
		//     // this.checkState =
		//     this.data.map(element => {
		//         if(!this.checkState || !Object.keys(this.checkState).length || !this.checkState[element._id]){
		//             this.checkState[element._id] = false;
		//         }
		//         return;
		//         // return false;
		//         // }
		//     }); // To set default state of the checkboxes
		// }

		if (this.checkedItem.length === 1) this.disableViewBtn = false;
		else this.disableViewBtn = true;

		this.disableRefreshButton = false;
		this.loading = false;
		this.error = false;
		// if(!this.showSelected && !this.transactionActionService.settleSuccess && !this.transactionActionService.settleFailed){
		//     console.log("HERE original stuffs are being saved inside grid component")
		//     this.originalData= JSON.parse(JSON.stringify(this.data));
		//     this.originalMetaData= JSON.parse(JSON.stringify(response.meta));
		//     this.originalParams= JSON.parse(JSON.stringify(params));
		// }
		if (this.data && !this.data.length) {
			this.disableViewBtn = true;
			this.disableRefreshButton = true;
			this.error = true;
			this.gridMessage = this.getGridMessage();
		}
		this.dataIsLoaded.emit(true);
	}

	displayGrid(params: any, isScroll: boolean = false, sort: boolean = false): void {
		console.log(
			'display grid this.params, params and this.checkedItems  --> PARAMS: ',
			this.params,
			JSON.parse(JSON.stringify(params)),
			'checked item',
			this.checkedItem,
			'store value',
			this.storeValue,
			this.businessService.businessID.getValue()
		);
		this.paginateId = this.storeValue + 'server';
		const roles = this.userHandlerService.userRoles;

		if(roles.isVendor){
			if("" !== this.userHandlerService.userData.xTag){
				params['xVendorTag'] = this.userHandlerService.userData.xTag;
			}
		}

		//Fetch Employee/Customer by business id while logging with dealer/business-employee
		if ((roles.isDealer || roles.isBusinessEmployee) && ['SetupBusinessEmployeeStore', 'SetupBillingCustomerStore'].includes(this.storeValue)) {
			params.xBusinessID = this.businessService.businessID.getValue();
		}

		//Fetch Representative by company id while logging with vendor/dealer
		if (this.storeValue === 'SetupCompanyRepresentativeStore' && this.companyService.companyID.getValue()) {
			if (roles.isVendor || roles.isDealer) {
				params.xCompanyID = this.companyService.companyID.getValue();
			}
			if (roles.isDealer) {
				params[FETCH_DEALERS_KEY] = true;
			}
		}

		if(this.storeValue === 'SetupBillingStore'){
			if (roles.isBusinessEmployee || roles.isCustomer) {
				params.xBusinessID = this.businessService.businessID.getValue();
			}
		}

		// console.log("display grid before biz emp condition", this.checkedItem);
		//For Business Employee only particular store to which employee belongs need to be shown(Not needed for now)\
		// if(this.userHandlerService.userRoles.isBusinessEmployee && this.storeValue==='SetupBusinessStructureStoreSetupStore'){
		//     params['_id']={
		//         '$in':JSON.stringify(this.storeService.getAccessStoreIDs())
		//     };
		//     console.log("display grid inside biz emp condition", params);
		// }

		//TODO move the logic for fetching company info to @Input parameters
		if (this.userHandlerService.userRoles.isDealer && this.storeValue === 'SetupCompanyStore') {
			params = {};
			if (!this.companyService.companyID.getValue()) {
				return;
			}
			params._id = this.companyService.companyID.getValue();
			if (this.checkValue) {
				params.xStatus = 1;
			}
			params.noGrid = true;
		}
		//TODO move the logic for fetching business info @Input parameters
		if (this.userHandlerService.userRoles.isBusinessEmployee) {
			if (this.storeValue === 'SetupBusinessInformationStore') {
				params = {};
				params._id = this.businessService.businessID.getValue();
				params.noGrid = true;
			} else if (this.storeValue === 'SetupBusinessEmployeeStore' || this.storeValue === 'SetupBillingCustomerStore') {
				// console.log(
				//     "Params before if condition=======>>>>",
				//     JSON.parse(JSON.stringify(params))
				// );
				if (this.treeService.nodeClick && this.treeService.storeID.getValue()) {
					params = {};
					params = this.params;
				} else if (this.storeService.getAccessStoreIDs().length) {
					let storeIdString = '';
					this.storeService.getAccessStoreIDs().map((id) => {
						if (storeIdString.length) {
							storeIdString = storeIdString + ',' + id;
						} else {
							storeIdString = id;
						}
					});
					params.storeIDs = storeIdString;
					// console.log(
					//     "Params inside if=======>>>>",
					//     JSON.parse(JSON.stringify(params))
					// );
					if (params.hasOwnProperty('tree_filter')) {
						//Removes tree-filter from params.
						delete params['tree_filter'];
					}
				}
			} else {
				if (this.storeService.getMainStoreID().length) {
					params.xStoreID = this.storeService.getMainStoreID();
				}
			}
		}

		if (this.removeLoading) {
			this.loading = false;
			// this.error=false;
		} else {
			this.loading = true;
			// this.error=false;
		}
		// console.log("params----------??", JSON.parse(JSON.stringify(params)), this.selAll, this.showSelected, JSON.parse(JSON.stringify(this.loading)));
		if (!this.showSelected) {
			if (!this.summaryReportService.print) {
				if (params.hasOwnProperty('selectAll')) {
					// this.params.selectAll= params.selectAll;
					delete params.selectAll;
				}
				if (params.hasOwnProperty('excludeIDs')) {
					// this.params.selectAll= params.selectAll;
					delete params.excludeIDs;
				}
			}
		}
		console.log('Parameters to be sent to store--->', this.params, JSON.parse(JSON.stringify(params)), this.storeObj, this.storeValue, this.checkedItem); // add functionality in stores->services->database for tree_filter
		// console.log("this.transactionActionService.actionPerformed.getValue()", this.transactionActionService.actionPerformed.getValue());
		if (this.transactionActionService.actionPerformed.getValue()) {
			this.showSelected = false;
			this.transactionActionService.setActionPerformed(false);
			// console.log("this.transactionActionService.actionPerformed.getValue() 2", this.transactionActionService.actionPerformed.getValue());
			// this.responseModalClose=false;
		}
		// console.log("this.pendingRequest",this.pendingRequest);
		if (this.pendingRequest && this.pendingRequest.hasOwnProperty('closed') && !this.pendingRequest.closed) {
			this.pendingRequest.unsubscribe();
			console.log(
				'HEREEEEEEEEEEE====>',
				JSON.parse(JSON.stringify(params)),
				JSON.parse(JSON.stringify(this.params)),
				JSON.parse(JSON.stringify(this.originalParams))
			);
			if (this.showSelected) {
				this.sortedBy = this.originalParams['sortKey'];
				this.sortInOrder = this.originalParams['sortType'] === -1 ? 'dsc' : 'asc';
				this.setSortOrderToInitial(true);
				if (this.originalParams.hasOwnProperty('sortKey')) {
					params['sortKey'] = this.originalParams['sortKey'];
					this.params['sortKey'] = this.originalParams['sortKey'];
				} else {
					delete params.sortKey;
					delete this.params.sortKey;
				}
				if (this.originalParams.hasOwnProperty('sortType')) {
					params['sortType'] = this.originalParams['sortType'];
					this.params['sortType'] = this.originalParams['sortType'];
				} else {
					delete params.sortType;
					delete this.params.sortType;
				}
			}
		}

		// console.log("params 2", JSON.parse(JSON.stringify(params)));
		if(this.sumReportDataInItFlag){
			this.sumReportDataInItFlag = false;
			this.processDisplayGridRequestData({'data':this.sumReportDataObj, 'meta':{'totalRecords':this.sumReportDataObj.length}}, params, isScroll, sort);
			return;
		}

		// if (this.storeValue === 'TransactionsStore' || this.storeValue === 'SettledTransactionsStore') {
		// 	params['sortKey'] = 'xAuthDate';
		// 	params['sortType'] = -1;
		// }

		this.pendingRequest = this.storeObj.getAll(JSON.parse(JSON.stringify(params))).subscribe(
			(response: any) => {
				console.log('Response inside grid cmp', response, this.storeValue, this.checkedItem, JSON.parse(JSON.stringify(params)));
				// if (
				//     this.storeValue === "TransactionsStore" ||
				//     this.storeValue === "BatchStore" ||
				//     this.storeValue === "SettledTransactionsStore" ||
				//     this.storeValue === "SettledBatchStore"
				// ) {
				//     console.log("pctrlist response",response.data);
				//     response.data.map(item => {
				//         this.pctrList.push(item.xPCtrID);
				//     });
				//     this.pctrList = this.pctrList.filter(unique);
				//     this.pctrListEvent.emit(this.pctrList);
				//     console.log("pctr list", this.pctrList);
				//     this.pctrList=[];
				//     console.log("pctr list cleaned",this.pctrList);
				// }
				// if(!this.showSelected){ Code for settle action maintining selection Bug-: Selections of page 2 won't persist
				//     // if(!this.refreshClick){
				//         this.selTxn=0;
				//         this.selTxnAmt=0.00;
				//     // }
				//     this.updatedCheckedArray=[];
				// }
				// if(!isScroll)
				//     this.resetStates(); //To set grid states when new/refreshed data is displayed.
				// this.advancedFilterFormDataToBeReused={};
				// ----------------CSV file get name of users and file -------------------------
				// if (this.storeValue === "CsvFileHistoryStore") {
				//     console.log("csv file response in grid", response);
				//     response.data.map(singleCsvObj => {
				//         let date = new Date(singleCsvObj.updatedAt);
				//         let day = date.getUTCDate().toString();
				//         let month = (date.getUTCMonth() + 1).toString();
				//         let hour = date.getUTCHours().toString();
				//         let minutes = date.getUTCMinutes().toString();
				//         day = day.length < 2 ? "0" + day : day;
				//         month = month.length < 2 ? "0" + month : month;
				//         hour = hour.length < 2 ? "0" + hour : hour;
				//         minutes =
				//             minutes.length < 2 ? "0" + minutes : minutes;
				//         singleCsvObj.convertedTime =
				//             month + "/" + day + " " + hour + ":" + minutes;
				//         singleCsvObj.updatedAt = date.toString();
				//         if (!singleCsvObj.csv_details.resultFileName) {
				//             singleCsvObj.csv_details.resultFileName = "-";
				//         }
				//     });
				// }
				//-----------------CSV file get name of users and file -------------------------
				if (response.hasOwnProperty('txnData') && response.hasOwnProperty('settledTxnData')) {
					// console.log(
					//     "Response inside grid cmp txnData settledTxnData",
					//     JSON.parse(JSON.stringify(response)),
					//     this.storeValue, this.checkedItem
					// );
					let data = [];
					if (response['txnData'].hasOwnProperty('data') && response['txnData']['data'].length) {
						data = response['txnData']['data'].map((singleData) => singleData);
						this.completeLoad = true;
					}
					if (response['settledTxnData'].hasOwnProperty('data') && response['settledTxnData']['data'].length) {
						response['settledTxnData']['data'].map((ele) => {
							let foundIndex = data.findIndex((p) => p.xRefNum === ele.xRefNum);
							if (foundIndex === -1) {
								console.log('no match found, APPEND txn data');
								data = data.concat(ele);
							} else {
								console.log('match found, SKIP txn data', ele._id.toString());
								return;
							}
						});
						this.completeLoad = true;
					}
					delete response['txnData'];
					delete response['settledTxnData'];
					response['data'] = JSON.parse(JSON.stringify(data));
					response['meta'] = {};
					response['meta']['page'] = 1;
					response['meta']['totalRecords'] = null;

					console.log("response------------------:", response);

					// console.log(
					//     "Response inside grid cmp txnData settledTxnData 2",
					//     JSON.parse(JSON.stringify(response)),
					//     this.storeValue, this.checkedItem
					// );
				}
				this.processDisplayGridRequestData(response, params, isScroll, sort);
			},
			(error: any) => {
				console.log('Inside grid component, subscribe Failure func', error);
				this.loading = false;
				this.error = true;
				// this.notificationService.error('Oops Something went wrong!', 'No Records Available');
			},
			() => {
				// console.log("subscription complete func inside grid");
				// For displaying list details by default
				// if (this.data && this.data.length) {
				//     if (this.params.page === 1) {
				//         //select first row
				//         this.selectedRowId = this.data[0]._id;
				//         this.obj.act = { type: "view" };
				//         this.obj.id = this.data[0]._id;
				//         this.actionRequest.emit(this.obj);
				//         //check first row checkBox if checkAction is allowed
				//         if (this.isCheckAction) {
				//             this.singleCheckedItemIndex = 0;
				//             if (
				//                 !this.checkState[this.singleCheckedItemIndex] &&
				//                 this.data[0] &&
				//                 Object.keys(this.data[0]).length
				//             ) {
				//                 this.checkState[
				//                     this.singleCheckedItemIndex
				//                 ] = true;
				//                 this.checkItem(
				//                     this.data[0]._id,
				//                     this.data[0],
				//                     null
				//                 );
				//             }
				//         }
				//     }
				// }
				// this.params=JSON.parse(JSON.stringify(params));
				// console.log("Subscription completed, PARAMS -->", this.params);
			}
		);
	} // displayGrid Function Ends

	// reverseMediumTag(ele: any){
	//     console.log(ele, "ele: reverseMediumTag: grid");
	//     this.constantService.updateData(this.storeValue, ele).then(res => {
	//         console.log(res, "res: reverseMediumTag: grid");
	//     }).catch(err => console.log(err, "err: reverseMediumTag: grid"))
	// }

	saveOriginalDataAndParams(response, params) {
		if (!this.showSelected && !this.transactionActionService.settleSuccess && !this.transactionActionService.settleFailed) {
			console.log('HERE original stuffs are being saved inside grid component', this.data, this.hashedData);
			this.originalData = JSON.parse(JSON.stringify(this.data));
			this.originalMetaData = JSON.parse(JSON.stringify(response.meta));
			this.originalParams = JSON.parse(JSON.stringify(params));
			this.originalHashedData = JSON.parse(JSON.stringify(this.hashedData));
		}

		this.disableExportSummary = this.originalData.length == 0 ? true : false
	}
	/**
	 * @brief      Gets the grid message.
	 * @return     The appropriate grid message.
	 */

	getGridMessage(): string {
		if (['SetupBusinessEmployeeStore', 'SetupBillingCustomerStore'].includes(this.storeValue) && !this.businessService.businessID.getValue()) {
			return 'Please select a business';
		}

		if (this.storeValue === 'SetupCompanyRepresentativeStore' && !this.companyService.companyID.getValue()) {
			return 'Please select a company';
		}

		return 'No Record Found';
	}

	StructureDataFilterForEmpNoScroll() {
		// (isScroll=false) To filter store, merchant-account, profit-center, terminal and peripheral when employee is logged in
		if (this.storeValue === 'SetupBusinessStructureStoreSetupStore') {
			this.data = this.data.filter((singleData) => this.storeService.getAccessStoreIDs().indexOf(singleData._id) !== -1);
		} else if (this.storeValue === 'SetupStructureProfitCenterStore') {
			this.data = this.data.filter((singleData) => this.storeService.getAccessStoreIDs().indexOf(singleData.xStoreID) !== -1);
		} else if (this.storeValue === 'SetupPaymentProcessingMerchantAccountStore') {
			this.data = this.data.filter((singleData) => this.storeService.getAccessStoreIDs().indexOf(singleData.xStoreID) !== -1);
		} else if (this.storeValue === 'SetupStructureTerminalStore') {
			this.data = this.data.filter((singleData) => this.storeService.getAccessStoreIDs().indexOf(singleData.xStoreID) !== -1);
		} else if (this.storeValue === 'SetupStructurePeripheralStore') {
			this.data = this.data.filter((singleData) => this.storeService.getAccessStoreIDs().indexOf(singleData.xStoreID) !== -1);
		}
	}

	StructureDataFilterForEmpScroll(data) {
		// (isScroll=true) To filter store, merchant-account, profit-center, terminal and peripheral when employee is logged in
		if (this.storeValue === 'SetupBusinessStructureStoreSetupStore') {
			this.data = this.data.concat(data);
			this.data = this.data.filter((singleData) => this.storeService.getAccessStoreIDs().indexOf(singleData._id) !== -1);
		} else if (this.storeValue === 'SetupStructureProfitCenterStore') {
			this.data = this.data.concat(data);
			this.data = this.data.filter((singleData) => this.storeService.getAccessStoreIDs().indexOf(singleData.xStoreID) !== -1);
		} else if (this.storeValue === 'SetupPaymentProcessingMerchantAccountStore') {
			this.data = this.data.concat(data);
			this.data = this.data.filter((singleData) => this.storeService.getAccessStoreIDs().indexOf(singleData.xStoreID) !== -1);
		} else if (this.storeValue === 'SetupStructureTerminalStore') {
			this.data = this.data.concat(data);
			this.data = this.data.filter((singleData) => this.storeService.getAccessStoreIDs().indexOf(singleData.xStoreID) !== -1);
		} else if (this.storeValue === 'SetupStructurePeripheralStore') {
			this.data = this.data.concat(data);
			this.data = this.data.filter((singleData) => this.storeService.getAccessStoreIDs().indexOf(singleData.xStoreID) !== -1);
		}
	}

	getValue(element: any, key: string): any {
		key = key.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
		key = key.replace(/^\./, ''); // strip a leading dot
		const a = key.split('.');
		for (let i = 0; i < a.length; ++i) {
			const k = a[i];
			if (k in element) {
				element = element[k];
			} else {
				return;
			}
		}
		return element;
	}

	truncateString(value: any): any {
		if (typeof value === 'string') {
			if (this.STRLENGTH && value.length > this.STRLENGTH) {
				return value.substring(0, this.STRLENGTH) + '...';
			} else {
				return value;
			}
		} else {
			return value;
		}
	}

	processAction(act: any, elementID: any, element: any = {}, event?: any): void {
		// console.log("Process Action===>", act, elementID, element);
		if (act) {
			switch (act.type) {
				case 'edit':
					this.treeService.nodeClick = false;
					this.tabStructureService.editMode = true;
					this.tabStructureService.tabMode = false;
					this.treeService.setEnable(false);
					this.treeService.setShow(true);
					console.log('process action edit', act);
					if (act.action === 'emit') {
						this.emitObject(act, elementID);
					} else if (act.action === 'url_key') {
						// console.log('GRID: Case ->', 'url_key');
						this.tabStructureService.currentNodeName = element.xName;
						const link = [act.onClick, { [act['paramKey']]: elementID }];
						this.router.navigate(link);
					} else {
						this.tabStructureService.currentNodeName = element.xName;
						const link = [act.onClick, elementID];
						this.router.navigate(link);
					}
					break;
				case 'isPaid':
					if (act.action === 'func' && act.onClick === 'isPaid') {
						let title = SWAL_TITLE_ARE_YOU_SURE;
						let text = IS_PAID_WARNING;
						this.showSuspendIsPaidWarningSwal(title, text, elementID, element, 'isPaid');
					}
					break;
				case 'suspend':
					if (act.action === 'func' && act.onClick === 'suspend') {
						let title = SWAL_TITLE_ARE_YOU_SURE;
						let text = IS_SUSPENDED;
						this.showSuspendIsPaidWarningSwal(title, text, elementID, element, 'suspend');
					}
					break;
				case 'unsuspend':
					if (act.action === 'func' && act.onClick === 'unsuspend') {
						let title = SWAL_TITLE_ARE_YOU_SURE;
						let text = IS_UNSUSPENDED;
						this.showSuspendIsPaidWarningSwal(title, text, elementID, element, 'unsuspend');
					}
					break;
				case 'disable':
					if (act.action === 'func' && act.onClick === 'disable') {
						let title = SWAL_TITLE_ARE_YOU_SURE;
						let text = IS_DISABLE_BILLING;
						this.showSuspendIsPaidWarningSwal(title, text, elementID, element, 'disable');
					}
					break;		
				case 'runnow':
					if (act.action === 'func' && act.onClick === 'runnow') {
						if(element.xBillingState === "Approved"){
							this.notificationService.info('Invoice selected already approved. Can not approve again.');
							return;
						}

						let title = SWAL_TITLE_ARE_YOU_SURE;
						let text = "You are approving this record.";
						this.showSuspendIsPaidWarningSwal(title, text, elementID, element, 'runnow');
					}
					break;
				case 'notifyCustomer':
					if (act.action === 'func' && act.onClick === 'notifyCustomer') {
						this.emailAddressSwal(elementID, element);
					}					
					break;
				case 'payInvoiceNow':
					if (act.action === 'func' && act.onClick === 'payInvoiceNow') {
						if(element.xPaymentStatus == "Paid" || element.xPaymentStatus == "Manually Paid"
						||element.xPaymentStatus == "Automatically Paid"){
							this.notificationService.info('Record selected already paid. Can not charge again.');
							return;
						}
						if(element.xBillingState !== "Approved"){
							this.notificationService.info('Record selected not approved. Please approve it first.');
							return;
						}
						let title = SWAL_TITLE_ARE_YOU_SURE;
						let text = "You are charging this record.";
						this.showSuspendIsPaidWarningSwal(title, text, elementID, element, 'payInvoiceNow');
					}
					break;															
				case 'delete':
					if (!this.isDeleteOperationAllowed()) {
						this.notificationService.error('Operation Not Permitted', 'Failed');
						return;
					}
					// if (this.storeValue === SETUP_BILLING_STORE || this.storeValue === SETUP_INVOICE_STORE) {
					// 	const currentDate = new Date();
					// 	const specifiedDate = new Date(element['orgCreateAt']);
					// 	const currentTimeStamp = currentDate.getTime();
					// 	const specifiedTimeStamp = specifiedDate.getTime();
					// 	//create date is not early than 180 days
					// 	if (specifiedTimeStamp > currentTimeStamp - (180 * 24 * 60 * 60 * 1000)) {
					// 		this.notificationService.info('Records within 180 days can not be deleted.');
					// 		return;
					// 	}
					// }
					if (act.action === 'func' && act.onClick === 'destroy') {
						let title = SWAL_TITLE_ARE_YOU_SURE;
						let text = SWAL_WARNING_TEXT;
						switch (this.storeValue) {
							case SETUP_PERMISSION_STORE:
								this.emitObject(act, elementID);
								break;
							case SETUP_STRUCUTRE_PCTR_STORE:
								title = 'Warning';
								text = PCTR_DELETE_SWAL_TEXT;
								this.showWarningSwal(title, text, elementID, element);
								break;
							default:
								text = validateFor(DB_KEY_XNAME, element) ? SWAL_WARNING_INFO + `<b>${element.xName}</b>` + '. ' + '<br/>' + text : text;
								this.showWarningSwal(title, text, elementID, element);
								break;
						}
					}
					break;
				case 'view':
					// console.log("act.action", act.action, elementID);
					if (act.action === 'emit') {
						this.emitObject(act, elementID);
					} else {
						const link = [act.onClick, elementID];
						this.router.navigate(link);
					}
					break;
				// case 'toggle':
				// 	if (act.action === 'func' && act.onClick === 'toggle') {
				// 		if (act.action === 'func' && act.onClick === 'toggle') {
				// 			this.storeObj.toggle(element).subscribe(
				// 				(response: any) => {
				// 					// this.notificationService.success('Record status is Toggled!', 'Record Updated');
				// 				},
				// 				(error: any) => {
				// 					console.log('Inside Grid Component, toggle-subscribe Failure func', error);
				// 					// this.notificationService.error(errMsg, 'Record Not Updated!');
				// 				},
				// 				() => {
				// 					this.params.page = 1;
				// 					this.params.keyword = null;
				// 					this.params.limit = LIMIT;
				// 					this.displayGrid(JSON.parse(JSON.stringify(this.params)));
				// 				}
				// 			);
				// 		}
				// 		// *****************************************************************************
				// 	} else {
				// 		console.log('Inside else, Incorrect Function');
				// 	}
				// 	break;
				case 'modal':
					if (act.action === 'emit') {
						// console.log(
						//     "YES HERE",
						//     this.storeValue,
						//     this.isPendingItemSelected([elementID])
						// );
						if (this.storeValue === 'CsvFileHistoryStore') {
							console.log('value to be returned', element, elementID);
							console.log('csv event', act);
							if (act.onClick === 'viewStructure') {
								this.emitCsvHistoryStructure.emit(element.suggested_structure);
							} else if (act.onClick === 'viewImportResponse') {
								this.emitCsvHistoryImportResponse.emit(
									element.action_worker_queue_output && element.action_worker_queue_output.responseObj
										? element.action_worker_queue_output.responseObj
										: []
								);
							}
						} else if (!(this.storeValue === 'BatchStore' || this.storeValue === 'SettledBatchStore') || !this.isPendingItemSelected([elementID])) {
							console.log('No pending items selected....', act);
							if (act.value === TRANSACTION_ACTION_SETTLE || act.value === 'clean' || act.text === 'Permissions' || act.value === 'pay-now') {
								this.emitObject(act, elementID);
							} else if (act.value === 'reSync') {
								// console.log("Resync");
								this.obj.act = act;
								this.obj.id = elementID;
								this.obj.refNum = element.xRefNum;
								// console.log("Resync", JSON.parse(JSON.stringify(this.obj)));
								this.actionRequest.emit(this.obj);
							}
							this.actionPerformed = false;
							this.saveCheckedArray = [];
							this.saveCheckedArray.push(elementID);
						}
					} else {
						const link = [act.onClick, elementID];
						this.router.navigate(link);
					}
					break;
				case 'route':
					if (act && act.hasOwnProperty('idField')) {
						elementID = element[act['idField']];
					}
					if (!elementID) {
						const recordType = act.text.slice(3);
						this.notificationService.error(recordType + ' not found', 'Error!');
						return;
					}
					const link = [act.onClick, elementID];
					this.router.navigate(link);
					break;
				case 'select':
					customSwal
						.fire({
							title: `Are you sure, you want to Procced?`,
							text: 'All the data on this page will be lost!',
							icon: 'warning',
							showCancelButton: true,
							confirmButtonText: 'Yes, go ahead!',
							cancelButtonText: 'No, I changed my Mind!',
							reverseButtons: true,
						})
						.then((result) => {
							if (result.value) {
								this.emitObject(act, elementID);
							}
						});
					break;
				case GRID_ACTIONS.download:
					console.log('download: grid');
					this.emitObject(act, elementID);
					break;
				case 'suspend':
					customSwal
						.fire({
							title: `Are you sure, you want to Procced?`,
							text: 'You are about to suspend the billing for this company! Click Yes to suspend, No to decline. ',
							icon: 'warning',
							showCancelButton: true,
							confirmButtonText: 'Yes',
							cancelButtonText: 'No',
							reverseButtons: true,
						})
						.then((result) => {
							console.log('account suspended');
						})
						.catch((err) => console.log(err));
					break;
				case 'isPaid':
					customSwal
						.fire({
							title: `Are you sure, you want to Procced?`,
							text: `You are about to manually mark this company's status as paid for this month! Click Yes to continue, No to decline.`,
							icon: 'warning',
							showCancelButton: true,
							confirmButtonText: 'Yes',
							cancelButtonText: 'No',
							reverseButtons: true,
						})
						.then((result) => {
							console.log('amount paid');
						})
						.catch((err) => console.log(err));
					break;
				case 'payNow':
					console.log('pay now');
					console.log(this.obj.id);
					break;
				default:
					break;
			}
		}
		if (event) {
			event.stopPropagation();
		}
	} // process Action function Ends

	checkShiftPress(element: any, multiMode: boolean, event?: any) {
		let preCheckedElenents = this.viewPortItems.filter((item) => item._id === this.lastCheckedID);
		let preCheckedIndex = preCheckedElenents.length > 0 ? this.viewPortItems.indexOf(preCheckedElenents[0]) : -1;
		let currentCheckedIndex = this.viewPortItems.indexOf(element);
		if (preCheckedIndex === -1 || currentCheckedIndex === -1) {
			console.log("Cound not find transaction")
			return;
		}
		if (preCheckedIndex > currentCheckedIndex) {
			var firstIndex = currentCheckedIndex;
			var secondIndex = preCheckedIndex;
		} else {
			var firstIndex = preCheckedIndex;
			var secondIndex = currentCheckedIndex;
		}
		for (let i = firstIndex + 1; i < secondIndex; i++) {
			this.checkItem(this.viewPortItems[i],multiMode,event,true);
		}
	}


	checkItem(element: any, multiMode: boolean, event?: any,callbySelf = false) {
		if (event) {
			event.stopPropagation();
		}

		if(event && event.shiftKey && !callbySelf){
			this.checkShiftPress(element,multiMode,event);
		}
		if(!callbySelf){
			this.lastCheckedID = element._id;
		}

		if (this.showSelected && this.checkedItem.length > 0) {
			// console.log("event, id, type -------> ", this.checkedItem.indexOf(element._id));
			this.loading = true;
			this.error = false;
			this.disableViewBtn = true;
			if (this.selAll) {
				this.selAll = false;
				this.sel = 'sel_all';
			}
			element['isChecked'] = false;
			this.checkedItem.splice(this.checkedItem.indexOf(element._id), 1);
			console.log('view port items', this.viewPortItems);
			this.viewPortItems.splice(this.viewPortItems.indexOf(element), 1);
			let tempData = this.data.filter((singleData) => singleData._id !== element._id);
			this.data = [];
			// console.log("this.data.indexOf(element._id)",JSON.parse(JSON.stringify(tempData)));
			// console.log("this.data.indexOf(element._id) 1", this.viewPortItems);
			if (this.checkedItem.length > 0) {
				this.data = tempData.map((singleData) => singleData);
			} else {
				// console.log("HERE==>");
				this.selTxn = 0;
				this.selTxnAmt = 0.0;
				this.selMerAmt = 0.0;
				this.onShowSelectedClick();
				return;
			}

			this.loading = false;
			this.selTxn = this.selTxn - 1;
			if (!element.xIsVoided && element.xRspStatus !== TRANSACTION_ACTION_RESPONSE_STATUS_FAIL) {
				switch (element.xTxnActTag) {
					case TYPES.txnActionItem['sale']:
						this.selTxnAmt = this.selTxnAmt - this.getAmtInCents(element.xTtlAmt);
						this.selMerAmt = this.selMerAmt - (this.getAmtInCents(element.xTtlAmt) - this.getAmtInCents(element.xIncSurcAmt));
						break;
					case TYPES.txnActionItem['return']:
						this.selTxnAmt = this.selTxnAmt + this.getAmtInCents(element.xTtlAmt);
						this.selMerAmt = this.selMerAmt + this.getAmtInCents(element.xTtlAmt);
						break;

					default:
						// code...
						break;
				}
			}
			this.totTxnSelTxnObj = {
				totTxn: null,
				totTxnAmt: null,
				totalMerAmt: null,
				selTxn: this.selTxn,
				selTxnAmt: '$' + this.getAmtInDollars(this.selTxnAmt),
				selMerAmt: '$' + this.getAmtInDollars(this.selMerAmt),
			};
			if (this.checkedItem.length === 1) {
				let newArr = this.action.filter((act: any) => act.type === 'view');
				this.processAction(newArr[0], element._id);
				this.disableViewBtn = false;
			}
		} else {
			// console.log(this.desel, element['isChecked']);
			if (this.desel && element.hasOwnProperty('isChecked') && element.isChecked) {
				console.log('Start deselcting===>');
				if (this.selAllCheckbox && this.selAllCheckbox.hasOwnProperty('nativeElement')) {
					this.selAllCheckbox.nativeElement.indeterminate = true;
				}
				this.unCheckedItem.push(element._id);
				if (this.showSelected && this.selAll) {
					// console.log("HERE===>", this.data, this.unCheckedItem);
					this.viewPortItems.splice(this.viewPortItems.indexOf(element), 1);
					let tempData = this.data.filter((singleData) => singleData._id !== element._id);
					this.data = [];
					this.data = tempData.map((singleData) => singleData);
				} else {
					this.data = this.data.map((singleData) => {
						if (singleData._id === element._id) {
							singleData.isChecked = false;
						}
						return singleData;
					});
				}
				// console.log("This data", JSON.parse(JSON.stringify(this.data)), element.xTxnActTag);
				this.selTxn = this.selTxn - 1;
				if (!element.xIsVoided && element.xRspStatus !== TRANSACTION_ACTION_RESPONSE_STATUS_FAIL) {
					switch (element.xTxnActTag) {
						case TYPES.txnActionItem['sale']:
							this.selTxnAmt = this.selTxnAmt - this.getAmtInCents(element.xTtlAmt);
							this.selMerAmt = this.selMerAmt -(this.getAmtInCents(element.xTtlAmt)- this.getAmtInCents(element.xIncSurcAmt));
							break;
						case TYPES.txnActionItem['return']:
							this.selTxnAmt = this.selTxnAmt + this.getAmtInCents(element.xTtlAmt);
							this.selMerAmt = this.selMerAmt + this.getAmtInCents(element.xTtlAmt);

						default:
							// code...
							break;
					}
				}
				this.totTxnSelTxnObj['selTxn'] = this.selTxn;
				this.totTxnSelTxnObj['selTxnAmt'] = '$' + this.getAmtInDollars(this.selTxnAmt);
				this.totTxnSelTxnObj['selMerAmt'] = '$' + this.getAmtInDollars(this.selMerAmt);
				if (this.unCheckedItem.length === this.originalMetaData.totalRecords) {
					if (this.selAllCheckbox && this.selAllCheckbox.hasOwnProperty('nativeElement')) {
						this.selAllCheckbox.nativeElement.indeterminate = false;
					}
					this.treeService.setShow(true);
					this.toggleEnableTree(true);
					this.unCheckedItem = [];
					this.selAll = false;
					this.sel = 'sel_all';
					this.desel = false;
					this.showSelected = false;
					this.showSelectedCheckbox = false;
					this.data = this.originalData.map((singleData) => {
						// if(singleData._id === element._id){
						singleData.isChecked = false;
						// }
						return singleData;
					});
					this.hashedData = JSON.parse(JSON.stringify(this.originalHashedData));
					// console.log("HEREEEEEE", JSON.parse(JSON.stringify(this.originalTotTxnSelTxnObj)))
					this.totTxnSelTxnObj = JSON.parse(JSON.stringify(this.originalTotTxnSelTxnObj));
					this.totalTxn = this.originalMetaData && this.originalMetaData.hasOwnProperty('totalRecords') ? this.originalMetaData.totalRecords : 0;
					this.totalTxnAmt =
						this.originalMetaData && this.originalMetaData.hasOwnProperty('totalAmount')
							? this.getAmtInDollars(this.originalMetaData.totalAmount)
							: '$0.00';
					if (this.params.hasOwnProperty('selectAll')) delete this.params.selectAll;
				} else if (
					this.originalMetaData &&
					this.originalMetaData.hasOwnProperty('totalRecords') &&
					this.originalMetaData.totalRecords - this.unCheckedItem.length <= 100
				) {
					// console.log("yahan se true ho rha?", this.unCheckedItem.length, this.originalMetaData.totalRecords);
					this.showSelectedCheckbox = true;
				} else {
					this.showSelectedCheckbox = false;
				}

				// console.log("this.unchecked", this.unCheckedItem, this.checkedItem);
			} else {
				console.log('Start selecting===>');
				if (this.unCheckedItem.length && this.unCheckedItem.indexOf(element._id) >= 0) {
					this.unCheckedItem.splice(this.unCheckedItem.indexOf(element._id), 1);
				}
				if (
					this.selAll &&
					this.originalMetaData &&
					this.originalMetaData.hasOwnProperty('totalRecords') &&
					this.originalMetaData.totalRecords - this.unCheckedItem.length <= 100
				) {
					this.showSelectedCheckbox = true;
				} else {
					this.showSelectedCheckbox = false;
				}
				element['isChecked'] = true;
				if (this.selAll) {
					if (this.unCheckedItem.length === 0) {
						if (this.selAllCheckbox && this.selAllCheckbox.hasOwnProperty('nativeElement')) {
							this.selAllCheckbox.nativeElement.indeterminate = false;
						}
						this.selAll = true;
						this.sel = 'desel_all';
					} else if (this.unCheckedItem.length === this.data.length) {
						if (this.selAllCheckbox && this.selAllCheckbox.hasOwnProperty('nativeElement')) {
							this.selAllCheckbox.nativeElement.indeterminate = false;
						}
						this.selAll = false;
						this.sel = 'sel_all';
					}
					this.selTxn = this.selTxn + 1;
					if (!element.xIsVoided && element.xRspStatus !== TRANSACTION_ACTION_RESPONSE_STATUS_FAIL) {
						switch (element.xTxnActTag) {
							case TYPES.txnActionItem['sale']:
								this.selTxnAmt = this.selTxnAmt + this.getAmtInCents(element.xTtlAmt);
								this.selMerAmt = this.selMerAmt + this.getAmtInCents(element.xTtlAmt) - this.getAmtInCents(element.xIncSurcAmt);
								break;
							case TYPES.txnActionItem['return']:
								this.selTxnAmt = this.selTxnAmt - this.getAmtInCents(element.xTtlAmt);
								this.selMerAmt = this.selMerAmt - this.getAmtInCents(element.xTtlAmt);
								break;

							default:
								// code...
								break;
						}
					}
					this.totTxnSelTxnObj['selTxn'] = this.selTxn;
					this.totTxnSelTxnObj['selTxnAmt'] = '$' + this.getAmtInDollars(this.selTxnAmt);
					this.totTxnSelTxnObj['selMerAmt'] = '$' + this.getAmtInDollars(this.selMerAmt);
				} else {
					if (multiMode) {
						this.singleSelectMode = false;
					} else {
						this.singleSelectMode = true;
					}
					if (!this.singleSelectMode) {
						this.disableViewBtn = true;
						// console.log("here in multi selection mode");
						if (this.checkedItem.indexOf(element._id) === -1) {
							// if ((this.checkedItem.indexOf(id) === -1) && (this.selectedRowId.indexOf(id) === -1)) {
							// console.log("here in multi selection mode if");
							// this.checkState[id]=true;

							//@cary
							// let preBusinessID = this.businessService.businessID.getValue();
							// if(element.hasOwnProperty('xBusinessID')){
							// 	let currentBusinessID=element.xBusinessID;
							// 	if(preBusinessID != currentBusinessID){
							// 		console.log("business ID changed.",currentBusinessID);
							// 		// this.treeService.setId(currentBusinessID,'business');
							// 	}
							// }

							element['isChecked'] = true;

							this.checkedItem.push(element._id);
							// this.selectedRowId.push(id);
							// this.txnPctrIds.push(element.xPCtrID);
							// this.txnNumbers.push(element.xTxnNum);
							// this.amtsObjArr.push(amtObj);
							// this.types.push(obj);
							this.selTxn = this.selTxn + 1;
							if (!element.xIsVoided && element.xRspStatus !== TRANSACTION_ACTION_RESPONSE_STATUS_FAIL) {
								switch (element.xTxnActTag) {
									case TYPES.txnActionItem['sale']:
										this.selTxnAmt = this.selTxnAmt + this.getAmtInCents(element.xTtlAmt);
										this.selMerAmt = this.selMerAmt + this.getAmtInCents(element.xTtlAmt) - this.getAmtInCents(element.xIncSurcAmt);
										// totalSaleAmount += this.getAmtInCents(element.xTtlAmt);
										break;
									case TYPES.txnActionItem['return']:
										this.selTxnAmt = this.selTxnAmt - this.getAmtInCents(element.xTtlAmt);
										this.selMerAmt = this.selMerAmt - this.getAmtInCents(element.xTtlAmt);
										// totalRefundAmount += this.getAmtInCents(element.xTtlAmt);
										break;

									default:
										// code...
										break;
								}
							}
							// this.selTxnAmt=this.selTxnAmt + (parseFloat(element.xTtlAmt.substring(1)));
							this.totTxnSelTxnObj = {
								totTxn: this.totalTxn,
								totTxnAmt: this.totalTxnAmt,
								totalMerAmt: this.totalMerAmt,
								selTxn: this.selTxn,
								selTxnAmt: '$' + this.getAmtInDollars(this.selTxnAmt),
								selMerAmt: '$' + this.getAmtInDollars(this.selMerAmt),
							};
							if (this.checkedItem.length === 1) {
								let newArr = this.action.filter((act: any) => act.type === 'view');
								this.processAction(newArr[0], this.checkedItem[0]);
								this.disableViewBtn = false;
							}
						} else {
							console.log('here in multi selection mode else');
							// if(event){
							if (this.selAll) {
								this.selAll = false;
								this.sel = 'sel_all';
							}
							// this.checkState[id]=false;
							element['isChecked'] = false;

							this.checkedItem.splice(this.checkedItem.indexOf(element._id), 1);
							// this.selectedRowId.splice(this.selectedRowId.indexOf(id), 1);
							// this.txnPctrIds.splice(this.txnPctrIds.indexOf(element.xPCtrID), 1);
							// this.txnNumbers.splice(this.txnNumbers.indexOf(element.xTxnNum), 1);
							// this.amtsObjArr.splice(
							//     this.amtsObjArr.findIndex(obj => obj.id === element._id),
							//     1
							// );
							// // console.log(
							// //     "index --->",
							// //     this.types.findIndex(obj => obj.id === id)
							// // );
							// this.types.splice(this.types.findIndex(obj => obj.id === element._id), 1);
							this.selTxn = this.selTxn - 1;
							if (!element.xIsVoided && element.xRspStatus !== TRANSACTION_ACTION_RESPONSE_STATUS_FAIL) {
								switch (element.xTxnActTag) {
									case TYPES.txnActionItem['sale']:
										this.selTxnAmt = this.selTxnAmt - this.getAmtInCents(element.xTtlAmt);
										this.selMerAmt = this.selMerAmt - (this.getAmtInCents(element.xTtlAmt) - this.getAmtInCents(element.xIncSurcAmt));
										// totalSaleAmount += this.getAmtInCents(element.xTtlAmt);
										break;
									case TYPES.txnActionItem['return']:
										this.selTxnAmt = this.selTxnAmt + this.getAmtInCents(element.xTtlAmt);
										this.selMerAmt = this.selMerAmt + this.getAmtInCents(element.xTtlAmt);
										// totalRefundAmount += this.getAmtInCents(element.xTtlAmt);
										break;

									default:
										// code...
										break;
								}
							}
							// this.selTxnAmt=this.selTxnAmt - (parseFloat(element.xTtlAmt.substring(1)));
							this.totTxnSelTxnObj = {
								totTxn: this.totalTxn,
								totTxnAmt: this.totalTxnAmt,
								totalMerAmt: this.totalMerAmt,
								selTxn: this.selTxn,
								selTxnAmt: '$' + this.getAmtInDollars(this.selTxnAmt),
								selMerAmt: '$' + this.getAmtInDollars(this.selMerAmt),
							};
							if (this.checkedItem.length === 1) {
								let newArr = this.action.filter((act: any) => act.type === 'view');
								this.processAction(newArr[0], this.checkedItem[0]);
								this.disableViewBtn = false;
							}
							// }
						}

						// //Update selectedRowIds
						// if (this.selectedRowId.indexOf(id) === -1){
						//     this.selectedRowId.push(id);
						// }
						// else{
						//     this.selectedRowId.splice(this.selectedRowId.indexOf(id), 1);
						// }
					} else {
						// console.log("here in else single selection mode");
						this.disableViewBtn = false;
						if (this.checkedItem.indexOf(element._id) === -1) {
							// if ((this.checkedItem.indexOf(id) === -1) && (this.selectedRowId.indexOf(id) === -1)) {
							// Object.keys(this.checkState).map((key_id)=>{
							//     if(key_id === id)
							//         this.checkState[key_id]=true;
							//     else
							//         this.checkState[key_id]=false;

							// });
							if (this.checkedItem.length && this.data.filter((singleData) => this.checkedItem[0] === singleData._id).length) {
								delete this.data.filter((singleData) => this.checkedItem[0] === singleData._id)[0].isChecked;
							}
							element['isChecked'] = true;
							this.checkedItem = [];
							// this.selectedRowId=[];
							// this.txnPctrIds = [];
							// this.txnNumbers = [];
							// this.amtsObjArr = [];
							// this.types = [];
							this.selTxn = 0;
							this.selTxnAmt = 0.0;
							// console.log("this.totalTxn sel", this.totalTxn, this.totalTxnAmt);
							// console.log("this.totalTxn sel", this.totTxnSelTxnObj);
							// if(this.totTxnSelTxnObj && this.totTxnSelTxnObj.hasOwnProperty('selTxn') && this.totTxnSelTxnObj.hasOwnProperty('selTxnAmt')){
							//     this.totTxnSelTxnObj['selTxn']=null;
							//     this.totTxnSelTxnObj['selTxnAmt']=null;
							// }
							// else{
							//     this.totTxnSelTxnObj={
							//         totTxn: this.originalMetaData && this.originalMetaData.hasOwnProperty('totalRecords') ? this.originalMetaData.totalRecords : 0,
							//         totTxnAmt: this.originalMetaData && this.originalMetaData.hasOwnProperty('totalAmount') ? this.originalMetaData.totalAmount : 0.00,
							//         selTxn: 0,
							//         selTxnAmt: 0.00
							//     }
							// }
							// this.sel = 'desel_all';
							this.checkedItem.push(element._id);
							// this.selectedRowId.push(id);
							// this.txnPctrIds.push(element.xPCtrID);
							// this.txnNumbers.push(element.xTxnNum);
							// this.amtsObjArr.push(amtObj);
							// this.types.push(obj);
							this.selTxn = this.selTxn + 1;
							if (!element.xIsVoided && element.xRspStatus !== TRANSACTION_ACTION_RESPONSE_STATUS_FAIL) {
								switch (element.xTxnActTag) {
									case TYPES.txnActionItem['sale']:
										this.selTxnAmt = this.selTxnAmt + this.getAmtInCents(element.xTtlAmt);
										this.selMerAmt = this.selMerAmt + this.getAmtInCents(element.xTtlAmt) - this.getAmtInCents(element.xIncSurcAmt);
										// totalSaleAmount += this.getAmtInCents(element.xTtlAmt);
										break;
									case TYPES.txnActionItem['return']:
										this.selTxnAmt = this.selTxnAmt - this.getAmtInCents(element.xTtlAmt);
										this.selMerAmt = this.selMerAmt - this.getAmtInCents(element.xTtlAmt);
										// totalRefundAmount += this.getAmtInCents(element.xTtlAmt);
										break;

									default:
										// code...
										break;
								}
							}
							this.totTxnSelTxnObj = {
								totTxn: this.totalTxn,
								totTxnAmt: this.totalTxnAmt,
								totalMerAmt: this.totalMerAmt,
								selTxn: this.selTxn,
								selTxnAmt: '$' + this.getAmtInDollars(this.selTxnAmt),
								selMerAmt: '$' + this.getAmtInDollars(this.selMerAmt),
							};
						}
						// if (this.checkedItem.indexOf(id) === -1){
						//     // this.selectedRowId.push(id);
						// }
					}
				}
			}
		}
		// this._checkedItem= Observable.of(this.checkedItem);
		// console.log("HEREEEOPOPOP", this.checkedItem.length, this.selAll, this.unCheckedItem.length);
		if (this.checkedItem.length > 0 || this.selAll || (this.unCheckedItem.length !== 0 && this.unCheckedItem.length < this.originalMetaData.totalRecords)) {
			this.disableReportBtn = false;
		} else {
			this.disableReportBtn = true;
		}

		if (this.unCheckedItem.length === this.originalMetaData.totalRecords) {
			this.selAllCheckbox.nativeElement.indeterminate = false;
			this.selAll = false;
			this.disableReportBtn = true;
			this.disableViewBtn = true;
			this.sel = 'desel_all';
		}
		this.selectedTxnEvent.emit(this.totTxnSelTxnObj);
	}

	checkAll(type: string) {
		switch (type) {
			case 'sel_all':
				if (this.data.length !== 1) {
					this.disableViewBtn = true;
				} else {
					this.disableViewBtn = false;
				}
				this.selAll = true;
				this.desel = true;
				this.singleSelectMode = false;
				this.params['selectAll'] = true;
				// this.params['']
				this.checkedItem = [];
				if (this.originalMetaData && this.originalMetaData.hasOwnProperty('totalRecords') && this.originalMetaData.hasOwnProperty('totalRecords')) {
					if (this.data.length && this.originalMetaData.totalRecords <= 100) {
						this.showSelectedCheckbox = true;
					} else {
						this.showSelectedCheckbox = false;
					}
				}

				console.log('ORIGINAL META DATA', this.originalMetaData);
				this.totalTxn = this.originalMetaData && this.originalMetaData.hasOwnProperty('totalRecords') ? this.originalMetaData.totalRecords : 0;
				this.totalTxnAmt =
					this.originalMetaData && this.originalMetaData.hasOwnProperty('totalAmount')
						? '$' + this.getAmtInDollars(this.originalMetaData.totalAmount)
						: '$0.00';
				this.selTxn = this.originalMetaData && this.originalMetaData.hasOwnProperty('totalRecords') ? this.originalMetaData.totalRecords : 0;
				this.selTxnAmt = this.originalMetaData && this.originalMetaData.hasOwnProperty('totalAmount') ? this.originalMetaData.totalAmount : 0.0;
				this.selMerAmt = this.originalMetaData && this.originalMetaData.hasOwnProperty('totalMerAmount') ? this.originalMetaData.totalMerAmount : 0.0;
			
				this.totTxnSelTxnObj = {
					totTxn: this.totalTxn,
					totTxnAmt: this.totalTxnAmt,
					totalMerAmt: this.totalMerAmt,
					selTxn: this.selTxn,
					selTxnAmt: '$' + this.getAmtInDollars(this.selTxnAmt),
					selMerAmt: '$' + this.getAmtInDollars(this.selMerAmt),
				};
				// this.txnPctrIds = [];
				// this.txnNumbers = [];
				// this.amtsObjArr = [];
				// this.types = [];
				// this.selTxn=0;
				// this.selTxnAmt=0;
				// this.totTxnSelTxnObj={
				//     totTxn: this.totalTxn,
				//     totTxnAmt: this.totalTxnAmt,
				//     selTxn: 0,
				//     selTxnAmt: 0
				// }
				// let totalSaleAmount = 0;
				// let totalRefundAmount = 0;
				this.data.map((element) => {
					element['isChecked'] = true;
				});

				// this.selTxnAmt = totalSaleAmount - totalRefundAmount;
				// console.log("this.totalTxn sel", this.totalTxn, this.totalTxnAmt);
				// console.log("this.totalTxn sel", this.totTxnSelTxnObj);
				// if(this.totTxnSelTxnObj && this.totTxnSelTxnObj.hasOwnProperty('selTxn') && this.totTxnSelTxnObj.hasOwnProperty('selTxnAmt')){
				//     this.totTxnSelTxnObj['selTxn']=null;
				//     this.totTxnSelTxnObj['selTxnAmt']=null;
				// }
				// else{
				//     this.totTxnSelTxnObj={
				//         totTxn: this.originalMetaData && this.originalMetaData.hasOwnProperty('totalRecords') ? this.originalMetaData.totalRecords : 0,
				//         totTxnAmt: this.originalMetaData && this.originalMetaData.hasOwnProperty('totalAmount') ? this.originalMetaData.totalAmount : 0.00,
				//         selTxn: null,
				//         selTxnAmt: null
				//     }
				// }
				// this.totTxnSelTxnObj={
				//     totTxn: this.totalTxn,
				//     totTxnAmt: this.totalTxnAmt,
				//     selTxn: null,
				//     selTxnAmt: null
				// }
				this.disableReportBtn = false;
				this.sel = 'desel_all';
				break;
			case 'desel_all':
				if (this.selAllCheckbox && this.selAllCheckbox.hasOwnProperty('nativeElement')) {
					this.selAllCheckbox.nativeElement.indeterminate = false;
				}
				this.selAll = false;
				this.desel = false;
				this.singleSelectMode = true;
				this.showSelectedCheckbox = false;
				if (this.params.hasOwnProperty('selectAll')) {
					delete this.params.selectAll;
				}
				this.data.map((singleData) => {
					if (singleData.hasOwnProperty('isChecked')) {
						delete singleData['isChecked'];
					}
				});
				this.selTxn = 0;
				this.selTxnAmt = 0.0;
				this.selMerAmt = 0.0;
				// console.log("this.totalTxn desel", this.totalTxn, this.totalTxnAmt);
				// console.log("this.totalTxn sel", this.totalTxn, this.totalTxnAmt);
				// console.log("this.totalTxn sel", this.totTxnSelTxnObj);
				if (this.totTxnSelTxnObj && this.totTxnSelTxnObj.hasOwnProperty('selTxn') && this.totTxnSelTxnObj.hasOwnProperty('selTxnAmt')) {
					this.totTxnSelTxnObj['selTxn'] = null;
					this.totTxnSelTxnObj['selTxnAmt'] = null;
					this.totTxnSelTxnObj['selMerAmt'] = null;
				} else {
					this.totTxnSelTxnObj = {
						totTxn: this.originalMetaData && this.originalMetaData.hasOwnProperty('totalRecords') ? this.originalMetaData.totalRecords : 0,
						totTxnAmt:
							this.originalMetaData && this.originalMetaData.hasOwnProperty('totalAmount')
								? '$' + this.getAmtInDollars(this.originalMetaData.totalAmount)
								: '$0.00',
						selTxn: null,
						selTxnAmt: null,
						selMerAmt: null,
					};
				}
				this.checkedItem = [];
				this.unCheckedItem = [];
				// this.selectedRowId = [];
				this.txnPctrIds = [];
				this.txnNumbers = [];
				this.amtsObjArr = [];
				this.types = [];
				this.disableReportBtn = true;
				this.disableViewBtn = true;
				this.sel = 'sel_all';
				break;
			// case "desel":
			//
		}
		// this._checkedItem= Observable.of(this.checkedItem);
		// console.log("this.totObj", this.totTxnSelTxnObj);
		this.summaryReportService.setCheckedObj(this.types);
		this.selectedTxnEvent.emit(this.totTxnSelTxnObj);
	}

	getAmtInCents(amt) {
		amt = amt.substring(1); //Remove "$"
		let l = amt.length;
		if ((parseFloat(amt) * 100).toString().length !== l) return parseInt(Math.round(parseFloat(amt) * 100).toString());
		else return parseInt((parseFloat(amt) * 100).toString());
	}

	getAmtInDollars(amt) {
		return (parseInt(amt) / 100).toFixed(2);
	}

	emitObject(action: any, elementID: string) {
		this.obj.act = action;
		this.obj.id = elementID;
		let data = this.originalData.find((element) => element._id === this.obj.id);
		this.obj.data = data;
		this.actionRequest.emit(this.obj);
	}

	isDeleteOperationAllowed() {
		const roles = this.userHandlerService.userRoles;
		return !(
			(this.storeValue === 'SetupCompanyStore' && roles.isDealer) ||
			(this.storeValue === 'SetupBusinessInformationStore' && roles.isBusinessEmployee)
		);
	}

	async delete(elementID: string, elementData: any) {
		console.log('elementData: dlete: grid--->', elementData);
		console.log('elementID: delete: grid--->', elementID);
		console.log('storeValue: dlete: grid--->', this.storeValue);

		if (this.storeValue === SETUP_BILLING_STORE || this.storeValue === SETUP_INVOICE_STORE) {
			this.continueDeleteAction(elementID, elementData, this.storeValue);
		} else {
			let node: string = '';

			let checkTxnBatchQuery = {
				id: elementID,
				nodeType: this.storeValue,
			};

			let shouldCheckHasCustomer = false;
			let customerQuery = {
				limit: 0,
				xBusinessID: validateFor('xBusinessID', elementData) ? elementData.xBusinessID : '',
			};

			switch (this.storeValue) {
				case SETUP_BUSINESS_STRUCTURE_STORE:
					shouldCheckHasCustomer = true;
					switch (elementData.xTypeTag) {
						case TYPES.stru.propertyTags:
							checkTxnBatchQuery['structType'] = node = TYPES.stru.propertyTags;
							break;
						case TYPES.stru.regionTags:
							checkTxnBatchQuery['structType'] = node = TYPES.stru.regionTags;
							break;
						case TYPES.stru.chainTags:
							checkTxnBatchQuery['structType'] = node = TYPES.stru.chainTags;
							break;
					}
					customerQuery['struct_type'] = checkTxnBatchQuery['structType'];
					customerQuery['structID'] = validateFor('_id', elementData) ? elementData._id : '';
					break;
				case SETUP_BUSINESS_INFO_STORE:
					node = 'Business';
					shouldCheckHasCustomer = true;
					customerQuery['xBusinessID'] = validateFor('_id', elementData) ? elementData._id : '';
					break;
				case SETUP_BUSINESS_STRUCTURE_STORE_SETUP_STORE:
					node = 'Store';
					shouldCheckHasCustomer = true;
					customerQuery['storeIDs'] = validateFor('_id', elementData) ? [elementData._id] : '';
					break;
				case SETUP_COMPANY_STORE:
					node = 'Company';
					shouldCheckHasCustomer = true;
					delete customerQuery['xBusinessID'];
					customerQuery['xCompanyID'] = elementData._id;
					break;
				// case SETUP_STRUCTURE_TERMINAL_STORE:
				//     this.overlayText = OVERLAY_CHECKING_NODE_TEXT;
				//     try{
				//         parentPCTRID = validateFor(DB_XPCTERMINALS_PCTRID_KEY,elementData)
				//             ? elementData[DB_XPCTERMINALS_PCTRID_KEY]
				//             : '';
				//         const message = await this.canTerminalBeDeleted(parentPCTRID);
				//         if(message){
				//             console.log('delete: swal to be shown with the provided message',message);
				//             setTimeout(()=>{
				//                 this.showOverLay = false;
				//                 swal('Not Allowed!', message, 'error');
				//             },SWAL_DISPLAY_DELAY);
				//             return;
				//         }
				//     } catch(err){
				//         console.log('delete: canTerminalBeDeleted failed with err',err);
				//         this.notificationService.error(CANNOT_DELETE_TERMINAL_API_FAILED,'Error');
				//     }
				//     break;
				default:
					console.log('Reached default case: checkCustomers');
					break;
			}

			console.log('shouldCheckHasCustomer: delete: grid--->', shouldCheckHasCustomer);
			if (shouldCheckHasCustomer) {
				this.overlayText = OVERLAY_CHECKING_ASSOCIATED_CUSTOMER_TEXT;
				let message = CAN_NOT_DELETE_NODE_MESSAGE_FIRST_PART + node + '.' + CAN_NOT_DELETE_NODE_MESSAGE_SECOND_PART + node + '.';
				console.log('node: delete: grid--->', node);
				console.log('CAN_NOT_DELETE_NODE_MESSAGE: delete: grid--->', message);
				try {
					let hasCustomer = await this.checkCustomers(customerQuery);
					console.log('hasCustomer: delete: grid--->', hasCustomer);
					if (hasCustomer) {
						console.log('swal should be shown');
						this.showOverLay = false;
						//Swal will not be displayed without setTimeout. There should be a delay present between successive swals.
						setTimeout(() => {
							customSwal.fire({
								title: 'Not Allowed!',
								text: message,
								icon: 'error',
								confirmButtonText: 'Okay',
							});
						}, 250);
						return;
					}
				} catch (error) {
					console.log('error: delete: grid--->', error);
					console.log('node: delete: grid--->', node);
					this.showOverLay = false;
					this.notificationService.error(CUSTOMER_CHECK_FAILED_MESSAGE, 'Error');
					return;
				}
			}
			if (this.storeValue === STORE_NAMES.setupSecurityRolesStore) {
				this.handleRoleDeletion(elementData._id);
				return;
			}
			this.checkTxnBatches(checkTxnBatchQuery, elementID, elementData, this.storeValue);
		}
	}

	emailAddressSwal(elementID: string, element: any){
		const defaultEmails = 'default@example.com,another@example.com';
		customSwal.fire({
            title: 'Enter Email Addresses',
			text: 'Please enter email addresses separated by ,',
            input: 'textarea',
			inputValue: defaultEmails,
            inputPlaceholder: 'Enter email addresses separated by ,',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            preConfirm: (value) => {
                const emails = value.split(',').map(email => email.trim());
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const invalidEmails = emails.filter(email => !emailPattern.test(email));

                if (invalidEmails.length > 0) {
                    return customSwal.showValidationMessage(`Invalid email address(es): ${invalidEmails.join(', ')}`);
                }

                return emails;
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const validEmails = result.value;
                console.log('Valid emails:', validEmails);
				this.doNotifyCustomer(elementID,validEmails);
            }
        });
	}


	/**
	 * Show warnging swal
	 * @param `title`: string: Title of swal
	 * @param `swalText`: string: Text to be displayed as info
	 * @param  `elementID`: mongoID: ID of node that will be deleted
	 * @param `element`: Element data that will be deleted
	 */
	showSuspendIsPaidWarningSwal(title: string, swalText: string, elementID: string, element: any, type: string) {
		console.log('title: showWarningSwal', title);
		console.log('swalText: showWarningSwal', swalText);
		console.log('elementID: showWarningSwal', elementID);
		console.log('element: showWarningSwal', element);
		// if (this.storeValue === SETUP_BILLING_STORE) {
		// 	const specifiedDate = element['xRunningStatus'].toString();
		// 	//create date is not early than 180 days
		// 	if (specifiedDate === 'Deactived') {
		// 		this.notificationService.warning('Record has been deactived.','Deactive');
		// 		return;
		// 	}
		// }
		customSwal
			.fire({
				title: title,
				html: swalText + ' Click Yes to continue, No to decline',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				reverseButtons: true,
			})
			.then((result) => {
				if (result.value) {
					console.log('swal confirmed');
					this.showOverLay = true;
					if (type === 'isPaid') {
						this.overlayText = OVERLAY_PERFORMING_PAID;
						this.hasPaid(elementID, element);
					} else if (type === 'unsuspend') { //@cary billing
						this.overlayText = OVERLAY_PERFORMING_UNSUSPENDED;
						this.doUnSuspend(elementID, element);
					}else if (type === 'suspend') {
						this.overlayText = OVERLAY_PERFORMING_SUSPENDED;
						this.doSuspend(elementID, element);
					}else if (type === 'disable') {
						this.overlayText = OVERLAY_PERFORMING_DISABLE;
						this.doDisable(elementID, element);
					}else if (type === 'runnow') {
						this.overlayText = OVERLAY_PERFORMING_RUNNOW;
						this.doRunnow(elementID, element);
					}else if (type === 'payInvoiceNow') {
						this.overlayText = OVERLAY_PERFORMING_RUNNOW;
						this.doPayInvoiceNow(elementID, element);
					}
				} else {
					console.log('swal cancelled');
					setTimeout(() => {
						customSwal.fire({
							title: 'Cancelled',
							text: 'Selected record is safe',
							icon: 'error',
							confirmButtonText: 'Okay',
						});
					}, SWAL_DISPLAY_DELAY);
				}
			});
	}

	hasPaid(elementID: string, elementData: any) {
		console.log('elementData: hasPaid: grid--->', elementData);
		console.log('elementID: hasPaid: grid--->', elementID);
		console.log('storeValue: hasPaid: grid--->', this.storeValue);

		if (this.storeValue === SETUP_BILLING_STORE || this.storeValue === SETUP_INVOICE_STORE) {
				console.log('Inside Store billing hasPaid function in grid');
				this.storeObj
					.patch(elementID, {
						xIsPaid: true,
						xPaymentStatus: 'Manually Paid',
						// xBillingState: 'Manually Paid',
						xIsSuspended: false,
						xSuspendState: 'Active',
						xFinalBillingDate: new Date(),
						xIsCurrent: false,
						isManualPatching: true,
					})
					.subscribe(
						(response: any) => {
							console.log('response inside hasPaid: billinglist', response);
							this.showOverLay = false;
							customSwal.fire({
								title: 'Paid!',
								text: 'Selected invoice payment status has been set to paid.',
								icon: 'success',
								confirmButtonText: 'Okay',
							});
							const companyID = this.companyService.companyID.getValue();
							this.companyService.setCompanyID(companyID);
						},
						(error) => {
							console.log('Inside Billing Paid Error: ', error);
							this.showOverLay = false;
							const errorMessage = error.message ? error.message : JSON.parse(error._body).message;
							customSwal.fire({
								title: 'Error!',
								text: errorMessage,
								icon: 'error',
								confirmButtonText: 'Okay',
							});
							const companyID = this.companyService.companyID.getValue();
							this.companyService.setCompanyID(companyID);
						}
					);
				return;
		}
	}

	doSuspend(elementID: string, elementData: any) {
		console.log('elementData: suspend: grid--->', elementData);
		console.log('elementID: suspend: grid--->', elementID);
		console.log('storeValue: suspend: grid--->', this.storeValue);

		if (this.storeValue === SETUP_BILLING_STORE || this.storeValue === SETUP_INVOICE_STORE) {
				console.log('Inside Store billing doSuspend function in grid');
				this.storeObj
					.patch(elementID, {
						xIsSuspended: true,
						xSuspendState: 'Manually Suspended',
					})
					.subscribe(
						(response: any) => {
							console.log('response inside doSuspend: billinglist', response);
							this.showOverLay = false;
							customSwal.fire({
								title: 'Suspended!',
								text: 'Selected company has been suspended.',
								icon: 'success',
								confirmButtonText: 'Okay',
							});
							const companyID = this.companyService.companyID.getValue();
							this.companyService.setCompanyID(companyID);
						},
						(error) => {
							console.log('Inside Billing Suspend Error: ', error);
							this.showOverLay = false;
							const errorMessage = error.message ? error.message : JSON.parse(error._body).message;
							customSwal.fire({
								title: 'Error!',
								text: errorMessage,
								icon: 'error',
								confirmButtonText: 'Okay',
							});
							const companyID = this.companyService.companyID.getValue();
							this.companyService.setCompanyID(companyID);
						}
					);
				return;
		}
	}

	// @cary billing
	doUnSuspend(elementID: string, elementData: any) {
		console.log('elementData: un-suspend: grid--->', elementData);
		console.log('elementID: un-suspend: grid--->', elementID);
		console.log('storeValue: un-suspend: grid--->', this.storeValue);

		if (this.storeValue === SETUP_BILLING_STORE || this.storeValue === SETUP_INVOICE_STORE) {
			console.log('Inside Store billing un-suspend function in grid');
			this.storeObj
				.patch(elementID, {
					xIsSuspended: false,
					xSuspendState: 'Active',
				})
				.subscribe(
					(response: any) => {
						console.log('response inside un-suspend: billinglist', response);
						this.showOverLay = false;
						customSwal.fire({
							title: 'Un-Suspended!',
							text: 'Selected company has been un-suspended.',
							icon: 'success',
							confirmButtonText: 'Okay',
						});
						const companyID = this.companyService.companyID.getValue();
						this.companyService.setCompanyID(companyID);
					},
					(error) => {
						console.log('Inside Billing Un-Suspend Error: ', error);
						this.showOverLay = false;
						const errorMessage = error.message ? error.message : JSON.parse(error._body).message;
						customSwal.fire({
							title: 'Error!',
							text: errorMessage,
							icon: 'error',
							confirmButtonText: 'Okay',
						});
						const companyID = this.companyService.companyID.getValue();
						this.companyService.setCompanyID(companyID);
					}
				);
			return;
		}
	}	

	doNotifyCustomer(elementID: string, emailAddress: any) {
		// console.log('elementData:doNotifyCustomer: grid--->', elementData);
		console.log('elementID: doNotifyCustomer: grid--->', elementID);
		console.log('storeValue: doNotifyCustomer: grid--->', this.storeValue);

		if (this.storeValue === SETUP_BILLING_STORE || this.storeValue === SETUP_INVOICE_STORE) {
			console.log('Inside Store billing doNotifyCustomer function in grid');
			this.storeObj
				.patch(elementID, {
					requestType: 'notifyCustomer',
					emailAddress: emailAddress,
				})
				.subscribe(
					(response: any) => {
						console.log('response inside doNotifyCustomer: billinglist', response);
						this.showOverLay = false;
						customSwal.fire({
							title: 'Send Summary Report!',
							text: 'System will send invoice summary report to listed email addresses.',
							icon: 'success',
							confirmButtonText: 'Okay',
						});
						const companyID = this.companyService.companyID.getValue();
						this.companyService.setCompanyID(companyID);
					},
					(error) => {
						console.log('Inside Billing doNotifyCustomer Error: ', error);
						this.showOverLay = false;
						const errorMessage = error.message ? error.message : JSON.parse(error._body).message;
						customSwal.fire({
							title: 'Error!',
							text: errorMessage,
							icon: 'error',
							confirmButtonText: 'Okay',
						});
						const companyID = this.companyService.companyID.getValue();
						this.companyService.setCompanyID(companyID);
					}
				);
			return;
		}
	}
		

	doPayInvoiceNow(elementID: string, elementData: any) {
		console.log('elementData:doPayInvoiceNow: grid--->', elementData);
		console.log('elementID: doPayInvoiceNow: grid--->', elementID);
		console.log('storeValue: doPayInvoiceNow: grid--->', this.storeValue);

		if (this.storeValue === SETUP_BILLING_STORE || this.storeValue === SETUP_INVOICE_STORE) {
			console.log('Inside Store billing doRunnow function in grid');
			this.storeObj
				.patch(elementID, {
					payInvoiceNow: true,
				})
				.subscribe(
					(response: any) => {
						console.log('response inside doPayInvoiceNow: billinglist', response);
						this.showOverLay = false;
						customSwal.fire({
							title: 'Pay Now!',
							text: 'Selected record will be charged immediately.This will take a few minutes, please check payment status of the record later.',
							icon: 'success',
							confirmButtonText: 'Okay',
						});
						const companyID = this.companyService.companyID.getValue();
						this.companyService.setCompanyID(companyID);
					},
					(error) => {
						console.log('Inside Billing doPayInvoiceNow Error: ', error);
						this.showOverLay = false;
						const errorMessage = error.message ? error.message : JSON.parse(error._body).message;
						customSwal.fire({
							title: 'Error!',
							text: errorMessage,
							icon: 'error',
							confirmButtonText: 'Okay',
						});
						const companyID = this.companyService.companyID.getValue();
						this.companyService.setCompanyID(companyID);
					}
				);
			return;
		}
	}
	
	doRunnow(elementID: string, elementData: any) {
		console.log('elementData:doRunnow: grid--->', elementData);
		console.log('elementID: doRunnow: grid--->', elementID);
		console.log('storeValue: doRunnow: grid--->', this.storeValue);

		if (this.storeValue === SETUP_BILLING_STORE || this.storeValue === SETUP_INVOICE_STORE) {
			console.log('Inside Store billing doRunnow function in grid');
			this.storeObj
				.patch(elementID, {
					// runnow: true,
					xBillingState: "Approved",
				})
				.subscribe(
					(response: any) => {
						console.log('response inside un-suspend: billinglist', response);
						this.showOverLay = false;
						customSwal.fire({
							title: 'Approve!',
							text: 'Selected Invoice is approved and will enter the subsequent processing.',
							icon: 'success',
							confirmButtonText: 'Okay',
						});
						const companyID = this.companyService.companyID.getValue();
						this.companyService.setCompanyID(companyID);
					},
					(error) => {
						console.log('Inside Billing doRunnow Error: ', error);
						this.showOverLay = false;
						const errorMessage = error.message ? error.message : JSON.parse(error._body).message;
						customSwal.fire({
							title: 'Error!',
							text: errorMessage,
							icon: 'error',
							confirmButtonText: 'Okay',
						});
						const companyID = this.companyService.companyID.getValue();
						this.companyService.setCompanyID(companyID);
					}
				);
			return;
		}
	}
	
	doDisable(elementID: string, elementData: any) {
		console.log('elementData: do Disable: grid--->', elementData);
		console.log('elementID: do Disable: grid--->', elementID);
		console.log('storeValue: do Disable: grid--->', this.storeValue);

		if (this.storeValue === SETUP_BILLING_STORE) {
			console.log('Inside Store billing do Disable function in grid');
			this.storeObj
				.patch(elementID, {
					xIsVoided: true,
					xRunningStatus: 'Deactived',
				})
				.subscribe(
					(response: any) => {
						console.log('response inside do Disable: billinglist', response);
						this.showOverLay = false;
						customSwal.fire({
							title: 'Deactive!',
							text: 'Selected record has been deactived.',
							icon: 'success',
							confirmButtonText: 'Okay',
						});
						const companyID = this.companyService.companyID.getValue();
						this.companyService.setCompanyID(companyID);
					},
					(error) => {
						console.log('Inside Billing Disable: ', error);
						this.showOverLay = false;
						const errorMessage = error.message ? error.message : JSON.parse(error._body).message;
						customSwal.fire({
							title: 'Error!',
							text: errorMessage,
							icon: 'error',
							confirmButtonText: 'Okay',
						});
						const companyID = this.companyService.companyID.getValue();
						this.companyService.setCompanyID(companyID);
					}
				);
			return;
		}else if (this.storeValue === SETUP_INVOICE_STORE) {
			console.log('Inside Store billing do Disable function in grid');
			this.storeObj
				.patch(elementID, {
				})
				.subscribe(
					(response: any) => {
						console.log('response inside do Disable: billinglist', response);
						this.showOverLay = false;
						customSwal.fire({
							title: 'Deactive!',
							text: 'Selected record has been deactived.',
							icon: 'success',
							confirmButtonText: 'Okay',
						});
						const companyID = this.companyService.companyID.getValue();
						this.companyService.setCompanyID(companyID);
					},
					(error) => {
						console.log('Inside Billing Disable: ', error);
						this.showOverLay = false;
						const errorMessage = error.message ? error.message : JSON.parse(error._body).message;
						customSwal.fire({
							title: 'Error!',
							text: errorMessage,
							icon: 'error',
							confirmButtonText: 'Okay',
						});
						const companyID = this.companyService.companyID.getValue();
						this.companyService.setCompanyID(companyID);
					}
				);
			return;
		}
	}

	/**
	 * Show warnging swal
	 * @param `title`: string: Title of swal
	 * @param `swalText`: string: Text to be displayed as info
	 * @param  `elementID`: mongoID: ID of node that will be deleted
	 * @param `element`: Element data that will be deleted
	 */
	showWarningSwal(title: string, swalText: string, elementID: string, element: any) {
		console.log('title: showWarningSwal', title);
		console.log('swalText: showWarningSwal', swalText);
		console.log('elementID: showWarningSwal', elementID);
		console.log('element: showWarningSwal', element);
		customSwal
			.fire({
				title: title,
				html: swalText + ' Click Yes to continue, No to decline',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				reverseButtons: true,
			})
			.then((result) => {
				if (result.value) {
					console.log('swal confirmed');
					this.showOverLay = true;
					this.delete(elementID, element);
				} else {
					console.log('swal cancelled');
					setTimeout(() => {
						customSwal.fire({
							title: 'Cancelled',
							text: 'Selected record is safe',
							icon: 'error',
							confirmButtonText: 'Okay',
						});
					}, SWAL_DISPLAY_DELAY);
				}
			});
	}

	/**
	 * Check for transaction batch
	 * @param `checkTxnBatchQuery`: object: query with nodeType and elementID
	 * @param `elementID`: mongo ID: mongod id of element,
	 * @param `elementData`: data related to element to be deleted
	 */
	checkTxnBatches(checkTxnBatchQuery: object, elementID: string, elementData: object, storeValue: string) {
		console.log('checkTxnBatchQuery: checkTxnBatches', checkTxnBatchQuery);
		console.log('checkTxnBatchQuery: elementID', elementID);
		console.log('checkTxnBatchQuery: storeValue', storeValue);
		this.overlayText = OVERLAY_CHECKING_ASSOCIATED_TXN_TEXT;
		this.httpService.store(CHECK_TXN_BATCHES_END_POINT, checkTxnBatchQuery).subscribe(
			async (actionWorkerResponse) => {
				console.log('actionWorkerResponse: checkTxnBatches', actionWorkerResponse);
				let actionID = validateFor(ACTION_WORKER.action_id, actionWorkerResponse) ? actionWorkerResponse[ACTION_WORKER.action_id] : '';
				this.actionWorkerService
					.pollActionWorker(actionID)
					.then(async (queueResponse: any) => {
						if (!validateFor(BE_KEY.success, queueResponse)) {
							this.showOverLay = false;
							let message = validateFor(BE_KEY.message, queueResponse) ? queueResponse[BE_KEY.message] : CHECK_TXN_BATCH_FAILED_ERROR_MESSAGE;
							customSwal.fire({
								title: SWAL_OPERATION_FAILED,
								text: message,
								icon: 'error',
								confirmButtonText: 'Ok',
							});
							return;
						}
						console.log('queueResponse: checkTxnBatches', queueResponse);
						let responseObj = validateFor(ACTION_WORKER.responseObj, queueResponse) ? queueResponse[ACTION_WORKER.responseObj] : {};
						if (responseObj && validateFor(BE_KEY.data, responseObj)) {
							let respData = responseObj[BE_KEY.data];
							const notAllowedSwal = Swal.mixin({
								customClass: {
									cancelButton: 'sa2-cancel-btn',
									confirmButton: 'sa2-confirm-btn',
									content: 'no-padding',
								},
								buttonsStyling: false,
							});
							if (validateFor(BE_KEY.error, respData) && respData[BE_KEY.error]) {
								console.log('overlay set to false');
								this.showOverLay = false;
								let warningClass = validateFor(CHECK_TXN_BATCH_KEY_CLASS, respData) ? respData[CHECK_TXN_BATCH_KEY_CLASS] : '';
								if (!warningClass) {
									this.showOverLay = false;
									this.notificationService.error(ASSOCIATED_TXN_CHECK_FAILED_MESSAGE, ERROR);
									return;
								}
								switch (warningClass) {
									case CHECK_TXN_BATCH_CLASS.notAllowed:
										notAllowedSwal.fire({
											title: 'Not Allowed!',
											text: respData.message,
											icon: 'error',
											confirmButtonText: 'Okay',
										});
										break;
									case CHECK_TXN_BATCH_CLASS.warning:
										this.handleDeleteActionForConfirm(respData, elementID, elementData, storeValue);
										break;
									default:
										console.log('reached default case: no warning class');
										break;
								}
							}
						} else {
							this.continueDeleteAction(elementID, elementData, storeValue);
						}
					})
					.catch((queueError) => {
						console.log('queueError: checkTxnBatches', queueError);
						let message = validateFor(BE_KEY.message, queueError) ? queueError[BE_KEY.message] : ACTION_WORKER_FAIL_MSG;
						this.notificationService.error(message, ERROR);
						this.showOverLay = false;
					});
			},
			(checkTxnBatchError) => {
				console.log('Error while checking transactions', checkTxnBatchError);
				this.notificationService.error(CHECK_TXN_BATCH_FAILED_ERROR_MESSAGE, ERROR);
				this.showOverLay = false;
			}
		);
	}

	/**
	 * Check store. Create query and perform delete action. Set respective overlay message.
	 * @param {string} elementID
	 * @param {object} elementData
	 * @memberof GridComponent
	 */
	async continueDeleteAction(elementID: string, elementData: any, storeValue: string) {
		console.log('this.storeValue:::', this.storeValue);
		console.log('STORE_NAMES.customerStore:::', STORE_NAMES.customerStore);
		// return;
		switch (this.storeValue) {
			case SETUP_STRUCUTRE_PCTR_STORE:
				console.log('pctr delete called');
				this.overlayText = OVERLAY_FETCHING_REQUIRED_INFO_TEXT;
				try {
					this.setPCtrNameBeforeDelete(elementData);
					await this.performPCTRDeleteAction(elementID);
				} catch (error) {
					this.showOverLay = false;
					this.notificationService.error(UNABLE_TO_PERFORM_DELETE_ACTION_MSG, 'Error');
				}
				return;
			case MEMBER_ACCOUNT_STORE:
				this.overlayText = OVERLAY_PERFORMING_DELETE_ACTION_TEXT;
				let xAcctTok = validateFor(DB_XACCT_TOKEN_KEY, elementData) ? elementData[DB_XACCT_TOKEN_KEY] : '';
				if (!xAcctTok) {
					this.notificationService.error(NO_ACCOUNT_TOKEN_PRESENT_ERROR_MSG, 'Error');
					return;
				}
				let deleteSafeQuery = {
					xAcctTok: [xAcctTok],
					deleteQuery: {
						_id: elementID,
					},
				};
				this.finalDeleteOperation(deleteSafeQuery);
				return;
			case STORE_NAMES.customerStore:
				try {
					this.overlayText = 'Please wait. Checking if this customer is present in billed business!';
					const customerQuery = {
						xVendorCustomerID: elementID,
						limit: 0,
					};
					console.log('checkCustomers customerquery:::', customerQuery);
					let hasCustomer = await this.checkCustomers(customerQuery);
					console.log('hasCustomer: delete: grid--->', hasCustomer);
					console.log('checkCustomers customerquery:::', customerQuery);
					if (hasCustomer) {
						console.log('checkCustomers customerquery:::', customerQuery);
						this.overlayText = 'Please wait. Patching customer in billed business with vendor customer id!';
						console.log('getExistingOriginalCustomer customerquery:::', customerQuery);
						const getPatchingCustomerID: string = await this.getExistingOriginalCustomer(customerQuery);
						console.log('getPatchingCustomerID:::', getPatchingCustomerID);
						if (getPatchingCustomerID !== '0') {
							let patchData = {};
							if(this.userHandlerService.userRoles.isVendor){
								patchData = {
									xVendorCustomerID: null,
								}
							}else{
								patchData = {
									xDealerCustomerID: null,
								}
							}

							this.setupBillingCustomerStore
								.patch(getPatchingCustomerID, patchData)
								.subscribe(
									(response: any) => {
										console.log('response inside original customerstore patched:::', response);
										this.overlayText = OVERLAY_PERFORMING_DELETE_ACTION_TEXT;
										this.finalDeleteOperation(elementID);
										return;
									},
									(error: any) => {
										console.log('response inside original customerstore patched error::: ', error);
										this.showOverLay = false;
										const errorMessage = error.message ? error.message : JSON.parse(error._body).message;
										customSwal.fire({
											title: 'Error!',
											text: errorMessage,
											icon: 'error',
											confirmButtonText: 'Okay',
										});
										const companyID = this.companyService.companyID.getValue();
										this.companyService.setCompanyID(companyID);
									}
								);
						} else {
							this.overlayText = OVERLAY_PERFORMING_DELETE_ACTION_TEXT;
							this.finalDeleteOperation(elementID);
							return;
						}
					} else {
						this.overlayText = OVERLAY_PERFORMING_DELETE_ACTION_TEXT;
						this.finalDeleteOperation(elementID);
						return;
					}
				} catch (error) {
					console.log('error: delete: grid--->', error);
					this.showOverLay = false;
					this.notificationService.error(CUSTOMER_CHECK_FAILED_MESSAGE, 'Error');
					return;
				}
				break;
			default:
				this.overlayText = OVERLAY_PERFORMING_DELETE_ACTION_TEXT;
				this.finalDeleteOperation(elementID);
				break;
		}
	}

	/**
	 * Set pCtrToBeDeleted with profit center name on which
	 * delete action is being performed
	 *  @param {object} pCtrData pctr which will be deleted
	 */
	setPCtrNameBeforeDelete(pCtrData: any): void {
		this.pCtrToBeDeleted = validateFor(DB_KEY_XNAME, pCtrData) ? pCtrData[DB_KEY_XNAME] : '';
	}

	/**
	 * Show warning swal if closed Txn exists. Proceed to delete if user clicks confirm.
	 *
	 * @param {object}  respData  response data from action worker responseObj
	 * @param {string}  elementID id of element on which action is performed
	 */
	handleDeleteActionForConfirm(respData: any, elementID: string, elementData: any, storeValue: string) {
		customSwal
			.fire({
				title: 'Warning',
				text: respData.message + ' Click Yes to continue, No to decline',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				reverseButtons: true,
			})
			.then((result) => {
				if (result.value) {
					this.showOverLay = true;
					this.continueDeleteAction(elementID, elementData, storeValue);
				} else {
					this.showOverLay = false;
					setTimeout(() => {
						customSwal.fire({
							title: 'Cancelled',
							text: 'Selected record is safe',
							icon: 'error',
							confirmButtonText: 'Okay',
						});
					}, SWAL_DISPLAY_DELAY);
				}
			});
	}

	/**
	 * Set query amd check for reperucssion data of pctr.
	 * @param pctrID: string: mongo-id of pctr
	 */
	async performPCTRDeleteAction(pctrID: string) {
		return new Promise(async (resolve, reject) => {
			let params = {
				id: pctrID,
				node: SETUP_STRUCUTRE_PCTR_STORE,
			};
			this.pctrID = pctrID;
			try {
				//Await for fetching pctr associated data and return. Procced when user clicks on procced in modal.
				await this.checkRepercussionDataBeforeDelete(params);
				return resolve(true);
			} catch (error) {
				console.log('repercussion thrown error', error);
				this.notificationService.error(UNABLE_TO_PERFORM_DELETE_ACTION_MSG, 'Error');
				return reject(error);
			}
		});
	}

	/**
	 * Check for associated nodes below a node. Show them in a modal.
	 * @param  `params`: object: contains node type and id of node
	 * @returns boolean
	 */
	checkRepercussionDataBeforeDelete(params: any = {}): Promise<boolean> {
		console.log('checkRepercussionDataBeforeDelete called: params', params);
		return new Promise((resolve, reject) => {
			this.httpService.store(DELETE_REPERCUSSION_LIST_END_POINT, params).subscribe(
				(repercussionResponse) => {
					this.showOverLay = false;
					console.log('repercussionResponse: checkRepercussionDataBeforeDelete', repercussionResponse);
					this.deleteRepercussionData = Object.keys(repercussionResponse).length ? repercussionResponse : {};
					this.showPctrDeleteModal = true;
					return resolve(true);
				},
				(repercussionError) => {
					this.showOverLay = false;
					console.log('repercussionError: checkRepercussionDataBeforeDelete', repercussionError);
					return reject(repercussionError);
				}
			);
		});
	}

	/**
	 * Proceed to delete on click of proceed from delete modal
	 * @param: `event`: boolean
	 */
	onProccedToDeletePctrClick(event: boolean) {
		console.log('onProccedToDeletePctrClick', event);
		if (event) {
			console.log('onProccedToDeletePctrClick: pctrID', this.pctrID);
			this.showPctrDeleteModal = false;
			this.showOverLay = true;
			this.overlayText = OVERLAY_PERFORMING_DELETE_ACTION_TEXT;
			this.finalDeleteOperation(this.pctrID);
		}
	}

	/**
	 * Check if customer is associated with any node. Prevent delete action if associated.
	 * @param query : object : query required to check if customer is associated with any node
	 * @param elementData : object: node-data on which delete action is being performed
	 * @returns boolean: True if customer is associated with selected node
	 * 					 False if not.
	 */
	checkCustomers(query: any = {}) {
		console.log('customerQuery: checkCustomers: grid--->', query);
		return new Promise((resolve, reject) => {
			this.setupBillingCustomerStore.getAll(query).subscribe(
				(customerResponse) => {
					console.log('customer get api response: checkCustomers: grid --->', customerResponse);
					console.log('has meta: check customer--->', validateFor('meta', customerResponse));
					console.log('has total records: check customer--->', validateFor('totalRecords', customerResponse.meta));
					// console.log(
					// 	'resolving true if this is true--->',
					// 	validateFor('meta', customerResponse) &&
					// 		customerResponse.meta.hasOwnProperty('totalRecords') &&
					// 		customerResponse.meta.totalRecords
					// );
					// console.log('using validate for--->', validateFor('totalRecords', customerResponse.meta));
					// console.log(
					// 	'using hasOwnProperty--->',
					// 	customerResponse.meta.hasOwnProperty('totalRecords') && customerResponse.meta.totalRecords
					// );
					if (validateFor('meta', customerResponse) && validateFor('totalRecords', customerResponse.meta)) {
						return resolve(true);
					}
					return resolve(false);
				},
				(error) => {
					console.log('customer get api error: checkCustomers: grid--->', error);
					return reject(error);
				}
			);
		});
	}

	getExistingOriginalCustomer(query: any = {}): Promise<string> {
		console.log('getExistingOriginalCustomer customerquery:::', query);
		return new Promise((resolve, reject) => {
			this.setupBillingCustomerStore.getAll(query).subscribe(
				(customerResponse) => {
					console.log('customer get api response: checkCustomers: grid --->', customerResponse);
					console.log('has meta: check customer--->', validateFor('meta', customerResponse));
					console.log('has total records: check customer--->', validateFor('totalRecords', customerResponse.meta));
					if (validateFor('data', customerResponse) && customerResponse.data.length) {
						const id = customerResponse.data[0]._id;
						return resolve(id);
					}
					return resolve('0');
				},
				(error) => {
					console.log('customer get api error: checkCustomers: grid--->', error);
					return reject(error);
				}
			);
		});
	}

	/**
	 * checks if terminal can be deleted
	 * 1. if parent pctr has active safes linked to it, and the terminal to be deleted is the last terminal under that pctr, then the terminal cannot be deleted
	 *
	 * @params : `parentPCTRID` : string : mongoID of parent pctr for which safes are to be checked
	 * @return : `message` : Promise<string> : if message is empty no issue, else return issue to be shown
	 */
	// canTerminalBeDeleted(parentPCTRID:string):Promise<string>{
	//     return new Promise((resolve,reject)=>{
	//         console.log('canTerminalBeDeleted: parentPCTRID',parentPCTRID);
	//         // console.log('canTerminalBeDeleted: elementData',elementData);
	//         // let parentPCTRID = validateFor(DB_XPCTERMINALS_PCTRID_KEY,elementData) ? elementData[DB_XPCTERMINALS_PCTRID_KEY] : '';
	//         if(!parentPCTRID){
	//             return resolve(CANNOT_DELETE_TERMINAL_NO_PCTRID_MSG)
	//         }
	//         let safeCountQuery = {
	//             [DB_XPCSAFE_PCTRID_KEY] : parentPCTRID,
	//             limit: 0
	//         }
	//         this.memberAccountStore.getAll(safeCountQuery)
	//             .subscribe(safeFetchResponse => {
	//                 console.log('canTerminalBeDeleted:safeFetchResponse',safeFetchResponse);
	//                 let safeTotalRecords = getTotalRecordCount(safeFetchResponse);
	//                 console.log('canTerminalBeDeleted:safeTotalRecords',safeTotalRecords);
	//                 if(!safeTotalRecords){
	//                     return resolve('');
	//                 }
	//                 let terminalCountQuery = {
	//                     [DB_XPCTERMINALS_PCTRID_KEY] : parentPCTRID,
	//                     limit: 0
	//                 }
	//                 this.terminalStore.getAll(terminalCountQuery)
	//                     .subscribe(terminalCountResponse => {
	//                         console.log('canTerminalBeDeleted:terminalCountResponse',terminalCountResponse);
	//                         let terminalTotalRecords = getTotalRecordCount(terminalCountResponse);
	//                         console.log('canTerminalBeDeleted:terminalTotalRecords',terminalTotalRecords);
	//                         if(terminalTotalRecords === 1){
	//                             return resolve(CANNOT_DELETE_TERMINAL_SAFE_EXIST_AND_LAST_TERM_MSG)
	//                         }
	//                         return resolve('');
	//                     },
	//                     terminalCountError => {
	//                         console.log('canTerminalBeDeleted:terminalCountError',terminalCountError);
	//                         return reject(terminalCountError);
	//                     });
	//             },
	//             safeFetchError => {
	//                 console.log('canTerminalBeDeleted:safeFetchError',safeFetchError);
	//                 return reject(safeFetchError);
	//             });
	//         // return resolve('');
	//     })
	// }

	/**
	 * Delete selected node.
	 * @param `queryElement`: string or object: query-object of member-accounts delete. mongo-id for rest of the nodes.
	 */
	finalDeleteOperation(queryElement: any) {
		// if(isConfirm){
		if (this.storeValue === SETUP_BILLING_STORE || this.storeValue === SETUP_INVOICE_STORE) {
			console.log('Inside Store billing final delete operation');
			this.storeObj.patch(queryElement,{'xStatus':-1}).subscribe(
			// this.storeObj.destroy(queryElement).subscribe(
				(response: any) => {
					console.log('response inside finaldeleteoperation: billinglist', response);
					this.showOverLay = false;
					customSwal.fire({
						title: 'Deleted!',
						text: 'Selected record has been deleted.',
						icon: 'success',
						confirmButtonText: 'Okay',
					});
					const companyID = this.companyService.companyID.getValue();
					this.companyService.setCompanyID(companyID);
				},
				(error) => {
					console.log('Inside Billing Delete Error: ', error);
					this.showOverLay = false;
					const errorMessage = error.message ? error.message : JSON.parse(error._body).message;
					customSwal.fire({
						title: 'Error!',
						text: errorMessage,
						icon: 'error',
						confirmButtonText: 'Okay',
					});
					const companyID = this.companyService.companyID.getValue();
					this.companyService.setCompanyID(companyID);
				}
			);
			return;
		}
		console.log('Also Outside Store billing final delete operation');
		this.storeObj.destroy(queryElement).subscribe(
			(response: any) => {
				console.log('response inside finalDeleteOperation', response);
				let deleteTimer = setInterval(() => {
					this.httpService.get(API_END_POINT.action_worker_queues, response['action_id']).subscribe(
						(res) => {
							console.log('res===>', res);
							if (res.hasOwnProperty('action_progress') && res.action_progress >= 100) {
								clearInterval(deleteTimer);
								this.showOverLay = false;
								if (res.hasOwnProperty('status') && res['status'] === 'Failed') {
									console.log('Deletion failed');
									let title = SWAL_OPERATION_FAILED;
									let message = res.action_output.message;
									if (res.action_output.message.includes(SWAL_SAFE_FAIL_MSG)) {
										title = res.action_output.message.includes(SWAL_SAFE_FAIL_MSG) ? SWAL_SAFE_FAIL_MSG : SWAL_OPERATION_FAILED;
										let splitMessage = message.split(SWAL_SAFE_FAIL_MSG);
										/* NOTE:
										 * The below logic is that BE sends a message and list all combined in a string
										 * when split is run on message the 1st element is empty and 2md element has the list
										 * this 2nd element is displayed
										 * but if anything goes wrong show the entire message whatever it maybe
										 */
										message = splitMessage.length === 2 ? splitMessage[1] : message;
									}
									const deleteFailSwal = Swal.mixin({
										customClass: {
											confirmButton: 'sa2-confirm-btn',
											title: 'safe-fail-title',
											icon: 'mb-0',
										},
										buttonsStyling: false,
									});
									deleteFailSwal.fire({
										title: title,
										html: `<p class='safe-fail-content'>${message}</p>`,
										icon: 'error',
										confirmButtonText: 'Okay',
									});
								} else {
									console.log('Successfully deleted');
									if (this.storeValue === 'SetupSecurityRolesStore' && response.data.isRoleInUse) {
										customSwal.fire({
											title: 'Operation not permited!',
											text: 'Selected record can not be deleted as it is in use.',
											icon: 'error',
											confirmButtonText: 'Okay',
										});
									} else {
										customSwal.fire({
											title: 'Deleted!',
											text: 'Selected record has been deleted.',
											icon: 'success',
											confirmButtonText: 'Okay',
										});
									}
									console.log("I'm inside delete complete in grid");
									this.businessService.toggleRefreshTree();
									//Refresh company/business list to be reflected in Topnav
									if (this.storeValue === 'SetupCompanyStore') {
										this.companyService.toggleRefreshCompany();
										this.companyService.resetCompanyID();
									}

									if (this.storeValue === 'SetupBusinessInformationStore') {
										this.businessService.toggleRefreshBusinessList();
										this.businessService.resetBusinessID();
										this.treeService.resetBusinessID();
									}

									if (this.storeValue === 'SetupCompanyRepresentativeStore' || this.storeValue === 'SetupBusinessEmployeeStore') {
										this.storeObj.setEndPoint('advanced');
									}

									this.params.page = 1;
									this.params.keyword = null;
									this.params.limit = LIMIT;
									// console.log("here 5");
									this.displayGrid(JSON.parse(JSON.stringify(this.params)));
								}
							}
						},
						(err) => {
							console.log('Delete Action Error', err);
						}
					);
				}, 5000);
				// this.showOverLay = false;
				// if (
				//     this.storeValue === "SetupSecurityRolesStore" &&
				//     response.data.isRoleInUse
				// ) {
				//     swal(
				//         "Operation not permited!",
				//         "Selected record can not be deleted as it is in use.",
				//         "error"
				//     );
				// } else {
				//     swal(
				//         "Deleted!",
				//         "Selected record has been deleted.",
				//         "success"
				//     );
				// }
			},
			(error: any) => {
				console.log("I'm inside delete error in grid", error);
				//  else {
				this.showOverLay = false;
				const errorMessage = error.message ? error.message : JSON.parse(error._body).message;
				if (errorMessage.includes('Cast to ObjectId failed')) {
					this.errMsg = 'No such record exists';

					// this.notificationService.error(this.errMsg, elementID + ' Not Found!');
				} else if (!errorMessage.includes(NO_PERMISSION_MSG)) {
					this.errMsg = 'Un-expected Error';
					// this.notificationService.error(this.errMsg, ' Some error occured!');
					customSwal.fire({
						title: 'Error!',
						text: errorMessage,
						icon: 'error',
						confirmButtonText: 'Okay',
					});
				}
				// }
				// swal('Error!', 'Something went wrong.', 'error');
				this.loading = true;
				this.params.page = 1;
				this.params.keyword = null;
				this.params.limit = LIMIT;
				// console.log("here inside error");
				this.businessService.toggleRefreshTree();
				this.displayGrid(JSON.parse(JSON.stringify(this.params)));
			},
			() => {}
		);
	}
	// }

	sortTable(n, colName, nestedKey) {
		// Zorrik cannot keep skip counter
		if (this.sorting) {
			//For import txns, to show status in grid, its created from constant service.
			//In order to run sort on it, colName should be the one present in model
			if (colName === IMPORT_TXN_FILE_PORCESSING_STATUS_KEY) {
				colName = IMPORT_TXN.outputFilePath;
			}
			this.params.page = 1;
			this.commonSortFunction(n, true);
			this.sortedBy = colName;

			if (colName === 'netTotal' || colName === 'serviceCharge') {
				// When column to be sorted is net Total, then don't do anything for now
				// let newdata = JSON.parse(JSON.stringify(this.constantService.sortCompleteData(this.data, this.storeValue, this.order[n], colName, nestedKey)));
				// this.data=[];
				// this.data = JSON.parse(JSON.stringify(newdata));
				// this.loading=false;
			} else {
				this.data = [];
				this.params['sortKey'] = colName;
				this.params['page'] = 1; //To bring back the initial number of records after sorting.
				this.completeLoad = false;
				// delete this.params['$skip'];
				if (this.order[n] === 'none') {
					this.params['sortType'] = 1;
				} else {
					this.params['sortType'] = this.order[n] === 'asc' ? 1 : -1;
				}
				this.displayGrid(JSON.parse(JSON.stringify(this.params)), false, true);
			}
		}
	}

	frontEndSortTable(isShowSelected, colDetail, n) {
		console.log('HEREEEE in fE sort');
		if (
			isShowSelected &&
			colDetail.header !== 'Actions' &&
			colDetail.header !== 'Sel. All' &&
			colDetail.header !== 'Action_State' &&
			colDetail.mapTo !== 'netTotal' &&
			colDetail.mapTo !== 'serviceCharge'
		) {
			console.log('Here in sortTableFrontEnd=> isShowSelected', n, colDetail);
			this.commonSortFunction(n);
			let sortedData = this.constantService.sortCompleteData(JSON.parse(JSON.stringify(this.data)), this.order[n], colDetail.mapTo, colDetail.nestedKey);
			this.data = [];
			this.data = sortedData.map((singleData) => singleData);
			// this.viewPortItems
			this.error = false;
			this.loading = false;
		} else {
			console.log('Here in sortTableFrontEnd=> not isShowSelected', n, colDetail);
		}
	}

	commonSortFunction(n, backendSort: boolean = false) {
		this.order = this.order.map((colOrder, index) => {
			if (index === n) {
				return colOrder;
			} else {
				return 'none';
			}
		});
		// console.log("this.order[n]", this.order[n]);
		if (this.order[n] === 'dsc' || this.order[n] === 'none') {
			this.order[n] = 'asc';
			if (backendSort) this.sortInOrder = 'asc';
		} else if (this.order[n] === 'asc') {
			this.order[n] = 'dsc';
			if (backendSort) this.sortInOrder = 'dsc';
		}
		this.error = false;
		this.loading = true;
	}

	onShowDetailsClick(event: Event) {
		event.preventDefault();
		this.detailVisible.emit(true);
	}

	onShowDisabledClick(event: Event) {
		event.preventDefault();
		this.disabledVisible.emit(this.disabledChecked);
	}

	onNewClick(event: Event) {
		event.preventDefault();
		this.newClick.emit(true);
	}

	onShowSummaryClick(event: Event) {
		event.preventDefault();
		if (this.selAll) this.setCheckedObjInSummaryReportService(true);
		else this.setCheckedObjInSummaryReportService();
		this.viewSummary.emit(true);
	}

	setCheckedObjInSummaryReportService(isSelectAll: boolean = false) {
		this.types = [];
		if (isSelectAll) {
			this.summaryReportService.selectAll = true;
			// console.log("Original params", this.originalParams);
			this.summaryReportService.parameters = JSON.parse(JSON.stringify(this.originalParams));
			this.data.map((singleData) => {
				if (this.unCheckedItem.indexOf(singleData._id) >= 0) {
					const obj = {
						id: singleData._id,
						txnNums: singleData.xTxnNum,
						refNums: singleData.xRefNum,
						type: singleData.xTypeTag,
					};
					this.types.push(obj);
				}
			});
			this.summaryReportService.setUnCheckedObj(this.types);
		} else {
			this.summaryReportService.selectAll = false;
			this.data.map((singleData) => {
				if (this.checkedItem.indexOf(singleData._id) >= 0) {
					const obj = {
						id: singleData._id,
						txnNums: singleData.xTxnNum,
						refNums: singleData.xRefNum,
						type: singleData.xTypeTag,
					};
					this.types.push(obj);
				}
			});
			this.summaryReportService.setCheckedObj(this.types);
		}
	}

	onExportSummaryClick(event: Event){
		event.preventDefault();
		this.disableExportSummary = true;
		// if(this.completeLoad && 1 === this.originalParams.page){
		if(false){

			console.log("no need ask original data from host");
			this.processExportSummary(this.originalData);
		}else{
			console.log("ask original data from host");
			let params = this.originalParams;
			params.page = 1
			params.limit = 5000;
			let observableBatchRecords = [];
			observableBatchRecords.push(
				this.httpService
				.getAll("xpeBatches", params)
			);
	
			forkJoin(observableBatchRecords).subscribe((response: any) => {
				console.log(response);
				let data = response[0].data;
				this.constantService
				.updateData(this.storeValue, data)
				.then((updatedData) => {
					this.processExportSummary(data);
				})
			})
		}		
		this.disableExportSummary = false;
	}

	processExportSummary(recordList = []){
		let settleDate, openDate, settleAmt, serviceCharge,merchantAmt,despoitAmt, storeID, batchNum, storeName, storeNumber;
		let storeInfoMap = new Map();
		// let findString = "StoreName,StoreNum,BatchNum,OpenDate,SettleDate,SettleTotal,NetTotal \n";
		let findString = "Store Name,Store Number,Batch Number,Open Date(MM/DD),Settle Date(MM/DD),Service Charge,Merchant Amount,Settle Amount \n";
		let observable = [];
		let storeIDArr = [];

		recordList.map(record => {
			if(storeIDArr.indexOf(record.xStoreID) === -1){
				storeIDArr.push(record.xStoreID);
			}
		});
		observable.push(
			this.httpService
				.getAll("xbcStores", {storeIds: storeIDArr.toString(), limit:5000})
		);
		forkJoin(observable).subscribe(
			(response: any) => {
				console.log(response);
				response[0].data.map(record=>{
					storeInfoMap.set(record._id, record);
				})
			this.originalData.map(record=>{
				// openDate = JSON.stringify(record.xOpenDate).substring(0,4) + "/" + record.xOpenDateTime;
				// settleDate = JSON.stringify(record.xSettleDate).substring(0,4) + "/" + record.xSettleDateTime;

				openDate = record.xOpenDateTime;
				settleDate = record.xSettleDateTime;
				settleAmt = record.SaleTotal ? '$' + (parseInt(record.SaleTotal) / 100).toFixed(2) : '$0.00';
				merchantAmt = record.merTotal;
				serviceCharge = record.serviceCharge;
				despoitAmt = record.netTotal;
				batchNum = record.xBatchNum;
				storeID = record.xStoreID
				if(storeInfoMap.has(storeID)){
					let storeInfo = storeInfoMap.get(storeID);
					if(storeInfo){
						storeName = storeInfo.xName;
						storeNumber = storeInfo.xStoreNum;
					}
				}

				findString += storeName + ",";
				findString += storeNumber + ",";
				findString += batchNum + ",";
				findString += openDate + ",";
				findString += settleDate + ",";
				// findString += settleAmt + ",";
				findString += serviceCharge + ",";
				findString += merchantAmt + ",";
				findString += despoitAmt + "\n";
		})
			console.log(findString);
			var blob = new Blob([findString], { type: "text/csv;charset=utf-8;" });
			var url = URL.createObjectURL(blob);
		  
			var pom = document.createElement('a');
			pom.href = url;
			pom.setAttribute('download', "SummaryReport.csv");
			pom.click();
		},
			error => {}
		)
	}

	onRefreshClick(event: Event) {
		event.preventDefault();
		this.refreshClick = true;
		this.isPreviousActionSettle = false;
		// console.log("this.refreshGrid", this.refreshGrid);
		this.refresh.emit(true);
	}

	onReSyncClick(event: Event) {
		event.preventDefault();
		this.isPreviousActionSettle = false;
		// console.log("this.refreshGrid", this.refreshGrid);
		this.reSync.emit(true);
	}

	onResetSelectionClick(event?: Event) {
		if (this.selAllCheckbox && this.selAllCheckbox.hasOwnProperty('nativeElement')) {
			this.selAllCheckbox.nativeElement.indeterminate = false;
		}
		this.showSelected = false;
		this.showSelectedCheckbox = false;
		this.checkedItem = [];
		this.unCheckedItem = [];
		this.checkAll('desel_all');
		// this._checkedItem= Observable.of(this.checkedItem);
		this.summaryReportService.setCheckedObj([]);
		this.summaryReportService.setUnCheckedObj([]);
		this.summaryReportService.selectAll = false;
		this.singleSelectMode = true;
		this.disableReportBtn = true;
		this.disableViewBtn = true;
		this.selAll = false;
		this.sel = 'sel_all';
		// console.log("this.totTxnSelTxnObj", this.totTxnSelTxnObj, JSON.parse(JSON.stringify(this.originalTotTxnSelTxnObj)));
		// this.totTxnSelTxnObj= JSON.parse(JSON.stringify(this.originalTotTxnSelTxnObj));
		this.totalTxn = this.originalTotTxnSelTxnObj.totTxn;
		this.totalTxnAmt = this.originalTotTxnSelTxnObj.totTxnAmt;
		this.selTxn = 0;
		this.selTxnAmt = 0.0;
		this.totTxnSelTxnObj = {
			totTxn: this.totalTxn,
			totTxnAmt: this.totalTxnAmt,
			totalMerAmt: this.totalMerAmt,
			selTxn: this.selTxn,
			selTxnAmt: '$' + this.getAmtInDollars(this.selTxnAmt),
		};
		// console.log("this.totTxnSelTxnObj after", this.totTxnSelTxnObj);
		this.selectedTxnEvent.emit(this.totTxnSelTxnObj);
		if (event) {
			console.log('HEREEEEEEEEE1234');
			this.treeService.setShow(true);
			this.toggleEnableTree(true);
			this.data = [];
			this.data = this.originalData.map((singleData) => {
				singleData['isChecked'] = false;
				return singleData;
			});
			if (this.data && !this.data.length) {
				this.disableViewBtn = true;
				this.disableRefreshButton = true;
				this.error = true;
				this.gridMessage = this.getGridMessage();
			}
			this.hashedData = [];
			this.hashedData = JSON.parse(JSON.stringify(this.originalHashedData));
			this.params = JSON.parse(JSON.stringify(this.originalParams));
			// console.log("this.params====>", JSON.parse(JSON.stringify(this.params)), this.viewPortItems, JSON.parse(JSON.stringify(this.data)));
			// console.log("this.originalMetaData===>", this.originalMetaData);
			this.metaData = this.originalMetaData;
			// console.log("this.originalMetaData===>", JSON.parse(JSON.stringify(this.metaData)));
			// console.log("this.originalMetaData===>1", this.metaData.totalRecords === this.data.length);

			if (this.metaData.totalRecords === this.data.length) {
				this.completeLoad = true;
				// console.log("this.complete", this.completeLoad);
			} else {
				this.completeLoad = false;
			}
			this.loading = false;
		}
	}

	/**
	 * resets filters, params and data
	 *
	 * @params : `fetchData` : boolean : call displayGrid function to fetch data after resetting
	 */
	onCompleteResetClick(fetchData: boolean) {
		// console.log(this.params, this.unCheckedItem,this.checkedItem, this.selAll);
		// if(this.selAllCheckbox && this.selAllCheckbox.hasOwnProperty('nativeElement')){
		//     this.selAllCheckbox.nativeElement.indeterminate=false;
		// }
		console.log('onCompleteResetClick:fetchData', fetchData);
		this.checkAll('desel_all');
		this.keyword = '';
		this.isPreviousActionSettle = false;
		this.resetAdvancedFilterForm = true;
		this.advancedFilterFormDataToBeReused = {};
		// this.advancedFilterFormData={};
		this.setSortOrderToInitial();
		if (this.params && this.params.hasOwnProperty('keyword')) {
			this.params.keyword = null;
		}
		if (this.params && this.params.hasOwnProperty('page')) {
			this.params.page = 1;
		}
		if (this.params && this.params.hasOwnProperty('skip')) {
			this.params.skip = 0;
		}
		if (this.params && this.params.hasOwnProperty('sortKey')) {
			delete this.params.sortKey;
		}
		if (this.params && this.params.hasOwnProperty('sortType')) {
			delete this.params.sortType;
		}
		if (this.params && this.params.hasOwnProperty('advanceKeyword')) {
			delete this.params.advanceKeyword;
		}
		this.loading = true;
		this.error = false;
		this.data = [];
		this.hashedData = [];
		if (fetchData) {
			this.displayGrid(JSON.parse(JSON.stringify(this.params)));
		}
		// if(this.params && this.params.hasOwnProperty('sortKey')){
		//     delete this.params.sortKey;
		// }
	}

	onShowSelectedClick(event?: Event) {
		console.log('HERE CALLED 2 times');
		this.showSelectedClicked = true;
		this.loading = true;
		this.error = false;
		this.data = [];
		this.isPreviousActionSettle = false;
		if (!event) {
			this.showSelected = false;
			this.toggleEnableTree(true);
		}
		this.showFilter = false;
		this.showAdvancedSearch = false;

		if (this.showSelected) {
			this.toggleEnableTree(false);
			// this.setSortOrderToInitial(true);
			// console.log("Show Selected", this.showSelected);
			// console.log("Show Selected checkedItem", this.checkedItem);
			// console.log("Show Selected params", this.params);
			if (!this.selAll) {
				this.params['txnIds'] = this.checkedItem.toString();
			} else {
				this.params['excludeIDs'] = this.unCheckedItem.toString();
				this.params['selectAll'] = true;
			}
			this.params['page'] = 1;
			this.params['limit'] = 100;
			if (!this.selAll) {
				if (this.params.hasOwnProperty('advanceKeyword')) delete this.params.advanceKeyword;
				if (this.params.hasOwnProperty('xStoreID')) delete this.params.xStoreID;
				if (this.params.hasOwnProperty('xPCtrID')) delete this.params.xPCtrID;
				if (this.params.hasOwnProperty('xMerAcctID')) delete this.params.xMerAcctID;
				if (this.params.hasOwnProperty('xTerminalID')) delete this.params.xTerminalID;
				if (this.params.hasOwnProperty('xPeriphID')) delete this.params.xPeriphID;
				if (this.params.hasOwnProperty('keyword')) this.params.keyword = null;
				if (this.params.hasOwnProperty('hashData')) delete this.params.hashData;
			}
			// if (this.params.hasOwnProperty("sortKey"))
			//     delete this.params.sortKey;
			// if (this.params.hasOwnProperty("sortType"))
			//     delete this.params.sortType;
			// console.log("Show Selected checkedItem", this.checkedItem);
			// console.log("Show Selected params 2", this.params);
			// this.params['fetchAll']
			this.displayGrid(JSON.parse(JSON.stringify(this.params)), false, true);
		} else {
			// if(!this.params.hasOwnProperty('xCustomerID')){
			//     this.treeService.setShow(true);
			//     this.toggleEnableTree(true);
			// }
			console.log(this.txn_mode, 'txn_mode:onShowSelectedClick: grid ', 'storeValue', this.storeValue);
			if (this.txn_mode !== 'CUSTOMER') {
				this.treeService.setShow(true);
				this.toggleEnableTree(true);
			}

			// console.log("Show Selected", this.showSelected, this.viewPortItems, this.data);
			this.setSortOrderToInitial(true);
			this.data = [];
			this.totTxnSelTxnObj = JSON.parse(JSON.stringify(this.originalTotTxnSelTxnObj));
			// console.log("totTxnSelTxnObj", this.totTxnSelTxnObj);
			this.totalTxn = this.originalTotTxnSelTxnObj.totTxn;
			this.totalTxnAmt = this.originalTotTxnSelTxnObj.totTxnAmt;
			this.totalMerAmt = this.originalTotTxnSelTxnObj.totalMerAmt;
			if (this.selAll && this.unCheckedItem.length === this.totalTxn) {
				// console.log("HERE BRO");
				this.selTxn = 0;
				this.selTxnAmt = 0.0;
				this.selMerAmt = 0.0;
				this.disableReportBtn = true;
				this.disableViewBtn = true;
			} else if (!this.selAll && this.checkedItem.length === 0) {
				// console.log("HERE BRO 2");
				this.selTxn = 0;
				this.selTxnAmt = 0.0;
				this.selMerAmt = 0.0;
				this.disableReportBtn = true;
				this.disableViewBtn = true;
			}
			// else{
			//     console.log("HERE BRO 3");
			this.totTxnSelTxnObj['selTxn'] = this.selTxn;
			this.totTxnSelTxnObj['selTxnAmt'] = '$' + this.getAmtInDollars(this.selTxnAmt);
			this.totTxnSelTxnObj['selMerAmt'] = '$' + this.getAmtInDollars(this.selMerAmt);
			// }
			// console.log("totTxnSelTxnObj 2", this.totTxnSelTxnObj);
			// console.log("Unchecked-->", this.unCheckedItem);
			// if(this.checkedItem.length === 0){

			// }
			this.selectedTxnEvent.emit(this.totTxnSelTxnObj);
			if (this.selAll) {
				this.data = this.originalData.map((singleData) => {
					// console.log(singleData);
					if (this.unCheckedItem.indexOf(singleData._id) >= 0) {
						singleData['isChecked'] = false;
					} else {
						singleData['isChecked'] = true;
					}
					return singleData;
				});
			} else {
				if (this.checkedItem.length !== 1) this.disableViewBtn = true;
				else {
					let newArr = this.action.filter((act: any) => act.type === 'view');
					this.processAction(newArr[0], this.checkedItem[0]);
					this.disableViewBtn = false;
				}
				this.data = this.originalData.map((singleData) => {
					// console.log(singleData);
					if (this.checkedItem.indexOf(singleData._id) >= 0) {
						singleData['isChecked'] = true;
					} else {
						singleData['isChecked'] = false;
					}
					return singleData;
				});
			}
			if (this.data && !this.data.length) {
				this.disableViewBtn = true;
				this.disableRefreshButton = true;
				this.error = true;
				this.gridMessage = this.getGridMessage();
			}
			this.params = JSON.parse(JSON.stringify(this.originalParams));
			// console.log("this.params====>", JSON.parse(JSON.stringify(this.params)), this.viewPortItems, JSON.parse(JSON.stringify(this.data)));
			console.log('this.originalMetaData===>', this.originalMetaData);
			this.metaData = this.originalMetaData;
			this.hashedData = JSON.parse(JSON.stringify(this.originalHashedData));
			// console.log("this.originalMetaData===>", JSON.parse(JSON.stringify(this.metaData)));
			console.log('this.originalMetaData===>1', this.metaData.totalRecords, this.data.length);

			if (this.metaData.totalRecords === this.data.length) {
				this.completeLoad = true;
				// console.log("this.complete", this.completeLoad);
			} else {
				this.completeLoad = false;
			}
			this.loading = false;
			if (this.unCheckedItem.length) {
				// console.log("HERE==-->", this.selAllCheckbox);
				if (this.selAllCheckbox && this.selAllCheckbox.hasOwnProperty('nativeElement')) {
					this.selAllCheckbox.nativeElement.indeterminate = true;
				}
			}
			this.showSelectedClicked = false;
			// this.data=JSON.parse(JSON.stringify(this.originalData));
		}
	}

	toggleEnableTree(enable) {
		this.treeService.setEnable(enable);
	}

	onShowAdvancedSearchClick(event: Event) {
		// this.searchParams=['Transaction #','Card Type', 'Card last 4 digits', ]
		// console.log("Changing from here??", this.advancedFilterFormDataToBeReused);
		// this.resetAdvancedFilterForm=false;
		// if(Object.keys(this.advancedFilterFormDataToBeReused).length){
		this.advancedFilterFormData = JSON.parse(JSON.stringify(this.advancedFilterFormDataToBeReused));
		// }
		this.showFilter = !this.showFilter;
		this.showAdvancedSearch = this.showAdvancedSearch ? false : true;
	}

	onAdvanceSearchEvent(dataFromAdvSearch) {
		// this.resetSelection();
		// console.log("this....", this.checkedItem, this.selAll, this.unCheckedItem);
		// this.checkedItem=[];
		// this.selTxn=0;
		// this.selTxnAmt=0.00;
		// if(this.selAll)
		this.checkAll('desel_all');
		// console.log("this....2", this.checkedItem, this.selAll, this.unCheckedItem);
		this.resetAdvancedFilterForm = false;
		// this.advancedFilterFormData={};
		this.params.page = 1;
		if (Object.keys(dataFromAdvSearch).length) {
			this.advancedFilterFormDataToBeReused = JSON.parse(JSON.stringify(dataFromAdvSearch));
			this.params.advanceKeyword = JSON.stringify(dataFromAdvSearch);
		} else {
			this.params.advanceKeyword = null;
		}
		this.params.limit = LIMIT;
		// console.log(
		//     "Params after modification",
		//     JSON.parse(JSON.stringify(this.params))
		// );
		this.error = false;
		this.loading = true;
		this.data = [];
		this.hashedData = [];
		this.displayGrid(JSON.parse(JSON.stringify(this.params)));
	}

	onSearch(keyword: any): void {
		// if(this.selAll)
		this.checkAll('desel_all');
		this.params.page = 1;
		if (keyword) {
			this.params.keyword = keyword;
		} else {
			this.params.keyword = null;
		}
		this.params.limit = LIMIT;
		// console.log("here 6");
		this.error = false;
		this.loading = true;
		this.data = [];
		this.hashedData = [];
		this.displayGrid(JSON.parse(JSON.stringify(this.params)));
	}

	nextPage(page: any): void {
		this.params.page = page;
		this.params.limit = LIMIT;
		// console.log("here 7");
		this.displayGrid(JSON.parse(JSON.stringify(this.params)));
	}

	trackById(index, item) {
		return item._id;
	}

	checkDisableReport() {
		// console.log("this.disableReportBtn && !this.error", this.disableReportBtn, this.error);
		if (this.disableReportBtn || this.error) {
			return true;
		} else {
			return false;
		}
	}

	onScroll() {
		console.log('Request Received===>', this.pendingRequest);
		if (this.pendingRequest && this.pendingRequest.hasOwnProperty('closed') && this.pendingRequest.closed) {
			console.log('Request is accepted');
			let isScroll = true;
			if (!this.completeLoad && this.data.length) {
				console.log('Grid scrolled..., fetching more data', new Date());
				console.log('initial page value ==>', this.params.page);
				console.log('initial params value ==>', JSON.parse(JSON.stringify(this.params)));
				this.params.page += 1;
				this.params.limit = LIMIT;
				console.log('incremented page value ==>', this.params.page);
				this.displayGrid(JSON.parse(JSON.stringify(this.params)), isScroll);
			} else {
				console.log('Grid scrolled..., complete data already fetched, NO MORE API CALL !!', new Date());
			}
		} else {
			console.log('Request is ignored IN ONSCROLL');
		}
	}

	/**
	 * Close delete pctr modal by  setting showPctrDeleteModal to false
	 * @param `event`: boolean: event emitted by delete-pctr-modal
	 */
	onPctrDeleteModalCloseEvent(event: boolean) {
		console.log('hide modal called', event);
		if (!event) {
			this.showPctrDeleteModal = false;
		}
	}

	/**
	 * Enable/Disable import transactions button when pctr is clicked in tree and
	 * outstanding txns/ import txns store is selected
	 */
	toggleImportTxnsBtn() {
		if (validateFor(PARAM_PCTRID, this.parameters) && (this.storeValue === TXNS_STORE || this.storeValue === STORE_NAMES.importTransactionsStore)) {
			this.enableImportTxns = true;
		} else {
			this.enableImportTxns = false;
		}
	}

	onImportTxnsClick() {
		this.showImportTxnModal = true;
	}

	onImportTxnModalClose() {
		this.showImportTxnModal = false;
	}

	onRefreshImportTxnGrid() {
		this.refresh.emit(true);
	}

	/**
	 * @param roleID {string} _id of role to be deleted
	 * Role deletion is done through roleLookup api.
	 * Check if any user is attached to role, show error swal if present
	 * Else delete role.
	 */
	handleRoleDeletion(roleID) {
		this.storeObj.destroy(roleID).subscribe(
			(deleteResp) => {
				// console.log('deleteResp: handleRoleDeletion', deleteResp);
				this.showOverLay = false;
				const data = validateFor('data', deleteResp) ? deleteResp.data : {};
				const isRoleInUse = validateFor(IS_ROLE_IN_USE_KEY, data) ? data[IS_ROLE_IN_USE_KEY] : false;
				if (data.success) {
					this.fireSwalForRoleDeletion('Deleted!', 'Selected record has been deleted.', 'success');
				} else if (isRoleInUse) {
					this.fireSwalForRoleDeletion('Not Allowed!', ROLE_DELETION_NOT_ALLOWED, 'error');
				} else {
					this.fireSwalForRoleDeletion('Error!', ROLE_DELETION_FAILED_MSG, 'error');
				}
			},
			(error) => {
				this.showOverLay = false;
				// console.log('error: handleRoleDeletion', error);
				let msg = validateFor('message', error) ? error.message : ROLE_DELETION_FAILED_MSG;
				this.notificationService.error(msg, 'Error');
			}
		);
	}

	/**
	 * Show swal for role deletion response
	 */
	fireSwalForRoleDeletion(title, text, icon) {
		customSwal
			.fire({
				title: title,
				text: text,
				icon: icon,
				confirmButtonText: 'Okay',
			})
			.then((res) => {
				this.displayGrid(JSON.parse(JSON.stringify(this.params)));
			});
	}

	ngOnDestroy() {
		this.alive = false;
	}
}
