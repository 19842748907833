import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionAdvancedSearchComponent } from './transaction-advanced-search/transaction-advanced-search.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AdvancedSearchComponent } from './advanced-search.component';
import { CustomerAdvancedSearchComponent } from './customer-advanced-search/customer-advanced-search.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, FormsModule],
    declarations: [
        AdvancedSearchComponent,
        TransactionAdvancedSearchComponent,
        CustomerAdvancedSearchComponent
    ],
    exports: [
        AdvancedSearchComponent,
        TransactionAdvancedSearchComponent,
        CustomerAdvancedSearchComponent
    ]
})
export class AdvancedSearchModule {}
