import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthenticationService, CompanyService, NotificationService, UserHandlerService } from 'app/shared/services';
import { XpebillingService } from 'app/shared/services/xpebilling.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanySuspendedService } from '../company-suspended.service';

const VENDOR = 'vendor';
const DEALER = 'dealer';
const BUSINESS_EMPLOYEE = 'employee';
const CUSTOMER = 'customer';

@Injectable()
export class CompanySuspendedGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown> {
	constructor(
		private _authenticate: AuthenticationService,
		private _user: UserHandlerService,
		private _xpebilling: XpebillingService,
		private _notification: NotificationService,
		private _router: Router,
		private _companySuspend: CompanySuspendedService,
		private _company: CompanyService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return true;
	}
	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log("CompanySuspended:::", this._companySuspend.isSuspend);
		if(this._companySuspend.isSuspend) {
      this._router.navigate['/company-suspended'];
      return;
    }
    return true;
	}
	canDeactivate(
		component: unknown,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return true;
	}
}
