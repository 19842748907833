import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpService } from '../shared/services/index';

const baseEndpoint = 'xbcReps';
const advancedEndpoint = 'repRoles';

@Injectable()
export class SetupCompanyRepresentativeStore {
    endpoint: string = baseEndpoint;

    constructor(protected httpService: HttpService) {}

    setEndPoint(mode: string) {
        switch (mode) {
            case 'base':
                this.endpoint = baseEndpoint;
                break;
            case 'advanced':
                this.endpoint = advancedEndpoint;
                break;
            default:
                break;
        }
    }

    getAll(params: any): any {
        if (params.businessID) {
            delete params.businessID;
        }
        if (this.endpoint === advancedEndpoint) {
            return this.httpService.getAll(this.endpoint, params)
            .pipe(
                map((response: Response) => {
                    const modifiedResponse = JSON.parse(JSON.stringify(response));
                    modifiedResponse.data = modifiedResponse.data.map((rep: any) => {
                        rep.xRoles = '';
                        rep.xCompanyName = '';
                        if (rep.roles && rep.roles.length) {
                            console.log('yes here....');
                            for (let i = 0; i < rep.roles.length; ++i) {
                                if (i === rep.roles.length - 1) {
                                    rep.xRoles += rep.roles[i].xName;
                                } else {
                                    rep.xRoles += rep.roles[i].xName + ', ';
                                }
                            }
                            delete rep.roles;
                        }
                        if(rep.companyName)
                            rep.xCompanyName = rep.companyName;
                        return rep;
                    });
                    return modifiedResponse;
                })
            );
        } else if (this.endpoint === baseEndpoint) {
            return this.httpService.getAll(this.endpoint, params)
                .pipe(
                    map((response: Response) => {
                        return response;
                    })
                );
        }
    }

    store(element: any): any {
        this.setEndPoint('base');
        return this.httpService.store(this.endpoint, element)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    update(elementID: string, newElement: any): any {
        this.setEndPoint('base');
        return this.httpService.update(this.endpoint, elementID, newElement)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    get(elementID: any): any {
        this.setEndPoint('base');
        return this.httpService.get(this.endpoint, elementID)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    destroy(elementID: any): any {
        this.setEndPoint('base');
        return this.httpService.destroy(this.endpoint, elementID)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }
}
