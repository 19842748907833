import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BreadcrumbService } from '../../shared/services';

@Component({
    selector: 'setup-cmp',
    templateUrl: 'setup.component.html'
})
export class SetupComponent implements OnInit {
    constructor(private router: Router, private breadcrumbService: BreadcrumbService) {}

    ngOnInit() {
        this.setBreadCrumbValue();
    }

    setBreadCrumbValue(): void {
        const breadCrumb: any = {};
        breadCrumb.root = 'Setup';
        breadCrumb.child = 'Business';
        breadCrumb.subChild = 'List';
        breadCrumb.subChildLink = null;
        breadCrumb.grandChild = null;
        this.breadcrumbService.setBreadCrumb(breadCrumb);
    }
}
