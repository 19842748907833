import { vTermTokenData,vTermTokenDataModel } from '../../models/index';
import { TAGSCONSTS } from '../../constants';
import { validateFor } from '../../common-functions';

// BE fields
const BE_MERCHANT_CAPABILITIES_KEY = 'merchantCapabilities';

const PAYMENT_MODE_PAYNOW: string = 'payNow';

export const ACH_TERM_NUM_KEY = 'achTermNum'; //Key used in route which will have terminal number whose parent merchant-account name has ACH as prefix.
export const CC_TERM_NUM_KEY = 'ccTermNum'; //Key used in route which will have terminal number whose parent merchant-account name has CC as prefix.

export const NO_CC_PREFIX_FLAG = 'NO_CC_PREFIX';

export const NO_PREFIX_FLAG = 'NO_PREFIX';

export const NO_CC_PREFIX_MESSAGE =
	'<p>Only Credit Card based transactions are Supported in Dealer Mode.</p>';
export const NO_PREFIX_MESSAGE =`
	<p style='text-align: left;font-size:15px;'>The selected terminal cannot be used for transaction, Since it is not setup for performing transaction.
	<br><br>
	To setup the terminal for performing <b>Credit Card based transaction</b>:
	<br>
	Add "<b>[CC]</b>" prefix to the Merchant Account Name.
	<br><br>
	To setup the terminal for performing <b>ACH based transaction</b>:
	<br>
	Add "<b>[ACH]</b>" prefix to the Merchant Account Name.
	</p>
`;
// export const NO_PREFIX_MESSAGE =
// 	'The selected terminal cannot be used for transaction, since it is not setup for performing transaction.\n\nTo setup the terminal for performing Credit Card based transaction:\nNavigate to Setup -> Structure -> Select the Merchant Account -> Add "[CC]" prefix to the Merchant Account Name.\n\nTo setup the terminal for performing ACH based transaction:\nNavigate to Setup -> Structure -> Select the Merchant Account -> Add "[ACH]" prefix to the Merchant Account Name.';
export const MULTIPLE_CC_MERCHANT_ACCOUNT_FOR_TXN_MSG =
	'Invalid setup. Multiple merchant accounts exists for Credit Card. (contact support)';
export const MULTIPLE_ACH_MERCHANT_ACCOUNT_FOR_TXN_MSG = 'Invalid setup. Multiple merchant accounts exists for ACH. (contact support)';
export const SETUP_ALTERED_MESSAGE = 'The previous setup has been altered. Can not continue for transaction (contact support). '; //TODO:verify message
export const UNABLE_TO_GENERATE_MESSAGE = 'Unable to generate token.';

//Keys
const DB_XNAME_KEY = 'xName';
const DB_GTID_KEY = 'xGTID';
const DB_GMID_KEY = 'xGMID';
const DB_GMPW_KEY = 'xGMPW';
const DB_ID_KEY = '_id';
const DB_EMAIL_KEY = 'xEmail';
const DB_PHONE_KEY = 'xPhone';
const DB_ADDR_KEY = 'xAddress';
const DB_REFNUM_KEY = 'xRefNum';
const DB_BIZID_KEY = 'xBusinessID';
const DB_XROLES_KEY = 'xRoles';
const DB_XTAG_KEY = 'xTag';
const PERMISSIONS_KEY = 'permissions';

/**
 * Set data object which is encrypted
 * @param `businessName`: string: parent business name
 * @param: `vtermInfoResponse`: object: response from vtermInfoEndPoint (this data will have pctr and store records)
 * @param: `userData`: object: user data from userHandlerService
 * @param: `actionBy`: string: action performed by which user (role.cust/role.rep)
 * @param: `ccTerminal`: object : cc terminal data
 * @param: `achTerminal`: object: ach terminal data
 * @returns object to be encrypted
 */
export function setDataForTokenCreation(businessName, vtermInfoResponse, userData, actionBy, ccTerminal, achTerminal?: any) {
	console.log('setData:------->res', vtermInfoResponse, 'ccTerminal', ccTerminal, 'achTerminal', achTerminal, 'userData', userData);
	let tokenData:vTermTokenDataModel = Object.assign({},vTermTokenData);
	console.log('setDataForTokenCreation:vTermTokenData',tokenData)
	let shouldSetCCTerminal = ccTerminal && Object.keys(ccTerminal).length;
	let shouldSetACHTerminal = achTerminal && Object.keys(achTerminal).length;
	let terminalData = shouldSetCCTerminal ? ccTerminal : shouldSetACHTerminal ? achTerminal : {};
	tokenData.xTerminalName = validateFor(DB_XNAME_KEY, terminalData) ? terminalData[DB_XNAME_KEY] : '';
	tokenData.xGTID = validateFor(DB_GTID_KEY, terminalData) ? terminalData[DB_GTID_KEY] : '';
	tokenData.xGMID = validateFor(DB_GMID_KEY, vtermInfoResponse.pCtrRecord) ? vtermInfoResponse.pCtrRecord[DB_GMID_KEY] : '';
	tokenData.xGMPW = validateFor(DB_GMPW_KEY, vtermInfoResponse.pCtrRecord) ? vtermInfoResponse.pCtrRecord[DB_GMPW_KEY] : '';
	tokenData.xEnableServiceChargeAmount = validateFor("xEnableServiceChargeAmount", vtermInfoResponse.pCtrRecord) ? vtermInfoResponse.pCtrRecord["xEnableServiceChargeAmount"] : 0;
	tokenData.xPCtrName = validateFor(DB_XNAME_KEY, vtermInfoResponse.pCtrRecord) ? vtermInfoResponse.pCtrRecord[DB_XNAME_KEY] : '';
	tokenData.merchantInfo.name = validateFor(DB_XNAME_KEY, vtermInfoResponse.pCtrRecord) ? vtermInfoResponse.pCtrRecord[DB_XNAME_KEY] : '';
	tokenData.merchantInfo.id = validateFor(DB_ID_KEY, vtermInfoResponse.pCtrRecord) ? vtermInfoResponse.pCtrRecord[DB_ID_KEY] : '';
	tokenData.storeInfo.email = validateFor(DB_EMAIL_KEY, vtermInfoResponse.storeRecord) ? vtermInfoResponse.storeRecord[DB_EMAIL_KEY] : '';
	tokenData.storeInfo.name = validateFor(DB_XNAME_KEY, vtermInfoResponse.storeRecord) ? vtermInfoResponse.storeRecord[DB_XNAME_KEY] : '';
	tokenData.storeInfo.phone = validateFor(DB_PHONE_KEY, vtermInfoResponse.storeRecord) ? vtermInfoResponse.storeRecord[DB_PHONE_KEY] : '';
	tokenData.storeInfo.address = validateFor(DB_ADDR_KEY, vtermInfoResponse.storeRecord) ? vtermInfoResponse.storeRecord[DB_ADDR_KEY] : '';
	tokenData.storeInfo.refNum = validateFor(DB_REFNUM_KEY, vtermInfoResponse.storeRecord) ? vtermInfoResponse.storeRecord[DB_REFNUM_KEY] : '';
	tokenData.storeInfo._id = validateFor(DB_ID_KEY, vtermInfoResponse.storeRecord) ? vtermInfoResponse.storeRecord[DB_ID_KEY] : '';
	tokenData.businessInfo.xVTerminalShowExpandBtn = validateFor('xVTerminalShowExpandBtn', vtermInfoResponse.businessRecord) ? vtermInfoResponse.businessRecord['xVTerminalShowExpandBtn'] : false;
	tokenData.businessInfo.name = validateFor(DB_XNAME_KEY, vtermInfoResponse.businessRecord) ? vtermInfoResponse.businessRecord[DB_XNAME_KEY] : '';
	tokenData.businessInfo._id = validateFor(DB_ID_KEY, vtermInfoResponse.businessRecord) ? vtermInfoResponse.businessRecord[DB_ID_KEY] : '';
	tokenData.webAppInfo.bizID = validateFor(DB_BIZID_KEY, terminalData) ? terminalData[DB_BIZID_KEY] : '';
	tokenData.webAppInfo.terminalID = validateFor(DB_ID_KEY, terminalData) ? terminalData[DB_ID_KEY] : '';
	tokenData.webAppInfo.actionBy = actionBy ? actionBy : '';
	tokenData.webAppInfo.xBusinessName = businessName ? businessName : '';
	tokenData.userInfo.userID = Object.keys(userData).length && validateFor(DB_ID_KEY, userData) ? userData[DB_ID_KEY] : '';
	//==============
	/**
	 * TODO: ADD THIS WHEN NEW VTERM SNIPPETS ARE GENERATED FROM CLIENT'S SIDE. SOME ARE USING OLD
	 * SNIPPETS WHICH DONT HAVE PERMISSIONS ADDED IN MODULAR ROLE RELEASE.
	 */
	// tokenData.userInfo.permissions = validateFor(PERMISSIONS_KEY, userData) ? userData[PERMISSIONS_KEY]: {};
	//==============
	tokenData.initiatorRole =
		validateFor(DB_XROLES_KEY, userData) && userData[DB_XROLES_KEY].length && validateFor(DB_XTAG_KEY, userData[DB_XROLES_KEY][0])
			? userData[DB_XROLES_KEY][0][DB_XTAG_KEY]
			: '';
	tokenData.paymentMode = PAYMENT_MODE_PAYNOW;
	if (shouldSetCCTerminal) {
		console.log('should set cc terminal true');
		tokenData.terminalInfo.ccTerminal = ccTerminal;
	}
	if (shouldSetACHTerminal) {
		console.log('should set ach terminal true');
		tokenData.terminalInfo.achTerminal = achTerminal;
	}
	console.log('fetchterminalPctr: setData: common-functions---->', tokenData);
	return tokenData;
}

/**
 * Validate whether terminal has merchant capabilities
 * CASES: 1. Terminal has no merchant capabilities i.e Parent merchant account of terminal has no permission for
 * 			 transaction. (Invalid)
 * 		  2. Terminal has ACH merchant capabilities (Invalid)
 * 		  3. Terminal has CC merchant capabilities (Valid)
 * @param `terminalRecords` : array: Terminal data
 * @returns `string` : return error flag, return empty string if no error
 */
export function validateTerminalForMerchantCapabilities(terminalRecords: any = []): string {
	let terminalData = terminalRecords.length && terminalRecords[0] ? terminalRecords[0] : {};
	console.log('validateTerminalForMerchantCapabilities: validateTerminalForParentMerAcctName--->', terminalData);
	if (validateFor(BE_MERCHANT_CAPABILITIES_KEY, terminalData)) {
		if (!terminalData[BE_MERCHANT_CAPABILITIES_KEY].length) {
			return NO_PREFIX_FLAG;
		}
		if (terminalData[BE_MERCHANT_CAPABILITIES_KEY].includes(TAGSCONSTS.brandTags.brandItemTags.genericElectronicCheck)) {
			return NO_CC_PREFIX_FLAG;
		}
		return '';
	}
	return NO_PREFIX_FLAG;
}

/**
 * Check if GMID, GTID or GMPW is empty.
 * @param `payloadData`: object: Data which is to be encrypted
 * @returns `boolean`: False if GMID, GTID or GMPW is empty. True if all are present
 */
export function validatePgComFields(payloadData: any = {}) {
	console.log('validatePgComFields: payloadData--->', payloadData);
	if (!validateFor('xGMID', payloadData) || !validateFor('xGTID', payloadData) || !validateFor('xGMPW', payloadData)) {
		return false;
	} else {
		return true;
	}
}
