import { Component, OnInit } from '@angular/core';
import { SetupBusinessStructureStore } from '../../../../../store';
import {
	TreeService,
	UrlStoreService,
	BusinessService,
	TabStructureService,
	UserHandlerService,
	BreadcrumbService
} from '../../../../../shared/services';
import { Router } from '@angular/router';
import { TAGSCONSTS } from '../../../../../constants/tags.constants';
@Component({
	selector: 'app-setup-structure-chain-list',
	templateUrl: './setup-structure-chain-list.component.html',
	styleUrls: ['./setup-structure-chain-list.component.scss']
})
export class SetupStructureChainListComponent implements OnInit {
	public attributes: any[] = [];
	public storeValue: string = '';
	public storeObject: any = {};
	public action: any[] = [];
	public parameters: any = {
		// noGrid: true
		xTypeTag: TAGSCONSTS.structTags.chainTags
	};
	showDetails: boolean = false;
	fetchByStore: boolean = true;
	setBizId: boolean = true;

	// public detailVisible: boolean = true;
	public chainID: string = '';
	public transactionType: string = 'all';

	public viewDetails: any[] = [
		{ header: 'Active', mapTo: 'xStatus' },
		{ header: 'Name', mapTo: 'xName' },
		{ header: 'Sub Name', mapTo: 'xSubName' },
		{ header: 'City', mapTo: 'xAddr.xCity', nested: true },
		{ header: 'Main Phone', mapTo: 'xMainContact.xPhone', nested: true },
		{ header: 'ID', mapTo: '_id' }
	];

	constructor(
		private router: Router,
		private setupBusinessStructureStore: SetupBusinessStructureStore,
		public treeService: TreeService,
		public urlStoreService: UrlStoreService,
		public businessService: BusinessService,
		private tabStructureService: TabStructureService,
		private userHandlerService: UserHandlerService,
		private breadcrumbService: BreadcrumbService
	) {
		// this.urlStoreService.previousUrl = this.urlStoreService.currentUrl;
		// console.log("current url chain list", router.url);
		// this.urlStoreService.currentUrl = router.url;
	}

	ngOnInit() {
		this.setBreadCrumbValue();
		this.treeService.isParent = false;
		this.tabStructureService.checkAndSetTabNameToDef(
			this.tabStructureService.tabMode,
			this.tabStructureService.isCancelClick,
			this.tabStructureService.isSubmitClick
		);
		//For downward hierarchy------->
		if (this.treeService.businessID.getValue()) {
			console.log('here');
			this.setBizId = false;
			// this.tabStructureService.setTabNameToDefault();
			this.parameters = {
				xTypeTag: TAGSCONSTS.structTags.chainTags,
				xBusinessID: this.treeService.businessID.getValue()
			};
		}
		//End downward hierarchy------->
		//For self------>
		if (this.treeService.chainID.getValue()) {
			this.setBizId = false;
			this.router.navigate(['/dashboard/setup/business/structure/chain', this.treeService.chainID.getValue()]);
		}
		// End for self------->
		//For upward hierarchy------------->
		if (this.treeService.regionID.getValue()) {
			// to open respective chain if xChainID is present else show no chain exists
			this.setBizId = false;
			console.log(this.treeService.treeData);
			if (this.treeService.treeData.xChainID) {
				this.treeService.isParent = true;
				this.router.navigate([
					'/dashboard/setup/business/structure/chain',
					this.treeService.treeData ? this.treeService.treeData.xChainID : null
				]);
			} else {
				this.treeService.isParent = true;
				this.router.navigate([
					'/dashboard/setup/business/structure/chain',
					'null'
					// "noChain"
				]);
			}
		}
		if (this.treeService.propertyID.getValue()) {
			console.log(this.treeService.treeData);
			this.setBizId = false;
			if (this.treeService.treeData.xChainID) {
				this.treeService.isParent = true;
				this.router.navigate([
					'/dashboard/setup/business/structure/chain',
					this.treeService.treeData ? this.treeService.treeData.xChainID : null
				]);
			} else {
				this.treeService.isParent = true;
				this.router.navigate(['/dashboard/setup/business/structure/chain', 'null']);
			}
		}
		if (this.treeService.storeID.getValue()) {
			console.log(this.treeService.treeData);
			this.setBizId = false;
			if (this.treeService.treeData.xStructs ? this.treeService.treeData.xStructs.xChainID : false) {
				this.treeService.isParent = true;
				this.router.navigate([
					'/dashboard/setup/business/structure/chain',
					this.treeService.treeData ? this.treeService.treeData.xStructs.xChainID : null
				]);
			} else {
				this.treeService.isParent = true;
				this.router.navigate(['/dashboard/setup/business/structure/chain', 'null']);
			}
		}
		if (
			this.treeService.profitCenterID.getValue() ||
			this.treeService.merchantAccID.getValue() ||
			this.treeService.terminalID.getValue() ||
			this.treeService.periphID.getValue()
		) {
			this.setBizId = false;
			console.log('This is the store data??', this.treeService.treeData);
			if (this.treeService.treeData.xStructs ? this.treeService.treeData.xStructs.xChainID : false) {
				this.treeService.isParent = true;
				this.router.navigate([
					'/dashboard/setup/business/structure/chain',
					this.treeService.treeData ? this.treeService.treeData.xStructs.xChainID : null
				]);
			} else {
				this.treeService.isParent = true;
				this.router.navigate(['/dashboard/setup/business/structure/chain', 'null']);
			}
		} else {
			console.log('here it is setting business id??');
			if (this.setBizId) {
				this.treeService.setId(this.businessService.businessID.getValue(), 'business');
			}
		}
		//End of upward hierarchy------------->

		this.setGridProperties();
	}

	setBreadCrumbValue(): void {
		const breadCrumb: any = {};
		breadCrumb.root = 'Setup';
		breadCrumb.child = 'Structure';
		breadCrumb.subChild = 'Chain';
		breadCrumb.subChildLink = null;
		breadCrumb.grandChild = 'Chains';
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	setGridProperties() {
		this.storeValue = 'SetupBusinessStructureStore';
		this.storeObject = this.setupBusinessStructureStore;
		const editLink = '/dashboard/setup/business/structure/chain';
		this.action = [{ type: 'view', visible: true, text: 'Details', icon: 'fa fa-eye', action: 'emit' }];
		if (!this.userHandlerService.userRoles.isBusinessEmployee) {
			this.attributes = [
				{ header: 'Active', mapTo: 'xStatus', width: '10%' },
				{ header: 'Name', mapTo: 'xName', width: '20%' },
				{ header: 'Sub Name', mapTo: 'xSubName', width: '20%' },
				{ header: 'City', mapTo: 'xAddr.xCity', width: '20%' },
				{ header: 'Main Phone', mapTo: 'xMainContact.xPhone', width: '20%' },
				{ header: 'Actions', mapTo: '', width: '10%' }
			];
			this.action.push(
				{ type: 'edit', visible: true, text: 'Edit', icon: 'fa fa-edit', action: 'url', onClick: editLink },
				{ 
					type: 'delete', 
					visible: true, 
					text: 'Delete', 
					icon: 'fa fa-trash', 
					action: 'func', 
					onClick: 'destroy',
					disabled: !this.userHandlerService.userPerms.canDeleteSetup
				}
			);
		} else if (this.userHandlerService.userRoles.isBusinessEmployee) {
			this.attributes = [
				{ header: 'Active', mapTo: 'xStatus', width: '10%' },
				{ header: 'Name', mapTo: 'xName', width: '25%' },
				{ header: 'Sub Name', mapTo: 'xSubName', width: '20%' },
				{ header: 'City', mapTo: 'xAddr.xCity', width: '25%' },
				{ header: 'Main Phone', mapTo: 'xMainContact.xPhone', width: '20%' }
			];
		}
	}

	onActionRequest(obj: any) {
		if (obj.act.type === 'view') {
			console.log("I'm here bro", obj);
			this.chainID = obj.id;
			// this.detailVisible = true;
		}
	}

	onShowDetails(obj: any) {
		console.log('Show object', obj);
		this.showDetails = obj;
	}

	viewClosed(visible: boolean) {
		if (!visible) {
			this.chainID = '';
			this.showDetails = false;
		}
	}
}
