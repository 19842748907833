<div class="login-page animate">
    <div class="login-wrap">
        <div class="login-inner">
            <img src="assets/images/cloud9-logo.png" class="user-avatar" />
            <div class="t-p">
                <p class="lead login-page-headers">Reset Password</p>
            </div>
            <form #form="ngForm" name="forgotPasswordForm" (ngSubmit)="changePassword(form.value)">
                <div class="form-content">
                    <div class="form-group no-margin box-notification-icon">
                        <input
                            type="password"
                            class="form-control input-rounded input-lg"
                            name="newPassword"
                            [(ngModel)]="newPassword"
                            placeholder="New Password"
                            (keyup)="validatePassword($event)"
                        />
                        <span *ngIf="!!passwordValidity && !!passwordValidity.isValid">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                        </span>
                        <span tooltip="Password must contain at least one upper case letter [A-Z], one lower case letter [a-z], one number [0-9] and one special character [*, #, $ ...]"
                            placement="top" container="body" containerClass="tooltip-global" 
                            *ngIf="!!(!passwordValidity) || (newPassword.length && passwordValidity && !passwordValidity.isValid)">
                            <i class="fa fa-exclamation-triangle inline-warning-color" aria-hidden="true"></i>
                        </span>
                        <!-- <small
                            *ngIf="(passwordValidity && !passwordValidity.isValid)"
                            [class.text-danger]="!passwordValidity.isValid"
                            >{{ passwordValidity.validityMessage }}</small
                        > -->
                    </div>
                    <div class="form-group no-margin box-notification-icon">
                        <input
                            type="password"
                            class="form-control input-rounded input-lg"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            [(ngModel)]="confirmPassword"
                            (keyup)="confirmMatch()"
                        />
                        <span *ngIf="passwordValidity && passwordValidity.isValid && passwordValidity.isMatch">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                        </span>
                        <span tooltip="Password should match." placement="top" container="body" containerClass="tooltip-global" 
                            *ngIf="(!!passwordValidity && confirmPassword.length && confirmPassword.length >= newPassword.length && !passwordValidity.isMatch)">
                            <i class="fa fa-times-circle inline-danger-color" aria-hidden="true"></i>
                        </span>
                        <!-- <small
                            *ngIf="!passwordValidity || (passwordValidity && !passwordValidity.isMatch)"
                            [class.text-danger]="!passwordValidity.isMatch"
                            >{{ passwordValidity.matchMessage }}</small
                        > -->
                    </div>
                </div>
                <button class="btn btn-secondary btn-round btn-block btn-lg">
                    <span> Change Password </span>
                </button>
            </form>
            <div class="m-t">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                <a (click)="routeToLoginPage()" class="login-page-links" style="font-size: 15px;">Go back to login page</a>
            </div>
        </div>
    </div>
</div>
