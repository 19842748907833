import { Validators } from '@angular/forms';
import { TAGSCONSTS } from './tags.constants';

export const MONTHS_ARRAY = [
	{ value: 1, name: 'Jan' },
	{ value: 2, name: 'Feb' },
	{ value: 3, name: 'Mar' },
	{ value: 4, name: 'Apr' },
	{ value: 5, name: 'May' },
	{ value: 6, name: 'Jun' },
	{ value: 7, name: 'Jul' },
	{ value: 8, name: 'Aug' },
	{ value: 9, name: 'Sept' },
	{ value: 10, name: 'Oct' },
	{ value: 11, name: 'Nov' },
	{ value: 12, name: 'Dec' },
];

export const ACH_FORM_CONTROLS = [
	{ name: 'accountType', value: 'savings', validate: [Validators.required ] },
	{ name: 'accountNumber', value: '', validate: [Validators.required, Validators.pattern("^[0-9]*$")] },
	{ name: 'routingNumber', value: '', validate: [Validators.required, Validators.pattern("^[0-9]*$")] },
];
export const CARD_FORM_CONTROLS = [
	{ name: 'cardNumber', value: '', validate: [Validators.required,  Validators.minLength(16), Validators.maxLength(16), Validators.pattern("^[0-9]*$")]  },
	{ name: 'cvv', value: '', validate: [Validators.required, Validators.maxLength(3),  Validators.pattern("^[0-9]*$")] },
	{ name: 'expMon', value: new Date().getMonth(), validate: [Validators.required] },
	{ name: 'expYear', value: new Date().getFullYear().toString().slice(2,4), validate: [Validators.required] },
];
export const PAYMENT_METHOD = 'paymentMethod';

export const PAYMENT_TYPE_KEY = 'paymentType';

export const PAYMENT_METHOD_CARD = {
	BRAND: TAGSCONSTS.brandTags.brandItemTags.visa,
	MDM: TAGSCONSTS.mediumTags.mdmItemTags.creditCard,
};

export const PAYMENT_METHOD_ACH = {
	BRAND: TAGSCONSTS.brandTags.brandItemTags.genericElectronicCheck,
	MDM: TAGSCONSTS.mediumTags.mdmItemTags.electronicCheck,
};

export const PAYMENT_METHOD_TYPE = {
	CARD: 'creditCard',
	ACH: 'ach',
};

export const PAYMENT_TYPES = {
	EXISTING: 'existing',
	NEW: 'new',
};
