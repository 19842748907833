<div class="login-page animate">
    <div class="login-wrap">
        <div class="login-inner">
            <img src="assets/images/cloud9-logo.png" class="user-avatar" />
            <div class="t-p">
                <h5 class="no-margin">Forgot Password</h5>
            </div>
            <form
                name="forgotPasswordForm"
                (ngSubmit)="getLink(form.value)"
                #form="ngForm"
            >
                <div class="form-content">
                    <div class="form-group no-margin">
                        <input
                            #uName
                            type="text"
                            class="form-control input-rounded input-lg"
                            placeholder="Username"
                            name="userName"
                            [(ngModel)]="userName"
                        />
                        <small
                            *ngIf="emailResponse"
                            [class.text-danger]="emailResponse.error"
                            >{{ emailResponse.message }}</small
                        >
                    </div>
                </div>
                <button
                    class="btn btn-secondary btn-round btn-block btn-lg"
                    [disabled]="disable || !userName.length"
                >
                    <span *ngIf="!disable">
                        {{ buttonText }}
                    </span>
                    <span *ngIf="disable"
                        >Please wait...
                        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    </span>
                </button>
            </form>
            <div class="m-t">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                <a
                    (click)="routeToLoginPage()"
                    class="login-page-links"
                    style="font-size: 15px;"
                    >Go back to login page</a
                >
            </div>
        </div>
    </div>
</div>
