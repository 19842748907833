import { Injectable } from '@angular/core';

import { HttpService } from '../shared/services';
import { BATCHLIMIT } from '../constants/configuration.constants';
const endpoint = 'xpeBatches';

@Injectable()
export class BatchStore {

    constructor(protected httpService: HttpService) { }

    getAll(params: any): any {
        if(params.hasOwnProperty('limit')){
            params.limit=BATCHLIMIT;
        }
        else{
            params['limit']=BATCHLIMIT;
        }
        return this.httpService.getAll(endpoint, params);
    }

    store(element: any): any {
        return this.httpService.store(endpoint, element);
    }

    update(elementID: string, newElement: any): any {
        return this.httpService.update(endpoint, elementID, newElement);
    }

    get(elementID: any): any {
        return this.httpService.get(endpoint, elementID);
    }

    destroy(elementID: any): any {
        return this.httpService.destroy(endpoint, elementID);
    }
}
