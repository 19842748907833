import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SummaryReportComponent } from './summary-report.component';
import {GridModule} from '../grid/grid.module';
import { SummaryReportService } from '../../services';
import { AccordionModule } from 'ngx-bootstrap/accordion';

@NgModule({
  imports: [
    CommonModule,
    GridModule,
    ModalModule,
    AccordionModule.forRoot()
  ],
  declarations: [SummaryReportComponent],
  providers:[SummaryReportService],
  exports: [SummaryReportComponent]
})
export class SummaryReportModule { }
