import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { SetupBusinessStructureComponent } from './setup-business-structure.component';

// import {
// 	SetupStructureInformationComponent,
// 	SetupStructureChainComponent,
// 	SetupStructureRegionComponent,
// 	SetupStructurePropertyComponent,
// 	SetupStructureStoreComponent,
// 	SetupStructureProfitCenterComponent,
// 	SetupStructureTerminalComponent,
// 	SetupStructureMerchantAccountComponent,
// 	SetupStructurePeripheralListComponent,
// 	SetupStructureInformationListComponent,
// 	SetupStructureChainListComponent,
// 	SetupStructureRegionListComponent,
// 	SetupStructurePropertyListComponent,
// 	SetupStructureStoreListComponent,
// 	SetupStructureMerchantAccountListComponent,
// 	SetupStructureProfitCenterListComponent,
// 	SetupStructureTerminalsListComponent,
// 	SetupStructurePeripheralsListComponent
// } from './components';

// const routes: Routes = [
// 	{
// 		path: '',
// 		component: SetupBusinessStructureComponent,
// 		children: [
// 			{ path: 'information', component: SetupStructureInformationListComponent, data: { breadcrumb: 'Information' } },
// 			{ path: 'information/:Id', component: SetupStructureInformationComponent, data: { breadcrumb: 'Information' } },
// 			{ path: 'chain', component: SetupStructureChainListComponent, data: { breadcrumb: 'Chain' } },
// 			{ path: 'chain/:Id', component: SetupStructureChainComponent, data: { breadcrumb: 'Chain' } },
// 			{ path: 'region', component: SetupStructureRegionListComponent },
// 			{ path: 'region/:Id', component: SetupStructureRegionComponent },
// 			{ path: 'property', component: SetupStructurePropertyListComponent },
// 			{ path: 'property/:Id', component: SetupStructurePropertyComponent },
// 			{ path: 'store', component: SetupStructureStoreListComponent },
// 			{ path: 'store/:Id', component: SetupStructureStoreComponent },
// 			{ path: 'profit-center', component: SetupStructureProfitCenterListComponent },
// 			{ path: 'profit-center/:Id', component: SetupStructureProfitCenterComponent },
// 			{ path: 'payment-device', component: SetupStructureTerminalsListComponent },
// 			{ path: 'payment-device/:Id', component: SetupStructureTerminalComponent },
// 			{ path: 'merchant-account', component: SetupStructureMerchantAccountListComponent },
// 			{ path: 'merchant-account/:Id', component: SetupStructureMerchantAccountComponent },
// 			{ path: 'peripheral-list', component: SetupStructurePeripheralsListComponent },
// 			{ path: 'peripheral-list/:Id', component: SetupStructurePeripheralListComponent }
// 		]
// 	}
// ];
const routes: Routes = [
	{ path: '', component: SetupBusinessStructureComponent }
	// { path: 'member-accounts', component: MemberAccountsComponent }
];

@NgModule({
	imports: [RouterModule.forChild(routes), TooltipModule.forRoot()],
	exports: [RouterModule]
})
export class SetupBusinessStructureRoutingModule {}
