import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import { NgxAsideModule } from 'ngx-aside';
import { BusinessTreeComponent } from './tree.component';
// import { TreeModule } from 'angular-tree-component';
import { TreeModule } from '@circlon/angular-tree-component';
// import /*BusinessTreeStore*/ /*, SetupBusinessStructureStore*/ '../../../store/index';
import { HttpService } from '../../services/index';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        TreeModule,
        TooltipModule.forRoot(),
        // NgxAsideModule,
        BsDropdownModule.forRoot()],
    declarations: [BusinessTreeComponent],
    exports: [BusinessTreeComponent],
    providers: [
        HttpService
        /*BusinessTreeStore,*/
        /*SetupBusinessStructureStore,*/
    ]
})
export class TreeComponentModule { }
