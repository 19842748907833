import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgxPaginationModule } from "ngx-pagination";
// import { NgxAsideModule } from 'ngx-aside';

import {
    TransactionsStore,
    StatusStore,
    BatchStore,
    SettledTransactionsStore,
    SettleSuccessTransactionsListStore,
    SettleFailedTransactionsListStore,
    ImportTxnsStore
} from "../../store";
import { TransactionRoutingModule } from "./transaction-routing.module";
import { TransactionComponent } from "./transaction.component";
import {
    FilterService,
    NotificationService,
    TransactionActionService,
    BatchActionService,
    TimerService
} from "../../shared/services";
import {
    TreeComponentModule,
    GridModule,
    SectionsModule,
    SummaryReportModule
} from "../../shared/modules";
import {
    OutstandingBatchesComponent,
    SettledBatchesComponent,
    OutstandingTransactionsComponent,
    SettledTransactionsComponent
} from "./components/index";
import { RePrintComponent } from "./shared/component/re-print/re-print.component";
import { TxnBatchResponseModalModule } from "./shared/modules/txn-batch-response-modal/txn-batch-response-modal.module";
import { TxnBatchResponseModalComponent } from "./shared/modules/txn-batch-response-modal/txn-batch-response-modal.component";
import { PaginationModalModule } from "./shared/modules/pagination-modal/pagination-modal.module";
import { PaginationModalComponent } from "./shared/modules/pagination-modal/pagination-modal.component";

@NgModule({
    imports: [
        CommonModule,
        TransactionRoutingModule,
        FormsModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        BsDatepickerModule.forRoot(),
        NgxPaginationModule,
        TreeComponentModule,
        SectionsModule,
        GridModule,
        SummaryReportModule,
        ModalModule,
        TxnBatchResponseModalModule,
        PaginationModalModule
        // NgxAsideModule
    ],
    declarations: [
        TransactionComponent,
        OutstandingBatchesComponent,
        SettledBatchesComponent,
        OutstandingTransactionsComponent,
        SettledTransactionsComponent,
        RePrintComponent,
        TxnBatchResponseModalComponent,
        PaginationModalComponent
    ],
    exports: [TransactionComponent],
    providers: [
        FilterService,
        NotificationService,
        TransactionsStore,
        SettleSuccessTransactionsListStore,
        SettleFailedTransactionsListStore,
        StatusStore,
        BatchStore,
        SettledTransactionsStore,
        TransactionActionService,
        BatchActionService,
        TimerService,
        ImportTxnsStore
    ]
})
export class TransactionModule {}
