import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginAsCustomerComponent } from './login-as-customer.component';
import { RegisterCustomerComponent } from './shared/components/register-customer/register-customer.component';
import { CustomerLoginComponent } from './shared/components/customer-login/customer-login.component';
import { LoginResolverService } from '../shared/resolver/login-resolver-service';
const routes: Routes = [
	{
		path: 'customer',
		component: LoginAsCustomerComponent,
		children: [
			{ path: 'login', component: CustomerLoginComponent },
			{
				path: 'register',
				component: RegisterCustomerComponent
				// resolve: {
				// 	idResolver: LoginResolverService
				// }
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LoginAsCustomerRoutingModule {}
