import { validateFor } from './miscellaneous.function';
import { ACCT_TYPE_MAPPING } from 'app/constants/mapping.constants';

//payment mode
export const PAYMENT_MODE_PAYNOW: string = 'payNow';
export const PAYMENT_MODE_CREATE_PAYMENT: string = 'createPayment';
// Type of transaction
export const transactionType = {
	Auth: 'Authorization',
	Sale: 'Sale'
};
export const SUCCESS: string = 'success';
const PAYMENT_METHOD_SUCCESS_MSG = 'Payment Method has been registerd successfully';
const MEDIUM_CREDIT: string = 'Credit';
const MEDIUM_ELECTRONIC_CHECK: string = 'ElectronicCheck';
const SOMETHING_WENT_WRONG_ERROR_MESSAGE: string = 'Something went wrong. Please contact support.';

//constants
const charactersToBeKeptBefore = 6;
const charactersToBeKeptAfter = 4;
const DOLLAR_SIGN: string = '$';

//Back-End fields
const BE_PAYMENT_INFO_KEY: string = 'paymentInfo';
const BE_RESPONSE_KEY: string = 'response';
const BE_AUTH_AMT_KEY: string = 'AuthAmt';
const BE_PAYMENT_METHOD_KEY: string = 'paymentMethod';
const BE_SAVE_CARD_KEY: string = 'saveCard';
const BE_SAFE_CREATION_RESPONSE_KEY: string = 'safeCreationResponse';
const BE_PGCOM_RESPONSE_KEY: string = 'pgComResponse';
const BE_ERROR_TEXT_KEY: string = 'ErrorText';
const BE_RESPONSE_TEXT_KEY: string = 'ResponseText';
const BE_MEDIUM_KEY: string = 'Medium';
const BE_STATUS_KEY: string = 'Status';
const BE_BRAND_KEY: string = 'Brand';
const BE_ACCT_NUM_KEY: string = 'AccountNum';
const BE_EXP_DATE_KEY: string = 'ExpDate';
const BE_ECHEK_ACCT_NUM_KEY: string = 'EleCheckAccountNumber';
const BE_ECHECK_RTG_NUM_KEY: string = 'EleCheckRoutingNumber';
const BE_ACCT_TYPE_KEY: string = 'AcctType';
const BE_ERROR_KEY: string = 'error';
const BE_CARD_TOKEN_KEY: string = 'CardToken';
const BE_XNAME_KEY: string = 'xName';
const BE_ERROR_OBJ_KEY: string = 'errorObj';
const BE_MESSAGE_KEY: string = 'message';
const MASKED_CARD_TOKEN_KEY: string = 'maskedCardToken';
const PGCOM_STATUS_KEY: string = 'Status';
const PGCOM_ERROR_TEXT_KEY: string = 'ErrorText';
const PGCOM_RESULT_TEXT_KEY: string = 'ResultText';
const STATUS_FAIL: string = 'fail';

/**
 * Set data from payment api which is used for display in UI
 * @param `tokenData`: object: Data from decrypted token
 * @return `paymentResponse`: object: payment data (transaction response)
 */
export function setPaymentResponse(tokenData: any) {
	console.log('called setPaymentResponse', tokenData);
	let paymentResponse =
		validateFor(BE_PAYMENT_INFO_KEY, tokenData) && validateFor(BE_RESPONSE_KEY, tokenData.paymentInfo)
			? tokenData.paymentInfo.response
			: {};
	paymentResponse.showPaymentInfo = true;
	paymentResponse[BE_AUTH_AMT_KEY] = validateFor(BE_AUTH_AMT_KEY, paymentResponse)
		? DOLLAR_SIGN + (parseInt(paymentResponse[BE_AUTH_AMT_KEY].toString()) / 100).toFixed(2)
		: '-';

	let paymentMethod = validateFor(BE_PAYMENT_METHOD_KEY, tokenData.requestPayload) ? tokenData.requestPayload.paymentMethod : null;
	if (validateFor(BE_SAVE_CARD_KEY, paymentMethod)) {
		let safeCreationResponse = validateFor(BE_SAFE_CREATION_RESPONSE_KEY, tokenData) ? tokenData.safeCreationResponse : {};
		let safePgCommResponse =
				validateFor(BE_PGCOM_RESPONSE_KEY, safeCreationResponse)
				? safeCreationResponse.pgComResponse
				: {};
		console.log('safeCreationResponse: setPaymentResponse', safeCreationResponse);
		let hasSafeCreationFailed = false;
		//Safe creation fail-case
		if(validateFor(BE_ERROR_KEY, safeCreationResponse) && safeCreationResponse[BE_ERROR_KEY]) {
			paymentResponse[PGCOM_STATUS_KEY] = STATUS_FAIL;
			paymentResponse[PGCOM_ERROR_TEXT_KEY] = '-';
			paymentResponse[PGCOM_RESULT_TEXT_KEY] = safeCreationResponse[BE_MESSAGE_KEY];
			// paymentResponse.paymentMethodFailureMsg = safeCreationResponse[BE_MESSAGE_KEY];
			hasSafeCreationFailed = true;
			console.log('failure message: setPayNowResponse', paymentResponse.paymentMethodFailureMsg);
		}

		paymentResponse[BE_CARD_TOKEN_KEY] =
				!hasSafeCreationFailed
				&& Object.keys(safePgCommResponse).length && validateFor(BE_CARD_TOKEN_KEY, safePgCommResponse)
				? safePgCommResponse.CardToken
				: '-';

		paymentResponse[MASKED_CARD_TOKEN_KEY] =
				!hasSafeCreationFailed 
				&& Object.keys(safePgCommResponse).length && validateFor(BE_CARD_TOKEN_KEY, safePgCommResponse)
				? maskAcctToken(safePgCommResponse.CardToken, charactersToBeKeptBefore, charactersToBeKeptAfter)
				: '-';
	}
	return paymentResponse;
}

/**
 * Set data from create-payment api which is used for display in UI
 * @param `tokenData`: object: Data from decrypted token
 * @return `createPaymentResponse`: object: modified payment-response data (transaction response)
 */
export function setCreatePaymentResponse(tokenData: any) {
	console.table('data: setCreatePaymentResponse', tokenData);
	let payloadData = tokenData.requestPayload;
	let createPaymentResponse: any = {};
	let pgComData = validateFor(BE_PGCOM_RESPONSE_KEY, tokenData) ? tokenData.pgComResponse : {};
	let hasError = false;
	console.table('pgcomData:setCreatePaymentResponse---> ', pgComData);
	if(validateFor(BE_ERROR_KEY, tokenData) && tokenData[BE_ERROR_KEY]){
		let message = validateFor(BE_MESSAGE_KEY, tokenData) ? tokenData[BE_MESSAGE_KEY]: SOMETHING_WENT_WRONG_ERROR_MESSAGE;
		console.log('setCreatePaymentResponse: message', message);
		hasError = true;
		createPaymentResponse.paymentMethodFailureMsg = message;
	} else if (validateFor(BE_STATUS_KEY, pgComData) && pgComData.Status === SUCCESS) {
		createPaymentResponse.paymentMethodSuccessMsg = PAYMENT_METHOD_SUCCESS_MSG;
	} else {
		createPaymentResponse.paymentMethodFailureMsg = pgComData.ErrorText;
	}
	createPaymentResponse.customerName = validateFor(BE_XNAME_KEY, payloadData) ? payloadData.xName : '-';
	createPaymentResponse.brand = validateFor(BE_BRAND_KEY, pgComData) ? pgComData.Brand : '-';
	createPaymentResponse.medium = validateFor(BE_MEDIUM_KEY, pgComData) ? pgComData.Medium : '-';
	createPaymentResponse.maskedCardToken = validateFor(BE_CARD_TOKEN_KEY, pgComData)
		&& !hasError
		? maskAcctToken(pgComData.CardToken, charactersToBeKeptBefore, charactersToBeKeptAfter)
		: '-';
	createPaymentResponse.cardToken = validateFor(BE_CARD_TOKEN_KEY, pgComData) ? pgComData.CardToken : '-';
	createPaymentResponse.showCreatePaymentInfo = true;
	switch (pgComData[BE_MEDIUM_KEY]) {
		case MEDIUM_CREDIT:
			createPaymentResponse = setCreditCardFields(createPaymentResponse, pgComData);
			break;
		case MEDIUM_ELECTRONIC_CHECK:
			createPaymentResponse = setECheckFields(createPaymentResponse, pgComData);
			break;
		default:
			console.log('pgComm Medium:  No case Matched'); //TODO-: TO be used later
	}
	console.log('createPaymentResponse: setCreatePaymentResponse---->', createPaymentResponse);
	return createPaymentResponse;
}

/**
 * Set credit-card fields
 * @param `createPaymentResponse`: create-payment response data
 * @param `pgComData`: data from pgCom api
 * @returns `createPaymentResponse`: modified create-payment data
 */
function setCreditCardFields(createPaymentResponse, pgComData): void {
	let expDate = validateFor(BE_EXP_DATE_KEY, pgComData) ? pgComData.ExpDate : '-';
	console.log('expiry date', expDate);
	createPaymentResponse.expMonth = parseInt(expDate.toString().slice(0, 2));
	createPaymentResponse.expYear = parseInt(expDate.toString().slice(2, 4));
	createPaymentResponse.accountNum = validateFor(BE_ACCT_NUM_KEY, pgComData) ? pgComData.AccountNum : '-';
	console.log('createPaymentResponse: setCreditCardFields--->', createPaymentResponse);
	return createPaymentResponse;
}

/**
 * Set ACH fields
 * @param `createPaymentResponse`: create-payment response data
 * @param `pgComData`: pgCom data in payload
 * @returns `createPaymentResponse`: modified create-payment data
 */
function setECheckFields(createPaymentResponse, pgComData): void {
	createPaymentResponse.accountNum = validateFor(BE_ECHEK_ACCT_NUM_KEY, pgComData) ? pgComData.EleCheckAccountNumber : '-';
	createPaymentResponse.accountType =
		validateFor(BE_ACCT_TYPE_KEY, pgComData) && ACCT_TYPE_MAPPING[pgComData.AccountType]
			? ACCT_TYPE_MAPPING[pgComData.AccountType]
			: '-';
	createPaymentResponse.routingNum = validateFor(BE_ECHECK_RTG_NUM_KEY, pgComData) ? pgComData.EleCheckRoutingNumber : '-';
	console.log('createPaymentResponse: setECheckFields--->', createPaymentResponse);
	return createPaymentResponse;
}

//TODO: Move to upper level
function maskAcctToken(acctToken, keepBefore, keepAfter): string {
	let first = acctToken.substring(0, keepBefore);
	let last = acctToken.substring(acctToken.length - keepAfter);
	// let mask = acctToken.substring(keepBefore, acctToken.length - keepAfter).replace(/[a-z\d]/gi, '*');
	let mask = '';
	let maskLength = acctToken.length - (keepBefore + keepAfter);
	for (let i = 0; i < maskLength; i++) {
		mask += '*';
	}
	// console.log(first + mask + last);
	acctToken = first + mask + last;
	console.log('masked acctToken: constant service---->', acctToken);
	return acctToken;
}
