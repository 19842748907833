<div class="filter-dropdown-wrap">
	<div class="dropdown" dropdown [autoClose]="'outsideClick'">
		<a class="btn btn-link dropdown-toggle" dropdownToggle>
			 <i class="fa fa-filter"></i> Filter
		</a>
		<div class="dropdown-menu filter-dropdown pull-right" *dropdownMenu>
			<div class="filter">
				<div class="row">
					<form (ngSubmit)="onFilterSubmit()" #filter_info="ngForm">
						<div class="col-sm-10">
							<!-- <form (ngSubmit)="onFilterSubmit()" #filter_info="ngForm"> -->
							<div class="row">
								<div class="col-sm-4">
									<label for="chain_filter">
										<small>Chain</small>
									</label>
									<div class="row">
										<div class="col-xs-12">
											<select class="form-control form-control-sm" id="chain_filter" name="chain_filter" [(ngModel)]="filterModel.chain" #chain_filter="ngModel" required>
												<option>No Option Selected </option>
	                                            <option *ngFor ="let dl of dataList">{{dl}}</option>
	                                        </select>
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<label for="inputtext">
										<small>Store</small>
									</label>
									<div class="row">
										<div class="col-xs-12">
											<select class="form-control" name="store_filter"
											[(ngModel)]="filterModel.store">
												<option>No Store Selected</option>
												<option *ngFor ="let dl of dataList">{{dl}}</option>
											</select>
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<label for="inputtext">
										<small>Profit Center</small>
									</label>
									<div class="row">
										<div class="col-xs-12">
											<select class="form-control" name="profitCenter_filter"
											[(ngModel)]="filterModel.profitCenter">
												<option>No Profit Center Selected</option>
												<option *ngFor ="let dl of dataList">{{dl}}</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-2">
							<label>
								<small>&nbsp;</small>
							</label>
							<a class="btn btn-link " (click)="resetFilterInfo(filter_info)">		Clear
							</a>
							<button class="btn btn-primary btn-sm" >Search</button>
						</div>
					</form>
				</div>
				<div *ngIf="modifyFilter">
					<form (ngSubmit)="onTransactionSubmit()" #transaction_filter_info="ngForm">
						<h5 class="filter-heading">Transaction Filter</h5>
						<div class="row filter-row">
							<div class="col-sm-2">
								<label class="p-t-25">
									<i class="fa fa-check text-success"></i>Date
								</label>
							</div>
							<div class="col-sm-9">
								<div class="row">
									<div class="col-sm-3">
										<label>
											<small>From</small>
										</label>
										<div>
											<input [(ngModel)]="transactionFilterModel.date_from"
											type="date" class="form-control input-sm date" id="date_from"
											name="date_from" >
										</div>
									</div>
									<div class="col-sm-3">
										<label>
											<small>To</small>
										</label>
										<div>
											<input [(ngModel)]="transactionFilterModel.date_to" type="date" class="form-control input-sm date" id="date_to" name="date_to">
										</div>
									</div>
									<div class="col-sm-3">
										<label>
											<small>ALL DATES</small>
										</label>
										<div>
											<input [(ngModel)]="transactionFilterModel.date_allDate" type="text"
											class="form-control input-sm" id="date_allDate" name="date_allDate"
											placeholder="All">
										</div>
									</div>
									<div class="col-sm-3">
										<label>
											<small>ALL CARD TYPES</small>
										</label>
										<div>
											<input [(ngModel)]="transactionFilterModel.date_allCard" type="text"
											class="form-control input-sm" id="date_allCard" name="date_allCard"
											placeholder="All">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row filter-row">
							<div class="col-sm-2">
								<label class="p-t-25">
									<i class="fa fa-check text-success"></i>ACCT#
								</label>
							</div>
							<div class="col-sm-9">
								<div class="row">
									<div class="col-sm-4">
										<input [(ngModel)]="transactionFilterModel.accNumber" name="accNumber" id="accNumber" type="number" class="form-control input-sm"
										 placeholder="12345678">
									</div>
									<div class="col-sm-offset-2 col-sm-2">
										<label class="p-t-5">
											<i class="fa fa-check text-success"></i>AMOUNT
										</label>
									</div>
									<div class="col-sm-4">
										<input [(ngModel)]="transactionFilterModel.amount"
										 name="amount" id="amount" type="number" class="form-control input-sm"placeholder="12345678">
									</div>
								</div>
							</div>
						</div>
						<div class="row filter-row">
							<div class="col-sm-2">
								<label class="p-t-25">
									<i class="fa fa-check text-success"></i>TRANS#
								</label>
							</div>
							<div class="col-sm-9">
								<div class="row">
									<div class="col-sm-4">
										<input type="number" name="transactionNumber"  id="transactionNumber" [(ngModel)]="transactionFilterModel.transactionNumber" class="form-control input-sm" placeholder="12345678">
									</div>
									<div class="col-sm-offset-2 col-sm-2">
										<label class="p-t-5">
											<i class="fa fa-check text-success"></i>APP CODE
										</label>
									</div>
									<div class="col-sm-4">
										<input [(ngModel)]="transactionFilterModel.appCode" name="appcode"
										type="number" class="form-control input-sm" id="appcode"
										placeholder="12345678">
									</div>
								</div>
							</div>
						</div>

						<div class="btn-center">
							<a type="button" class="btn btn-link text-center" (click)="resetTransactionInfo(transaction_filter_info)">Clear
							</a>
							<button class="btn btn-primary btn-sm text-center">Done</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>