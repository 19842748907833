import { Injectable } from '@angular/core';
 
import { HttpService } from '../shared/services/index';

const endPoint = 'xicAccts';

@Injectable()
export class SetupBillingAccountsStore {
    constructor(protected httpService: HttpService) {}

    getAll(params: any): any {
        return this.httpService.getAll(endPoint, params);
    }

    store(element: any): any {
        return this.httpService.store(endPoint, element);
    }

    update(elementID: string, newElement: any): any {
        return this.httpService.update(endPoint, elementID, newElement);
    }

    get(elementID: any): any {
        return this.httpService.get(endPoint, elementID);
    }

    destroy(elementID: any): any {
        return this.httpService.destroy(endPoint, elementID);
    }
}
