import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Location } from '@angular/common';
import { UserHandlerService } from '../../services/';

@Injectable()
/**
 * @brief      Class for role guard.
 *             Checks if the user is authorized to access the route
 */
export class RoleGuard implements CanActivate, CanActivateChild {
    constructor(private userHandlerService: UserHandlerService, private router: Router, private location: Location) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        return this.checkRoleAuthorization(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    checkRoleAuthorization(url: string): boolean {
        const roles = this.userHandlerService.userRoles;
        const isVendorRep = roles.isVendor;
        const isDealerRep = roles.isDealer;
        const isBusinessEmp = roles.isBusinessEmployee;
        const isCustomer = roles.isCustomer;
        console.log('roleGuard:check here', roles);
        if (url.includes('setup') && (url.includes('business/information/add-edit') || url.includes('billing/customer/add-edit'))) {
            if (isVendorRep) {
                return true;
            }
        }

        if (url.includes('setup') && url.includes('business')) {
            if ((isDealerRep || isBusinessEmp) && !isVendorRep && !isCustomer) {
                return true;
            }
        }
        if (url.includes('setup') && url.includes('company')) {
            if ((isDealerRep || isVendorRep) && !isBusinessEmp && !isCustomer) {
                return true;
            }
        }
        if (url.includes('setup') && (url.includes('billing') || url.includes('licensing'))) {
            if ((isDealerRep || isBusinessEmp || isVendorRep) && !isCustomer) {
                return true;
            }
        }
        //User should be routed to home page when refreshed on roles page
        if (url.includes('setup') && url.includes('security')) {
            if((isDealerRep || isBusinessEmp || isVendorRep)){
                return true;
            }
        }
        if (
            url.includes('dashboard') &&
            (url.includes('settled-transactions') ||
                url.includes('outstanding-batches') ||
                url.includes('settled-batches') ||
                url.includes('import-transactions'))
        ) {
            if ((isDealerRep || isBusinessEmp) && !isVendorRep && !isCustomer) {
                console.log('Inside role gaurd :D');
                return true;
            }
        }
        if (url.includes('dashboard') && url.includes('outstanding-transactions')) {
            // oustanding transaction to be allowed only for dealer,emp,cust and not vendor
            if ((isDealerRep || isBusinessEmp || isCustomer) && !isVendorRep) {
                return true;
            }
        }
        if (
            url.includes('dashboard') &&
            (url.includes('home') || url.includes('statuses') || url.includes('logs') || url.includes('statistics'))
        ) {
            if (!isCustomer) {
                console.log('roleGuard: in home');
                return true;
            }
        }

        // if (url.includes('dashboard') && url.includes('home')) {
        //     console.log('roleGuard: home');
        //     if (!isCustomer) {
        //         console.log('roleGuard: in home');
        //         return true;
        //     }
        // }

        if (url.includes('dashboard') && url.includes('customer') && url.includes('pay-now')) {
            console.log('roleGuard: check for pay-now');
            // if (isCustomer && !(isVendorRep || isDealerRep || isBusinessEmp)) {
            console.log('roleGuard: validated for customer');
            return true;
            // }
        }

        if(url.includes('dashboard/setup/billing/customer/member-accounts;custID=') && url.includes(';token=')){
            console.log('roleGuard: check for member-accounts being routed from vterm');
            return true;
        }

        if (isCustomer) {
            console.log('roleGuard: in customer');
            this.router.navigate(['/dashboard/customer/outstanding-transactions']);
        } else {
            // Navigate to the home page with extras
            console.log('role-guard: route back to home');
            this.router.navigate(['/dashboard/home']);
        }
        // this.location.back();
        return false;
    }
}
