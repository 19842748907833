<div class="login-page animate">
    <div class="login-wrap">
        <div class="login-inner">
            <img src="assets/images/cloud9-logo.png" class="user-avatar" />
            <form
                name="loginForm"
                (ngSubmit)="login(form.value, false)"
                #form="ngForm"
            >
                <div *ngIf="!showTwoFABtns">
                    <p *ngIf="!inSecureUser" class="lead login-page-headers">Login</p>
                    <p *ngIf="inSecureUser" class="lead login-page-headers">Enter Verification Code</p>
                    <div class="form-content">
                        <div
                            class="form-group no-margin"
                            [hidden]="inSecureUser"
                        >
                            <input
                                #uName
                                type="text"
                                class="form-control input-rounded input-lg"
                                placeholder="Username"
                                name="username"
                                id="username"
                                [(ngModel)]="username"
                                [disabled]="disable"
                            />
                        </div>
                        <div
                            *ngIf="!inSecureUser" 
                            class="form-group no-margin"
                        >
                            <input
                                type="password"
                                class="form-control input-rounded input-lg"
                                name="password"
                                placeholder="Password"
                                [(ngModel)]="password"
                                [disabled]="disable"
                            />
                        </div>
                        <div
                            [hidden]="!inSecureUser"
                            class="form-group no-margin"
                        >
                            <input
                                #oneTimePassword
                                type="string"
                                class="form-control input-rounded input-lg"
                                name="otp"
                                placeholder="Verification Code"
                                [(ngModel)]="otp"
                                [disabled]="disable"
                            />
                            <small
                                *ngIf="inSecureUser && timeLeft"
                            >
                                {{ EMAIL_SENT_MESSAGE }}
                                You can generate new code in {{ timeLeft | timerFormat }} minutes.
                            </small>
                            <small
                                *ngIf="!timeLeft"
                            >
                            {{OTP_EXPIRED_ERR_MSG}}
                            </small>
                        </div>
                        <div
                            *ngIf="!inSecureUser"
                            class="text-left m-l"
                        >
                            <div class="clearfix">
                                <div class="pull-left">
                                    <label class="checkbox-wrap m-t-10">
                                        <label class="checkbox1">
                                            <input
                                                type="checkbox"
                                                name="remember_me"
                                                [(ngModel)]="rememberMe"
                                                [disabled]="disable"
                                            />
                                            <span></span>
                                        </label>
                                        <span class="text login-page-links"
                                            >Stay Signed In</span
                                        >
                                    </label>
                                </div>
                                <div
                                    class="pull-right m-r-10"
                                    style="margin-top:7px;"
                                >
                                    <a
                                        [routerLink]="['forgot-password']"
                                        class="login-page-links"
                                    >
                                        Forgot Password?
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div 
                        *ngIf="!inSecureUser" 
                        class="p-b-10 d-flex justify-content-center"
                    >
                        <re-captcha
                            name="re-captcha"
                            (error)="recaptchaError($event)"
                            required
                            [siteKey]=GOOGLE_RECAPTCHA_SITE_KEY
                            [(ngModel)]="recaptchaVerified">
                        </re-captcha>
                    </div>
                    <button
                        class="btn btn-secondary btn-round btn-block btn-lg"
                        [disabled]="authenticationService.isLoading || disable || env_status_for_recapt == !recaptchaVerified"
                        *ngIf="!inSecureUser"
                    >
                        <span *ngIf="!disable"> Log In </span>
                        <span *ngIf="disable"
                            >Please wait...
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i
                        ></span>
                    </button>
                    <button
                        type="button"
                        (click)="onSubmitOTP(form.value)"
                        *ngIf="inSecureUser"
                        class="btn btn-secondary btn-round btn-block btn-lg"
                        [disabled]="otpVerificationLoader || disable || newOTPLoader"
                    >
                        <span *ngIf="!otpVerificationLoader"> Submit </span>
                        <span *ngIf="otpVerificationLoader"
                            >Verifying...
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i
                        ></span>
                    </button>
                    <i 
                        *ngIf="NO_OF_ATTEMPTS_REMAINING" 
                        class="fa fa-info-circle float-right login-info" 
                        [tooltip]="NO_OF_ATTEMPTS_REMAINING" 
                        placement="top" containerClass="tooltip-global"
                        container="body" aria-hidden="true"
                    >
                    </i>
                    <button
                        type="button"
                        (click)="generateNewOTP()"
                        *ngIf="inSecureUser"
                        class="btn btn-secondary btn-round btn-block btn-lg mt-2"
                        [disabled]="otpVerificationLoader || disable || newOTPLoader || timeLeft"
                    >
                        <span *ngIf="!newOTPLoader"> Generate New Verification Code </span>
                        <span *ngIf="newOTPLoader"
                            >Please wait...
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i
                        ></span>
                    </button>
                </div>
                <div 
                    *ngIf="showTwoFABtns"
                >
                    <p class="lead login-page-headers">
                        Two Factor Authentication
                    </p>
                    <button
                        type="button"
                        (click)="onEnableTwoFA()"
                        class="btn btn-secondary btn-round btn-block btn-lg"
                        [disabled]="disableTwoFABtn"
                    >
                        <span> Enable Two Factor Authentication </span>
                    </button>
                    <button
                        type="button"
                        (click)="skipForNow()"
                        class="mt-2 btn btn-link text-white cursor-pointer"
                    >
                        <u>Skip for now</u>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>