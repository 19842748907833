import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET } from '@angular/router';

import { UserHandlerService } from '../../services';

interface IChild {
	name: string;
	link: string;
}

class Breadcrumb {
	root: string = '';
	rootList?: IChild[] = [];
	child: string = '';
	childList?: IChild[] = [];
	subChild: string = '';
	subChildLink: string = null;
	grandChild: string = null;
	grandChildLink: string = null;
	greatGrandChild: string = null;
	toolTip: boolean = false;
	toolTipData: string = '';
	loading: boolean = false;
}

const childList = {
	company: [{ name: 'Companies', link: 'setup/company/information' }, { name: 'Representatives', link: 'setup/company/representative' }],
	business: [
		{ name: 'Businesses', link: 'setup/business/information' },
		{ name: 'Structure', link: 'setup/business/structure/information' },
		{ name: 'Payment Process', link: 'coming-soon' },
		{ name: 'Employees', link: 'setup/business/employee' }
	],
	billing: [],
	security: [{ name: 'Users', link: 'coming-soon' }, { name: 'Roles', link: 'setup/security/roles' }]
};

@Component({
	selector: 'breadcrumb-cmp',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnChanges {
	@Input()
	breadCrumbValue: Breadcrumb;
	@Input()
	modifyFilter: boolean;
	constructor(private router: Router, private activatedRoute: ActivatedRoute, private userHandlerService: UserHandlerService) {
		this.breadCrumbValue = new Breadcrumb();
	}

	ngOnInit() {}

	ngOnChanges() {
		if (!(this.userHandlerService.userRoles.isVendor && ['Business', 'Billing'].includes(this.breadCrumbValue.child))) {
			this.breadCrumbValue.childList = this.breadCrumbValue.child ? childList[this.breadCrumbValue.child.toLowerCase()] : [];
		}
	}
}
