import { Component, ViewChild, AfterViewInit, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { NotificationService, HttpService, XsaConstsService } from '../../services';
import { SetupPermissionsStore } from '../../../store/index';

// const businessEndpoint = 'xbaConsts';
const systemEndpoint = 'xsaConsts';

@Component({
    selector: 'permission-modal-cmp',
    templateUrl: './permission-modal.component.html',
    styleUrls: ['./permission-modal.component.scss']
})
export class PermissionModalComponent implements OnInit, AfterViewInit {
    @Output() onCloseModal = new EventEmitter<boolean>();
    @Input() selectedRoleID: string;
    @ViewChild('editPermissionModal') public editPermissionModal: ModalDirective;
    // stateObj: any;
    loadingPermissions: boolean = false;
    permisionsList: any[] = [];

    functionTags: any[] = []; // stores array of function-tags retrieved from constants[DATABASE]
    filteredFuncTags: any[] = []; // stores array of function-tags filtered on the basis of selected module tag
    moduleTags: any[] = []; // stores array of module-tags retrieved from constants[DATABASE]
    permissionTags: any[] = []; // stores array of permission-tags retrieved from constants[DATABASE]
    selectedModTag: string = ''; // For maintaining selected module tag when adding a new permission
    submittingPermissions: boolean = false;

    constructor(
        private notificationService: NotificationService,
        private httpService: HttpService,
        private xsaConstsService: XsaConstsService,
        private setupPermissionsStore: SetupPermissionsStore
    ) {
        // this.stateObj = {
        //     'modalVisibility': false,
        //     'refreshGrid': false
        // };
    }
    ngAfterViewInit() {
        this.editPermissionModal.show();
    }
    ngOnInit(): void {
        this.loadingPermissions = true;
        this.httpService.authenticatedAPI=false;
        forkJoin([
            this.xsaConstsService.getXsaItems('func'),
            this.xsaConstsService.getXsaItems('mdu'),
            this.xsaConstsService.getXsaItems('perm'),
            this.setupPermissionsStore.getAll({ tree_filter: this.selectedRoleID })
        ]).subscribe(
            (response: any) => {
                // console.log('permission modal response',response);
                this.httpService.authenticatedAPI=true;
                this.setConstants(response[0].data, response[1].data, response[2].data);
                this.initializePermissionsArray(response[3].data, this.selectedRoleID);
            },
            (error: any) => {
                this.httpService.authenticatedAPI=true;
                const errMsg = JSON.parse(error._body).message;
                this.notificationService.error(errMsg, ' ERROR');
                this.editPermissionModal.hide(); // hide the permission modal if some error occurs
            },
            () => {
                setTimeout(() => {
                    this.loadingPermissions = false;
                }, 500);
            }
        );
    }

    onHideModal(event: any) {
        this.onCloseModal.emit(true);
    }

    initializePermissionsArray(permissionsData: any[], roleId: string): void {
        permissionsData.filter(data=> data.xStatus);
        for (let j = 0; j < permissionsData.length; ++j) {            
            if (
                permissionsData[j].xEntyID === roleId && permissionsData[j].hasOwnProperty('isPresent')
                    ? permissionsData[j].isPresent
                    : true
            ) {
                permissionsData[j].isPresent = true; // permission is already added
            } else {
                permissionsData[j].isPresent = false; // permission is not added
            }
            if (permissionsData[j].xStatus === 1) {
                permissionsData[j].status = true; //permission is active
            } else {
                permissionsData[j].status = false; //permission is in-active
            }
        }
        // this.permisionsList = _.cloneDeep(permissionsData);
        this.permisionsList = JSON.parse(JSON.stringify(permissionsData));
    }

    setConstants(funTag: any[], modTag: any[], permTag: any[]) {
        //To set values for function, module, permission Tags
        this.functionTags = funTag.filter(tag=> tag.xStatus);
        this.moduleTags = modTag.filter(tag=> tag.xStatus);
        this.permissionTags = permTag.filter(tag=> tag.xStatus);
    }

    addNewPermission(functionTag: string) {
        const newPerm = {
            xTntTag: 'Tenant Tag',
            xWall1EntyID: '',
            xWall2EntyID: '',
            xStatus: 1,
            xNotes: 'New Permission', //TODO-: Show in UI
            xEntyTypeTag: 'role', //modify when user option is also available
            xEntyID: this.selectedRoleID,
            xPrioLvl:1, //Hardcoded as it is not known yet
            xMduTag: this.selectedModTag,
            xFuncTag: functionTag,
            xPermTag: 'type-1', //Hardcoded as it's not known from where wud dis value be added
            xPermLvl: 10,
            isNewPerm: true, //Used just for reference purpose, this is not sent to backend model
            isModified: false //Used just for reference purpose, this is not sent to backend model
        };
        // console.log(newPerm, '****************>>>>>>>>>>>>');
        const index = this.permisionsList.findIndex(p => newPerm.xMduTag === p.xMduTag && newPerm.xFuncTag === p.xFuncTag && p.isPresent);
        if (index !== -1) {
            this.notificationService.warning('This permission combo is already added', 'Warning!');
        } else {
            let i: number = 0;
            this.permisionsList = this.permisionsList.map(p => {
                if (!p.isPresent) {
                    if (newPerm.xMduTag === p.xMduTag && newPerm.xFuncTag === p.xFuncTag) {
                        p.isPresent = true;
                        i = 1;
                        return p;
                    } else {
                        if (!i) {
                            i = 0;
                        }
                        return p;
                    }
                } else {
                    return p;
                }
            });
            if (i === 0) {
                this.permisionsList.push(JSON.parse(JSON.stringify(newPerm)));
                this.initializePermissionsArray(this.permisionsList, this.selectedRoleID);
            }
        }
    }

    onSelectModuleTag(moduleTag: string) {
        console.log("moduleTag",moduleTag);
        if (moduleTag === 'Select Module Tag') {
            this.selectedModTag = '';
        } else {
            this.selectedModTag = moduleTag;
        }
        this.filterFunctionTag(moduleTag);
    }

    filterFunctionTag(moduleTag){
        if(moduleTag){
            moduleTag = moduleTag.replace('mdu.','');
            console.log('new modTag -->', moduleTag);
            this.filteredFuncTags = this.functionTags.filter((func)=> func.xTag.includes(moduleTag));
            console.log('newFuncTags --->', this.filteredFuncTags);
            console.log('all FuncTags --->', this.functionTags);
        }
    }

    deletePermission(perm: any) {
        const index = this.permisionsList.indexOf(perm);
        this.permisionsList[index].isPresent = false;
    }

    savePermissions() {
        this.submittingPermissions = true;
        let addPermsissions: any[];
        // let storeAPI: any;
        let deletePermsissions: any[];
        let updatePermsissions: any[];
        let modifyPermisions: any[];

        addPermsissions = this.permisionsList
            .filter(
                perm =>
                    perm.isNewPerm &&
                    perm.isPresent &&
                    delete perm.isNewPerm &&
                    delete perm.isPresent &&
                    delete perm.status &&
                    delete perm.isModified
            )
            .map(perm => this.setupPermissionsStore.store(perm));

        deletePermsissions = this.permisionsList
            .filter(perm => !perm.isNewPerm && !perm.isPresent && perm._id)
            .map(perm => this.setupPermissionsStore.destroy(perm._id));

        updatePermsissions = this.permisionsList
            .filter(
                perm =>
                    !perm.isNewPerm &&
                    perm.isPresent &&
                    perm.isModified &&
                    perm._id &&
                    delete perm.isNewPerm &&
                    delete perm.isPresent &&
                    delete perm.status &&
                    delete perm.isModified
            )
            .map(perm => this.setupPermissionsStore.update(perm._id, perm));

        modifyPermisions = deletePermsissions.concat(updatePermsissions);
        modifyPermisions = modifyPermisions.concat(addPermsissions);
        // console.log('modifyPermisions', modifyPermisions)
        forkJoin(modifyPermisions).subscribe(
            (response: any) => {
                console.log(response, 'Modify Permissions Request completed');
            },
            (error: any) => {
                console.log('error: savePermissions', error);
                const errMsg = error.message ? error.message: 'Something went wrong.'
                this.submittingPermissions = false;
                this.notificationService.error(errMsg, ' ERROR');
                this.editPermissionModal.hide();
            },
            () => {
                console.log('Modifiypermission subscription complete....');
                this.submittingPermissions = false;
                this.editPermissionModal.hide();
                this.notificationService.success('Permissions Modified', 'Success!');
            }
        );
    }

    editPriority(perm: any, priorityValue: any) {
        const index = this.permisionsList.indexOf(perm);
        this.permisionsList[index].isModified = true;
        //TODO-: Implement if priority field is maintained
        // this.permisionsList[index].xPriority = priorityValue;
    }

    editStatus(event: any, perm: any) {
        const index = this.permisionsList.indexOf(perm);
        this.permisionsList[index].status = event.target.checked;
        this.permisionsList[index].xStatus = this.permisionsList[index].status ? 1 : 0;
        this.permisionsList[index].isModified = true;
    }

    editPerm(event: any, perm: any) {
        const index = this.permisionsList.indexOf(perm);
        this.permisionsList[index].xPermLvl = event.target.checked ? 10 : 0;
        this.permisionsList[index].isModified = true;
    }
}
