import { OnInit, OnDestroy, HostListener, Directive } from '@angular/core';
import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlStoreService } from '../../services';
import { customSwal } from '../../../constants';
@Directive()
@Injectable()
export class RouteGuardGuard implements CanDeactivate<any>, OnInit, OnDestroy {
	@HostListener('window:beforeunload')
	// @HostListener('window:')
	deactivate: boolean = false;
	constructor(public urlStoreService: UrlStoreService) {}
	ngOnInit() {
		console.log('HERE RouteGuardGuard INIT ------------>');
	}
	canDeactivate(
		component: any,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		console.log('HERE RouteGuardGuard Component------------>', component);
		console.log('currentRoute------------>', currentRoute);
		console.log('currentState------------>', currentState);
		console.log('nextState------------>', nextState);
		if (!this.urlStoreService.submitAction) {
			if (
				component.form.dirty &&
				(!component.hasOwnProperty('bizChangeEvent') || (component.hasOwnProperty('bizChangeEvent') && !component.bizChangeEvent))
			) {
				console.log(
					'RouteGuard canDeactivate: IF CASE component.bizChangeEvent  ---->',
					component.hasOwnProperty('bizChangeEvent') ? component.bizChangeEvent : 'NULL'
				);

				console.log('component has property disable route guard', component.hasOwnProperty('disableRouteGuard'));
				console.log('component disable route guard true or false', component['disableRouteGuard']);
				if (component.hasOwnProperty('disableRouteGuard') && component['disableRouteGuard']) {
					return true;
				} else {
					return this.waitForUser().then(value => {
						console.log('Value inside route-guard', value);
						return value;
					});
				}
			} else {
				console.log(
					'RouteGuard canDeactivate: ELSE CASE component.bizChangeEvent  ---->',
					component.hasOwnProperty('bizChangeEvent') ? component.bizChangeEvent : 'NULL'
				);
				return true;
			}
		} else {
			return true;
		}
	}

	waitForUser(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			try {
				console.log('waitForUser: pageChangeSwal executed');
				customSwal.fire({
					title: `Are you sure, you want to Cancel?`,
					text: 'All the data on this page will be lost!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, go ahead!',
					cancelButtonText: 'No, I changed my Mind!',
					reverseButtons: true
				}).then((result) => {
					if (result.value) {
						// console.log('return true');
						return resolve(true);
					} else {
						// console.log('return false');
						return resolve(false);
					}
				});
			} catch (pageChangeSwalError) {
				console.log('waitForUser: pageChangeSwalError --->', pageChangeSwalError);
				return resolve(false);
			}
		});
	}

	ngOnDestroy() {
		console.log('HERE RouteGuardGuard DESTROYED ------------>');
	}
}
