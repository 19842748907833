import { Component, ViewChild } from '@angular/core';

@Component({
    selector: 'app-config-modal',
    templateUrl: './config-modal.component.html'
})
export class ConfigModalComponent {
    @ViewChild('configModal', {static: true}) public configModal: any;

    public configData: string = JSON.stringify(
        {
            'Conn-Prm': {
                Url: '$WEB-C9PG-PWE-TEST',
                UName: 'POS01',
                Pwd: '',
                Timeout: -113
            },
            'Conn-Opt': {
                'Timeout-Trx': '10000',
                'Timeout-Trx_UI': '120000',
                'Timeout-Batch': '300000',
                UsesStrFmt: 'Y'
            },
            'Sess-Def': {
                GMID: '1001396250',
                GTID: 'GT1001396253',
                GMPW: '123'
            }
        },
        undefined,
        2
    );

    constructor() {
    }

    show() {
        this.configModal.show();
    }
    hide() {
        this.configModal.hide();
    }

    download(event: Event): void {
        const bb = new Blob([this.configData], {type: 'text/json'});
        const dom: any = document.createElement('a');
        dom.setAttribute('href', window.URL.createObjectURL(bb));
        dom.setAttribute('download', 'config_data.json');
        dom.dataset.downloadurl = ['text/plain', dom.download, dom.href].join(':');
        dom.click();
        event.preventDefault();
    }
}
