import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Business, TabNames } from '../../models';
import { HttpService } from './http-service';

@Injectable()
export class BusinessService {
	businessID: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	refreshTree: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	refreshBusinessList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	tabStructureNames: BehaviorSubject<TabNames> = new BehaviorSubject<TabNames>(new TabNames());
	private _businessData: Business;

	watchBusinessID$ = this.businessID.asObservable(); // Watch if businessID is changed
	watchRefreshTree$ = this.refreshTree.asObservable(); // Watch if entity is added in business tree
	watchRefreshBusinessList$ = this.refreshBusinessList.asObservable(); // Watch if new business is added or removed
	watchTabStructureNames$ = this.tabStructureNames.asObservable(); // Watch if new business is added or removed

	_editMode: boolean = false;
	_tabMode: boolean = false;

	constructor(private httpService: HttpService) {}

	setBusinessID(businessID: string): void {
		this.businessID.next(businessID);
		this.fetchBusinessInformation();
	}

	setTabStructureNames(obj: TabNames): void {
		// this.businessID.next(businessID);
		// this.fetchBusinessInformation();
		// let newObj=new TabNames();
		// Object.keys(obj).map(key=>{
		//     if()
		// })

		this.tabStructureNames.next(obj);
	}
	getTabStructureNames(endPoint: string, params: any): Promise<any> {
		return new Promise((resolve, reject) => {
			console.log('endPoint, params', endPoint, params);
			this.httpService.getAll(endPoint, params, true).subscribe(
				(response: Response | any) => {
					console.log('Response inside getTabStructureNames function', response);
					return resolve(response);
				},
				(err: any) => {
					console.log('Error inside getTabStructureNames function', err);
					return reject(err);
				},
				() => {}
			);
		});
	}

	set businessData(business: Business) {
		this._businessData = business;
	}

	get businessData(): Business {
		return this._businessData;
	}
	set editMode(eMode: boolean) {
		this._editMode = eMode;
	}

	get editMode(): boolean {
		return this._editMode;
	}
	set tabMode(tMode: boolean) {
		this._tabMode = tMode;
	}

	get tabMode(): boolean {
		return this._tabMode;
	}

	toggleRefreshTree(): void {
		this.refreshTree.next(false);
		this.refreshTree.next(true);
	}

	toggleRefreshBusinessList(): void {
		this.refreshBusinessList.next(false);
		this.refreshBusinessList.next(true);
	}

	resetBusinessID(): void {
		this.businessID.next(null);
		this.businessData = null;
	}

	fetchBusinessInformation(): Promise<any> {
		return new Promise((resolve, reject) => {
			if (!this.businessID.getValue()) {
				this.businessData = null;
				return reject(null);
			}
			this.httpService.get('xbcBusinesses', this.businessID.getValue()).subscribe(
				(response: Response | any) => {
					this._businessData = response.data;
					return resolve(this._businessData);
				},
				(error: any) => {
					console.log(error, 'error while fetching business');
					return reject(error);
				}
			);
		});
	}

	//clear service data
	intialiseServiceData() {
		this.businessID.next(null);
		this.refreshTree.next(false);
		this.refreshBusinessList.next(false);
		this.businessData = null;
	}
}
