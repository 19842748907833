import { SysInfo } from './system-info.model';
import { EntrCaps } from './entry-caps.model';
import { SecCaps } from './sec-caps.model';
import { AcptCaps } from './acpt-caps.model';
import { DBAudit } from './dbAudit.model';

export class Terminal {
    xTntTag: string;
    xWall1EntyID: string;
    xWall2EntyID: string;
    xTag: string;
    xStatus: number;
    xName: string;
    xTypeTag: string;
    xNotes: string;
    xRefNum: string;
    xBusinessID: string;
    xStoreID: string;
    xMainPCtrID: string;
    xMainMerAcctID: string;
    xMainPCtrNum: string;
    xMainLaneNum: string;
    xGTID: string;
    xUName: string;
    xPwd: string;
    xConnTok: string;
    xCfgInfoStr: string;
    xHWSysInfo: SysInfo;
    xSWSysInfo: SysInfo;
    xCtrlSysInfo: SysInfo;
    xEntrCaps: EntrCaps;
    // xOutpCaps: OutpCaps;
    xSecCaps: SecCaps;
    xAcptCaps: AcptCaps;
    // xOvdProcParams: OvdProcParams[];
    xIsRfrTerminal: Boolean;
    xRfrCfgInfoStr: String;
    xTerminalNum: String;
    xShortCode: String;
    xDBAudit: DBAudit
    xTerminalType: String;

    constructor(obj?: any) {
        this.xTntTag = (obj && obj.xTntTag) || 'tnttag';
        this.xWall1EntyID = (obj && obj.xWall1EntyID) || '';
        this.xWall2EntyID = (obj && obj.xWall2EntyID) || '';
        this.xTag = (obj && obj.xTag) || 'dev';
        this.xStatus = (obj && obj.xStatus) || 1;
        this.xName = (obj && obj.xName) || '';
        this.xTypeTag = (obj && obj.xTypeTag) || 'terminal';
        this.xNotes = (obj && obj.xNotes) || '';
        this.xRefNum = (obj && obj.xRefNum) || '';
        this.xBusinessID = (obj && obj.xBusinessID) || '';
        this.xStoreID = (obj && obj.xStoreID) || '';
        this.xMainPCtrID = (obj && obj.xMainPCtrID) || '';
        this.xMainMerAcctID = (obj && obj.xMainMerAcctID) || '';
        this.xMainPCtrNum = (obj && obj.xMainPCtrNum) || '';
        this.xMainLaneNum = (obj && obj.xMainLaneNum) || '';
        this.xGTID = (obj && obj.xGTID) || '';
        this.xUName = (obj && obj.xUName) || '';
        this.xPwd = (obj && obj.xPwd) || '';
        this.xConnTok = (obj && obj.xConnTok) || '';
        this.xCfgInfoStr=(obj&&obj.xCfgInfoStr)||'';
        this.xHWSysInfo = (obj && new SysInfo(obj.xHWSysInfo)) || new SysInfo();
        this.xSWSysInfo = (obj && new SysInfo(obj.xSWSysInfo)) || new SysInfo();
        this.xCtrlSysInfo = (obj && new SysInfo(obj.xCtrlSysInfo)) || new SysInfo();
        this.xEntrCaps = (obj && new EntrCaps(obj.xEntrCaps)) || new EntrCaps();
        // this.xOutpCaps = (obj && new OutpCaps(obj.xOutpCaps)) || new OutpCaps();
        this.xSecCaps = (obj && new SecCaps(obj.xSecCaps)) || new SecCaps();
        this.xAcptCaps = (obj && new AcptCaps(obj.xAcptCaps)) || new AcptCaps();
        // this.xOvdProcParams = (obj && obj.xOvdProcParams) || [];
        this.xIsRfrTerminal = (obj && obj.xIsRfrTerminal) || false;
        this.xRfrCfgInfoStr = (obj && obj.xRfrCfgInfoStr) || '';
        this.xTerminalNum = (obj && obj.xTerminalNum) || '';
        this.xShortCode = (obj && obj.xShortCode) || '';
        this.xDBAudit = (obj && new DBAudit(obj.xDBAudit)) || new DBAudit();

    }
}
