import { Component, OnInit } from '@angular/core';
import { DATEFORMAT } from '../../constants';
import { StatusStore } from '../../store';

@Component({
    selector: 'app-statuses',
    templateUrl: './statuses.component.html',
    styleUrls: ['./statuses.component.scss']
})
export class StatusesComponent implements OnInit {
    public attributes: any[] = [];
    public storeValue: string = '';
    public storeObject: any = {};
    public action: any[] = [];
    showDetails:boolean=false;
    public parameters: any = {
        noGrid: true
    };

    // public detailVisible: boolean = true;
    public statusID: string = '';
    public transactionType: string = 'all';

    public viewDetails: any[] = [
        { header: 'Status', mapTo: 'xStatus' },
        { header: 'Instance Type ', mapTo: 'xInstTypeTag' },
        // { header: 'Timestamp', mapTo: 'xTakeTS', type: 'date', format: DATEFORMAT },
        { header: 'Status Tag', mapTo: 'xStsTag' },
        { header: 'Current Value', mapTo: 'xStrVal' },
        { header: 'Current Value (Number)', mapTo: 'xNumVal' }
    ];

    constructor(private statusStore: StatusStore) {}

    ngOnInit() {
        this.setGridProperties();
    }

    setGridProperties() {
        this.storeValue = 'StatusStore';
        this.storeObject = this.statusStore;
        this.attributes = [
            { header: 'Entity Type', mapTo: 'xInstTypeTag', width: '10%' },
            // { header: 'Entity Instance', mapTo: 'xTakeTS', width: '15%', type: 'date', format: DATEFORMAT },
            { header: 'Ref. Number', mapTo: 'xStsTag', width: '15%' },
            // { header: 'Medium', mapTo: 'xMdmTag', width: '10%' },
            { header: 'Item', mapTo: 'xStrVal', width: '10%' },
            { header: 'Value', mapTo: 'xNumVal', width: '10%' }
        ];

        this.action = [
            { type: 'view', visible: true, text: 'Details', icon: 'fa fa-eye', action: 'emit' },
            { type: 'other', visible: true, text: 'Settle', icon: 'fa fa-info', action: 'func' }
        ];
    }

    onActionRequest(obj: any) {
        if (obj.act.type === 'view') {
            console.log("I'm here bro", obj);

            this.statusID = obj.id;
            // this.detailVisible = true;
        }
    }
    onShowDetails(obj:any){
        console.log("Show object",obj);
        this.showDetails=obj;
    }

    viewClosed(visible: boolean) {
        if (!visible) {
            this.statusID = '';
            this.showDetails=false;
        }
    }
}
