import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { TAGSCONSTS } from '../../../../constants';
import { LoginStoreService, HttpService, NotificationService, GlobalValidator, GmNumberService } from '../../../../shared/services';
import { SetupSecurityRolesStore, SetupPermissionsStore } from '../../../../store';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { 
        INVALID_TERMINAL_NUMBER_MESSAGE, 
        NOT_FOUND_URL, 
        validateCustomerContextResponse, 
        PREVIOUS_CONFIGURATION_HAS_BEEN_ALTERED_MESSAGE, 
        CORRUPTED_TOKEN_MESSAGE, 
        NO_TOKEN_AVAILABLE_MESSAGE,
        TOKEN_DECRYPTION_FAILED_MESSAGE, 
        PAYLOAD_TERMINAL_NUMBERS_KEY, 
        PARAM_STRING
    } from '../common-functions/index';
import { TerminalNumbersObj } from '../common-functions/index';

import { MESSAGES } from  '../../../../constants/messages.constants';
import { validateFor } from '../../../../common-functions';

const customer_registration_endpoint = 'xbcCustomers';
const NumAPIEndPoint = 'pgComm/numberAPI_modelNums';
const customer_context_endpoint = 'customer_context';
const VTERM_DECRYPT_END_POINT = 'vTerm/decrypt';
const CUSTOEMR_LOGIN_URL = 'customer/login';

const CUSTOMER_SUCCESS_CREATION = 'New customer has been successfully created.';
const CUSTOMER_FAIL_CREATION = 'Unable to create new customer';
const TERMINAL_DATA_NOT_AVAILABLE = 'Unable to fetch Terminal data. Please try again.';
const ROLE_FETCH_FAILURE = 'Unable to fetch roles.';
const ADD_YOUR_OWN_CUSTOMER_NUMBER_MSG = 'Please add your existing Customer Number. If not present, Please generate a new Customer Number';
const FAILED_TO_SET_DATA = 'Failed to set configuration data. Routing back to login page. Please try again.';

//BE-KEY
const BE_TERMINAL_DATA_KEY: string = 'terminalData';
const BE_TERMINAL_NUM_KEY: string = 'terminalNum';
const BE_ERRORS_KEY: string = 'errors';
const BE_DATA_KEY: string = 'data';

const BE_ERROR_KEY = 'error';

const INVALID_PASSWORD_KEY: string = 'INVALID_PASSWORD';
const REQUIRED_KEY: string = 'required';

@Component({
    selector: 'app-register-customer',
    templateUrl: './register-customer.component.html',
    styleUrls: ['./register-customer.component.scss']
})
export class RegisterCustomerComponent implements OnInit, OnDestroy {
    
    public ADD_YOUR_OWN_CUSTOMER_NUMBER_MSG: string = ADD_YOUR_OWN_CUSTOMER_NUMBER_MSG;

    @ViewChild('rolesModal')
    public rolesModal: ModalDirective;
    form: FormGroup; //Required for reactive form validation
    storeId: string;
    bizId: string;
    l1IncorrectNotifyMailFormat: boolean = false; //notification level 1 email validation
    l2IncorrectNotifyMailFormat: boolean = false; //notification level 2 email validation
    l3IncorrectNotifyMailFormat: boolean = false; //notification level 3 email validation
    dontSubmit: boolean = false;
    submitting: boolean = false;
    isFetchNewNumber: boolean = false;
    numberKey: string = 'xCustomerNum';

    serverErrors: any = {}; //Stores serverErrors returned by server
    formErrors = {
        acctxName: ''
    };
    fetchedRoles: any = {};
    rolesData: any[] = [];
    noRoles: boolean = false;
    saveRoles: boolean = false;
    valid: boolean = true;
    rolesArray: any[] = [];
    userAssociatedRolesArray: any[] = [];
    roleAssociatedPermisionsList: any[] = [];
    usersData: any[] = [];
    userRoles: any = {};
    userInformation: any = {};
    loadingRoleAssociatedPerm: boolean = false;
    selectedRoleName: string;
    showPermsDiv: boolean = false;
    displayPermissionModalCmp: boolean = false;
    displayPreviewPermissionModalCmp: boolean = false;
    selectedRoleID: string;
    finalRoleIDs: string;
    alive = true;
    // termNum: any = {}
    public terminalNumbersObj: TerminalNumbersObj = {}
    public token: string = '';
    companyID = '';
    constructor(
        private router: Router,
        private fb: FormBuilder,
        private httpService: HttpService,
        private notificationService: NotificationService,
        private gmNumberService: GmNumberService,
        public loginStoreService: LoginStoreService,
        public validators: GlobalValidator,
        private setupSecurityRolesStore: SetupSecurityRolesStore,
        private setupPermissionsStore: SetupPermissionsStore,
        private activatedRoute: ActivatedRoute
    ) {}

    async ngOnInit() {
        // this.activatedRoute.data.map(data => { data.idResolver}).subscribe(res=>{console.log("resolver res",res)});
        console.log(window.location.href);
        console.log('login service storeobj', this.loginStoreService.storeObj);
        console.log('login service storedata', this.loginStoreService.storeData);
        this.storeId = this.loginStoreService.storeObj.id;
        this.bizId = this.loginStoreService.storeData.xBusinessID;
        this.companyID = this.loginStoreService.companyID;
        console.log('store id', this.storeId, 'bizid', this.bizId);
        if(!this.storeId || !this.bizId || !this.companyID){
            this.notificationService.error(FAILED_TO_SET_DATA, 'Error');
            this.navigateToCustomerLogin();
            return;
        }
        this.httpService.authenticatedAPI = false;
        this.setupSecurityRolesStore.getAll({ xCompanyID: this.companyID, noGrid: true }).subscribe(
            rolesResponse => {
                this.httpService.authenticatedAPI = true;
                console.log('Roles Response', rolesResponse);
                this.processRoles(rolesResponse);
            },
            rolesError => {
                this.httpService.authenticatedAPI = true;
                console.log('Roles Error', rolesError);
                this.notificationService.error('Terminal Data Unavailable', 'Error');
            }
        );
        this.initValidation();
        this.getNewNumber();

        //Refresh handling: On refersh, data in loginStoreService will be lost, set it again.
        //If no storeId or bizId is available, call getCustomerContextData
        if (!this.storeId || !this.bizId) {
            // this.storeId = window.location.href.slice(window.location.href.indexOf('/customer/register/') + 19);
            // console.log('storeid', this.storeId);
            // this.loginStoreService.callAPIStoreData(this.storeId)
            this.activatedRoute.queryParams.subscribe(queryParam => {
                console.log('queryParam: ngOnInit: register customer', queryParam);
                if(!validateFor(PARAM_STRING, queryParam)){
                    this.routeToNotFoundPage(NO_TOKEN_AVAILABLE_MESSAGE);
                    return;
                }
                this.token = queryParam[PARAM_STRING];
                this.httpService.authenticatedAPI = false;
                this.httpService.store(VTERM_DECRYPT_END_POINT, {vTermToken: this.token}).subscribe(
                async decryptResponse => {
                        console.log("decryptResponse: ngOnInit: customer registration", decryptResponse);
                        this.httpService.authenticatedAPI = true;
                        if(validateFor(BE_ERROR_KEY, decryptResponse) && decryptResponse[BE_ERROR_KEY]){
                            this.routeToNotFoundPage(CORRUPTED_TOKEN_MESSAGE);
                            return;
                        }
                        this.terminalNumbersObj = validateFor(BE_DATA_KEY, decryptResponse) 
                            && validateFor(PAYLOAD_TERMINAL_NUMBERS_KEY, decryptResponse[BE_DATA_KEY]) 
                            ? decryptResponse[BE_DATA_KEY][PAYLOAD_TERMINAL_NUMBERS_KEY]: {};
                        console.log('terminalNumbersObj: after decryption', this.terminalNumbersObj);
                        try{
                            let isloginStoreServiceSet = await this.getCustomerContextData(this.terminalNumbersObj);
                            console.log("is login store service set after hitting customer_context_api?", isloginStoreServiceSet);
                            if(!isloginStoreServiceSet){
                                this.notificationService.error(TERMINAL_DATA_NOT_AVAILABLE, 'Error');
                                this.routeToLoginPage();
                                return;
                            }
                            this.storeId = this.loginStoreService.storeObj.id;
                            this.bizId = this.loginStoreService.storeData.xBusinessID;
                            if(this.bizId){
                                this.form.patchValue({'xBusinessID': this.bizId});
                            }
                            if(this.storeId){
                                this.form.patchValue({'xMainStoreID': this.storeId});
                                this.form.patchValue({'xSignupStoreID': this.storeId});  
                            }
                        }catch(error){
                            if(validateFor(BE_ERRORS_KEY, error ) && validateFor(BE_TERMINAL_NUM_KEY, error[BE_ERRORS_KEY])){
                                console.log("routing to not found page");
                                this.routeToNotFoundPage(INVALID_TERMINAL_NUMBER_MESSAGE);
                                return;
                            }
                            this.notificationService.error(TERMINAL_DATA_NOT_AVAILABLE, 'Error');
                            this.routeToLoginPage()
                            return;
                        }
                        console.log('store data after api call', this.loginStoreService.storeData); 
                    },
                    decryptError => {
                        console.log("error: ngOnInit: customer registration", decryptError);
                        this.httpService.authenticatedAPI = true;
                        this.notificationService.error(TOKEN_DECRYPTION_FAILED_MESSAGE, 'Error');
                    });  
            })
		    console.log("token: ngOnInit", this.token);  
        }
        // console.log("this.loginStoreObj", this.loginStoreObj);
    }

    routeToLoginPage() {
		console.log('previous page called');
		const link = ['/login'];
		this.router.navigate(link);
    }
    
    //If terminal number is tampered, route to not-found page.
	routeToNotFoundPage(message) {
		this.notificationService.error(message, 'Error');
		this.router.navigateByUrl(NOT_FOUND_URL);
	}

    /*
     * process response of role fetch and set default role to customer role
     *
     * @params : rolesResponse : object : response from role fetch
     */
    processRoles(rolesResponse) {
        console.log('processRoles: rolesResponse', rolesResponse);
        this.fetchedRoles = rolesResponse.data.filter(tag => tag.xStatus);
        this.rolesData = this.fetchedRoles.filter(role => role.xTag === TAGSCONSTS.roleTags.roleItemTags.customerRole);
        console.log('processRoles: rolesData', this.rolesData);
        // ------------------------------------------------ if need role modal ---------------------------------------------------------------
        // if (!this.rolesData.length) {
        //     this.noRoles = true;
        // }
        // this.rolesArray = this.rolesData.map((element: any, index: number) => {
        //     console.log('ELEMENT', element);
        //     return {
        //         id: element._id,
        //         text: element.xName,
        //         status: true,
        //         isPresent: false
        //     };
        // });
        // -------------------------------------------------------- END ---------------------------------------------------------------
        // ------------------------------------------------ setting role by default ---------------------------------------------------------------
        if (this.rolesData) {
            this.roles.push(
                new FormGroup({
                    xStatus: new FormControl(1),
                    xRoleID: new FormControl(this.rolesData[0]._id)
                })
            );
            console.log('processRoles: roles', this.roles);
        } else {
            this.notificationService.error(ROLE_FETCH_FAILURE, 'Error');
        }
        // -------------------------------------------------------- END ---------------------------------------------------------------
        console.log('Check this bro====>', JSON.parse(JSON.stringify(this.rolesArray)));
    }

    initValidation(): void {
        this.form = this.fb.group({
            xTntTag: [''],
            xWall1EntyID: [''],
            xWall2EntyID: [''],
            xTag: ['', []],
            xStatus: ['1'],
            xName: ['', []],
            xCompanyName: [
                '',
                [
                    /*Validators.required*/
                ]
            ],
            xTypeTag: [TAGSCONSTS.customerTags.regularCust],
            xNotes: [''],
            xRefNum: ['', []],
            xCustomerNum: ['', [Validators.required]],
            xFormalName: this.fb.group({
                xPrefix: ['', []],
                xForename: ['', [Validators.required]],
                xMiddlename: ['', []],
                xSurname: ['', [Validators.required]],
                xSuffix: ['', []],
                xFullName: ['', []]
            }),
            xAddr: this.fb.group({
                xCO: ['', []],
                xLine1: ['', [Validators.required]],
                xLine2: [''],
                xSuite: [''],
                xCity: ['', [Validators.required]],
                xSOP: ['', [Validators.required]],
                xPostCode: ['', [Validators.required]],
                xCountry: ['US', [Validators.required]]
            }),
            xContact: this.fb.group({
                xEmail: ['', [Validators.required, GlobalValidator.mailFormat]],
                xEmailTypeTag: ['', []],
                xPhone: ['', [Validators.required]],
                xPhoneTypeTag: ['', []]
            }),
            xAltAddr: this.fb.group({
                xCO: [''],
                xLine1: ['', []],
                xLine2: ['', []],
                xSuite: ['', []],
                xCity: ['', []],
                xSOP: ['', []],
                xPostCode: ['', []],
                xCountry: ['', []]
            }),
            xNotify: this.fb.group({
                xL1URLs: [[], []],
                xL2URLs: [[], []],
                xL3URLs: [[], []]
            }),
            xAltContact: this.fb.group({
                xEmail: ['', [GlobalValidator.mailFormat]],
                xEmailTypeTag: ['', []],
                xPhone: ['', []],
                xPhoneTypeTag: ['', []]
            }),
            xBusinessID: [this.bizId],
            xMainStoreID: [this.storeId],
            xSignupStoreID: [this.storeId],
            xPayAccts: this.fb.array([]),
            xUserInfo: this.fb.group({
                xLoginUName: ['', Validators.required] /*(str) In: Username*/,
                xHashedPwd: ['', [Validators.required, GlobalValidator.validatePassword]] /*(str) In: Password*/
            }),
            xRoles: this.fb.array([])
            // xAccStores: this.fb.array([]),
        });

        console.log('check this.form==>', this.form);
    }

    //Set loginStoreService by fetching data from customer_context_endpoint
    //Parameters: termNum: Object
    //Return type: boolean
    getCustomerContextData(terminalNumbersObj): Promise<boolean> {
        console.log('termNum: getCustomerContextData===>', terminalNumbersObj);
		// if (termNum.hasOwnProperty('achTerminalNum') || termNum.hasOwnProperty('creditCardTerminalNum')) {
			this.httpService.authenticatedAPI = false;
			// fetchingTerminal = true;
			const params = {
				terminalNumbersObj: terminalNumbersObj
			};
            this.loginStoreService.setFetchedRelevantData(false);
            return new Promise((resolve, reject) => {
                this.httpService.store(customer_context_endpoint, params).subscribe(
                    customerContextResponse => {
                        console.log('getCustomerContextData: res', customerContextResponse);
                        // this.fetchingTerminal = false;
                        this.httpService.authenticatedAPI = true;
                        let terminalsArray = validateFor(BE_TERMINAL_DATA_KEY, customerContextResponse) ? customerContextResponse.terminalData : [];
                        //If terminal numbers in link and response dont match, terminal number has been tampered.
                        if (terminalsArray.length !== Object.keys(this.terminalNumbersObj).length) {
                            this.routeToNotFoundPage(INVALID_TERMINAL_NUMBER_MESSAGE);
                            return reject(false);
                        }
                        //Before setting loginStoreService check if pctr, store and terminal data are available
                        if (!validateCustomerContextResponse(customerContextResponse)) {
                            this.notificationService.error(PREVIOUS_CONFIGURATION_HAS_BEEN_ALTERED_MESSAGE, 'Error');
                            this.routeToLoginPage();
                            return;
                        }
                        this.loginStoreService.setData(customerContextResponse);
                        return resolve(true);
                    },
                    error => {
                        console.log('getCustomerContextData: error', error);
                        this.httpService.authenticatedAPI = true;
                        // this.fetchingTerminal = false;
                        // this.notificationService.error(TERMINAL_DATA_NOT_AVAILABLE, 'Error');
                        // this.routeToLoginPage();
                        return reject(error);
                    }
                );
            })
			
		// } else {
            // this.notificationService.error('Please enter a terminal number', 'Error');
			// this.fetchingTerminal = false;
        // }
    }

    get roles(): FormArray {
        return this.form.get('xRoles') as FormArray;
    }

    get userInfo(): any {
        return this.form.get('xUserInfo');
    }

    // initializeRolesArray(): void {
    //     for (let i = 0; i < this.usersData[0].xRoles.length; i++) {
    //         for (let j = 0; j < this.rolesArray.length; ++j) {
    //             if (
    //                 this.rolesArray[j].id ===
    //                 this.usersData[0].xRoles[i].xRoleID
    //             ) {
    //                 this.rolesArray[j].isPresent = true; // role is already added
    //                 if (this.usersData[0].xRoles[i].xStatus) {
    //                     this.rolesArray[j].status = true; //role is active
    //                 } else {
    //                     this.rolesArray[j].status = false; //role is in-active
    //                 }
    //             }
    //         }
    //     }
    //     // this.userAssociatedRolesArray = _.cloneDeep(this.rolesArray);
    //     this.userAssociatedRolesArray = JSON.parse(
    //         JSON.stringify(this.rolesArray)
    //     );
    // }

    eidtRoles(): void {
        this.saveRoles = true;
        // this.userAssociatedRolesArray = _.cloneDeep(this.rolesArray);
        console.log('this.userAssociatedRolesArray in editRoles', JSON.parse(JSON.stringify(this.userAssociatedRolesArray)));
        this.userAssociatedRolesArray = JSON.parse(JSON.stringify(this.rolesArray));
        console.log('this.userAssociatedRolesArray in editRoles', this.userAssociatedRolesArray, this.rolesArray);
        this.rolesSelected(this.userAssociatedRolesArray);
        this.rolesModal.hide();
    }

    abortRoleModal(): void {
        if (!this.saveRoles) {
            // this.rolesArray = this.userAssociatedRolesArray.length ? _.cloneDeep(this.userAssociatedRolesArray) : this.rolesArray;
            this.rolesArray = this.userAssociatedRolesArray.length
                ? JSON.parse(JSON.stringify(this.userAssociatedRolesArray))
                : this.rolesArray;
        }
        this.saveRoles = false;
    }

    rolesSelected(value: any[]) {
        while (this.roles.length) {
            this.roles.removeAt(0);
        }
        value.map((element: any) => {
            if (element.isPresent) {
                this.roles.push(
                    new FormGroup({
                        xStatus: element.status ? new FormControl(1) : new FormControl(0),
                        xRoleID: new FormControl(element.id)
                    })
                );
            }
        });
    }

    showPermissionModal() {
        // this.rolesModal.hide(); //TODO-: remove if roles modal is not to be hidden
        this.displayPermissionModalCmp = true;
    }

    showPreviewModal() {
        this.finalRoleIDs = this.roles.value.map((role: any) => role.xRoleID);
        this.displayPreviewPermissionModalCmp = true;
    }

    permissionModalClosed(visible: boolean) {
        this.displayPermissionModalCmp = visible;
        this.getPermissions(this.selectedRoleID, this.selectedRoleName);
    }

    previewPermissionModalClosed(visible: boolean) {
        this.displayPreviewPermissionModalCmp = visible;
    }

    getPermissions(roleID: string, roleName: string) {
        this.loadingRoleAssociatedPerm = true;
        this.selectedRoleName = roleName;
        this.showPermsDiv = true;
        this.selectedRoleID = roleID;
        this.httpService.authenticatedAPI = false;
        forkJoin(this.setupPermissionsStore.getAll({ tree_filter: roleID })).subscribe(
            (response: any) => {
                this.httpService.authenticatedAPI = true;
                // this.processCommonAPIData(response[0].data);
                this.roleAssociatedPermisionsList = response[0].data;
            },
            (error: any) => {
                // this.submitting = false;
                // this.handleErrorResponse(error);
                this.httpService.authenticatedAPI = true;
                this.showPermsDiv = false;
            },
            () => {
                setTimeout(() => {
                    this.loadingRoleAssociatedPerm = false;
                }, 500);
            }
        );
    }

    onItemAddedEditedOrRemoved(val) {
        console.log('here 2', val);
        let currNotifyObj = this.form.getRawValue().xNotify;
        const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        Object.keys(currNotifyObj).map(key => {
            if (currNotifyObj[key].length > 0) {
                let incorrectFormat = false;
                currNotifyObj[key].map(obj => {
                    console.log('OBJ--->', obj);
                    let val = obj.value;
                    if (val !== '' && (val.length <= 5 || !EMAIL_REGEXP.test(val))) {
                        // this.modifyUrlLevelMailFormats(key, true);
                        incorrectFormat = true;
                    }
                });
                this.modifyUrlLevelMailFormats(key, incorrectFormat);
            } else {
                this.modifyUrlLevelMailFormats(key, false);
            }
        });
    }

    onItemEdited(val, level) {
        const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        if (val.value !== '' && (val.value.length <= 5 || !EMAIL_REGEXP.test(val.value))) {
            this.modifyUrlLevelMailFormats(level, true);
        } else {
            this.modifyUrlLevelMailFormats(level, false);
        }
    }

    modifyUrlLevelMailFormats(key, value) {
        switch (key) {
            case 'xL1URLs':
                console.log('case1');
                this.l1IncorrectNotifyMailFormat = value;
                break;
            case 'xL2URLs':
                console.log('case2');
                this.l2IncorrectNotifyMailFormat = value;
                break;
            case 'xL3URLs':
                console.log('case3');
                this.l3IncorrectNotifyMailFormat = value;
                break;
        }
        if (this.l1IncorrectNotifyMailFormat || this.l2IncorrectNotifyMailFormat || this.l3IncorrectNotifyMailFormat) {
            this.dontSubmit = true;
        } else {
            this.dontSubmit = false;
        }
    }

    onBlurPasswordCheck(event) {
        console.log('event onBlurPasswordCheck----------->', event);

        GlobalValidator.passwordFormat(event)
            .then(res => {
                console.log('this.valid', res);
                if (res) {
                    console.log('this.valid 2', res);
                    this.valid = true;
                }
            })
            .catch(err => {
                console.log('this.valid3', err);
                if (!err) {
                    console.log('this.valid3', err);
                    this.valid = false;
                }
            });
    }

    onSubmit(form) {
        console.log('onSubmit form', form);
        this.submitting = true;
        this.userInformation = form.value.hasOwnProperty('xUserInfo') ? form.value.xUserInfo : this.userInformation;
        this.userRoles = form.value.hasOwnProperty('xRoles') ? form.value.xRoles : this.userRoles;
        console.log('onSubmit: userRoles', this.userRoles);
        // if (!this.userRoles.length) {
        //     this.notificationService.error('Please select at least one role', 'ERROR');
        //     this.submitting = false;
        //     return;
        // }
        let errorMessage = this.checkFormValidity(form);
        if (errorMessage) {
            console.log('onSubmit: validity fail');
            this.submitting = false;
            this.notificationService.error(errorMessage, 'Error');
            return;
        }

        let fName = form.controls.xFormalName ? form.controls.xFormalName.value.xForename : '';
        let lName = form.controls.xFormalName ? form.controls.xFormalName.value.xSurname : '';
        console.log('fname', fName, '   lname', lName);
        form.controls.xName.setValue(fName + ' ' + lName);
        let customerRegistrationData = form.getRawValue();

        delete customerRegistrationData.xRoles;

        delete customerRegistrationData.xUserInfo;
        console.log('customerRegistrationData', customerRegistrationData);
        if (customerRegistrationData && customerRegistrationData.hasOwnProperty('safe')) {
            customerRegistrationData.safe.forEach(element => {
                element.xTypeTag = TAGSCONSTS.safeTags.regularSafe;
            });
        }
        if (customerRegistrationData.hasOwnProperty('xNotify')) {
            Object.keys(customerRegistrationData['xNotify']).map(key => {
                let tempArr = [];
                customerRegistrationData['xNotify'][key].map(val => {
                    tempArr.push(val.value);
                });
                console.log(JSON.parse(JSON.stringify(tempArr)));
                customerRegistrationData['xNotify'][key] = [];
                console.log(JSON.parse(JSON.stringify(customerRegistrationData['xNotify'])));
                tempArr.map(finalEmail => {
                    customerRegistrationData['xNotify'][key].push(finalEmail);
                });
                customerRegistrationData['xNotify'][key] = customerRegistrationData['xNotify'][key].toString();
            });
        }
        // this.serverErrors = {}; //Reset Errors
        //Add New setupBillingCustomer
        customerRegistrationData.user = {
            xTntTag: 'test',
            xWall1EntyID: '',
            xWall2EntyID: '',
            xEmployeeNum: '',
            xTag: '',
            xStatus: 1,
            xName: this.userInformation.xLoginUName,
            xTypeTag: TAGSCONSTS.userTags.regularUser,
            xNotes: '',
            xRefNum: '',
            xLoginUName: this.userInformation.xLoginUName,
            xHashedPwd: this.userInformation.xHashedPwd,
            xRoles: this.userRoles,
            xEntyTypeTag: 'cust',
            xEntyID: ''
        };

        //Send password only if user has chnaged it
        if (this.userInfo.controls['xHashedPwd'].dirty) {
            customerRegistrationData.user.xHashedPwd = this.userInformation.xHashedPwd;
        }
        this.httpService.authenticatedAPI = false;
        this.httpService.store(customer_registration_endpoint, customerRegistrationData).subscribe(
            (response: any) => {
                // this.handleSuccessResponse();
                this.httpService.authenticatedAPI = true;
                this.submitting = false;
                this.handleSubmitSuccessResponse();
            },
            (error: any) => {
                // this.handleErrorResponse(error);
                this.httpService.authenticatedAPI = true;
                console.log('onSubmit: error', error);
                this.submitting = false;
                this.serverErrors = error.errors;
                this.handleError(error);
                console.log('this.serverErrors', this.serverErrors);
            }
        );
    }

    // on submit show notification and route to login as cust page
    handleSubmitSuccessResponse() {
        console.log('handleSubmitSuccessResponse: called');
        this.notificationService.success(CUSTOMER_SUCCESS_CREATION, 'Creation Successful');
        this.navigateToCustomerLogin();
    }

    handleError(error: any): void {
        this.notificationService.error(CUSTOMER_FAIL_CREATION, 'Error');
        console.log('Error inside error handler', error);
        if (Object.keys(error).length && error.hasOwnProperty('errors') && error.errors) {
            for (const field of Object.keys(error.errors)) {
                if (field === 'xFormalName.xForename' || field === 'xFormalName.xSurname') {
                    console.log('This.form', this.form);
                    if (this.form.value.xFormalName.xForename && this.form.value.xFormalName.xSurname) {
                        console.log('Inside true case');
                        delete this.serverErrors['xFormalName.xForename'];
                        delete this.serverErrors['xFormalName.xSurname'];
                        this.formErrors['acctxName'] = 'Field is not allowed to be empty';
                    } else if (this.form.value.hasOwnProperty('safe') && this.form.value.safe[0].xName) {
                        this.formErrors[field] = 'Field is not allowed to be empty';
                    } else {
                        this.formErrors[field] = 'Field is not allowed to be empty';
                        this.formErrors['acctxName'] = 'Field is not allowed to be empty';
                    }
                }
                if (field === 'xCustomerNum') {
                    console.log('Inside handle error', error);
                    if (error.name === 'Conflict') {
                        let err = error.errors.xCustomerNum.replace(/customer/g, 'Customer');
                        this.notificationService.error(err, 'Conflict');
                        this.clearErrors('xCustomerNum');
                    } else {
                        this.formErrors[field] = 'Field is not allowed to be empty';
                    }
                } else {
                    if (this.formErrors.hasOwnProperty(field)) {
                        this.formErrors[field] = 'Field is not allowed to be empty';
                    }
                }
            }
        }
    }

    clearErrors(errorName: string): void {
        if (this.serverErrors[errorName]) {
            delete this.serverErrors[errorName];
        }
    }

    /**
     * generate 10 digit random number
     */
    getRandomNumber() {
        return parseInt((Math.random() * 10000000000).toString()).toString();
    }

    getNewNumber() {
        console.log('get new number clicked...');
        // this.clearErrors(this.numberKey);
        this.isFetchNewNumber = true;
        this.form.controls[this.numberKey].disable();
        this.gmNumberService.fetchGMData(NumAPIEndPoint, { count: '1' }).subscribe(
            (res: any) => {
                this.form.controls[this.numberKey].enable();
                if (res && res.hasOwnProperty('data') && res.data) {
                    if (
                        Object.keys(res).length &&
                        res.constructor === Object &&
                        res['data'].hasOwnProperty('NumList') &&
                        res.data.NumList
                    ) {
                        this.form.controls[this.numberKey].setValue(res.data.NumList);
                    } else this.handleNumberFetch_FailCase();
                } else {
                    this.handleNumberFetch_FailCase();
                }
                this.isFetchNewNumber = false;
            },
            (err: any) => {
                this.form.controls[this.numberKey].enable();
                this.handleNumberFetch_FailCase();
                this.isFetchNewNumber = false;
            }
        );
    }

    handleNumberFetch_FailCase() {
        let randomNumber = this.getRandomNumber();
        console.log('Error in fetching model number inside modelNumber component');
        this.form.controls[this.numberKey].setValue(randomNumber);
        this.notificationService.warning('Unable to fetch model number. Proceeding with random number.', 'Warning');
    }

    navigateToCustomerLogin() {
        let url = document.location.href;
		url = decodeURI(url);
		console.log("url: navigateToCustomerLogin", url);
		let index = url.indexOf('param');
        let token = url.substring(index + 6);

        this.router.navigate([CUSTOEMR_LOGIN_URL], {
            queryParams: { param: token }
        });
    }

    onKeypress(event: any, source?: string) {
        console.log('event', event, source);
        if (source === 'phoneNum') {
            GlobalValidator.phoneNumberFormat(event);
        } else if (source === 'postCode') {
            GlobalValidator.numberOnlyFormat(event);
        }
    }

    onPaste(event: any, source?: string) {
        console.log('event in paste', event, source);
        if (source === 'phoneNum') {
            GlobalValidator.phoneNumberFormat(event);
        } else if (source === 'postCode') {
            GlobalValidator.numberOnlyFormat(event);
        }
    }

    // checks form validity for certain fields and return boolean value
    checkFormValidity(form: FormGroup) {
        let formValue = form.getRawValue();
        console.log('checkFormValidity: xroles length', formValue.xRoles.length);
        if (!form.valid || !formValue.xRoles.length) {
            console.log('checkFormValidity:form value', formValue);
            console.log('checkFormValidity:form', form);
            this.setControlsTouched(this.form);
            this.setControlsTouched(this.form.controls['xAddr']);
            this.setControlsTouched(this.form.controls['xContact']);
            this.setControlsTouched(this.form.controls['xFormalName']);
            this.setControlsTouched(this.form.controls['xUserInfo']);
            if (!formValue.xRoles.length) {
                this.serverErrors['xRoles'] = true;
            }

            if(
                !validateFor(REQUIRED_KEY, form.get('xUserInfo').get('xHashedPwd').errors)
                && validateFor(INVALID_PASSWORD_KEY, form.get('xUserInfo').get('xHashedPwd').errors) 
            ){
                console.log('PASSWORD INVALIDITY EXISTS');
                return MESSAGES.InvalidPasswordFormat.message;
            }
            return MESSAGES.FillAllRequiredFields.message;
        }
        return '';
    }

    // run loop on control to set them as touched
    setControlsTouched(formControl) {
        console.log('setControlsTouched: formcontrol', formControl);
        Object.keys(formControl['controls']).forEach(controlField => {
            console.log('setControlsTouched: control', controlField);
            formControl['controls'][controlField].markAsTouched();
        });
    }

    ngOnDestroy() {
        this.alive = false;
    }
}