import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TimerService {

    fetch:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);


    watchFetch$= this.fetch.asObservable();

    public mainTimer: any;
    public miniTimer: any;

    public timer:number=0;


    constructor() { }

    startMainTimer() {
        console.log("main timer", this.mainTimer);
        if(this.mainTimer){
            this.stopMainTimer();
        }
        this.mainTimer = setInterval(() => {
            this.timer += 1;
            console.log("this.timer", this.timer);
            if (this.timer === 60 * 5) {
               this.stopMainTimer();
            }
        }, 1000);
    }

    stopMainTimer(){
        if(this.mainTimer){
            clearInterval(this.mainTimer);
            this.mainTimer=0;
            this.setFetch(false);
            this.timer=0;
        }
    }

    startMiniTimer():Promise<boolean>{
        return new Promise((resolve,reject)=>{
            if(this.miniTimer){
                this.stopMiniTimer();
            }
            this.miniTimer=setInterval(()=>{
                // this.displayGrid(param,scroll); 
                if((this.timer+20) === 60*5){
                    this.stopMiniTimer();
                }
                return  this.setFetch(true);
            }, 20000);
        })
    }

    stopMiniTimer(){
        if(this.miniTimer){
            clearInterval(this.miniTimer);
            this.miniTimer=0;
            // this.setFetch(false);
            // this.timer=0;
        }
    }

    setFetch(fetch:boolean){
        this.fetch.next(fetch);
    }
}