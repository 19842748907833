import { NgModule } from '@angular/core';

import { ValidateOnBlurDirective } from '../../directive/validate-on-blur-directive';

@NgModule({
    imports: [],
    declarations: [ValidateOnBlurDirective],
    exports: [ValidateOnBlurDirective],
    providers: []
})
export class ResourceEditorModule {}
