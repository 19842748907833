import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
	OutstandingBatchesComponent,
	SettledBatchesComponent,
	LayoutComponent,
	HomeComponent,
	DashboardComponent,
	OutstandingTransactionsComponent,
	SettledTransactionsComponent,
	StatusesComponent,
	StatisticsComponent,
	CsvFileHistoryComponent,
	PayNowComponent,
} from './index';
import { PaymentFormComponent } from './pay-now/components/payment-form/payment-form.component';
import { CreateNewPaymentTypeComponent } from './pay-now/components/create-new-payment-type/create-new-payment-type.component';
import { SelectPaymentMethodComponent } from './pay-now/components/select-payment-method/select-payment-method.component';
import { ConfirmPaymentComponent } from './pay-now/components/confirm-payment/confirm-payment.component';
import { AuthGuard } from '../shared/guard/auth-guard/auth.guard';
import { UserAccessResolver } from '../shared/resolver';
import { RoleGuard } from 'app/shared';
import { CompanySuspendedComponent } from 'app/pages/company-suspended/company-suspended.component';
import { CompanySuspendedGuard } from 'app/pages/company-suspended/guard/company-suspended.guard';

const routes: Routes = [
	{
		path: 'company-suspended',
		canActivate: [AuthGuard],
		component: CompanySuspendedComponent,
	},
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		resolve: {
			user: UserAccessResolver,
		},
		children: [
			{
				path: '',
				canActivateChild: [AuthGuard, CompanySuspendedGuard],
				children: [
					{
						path: 'coming-soon',
						loadChildren: () => import('./coming-soon/coming-soon.module').then((m) => m.ComingSoonModule),
						data: {
							breadcrumb: 'Coming Soon',
						},
					},
					{
						path: '',
						canActivateChild: [RoleGuard],
						// // loadChildren: './home/home.module#HomeModule',
						component: LayoutComponent,
						children: [
							{
								path: 'home',
								component: HomeComponent,
								// loadChildren:
								// "./coming-soon/coming-soon.module#ComingSoonModule"
							},
							{
								path: 'outstanding-batches',
								component: OutstandingBatchesComponent,
							},
							{
								path: 'settled-batches',
								component: SettledBatchesComponent,
							},
							{
								path: 'outstanding-transactions',
								component: OutstandingTransactionsComponent,
							},
							{
								path: 'outstanding-transactions/batch/:Id',
								component: OutstandingTransactionsComponent,
							},
							{
								path: 'outstanding-transactions/cust/:Id',
								component: OutstandingTransactionsComponent,
							},
							{
								path: 'settled-transactions',
								component: SettledTransactionsComponent,
							},
							{
								path: 'settled-transactions/batch/:Id',
								component: SettledTransactionsComponent,
							},
							{
								path: 'settled-transactions/cust/:Id',
								component: SettledTransactionsComponent,
							},
							{ path: 'statuses', component: StatusesComponent },
							// { path: "application-logs", component: ApplicationLogsComponent },
							// { path: "system-logs", component: SystemLogComponent },
							{
								path: 'statistics',
								component: StatisticsComponent,
							},
						],
					},
					{
						path: 'setup',
						loadChildren: () => import('./setup/setup.module').then((m) => m.SetupModule),
						data: {
							breadcrumb: { root: 'Setup' },
						},
					},
					{
						path: 'vterminal',
						loadChildren: () => import('./vterminal/vterminal.module').then((m) => m.VterminalModule),
						data: {
							breadcrumb: 'Vterminal',
						},
					},
					{
						path: 'transactions',
						redirectTo: '/dashboard/outstanding-transactions',
						// loadChildren: './transaction/transaction.module#TransactionModule'
					},
					{
						path: 'vault',
						loadChildren: () => import('./vault/vault.module').then((m) => m.VaultModule),
					},
					{
						path: 'support',
						loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
					},
					{
						path: 'csv-file-history',
						component: CsvFileHistoryComponent,
					},
					{
						path: 'import-transactions',
						canActivate: [RoleGuard],
						loadChildren: () => import('./import-transactions/import-transactions.module').then((m) => m.ImportTransactionsModule),
					},
				],
			},
			{
				path: 'customer',
				component: LayoutComponent,
				canActivateChild: [RoleGuard, CompanySuspendedGuard],
				children: [
					{
						path: 'outstanding-transactions',
						component: OutstandingTransactionsComponent,
					},
					{
						path: 'pay-now',
						component: PayNowComponent,
						children: [
							{
								path: '',
								component: PaymentFormComponent,
							},
							{
								path: 'create-payment-method',
								component: CreateNewPaymentTypeComponent,
							},
							{
								path: 'select-payment-method',
								component: SelectPaymentMethodComponent,
							},
							{
								path: 'confirm-payment',
								component: ConfirmPaymentComponent,
							},
						],
					},
				],
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class DashboardRoutingModule {}
