import { Injectable } from '@angular/core';
 

import { HttpService } from '../shared/services';
import { TRANSACTIONLIMIT } from '../constants/configuration.constants';
const endpoint = 'xpeSettledTxns';

@Injectable()
export class SettledTransactionsStore {
    static TransactionsData: any[];
    protected gridProps: any = {};

    constructor(protected httpService: HttpService) {}

    getAll(params: any): any {
        if(params.hasOwnProperty('limit')){
            // params.limit=TRANSACTIONLIMIT;
        }
        else{
            params['limit']=TRANSACTIONLIMIT;
        }
        return this.httpService.getAll(endpoint, params);
    }

    store(element: any): any {
        return this.httpService.store(endpoint, element);
    }

    update(elementID: string, newElement: any): any {
        return this.httpService.update(endpoint, elementID, newElement);
    }

    get(elementID: any): any {
        return this.httpService.get(endpoint, elementID);
    }

    destroy(elementID: any): any {
        return this.httpService.destroy(endpoint, elementID);
    }

    getViewGridProps(): any {
        const editLink = '';
        const viewLink = '';
        this.gridProps.action = [
            { type: 'edit', visible: false, text: 'Edit', icon: 'fa fa-edit', action: 'url', onClick: editLink },
            { type: 'delete', visible: false, text: 'Delete', icon: 'fa fa-trash', action: 'func', onClick: 'destroy' },
            { type: 'toggle', visible: false, text: 'Activate/De-Activate', icon: 'fa fa-toggle-on', action: 'func', onClick: 'toggle' },
            { type: 'view', visible: false, text: 'Details', icon: 'fa fa-info-circle', action: 'func', onClick: viewLink }
        ];
        this.gridProps.storeValue = 'TransactionsViewStore';
        this.gridProps.attributes = [
            { header: 'Time_Settle', mapTo: 'Time_Settle' },
            { header: 'Acct_Number', mapTo: 'Acct_Number' },
            { header: 'Srvr_Num', mapTo: 'Srvr_Num' },
            { header: 'Chain', mapTo: 'Chain' },
            { header: 'City', mapTo: 'City' },
            { header: 'State', mapTo: 'State' },
            { header: 'Status', mapTo: 'Status' }
            //, {'header': 'Actions'       , 'mapTo': '' }
        ];
        return this.gridProps;
    }
}
