import { Injectable } from '@angular/core';
import { API_END_POINT } from 'app/constants';
import { HttpService } from '../shared/services/index';

@Injectable()
export class ImportTxnsStore {
	constructor(protected httpService: HttpService) {}

	getAll(params: any): any {
		return this.httpService.getAll(API_END_POINT.importTransaction, params)
	}

	store(element: any): any {
		return this.httpService.store(API_END_POINT.importTransaction, element)
	}

	update(elementID: string, newElement: any): any {
		return this.httpService.update(API_END_POINT.importTransaction, elementID, newElement)
	}

	get(elementID: any): any {
		return this.httpService.get(API_END_POINT.importTransaction, elementID)
	}

	destroy(data: any): any {
		return this.httpService.store(API_END_POINT.importTransaction, data);
	}
}
