<!-- loader beginning -->
<div *ngIf="tokenDecryptionLoader" class="hide-vertical-scroll" style="text-align: center; position:realtive">
	<div class="col-md-12">
		<div class="grid-spinner">
			<div class="bounce1"></div>
			<div class="bounce2"></div>
			<div class="bounce3"></div>
		</div>
		<span style="color:#3b5877">Fetching required data</span>
	</div>
</div>
<!-- loader ending -->
<section *ngIf="!tokenDecryptionLoader" class="padder debug-info-wrap">
    <div class="row m-t-30">
        <div class="col-md-10 col-lg-10 offset-md-1">
            <!-- success message pay-now beginning-->
            <div class="successAlertDiv" *ngIf="paymentResponse?.Status  && paymentResponse?.Status === SUCCESS">
                <div class="alert alert-success">
                    <h4 class="modal-title">
                        Transaction Successful
                    </h4>
                    <div>
                        <h6 class="modal-title">Transaction Info:</h6>
                        {{ paymentResponse?.ResponseText }}
                    </div>
                    <div>
                        <h6 class="modal-title">
                            Status:
                        </h6>
                        {{ paymentResponse?.ResultText }}
                    </div>
                </div>
            </div>
            <!-- success message pay-now ending-->
            <!-- failure message pay-now beginning-->
            <div class="failedAlertDiv" *ngIf="paymentResponse?.Status && paymentResponse?.Status !== SUCCESS">
                <div class="alert alert-danger">
                    <div>
                        <h4 class="modal-title">Transaction Failed </h4>
                    </div>
                    <div>
                        <h6 class="modal-title">Transaction Info:</h6>
                        {{ paymentResponse?.ErrorText }}
                    </div>
                    <div>
                        <h6 class="modal-title">
                            Status:
                        </h6>
                        {{ paymentResponse?.ResultText }}
                    </div>
                </div>
            </div>
            <!-- failure message pay-now ending-->
            <!-- success message payment-method beginnig-->
            <div class="successAlertDiv" *ngIf="createPaymentResponse?.paymentMethodSuccessMsg">
                <div class="alert alert-success">
                    <h6 class="modal-title">
                        {{ createPaymentResponse.paymentMethodSuccessMsg }}
                    </h6>
                </div>
            </div>
            <!-- success message payment-method ending-->
            <!-- failure message payment-method beginning-->
            <div class="failedAlertDiv" *ngIf="paymentMethodErrorMessage">
                <div class="alert alert-danger">
                    <h6 class="modal-title">{{ PAYMENT_METHOD_FAILED_TO_REGISTER_ERROR_MESSAGE }} {{ paymentMethodErrorMessage }}</h6>
                </div>
            </div>
            <!-- failure message payment-method  end-->
            <!-- payment-method response beginning-->
            <div *ngIf="createPaymentResponse?.showCreatePaymentInfo" class="card card-default">
                <div class="card-body vterm-info">
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Customer Name:</label>
                            <div>{{ createPaymentResponse?.customerName }}</div>
                        </div>
                        <div class="col-sm-6">
                            <label>Card-Token:</label>
                            <!-- <div>{{ createPaymentResponse?.cardToken }}</div> -->
                            <div>{{ createPaymentResponse?.maskedCardToken }}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Medium:</label>
                            <div>{{ createPaymentResponse?.medium }}</div>
                        </div>
                        <div class="col-sm-6">
                            <label>Brand:</label>
                            <div>{{ createPaymentResponse?.brand }}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Account Number:</label>
                            <div>{{ createPaymentResponse?.accountNum }}</div>
                        </div>
                        <div *ngIf="createPaymentResponse?.medium === MEDIUM_ELECTRONIC_CHECK" class="col-sm-6">
                            <label>Routing Number:</label>
                            <div>{{ createPaymentResponse?.routingNum }}</div>
                        </div>
                        <div *ngIf="createPaymentResponse?.medium === MEDIUM_CREDIT" class="col-sm-6">
                            <label>Expiry Date:</label>
                            <div>{{ createPaymentResponse?.expMonth }}/{{ createPaymentResponse?.expYear }}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="createPaymentResponse?.medium === MEDIUM_ELECTRONIC_CHECK" class="col-sm-6">
                            <label>Account Type:</label>
                            <div>{{ createPaymentResponse?.accountType }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- payment-method response ending-->
            <!-- payment response beginnig -->
            <div *ngIf="paymentResponse?.showPaymentInfo" class="card card-default">
                <div class="card-body vterm-info">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Account Number: </label>
                                <!-- <p>{{paymentResponse?.AccountNum}}</p> -->
                                <div *ngIf="paymentResponse.AccountNum">{{ paymentResponse?.AccountNum }}</div>
                                <div *ngIf="!paymentResponse.AccountNum">-</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Transaction Type: </label>
                                <div *ngIf="paymentResponse.TransType">
                                    {{ transactionType[(paymentResponse?.TransType)] }}
                                </div>
                                <div *ngIf="!paymentResponse.TransType">-</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Auth Amt: </label>
                                <div *ngIf="paymentResponse.AuthAmt">{{ paymentResponse?.AuthAmt }}</div>
                                <div *ngIf="!paymentResponse.AuthAmt">-</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group no-margin">
                                <label>Auth Code: </label>
                                <div *ngIf="paymentResponse.AuthCode">{{ paymentResponse?.AuthCode }}</div>
                                <div *ngIf="!paymentResponse.AuthCode">-</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Brand: </label>
                                <!-- <p>{{paymentResponse?.Brand}}</p> -->
                                <div *ngIf="paymentResponse.Brand">{{ paymentResponse?.Brand }}</div>
                                <div *ngIf="!paymentResponse.Brand">-</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group no-margin">
                                <label>Medium: </label>
                                <!-- <p>{{paymentResponse?.Medium}}</p> -->
                                <div *ngIf="paymentResponse.Medium">{{ paymentResponse?.Medium }}</div>
                                <div *ngIf="!paymentResponse.Medium">-</div>
                            </div>
                        </div>
                        <!-- <div class="col-sm-4">
                            <div class="form-group no-margin">
                                <label>Entry Mode: </label>
                                <div *ngIf="paymentResponse.EntryMode">{{ paymentResponse?.EntryMode }}</div>
                                <div *ngIf="!paymentResponse.EntryMode">-</div>
                            </div>
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>GTRC: </label>
                                <div *ngIf="paymentResponse.GTRC">{{ paymentResponse?.GTRC }}</div>
                                <div *ngIf="!paymentResponse.GTRC">-</div>
                            </div>
                        </div>
                        <div *ngIf="paymentResponse.CardToken" class="col-sm-4">
                            <div class="form-group">
                                <label>Card Token: </label>
                                <div>{{ paymentResponse?.maskedCardToken }}</div>
                            </div>
                        </div>
                        <!-- <div *ngIf="paymentResponse.CardToken" class="col-sm-4">
                            <div class="form-group">
                                <label>Card Token: </label>
                                <div>{{ paymentResponse?.maskedCardToken }}</div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- payment response ending -->
            <!-- customer details beginnig -->
            <div class="card card-default">
                <div class="card-header"><label>Customer Details</label></div>
                <div class="card-body vterm-info">
                    <div class="row no-margin">
                        <div class="col-sm-6">
                            <label>Customer Name: </label>
                            <div class="no-margin" [tooltip]=entityData?.xName placement="top" containerClass="tooltip-global">
                                {{ truncateString(entityData?.xName,TRUNCATE_LENGTH) }}
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>Member Number: </label>
                            <div class="no-margin">{{ entityData?.xCustomerNum }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- customer details ending -->
            <div class="d-flex justify-content-center">
                <button
                    class="btn btn-primary pull-right"
                    type="button"
                    (click)="onProceed(PAYMENT_MODE_PAYNOW)"
                    container="body"
                    tooltip="Pay Now"
                    placement="top"
                    containerClass="tooltip-global"
                    [disabled]="shouldDisableBtn"
                >
                    <span>
                        Pay Now
                        <i class="fa fa-angle-double-right" aria-hidden="true" *ngIf="!payNowBtnLoader"></i>
                        <i
                            class="fa fa-spinner fa-spin"
                            *ngIf="payNowBtnLoader && paymentMode === PAYMENT_MODE_PAYNOW"
                            aria-hidden="true"
                        ></i>
                    </span>
                </button>

                <button
                    class="btn btn-primary pull-right m-r-10 ml-1"
                    type="button"
                    (click)="onProceed(PAYMENT_MODE_CREATE_PAYMENT)"
                    container="body"
                    tooltip="Register Payment Method"
                    placement="top"
                    containerClass="tooltip-global"
                    [disabled]="shouldDisableBtn"
                >
                    <span>
                        Manage Accounts
                        <i
                            class="fa fa-angle-double-right"
                            aria-hidden="true"
                            *ngIf="!manageAcctsBtnLoader"
                        ></i>
                        <i
                            class="fa fa-spinner fa-spin"
                            *ngIf="manageAcctsBtnLoader && paymentMode === PAYMENT_MODE_CREATE_PAYMENT"
                            aria-hidden="true"
                        ></i>
                    </span>
                </button>
            </div>
        </div>
    </div>
    <!-- debug-info details beginning -->
    <div class="debug-info fixed-bottom">
        <div>
            <div class="form-group">
                <label>Store Name:</label>
                <div>{{ truncateString(storeData?.xName, TRUNCATE_LENGTH) }}</div>
            </div>
            <div class="form-group no-margin">
                <label>Store RefNum: </label>
                <div>{{ storeData?.xRefNum }}</div>
            </div>
        </div>
        <div>
            <div class="form-group">
                <label>Profit Center: </label>
                <div>{{ truncateString(pCtrData?.xName, TRUNCATE_LENGTH) }}</div>
            </div>
            <div class="form-group no-margin">
                <label>GMID: </label>
                <div>{{ pCtrData?.xGMID }}</div>
            </div>
        </div>
        <div *ngIf="ccTerminalData?.xName">
            <div class="form-group">
                <label>CC Terminal: </label>
                <div>{{ truncateString(ccTerminalData?.xName, TRUNCATE_LENGTH) }}</div>
            </div>
            <div class="form-group no-margin">
                <label>CC GTID: </label>
                <div>{{ ccTerminalData?.xGTID }}</div>
            </div>
        </div>
        <div *ngIf="achTerminalData?.xName">
            <div class="form-group">
                <label>ACH Terminal: </label>
                <div>{{ truncateString(achTerminalData?.xName, TRUNCATE_LENGTH) }}</div>
            </div> 
            <div class="form-group no-margin">
                <label>ACH GTID: </label>
                <div>{{ achTerminalData?.xGTID }}</div>
            </div>
        </div>    
    </div>                 
    <!-- debug-info details ending -->
</section>
