export class Address {
    xCO: string;
    xLine1: string;
    xLine2: string;
    xSuite: string;
    xCity: string;
    xSOP: string;
    xPostCode: number;
    xCountry: string;

    constructor(obj?: any) {
        this.xCO = (obj && obj.xCO) || 'care of';
        this.xLine1 = (obj && obj.xLine1) || '';
        this.xLine2 = (obj && obj.xLine2) || '';
        this.xSuite = (obj && obj.xSuite) || '';
        this.xCity = (obj && obj.xCity) || '';
        this.xSOP = (obj && obj.xSOP) || '';
        this.xPostCode = (obj && obj.xPostCode) || null;
        this.xCountry = (obj && obj.xCountry) || '';
    }
}

