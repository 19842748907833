import { validateFor } from '../../../../common-functions';

export const INVALID_TERMINAL_NUMBER_MESSAGE: string = 'Invalid Terminal Number. Please contact support.';
export const NOT_FOUND_URL: string = '/not-found';
export const PREVIOUS_CONFIGURATION_HAS_BEEN_ALTERED_MESSAGE = 'Previous configuration has been altered. Please contact support.';
export const TOKEN_DECRYPTION_FAILED_MESSAGE: string = 'Failed to decrypt token. Please try again.';
export const CORRUPTED_TOKEN_MESSAGE: string = 'Token has been malformed. Please try again.';
export const PAYLOAD_TERMINAL_NUMBERS_KEY: string = 'terminalNumbers';
export const NO_TOKEN_AVAILABLE_MESSAGE: string = 'No token available. Please check URL.'

export const PARAM_STRING: string = 'param';

const BE_TERMINAL_DATA_KEY: string = 'terminalData';
const BE_PCTR_DATA_KEY: string = 'pctrData';
const BE_STORE_DATA_KEY: string = 'storeData';
/**
 * Check if termNum object is set
 * @return boolean: True if termNum is set, False if not set.
 */
export function validateTerminalNumbers(terminalNumbersObj: any = {}) {
	if (!terminalNumbersObj || !Object.keys(terminalNumbersObj).length) {
		return false;
	}
	return true;
}

/**
 * Check if pctr, store or terminal data are empty
 * @param `customerContextResponse`: object: Customer context api
 */
export function validateCustomerContextResponse(customerContextResponse: any) {
	let pctrData = validateFor(BE_PCTR_DATA_KEY, customerContextResponse) ? customerContextResponse[BE_PCTR_DATA_KEY] : {};
	let storeData = validateFor(BE_STORE_DATA_KEY, customerContextResponse) ? customerContextResponse[BE_STORE_DATA_KEY] : {};
	let terminalData = validateFor(BE_TERMINAL_DATA_KEY, customerContextResponse) ? customerContextResponse[BE_TERMINAL_DATA_KEY] : {};
	if (!Object.keys(pctrData).length || !Object.keys(storeData).length || !Object.keys(terminalData).length) {
		return false;
	}
	return true;
}
