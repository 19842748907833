// run loop on control to set them as touched
export function setControlsTouched(formControl) {
    if (!formControl) {
        console.log('setControlsTouched: return value', formControl);
        return;
    }
    console.log('setControlsTouched: formcontrol', formControl);
    Object.keys(formControl['controls']).forEach(controlField => {
        console.log('setControlsTouched: control', controlField);
        formControl['controls'][controlField].markAsTouched();
    });
}
