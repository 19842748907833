import { Injectable } from '@angular/core';
 
import { HttpService } from '../shared/services/index';
import { map } from 'rxjs/operators';
const endPoint = 'xscRoles';
const deleteRoleEndPoint = 'roleLookup';

@Injectable()
export class SetupSecurityRolesStore {
    static rolesData: any[];

    static toggle(element: any): void {
    }

    constructor(protected httpService: HttpService) {}

    getAll(params: any): any {
        if (params.businessID) {
            delete params.businessID;
        }
        return this.httpService.getAll(endPoint, params)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    store(element: any): any {
        return this.httpService.store(endPoint, element)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    update(elementID: string, newElement: any): any {
        return this.httpService.update(endPoint, elementID, newElement)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    get(elementID: any): any {
        return this.httpService.get(endPoint, elementID)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    destroy(elementID: any): any {
        return this.httpService.get(deleteRoleEndPoint, elementID)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }
}
