<section *ngIf="!loading" class="setup buis-info">
    <form class="form-horizontal" [ngClass]="{'disable-form':false}"
        [formGroup]="form" (ngSubmit)="onSubmit(form)">
        <div class="row top-stat">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-check-circle-o label-icon" aria-hidden="true"></i>
                        <!-- <i class="fa fa-user-circle-o" aria-hidden="true"></i> -->
                        <label class="p-l-5" for="name">Status:</label>
                        <label class="detail-label"> Active</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-sliders label-icon" aria-hidden="true"></i>
                        <label for="name" class="p-l-5">Type:</label>
                        <label class="detail-label">Terminal</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-briefcase label-icon" aria-hidden="true"></i>
                        <label for="dba_name" class="p-l-5">Store:</label>
                        <label class="detail-label">{{storeName}}</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-level-up label-icon fa-flip-horizontal" aria-hidden="true"></i>
                        <label for="dba_name" class="p-l-5">Merchant Account:</label>
                        <label class="detail-label">{{merAccName}}</label>
                    </div>
                </div>
            </div>
        </div>
        		<div>

        <div class="row buis-info">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">
                        <label class="">Basic Information </label>
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-12 col-sm-12">
                                <label for="name">Name:
                                    <small class="text-danger">*</small>
                                </label>
                                <small *ngIf="!serverErrors['xName'] && form.get('xName').hasError('required') && form.get('xName').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>
                                <small *ngIf="!serverErrors['xName'] && form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is of incorrect format. Correct Format-: 1) Name can not be more than 20 letters. 2) The first character of Name should not be an empty space. 3) The first character of Name should not be any special characters, E.g. /,& etc."
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                                <small *ngIf="serverErrors['xName']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="{{formErrors.xName}}" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <input type="text" (focus)="clearErrors('xName')" class="form-control form-control-sm" id="name" formControlName="xName"
                                    [ngClass]="{'has-error': (form.get('xName').hasError('required') && form.get('xName').touched) || (form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched) || serverErrors['xName']}"
                                    validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-12">
                                <label for="tag">Tag: </label>
                                <input type="text" class="form-control form-control-sm" id="tag" (focus)="clearErrors('xTag')" formControlName="xTag">
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="ref_num">Ref Number: </label>
                                <small *ngIf="!serverErrors['xRefNum'] && form.get('xRefNum').hasError('required') && form.get('xRefNum').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Reference is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <small *ngIf="serverErrors['xRefNum']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="{{formErrors.xRefNum}}" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>
                                <i class="fa fa-info-circle" tooltip="Do not enter the reference number unless instructed to do so" placement="top" containerClass="tooltip-global"
                                    container="body" aria-hidden="true"></i>
                                <input type="text" class="form-control form-control-sm" id="ref_num" (focus)="clearErrors('xRefNum')" formControlName="xRefNum"
                                    [ngClass]="{'has-error': (form.get('xRefNum').hasError('required') && form.get('xRefNum').touched) || serverErrors['xRefNum']}"
                                    validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-6 col-sm-12">
                                <label for="ter_num">Terminal Number:
                                    <small class="text-danger">*</small>
                                </label>
                                <app-model-number [modelNumber]=payDevInfo.xTerminalNum [form]=form [serverErrors]=serverErrors [numberKey]=numberKey></app-model-number>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="short_code">Short Code:
                                    <small class="text-danger">*</small>
                                </label>
                                <small *ngIf="!serverErrors['xShortCode'] && form.get('xShortCode').hasError('required') && form.get('xShortCode').touched"
                                    class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Unique Short Code is required" placement="top"
                                        containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>

                                <small *ngIf="serverErrors['xShortCode']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Unique Short Code is required" placement="top"
                                        containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>

                                <input type="text" class="form-control form-control-sm" id="short_code" (focus)="clearErrors('xShortCode')" formControlName="xShortCode"
                                    [ngClass]="{'has-error': (form.get('xShortCode').hasError('required') && form.get('xShortCode').touched) || serverErrors['xShortCode']}"
                                    validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row m-t-5">
                            <div class="col-md-6 col-sm-12">
                                <label for="regSerNum">Serial Number:</label>
                                <input type="text" class="form-control form-control-sm" id="regSerNum" formControlName="xRegSerNum" readonly>
                            </div>
                            <div class="col-md-6 col-sm-12" style="padding-top: 26.25px;">
                                <input class=" btn btn-sm btn-primary pull-left " style="height: 36.04px;"
                                       name="next" type="button"

                                       *ngIf="form.get('xRegSerNum').value === ''"
                                       (click)="openRegisterTerminalModal(registerTerminaltemplate, 'register')"
                                       value="Register Terminal "
                                >
                                <input class=" btn btn-sm btn-primary pull-left " style="height: 36.04px;"
                                       name="next" type="button"
                                       *ngIf="form.get('xRegSerNum').value !== ''"
                                       (click)="openRegisterTerminalModal(registerTerminaltemplate, 'unregister')"
                                       value="Unregister Terminal "
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <label>Gateway</label>
                    </div>
                    <div class="card-body">
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="uname">Username: </label>
                                <small *ngIf="!serverErrors['xUName'] && form.get('xUName').hasError('required') && form.get('xUName').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="UserName is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <small *ngIf="serverErrors['xUName']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="{{formErrors.xUName}}" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <input type="text" class="form-control form-control-sm" id="uName" (focus)="clearErrors('xUName')" formControlName="xUName"
                                    [ngClass]="{'has-error': (form.get('xUName').hasError('required') && form.get('xUName').touched) || serverErrors['xUName']}"
                                    validateOnBlur>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="pswd">Password: </label>
                                <small *ngIf="!serverErrors['xPwd'] && form.get('xPwd').hasError('required') && form.get('xPwd').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Password is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <small *ngIf="serverErrors['xPwd']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="{{formErrors.xPwd}}" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <input type="password" class="form-control form-control-sm" id="pass" (focus)="clearErrors('xPwd')" formControlName="xPwd"
                                    [ngClass]="{'has-error': (form.get('xPwd').hasError('required') && form.get('xPwd').touched) || serverErrors['xPwd']}"
                                    validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-8 col-sm-12">
                                <label for="gtid">GTID: </label>
                                <small *ngIf="!serverErrors['xGTID'] && form.get('xGTID').hasError('required') && form.get('xGTID').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="GTID is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <small *ngIf="serverErrors['xGTID']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="{{formErrors.xGTID}}" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <div class="input-group">
                                    <input type="text" class="form-control form-control-sm" id="gtid" (focus)="clearErrors('xGTID')" formControlName="xGTID"
                                        [ngClass]="{'has-error': (form.get('xGTID').hasError('required') && form.get('xGTID').touched) || serverErrors['xGTID']
                                        || (form?.get('xGTID').hasError('GTID_InUse') && form?.get('xGTID').touched) 
                                        || (form?.get('xGTID').hasError('GTID_InValid') && form?.get('xGTID').touched), disabled: GV.isValidatingGTID}"
                                        (blur)='activateGTIDValidation($event)'
                                        validateOnBlur>
                                    <span *ngIf="form?.get('xGTID').status!=='PENDING' && toggleValidationMessage && !GV.isValidatingGTID">
                                        <span class="icon-align transparent-background"
                                            *ngIf="(form?.get('xGTID').hasError('GTID_InUse')) && form?.get('xGTID').status==='INVALID'">
                                            <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" GTID already in use"
                                                placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                        </span>
                                        <span class="icon-align transparent-background"
                                            *ngIf="(form?.get('xGTID').hasError('GTID_InValid')) && form?.get('xGTID').status==='INVALID'">
                                            <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" Invalid GTID " placement="top"
                                                containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                        </span>
                                        <span class="icon-align transparent-background" *ngIf="form?.get('xGTID').status==='VALID'">
                                            <i class="fa fa-check" container="body" tooltip=" GTID Validated for use" placement="top"
                                                containerClass="tooltip-global" aria-hidden="true" style="color:green;"></i>
                                        </span>
                                    </span>
                                    <span class="icon-align transparent-background" *ngIf="GV.isValidatingGTID && !edit">
                                        <i class="fa fa-spinner fa-spin text-primary"></i>
                                    </span>
                                    <div class="input-group-append">
                                        <button tooltip="Copy To Clipboard" placement="top" container="body" containerClass="tooltip-global" type="button"
                                            class="btn btn-light always-active" (click)="copyToClipboard('gtid')">
                                            <i class="fa fa-copy"></i>
                                        </button>
                                        <button tooltip="Get new GTID" placement="top" container="body" containerClass="tooltip-global" type="button"
                                            class="btn btn-light" (click)="getNewGTID()" [disabled]="edit || GV.isValidatingGTID">
                                            <i class="fa fa-undo"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label for="mainLaneNum">Main Lane Number: </label>
                                <small *ngIf="!serverErrors['xMainLaneNum'] && form.get('xMainLaneNum').hasError('required') && form.get('xMainLaneNum').touched"
                                    class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Lane Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <small *ngIf="serverErrors['xMainLaneNum']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="{{formErrors.xMainLaneNum}}" placement="top"
                                        containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>

                                <input type="text" class="form-control form-control-sm" id="mainLaneNum" (focus)="clearErrors('xMainLaneNum')" formControlName="xMainLaneNum"
                                    [ngClass]="{'has-error': (form.get('xMainLaneNum').hasError('required') && form.get('xMainLaneNum').touched) || serverErrors['xMainLaneNum']}"
                                    (keypress)="onKeyPress($event)" (paste)="onPaste($event)" validateOnBlur>
                            </div>
                        </div>

                        <div class="form-group row no-margin">
                            <div class="col-md-12 col-sm-12">
                                <label for="token">Token: </label>
                                <small *ngIf="!serverErrors['xConnTok'] && form.get('xConnTok').hasError('required') && form.get('xConnTok').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Token is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <small *ngIf="serverErrors['xConnTok']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="{{formErrors.xConnTok}}" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <input type="text" class="form-control form-control-sm" id="token" (focus)="clearErrors('xConnTok')" formControlName="xConnTok"
                                    [ngClass]="{'has-error': (form.get('xConnTok').hasError('required') && form.get('xConnTok').touched) || serverErrors['xConnTok']}"
                                    validateOnBlur>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <label>Token</label>
                    </div>
                    <div class="card-body">
                        <div class="form-group no-margin">
                            <div class="input-group">
                                <input type="text" class="form-control" id="configData">
                                <span class="input-group-btn">
                                    <button class="btn btn-sm btn-primary pull-right" name="next" type="button" (click)="configModal.show()">
                                        Config Data
                                        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    </button>
                                </span>
                                <span class="input-group-btn" style="margin-left: 5px;">
                                    <button class="btn btn-sm btn-primary pull-right" name="next" type="button" (click)="show()">
                                        vTerm Link Snippet
                                        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <label>Notes</label>
                    </div>
                    <div class="form-group no-margin">
                        <small class="inline-error"> </small>
                        <small *ngIf="serverErrors['xNotes']" class="inline-error">
                            <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Notes is required" placement="top" containerClass="tooltip-custom inline-error"
                                aria-hidden="true"></i>
                        </small>
                        <textarea class="form-control notes-area" rows="4" id="notes" (focus)="clearErrors('xNotes')" formControlName="xNotes" style="min-height: 127px;"></textarea>
                    </div>
                </div>

            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">
                        <label>Entry Capabilities</label>
                    </div>
                    <div class="card-body" formGroupName='xEntrCaps'>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">Manual Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="man_entry" formControlName="xHasManEntr">
                                        <option value=null selected="!payDevInfo.xHasManEntr">Select Manual Entry</option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="msr_entry">MSR Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="msr_entry" formControlName="xHasMSREntr">
                                        <option value=null selected="!payDevInfo.xHasMSREntr">Select MSR Entry</option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">ICC Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="icc_entry" formControlName="xHasICCEntr">
                                        <option value=null selected="!payDevInfo.xHasICCEntr">Select ICC Entry</option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">Contactless Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="cles_entry" formControlName="xHasClessEntr">
                                        <option value=null selected="!payDevInfo.xHasClessEntr">Select Contactless Entry</option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">MICR Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="micr_entry" formControlName="xHasMICREntr">
                                        <option value=null selected="!payDevInfo.xHasMICREntr">Select MICR Entry</option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">PIN Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="pin_entry" formControlName="xHasPINEntr">
                                        <option value=null selected="!payDevInfo.xHasPINEntr">Select PIN Entry</option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">Signature Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="sig_entry" formControlName="xHasSigEntr">
                                        <option value=null selected="!payDevInfo.xHasSigEntr">Select Signature Entry</option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <label>Security Capabilities</label>
                    </div>
                    <div class="card-body" formGroupName='xSecCaps'>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">Certificate Cap: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="certificate_cap" formControlName="xCertTag">
                                        <option value="" selected="!payDevInfo.xCertTag">Select certificate type </option>
                                        <option *ngFor="let certTag of certCapTags" [value]="certTag.xTag">{{certTag.xName}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">Encryption Cap: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="encryption_cap" formControlName="xEncTag">
                                        <option value="" selected="!payDevInfo.xEncTag">Select Encryption type </option>
                                        <option *ngFor="let encTag of encCapTags" [value]="encTag.xTag">{{encTag.xName}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-12 col-sm-12">
                                <label for="uname">Certificate Number: </label>
                                <input type="text" class="form-control form-control-sm" id="certNum" (focus)="clearErrors('xCertNum')" formControlName="xCertNum">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <tabset>
                        <tab formGroupName='xHWSysInfo'>
                            <ng-template tabHeading>
                                HW System Info
                            </ng-template>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Make: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xMakeTag'] && form.get('xHWSysInfo.xMakeTag').hasError('required') && form.get('xHWSysInfo').get('xMakeTag').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Make is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xMakeTag']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Make is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="make" (focus)="clearErrors('xHWSysInfo.xMakeTag')" formControlName="xMakeTag"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xMakeTag').hasError('required') && form.get('xHWSysInfo').get('xMakeTag').touched) || serverErrors['xHWSysInfo.xMakeTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Model Number: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xModelNum'] && form.get('xHWSysInfo').get('xModelNum').hasError('required') && form.get('xHWSysInfo').get('xModelNum').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Model Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xModelNum']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Model Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="modnum" (focus)="clearErrors('xHWSysInfo.xModelNum')" formControlName="xModelNum"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xModelNum').hasError('required') && form.get('xHWSysInfo').get('xModelNum').touched) || serverErrors['xHWSysInfo.xModelNum']}"
                                        validateOnBlur>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Family: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xFamilyTag'] && form.get('xHWSysInfo').get('xFamilyTag').hasError('required') && form.get('xHWSysInfo').get('xFamilyTag').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Family is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xFamilyTag']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Family is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="family" (focus)="clearErrors('xHWSysInfo.xFamilyTag')" formControlName="xFamilyTag"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xFamilyTag').hasError('required') && form.get('xHWSysInfo').get('xFamilyTag').touched) || serverErrors['xHWSysInfo.xFamilyTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Serial Number: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xSerNum'] && form.get('xHWSysInfo').get('xSerNum').hasError('required') && form.get('xHWSysInfo').get('xSerNum').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Serial Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xSerNum']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Serial Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="serialnum" (focus)="clearErrors('xHWSysInfo.xSerNum')" formControlName="xSerNum"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xSerNum').hasError('required') && form.get('xHWSysInfo').get('xSerNum').touched) || serverErrors['xHWSysInfo.xSerNum']}"
                                        validateOnBlur>
                                </div>
                            </div>
                            <div class="form-group row no-margin">
                                <div class="col-md-6">
                                    <label>Model: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xModelTag'] && form.get('xHWSysInfo').get('xModelTag').hasError('required') && form.get('xHWSysInfo').get('xModelTag').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Model is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xModelTag']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Model is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="model" (focus)="clearErrors('xHWSysInfo.xModelTag')" formControlName="xModelTag"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xModelTag').hasError('required') && form.get('xHWSysInfo').get('xModelTag').touched) || serverErrors['xHWSysInfo.xModelTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Version: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xVersion'] && form.get('xHWSysInfo').get('xVersion').hasError('required') && form.get('xHWSysInfo').get('xVersion').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Version is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xVersion']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Version is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="version" (focus)="clearErrors('xHWSysInfo.xVersion')" formControlName="xVersion"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xVersion').hasError('required') && form.get('xHWSysInfo').get('xVersion').touched) || serverErrors['xHWSysInfo.xVersion']}"
                                        validateOnBlur>
                                </div>
                            </div>
                        </tab>
                        <tab formGroupName='xSWSysInfo'>
                            <ng-template tabHeading>
                                SW System Info
                            </ng-template>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Make: </label>
                                    <small *ngIf="!serverErrors['xSWSysInfo.xMakeTag'] && form.get('xSWSysInfo').get('xMakeTag').hasError('required') && form.get('xSWSysInfo').get('xMakeTag').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Make is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xSWSysInfo.xMakeTag']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Make is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="makeSW" (focus)="clearErrors('xSWSysInfo.xMakeTag')" formControlName="xMakeTag"
                                        [ngClass]="{'has-error': (form.get('xSWSysInfo').get('xMakeTag').hasError('required') && form.get('xSWSysInfo').get('xMakeTag').touched) || serverErrors['xSWSysInfo.xMakeTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Model Number: </label>
                                    <small *ngIf="!serverErrors['xSWSysInfo.xModelNum'] && form.get('xSWSysInfo').get('xModelNum').hasError('required') && form.get('xSWSysInfo').get('xModelNum').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Model Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xSWSysInfo.xModelNum']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Model Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="modelnumSW" (focus)="clearErrors('xSWSysInfo.xModelNum')" formControlName="xModelNum"
                                        [ngClass]="{'has-error': (form.get('xSWSysInfo').get('xModelNum').hasError('required') && form.get('xSWSysInfo').get('xModelNum').touched) || serverErrors['xSWSysInfo.xModelNum']}"
                                        validateOnBlur>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Family: </label>
                                    <small *ngIf="!serverErrors['xSWSysInfo.xFamilyTag'] && form.get('xSWSysInfo').get('xFamilyTag').hasError('required') && form.get('xSWSysInfo').get('xFamilyTag').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Family is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xSWSysInfo.xFamilyTag']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Family is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="familySW" (focus)="clearErrors('xSWSysInfo.xFamilyTag')" formControlName="xFamilyTag"
                                        [ngClass]="{'has-error': (form.get('xSWSysInfo').get('xFamilyTag').hasError('required') && form.get('xSWSysInfo').get('xFamilyTag').touched) || serverErrors['xSWSysInfo.xFamilyTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Serial Number: </label>
                                    <small *ngIf="!serverErrors['xSWSysInfo.xSerNum'] && form.get('xSWSysInfo').get('xSerNum').hasError('required') && form.get('xSWSysInfo').get('xSerNum').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Serial Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xSWSysInfo.xSerNum']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Serial Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="serialnumSW" (focus)="clearErrors('xSWSysInfo.xSerNum')" formControlName="xSerNum"
                                        [ngClass]="{'has-error': (form.get('xSWSysInfo').get('xSerNum').hasError('required') && form.get('xSWSysInfo').get('xSerNum').touched) || serverErrors['xSWSysInfo.xSerNum']}"
                                        validateOnBlur>
                                </div>
                            </div>
                            <div class="form-group row no-margin">
                                <div class="col-md-6">
                                    <label>Model: </label>
                                    <small *ngIf="!serverErrors['xSWSysInfo.xModelTag'] && form.get('xSWSysInfo').get('xModelTag').hasError('required') && form.get('xSWSysInfo').get('xModelTag').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Model is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xSWSysInfo.xModelTag']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Model is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="modelSW" (focus)="clearErrors('xSWSysInfo.xModelTag')" formControlName="xModelTag"
                                        [ngClass]="{'has-error': (form.get('xSWSysInfo').get('xModelTag').hasError('required') && form.get('xSWSysInfo').get('xModelTag').touched) || serverErrors['xSWSysInfo.xModelTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Version: </label>
                                    <small *ngIf="!serverErrors['xSWSysInfo.xVersion'] && form.get('xSWSysInfo').get('xVersion').hasError('required') && form.get('xSWSysInfo').get('xVersion').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Version is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xSWSysInfo.xVersion']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Version is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="versionSW" (focus)="clearErrors('xSWSysInfo.xVersion')" formControlName="xVersion"
                                        [ngClass]="{'has-error': (form.get('xSWSysInfo').get('xVersion').hasError('required') && form.get('xSWSysInfo').get('xVersion').touched) || serverErrors['xSWSysInfo.xVersion']}"
                                        validateOnBlur>
                                </div>
                            </div>
                        </tab>
                        <tab formGroupName='xCtrlSysInfo'>
                            <ng-template tabHeading>
                                Controller System Info
                            </ng-template>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Make: </label>
                                    <small *ngIf="!serverErrors['xCtrlSysInfo.xVersion'] && form.get('xCtrlSysInfo').get('xVersion').hasError('required') && form.get('xCtrlSysInfo').get('xVersion').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Version is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xCtrlSysInfo.xVersion']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Version is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="makeCon" (focus)="clearErrors('xCtrlSysInfo.xMakeTag')" formControlName="xMakeTag"
                                        [ngClass]="{'has-error': (form.get('xCtrlSysInfo').get('xMakeTag').hasError('required') && form.get('xCtrlSysInfo').get('xMakeTag').touched) || serverErrors['xCtrlSysInfo.xMakeTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Model Number: </label>
                                    <small *ngIf="!serverErrors['xCtrlSysInfo.xModelNum'] && form.get('xCtrlSysInfo').get('xModelNum').hasError('required') && form.get('xCtrlSysInfo').get('xModelNum').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Model Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xCtrlSysInfo.xModelNum']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Model Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="modelnumCon" (focus)="clearErrors('xCtrlSysInfo.xModelNum')"
                                        formControlName="xModelNum" [ngClass]="{'has-error': (form.get('xCtrlSysInfo').get('xModelNum').hasError('required') && form.get('xCtrlSysInfo').get('xModelNum').touched) || serverErrors['xCtrlSysInfo.xModelNum']}"
                                        validateOnBlur>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Family: </label>
                                    <small *ngIf="!serverErrors['xCtrlSysInfo.xFamilyTag'] && form.get('xCtrlSysInfo').get('xFamilyTag').hasError('required') && form.get('xCtrlSysInfo').get('xFamilyTag').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Family is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xCtrlSysInfo.xFamilyTag']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Family is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="familyCon" (focus)="clearErrors('xCtrlSysInfo.xFamilyTag')" formControlName="xFamilyTag"
                                        [ngClass]="{'has-error': (form.get('xCtrlSysInfo').get('xFamilyTag').hasError('required') && form.get('xCtrlSysInfo').get('xFamilyTag').touched) || serverErrors['xCtrlSysInfo.xFamilyTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Serial Number: </label>
                                    <small *ngIf="!serverErrors['xCtrlSysInfo.xSerNum'] && form.get('xCtrlSysInfo').get('xSerNum').hasError('required') && form.get('xCtrlSysInfo').get('xSerNum').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Serial Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xCtrlSysInfo.xSerNum']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Serial Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="serialnumCon" (focus)="clearErrors('xCtrlSysInfo.xSerNum')" formControlName="xSerNum"
                                        [ngClass]="{'has-error': (form.get('xCtrlSysInfo').get('xSerNum').hasError('required') && form.get('xCtrlSysInfo').get('xSerNum').touched) || serverErrors['xCtrlSysInfo.xSerNum']}"
                                        validateOnBlur>
                                </div>
                            </div>
                            <div class="form-group row no-margin">
                                <div class="col-md-6">
                                    <label>Model: </label>
                                    <small *ngIf="!serverErrors['xCtrlSysInfo.xModelTag'] && form.get('xCtrlSysInfo').get('xModelTag').hasError('required') && form.get('xCtrlSysInfo').get('xModelTag').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Model is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xCtrlSysInfo.xModelTag']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Model is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="modelCon" (focus)="clearErrors('xCtrlSysInfo.xModelTag')" formControlName="xModelTag"
                                        [ngClass]="{'has-error': (form.get('xCtrlSysInfo').get('xModelTag').hasError('required') && form.get('xCtrlSysInfo').get('xModelTag').touched) || serverErrors['xCtrlSysInfo.xModelTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Version: </label>
                                    <small *ngIf="!serverErrors['xCtrlSysInfo.xVersion'] && form.get('xCtrlSysInfo').get('xVersion').hasError('required') && form.get('xCtrlSysInfo').get('xVersion').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Version is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <small *ngIf="serverErrors['xCtrlSysInfo.xVersion']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Version is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="text" class="form-control form-control-sm" id="versionCon" (focus)="clearErrors('xCtrlSysInfo.xVersion')" formControlName="xVersion"
                                        [ngClass]="{'has-error': (form.get('xCtrlSysInfo').get('xVersion').hasError('required') && form.get('xCtrlSysInfo').get('xVersion').touched) || serverErrors['xCtrlSysInfo.xVersion']}"
                                        validateOnBlur>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>
        <!-- Lable config beginning -->
        <div class="card" formGroupName='xLabelConfigs'>
            <div class="card-header">
                <label>Label Configs</label>
            </div>
            <div class="card-body">
                <div class="form-group row no-margin">
                    <div class="col-sm-12 col-md-4">
                        <label for="xMemberNumberLabel">Member Number Label:</label>
                        <input type="text" class="form-control form-control-sm" id="xMemberNumberLabel" formControlName="xMemberNumberLabel"
                        validateOnBlur>                    
                    </div>

                    <div class="col-sm-12 col-md-4">
                        <label for="xInvoiceNumberLabel">Invoice Number Label:</label>
                        <input type="text" class="form-control form-control-sm" id="xInvoiceNumberLabel" formControlName="xInvoiceNumberLabel"
                        validateOnBlur>                    
                    </div>

                    <div class="col-sm-12 col-md-4">
                        <label for="xPONumberLabel">PO Number Label:</label>
                        <input type="text" class="form-control form-control-sm" id="xPONumberLabel" formControlName="xPONumberLabel"
                        validateOnBlur>                    
                    </div>
                </div>
            </div>
        </div>
        <!-- Label configs ending -->
        <div class="row m-t-5" *ngIf=true>
            <div class="col-sm-12 text-right">
                <!-- <a class="btn btn-secondary" [routerLink]="['/dashboard/setup/business/structure/information']" [routerLinkActive]="['router-link-active']"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a> -->
                <button class="btn btn-secondary" (click)="onCancel($event)">
                    <i class="fa fa-times" aria-hidden="true"></i> Cancel</button>
                <span 
                    class="tooltip-wrap-span" 
                    [tooltip]="(edit && !canEdit) || (!edit && !canAdd) ? noPermMsg : 'Save'" 
                    placement="left" 
                    container="body" 
                    containerClass="tooltip-global"
                >
                    <button 
                        class="btn btn-primary" 
                        name="next" 
                        type="submit" 
                        [disabled]="submitting || (edit && !canEdit) || (!edit && !canAdd)"
                    >
                        <span *ngIf="!submitting"> Save
                            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                        </span>
                        <span *ngIf="submitting">Please wait...
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </span>
                    </button>
                </span>
            </div>
        </div>
        </div>
        
    </form>
    <div class="row  m-t-5" *ngIf='parentMode && !userHandlerService?.userRoles?.isBusinessEmployee'>
        <div class="col-sm-12 text-right">
            <!-- <a class="btn btn-secondary" (click)="routeToPrevious()"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a> -->
            <!--<a class="btn btn-secondary" [routerLink]="['/dashboard/setup/business/information']" [routerLinkActive]="['router-link-active']"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a>-->
            <button 
                class="btn btn-primary" 
                (click)="onEditClick($event)"
                [disabled]="!canEdit"
            >
                <i class="fa fa-pencil" aria-hidden="true"></i> Edit</button>
        </div>
    </div>
</section>
<section *ngIf="!edit && loading">
    <div style="text-align: center; position:realtive">
        <div class="col-md-12">
            <!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="color:#445d75;position:relative; top:50px; text-align:Center"></i>
            <span class="sr-only">Loading Tags...</span> -->
            <div class="grid-spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <span style="color:#3b5877">Fetching required data</span>
        </div>
    </div>
</section>
<section *ngIf="edit && loading">
    <div style="text-align: center; position:realtive">
        <div class="col-md-12">
            <!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="color:#445d75;position:relative; top:50px; text-align:Center"></i>
            <span class="sr-only">Loading Tags...</span> -->
            <div class="grid-spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <span style="color:#3b5877">Fetching data</span>
        </div>
    </div>
</section>
<!-- Configuration Modal -->
<app-config-modal #configModal (onViewClose)="viewClosed($event)"></app-config-modal>
<div class="modal fade" bsModal #standAloneVTermLinkModal="bs-modal" [config]="{ backdrop: 'true' }" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">
                    Stand Alone VTerm Access Link Snippet
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideSnippetModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" class="vTermModalBody">
                <select [(ngModel)]="selectedTemplate" #templateDropdown="ngModel" *ngIf="!standAloneVTermSnippetError.length"
                    class="form-control form-control-sm m-b-5"
                    (change)="onSelectTemplate($event)"
                >
                    <option value= -1>
                        Select a Template
                    </option>
                    <option *ngFor="let template of TEMPLATE_ARR; index as ind" [value]="ind">
                        {{ TEMPLATE_ARR[ind].templateName }}
                    </option>
                </select>
                <div 
                    *ngIf="standAloneVTermSnippet || standAloneVTermSnippetError.length" 
                    class="config-data" 
                    style="overflow: scroll; max-height: 300px;"
                >
                    <p *ngIf="!standAloneVTermSnippetError.length" id="copy-target" (click)="snippetClick('copy-target')">
                        {{ standAloneVTermSnippet }}
                    </p>
                    <div *ngIf="!shouldGenerateSnippet">
                        <div *ngFor='let error of standAloneVTermSnippetError'>
                            {{ error }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <span>
                    <button type="button" class="btn btn-secondary" (click)="hideSnippetModal()">
                        <span>
                            <i class="fa fa-times" aria-hidden="true"></i>
                            Cancel</span>
                    </button>
                </span>
                <span *ngIf='standAloneVTermSnippet'>
                    <button type="button" class="btn btn-primary" (click)="selectHTMLSnippet('copy-target')" [disabled]="!shouldGenerateSnippet">
                        <span>
                            <i class="fa fa-copy" aria-hidden="true"></i>
                            CopySnippet</span>
                    </button>
                </span>
            </div>
        </div>
    </div>
</div>
<ng-template #registerTerminaltemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="operation === 'register'" >Register Terminal</h4>
        <h4 class="modal-title pull-left" *ngIf="operation === 'unregister'">Unregister Terminal</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="mb-3">
                <label for="xGMID">GMID: </label>
                <div class="input-group">
                    <input id="xGMID" class="form-control" value={{this.xActualGMID}} readonly #xGMID >
                </div>
            </div>
            <div class="mb-3" >
                <label for="xGTID">GTID: </label>
                <div class="input-group">
                    <input id="xGTID" class="form-control" placeholder="GTID" value={{this.xActualGTID}} readonly #xGTID>
                </div>
            </div>
            <div class="mb-3">
                <label for="xName">Name: </label>
                <div class="input-group">
                    <input id="xName" class="form-control" value="{{this.xActualName}}" readonly #xName>
                </div>
            </div>
            <div class="mb-3" *ngIf="operation === 'register'">
                <label for="xTerminalType">Terminal Type: </label>
                <div class="merch_type">
                    <select class="form-control form-control-sm" id="xTerminalType" #xTerminalType (change)="setSNLength($event)" style="height: calc(2.25rem + 2px);">
                        <option [value]="'select'"  > Select terminal type </option>
                        <option *ngFor="let terminalType of terminalTypeList" [value]="terminalType.value">{{terminalType.value}}</option>
                    </select>
                </div>
            </div>
            <div class="mb-3" *ngIf="operation === 'unregister' && this.xTerminalType !== undefined">
                <label for="xTerminalTypeU">Terminal Type: </label>
                    <div class="input-group">
                        <input id="xTerminalTypeU" class="form-control" value="{{this.xTerminalType}}" readonly #xTerminalTypeU>
                    </div>
            </div>
            <div class="mb-3" *ngIf="operation === 'register'" >
                <label for="xSerialNumberR">Serial Number</label>
                <div class="input-group " >
                    <input id="xSerialNumberR" class="form-control"  (change)="serialNumberInput($event) " [required]="!this.serialNumLength"
                           [maxLength]="this.serialNumLength"
                           [disabled]="!this.serialNumLength" >
                </div>
            </div>
            <div class="mb-3" *ngIf="operation === 'unregister'">
                <label for="xSerialNumberU">Serial Number</label>
                <div class="input-group">
                    <input id="xSerialNumberU" class="form-control" value="{{this.xActualSerialNumber}}" readonly>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary  pull-right" aria-label="Proceed"
                (click)="saveRegisterTerminal(xGMID.value, xGTID.value, this.xActualSerialNumber, this.xTerminalType)">
            <span aria-hidden="true" class="fa fa-angle-double-right">Proceed</span>
        </button>
    </div>
</ng-template>
