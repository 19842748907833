import { BrowserModule } from '@angular/platform-browser';
import { HttpClient,HttpClientModule } from '@angular/common/http'
import { JwtModule } from '@auth0/angular-jwt';
// import { AuthHttp, AuthConfig } from 'angular2-jwt';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule,APP_INITIALIZER } from "@angular/core";
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { SidebarModule } from 'ng-sidebar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './dashboard/dashboard.module';
// import { SetupModule } from './dashboard/setup/setup.module';
import { LoginModule } from "./login/login.module";
import { ApiConfig } from './shared/config/api.config';
import { VtermConfig } from './shared/config/vTerm.config';

import { BusinessTreeStore, SetupBusinessStructureStore, SetupBusinessInformationStore } from './store';
import {
	BusinessService,
	AuthenticationService,
	UserHandlerService,
	CompanyService,
	TreeService,
	StoreService,
	UrlStoreService,
	LoginStoreService
} from './shared/services';
import { LoginAsCustomerModule } from './login-as-customer/login-as-customer.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
export function initConfig(config:ApiConfig){
  return () => config.getConfig();
}
export function initVtermConfig(config:VtermConfig){
  return () => config.getConfig();
}

const STORAGE_ITEM_KEY = 'token';
const HEADER_NAME = 'Authorization';
const AUTH_SCHEME = 'Bearer ';
const WHITELISTED_DOMAINS = [
  'localhost:3030',
  'qalink.c9pg.com:3030',
  'qaportal.c9pg.com:3030',
  'portal.c9pg.com:3010',
  'testportal.c9pg.com:3010'
]

export function jwtTokenGetter(){
  return localStorage.getItem(STORAGE_ITEM_KEY) || sessionStorage.getItem(STORAGE_ITEM_KEY);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // FormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config:{
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: WHITELISTED_DOMAINS,
        headerName: HEADER_NAME,
        authScheme: AUTH_SCHEME,
        throwNoTokenError: false,
      }
    }),
    ToastrModule.forRoot({
      maxOpened: 2,
      autoDismiss: true,
      preventDuplicates: true
    }),
    SidebarModule.forRoot(),
    LoginModule,
    LoginAsCustomerModule,
    DashboardModule,
    AppRoutingModule,
    // NgxAsideModule,
    // SetupModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    // AuthHttp,
    // AuthConfig,
    ApiConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [ApiConfig],
      multi: true
    },
    VtermConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initVtermConfig,
      deps: [VtermConfig],
      multi: true
    },
    BusinessTreeStore,
    BusinessService,
    SetupBusinessStructureStore,
    SetupBusinessInformationStore,
    AuthenticationService,
    UserHandlerService,
    CompanyService,
    TreeService,
    StoreService,
    UrlStoreService,
    LoginStoreService,
    // {provide: ToastOptions, useClass: CustomToastOption},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
