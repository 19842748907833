<div class="card">
    <div class="card-header">
        <label>Upload Logo</label>
    </div>
    <div class="card-body">
        <div>
            <label for="fileUploader" class="no-margin btn btn-info btn-sm add-btn m-b-5">
                    <i class="fa fa-upload" aria-hidden="true"></i> Upload
            </label>
        </div>
        <input
            id="fileUploader"
            name="fileUpload"
            accept=".jpg, .jpeg, .png"
            multiple="false"
            type="file"
            (change)="fileUpload($event)"
            class="hide-no-dimensions"
        />
        <span #preview id="preview">
            <img *ngIf="image" src="{{ image }}" />
            <span *ngIf="!image">{{ NO_SELECTED_IMAGE_MSG }}</span>
        </span>
        <canvas #canvas class="hide-no-dimensions"></canvas>
    </div>
</div>
