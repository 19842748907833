import { Component, OnInit } from '@angular/core';
import {
	BreadcrumbService,
	FilterService,
	UserHandlerService,
	NotificationService,
	TreeService,
	BusinessService,
	TabStructureService
} from '../../../shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { SetupBusinessStructureStore } from '../../../store';
import { takeWhile, skip } from 'rxjs/operators';

@Component({
	selector: 'app-structure-layout',
	templateUrl: './structure-layout.component.html',
	styleUrls: ['./structure-layout.component.scss']
})
export class StructureLayoutComponent implements OnInit {
	public dt: Date = new Date();
	public minDate: Date = void 0;
	public treeAddAction: boolean;
	public treeButtonLinks: any;
	public treeNodeLinks: any;
	public JSON = JSON;
	public isLoading = false;
	public showTree: boolean = false;
	public isBusinessSelected: boolean = false;
	public hideTabRouterOutlet: boolean = false;
	alive: boolean = true;
	public enableTree: boolean = true;
	showGrid: boolean;
	labelNames = {
		biz: 'Business',
		chain: 'Chain',
		region: 'Region',
		prop: 'Property',
		store: 'Store',
		pCtr: 'Profit Center',
		merAcct: 'Merchant Account',
		terminal: 'Terminal',
		periph: 'Peripheral'
	};

	tabItems: { label: string; link: any[] }[] = [
		{
			label: 'Business',
			link: ['/dashboard/setup/business/structure/information']
		},
		{
			label: 'Chain',
			link: ['/dashboard/setup/business/structure/chain']
		},
		{
			label: 'Region',
			link: ['/dashboard/setup/business/structure/region']
		},
		{
			label: 'Property',
			link: ['/dashboard/setup/business/structure/property']
		},
		{
			label: 'Store',
			link: ['/dashboard/setup/business/structure/store']
		},
		{
			label: 'Profit Center',
			link: ['/dashboard/setup/business/structure/profit-center']
		},
		{
			label: 'Merchant Account',
			link: ['/dashboard/setup/business/structure/merchant-account']
		},
		{
			label: 'Terminal',
			link: ['/dashboard/setup/business/structure/terminal']
		},
		{
			label: 'Peripheral',
			link: ['/dashboard/setup/business/structure/peripheral-list']
		}
	];

	constructor(
		private router: Router,
		private breadcrumbService: BreadcrumbService,
		private filterService: FilterService,
		public userHandlerService: UserHandlerService,
		private treeService: TreeService,
		private businessService: BusinessService,
		private setupBusinessStructureStore: SetupBusinessStructureStore,
		private tabStructureService: TabStructureService
	) {
		this.treeAddAction = false;
		this.treeButtonLinks = {};
		this.treeNodeLinks = {};
	}

	ngOnInit(): void {
		console.log('hide tab: onInit: structurelayout', this.hideTabRouterOutlet);
		this.tabStructureService.watchHideTabStructure$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe(hidetab => {
				console.log('hidetab:tabStructureService :structure-layout--->', hidetab);
				//For member-accounts disable tab and tree
				this.hideTabRouterOutlet = hidetab;
				this.enableTree = !hidetab;
				this.showTree = hidetab;
			});

		this.tabStructureService.defaultTabStructureLabel = {};
		this.tabStructureService.previousTabStructureLabel = {};
		this.tabStructureService.storeDefTabStruct = true;

		if (this.businessService.businessID.getValue()) {
			this.isBusinessSelected = true;
		}

		this.tabStructureService.watchTabStructureLabels$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe(labels => {
				console.log('watcher k andr', labels);
				if (this.businessService.businessID.getValue()) this.tabItems = JSON.parse(JSON.stringify(labels)).map(label => label);
				else this.setTabNameToInitial();
			});

		/*this.showGrid = */ this.checkRepToggleLinks();
		this.filterService.setFilterValue(true);
		this.setBreadCrumbValue();
		this.setTreeAttributes();
		this.treeService.watchShowTree$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((show: boolean) => {
				this.showTree = !show;
			});
	}

	setTabNameToInitial() {
		this.tabItems = [
			{
				label: 'Business',
				link: ['/dashboard/setup/business/structure/information']
			},
			{
				label: 'Chain',
				link: ['/dashboard/setup/business/structure/chain']
			},
			{
				label: 'Region',
				link: ['/dashboard/setup/business/structure/region']
			},
			{
				label: 'Property',
				link: ['/dashboard/setup/business/structure/property']
			},
			{
				label: 'Store',
				link: ['/dashboard/setup/business/structure/store']
			},
			{
				label: 'Profit Center',
				link: ['/dashboard/setup/business/structure/profit-center']
			},
			{
				label: 'Merchant Account',
				link: ['/dashboard/setup/business/structure/merchant-account']
			},
			{
				label: 'Terminal',
				link: ['/dashboard/setup/business/structure/terminal']
			},
			{
				label: 'Peripheral',
				link: ['/dashboard/setup/business/structure/peripheral-list']
			}
		];
	}

	checkRepToggleLinks() /*: boolean*/ {
		if (this.userHandlerService.userRoles.isDealer) {
			this.businessService.watchBusinessID$
				.pipe(
					takeWhile(() => this.alive),
					skip(1)
				)
				.subscribe(bizId => {
					if (bizId) {
						this.isBusinessSelected = true;
						console.log('Yahan se change ho gya hai kya??');
						this.tabItems[0].link = ['/dashboard/setup/business/structure/information', bizId];
						this.router.navigate(this.tabItems[0].link);
					}
				});
			// return true;
		} else if (this.userHandlerService.userRoles.isBusinessEmployee) {
			if (this.businessService.businessID.getValue()) {
				this.isBusinessSelected = true;
				this.tabItems[0].link = ['/dashboard/setup/business/structure/information', this.businessService.businessID.getValue()];
			}
		}
	}

	setTreeAttributes(): void {
		this.treeButtonLinks = {};
		this.treeAddAction = true;
		this.treeNodeLinks.chain = 'chain';
		this.treeNodeLinks.property = 'property';
		this.treeNodeLinks.store = 'store';
		this.treeNodeLinks.profitCenter = 'profitCenter';
	}
	setBreadCrumbValue(): void {
		const breadCrumb: any = {};
		breadCrumb.root = 'Home';
		breadCrumb.child = '';
		breadCrumb.subChild = '';
		breadCrumb.subChildLink = null;
		breadCrumb.grandChild = null;
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	onTabBtnClick() {
		console.log('Here I come');

		this.tabStructureService.tabMode = true;
	}

	onNodeClick(nodeDetails: any) {
		const nodeID = nodeDetails.nodeId;
		const nodeType = nodeDetails.nodeType;
		let link: any[] = [];
		switch (nodeType) {
			case 'business':
				link = ['/dashboard/setup/business/structure/information', nodeID];
				break;
			case 'chain':
				link = ['/dashboard/setup/business/structure/chain', nodeID];
				break;
			case 'region':
				link = ['/dashboard/setup/business/structure/region', nodeID];
				break;
			case 'property':
				link = ['/dashboard/setup/business/structure/property', nodeID];
				break;
			case 'store':
				this.treeService.setId(nodeID, 'store');
				link = ['/dashboard/setup/business/structure/store', nodeID];
				break;
			case 'merchant_account':
				link = ['/dashboard/setup/business/structure/merchant-account', nodeID];
				break;
			case 'terminal':
				link = ['/dashboard/setup/business/structure/terminal', nodeID];
				break;
			case 'profit_center':
				link = ['/dashboard/setup/business/structure/profit-center', nodeID];
				break;
			case 'peripheral':
				link = ['/dashboard/setup/business/structure/peripheral-list', nodeID];
				break;
			default:
				break;
		}
		this.router.navigate(link); //Route to setup page
	}

	onButtonClick(nodeDetails: any): void {
		console.log('button clicked inside s-b-str===>', nodeDetails);
		const addNodePath = nodeDetails.nodePath;
		const addNodeType = nodeDetails.nodeType;
		this.setupBusinessStructureStore.setNodePath(addNodePath);
		let link: any[] = [];
		switch (addNodeType) {
			case 'chain':
				link = ['/dashboard/setup/business/structure/chain/add-edit'];
				break;
			case 'region':
				link = ['/dashboard/setup/business/structure/region/add-edit'];
				break;
			case 'property':
				link = ['/dashboard/setup/business/structure/property/add-edit'];
				break;
			case 'store':
				link = ['/dashboard/setup/business/structure/store/add-edit'];
				break;
			case 'merchant_account':
				link = ['/dashboard/setup/business/structure/merchant-account/add-edit'];
				break;
			case 'terminal':
				link = ['/dashboard/setup/business/structure/terminal/add-edit'];
				break;
			case 'profit_center':
				link = ['/dashboard/setup/business/structure/profit-center/add-edit'];
				break;
			case 'peripheral':
				link = ['/dashboard/setup/business/structure/peripheral-list/add-edit'];
				break;
			default:
				break;
		}
		this.router.navigate(link); //Route to setup page
	}

	ngOnDestroy() {
		this.alive = false;
	}
}
