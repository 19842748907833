import { Injectable } from "@angular/core";
import { HttpService } from "../shared/services/index";

const endPoint = "csv_import/csv_files_history";
const LIMIT = 20;
@Injectable()
export class CsvFileHistoryStore {
    constructor(protected httpService: HttpService) {}

    getAll(params: any): any {
        params.limit = LIMIT;
        console.log("params in csv history store", params);
        return this.httpService
            .getAll(endPoint, params);
    }
}
