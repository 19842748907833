
export class DBAudit {

    xDataSrc: string;
    // xFacebook: string;
    // xTwitter: string;
    // xLinkedIn: string;

    constructor(obj?: any) {
        // this.xPhone = (obj && obj.xPhone) || '';
        this.xDataSrc = (obj && obj.xDataSrc) || '';
        // this.xFacebook = (obj && obj.xFacebook) || '';
        // this.xTwitter = (obj && obj.xTwitter) || '';
        // this.xLinkedIn = (obj && obj.xLinkedIn) || '';
    }
}
