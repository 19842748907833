<!-- --------------------------------------- confirm payment ------------------------------------------------------>
<div>
    <div class="card card-default">
        <div class="card-body vterm-info">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>LOT Number: </label>
                        <p>{{ paymentData?.lotNumber }}</p>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Amount: </label>
                        <p>{{ paymentData?.paymentAmount }}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Card Type: </label>
                        <p>{{ paymentMethod?.brand }} {{ paymentMethod?.medium }}</p>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Card Number: </label>
                        <p>{{ paymentMethod?.xMaskedAcctNum }}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Scheduled Date: </label>
                        <p>{{ paymentData?.scheduledDateString }}</p>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Notes: </label>
                        <p>{{ paymentData?.notes }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pull-right">
        <button type="button" class="btn btn-secondary" (click)="routeToPaymentForm()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="proceedToPay()">Proceed</button>
    </div>
</div>
<!-- --------------------------------------- confirm payment ------------------------------------------------------>
