<section *ngIf="!loading" class="setup setup-customer">
    <form class="form-horizontal" [ngClass]="{'disable-form':(parentMode || userHandlerService?.userRoles?.isBusinessEmployee)}"
        [formGroup]="form" (ngSubmit)="onSubmit(form)">
        <div class="row top-stat">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-check-circle-o label-icon label-head-icon" aria-hidden="true"></i>
                        <label class="p-l-5" for="name">Status:</label>
                        <label class="detail-label"> Active</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-sliders label-icon" aria-hidden="true"></i>
                        <label for="name" class="p-l-5">Type:</label>
                        <label class="detail-label"> Profit Center</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-map-marker label-icon" aria-hidden="true"></i>
                        <label for="store_name" class="p-l-5">Store:</label>
                        <label class="detail-label"> {{storeName}}</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-level-up label-icon fa-flip-horizontal" aria-hidden="true"></i>
                        <label for="parent_name" class="p-l-5">Store:</label>
                        <label class="detail-label"> {{storeName}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div>

        <div class="row">
            <div class="col-sm-6">
                <div class="card">
                    <div class="card-header">
                        <label class="">Basic Information
                        </label>
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-6">
                                <label for="name">Name:
                                    <small class="text-danger">*</small>
                                </label>
                                <small *ngIf="!serverErrors['xName'] && form.get('xName').hasError('required') && form.get('xName').touched"
                                    class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                                <small *ngIf="!serverErrors['xName'] && form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched"
                                    class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is of incorrect format. Correct Format-: 1) Name can not be more than 20 letters. 2) The first character of Name should not be an empty space. 3) The first character of Name should not be any special characters, E.g. /,& etc."
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                                <small *ngIf="serverErrors['xName']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>

                                <input type="text" class="form-control form-control-sm" id="name" formControlName="xName"
                                    [ngClass]="{'has-error': (form.get('xName').hasError('required') && form.get('xName').touched) || (form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched) || serverErrors['xName']}"
                                    (focus)="clearErrors('xName')" validateOnBlur>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <label for="service_charge">ServiceChargeAmount: </label>
                                
                                <div class="input-group-append merch-info input-group">
                                    <input type="text" readonly="true" class="form-control form-control-sm" id="service_charge" value="3%">
                                    <input id="service_charge_check" placement="top" container="body" containerClass="tooltip-global"
                                    type="checkbox" class="form-control form-control-sm" formControlName="xEnableServiceChargeAmount">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-6">
                                <label for="tag">Tag: </label>
                                <input type="text" class="form-control form-control-sm" id="tag" formControlName="xTntTag">
                            </div>
                        <!-- </div>
                        <div class="form-group row "> -->
                            <div class="col-md-6 col-sm-6">
                                <label for="ref_num">Ref Number:
                                    <!-- <small class="text-danger">*</small> -->
                                </label>
                                <input type="text" class="form-control form-control-sm" id="ref_num" formControlName="xRefNum" [value]="gmID">
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-6 col-sm-6">
                                <label for="pctr_num">Profit Center Number:
                                    <small class="text-danger">*</small>
                                </label>
                                <!-- <small *ngIf="!serverErrors['xPCtrNum'] && form.get('xPCtrNum').hasError('required') && form.get('xPCtrNum').touched" class="inline-error">

                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" Profit Center Number is required" placement="top"
                                        containerClass="tooltip-custom inline-error" aria-hidden="true"></i>

                                </small>
                                <small *ngIf="serverErrors['xPCtrNum']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" Profit Center Number is required" placement="top"
                                        containerClass="tooltip-custom inline-error" aria-hidden="true"></i>

                                </small>

                                <input type="text" class="form-control form-control-sm" id="pctr_num" formControlName="xPCtrNum" [ngClass]="{'has-error': (form.get('xPCtrNum').hasError('required') && form.get('xPCtrNum').touched) || serverErrors['xPCtrNum']}"
                                    (focus)="clearErrors('xPCtrNum')" validateOnBlur> -->
                                <app-model-number [modelNumber]=profitCenterModel.xPCtrNum [form]=form [serverErrors]=serverErrors
                                    [numberKey]=numberKey></app-model-number>
                            </div>
                        <!-- </div>
                        <div class="form-group row no-margin"> -->
                            <div class="col-md-6 col-sm-6">
                                <label for="short_code">Short Code:
                                    <small class="text-danger">*</small>
                                </label>
                                <small *ngIf="!serverErrors['xShortCode'] && form.get('xShortCode').hasError('required') && form.get('xShortCode').touched"
                                    class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Unique Short Code is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                                <small *ngIf="serverErrors['xShortCode']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Unique Short Code is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>

                                <input type="text" class="form-control form-control-sm" id="short_code" formControlName="xShortCode"
                                    [ngClass]="{'has-error': (form.get('xShortCode').hasError('required') && form.get('xShortCode').touched) || serverErrors['xShortCode']}"
                                    (focus)="clearErrors('xShortCode')" validateOnBlur>
                            </div>
                        </div>   
                    </div>
                </div>
                <div class='col-sm-12'>
                    <div class="card">
                        <div class="card-header">
                            <label>Customer Link Generation</label>
                        </div>
                        <div class="card-body">        
                            <div *ngIf='edit' class="input-group">
                                <div *ngIf='!customerLinkGenerationLoader && customerLink' class='config-data single-line-no-wrap full-parent-width font-size-12-px' id='copy-target' (click)="selectHTMLSnippet('copy-target')" tooltip="Click the text to copy link." placement="top" container="body" containerClass="tooltip-global">
                                    {{ customerLink }}
                                </div>
                                <div *ngIf='customerLinkGenerationLoader' class='config-data single-line-no-wrap full-parent-width font-size-12-px' (click)="selectHTMLSnippet('copy-target')" tooltip="Loading..." placement="top" container="body" containerClass="tooltip-global">
                                    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                </div>
                                <div *ngIf='!customerLinkGenerationLoader && !customerLink' class="config-data single-line-no-wrap full-parent-width font-size-12-px danger-text">
                                    {{ customerLinkErrorMessage }}
                                </div>
                                <button *ngIf='customerLink' tooltip="Copy link" placement="top" container="body" containerClass="tooltip-global" type='button' class='btn btn-light btn-margin' (click)="selectHTMLSnippet('copy-target')" [disabled]="customerLinkGenerationLoader">
                                    <i class="fa fa-copy" aria-hidden="true"></i>
                                </button>
                                <button tooltip="Re-generate link" placement="top" container="body" containerClass="tooltip-global" type='button' class='btn btn-light' (click)="generateCustomerLink(profitCenterModel._id)" [disabled]="customerLinkGenerationLoader">
                                    <i class="fa fa-undo" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div *ngIf='!edit' class='input-group'>
                                <div class='single-line-no-wrap full-parent-width text-align-center'>
                                    <label>
                                        {{ CREATE_PCTR_BEFORE_LINK_CAN_BE_GENERATED_MSG }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                    <div class="card-header">
                        <label class="">Payment Processing
                        </label>
                    </div>
                    <div class="card-body">
                        <div class="form-group row ">
                            <div class="col-md-3 col-sm-12">
                                <label for="processor">Industry: <small class="text-danger">*</small></label>
                                <small *ngIf="!serverErrors['xIndstTag'] && form.get('xIndstTag').hasError('required') && form.get('xIndstTag').touched"
                                    class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" Industry is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                                <small *ngIf="serverErrors['xIndstTag']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" Industry is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                            </div>
                            <div class="col-md-9 col-sm-12 merch-info">
                                <div class="merch_type" [ngClass]="{'has-error': (form.get('xIndstTag').hasError('required') && form.get('xIndstTag').touched) || serverErrors['xIndstTag']}">
                                    <select class="form-control form-control-sm" formControlName="xIndstTag">
                                        <option value="" selected="!profitCenterModel.xIndstTag">Select Industry
                                        </option>
                                        <option *ngFor="let industry of industryList" [value]="industry.xTag">{{industry.xName}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-3 col-sm-12">
                                <label for="processor">Attendance: <small class="text-danger">*</small></label>
                                <small *ngIf="!serverErrors['xAttnTag'] && form.get('xAttnTag').hasError('required') && form.get('xAttnTag').touched"
                                    class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" Attendance is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                                <small *ngIf="serverErrors['xAttnTag']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" Attendance is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                            </div>
                            <div class="col-md-9 col-sm-12 merch-info">
                                <div class="merch_type" [ngClass]="{'has-error': (form.get('xAttnTag').hasError('required') && form.get('xAttnTag').touched) || serverErrors['xAttnTag']}">
                                    <select class="form-control form-control-sm" formControlName="xAttnTag">
                                        <option value="" selected="!profitCenterModel.xAttnTag">Select Attendance
                                        </option>
                                        <option *ngFor="let attendance of attendanceList" [value]="attendance.xTag">{{attendance.xName}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-3 col-sm-12">
                                <label for="processor">Presence: <small class="text-danger">*</small></label>
                                <small *ngIf="!serverErrors['xPresTag'] && form.get('xPresTag').hasError('required') && form.get('xPresTag').touched"
                                    class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" Presence is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                                <small *ngIf="serverErrors['xPresTag']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" Presence is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                            </div>
                            <div class="col-md-9 col-sm-12 merch-info">
                                <div class="merch_type" [ngClass]="{'has-error': (form.get('xPresTag').hasError('required') && form.get('xPresTag').touched) || serverErrors['xPresTag']}">
                                    <select class="form-control form-control-sm" formControlName="xPresTag">
                                        <option value="" selected="!profitCenterModel.xPresTag">Select Presence</option>
                                        <option *ngFor="let presence of presenceList" [value]="presence.xTag">{{presence.xName}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-3 col-sm-12">
                                <label for="gmid">GMID: <small class="text-danger">*</small></label>
                                <small *ngIf="!serverErrors['xGMID'] && form.get('xGMID').hasError('required') && form.get('xGMID').touched"
                                    class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" GMID is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                                <small *ngIf="serverErrors['xGMID']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" GMID is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                            </div>
                            <div class="col-md-9 col-sm-12 merch-info input-group">
                                <input type="text" class="form-control form-control-sm"

                                [ngClass]="

                                {'has-error': (form.get('xGMID').hasError('required') && form.get('xGMID').touched) || serverErrors['xGMID']
                                || (form?.get('xGMID').hasError('GMID_InUse') && form?.get('xGMID').touched) 
                                || (form?.get('xGMID').hasError('GMID_InValid') && form?.get('xGMID').touched), disabled: GV.isValidatingGMID}
                                "
                                    id="gmid" formControlName="xGMID" [(ngModel)]="gmID"
                                    (focus)="clearErrors('xGMID')" (blur)="activateGMIDValidation($event)" (keypress) = "gmidTypeValidation($event)" (paste)="gmidTypeValidation($event)" validateOnBlur>
                                    <span *ngIf="form?.get('xGMID').status!=='PENDING' && toggleValidationMessage && !GV.isValidatingGMID">
                                        <span class="icon-align" *ngIf="(form?.get('xGMID').hasError('GMID_InUse')) && form?.get('xGMID').status==='INVALID'">
                                            <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" GMID already in use" placement="top" containerClass="tooltip-custom inline-error"
                                                aria-hidden="true"></i>
                                        </span>
                                        <span class="icon-align" *ngIf="(form?.get('xGMID').hasError('GMID_InValid')) && form?.get('xGMID').status==='INVALID'">
                                            <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" Invalid GMID " placement="top" containerClass="tooltip-custom inline-error"
                                                aria-hidden="true"></i>
                                        </span>
                                        <span class="icon-align" *ngIf="form?.get('xGMID').status==='VALID'">
                                            <i class="fa fa-check" container="body" tooltip=" GMID Validated for use" placement="top" containerClass="tooltip-global"
                                                aria-hidden="true" style="color:green;"></i>
                                        </span>
                                    </span>
                                    <span class="icon-align" *ngIf="GV.isValidatingGMID && !edit">
                                        <i class="fa fa-spinner fa-spin text-primary"></i>
                                    </span>

                                    <div class="input-group-append">
                                        <button tooltip="Copy To Clipboard" placement="top" container="body" containerClass="tooltip-global" type="button"
                                            class="btn btn-light always-active" (click)="copyToClipboard('gmid')">
                                            <i class="fa fa-copy"></i>
                                        </button>
                                        <button tooltip="Get new GMID" placement="top" container="body" containerClass="tooltip-global" type="button"
                                            class="btn btn-light" (click)="getNewGMID()" [disabled]="edit || GV.isValidatingGMID"><i
                                                class="fa fa-undo"></i></button>
                                    </div>
                            </div>

                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-3 col-sm-12">
                                <label for="gmpw">GMPW: <small class="text-danger">*</small></label>
                                <small *ngIf="!serverErrors['xGMPW'] && form.get('xGMPW').hasError('required') && form.get('xGMPW').touched"
                                    class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" GMPW is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                                <small *ngIf="serverErrors['xGMPW']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip=" GMPW is required"
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                            </div>
                            <div class="col-md-9 col-sm-12 merch-info input-group">
                                <input type="text" class="form-control form-control-sm" id="gmpw" [ngClass]="{'has-error': (form.get('xGMPW').hasError('required') && form.get('xGMPW').touched) || serverErrors['xGMPW']}"
                                    formControlName="xGMPW">

                                    <div class="input-group-append">
                                        <button tooltip="Copy To Clipboard" placement="top" container="body" containerClass="tooltip-global"
                                                type="button" class="btn btn-light always-active" (click)="copyToClipboard('gmpw')">
                                            <i class="fa fa-copy"></i>
                                        </button>
                                        <button tooltip="Get new GMPW" placement="top" container="body" containerClass="tooltip-global"
                                                type="button" class="btn btn-light" (click)="getNewGMPW()">
                                            <i class="fa fa-undo"></i>
                                        </button>
                                    </div>    
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <label>Notes</label>
                    </div>
                    <div class="form-group  no-margin">
                        <div class="">
                            <textarea class="form-control notes-area" rows="2" id="notes" formControlName="xNotes" style="height:54px"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row m-t-5 row100" *ngIf='!(parentMode || userHandlerService?.userRoles?.isBusinessEmployee)'>
            <div class="col-sm-12 text-right">
                <!-- <a class="btn btn-secondary m-r-10" [routerLink]="['/dashboard/setup/business/structure/information']" [routerLinkActive]="['router-link-active']"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a> -->
                <button class="btn btn-secondary" (click)="onCancel($event)">
                    <i class="fa fa-times" aria-hidden="true"></i> Cancel</button>
                <span 
                    class="tooltip-wrap-span" 
                    [tooltip]="(edit && !canEdit) || (!edit && !canAdd) ? noPermMsg : 'Save'" 
                    placement="left" 
                    container="body" 
                    containerClass="tooltip-global"
                >
                    <button class="btn btn-primary" name="next" type="submit" [disabled]="submitting || (edit && !canEdit) || (!edit && !canAdd)">
                        <span *ngIf="!submitting"> Save
                            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                        </span>
                        <span *ngIf="submitting">Please wait...
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </span>
                    </button>
                </span>
            </div>
        </div>
        </div>
        
    </form>
    <div class="row  m-t-5" *ngIf='parentMode && !userHandlerService?.userRoles?.isBusinessEmployee'>
        <div class="col-sm-12 text-right">
            <!-- <a class="btn btn-secondary" (click)="routeToPrevious()"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a> -->
            <!--<a class="btn btn-secondary" [routerLink]="['/dashboard/setup/business/information']" [routerLinkActive]="['router-link-active']"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a>-->
            <button 
                class="btn btn-primary" 
                (click)="onEditClick($event)"
                [disabled]="!canEdit"
            >
                <i class="fa fa-pencil" aria-hidden="true"></i>
                Edit
            </button>
        </div>
    </div>
</section>
<section *ngIf="!edit && loading">
    <div style="text-align: center; position:realtive">
        <div class="col-md-12">
            <!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="color:#445d75;position:relative; top:50px; text-align:Center"></i>
            <span class="sr-only">Loading Tags...</span> -->
            <div class="grid-spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <span style="color:#3b5877">Fetching required data</span>
        </div>
    </div>
</section>
<section *ngIf="edit && loading">
    <div style="text-align: center; position:realtive">
        <div class="col-md-12">
            <!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="color:#445d75;position:relative; top:50px; text-align:Center"></i>
            <span class="sr-only">Loading Tags...</span> -->
            <div class="grid-spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <span style="color:#3b5877">Fetching data</span>
        </div>
    </div>
</section>

<section *ngIf="!edit && submitting">
    <div class="overlay">
        <div class="overlay-content">
            <div>
                <i style="color:white;" class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
            </div>
            <div>
                <span style="font-size:20px">Please wait. Creating profit center and migrating corresponding batches.</span>
            </div>
        </div>
    </div>
</section>