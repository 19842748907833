import { Injectable } from '@angular/core';
 
import { HttpService } from '../shared/services/index';

const CREATE_END_POINT: string = 'xpcSafes';
const UPDATE_END_POINT: string = 'pre_safes';
const DELETE_END_POINT: string = 'deleteSafes';
const GET_END_POINT: string = CREATE_END_POINT;

@Injectable()
export class MemberAccountStore {
	constructor(protected httpService: HttpService) {}

	getAll(params: any): any {
		return this.httpService.getAll(GET_END_POINT, params);
	}

	store(element: any): any {
		return this.httpService.store(CREATE_END_POINT, element);
	}

	update(elementID: string, newElement: any): any {
		return this.httpService.update(UPDATE_END_POINT, elementID, newElement);
	}

	get(elementID: any): any {
		return this.httpService.get(GET_END_POINT, elementID);
	}

	destroy(data: any): any {
		return this.httpService.store(DELETE_END_POINT, data);
	}
}
