<div
	bsModal
	#tokenResponseModal="bs-modal"
	class="modal fade"
	tabindex="-1"
	role="dialog"
	aria-labelledby="myLargeModalLabel"
	aria-hidden="true"
	[config]="{ backdrop: 'static' }"
>
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="closeModal()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">{{modalTitle}} Response</h4>
			</div>
			<div *ngIf="showPaymentResponse" class="modal-body pb-0">
				<!-- success message pay-now beginning-->
				<div class="successAlertDiv" *ngIf="paymentResponse.Status === SUCCESS">
					<div class="alert alert-success">
						<h4 class="modal-title">
							Transaction Successful
						</h4>
						<div>
							<h6 class="modal-title">Transaction Info:</h6>
							{{ paymentResponse?.ResponseText }}
						</div>
						<div>
							<h6 class="modal-title">
								Status:
							</h6>
							{{ paymentResponse?.ResultText }}
						</div>
					</div>
				</div>
				<!-- success message pay-now ending-->
				<!-- failure message pay-now beginning-->
				<div class="failedAlertDiv" *ngIf="paymentResponse.Status !== SUCCESS">
					<div class="alert alert-danger">
						<div>
							<h4 class="modal-title">Transaction Failed</h4>
						</div>
						<div>
							<h6 class="modal-title">Transaction Info:</h6>
							{{ paymentResponse?.ErrorText }}
						</div>
						<div>
							<h6 class="modal-title">
								Status:
							</h6>
							{{ paymentResponse?.ResultText }}
						</div>
					</div>
				</div>
				<!-- failure message pay-now ending-->
				<!-- success message payment-method beginnig-->
				<div class="successAlertDiv" *ngIf="createPaymentResponse.paymentMethodSuccessMsg">
					<div class="alert alert-success">
						<h6 class="modal-title">
							{{ createPaymentResponse.paymentMethodSuccessMsg }}
						</h6>
					</div>
				</div>
				<!-- success message payment-method ending-->
				<!-- failure message payment-method beginning-->
				<div
					class="failedAlertDiv"
					*ngIf="createPaymentResponse.paymentMethodFailureMsg || paymentResponse.paymentMethodFailureMsg"
				>
					<div class="alert alert-danger">
						<h6 class="modal-title">Payment Method failed to register. {{ createPaymentResponse.paymentMethodFailureMsg }}</h6>
					</div>
				</div>
				<!-- failure message payment-method  end-->
				<div *ngIf="paymentResponse.showPaymentInfo" class="card card-default">
					<div class="card-body vterm-info">
						<div class="row">
							<div class="col-sm-4">
								<div class="form-group">
									<label>Account Number: </label>
									<div *ngIf="paymentResponse.AccountNum">{{ paymentResponse?.AccountNum }}</div>
									<div *ngIf="!paymentResponse.AccountNum">-</div>
								</div>
							</div>
							<div class="col-sm-4">
								<div class="form-group">
									<label>Transaction Type: </label>
									<div *ngIf="paymentResponse.TransType">
										{{ transactionType[(paymentResponse?.TransType)] }}
									</div>
									<div *ngIf="!paymentResponse.TransType">-</div>
								</div>
							</div>
							<div class="col-sm-4">
								<div class="form-group">
									<label>Auth Amt: </label>
									<div *ngIf="paymentResponse.AuthAmt">{{ paymentResponse?.AuthAmt }}</div>
									<div *ngIf="!paymentResponse.AuthAmt">-</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-4">
								<div class="form-group no-margin">
									<label>Auth Code: </label>
									<div *ngIf="paymentResponse.AuthCode">{{ paymentResponse?.AuthCode }}</div>
									<div *ngIf="!paymentResponse.AuthCode">-</div>
								</div>
							</div>
							<div class="col-sm-4">
								<div class="form-group">
									<label>Brand: </label>
									<div *ngIf="paymentResponse.Brand">{{ paymentResponse?.Brand }}</div>
									<div *ngIf="!paymentResponse.Brand">-</div>
								</div>
							</div>
							<div class="col-sm-4">
								<div class="form-group no-margin">
									<label>Medium: </label>
									<div *ngIf="paymentResponse.Medium">{{ paymentResponse?.Medium }}</div>
									<div *ngIf="!paymentResponse.Medium">-</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<div class="form-group">
									<label>GTRC: </label>
									<div *ngIf="paymentResponse.GTRC">{{ paymentResponse?.GTRC }}</div>
									<div *ngIf="!paymentResponse.GTRC">-</div>
								</div>
							</div>
							<div *ngIf="paymentResponse.CardToken" class="col-sm-6">
								<div class="form-group">
									<label>Card Token: </label>
									<div>{{ paymentResponse?.maskedCardToken }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- payment response ending -->
			<!--- payment method creation response beginning -->
			<div *ngIf="showCreatePaymentResponse"  class="modal-body pb-0">
				<div *ngIf="!createPaymentResponse?.paymentMethodFailureMsg" class="successAlertDiv">
					<div class="alert alert-success">
						<h6 class="modal-title">
							Payment Method has been registered successfully.
						</h6>
					</div>
				</div>
				<div *ngIf="createPaymentResponse?.paymentMethodFailureMsg" class="failedAlertDiv">
					<div class="alert alert-danger">
						<h6 class="modal-title">Payment Method failed to register. {{ createPaymentResponse?.paymentMethodFailureMsg }}</h6>
					</div>
				</div>
				<div class="card card-default">
					<div class="card-body vterm-info">
						<div class="row">
							<div class="col-sm-6">
								<div class="form-group">
									<label class="no-margin">Customer Name:</label>
									<div>{{ createPaymentResponse?.customerName }}</div>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<label class="no-margin">Card-Token:</label>
									<div>{{ createPaymentResponse?.maskedCardToken }}</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<div class="form-group">
									<label class="no-margin">Medium:</label>
									<div>{{ createPaymentResponse?.medium }}</div>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<label class="no-margin">Brand:</label>
									<div>{{ createPaymentResponse?.brand }}</div>
								</div>
							</div>
						</div>
						<div class="row" [class.no-margin]="createPaymentResponse.medium === 'Credit'">
							<div class="col-sm-6">
								<div class="form-group no-margin">
									<label class="no-margin">Account Number:</label>
									<div>{{ createPaymentResponse?.accountNum }}</div>
								</div>
							</div>
							<div *ngIf="createPaymentResponse.medium === 'ElectronicCheck'" class="col-sm-6">
								<div class="form-group no-margin">
									<label class="no-margin">Routing Number:</label>
									<div>{{ createPaymentResponse?.routingNum }}</div>
								</div>
							</div>
							<div *ngIf="createPaymentResponse.medium === 'Credit'" class="col-sm-6">
								<div class="form-group no-margin">
									<label class="no-margin">Expiry Date:</label>
									<div>{{ createPaymentResponse?.expMonth }} / {{ createPaymentResponse?.expYear }}</div>
								</div>
							</div>
						</div>
						<div *ngIf="createPaymentResponse.medium === 'ElectronicCheck'" class="row no-margin">
							<div class="col-sm-6">
								<div class="form-group no-margin">
									<label class="no-margin">Account Type:</label>
									<div>{{ createPaymentResponse?.accountType }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--- payment method creation response ending -->
			<div class="modal-footer">
				<button class="btn btn-secondary" type="button" (click)="closeModal()">Close</button>
			</div>
		</div>
	</div>
</div>
