<div bsModal #addTipsModal="bs-modal" *ngIf="showPaginationModal" [config]="{ show: true, ignoreBackdropClick: true}" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" (click)="onPaginationModalClose(true)" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
              <h4 class="modal-title">Select transaction pages</h4>
          </div>
          <div>
              <table class="table proc-table">
                  <tbody>
                      <ng-container *ngFor="let page of totalPages;">
                          <tr>
                              <td width="50%">
                                  Page {{page}}
                                  <!-- <span *ngIf=checkDone(page) style='color: green;'>
                                    <i class="fa fa-clock-o" aria-hidden="true"></i> Pending
                                  </span> -->
                                  <!-- <span *ngIf=checkDone(page) class="btn btn-warning">
                                    Pending
                                  </span> -->
                              </td>
                              <!--<td>
                                              {{configParam.xParamKey}}
                                          </td>-->
                              <td width="50%">
                                  <button (click)="onPerformActionClick(page, actionType)" [disabled]="performing"> 
                                  <!-- <button [disabled]=checkDone(page) (click)="onPerformActionClick(page, actionType)">  -->
                                    <span *ngIf="performingActionOnPage != page">
                                        <!-- <span *ngIf=checkDone(page) style='color: green;'>
                                            Perform Action Again
                                        </span> -->
                                        <!-- <span *ngIf=!checkDone(page)> -->
                                        <span>
                                            Perform Action
                                        </span>
                                    </span>
                                    <span *ngIf="performingActionOnPage === page">
                                        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                        Performing Action...
                                    </span>
                                  </button>
                                  <!-- <input type='text' [value]="procParams.value[i].xParamVal" (input)="procParams.value[i].xParamVal=$event.target.value"> -->
                              </td>
                          </tr>
                      </ng-container>
                  </tbody>
              </table>
          </div>
          <div class="modal-footer">
            <button *ngIf="totalRecords < 8000"  type="button" class="btn btn-primary" (click)="printExportReport()" aria-label="Print" [disabled]="printLoader">
                <span *ngIf="!printLoader">
                    <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                    Export All
                </span> 
                <span *ngIf="printLoader">
                    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    Printing...
                </span>
                <!-- <i class="fa fa-print" aria-hidden="true"></i> Print -->
            </button>
              <a class="btn btn-sm btn-secondary" (click)="onPaginationModalClose(true)">
                  <i class="fa fa-times" aria-hidden="true"></i> Cancel</a>
              <!-- <button class="btn btn-sm btn-primary" (click)="onPaginationModalClose('finalize')">Continue
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
              </button> -->
          </div>
      </div>
  </div>
</div>