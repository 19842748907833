import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

import {
  BreadcrumbService,
  NotificationService,
  FilterService,
  BusinessService,
  TreeService
} from "../../../shared/services";
import {
  SetupBusinessStructureStore,
  SetupBusinessInformationStore
} from "../../../store/index";
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: "app-setup-business-structure",
  templateUrl: "./setup-business-structure.component.html",
  styleUrls: ["./setup-business-structure.component.scss"]
})
export class SetupBusinessStructureComponent implements OnInit, OnDestroy {
  treeAddAction: boolean = true;
  treeButtonLinks: any = {};
  treeNodeLinks: any = {};
  helpImage: string = "";
  setupBusinessStructureModel: any = {};
  data: any[] = [];
  metaData: any = {};
  businessInfo: any = {};
  alive: boolean = true;
  public showTree: boolean; // To make tree visible.

  tabItems: { label: string; link: string }[] = [
    {
      label: "Business",
      link: "/dashboard/setup/business/structure/information"
    },
    { label: "Chain", link: "/dashboard/setup/business/structure/chain" },
    { label: "Region", link: "/dashboard/setup/business/structure/region" },
    { label: "Property", link: "/dashboard/setup/business/structure/property" },
    { label: "Store", link: "/dashboard/setup/business/structure/store" },
    {
      label: "Profit Center",
      link: "/dashboard/setup/business/structure/profit-center"
    },
    {
      label: "Merchant Account",
      link: "/dashboard/setup/business/structure/merchant-account"
    },
    {
      label: "Terminals",
      link: "/dashboard/setup/business/structure/terminal"
    },
    {
      label: "Peripherals",
      link: "/dashboard/setup/business/structure/peripheral-list"
    }
  ];

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private notificationService: NotificationService,
    private businessService: BusinessService,
    private filterService: FilterService,
    private setupBusinessStructureStore: SetupBusinessStructureStore,
    private setupBusinessInformationStore: SetupBusinessInformationStore,
    private treeService: TreeService
  ) {}

  ngOnInit() {
    this.setBreadCrumbValue();
    this.setTreeAttributes();

    this.filterService.setFilterValue(false);
    this.helpImage = "assets/images/helpImage.png";
    // const businessId = localStorage.getItem('businessId');
    const businessId =
      localStorage.getItem("businessId") ||
      sessionStorage.getItem("businessId");

    this.businessService.watchBusinessID$
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe((bizId: string) => {
        console.log(bizId, "BUSINESS ID CHANGED!!");
        let link: any[] = [];
        link = ["/dashboard/setup/business/structure/information"];
        this.router.navigate(link); //Route to structure-info page as business is changed
      });
    this.treeService.watchShowTree$
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe((show: boolean) => {
        this.showTree = show;
      });
  }

  onButtonClick(nodeDetails: any): void {
    console.log("button clicked inside s-b-str 1===>", nodeDetails);
    const addNodePath = nodeDetails.nodePath;
    const addNodeType = nodeDetails.nodeType;
    console.log("inside button click", addNodeType);
    this.setupBusinessStructureStore.setNodePath(addNodePath);
    let link: any[] = [];
    switch (addNodeType) {
      case "chain":
        link = ["/dashboard/setup/business/structure/chain"];
        break;
      case "region":
        link = ["/dashboard/setup/business/structure/region/add-edit"];
        break;
      case "property":
        link = ["/dashboard/setup/business/structure/property"];
        break;
      case "store":
        link = ["/dashboard/setup/business/structure/store"];
        break;
      case "merchant_account":
        link = ["/dashboard/setup/business/structure/merchant-account"];
        break;
      case "terminal":
        link = ["/dashboard/setup/business/structure/terminal"];
        break;
      case "profit_center":
        link = ["/dashboard/setup/business/structure/profit-center"];
        break;
      case "peripheral":
        link = ["/dashboard/setup/business/structure/peripheral-list"];
        break;
      default:
        break;
    }
    console.log("Inside region", link);
    this.router.navigate(link); //Route to setup page
  }

  onNodeClick(nodeDetails: any) {
    const nodeID = nodeDetails.nodeId;
    const nodeType = nodeDetails.nodeType;
    let link: any[] = [];
    switch (nodeType) {
      case "chain":
        link = ["/dashboard/setup/business/structure/chain", nodeID];
        break;
      case "region":
        link = ["/dashboard/setup/business/structure/region", nodeID];
        break;
      case "property":
        link = ["/dashboard/setup/business/structure/property", nodeID];
        break;
      case "store":
        this.treeService.setId(nodeID, "store");
        link = ["/dashboard/setup/business/structure/store", nodeID];
        break;
      case "merchant_account":
        link = ["/dashboard/setup/business/structure/merchant-account", nodeID];
        break;
      case "terminal":
        link = ["/dashboard/setup/business/structure/terminal", nodeID];
        break;
      case "profit_center":
        link = ["/dashboard/setup/business/structure/profit-center", nodeID];
        break;
      case "peripheral":
        link = ["/dashboard/setup/business/structure/peripheral-list", nodeID];
        break;
      default:
        break;
    }
    this.router.navigate(link); //Route to setup page
  }

  // toggleVisible(val : boolean): void {
  // 	this.visible = val;
  // 	console.log('set-bus-str toggle function');
  // }

  setTreeAttributes(): void {
    this.treeButtonLinks.chain = "/dashboard/setup/business/structure/chain/";
    this.treeButtonLinks.region = "/dashboard/setup/business/structure/region/";
    this.treeButtonLinks.property =
      "/dashboard/setup/business/structure/property/";
    this.treeButtonLinks.store = "/dashboard/setup/business/structure/store/";
    this.treeButtonLinks.profitCenter =
      "/dashboard/setup/structure/profit-center/";

    this.treeNodeLinks.chain = "chain";
    this.treeNodeLinks.region = "region";
    this.treeNodeLinks.property = "property";
    this.treeNodeLinks.store = "store";
    this.treeNodeLinks.profitCenter = "profitCenter";
  }

  setBreadCrumbValue(): void {
    const breadCrumb: any = {};
    breadCrumb.root = "Setup";
    breadCrumb.child = "Business";
    breadCrumb.subChild = "Structure";
    breadCrumb.subChildLink = null;
    breadCrumb.grandChild = null;
    this.breadcrumbService.setBreadCrumb(breadCrumb);
  }

  // onSubmit(): void {
  // 	console.log('...............Business Structure Form Submitted............................');
  // }

  // restore(): void {
  // 	console.log('...............Business Structure Form Restore function called!!............');
  // }

  // nextPage() {
  // 	let obv1 = this.setupBusinessStructureStoreSetupService.getAll();
  // 	let obv2 = this.setupBusinessStructurePropertySetupService.getAll();
  // 	Observable.forkJoin( [obv1, obv2])
  // 	.subscribe(
  // 		(response: any)  => {
  // 			console.log('I have this--->', response);
  // 			if(!response[0].data.length) {
  // 				this.notificationService.warning('INVALID ENTRY!', 'Add store to the business before proceeding');
  // 			}
  // 			if(!response[1].data.length) {
  // 				this.notificationService.warning('INVALID ENTRY!', 'Add Merchant Account to the business before proceeding');
  // 			}
  // 			if(response[0].data.length && response[1].data.length) {
  // 				let link = ['/dashboard/setup-business-payment-process/'];
  // 				this.router.navigate(link);//Route to setup-business-payment-process page
  // 			}

  // 		},
  // 		(error: any) => {
  // 			console.log('Inside nextPage, subscribe Failure func', error);
  // 			this.notificationService.error('ERROR', 'Oops Something went wrong!');
  // 		},
  // 		() => {
  // 			console.log('subscription complete func');
  // 		}
  // 		);
  // }

  routeToPrevious(): void {
    const link = ["/dashboard/setup/business/structure/information"];
    this.router.navigate(link);
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
