export class SecCaps {
    xCertTag: string;
    xEncTag: string;
    xCertNum: string;

    constructor(obj?: any) {
        this.xCertTag = (obj && obj.xCertTag) || '';
        this.xEncTag = (obj && obj.xEncTag) || '';
        this.xCertNum = (obj && obj.xCertNum) || '';
    }
}