export class VTermInitForm{
    xTerminalName: string;
    webAppInfo:any
    xGTID: string;
    xPCtrName: string;
    xGMID: string;


    constructor(obj?:any){   

        this.xTerminalName=(obj && obj.xTerminalName) || '';
        this.webAppInfo=(obj && obj.webAppInfo) || {};
        this.xGTID=(obj && obj.xGTID) || '';
        this.xPCtrName=(obj && obj.xPCtrName) || '';
        this.xGMID=(obj && obj.xGMID) || '';
    }
}