import { Component, OnInit, Input, OnChanges, SimpleChanges/*, ChangeDetectorRef*/ } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import {JsonPipe} from '@angular/common';

import { BaseComponent } from '../../base.component';
import { MainContact, FormalName } from '../../../../../../models';
import { GlobalValidator } from '../../../../../services';
import { phoneMask } from '../../../../utils';

@Component({
    selector: 'app-main-contact-row',
    templateUrl: './main-contact-row.component.html',
    styleUrls: ['./main-contact-row.component.scss']
})
export class MainContactRowComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() addressContactValidate:boolean=true;
    @Input() mainContact: MainContact;
    // @Input() formalName:FormalName;
    @Input() mainContactForm: FormGroup;
    // @Input() mainContactForm: FormGroup;
    @Input() serverErrors: any = {};
    @Input() tagType:string;
    mask = phoneMask;

    constructor(public fb: FormBuilder/*, private cd: ChangeDetectorRef*/) {
        super();
        this.formErrors = {
            xMainContact: {
                xFirstName: '',
                xLastName: '',
                xEmail: '',
                xPhone: ''
            }
        };
    }

    ngOnChanges(change: SimpleChanges) {
        // if (change.hasOwnProperty('mainContact') && !change.mainContact.firstChange) {
        //     if (!this.mainContactForm.controls['xMainContact']) {
        //         this.mainContactForm.addControl('xMainContact', this.toFormGroup(new MainContact(this.mainContact)));
        //     }
        //     this.mainContactForm.controls['xMainContact'].patchValue(new MainContact(this.mainContact));
        //     if (this.tagType === 'biz.comp') {
        //         this.contact.controls['xFirstName'].disable();
        //         this.contact.controls['xLastName'].disable();
        //         this.contact.controls['xEmail'].disable();
        //         this.contact.controls['xPhone'].disable();

        //     }
        // }

        // if (change.hasOwnProperty('serverErrors') && !change.serverErrors.firstChange) {
        //     this.validateServerErrors();
        // }
        console.log("Inside changes");

        if (change.hasOwnProperty('mainContact') && !change.mainContact.firstChange) {
            console.log("Inside main contact");
            if (!this.mainContactForm.controls['xMainContact']) {
                this.mainContactForm.addControl('xMainContact', this.toFormGroup(new MainContact(this.mainContact)));
            }
            this.mainContactForm.controls['xMainContact'].patchValue(new MainContact(this.mainContact));
        }

        if (change.hasOwnProperty('serverErrors') && !change.serverErrors.firstChange) {
            console.log("Inside SERVER ERROR");
            this.validateServerErrors();
        }
    }

    ngOnInit() {
        // console.log("Ye form group a rha", JSON.parse(JSON.stringify(this.mainContactForm)));
        this.mainContactForm.addControl('xMainContact', this.toFormGroup(new MainContact(this.mainContact)));
        // this.contact = this.toFormGroup(new MainContact(this.mainContact));
        // this.contact.valueChanges
        //     .subscribe(data => this.onValueChanged(this.mainContactForm, data));

        this.onValueChanged(this.mainContactForm); // (re)set validation messages now
    }

    set contact(value: FormGroup) {
        this.mainContactForm.controls['xMainContact'] = value;
    }

    get contact() {
        return this.mainContactForm.get('xMainContact') as FormGroup;
    }

    public toFormGroup(data: MainContact): FormGroup {
        const formGroup = this.fb.group({
            // xFirstName: [data.xFirstName || '', Validators.required],
            // xLastName: [data.xLastName || '', Validators.required],
            xEmail: [data.xEmail || ''],
            xEmailTypeTag: [data.xEmailTypeTag || '',[]],
            xPhone: [data.xPhone || ''],
            xPhoneTypeTag: [data.xPhoneTypeTag || '', []],

        });
        if(this.addressContactValidate){
            formGroup.controls['xEmail'].setValidators([Validators.compose([GlobalValidator.mailFormat, Validators.required])]);
            formGroup.controls['xPhone'].setValidators([Validators.required]);
        }
        return formGroup;
    }


    onKeypress(event: any) {
        GlobalValidator.phoneNumberFormat(event);
    }

    onPaste(event: any) {
        GlobalValidator.phoneNumberFormat(event);
    }

}


    // clearErrors(errorName: string): void {
    //     console.log("errorName",errorName,this.serverErrors,this.formErrors,this.serverErrors['xMainContact.'+errorName]);
    //     if (this.serverErrors['xMainContact.'+errorName]) {
    //         console.log("errorName2",errorName);
    //         delete this.serverErrors['xMainContact.'+errorName];
    //         console.log('this.serverErrors',this.serverErrors);
    //     }
    // }

