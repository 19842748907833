import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { takeWhile, skip, filter } from 'rxjs/operators';
import { SetupBusinessInformationStore, SetupCompanyStore } from '../../../store/index';
import {
	NotificationService,
	BusinessService,
	UserHandlerService,
	AuthenticationService,
	CompanyService,
	TreeService,
	StoreService,
	UrlStoreService,
	TabStructureService,
	LoginStoreService,
	HttpService,
	GenerateCustomerLinkService,
} from '../../../shared/services';
import { customSwal } from '../../../constants';
import { validateFor } from '../../../common-functions';
import { CompanySuspendedService } from 'app/pages/company-suspended/company-suspended.service';
const COULD_NOT_SET_BUSINESS_DATA_MSG = 'Could not set business data. Routing to home page.';
const strLength = 50;
const HOME_LINK = '/dashboard/home';

const ERROR_VALUE = 'Error';

const TERMINAL_NUMBER_ENCRYPTION_FAILED_MSG = 'Failed to encrypt Terminal Number. Please try again.';
const TERMINAL_NUMBER_MALFORMED_MSG = 'Terminal Number has been malformed. Routing to Dealer Login page.';
const FAILED_TO_SET_COMPANY_ID = 'Failed to set companyID. Please try again or contact support.';

@Component({
	selector: 'top-nav',
	templateUrl: './topnav.component.html',
	styleUrls: ['./topnav.component.scss'],
})
export class TopnavComponent implements OnInit, OnDestroy {
	isIn = false; // store state
	isopen = false;
	isopenbill = false;
	isReportsOpen: boolean = false;

	breadCrumbValue: any = {};
	list: any[] = [];

	//Entity Here Represents Business/Company
	entityList: any[] = []; //Stores list of business
	selectedEntityId: string = null; //Stores selected business-Id/company-ID
	entityDetails: any = {}; //Stores entity details
	previousSelectedEntityId: string = null;
	public selectedEntity = [];
	previousSelectedEntityName = '';
	entityListLoading: boolean = true;

	alive: boolean = true;
	currentUrl: string = '';

	//Authorization variables
	isDealerRep: boolean = false;
	isVendorRep: boolean = false;
	isBusinessEmp: boolean = false;
	isCustomer: boolean = false;

	//setRole
	role: string = '';

	//user type can either be representative or employee
	userTypeDetails: any = {};
	user: any = {};
	bizId: string;
	currBizID: string = null;

	public businessName: string = 'Select Business';
	public companyName: string = 'Select Company';
	public dropDownEntityList: Array<any> = [];

	enableTree: boolean = true;
	showTree: boolean = false;
	enableCompany: boolean = false;
	companySuspended: boolean = false;
	// show: boolean = false;

	customerLinkErrorMessage: string = ''; // customer link generation fail message

	public showOverLay: boolean = false; //show overlay if logging out

	constructor(
		// private http: Http,
		private setupBusinessInformationStore: SetupBusinessInformationStore,
		private setupCompanyStore: SetupCompanyStore,
		private notificationService: NotificationService,
		private businessService: BusinessService,
		private userHandlerService: UserHandlerService,
		private authenticationService: AuthenticationService,
		private companyService: CompanyService,
		// private breadcrumbService: BreadcrumbService,
		private router: Router,
		private treeService: TreeService,
		// private httpService: HttpService,
		private storeService: StoreService,
		private urlStoreService: UrlStoreService,
		// private timerService: TimerService,
		private tabStructureService: TabStructureService,
		// private constantService: ConstantService,
		private loginStoreService: LoginStoreService,
		private httpService: HttpService,
		private generateCustomerLinkService: GenerateCustomerLinkService,
		private _companySuspend: CompanySuspendedService
	) {
		this.user = this.userHandlerService.userData;
		console.log('user type details', this.userHandlerService.userType);
		this.userTypeDetails = this.userHandlerService.userType;
		this.entityDetails = this.userHandlerService.organisation;
	}

	toggleState() {
		// click handler
		const bool = this.isIn;
		this.isIn = bool === false ? true : false;
	}

	async ngOnInit() {
		this.httpService.watchAuthFail$.subscribe((authFail) => {
			if (authFail) this.logout();
		});
		if (this.userHandlerService.userRoles.isCustomer) {
			this.userHandlerService.userRoles.isBusinessEmployee = false;
			this.userHandlerService.userRoles.isDealer = false;
			this.userHandlerService.userRoles.isVendor = false;
		}
		console.log('roles', this.userHandlerService.userRoles);
		this.setAuthorizations();
		this.setPermissions();

		if (this.isVendorRep) {
			this.entityListLoading = true;
			this.fetchEntityList(this.setupCompanyStore);
		}

		this._companySuspend.isSuspended$.subscribe((isSuspended) => {
			this.companySuspended = isSuspended;
		});

		this.treeService.watchEnableTree$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((enableVal: boolean) => {
				console.log('enable tree called');
				this.enableTree = enableVal;
				this.showTree = !enableVal;
			});

		//Set company id to which the dealer belongs to
		console.log('this.user', this.user);
		if (this.user.xEntyTypeTag.toLowerCase() === 'rep' && this.isDealerRep) {
			this.companyService.setCompanyID(this.userTypeDetails.xCompanyID);
			this.entityListLoading = true;
			this.fetchEntityList(this.setupBusinessInformationStore);
		}

		if (this.isBusinessEmp) {
			this.businessService.setBusinessID(this.userTypeDetails.xBusinessID);
			this.treeService.setId(this.userTypeDetails.xBusinessID, 'business');
			try {
				await this.companyService.setCompanyIDForEmpLogin();
			} catch (error) {
				console.log('error: top nav emp login', error);
				const message = validateFor('message', error) ? error.message : FAILED_TO_SET_COMPANY_ID;
				this.notificationService.error(message, 'Error');
			}
			this.userHandlerService.fetchEmployeeDetails(this.userHandlerService.userData.xEntyID).subscribe((res: any) => {
				console.log('RESponse in top', JSON.parse(JSON.stringify(res)));
				// this.storeService.setMainStoreID(res.data.xMainStoreID);
				res.data.xAccStores.map((storeDetail) => {
					this.storeService.pushToAccessStoreIDs(storeDetail.xStoreID, storeDetail.xStatus);
				});
				// if(!res.data.xAccStores.indexOf(res.data.xMainStoreID)){
				// 	this.storeService.pushToAccessStoreIDs(res.data.xMainStoreID);
				// }
				console.log('User details inside topnav', this.storeService.getAccessStoreIDs());
			});

			this.bizId = this.businessService ? this.businessService.businessID.getValue() : '';
		}

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeWhile(() => this.alive)
			)
			.subscribe((val: any) => {
				console.log('val event--->', val);
				this.currentUrl = val.url;
				console.log('Current url inside topnav', this.currentUrl);
				this.urlStoreService.swapUrl(val.url);
				// console.log(this.currentUrl.slice(this.currentUrl.lastIndexOf('/') + 1, this.currentUrl.length))
				const currentRoute = this.currentUrl.split('dashboard/setup/licensing/info/current');
				console.log('currentRoute ===> ', currentRoute);
				if (currentRoute.length === 2 && currentRoute[1] !== '') {
					this.enableCompany = true;
				} else {
					this.enableCompany = false;
				}
			});

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationStart),
				takeWhile(() => this.alive)
			)
			.subscribe((val: any) => {
				console.log('navigation start called');
				console.log('Val event-->', val);
				console.log('current url', this.urlStoreService.currentUrl);
				if (
					this.urlStoreService.currentUrl.includes('/add-edit/') ||
					val.url.includes('/dashboard/home') ||
					val.url.includes('/dashboard/outstanding-transactions') ||
					val.url.includes('/dashboard/outstanding-batches') ||
					val.url.includes('/dashboard/settled-transactions') ||
					val.url.includes('/dashboard/settled-batches') ||
					(val.url.includes('/dashboard/transactions') &&
						!this.urlStoreService.currentUrl.includes('/dashboard/outstanding-transactions') &&
						!this.urlStoreService.currentUrl.includes('/dashboard/transactions'))
				) {
					console.log('on navigation start employee add/edit was prev page fix tree properties here');
					this.treeService.setEnable(true);
					this.treeService.setShow(false);
				}
				if (!val.url.includes('structure')) {
					// console.log("HERE----->");
					this.tabStructureService.defaultTabStructureLabel = {};
					this.tabStructureService.previousTabStructureLabel = {};
					this.tabStructureService.storeDefTabStruct = true;
				}
				//BG polling code start
				// if(this.timerService.mainTimer){
				// 	this.timerService.stopMainTimer();
				// }
				// if(this.timerService.miniTimer){
				// 	this.timerService.stopMiniTimer();
				// }
				//BG polling code end
			});

		//Watch for changes if any business is added or removed
		this.businessService.watchRefreshBusinessList$.pipe(takeWhile(() => this.alive)).subscribe((refreshBusinessList: boolean) => {
			if (refreshBusinessList) {
				this.entityListLoading = true;
				this.fetchEntityList(this.setupBusinessInformationStore);
			}
		});

		//Watch for changes if any company is added or removed
		this.companyService.watchRefreshCompanyList$.pipe(takeWhile(() => this.alive)).subscribe((refreshCompanyList: boolean) => {
			if (refreshCompanyList) {
				this.entityListLoading = true;
				this.fetchEntityList(this.setupCompanyStore);
			}
		});

		this.businessService.watchBusinessID$.pipe(takeWhile(() => this.alive)).subscribe((bid: string) => {
			console.log('Is this being changed', bid);
			this.currBizID = bid;
			this.selectedEntityId = bid;
			this.previousSelectedEntityId = this.selectedEntityId;
			if (bid) {
				// this.fetchEntityList(this.setupBusinessInformationStore); //Future implementation
				this.tabStructureService.selectedEntityDetail = this.entityList.filter((entityDetail) => this.selectedEntityId === entityDetail._id)[0];
				for (let index = 0; index < this.dropDownEntityList.length; index++) {
					if (this.dropDownEntityList[index].id === this.selectedEntityId) {
						this.selectedEntity = [
							{
								id: this.dropDownEntityList[index].id,
								text: this.dropDownEntityList[index].text,
							},
						];
						this.previousSelectedEntityName = this.dropDownEntityList[index].text;
						break;
					}
				}
				console.log('previous id:', this.previousSelectedEntityId);
				console.log('previous name:', this.previousSelectedEntityName);
			}
		});

		this.companyService.watchCompanyID$.pipe(takeWhile(() => this.alive)).subscribe((compid: string) => {
			if (this.isVendorRep) {
				this.selectedEntityId = compid;
				console.log('watchcompanyID: compID', compid);
				this.previousSelectedEntityId = this.selectedEntityId;
				if (compid) {
					for (let index = 0; index < this.dropDownEntityList.length; index++) {
						if (this.dropDownEntityList[index].id === this.selectedEntityId) {
							this.selectedEntity = [
								{
									id: this.dropDownEntityList[index].id,
									text: this.dropDownEntityList[index].text,
								},
							];
							this.previousSelectedEntityName = this.dropDownEntityList[index].text;
							break;
						}
					}
					console.log('previous id:', this.previousSelectedEntityId);
					console.log('previous name:', this.previousSelectedEntityName);
				}
			}
		});
		if (this.isCustomer) {
			console.log('setting customer business and store', JSON.parse(JSON.stringify(this.loginStoreService.storeData)));
			if (
				Object.keys(this.loginStoreService.storeData).length &&
				this.loginStoreService.storeData.hasOwnProperty('xBusinessID') &&
				this.loginStoreService.storeData['xBusinessID']
			) {
				this.businessService.setBusinessID(this.loginStoreService.storeData['xBusinessID']);
				this.bizId = this.loginStoreService.storeData['xBusinessID'];
				console.log('business data', this.businessService.businessData);
			}
			// this.treeService.setId("5ad44722be7f6a259872fad1", "business");
			// this.companyService.setCompanyID("5aaf53d21f0b1a13ed50314b");
		}
	}

	setAuthorizations() {
		const roles = this.userHandlerService.userRoles;
		this.isVendorRep = roles.isVendor;
		this.isDealerRep = roles.isDealer;
		this.isBusinessEmp = roles.isBusinessEmployee;
		this.isCustomer = roles.isCustomer;
		this.setRole();
	}

	setPermissions() {
		// console.log("User Data: ", this.userHandlerService.userData);
		// console.log("User Permissions: ", this.userHandlerService.userData.permissions);
		this.userHandlerService.setUserPermissions(this.userHandlerService.userData ? this.userHandlerService.userData.permissions : {});
	}

	setRole() {
		if (this.isVendorRep) {
			this.role = 'Vendor';
		}
		if (this.isDealerRep) {
			this.role = 'Dealer';
		}
		if (this.isBusinessEmp) {
			this.role = 'Business Employee';
		}
		if (this.isCustomer) {
			this.role = 'Customer';
		}
	}

	/**
	 * @brief      Fetches company/business list.
	 *
	 * @param      store     Can be either company/business store
	 */
	fetchEntityList(store: SetupBusinessInformationStore | SetupCompanyStore) {
		let parm = { noGrid: true, sortType: 1, sortKey: 'xName', fetchAll: true, selectKey: 'xName' };
		if (this.isVendorRep && "" !== this.userHandlerService.userData.xTag) {
			parm['xVendorTag']=this.userHandlerService.userData.xTag;
		}
		store.getAll(parm).subscribe(
			(response: any) => {
				console.log('Entity list before', JSON.parse(JSON.stringify(response.data)));
				this.entityListLoading = false;
				// this.entityList = this.constantService.sortCompleteData(response.data, store, 'asc', 'xName', false);
				console.log('dropDownEntityList before', this.dropDownEntityList);
				this.dropDownEntityList = [];
				this.entityList = response.data;
				this.entityList.map((entity) => {
					this.dropDownEntityList.push({
						id: entity._id,
						text: this.truncateString(entity.xName),
					});
				});
				// this.businessName = this.dropDownEntityList[0].text;
				console.log('fetchEntityList: previous biz', this.previousSelectedEntityName, this.previousSelectedEntityId);
				console.log('fetchEntityList: selectedBiz', this.selectedEntity);
				console.log('fetchEntityList: selectedEntityID', this.selectedEntityId);

				let defaultEntity = [
					{
						id: this.dropDownEntityList[0].id,
						text: this.dropDownEntityList[0].text,
					},
				];

				if (!this.selectedEntityId) {
					this.selectedEntity = defaultEntity;
				} else {
					let entityIndex = this.dropDownEntityList.findIndex((element) => element.id === this.selectedEntityId);
					if (entityIndex !== -1) {
						this.selectedEntity = [this.dropDownEntityList[entityIndex]];
					} else {
						this.selectedEntity = defaultEntity;
					}
				}
				this.previousSelectedEntityId = this.selectedEntity[0].id;
				this.previousSelectedEntityName = this.selectedEntity[0].text;
				console.log('fetchEntityList: selectedBiz', this.selectedEntity);
				console.log('fetchEntityList: previousSelectedEntityId', this.previousSelectedEntityId);
				console.log('fetchEntityList: previousSelectedEntityName', this.previousSelectedEntityName);
				console.log('Entity list after', JSON.parse(JSON.stringify(this.entityList)));
				if (this.entityList.length) {
					if (this.isDealerRep) {
						if (this.selectedEntityId) {
							// this.tabStructureService.selectedEntityDetail = this.entityList.filter(
							//     entity => entity._id === this.selectedEntityId
							// )[0];
							//Set business data with all the details of business.
							this.businessService
								.fetchBusinessInformation()
								.then((bizData) => {
									console.log('bizData: fetchEntityList--->', bizData);
									this.tabStructureService.selectedEntityDetail = bizData;
									console.log('When does it come here?', this.tabStructureService.selectedEntityDetail);
									this.previousSelectedEntityId = this.selectedEntityId;
									this.previousSelectedEntityName = this.tabStructureService.selectedEntityDetail['xName'];
									console.log('previousSelectedEntityId:', this.previousSelectedEntityId);
									console.log('previousSelectedEntityName', this.previousSelectedEntityName);
									this.businessService.businessData = JSON.parse(JSON.stringify(this.tabStructureService.selectedEntityDetail));
								})
								.catch((error) => {
									console.log(error);
									this.notificationService.error(COULD_NOT_SET_BUSINESS_DATA_MSG, ERROR_VALUE);
									if (!this.treeService.businessID.getValue()) {
										console.log('no biz id');
										this.logout();
									}
									this.router.navigateByUrl(HOME_LINK);
								});
						} else {
							this.selectedEntityId = this.entityList[0]._id ? this.entityList[0]._id : null;
							this.previousSelectedEntityId = this.selectedEntityId;
							this.previousSelectedEntityName = this.entityList[0].xName ? this.entityList[0].xName : null;

							this.tabStructureService.selectedEntityDetail = this.entityList[0];
							this.businessService.setBusinessID(this.selectedEntityId);
							this.treeService.setId(this.selectedEntityId, 'business');
						}
					} else if (this.isVendorRep) {
						this.selectedEntityId = this.entityList[0]._id ? this.entityList[0]._id : null;
						this.previousSelectedEntityId = this.selectedEntityId;
						this.previousSelectedEntityName = this.entityList[0].xName ? this.entityList[0].xName : null;
						this.companyService.setCompanyID(this.selectedEntityId);
					}
					// console.log("this.selectedEntityId",this.selectedEntityId);
				}
			},
			(error: any) => {
				const errMsg = JSON.parse(error._body).message;
				this.handleErrorResponse(errMsg, ' No company available!');
			}
		);
	}

	onEntitySelect(event: any, type: string) {
		console.log('Inside onEntity Select Event-------->', event, 'Inside onEntity Select Type-------->', type);
		if (!event) {
			return;
		}
		let TopnavComponent = this;
		if (event.id !== 'null') {
			customSwal
				.fire({
					title: `Are you sure, you want to change the ${type}?`,
					text: 'All the data on this page will be lost!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, go ahead!',
					cancelButtonText: 'No, I changed my Mind!',
					reverseButtons: true,
				})
				.then((result) => {
					if (result.value) {
						console.log('previousid', event);
						TopnavComponent.previousSelectedEntityId = event.id;
						TopnavComponent.previousSelectedEntityName = event.text ? event.text : '';
						TopnavComponent.selectedEntityId = event.id ? event.id : null;
						console.log('this.previousSelectedEntityName -->', TopnavComponent.previousSelectedEntityName);
						console.log('this.previousSelectedEntityId -->', TopnavComponent.previousSelectedEntityId);
						console.log('selectedentityid', TopnavComponent.selectedEntityId);
						// event.name ? event.name : null ;
						if (type === 'business') {
							TopnavComponent.tabStructureService.selectedEntityDetail = TopnavComponent.entityList.filter(
								(entityDetail) => TopnavComponent.selectedEntityId === entityDetail._id
							)[0];
							TopnavComponent.tabStructureService.storeDefTabStruct = true;
							TopnavComponent.businessService.setBusinessID(TopnavComponent.selectedEntityId);
							TopnavComponent.treeService.setId(TopnavComponent.selectedEntityId, 'business');
							if (TopnavComponent.router.url.includes('structure')) {
								TopnavComponent.tabStructureService.storeDefTabStruct = true;
								console.log('this.tabStructureService.storeDefTabStruct', TopnavComponent.tabStructureService.storeDefTabStruct);
								TopnavComponent.router.navigate(['dashboard/setup/business/structure/information', TopnavComponent.selectedEntityId]);
							}
						}

						if (type === 'company') {
							TopnavComponent.companyService.setCompanyID(TopnavComponent.selectedEntityId);
						}
					} else {
						console.log('CHECK MAADI --> this.previousSelectedEntityId', TopnavComponent.previousSelectedEntityId);
						console.log('tabStructureService.setTabNameToInitial', TopnavComponent.tabStructureService);
						if (TopnavComponent.previousSelectedEntityId) {
							TopnavComponent.selectedEntity = [
								{
									id: TopnavComponent.previousSelectedEntityId,
									text: TopnavComponent.previousSelectedEntityName,
								},
							];
							console.log('CHECK MAADI --> this.selectedEntity', TopnavComponent.selectedEntity);
							TopnavComponent.selectedEntityId = TopnavComponent.previousSelectedEntityId;
						} else {
							TopnavComponent.tabStructureService.setTabNameToInitial();
						}
					}
				});
			this.businessName = event.text;
			console.log('businessname', this.businessName);
		} else {
			if (type === 'business') {
				console.log('HERE =========>');
				this.businessService.resetBusinessID();
				this.treeService.resetBusinessID();
				this.tabStructureService.setTabNameToInitial();
				this.previousSelectedEntityId = null;
				this.previousSelectedEntityName = '';
				this.selectedEntityId = null;
				console.log('This is current url when select biz is clicked', this.currentUrl);
				if (this.currentUrl.includes('structure')) {
					this.router.navigate(['dashboard/setup/business/structure/information']);
				}
				// else{
				// 	this.router.navigate(['dashboard/home'])
				// }
			}

			if (type === 'company') {
				this.companyService.resetCompanyID();
			}
		}
	}

	truncateString(value: any): any {
		if (typeof value === 'string') {
			if (value.length > strLength) {
				return value.substring(0, strLength) + '...';
			} else {
				return value;
			}
		} else {
			return value;
		}
	}

	changeTheme(color: string): void {
		const link: any = $('<link>');
		link.appendTo('head')
			.attr({ type: 'text/css', rel: 'stylesheet' })
			.attr('href', 'themes/app-' + color + '.css');
	}

	rtl(): void {
		const body: any = $('body');
		body.toggleClass('rtl');
	}

	sidebarToggler(): void {
		const sidebar: any = $('#sidebar');
		const mainContainer: any = $('.main-container');
		sidebar.toggleClass('sidebar-left-zero');
		mainContainer.toggleClass('main-container-ml-zero');
	}

	setupHome() {
		console.log('setup home');
		this.treeService.setShow(false);
		this.treeService.setEnable(true);
	}

	handleErrorResponse(errorName: string, errorMsg: string) {
		this.notificationService.error(errorMsg, errorName);
		this.logout();
	}

	logout() {
		//TODO Code for logging out should be centralized
		console.log('logout:terminaldata', this.loginStoreService.terminalData);
		console.log('terminal num:logout---> ', this.loginStoreService.terminalNumbersObj);
		console.log('logout:terminaldata', this.loginStoreService.terminalData.xTerminalNum);
		this.companyService.resetCompanyID();
		this.businessService.resetBusinessID();
		this.treeService.setId(null, 'business');
		this.treeService.resetStore();
		let logoutUrl: any = '';
		if (!this.userHandlerService.userRoles.isCustomer) {
			this.authenticationService.logout(logoutUrl);
			return;
		}
		this.showOverLay = true;
		this.generateCustomerLinkService
			.generateCustomerLink()
			.then((custLink) => {
				logoutUrl = custLink;
				this.authenticationService.logout(logoutUrl);
			})
			.catch((error) => {
				this.showOverLay = false;
				this.authenticationService.logout();
			});
	}

	ngOnDestroy() {
		this.alive = false;
	}
}
