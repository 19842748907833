import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// import { TreeService } from '../../shared/services';
import { SetupComponent } from './setup.component';
import { SetupRoutingModule } from './setup-routing.module';
// import { XbaConstsService/*, ConstantService */} from '../../shared/services';

import { SectionsModule, GridModule, ResourceEditorModule, TreeComponentModule } from '../../shared/modules';
// import { NgxAsideModule } from 'ngx-aside';
import { SetupBusinessStructureModule } from '../setup/setup-business-structure/setup-business-structure.module';
import { SetupBillingCustomerModule } from './setup-billing/setup-billing-customer/setup-billing-customer.module';

// import {
//     SetupStructureInformationComponent,
//     SetupStructureChainComponent,
//     SetupStructureRegionComponent,
//     SetupStructurePropertyComponent,
//     SetupStructureStoreComponent,
//     SetupStructureProfitCenterComponent,
//     SetupStructureTerminalComponent,
//     SetupStructureMerchantAccountComponent,
//     SetupStructurePeripheralListComponent,
//     SetupStructureInformationListComponent,
//     SetupStructureChainListComponent,
//     SetupStructureRegionListComponent,
//     SetupStructurePropertyListComponent,
//     SetupStructureStoreListComponent,
//     SetupStructureMerchantAccountListComponent,
//     SetupStructureProfitCenterListComponent,
//     SetupStructureTerminalsListComponent,
//     SetupStructurePeripheralsListComponent,
//     ConfigModalComponent
// } from './setup-business-structure/components';

import { StructureLayoutComponent } from './structure-layout/structure-layout.component';
import { EmployeeLayoutComponent } from './employee-layout/employee-layout.component';
// import { AddEditComponent } from './setup-business-information/components/add-edit/add-edit.component';

import {
	SetupCompanyStore,
	SetupBusinessEmployeeStore,
	SetupCompanyRepresentativeStore,
	TransactionsStore,
	SettledTransactionsStore,
	BatchStore,
	StatusStore,
	SetupBusinessStructureStoreSetupStore,
	SetupStructureProfitCenterStore,
	SetupStructureTerminalStore,
	SetupStructurePeripheralStore,
	SetupPaymentProcessingMerchantAccountStore,
	ConstantStore,
} from '../../store';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MemberAccountStore } from 'app/store/member-account-store';
import { MemberAccountsModule } from 'app/shared/modules/member-accounts/member-accounts.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		SetupRoutingModule,
		ReactiveFormsModule,
		SectionsModule,
		// NgxAsideModule,
		ModalModule.forRoot(),
		TooltipModule.forRoot(),
		TabsModule.forRoot(),
		GridModule,
		ResourceEditorModule,
		TreeComponentModule,
		// SharedPipesModule,
		SectionsModule,
		SetupBusinessStructureModule,
		SetupBillingCustomerModule,
		MemberAccountsModule,
	],

	// SetupBusinessStructureRoutingModule

	declarations: [StructureLayoutComponent, SetupComponent, EmployeeLayoutComponent],
	// FilterAccountEntriesPipe,
	// SetupStructureInformationComponent,
	// SetupStructureChainComponent,
	// SetupStructureRegionComponent,
	// SetupStructurePropertyComponent,
	// SetupStructureStoreComponent,
	// SetupStructureProfitCenterComponent,
	// SetupStructureTerminalComponent,
	// SetupStructureMerchantAccountComponent,
	// SetupStructurePeripheralListComponent,
	// SetupStructureInformationListComponent,
	// SetupStructureChainListComponent,
	// SetupStructureRegionListComponent,
	// SetupStructurePropertyListComponent,
	// SetupStructureStoreListComponent,
	// SetupStructureMerchantAccountListComponent,
	// SetupStructureProfitCenterListComponent,
	// SetupStructureTerminalsListComponent,
	// SetupStructurePeripheralsListComponent,
	// ConfigModalComponent,
	exports: [SetupComponent],
	providers: [
		/*TreeService,*/ SetupCompanyStore,
		SetupBusinessEmployeeStore,
		SetupCompanyRepresentativeStore,
		TransactionsStore,
		BatchStore,
		SettledTransactionsStore,
		StatusStore,
		SetupBusinessStructureStoreSetupStore,
		SetupStructureProfitCenterStore,
		SetupStructureTerminalStore,
		SetupStructurePeripheralStore,
		SetupPaymentProcessingMerchantAccountStore,
		// XbaConstsService,
		ConstantStore,
		// ConstantService
		MemberAccountStore,
	],
})
export class SetupModule {}
