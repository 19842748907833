<div *ngIf="!fetchingData" class="customer-store-info-wrap text-left">
    <div class="customer-store-info-inner">
        <div class="login-wrap login-wrap-left">
            <div class="login-inner store-info-wrap t-p">
                <img *ngIf="storeObj.image" src="{{ storeObj?.image }}" class="user-avatar img-size" />
                <!-- <h3>Store Info</h3> -->
                <div class="px-15">
                    <div class="d-flex py-10 flex-wrap">
                        <i class="fa fa-map-marker store-label-icon"></i>
                        <div style="flex:1">
                            <h5 class="detail-label">{{ storeObj?.storeName }}</h5>
                            <p class="detail-label">{{ storeObj?.storeAddress }}</p>
                        </div>
                    </div>

                    <div class="d-flex py-10 flex-wrap">
                        <i class="fa fa-phone store-label-icon"></i>
                        <p class="detail-label">{{ storeObj?.storePhoneNum }}</p>
                    </div>
                    <div class="d-flex py-10 flex-wrap">
                        <i class="fa fa-envelope store-label-icon"></i>
                        <p class="detail-label">{{ storeObj?.storeEmail }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="customer-login-footer">
        Powered by <a href="https://portal.c9pg.com/" target="_blank"><img src="assets/images/cloud9-logo-dark.png"/></a>
    </div>
</div>
<div *ngIf="fetchingData" class="customer-store-info-wrap text-left">
    <div class="customer-login-wrap" style="text-align: center; position:relative">
        <div class="col-md-12">
            <div class="grid-spinner">
                <div class="bounce1" style="background-color: white !important"></div>
                <div class="bounce2" style="background-color: white !important"></div>
                <div class="bounce3" style="background-color: white !important"></div>
            </div>
            <span style="color: white">Fetching data</span>
        </div>
    </div>
</div>
