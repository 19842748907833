import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
    FormGroup,
    FormBuilder,
    Validators
} from "@angular/forms";
import { forkJoin } from "rxjs";
import { takeWhile, skip } from "rxjs/operators";
import { TAGSCONSTS } from "../../../../../../constants/tags.constants";

import {
    SysInfo,
    Peripheral
} from "../../../../../../models";
import {
    BreadcrumbService,
    NotificationService,
    BusinessService,
    GlobalValidator,
    XpaConstsService,
    HttpService,
    TreeService,
    UrlStoreService,
    TabStructureService,
    UserHandlerService
} from "../../../../../../shared/services";
import {
    SetupStructurePeripheralStore,
    SetupBusinessStructureStore,
    SetupStructureTerminalStore,
    ConstantStore /*, BusinessTreeStore*/
} from "../../../../../../store";
import { NO_PERMISSION_MSG } from "../../../../../../constants";

@Component({
    selector: "add-edit",
    templateUrl: "./add-edit.component.html",
    styleUrls: ["./add-edit.component.scss"]
})
export class SetupStructurePeripheralListComponent
    implements OnInit, OnDestroy {
    bizID: string = ""; //for storing the businessID fetched via service
    nodePath: any[] = []; //For storing the path where the payment device belongs
    peripheralInfo: any = {}; //For storing value retrieved from server in edit mode
    serverErrors: any = {}; //Stores errors returned by server
    form: FormGroup; //Required for reactive form validation
    submitting: boolean = false; //For handling form submission
    edit: boolean = false; //add/edit mode
    alive: boolean = true;
    formErrors = {
        xName: "",
        xTag: "",
        xRefNum: "",
        xPeriphNum: "",
        xShortCode: ""
    };
    loading: boolean = false;
    // tag:string;
    accountEntryTags: any[] = []; //manual/msr/icc/contactLess/micr tags
    pinEntryTags: any[] = [];
    sigEntryTags: any[] = [];

    //Security Capabilities
    certificateCapTags: any[] = [];
    encryptionCapTags: any[] = [];

    storeName: string;
    terName: string = "";
    merAccName: string = "";
    chainName: string = "";
    regionName: string = "";
    propName: string = "";
    pctrName: string = "";

    terminalList: any[] = []; //Stores List of Payment Devices
    capabilities: any[] = [
        { label: "Yes", value: true },
        { label: "No", value: false }
    ];
    numberKey: string = "xPeriphNum";
    parentMode: boolean = false;

    bizChangeEvent: boolean = false;
    canEdit = true;
    canAdd = true;
    noPermMsg = NO_PERMISSION_MSG;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private notificationService: NotificationService,
        private businessService: BusinessService,
        private fb: FormBuilder,
        private httpService: HttpService,
        private xpaConstsService: XpaConstsService,
        private setupBusinessStructureStore: SetupBusinessStructureStore,
        private constantStore: ConstantStore,
        private setupStructurePeripheralStore: SetupStructurePeripheralStore,
        // protected businessTreeStore:BusinessTreeStore,
        private setupStructureTerminalStore: SetupStructureTerminalStore,
        public treeService: TreeService,
        public urlStoreService: UrlStoreService,
        private tabStructureService: TabStructureService,
        public userHandlerService: UserHandlerService
    ) {
        this.serverErrors = {};
    }

    ngOnInit() {
        this.setupPerms();
        this.tabStructureService.checkAndSetTabNameToDef(
            this.tabStructureService.tabMode,
            this.tabStructureService.isCancelClick,
            this.tabStructureService.isSubmitClick
        );
        this.setBreadCrumbValue();
        this.nodePath = this.setupBusinessStructureStore.getNodePath();
        console.log("Inside peripheral add-edit init", this.route);
        this.route.params.forEach((params: Params) => {
            this.edit = false;
            this.toFormGroup(new Peripheral());

            if (params.hasOwnProperty("Id") && params.Id) {
                this.edit = true;
                this.loading = true;
                if (
                    !this.treeService.periphID.getValue() &&
                    !this.treeService.isParent
                ) {
                    console.log("Inside If");
                    this.treeService.setId(params.Id, "peripheral");
                }
                this.setupStructurePeripheralStore
                    .get(params["Id"])
                    .subscribe(res => {
                        // this.peripheralInfo = response[3].data;
                        this.peripheralInfo = res.data;
                        console.log("paymentDevInfo", this.peripheralInfo);
                        this.storeID.setValue(
                            this.peripheralInfo.hasOwnProperty("xStoreID") &&
                            this.peripheralInfo.xStoreID
                                ? this.peripheralInfo.xStoreID
                                : ""
                        );
                        this.terID.setValue(
                            this.peripheralInfo.hasOwnProperty("xTerminalID") &&
                            this.peripheralInfo.xTerminalID
                                ? this.peripheralInfo.xTerminalID
                                : ""
                        );
                        this.storeName = "";
                        this.terName = "";
                        this.tabStructureService.defaultTabStructureLabel.map(
                            singleLabel => {
                                if (
                                    singleLabel &&
                                    singleLabel.hasOwnProperty("label") &&
                                    singleLabel.hasOwnProperty("name") &&
                                    singleLabel.label === "Store"
                                ) {
                                    this.storeName = singleLabel.name.substring(
                                        3,
                                        singleLabel.name.length
                                    );
                                }
                                if (
                                    singleLabel &&
                                    singleLabel.hasOwnProperty("label") &&
                                    singleLabel.hasOwnProperty("name") &&
                                    singleLabel.label.includes("Terminal")
                                ) {
                                    this.terName = singleLabel.name.substring(
                                        3,
                                        singleLabel.name.length
                                    );
                                }
                            }
                        );
                        // this.pctrID.setValue(this.peripheralInfo.hasOwnProperty('xMainPCtrID') && this.peripheralInfo.xMainPCtrID? this.peripheralInfo.xMainPCtrID : '');
                        // this.merAccID.setValue(this.peripheralInfo.hasOwnProperty('xMainMerAcctID') && this.peripheralInfo.xMainMerAcctID? this.peripheralInfo.xMainMerAcctID : '');
                        this.fetchData(params);
                        this.addModeActions();
                    });
            }
            // this.nodePath = this.setupBusinessStructureStore.getNodePath();
            // this.setStoreId();
            // this.setBusinessID();
            // this.setTerminalID();
        });
        if (this.tabStructureService.tabMode) {
            this.checkParentMode();
        }
        this.setTabNameNewPeriph();

        this.setupBusinessStructureStore.watchNodePath$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe((nodePath: any[]) => {
                console.log("This is updated node path periphs", nodePath);
                // if(this.treeService.periphID.getValue()){
                // this.edit=false;
                // this.treeService.setId(this.businessService.businessID.getValue(), 'business');
                this.nodePath = nodePath;
                this.addModeActions();
                // }
                this.parentMode = false;
                // this.checkParentMode();
                // this.setTabNameNewPeriph();
            });
        if (!this.edit) this.addModeActions();
        this.tabStructureService.watchParentFetched$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(res => {
                console.log("Here in watcher of parent fetched", res);
                if (res) {
                    console.log("Here in watcher of parent fetched res true");
                    this.setTabNameNewPeriph();
                }
            });
        this.businessService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizId => {
                if (bizId) {
                    this.bizChangeEvent = true;
                } else {
                    this.router.navigate([
                        "/dashboard/setup/business/structure/information"
                    ]);
                }
            });
    }

    setTabNameNewPeriph() {
        if (!this.edit) {
            console.log(
                "%c Add mode hai k??",
                "background: blue; color: white"
            );
            this.fetchData();
            if (this.tabStructureService.tabLabelItems.length) {
                // console.log('%c Add mode hai k??', 'background: pink; color: white');
                this.tabStructureService.tabLabelItems.map(labelItem => {
                    // console.log('%c Add mode hai k??', 'background: grey; color: black', labelItem);
                    if (labelItem.label.includes("Peripheral")) {
                        if (labelItem.hasOwnProperty("type")) {
                            labelItem["label"] = "Peripheral";
                            labelItem["type"] = "New";
                            labelItem["name"] = "";
                        }
                    }
                });
                console.log(
                    "%c Peripheral Add mode hai k??",
                    "background: grey; color: black",
                    this.tabStructureService.tabLabelItems
                );
                this.tabStructureService.setTabStructureNames(
                    this.tabStructureService.tabLabelItems
                );
            }
        }
    }

    checkParentMode() {
        if (this.tabStructureService.tabLabelItems.length) {
            this.tabStructureService.tabLabelItems.map(labelItem => {
                if (labelItem.label.includes("Peripheral")) {
                    if (
                        labelItem.hasOwnProperty("type") &&
                        labelItem["type"] === "Parent"
                    ) {
                        this.parentMode = true;
                    } else {
                        this.parentMode = false;
                    }
                }
            });
        }
    }

    addModeActions() {
        // if (!this.edit) {
        this.bizID = this.businessService.businessID.getValue();
        this.form.controls["xWall2EntyID"].setValue(this.bizID);
        this.setBusinessID();
        console.log("HERE I'm", this.storeID.value, this.terID.value);
        if (!this.storeID.value) {
            this.setStoreId();
        }
        if (!this.terID.value) {
            this.setTerminalID();
        }
        // const terObj: any = this.nodePath.filter(
        //     node => node.type === "terminal"
        // );
        // const stoObj: any = this.nodePath.filter(
        //     node => node.type === "store"
        // );
        // if (terObj.length) {
        //     this.setTerminalID();
        // }
        // else{
        //     if(this.treeService.terminalID.getValue()){
        //         this.terID.setValue(this.treeService.terminalID.getValue());
        //         this.terName = this.tabStructureService.currentNodeName;
        //     }
        //     else{
        //         this.terID.setValue("");
        //         this.terName = "";
        //         this.notificationService.error(
        //             "Cannot modify this Terminal",
        //             "ERROR"
        //         );
        //         // this.urlStoreService.routeBackToPreviousUrl();
        //     }
        // }
        // if (stoObj.length) {
        //     this.setStoreId();
        // }
        // }
    }

    fetchData(params?: any) {
        //Get Contants and Payment Device List
        const observables = [
            //this.setupStructureTerminalStore
            //    .getAll({})
            //    .map((res: Response) => res), // Payment Device List
            // this.xpaConstsService.getXpaConsts('acctent').map((res: Response) => res), // Account Entry Types
            // this.xpaConstsService.getXpaConsts('pinent').map((res: Response) => res), // PIN Entry Types
            // this.xpaConstsService.getXpaConsts('sigent').map((res: Response) => res), // Signature Entry Types

            this.xpaConstsService
                .getXpaItems("cert"), // Certificate Types
            this.xpaConstsService.getXpaItems("enc") // Encryption Types
        ];

        // if (this.edit) {
        //     observables.push(
        //         this.setupStructurePeripheralStore
        //             .get(params["Id"])
        //             .map((res: Response | any) => res)
        //     );
        // }

        forkJoin(observables).subscribe(
            (response: any) => {
                console.log(response);
                // this.terminalList = response[0].data.filter(tag=> tag.xStatus);
                // this.certificateCapTags = response[1].data.filter(tag=> tag.xStatus);
                // this.encryptionCapTags = response[2].data.filter(tag=> tag.xStatus);
                this.certificateCapTags = response[0].data.filter(tag => tag.xStatus);
                this.encryptionCapTags = response[1].data.filter(tag => tag.xStatus);
                console.log("certificateCapTags", this.certificateCapTags);
                console.log("encryptionCapTags", this.encryptionCapTags);
                // this.setConstants(response[1].data, response[2].data, response[3].data);

                if (this.edit) {
                    this.loading = false;
                    console.log(
                        "Data coming in edit mode",
                        this.peripheralInfo
                    );
                    this.toFormGroup(new Peripheral(this.peripheralInfo));
                    // this.setFormValues(this.peripheralInfo);
                }
            },
            (error: any) => {
                this.handleErrorResponse(error);
                this.urlStoreService.routeBackToPreviousUrl();
            },
            () => {}
        );
    }

    setConstants(aactEntTags: any[], pinEntTags: any[], sigEntTags: any[]) {
        this.accountEntryTags = aactEntTags;
        this.pinEntryTags = pinEntTags;
        this.sigEntryTags = sigEntTags;
    }

    setBreadCrumbValue() {
        const breadCrumb: any = {};
        breadCrumb.root = "Setup";
        breadCrumb.child = "Business";
        breadCrumb.subChild = "Structure";
        breadCrumb.subChildLink = "setup/business/structure/information";
        breadCrumb.grandChild = "Peripheral-Setup";
        breadCrumb.rootList = [];
        this.breadcrumbService.setBreadCrumb(breadCrumb);
    }

    toFormGroup(data: Peripheral): void {
        this.form = this.fb.group({
            xTntTag: [data.xTntTag || "tnttag", []],
            xWall1EntyID: [data.xWall1EntyID || "", []],
            xWall2EntyID: [data.xWall2EntyID || this.bizID, []],
            xTag: [data.xTag || "", []],
            xStatus: [data.xStatus || "1", []],
            xName: [
                data.xName || "",
                [Validators.required, GlobalValidator.validateName]
            ],
            xTypeTag: [
                data.xTypeTag || TAGSCONSTS.peripheralTags.regularPeripheral,
                []
            ],
            xNotes: [data.xNotes || "", []],
            xRefNum: [data.xRefNum || "", []],
            xPeriphNum: [data.xPeriphNum || "", [Validators.required]],
            xShortCode: [data.xShortCode || "", [Validators.required]],
            xBusinessID: [data.xBusinessID || "", []],
            xStoreID: [data.xStoreID || "", []],
            xTerminalID: [data.xTerminalID || "", []],
            // xDeviceID: [data.xDeviceID || '', []],
            xConnUrl: [data.xConnUrl || "", []],
            // xCertTag: [data.xCertTag || '', []],
            // xEncTag: [data.xEncTag || '', []],
            // xCertNum: [data.xCertNum || '', []],
            xSecCaps: this.fb.group(data.xSecCaps),
            xHWSysInfo: this.fb.group(new SysInfo(data.xHWSysInfo)),
            xSWSysInfo: this.fb.group(new SysInfo(data.xSWSysInfo)),
            xConnCaps: this.fb.group(data.xConnCaps),
            xEntrCaps: this.fb.group(data.xEntrCaps),
            xOutpCaps: this.fb.group(data.xOutpCaps)
        });
    }

    setStoreId() {
        const storeObj: any = this.nodePath.filter(
            node => node.type === "store"
        );
        const chainObj: any = this.nodePath.filter(
            //For tab name
            node => node.type === "chain"
        );
        const regionObj: any = this.nodePath.filter(
            //For tab name
            node => node.type === "region"
        );
        const propObj: any = this.nodePath.filter(
            //For tab name
            node => node.type === "property"
        );
        const pctrObj: any = this.nodePath.filter(
            //For tab name
            node => node.type === "profit_center"
        );
        const merObj: any = this.nodePath.filter(
            //For tab name
            node => node.type === "merchant_account"
        );
        if (storeObj.length) {
            this.storeID.setValue(storeObj[0].id);
            this.storeName = storeObj[0].name;
        } else {
            // this.httpService.get("")
            if (this.treeService.terminalID.getValue()) {
                this.setupStructureTerminalStore
                    .get(this.treeService.terminalID.getValue())
                    .subscribe(res => {
                        this.storeID.setValue(res.data.xStoreID);
                        this.tabStructureService.defaultTabStructureLabel.map(
                            singleLabel => {
                                if (
                                    singleLabel &&
                                    singleLabel.hasOwnProperty("label") &&
                                    singleLabel.hasOwnProperty("name") &&
                                    singleLabel.label === "Store"
                                ) {
                                    this.storeName = singleLabel.name.substring(
                                        3,
                                        singleLabel.name.length
                                    );
                                }
                                // if(singleLabel && singleLabel.hasOwnProperty('label') && singleLabel.hasOwnProperty('name') && singleLabel.label.includes('Terminal')){
                                //     this.terName = singleLabel.name.substring(3, singleLabel.name.length);
                                // }
                            }
                        );
                    });
            } else {
                this.notificationService.error(
                    "Cannot alter this store",
                    "ERROR"
                );
            }
            // this.urlStoreService.routeBackToPreviousUrl();
        }
        this.chainName = chainObj.length ? chainObj[0].name : ""; //For tab name
        this.regionName = regionObj.length ? regionObj[0].name : ""; //For tab name
        this.propName = propObj.length ? propObj[0].name : ""; //For tab name
        this.pctrName = pctrObj.length ? pctrObj[0].name : ""; //For tab name
        this.merAccName = merObj.length ? merObj[0].name : ""; //For tab name
        // if (storeObj.length) {
        //     this.storeID.setValue(storeObj[0].id);
        //     this.storeName = storeObj[0].name;
        // } else {
        //     this.notificationService.error("Cannot alter this store", "ERROR");
        //     // this.urlStoreService.routeBackToPreviousUrl();
        // }
    }

    setBusinessID() {
        const bizObj: any = this.nodePath.filter(
            node => node.type === "business"
        );
        if (bizObj.length) {
            if (this.bizID === bizObj[0].id) {
                this.businessID.setValue(bizObj[0].id);
            } else {
                if (this.bizID) {
                    this.businessID.setValue(this.bizID);
                } else {
                    this.notificationService.error(
                        "Cannot modify this business",
                        "ERROR"
                    );
                    // this.urlStoreService.routeBackToPreviousUrl();
                }
            }
        } else {
            this.businessID.setValue(this.bizID);
        }
    }

    setTerminalID() {
        const terObj: any = this.nodePath.filter(
            node => node.type === "terminal"
        );
        if (terObj.length) {
            this.terID.setValue(terObj[0].id);
            this.terName = terObj[0].name;
        } else {
            if (this.treeService.terminalID.getValue()) {
                this.terID.setValue(this.treeService.terminalID.getValue());
                this.tabStructureService.defaultTabStructureLabel.map(
                    singleLabel => {
                        // if(singleLabel && singleLabel.hasOwnProperty('label') && singleLabel.hasOwnProperty('name') && singleLabel.label === 'Store'){
                        //     this.storeName = singleLabel.name.substring(3, singleLabel.name.length);
                        // }
                        if (
                            singleLabel &&
                            singleLabel.hasOwnProperty("label") &&
                            singleLabel.hasOwnProperty("name") &&
                            singleLabel.label.includes("Terminal")
                        ) {
                            this.terName = singleLabel.name.substring(
                                3,
                                singleLabel.name.length
                            );
                        }
                    }
                );
            } else {
                this.notificationService.error(
                    "Cannot modify this Payment Device",
                    "ERROR"
                );
            }
            // this.urlStoreService.routeBackToPreviousUrl();
        }
    }

    get storeID(): any {
        return this.form.get("xStoreID");
    }
    get businessID(): any {
        return this.form.get("xBusinessID");
    }
    get terID(): any {
        return this.form.get("xTerminalID");
    }

    onSubmit(form: FormGroup): void {
        this.urlStoreService.submitAction = true;
        this.tabStructureService.isSubmitClick = true;
        this.submitting = true;
        this.serverErrors = {}; //Reset Errors

        const peripheralModel = form.getRawValue();
        console.log("peripheralModel", peripheralModel);

        if (peripheralModel.xEntrCaps) {
            for (const field of Object.keys(peripheralModel.xEntrCaps)) {
                if (peripheralModel.xEntrCaps[field] === "null") {
                    peripheralModel.xEntrCaps[field] = false;
                }
            }
        }
        if (peripheralModel.xOutpCaps) {
            for (const field of Object.keys(peripheralModel.xOutpCaps)) {
                if (peripheralModel.xOutpCaps[field] === "null") {
                    peripheralModel.xOutpCaps[field] = false;
                }
            }
        }

        if (peripheralModel.xSecCaps) {
            for (const field of Object.keys(peripheralModel.xSecCaps)) {
                if (field === "xCertTag") {
                    if (peripheralModel.xSecCaps[field] === "null") {
                        peripheralModel.xSecCaps[field] = "cert.none";
                    }
                } else if (field === "xEncTag") {
                    if (peripheralModel.xSecCaps[field] === "null") {
                        peripheralModel.xSecCaps[field] = "enc.none";
                    }
                }
            }
        }

        if (this.edit) {
            //Update Peripheral
            console.log("Submit of edit mode", peripheralModel);
            this.setupStructurePeripheralStore
                .update(this.peripheralInfo._id, peripheralModel)
                .subscribe(
                    (response: any) => {
                        this.handleSuccessResponse(
                            response.data,
                            peripheralModel
                        );
                    },
                    (error: any) => {
                        this.serverErrors = error.errors;
                        this.handleError(error);
                    },
                    () => {}
                );
        } else {
            //Add New Peripheral
            this.setupStructurePeripheralStore.store(peripheralModel).subscribe(
                (response: any) => {
                    this.handleSuccessResponse(response.data, peripheralModel);
                },
                (error: any) => {
                    this.serverErrors = error.errors;
                    this.handleError(error);
                },
                () => {}
            );
        }
    }

    handleSuccessResponse(data: any, peripheralModel?: any): void {
        this.submitting = false;
        const msg = this.edit
            ? "Record Successfully Updated."
            : "Record Successfully Added.";
        this.notificationService.success(msg, "Success!");
        this.businessService.toggleRefreshTree();
        // Proper routing depending upon nodeClick or any other click
        if (this.treeService.nodeClick) {
            // this.router.navigated = false;
            if (this.edit) {
                // this.urlStoreService.routeToCurrentUrl(data, "peripheral");
                this.setupStructurePeripheralStore
                    .get(data.id)
                    .subscribe(response => {
                        this.peripheralInfo = response.data;
                        this.toFormGroup(new Peripheral(this.peripheralInfo));
                    });
            } else {
                this.tabStructureService.currentNodeName =
                    peripheralModel.xName;
                this.urlStoreService.routeToCurrentUrl(
                    data,
                    "peripheral",
                    "add"
                );
            }
        } else {
            if (this.tabStructureService.tabMode) {
                this.urlStoreService.routeBackToPreviousUrl();
            } else {
                if (this.tabStructureService.addNodeBtnClick) {
                    this.tabStructureService.currentNodeName =
                        peripheralModel.xName;
                    this.urlStoreService.routeToCurrentUrl(
                        data,
                        "peripheral",
                        "add"
                    );
                } else {
                    this.treeService.setId(
                        this.businessService.businessID.getValue(),
                        "business"
                    );
                    console.log(
                        "onsuccess no tab mode and node click   peripheral",
                        this.treeService.businessID.getValue(),
                        this.treeService.chainID.getValue()
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            }
        }
    }

    handleError(error: any) {
        this.submitting = false;
        for (const field of Object.keys(error.errors)) {
            if (this.formErrors.hasOwnProperty(field)) {
                // this.formErrors[field] = 'Field is not allowed to be empty';
                if (field === "xPeriphNum") {
                    console.log("Inside handle error", error);
                    if (error.name === "Conflict") {
                        let err = error.errors.xPeriphNum.replace(
                            /peripheral/g,
                            "Peripheral"
                        );
                        this.notificationService.error(err, "Conflict");
                        this.clearErrors("xPeriphNum");
                    } else {
                        this.formErrors[field] =
                            "Field is not allowed to be empty";
                    }
                } else {
                    this.formErrors[field] = "Field is not allowed to be empty";
                }
            }
            if (field === "xRefNum") {
                console.log("Inside reference number handle error", error);
                if (error.name === "Conflict") {
                    let err = error.errors.xRefNum.replace(
                        /peripheral/g,
                        "Peripheral"
                    );
                    this.notificationService.error(err, "Conflict");
                    this.clearErrors("xRefNum");
                }
            }
        }
    }

    handleErrorResponse(error: any): void {
        this.submitting = false;
        const msg = this.edit ? "Record Not Updated." : "Record Not Added.";
        try {
            this.serverErrors = JSON.parse(error._body).errors;
            this.notificationService.error(
                JSON.parse(error._body).name === "BadRequest"
                    ? msg
                    : JSON.parse(error._body).name,
                JSON.parse(error._body).message === "Invalid data"
                    ? "Please fill all Required Details"
                    : JSON.parse(error._body).message
            );
        } catch (err) {
            this.notificationService.error("Something went Wrong", "ERROR");
        }
        this.submitting = false;
    }

    clearErrors(errorName: string): void {
        if (this.serverErrors[errorName]) {
            delete this.serverErrors[errorName];
        }
    }

    onCancel(event: Event) {
        event.preventDefault();
        if (this.tabStructureService.tabMode) {
            if (!this.edit) {
                this.urlStoreService.routeBackToPreviousUrl();
            } else {
                this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
                this.urlStoreService.routeToSecondLastUrl();
            }
        } else {
            if (this.edit) {
                console.log("no tabmode and edit");
                if (!this.tabStructureService.editMode) {
                    console.log("no tabmode and edit and editmode");
                    this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
                    if (
                        this.urlStoreService.previousUrl &&
                        !this.urlStoreService.previousUrl.includes("add-edit")
                    ) {
                        this.treeService.setId(
                            this.treeService.previousSelectedNode.id,
                            this.treeService.previousSelectedNode.type
                        );
                    }
                    this.urlStoreService.routeBackToPreviousUrl();
                    // }
                } else {
                    console.log("no tabmode and edit and no editmode");
                    if (this.treeService.nodeClick) {
                        this.urlStoreService.routeToSecondLastUrl();
                    } else {
                        this.treeService.setId(
                            this.treeService.previousSelectedNode.id,
                            this.treeService.previousSelectedNode.type
                        );
                        this.urlStoreService.routeBackToPreviousUrl();
                    }
                }
            } else {
                console.log("no tabmode and no edit");
                this.urlStoreService.routeBackToPreviousUrl();
            }
        }
    }

    setupPerms(){
        this.canEdit = this.userHandlerService.userPerms.canEditSetup;
        this.canAdd = this.userHandlerService.userPerms.canCreateSetup;
    }

    ngOnDestroy() {
        this.alive = false;
    }
}
