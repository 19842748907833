import { Injectable } from '@angular/core';
import { API_END_POINT } from 'app/constants';
import { HttpService } from '../shared/services/index';

const endPoint = API_END_POINT.xpeInvoice;
const schedulerEndpoint = API_END_POINT.action_scheduler_queues;

@Injectable()
export class SetupInvoiceStore {
	static storesData: any[];

	constructor(protected httpService: HttpService) {}

	getAll(params: any): any {
		return this.httpService.getAll(endPoint, params);
	}

	store(element: any): any {
		return this.httpService.store(endPoint, element);
	}

	patch(elementID: string, updatedElement: any): any {
		return this.httpService.patch(endPoint, elementID, updatedElement);
	}

	update(elementID: string, newElement: any): any {
		return this.httpService.update(endPoint, elementID, newElement);
	}

	get(elementID: any): any {
		return this.httpService.get(endPoint, elementID);
	}

	destroy(elementID: any): any {
		return this.httpService.destroy(endPoint, elementID);
	}

	deleteSchedulerEntry(params: any): any {
		return this.httpService.destroy(schedulerEndpoint, params);
	}
}
