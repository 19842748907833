import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
	UserHandlerService,
	LoginStoreService,
	HttpService,
	NotificationService,
	AuthenticationService
} from '../../../../shared/services';
import { 
	API_END_POINT, 
	AUTH_MESSAGES, 
	ERR_NAMES, 
	GOOGLE_RECAPTCHA_SITE_KEY, 
	MESSAGES, 
	XSCUSERS
} from '../../../../constants/index';
import {
	TerminalNumbersObj,
	NOT_FOUND_URL,
	INVALID_TERMINAL_NUMBER_MESSAGE,
	validateCustomerContextResponse,
	PREVIOUS_CONFIGURATION_HAS_BEEN_ALTERED_MESSAGE,
	CORRUPTED_TOKEN_MESSAGE,
	NO_TOKEN_AVAILABLE_MESSAGE,
	PAYLOAD_TERMINAL_NUMBERS_KEY,
	TOKEN_DECRYPTION_FAILED_MESSAGE,
	PARAM_STRING
} from '../common-functions/index';
import { validateFor } from '../../../../common-functions';
import { Subscription, timer } from 'rxjs';
import { WEB_APP_HOST } from 'app/shared/config/webApp.config';

const customer_context_endpoint = 'customer_context';
const VTERM_DECRYPT_END_POINT = 'vTerm/decrypt';
const CUSTOMER_REGISTRATION_URL: string = 'customer/register';
//BE-KEYS
const BE_TERMINAL_DATA_KEY: string = 'terminalData';
const BE_PCTR_DATA_KEY: string = 'pctrData';
const BE_TERMINAL_NUM_KEY: string = 'terminalNum';
const BE_ERRORS_KEY: string = 'errors';
const BE_DATA_KEY: string = 'data';
const BE_ERROR_KEY = 'error';

const TWO_FA_TOKEN_KEY: string = 'twoFAToken';
const ACCESS_TOKEN_KEY: string = 'accessToken';
const OTP_VERIFICATION_RESP_KEY: string = 'otpVerificationResp';
const OTP_KEY: string = 'otp';
const REMAINING_ATTEMPTS_KEY: string = 'remAttempts';
const USER_KEY = 'user';
const OTP_EXPIRY_INIT_TIME: number = 300; //60*5 seconds for otp expiry

const CUST_DASHBOARD_URL: string = 'dashboard/customer/pay-now';
const SOMETHING_WENT_WRONG_ERR = 'Something went wrong. Please try again.';
const WEB_APP_HOST_STAGING = 'https://testportal.c9pg.com';
@Component({
	selector: 'app-customer-login',
	templateUrl: './customer-login.component.html',
	styleUrls: ['./customer-login.component.scss']
})
export class CustomerLoginComponent implements OnInit {
	terminalNumbersObj: TerminalNumbersObj = {};
	terminalData: any;
	storeData: any;
	username: string = '';
	password: string = '';
	otp: string = '';
	rememberMe: boolean = false;
	disable: boolean = false;
	storeObj: any = {};
	fetchingData: boolean = true;
	public token: string = ''; //Token from url
	inSecureUser: boolean = false; //True if twoFAtoken is generated
    twoFAtoken: string = ''; //Token which contains encrypted data for 2FA
    otpVerificationLoader: boolean = false;
    newOTPLoader: boolean = false; //set to true on generating new request
    timeLeft: number = 0; //remaining time for otp expiry
    countDown: Subscription;
    tick = 1000;
    recaptchaVerified: string = '';
	xLoginCoolDown = '';
	showTwoFABtns = false; //controller for Skip for now and Enable Two FA buttons
    loginResponse = {}; //response from login api
	disableTwoFABtn = false;
    NO_OF_ATTEMPTS_REMAINING: string;
    EMAIL_SENT_MESSAGE: string = '';
    OTP_EXPIRED_ERR_MSG: string = '';
    GOOGLE_RECAPTCHA_SITE_KEY: string = GOOGLE_RECAPTCHA_SITE_KEY;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		public loginStoreService: LoginStoreService,
		public httpService: HttpService,
		private notificationService: NotificationService,
		public userHandlerService: UserHandlerService,
		private authenticationService: AuthenticationService
	) {}

	ngOnInit() {
		if(WEB_APP_HOST === WEB_APP_HOST_STAGING){
			this.disableTwoFABtn = true;
        }
		console.log('Activated Route', this.activatedRoute);
		this.fetchingData = true;
		this.activatedRoute.queryParams.subscribe(queryParam => {
			console.log('param: ngOnInit: customer login', queryParam[PARAM_STRING])
			if(!validateFor(PARAM_STRING, queryParam)){
				this.routeToNotFoundPage(NO_TOKEN_AVAILABLE_MESSAGE);
				return;
			}
			this.token = queryParam[PARAM_STRING];
			this.loginStoreService.tokenForLoginAsCustomer = this.token;
			this.httpService.authenticatedAPI = false;
			this.httpService.store(VTERM_DECRYPT_END_POINT, {vTermToken: this.token}).subscribe(
			decryptResponse => {
				console.log("decryptResponse: ngOnInit: customer login", decryptResponse);
				this.httpService.authenticatedAPI = true;
				if(validateFor(BE_ERROR_KEY, decryptResponse) && decryptResponse[BE_ERROR_KEY]){
					this.routeToNotFoundPage(CORRUPTED_TOKEN_MESSAGE);
					return;
				}
				this.terminalNumbersObj = validateFor(BE_DATA_KEY, decryptResponse) 
					&& validateFor(PAYLOAD_TERMINAL_NUMBERS_KEY, decryptResponse[BE_DATA_KEY]) 
					? decryptResponse[BE_DATA_KEY][PAYLOAD_TERMINAL_NUMBERS_KEY]
					: {};
				this.getCustomerContextData();
				console.log('customer login init: loginStoreService store', this.loginStoreService.storeData);
				console.log('customer login init: loginStoreService terminal', this.loginStoreService.terminalData);
				console.log('terminalNumbersObj: after decryption', this.terminalNumbersObj);
				console.log('terminalNumbersObj', this.terminalNumbersObj);
			},
			decryptError => {
				console.log("error: ngOnInit: customer login", decryptError);
				this.httpService.authenticatedAPI = true;
				this.notificationService.error(TOKEN_DECRYPTION_FAILED_MESSAGE, 'Error');
			}
		);
		})
	}

	login(form: any, enableTwoFA: boolean) {
		console.log('form', form);
		const username = form && form.username ? form.username : '';
		const password = form && form.password ? form.password : '';
		console.log('login-as-customer login: credentials', username, password, this.terminalNumbersObj);
		this.userHandlerService.userRoles.isCustomer = true;
		console.log('roles', this.userHandlerService.userRoles);
		this.username = username;
        this.password = password;
		this.disable = true;
		this.httpService.authenticatedAPI = false;
		this.authenticationService
			.login(username, password, this.terminalNumbersObj, enableTwoFA)
			.then(res => {
				console.log('res: login', res);
				this.loginResponse = res;
				if(validateFor(TWO_FA_TOKEN_KEY, this.loginResponse)){
					this.handleLoginResponse();
					return;
				}
                if(this.checkInsecureUser() && this.shouldEnableTwoFA()){
                    this.showTwoFABtns = true;
                    return;
                }
                this.handleAccessToken();
			})
			.catch(loginError => {
				this.handleLoginError(loginError);
			});
	}

	handleLoginError(loginError){
		this.disable = false;
		this.newOTPLoader = false;
		let errorMessage = '';
		let errorName = '';
		console.log('login failed: error', loginError);
		// this.notificationService.error('Login Failed.', 'Error');
		if (loginError.name === 'NotAuthenticated' && loginError.message === 'Error') {
			console.log('modalu');
			errorMessage = MESSAGES['notAuthenticated'].message;
			errorName = MESSAGES['notAuthenticated'].name;
			this.showLoginErrorNotification(errorMessage, errorName);
		} else if (loginError.message && loginError.message.includes('Missing credentials')) {
			errorMessage = MESSAGES['MissingCredentials'].message;
			errorName = MESSAGES['MissingCredentials'].name;
			this.showLoginErrorNotification(errorMessage, errorName);
		} else if(loginError.name == ERR_NAMES.too_many_requests){
			this.username = '';
			this.password = '';
			this.otp = '';
			this.inSecureUser = false;
			//clear tokens stored
			this.rememberMe ? localStorage.removeItem('token') : sessionStorage.removeItem('token');
			localStorage.removeItem('rememberMe');
		} else {
			console.log('nantara');
			this.showLoginErrorNotification(
				loginError && loginError.message ? loginError.message : 'Login Failed',
				loginError && loginError.name ? loginError.name : 'Error'
			);
		}
	}

	//Error notification if login is failed
	//paramters: error-message(string), error-name(string)
	showLoginErrorNotification(errorMessage: string, errorName: string) {
		this.notificationService.error(errorMessage, errorName);
	}

	//Maintain uniform params in customer-registration link and customer-login link
	openCustomerRegistration() {
		console.log('term num: openCustomerRegistration --->', this.terminalNumbersObj);
		this.router.navigate([CUSTOMER_REGISTRATION_URL], {
			queryParams: { param: this.token }
		});
	}

	routeToLoginPage() {
		console.log('previous page called');
		const link = ['/login'];
		this.router.navigate(link);
	}

	// fetch data w.r.t terminalNumber
	getCustomerContextData() {
		console.log('getCustomerContextData: terminalNumbersObj===>', this.terminalNumbersObj);
		this.httpService.authenticatedAPI = false;
		// this.fetchingTerminal = true;
		const params = {
			terminalNumbersObj: this.terminalNumbersObj
		};
		this.loginStoreService.setFetchedRelevantData(false);
		this.httpService.store(customer_context_endpoint, params).subscribe(
			customerContextResponse => {
				console.log('getCustomerContextData: res', customerContextResponse);
				this.fetchingData = false;
				// this.fetchingTerminal = false;
				let terminalsArray = validateFor(BE_TERMINAL_DATA_KEY, customerContextResponse) ? customerContextResponse.terminalData : [];
				//If terminal numbers in link and response dont match, terminal number has been tampered.
				if (terminalsArray.length < Object.keys(this.terminalNumbersObj).length) {
					this.routeToNotFoundPage(INVALID_TERMINAL_NUMBER_MESSAGE);
					return;
				} else if (terminalsArray.length > Object.keys(this.terminalNumbersObj).length) {
					console.log('multi terminal found');
					//dupliated terminal ID, filter to avoid this bug
					let pctrData = validateFor(BE_PCTR_DATA_KEY, customerContextResponse) ? customerContextResponse[BE_PCTR_DATA_KEY] : {};
					let pctrID = validateFor("_id", pctrData) ? pctrData["_id"] : "";
					customerContextResponse[BE_TERMINAL_DATA_KEY] = terminalsArray.filter(obj => obj.xMainPCtrID === pctrID);
				}
				//Before setting loginStoreService check if pctr, store and terminal data are available
				if (!validateCustomerContextResponse(customerContextResponse)) {
					this.notificationService.error(PREVIOUS_CONFIGURATION_HAS_BEEN_ALTERED_MESSAGE, 'Error');
					this.routeToLoginPage();
					return;
				}
				this.httpService.authenticatedAPI = true;
				this.loginStoreService.setData(customerContextResponse);
			},
			error => {
				console.log('getCustomerContextData: error', error);
				this.httpService.authenticatedAPI = true;
				if (validateFor(BE_ERRORS_KEY, error) && validateFor(BE_TERMINAL_NUM_KEY, error[BE_ERRORS_KEY])) {
					console.log('routing to not-found page: error in customer context api');
					this.routeToNotFoundPage(INVALID_TERMINAL_NUMBER_MESSAGE);
					return;
				}
				this.notificationService.error(INVALID_TERMINAL_NUMBER_MESSAGE, 'Error');
				this.routeToLoginPage();
			}
		);
	}

	recaptchaError($event){
        this.notificationService.error('Something went wrong.','Error');
    }

	/**
     * Store token only when user submits OTP
     * Verify OTP, if valid, route to home page
     * @param value 
    */
	onSubmitOTP(value: any){
        if(!validateFor(OTP_KEY, value)){
            this.notificationService.error(AUTH_MESSAGES.OTP_IS_REQUIRED_ERR_MSG, 'Error');
            return;
        }
        console.log('form value: onSubmitOTP', value);
        this.EMAIL_SENT_MESSAGE = '';
        this.OTP_EXPIRED_ERR_MSG = '';
        this.authenticationService.storeAccessToken();
        const twoFAData = {
            otp: value[OTP_KEY],
			requestTimeStamp: new Date(),
            token: this.twoFAtoken,
            xLoginCoolDown: new Date(this.xLoginCoolDown)
        }
        this.otpVerificationLoader = true;
		this.disable = true;
        this.httpService.store(API_END_POINT.verifyOTP, twoFAData)
            .subscribe(resp => {
                this.disable = false;
				this.otpVerificationLoader = false;
                const otpVerificationResp = validateFor(OTP_VERIFICATION_RESP_KEY, resp)  
                    ? resp[OTP_VERIFICATION_RESP_KEY]
                    : {}
                this.NO_OF_ATTEMPTS_REMAINING = validateFor(REMAINING_ATTEMPTS_KEY, otpVerificationResp)
                    ? AUTH_MESSAGES.NO_OF_ATTEMPTS_REMAINING 
						+ otpVerificationResp[REMAINING_ATTEMPTS_KEY]
                    : '';
				if(!this.NO_OF_ATTEMPTS_REMAINING){
					this.handleTooManyRequests();
					return;
				}
                console.log('otp verification response: onSubmitOTP', otpVerificationResp);
                if(otpVerificationResp.success){
					/** If user logs in with registered ip, only accessToken will be available */
					if(validateFor(ACCESS_TOKEN_KEY, otpVerificationResp)){
						this.authenticationService.accessToken = otpVerificationResp[ACCESS_TOKEN_KEY];
						this.authenticationService.storeAccessToken();
					}
                    let message = otpVerificationResp.message 
						? otpVerificationResp.message
						: AUTH_MESSAGES.OTP_VERIFICATION_SUCCESSFUL_MSG;
                    this.notificationService.success(message, 'Success'); 
                    this.router.navigate([CUST_DASHBOARD_URL]);
                    this.httpService.authenticatedAPI = true;
                }else{
					this.otp = ''; //if error clear otp
                    let message = otpVerificationResp.message 
						? otpVerificationResp.message
						: AUTH_MESSAGES.OTP_VERIFICATION_FAILED_MSG;
                    this.notificationService.error(message, 'Error'); 
                }
            }, error => {
                this.otpVerificationLoader = false;
                this.disable = false;
				this.otp = ''; //if error clear otp
                console.log('onSubmitOTP: error', error);
                let message = error.message 
					? error.message
					: AUTH_MESSAGES.OTP_VERIFICATION_FAILED_MSG;
                if(error.name == ERR_NAMES.too_many_requests){
					this.username = '';
					this.password = '';
					this.handleTooManyRequests();
					return;
                }
				this.notificationService.error(message, 'Error');
            })
    }

	/**
     * Show error notification, set boolans and clear tokens
     */
	 handleTooManyRequests(){
        this.notificationService.error(AUTH_MESSAGES.TOO_MANY_REQUESTS, 'Error');
        this.NO_OF_ATTEMPTS_REMAINING = '';
        this.inSecureUser = false;
        this.otpVerificationLoader = false;
        if(this.countDown) this.countDown.unsubscribe();
		this.rememberMe ? localStorage.removeItem('token') : sessionStorage.removeItem('token');
		localStorage.removeItem('rememberMe');
    }

	/**
     * Decrease timeLeft each second until it reaches 0
     */
	startTimer() {
        this.countDown = timer(0, this.tick)
            .subscribe(() => {
                if(this.timeLeft > 0) this.timeLeft--;
                else {
					this.OTP_EXPIRED_ERR_MSG = AUTH_MESSAGES.OTP_EXPIRED_ERR_MSG; 
					this.countDown.unsubscribe();
				}
            })
    }

    /**
     * On click of generate new verification code, re initiate login request
     */
    generateNewOTP(){
		this.otp = '';
		this.newOTPLoader = true;
        this.countDown.unsubscribe();
		this.onEnableTwoFA();
    }

    ngOnDestroy(){
		this.username = '';
		this.password = '';
		this.otp = '';
        if(this.countDown) this.countDown.unsubscribe();
    }

	/**
	 * if accessToken is present in login response, route to home page
	 * @memberof LoginComponent
	 */
	handleAccessToken(){
		// console.log('handleAccessToken: loginresponse', JSON.parse(JSON.stringify(this.loginResponse)));
		// console.log('user: handleAccessToken', JSON.parse(JSON.stringify(this.loginResponse['user'])));
		if(validateFor(ACCESS_TOKEN_KEY, this.loginResponse)){
			this.authenticationService.storeAccessToken();
		}
		/** Route to home only if token is present */
		if (this.authenticationService.accessToken) {
			this.disable = false;
			this.router.navigate([CUST_DASHBOARD_URL]);
			this.httpService.authenticatedAPI = true;
		} 
	}

	/**
     * Call login function with enableTwoFA set to false
     * @memberof LoginComponent
     */
	 skipForNow(){
        this.authenticationService
            .login(this.username, this.password, this.terminalNumbersObj, false)
            .then(res => {
                this.loginResponse = res;
                this.handleAccessToken();
            }).catch(err => {
                console.log('error: onEnableTwoFA', err);
                this.handleLoginError(err);
            })
    }

	/**
     * Show otp related fields by setting showTwoFABtns to false
     * and call handleLoginResponse
     */
	onEnableTwoFA(){
		this.inSecureUser = true;
		this.showTwoFABtns = false;
		this.OTP_EXPIRED_ERR_MSG = '';
		this.authenticationService
			.login(this.username, this.password, this.terminalNumbersObj, true)
			.then(res => {
				this.loginResponse = res;
				this.newOTPLoader = false;
				this.handleLoginResponse();
			}).catch(err => {
				this.newOTPLoader = false;
				console.log('error: onEnableTwoFA', err);
				this.handleLoginError(err);
			})
    }

	/**
	 * From login response 
	 * set otp timer
	 * set xLoginCoolDown and inSecureUser
	 * set twoFAtoken 
	 * @returns
	 * @memberof LoginComponent
	*/
	handleLoginResponse(){
		/** If user logs in with unregistered ip, twoFAToken will be present in response */
		if(validateFor(TWO_FA_TOKEN_KEY, this.loginResponse)){
			this.EMAIL_SENT_MESSAGE = AUTH_MESSAGES.EMAIL_SENT_MESSAGE;
			this.timeLeft = OTP_EXPIRY_INIT_TIME;
			this.startTimer();
			this.inSecureUser = true;
			this.xLoginCoolDown = validateFor(USER_KEY, this.loginResponse) && validateFor(XSCUSERS.xLoginCoolDown, this.loginResponse[USER_KEY])
				? this.loginResponse[USER_KEY][XSCUSERS.xLoginCoolDown]
				: '';
			this.twoFAtoken = this.loginResponse[TWO_FA_TOKEN_KEY];
			this.disable = false;
		}
    }

	/**
     * Check xInSecure bool of user
     * @returns boolean
     * @memberof LoginComponent
     */
	checkInsecureUser(){
        const user = validateFor(USER_KEY, this.loginResponse)
            ? this.loginResponse[USER_KEY]
            : {}
        let xInSecure = user.hasOwnProperty(XSCUSERS.xInSecure)
            ? user[XSCUSERS.xInSecure]
            : true;
        // console.log('insecure user: checkInsecureUser', xInSecure);
        return xInSecure;
    }

	/**
     * If user has no enableTwoFA property or enableTwoFA is set to true
     * return true, else return false
     * @returns boolean
     * @memberof LoginComponent
     */
	shouldEnableTwoFA(){
        return (
            (
                validateFor(USER_KEY, this.loginResponse) 
                && (!this.loginResponse[USER_KEY].hasOwnProperty(XSCUSERS.enableTwoFA) 
                || !this.loginResponse[USER_KEY][XSCUSERS.enableTwoFA])
            )
            || this.loginResponse[USER_KEY][XSCUSERS.enableTwoFA]
        )
    }

	//If terminal number is tampered, route to not-found page.
	routeToNotFoundPage(message: string = '') {
		this.notificationService.error(message, 'Error');
		this.router.navigateByUrl(NOT_FOUND_URL);
	}
}