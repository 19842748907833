import {
    Component,
    OnInit,
    Input,
    SimpleChanges,
    OnChanges /*, ChangeDetectorRef*/
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";

import { Address } from "../../../../../models";
import { BaseComponent } from "../base.component";
import { GlobalValidator } from "../../../../services";
@Component({
    selector: "app-address",
    templateUrl: "./address.component.html",
    styleUrls: ["./address.component.scss"]
})
export class AddressComponent extends BaseComponent
    implements OnInit, OnChanges {
    // @Input() parentAddress;
    @Input()
    addressContactValidate: boolean = true;
    @Input()
    address: Address;
    @Input()
    addressForm: FormGroup;
    @Input()
    serverErrors: any = {};
    @Input()
    tagType: string;
    constructor(public fb: FormBuilder /*, public cd: ChangeDetectorRef*/) {
        super();
        this.formErrors = {
            xAddr: {
                xCO: "",
                xLine1: "",
                xLine2: "",
                xSuite: "",
                xCity: "",
                xSOP: "",
                xPostCode: "",
                xCountry: ""
            }
        };
    }

    ngOnChanges(change: SimpleChanges) {
        // if (change.hasOwnProperty('addressForm') && !change.addressForm.firstChange) {
        //     this.addr.valueChanges.subscribe(data => this.onValueChanged(this.addressForm, data));
        // }
        if (change.hasOwnProperty("address") && !change.address.firstChange) {
            if (!this.addressForm.controls["xAddr"]) {
                this.addressForm.addControl(
                    "xAddr",
                    this.toFormGroup(new Address(this.address))
                );
            }
            this.addressForm.controls["xAddr"].patchValue(
                new Address(this.address)
            );
            if (this.tagType === "Company Business") {
                this.addr.controls["xCO"].disable();
                this.addr.controls["xLine1"].disable();
                this.addr.controls["xLine2"].disable();
                this.addr.controls["xSuite"].disable();
                this.addr.controls["xCity"].disable();
                this.addr.controls["xSOP"].disable();
                this.addr.controls["xPostCode"].disable();
                this.addr.controls["xCountry"].disable();
            }
        }

        // if(change.hasOwnProperty('parentAddress') && !change.parentAddress.firstChange){
        //     console.log("parent address in changes", JSON.parse(JSON.stringify(this.parentAddress)));
        //     if(!this.addressForm.controls['xAddr']){
        //         this.addressForm.addControl('xAddr', this.toFormGroup(new Address(this.parentAddress)));
        //     }
        //     this.addressForm.controls['xAddr'].patchValue(new Address(this.parentAddress));
        //     // if (this.tagType === 'Company Business') {
        //     //     this.addr.controls['xCO'].disable();
        //     //     this.addr.controls['xLine1'].disable();
        //     //     this.addr.controls['xLine2'].disable();
        //     //     this.addr.controls['xSuite'].disable();
        //     //     this.addr.controls['xCity'].disable();
        //     //     this.addr.controls['xSOP'].disable();
        //     //     this.addr.controls['xPostCode'].disable();
        //     //     this.addr.controls['xCountry'].disable();
        //     // }
        // }

        if (
            change.hasOwnProperty("serverErrors") &&
            !change.serverErrors.firstChange
        ) {
            this.validateServerErrors();
        }
    }

    ngOnInit() {
        // console.log("parent address", this.parentAddress);
        // if(this.parentAddress){
        //     this.addressForm.addControl('xAddr', this.toFormGroup(new Address(this.parentAddress)));
        // }
        // else{
        this.addressForm.addControl(
            "xAddr",
            this.toFormGroup(new Address(this.address))
        );
        // }
        // this.addr = this.toFormGroup(new Address(this.address));
        // this.addr.valueChanges.subscribe(data => this.onValueChanged(this.addressForm, data));

        this.onValueChanged(this.addressForm); // (re)set validation messages now
    }

    toFormGroup(data: Address) {
        const formGroup = this.fb.group({
            xCO: [data.xCO],
            xLine1: [data.xLine1],
            xLine2: [data.xLine2],
            xSuite: [data.xSuite],
            xCity: [data.xCity],
            xSOP: [data.xSOP],
            xPostCode: [data.xPostCode],
            xCountry: [data.xCountry ? data.xCountry : "US"]
        });
        if (this.addressContactValidate) {
            formGroup.controls["xLine1"].setValidators([Validators.required]);
            formGroup.controls["xCity"].setValidators([Validators.required]);
            formGroup.controls["xSOP"].setValidators([Validators.required]);
            formGroup.controls["xPostCode"].setValidators([
                Validators.required
            ]);
            formGroup.controls["xCountry"].setValidators([Validators.required]);
        }
        formGroup.controls["xCity"].setValidators([
            GlobalValidator.validateCity
        ]);
        formGroup.controls["xSOP"].setValidators([
            GlobalValidator.validateState
        ]);
        formGroup.controls["xPostCode"].setValidators([
            GlobalValidator.validatePostCode
        ]);
        formGroup.controls["xCountry"].setValidators([
            GlobalValidator.validateCountry
        ])
        return formGroup;
    }

    set addr(value: FormGroup) {
        this.addressForm.controls["xAddr"] = value;
    }

    get addr() {
        return this.addressForm.get("xAddr") as FormGroup;
    }

    onKeyPress(event: any) {
        GlobalValidator.numberCharacterFormat(event);
    }

    onPaste(event: any) {
        GlobalValidator.numberCharacterFormat(event);
    }
    // clearErrors(sectionName:string , errorName: string): void {
    //         console.log("errorName",errorName,this.serverErrors,this.formErrors,this.serverErrors['xMainContact.'+errorName]);
    //         if (this.serverErrors[sectionName+'.'+errorName]) {
    //             console.log("errorName2",errorName);
    //             delete this.serverErrors[sectionName+'.'+errorName];
    //             console.log('this.serverErrors',this.serverErrors);
    //         }
    //     }
}
