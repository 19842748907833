import { Address } from './address.model';
import { Social } from './social.model';
import { MainContact } from './main-contact.model';
import { DBAudit } from './dbAudit.model';
import { Structs } from './structs.model';
import { Limit } from './limit.model';

export class Business {
    xTntTag: string;
    xWall1EntyID: string;
    xWall2EntyID: string;
    xBusinessNum: string;
    xTag: string;
    xStatus: number;
    xName: string;
    // xPhone: string;
    xTypeTag: string;
    xNotes: string;
    xRefNum: string;
    xDBAName: string;
    xAKAName: string;
    xTZTag: any;
    xMainCcyTag: string;
    xAddr: Address;
    // xSocial: GeneralContact;
    xSocial: Social;
    // xFormalName:FormalName;
    xMainContact: MainContact;
    xSvcCompanyID: string;
    xStructCaps: any;
    xLimitCaps: any;
    xAsCustomerID: string;
    xOrgSWLcsTag: string;
    xDBAudit: DBAudit;
    xAutoFillMermNum:Boolean;
    xVTerminalShowExpandBtn:Boolean;

    constructor(obj?: any) {
        this.xTntTag = (obj && obj.xTntTag) || '';
        this.xWall1EntyID = (obj && obj.xWall1EntyID) || '';
        this.xWall2EntyID = (obj && obj.xWall2EntyID) || '';
        this.xBusinessNum = (obj && obj.xBusinessNum) || '';
        this.xTag = (obj && obj.xTag) || '';
        this.xStatus = (obj && obj.xStatus) || '';
        this.xName = (obj && obj.xName) || '';
        // // this.xPhone = (obj && obj.xPhone) || '';
        this.xTypeTag = (obj && obj.xTypeTag) || '';
        this.xNotes = (obj && obj.xNotes) || '';
        this.xRefNum = (obj && obj.xRefNum) || '';
        this.xDBAName = (obj && obj.xDBAName) || '';
        this.xAKAName = (obj && obj.xAKAName) || '';
        this.xTZTag = (obj && obj.xTZTag) || Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.xMainCcyTag = (obj && obj.xMainCcyTag) || '';
        this.xAddr = (obj && new Address(obj.xAddr)) || new Address();
        // this.xSocial = (obj && (new GeneralContact(obj.xSocial))) || new GeneralContact();
        this.xSocial = (obj && new Social(obj.xSocial)) || new Social();
        // this.xFormalName = (obj && new FormalName(obj.xFormalName)) || new FormalName();
        // this.xMainContact = (obj && new MainContact(obj.xMainContact)) || new MainContact();
        this.xMainContact=(obj && new MainContact(obj.xMainContact)) || new MainContact();
        this.xSvcCompanyID = (obj && obj.xSvcCompanyID) || '';
        this.xStructCaps = (obj && new Structs(obj.xStructCaps)) || new Structs();
        this.xLimitCaps = (obj && new Limit(obj.xLimitCaps)) || new Limit();
        this.xAsCustomerID = (obj && obj.xAsCustomerID) || '';
        this.xOrgSWLcsTag = (obj && obj.xOrgSWLcsTag) || '';
        this.xDBAudit = (obj && new DBAudit(obj.xDBAudit)) || new DBAudit();
        this.xAutoFillMermNum = (obj && obj.hasOwnProperty("xAutoFillMermNum") && typeof(obj.xAutoFillMermNum) !== 'undefined' ) ?  obj.xAutoFillMermNum :true;
        this.xVTerminalShowExpandBtn = (obj && obj.hasOwnProperty("xVTerminalShowExpandBtn") && typeof(obj.xVTerminalShowExpandBtn) !== 'undefined' ) ?  obj.xVTerminalShowExpandBtn :false;
        
    }
}
