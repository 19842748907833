import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    LoginStoreService,
    HttpService,
    AuthenticationService,
    UserHandlerService,
    NotificationService
} from "./../shared/services";
import {
    SetupStructureTerminalStore,
    SetupBusinessStructureStoreSetupStore
} from "../store";
@Component({
    selector: "app-login-as-customer",
    templateUrl: "./login-as-customer.component.html",
    styleUrls: ["./login-as-customer.component.scss"]
})
export class LoginAsCustomerComponent implements OnInit {
    // termNum: any;
    // terminalData: any;
    // storeData: any;
    // username: string = "";
    // password: string = "";
    // rememberMe: boolean = false;
    alive: boolean = true;
    fetchingData: boolean = true;

    // storeObj: any = {};

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public loginStoreService: LoginStoreService,
        public httpService: HttpService,
        private notificationService: NotificationService,
        public userHandlerService: UserHandlerService,
        private setupStructureTerminalStore: SetupStructureTerminalStore,
        private setupBusinessStructureStoreSetupStore: SetupBusinessStructureStoreSetupStore
    ) {}

    ngOnInit() {}
}
