import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TagInputModule } from "ngx-chips";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { LoginAsCustomerComponent } from "./login-as-customer.component";
import { RegisterCustomerComponent } from "./shared/components/register-customer/register-customer.component";
import { LoginAsCustomerRoutingModule } from "./login-as-customer-routing.module";
import { StoreInfoComponent } from "./shared/components/store-info/store-info.component";
import { CustomerLoginComponent } from "./shared/components/customer-login/customer-login.component";
import {
    SectionsModule,
    PermissionModalModule,
    PreviewPermissionModalModule
} from "../shared/modules";
import { SetupSecurityRolesStore, SetupPermissionsStore } from "../store";
import { LoginResolverService } from "../shared/resolver/login-resolver-service";
import { RecaptchaModule,RecaptchaFormsModule } from "ng-recaptcha";
import { SharedPipesModule } from "app/shared";
import { TimerFormatPipe } from "app/shared/pipes/timer-format.pipe";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TooltipModule.forRoot(),
        ModalModule,
        SectionsModule,
        TagInputModule,
        LoginAsCustomerRoutingModule,
        PermissionModalModule,
        PreviewPermissionModalModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        SharedPipesModule
    ],
    declarations: [
        RegisterCustomerComponent,
        LoginAsCustomerComponent,
        StoreInfoComponent,
        CustomerLoginComponent
    ],
    providers: [
        SetupSecurityRolesStore,
        SetupPermissionsStore,
        LoginResolverService,
        TimerFormatPipe
    ]
})
export class LoginAsCustomerModule {}
