import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormArray,
    FormControl
} from "@angular/forms";
import { forkJoin } from "rxjs";
import { takeWhile, skip } from "rxjs/operators";
import { TAGSCONSTS } from "../../../../../../constants/tags.constants";

import {
    BreadcrumbService,
    NotificationService,
    BusinessService,
    GlobalValidator,
    XpaConstsService,
    XsaConstsService,
    HttpService,
    TreeService,
    UrlStoreService,
    TabStructureService,
    UserHandlerService,
    ActionWorkerService
} from "../../../../../../shared/services";
import {
    SetupPaymentProcessingMerchantAccountStore,
    SetupBusinessStructureStore,
    ConstantStore,
    TransactionsStore,
    SetupStructureProfitCenterStore,
    SetupBusinessStructureStoreSetupStore
} from "../../../../../../store";
import { DBAudit } from "app/models";
import { validateFor } from "../../../../../../common-functions";
import { NO_PERMISSION_MSG } from "../../../../../../constants";
import { customSwal } from "../../../../../../constants";
// import { getParentRenderElement } from "@angular/core/src/view/util";

const batchTimeParamKey = "BatchTime";
const batchTimeParamVal = "9999EST";
const TPPID_VAL = 'R91001';
const RAPID_CONN_TPPID_TAG = 'param.rapidconnect.tppid';

const NO_JOB_ID_MSG = 'Unable to fetch action information. Please contact support.';	
const GENERAL_FALL_BACK_MSG = 'Something went wrong. Could not perform selected action. Please contact support.';

const STORE_ID_NOT_SET_ERROR: string = 'Could not set Store ID. Please try again.';
const AUTO_BATCH_TIME_INFO: string = 'Set value as 9999EST, If you want to disable auto batch.';
const TPP_ID_INFO: string = `Set default value to ${TPPID_VAL}`;
const ENABLE_PROCESSOR_CHAGNE = 'This will enable processor change even if there are outstanding transactions underneath.';
const DISABLE_PROCESSOR_CHANGE = 'You are disabling the processor change. You can enable it later.';
@Component({
    selector: "add-edit",
    templateUrl: "./add-edit.component.html",
    styleUrls: ["./add-edit.component.scss"]
})
export class SetupStructureMerchantAccountComponent
    implements OnInit, OnDestroy {
    bizID: string = ""; //for storing the businessID fetched via service
    storeId: string = "";
    nodePath: any[] = []; //For storing the path where the payment device belongs
    merchantInfo: any; //For storing value retrieved from server in edit mode
    serverErrors: any; //Stores errors returned by server
    form: FormGroup; //Required for reactive form validation
    submitting: boolean = false; //For handling form submission
    edit: boolean = false; //add/edit mode
    formErrors = {
        xName: "",
        xTag: "",
        xRefNum: "",
        xMerAcctNum: ""
    };
    loading: boolean = false;
    tag: string;
    xRefNumEditMode: string = "";
    // businessID: string = '';

    mediumTagList: any[] = []; //For storing list of medium tags
    brandTagList: any[] = []; //For storing list of brand tags
    mappingBrandTagList: any[] = []; //For storing list of card brand tags (For mapping)
    transactionTagList: any[] = []; //For storing list of transaction tags
    processorList: any[] = []; //For storing list of processor
    profitCenterList: any[] = []; //For storing list of profit center
    captItemList: any[] = []; //For storing capture item list in edit mode
    captItem: any = [];
    storeName: string;
    pctrName: string;
    procParamName: any = {};
    alive: boolean = true;
    chainName: string;
    regionName: string;
    propName: string;

    bizChangeEvent: boolean = false;
    showOverLay: boolean = false; // controls overlay when save button is clicked
    processorPlaceholder: string[] = [];

    //Controls the checkbox of acceptance section
    isAllAcceptanceTagsChecked: any = {
        medium: false,
        brand: false,
        transaction: false
    };

    //Controls the checkbox of mapping section
    isMappingTagsChecked: any = {
        profitCenter: false,
        medium: false,
        brand: false
    };
    numberKey: string = "xMerAcctNum";
    parentMode: boolean = false;
    showProcessorLock = false; //boolean controller for displaying lock button on processor
    enableProcessorChange = false; //boolean controller for enable/disabling processor change
    AUTO_BATCH_TIME_INFO: string = AUTO_BATCH_TIME_INFO;
    batchTimeParamKey: string = batchTimeParamKey;
    RAPID_CONN_TPPID_TAG: string = RAPID_CONN_TPPID_TAG;
    TPP_ID_INFO: string = TPP_ID_INFO;
    canEdit = true;
    canAdd = true;
    noPermMsg = NO_PERMISSION_MSG;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private notificationService: NotificationService,
        private businessService: BusinessService,
        private setupPaymentProcessingMerchantAccountStore: SetupPaymentProcessingMerchantAccountStore,
        private setupBusinessStructureStore: SetupBusinessStructureStore,
        private fb: FormBuilder,
        private xpaConstsService: XpaConstsService,
        private httpService: HttpService,
        public treeService: TreeService,
        public urlStoreService: UrlStoreService,
        public transactionsStore: TransactionsStore,
        private tabStructureService: TabStructureService,
        private setupStructureProfitCenterStore: SetupStructureProfitCenterStore,
        private setupBusinessStructureStoreSetupStore: SetupBusinessStructureStoreSetupStore,
        public userHandlerService: UserHandlerService,
        private actionWorkerService: ActionWorkerService
    ) {
        //   this.router.routeReuseStrategy.shouldReuseRoute = function(){
        //     return false;
        //  }
        this.merchantInfo = {};
        this.serverErrors = {};
    }

    ngOnInit() {
        console.log('merchant-acct-init: init called');
        this.setupPerms();
        this.enableProcessorChange = false;
        //TODO-: Check use of this function
        // ******************************************************************************************
        this.tabStructureService.checkAndSetTabNameToDef(
            this.tabStructureService.tabMode,
            this.tabStructureService.isCancelClick,
            this.tabStructureService.isSubmitClick
        );
        this.urlStoreService.submitAction = false;

        this.setBreadCrumbValue();
        this.nodePath = this.setupBusinessStructureStore.getNodePath();
        // ******************************************************************************************

        // incase of edit mode we get merchant id here
        this.route.params.forEach(async (params: Params) => {
            console.log('merchant-acct-init: router');
            console.log('merchant-acct-init: isMappingTagsChecked',this.isMappingTagsChecked);
            this.edit = false;
            this.initValidation();
            if (params.hasOwnProperty("Id") && params.Id) {
                this.edit = true;
                this.loading = true;
                if (
                    !this.treeService.merchantAccID.getValue() &&
                    !this.tabStructureService.tabMode
                ) {
                    console.log(
                        "!this.treeService.merchantAccID.getValue() && !this.tabStructureService.tabMode && this.tabStructureService.editMode",
                        !this.treeService.merchantAccID.getValue() &&
                            !this.tabStructureService.tabMode &&
                            this.tabStructureService.editMode
                    );
                    this.treeService.setId(params.Id, "merchant_account");
                }
                // else{
                this.merchantInfo = await this.editModeActions(params);
                console.log("Merchant Account : Route Params Change Event : merchantInfo ===>", this.merchantInfo);
                this.storeID.setValue(this.merchantInfo["xStoreID"]);
                this.pctrID.setValue(this.merchantInfo["xMainPCtrID"]);
                // this.setPctrID();
                this.fetchData(params);
                // }
                // else {
                //   console.log("fetchData is called from here");
                //   this.getTabStructure(params.Id).then(res => {
                //     console.log("fetchData is called from here .then1");
                //     this.fetchData(params);
                //   })
                // }
            } else {
                console.log("Here params else");
                // this.fetchData(params);
                // this.getParentDetails().then(res => {
                //     this.fetchData(params);
                // });
            }
            console.log("Here we come");
            // if(!this.edit)
            //     this.addModeActions();
            // this.nodePath = this.setupBusinessStructureStore.getNodePath();
        });
        if (this.tabStructureService.tabMode) {
            this.checkParentMode();
        }
        this.setTabNameNewMerAcct();
        console.log("Here we come 2");
        this.setupBusinessStructureStore.watchNodePath$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe((nodePath: any[]) => {
                console.log("Here watcher");
                console.log('merchant-acct-init-watcher');
                this.nodePath = nodePath;
                if (this.treeService.merchantAccID.getValue()) {
                    console.log('merchant-acct-init-watcher: skip call as edit');
                    // this.edit = true;
                    // this.initValidation();
                    // this.fetchData({
                    //     Id: this.treeService.merchantAccID.getValue()
                    // });
                }else{
                    console.log('merchant-acct-init-watcher: set add mode actions');
                    this.edit = false;
                    this.addModeActions();
                }
                console.log('merchant-acct-init-watcher: isMappingTagsChecked',this.isMappingTagsChecked);
                this.parentMode = false;
                // this.checkParentMode();
                // this.setTabNameNewMerAcct();
            });

        this.tabStructureService.watchParentFetched$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(res => {
                console.log("Here in watcher of parent fetched", res);
                if (res) {
                    console.log("Here in watcher of parent fetched res true");
                    this.setTabNameNewMerAcct();
                }
            });
        console.log("Here we come 3");
        // if (!this.edit) {
        //     this.addModeActions(); //checks if in add mode or not and then performs required actions
        // }
        this.businessService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizId => {
                if (bizId) {
                    this.bizChangeEvent = true;
                } else {
                    this.router.navigate([
                        "/dashboard/setup/business/structure/information"
                    ]);
                }
            });
    }

    checkParentMode() {
        // this.parentMode=false;
        if (this.tabStructureService.tabLabelItems.length) {
            this.tabStructureService.tabLabelItems.map(labelItem => {
                if (labelItem.label.includes("Merchant")) {
                    if (
                        labelItem.hasOwnProperty("type") &&
                        labelItem["type"] === "Parent"
                    ) {
                        this.parentMode = true;
                    } else {
                        this.parentMode = false;
                    }
                }
            });
        }
    }

    setTabNameNewMerAcct() {
        if (!this.edit) {
            console.log(
                "%c Add mode hai k??",
                "background: blue; color: white"
            );
            this.addModeActions();
            if (this.tabStructureService.tabLabelItems.length) {
                // console.log('%c Add mode hai k??', 'background: pink; color: white');
                this.tabStructureService.tabLabelItems.map(labelItem => {
                    // console.log('%c Add mode hai k??', 'background: grey; color: black', labelItem);
                    if (labelItem.label.includes("Merchant")) {
                        if (labelItem.hasOwnProperty("type")) {
                            labelItem["label"] = "Merchant Account";
                            labelItem["type"] = "New";
                            labelItem["name"] = "";
                        }
                    }
                });
                console.log(
                    "%c Merchant Add mode hai k??",
                    "background: grey; color: black",
                    this.tabStructureService.tabLabelItems
                );
                this.tabStructureService.setTabStructureNames(
                    this.tabStructureService.tabLabelItems
                );
            }
        }
    }

    editModeActions(params) {
        return new Promise((resolve, reject) => {
            this.setupPaymentProcessingMerchantAccountStore
                .get(params["Id"])
                .subscribe(
                    res => {
                        console.log("res-------->", res);
                        resolve(res.data);
                    },
                    err => reject(err)
                );
        });
    }

   async addModeActions() {
        console.log("add mode action");
        this.bizID = this.businessService.businessID.getValue();
        this.form.controls["xWall2EntyID"].setValue(this.bizID);
        this.setBusinessID();
        this.setPctrID();
        try{
            let storeID = await this.setStoreId();
            console.log("store id:addModeActions: after await---->",storeID)
            if(!storeID){
                this.notificationService.error(STORE_ID_NOT_SET_ERROR, 'Error');
                return;
            }
            this.storeID.setValue(storeID)
        }catch(err){
            this.handleErrorResponse(err);
            console.log("err:addModeActions----> ",err)
        }
        this.fetchData();

    }

    fetchData(params?: any) {
        console.log("***********FETCH DATA CALLED******************");
        console.log(
            "Inside fetchData() mer: check store id",
            params,
            this.storeID.value.length,
            this.pctrID.value.length
        );

        if (this.storeID.value.length === 0) {
            console.log("Inside fetchData(): node path---->", this.nodePath);
            const storeId = this.nodePath
                .filter(node => node.type === "store")
                .reduce((previous, node) => node.id, "");
                console.log("store id is set from nodePath:fetchData ",storeId)
                console.log("*****************store id set from here: nodePath *******")
                if(storeId){
                    this.storeID.setValue(storeId);
                }
                console.log("store id: fetchData -------->",this.treeService.storeID.getValue())
        }
        if (this.pctrID.value.length === 0) {
            console.log("Inside fetchData() mer 2", this.nodePath);
            const pctrId = this.nodePath
                .filter(node => node.type === "profit_center")
                .reduce((previous, node) => node.id, "");
            if (pctrId) this.pctrID.setValue(pctrId);
            else
                this.pctrID.setValue(
                    this.treeService.profitCenterID.getValue()
                );
        }
        //Get Contants
        console.log("this.pctrID", this.pctrID.value);
        console.log(
            "this.storeId",
            JSON.parse(JSON.stringify(this.storeID.value))
        );
        const observables = [
            this.setupStructureProfitCenterStore
                .get(this.pctrID.value),
            this.setupPaymentProcessingMerchantAccountStore
                .getAll({ xMainPCtrID: this.pctrID.value }),
            this.xpaConstsService
                .getXpaItems("brand"),
            this.xpaConstsService
                .getXpaItems("txnact"),
            this.httpService
                .getAll("xpaProcs", {}, true)
        ];

        if (this.edit) {
            if (
                !this.treeService.merchantAccID.getValue() &&
                ((this.tabStructureService.editMode ||
                    this.treeService.nodeClick) &&
                    !this.tabStructureService.tabMode)
            ) {
                console.log("Inside If");
                if (params && params.hasOwnProperty("Id"))
                    this.treeService.setId(params.Id, "merchant_account");
            }
            // observables.push(

            // );
            observables.push(
                this.xpaConstsService
                    .getXpaItems("capt")
            );
            observables.push(
                this.transactionsStore
                    .getAll({ xPCtrID: this.pctrID.value })
            );
            observables.push(
                this.setupBusinessStructureStoreSetupStore
                    .get(this.storeID.value)
            );
        }

        forkJoin(observables).subscribe(
            (response: any) => {
                // this.mediumTagList = response[1].data /*.map(tag => tag.xName)*/;
                this.pctrName = response[0].data.xName;
                this.brandTagList = [];
                this.mappingBrandTagList = [];
                this.brandTagList = response[2].data.filter(tag => tag.xStatus);
                console.log(
                    "this.brandtag fetched with 1 status",
                    JSON.parse(JSON.stringify(this.brandTagList))
                );
                this.mappingBrandTagList = this.brandTagList.filter(
                    brandTag => {
                        return brandTag;
                    }
                );
                // this.mappingBrandTagList.filter(tag=> tag.xStatus);
                response[2].data.map(brandTag => {
                    if (response[1].data.length) {
                        response[1].data.map(merData => {
                            // else{
                            if (this.edit) {
                                if (
                                    params &&
                                    params.hasOwnProperty("Id") &&
                                    !(merData._id === params["Id"])
                                ) {
                                    if (
                                        merData.xMapping.xBrandTags.length ===
                                            1 &&
                                        merData.xMapping.xBrandTags[0] === "all"
                                    ) {
                                        this.mappingBrandTagList = [];
                                    } else {
                                        this.updateMappingBrandTagList(
                                            merData,
                                            brandTag
                                        );
                                    }
                                }
                            } else {
                                console.log(
                                    "merData.xMapping.xBrandTags",
                                    merData.xMapping.xBrandTags
                                );
                                if (
                                    merData.xMapping.xBrandTags.length === 1 &&
                                    merData.xMapping.xBrandTags[0] === "all"
                                ) {
                                    this.mappingBrandTagList = [];
                                } else {
                                    this.updateMappingBrandTagList(
                                        merData,
                                        brandTag
                                    );
                                }
                            }
                            // }
                        });
                    }
                });
                this.transactionTagList =
                    response[3].data /*.map(tag => tag.xName)*/;
                console.log("this.processorList -1", response[4].data);
                this.processorList = response[4].data.filter(proc => {
                    if (proc.xAcptCaps.xAcptIndstTags.length) {
                        if (
                            proc.xAcptCaps.xAcptIndstTags.indexOf(
                                response[0].data.xIndstTag
                            ) >= 0
                        ) {
                            return proc;
                        } else {
                            return;
                        }
                    } else {
                        return proc;
                    }
                });
                console.log("this.processorList 0", this.processorList);
                this.processorList = this.processorList.filter(
                    tag => tag.xStatus
                );
                console.log("this.processorList 1", this.processorList);
                
                this.isAllAcceptanceTagsChecked.brand = true;
                // tagsArray = brandTags;
                // this.form.controls.xAcptCaps.get('xAcptBrandTags').setValue(['all']);
                this.addAllTags(
                    this.brandTagList,
                    this.acceptedBrandTags,
                    this.isAllAcceptanceTagsChecked.brand
                );
                this.isAllAcceptanceTagsChecked.transaction = true;
                // tagsArray = brandTags;
                // this.form.controls.xAcptCaps.get('xAcptTxnActTags').setValue(['all']);
                this.addAllTags(
                    this.transactionTagList,
                    this.acceptedTransactionTags,
                    this.isAllAcceptanceTagsChecked.transaction
                );
                if (this.edit) {
                    // this.merchantInfo = response[6].data;
                    this.storeName = response[7].data.xName;
                    this.captItemList = response[5].data.filter(
                        tag => tag.xStatus
                    );
                    // this.txnList = response[8].data;
                    // if(response[8].data.length > 0){
                    //   this.disableProcessor=true;
                    // }
                    // else{
                    //   this.disableProcessor=false;
                    // }
                    console.log("this.captItemList", this.captItemList);
                    console.log("data", this.merchantInfo);
                    this.setFormValues(this.merchantInfo);
                    this.setAcceptanceAndMappingField(
                        // this.mediumTagList,
                        this.brandTagList,
                        this.mappingBrandTagList,
                        this.transactionTagList,
                        this.profitCenterList
                    );
                    this.captItem = this.captItemList.filter(
                        item => item.xTag === this.merchantInfo.xCaptTag
                    );
                    console.log(
                        "This.captItem inside observable for edit mode",
                        this.captItem
                    );
                    if (
                        this.captItem &&
                        this.captItem.length &&
                        this.captItem[0]
                    ) {
                        this.form.controls["xCaptTag"].setValue(
                            this.captItem[0].xName
                        );
                    }
                    if (
                        this.form &&
                        this.form.value.hasOwnProperty("xProcTag")
                    ) {
                        if (response[6].data.length > 0) {
                            this.showProcessorLock = true;
                            this.enableProcessorChange = false;
                            this.form.get("xProcTag").disable();
                        } else {
                            this.showProcessorLock = false;
                            this.enableProcessorChange = true;
                            this.form.get("xProcTag").enable();
                        }
                    }
                    this.loading = false;
                }
            },
            (error: any) => {
                this.handleErrorResponse(error);
                this.urlStoreService.routeBackToPreviousUrl();
            },
            () => {}
        );
    }

    updateMappingBrandTagList(merData: any, brandTag: any) {
        if (
            merData.hasOwnProperty("xMapping") &&
            merData.xMapping.hasOwnProperty("xBrandTags") &&
            merData.xMapping.xBrandTags.length
        ) {
            if (merData.xMapping.xBrandTags.indexOf(brandTag.xTag) !== -1) {
                this.mappingBrandTagList.map((mapBrandTag, index) => {
                    if (mapBrandTag === brandTag) {
                        this.mappingBrandTagList.splice(index, 1);
                    }
                });
            }
        }
    }

    // EDIT-: Added default Value for PCTR List as it is not used for now, under mapping section.
    setAcceptanceAndMappingField(
        // mediumTags: any[],
        brandTags: any[],
        mappingBrandTag: any[],
        transactionTags: any[],
        profitCenters: any[] = []
    ) {
        console.log('setAcceptanceAndMappingField: merchantInfo ---->',this.merchantInfo);
        let tagsArray: any[] = [];
        this.isMappingTagsChecked= {
            profitCenter: false,
            medium: false,
            brand: false
        };
        const { xAcptCaps } = this.merchantInfo;

        if (xAcptCaps.xAcptBrandTags && xAcptCaps.xAcptBrandTags.length) {
            if (xAcptCaps.xAcptBrandTags[0] !== "all") {
                tagsArray = this.getObjectsPresentInArray(
                    brandTags,
                    xAcptCaps.xAcptBrandTags
                );
            } else {
                this.isAllAcceptanceTagsChecked.brand = true;
                tagsArray = brandTags;
            }
            this.setAcceptanceTags(this.acceptedBrandTags, tagsArray);
        }

        if (xAcptCaps.xAcptTxnActTags && xAcptCaps.xAcptTxnActTags.length) {
            if (xAcptCaps.xAcptTxnActTags[0] !== "all") {
                tagsArray = this.getObjectsPresentInArray(
                    transactionTags,
                    xAcptCaps.xAcptTxnActTags
                );
            } else {
                this.isAllAcceptanceTagsChecked.transaction = true;
                tagsArray = transactionTags;
            }
            this.setAcceptanceTags(this.acceptedTransactionTags, tagsArray);
        }
        const { xMapping } = this.merchantInfo;
        console.log('setAcceptanceAndMappingField: xMapping.xBrandTags',xMapping.xBrandTags);
        if (xMapping.xBrandTags && xMapping.xBrandTags.length) {
            if (xMapping.xBrandTags[0] !== "all") {
                console.log('setAcceptanceAndMappingField: set as false');
                tagsArray = this.getObjectsPresentInArray(
                    mappingBrandTag,
                    xMapping.xBrandTags
                );
                // this.isMappingTagsChecked.brand = false;
            } else {
                console.log('setAcceptanceAndMappingField: set as true');
                this.isMappingTagsChecked.brand = true;
                tagsArray = mappingBrandTag;
            }
            this.setAcceptanceTags(this.mappedBrandTags, tagsArray);
        }
        console.log("xMappings", xMapping);

        // this.addAllTags(this.brandTagList, this.acceptedBrandTags, this.isAllAcceptanceTagsChecked.brand=true);
        // this.addAllTags(this.transactionTagList, this.acceptedTransactionTags, this.isAllAcceptanceTagsChecked.transaction);
    }

    //Inserts Tags in FormArray
    setAcceptanceTags(acceptedTags: FormArray, tags: any[]) {
        console.log(
            "Inside accepted tag",
            JSON.parse(JSON.stringify(acceptedTags.value)),
            JSON.parse(JSON.stringify(tags))
        );
        tags.forEach((tag: any) => {
            acceptedTags.push(new FormControl(tag));
        });
    }

    //Returns an array of objects whose xTag matches the list of provided xTag
    getObjectsPresentInArray(tagList: any[], tags: string[]) {
        return tagList.filter(tagObj => {
            return tags.findIndex(t => t === tagObj.xTag) >= 0 ? true : false;
        });
    }

    //TODO Use xTag instead of xTypeTag
    //Returns an array of objects whose xTypeTag matches the list of provided xTag

    // Updated this function it now uses xShortCode instead of xTypeTag or xTag or _id
    // getObjectsBySC(pctrList: any[], pctrSCs: string[]) {
    //   return pctrList.filter(pctrObj => {
    //     return pctrSCs.findIndex(t => t === pctrObj.xShortCode) >= 0 ? true : false;
    //   });
    // }

    //Function which returns an array of objects whose _id matches the list of provided ids
    getObjectsByID(pctrList: any[], pctrIDs: string[]) {
        return pctrList.filter(pctrObj => {
            return pctrIDs.findIndex(t => t === pctrObj._id) >= 0
                ? true
                : false;
        });
    }

    setBreadCrumbValue(): void {
        const breadCrumb: any = {};
        breadCrumb.root = "Setup";
        breadCrumb.child = "Business";
        breadCrumb.subChild = "Structure";
        breadCrumb.subChildLink = "setup/business/structure/information";
        breadCrumb.grandChild = "Merchant-Account-Setup";
        breadCrumb.rootList = [];
        this.breadcrumbService.setBreadCrumb(breadCrumb);
    }

    initValidation(): void {
        this.form = this.fb.group({
            xTntTag: ["tnttag", []],
            xWall1EntyID: ["", []],
            xWall2EntyID: [this.bizID, []],
            xMerAcctNum: ["", []],
            xTag: [/*{ value: "payMer", disabled: true }*/ "", []],
            xStatus: ["1", []],
            xName: ["", [Validators.required, GlobalValidator.validateMerchantName]],
            xTypeTag: [TAGSCONSTS.merchantAccountTags.regularMerAcct, []],
            xNotes: ["", []],
            xRefNum: ["", []],
            xBusinessID: ["", []],
            xStoreID: ["", []],
            // xGMKey: ["", []],
            // xGMPwd: ["", []],
            xProcTag: ["", []],
            xCaptTag: ["", []],
            xIndstTag: ["", []],
            xAttnTag: ["", []],
            xPresTag: ["", []],
            xMainTerminalNum: ["", []],
            xAcptCaps: this.fb.group({
                xAcptMdmTags: this.fb.array([]),
                xAcptBrandTags: this.fb.array([]),
                xAcptTxnActTags: this.fb.array([])
            }),
            xMainPCtrID: ["", []],
            xProcParams: this.fb.array([]),
            xMapping: this.fb.group({
                // xPCtrIDs: this.fb.array([]),
                xPCtrShortCodes: this.fb.array([]),
                xMdmTags: this.fb.array([]),
                xBrandTags: this.fb.array([])
            }),
            xDBAudit: this.fb.group(
                new DBAudit({
                    xDataSrc: TAGSCONSTS.dataSourceTags.dataSrcItemTags.self
                })
            )
        });
    }

    setStoreId() {
        return new Promise((resolve, reject) => {
            console.log("object inside store", this.nodePath);
        const storeObj: any = this.nodePath.filter(
            node => node.type === "store"
        );
        const chainObj: any = this.nodePath.filter(
            //For tab name
            node => node.type === "chain"
        );
        const regionObj: any = this.nodePath.filter(
            //For tab name
            node => node.type === "region"
        );
        const propObj: any = this.nodePath.filter(
            //For tab name
            node => node.type === "property"
        );
        //If case: When add mode is opened from tree
        //Else case: No nodepath is set for store 
        //Steps to reproduce else case: Select home, setup-structure, list of pctrs from Tab, pctr edit, list of mer-acct from tab, click on Add
        if (storeObj.length) {
            console.log("*****************store id set from here: storeObj *******", storeObj)
            this.storeID.setValue(storeObj[0].id);
            this.storeName = storeObj[0].name;
            return resolve(this.storeID.value);
        } else {
                this.tabStructureService.defaultTabStructureLabel.map(
                    singleLabel => {
                        if (
                            singleLabel &&
                            singleLabel.hasOwnProperty("label") &&
                            singleLabel.hasOwnProperty("name") &&
                            singleLabel.label === "Store"
                        ) {
                            this.storeName = singleLabel.name.substring(
                                3,
                                singleLabel.name.length
                            );
                        }
                    }
                );
                console.log("inside else: pctr value--->",this.treeService.profitCenterID.getValue())
                if (this.treeService.profitCenterID.getValue()) {
                    this.setupStructureProfitCenterStore
                        .get(this.treeService.profitCenterID.getValue())
                        .subscribe(res => {
                            console.log("res:setStoreId--->",res)
                            let storeID =  res && res.hasOwnProperty('data') && res.data.hasOwnProperty('xStoreID') && res.data.xStoreID ? res.data.xStoreID: null;
                            // if(!storeID){
                            //     return reject(storeID);
                            // } 
                            console.log("*****************store id set from here: setupStructureProfitCenterStore get*******")
                            if(storeID)
                                this.storeID.setValue(storeID);
                           
                            console.log(" store id: before invoking fetchData: check store id--->", this.storeID.value)
                            return resolve(this.storeID.value);
                            // console.log("store id is set from res:fetchData ",storeID)

                            // this.fetchData();
                        }, err => {
                            console.log("err",err)
                            return reject(err);
                        });
                }
        }
        this.chainName = chainObj.length ? chainObj[0].name : ""; //For tab name
        this.regionName = regionObj.length ? regionObj[0].name : ""; //For tab name
        this.propName = propObj.length ? propObj[0].name : ""; //For tab name

        //  else {
        //   this.notificationService.error("Cannot alter this store merchant", "ERROR");
        //   this.routeBackToBusinessStructure();
        // }
        })
        
    }

    setBusinessID() {
        const bizObj: any = this.nodePath.filter(
            node => node.type === "business"
        );
        if (bizObj.length) {
            if (this.bizID === bizObj[0].id) {
                this.businessID.setValue(bizObj[0].id);
            } else {
                if (this.bizID) {
                    this.businessID.setValue(this.bizID);
                } else {
                    this.notificationService.error(
                        "Cannot modify this business",
                        "ERROR"
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            }
        } else {
            this.businessID.setValue(this.bizID);
        }
    }
    setPctrID(obj?: any) {
        console.log("object inside pctr", obj);
        if (obj && obj.hasOwnProperty("pCtr")) {
            this.pctrID.setValue(obj.pCtr.id);
            this.tabStructureService.defaultTabStructureLabel.map(
                singleLabel => {
                    if (
                        singleLabel &&
                        singleLabel.hasOwnProperty("label") &&
                        singleLabel.hasOwnProperty("name") &&
                        singleLabel.label === "Profit"
                    ) {
                        this.pctrName = singleLabel.name.substring(
                            3,
                            singleLabel.name.length
                        );
                    }
                }
            );
        } else {
            const pctrObj: any = this.nodePath.filter(
                node => node.type === "profit_center"
            );
            // if (pctrObj.length) {
            if (pctrObj.length) {
                this.pctrID.setValue(pctrObj[0].id);
                this.tabStructureService.defaultTabStructureLabel.map(
                    singleLabel => {
                        if (
                            singleLabel &&
                            singleLabel.hasOwnProperty("label") &&
                            singleLabel.hasOwnProperty("name") &&
                            singleLabel.label === "Profit"
                        ) {
                            this.pctrName = singleLabel.name.substring(
                                3,
                                singleLabel.name.length
                            );
                        }
                    }
                );
            } else {
                if (this.treeService.profitCenterID.getValue()) {
                    this.pctrID.setValue(
                        this.treeService.profitCenterID.getValue()
                    );
                    this.tabStructureService.defaultTabStructureLabel.map(
                        singleLabel => {
                            if (
                                singleLabel &&
                                singleLabel.hasOwnProperty("label") &&
                                singleLabel.hasOwnProperty("name") &&
                                singleLabel.label === "Profit"
                            ) {
                                this.pctrName = singleLabel.name.substring(
                                    3,
                                    singleLabel.name.length
                                );
                            }
                        }
                    );
                }
                // else{
                //     this.notificationService.error(
                //         "Cannot modify this Profit Center",
                //         "ERROR"
                //     );
                //     this.urlStoreService.routeBackToPreviousUrl();
                // }
            }
        }
    }

    setFormValues(data: any): void {
        this.form.patchValue({
            xTntTag: data.xTntTag,
            xWall1EntyID: data.xWall1EntyID,
            xWall2EntyID: data.xWall2EntyID,
            xMerAcctNum: data.xMerAcctNum,
            xTag: data.xTag,
            xStatus: data.xStatus,
            xName: data.xName,
            xTypeTag: data.xTypeTag,
            xNotes: data.xNotes,
            xRefNum: data.xRefNum,
            xBusinessID: data.xBusinessID,
            xStoreID: data.xStoreID,
            // xGMKey: data.xGMKey,
            // xGMPwd: data.xGMPwd,
            xMainPCtrID: data.xMainPCtrID,
            xProcTag: data.xProcTag,
            xCaptTag: data.xCaptTag,
            xIndstTag: data.xIndstTag,
            xAttnTag: data.xAttnTag,
            xPresTag: data.xPresTag,
            xMainTerminalNum: data.xMainTerminalNum,
            xDBAudit: data.xDBAudit
                ? data.xDBAudit
                : TAGSCONSTS.dataSourceTags.dataSrcItemTags.self
        });
        console.log("Data====>", data);

        if (data && data.xProcParams.constructor === Array) {
            while (this.procParams.length) {
                this.procParams.removeAt(0);
            }
            const processorSelected = this.getAppliedProcessor(this.processorList, data.xProcTag);
            this.processorPlaceholder = [];
            // console.log('setFormValues:processorSelected', processorSelected);
            if (processorSelected && 
                processorSelected.hasOwnProperty('xProcParamDefs') && 
                processorSelected.xProcParamDefs.length) {
                    processorSelected.xProcParamDefs.forEach(processorField => {
                        // console.log('setFormValues:processorField', processorField);
                        let params = data.xProcParams.find(field => {
                            if (field.xParamTag === processorField.xTag) return true;
                        });
                        this.processorPlaceholder.push('');
                        // console.log('setFormValues:pre-params', params ? JSON.parse(JSON.stringify(params)) : params);
                        if (!params) {
                            // console.log('setFormValues:!params');
                            params = {
                                xParamTag: processorField.xTag,
                                xParamKey: processorField.xKey,
                                xParamVal: ''
                            };
                            if (processorField.xKey === batchTimeParamKey) {
                                params.xParamVal = '';
                                this.processorPlaceholder.pop();
                                this.processorPlaceholder.push(batchTimeParamVal);
                            }
                        }
                        // console.log('setFormValues:post params', JSON.parse(JSON.stringify(params)));
                        this.procParams.push(
                            this.fb.group({
                                xParamTag: [params.xParamTag],
                                xParamKey: [params.xParamKey],
                                xParamVal: [params.xParamVal]
                            })
                        );
                    });
                    console.log('processorPlaceholder', this.processorPlaceholder);
                }
            console.log("HERE HERE", processorSelected);
            if (processorSelected !== null) {
                console.log("HERE HERE HERE");
                processorSelected.xProcParamDefs.forEach(config => {
                    this.setProcParamNames(config);
                });
            }
        }
        if (this.edit) {
            this.form.controls["xRefNum"].disable();
            this.xRefNumEditMode = data.xRefNum;
        } else {
            this.form.controls["xRefNum"].enable();
        }
    }

    processorSelected(event: any) {
        console.log("event.target.value", event.target.value);
        const processorSelected = this.getAppliedProcessor(
            this.processorList,
            event.target.value
        );
        if (processorSelected !== null) {
            this.xpaConstsService.getXpaItems("capt").subscribe(
                (res: any) => {
                    this.setProcessorParams(processorSelected.xProcParamDefs);
                    this.captItem = [];
                    this.captItem = res.data.filter(
                        captItem => captItem.xTag === processorSelected.xCaptTag
                    );
                    this.form.controls["xCaptTag"].setValue(
                        this.captItem[0].xName
                    );
                },
                (err: Error) => {
                    console.log("Error while fetching capture tag", err);
                    this.form.controls["xProcTag"].setValue("");
                    this.form.controls["xCaptTag"].setValue("");
                    this.procParams.removeAt(0);
                    this.notificationService.error(
                        "Unable to fetch Capture Mode for the selected processor. Please select the processor again.",
                        "Error"
                    );
                }
            );
        }
    }

    //Search for processor in processorList with search key a xTag
    getAppliedProcessor(processorList: any[], processor: string) {
        console.log("processorList processor", processorList, processor);
        for (let i = 0; i < processorList.length; i++) {
            if (processorList[i].xTag === processor) {
                return processorList[i];
            }
        }
        return null;
    }

    //Set Configuration Params of processor selected
    setProcessorParams(configParams: any[]) {
        while (this.procParams.length) {
            this.procParams.removeAt(0);
        }
        configParams.forEach((config: any) => {
            console.log("ConfigParams", config);
            let initParamVal = '';
            switch(true){
                case validateFor('xKey', config) && config.xKey === batchTimeParamKey:
                    initParamVal = batchTimeParamVal;
                    break;
                case validateFor('xTag', config) && config.xTag === RAPID_CONN_TPPID_TAG:
                    initParamVal = TPPID_VAL;
                    break;
                default:
                    initParamVal = '';
                    break;
            }
            this.procParams.push(
                this.fb.group({
                    xParamTag: [config.xTag],
                    xParamKey: [config.xKey], //map with xKey
                    // xParamName: [config.xName],
                    xParamVal: [
                        initParamVal,
                        [
                            Validators.minLength(config.xMinLen),
                            Validators.maxLength(config.xMaxLen)
                        ]
                    ]
                })
            );
            this.setProcParamNames(config);
        });
    }

    setProcParamNames(configParams: any) {
        this.procParamName[configParams.xTag] = configParams.xName;
    }

    get procTag(): FormControl {
        return this.form.get("xProcTag") as FormControl;
    }
    get procParams(): FormArray {
        return this.form.get("xProcParams") as FormArray;
    }
    get acceptedMediumTags(): FormArray {
        return this.form.get("xAcptCaps.xAcptMdmTags") as FormArray;
    }
    get acceptedBrandTags(): FormArray {
        return this.form.get("xAcptCaps.xAcptBrandTags") as FormArray;
    }
    get acceptedTransactionTags(): FormArray {
        return this.form.get("xAcptCaps.xAcptTxnActTags") as FormArray;
    }

    // get mappedProfitCenter(): FormArray {
    //   return this.form.get("xMapping.xPCtrIDs") as FormArray;
    // }
    get mappedMediumTags(): FormArray {
        return this.form.get("xMapping.xMdmTags") as FormArray;
    }
    get mappedBrandTags(): FormArray {
        return this.form.get("xMapping.xBrandTags") as FormArray;
    }
    get mappedProfitCenterShortCode(): FormArray {
        return this.form.get("xMapping.xPCtrShortCodes") as FormArray;
    }

    get storeID(): any {
        return this.form.get("xStoreID");
    }
    get businessID(): any {
        return this.form.get("xBusinessID");
    }
    get pctrID(): any {
        return this.form.get("xMainPCtrID");
    }

    //Search for processor in processorList with search key a 'xTag'
    getIndexOfObject(tagList: FormArray, tag: any) {
        // console.log("taglist",tagList, tag);
        // console.log("tagList.value.findIndex(t => t._id === tag._id);", tagList.value.findIndex(t => t._id === tag._id));
        // console.log("tag",tag);
        return tagList.value.findIndex(t => t._id === tag._id);
    }

    // getIndexOfObjectViaSC(tagList: FormArray, tag: any) {
    //   // console.log("taglist",tagList, tag);
    //   // console.log("tagList.value.findIndex(t => t._id === tag._id);", tagList.value.findIndex(t => t._id === tag._id));
    //   // console.log("tag",tag);
    //   return tagList.value.findIndex(t => t.xShortCode === tag.xShortCode);
    // }

    //Adds or removes all Tags from tagArray based upon checkox is checked or not
    addAllTags(tagList: any[], tagArray: FormArray, isChecked: boolean) {
        if (isChecked) {
            while (tagArray.length) {
                tagArray.removeAt(0);
            }
            tagList.forEach((tag: any) => {
                tagArray.push(new FormControl(tag));
            });
        } else {
            while (tagArray.length) {
                tagArray.removeAt(0);
            }
        }
    }

    //Inserts or deletes tags based upon checkbox is checked or not
    updateTagsArray(tagArray: FormArray, tag: any, relatedCheckbox: boolean) {
        console.log(
            "I'm here inside updateTagsArray",
            JSON.parse(JSON.stringify(tagArray.value)),
            tag
        );
        relatedCheckbox = false;
        const index = this.getIndexOfObject(tagArray, tag);
        if (index >= 0) {
            tagArray.removeAt(index);
        } else {
            tagArray.push(new FormControl(tag));
        }
    }

    // UpdatedPctrArray(tagArray: FormArray, tag: any, relatedCheckbox: boolean) {
    //   relatedCheckbox = false;
    //   const index = this.getIndexOfObject(tagArray, tag);
    //   if (index >= 0) {
    //     tagArray.removeAt(index);
    //   } else {
    //     tagArray.push(new FormControl(tag));
    //   }
    // }

    onSubmit(form: FormGroup): void {
        this.urlStoreService.submitAction = true;
        this.tabStructureService.isSubmitClick = true;
        this.submitting = true;
        this.serverErrors = {}; //Reset Errors
        const merchantModel = form.getRawValue();
        // merchantModel.xTypeTag=this.tag;
        if (merchantModel.xCaptTag) {
            merchantModel.xCaptTag = this.captItem[0].xTag;
        }
        if (this.edit) {
            merchantModel["xRefNum"] = this.xRefNumEditMode;
        }
        let accptMdmTags = [];
        merchantModel.xAcptCaps.xAcptBrandTags.map(tag => {
            if (
                tag.hasOwnProperty("xExtInfo") &&
                tag["xExtInfo"] &&
                tag["xExtInfo"].hasOwnProperty("xMdmTag")
            ) {
                if (accptMdmTags.length) {
                    if (accptMdmTags.indexOf(tag.xExtInfo.xMdmTaite) === -1) {
                        accptMdmTags.push(tag.xExtInfo.xMdmTag);
                    }
                } else {
                    accptMdmTags.push(tag.xExtInfo.xMdmTag);
                }
            }
        });
        let mapMdmTags = [];
        merchantModel.xMapping.xBrandTags.map(tag => {
            if (
                tag.hasOwnProperty("xExtInfo") &&
                tag["xExtInfo"] &&
                tag["xExtInfo"].hasOwnProperty("xMdmTag")
            ) {
                if (mapMdmTags.length) {
                    if (mapMdmTags.indexOf(tag.xExtInfo.xMdmTag) === -1) {
                        mapMdmTags.push(tag.xExtInfo.xMdmTag);
                    }
                } else {
                    mapMdmTags.push(tag.xExtInfo.xMdmTag);
                }
            }
        });

        merchantModel.xAcptCaps.xAcptMdmTags = this.isAllAcceptanceTagsChecked
            .brand
            ? ["all"]
            : accptMdmTags;
        merchantModel.xAcptCaps.xAcptBrandTags = this.isAllAcceptanceTagsChecked
            .brand
            ? ["all"]
            : merchantModel.xAcptCaps.xAcptBrandTags.map(tag => tag.xTag);
        merchantModel.xAcptCaps.xAcptTxnActTags = this
            .isAllAcceptanceTagsChecked.transaction
            ? ["all"]
            : merchantModel.xAcptCaps.xAcptTxnActTags.map(tag => tag.xTag);

        //TODO CHANGE TYPETAG TO TAG
        console.log("Ye dkho", this.isMappingTagsChecked.profitCenter);
        merchantModel.xMapping.xPCtrShortCodes = this.isMappingTagsChecked
            .profitCenter
            ? ["all"]
            : merchantModel.xMapping.xPCtrShortCodes.map(pctr => pctr._id);
        merchantModel.xMapping.xMdmTags = this.isMappingTagsChecked.brand
            ? ["all"]
            : mapMdmTags;
        merchantModel.xMapping.xBrandTags = this.isMappingTagsChecked.brand
            ? ["all"]
            : merchantModel.xMapping.xBrandTags.map(tag => tag.xTag);

        // console.log(merchantModel);
        merchantModel.xProcParams = this.procParams.value;
        console.log(
            "merchant model",
            JSON.parse(JSON.stringify(merchantModel))
        );
        this.showOverLay = true;

        if (this.edit) {
            //Update Merchant Account
            this.setupPaymentProcessingMerchantAccountStore
                .update(this.merchantInfo._id, merchantModel)
                .subscribe(
                    (response: any) => {
                        console.log('onSubmit: response',response);	
                        const requestID = validateFor('data',response) && validateFor('requestID',response.data) ? response.data.requestID : '';	
                        if(!requestID){	
                            this.notificationService.error(NO_JOB_ID_MSG,'Error');	
                            return;	
                        }	
                        // this.pollActionWorker(requestID)	
                        this.actionWorkerService.pollActionWorker(requestID)	
                            .then(actionResponse => {	
                                console.log('onSubmit:actionResponse',actionResponse);	
                                if(!actionResponse['success']){	
                                    this.handleActionWorkerFail(actionResponse);	
                                    return; 	
                                }	
                                this.handleSuccessResponse(	
                                    response.data,	
                                    merchantModel	
                                );	
                            })	
                            .catch(actionError => {	
                                console.log('onSubmit:actionError',actionError);	
                                this.handleActionWorkerFail(actionError);	
                            })
                    },
                    (error: any) => {
                        this.serverErrors = error.errors;
                        this.handleError(error);
                    },
                    () => {}
                );
        } else {
            //Add New Merchant Account
            // console.log('merchant model: onsubmit', merchantModel)
            this.setupPaymentProcessingMerchantAccountStore
                .store(merchantModel)
                .subscribe(
                    (response: any) => {
                        console.log('onSubmit: response',response);	
                        const requestID = validateFor('data',response) && validateFor('requestID',response.data) ? response.data.requestID : '';	
                        if(!requestID){	
                            this.notificationService.error(NO_JOB_ID_MSG,'Error');	
                            return;	
                        }	
                        // this.pollActionWorker(requestID)	
                        this.actionWorkerService.pollActionWorker(requestID)	
                            .then(actionResponse => {	
                                console.log('onSubmit:actionResponse',actionResponse);	
                                if(!actionResponse['success']){	
                                    this.handleActionWorkerFail(actionResponse);	
                                    return; 	
                                }	
                                this.handleSuccessResponse(	
                                    response.data,	
                                    merchantModel	
                                );	
                            })	
                            .catch(actionError => {	
                                console.log('onSubmit:actionError',actionError);	
                                this.handleActionWorkerFail(actionError);	
                            })
                    },
                    (error: any) => {
                        this.serverErrors = error.errors;
                        this.handleError(error);
                    },
                    () => {}
                );
        }
    }

    	
    /**	
     * if action worker fails or success is false this function handles the response	
     *	
     * @params : `response` : object : response from action worker	
    */	
    handleActionWorkerFail(response){	
        console.log('handleActionWorkerSuccess: response',response);	
        this.submitting = false;	
        this.showOverLay = false;	
        const message = validateFor('message',response) ? response.message : GENERAL_FALL_BACK_MSG;	
        this.notificationService.error(message,'Error');	
    }

    handleSuccessResponse(data: any, merchantModel?: any): void {
        this.submitting = false;
        this.showOverLay = false;
        const msg = this.edit
            ? "Record Successfully Updated."
            : "Record Successfully Added.";
        this.notificationService.success(msg, "Success!");
        this.businessService.toggleRefreshTree();
        // Proper routing depending upon nodeClick or any other click
        if (this.treeService.nodeClick) {
            if (this.edit) {
                this.setupPaymentProcessingMerchantAccountStore
                    .get(data.id)
                    .subscribe(response => {
                        this.merchantInfo = response.data;
                        // this.captItemList = response[7].data;
                        console.log("this.captItemList", this.captItemList);
                        console.log("data", this.merchantInfo);
                        this.setFormValues(this.merchantInfo);
                        this.setAcceptanceAndMappingField(
                            // this.mediumTagList,
                            this.brandTagList,
                            this.mappingBrandTagList,
                            this.transactionTagList,
                            this.profitCenterList
                        );
                        // this.captItem=this.captItemList.filter(item=> item.xTag===this.merchantInfo.xCaptTag);
                        // console.log("This.captItem inside observable for edit mode", this.captItem);
                        // this.form.controls['xCaptTag'].setValue(this.captItem[0].xName);
                    });
            } else {
                this.tabStructureService.currentNodeName = merchantModel.xName;
                this.urlStoreService.routeToCurrentUrl(
                    data,
                    "merchant_account",
                    "add"
                );
            }
        } else {
            if (this.tabStructureService.tabMode) {
                this.urlStoreService.routeBackToPreviousUrl();
            } else {
                if (this.tabStructureService.addNodeBtnClick) {
                    this.tabStructureService.currentNodeName =
                        merchantModel.xName;
                    this.urlStoreService.routeToCurrentUrl(
                        data,
                        "merchant_account",
                        "add"
                    );
                } else {
                    this.treeService.setId(
                        this.businessService.businessID.getValue(),
                        "business"
                    );
                    console.log(
                        "onsuccess no tab mode and node click   mer acct",
                        this.treeService.businessID.getValue(),
                        this.treeService.chainID.getValue()
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            }
        }
    }

    handleError(error: any) {
        this.submitting = false;
        this.showOverLay = false;
        console.log("Inside handle error", error);
        for (const field of Object.keys(error.errors)) {
            if (this.formErrors.hasOwnProperty(field)) {
                // this.formErrors[field] = 'Field is not allowed to be empty';
                if (field === "xMerAcctNum") {
                    if (error.name === "Conflict") {
                        let err = error.errors.xMerAcctNum.replace(
                            /merchant account/g,
                            "Merchant Account"
                        );
                        this.notificationService.error(err, "Conflict");
                        this.clearErrors("xMerAcctNum");
                    } else {
                        this.formErrors[field] =
                            "Field is not allowed to be empty";
                    }
                } else {
                    this.formErrors[field] = "Field is not allowed to be empty";
                }
            }
            if (field === "xRefNum") {
                console.log("Inside reference number handle error", error);
                if (error.name === "Conflict") {
                    let err = error.errors.xRefNum.replace(
                        /merchant account/g,
                        "Merchant Account"
                    );
                    this.notificationService.error(err, "Conflict");
                    this.clearErrors("xRefNum");
                }
            }
        }
    }

    handleErrorResponse(error: any): void {
        this.submitting = false;
        const msg = this.edit ? "Record Not Updated." : "Record Not Added.";
        try {
            this.serverErrors = JSON.parse(error._body).errors;
            this.notificationService.error(
                JSON.parse(error._body).name === "BadRequest"
                    ? msg
                    : JSON.parse(error._body).name,
                JSON.parse(error._body).message === "Invalid data"
                    ? "Please fill all Required Details"
                    : JSON.parse(error._body).message
            );
        } catch (err) {
            this.notificationService.error("Something went Wrong", "ERROR");
        }
        this.submitting = false;
    }

    clearErrors(errorName: string): void {
        if (this.serverErrors[errorName]) {
            delete this.serverErrors[errorName];
        }
    }

    onCancel(event: Event) {
        event.preventDefault();
        if (this.tabStructureService.tabMode) {
            console.log(
                "tabmode===>",
                this.tabStructureService.previousTabStructureLabel,
                this.edit,
                this.urlStoreService.previousUrl,
                this.urlStoreService.currentUrl,
                this.urlStoreService.secondLastUrl
            );
            if (!this.edit) {
                this.urlStoreService.routeBackToPreviousUrl();
            } else {
                this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
                this.urlStoreService.routeToSecondLastUrl();
            }
        } else {
            if (this.edit) {
                console.log("no tabmode and edit");
                if (!this.tabStructureService.editMode) {
                    console.log("no tabmode and edit and editmode");
                    this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
                    if (
                        this.urlStoreService.previousUrl &&
                        !this.urlStoreService.previousUrl.includes("add-edit")
                    ) {
                        this.treeService.setId(
                            this.treeService.previousSelectedNode.id,
                            this.treeService.previousSelectedNode.type
                        );
                    }
                    this.urlStoreService.routeBackToPreviousUrl();
                    // }
                } else {
                    console.log("no tabmode and edit and no editmode");
                    if (this.treeService.nodeClick) {
                        this.urlStoreService.routeToSecondLastUrl();
                    } else {
                        console.log(
                            "id and type of previous selected node",
                            this.treeService.previousSelectedNode.id,
                            this.treeService.previousSelectedNode.type
                        );
                        this.treeService.setId(
                            this.treeService.previousSelectedNode.id,
                            this.treeService.previousSelectedNode.type
                        );
                        this.urlStoreService.routeBackToPreviousUrl();
                    }
                }
            } else {
                console.log("no tabmode and no edit");
                this.urlStoreService.routeBackToPreviousUrl();
            }
        }
    }

    onEditClick(event: Event) {
        // console.log("Here onEditClick function in biz===>", this.parentMode, JSON.parse(JSON.stringify(this.tabStructureService.defaultTabStructureLabel)));
        console.log(
            "Here onEditClick function in biz===>",
            this.urlStoreService.previousUrl,
            this.urlStoreService.currentUrl,
            this.urlStoreService.secondLastUrl
        );
        this.tabStructureService.previousTabStructureLabel = JSON.parse(
            JSON.stringify(this.tabStructureService.defaultTabStructureLabel)
        );
        this.treeService.isParent = false;
        this.parentMode = false;
        this.edit = true;
        this.treeService.setId(this.merchantInfo._id, "merchant_account");
        // this.router.navigate(['dashboard/setup/business/structure/information'])
    }

    setupPerms(){
        this.canAdd = this.userHandlerService.userPerms.canCreateSetup
        this.canEdit = this.userHandlerService.userPerms.canEditSetup;
    }
    
    /**
     * In edit mode, though there are outstanding transactions, allow user to change the processor.
     */
    toggleProcessorLock(){
        let text = this.enableProcessorChange ? DISABLE_PROCESSOR_CHANGE: ENABLE_PROCESSOR_CHAGNE;
        customSwal.fire({
            title: `Are you sure, you want to Procced?`,
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, go ahead!',
            cancelButtonText: 'No, I changed my Mind!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.enableProcessorChange = !this.enableProcessorChange;
                if(this.enableProcessorChange){
                    this.form.get("xProcTag").enable();
                }else{
                    this.form.get("xProcTag").disable();
                }
            }
        });
    }

    ngOnDestroy() {
        this.alive = false;
    }
}
