<section class="no-filter">
  <form class="form-horizontal padder">
    <!--<div class="row  m-b-1">-->
    <div class="grid-add-wrap">
      <grid-cmp [attributes]=attributes [storeValue]=storeValue [parameters]=parameters [storeObj]=storeObject [action]=action
        (actionRequest)="onActionRequest($event)" (detailVisible)="onShowDetails($event)"></grid-cmp>
      <!--</div>
      <div *ngIf="detailVisible" class="col-sm-4">
      </div>-->
    </div>
  </form>
  <details-cmp [id]=statusID [viewDetails]=viewDetails [storeValue]=storeValue [label]="'Status'" [store]=storeObject [show]=showDetails (onViewClose)="viewClosed($event)"></details-cmp>
</section>

<!--[class.col-sm-10]=" !detailVisible " [class.col-sm-8]="detailVisible "-->