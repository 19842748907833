import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TreeService} from './tree-service';

@Injectable()
export class UrlStoreService {
    constructor(
        private router:Router,
        private treeService:TreeService
    ){

    }

    public previousURL:string='';
    public currentURL:string='';
    public secondLastURL:string='';
    public _submitAction:boolean=false;
    public _previousSelectedEntityId:string=null;

    get previousUrl(): string {
        return this.previousURL;
    }
    set previousUrl(prevurl:string)  {
        this.previousURL=prevurl;
    }
    
    get currentUrl(): string {
        return this.currentURL;
    }
    set currentUrl(currenturl:string) {
        this.currentURL=currenturl;
    }
    get secondLastUrl(): string {
        return this.secondLastURL;
    }
    set secondLastUrl(secondlasturl:string) {
        this.secondLastURL=secondlasturl;
    }

    get submitAction(): boolean {
        return this._submitAction;
    }
    set submitAction(action:boolean) {
        this._submitAction=action;
    }

    get previousSelectedEntityId(): string {
        return this._previousSelectedEntityId;
    }
    set previousSelectedEntityId(id:string) {
        this._previousSelectedEntityId=id;
    }



    // get form(): any {
    //     return this._form;
    // }
    // set form(currForm:any) {
    //     this._form=currForm;
    // }
    // get safe(): any {
    //     return this._safe;
    // }
    // set safe(currSafe:any) {
    //     console.log("safe setter")
    //     this._safe=currSafe;
    //     this._form['safe']=currSafe[0].value.map(safeVal=> safeVal);
    //     console.log("this._form", this._form);
    // }

    swapUrl(currentUrl:string){
        console.log("this.cururl in urlstore", this.currentURL, currentUrl);
        console.log("this.secondLastUrl in urlstore", this.secondLastUrl);
        console.log("this.previousUrl in urlstore", this.previousUrl);
        // if(this.currentURL !== currentUrl){
            if(this.previousUrl.length){
                this.secondLastUrl=this.previousUrl;
            }
            this.previousUrl=this.currentURL;
            this.currentUrl=currentUrl;
        // }
    }

    routeToSecondLastUrl(){
        console.log("this.secondLastUrl", this.secondLastUrl);
        console.log("this.previousUrl", this.previousUrl);
        const link = [this.secondLastUrl];
        this.router.navigate(link);
    }

    routeBackToPreviousUrl(){
        console.log("this.previousURL", this.previousURL);
        const link = [this.previousUrl];
        this.router.navigate(link);
    }

    routeToCurrentUrl(data?:any, nodeType?:string, mode?:string){
        if(mode && mode === "add"){
            if(this.currentUrl.endsWith('add-edit')){
                this.currentUrl= this.currentUrl.replace("add-edit", data.id);
            }
            else{
                this.currentUrl=this.currentUrl.substring(0,this.currentUrl.lastIndexOf('/')+1);
                this.currentUrl=this.currentUrl+data.id;
            }
        }
        console.log("current url routing", JSON.parse(JSON.stringify(data)), nodeType);
        this.treeService.setId(data.id, nodeType);
        console.log("this.currentUrl====>", this.currentUrl);
        this.router.navigate([this.currentUrl]);
    }

   
}
