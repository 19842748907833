<div class="card" [formGroup]="form">
	<div class=" card-header">
		<label class="">Security (For Websites)</label>
	</div>
	<div class="card-body" *ngIf="!apiLoading && !apiError" formGroupName="xUserInfo">
		<div class="form-group row">
			<div class="col-md-12 col-sm-12">
				<label for="username">Username:
					<small class="text-danger">*</small>
				</label>
				<!-- <label for="phone">Phone <small class="text-danger">*</small></label> -->
				<small *ngIf="!serverErrors['xLoginUName'] && form.get( 'xUserInfo').get('xLoginUName').hasError('required') && form.get('xUserInfo').get('xLoginUName').touched"
					class="inline-error">
					<i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Username is required" placement="top"
						containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
				</small>

				<small *ngIf="serverErrors['xLoginUName']" class="inline-error">
					<i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Username is required" placement="top"
						containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
				</small>

				<input type="text" class="form-control form-control-sm" id="username" formControlName="xLoginUName" 
					[ngClass]="{ 'has-error': (form.get('xUserInfo').get('xLoginUName').hasError('required') && form.get('xUserInfo').get('xLoginUName').touched) || serverErrors['xLoginUName']}"
					(focus)= "clearErrors('xLoginUName')"
					validateOnBlur>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-12 col-sm-12">
				<label for="password">Password:
					<small class="text-danger">*</small>
				</label>
				<small *ngIf="!serverErrors['xHashedPwd'] && form.get('xUserInfo').get('xHashedPwd').hasError('required') && form.get('xUserInfo').get('xHashedPwd').touched"
					class="inline-error">
					<i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Password is required" placement="top"
						containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
				</small>
				<small *ngIf="serverErrors['xHashedPwd']" class="inline-error">
					<i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Password is required" placement="top"
						containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
				</small>
				<small *ngIf="form.get(DB_XUSERINFO_KEY).get(DB_XHASHED_PWD_KEY).hasError('INVALID_PASSWORD') && form.get(DB_XUSERINFO_KEY).get(DB_XHASHED_PWD_KEY).touched">
					<i class="fa fa-exclamation-circle" container="body" tooltip="Password must contain at least one upper case letter [A-Z], one lower case letter [a-z], one number [0-9] and one special character [*, #, $ ...]"
						placement="top" container="body" containerClass="tooltip-global" aria-hidden="true"></i>
				</small>
				<input type="password" class="form-control form-control-sm" id="password" formControlName="xHashedPwd"
					[ngClass]="{ 'has-error': ((form.get(DB_XUSERINFO_KEY).get(DB_XHASHED_PWD_KEY).hasError('required') && form.get(DB_XUSERINFO_KEY).get(DB_XHASHED_PWD_KEY).touched) || serverErrors[DB_XHASHED_PWD_KEY]) || (form.get(DB_XUSERINFO_KEY).get(DB_XHASHED_PWD_KEY).hasError('INVALID_PASSWORD') && form.get(DB_XUSERINFO_KEY).get(DB_XHASHED_PWD_KEY).touched)}"
					 (focus)= "clearErrors('xHashedPwd')" validateOnBlur>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-9 col-xs-9">
				<label for="business_type" class="control-label">Roles:
					<span></span>
					<small class="text-danger">*</small>
				</label>
				<small *ngIf="serverErrors['xRoles']" class="inline-error">
					<i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Roles is required" placement="top"
						containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
				</small>
				<div class="card no-margin">
					<div *ngIf="roles.length">
						<ul class="p-2 no-margin">
							<ng-container *ngFor="let role of userAssociatedRolesArray">
								<li *ngIf="role.isPresent">{{role.text}}</li>
							</ng-container>
						</ul>
					</div>
					<div class="p-2" *ngIf="!roles.length" [ngClass]="{'has-error':serverErrors['xRoles']}">
						<span class="span-select" [ngClass]="{'danger-text':serverErrors['xRoles']}">
							Please Add a role
						</span>
					</div>
				</div>
			</div>

			<div class="col-xs-3 m-t-30">
				<button class="btn btn-primary btn-sm btn-block" type="button" (click)="rolesModal.show(); clearErrors('xRoles')">Add / Edit</button>
			</div>
		</div>
		<div class="form-group row no-margin" *ngIf="!onRepresentativePage && !onCustomerPage">
			<div class="col-md-9 col-xs-9">
				<label for="business_type" class="control-label">Stores:
					<span> </span>
					<small class="text-danger">*</small>
				</label>
				<small *ngIf="serverErrors['xAccStores']" class="inline-error">
					<i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Stores is required" placement="top"
						containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
				</small>
				<div class="card no-margin">
					<div *ngIf="stores.length">
						<ul class="p-2 no-margin">
							<ng-container *ngFor="let store of userAssociatedStoresArray">
								<li *ngIf="store.isPresent">{{store.text}}</li>
							</ng-container>
						</ul>
					</div>
					<div class="p-2" *ngIf="!stores.length" [ngClass]="{'has-error':serverErrors['xAccStores']}">
						<span class="span-select" [ngClass]="{'danger-text':serverErrors['xAccStores']}">
							Please Add a store
						</span>
					</div>
				</div>
			</div>
			<div class="col-xs-3 m-t-30">
				<button class="btn btn-primary btn-sm btn-block" type="button" (click)="storesModal.show(); clearErrors('xAccStores')" [disabled]="disableAddStore">Add
					/ Edit</button>
			</div>
		</div>
	</div>
	<div class="un-ass-per" *ngIf="apiLoading && !apiError" style="text-align: center;">
		<span>
			<div class="grid-spinner">
				<div class="bounce1"></div>
				<div class="bounce2"></div>
				<div class="bounce3"></div>
			</div>
			<span style="color:#3b5877">Fetching required data</span>
		</span>
	</div>
</div>

<div bsModal #rolesModal="bs-modal" class="modal fade modal-role" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
	aria-hidden="true" (onHide)="abortRoleModal()">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="rolesModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">Add New / Edit Roles</h4>
			</div>
			<form class="form-horizontal" [formGroup]="form" (ngSubmit)="editRoles()" novalidate>
				<div class="">
					<div class="row">
						<div class="col-8 p-r-0 split">
							<small class="modal-label">Assigned Roles</small>
							<div class="form-group no-margin added-roles">
								<ul>
									<ng-container *ngFor="let role of rolesArray">
										<li 
											*ngIf="role.isPresent" 
											class="active" 
											[class.selected-row]="role.id===selectedRoleID"
											(click)="getPermissions(role.id, role.text)"
										>
											<a>
												<span class="span-text">{{role.text}}</span>
												<button *ngIf="role.isPresent" class="btn btn-secondary add-btn delete-btn pull-right" type="button"
													(click)="role.isPresent=false">
													<i class="fa fa-trash-o" aria-hidden="true"></i>
												</button>
											</a>
										</li>
									</ng-container>
								</ul>
							</div>
							<small class="modal-label span-sibling">Unassigned Roles</small>
							<div class="added-roles">
								<ul class="all-roles">
									<ng-container *ngFor="let role of rolesArray">
										<li *ngIf="!role.isPresent" [class.selected-row]="role.id===selectedRoleID" (click)="getPermissions(role.id, role.text)">
											<a>
												<span class="span-text">{{role.text}}</span>
												<button *ngIf="!role.isPresent" class="btn btn-secondary add-btn pull-right" type="button"
													(click)="addRole(role)">
													<i class="fa fa-plus" aria-hidden="true"></i>
												</button>
											</a>
										</li>
									</ng-container>
								</ul>
							</div>
						</div>
						<div class="col-4 p-l-0" *ngIf="showPermsDiv">
							<div class="store-mgr">
								<b>
									<label>Role: </label> {{selectedRoleName}}</b>
								<div class="form-group no-margin">
									<div>
										<div class="store-mgr-scroll">
											<table class="table table-sm">
												<thead>
													<tr>
														<th class="w-40">Module</th>
														<th>Function</th>
													</tr>
												</thead>
												<tbody *ngIf="!loadingRoleAssociatedPerm">
													<ng-container *ngFor="let perm of roleAssociatedPermisionsList">
														<tr>
															<td *ngIf="moduleTagMapping[perm.xMduTag]">
																{{moduleTagMapping[perm.xMduTag]}}
															</td>
															<td *ngIf="perm.xName">
																{{perm.xName}}
															</td>
														</tr>
													</ng-container>
												</tbody>
												<tbody *ngIf="loadingRoleAssociatedPerm">
													<tr>
														<td style="text-align: center;" [colSpan]="2">
															<div class="grid-spinner">
																<div class="bounce1"></div>
																<div class="bounce2"></div>
																<div class="bounce3"></div>
															</div>
															<span style="color:#3b5877">Fetching Permissions</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div *ngIf="!roleAssociatedPermisionsList.length && !loadingRoleAssociatedPerm">
											<label class="p-2 text-center">
												This role has no assigned permissions. Click on Add/Edit to modify the permissions.
											</label>
										</div>
										<div class="add-edit-btn" *ngIf="!loadingRoleAssociatedPerm">
											<button class="btn btn-sm btn-primary" type="button" (click)="routeToRoleSetup()">Add/Edit</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-4 text-center p-l-0" *ngIf="!showPermsDiv">
							<div class="empty-store-mgr">
								<img src="../../../../assets/images/modal-empty.png">
								<p>Click on roles to check their permissions. </p>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<div class="row">
						<div class="col-sm-12 clearfix">
							<button class="btn btn btn-primary pull-right">Save
								<i class="fa fa-angle-double-right" aria-hidden="true"></i>
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
<div *ngIf="displayPermissionModalCmp">
	<permission-modal-cmp [selectedRoleID]=selectedRoleID (onCloseModal)="permissionModalClosed($event)"></permission-modal-cmp>
</div>

<div *ngIf="displayPreviewPermissionModalCmp">
	<preview-permission-modal-cmp [roleIDs]=finalRoleIDs (onCloseModal)="previewPermissionModalClosed($event)"></preview-permission-modal-cmp>
</div>

<div bsModal #storesModal="bs-modal" class="modal fade modal-role modal-store" tabindex="-1" role="dialog"
	aria-labelledby="myLargeModalLabel" aria-hidden="true" (onHide)="abortStoreModal()">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="storesModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">Add New / Edit Stores</h4>
			</div>
			<form class="form-horizontal" [formGroup]="form" (ngSubmit)="editStores()" novalidate>
				<div class="split split-none">
					<small class="modal-label">Assigned Stores</small>
					<div class="form-group no-margin added-roles">
						<ul>
							<ng-container *ngFor="let store of storesArray">
								<li *ngIf="store.isPresent">
									<label class="checkbox1">
										<input type="checkbox" name="status" class="checkbox" [checked]="store.status" [disabled]="store.id===storeId || store.id===setupBusinessEmployeeModel.xMainStoreID"
											(change)="store.status=! store.status">
										<span></span>
									</label>
									<span class="span-text">{{store.text}}</span>
									<button *ngIf="store.isPresent" class="btn btn-secondary add-btn delete-btn pull-right" type="button"
										(click)="store.isPresent=false" [disabled]="store.id===storeId || store.id===setupBusinessEmployeeModel.xMainStoreID">
										<i class="fa fa-trash-o" aria-hidden="true"></i>
									</button>
								</li>
							</ng-container>
						</ul>
					</div>
					<small class="modal-label span-sibling">Unassigned Stores</small>
					<div class="form-group no-margin added-roles">
						<ul class="all-roles">
							<ng-container *ngFor="let store of storesArray">
								<li *ngIf="!store.isPresent">
									<label class="checkbox1">
										<input type="checkbox" name="status" class="checkbox" [checked]="store.status" (change)="store.status=!store.status">
										<span></span>
									</label>
									<span class="span-text">{{store.text}}</span>
									<button *ngIf="!store.isPresent" class="btn btn-secondary add-btn pull-right" type="button" (click)="store.isPresent=true">
										<i class="fa fa-plus" aria-hidden="true"></i>
									</button>
								</li>
							</ng-container>
						</ul>
					</div>
				</div>
				<div class="modal-footer">
					<button class="btn btn-sm btn-primary pull-right">Save
						<i class="fa fa-angle-double-right" aria-hidden="true"></i>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>