import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { BreadcrumbService, FilterService, TreeService, NotificationService, UrlStoreService, UserHandlerService } from '../../../../shared/services';
import { SetupBillingCustomerStore } from '../../../../store';
const TOOLTILP_MESSAGE = {
	selectStore: 'Please select a Store to add a Customer.',
	addCustomer: 'Add Customer'
};

@Component({
	selector: 'app-setup-billing-customer',
	templateUrl: './setup-billing-customer.component.html',
	styleUrls: ['./setup-billing-customer.component.scss']
})
export class SetupBillingCustomerComponent implements OnInit, OnDestroy {
	@ViewChild('NgxAsidePanelRight', { static: true }) ngxAsidePanelRight: any;

	// detailVisible: boolean = false;
	public attributes: any[] = [];
	public action: any[] = [];
	public storeValue: string = '';
	public storeObject: any = {};
	// protected tree_filter: string = '';
	// public refreshGrid: boolean;
	fetchByStore: boolean = true;
	checkValue: boolean = false;
	public showTree: boolean;
	public mode: string = '';
	public custId: string = '';
	public parameters: any;
	showDetails: boolean = false;
	isStoreSelected: boolean = false;
	tooltipMessage: string = '';

	public customer: any = {};
	private alive: boolean = true;

	constructor(
		private setupBillingCustomerStore: SetupBillingCustomerStore,
		private router: Router,
		private breadcrumbService: BreadcrumbService,
		public treeService: TreeService,
		private filterService: FilterService,
		private notificationService: NotificationService,
		public urlStoreService: UrlStoreService,
		private userHandlerService: UserHandlerService
	) {
		//this.urlStoreService.swapUrl(router.url);
	}

	ngOnInit(): void {
		this.setBreadCrumbValue();
		// this.setTreeAttributes();
		// this.filterService.setFilterValue(false);

		// this.tree_filter = null;
		this.storeValue = 'SetupBillingCustomerStore';
		this.storeObject = this.setupBillingCustomerStore;
		this.attributes = [
			{ header: 'Customer #', mapTo: 'xCustomerNum', width: '11%' },
			{
				header: 'First Name',
				mapTo: 'xFormalName.xForename',
				width: '12%'
			},
			{
				header: 'Last Name',
				mapTo: 'xFormalName.xSurname',
				width: '12%'
			},
			{ header: 'Company', mapTo: 'xCompanyName', width: '12%' },
			{ header: 'City', mapTo: 'xAddr.xCity', width: '11%' },
			{ header: 'State', mapTo: 'xAddr.xSOP', width: '11%' },
			{ header: 'Post Code', mapTo: 'xAddr.xPostCode', width: '12%' },
			{ header: 'Country', mapTo: 'xAddr.xCountry', width: '8%' },
			{ header: 'Actions', mapTo: '', width: '11%' }
		];
		let editLink = '';
		if (this.router.url && this.router.url.includes('business')) {
			//case when customer edit is to be opened in tab view under business
			editLink = '/dashboard/setup/business/customer/add-edit';
		} else {
			//case when customer edit is to be opened individually under billing
			editLink = '/dashboard/setup/billing/customer/add-edit/';
		}

		const viewLink = '/dashboard/setup-billing-customer/';
		this.action = [
			{
				type: 'edit',
				visible: true,
				text: 'Edit',
				icon: 'fa fa-pencil-square-o',
				action: 'url',
				onClick: editLink
			},
			{
				type: 'delete',
				visible: true,
				text: 'Delete',
				icon: 'fa fa-trash-o',
				action: 'func',
				onClick: 'destroy',
				disabled: !this.userHandlerService.userPerms.canDeleteCustomer
			},
			{
				type: 'view',
				visible: true,
				text: '',
				icon: 'fa fa-info-circle',
				action: 'emit',
				onClick: viewLink
			},
			{
				type: 'edit',
				visible: true,
				text: 'View Transactions',
				icon: 'fa fa-exchange',
				action: 'func',
				value: 'transaction',
				onClick: '/dashboard/outstanding-transactions/cust',
				disabled: !this.userHandlerService.userPerms.canViewCustTxns
			},
			{
				type: 'edit',
				visible: true,
				text: 'Payment Accounts',
				icon: 'fa fa-user',
				action: 'url_key',
				onClick: '/dashboard/setup/billing/customer/member-accounts',
				paramKey: 'custID'
			}
		];
		if (this.treeService.businessID.getValue()) {
			this.parameters = {
				xBusinessID: this.treeService.businessID.getValue()
			};
		}
		if (this.treeService.storeID.getValue()) {
			this.parameters = {
				xMainStoreID: this.treeService.storeID.getValue()
			};
		}
		if (this.treeService.chainID.getValue()) {
			this.parameters = {
				struct_type: 'chain',
				structID: this.treeService.chainID.getValue()
			};
		}
		if (this.treeService.regionID.getValue()) {
			this.parameters = {
				struct_type: 'region',
				structID: this.treeService.regionID.getValue()
			};
		}
		if (this.treeService.propertyID.getValue()) {
			this.parameters = {
				struct_type: 'property',
				structID: this.treeService.propertyID.getValue()
			};
		}
		this.treeService.watchBusinessID$
			.pipe(takeWhile(() => this.alive))
			.subscribe((bizID: string) => {
				this.parameters = { xBusinessID: bizID };
			});
		this.treeService.watchStoreID$
			.pipe(takeWhile(() => this.alive))
			.subscribe((storeID: string) => {
				if (storeID) {
					this.isStoreSelected = true;
					this.tooltipMessage = TOOLTILP_MESSAGE.addCustomer;
					this.parameters = { xMainStoreID: storeID };
				} else {
					this.tooltipMessage = TOOLTILP_MESSAGE.selectStore;
					this.isStoreSelected = false;
				}
			});
		this.treeService.watchChainID$
			.pipe(takeWhile(() => this.alive))
			.subscribe((storeID: string) => {
				if (storeID) {
					this.parameters = {
						struct_type: 'chain',
						structID: this.treeService.chainID.getValue()
					};
				}
			});
		this.treeService.watchRegionID$
			.pipe(takeWhile(() => this.alive))
			.subscribe((storeID: string) => {
				if (storeID) {
					this.parameters = {
						struct_type: 'region',
						structID: this.treeService.regionID.getValue()
					};
				}
			});
		this.treeService.watchPropertyID$
			.pipe(takeWhile(() => this.alive))
			.subscribe((storeID: string) => {
				if (storeID) {
					this.parameters = {
						struct_type: 'property',
						structID: this.treeService.propertyID.getValue()
					};
				}
			});
	}

	ngAfterViewInit() {
		Promise.resolve(null).then(() => {
			this.treeService.setEnable(true);
		});
	}

	setBreadCrumbValue(): void {
		const breadCrumb: any = {};
		breadCrumb.root = 'Setup';
		breadCrumb.child = 'Billing';
		breadCrumb.subChild = 'Customers';
		breadCrumb.subChildLink = null;
		breadCrumb.grandChild = null;
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	onActionRequest(obj: any) {
		if (obj.act.type === 'view') {
			this.mode = 'view';
			this.custId = obj.id;
			// this.detailVisible = true;
			// this.refreshGrid = false;
			this.fetchCustomerDetails(this.custId);
		}
	}

	onCheckValue() {
		this.checkValue = !this.checkValue;
	}

	fetchCustomerDetails(customerID: string) {
		this.setupBillingCustomerStore
			.get(customerID)
			.pipe(
				takeWhile(() => this.alive)
			)
			.subscribe(
				(response: any) => {
					this.customer = response.data;
				},
				(error: any) => {
					const errMsg = JSON.parse(error._body).message;
					this.notificationService.error(errMsg, customerID + ' Not Found!');
					// this.detailVisible = false;
				}
			);
	}

	onShowDetails(obj: any) {
		this.showDetails = obj;
		if (this.showDetails) {
			this.ngxAsidePanelRight.open();
		}
	}

	viewClosed() {
		// this.detailVisible = visible;
		this.custId = '';
		this.showDetails = false;
		this.ngxAsidePanelRight.close();
	}

	onAddClick() {
		this.treeService.nodeClick = false;
		// this.tabStructureService.addNodeBtnClick=false;
		if (this.router.url && this.router.url.includes('business')) {
			//case when customer edit is to be opened in tab view under business
			this.router.navigate(['/dashboard/setup/business/customer/add-edit']);
		} else {
			//case when customer edit is to be opened individually under billing
			this.router.navigate(['/dashboard/setup/billing/customer/add-edit']);
		}
	}

	ngOnDestroy() {
		this.alive = false;
	}
}
