import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard, RouteGuardGuard } from '../../shared/guard';

import { SetupComponent } from './setup.component';

import {
	SetupStructureInformationComponent,
	SetupStructureChainComponent,
	SetupStructureRegionComponent,
	SetupStructurePropertyComponent,
	SetupStructureStoreComponent,
	SetupStructureProfitCenterComponent,
	SetupStructureTerminalComponent,
	SetupStructureMerchantAccountComponent,
	SetupStructurePeripheralListComponent,
	SetupStructureInformationListComponent,
	SetupStructureChainListComponent,
	SetupStructureRegionListComponent,
	SetupStructurePropertyListComponent,
	SetupStructureStoreListComponent,
	SetupStructureMerchantAccountListComponent,
	SetupStructureProfitCenterListComponent,
	SetupStructureTerminalsListComponent,
	SetupStructurePeripheralsListComponent
} from './setup-business-structure/components';
import { AddEditComponent } from './setup-billing/setup-billing-customer/components/add-edit/add-edit.component';
import { SetupBillingCustomerComponent } from './setup-billing/setup-billing-customer/setup-billing-customer.component';
import { StructureLayoutComponent } from './structure-layout/structure-layout.component';
import { EmployeeLayoutComponent } from './employee-layout/employee-layout.component';
import { SetupEmployeeComponent } from './setup-employee/setup-employee.component';
import { MemberAccountsComponent } from 'app/shared/modules/member-accounts/member-accounts.component';
// import {} from './setup-billing/setup-billing-customer/setup-billing-customer.component';
// import { AddEditComponent } from './setup-business-information/components/add-edit/add-edit.component';

const routes: Routes = [
	{
		path: '',
		component: SetupComponent,
		canActivate: [RoleGuard],
		children: [
			{
				path: 'business',
				data: {
					breadcrumb: { child: 'Business' }
				},
				canActivateChild: [RoleGuard],
				children: [
					{
						path: 'information',
						data: {
							breadcrumb: 'Information'
						},
						loadChildren: () => import('./setup-business-information/setup-business-information.module').then(m => m.SetupBusinessInformationModule)
					},
					{
						path: '',
						component: EmployeeLayoutComponent,
						children: [
							{
								path: 'employee',
								loadChildren: () => import('./setup-employee/setup-employee.module').then(m => m.SetupEmployeeModule),
								data: { breadcrumb: 'Employees' }
							},
							{
								path: 'customer',
								// loadChildren:
								//     './setup-billing/setup-billing-customer/setup-billing-customer.module#SetupBillingCustomerModule',
								component: SetupBillingCustomerComponent,
								data: { breadcrumb: 'Customer' }
							},
							{
								path: 'customer/add-edit',
								component: AddEditComponent,
								data: { breadcrumb: 'Customer' }
							},

							{
								path: 'customer/add-edit/:Id',
								component: AddEditComponent,
								data: { breadcrumb: 'Customer' }
							}
						]
						// loadChildren: './setup-employee/setup-employee.module#SetupEmployeeModule',
					},
					{
						path: 'structure',
						component: StructureLayoutComponent,
						// loadChildren: './setup-business-structure/setup-business-structure.module#SetupBusinessStructureModule',
						children: [
							{ path: 'information', component: SetupStructureInformationListComponent, data: { breadcrumb: 'Information' } },
							{ path: 'information/:Id', component: SetupStructureInformationComponent, data: { breadcrumb: 'Information' } },
							{ path: 'chain', component: SetupStructureChainListComponent, data: { breadcrumb: 'Chain' } },
							{
								path: 'chain/add-edit',
								component: SetupStructureChainComponent,
								canDeactivate: [RouteGuardGuard],
								data: { breadcrumb: 'Chain' }
							},
							{
								path: 'chain/:Id',
								component: SetupStructureChainComponent,
								canDeactivate: [RouteGuardGuard],
								data: { breadcrumb: 'Chain' }
							},
							{ path: 'region', component: SetupStructureRegionListComponent },
							{ path: 'region/add-edit', component: SetupStructureRegionComponent, canDeactivate: [RouteGuardGuard] },
							{ path: 'region/:Id', component: SetupStructureRegionComponent, canDeactivate: [RouteGuardGuard] },
							{ path: 'property', component: SetupStructurePropertyListComponent },
							{ path: 'property/add-edit', component: SetupStructurePropertyComponent, canDeactivate: [RouteGuardGuard] },
							{ path: 'property/:Id', component: SetupStructurePropertyComponent, canDeactivate: [RouteGuardGuard] },
							{ path: 'store', component: SetupStructureStoreListComponent },
							{ path: 'store/member-accounts', component: MemberAccountsComponent },
							{ path: 'store/add-edit', component: SetupStructureStoreComponent, canDeactivate: [RouteGuardGuard] },
							{ path: 'store/:Id', component: SetupStructureStoreComponent, canDeactivate: [RouteGuardGuard] },
							{ path: 'profit-center', component: SetupStructureProfitCenterListComponent },
							{
								path: 'profit-center/add-edit',
								component: SetupStructureProfitCenterComponent,
								canDeactivate: [RouteGuardGuard]
							},
							{ path: 'profit-center/:Id', component: SetupStructureProfitCenterComponent, canDeactivate: [RouteGuardGuard] },
							{ path: 'terminal', component: SetupStructureTerminalsListComponent },
							{ path: 'terminal/add-edit', component: SetupStructureTerminalComponent, canDeactivate: [RouteGuardGuard] },
							{ path: 'terminal/:Id', component: SetupStructureTerminalComponent, canDeactivate: [RouteGuardGuard] },
							{ path: 'merchant-account', component: SetupStructureMerchantAccountListComponent },
							{
								path: 'merchant-account/add-edit',
								component: SetupStructureMerchantAccountComponent,
								canDeactivate: [RouteGuardGuard]
							},
							{
								path: 'merchant-account/:Id',
								component: SetupStructureMerchantAccountComponent,
								canDeactivate: [RouteGuardGuard]
							},
							{ path: 'peripheral-list', component: SetupStructurePeripheralsListComponent },
							{
								path: 'peripheral-list/add-edit',
								component: SetupStructurePeripheralListComponent,
								canDeactivate: [RouteGuardGuard]
							},
							{
								path: 'peripheral-list/:Id',
								component: SetupStructurePeripheralListComponent,
								canDeactivate: [RouteGuardGuard]
							}
						],
						data: {
							breadcrumb: 'Structure'
						}
					}
				]
			},
			{
				path: 'company',
				canActivateChild: [RoleGuard],
				children: [
					{ path: 'information', loadChildren: () => import('./setup-company/setup-company.module').then(m => m.SetupCompanyModule) },
					{ path: 'representative', loadChildren: () => import('./setup-representative/setup-representative.module').then(m => m.SetupRepresentativeModule) }
				]
			},
			{
				path: 'security',
				canActivateChild: [RoleGuard],
				children: [
					{ path: 'roles', loadChildren: () => import('./setup-security-roles/setup-security-roles.module').then(m => m.SetupSecurityRolesModule) }
					// { path: 'user', loadChildren: './setup-representative/setup-representative.module#SetupRepresentativeModule' },
				]
			},
			{
				path: 'billing',
				loadChildren: () => import('./setup-billing/setup-billing.module').then(m => m.SetupBillingModule)
			},
			{
				path: 'licensing',
				loadChildren: () => import('./licensing/billing.module').then(m => m.BillingModule)
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SetupRoutingModule {}
