<div>
    <transaction-advanced-search
        *ngIf="storeValue === 'TransactionsStore' || storeValue === 'SettledTransactionsStore'"
        [resetFilterForm]="resetFilterForm"
        [advancedFilterFormData]="advancedFilterFormData"
        (advanceSearchEvent)="onAdvanceSearchEvent($event)"
    ></transaction-advanced-search>
    <customer-advanced-search
        *ngIf="storeValue === 'SetupBillingCustomerStore'"
        (advanceSearchEvent)="onAdvanceSearchEvent($event)"
        [resetFilterForm]="resetFilterForm"
        [advancedFilterFormData]="advancedFilterFormData"
    ></customer-advanced-search>
</div>
