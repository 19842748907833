import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import {SummaryReportService, HttpService, TransactionActionService} from '../../services';
import { NotificationService } from '../../services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DATEFORMAT, TAGSCONSTS } from '../../../constants';
import { BusinessService } from '../../services';
import { TransactionsStore } from '../../../store';
import { validateFor,convertArrToString } from '../../../common-functions/'

const STRLENGTH = 30;
// interface report {
//   brand:string;
//   totQty:number;
//   baseTot:number;
//   tipTot:number;
//   cashbackTot:number;
//   taxTot:number;
//   total:number;
// }
@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.scss'],

})

export class SummaryReportComponent implements OnInit, OnChanges, AfterViewInit {
  objectKeys=Object.keys; //To loop across an Object in html.
  @Input() showModal:boolean;
  @Input() showTxn:boolean= false;
  @Input() showBatch:boolean = false;
  @Input() hideBatchTxn:boolean = true;
  @Input() store:any = {};
  @Input() type:string;
  @Input() successIDs:string[];
  @Input() failedIDs:string[];
  @Input() fetchByTxnNumber:boolean=false;
  @Input() fetchByActionID:boolean=false;
  @Input() actionID:string='';
  // @Input() public batchTxnParameters: any;
  @Output() onCloseModal = new EventEmitter<boolean>();
  @Output() printBatchDetail = new EventEmitter<boolean>();
  @Output() printFinalBatchDetail = new EventEmitter<boolean>();
  @ViewChild('summaryReportModal') public summaryReportModal: ModalDirective;
  
  attributes:any[];
  alive:boolean=true;
  finalReportData:any={};
  totalSummary: any = {};
  loading:boolean=true;
  error:boolean=false;
  hideBatchTxnTable:boolean=true;
  checkedObjs:any={};
  unCheckedObjs:any={};
  modelIds: string[] = [];
  modelNums: string[] = [];
  public open:boolean=true;
  responseType:string="";
  printLoader: boolean=false;
  // public showBatchTxn:boolean=true;
  public forAllHeadings:any={
    totQty: "Qty",
    baseTot: "Base Total",
    tipTot: "Tip Total",
    surchargeTot: "Service Charge",
    cashbackTot: "Cashback Total",
    taxTot: "Tax Total",
    merTot: "Merchant Total",
    total: "Total",
  };

  public fetchByStore: boolean = true; //to be sent(true) in order to make parameters field have effect while fetching grid records
  public showSearchBar: boolean = false; // to be sent(false) in order to display grid-cmp normally i.e without search bar and specific css
  public txnAttributes: any[] = [];
  public txnBatchStoreValue: string = '';
  // public batchTxnAttributes: any[] = [];
  // public batchTxnStoreValue: string = '';
  // public batchTxnParameters: any={};
  public txnStoreObject: any = {};
  public txnAction: any[] = [];
  public txnParameters: any = {};

  public showGrid:boolean=false;
  public settleAll:boolean=false;
  public settleFew:boolean=false;
  public sorting:boolean=false;
  public title:string='';
  selectAllCase:boolean=false;
  parameters:any={};

  batchNum: string = '-';
  storeName: string = '-';
  pctrName: string = '-';

  constructor(
    private summaryReportService:SummaryReportService,
    private httpService:HttpService,
    private notificationService:NotificationService,
    private businessService: BusinessService,
    public transactionStore: TransactionsStore,
    private transactionActionService: TransactionActionService
    // private constantStore:ConstantStore
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('showModal') && changes.showModal){
      console.log('changes2 in summary report',changes);
    }
    if(changes.hasOwnProperty('failedIDs') && this.failedIDs.length >= 0){
      // this.modelIds = this.failedIDs.map((id)=>id);
    }
    if(changes.hasOwnProperty('hideBatchTxn') && !changes.hideBatchTxn.firstChange){
      console.log("Show txn also", changes.hideBatchTxn.currentValue);
      // if(!changes.hideBatchTxn.currentValue){
      //   // setTimeout(()=>{
      //   //   this.printBatchDetail.emit(true);
      //   // }, 100);
      //   this.batchTxnStoreValue = 'TransactionsStore';
      //   this.batchTxnAttributes = [
      //     // { header: "Sel. All", mapTo: "", width: "1%" },
      //     { header: "Type", mapTo: "xTxnActTag", width: "10%" },
      //     { header: "Transaction #", mapTo: "xTxnNum", width: "11%" },
      //     { header: "Invoice", mapTo: "xInvNum", width: "10%" },
      //     // { header: "Svr.", mapTo: "xClerkNum", width: "6%" },
      //     { header: "Card", mapTo: "xMdmTag", width: "5%" },

      //     // { header: 'Sys. Tr. Num', mapTo: 'xSysTrcNum', width: '15%' },
      //     { header: "Account #", mapTo: "xMaskedAcctNum", width: "11%" },
      //     // { header: 'Card Type', mapTo: 'xBrandTag', width: '10%' },
      //     { header: "Entry", mapTo: "xAcctEntrTag", width: "6%" },
      //     { header: "Amount", mapTo: "xMainAmt", width: "8%" },
      //     { header: "Tip", mapTo: "xTipAmt", width: "6%" },
      //     // { header: "Tax", mapTo: "xIncTaxAmt", width: "5%" },

      //     { header: "Total", mapTo: "xTtlAmt", width: "8%" },
      //     { header: "App Code", mapTo: "xAuthCode", width: "8%" },
      //     {
      //         header: "Auth Time",
      //         mapTo: "xAuthDateTime",
      //         width: "8%",
      //         // type: "date",
      //         // format: DATEFORMAT
      //     },
      //     // {
      //     //     header: "Finalize Tm",
      //     //     mapTo: "xFinalizeTime",
      //     //     width: "8%",
      //     //     type: "date",
      //     //     format: DATEFORMAT
      //     // },
      //     // {
      //     //     header: "State",
      //     //     // mapTo: "xPullSyncInfo.xHasPendingSync",
      //     //     width: "8%",
      //     //     // nested: true
      //     // }
      //     // { header: 'Actions', mapTo: '', width: '5%' }
      //   ];
      //   this.batchTxnParameters = {
      //     'xBatchID' : this.modelIds.toString(),
      //     'noGrid' : true,
      //     'fetchAll' : true
      //   };

      // }
      // else{
      //   console.log("Why not here??");
      //   this.hideBatchTxnTable=this.hideBatchTxn;
      //   setTimeout(()=>{
      //     this.printFinalBatchDetail.emit(true);
      //   }, 10);
      // }
      // // this.modelIds = this.failedIDs.map((id)=>id);
    }
  }

  ngAfterViewInit() {
    // this.checkedObjs=this.summaryReportService.checkedObjs.getValue();
    if(this.showModal){
      this.summaryReportModal.show();
    }
    this.GetDataForReport();
  }


  GetDataForReport(){
    console.log("inside get Data", this.checkedObjs);
    // let modelIds = this.checkedObjs.map((obj)=>obj.id);
    // this.modelIds = this.checkedObjs.map((obj)=>obj.id);
    console.log("inside get Data", this.modelIds);
    let requestPayload={};
    if(this.settleFew){
      console.log("HEREEEEEEEEEEEEEEEEEEE", this.modelNums);
      if(this.type === 'txn'){
        requestPayload={
          // txnNums: this.modelNums
          refNums: this.modelNums
        }
      }
    }
    else if(this.settleAll){
      if(this.type === 'txn'){
        requestPayload={
          queue_id: this.actionID
          // requestForType: this.responseType
        }
      }
    }
    else{
      if(this.type==='txn'){
        if(this.selectAllCase){
          requestPayload={
            selectAll: true,
            excludeIDs: this.modelIds.toString(),
            queryParams: JSON.parse(JSON.stringify(this.parameters))
          }
          if(requestPayload && requestPayload.hasOwnProperty('queryParams')){
            if(requestPayload['queryParams'].hasOwnProperty('limit'))
                delete requestPayload['queryParams'].limit;
            if(requestPayload['queryParams'].hasOwnProperty('page'))
                delete requestPayload['queryParams'].page;
          }
        }
        else{
            requestPayload= {
              bizId: this.businessService.businessID.getValue()? this.businessService.businessID.getValue(): null,
              ids: this.modelIds,
              type: this.type //TOTO-: fetch type from component or service [hardcoded for now]
            }
        }
  
      }
      else if(this.type === 'settledTxn'){
        if(this.selectAllCase){
          requestPayload={
            selectAll: true,
            excludeIDs: this.modelIds.toString(),
            queryParams: JSON.parse(JSON.stringify(this.parameters))
          }
          if(requestPayload && requestPayload.hasOwnProperty('queryParams')){
            if(requestPayload['queryParams'].hasOwnProperty('limit'))
                delete requestPayload['queryParams'].limit;
            if(requestPayload['queryParams'].hasOwnProperty('page'))
                delete requestPayload['queryParams'].page;
          }
        }
        else{
          requestPayload= {
            bizId: this.businessService.businessID.getValue()? this.businessService.businessID.getValue(): null,
            ids: this.modelIds,
            type: this.type //TOTO-: fetch type from component or service [hardcoded for now]
          }
        }
      }
      else if(this.type === 'batch'){
          requestPayload= {
            bizID: this.businessService.businessID.getValue()? this.businessService.businessID.getValue(): null,
            batchID: this.modelIds[0],
            // isSettled: false
          }
      }
    }
    // if((requestPayload.hasOwnProperty('ids') && requestPayload['ids'].length > 0) || requestPayload.hasOwnProperty('batchID') && requestPayload['batchID'].length > 0 )
    // {
      this.summaryReportService.getReports(requestPayload, this.type).subscribe(
          (response: any) => {
              console.log("getReports --->", response);
              if(this.selectAllCase && this.type != 'settledTxn' && this.type != 'txn'){
                let abc= setInterval(()=>{
                  this.httpService.get('action_worker_queues',response.action_id)
                  .subscribe(res=>{
                    console.log("response in select all case", res);
                    if(res.action_progress === 100){
                      clearInterval(abc);
                      if(res.hasOwnProperty('status') && res['status'] === 'Failed'){
                        this.onHideModal();
                        this.notificationService.error("Something went wrong. Could not generate report.", "Error");
                      }
                      else{
                        console.log("finalresponse", res);
                        let response = res.action_output.responseObj;
                        this.totalSummary = response['All'] && response['All'].length? response['All'][0]: {};
                        if(this.totalSummary.hasOwnProperty('brand'))
                          delete this.totalSummary['brand'];

                          if(validateFor('txnInfoObj',response)){
                            this.batchNum = validateFor('batchNum',response.txnInfoObj) 
                              ? convertArrToString(response.txnInfoObj.batchNum) 
                              : '-';
                            this.pctrName = validateFor('pctrName',response.txnInfoObj) 
                              ? convertArrToString(response.txnInfoObj.pctrName) 
                              : '-';
                            this.storeName = validateFor('storeName',response.txnInfoObj) 
                              ? convertArrToString(response.txnInfoObj.storeName) 
                              : '-';
                          }
                          delete response.txnInfoObj;
  
                        this.finalReportData = response;
                        console.log("fnal", this.finalReportData);
                        if(this.finalReportData.hasOwnProperty('All'))
                          delete this.finalReportData['All'];
    
                        this.loading=false;
                      }
                    }
                  })
                }, 2000);

              }
              else{
                console.log("response in select manual case", response);
                this.totalSummary = response['All'] && response['All'].length? response['All'][0]: {};
                if(this.totalSummary.hasOwnProperty('brand'))
                  delete this.totalSummary['brand'];

                  if(validateFor('txnInfoObj',response)){
                    this.batchNum = validateFor('batchNum',response.txnInfoObj) 
                      ? convertArrToString(response.txnInfoObj.batchNum) 
                      : '-';
                    this.pctrName = validateFor('pctrName',response.txnInfoObj) 
                      ? convertArrToString(response.txnInfoObj.pctrName) 
                      : '-';
                    this.storeName = validateFor('storeName',response.txnInfoObj) 
                      ? convertArrToString(response.txnInfoObj.storeName) 
                      : '-';
                  }
                  delete response.txnInfoObj;  

                this.finalReportData = response;
                console.log("fnal", this.finalReportData);
                if(this.finalReportData.hasOwnProperty('All'))
                  delete this.finalReportData['All'];

                this.loading=false;
              }
              
              
          },
          (err: any) => {
              console.log("Error", err);
              this.error=true;
              this.notificationService.error('Unable to fetch reports', 'Error');
          },
          () => {

          }
      );
    // }
    // else{
    //   this.notificationService.error('Unable to fetch reports. Please try again', 'Error');
    //   this.onHideModal();
    // }
  }

  ngOnInit() {
    if(this.showTxn)
      this.title = "Transaction Detail";
    else if(this.showBatch){
      this.title = "Batch Detail";
    }
    else
      this.title = "Summary Report";
    console.log("this.fetch", this.fetchByTxnNumber);
    if(this.fetchByTxnNumber){
      this.fetchByActionID=false;
      if(this.successIDs && this.successIDs.length){
        console.log("success numbers in summary report", JSON.parse(JSON.stringify(this.successIDs)));
        this.settleFew=true;
        this.transactionActionService.settleSuccess=true;
        this.transactionActionService.settleFailed=false;
        this.responseType="succ";
        this.modelNums = this.successIDs.map((number)=>number);
      }
      else if(this.failedIDs && this.failedIDs.length){
        console.log("failed numbers in summary report", JSON.parse(JSON.stringify(this.failedIDs)));
        this.settleFew=true;
        this.transactionActionService.settleFailed=true;
        this.transactionActionService.settleSuccess=false;
        this.responseType="fail";
        this.modelNums = this.failedIDs.map((id)=>id);
      }
    }
    else if(this.fetchByActionID){
      this.settleAll=true;
      this.fetchByTxnNumber=false;
      if(this.successIDs && this.successIDs.length){
        console.log("success numbers in summary report", JSON.parse(JSON.stringify(this.successIDs)));
        // this.settleFew=true;
        this.transactionActionService.settleSuccess=true;
        this.transactionActionService.settleFailed=false;
        this.responseType="succ";
        // this.modelNums = this.successIDs.map((number)=>number);
      }
      else if(this.failedIDs && this.failedIDs.length){
        console.log("failed numbers in summary report", JSON.parse(JSON.stringify(this.failedIDs)));
        // this.settleFew=true;
        this.transactionActionService.settleFailed=true;
        this.transactionActionService.settleSuccess=false;
        this.responseType="fail";
        // this.modelNums = this.failedIDs.map((id)=>id);
      }
      console.log("fetchby action id",this.fetchByActionID, this.actionID);
    }
    else{
      this.settleAll=false;
      this.settleFew=false;
      this.transactionActionService.settleSuccess=false;
      this.transactionActionService.settleFailed=false;

      if(this.summaryReportService.selectAll){
        console.log("HERE= select all case", this.summaryReportService.parameters, this.summaryReportService.unCheckedObjs.getValue());
        this.selectAllCase=true;
          this.parameters= this.summaryReportService.parameters ? this.summaryReportService.parameters : {xBusinessID: this.businessService.businessID.getValue()};
          this.parameters['type']= this.type;
          if(this.summaryReportService.unCheckedObjs.getValue().length > 0){
            this.unCheckedObjs=this.summaryReportService.unCheckedObjs.getValue();
            this.modelIds = this.unCheckedObjs.map((obj)=>obj.id);
          }
          else {
            this.modelIds=[];
          }
        // }
      }
      else if(this.summaryReportService.checkedObjs.getValue().length > 0 || this.summaryReportService.unCheckedObjs.getValue().length > 0){
        console.log("HERE other case", this.summaryReportService.checkedObjs.getValue());
        this.selectAllCase=false;
        // if(this.summaryReportService.print){
        //   console.log("HERE in print case", this.summaryReportService.parameters, this.summaryReportService.checkedObjs.getValue())
        //   this.checkedObjs=this.summaryReportService.checkedObjs.getValue();
        //   this.modelIds = this.checkedObjs.map((obj)=>obj.id);
        //   this.modelNums = this.checkedObjs.map((obj => obj.refNums));
        // }
        // else{
          this.checkedObjs=this.summaryReportService.checkedObjs.getValue();
          this.modelIds = this.checkedObjs.map((obj)=>obj.id);
          this.modelNums = this.checkedObjs.map((obj => obj.refNums));
        // }
      }
      else if(this.successIDs && this.successIDs.length){
        console.log("successIDs in summary report", JSON.parse(JSON.stringify(this.successIDs)));
        this.selectAllCase=false;
        this.responseType="succ";
        this.modelIds = this.successIDs.map((id)=>id);
      }
      else if(this.failedIDs && this.failedIDs.length){
        console.log("failedIDs in summary report", JSON.parse(JSON.stringify(this.failedIDs)));
        this.selectAllCase=false;
        this.responseType="fail";
        this.modelIds = this.failedIDs.map((id)=>id);
      }
    }

    // console.log("CheckedObj in summary report", JSON.parse(JSON.stringify(this.summaryReportService.checkedObjs.getValue())));

    this.attributes = [
      { header: 'Type', mapTo:'brand', width: '10%'},
      { header: 'Qty', mapTo: 'totQty', width: '8%' },
      { header: 'Base Total', mapTo: 'baseTot', width: '8%' },
      { header: 'Tip Total', mapTo: 'tipTot', width: '10%' },
      { header: 'ServiceChg Total', mapTo: 'surchargeTot', width: '10%' },
      { header: 'Cashback Total', mapTo: 'cashbackTot', width: '12%' },
      { header: 'Tax Total', mapTo: 'taxTot', width: '8%' },
      { header: 'Merchant Total', mapTo: 'merTot', width: '8%' },
      { header: 'Total', mapTo:'total', width: '8%'}
    ];


    if(this.showTxn || this.showBatch)
      this.setGridProperties();
  }

  truncateString(value: any): any {
		if (typeof value === 'string') {
			if (value.length > STRLENGTH) {
				return value.substring(0, STRLENGTH) + '...';
			} else {
				return value;
			}
		} else {
			return value;
		}
  }

	printSummaryReport(body) {
		// console.log('body---->', body.nativeElement);
		// this.printElement(body.nativeElement);
    // this.httpService.
    // this.printElement(body)
    
    this.printLoader=true;
    this.httpService
        .store("generateHTMLs", {
          storeType: this.type,
          bizID: this.businessService.businessID.getValue(),
          modelNums: this.modelNums,
          timeZoneOffset:new Date().getTimezoneOffset(),
          fileType:"PDF",
        })
        .subscribe(
            res => {
                console.log("123 in summary report==>", res);
                // this.printElement(res.htmlString)
                let $printSection = document.getElementById("printSection");
                if(!$printSection){
                  $printSection = document.createElement("div");
                  $printSection.id = "printSection";
                  document.body.appendChild($printSection);
                }
                $printSection.innerHTML = res.htmlString;
                print();
                this.printLoader=false;
            },
            err => {
                console.log("1`12", err);
            }
        );
  }

  printExportReport(body) {
		// console.log('body---->', body.nativeElement);
		// this.printElement(body.nativeElement);
    // console.log('body---->', body, this.modelNums);
    // this.httpService.
    // this.printElement(body)
    
    this.printLoader=true;
    this.httpService
        .store("generateCSV", {
          storeType: this.type,
          bizID: this.businessService.businessID.getValue(),
          modelNums: this.modelNums,
          timeZoneOffset:new Date().getTimezoneOffset(),
          fileType:"CSV",
        })
        .subscribe(
            res => {
                // console.log("123 in summary report==>", res);
                // this.printElement(res.htmlString)
                this.printLoader=false;
                var arrayData = [];
                var isSummary = false;

            
                this.downloadBlob(res.htmlString, "Transactions_Detail.csv", "text/csv;charset=utf-8;");
            },
            err => {
                console.log("1`12", err);
            }
        );
  }

  downloadBlob(content, filename, contentType) {
		// Create a blob
		var blob = new Blob([content], { type: contentType });
		var url = URL.createObjectURL(blob);
	  
		// Create a link to download it
		var pom = document.createElement('a');
		pom.href = url;
		pom.setAttribute('download', filename);
		pom.click();
	}


  printElement(element){
    this.printLoader=true;
    let domClone = element.cloneNode(true);

    let $printSection = document.getElementById("printSection");

    if (!$printSection) {
      console.log('Always here!!');
        $printSection = document.createElement("div");
        $printSection.id = "printSection";
        document.body.appendChild($printSection);
    }

    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    console.log('new div --->', $printSection);
    print();
    this.printLoader=false;
    if(this.type === 'txn'){
      this.summaryReportModal.hide();
    }
  }

  onHideModal(event?: any) {
  	this.finalReportData={};
  	this.checkedObjs={};
    this.onCloseModal.emit(true);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  setGridProperties() {
    this.txnStoreObject = this.store;
    if(this.type === 'txn'){
      this.txnBatchStoreValue = 'TransactionsStore';
      this.txnAttributes = [
        // { header: "Sel. All", mapTo: "", width: "1%" },
        { header: "Type", mapTo: "xTxnActTag", width: "5%" },
        { header: "Transaction #", mapTo: "xTxnNum", width: "11%" },
        { header: "Customer#", mapTo: 'xCustNum', width: '10%' },
        { header: "Invoice", mapTo: "xInvNum", width: "8%" },
        // { header: "Svr.", mapTo: "xClerkNum", width: "6%" },
        { header: "Card", mapTo: "xBrandTag", width: "5%" },

        // { header: 'Sys. Tr. Num', mapTo: 'xSysTrcNum', width: '15%' },
        { header: "Account #", mapTo: "xMaskedAcctNum", width: "9%" },
        // { header: 'Card Type', mapTo: 'xBrandTag', width: '10%' },
        { header: "Entry", mapTo: "xAcctEntrTag", width: "6%" },
        { header: "Amount", mapTo: "xMainAmt", width: "8%" },
        { header: "Tip", mapTo: "xTipAmt", width: "6%" },
        // { header: "Tax", mapTo: "xIncTaxAmt", width: "5%" },

        { header: "Total", mapTo: "xTtlAmt", width: "8%" },
        { header: "App Code", mapTo: "xAuthCode", width: "8%" },
        {
            header: "Auth Time",
            mapTo: "xAuthDateTime",
            width: "8%",
            // type: "date",
            // format: DATEFORMAT
        },
        // {
        //     header: "Finalize Tm",
        //     mapTo: "xFinalizeTime",
        //     width: "8%",
        //     type: "date",
        //     format: DATEFORMAT
        // },
        // {
        //     header: "State",
        //     // mapTo: "xPullSyncInfo.xHasPendingSync",
        //     width: "8%",
        //     // nested: true
        // }
        // { header: 'Actions', mapTo: '', width: '5%' }
    ];
    }
    else if(this.type === 'settledTxn'){
      this.txnBatchStoreValue = 'SettledTransactionsStore';
      this.txnAttributes = [
        // { header: "Sel. All", mapTo: "", width: "1%" },
        { header: "Type", mapTo: "xTxnActTag", width: "5%" },
        { header: "Transaction #", mapTo: "xTxnNum", width: "12%" },
        { header: "Customer #", mapTo: 'xCustNum', width: '12%'},
        { header: "Invoice", mapTo: "xInvNum", width: "9%" },
        // { header: "Svr.", mapTo: "xClerkNum", width: "6%" },
        { header: "Card", mapTo: "xBrandTag", width: "5%" },

        // { header: 'Sys. Tr. Num', mapTo: 'xSysTrcNum', width: '15%' },
        { header: "Account #", mapTo: "xMaskedAcctNum", width: "11%" },
        // { header: 'Card Type', mapTo: 'xBrandTag', width: '10%' },
        { header: "Entry", mapTo: "xAcctEntrTag", width: "6%" },
        { header: "Amount", mapTo: "xMainAmt", width: "8%" },
        { header: "Tip", mapTo: "xTipAmt", width: "6%" },
        // { header: "Tax", mapTo: "xIncTaxAmt", width: "5%" },

        { header: "Total", mapTo: "xTtlAmt", width: "6%" },
        { header: "App Code", mapTo: "xAuthCode", width: "8%" },
        {
            header: "Auth Time",
            mapTo: "xAuthDateTime",
            width: "8%",
            // type: "date",
            // format: DATEFORMAT
        },
        // {
        //     header: "Finalize Tm",
        //     mapTo: "xFinalizeTime",
        //     width: "8%",
        //     type: "date",
        //     format: DATEFORMAT
        // },
        // {
        //     header: "State",
        //     // mapTo: "xPullSyncInfo.xHasPendingSync",
        //     width: "8%",
        //     // nested: true
        // }
        // { header: 'Actions', mapTo: '', width: '5%' }
    ];
    }
    else if(this.type === 'batch'){
      this.txnBatchStoreValue ='BatchStore';
      this.txnAttributes = [
        // { header: 'Status', mapTo: 'xStatus', width: '7%' },
        { header: 'Number', mapTo: 'xBatchNum', width: '20%' },
        { header: 'Merchant Account', mapTo: 'xMerchantNum', width: '20%' },
        {
            header: 'Open Date',
            mapTo: 'xOpenDateTime',
            width: '16%',
            // type: "date",
            // format: DATEFORMAT                   //TODO-: It has changed to 'number'. This is needed to be discussed with Jim and then accordingly pipe is to be designed and implemented
        },
        {
            header: 'Settle Date',
            mapTo: 'xSettleDateTime',
            width: '12%',
            // type: "date",
            // format: DATEFORMAT                   //TODO-: It has changed to 'number'. This is needed to be discussed with Jim and then accordingly pipe is to be designed and implemented
        },
        // { header: 'Total Debit', mapTo: 'xTtlDebitAmt', width: '10%' },
        // { header: 'Total Credit', mapTo: 'xTtlCreditAmt', width: '10%' },
        {header: "Net Total", mapTo: "netTotal", width: '20%' },
        // { header: 'Actions', mapTo: '', width:'10%' },
        { header: 'State', width: "12%" }
      ];


    }



      this.txnAction = [];
      if(this.settleFew){
        if(this.type === 'txn'){
          this.txnParameters = {
            'txnNums' : this.modelNums,
            // 'txnNums' : this.modelNums,
            'refNums' : this.modelNums,
            // 'noGrid' :
          };
        }
      }
      else if(this.settleAll){
        if(this.type === 'txn'){
          this.txnParameters = {
            'queue_id' : this.actionID,
            // 'noGrid' :
          };
        }
      }
      else{
        console.log("HERE BUDDY", this.summaryReportService.parameters);
        if(this.type === 'txn'){
          console.log("HERE BUDDY 1");
          if(this.summaryReportService.print){
            this.txnParameters=JSON.parse(JSON.stringify(this.summaryReportService.parameters));
          }
          else{
            this.txnParameters = {
              'txnIds' : this.modelIds.toString(),
              // 'noGrid' :
            };  
          }
        }
        else if(this.type === 'settledTxn'){
          console.log("HERE BUDDY 2");
          if(this.summaryReportService.print){
            this.txnParameters=JSON.parse(JSON.stringify(this.summaryReportService.parameters));
          }
          else{
            this.txnParameters = {
              'txnIds' : this.modelIds.toString()
            };
          }
        }
        else if(this.type === 'batch'){
          this.txnParameters = {
            'batchIds' : this.modelIds.toString(),
          };
        }
      }
      this.showGrid = true;
  }

  onTxnGridDataLoad(event){
    console.log("Event in summary report---->", event);
    // this.hideBatchTxn=true;
    if(!this.hideBatchTxn){
      this.hideBatchTxnTable=this.hideBatchTxn;
    }
    setTimeout(()=>{
      // this.hideBatchTxnTable=true;
      this.printBatchDetail.emit(true);
    }, 100);
  }
}
