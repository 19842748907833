<div bsModal #previewPermissionModal="bs-modal" class="modal fade edit-perm-modal preview-modal" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true" (onHide)="onHideModal($event)">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="previewPermissionModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Preview Permissions For Warren</h4>
            </div>
            <div class="preview">
                <div class="scroll">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Role</th>
                                <th>Priority</th>
                                <th>Module</th>
                                <th>Function</th>
                                <th>Permission</th>
                            </tr>
                        </thead>
                        <tbody class="" *ngIf="!loadingPreview && !!previewList.length">
                            <ng-container *ngFor="let prev of previewList">
                                <tr>
                                    <td>
                                        {{prev.roleName}}
                                    </td>
                                    <td>
                                        {{prev.xPermLvl}}
                                    </td>
                                    <td>
                                        {{prev.xMduTag}}
                                    </td>
                                    <td>
                                        {{prev.xFuncTag}}
                                    </td>
                                    <td>
                                        {{prev.permission}}
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                        <tbody class="no-permission" *ngIf="!loadingPreview && !previewList.length">
                            <label><h5>{{ NO_PERMISSION_ADDED_MESSAGE }}</h5></label> 
                        </tbody>
                        <tbody *ngIf="loadingPreview">
                            <tr>
                                <td style="text-align: center;" [colSpan]="6">
                                    <div class="grid-spinner">
                                            <div class="bounce1"></div>
                                            <div class="bounce2"></div>
                                            <div class="bounce3"></div>
                                    </div>
                                    <span style='color:#3b5877'>Fetching Permisssions</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-xs-12  clearfix">
                        <button type="button" class="btn btn btn-primary pull-right" (click)="previewPermissionModal.hide()">Ok <i class="fa fa-check" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>