<section *ngIf="businessService?.businessID?.getValue()">
  <form class="form-horizontal">
    <div class="grid-add-wrap">
        <span class="pull-right tooltip-wrap-span m-b-5"  container="body" containerClass="tooltip-global" placement="left" tooltip={{tooltipMessage}}>
            <button class="btn btn-info btn-sm add-btn" (click)="treeService.nodeClick=false;" [routerLink]="['/dashboard/setup/business/structure/peripheral-list/add-edit']"
              type="button" [routerLinkActive]="['router-link-active']" [disabled]='!addBtn'>
              <i class="fa fa-plus-circle" aria-hidden="true"></i> Add
            </button>
          </span>
      <grid-cmp [attributes]=attributes [storeValue]=storeValue [parameters]=parameters [fetchByStore]=fetchByStore [storeObj]=storeObject
        [action]=action (actionRequest)="onActionRequest($event)" (detailVisible)="onShowDetails($event)"></grid-cmp>
    </div>
  </form>
  <details-cmp [id]=periphID [viewDetails]=viewDetails [storeValue]=storeValue [show]=showDetails [label]="'Peripheral'" [store]=storeObject
    (onViewClose)="viewClosed($event)"></details-cmp>
</section>
<section *ngIf="!businessService?.businessID?.getValue()">
    <form class="form-horizontal">
        <div class="grid-add-wrap">
            <div class="row m-b-1">
                <div class="col-sm-10 offset-sm-1 m-t-30">
                    <div class="coming-soon">
                        <h4 class="text-muted">Please select a Business</h4>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>