import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { tap, switchMap } from 'rxjs/operators';
import {
    UserHandlerService,
    AuthenticationService,
    NotificationService,
    LoginStoreService,
    HttpService,
    BusinessService
} from '../services';
import { Role } from '../../models';

const customerContextEndpoint = 'customer_context';

@Injectable()
/**
 * @brief      Class for user access resolver.
 *             Prefetches data neccessary for role based access such as user information, user type information,
 *             user organisation information.
 */
export class UserAccessResolver implements Resolve<any> {
    constructor(
        private userService: UserHandlerService,
        private router: Router,
        private authenticationService: AuthenticationService,
        private notificationService: NotificationService,
        public loginStoreService: LoginStoreService,
        private httpService: HttpService,
        public businessService: BusinessService
    ) {}

    getRoleID(roles: Role[]): string {
        return roles.length ? roles[0]._id : '';
    }

    // handleErrorResponse(error: any) {
    //     try {
    //         const err = JSON.parse(error._body);
    //         this.notificationService.error(
    //             err.name,
    //             err.message
    //         );
    //     } catch (err) {
    //         this.notificationService.error('Something went Wrong', 'ERROR');
    //     }
    // }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        console.log('user-access-resolver:start');
        console.log('user-access-resolver: route', route);
        console.log('user-access-resolver: state', state.url);
        let userType = {};
        return new Promise((resolve, reject) => {
            this.userService
                .fetchUserInformation()
                .pipe(tap((x:any) => this.userService.setUserData(x.data)))
                .subscribe(
                    (httpResponse1: Response | any) => {
                        const { data } = httpResponse1;
                        console.log('user-access-resolver: response', data);
                        const stream =
                            data.xEntyTypeTag.toLowerCase() === 'rep'
                                ? this.userService.fetchRepresentativeDetails(data.xEntyID)
                                : data.xEntyTypeTag.toLowerCase() === 'cust'
                                ? this.userService.fetchCustomerDetails(data.xEntyID)
                                : this.userService.fetchEmployeeDetails(data.xEntyID);
                        return stream.pipe(
                            switchMap(
                                (httpResponse2: Response | any) => {
                                    console.log('user-access-resolver: switchMap', httpResponse2);
                                    const response2 = httpResponse2.data;
                                    userType = httpResponse2.data;
                                    return response2.hasOwnProperty('xBusinessID')
                                        ? this.userService.fetchBusinessDetails(response2.xBusinessID)
                                        : this.userService.fetchCompanyDetails(response2.xCompanyID);
                                },
                            )
                        )
                            .subscribe(
                                (httpResponse3: Response | any) => {
                                    this.userService.organisation = httpResponse3.data;
                                    this.userService.userType = userType;
                                    this.loginStoreService.setFetchedRelevantData(false);
                                    if (data.xEntyTypeTag.toLowerCase() === 'cust') {
                                        this.httpService.store(customerContextEndpoint, {}).subscribe(
                                            res => {
                                                console.log('terminalDataFetch: res', res);
                                                this.loginStoreService.setData(res);
                                                this.businessService.setBusinessID(this.loginStoreService.storeData['xBusinessID']);
                                                this.checkRouteForCustomer(state.url);
                                                return resolve(httpResponse3);
                                            },
                                            error => {
                                                console.log('terminalDataFetch: error', error);
                                                // this.fetchingTerminal = false;
                                                this.notificationService.error('Unable to fetch Terminal data. Please try again.', 'Error');
                                                return reject(error);
                                            }
                                        );
                                    } else {
                                        return resolve(httpResponse3);
                                    }
                                },
                                (error: any) => {
                                    // console.log(error);
                                    // this.handleErrorResponse(error);
                                    this.authenticationService.logout();
                                    return reject(error);
                                }
                            );
                    },
                    (error: any) => {
                        console.log(error);
                        // this.handleErrorResponse(error);
                        this.authenticationService.logout();
                        return reject(error);
                    }
                );
        });
    }

    /*
     * check route for customer , if home redirect to transaction landing page
     *
     * @params : url: string
     */
    checkRouteForCustomer(url: string) {
        console.log('checkRouteForCustomer: url', url);
        if (!url.includes('customer')) {
            console.log('checkRouteForCustomer: redirect from home');
            this.router.navigate(['/dashboard/customer/outstanding-transactions']);
        }
    }
}
