import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TagInputModule } from 'ngx-chips';
import { SidebarModule } from 'ng-sidebar';

import { ResourceEditorModule, TreeComponentModule, GridModule, SectionsModule } from '../../../shared/modules';
import {
	SetupBusinessStructureStoreSetupStore,
	SetupStructureProfitCenterStore,
	SetupStructureTerminalStore,
	SetupStructurePeripheralStore,
	SetupPaymentProcessingMerchantAccountStore,
	ConstantStore
} from '../../../store';
import { NotificationService, XpaConstsService, XsaConstsService, GmNumberService } from '../../../shared/services';
import { FilterAccountEntriesPipe } from '../../../shared/pipes/filter-account-entries.pipe';
import { SetupBusinessStructureRoutingModule } from './setup-business-structure-routing.module';

import { SetupBusinessStructureComponent } from './setup-business-structure.component';
import {
	SetupStructureInformationComponent,
	SetupStructureChainComponent,
	SetupStructureRegionComponent,
	SetupStructurePropertyComponent,
	SetupStructureStoreComponent,
	SetupStructureProfitCenterComponent,
	SetupStructureTerminalComponent,
	SetupStructureMerchantAccountComponent,
	SetupStructurePeripheralListComponent,
	SetupStructureInformationListComponent,
	SetupStructureChainListComponent,
	SetupStructureRegionListComponent,
	SetupStructurePropertyListComponent,
	SetupStructureStoreListComponent,
	SetupStructureMerchantAccountListComponent,
	SetupStructureProfitCenterListComponent,
	SetupStructureTerminalsListComponent,
	SetupStructurePeripheralsListComponent,
	ConfigModalComponent
	// StandAloneVTermLinkModalComponent
} from './components';
// import { MemberAccountsModule } from 'app/shared/modules/member-accounts/member-accounts.module';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ModalModule.forRoot(),
		TooltipModule.forRoot(),
		TabsModule.forRoot(),
		SidebarModule.forRoot(),
		ReactiveFormsModule,
		GridModule,
		ResourceEditorModule,
		TreeComponentModule,
		// SharedPipesModule,
		SectionsModule,
		TagInputModule,
		SetupBusinessStructureRoutingModule
		// MemberAccountsModule
	],
	declarations: [
		SetupBusinessStructureComponent,
		FilterAccountEntriesPipe,
		SetupStructureInformationComponent,
		SetupStructureChainComponent,
		SetupStructureRegionComponent,
		SetupStructurePropertyComponent,
		SetupStructureStoreComponent,
		SetupStructureProfitCenterComponent,
		SetupStructureTerminalComponent,
		SetupStructureMerchantAccountComponent,
		SetupStructurePeripheralListComponent,
		SetupStructureInformationListComponent,
		SetupStructureChainListComponent,
		SetupStructureRegionListComponent,
		SetupStructurePropertyListComponent,
		SetupStructureStoreListComponent,
		SetupStructureMerchantAccountListComponent,
		SetupStructureProfitCenterListComponent,
		SetupStructureTerminalsListComponent,
		SetupStructurePeripheralsListComponent,
		ConfigModalComponent
		// StandAloneVTermLinkModalComponent
	],
	exports: [SetupBusinessStructureComponent],
	providers: [
		NotificationService,
		XpaConstsService,
		XsaConstsService,
		SetupBusinessStructureStoreSetupStore,
		SetupStructureProfitCenterStore,
		SetupStructureTerminalStore,
		SetupStructurePeripheralStore,
		SetupPaymentProcessingMerchantAccountStore,
		ConstantStore,
		GmNumberService
	]
})
export class SetupBusinessStructureModule {}
