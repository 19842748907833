<div *ngIf="isLoading">
	<div style="text-align: center;">
		<!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
    <span class="sr-only">Loading...</span> -->
		<div class="grid-spinner">
			<div class="bounce1"></div>
			<div class="bounce2"></div>
			<div class="bounce3"></div>
		</div>
		<span style="color:#3b5877">Loading</span>
	</div>
</div>
<div *ngIf="!isLoading" [class.expand]="showTree" class="full-content-wrapper">
	<div class="sidebar-tree-wrapper" [ngClass]="{ 'disable-form': !enableTree }">
		<tree-cmp
			[addAction]="treeAddAction"
			[buttonLinks]="treeButtonLinks"
			[nodeLinks]="treeNodeLinks"
			[showUptoStore]="true"
			(nodeClicked)="onNodeClick($event)"
			(buttonClicked)="onButtonClick($event)"
		></tree-cmp>
	</div>
	<div class="main-tabbed-content" [ngClass]="userHandlerService.userRoles.isVendor ? '' : ''">
		<div class="inner-menu-wrap">
			<ul class="nav nav-justified inner-menu">
				<li class="nav-item" *ngFor="let item of tabItems">
					<a class="nav-link" [routerLink]="item.link" [routerLinkActive]="['active']">{{ item.label }}</a>
				</li>
			</ul>
		</div>
		<div class="inner-content">
			<div class="main-content-inner padder">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>
