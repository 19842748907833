import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { skip, takeWhile } from 'rxjs/operators';
import {
	BreadcrumbService,
	FilterService,
	UserHandlerService,
	NotificationService,
	TreeService,
	UrlStoreService,
	LoginStoreService
} from '../../shared/services';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
	public dt: Date = new Date();
	public minDate: Date = void 0;
	public treeAddAction: boolean;
	public treeButtonLinks: any;
	public treeNodeLinks: any;
	public JSON = JSON;
	public isLoading = false;
	public showTree: boolean = false;
	public enableTree: boolean = true;
	alive: boolean = true;
	treeVisible: boolean = true;
	loginStoreObj: any = {};

	tabItems: { label: string; link: string }[] = [
		{ label: 'Dashboard', link: '/dashboard/home' },
		{
			label: 'Outstanding Transactions',
			link: '/dashboard/outstanding-transactions'
		},
		{
			label: 'Outstanding Batches',
			link: '/dashboard/outstanding-batches'
		},
		{
			label: 'Settled Transactions',
			link: '/dashboard/settled-transactions'
		},
		{ label: 'Settled Batches', link: '/dashboard/settled-batches' }
		// { label: "Status", link: "/dashboard/statuses" },
		// // { label: "Application Logs", link: "/dashboard/application-logs" },
		// // { label: "System Logs", link: "/dashboard/system-logs" },
		// { label: "Statistics", link: "/dashboard/statistics" }
	];

	constructor(
		private breadcrumbService: BreadcrumbService,
		private router: Router,
		private filterService: FilterService,
		public userHandlerService: UserHandlerService,
		private notificationService: NotificationService,
		private treeService: TreeService,
		public urlStoreService: UrlStoreService,
		public loginStoreService: LoginStoreService
	) {
		//this.urlStoreService.swapUrl(router.url);
		this.treeAddAction = false;
		this.treeButtonLinks = {};
		this.treeNodeLinks = {};
	}

    ngOnInit(): void {
        console.log('login store service', JSON.parse(JSON.stringify(this.loginStoreService.storeObj)));
        console.log('type logged in', this.userHandlerService.userRoles);
        // this.loginStoreObj = this.loginStoreService.storeObj;
        this.filterService.setFilterValue(true);
        this.setBreadCrumbValue();
        this.setTreeAttributes();
		this.treeService.watchShowTree$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
            .subscribe((show: boolean) => {
                if (this.userHandlerService.userRoles.isCustomer) {
                    this.showTree = false;
                } else {
                    this.showTree = show;
                }
                console.log('layout-cmp:showTree:', this.showTree);
            });
        this.treeService.watchEnableTree$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
            .subscribe((enable: boolean) => {
                if (this.userHandlerService.userRoles.isCustomer) {
                    this.showTree = false;
                    this.enableTree = true;
                } else {
                    this.enableTree = enable;
                    this.showTree = !enable;
                }
                console.log('layout-cmp:enableTree', this.enableTree);
                console.log('layout-cmp:showTree', this.showTree);
            });
        this.loginStoreObj = this.loginStoreService.storeObj;
    }

	setTreeAttributes(): void {
		this.treeButtonLinks = {};
		this.treeAddAction = false;
		this.treeNodeLinks.chain = 'chain';
		this.treeNodeLinks.property = 'property';
		this.treeNodeLinks.store = 'store';
		this.treeNodeLinks.profitCenter = 'profitCenter';
	}
	setBreadCrumbValue(): void {
		const breadCrumb: any = {};
		breadCrumb.root = 'Home';
		breadCrumb.child = '';
		breadCrumb.subChild = '';
		breadCrumb.subChildLink = null;
		breadCrumb.grandChild = null;
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	onTabBtnClick() {
		//     this.treeService.setShow(false);
		//     this.treeService.setEnable(true);
	}

	onNodeClick(node: any): void {
		console.log(node);
		// this.treeService.setId(node.nodeId, node.nodeType);
	}

	//TODO-: To be implemented later, will remove tree from status. logs and statistics page

	// transactionTabClick(tabName:string){
	//   console.log("tabname", tabName);
	//   if((tabName === 'Statuses') || (tabName === 'Application Logs') || (tabName === 'System Logs') || (tabName === 'Statistics')){
	//     this.treeVisible=false;
	//   }
	//   else{
	//     this.treeVisible=true;
	//   }
	// }

	ngOnDestroy() {
		this.alive = false;
	}
}
