import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpService } from './http-service';

const fetchPaymentMethodEndpoint = 'xpcSafes'

@Injectable()
export class PaymentService {

  private _paymentData: any = {};
  private _paymentMethod: any = {};
  private _paymentMethodOptions: any = {};
  private _lastUsedPaymentMethod: any = {};

  constructor(
    private httpService: HttpService
  ) { }

  // call api and save payment method
  savePaymentMethod(newPaymentMethodObj) {
    console.log('savePaymentMethod: ', newPaymentMethodObj);
  }

  // call api and perform payment
  performPayment(){
    console.log('performPayment: paymentData',this._paymentData)
    console.log('performPayment: paymentMethod',this._paymentMethod)
  }

  // get payment method options of user by api call
  fetchPaymentMethodOptions(params): any{
    //   const placeholderPaymentData: any[] = [
    //     { cardType: 'Visa', cardNumber: '4111111111111111', cardName: 'asdfghjkl' },
    //     { cardType: 'MasterCard', cardNumber: '4111111111111122', cardName: 'qwertyuiop' }
    // ];
    // this._paymentMethodOptions = placeholderPaymentData;
    // this._lastUsedPaymentMethod = placeholderPaymentData[0];
    console.log('fetchPaymentMethodOptions: params',params);
    return this.httpService.getAll(fetchPaymentMethodEndpoint,params,true)
      .pipe(
				map((response: Response) => {
					return response;
				})
			);
  }

  get paymentData(){
    return this._paymentData;
  }

  set paymentData(paymentDataObj){
    this._paymentData = paymentDataObj;
  }

  get paymentMethod(){
    return this._paymentMethod;
  }

  set paymentMethod(paymentMethodObj){
    this._paymentMethod = paymentMethodObj;
  }

  get paymentMethodOptions(){
    return this._paymentMethodOptions;
  }

  get lastUsedPaymentMethod(){
      return this._lastUsedPaymentMethod;
  }

  set lastUsedPaymentMethod(paymentMethodObj){
    this._lastUsedPaymentMethod = paymentMethodObj;
  }
}
