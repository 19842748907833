const strLength = 20;
const BE_META_FIELD_KEY = 'meta';
const BE_TOTAL_REC_FIELD_KEY = 'totalRecords';
const MAX_FILE_NAME_LENGTH: number = 30;
/**
 * validates if field and value exists in the object
 *
 * @params : `field` : string : the name of field to be checked
 * @params : `object` : object : the object in which field is to be validated
 * @return : `boolean` : true: validation success, false: validation failed
 */
export function validateFor(field: string, object: object = {}) {
	// console.log('validateFor:field', field);
	// console.log('validateFor:object', object);
	return object && object.hasOwnProperty(field) && object[field] ? true : false;
}

/**
 * check if provided variable is an array and is not empty
 *
 * @params : `variable` : the variable on which validation to check if array and not empty is to be checked
 * @return : `boolean` : true: variable is an array and not empty, false: variable is not an array or an empty array
 */
export function validateIfArray(variable) {
	console.log('validateIfArray: variable', variable);
	return variable && Array.isArray(variable) && variable.length ? true : false;
}

/**
 * set data in edittxns variable and check if tip amt to be added as well
 *
 * @params : `value` : string : value to be truncated
 * @params : `length`: number : truncate to specific length or use default truncate length of 20
 * @return : `value` : string : truncated value
 */
export function truncateString(value: any, length: number = 0): any {
	let truncLength = length ? length : strLength;
	if (typeof value === 'string') {
		if (value.length > truncLength) {
			return value.substring(0, truncLength) + '...';
		} else {
			return value;
		}
	} else {
		return value;
	}
}

/**
 * mask card token for display
 *
 * @params : `cardToken` : string : card token string
 * @params : `shortCardTokenLength`: number : length of card token to be returned
 * @return : `maskedToken` : string : masked card token
 */
export function maskCardToken(cardToken: string, shortCardTokenLength: number = 0) {
	let maskedToken = '';
	let cardTokenLength = cardToken.length;
	for (let i = 0; i < cardTokenLength - 4; i++) {
		maskedToken += '*';
	}
	for (let i = cardTokenLength - 4; i < cardTokenLength; i++) {
		maskedToken += cardToken.charAt(i);
	}
	if (shortCardTokenLength) {
		maskedToken = maskedToken.substr(maskedToken.length - shortCardTokenLength);
	}
	return maskedToken;
}

/**
 * get total record count from response
 *
 * @params : `response` : object : response from api call
 * @return : `count` : number : total record count
 */
export function getTotalRecordCount(response): number {
	console.log('getTotalRecordCount: response');
	return validateFor(BE_META_FIELD_KEY, response) && validateFor(BE_TOTAL_REC_FIELD_KEY, response[BE_META_FIELD_KEY])
		? response[BE_META_FIELD_KEY][BE_TOTAL_REC_FIELD_KEY]
		: 0;
}

/**
 * Reduce file name length
 * @export
 * @param {*} fileName
 * @param {*} keepBefore
 * @param {*} keepAfter
 * @returns {string}
 */
export function reduceFileNameLength(fileName, keepBefore, keepAfter): string {
	if (fileName.length < MAX_FILE_NAME_LENGTH) {
		return fileName;
	}
	let first = fileName.substring(0, keepBefore);
	let last = fileName.substring(fileName.length - keepAfter);
	let mask = '';
	let maskLength = 3;
	for (let i = 0; i < maskLength; i++) {
		mask += '.';
	}
	fileName = first + mask + last;
	return fileName;
}

/**
 * Format date in mm/dd/year
 * @param date Date chosen by user to schedule txn
 * @return formatted date
 */
export function getFormattedDate(date: Date) {
	if (!date) {
		return '-';
	} else {
		date = new Date(date);
		let year = date.getFullYear();
		let month = (1 + date.getMonth()).toString();
		month = month.length > 1 ? month : '0' + month;
		let day = date.getDate().toString();
		day = day.length > 1 ? day : '0' + day;
		// console.log('date: getFormattedDate', day + '/' + month + '/' + year);
		let hours = date.getHours().toString().length < 2 ? '0' + date.getHours().toString() : date.getHours().toString();
		let mins = date.getMinutes().toString().length < 2 ? '0' + date.getMinutes().toString() : date.getMinutes().toString();
		let time = hours + ':' + mins;
		return month + '/' + day + '/' + year + ' ' + time;
	}
}

/**
 * convert array into comma separated string
 *
 * @params : `arr` : array
 * @return : `str` : string
 */
export function convertArrToString(arr) {
	let str = '';
	arr.forEach((val, index) => {
		if (index + 1 !== arr.length) str += val + ' , ';
		else str += val;
	});
	return str;
}
