import { Component, OnInit } from '@angular/core';

import { StatisticsStore } from '../../store/index';
import { DATEFORMAT } from '../../constants';

@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

    public attributes: any[] = [];
    public storeValue: string = '';
    public storeObject: any = {};
    public action: any[] = [];
    showDetails:boolean=false;
    public parameters: any = {
        noGrid: true
    };

    // public detailVisible: boolean = true;
    public statisticID: string = '';
    public statisticType: string = 'all';



    public viewDetails: any[] = [
        { header: 'Stat. Tag', mapTo: 'xStatTag', },
        { header: 'Enty. Tag', mapTo: 'xEntyTypeTag', },
        { header: 'Stat. Type', mapTo: 'xTypeTag', },
        { header: 'Value', mapTo: 'xVal' },
        { header: 'Type of Entity combination', mapTo: 'xEnty2TypeTag' },
        { header: 'Entity combination', mapTo: 'xEnty2Tag' },
    ];

    constructor(private statisticsStore: StatisticsStore) { }

    ngOnInit() {
        this.setGridProperties();
    }

    setGridProperties() {
        this.storeValue = 'StatisticsStore';
        this.storeObject = this.statisticsStore;
        this.attributes = [
            { header: 'Stat Tag', mapTo: 'xStatTag', width: '10%' },
            { header: 'Enty. Tag', mapTo: 'xEntyTypeTag', width: '10%' },
            { header: 'Stat Type', mapTo: 'xTypeTag', width: '10%' },
            { header: 'Value', mapTo: 'xVal', width: '10%' },
            // { header: 'Type of Entity combination', mapTo: 'xEnty2TypeTag', width: '10%'},
            { header: 'Ent. combination', mapTo: 'xEnty2Tag', width: '10%' },
        ];

        this.action = [
            { type: 'view', visible: true, text: 'Details', icon: 'fa fa-eye', action: 'emit' },
        ];
    }

    onActionRequest(obj: any) {
        console.log(obj);
        if (obj.act.type === 'view') {
            this.statisticID = obj.id;
            // this.detailVisible = true;
        }
    }

    onShowDetails(obj:any){
        console.log("Show object",obj);
        this.showDetails=obj;
    }

    viewClosed(visible: boolean) {
        // this.detailVisible = visible;
        if(!visible){
            this.statisticID='';
            this.showDetails=false;
        }
    }

    fetchFilter() {
        switch (this.statisticType) {
            case 'all':
                if (this.parameters.hasOwnProperty('filterType') && this.parameters.hasOwnProperty('currDate')) {
                    this.parameters = {
                        noGrid: true,
                        // xIsSettled: true
                    }
                }
                break;
            case 'thisWeek':
                this.parameters = {
                    noGrid: true,
                    // xIsSettled: true,
                    filterType: 'thisWeek',
                    currDate: new Date().toISOString()
                };
                break;
            case 'lastWeek':
                this.parameters = {
                    noGrid: true,
                    // xIsSettled: true,
                    filterType: 'lastWeek',
                    currDate: new Date().toISOString()
                };
                break;
            case 'thisMon':
                this.parameters = {
                    noGrid: true,
                    // xIsSettled: true,
                    filterType: 'thisMon',
                    currDate: new Date().toISOString()
                };
                break;
            case 'lastMon':
                this.parameters = {
                    noGrid: true,
                    // xIsSettled: true,
                    filterType: 'lastMon',
                    currDate: new Date().toISOString()
                };
                break;
            case 'today':
                this.parameters = {
                    noGrid: true,
                    // xIsSettled: true,
                    filterType: 'today',
                    currDate: new Date().toISOString()
                };
                break;
            case 'yesterday':
                this.parameters = {
                    noGrid: true,
                    // xIsSettled: true,
                    filterType: 'yesterday',
                    currDate: new Date().toISOString()
                };
                break;
        }
    }


}
