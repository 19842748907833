import { Injectable } from '@angular/core';
import { HttpService } from '../shared/services/index';

const endpoint = 'xscEntyPerms';

@Injectable()
export class SetupPermissionsStore {
    static customerData: any[];
    static roleIdForPermission: string;

    static toggle(element: any): void {
        console.log('Inside SetupPermissionsStore toggle function', element);
    }

    static getRoleId(): string {
        return SetupPermissionsStore.roleIdForPermission;
    }

    static setRoleId(id: string): void {
        SetupPermissionsStore.roleIdForPermission = id;
    }

    constructor(protected httpService: HttpService) {}

    loadPermissionsForPreview(permissionEndpoint: string, payload: any): any {
        return this.httpService.store(permissionEndpoint, payload);
    }

    getAll(params: any): any {
        if (params.businessID) {
            delete params.businessID;
        }
        return this.httpService.getAll(endpoint, params);
    }

    store(element: any): any {
        return this.httpService.store(endpoint, element);
    }

    update(elementID: string, newElement: any): any {
        return this.httpService.update(endpoint, elementID, newElement);
    }

    get(elementID: any): any {
        return this.httpService.get(endpoint, elementID);
    }

    destroy(elementID: any): any {
        return this.httpService.destroy(endpoint, elementID);
    }
}
