import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import { MainContact, Address } from '../../../../models';
import { MESSAGES } from '../../../../constants';
import { GlobalValidator } from '../../../services';

export class BaseComponent {

    serverErrors: any = {};

    formErrors: any = {};
    // validationMessages = {};

    constructor(/*public fb: FormBuilder*/) {
        this.serverErrors = {};
    }

    onValueChanged(contactForm: FormGroup, data?: any) {
        if (!contactForm) {
            return;
        }
        const form = contactForm;

        for (const field of Object.keys(this.formErrors)) {
            if (form.get(field)['controls']) {
                for (const subfield of Object.keys(form.get(field)['controls'])) {
                    this.formErrors[field][subfield] = '';
                    const control = form.get(field)['controls'][subfield];
                    if (control && control.touched && !control.valid) {
                        const messages = MESSAGES[subfield];
                        for (const key of Object.keys(control.errors)) {
                            this.formErrors[field][subfield] += messages[key] + ' ';
                        }
                    }
                }
            } else {
                const control = form.get(field);
                this.formErrors[field] = '';
                if (control && control.touched && !control.valid) {
                    const messages = MESSAGES[field];
                    for (const key of Object.keys(control.errors)) {
                        this.formErrors[field] += messages[key] + ' ';
                    }
                }
            }
        }

    }

    validateServerErrors() {
        // console.log("Inside validator",this.serverErrors);
        for (const field of Object.keys(this.serverErrors)) {
            if (this.checkNested(this.formErrors, field)) {
                console.log("this.formErrors, field",this.formErrors, field);
                const args = field.split('.');
                let message = this.serverErrors[field] ? this.serverErrors[field].message : '';
                message = message.replace(/".*?"/, 'Field');
                if (args.length === 2) {
                    this.formErrors[args[0]][args[1]] = message;
                };

                if (args.length === 1) {
                    this.formErrors[args[0]] = message;
                };
            }
        }
    }

    checkNested(obj, structure) {
        const args = structure.split('.');

        for (let i = 0; i < args.length; i++) {
            if (!obj || !obj.hasOwnProperty(args[i])) {
                return false;
            }
            obj = obj[args[i]];
        }
        return true;
    }

    clearErrors(sectionName:string , errorName: string): void {
        console.log("errorName",errorName,this.serverErrors,this.formErrors,this.serverErrors['xMainContact.'+errorName]);
        if (this.serverErrors[sectionName+'.'+errorName]) {
            console.log("errorName2",errorName);
            delete this.serverErrors[sectionName+'.'+errorName];
            console.log('this.serverErrors',this.serverErrors);
        }
    }
}
