import { Injectable } from '@angular/core';
import { HttpService } from '../shared/services/index';

const endPoint = 'structure';

@Injectable()
export class ConstantStore {
    constructor(protected httpService: HttpService) {}
    getConstants(tag:string, endPoint?:string):any{
        if(endPoint){
            return this.httpService.getConstants(endPoint,tag)
        }
    }
}