import { AcptCaps } from './acpt-caps.model';

export class MerchantAccount {    
    xTag: String;
	xStatus: Number;
	xName: String;
	xTypeTag: String;
	xNotes: String;
	xRefNum: String;
    xTntTag:String;
    xWall1EntyID:String;
    xWall2EntyID:String;
    xRefNum2:String;
    xMainTerminalNum:String;
    xMerAcctNum:String;
    xBusinessID:String;
    xStoreID:String;
    xProcTag:string;
    xCaptTag:String;
    xIndstTag:String;
    xAttnTag:String;
    xPresTag:String;
    xAcptCaps: AcptCaps;
    xProcParams: [
        {
            xParamTag: String;
            xParamKey: String;
            xParamVal: String;
        }
    ];
    xMainPCtrID:String;
    xMapping: {
        xPCtrShortCodes:String;
        xMdmTags:String;
        xBrandTags:String;
    };
    xAutoSettleTime:Number;
    // xDBAudit: xDBAudit
    constructor(obj?:any){
        this.xTag= (obj && obj.xTag) || '';
        this.xStatus= (obj && obj.xStatus) || '';
        this.xName= (obj && obj.xName) || '';
        this.xTypeTag= (obj && obj.xTypeTag) || '';
        this.xNotes= (obj && obj.xNotes) || '';
        this.xRefNum= (obj && obj.xRefNum) || '';
        this.xTntTag=(obj && obj.xTntTag) || '';
        this.xWall1EntyID=(obj && obj.xWall1EntyID) || '';
        this.xWall2EntyID=(obj && obj.xWall2EntyID) || '';
        this.xRefNum2=(obj && obj.xRefNum2) || '';
        this.xMainTerminalNum=(obj && obj.xMainTerminalNum) || '';
        this.xMerAcctNum=(obj && obj.xMerAcctNum) || '';
        this.xBusinessID=(obj && obj.xBusinessID) || '';
        this.xStoreID=(obj && obj.xStoreID) || '';
        this.xProcTag=(obj && obj.xProcTag) || '';
        this.xCaptTag=(obj && obj.xCaptTag) || '';
        this.xIndstTag=(obj && obj.xIndstTag) || '';
        this.xAttnTag=(obj && obj.xAttnTag) || '';
        this.xPresTag=(obj && obj.xPresTag) || '';
        this.xAcptCaps= (obj && new AcptCaps(obj.xAcptCaps)) || new AcptCaps();
        this.xProcParams=(obj && obj.xProcParams) || [];
        this.xMainPCtrID=(obj && obj.xMainPCtrID) || '';
        this.xMapping=(obj && obj.xMapping) || {
            xPCtrShortCodes:[],
            xMdmTags:[],
            xBrandTags:[]
        };
        this.xAutoSettleTime=(obj && obj.xAutoSettleTime) || '';
        }
}