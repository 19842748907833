<div *ngIf="show" id="printThis" class="print-summary-response re-print ">
    <div *ngFor="let singleTxnStoreData of transactionStoreDatalocal; let i=index" class="re-print2">
        <div class="section">
            <table width="100%" border="0" style="padding:0.5em">
                <tr>
                    <td></td>
                    <td class="w65">
                        <h4 class="text-center">{{singleTxnStoreData.storeData? singleTxnStoreData.storeData.xName : ''}}</h4>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="w65">
                        <h4 class="text-center">{{singleTxnStoreData.storeData? singleTxnStoreData.storeData.xSubName : ''}}</h4>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="w70">
                        <h4 class="text-center">
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xCO') && singleTxnStoreData.storeData.xAddr['xCO'].length">CO- {{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xCO : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xLine1') && singleTxnStoreData.storeData.xAddr['xLine1'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xLine1 : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xLine2') && singleTxnStoreData.storeData.xAddr['xLine2'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xLine2 : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xSuite') && singleTxnStoreData.storeData.xAddr['xSuite'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xSuite : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xCity') && singleTxnStoreData.storeData.xAddr['xCity'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xCity : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xSOP') && singleTxnStoreData.storeData.xAddr['xSOP'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xSOP : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xCountry') && singleTxnStoreData.storeData.xAddr['xCountry'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xCountry : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xPostCode') && singleTxnStoreData.storeData.xAddr['xPostCode'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xPostCode : ''}}</span>
                        </h4>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="w65">
                        <h4 class="text-center">{{singleTxnStoreData.storeData? singleTxnStoreData.storeData.xSvcPhone : ''}}</h4>
                    </td>
                    <td></td>
                </tr>
            </table>
            <br/>
            <table width="100%" border="0" style="padding:0.5em">
                <tr>
                    <td>
                        Server: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xClerkNum : ''}}
                    </td>
                    <td>
                        Table: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xTableNum : 1}}
                    </td>
                </tr>
                <tr>
                    <td>
                        Invoice: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xInvNum : ''}}
                    </td>
                    <td>
                        Trans: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xTxnNum : ''}}
                    </td>
                </tr>
                <tr>
                    <td>
                        Date: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.date : ''}}
                    </td>
                    <td>
                        Time: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.time : ''}}
                    </td>
                </tr>
            </table>
            <br/>
            <hr class="" />
            <br/>
            <div class='text-center'>
                {{singleTxnStoreData?.txnData?.xRspStatus !== 'success' ? '****  FAILED  ****' : ''}}
                {{singleTxnStoreData?.txnData?.xIsVoided && singleTxnStoreData?.txnData?.xRspStatus === 'success' ? '****  VOIDED  ****' : ''}}
            </div>
            <table width="100%" border="0" style="padding:0.5em">
                <tr>
                    <td class="w25">
                        {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xBrandTag : ''}}
                    </td>
                    <td class="w45">
                        {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xTxnActTag : ''}} 
                        <!-- {{singleTxnStoreData?.txnData?.xRspStatus !== 'success' ? '**FAILED**' : ''}}
                        {{singleTxnStoreData?.txnData?.xIsVoided && singleTxnStoreData?.txnData?.xRspStatus === 'success' ? '**VOIDED**' : ''}} -->
                    </td>
                    <td class="w5 text-right">
                        {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xAcctEntrTag : ''}}
                    </td>
                    <td class="w25 text-right">
                        REPRINT
                    </td>
                </tr>
            </table>
            <table cellspacing="10" width="100%" border="0" style="padding:0.5em">
                <tr>
                    <td>Acct#: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xMaskedAcctNum : ''}}</td>
                </tr>
                <tr>
                    <td>Exp Date: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xExpMonth : ''}} / {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xExpYear : ''}}</td>
                </tr>
                <tr>
                    <td>Auth Code: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xInitAuthCode : ''}}</td>
                </tr>
                <tr>
                    <td>Trace: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xHostTrcNum : ''}}</td>
                </tr>

            </table>
            <table cellspacing="10" width="100%" border="0" style="padding:0.5em">
                <tr>
                    <td class="w50 " style="line-height:26px">
                        <h4>Amount:</h4>
                    </td>
                    <td class="w50 text-right" style="line-height:26px">
                        <h4>{{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xMainAmt : ''}}</h4>
                    </td>
                </tr>
                <tr>
                    <td class="w50 " style="line-height:26px">
                        <h4> Tip:</h4>
                    </td>
                    <td class="w50 text-right" style="line-height:26px">
                        <h4>{{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xTipAmt : ''}}</h4>
                    </td>
                </tr>
                <tr>
                    <td class="w50 " style="line-height:26px">
                        <h4>Total:</h4>
                    </td>
                    <td class="w50 text-right" style="line-height:26px">
                        <h4>{{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xTtlAmt : ''}}</h4>
                    </td>
                </tr>
            </table>
            <br/>
            <hr class="" />
            <br/>
            <table cellspacing="10" width="100%" border="0" style="padding:0.5em">
                <tr>
                    <td>SIGNATURE<br/><br/><br/></td>
                </tr>
                <tr>
                    <td>
                        <small>Cardholder will pay card issuer above amount pursuant to cardholder agreement</small>
                        <div class="p30 text-center">
                            <small>*** Merchant Copy ***</small>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="section">
            <table width="100%" border="0" style="padding:0.5em">
                <tr>
                    <td></td>
                    <td class="w65">
                        <h4 class="text-center">{{singleTxnStoreData.storeData? singleTxnStoreData.storeData.xName : ''}}</h4>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="w65">
                        <h4 class="text-center">{{singleTxnStoreData.storeData? singleTxnStoreData.storeData.xSubName : ''}}</h4>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="w70">
                        <h4 class="text-center"><span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xCO') && singleTxnStoreData.storeData.xAddr['xCO'].length">CO- {{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xCO : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xLine1') && singleTxnStoreData.storeData.xAddr['xLine1'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xLine1 : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xLine2') && singleTxnStoreData.storeData.xAddr['xLine2'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xLine2 : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xSuite') && singleTxnStoreData.storeData.xAddr['xSuite'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xSuite : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xCity') && singleTxnStoreData.storeData.xAddr['xCity'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xCity : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xSOP') && singleTxnStoreData.storeData.xAddr['xSOP'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xSOP : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xCountry') && singleTxnStoreData.storeData.xAddr['xCountry'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xCountry : ''}}, </span>
                            <span *ngIf="singleTxnStoreData.storeData.hasOwnProperty('xAddr') && singleTxnStoreData.storeData.xAddr.hasOwnProperty('xPostCode') && singleTxnStoreData.storeData.xAddr['xPostCode'].length">{{singleTxnStoreData.storeData.xAddr? singleTxnStoreData.storeData.xAddr.xPostCode : ''}}</span>
                        </h4>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="w65">
                        <h4 class="text-center">{{singleTxnStoreData.storeData? singleTxnStoreData.storeData.xSvcPhone : ''}}</h4>
                    </td>
                    <td></td>
                </tr>
            </table>
            <br/>
            <table width="100%" border="0" style="padding:0.5em">
                <tr>
                    <td>
                        Server: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xClerkNum : ''}}
                    </td>
                    <td>
                        Table: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xTableNum : 1}}
                    </td>
                </tr>
                <tr>
                    <td>
                        Invoice: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xInvNum : ''}}
                    </td>
                    <td>
                        Trans: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xTxnNum : ''}}
                    </td>
                </tr>
                <tr>
                    <td>
                        Date: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.date : ''}}
                    </td>
                    <td>
                        Time: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.time : ''}}
                    </td>
                </tr>
            </table>
            <br/>
            <hr class="" />
            <br/>
            <div class='text-center'>
                {{singleTxnStoreData?.txnData?.xRspStatus !== 'success' ? '****  FAILED  ****' : ''}}
                {{singleTxnStoreData?.txnData?.xIsVoided && singleTxnStoreData?.txnData?.xRspStatus === 'success' ? '****  VOIDED  ****' : ''}}
            </div>
            <table width="100%" border="0" style="padding:0.5em">
                <tr>
                    <td class="w25">
                        {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xBrandTag : ''}}
                    </td>
                    <td class="w35">
                        {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xTxnActTag : ''}} ({{singleTxnStoreData?.txnData?.xRspStatus}}) {{singleTxnStoreData?.txnData?.xIsVoided ? '(Voided)' : ''}}
                    </td>
                    <td class="w15 text-right">
                        {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xAcctEntrTag : ''}}
                    </td>
                    <td class="w25 text-right">
                        REPRINT
                    </td>
                </tr>
            </table>
            <table cellspacing="10" width="100%" border="0" style="padding:0.5em">
                <tr>
                    <td>Acct#: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xMaskedAcctNum : ''}}</td>
                </tr>
                <tr>
                    <td>Exp Date: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xExpMonth : ''}} / {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xExpYear : ''}} </td>
                </tr>
                <tr>
                    <td>Auth Code: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xInitAuthCode : ''}} </td>
                </tr>
                <tr>
                    <td>Trace: {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xHostTrcNum : ''}} </td>
                </tr>

            </table>

            <table cellspacing="10" width="100%" border="0" style="padding:0.5em">
                <tr>
                    <td class="w50" style="line-height:26px">
                        <h4>Amount:</h4>
                    </td>
                    <td class="w50 text-right" style="line-height:26px">
                        <h4> {{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xMainAmt : ''}}</h4>
                    </td>
                </tr>
                <tr>
                    <td class="w50" style="line-height:26px">
                        <h4>Tip:</h4>
                    </td>
                    <td class="w50 text-right" style="line-height:26px">
                        <h4>{{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xTipAmt : ''}}</h4>
                    </td>
                </tr>
                <tr>
                    <td class="w50" style="line-height:26px">
                        <h4>Total:</h4>
                    </td>
                    <td class="w50 text-right" style="line-height:26px">
                        <h4>{{singleTxnStoreData.txnData? singleTxnStoreData.txnData.xTtlAmt : ''}}</h4>
                    </td>
                </tr>
            </table>
            <br/>
            <hr class="" />
            <br/>
            <table cellspacing="10" width="100%" border="0" style="padding:0.5em">
                <tr>
                    <td>SIGNATURE<br/><br/><br/></td>
                </tr>
                <tr>
                    <td>
                        <small>Cardholder will pay card issuer above amount pursuant to cardholder agreement</small>
                        <div class="p30 text-center">
                            <small>*** Merchant Copy ***</small>
                        </div>


                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>