export const CUSTOMER_NAME_TEMPLATE = {
	templateName: 'Order Number, Amount and Name Pre Template',
	createHtmlCode(vtermUrl, vtermToken, encryptAPI) {
		return `<form id="stand_alone_vterm_link_form" action=${vtermUrl} method="GET" target="_parent">
        <div>
            <table>
                <tr>
                    <td style="text-align: right; width: 100px; white-space: nowrap;"><label for="name">First Name: </label></td>
                    <td><input id="firstName"  value=""/></td>
                </tr>
                <tr>
                    <td style="text-align: right; width: 100px; white-space: nowrap;"><label for="lastName">Last Name:</label></td>
                    <td><input id="lastName" value="" /></td>
				</tr>
            </table>
        </div>
        <div style="display: inline-block;">
            <table>
                <tr>
                    <td style="text-align: right; width: 100px; white-space: nowrap;"><label for="addr">Order Number:</label></td>
                    <td><input id="orderNum"  value=""/></td>
                </tr>
                <tr>
                    <td style="text-align: right; width: 100px; white-space: nowrap;"><label for="amt">Amount:</label></td>
                    <td> <input id="amt"  value=""/></td>
                </tr>
                <tr>
					<td></td>
					<td style="float: right;">
						<input type="hidden" name="param" value="${vtermToken}" /> 
						<input type="hidden" id="coToken" name="coToken" value="" /> 
						<input type="hidden" id="stand_alone_vterm_callback_url" name="home" value="" /> 
						<button type="button" id="stand_alone_vterm_pay_now_btn" 
							onclick=' var xhr = new XMLHttpRequest();
							xhr.onreadystatechange = function() {
							if (xhr.readyState == XMLHttpRequest.DONE) { 
									let token = JSON.parse(xhr.responseText).data.vTermToken;
									document.getElementById("stand_alone_vterm_callback_url").value = window.parent.location.href;
									document.getElementById("coToken").value = token;
									document.getElementById("stand_alone_vterm_link_form").submit()
								}; 
							}; 
							let amt = document.getElementById("amt").value;
							let orderNum = document.getElementById("orderNum").value;
							let firstName = document.getElementById("firstName").value;
							let lastName = document.getElementById("lastName").value;
							let coData = "amount="+amt+"&"+"orderNumber="+orderNum+"&"+"firstName="+firstName+"&"+"lastName="+lastName;
							xhr.open("POST", "${encryptAPI}", true); 
							xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
							xhr.send(coData); 
							'> 
							Pay 
						</button> 
					</td>
                </tr>
            </table>
        </div>
</form>
	`;
	},
};
