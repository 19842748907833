import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UserLoggedGuard } from "./shared";

const routes: Routes = [
    {
        path: "login",
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        canActivate: [UserLoggedGuard]
    },
    {
        path: "not-found",
        loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
    },
    { path: "signup", loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
    { path: "**", redirectTo: "not-found" }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
