import { Injectable } from "@angular/core";
import { HttpService } from "../shared/services/http-service";
import { map } from 'rxjs/operators';
const endPoint = "xbcStores";

@Injectable()
export class SetupBusinessStructureStoreSetupStore {
    static storesData: any[];

    static toggle(element: any): void {
        console.log(
            "Inside SetupBusinessStructureStoreSetupStore toggle function",
            element
        );
    }

    constructor(protected httpService: HttpService) {}

    getAll(params: any): any {
        return this.httpService
            .getAll(endPoint, params)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    store(element: any): any {
        return this.httpService
            .store(endPoint, element)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    update(elementID: string, newElement: any): any {
        return this.httpService
            .update(endPoint, elementID, newElement)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    get(elementID: any): any {
        return this.httpService
            .get(endPoint, elementID)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    destroy(elementID: any): any {
        return this.httpService
            .destroy(endPoint, elementID)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }
}
