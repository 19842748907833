import { Injectable } from '@angular/core';
import { API_END_POINT, TAGSCONSTS, BE_KEY } from './../../constants/index';
import { validateFor } from './../../common-functions/index';
import { setDataForTokenCreation } from './../../dashboard/common-functions/validate-txn-terminals';
import { vTermTokenDataModel } from './../../models/index';
import { XBCCUSTOMERS, DB_MONGOID_KEY, xAddr, xContact, xFormalName } from './../../constants'
import { HttpService } from './http-service';
import { NotificationService } from './notification-service';
import { BusinessService } from './business-service';
import { UserHandlerService } from './user-handler-service';

//Message
const ENCRYPT_FAILURE_MSG = 'Unable to generate token.';

//Values and Keys
const PAYMENT_MODE_CREATE_PAYMENT: string = 'createPayment';
const SESSION_STORAGE_TOKEN_KEY:string = 'token';
const VTERM_INFO_TERM_REC_KEY = 'terminalRecords';
const VTERM_INFO_MERCH_CAP_KEY = 'merchantCapabilities';
const VTERM_TOKEN_KEY = 'vTermToken';

@Injectable()
export class VtermTokenService {
	constructor(
		private httpService: HttpService,
		private notificationService: NotificationService,
		private businessService: BusinessService,
		private userHandlerService: UserHandlerService
		) {}

	/**
	 * generates vterm token by using terminalID to hit vterm_info api, and then form payload and encrypt it.
	 *
	 * @params : `terminalID` : string : mongoID of terminal
	 * @params : `customerData` : object : customer data
	 * @return : `Promise` :
	 */
	generateVtermToken(terminalID,customerData):Promise<string> {
		return new Promise((resolve, reject) => {
			console.log('generateVtermToken:terminalID', terminalID);
			const vtermInfoParams = { terminalID: terminalID };
			this.httpService.getAll(API_END_POINT.vterm_info, vtermInfoParams).subscribe(
				(vtermInfoResponse) => {
					console.log('generateVtermToken:vtermInfoResponse', vtermInfoResponse);
					let tokenData = this.generateTokenData(vtermInfoResponse,customerData);
					// console.log('generateVtermToken:tokenData',tokenData)
					this.httpService.store(API_END_POINT.vtermEncrypt, tokenData).subscribe(
						(encryptResponse) => {
							console.log('generateVtermToken:encryptResponse', encryptResponse);
							if (
								!validateFor(BE_KEY.success, encryptResponse) ||
								!validateFor(BE_KEY.data, encryptResponse) ||
								!validateFor(VTERM_TOKEN_KEY, encryptResponse.data)
							) {
								this.notificationService.error(ENCRYPT_FAILURE_MSG, 'Error');
							}
							return resolve(encryptResponse.data.vTermToken);
						},
						(encryptError) => {
							console.log('generateVtermToken:encryptError', encryptError);
							return reject(encryptError);
						}
					);
				},
				(vtermInfoError) => {
					console.log('generateVtermToken:vtermInfoError', vtermInfoError);
					return reject(vtermInfoError);
				}
			);
		});
	}

    /**
	 * generate token data
	 *
	 * @params : `vTermInfoResponse` : object : vterm_info data
	 * @params : `customerData` : object : customer data
	 * @return : `tokenData` : object
	 */
	generateTokenData(vTermInfoResponse,customerData) {
		console.log('generateTokenData: vTermInfoResponse', vTermInfoResponse);
		let tokenData:vTermTokenDataModel, terminalObj, vTermInfoResponseData, bizName;
		vTermInfoResponseData = validateFor('data', vTermInfoResponse) ? vTermInfoResponse.data : {};
		terminalObj =
			validateFor(VTERM_INFO_TERM_REC_KEY, vTermInfoResponseData) && vTermInfoResponseData[VTERM_INFO_TERM_REC_KEY].length
				? vTermInfoResponseData[VTERM_INFO_TERM_REC_KEY][0]
				: {};
		const achTerminal = validateFor(VTERM_INFO_MERCH_CAP_KEY,terminalObj) 
			&& terminalObj[VTERM_INFO_MERCH_CAP_KEY].includes(TAGSCONSTS.brandTags.brandItemTags.genericElectronicCheck)
			? terminalObj
			: {};
		let ccTerminal =  validateFor(VTERM_INFO_MERCH_CAP_KEY,terminalObj) 
			&& terminalObj[VTERM_INFO_MERCH_CAP_KEY].includes(TAGSCONSTS.brandTags.brandItemTags.genericCreditCard)
			? terminalObj
			: {};
		/* Note 
			If no ACH or CC type is present, make terminal as default CC.
			This is required for adding payment method from payment accounts.
		*/
		if(!Object.keys(ccTerminal).length || !Object.keys(achTerminal).length){
			ccTerminal = terminalObj;	
		}
		console.log('generateTokenData: achTerminal',achTerminal);
		console.log('generateTokenData: ccTerminal',ccTerminal);
		bizName = this.businessService.businessData.xName;
		console.log('bizName: generateTokenData', bizName);

		tokenData = setDataForTokenCreation(
			bizName,
			vTermInfoResponseData,
			this.userHandlerService.userData,
			TAGSCONSTS.roleTags.roleItemTags.representativeRole,
			ccTerminal,
			achTerminal
		);
		console.log('generateTokenData:ach token', tokenData);
		console.log('generateTokenData:customerdata',customerData)
		let formalName = validateFor(XBCCUSTOMERS.xFormalName, customerData) ? customerData[XBCCUSTOMERS.xFormalName] : {};
		let contactDetails = validateFor(XBCCUSTOMERS.xContact, customerData) ? customerData[XBCCUSTOMERS.xContact] : {};
		let address = validateFor(XBCCUSTOMERS.xAddr, customerData) ? customerData[XBCCUSTOMERS.xAddr] : {};
		let l1Addr = validateFor(xAddr.xLine1, address) ? address[xAddr.xLine1] : '';
		let l2Addr = validateFor(xAddr.xLine2, address) ? address[xAddr.xLine2] : '';
		tokenData.authToken = sessionStorage.getItem(SESSION_STORAGE_TOKEN_KEY) ? sessionStorage.getItem(SESSION_STORAGE_TOKEN_KEY) : null;
		tokenData.paymentMode = PAYMENT_MODE_CREATE_PAYMENT;
		tokenData.billingAccountInfo._id = validateFor(DB_MONGOID_KEY, customerData) ? customerData[DB_MONGOID_KEY] : '';
		tokenData.billingAccountInfo.memberNumber = validateFor(XBCCUSTOMERS.xCustomerNum, customerData)
			? customerData[XBCCUSTOMERS.xCustomerNum]
			: '';
		tokenData.billingAccountInfo.firstName = validateFor(xFormalName.xForename, formalName) ? formalName[xFormalName.xForename] : '';
		tokenData.billingAccountInfo.lastName = validateFor(xFormalName.xSurname, formalName) ? formalName[xFormalName.xSurname] : '';
		tokenData.billingAccountInfo.phone = validateFor(xContact.xPhone, contactDetails) ? contactDetails[xContact.xPhone] : '';
		tokenData.billingAccountInfo.email = validateFor(xContact.xEmail, contactDetails) ? contactDetails[xContact.xEmail] : '';
		tokenData.billingAccountInfo.company = validateFor(XBCCUSTOMERS.xCompanyName, customerData) ? customerData[XBCCUSTOMERS.xCompanyName] : '';
		tokenData.billingAccountInfo.address = (l1Addr ? l1Addr : '') + (l2Addr ? ', ' + l2Addr : '');
		tokenData.billingAccountInfo.city = validateFor(xAddr.xCity, address) ? address[xAddr.xCity] : '';
		tokenData.billingAccountInfo.state = validateFor(xAddr.xSOP, address) ? address[xAddr.xSOP] : '';
		tokenData.billingAccountInfo.zipCode = validateFor(xAddr.xPostCode, address) ? address[xAddr.xPostCode] : '';
		tokenData.billingAccountInfo.country = validateFor(xAddr.xCountry, address) ? address[xAddr.xCountry] : '';
		tokenData.initiatorRole = TAGSCONSTS.roleTags.roleItemTags.customerRole;
		return tokenData;
	}
}
