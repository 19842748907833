<section>
	<form class="form-horizontal">
		<div class="grid-add-wrap">
			<!--<grid-cmp [attributes]=attributes [storeValue]=storeValue [parameters]=parameters [storeObj]=storeObject [action]=action
			 (actionRequest)="onActionRequest($event)"></grid-cmp>-->
			<div class="row m-b-1">
	            <div class="col-sm-10 offset-sm-1 m-t-30">
	                <div class="coming-soon">
	                	<h4 class="text-muted">Please select a Business</h4>
	                </div>
	            </div>
	        </div>

		</div>
	</form>	
	<!--<details-cmp [id]=statusID [viewDetails]=viewDetails [label]="'Status'" [store]=storeObject (onViewClose)="viewClosed($event)"></details-cmp>-->
</section>