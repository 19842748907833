import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WaiverOptionsComponent } from './waiver-options/waiver-options.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
    declarations: [WaiverOptionsComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
    ],
    exports: [WaiverOptionsComponent],
})
export class WaiverOptionsModule {}
