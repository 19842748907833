import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_END_POINT, STORE_NAMES } from 'app/constants';
import { IXpeBillingForm } from 'app/dashboard/setup/licensing/xpebilling.interface';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from './notification-service';
import { ApiConfig } from '../config/api.config';

@Injectable()
export class XpebillingService {
	API_HOST = '';
	ERROR = 'error';

	constructor(private http: HttpClient, private apiConfig: ApiConfig, private notification: NotificationService) {
		this.API_HOST = apiConfig.API_HOST;
	}

	getBillingUsingCompanyIDAndBID(role:string, bID:string, companyID: string, isCurrent: boolean, isSuspended: boolean): Promise<{ data: IXpeBillingForm; meta: {} }> {
		let query = '';
		console.log("getBillingUsingCompanyIDAndBID", bID);
		if (!bID || bID.length <= 0) {
			query =
			encodeURIComponent('xCompanyID') +
			'=' +
			encodeURIComponent(companyID) +
			'&' +
			encodeURIComponent('xTag') +
			'=' +
			encodeURIComponent(role) +
			'&' +
			encodeURIComponent('xIsCurrent') +
			'=' +
			encodeURIComponent(isCurrent) +
			'&' +
			encodeURIComponent('selectKey') +
			'=' +
			encodeURIComponent('_id,xCompanyID,xIsCurrent,xIsSuspended,xIsPaid,xDueBillingDate,xVendorTerminalID,xBusinessName,xBusinessID,xAmt');
		} else {
			query =
				encodeURIComponent('xCompanyID') +
				'=' +
				encodeURIComponent(companyID) +
				'&' +
				encodeURIComponent('xTag') +
				'=' +
				encodeURIComponent(role) +
				'&' +
				encodeURIComponent('xBusinessID') +
				'=' +
				encodeURIComponent(bID) +
				'&' +
				encodeURIComponent('xIsCurrent') +
				'=' +
				encodeURIComponent(isCurrent) +
				'&' +
				encodeURIComponent('selectKey') +
				'=' +
				encodeURIComponent('_id,xCompanyID,xIsCurrent,xIsSuspended,xIsPaid,xDueBillingDate,xVendorTerminalID,xBusinessName,xBusinessID,xAmt');
		}
		return this.http.get<{ data: IXpeBillingForm; meta: {} }>(`${this.API_HOST}/api/${API_END_POINT.xpeBilling}/?${query}`).toPromise();
	}


	getBillingUsingCompanyID(companyID: string, isCurrent: boolean, isSuspended: boolean): Promise<{ data: IXpeBillingForm; meta: {} }> {
		let query = '';
		if (isSuspended) {
			query =
				encodeURIComponent('xCompanyID') +
				'=' +
				encodeURIComponent(companyID) +
				'&' +
				encodeURIComponent('xIsCurrent') +
				'=' +
				encodeURIComponent(isCurrent) +
				'&' +
				encodeURIComponent('xIsSuspended') +
				'=' +
				encodeURIComponent(isSuspended) +
				'&' +
				encodeURIComponent('selectKey') +
				'=' +
				encodeURIComponent('_id,xCompanyID,xIsCurrent,xIsSuspended,xIsPaid,xDueBillingDate,xVendorTerminalID,xBusinessName,xBusinessID,xAmt');
		} else {
			query =
				encodeURIComponent('xCompanyID') +
				'=' +
				encodeURIComponent(companyID) +
				'&' +
				encodeURIComponent('xIsCurrent') +
				'=' +
				encodeURIComponent(isCurrent) +
				'&' +
				encodeURIComponent('selectKey') +
				'=' +
				encodeURIComponent('_id,xCompanyID,xIsCurrent,xIsSuspended,xIsPaid,xDueBillingDate,xVendorTerminalID,xBusinessName,xBusinessID,xAmt');
		}
		return this.http.get<{ data: IXpeBillingForm; meta: {} }>(`${this.API_HOST}/api/${API_END_POINT.xpeInvoice}/?${query}`).toPromise();
	}

	getXpebilling(id: string): Observable<{ data: IXpeBillingForm; meta: {} }> {
		return this.http.get<{ data: IXpeBillingForm; meta: {} }>(`${this.API_HOST}/api/${API_END_POINT.xpeInvoice}/${id}`).pipe(catchError(this.handleError));
	}

	getXpebillingRule(id: string): Observable<{ data: IXpeBillingForm; meta: {} }> {
		return this.http.get<{ data: IXpeBillingForm; meta: {} }>(`${this.API_HOST}/api/${API_END_POINT.xpeBilling}/${id}`).pipe(catchError(this.handleError));
	}

	handleError(errorResponse: HttpErrorResponse) {
		if (errorResponse.error instanceof ErrorEvent) {
			console.log('Client Side Error: ', errorResponse.error.message);
			this.notification.error(this.ERROR, errorResponse.error.message);
		} else {
			console.log('Server Side Error: ', errorResponse);
			this.notification.error(this.ERROR, errorResponse.error.message);
			// return errorResponse.error.message;
		}
		return throwError('There is a problem with the xpebilling-service. Please notify your admin');
	}
}
