import { TAGSCONSTS } from './tags.constants';
export const MEDIUM_TAGS_TYPE_MAPPING = {
	'mdm.cc': 'Card',
	'mdm.dc': 'Card',
	'mdm.gc': 'Card',
	'mdm.pc': 'Card',
	'mdm.lc': 'Card',
	'mdm.eb': 'Card',
	'mdm.ec': 'Bank',
};
export const MEDIUM_TAGS_MAPPING = {
	'mdm.cs': 'Cash',
	'mdm.cc': 'Credit Card',
	'mdm.dc': 'Debit Card',
	'mdm.gc': 'Gift Card',
	'mdm.pc': 'Prepaid Card',
	'mdm.lc': 'Loyalty Card',
	'mdm.eb': 'EBT Card',
	'mdm.ec': 'Bank',
};
export const BRAND_TAGS_MAPPING = {
	'brand.cs': 'Cash',
	'brand.cc': 'Credit Card',
	'brand.cc.vs': 'Visa',
	'brand.cc.mc': 'Master Card',
	'brand.cc.ax': 'American Express',
	'brand.cc.ds': 'Discover',
	'brand.cc.dn': 'Diners Club',
	'brand.cc.cb': 'Carte Blanche',
	'brand.cc.en': 'EnRoute',
	'brand.cc.jc': 'JCB',
	'brand.cc.ja': 'JAL',
	'brand.cc.up': 'Cup',
	'brand.cc.ma': 'Maestro Card',
	'brand.dc': 'DebitCard',
	'brand.gc': 'GiftCard',
	'brand.pc': 'Prepaid Card',
	'brand.lc': 'Loyalty Card',
	'brand.eb': 'EBTCard',
	'brand.eb.fs': 'Food Stamp Card',
	'brand.eb.cb': 'Cash Benefit Card',
	'brand.ec': 'ElectronicCheck',
};
export const ACCT_TYPE_MAPPING = {
	savings: 'Savings',
	checking: 'Checking',
};

export const DEALER_MODULE_TAGS = [
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.setup,
		name: 'Setup',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.txn,
		name: 'Transactions',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.safes,
		name: 'Vault',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.roles,
		name: 'Roles',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.cust,
		name: 'Customers',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.rep,
		name: 'Representatives/Dealers',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.emp,
		name: 'Employees',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.business,
		name: 'Business',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.company,
		name: 'Companies',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.importTxns,
		name: 'Import Tranasctions',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.batch,
		name: 'Batch/Settlement',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.billing,
		name: 'Billing'
	}
];

export const EMP_MODULE_TAGS = [
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.txn,
		name: 'Transactions',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.safes,
		name: 'Vault',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.roles,
		name: 'Roles',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.cust,
		name: 'Customers',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.emp,
		name: 'Employees',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.importTxns,
		name: 'Import Tranasctions',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.batch,
		name: 'Batch/Settlement',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.setup,
		name: 'Setup',
	},
];

export const VEN_MODULE_TAGS = [
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.roles,
		name: 'Roles',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.rep,
		name: 'Representatives/Dealers',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.company,
		name: 'Companies',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.safes,
		name: 'Vault',
	},
	{
		key: TAGSCONSTS.moduleTags.moduleItemTags.billing,
		name: 'Billing'
	}
];

export const MDOULE_TAGS_MAPPING = {
	'mdu.setup': 'Setup',
	'mdu.txn': 'Transactions',
	'mdu.roles': 'Roles',
	'mdu.cust': 'Customers',
	'mdu.emp': 'Employees',
	'mdu.rep': 'Dealers',
	'mdu.safes': 'Vault',
	'mdu.company': 'Companies',
	'mdu.business': 'Business',
	'mdu.importTxns': 'Import Transactions',
	'mdu.batch': 'Batch/Settlement',
	'mdu.billing': 'Billing'
};

export const FUNCTION_TAG_MAPPING = {
	'func.setup.create': 'Create Nodes',
	'func.setup.view': 'View Nodes',
	'func.setup.edit': 'Edit Nodes',
	'func.setup.delete': 'Delete Nodes',
	'func.rep.create': 'Create Dealer',
	'func.rep.view': 'View Dealer',
	'func.rep.edit': 'Edit Dealer',
	'func.rep.delete': 'Delete Dealer',
	'func.cust.create': 'Create Customer',
	'func.cust.view': 'View Customer',
	'func.cust.edit': 'Edit Customer',
	'func.cust.delete': 'Delete Customer',
	'func.cust.viewTxn': 'View Customer Transactions',
	'func.emp.create': 'Create Employee',
	'func.emp.view': 'View Employee',
	'func.emp.edit': 'Edit Employee',
	'func.emp.delete': 'Delete Employee',
	'func.safes.create': 'Create Safes',
	'func.safes.view': 'View Safes',
	'func.safes.edit': 'Edit Safes',
	'func.safes.delete': 'Delete Safes',
	'func.safes.paynow': 'Pay Now Using Saved Payment Methods',
	'func.roles.create': 'Create Role',
	'func.roles.view': 'View Role',
	'func.roles.edit': 'Edit Role',
	'func.roles.delete': 'Delete Role',
	'func.txn.settle': 'Settle Transactions',
	'func.txn.void': 'Void Transactions',
	'func.txn.redo': 'Redo Transactions',
	'func.txn.reverse': 'Reverse Transactions',
	'func.txn.finalize': 'Finalize Transactions',
	'func.txn.edit': 'Edit Transactions',
	'func.txn.print': 'Print Detail',
	'func.txn.report': 'Generate Report',
	'func.txn.reprint': 'Reprint',
	'func.txn.auth': 'Authorize',
	'func.txn.viewDetails': 'View Transaction Details',
	'func.txn.create': 'Perform Transactions',
	'func.business.create': 'Create Business',
	'func.business.view': 'View Business',
	'func.business.edit': 'Edit Business',
	'func.business.delete': 'Delete Business',
	'func.company.create': 'Create Company',
	'func.company.view': 'View Company',
	'func.company.edit': 'Edit Company',
	'func.company.delete': 'Delete Company',
	'func.company.viewStructure': 'View Structure',
	'func.company.viewImportResponse': 'View Import Response',
	'func.importTxns.create': 'Import Transactions',
	'func.importTxns.view': 'View Import Transaction Details',
	'func.batch.settle': 'Settle Batch',
	'func.batch.viewBatchTxns': 'View Batch Transactions',
	'func.batch.cleanup': 'Batch Cleanup',
	'func.batch.resync': 'Resync',
	'func.batch.view': 'View Batch Details',
	'func.billing.create': 'Create Billing',
	'func.billing.suspend': 'Suspend Company',
	'func.billing.delete': 'Delete Billing',
	'func.billing.edit': 'Edit Billing',
	'func.billing.list.view': 'View Billing List',
	'func.billing.report.view': 'View Billing Report',
	'func.billing.details.view': 'View Billing Details'
}