import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../shared/services/index';
import { map } from 'rxjs/operators';

const endpoint = 'xbcStructs';

@Injectable()
export class SetupBusinessStructureStore {
    static businessStructureData: any[];
    // nodePath: any[] = [];
    nodePath:BehaviorSubject<any[]>=new BehaviorSubject<any[]>([]);
    businessID: string = '';
    watchNodePath$=this.nodePath.asObservable();

    constructor(protected httpService: HttpService) {}

    getNodePath(): any[] {
        console.log("NodePath getter function", this.nodePath.getValue());
        return this.nodePath.getValue();
    }

    setNodePath(nodePath: any[]) {
        console.log("NodePath setter function", nodePath);
        this.nodePath.next(nodePath);
    }

    getBusinessID(): string {
        return this.businessID;
    }

    setBusinessID(businessID: string) {
        this.businessID = businessID;
    }

    getAll(params: any): any {
        console.log('params in structure store', params);
        return this.httpService
            .getAll(endpoint, params)
            .pipe(
				map((response: Response) => {
					return response;
				})
			);
    }

    store(element: any): any {
        return this.httpService
            .store(endpoint, element)
            .pipe(
				map((response: Response) => {
					return response;
				})
			);
    }

    update(elementID: string, newElement: any): any {
        return this.httpService
            .update(endpoint, elementID, newElement)
            .pipe(
				map((response: Response) => {
					return response;
				})
			);
    }

    get(elementID: any): any {
        console.log("I'm being called");
        return this.httpService
            .get(endpoint, elementID)
            .pipe(
				map((response: Response) => {
					return response;
				})
			);
    }

    destroy(elementID: any): any {
        return this.httpService
            .destroy(endpoint, elementID)
            .pipe(
				map((response: Response) => {
					return response;
				})
			);
    }
}
