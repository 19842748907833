import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SetupPermissionsStore } from '../../../store';
import { NotificationService, HttpService } from '../../services';

import { PreviewPermissionModalComponent } from './preview-permission-modal.component';

@NgModule({
	imports: [CommonModule, FormsModule, ModalModule],
	declarations: [PreviewPermissionModalComponent],
	exports: [PreviewPermissionModalComponent],
	providers: [SetupPermissionsStore, NotificationService, HttpService]
})
export class PreviewPermissionModalModule {}
