<div class="customer-form-wrap">
    <div *ngIf="!fetchingData" class="login-wrap">
        <div class="login-inner">
            <div *ngIf="!showTwoFABtns">
                <h1 *ngIf="!inSecureUser" class="login-title">Login</h1>
                <h1 *ngIf="inSecureUser" class="login-title">Enter Verification Code</h1>
                <form name="loginForm" (ngSubmit)="login(form.value, false)" #form="ngForm">
                    <div class="form-content">
                        <div 
                            [hidden]="inSecureUser" 
                            class="form-group no-margin">
                            <input
                                #uName
                                type="text"
                                class="form-control input-rounded input-lg"
                                placeholder="Username"
                                name="username"
                                [(ngModel)]="username"
                                [disabled]="disable"
                            />
                        </div>
                        <div 
                            [hidden]="!inSecureUser"  
                            class="form-group no-margin">
                            <input
                                type="text"
                                class="form-control input-rounded input-lg"
                                placeholder="Verification Code"
                                name="otp"
                                [(ngModel)]="otp"
                                [disabled]="disable"
                            />
                        </div>
                        <small
                            *ngIf="inSecureUser && timeLeft"
                        >
                            {{ EMAIL_SENT_MESSAGE }}
                            You can generate new code in {{ timeLeft | timerFormat }} minutes.
                        </small>
                        <small
                                *ngIf="!timeLeft"
                            >
                               {{OTP_EXPIRED_ERR_MSG}} 
                        </small>
                        <div *ngIf="!inSecureUser" class=" form-group no-margin ">
                            <input
                                type="password"
                                class="form-control input-rounded input-lg "
                                name="password"
                                placeholder="Password"
                                [(ngModel)]="password"
                                [disabled]="disable"
                            />
                        </div>
                    </div>
                    <div 
                        *ngIf="!inSecureUser" 
                        class="p-b-10 d-flex justify-content-center"
                    >
                        <re-captcha
                            name="re-captcha"
                            (error)="recaptchaError($event)"
                            required
                            [siteKey]=GOOGLE_RECAPTCHA_SITE_KEY
                            [(ngModel)]="recaptchaVerified">
                        </re-captcha>
                    </div>
                    <button 
                        *ngIf="!inSecureUser" 
                        class="btn btn-secondary btn-round btn-block btn-lg" 
                        [disabled]="authenticationService.isLoading || disable || !recaptchaVerified"
                    >
                        <span *ngIf="!disable"> Log In </span>
                        <span *ngIf="disable">Please wait... 
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </span>
                    </button>
                    <button 
                        type="button"
                        (click)="onSubmitOTP(form.value)"
                        *ngIf="inSecureUser" 
                        class="btn btn-secondary btn-round btn-block btn-lg" 
                        [disabled]="authenticationService.isLoading || disable"
                    >
                        <span *ngIf="!otpVerificationLoader"> Submit </span>
                        <span *ngIf="otpVerificationLoader">Verifying... 
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </span>
                    </button>
                    <i 
                        *ngIf="NO_OF_ATTEMPTS_REMAINING" 
                        class="fa fa-info-circle float-right login-info" 
                        [tooltip]="NO_OF_ATTEMPTS_REMAINING" 
                        placement="top" containerClass="tooltip-global"
                        container="body" aria-hidden="true"
                    >
                    </i>
                    <button 
                        type="button"
                        *ngIf="inSecureUser" 
                        (click)="generateNewOTP()"
                        class="btn btn-secondary btn-round btn-block btn-lg mt-2" 
                        [disabled]="authenticationService.isLoading || disable || newOTPLoader || timeLeft"
                    >
                        <span *ngIf="!newOTPLoader"> Generate New Verification Code </span>
                        <span *ngIf="newOTPLoader">Please wait... 
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </span>
                    </button>
                </form>
                <div class="m-t">
                    <span class="register-text pull-left">
                        Don't have an account?
                        <a class="login-page-links" (click)="openCustomerRegistration()"> Register </a>
                    </span>
                    <span class="pull-right">
                        <a [routerLink]="['/login/forgot-password']" class="login-page-links">
                            Forgot Password?
                        </a>
                    </span>
                    <br />
                    <br />
                    <span>
                        <a (click)="routeToLoginPage()" class="login-page-links" style="font-size: 15px;">
                            <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            Go back to login page
                        </a>
                    </span>
                </div>
            </div>
            <div 
                    *ngIf="showTwoFABtns"
                >
                    <p class="lead login-page-headers">
                        Two Factor Authentication
                    </p>
                    <button
                        type="button"
                        (click)="onEnableTwoFA()"
                        class="btn btn-secondary btn-round btn-block btn-lg"
                        [disabled]="disableTwoFABtn"
                    >
                        <span> Enable Two Factor Authentication </span>
                    </button>
                    <button
                        type="button"
                        (click)="skipForNow()"
                        class="mt-2 btn btn-link text-white cursor-pointer"
                    >
                        <u>Skip for now</u>
                    </button>
                </div>
        </div>
    </div>
</div>
<div *ngIf="fetchingData" class="customer-form-wrap">
    <div class="login-wrap" style="text-align: center; position:relative">
        <div class="col-md-12 login-inner">
            <div class="grid-spinner">
                <div class="bounce1" style="background-color: white !important"></div>
                <div class="bounce2" style="background-color: white !important"></div>
                <div class="bounce3" style="background-color: white !important"></div>
            </div>
            <span style="color: white">Fetching data</span>
        </div>
    </div>
</div>
