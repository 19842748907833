export class EntrCaps {
    xHasManEntr: boolean;
    xHasMSREntr: boolean;
    xHasICCEntr: boolean;
    xHasClessEntr: boolean;
    xHasMICREntr: boolean;
    xHasPINEntr: boolean;
    xHasSigEntr: boolean;

    constructor(obj?: any) {
        this.xHasManEntr = (obj && obj.xHasManEntr) || null;
        this.xHasMSREntr = (obj && obj.xHasMSREntr) || null;
        this.xHasICCEntr = (obj && obj.xHasICCEntr) || null;
        this.xHasClessEntr = (obj && obj.xHasClessEntr) || null;
        this.xHasMICREntr = (obj && obj.xHasMICREntr) || null;
        this.xHasPINEntr = (obj && obj.xHasPINEntr) || null;
        this.xHasSigEntr = (obj && obj.xHasSigEntr) || null;
    }
}
