import { Component, OnInit, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TAGSCONSTS } from "../../../../../../constants/tags.constants";
import { takeWhile, skip } from 'rxjs/operators';

import {
    BreadcrumbService,
    NotificationService,
    FilterService,
    // XbaConstsService,
    GlobalValidator,
    CompanyService,
    BusinessService,
    UserHandlerService,
    TreeService,
    UrlStoreService,
    TabStructureService
} from "../../../../../../shared/services";
import {
    SetupBusinessInformationStore,
    SetupCompanyStore,
    ConstantStore,
    SetupBusinessStructureStore
} from "../../../../../../store";
import {
    Business,
    Limit,
    Structs,
    DBAudit
} from "../../../../../../models";
import { NO_PERMISSION_MSG } from "../../../../../../constants";
// import { BaseComponent } from "../../../../../../shared/modules/sections/components/base.component";

@Component({
    selector: "add-edit",
    templateUrl: "./add-edit.component.html",
    styleUrls: ["./add-edit.component.scss"]
})
export class SetupStructureInformationComponent implements OnInit, OnDestroy {
    setupBusinessInformationModel: any = {}; //For storing value retrieved from server in edit mode
    businessType: any[]; //For storing array of xba constants
    serverErrors: any = {}; //Stores errors returned by server
    form: FormGroup; //Required for reactive form validation
    submitting: boolean = false; //For handling form submission
    edit: boolean = false; //Check for add/edit mode
    paramId: string = ""; //Show loading in case of edit mode
    disable: boolean = false;
    alive: boolean = true;
    xRefNumEditMode: string = "";

    loading: boolean = false;
    // tag:string;

    companyInformation: any = {};
    formErrors = {
        xName: "",
        // xPhone: '',
        xDBAName: "",
        xAKAName: "",
        xTntTag: "",
        xRefNum: "",
        xBusinessNum: ""
    };
    numberKey: string = "xBusinessNum";
    parentMode: boolean = false;

    bizChangeEvent: boolean = false;
    canAdd = true; //boolean controller to enable/disable adding new business
    canEdit = true; //boolean controller to enable/disable editing business
    noPermMsg = NO_PERMISSION_MSG;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private notificationService: NotificationService,
        private filterService: FilterService,
        // private xbaConstsService: XbaConstsService,
        private setupBusinessInformationStore: SetupBusinessInformationStore,
        private fb: FormBuilder,
        private location: Location,
        private companyService: CompanyService,
        public businessService: BusinessService,
        private setupCompanyStore: SetupCompanyStore,
        public userHandlerService: UserHandlerService,
        public treeService: TreeService,
        public constantStore: ConstantStore,
        public urlStoreService: UrlStoreService,
        public setupBusinessStructureStore: SetupBusinessStructureStore,
        private tabStructureService: TabStructureService
    ) {}

    ngOnInit() {
        // incase of edit mode we get business id here
        // this.loading=false;
        console.log(
            "%c HEre bis add-edit",
            "background: lime; color: white",
            this.tabStructureService.defaultTabStructureLabel,
            this.tabStructureService.previousTabStructureLabel
        );
        this.setupPerms();
        this.tabStructureService.checkAndSetTabNameToDef(
            this.tabStructureService.tabMode,
            this.tabStructureService.isCancelClick,
            this.tabStructureService.isSubmitClick
        );
        this.route.params.forEach((params: Params) => {
            if (params.hasOwnProperty("Id")) {
                if (this.businessService.businessID.getValue()) {
                    this.edit = true;
                    this.paramId = params["Id"];
                } else {
                    if (
                        !this.treeService.businessID.getValue() &&
                        ((this.tabStructureService.editMode ||
                            this.treeService.nodeClick) &&
                            !this.tabStructureService.tabMode)
                    ) {
                        console.log("Inside If of business");
                        this.treeService.setId(params.Id, "business");
                    }
                    // this.router.navigate(['/dashboard/setup/business/structure/information']);
                }
            }
        });
        if (this.tabStructureService.tabMode) {
            this.checkParentMode();
        }
        // this.getTabStructure();

        //TODO-: Used for testing getStructure route. Remove when implemented otherwise.
        // this.setupBusinessInformationStore.test();

        if (this.edit) {
            if (
                !this.treeService.businessID.getValue() &&
                ((this.tabStructureService.editMode ||
                    this.treeService.nodeClick) &&
                    !this.tabStructureService.tabMode)
            ) {
                console.log("Inside If of business");
                this.treeService.setId(
                    this.businessService.businessID.getValue(),
                    "business"
                );
            }
            this.loading = true;
            this.fetchBusinessInfo(this.paramId); //Fetch Company Data if Company ID is present
        }

        this.businessService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe((bizID: string) => {
                if (bizID) {
                    this.bizChangeEvent = true;
                    this.fetchBusinessInfo(bizID);
                } else {
                    this.router.navigate([
                        "/dashboard/setup/business/structure/information"
                    ]);
                }
            });

            // this.fetchBusinessTypes(); //Fetch all Business Type
            this.toFormGroup(new Business()); //Initialise Form Validation
        this.setBreadCrumbValue();
        this.filterService.setFilterValue(false);
        this.setupBusinessStructureStore.watchNodePath$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe((nodePath: any[]) => {
                console.log("This is updated node path", nodePath);
                // if (this.treeService.businessID.getValue()) {
                //     this.getTabStructure();
                // }
                this.parentMode = false;
                this.checkParentMode();
            });
    }

    // fetchBusinessTypes(): void {
    //   this.xbaConstsService.getXbaConsts("biz").subscribe(
    //     (response: any) => {
    //       //TODO remove company business from business list
    //       this.businessType = response.data.filter(d => d.xTypeTag !== 'biz.comp');
    //     },
    //     (error: any) => {
    //       this.notificationService.error(
    //         JSON.parse(error._body).name,
    //         JSON.parse(error._body).message
    //       );
    //     }
    //   );
    // }

    checkParentMode() {
        if (this.tabStructureService.tabLabelItems.length) {
            this.tabStructureService.tabLabelItems.map(labelItem => {
                if (labelItem.label.includes("Business")) {
                    if (
                        labelItem.hasOwnProperty("type") &&
                        labelItem["type"] === "Parent"
                    ) {
                        this.parentMode = true;
                    } else {
                        this.parentMode = false;
                    }
                }
            });
        }
    }

    fetchCompanyInformation(companyID: string): void {
        this.setupCompanyStore.get(companyID).subscribe(
            (response: any) => {
                this.companyInformation = response.data;
            },
            (error: any) => {
                this.notificationService.error(
                    JSON.parse(error._body).name,
                    JSON.parse(error._body).message
                );
            }
        );
    }

    fetchBusinessInfo(businessId: string): void {
        this.setupBusinessInformationStore.get(businessId).subscribe(
            (response: any) => {
                this.loading = false;
                this.setupBusinessInformationModel = response.data;
                this.form.patchValue(
                    new Business(this.setupBusinessInformationModel)
                );
                if (this.edit) {
                    this.form.controls["xRefNum"].disable();
                    this.xRefNumEditMode = this.setupBusinessInformationModel[
                        "xRefNum"
                    ];
                } else {
                    this.form.controls["xRefNum"].enable();
                }
            },
            (error: any) => {
                // this.routeToPrevious();
                this.urlStoreService.routeBackToPreviousUrl();
                this.businessService.resetBusinessID(); // Added to reset businessID from businessService when no data is found for a particular business
                this.treeService.resetBusinessID(); // Added to reset businessID from treeService when no data is found for a particular business
                this.businessService.toggleRefreshBusinessList(); // Added to remove the business from business list in the top nav when no data is found for a particular business
                const errMsg = JSON.parse(error._body).message;
                this.notificationService.error(
                    errMsg,
                    businessId + " Not Found!"
                );
            },
            () => {
                this.disable = true;
            }
        );
    }

    setBreadCrumbValue(): void {
        const breadCrumb: any = {};
        breadCrumb.root = "Setup";
        breadCrumb.child = "Business";
        breadCrumb.subChild = this.edit ? "Edit" : "Add";
        breadCrumb.subChildLink = null;
        breadCrumb.grandChild = null;
        this.breadcrumbService.setBreadCrumb(breadCrumb);
    }
    toFormGroup(data: Business): void {
        this.form = this.fb.group({
            xTntTag: [data.xTntTag || "", []],
            xWall1EntyID: [data.xWall1EntyID || "", []],
            xWall2EntyID: [data.xWall2EntyID || "", []],
            xTag: [{ value: "biz", disabled: true }, []],
            xStatus: [data.xStatus || "1", []],
            xName: [
                data.xName || "",
                [Validators.required, GlobalValidator.validateName]
            ],
            xTypeTag: [
                data.xTypeTag ||
                    TAGSCONSTS.buisnessTags.regularBiz /* || 'business'*/,
                []
            ],
            xBusinessNum: [data.xBusinessNum || "", [Validators.required]],
            xNotes: [data.xNotes || "", []],
            xRefNum: [data.xRefNum || "", []],
            xDBAName: [data.xDBAName || "", []],
            xAKAName: [data.xAKAName || "", []],
            xTZTag: [data.xTZTag, []],
            xMainCcyTag: [data.xMainCcyTag || "", []],
            // xAddr: this.fb.group(new Address(data.xAddr)),
            // xSocial: this.fb.group(new GeneralContact(data.xSocial)),
            // xMainContact: this.fb.group(new MainContact(data.xMainContact)),
            xSvcCompanyID: [this.companyService.companyID.getValue() || "", []],
            xStructCaps: this.fb.group(new Structs(data.xStructCaps)),
            xLimitCaps: this.fb.group(new Limit(data.xLimitCaps)),
            xAsCustomerID: [data.xAsCustomerID || "", []],
            xOrgSWLcsTag: [data.xOrgSWLcsTag || "", []],
            xDBAudit: this.fb.group(
                new DBAudit({
                    xDataSrc: TAGSCONSTS.dataSourceTags.dataSrcItemTags.self
                })
            ),
            xAutoFillMermNum:data.xAutoFillMermNum,
            xVTerminalShowExpandBtn:data.xVTerminalShowExpandBtn,
        });
    }

    onSubmit(form: FormGroup): void {
        this.tabStructureService.isSubmitClick = true;
        this.submitting = true;
        this.serverErrors = {}; //Reset Errors

        if (!this.edit) {
            this.form
                .get("xAddr")
                .get("xCO")
                .setValue(this.form.get("xName").value);
        } else {
            form.get("xRefNum").setValue(this.xRefNumEditMode);
        }

        const businessModel = form.getRawValue();
        // businessModel.xTypeTag=this.tag;
        console.log("biz model", businessModel);

        if (this.edit) {
            //Update business
            this.setupBusinessInformationStore
                .update(this.setupBusinessInformationModel._id, businessModel)
                .subscribe(
                    (response: any) => {
                        this.handleSuccessResponse(response.data);
                    },
                    (error: any) => {
                        this.serverErrors = error.errors;
                        this.handleError(this.serverErrors);
                    },
                    () => {}
                );
        } else {
            //Add new business
            this.setupBusinessInformationStore.store(businessModel).subscribe(
                (response: any) => {
                    this.handleSuccessResponse(response.data);
                },
                (error: any) => {
                    this.submitting = false;
                    this.serverErrors = error.errors;
                    this.handleError(this.serverErrors);
                },
                () => {
                    // this.submitting = false;
                }
            );
        }
    }

    handleSuccessResponse(data: any): void {
        // console.log("HERE---->");
        this.submitting = false;
        this.notificationService.success(
            "Record Successfully Added.",
            "Success!"
        );
        this.businessService.toggleRefreshTree();
        this.businessService.toggleRefreshBusinessList();
        if (this.treeService.nodeClick) {
            // console.log("HERE----> 1");
            if (this.edit) {
                // console.log("HERE----> 1.1");
                this.treeService.setId(
                    this.businessService.businessID.getValue(),
                    "business"
                );
                this.urlStoreService.routeBackToPreviousUrl();
                // this.fetchBusinessInfo(data.id);
            } else {
                // console.log("HERE----> 1.2");
                console.log("Inside add routing");
                this.urlStoreService.routeToCurrentUrl(data, "business", "add");
            }
        } else {
            this.treeService.setId(
                this.businessService.businessID.getValue(),
                "business"
            );
            this.urlStoreService.routeBackToPreviousUrl();
        }
    }

    handleError(error: any): void {
        this.submitting = false;
        for (const field of Object.keys(error)) {
            if (this.formErrors.hasOwnProperty(field)) {
                this.formErrors[field] = "Field is not allowed to be empty";
            }
            if (field === "xRefNum") {
                console.log("Inside reference number handle error", error);
                if (error.name === "Conflict") {
                    let err = error.errors.xRefNum.replace(
                        /business/g,
                        "Business"
                    );
                    this.notificationService.error(err, "Conflict");
                    this.clearErrors("xRefNum");
                }
            }
        }
    }

    onKeypress(event: any) {
        GlobalValidator.phoneNumberFormat(event);
    }

    onPaste(event: any) {
        GlobalValidator.phoneNumberFormat(event);
    }

    clearErrors(errorName: string): void {
        if (this.serverErrors[errorName]) {
            delete this.serverErrors[errorName];
        }
    }

    onCancel(event: Event) {
        event.preventDefault();
        if (this.tabStructureService.tabMode) {
            this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
            this.urlStoreService.routeToSecondLastUrl();
        } else {
            if (this.edit) {
                console.log("no tabmode and edit");
                if (!this.tabStructureService.editMode) {
                    console.log("no tabmode and edit and editmode");
                    this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
                    if (
                        this.urlStoreService.previousUrl &&
                        !this.urlStoreService.previousUrl.includes("add-edit")
                    ) {
                        this.treeService.setId(
                            this.treeService.previousSelectedNode.id,
                            this.treeService.previousSelectedNode.type
                        );
                    }
                    this.urlStoreService.routeBackToPreviousUrl();
                    // }
                } else {
                    console.log("no tabmode and edit and no editmode");
                    if (this.treeService.nodeClick) {
                        this.urlStoreService.routeToSecondLastUrl();
                    } else {
                        this.treeService.setId(
                            this.treeService.previousSelectedNode.id,
                            this.treeService.previousSelectedNode.type
                        );
                        this.urlStoreService.routeBackToPreviousUrl();
                    }
                }
            } else {
                console.log("no tabmode and no edit");
                this.urlStoreService.routeBackToPreviousUrl();
            }
        }
    }

    onEditClick(event: Event) {
        console.log(
            "Here onEditClick function in biz===>",
            this.parentMode,
            JSON.parse(JSON.stringify(this.setupBusinessInformationModel))
        );
        this.tabStructureService.previousTabStructureLabel = JSON.parse(
            JSON.stringify(this.tabStructureService.defaultTabStructureLabel)
        );
        this.treeService.isParent = false;
        this.parentMode = false;
        this.edit = true;
        this.treeService.setId(
            this.setupBusinessInformationModel._id,
            "business"
        );
        // this.router.navigate(['dashboard/setup/business/structure/information'])
    }

    setupPerms(){
        this.canAdd = this.userHandlerService.userPerms.canAddBiz;
        this.canEdit = this.userHandlerService.userPerms.canEditBiz;
    }

    ngOnDestroy() {
        this.alive = false;
    }
}
