import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetupLicenseComponent } from './setup-license.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [SetupLicenseComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	exports: [SetupLicenseComponent],
	providers: [],
})
export class SetupLicenseModule {}
