export class TabNames {
    business: string;
    chain: string;
    region: string;
    property: string;
    store:  string;
    pctr: string;
    meracct: string;
    terminal: string;
    periph: string;

    constructor(obj?:TabNames){
        this.business= obj? obj.business : "";
        this.chain= obj? obj.chain : "";
        this.region= obj? obj.region : "";
        this.property= obj? obj.property : "";
        this.store=  obj? obj.store : "";
        this.pctr= obj? obj.pctr : "";
        this.meracct= obj? obj.meracct : "";
        this.terminal= obj? obj.terminal : "";
        this.periph= obj? obj.periph : "";
    }
}