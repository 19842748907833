import { Component, OnInit } from '@angular/core';
import { SetupStructureTerminalStore } from '../../../../../store';
import { TreeService, BusinessService, TabStructureService, UserHandlerService, BreadcrumbService } from '../../../../../shared/services';
import { Router } from '@angular/router';

@Component({
	selector: 'app-setup-structure-terminals-list',
	templateUrl: './setup-structure-terminals-list.component.html',
	styleUrls: ['./setup-structure-terminals-list.component.scss']
})
export class SetupStructureTerminalsListComponent implements OnInit {
	public attributes: any[] = [];
	public storeValue: string = '';
	public storeObject: any = {};
	public action: any[] = [];
	public parameters: any = {
		// noGrid: true
		// xTypeTag: 'struct.chain'
	};
	fetchByStore: boolean = true;
	// public detailVisible: boolean = true;
	public terminalID: string = '';
	public transactionType: string = 'all';
	showDetails: boolean = false;
	setBizId: boolean = true;
	addBtn: boolean = false;
	tooltipMessage: string = 'Add Terminal';
	public viewDetails: any[] = [
		{ header: 'Active', mapTo: 'xStatus' },
		{ header: 'Name', mapTo: 'xName' },
		{ header: 'TypeTag', mapTo: 'xTypeTag' },
		{ header: 'Tag', mapTo: 'xTag' },
		{ header: 'Notes', mapTo: 'xNotes' },
		{ header: 'User Name', mapTo: 'xUName' },
		{ header: 'ID', mapTo: '_id' }
		// { header: 'Main Phone', mapTo: 'xMainContact.xPhone' }
	];

	constructor(
		private router: Router,
		private setupStructureTerminalStore: SetupStructureTerminalStore,
		public treeService: TreeService,
		public businessService: BusinessService,
		private tabStructureService: TabStructureService,
		private userHandlerService: UserHandlerService,
		private breadcrumbService: BreadcrumbService
	) {}

	ngOnInit() {
		this.setBreadCrumbValue();
		// this.urlStoreService.isForm=false;
		this.treeService.isParent = false;
		this.tabStructureService.checkAndSetTabNameToDef(
			this.tabStructureService.tabMode,
			this.tabStructureService.isCancelClick,
			this.tabStructureService.isSubmitClick
		);
		//For upward hierarchy-------->
		if (this.treeService.periphID.getValue()) {
			this.addBtn = false;
			this.tooltipMessage = 'Please select a merchant account to add terminal';
			this.setBizId = false;
			console.log(
				'%c this.treeService.treeDataUnderStore.xTerminalID hai ya nahi??',
				'background: pink; color: black',
				this.treeService.treeDataUnderStore.xTerminalID
			);
			if (this.treeService.treeDataUnderStore.xTerminalID) {
				console.log(
					'%c this.treeService.treeDataUnderStore.xTerminalID',
					'background: pink; color: black',
					this.treeService.treeDataUnderStore.xTerminalID
				);
				this.router.navigate(['/dashboard/setup/business/structure/terminal', this.treeService.treeDataUnderStore.xTerminalID]);
			}
		}
		//End of upward hierarchy-------->
		//For self------>
		if (this.treeService.terminalID.getValue()) {
			this.setBizId = false;
			this.treeService.isParent = true;
			this.router.navigate(['/dashboard/setup/business/structure/terminal', this.treeService.terminalID.getValue()]);
		}
		// End for self------->
		//For downward hierarchy------->
		if (this.treeService.businessID.getValue()) {
			this.addBtn = false;
			this.tooltipMessage = 'Please select a merchant account to add terminal';
			this.setBizId = false;
			console.log('here');
			// let tempObj = {
			//     business: "current",
			//     chain: "list",
			//     region: "list",
			//     property: "list",
			//     store: "list",
			//     pctr: "list",
			//     meracct: 'list',
			//     terminal: 'list',
			//     periph: 'list',
			// }
			// this.businessService.setTabStructureNames(tempObj);
		}
		if (this.treeService.chainID.getValue()) {
			this.addBtn = false;
			this.tooltipMessage = 'Please select a merchant account to add terminal';
			this.setBizId = false;
			this.parameters = {
				// xTypeTag: 'struct.property',
				struct_type: 'chain',
				structID: this.treeService.chainID.getValue()
			};
		}
		if (this.treeService.regionID.getValue()) {
			this.addBtn = false;
			this.tooltipMessage = 'Please select a merchant account to add terminal';
			this.setBizId = false;
			this.parameters = {
				// xTypeTag: 'struct.property',
				struct_type: 'region',
				structID: this.treeService.regionID.getValue()
			};
		}
		if (this.treeService.propertyID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a merchant account to add terminal';
			this.parameters = {
				// xTypeTag: 'struct.property',
				struct_type: 'property',
				structID: this.treeService.propertyID.getValue()
			};
		}
		if (this.treeService.storeID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a merchant account to add terminal';
			this.parameters = {
				// xTypeTag: 'struct.property',
				xStoreID: this.treeService.storeID.getValue()
			};
		}
		if (this.treeService.profitCenterID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a merchant account to add terminal';
			this.parameters = {
				xMainPCtrID: this.treeService.profitCenterID.getValue()
			};
		}
		if (this.treeService.merchantAccID.getValue()) {
			this.setBizId = false;
			this.addBtn = true;
			this.tooltipMessage = 'Add Terminal';
			this.parameters = {
				xMainMerAcctID: this.treeService.merchantAccID.getValue()
			};
		} else {
			console.log("I'm fucking here");
			if (this.setBizId) {
				this.addBtn = false;
				this.tooltipMessage = 'Please select a merchant account to add terminal';
				this.treeService.setId(this.businessService.businessID.getValue(), 'business');
			}
		}
		//End of downward hierarchy----->

		this.setGridProperties();
	}

	setBreadCrumbValue(): void {
		const breadCrumb: any = {};
		breadCrumb.root = 'Setup';
		breadCrumb.child = 'Structure';
		breadCrumb.subChild = 'Terminal';
		breadCrumb.subChildLink = null;
		breadCrumb.grandChild = 'Terminals';
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	setGridProperties() {
		this.storeValue = 'SetupStructureTerminalStore';
		this.storeObject = this.setupStructureTerminalStore;
		// this.attributes = [
		//     { header: 'Active', mapTo: 'xStatus', width: '10%' },
		//     { header: 'Name', mapTo: 'xName', width: '20%' },
		//     { header: 'TypeTag', mapTo: 'xTypeTag', width: '20%' },
		//     { header: 'Tag', mapTo: 'xTag', width: '20%' },
		//     // { header: 'Medium', mapTo: 'xMdmTag', width: '10%' },
		//     // { header: 'SoP', mapTo: 'xStrVal', width: '10%' },
		//     { header: 'Notes', mapTo: 'xNotes', width: '20%' },
		//     { header: 'Actions', mapTo: '', width: '10%'}
		// ];
		const editLink = '/dashboard/setup/business/structure/terminal';
		this.action = [
			{ type: 'view', visible: true, text: 'Details', icon: 'fa fa-eye', action: 'emit' }
			// { type: 'edit', visible: true, text: 'Edit', icon: 'fa fa-edit', action: 'url', onClick: editLink },
			// { type: 'delete', visible: true, text: 'Delete', icon: 'fa fa-trash', action: 'func', onClick: 'destroy'}
		];
		if (!this.userHandlerService.userRoles.isBusinessEmployee) {
			this.attributes = [
				{ header: 'Active', mapTo: 'xStatus', width: '10%' },
				{ header: 'Name', mapTo: 'xName', width: '20%' },
				{ header: 'Tag', mapTo: 'xTag', width: '20%' },
				{ header: 'Type Tag', mapTo: 'xTypeTag', width: '20%' },
				// { header: 'Medium', mapTo: 'xMdmTag', width: '10%' },
				// { header: 'SoP', mapTo: 'xStrVal', width: '10%' },
				{ header: 'Notes', mapTo: 'xNotes', width: '20%' },
				{ header: 'Actions', mapTo: '', width: '10%' }
			];
			this.action.push(
				{
					type: 'edit',
					visible: true,
					text: 'Edit',
					icon: 'fa fa-edit',
					action: 'url',
					onClick: editLink
				},
				{
					type: 'delete',
					visible: true,
					text: 'Delete',
					icon: 'fa fa-trash',
					action: 'func',
					onClick: 'destroy',
					disabled: !this.userHandlerService.userPerms.canDeleteSetup
				}
			);
		} else if (this.userHandlerService.userRoles.isBusinessEmployee) {
			this.attributes = [
				{ header: 'Active', mapTo: 'xStatus', width: '10%' },
				{ header: 'Name', mapTo: 'xName', width: '25%' },
				{ header: 'Tag', mapTo: 'xTag', width: '20%' },
				{ header: 'Type Tag', mapTo: 'xTypeTag', width: '20%' },
				// { header: 'Medium', mapTo: 'xMdmTag', width: '10%' },
				// { header: 'SoP', mapTo: 'xStrVal', width: '10%' },
				{ header: 'Notes', mapTo: 'xNotes', width: '25%' }
			];
		}
	}

	onActionRequest(obj: any) {
		if (obj.act.type === 'view') {
			console.log("I'm here bro", obj);
			this.terminalID = obj.id;
			// this.detailVisible = true;
		}
	}

	onShowDetails(obj: any) {
		this.showDetails = obj;
	}

	viewClosed(visible: boolean) {
		if (!visible) {
			this.terminalID = '';
			this.showDetails = false;
		}
	}
}
