import { environment } from "../../../environments/environment";
import { HttpClient} from '@angular/common/http'
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable()
export class VtermConfig{
	constructor(private http: HttpClient){}

	private VTERM_HOST_ENV : string = '';

	public getConfig(){
		return new Promise((resolve,reject)=>{
			this.http.get('./../../../assets/config/vterm-config.json')
			// .map(res=>res.json())
			.pipe(
				catchError((err) => {
					console.log('failed to get config file');
					return throwError({error:err});
				})
			)
			.subscribe((response:any)=>{
				console.log('getConfig: response',response);
				this.VTERM_HOST_ENV = response.VTERM_HOST_LOCAL;
				if(environment){
					if(environment.production){
						this.VTERM_HOST_ENV = response.VTERM_HOST_PRODUCTION; 
					}
					else if(environment.staging){
						this.VTERM_HOST_ENV = response.VTERM_HOST_STAGING;
					}else if(environment.hmr){
						this.VTERM_HOST_ENV = response.VTERM_HOST_QA;
					}
				}
				console.log("VTERM_HOST:", this.VTERM_HOST_ENV);
				return resolve(response);
			})
		})
	}

	get VTERM_HOST():string{
		return this.VTERM_HOST_ENV;
	}
}