import { Component, OnInit } from '@angular/core';
import { PaymentService,LoginStoreService,UserHandlerService } from '../../../../shared/services/index';
import { Router } from '@angular/router';

@Component({
    selector: 'app-confirm-payment',
    templateUrl: './confirm-payment.component.html',
    styleUrls: ['./confirm-payment.component.scss']
})
export class ConfirmPaymentComponent implements OnInit {
    public paymentData: any = {};
    public paymentMethod: any = {};
    constructor(
        private paymentService: PaymentService,
        private router: Router,
        private loginStoreService: LoginStoreService,
        private userHandlerService: UserHandlerService
        ) {}

    ngOnInit() {
        console.log('confirmPayment oninit: paymentservice data', this.paymentService.paymentData);
        console.log('confirmPayment oninit: paymentservice Method', this.paymentService.paymentMethod);
        this.paymentData = this.paymentService.paymentData;
        this.paymentMethod = this.paymentService.paymentMethod;
    }

    // convert expiry month and year to string
    convertToExpDateStr(month,year){
        let monthStr = '', yearStr = '', result = '';
        year = year % 100;
        console.log('year',year);
        if(month<10){
            monthStr = '0' + month.toString();
        } else {
            monthStr = month.toString();
        }
        console.log('month',monthStr);
        if(year<10){
            yearStr = '0' + year.toString();
        } else {
            yearStr = year.toString();
        }
        console.log('yearstr',yearStr);
        return monthStr + yearStr;
    }
    
    // create payLoad and proceed for payment
    proceedToPay(){
        console.log('proceedToPay: enter');
        let paymentType = 'sale';
        let entityData = Object.keys(this.userHandlerService.userType).length ? this.userHandlerService.userType : {};
        let userData = Object.keys(this.userHandlerService.userData).length ? this.userHandlerService.userData : {};
        console.log('proceedToPay: userdata',userData);
        console.log('proceedToPay: entitydata',entityData);
        let memberNumber = Object.keys(entityData).length 
            && entityData.hasOwnProperty('xMemberNumber')
            && entityData['xMemberNumber']
            ? entityData['xMemberNumber']
            : '';
        let customerName = Object.keys(entityData).length 
            && entityData.hasOwnProperty('xName')
            && entityData['xName']
            ? entityData['xName']
            : '';
        let customerAddr = Object.keys(entityData).length 
            && entityData.hasOwnProperty('xAddr')
            && entityData['xAddr']
            ? entityData['xAddr']
            : '';
        let customerEmail = Object.keys(entityData).length 
            && entityData.hasOwnProperty('xContact')
            && entityData['xContact']
            && entityData['xContact'].hasOwnProperty('xEmail')
            && entityData['xContact']['xEmail']
            ? entityData['xContact']['xEmail']
            : '';
        let customerPhone = Object.keys(entityData).length 
            && entityData.hasOwnProperty('xContact')
            && entityData['xContact']
            && entityData['xContact'].hasOwnProperty('xPhone')
            && entityData['xContact']['xPhone']
            ? entityData['xContact']['xPhone']
            : '';
        let userID = Object.keys(userData).length 
            && userData.hasOwnProperty('_id')
            && userData['_id']
            ? userData['_id']
            : '';
        let GMID = Object.keys(this.loginStoreService.pctrData).length
            && this.loginStoreService.pctrData.hasOwnProperty('xGMID')
            && this.loginStoreService.pctrData['xGMID']
            ? this.loginStoreService.pctrData['xGMID']
            : '';
        let GTID = Object.keys(this.loginStoreService.terminalData).length
            && this.loginStoreService.terminalData.hasOwnProperty('xGTID')
            && this.loginStoreService.terminalData['xGTID']
            ? this.loginStoreService.terminalData['xGTID']
            : '';
        let GMPW = Object.keys(this.loginStoreService.pctrData).length
            && this.loginStoreService.pctrData.hasOwnProperty('xGMPW')
            && this.loginStoreService.pctrData['xGMPW']
            ? this.loginStoreService.pctrData['xGMPW']
            : '';
        let pctrName = Object.keys(this.loginStoreService.pctrData).length
            && this.loginStoreService.pctrData.hasOwnProperty('xName')
            && this.loginStoreService.pctrData['xName']
            ? this.loginStoreService.pctrData['xName']
            : '';
        let storeName = Object.keys(this.loginStoreService.storeData).length
            && this.loginStoreService.storeData.hasOwnProperty('xName')
            && this.loginStoreService.storeData['xName']
            ? this.loginStoreService.storeData['xName']
            : '';
        let storeAddr = Object.keys(this.loginStoreService.storeData).length
            && this.loginStoreService.storeData.hasOwnProperty('xAddr')
            && this.loginStoreService.storeData['xAddr']
            ? this.loginStoreService.storeData['xAddr']
            : '';
        let bizID = Object.keys(this.loginStoreService.pctrData).length
            && this.loginStoreService.pctrData.hasOwnProperty('xBusinessID')
            && this.loginStoreService.pctrData['xBusinessID']
            ? this.loginStoreService.pctrData['xBusinessID']
            : '';
        let terminalID = Object.keys(this.loginStoreService.terminalData).length
            && this.loginStoreService.terminalData.hasOwnProperty('_id')
            && this.loginStoreService.terminalData['_id']
            ? this.loginStoreService.terminalData['_id']
            : '';
        let storeEmail = Object.keys(this.loginStoreService.storeData).length
            && this.loginStoreService.storeData.hasOwnProperty('xMainContact')
            && this.loginStoreService.storeData['xMainContact']
            && this.loginStoreService.storeData['xMainContact'].hasOwnProperty('xEmail')
            && this.loginStoreService.storeData['xMainContact']['xEmail']
            ? this.loginStoreService.storeData['xMainContact']['xEmail']
            : '';
        let storePhone = Object.keys(this.loginStoreService.storeData).length
            && this.loginStoreService.storeData.hasOwnProperty('xMainContact')
            && this.loginStoreService.storeData['xMainContact']
            && this.loginStoreService.storeData['xMainContact'].hasOwnProperty('xPhone')
            && this.loginStoreService.storeData['xMainContact']['xPhone']
            ? this.loginStoreService.storeData['xMainContact']['xPhone']
            : '';
        let amount = this.paymentData 
            && this.paymentData.hasOwnProperty('paymentAmount')
            && this.paymentData['paymentAmount']
            ? this.paymentData['paymentAmount']
            : '0';
        let notes = this.paymentData 
            && this.paymentData.hasOwnProperty('notes')
            && this.paymentData['notes']
            ? this.paymentData['notes']
            : '0';
        
        let payload = {
            requestID: "reqstID",
            paymentType: paymentType,
            GMID: GMID,
            GTID: GTID,
            GMPW: GMPW,
            accountNum: '',
            cvvNum: '',
            expDate: this.convertToExpDateStr(this.paymentService.paymentMethod['xExpMonth'],this.paymentService.paymentMethod['xExpYear']),
            amount: amount,
            incTaxAmt: '0',
            notes: notes,
            invoiceNumber: '',
            memberNumber: memberNumber,
            webAppInfo: {
                bizID: bizID,
                terminalID: terminalID
            },
            userInfo:{
                userID: userID
            },
            merchantInfo:{
                name: pctrName
            },
            storeInfo:{
                email: storeEmail,
                name: storeName,
                phone: storePhone,
                address: storeAddr
            },
            customerInfo:{
                memberNumber: memberNumber,
                name: customerName,
                email: customerEmail,
                address: customerAddr,
                phone:customerPhone
            }
        }
        console.log('proceedToPay: payload',payload);
    }
    // called when user clicks cancel
    routeToPaymentForm() {
        this.router.navigate(['/dashboard/customer/pay-now']);
    }
}
