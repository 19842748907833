<section *ngIf="!loading && !noRegion" class="setup buis-info">
    <form class="form-horizontal" [ngClass]="{'disable-form':(parentMode || userHandlerService?.userRoles?.isBusinessEmployee)}" [formGroup]="form" (ngSubmit)="onRegionSubmit(form)" novalidate>
        <div class="row top-stat">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-check-circle-o label-icon label-head-icon" aria-hidden="true"></i>
                        <label for="name">Status:
                        </label>
                        <label class="detail-label"> Active
                        </label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-sliders label-icon label-head-icon" aria-hidden="true"></i>
                        <label for="name" class="p-l-5">Type:</label>
                        <label class="detail-label"> Region</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-briefcase label-icon" aria-hidden="true"></i>
                        <label for="name">Business:
                        </label>
                        <label class="detail-label"> {{businessService.businessData?.xName}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-level-up label-icon fa-flip-horizontal" aria-hidden="true"></i>
                        <label *ngIf="ifRegionExtendsChain" class="p-l-5">Chain:</label>
                        <label *ngIf="!ifRegionExtendsChain" class="p-l-5">Business:</label>
                        <label *ngIf="ifRegionExtendsChain" class="detail-label">{{chainName}}</label>
                        <label *ngIf="!ifRegionExtendsChain" class="detail-label">{{businessService.businessData?.xName}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div>

        <div class="row">
            <div class="col-sm-8">
                <div class="card">
                    <div class="card-header">
                        <label class="">Basic</label>
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-12 col-sm-12">
                                <label for="name">Name:
                                    <small class="text-danger">*</small>
                                </label>

                                <small *ngIf="!serverErrors['xName'] && form.get('xName').hasError('required') && form.get('xName').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <small *ngIf="!serverErrors['xName'] && form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is of incorrect format. Correct Format-: 1) Name can not be more than 20 letters. 2) The first character of Name should not be an empty space. 3) The first character of Name should not be any special characters, E.g. /,& etc." placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <small *ngIf="serverErrors['xName']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <input type="text" class="form-control form-control-sm" id="store_Name" (focus)="clearErrors('xName')" formControlName="xName"
                                    [ngClass]="{'has-error': serverErrors['xName'] || (form.get('xName').hasError('required') && form.get('xName').touched) || (form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched)}"
                                    validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row">
                            <!-- <div class="col-md-6 col-sm-12">
                                <label for="phone">Phone: <small class="text-danger">*</small></label>

                                <small *ngIf="!serverErrors['xPhone'] && form.get('xPhone').hasError('required') && form.get('xPhone').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Phone is required" placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>

                                <small *ngIf="serverErrors['xPhone']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Phone is required" placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>

                                </small>

                                <input type="text" class="form-control form-control-sm" id="phone" (focus)="clearErrors('xPhone')" formControlName="xPhone"
                                    [ngClass]="{'has-error': (form.get('xPhone').hasError('required') && form.get('xPhone').touched) || serverErrors['xPhone']}"
                                    (keypress)="onKeypress($event)" (paste)="onPaste($event)" validateOnBlur>
                            </div> -->

                            <div class="col-md-6 col-sm-12">
                                <label for="name_two">Sub Name:
                                    <!-- <small class="text-danger">*</small> -->
                                </label>

                                <small *ngIf="!serverErrors['xSubName'] && form.get('xSubName').hasError('required') && form.get('xSubName').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Sub Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>

                                </small>

                                <small *ngIf="serverErrors['xSubName']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Sub Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>

                                </small>

                                <input type="text" class="form-control form-control-sm" id="name_two" (focus)="clearErrors('xSubName')" formControlName="xSubName"
                                    [ngClass]="{'has-error': (form.get('xSubName').hasError('required') && form.get('xSubName').touched) || serverErrors['xSubName']}"
                                    validateOnBlur>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="tag">Tag:
                                    <!-- <small class="text-danger">*</small> -->
                                </label>

                                <!-- <small *ngIf="!errors['xTag'] && form.get('xTag').hasError('required') && form.get('xTag').touched" class="inline-error"> Tag is required</small>

                                    <small *ngIf="errors['xTag']" class="inline-error"> {{ errors['xTag']?.message.replace('"xTag"', 'Field')}}</small> -->

                                <input type="text" class="form-control form-control-sm" id="tag" (focus)="clearErrors('xTag')" formControlName="xTag" [disabled]=true>
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-6 col-sm-12">
                                <label for="ref_num">Ref Number:
                                    <!-- <small class="text-danger">*</small> -->
                                </label>

                                <small *ngIf="!serverErrors['xRefNum'] && form.get('xRefNum').hasError('required') && form.get('xRefNum').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Ref Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <small *ngIf="serverErrors['xRefNum']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Ref Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>

                                </small>
                                <i class="fa fa-info-circle" tooltip="Do not enter the reference number unless instructed to do so" placement="top" containerClass="tooltip-global"
                                    container="body" aria-hidden="true"></i>
                                <input type="text" class="form-control form-control-sm" id="ref_num" (focus)="clearErrors('xRefNum')" formControlName="xRefNum"
                                    [ngClass]="{'has-error': (form.get('xRefNum').hasError('required') && form.get('xRefNum').touched) || formErrors['xRefNum']}"
                                    validateOnBlur>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="region_num">Region Number:
                                    <small class="text-danger">*</small>
                                </label>
                                <!-- <small *ngIf="!serverErrors['xStructNum'] && form.get('xStructNum').hasError('required') && form.get('xStructNum').touched" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Region number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                <small *ngIf="serverErrors['xStructNum']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Region number is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>

                                </small>

                                <input type="text" class="form-control form-control-sm" id="region_num" (focus)="clearErrors('xStructNum')" formControlName="xStructNum"
                                    [ngClass]="{'has-error': serverErrors['xStructNum'] || (form.get('xStructNum').hasError('required') && form.get('xStructNum').touched)}"
                                    validateOnBlur> -->
                                <app-model-number [modelNumber]=regionSetupModel.xStructNum [form]=form [serverErrors]=serverErrors [numberKey]=numberKey></app-model-number>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="card" formGroupName='xAddr'>
                    <div class="card-header">
                        <label class="">Address</label>
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-12">
                                <label for="line1">Line1 <small class="text-danger">*</small></label>

                                <small *ngIf="!errors['xAddr.xLine1'] && form.get('xAddr').get('xLine1').hasError('required') && form.get('xAddr').get('xLine1').touched" class="inline-error"> Line1 is required</small>

                                <small *ngIf="errors['xAddr.xLine1']" class="inline-error"> {{ errors['xAddr.xLine1']?.message.replace('"xLine1"', 'Field')}}</small>

                                <input type="text" class="form-control form-control-sm" id="line1" (focus)="clearErrors('xAddr.xLine1')" formControlName="xLine1" [ngClass]="{'has-error': (form.get('xAddr').get('xLine1').hasError('required') && form.get('xAddr').get('xLine1').touched) || errors['xAddr.xLine1']}" validateOnBlur>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="line2">Line2 <small class="text-danger">*</small></label>

                                <small *ngIf="!errors['xAddr.xLine2'] && form.get('xAddr').get('xLine2').hasError('required') && form.get('xAddr').get('xLine2').touched" class="inline-error"> Line2 is required</small>

                                <small *ngIf="errors['xAddr.xLine2']" class="inline-error"> {{ errors['xAddr.xLine2']?.message.replace('"xLine2"', 'Field')}}</small>

                                <input type="text" class="form-control form-control-sm" id="line2" (focus)="clearErrors('xAddr.xLine2')" formControlName="xLine2" [ngClass]="{'has-error': (form.get('xAddr').get('xLine2').hasError('required') && form.get('xAddr').get('xLine2').touched) || errors['xAddr.xLine2']}" validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-12">
                                <label for="suite">Suite <small class="text-danger">*</small></label>

                                <small *ngIf="!errors['xAddr.xSuite'] && form.get('xAddr').get('xSuite').hasError('required') && form.get('xAddr').get('xSuite').touched" class="inline-error"> Suite is required</small>

                                <small *ngIf="errors['xAddr.xSuite']" class="inline-error"> {{ errors['xAddr.xSuite']?.message.replace('"xSuite"', 'Field')}}</small>

                                <input type="text" class="form-control form-control-sm"
                                id="suite" (focus)="clearErrors('xAddr.xSuite')" formControlName="xSuite" [ngClass]="{'has-error': (form.get('xAddr').get('xSuite').hasError('required') && form.get('xAddr').get('xSuite').touched) || errors['xAddr.xSuite']}" validateOnBlur>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="city">City <small class="text-danger">*</small></label>

                                <small *ngIf="!errors['xAddr.xCity'] && form.get('xAddr').get('xCity').hasError('required') && form.get('xAddr').get('xCity').touched" class="inline-error"> City is required</small>

                                <small *ngIf="errors['xAddr.xCity']" class="inline-error"> {{ errors['xAddr.xCity']?.message.replace('"xCity"', 'Field')}}</small>

                                <input type="text" class="form-control form-control-sm"
                                id="city" (focus)="clearErrors('xAddr.xCity')" formControlName="xCity" [ngClass]="{'has-error': (form.get('xAddr').get('xCity').hasError('required') && form.get('xAddr').get('xCity').touched) || errors['xAddr.xCity']}" validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-12">
                                <label for="post_code">Post Code <small class="text-danger">*</small></label>

                                <small *ngIf="!errors['xAddr.xPostCode'] && form.get('xAddr').get('xPostCode').hasError('required') && form.get('xAddr').get('xPostCode').touched" class="inline-error"> PostCode is required</small>

                                <small *ngIf="errors['xAddr.xPostCode']" class="inline-error"> {{ errors['xAddr.xPostCode']?.message.replace('"xPostCode"', 'Field')}}</small>

                                <input type="number" class="form-control form-control-sm"
                                id="post_code" (focus)="clearErrors('xAddr.xPostCode')" formControlName="xPostCode" [ngClass]="{'has-error': (form.get('xAddr').get('xPostCode').hasError('required') && form.get('xAddr').get('xPostCode').touched) || errors['xAddr.xPostCode']}" validateOnBlur>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="state">State/Province <small class="text-danger">*</small></label>

                                <small *ngIf="!errors['xAddr.xSOP'] && form.get('xAddr').get('xSOP').hasError('required') && form.get('xAddr').get('xSOP').touched" class="inline-error"> SOP is required</small>

                                <small *ngIf="errors['xAddr.xSOP']" class="inline-error"> {{ errors['xAddr.xSOP']?.message.replace('"xSOP"', 'Field')}}</small>

                                <input type="text" class="form-control form-control-sm"
                                id="state" (focus)="clearErrors('xAddr.xSOP')" formControlName="xSOP" [ngClass]="{'has-error': (form.get('xAddr').get('xSOP').hasError('required') && form.get('xAddr').get('xSOP').touched) || errors['xAddr.xSOP']}"  validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-12 col-sm-12">
                                <label for="country">Country <small class="text-danger">*</small></label>

                                <small *ngIf="!errors['xAddr.xCountry'] && form.get('xAddr').get('xCountry').hasError('required') && form.get('xAddr').get('xCountry').touched" class="inline-error"> Country is required</small>

                                <small *ngIf="errors['xAddr.xCountry']" class="inline-error"> {{ errors['xAddr.xCountry']?.message.replace('"xCountry"', 'Field')}}</small>

                                <input type="text" class="form-control form-control-sm"
                                id="country" (focus)="clearErrors('xAddr.xCountry')" formControlName="xCountry" [ngClass]="{'has-error': (form.get('xAddr').get('xCountry').hasError('required') && form.get('xAddr').get('xCountry').touched) || errors['xAddr.xCountry']}" validateOnBlur>
                            </div>
                        </div>
                    </div>
                </div> -->
                <app-address [addressContactValidate]=addressContactValidate [address]=regionSetupModel.xAddr [addressForm]=form [serverErrors]=serverErrors></app-address>

            </div>
            <div class="col-sm-4">
                <!-- <div class="card" formGroupName='xMainContact'>
                    <div class="card-header">
                         <label class="">Main Contact</label>
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-12 col-sm-12">
                                <label for="first_name">First Name <small class="text-danger">*</small></label>
                                <small *ngIf="!errors['xMainContact.xFirstName'] && form.get('xMainContact').get('xFirstName').hasError('required') && form.get('xMainContact').get('xFirstName').touched" class="inline-error"> First Name is required</small>

                                <small *ngIf="errors['xMainContact.xFirstName']" class="inline-error"> {{ errors['xMainContact.xFirstName']?.message.replace('"xFirstName"', 'Field')}}</small>

                                <input type="text" class="form-control form-control-sm" id="first_name" (focus)="clearErrors('xMainContact.xFirstName')" formControlName="xFirstName" [ngClass]="{'has-error': (form.get('xMainContact').get('xFirstName').hasError('required') && form.get('xMainContact').get('xFirstName').touched) || errors['xMainContact.xFirstName']}" validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12 col-sm-12">
                                <label for="last_name">Last Name <small class="text-danger">*</small></label>
                                <small *ngIf="!errors['xMainContact.xLastName'] && form.get('xMainContact').get('xLastName').hasError('required') && form.get('xMainContact').get('xLastName').touched" class="inline-error"> Last Name is required</small>

                                <small *ngIf="errors['xMainContact.xLastName']" class="inline-error"> {{ errors['xMainContact.xLastName']?.message.replace('"xLastName"', 'Field')}}</small>

                                <input type="text" class="form-control form-control-sm" id="last_name" (focus)="clearErrors('xMainContact.xLastName')" formControlName="xLastName" [ngClass]="{'has-error': (form.get('xMainContact').get('xLastName').hasError('required') && form.get('xMainContact').get('xLastName').touched) || errors['xMainContact.xLastName']}" validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-12 col-sm-12">
                                <label for="phone">Phone <small class="text-danger">*</small></label>
                                <small *ngIf="!errors['xMainContact.xPhone'] && form.get('xMainContact').get('xPhone').hasError('required') && form.get('xMainContact').get('xPhone').touched" class="inline-error"> Phone is required</small>

                                <small *ngIf="errors['xMainContact.xPhone']" class="inline-error"> {{ errors['xMainContact.xPhone']?.message.replace('"xPhone"', 'Field')}}</small>

                                <input type="number" class="form-control form-control-sm"
                                id="phone" (focus)="clearErrors('xMainContact.xPhone')" formControlName="xPhone" [ngClass]="{'has-error': (form.get('xMainContact').get('xPhone').hasError('required') && form.get('xMainContact').get('xPhone').touched) || errors['xMainContact.xPhone']}" validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-12 col-sm-12">
                                <label for="email">Email <small class="text-danger">*</small></label>
                                <small *ngIf="!errors['xMainContact.xEmail'] && form.get('xMainContact').get('xEmail').hasError('required') && form.get('xMainContact').get('xEmail').touched" class="inline-error"> Email is required</small>

                                <small *ngIf="errors['xMainContact.xEmail']" class="inline-error"> {{ errors['xMainContact.xEmail']?.message.replace('"xEmail"', 'Field')}}</small>

                                <small *ngIf="!errors['xMainContact.xEmail'] &&  form.get('xMainContact').get('xEmail').hasError('incorrectMailFormat') && form.get('xMainContact').get('xEmail').touched" class="inline-error"> Email format is invalid.</small>

                                <input type="email" class="form-control form-control-sm"
                                id="email" (focus)="clearErrors('xMainContact.xEmail')" formControlName="xEmail" [ngClass]="{'has-error': (form.get('xMainContact').get('xEmail').hasError('required') && form.get('xMainContact').get('xEmail').touched) || errors['xMainContact.xEmail']}" validateOnBlur>
                            </div>
                        </div>
                    </div>
                </div> -->

                <app-main-contact-column [addressContactValidate]=addressContactValidate [mainContactForm]=form [mainContact]=regionSetupModel.xMainContact [serverErrors]=serverErrors></app-main-contact-column>

                <!-- <div class="card" formGroupName='xSocial'>
                    <div class="card-header">
                        <label class="">General Contact</label>
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-12 col-sm-12">
                                <div class="input-group">
                                    <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-desktop fa-fw" aria-hidden="true"></i></span>
                                    <input type="text" class="form-control form-control-sm" id="website" (focus)="clearErrors('xSocial.xWebsite')" formControlName="xWebsite" [ngClass]="{'has-error': ((form.get('xSocial').get('xWebsite').hasError('required') || form.get('xSocial').get('xWebsite').hasError('incorrectWebsiteFormat')) && form.get('xSocial').get('xWebsite').touched) || errors['xSocial.xWebsite']}"   validateOnBlur placeholder="Website">
                                </div>
                                <div class="pull-left">
                                    <small *ngIf="!errors['xSocial.xWebsite'] && form.get('xSocial').get('xWebsite').hasError('required') && form.get('xSocial').get('xWebsite').touched" class="inline-error"> Website is required</small>

                                    <small *ngIf="errors['xSocial.xWebsite']" class="inline-error"> {{ errors['xSocial.xWebsite']?.message.replace('"xWebsite"', 'Field')}}</small>

                                    <small *ngIf="!errors['xSocial.xWebsite'] && form.get('xSocial').get('xWebsite').hasError('incorrectWebsiteFormat') && form.get('xSocial').get('xWebsite').touched" class="inline-error"> Website format is invalid.</small>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12 col-sm-12">
                                <div class="input-group">
                                    <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-mobile fa-fw" aria-hidden="true"></i></span>
                                    <input type="number" class="form-control form-control-sm" id="phone" (focus)="clearErrors('xSocial.xPhone')" formControlName="xPhone" [ngClass]="{'has-error': (form.get('xSocial').get('xPhone').hasError('required') && form.get('xSocial').get('xPhone').touched) || errors['xSocial.xPhone']}" validateOnBlur placeholder="Phone *">
                                </div>
                                <div class="pull-left">
                                     <small *ngIf="!errors['xSocial.xPhone'] && form.get('xSocial').get('xPhone').hasError('required') && form.get('xSocial').get('xPhone').touched" class="inline-error"> Phone is required</small>

                                    <small *ngIf="errors['xSocial.xPhone']" class="inline-error"> {{ errors['xSocial.xPhone']?.message.replace('"xPhone"', 'Field')}}</small>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12 col-sm-12">
                                 <div class="input-group">
                                    <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-facebook fa-fw" aria-hidden="true"></i></span>
                                    <input type="text" class="form-control form-control-sm" id="facebook_email" placeholder="" (focus)="clearErrors('xSocial.xFacebook')" formControlName="xFacebook" [ngClass]="{'has-error': (form.get('xSocial').get('xFacebook').hasError('required') && form.get('xSocial').get('xFacebook').touched) || errors['xSocial.xFacebook']}" validateOnBlur placeholder="Facebook">
                                </div>
                                <div class="pull-left">
                                    <small *ngIf="!errors['xSocial.xFacebook'] && form.get('xSocial').get('xFacebook').hasError('required') && form.get('xSocial').get('xFacebook').touched" class="inline-error"> Facebook is required</small>

                                    <small *ngIf="errors['xSocial.xFacebook']" class="inline-error"> {{ errors['xSocial.xFacebook']?.message.replace('"xFacebook"', 'Field')}}</small>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12 col-sm-12">
                                <div class="input-group">
                                    <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-twitter fa-fw" aria-hidden="true"></i></span>
                                    <input type="text" class="form-control form-control-sm" id="witter" (focus)="clearErrors('xSocial.xTwitter')" formControlName="xTwitter" [ngClass]="{'has-error': (form.get('xSocial').get('xTwitter').hasError('required') && form.get('xSocial').get('xTwitter').touched) || errors['xSocial.xTwitter']}" validateOnBlur placeholder="Twitter">
                                </div>
                                <div class="pull-left">
                                    <small *ngIf="!errors['xSocial.xTwitter'] && form.get('xSocial').get('xTwitter').hasError('required') && form.get('xSocial').get('xTwitter').touched" class="inline-error"> Twitter is required</small>

                                    <small *ngIf="errors['xSocial.xTwitter']" class="inline-error"> {{ errors['xSocial.xTwitter']?.message.replace('"xTwitter"', 'Field')}}</small>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-12 col-sm-12">
                                <div class="input-group">
                                    <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-linkedin
                                    fa-fw" aria-hidden="true"></i></span>
                                    <input type="text" class="form-control form-control-sm" id="person_email" (focus)="clearErrors('xSocial.xLinkedIn')" formControlName="xLinkedIn" [ngClass]="{'has-error': (form.get('xSocial').get('xLinkedIn').hasError('required') && form.get('xSocial').get('xLinkedIn').touched) || errors['xSocial.xLinkedIn']}" validateOnBlur placeholder="Linkedin">
                                </div>
                                <div class="pull-left">
                                    <small *ngIf="!errors['xSocial.xLinkedIn'] && form.get('xSocial').get('xLinkedIn').hasError('required') && form.get('xSocial').get('xLinkedIn').touched" class="inline-error"> Linkedin is required</small>

                                    <small *ngIf="errors['xSocial.xLinkedIn']" class="inline-error"> {{ errors['xSocial.xLinkedIn']?.message.replace('"xLinkedIn"', 'Field')}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                
                <div class="card">
                    <div class="card-header">
                        <label>Notes</label>
                    </div>
                    <div class="">
                        <!-- <small *ngIf="!errors['xNotes'] && form.get('xNotes').hasError('required') && form.get('xNotes').touched" class="inline-error"> Notes is required</small>

                        <small *ngIf="errors['xNotes']" class="inline-error"> {{ errors['xNotes']?.message.replace('"xNotes"', 'Field')}}</small> -->

                        <textarea class="form-control notes-area" rows="4" id="notes" formControlName="xNotes" validateOnBlur style="min-height: 131px;">
                        </textarea>
                    </div>
                </div>

                    <app-social-contact-column [generalContact]=regionSetupModel.xSocial [generalContactForm]=form [serverErrors]=serverErrors></app-social-contact-column>
                
            </div>
        </div>
        <div class="row m-t-5" *ngIf='!(parentMode || userHandlerService?.userRoles?.isBusinessEmployee)'>
            <div class="col-sm-12 text-right">
                <!-- <a class="btn btn-secondary" [routerLink]="['/dashboard/setup/business/structure/information']" [routerLinkActive]="['router-link-active']"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a> -->
                <button class="btn btn-secondary" (click)="onCancel($event)">
                    <i class="fa fa-times" aria-hidden="true"></i> Cancel</button>
                <span 
                    class="tooltip-wrap-span" 
                    [tooltip]="(edit && !canEdit) || (!edit && !canAdd) ? noPermMsg : 'Save'" 
                    placement="left" 
                    container="body" 
                    containerClass="tooltip-global"
                >
                    <button class="btn btn-primary" [disabled]="submitting || (edit && !canEdit) || (!edit && !canAdd)">
                        <span *ngIf="!submitting"> Save
                            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                        </span>
                        <span *ngIf="submitting">Please wait...
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </span>
                    </button>
                </span>
            </div>
        </div>
        </div>
    </form>
    <div class="row  m-t-5" *ngIf='parentMode && !userHandlerService?.userRoles?.isBusinessEmployee'>
        <div class="col-sm-12 text-right">
            <button [disabled]="!canEdit" class="btn btn-primary" (click)="onEditClick($event)"><i class="fa fa-pencil" aria-hidden="true"></i>  Edit</button>
        </div>
    </div>
</section>
<section *ngIf="!loading && noRegion">
    <div class="row m-b-1">
        <div class="col-sm-10 offset-sm-1 m-t-30">
            <div class="coming-soon">
                <h4 class="text-muted">No region exists, corresponding to selected node.</h4>
            </div>
        </div>
    </div>
</section>
<section *ngIf="edit && loading">
    <div style="text-align: center; position:realtive">
        <div class="col-md-12">
            <!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="color:#445d75;position:relative; top:50px; text-align:Center"></i>
            <span class="sr-only">Loading Tags...</span> -->
            <div class="grid-spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <span style="color:#3b5877">Fetching data</span>
        </div>
    </div>
</section>