
	<div
		bsModal
		#addPaymentMethodModal="bs-modal"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-labelledby="myLargeModalLabel"
		aria-hidden="true"
		[config]="{ backdrop: 'static' }"
	>
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
			<!-- modal header beginnig-->
			<div class="modal-header">
					<button type="button" class="close" (click)="onHideModal()" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 *ngIf="modalMode === MODAL_MODE_ADD" class="modal-title">Add New Payment Method</h4>
					<h4 *ngIf="modalMode === MODAL_MODE_EDIT" class="modal-title">Edit Payment Method</h4>
			</div>
			<!-- modal header ending-->
			<!-- member-acct form beginnig -->
			<ng-container *ngIf="!modalLoader">
					<div  class="modal-body pb-0">
						<form class="form-horizontal" [formGroup]="accountForm" (ngSubmit)="onSubmit()" novalidate>
						<div *ngIf="!hasNoData">
							<ng-container *ngIf="(modalMode === MODAL_MODE_ADD && setDepositAcct) || modalMode === MODAL_MODE_EDIT">
								<div class="row">
										<div class="col-sm-12">
											<!-- name beginnig -->
											<div class="form-group row">
												<div class="col-sm-6">
													<small
														*ngIf="
															!serverErrors['xName'] &&
															(accountForm.get('xName').hasError('required') &&
															accountForm.get('xName').touched)
														"
														class="inline-error"
													>
														<i
															class="fa fa-exclamation-triangle inline-error"
															container="body"
															tooltip="Name is required"
															placement="top"
															containerClass="tooltip-custom inline-error"
															aria-hidden="true"
														></i>
													</small>
		
													<small *ngIf="serverErrors['xName']" class="inline-error">
														<i
															class="fa fa-exclamation-triangle inline-error"
															container="body"
															tooltip="Name is required"
															placement="top"
															containerClass="tooltip-custom inline-error"
															aria-hidden="true"
														></i>
													</small>
													<label for="name" class="control-label"> Name: <small class="text-danger">*</small></label>
													<input
														type="text"
														class="form-control form-control-sm"
														id="name"
														formControlName="xName"
														(focus)="clearErrors('xName')"
														[ngClass]="{
															'has-error':
																serverErrors['xName'] ||
																(accountForm.get('xName').hasError('required') && accountForm.get('xName').touched)
														}"
														validateOnBlur
													>
												</div>
											</div>
											<!-- name ending -->
		
										</div>
									</div>
									<!-- acct-token beginning -->
									<div class="row form-group">
											<div class="col-md-6" *ngIf="modalMode === MODAL_MODE_EDIT">
												<div>
													<label for="acct_token"> Account Token : </label>
												</div>
												<div>
													<span>{{ memberAccount.maskedAccountToken }}
													</span>
												</div>
											</div>
										</div>
									<div class="form-group row">
										<div class="col-sm-4">
											<label for="medium">Medium: </label><div>{{memberAccount.medium}} </div>
										</div>
										<div class="col-sm-4">
											<label for="brand">Brand: </label><div>{{memberAccount.brand}} </div>
										</div>
									</div>
									<!-- acct-token ending -->
									<div class="form-group row">
										<!-- Acct-Number beginnig. Show Acct-Number in view only in edit modalMode -->
										<div *ngIf="modalMode === MODAL_MODE_ADD" class="col-sm-4">
											<small
												*ngIf="
													!serverErrors['xRealAcctNum'] &&
													accountForm.get('xRealAcctNum').hasError('required') &&
													accountForm.get('xRealAcctNum').touched
												"
												class="inline-error"
											>
												<i
													class="fa fa-exclamation-triangle inline-error"
													container="body"
													tooltip="Account Number is required"
													placement="top"
													containerClass="tooltip-custom inline-error"
													aria-hidden="true"
												></i>
											</small>
		
											<small *ngIf="serverErrors['xRealAcctNum']" class="inline-error">
												<i
													class="fa fa-exclamation-triangle inline-error"
													container="body"
													tooltip="Account Number is required"
													placement="top"
													containerClass="tooltip-custom inline-error"
													aria-hidden="true"
												></i>
											</small>
											<div>
												<label for="acct_number"> Account Number:<small class="text-danger"> *</small> </label>
												<input
													type="text"
													class="form-control form-control-sm"
													id="acct_number"
													formControlName="xRealAcctNum"
													(focus)="clearErrors('xRealAcctNum')"
													[ngClass]="{
														'has-error':
															serverErrors['xRealAcctNum'] ||
															(accountForm.get('xRealAcctNum').hasError('required') && accountForm.get('xRealAcctNum').touched)
													}"
													validateOnBlur
												/>
											</div>
										</div>
										<div class="col-md-4" *ngIf="modalMode === MODAL_MODE_EDIT"><div>
											<label for="acct_number"> Account Number: </label>
										</div>
											<div><span>
												{{ memberAccount.accountNumber }}
											</span>
											</div>
										</div>
										<div class='col-md-4' *ngIf='showExpDate'>
											<div>
												<label>Expiry Date</label>
											</div>
											<div class="form-group  row">
													<div class="col-sm-6">
														<div class="merch-info">
															<div class="merch_type">
																<small
																	*ngIf="
																		!serverErrors['xExpMonth'] &&
																		accountForm.get('xExpMonth')?.hasError('required') &&
																		accountForm.get('xExpMonth')?.touched
																	"
																	class="inline-error"
																>
																	<i
																		class="fa fa-exclamation-triangle inline-error"
																		container="body"
																		tooltip="Profit Center is required"
																		placement="top"
																		containerClass="tooltip-custom inline-error"
																		aria-hidden="true"
																	></i>
																</small>
																<small *ngIf="serverErrors['xExpMonth']" class="inline-error">
																	<i
																		class="fa fa-exclamation-triangle inline-error"
																		container="body"
																		tooltip="Profit Center is required"
																		placement="top"
																		containerClass="tooltip-custom inline-error"
																		aria-hidden="true"
																	></i>
																</small>
																<div>
																	{{ xExpMonth }}
																	<select class="form-control form-control-sm" formControlName="xExpMonth" [ngClass]="{
																		'has-error':
																			serverErrors['xExpMonth'] ||
																			(accountForm.get('xExpMonth')?.hasError('required') && accountForm.get('xExpMonth').touched)
																	}" validateOnBlur>
																		<option *ngFor="let month of months" [value]="month?.value" id="{{ month?.value }}">{{
																			month?.name
																		}}</option>
																	</select>
																	<span>
																		<i class="fa fa-angle-down"></i>
																	</span>
																</div>
															</div>
														</div>
													</div>
													<div class="col-sm-6">
														<div class="merch-info">
															<small
																*ngIf="
																	!serverErrors['xExpYear'] &&
																	accountForm.get('xExpYear')?.hasError('required') &&
																	accountForm.get('xExpYear')?.touched
																"
																class="inline-error"
															>
																<i
																	class="fa fa-exclamation-triangle inline-error"
																	container="body"
																	tooltip="Profit Center is required"
																	placement="top"
																	containerClass="tooltip-custom inline-error"
																	aria-hidden="true"
																></i>
															</small>
															<small *ngIf="serverErrors['xExpYear']" class="inline-error">
																<i
																	class="fa fa-exclamation-triangle inline-error"
																	container="body"
																	tooltip="Profit Center is required"
																	placement="top"
																	containerClass="tooltip-custom inline-error"
																	aria-hidden="true"
																></i>
															</small>
															<div class="merch_type">
																{{ xExpYear }}
																<select class="form-control form-control-sm" formControlName="xExpYear"  [ngClass]="{
																	'has-error':
																		serverErrors['xExpYear'] ||
																		(accountForm.get('xExpYear')?.hasError('required') && accountForm.get('xExpYear').touched)
																}" validateOnBlur>
																	<option
																		*ngFor="let yearObj of yearObjArray"
																		[value]="yearObj?.value"
																		id="{{ yearObj?.value }}"
																		>{{ yearObj?.year }}</option
																	>
																</select>
																<span>
																	<i class="fa fa-angle-down"></i>
																</span>
															</div>
														</div>
													</div>
												</div>
										</div>
									<!-- Acct-Number ending -->
									<!-- Routing-Number beginning. In edit modalMode only view is available -->
										<div *ngIf="showEcheck && modalMode === MODAL_MODE_ADD" class="col-md-4">
												<small
													*ngIf="
														!serverErrors['xRtgNum'] &&
														accountForm.get('xRtgNum').hasError('required') &&
														accountForm.get('xRtgNum').touched
													"
													class="inline-error"
												>
													<i
														class="fa fa-exclamation-triangle inline-error"
														container="body"
														tooltip="Routing Number is required"
														placement="top"
														containerClass="tooltip-custom inline-error"
														aria-hidden="true"
													></i>
												</small>
		
												<small *ngIf="serverErrors['xRtgNum']" class="inline-error">
													<i
														class="fa fa-exclamation-triangle inline-error"
														container="body"
														tooltip="Routing Number is required"
														placement="top"
														containerClass="tooltip-custom inline-error"
														aria-hidden="true"
													></i>
												</small>
												<label for="routing_number">Routing Number:<small class="text-danger"> *</small> </label>
												<input
													type="text"
													class="form-control form-control-sm"
													id="routing_number"
													formControlName="xRtgNum"
													(focus)="clearErrors('xRtgNum')"
													[ngClass]="{
														'has-error':
															serverErrors['xRtgNum'] ||
															(accountForm.get('xRtgNum').hasError('required') && accountForm.get('xRtgNum').touched)
													}"
													validateOnBlur
												/>
										</div>
										<div  class="col-md-4" *ngIf="showEcheck && modalMode === MODAL_MODE_EDIT"><div>
											<label for="routing_number">Routing Number: </label>
											</div>
										<div> <span>
												{{memberAccount.routingNumber}}
										</span>
										</div>
										</div>
										<!-- Routing-Number ending -->
										<!-- Acct-type beginning. Show if payment modalMode is bank(Echeck) -->
										<div *ngIf="showEcheck" class="col-sm-4">
										<label for="brand">Account Type: </label>
										<div class=" merch-info">
											<div class="merch_type">
												<select
													(change)="onChangeAcctType($event)"
													class="form-control form-control-sm"
													formControlName="xAcctName"
													validateOnBlur
												>
													<option value="" selected="chooseAccount"> Select Account </option> 
													<option *ngFor="let acct of acctTypes" [value]="acct.value" id="{{ acct.type }}">{{
														acct.type
													}}</option>
												</select>
		
												<span>
													<i class="fa fa-angle-down"></i>
												</span>
											</div>
										</div>
										</div>
										<!-- Acct-type ending -->
									</div>
									<!-- Address  beginning -->
									<div class="form-group row">
										<div class="col-sm-12">
											<label for="address">Address:</label>
											<textarea type="text" class="form-control form-control-sm" id="address" formControlName="address"></textarea>
										</div>
									</div>
									<!-- Address  ending -->
									<!-- city, state, zip-code beginnig -->
									<div  class="form-group row ">
										<div *ngIf="memberAccount.medium !== MEDIUM_CREDIT" class="col-sm-4">
											<label for="customerCity">City:</label>
											<input
												type="text"
												class="form-control form-control-sm"
												id="customerCity"
												formControlName="customerCity"
											/>
										</div>
										<div *ngIf="memberAccount.medium !== MEDIUM_CREDIT" class="col-sm-4">
											<label for="customerState">State:</label>
											<input
												type="text"
												class=" form-control form-control-sm"
												id="customerState"
												formControlName="customerState"
											/>
										</div>
										<div class="col-sm-4">
											<label for="zipCode">Zip Code:</label>
											<input type="text" class=" form-control form-control-sm" id="zipCode" formControlName="zipCode" />
										</div>
									</div>
								</ng-container>
									<!-- city, state, zip-code ending -->
									<div class="form-group row ">
										<!-- Profit center beginning -->
										<div class="col-sm-6" *ngIf="modalMode === MODAL_MODE_ADD">
											<div class="row">
												<div class="col-sm-12">
													<small
														*ngIf="
															!serverErrors['xSignupPCtrID'] &&
															accountForm.get('xSignupPCtrID')?.hasError('required') &&
															accountForm.get('xSignupPCtrID')?.touched
														"
														class="inline-error"
													>
														<i
															class="fa fa-exclamation-triangle inline-error"
															container="body"
															tooltip="Profit Center is required"
															placement="top"
															containerClass="tooltip-custom inline-error"
															aria-hidden="true"
														></i>
													</small>
													<small *ngIf="serverErrors['xSignupPCtrID']" class="inline-error">
														<i
															class="fa fa-exclamation-triangle inline-error"
															container="body"
															tooltip="Profit Center is required"
															placement="top"
															containerClass="tooltip-custom inline-error"
															aria-hidden="true"
														></i>
													</small>
													<label for="profit_center">Profit Center:<small class="text-danger">*</small> </label>
		
														<div class=" merch-info">
															<div class="merch_type" >
																<select
																	(change)="onChangePCtr($event)"
																	class="form-control form-control-sm"
																	formControlName="xSignupPCtrID"
																	[ngClass]="{
																		'has-error':
																			serverErrors['xSignupPCtrID'] ||
																			(accountForm.get('xSignupPCtrID')?.hasError('required') && accountForm.get('xSignupPCtrID').touched)
																	}"
																	validateOnBlur
																>
																	<option *ngFor="let pctr of pctrs" [value]="pctr?._id" id="{{ pctr?._id }}">{{
																		pctr?.xName
																	}}</option>
																</select>
		
																<span>
																	<i class="fa fa-angle-down"></i>
																</span>
															</div>
														</div>
													<!-- <div>
		
													</div> -->
												</div>
											</div>
										</div>
										<div class="col-sm-6" *ngIf="modalMode === MODAL_MODE_EDIT">
											<label for="pctrName">Profit Center:</label>
											<div>
												<span>{{memberAccount?.pctrName}}</span>
											</div>
										</div>
										<!-- Profit center ending -->
										<!-- terminal dropdown beginning -->
										<div class="col-sm-6" *ngIf="accountForm.get('xSignupPCtrID')?.value?.length">
											<div class="row">
												<div class="col-sm-12">
													<small
														*ngIf="
															!serverErrors['xTerminalID'] &&
															accountForm.get('xTerminalID')?.hasError('required') &&
															accountForm.get('xTerminalID')?.touched
														"
														class="inline-error"
													>
														<i
															class="fa fa-exclamation-triangle inline-error"
															container="body"
															tooltip="Terminal is required"
															placement="top"
															containerClass="tooltip-custom inline-error"
															aria-hidden="true"
														></i>
													</small>
													<small *ngIf="serverErrors['xTerminalID']" class="inline-error">
														<i
															class="fa fa-exclamation-triangle inline-error"
															container="body"
															tooltip="Terminal is required"
															placement="top"
															containerClass="tooltip-custom inline-error"
															aria-hidden="true"
														></i>
													</small>
													<label for="terminal">Terminal:<small class="text-danger">*</small> </label>
		
														<div class=" merch-info">
															<div class="merch_type" >
																<select
																	(change)="onChangeTerminalDropdown($event)"
																	class="form-control form-control-sm"
																	formControlName="xTerminalID"
																	[ngClass]="{
																		'has-error':
																			serverErrors['xTerminalID'] ||
																			(accountForm.get('xTerminalID')?.hasError('required') && accountForm.get('xTerminalID').touched)
																	}"
																	validateOnBlur
																>
																	<option *ngFor="let terminal of terminalsArray" [value]="terminal?._id" id="{{ terminal?._id }}">{{
																		terminal?.xName
																	}}</option>
																</select>
																<span>
																	<i class="fa fa-angle-down"></i>
																</span>
															</div>
														</div>
													<!-- <div>
		
													</div> -->
												</div>
											</div>
										</div>
										<!-- terminal dropdown ending -->
									</div>
									<!-- Structure Info beginnig. -->
									<div *ngIf="accountForm.get('xSignupPCtrID')?.value?.length" class="form-group row">
										<div class="col-sm-4">
											<div>
												<label for="xGMID">GMID: </label>
											</div>
											<div >
												<span> {{ xGMID }} </span>
											</div>
										</div>
										<div class="col-sm-4">
											<div>
												<label for="xGMPW">GMPW: </label>
											</div>
											<div>
												<span> {{ xGMPW }} </span>
											</div>
										</div>
										<div class="col-sm-4" *ngIf="accountForm.get('xTerminalID')?.value?.length">
											<div>
												<label for="xTerminalID">GTID: </label>
											</div>
											<div>
												<span> {{ xGTID }} </span>
											</div>
										</div>
									</div>
						</div>
						<div *ngIf="!hasNoData" class="modal-footer border-top-0">
							<a class="btn btn-secondary" (click)="onCancel($event)"> <i class="fa fa-times" aria-hidden="true"></i> Cancel</a>
							<span
								class="pull-right tooltip-wrap-span"
								[tooltip]="!canEdit ? noPermMsg: 'Save'"
								placement="left"
								container="body"
								containerClass="tooltip-global"
							>
								<button *ngIf='modalMode === MODAL_MODE_EDIT || setDepositAcct' class="btn btn-primary " name="next" type="submit" [disabled]="submitting || !canEdit">
									<span *ngIf="!submitting">
										Save
										<i class="fa fa-angle-double-right" aria-hidden="true"></i>
									</span>
									<span *ngIf="submitting"
										>Please wait...
										<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
									</span>
								</button>
							</span>
							<span
								class="pull-right tooltip-wrap-span"
								[tooltip]="!canAdd ? noPermMsg: 'Proceed'"
								placement="left"
								container="body"
								containerClass="tooltip-global"
							>
								<button
									*ngIf="modalMode === MODAL_MODE_ADD && setPaymentAcct"
									class="btn btn-primary"
									(click)="onProceed()"
									name="proceed"
									type="button"
									[disabled]="proceedLoader || !canAdd"
								>
									<span *ngIf="!proceedLoader">Proceed</span>
									<span *ngIf="proceedLoader"
										>Please wait...
										<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
									</span>
								</button>
							</span>
						</div>
						<div *ngIf="hasNoData" class="modal-body pb-0"> 
							<div style="text-align: center; padding-top: 16px;">
								<label>
										{{FAILURE_MESSAGE}}
								</label> 	 
							</div>
						</div>
						<div *ngIf="hasNoData" class="modal-footer border-top-0">
							<button (click)="onCancel($event)" class="btn btn-primary " name="next" type="submit" >
								<span *ngIf="!submitting">
									OK
								</span>
							</button>
						</div>
					</form>
				</div>
			</ng-container>
			<div *ngIf="modalLoader" class="modal-body">
					<div style="text-align: center; position:realtive">
						<div class="col-md-12">
							<div class="grid-spinner">
								<div class="bounce1"></div>
								<div class="bounce2"></div>
								<div class="bounce3"></div>
							</div>
							<span style="color:#3b5877">Fetching data... Please wait.</span>
						</div>
					</div>
			</div>
		</div>
							<!-- member-acct form ending -->

		</div>
	</div>

		<!-- <div *ngIf="modalMode === MODAL_MODE_EDIT && loading">
			<div style="text-align: center; position:realtive">
				<div class="col-md-12">
					<div class="grid-spinner">
						<div class="bounce1"></div>
						<div class="bounce2"></div>
						<div class="bounce3"></div>
					</div>
					<span style="color:#3b5877">Fetching TERMINAL data</span>
				</div>
			</div>
		</div> -->
	<!-- </div> -->