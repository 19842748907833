import { IMPORT_TXN } from 'app/constants';
import { IMPORT_FILE_KEY } from './constants';

export function createFormData(form: any) {
	let formData = new FormData();
	formData.append(IMPORT_TXN.xTerminalID, form.value[IMPORT_TXN.xTerminalID]);
	formData.append(IMPORT_TXN.xBusinessID, form.value[IMPORT_TXN.xBusinessID]);
	formData.append(IMPORT_TXN.xStoreID, form.value[IMPORT_TXN.xStoreID])
	formData.append(IMPORT_TXN.xPCtrID, form.value[IMPORT_TXN.xPCtrID]);
	formData.append(IMPORT_FILE_KEY, form.value[IMPORT_FILE_KEY]);
	formData.append(IMPORT_TXN.definitionFileID, form.value[IMPORT_TXN.definitionFileID]);
	return formData;
}
