<section *ngIf="!loading" class="setup buss-info" [ngClass]="{'background':true}">
    <form class="form-horizontal" [formGroup]="form" (ngSubmit)="onSubmit(form, ROUTE_TO_CUSTOMER_LIST)" novalidate>
        <div class="row top-stat">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-briefcase label-icon" aria-hidden="true"></i>
                        <!-- <i class="fa fa-user-circle-o" aria-hidden="true"></i> -->
                        <label class="p-l-5" for="name">Business: </label>
                        <label class="detail-label"> {{ businessService.businessData?.xName }} </label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-check-circle-o label-icon" aria-hidden="true"></i>
                        <label for="name" class="p-l-5">Status:</label>
                        <label class="detail-label">Active</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-sign-in label-icon" aria-hidden="true"></i>
                        <label for="dba_name" class="p-l-5">Signup Store:</label>
                        <label class="detail-label">{{ signupStoreName }}</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-sliders  label-icon" aria-hidden="true"></i>
                        <label for="dba_name" class="p-l-5">Type:</label>
                        <label class="detail-label">Regular Customer</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="scroll-container-cust-setup">
            <div class="row">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-header">
                            <label class="">Basic</label>
                        </div>
                        <div class="card-body">
                            <div class="form-group row" formGroupName="xFormalName">
                                <div class="col-md-6 col-sm-12">
                                    <label for="fore_name"
                                        >First Name:
                                        <small class="text-danger">*</small>
                                    </label>

                                    <small
                                        *ngIf="
                                            !serverErrors['xFormalName.xForename'] &&
                                            form
                                                .get('xFormalName')
                                                .get('xForename')
                                                .hasError('required') &&
                                            form.get('xFormalName').get('xForename').touched
                                        "
                                        class="inline-error"
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="Fore Name is required"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <small *ngIf="serverErrors['xFormalName.xForename']" class="inline-error">
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="This field is not allowed to be empty"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="fore_name"
                                        (focus)="clearErrors('xFormalName.xForename')"
                                        formControlName="xForename"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xFormalName')
                                                    .get('xForename')
                                                    .hasError('required') &&
                                                    form.get('xFormalName').get('xForename').touched) ||
                                                serverErrors['xFormalName.xForename']
                                        }"
                                        validateOnBlur
                                    />
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <label for="sur_name"
                                        >Last Name:
                                        <small class="text-danger">*</small>
                                    </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xFormalName.xSurname'] &&
                                            form
                                                .get('xFormalName')
                                                .get('xSurname')
                                                .hasError('required') &&
                                            form.get('xFormalName').get('xSurname').touched
                                        "
                                        class="inline-error"
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="Sur Name is required"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <small *ngIf="serverErrors['xFormalName.xSurname']" class="inline-error">
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="This field is not allowed to be empty"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="sur_name"
                                        (focus)="clearErrors('xFormalName.xSurname')"
                                        formControlName="xSurname"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xFormalName')
                                                    .get('xSurname')
                                                    .hasError('required') &&
                                                    form.get('xFormalName').get('xSurname').touched) ||
                                                serverErrors['xFormalName.xSurname']
                                        }"
                                        validateOnBlur
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6 col-sm-6">
                                    <label for="cust_num"
                                        >Customer Number:
                                        <small class="text-danger">*</small>
                                    </label>
                                    <app-model-number
                                        [modelNumber]="setupBillingCustomerModel.xCustomerNum"
                                        [form]="form"
                                        [serverErrors]="serverErrors"
                                        [numberKey]="numberKey"
                                    ></app-model-number>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <label for="company_name"
                                        >Company Name:
                                        <!-- <small class="text-danger">*</small> -->
                                    </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xCompanyName'] &&
                                            form
                                                .get('xCompanyName')
                                                .hasError('required') &&
                                            form.get('xCompanyName').touched
                                        "
                                        class="inline-error"
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="Company Name is required"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <small *ngIf="serverErrors['xCompanyName']" class="inline-error">
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="This field is not allowed to be empty"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="company_name"
                                        (focus)="clearErrors('xCompanyName')"
                                        formControlName="xCompanyName"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xCompanyName')
                                                    .hasError('required') &&
                                                    form.get('xCompanyName').touched) ||
                                                serverErrors['xCompanyName']
                                        }"
                                        validateOnBlur
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card ">
                        <div class="card-header">
                            <label class="">Contact</label>
                        </div>
                        <div class="card-body">
                            <div class="form-group row">
                                <div class="col-md-6 col-sm-12" formGroupName="xContact">
                                    <label for="phone"
                                        >Phone:
                                        <small class="text-danger">*</small>
                                    </label>

                                    <small
                                        *ngIf="
                                            !serverErrors['xContact.xPhone'] &&
                                            form
                                                .get('xContact')
                                                .get('xPhone')
                                                .hasError('required') &&
                                            form.get('xContact').get('xPhone').touched
                                        "
                                        class="inline-error"
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="Phone Number is required"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <small *ngIf="serverErrors['xContact.xPhone']" class="inline-error">
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="This field is not allowed to be empty"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="phone"
                                        (focus)="clearErrors('xContact.xPhone')"
                                        formControlName="xPhone"
                                        (keypress)="onKeypress($event, 'phoneNum')"
                                        (paste)="onPaste($event, 'phoneNum')"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xContact')
                                                    .get('xPhone')
                                                    .hasError('required') &&
                                                    form.get('xContact').get('xPhone').touched) ||
                                                serverErrors['xContact.xPhone']
                                        }"
                                        validateOnBlur
                                    />
                                </div>
                                <div class="col-md-6 col-sm-12" formGroupName="xContact">
                                    <label for="email"
                                        >Email:
                                        <small class="text-danger">*</small>
                                    </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xContact.xEmail'] &&
                                            form
                                                .get('xContact')
                                                .get('xEmail')
                                                .hasError('required') &&
                                            form.get('xContact').get('xEmail').touched
                                        "
                                        class="inline-error"
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="Email is required"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <small *ngIf="serverErrors['xContact.xEmail']" class="inline-error">
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="This field is not allowed to be empty"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="email"
                                        (focus)="clearErrors('xContact.xEmail')"
                                        formControlName="xEmail"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xContact')
                                                    .get('xEmail')
                                                    .hasError('required') &&
                                                    form.get('xContact').get('xEmail').touched) ||
                                                serverErrors['xContact.xEmail']
                                        }"
                                        (blur)="setUNameFromEmail()"
                                        validateOnBlur
                                        id="xContactEmail"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-12 col-sm-12" formGroupName="xAddr">
                                    <label for="line1"
                                        >Line1:
                                        <small class="text-danger">*</small>
                                    </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAddr.xLine1'] &&
                                            form
                                                .get('xAddr')
                                                .get('xLine1')
                                                .hasError('required') &&
                                            form.get('xAddr').get('xLine1').touched
                                        "
                                        class="inline-error"
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="Line 1 is required"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <small *ngIf="serverErrors['xAddr.xLine1']" class="inline-error">
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="This field is not allowed to be empty"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="line1"
                                        (focus)="clearErrors('xAddr.xLine1')"
                                        formControlName="xLine1"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAddr')
                                                    .get('xLine1')
                                                    .hasError('required') &&
                                                    form.get('xAddr').get('xLine1').touched) ||
                                                serverErrors['xAddr.xLine1']
                                        }"
                                        validateOnBlur
                                        id="xAddrLine1"
                                    />
                                </div>
                            </div>
                            <div class="form-group row" formGroupName="xAddr">
                                <div class="col-md-6 col-sm-12">
                                    <label for="line2">Line2: </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAddr.xLine2'] &&
                                            form
                                                .get('xAddr')
                                                .get('xLine2')
                                                .hasError('required') &&
                                            form.get('xAddr').get('xLine2').touched
                                        "
                                        class="inline-error"
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="Line 2 is required"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <small *ngIf="serverErrors['xAddr.xLine2']" class="inline-error">
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="This field is not allowed to be empty"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="line2"
                                        (focus)="clearErrors('xAddr.xLine2')"
                                        formControlName="xLine2"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAddr')
                                                    .get('xLine2')
                                                    .hasError('required') &&
                                                    form.get('xAddr').get('xLine2').touched) ||
                                                serverErrors['xAddr.xLine2']
                                        }"
                                        validateOnBlur
                                        id="xAddrLine2"
                                    />
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <label for="suite">Suite: </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAddr.xSuite'] &&
                                            form
                                                .get('xAddr')
                                                .get('xSuite')
                                                .hasError('required') &&
                                            form.get('xAddr').get('xSuite').touched
                                        "
                                        class="inline-error"
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="Suite is required"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <small *ngIf="serverErrors['xAddr.xSuite']" class="inline-error">
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="This field is not allowed to be empty"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="suite"
                                        (focus)="clearErrors('xAddr.xSuite')"
                                        formControlName="xSuite"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAddr')
                                                    .get('xSuite')
                                                    .hasError('required') &&
                                                    form.get('xAddr').get('xSuite').touched) ||
                                                serverErrors['xAddr.xSuite']
                                        }"
                                        validateOnBlur
                                        id="xAddrSuite"
                                    />
                                </div>
                            </div>
                            <div class="form-group row" formGroupName="xAddr">
                                <div class="col-md-6 col-sm-12">
                                    <label for="city"
                                        >City:
                                        <small class="text-danger">*</small>
                                    </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAddr.xCity'] &&
                                            form
                                                .get('xAddr')
                                                .get('xCity')
                                                .hasError('required') &&
                                            form.get('xAddr').get('xCity').touched
                                        "
                                        class="inline-error"
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="City is required"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <small *ngIf="serverErrors['xAddr.xCity']" class="inline-error">
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="This field is not allowed to be empty"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="city"
                                        (focus)="clearErrors('xAddr.xCity')"
                                        formControlName="xCity"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAddr')
                                                    .get('xCity')
                                                    .hasError('required') &&
                                                    form.get('xAddr').get('xCity').touched) ||
                                                serverErrors['xAddr.xCity']
                                        }"
                                        validateOnBlur
                                        id="xAddrCity"
                                    />
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <label for="state"
                                        >State/Province:
                                        <small class="text-danger">*</small>
                                    </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAddr.xSOP'] &&
                                            form
                                                .get('xAddr')
                                                .get('xSOP')
                                                .hasError('required') &&
                                            form.get('xAddr').get('xSOP').touched
                                        "
                                        class="inline-error"
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="State is required"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <small *ngIf="serverErrors['xAddr.xSOP']" class="inline-error">
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="This field is not allowed to be empty"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="state"
                                        (focus)="clearErrors('xAddr.xSOP')"
                                        formControlName="xSOP"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAddr')
                                                    .get('xSOP')
                                                    .hasError('required') &&
                                                    form.get('xAddr').get('xSOP').touched) ||
                                                serverErrors['xAddr.xSOP']
                                        }"
                                        validateOnBlur
                                        id="xAddrSOP"
                                    />
                                </div>
                            </div>
                            <div class="form-group row no-margin" formGroupName="xAddr">
                                <div class="col-md-6 col-sm-12">
                                    <label for="postal_code"
                                        >Postal Code:
                                        <small class="text-danger">*</small>
                                    </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAddr.xPostCode'] &&
                                            form
                                                .get('xAddr')
                                                .get('xPostCode')
                                                .hasError('required') &&
                                            form.get('xAddr').get('xPostCode').touched
                                        "
                                        class="inline-error"
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="Postal code is required"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <small *ngIf="serverErrors['xAddr.xPostCode']" class="inline-error">
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="This field is not allowed to be empty"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="postal_code"
                                        (focus)="clearErrors('xAddr.xPostCode')"
                                        (keypress)="onKeypress($event, 'postCode')"
                                        (paste)="onPaste($event, 'postCode')"
                                        formControlName="xPostCode"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAddr')
                                                    .get('xPostCode')
                                                    .hasError('required') &&
                                                    form.get('xAddr').get('xPostCode').touched) ||
                                                serverErrors['xAddr.xPostCode']
                                        }"
                                        validateOnBlur
                                        id="xAddrPostCode"
                                    />
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <label for="country"
                                        >Country:
                                        <small class="text-danger">*</small>
                                    </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAddr.xCountry'] &&
                                            form
                                                .get('xAddr')
                                                .get('xCountry')
                                                .hasError('required') &&
                                            form.get('xAddr').get('xCountry').touched
                                        "
                                        class="inline-error"
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="Country is required"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <small *ngIf="serverErrors['xAddr.xCountry']" class="inline-error">
                                        <i
                                            class="fa fa-exclamation-triangle inline-error"
                                            container="body"
                                            tooltip="This field is not allowed to be empty"
                                            placement="top"
                                            containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"
                                        ></i>
                                    </small>

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="country"
                                        (focus)="clearErrors('xAddr.xCountry')"
                                        formControlName="xCountry"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAddr')
                                                    .get('xCountry')
                                                    .hasError('required') &&
                                                    form.get('xAddr').get('xCountry').touched) ||
                                                serverErrors['xAddr.xCountry']
                                        }"
                                        validateOnBlur
                                        id="xAddrCountry"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="m-b-10">
                        <div *ngIf="!showSecurity">
                            <div class="card no-margin">
                                <div class="card-header">
                                    <label>Add credentials</label>
                                </div>
                                <div *ngIf="showAddUserCheckBox" class="cust-add-user-wrap">
                                    <div class="btn btn-light">
                                        <label class="checkbox-wrap no-margin">
                                            <label class="checkbox1 no-margin"  for="show_user">
                                                <input [checked]="edit && showSecurity" type="checkbox" id="show_user" (change)="onAddUserClick($event)" >
                                                <span></span>
                                                Add User
                                            </label>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showSecurity" class="security-panel" [ngClass]="{'add-gap': showAddUserCheckBox}" >
                            <div class="clearfix">
                                <app-security
                                    [source]="source"
                                    [edit]="edit"
                                    [userData]="employeeData"
                                    [form]="form"
                                    [serverErrors]="serverErrors"
                                    [securityData]="securityData"
                                    [hasUser]="hasUser"
                                ></app-security>
                            </div>
                            <div *ngIf="showAddUserCheckBox" class="remove-wrap">
                                <div>
                                    <i class="fa fa-remove add-cust-remove"></i>
                                    <label class="checkbox-wrap no-margin">
                                        <label class="checkbox1 no-margin"  for="show_user">
                                            <input [checked]="edit && showSecurity" type="checkbox" id="show_user" (change)="onAddUserClick($event)" >
                                            <span></span>
                                        </label>
                                    </label>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                
                <div  class="col">
                    <div *ngIf="!isLoading" class="card">
                        <div class="card-header">
                            <label class="m-t-5">Payment Accounts</label>
                            <!-- <button [disabled]="disable" class="btn btn-info btn-sm pull-right m-b-5 add-btn" (click)="onAddNewPaymentmethodClick()" > -->
                            <!-- <button type="button" class="btn btn-info btn-sm pull-right add-btn" (click)="onAddNewPaymentmethodClick()" >
                            <i class="fa fa-plus-circle" aria-hidden="true"></i> Manage Payment Accounts
                                </button> -->
                            <span class="pull-right tooltip-wrap-span" container="body" containerClass="tooltip-global" placement="top"
                                tooltip={{tooltipMessage}}>
                                <button class="btn btn-info btn-sm add-btn" (click)="onAddNewPaymentmethodClick()"
                                    type="button" [disabled]="submitting">
                                    <i class="fa fa-plus-circle" aria-hidden="true"></i>  Manage Payment Accounts 
                                </button>
                            </span>
                        </div>
                        <div>
                            <div *ngIf="!isLoading && edit" >
                                <section>
                                    <div class="grid-add-wrap">
                                        <!-- <span
                                            class="pull-right tooltip-wrap-span m-b-5"
                                            tooltip="Add New Payment Method"
                                            placement="top"
                                            container="body"
                                            containerClass="tooltip-global"
                                        > 
                                        </span>                                  -->
                                    </div>    
                                    <div class="payment_method_grid_cust">
                                        <grid-cmp
                                            [parameters]="gridParams"
                                            [isPaymentAccountEdit]="isPaymentAccountEdit"
                                            [storeObj]="memberAccountStoreObj"
                                            [storeValue]="memberAccountStoreValue"
                                            [attributes]="gridAttributes"
                                            [showDetails]="false"
                                            [showSearchBar]="false"
                                            [fetchByStore]="fetchByStore"
                                            >   
                                        </grid-cmp>  
                                    </div>                    
                                </section>
                                  
                                   
                                <!-- <app-accounts [storeForm]=form [accountList]=accountList [isPayment]=true [formErrors]=formErrors></app-accounts> -->
                            </div>
                            <div *ngIf="!edit">
                                <div class="card-body">
                                    <div class="padder-v text-center fixed-height-box-customer">
                                        <h6 class="no-margin text-muted">Please Setup Payment Accounts</h6>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    
                    <div class="card">
                        <div class="card-header">
                            <label class="">Alternate Contact</label>
                        </div>
                        <div class="card-body">
                            <div class="form-group row">
                                <div class="col-md-6 col-sm-12" formGroupName="xAltContact">
                                    <label for="alt_phone">Alternate Phone: </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAltContact.xPhone'] &&
                                            form
                                                .get('xAltContact')
                                                .get('xPhone')
                                                .hasError('required') &&
                                            form.get('xAltContact').get('xPhone').touched
                                        "
                                        class="inline-error"
                                    >
                                        Phone is required</small
                                    >

                                    <small *ngIf="serverErrors['xAltContact.xPhone']" class="inline-error">
                                        {{ serverErrors['xAltContact.xPhone']?.message.replace('"xPhone"', 'Field') }}</small
                                    >

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="alt_phone"
                                        (focus)="clearErrors('xAltContact.xPhone')"
                                        formControlName="xPhone"
                                        (keypress)="onKeypress($event, 'phoneNum')"
                                        (paste)="onPaste($event, 'phoneNum')"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAltContact')
                                                    .get('xPhone')
                                                    .hasError('required') &&
                                                    form.get('xAltContact').get('xPhone').touched) ||
                                                serverErrors['xAltContact.xPhone']
                                        }"
                                        validateOnBlur
                                    />
                                </div>
                                <div class="col-md-6 col-sm-12" formGroupName="xAltContact">
                                    <label for="email"
                                        >Email:
                                        <!-- <small class="text-danger">*</small> -->
                                    </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAltContact.xEmail'] &&
                                            form
                                                .get('xAltContact')
                                                .get('xEmail')
                                                .hasError('required') &&
                                            form.get('xAltContact').get('xEmail').touched
                                        "
                                        class="inline-error"
                                    >
                                        Email is required</small
                                    >

                                    <small *ngIf="serverErrors['xAltContact.xEmail']" class="inline-error">
                                        {{ serverErrors['xAltContact.xEmail']?.message.replace('"xEmail"', 'Field') }}</small
                                    >

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="email"
                                        (focus)="clearErrors('xAltContact.xEmail')"
                                        formControlName="xEmail"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAltContact')
                                                    .get('xEmail')
                                                    .hasError('required') &&
                                                    form.get('xAltContact').get('xEmail').touched) ||
                                                serverErrors['xAltContact.xEmail']
                                        }"
                                        validateOnBlur
                                        id="xAltContactEmail"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-12 col-sm-12" formGroupName="xAltAddr">
                                    <label for="line1"
                                        >Line1:
                                        <!-- <small class="text-danger">*</small> -->
                                    </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAltAddr.xLine1'] &&
                                            form
                                                .get('xAltAddr')
                                                .get('xLine1')
                                                .hasError('required') &&
                                            form.get('xAltAddr').get('xLine1').touched
                                        "
                                        class="inline-error"
                                    >
                                        Address Line 1 is required</small
                                    >

                                    <small *ngIf="serverErrors['xAltAddr.xLine1']" class="inline-error">
                                        {{ serverErrors['xAltAddr.xLine1']?.message.replace('"xLine1"', 'Field') }}</small
                                    >

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="line1"
                                        (focus)="clearErrors('xAltAddr.xLine1')"
                                        formControlName="xLine1"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAltAddr')
                                                    .get('xLine1')
                                                    .hasError('required') &&
                                                    form.get('xAltAddr').get('xLine1').touched) ||
                                                serverErrors['xAltAddr.xLine1']
                                        }"
                                        validateOnBlur
                                        id="xAltAddrLine1"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6 col-sm-12" formGroupName="xAltAddr">
                                    <label for="line2">Line2: </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAltAddr.xLine2'] &&
                                            form
                                                .get('xAltAddr')
                                                .get('xLine2')
                                                .hasError('required') &&
                                            form.get('xAltAddr').get('xLine2').touched
                                        "
                                        class="inline-error"
                                    >
                                        Suite is required</small
                                    >

                                    <small *ngIf="serverErrors['xAltAddr.xLine2']" class="inline-error">
                                        {{ serverErrors['xAltAddr.xLine2']?.message.replace('"xLine2"', 'Field') }}</small
                                    >

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="line2"
                                        (focus)="clearErrors('xAltAddr.xLine2')"
                                        formControlName="xLine2"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAltAddr')
                                                    .get('xLine2')
                                                    .hasError('required') &&
                                                    form.get('xAltAddr').get('xLine2').touched) ||
                                                serverErrors['xAltAddr.xLine2']
                                        }"
                                        validateOnBlur
                                        id="xAltAddrLine2"
                                    />
                                </div>
                                <div class="col-md-6 col-sm-12" formGroupName="xAltAddr">
                                    <label for="suite">Suite: </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAltAddr.xSuite'] &&
                                            form
                                                .get('xAltAddr')
                                                .get('xSuite')
                                                .hasError('required') &&
                                            form.get('xAltAddr').get('xSuite').touched
                                        "
                                        class="inline-error"
                                    >
                                        Suite is required</small
                                    >

                                    <small *ngIf="serverErrors['xAltAddr.xSuite']" class="inline-error">
                                        {{ serverErrors['xAltAddr.xSuite']?.message.replace('"xSuite"', 'Field') }}</small
                                    >

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="suite"
                                        (focus)="clearErrors('xAltAddr.xSuite')"
                                        formControlName="xSuite"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAltAddr')
                                                    .get('xSuite')
                                                    .hasError('required') &&
                                                    form.get('xAltAddr').get('xSuite').touched) ||
                                                serverErrors['xAltAddr.xSuite']
                                        }"
                                        validateOnBlur
                                        id="xAltAddrSuite"
                                    />
                                </div>
                            </div>
                            <div class="form-group row" formGroupName="xAltAddr">
                                <div class="col-md-6 col-sm-12">
                                    <label for="city">City: </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAltAddr.xCity'] &&
                                            form
                                                .get('xAltAddr')
                                                .get('xCity')
                                                .hasError('required') &&
                                            form.get('xAltAddr').get('xCity').touched
                                        "
                                        class="inline-error"
                                    >
                                        Suite is required</small
                                    >

                                    <small *ngIf="serverErrors['xAltAddr.xCity']" class="inline-error">
                                        {{ serverErrors['xAltAddr.xCity']?.message.replace('"xCity"', 'Field') }}</small
                                    >

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="city"
                                        (focus)="clearErrors('xAltAddr.xCity')"
                                        formControlName="xCity"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAltAddr')
                                                    .get('xCity')
                                                    .hasError('required') &&
                                                    form.get('xAltAddr').get('xCity').touched) ||
                                                serverErrors['xAltAddr.xCity']
                                        }"
                                        validateOnBlur
                                        id="xAltAddrCity"
                                    />
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <label for="postal_code">Postal Code: </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAltAddr.xPostCode'] &&
                                            form
                                                .get('xAltAddr')
                                                .get('xPostCode')
                                                .hasError('required') &&
                                            form.get('xAltAddr').get('xPostCode').touched
                                        "
                                        class="inline-error"
                                    >
                                        Suite is required</small
                                    >

                                    <small *ngIf="serverErrors['xAltAddr.xPostCode']" class="inline-error">
                                        {{ serverErrors['xAltAddr.xPostCode']?.message.replace('"xPostCode"', 'Field') }}</small
                                    >

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="postal_code"
                                        (focus)="clearErrors('xAltAddr.xPostCode')"
                                        (keypress)="onKeypress($event, 'postCode')"
                                        (paste)="onPaste($event, 'postCode')"
                                        formControlName="xPostCode"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAltAddr')
                                                    .get('xPostCode')
                                                    .hasError('required') &&
                                                    form.get('xAltAddr').get('xPostCode').touched) ||
                                                serverErrors['xAltAddr.xPostCode']
                                        }"
                                        validateOnBlur
                                        id="xAltAddrPostCode"
                                    />
                                </div>
                            </div>
                            <div class="form-group row no-margin" formGroupName="xAltAddr">
                                <div class="col-md-6 col-sm-12">
                                    <label for="state">State/Province: </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAltAddr.xSOP'] &&
                                            form
                                                .get('xAltAddr')
                                                .get('xSOP')
                                                .hasError('required') &&
                                            form.get('xAltAddr').get('xSOP').touched
                                        "
                                        class="inline-error"
                                    >
                                        Suite is required</small
                                    >

                                    <small *ngIf="serverErrors['xAltAddr.xSOP']" class="inline-error">
                                        {{ serverErrors['xAltAddr.xSOP']?.message.replace('"xSOP"', 'Field') }}</small
                                    >

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="state"
                                        (focus)="clearErrors('xAltAddr.xSOP')"
                                        formControlName="xSOP"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAltAddr')
                                                    .get('xSOP')
                                                    .hasError('required') &&
                                                    form.get('xAltAddr').get('xSOP').touched) ||
                                                serverErrors['xAltAddr.xSOP']
                                        }"
                                        validateOnBlur
                                        id="xAltAddrSOP"
                                    />
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <label for="country">Country: </label>
                                    <small
                                        *ngIf="
                                            !serverErrors['xAltAddr.xCountry'] &&
                                            form
                                                .get('xAltAddr')
                                                .get('xCountry')
                                                .hasError('required') &&
                                            form.get('xAltAddr').get('xCountry').touched
                                        "
                                        class="inline-error"
                                    >
                                        Suite is required</small
                                    >

                                    <small *ngIf="serverErrors['xAltAddr.xCountry']" class="inline-error">
                                        {{ serverErrors['xAltAddr.xCountry']?.message.replace('"xCountry"', 'Field') }}</small
                                    >

                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="country"
                                        (focus)="clearErrors('xAltAddr.xCountry')"
                                        formControlName="xCountry"
                                        [ngClass]="{
                                            'has-error':
                                                (form
                                                    .get('xAltAddr')
                                                    .get('xCountry')
                                                    .hasError('required') &&
                                                    form.get('xAltAddr').get('xCountry').touched) ||
                                                serverErrors['xAltAddr.xCountry']
                                        }"
                                        validateOnBlur
                                        id="xAltAddrCountrty"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    

                    
                    <!-- <div>
                        <div><label for="show_user">Add User</label>
                            <input type="checkbox" id="show_user" (change)="onSecurityFields($event)" >
                            
                        </div>
                        <div *ngIf="showSecurity">
                                <app-security
                                [source]="source"
                                [edit]="edit"
                                [userData]="employeeData"
                                [form]="form"
                                [serverErrors]="serverErrors"
                                [securityData]="securityData"
                                [hasUser]="hasUser"
                            ></app-security>
                        </div>
                       
                    </div> -->
                    <div class="card">
                        <div class="card-header">
                            <label>Notes</label>
                        </div>
                        <div class="form-group no-margin">
                            <div class="">
                                <small *ngIf="serverErrors['xNotes']" class="inline-error"> {{ serverErrors['xNotes']?.message.replace('"xNotes"', 'Field')}}</small>
                                <textarea class="form-control notes-area-cust-setup" rows="8" id="notes" (focus)="clearErrors('xNotes')" formControlName="xNotes" validateOnBlur >
                                </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ROW finsihes here -->
             
            <div class="card" formGroupName='xNotify'>
                <div class="card-header">
                    <label>Notify</label>
                </div>
                <div class="card-body">
                    <div class="form-group row no-margin">
                        <div class="col-sm-12 col-md-4">
                            <label for="name">Level 1:</label>
                            <small *ngIf="l1IncorrectNotifyMailFormat" class="inline-error">
                                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Email format is not valid" placement="top" containerClass="tooltip-custom inline-error"
                                    aria-hidden="true"></i>
                            </small>

                            <tag-input inputId='xL1URLS' [formControlName]="'xL1URLs'" [addOnBlur]="'true'" [separatorKeyCodes]="[32]" [ngClass]="{'has-error': l1IncorrectNotifyMailFormat}"
                                [placeholder]="'Add Email Ids'" [secondaryPlaceholder]="'Add Email Ids'" [editable]='true'
                                (onAdd)="onItemAddedEditedOrRemoved($event)" (onRemove)="onItemAddedEditedOrRemoved($event)"
                                (onTagEdited)="onItemEdited($event,'xL1URLs')">
                                <!-- <ng-template style="background: Red" let-item="item" let-index="index">
                                            {{item.value}}
                                        </ng-template> -->
                            </tag-input>
                        </div>
                        <!-- <tag-input [formControlName]="'xL1URLs'" [placeholder]="'Add URLs'" (onAdd)="onItemAdded($event)" [editable]='true' [errorMessages]="errorMessages" [validators]="validators"></tag-input> -->

                        <div class="col-sm-12 col-md-4">
                            <label for="name">Level 2:</label>
                            <small *ngIf="l2IncorrectNotifyMailFormat" class="inline-error">
                                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Email format is not valid" placement="top" containerClass="tooltip-custom inline-error"
                                    aria-hidden="true"></i>
                            </small>

                            <tag-input inputId='xL2URLS' [formControlName]="'xL2URLs'" [addOnBlur]="'true'" [separatorKeyCodes]="[32]" [ngClass]="{'has-error': l2IncorrectNotifyMailFormat}"
                                [placeholder]="'Add Email Ids'" [secondaryPlaceholder]="'Add Email Ids'" [editable]='true'
                                (onAdd)="onItemAddedEditedOrRemoved($event)" (onRemove)="onItemAddedEditedOrRemoved($event)"
                                (onTagEdited)="onItemEdited($event,'xL2URLs')">
                                <!-- <ng-template style="background: Red" let-item="item" let-index="index">
                                                    {{item.value}}
                                                </ng-template> -->
                            </tag-input>
                        </div>
                        <!-- <tag-input [formControlName]="'xL2URLs'" [placeholder]="'Add URLs'" (onAdd)="onItemAdded($event)" [editable]='true' [errorMessages]="errorMessages" [validators]="validators"></tag-input> -->

                        <div class="col-sm-12 col-md-4">
                            <label for="name">Level 3:</label>
                            <small *ngIf="l3IncorrectNotifyMailFormat" class="inline-error">
                                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Email format is not valid" placement="top" containerClass="tooltip-custom inline-error"
                                    aria-hidden="true"></i>
                            </small>

                            <tag-input inputId='xL3URLS'[formControlName]="'xL3URLs'" [addOnBlur]="'true'" [separatorKeyCodes]="[32]" [ngClass]="{'has-error': l3IncorrectNotifyMailFormat}"
                                [placeholder]="'Add Email Ids'" [secondaryPlaceholder]="'Add Email Ids'" [editable]='true'
                                (onAdd)="onItemAddedEditedOrRemoved($event)" (onRemove)="onItemAddedEditedOrRemoved($event)"
                                (onTagEdited)="onItemEdited($event,'xL3URLs')">
                                <!-- <ng-template style="background: Red" let-item="item" let-index="index">
                                                    {{item.value}}
                                                </ng-template> -->
                            </tag-input>
                            <!-- <tag-input [formControlName]="'xL3URLs'" [ngClass]="{'has-error': getL3IncorrectNotifyMailFormat()}" [placeholder]="'Add URLs'"
                                        [secondaryPlaceholder]="'Add URLs'" [onAdding]='validateL3BeforeAdding' [editable]='true'>
                                        <ng-template style="background: Red" let-item="item" let-index="index">
                                                    {{item.value}}
                                                </ng-template>
                                    </tag-input> -->
                        </div>
                        <!-- <tag-input [formControlName]="'xL3URLs'" [placeholder]="'Add URLs'" (onAdd)="onItemAdded($event)" [editable]='true' [errorMessages]="errorMessages" [validators]="validators"></tag-input> -->
                    </div>
                </div>
            </div>

            <div class="row m-t-5">
                <div class="col-sm-12 text-right">
                    <a class="btn btn-secondary" (click)="onCancel($event)"> <i class="fa fa-times" aria-hidden="true"></i> Cancel</a>
                    <span 
                        class="tooltip-wrap-span" 
                        [tooltip]="(edit && !canEdit) || (!edit && !canAdd) ? noPermMsg : 'Save'" 
                        placement="left" 
                        container="body" 
                        containerClass="tooltip-global"
                    >
                        <button class="btn btn-primary " name="next" type="submit" [disabled]="submitting || (edit && !canEdit) || (!edit && !canAdd)">
                            <span *ngIf="!submitting">
                                Save
                                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                            </span>
                            <span *ngIf="submitting"
                                >Please wait...
                                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                            </span>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </form>
</section>
<section *ngIf="edit && loading">
    <div style="text-align: center; position:realtive">
        <div class="col-md-12">
            <div class="grid-spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <span style="color:#3b5877">Fetching required data</span>
        </div>
    </div>
</section>
