import { Component, OnInit, AfterViewInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { validateFor } from '../../../../common-functions';
import { maskCardToken } from '../../../../common-functions/miscellaneous.function';
import { BRAND_TAGS_MAPPING, MEDIUM_TAGS_MAPPING } from '../../../../constants/mapping.constants';

const NO_DEPENDENCY_MSG: string = 'This Node has no associated dependencies.'
const NODES_WILL_BE_DELETED_WARNING_MESSAGE: string = 'Deleting this item will result in removal of dependencies which contain other stored data and settings.';

//Nodes below profit-center
const MERCHANT_ACCOUNT_KEY: string = 'merchant_accounts';
const TERMINAL_KEY: string = 'terminals';
const PERIPHERAL_KEY: string = 'peripherals';
const DEPOSIT_ACCOUNT_KEY: string = 'depositAccounts';
const PAYMENT_ACCOUNT_KEY: string = 'paymentAccounts';
const SAFES_KEY: string = 'safes';

const NODE_NAME_PERIPHERALS: string = 'Peripherals';
const NODE_NAME_TERMINAL: string = 'Terminals';
const NODE_NAME_MERCHANT_ACCOUNT: string = 'Merchant Accounts';
const NODE_NAME_DEPOSIT_ACCOUNT: string = 'Deposit Accounts';
const NODE_NAME_PAYMENT_ACCOUNT: string = 'Payment Accounts';
const LIST_OF_SAFES: string = 'list_of_safes';

const MERCHANT_ACCT_TABLE_HEADER: any[] = ['Name', 'Ref Num'];
const TERMINAL_TABLE_HEADER: any = ['Name', 'GTID'];
const PERIPH_TABLE_HEADER: string[] = ['Name', 'Peripheral Number']; // might change in future if sync for peripheral is added
const PAYMENT_ACCOUNT_TABLE_HEADER: any[] = ['Account Token', 'Brand', 'Medium'];
const DEPOSIT_ACCOUNT_TABLE_HEADER: any[] = PAYMENT_ACCOUNT_TABLE_HEADER;

@Component({
	selector: 'app-delete-pctr-modal',
	templateUrl: './delete-pctr-modal.component.html',
	styleUrls: ['./delete-pctr-modal.component.scss']
})
export class DeletePctrModalComponent implements OnInit, AfterViewInit {
	@ViewChild('deletePctrModal', {static: true}) public deletePctrModal: ModalDirective;
	@Output() onModalCloseEvent = new EventEmitter<boolean>();
	@Output() shouldDeletePctr = new EventEmitter<boolean>();
	@Input() repercussionData: any = {};
	@Input() pCtrToBeDeleted: string = '';

	public MERCHANT_ACCOUNT_KEY = MERCHANT_ACCOUNT_KEY;
	public TERMINAL_KEY = TERMINAL_KEY;
	public PERIPHERAL_KEY = PERIPHERAL_KEY;
	public DEPOSIT_ACCOUNT_KEY = DEPOSIT_ACCOUNT_KEY;
	public PAYMENT_ACCOUNT_KEY = PAYMENT_ACCOUNT_KEY;

	public activeNode: string = MERCHANT_ACCOUNT_KEY; //Set default active node to merchant-acct
	public tableBody: any[] = []; //Data to be displayed in  table body according to selected node
	public tableHeaders: any[] = []; //Data to be displayed in table header according to selected node
	public currentNodeName: string = NODE_NAME_MERCHANT_ACCOUNT; //Current node name selected in tab

	public NODE_NAME_PERIPHERALS = NODE_NAME_PERIPHERALS;
	public NODE_NAME_TERMINAL = NODE_NAME_TERMINAL;
	public NODE_NAME_MERCHANT_ACCOUNT = NODE_NAME_MERCHANT_ACCOUNT;
	public NODE_NAME_DEPOSIT_ACCOUNT = NODE_NAME_DEPOSIT_ACCOUNT;
	public NODE_NAME_PAYMENT_ACCOUNT = NODE_NAME_PAYMENT_ACCOUNT;
	public NODES_WILL_BE_DELETED_WARNING_MESSAGE = NODES_WILL_BE_DELETED_WARNING_MESSAGE;

  //Total number of nodes
	public totalMerchantAccounts: number = 0; //Total merchant accounts
	public totalPaymentAccounts: number = 0; //Total payment accounts of all customers under store
	public totalDepositAccounts: number = 0; //Total deposit accounts under store
	public totalTerminals: number = 0; //Total terminals
	public totalPeriphs: number = 0; // Total peripherals

  	public BRAND_TAGS_MAPPING = BRAND_TAGS_MAPPING; //Used directly in HTML to map brand tag
  	public MEDIUM_TAGS_MAPPING = MEDIUM_TAGS_MAPPING;//Used directly in HTML to map medium

  	public maskCardToken = maskCardToken; //Function to mask cardToken
  
  	public noDependenciesMsg: string = NO_DEPENDENCY_MSG;

	constructor() {}

	ngOnInit() {
		console.log('delete modal component called');
		//By default, selected node is merchant account
		this.tableHeaders = MERCHANT_ACCT_TABLE_HEADER;
		this.tableBody = validateFor(MERCHANT_ACCOUNT_KEY, this.repercussionData) ? this.repercussionData[MERCHANT_ACCOUNT_KEY] : {};
		this.totalMerchantAccounts = this.tableBody.length;
		this.totalTerminals = validateFor(TERMINAL_KEY, this.repercussionData) ? this.repercussionData[TERMINAL_KEY].length : 0;
		this.totalPeriphs = validateFor(PERIPHERAL_KEY, this.repercussionData) ? this.repercussionData[PERIPHERAL_KEY].length : 0;

		if (this.validateSafes()) {
			this.setDespositAccountsCount();
			this.setPaymentAccountsCount();
		}
		console.log('default table headers', this.tableHeaders);
		console.log('default data in table body', this.tableBody);
    	console.log('repercussion data: ngOnInit', this.repercussionData);
	}

	/**
	 * Set total number of payment accounts count
	 */
	setPaymentAccountsCount(){
		let paymentAccounts = this.repercussionData[SAFES_KEY][PAYMENT_ACCOUNT_KEY];
		if (paymentAccounts.length) {
			for (let i = 0; i < paymentAccounts.length; i++) {
				if (validateFor(LIST_OF_SAFES, paymentAccounts[i])) {
					this.totalPaymentAccounts =
						this.totalPaymentAccounts + paymentAccounts[i][LIST_OF_SAFES].length;
				}
			}
		}
		console.log('total payment accounts: setPaymentAccountsCount', this.totalPaymentAccounts);
	}

	/**
	 * Set total number of Deposit accounts count
	 */
	setDespositAccountsCount(){
		let depositAccounts = this.repercussionData[SAFES_KEY][DEPOSIT_ACCOUNT_KEY];
		if (depositAccounts.length) {
			for (let i = 0; i < depositAccounts.length; i++) {
				if (validateFor(LIST_OF_SAFES, depositAccounts[i])) {
					this.totalDepositAccounts =
						this.totalDepositAccounts + depositAccounts[i][LIST_OF_SAFES].length;
				}
			}
		}
		console.log('total deposit accounts: setDespositAccountsCount', this.totalDepositAccounts);
	}

	/**
	 * Validate repercussionData data for safes record
	 */
	validateSafes() {
		console.log('validateSafes:', this.repercussionData);
		return (
			validateFor(SAFES_KEY, this.repercussionData) 
			&& validateFor(PAYMENT_ACCOUNT_KEY, this.repercussionData[SAFES_KEY]) 
			&& validateFor(DEPOSIT_ACCOUNT_KEY, this.repercussionData[SAFES_KEY]) 
		);
	}

	/**
	 * Set data do be displayed on table when node is clicked
	 * @param `clickedNode`: string: type of clicked node
	 */
	onNodeClick(clickedNode: string) {
		console.log('clickedNode: onClick', clickedNode);
		this.activeNode = clickedNode;
		switch (clickedNode) {
			case PERIPHERAL_KEY:
				this.tableHeaders = PERIPH_TABLE_HEADER;
				this.tableBody = validateFor(clickedNode, this.repercussionData) ? this.repercussionData[clickedNode] : [];
				this.currentNodeName = NODE_NAME_PERIPHERALS;
				break;
			case TERMINAL_KEY:
				this.tableHeaders = TERMINAL_TABLE_HEADER;
				this.tableBody = validateFor(clickedNode, this.repercussionData) ? this.repercussionData[clickedNode] : [];
				this.currentNodeName = NODE_NAME_TERMINAL;
				break;
			case MERCHANT_ACCOUNT_KEY:
				this.tableHeaders = MERCHANT_ACCT_TABLE_HEADER;
				this.tableBody = validateFor(clickedNode, this.repercussionData) ? this.repercussionData[clickedNode] : [];
				this.currentNodeName = NODE_NAME_MERCHANT_ACCOUNT;
				break;
			case DEPOSIT_ACCOUNT_KEY:
				this.tableHeaders = DEPOSIT_ACCOUNT_TABLE_HEADER;
				this.tableBody = this.validateSafes() ? this.repercussionData[SAFES_KEY][DEPOSIT_ACCOUNT_KEY] : [];
				// console.log('deposit accts: onNodeClick', this.repercussionData[SAFES_KEY][DEPOSIT_ACCOUNT_KEY]);
				break;
			case PAYMENT_ACCOUNT_KEY:
				this.tableHeaders = PAYMENT_ACCOUNT_TABLE_HEADER;
				this.tableBody = this.validateSafes() ? this.repercussionData[SAFES_KEY][PAYMENT_ACCOUNT_KEY] : [];
				// console.log('payment accts: onNodeClick', this.repercussionData[SAFES_KEY][PAYMENT_ACCOUNT_KEY]);
				break;
		}
		console.log('after click: tableHeaders', this.tableHeaders);
		console.log('after click: tableBody', this.tableBody);
	}

	/**
	 * show modal
	 */
	ngAfterViewInit() {
		this.deletePctrModal.show();
	}

  /**
   * Emit true if proceed to delete is clicked.
   */
	onProceedToDeletePctrClick() {
		console.log('onProceedToDeletePctrClick: proceed clicked');
		this.shouldDeletePctr.emit(true);
		this.deletePctrModal.hide();
	}

	/**
	 * Close modal
	 */
	closeDeleteModal() {
		this.onModalCloseEvent.emit(false);
		this.deletePctrModal.hide();
	}
}
