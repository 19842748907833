<section *ngIf='!loading'>
  <form class="form-horizontal" [formGroup]="searchParams" (ngSubmit)="onSubmit(searchParams)" novalidate>
    <div class="card">
      <div class="card-body">
        <div class="row form-group ">
          <div class="col-sm-9">
            <div class="row">
              <div class="col-md-3 col-sm-6">
                <label for="txnnum">Transaction Number:</label>
                <input type="text" class="form-control form-control-sm" id="txnnum" formControlName="txnNum">
              </div>
              <div class="col-md-3 col-sm-6">
                <label for="invnum">Invoice Number:</label>
                <input type="text" class="form-control form-control-sm" id="invnum" formControlName="invoiceNum">
              </div>
              <div class="col-md-3 col-sm-6">
                <label for="totamtfrom">Total Amount From:</label>
                <div class="addon-icon">
                  <i class="fa fa-usd"></i>
                  <input type="text" class="form-control form-control-sm" id="totamtfrom" formControlName="minTotAmt">
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <label for="totamtto"> To:</label>
                <div class="addon-icon">
                  <i class="fa fa-usd"></i>
                  <input type="text" class="form-control form-control-sm" id="totamtto" formControlName="maxTotAmt">
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class='row'>
              <div class='col-sm-12'>
                <label for="authcode">Auth Code:</label>
                <input type="text" class="form-control form-control-sm" id="authcode" formControlName="authCode">
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-9">
            <div class="row">
              <div class="col-md-3 col-sm-6 merch-info">
                <label for="cardtype">Card Type:</label>
                <!-- <input type="text" class="form-control form-control-sm" id="cardtype" formControlName="cardType"> -->
                <div class="merch_type">
                  <select class="form-control form-control-sm" formControlName="cardMdm">
                      <option value="" selected="!selectedCardType">Select Card Type </option>
                      <option *ngFor="let card of cardType" [value]="card.tag">{{card.value}}</option>
                  </select>
                  <span>
                      <i class="fa fa-angle-down"></i>
                  </span>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 merch-info">
                <label for="cardbrand">Card Brand:</label>
                <!-- <input type="text" class="form-control form-control-sm" id="cardbrand" formControlName="cardBrand"> -->
                <div class="merch_type">
                  <select class="form-control form-control-sm" formControlName="cardBrand">
                    <option value="" selected="!selectedCardBrand">Select Card Brand </option>
                    <option *ngFor="let brand of brandType" [value]="brand.tag">{{brand.value}}</option>
                  </select>
                  <span>
                      <i class="fa fa-angle-down"></i>
                  </span>
                </div>
              </div>
              <div class='col-md-3 col-sm-6'>
                <label>Card First 6 digits</label>
                <input type="text" class="form-control form-control-sm" id="card_firstSix" formControlName="card_firstSixDigits"/>
              </div>
              <div class="col-md-3 col-sm-6">
                <label for="card">Card last 4 digits:</label>
                <input type="text" class="form-control form-control-sm" id="card_lastFour" formControlName="card_lastFourDigits">
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="row">
              <div class="col-md-8">
                <div class="merch-info">
                  <label for="cardbrand">Source:</label>
                  <!-- <input type="text" class="form-control form-control-sm" id="cardbrand" formControlName="cardBrand"> -->
                  <div class="merch_type">
                    <select class="form-control form-control-sm" formControlName="source">
                      <option value="" selected="!selectedSource">Select Source </option>
                      <option *ngFor="let source of SOURCE_ARR" [value]="source.value">{{ source.tag }}</option>
                    </select>
                    <span>
                        <i class="fa fa-angle-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="search-form-margin">
                  <div class="row">
                    <!-- <div class="col-sm-6">
                      <button type='reset' class="btn btn-secondary btn-sm btn-block">
                        <i class="fa fa-undo" aria-hidden="true"></i> Reset
                      </button>
                    </div> -->
                    <div class="col-sm-12 merch-info">
                      <button type='submit' class="btn btn-primary btn-sm btn-block">
                        <i class="fa fa-search" aria-hidden="true"></i> Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='row form-group'>
          <div class='col-sm-9'>
            <!-- <div class='row'>
              <div class='col-md-3 col-sm-6'>
                <button type='submit' class="btn btn-primary btn-sm btn-block">
                  <i class="fa fa-search" aria-hidden="true"></i> Search
                </button>
              </div>
            </div> -->
          </div>
          <!-- <div class='col-md-3 col-sm-6'>
            <div class='row'>
              <div class='col-sm-12'>
                <button type='submit' class="btn btn-primary btn-sm btn-block">
                  <i class="fa fa-search" aria-hidden="true"></i> Search
                </button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </form>
</section>