<section *ngIf="!loading && !noProp" class="setup buis-info">
    <form class="form-horizontal" [ngClass]="{'disable-form':(parentMode || userHandlerService?.userRoles?.isBusinessEmployee)}" [formGroup]="form" (ngSubmit)="onPropertySubmit(form)" novalidate>
        <div class="row top-stat">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-check-circle-o label-icon label-head-icon" aria-hidden="true"></i>
                        <label for="name">Status:</label>
                        <label class="detail-label"> Active</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-sliders label-icon label-head-icon" aria-hidden="true"></i>
                        <label for="name" class="p-l-5">Type:</label>
                        <label class="detail-label"> Property</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-briefcase label-icon" aria-hidden="true"></i>
                        <label for="name">Business:
                        </label>
                        <label class="detail-label"> {{businessService.businessData?.xName}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-level-up label-icon fa-flip-horizontal" aria-hidden="true"></i>
                        <label *ngIf="ifPropExtendsChain && !ifPropExtendsRegion" class="p-l-5">Chain:</label>
                        <label *ngIf="ifPropExtendsRegion" class="p-l-5">Region:</label>
                        <label *ngIf="!ifPropExtendsRegion && !ifPropExtendsChain" class="p-l-5">Business:</label>
                        <label *ngIf="ifPropExtendsChain && !ifPropExtendsRegion" class="detail-label">{{chainName}}</label>
                        <label *ngIf="ifPropExtendsRegion" class="detail-label">{{regionName}}</label>
                        <label *ngIf="!ifPropExtendsRegion && !ifPropExtendsChain" class="detail-label"> {{businessService.businessData?.xName}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div>

        <div class="row">
            <div class="col-sm-8">
                <div class="card">
                    <div class="card-header">
                        <label class="">Basic</label>
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-12 col-sm-12">
                                <label for="name">Name:
                                    <small class="text-danger">*</small>
                                </label>

                                <small *ngIf="!serverErrors['xName'] && form.get('xName').hasError('required') && form.get('xName').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>
                                <small *ngIf="!serverErrors['xName'] && form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is of incorrect format. Correct Format-: 1) Name can not be more than 20 letters. 2) The first character of Name should not be an empty space. 3) The first character of Name should not be any special characters, E.g. /,& etc." placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>
                                <small *ngIf="serverErrors['xName']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <input type="text" class="form-control form-control-sm" id="store_Name" (focus)="clearErrors('xName')" formControlName="xName"
                                    [ngClass]="{'has-error': (form.get('xName').hasError('required') && form.get('xName').touched) || (form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched) || serverErrors['xName']}"
                                    validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-12">
                                <label for="name_two">Sub Name:
                                    <!-- <small class="text-danger">*</small> -->
                                </label>

                                <small *ngIf="!serverErrors['xSubName'] && form.get('xSubName').hasError('required') && form.get('xSubName').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Sub Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>

                                </small>

                                <small *ngIf="serverErrors['xSubName']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Sub Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <input type="text" class="form-control form-control-sm" id="name_two" (focus)="clearErrors('xSubName')" formControlName="xSubName"
                                    [ngClass]="{'has-error': (form.get('xSubName').hasError('required') && form.get('xSubName').touched) || serverErrors['xSubName']}"
                                    validateOnBlur>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="tag">Tag:
                                    <!-- <small class="text-danger">*</small> -->
                                </label>
                                <input type="text" class="form-control form-control-sm" id="tag" (focus)="clearErrors('xTag')" formControlName="xTag" [disabled]=true>
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-6 col-sm-12">
                                <label for="ref_num">Ref Number:
                                    <!-- <small class="text-danger">*</small> -->
                                </label>

                                <small *ngIf="!serverErrors['xRefNum'] && form.get('xRefNum').hasError('required') && form.get('xRefNum').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Ref Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>

                                <small *ngIf="serverErrors['xRefNum']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Ref Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>
                                <i class="fa fa-info-circle" tooltip="Do not enter the reference number unless instructed to do so" placement="top" containerClass="tooltip-global"
                                    container="body" aria-hidden="true"></i>
                                <input type="text" class="form-control form-control-sm" id="ref_num" (focus)="clearErrors('xRefNum')" formControlName="xRefNum"
                                    [ngClass]="{'has-error': (form.get('xRefNum').hasError('required') && form.get('xRefNum').touched) || formErrors['xRefNum']}"
                                    validateOnBlur>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="prop_num">Property Number:
                                    <small class="text-danger">*</small>
                                </label>
                                <app-model-number [modelNumber]=propertySetupModel.xStructNum [form]=form [serverErrors]=serverErrors [numberKey]=numberKey></app-model-number>
                            </div>
                        </div>
                    </div>
                </div>
                <app-address [addressContactValidate]=addressContactValidate [address]=propertySetupModel.xAddr [addressForm]=form [serverErrors]=serverErrors></app-address>

            </div>
            <div class="col-sm-4">
                <app-main-contact-column [addressContactValidate]=addressContactValidate [addressContactValidate]=addressContactValidate [mainContactForm]=form [mainContact]=propertySetupModel.xMainContact [serverErrors]=serverErrors></app-main-contact-column>
                <div class="card">
                    <div class="card-header">
                        <label>Notes</label>
                    </div>
                    <div class="">
                        <small *ngIf="!formErrors['xNotes'] && form.get('xNotes').hasError('required') && form.get('xNotes').touched" class="inline-error">
                            <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Notes is required" placement="top" containerClass="tooltip-custom inline-error"
                                aria-hidden="true"></i>
                        </small>
                        <small *ngIf="formErrors['xNotes']" class="inline-error">
                            <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Notes is required" placement="top" containerClass="tooltip-custom inline-error"
                                aria-hidden="true"></i>
                        </small>

                        <textarea class="form-control notes-area" rows="4" id="notes" formControlName="xNotes" validateOnBlur style="min-height: 131px;">
                        </textarea>
                    </div>
                </div>
                <app-social-contact-column [generalContact]=propertySetupModel.xSocial [generalContactForm]=form [serverErrors]=serverErrors></app-social-contact-column>
            </div>
        </div>
        <div class="row m-t-5" *ngIf='!(parentMode || userHandlerService?.userRoles?.isBusinessEmployee)'>
            <div class="col-sm-12 text-right">
                <!-- <a class="btn btn-secondary" [routerLink]="['/dashboard/setup/business/structure/information']" [routerLinkActive]="['router-link-active']"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a> -->
                <button class="btn btn-secondary" (click)="onCancel($event)">
                    <i class="fa fa-times" aria-hidden="true"></i> Cancel</button>
                <span 
                    class="tooltip-wrap-span" 
                    [tooltip]="(edit && !canEdit) || (!edit && !canAdd) ? noPermMsg : 'Save'" 
                    placement="left" 
                    container="body" 
                    containerClass="tooltip-global"
                >
                    <button class="btn btn-primary" type='submit' [disabled]="submitting || (edit && !canEdit) || (!edit && !canAdd)">
                        <span *ngIf="!submitting"> Save
                            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                        </span>
                        <span *ngIf="submitting">Please wait...
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </span>
                    </button>
                </span>
            </div>
        </div>
        </div>
    </form>
    <div class="row  m-t-5" *ngIf='parentMode && !userHandlerService?.userRoles?.isBusinessEmployee'>
        <div class="col-sm-12 text-right">
            <button class="btn btn-primary" (click)="onEditClick($event)" [disabled]="!canEdit"><i class="fa fa-pencil" aria-hidden="true"></i>  Edit</button>
        </div>
    </div>
</section>
<section *ngIf="!loading && noProp">
    <div class="row m-b-1">
        <div class="col-sm-10 offset-sm-1 m-t-30">
            <div class="coming-soon">
                <h4 class="text-muted">No property exists, corresponding to selected node.</h4>
            </div>
        </div>
    </div>
</section>
<section *ngIf="edit && loading">
    <div style="text-align: center; position:realtive">
        <div class="col-md-12">
            <div class="grid-spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <span style="color:#3b5877">Fetching data</span>
        </div>
    </div>
</section>