<!-- <div class="login-page animate">
  <div class="customer-login-wrap"> -->
<div class="customer-form-wrap customer-register-form">
  <h1 class="login-title" style="margin: 40px 0;">Register</h1>
  <div class="register-inner">
    <form [formGroup]="form" (ngSubmit)="onSubmit(form)" novalidate>
      <div class="text-left">
        <div class="customer-register-card">
          <div class="row">
            <div class="col-sm-12">
              <h3>Basic</h3>
            </div>
            <div class="col-md-6 col-sm-12" formGroupName="xFormalName">
              <label for="fore_name">First Name:<small class="text-danger"> * </small></label>
              <small *ngIf="!serverErrors['xFormalName.xForename'] && form.get('xFormalName').get('xForename').hasError('required') && form.get('xFormalName').get('xForename').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Fore Name is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <small *ngIf="serverErrors['xFormalName.xForename']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="This field is not allowed to be empty"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <input type="text" class="form-control input-rounded" formControlName="xForename" (focus)="clearErrors('xFormalName.xForename')"
                [ngClass]="{'has-error': (form.get('xFormalName').get('xForename').hasError('required') && form.get('xFormalName').get('xForename').touched) || serverErrors['xFormalName.xForename']}"
                validateOnBlur>
            </div>
            <div class="col-md-6 col-sm-12" formGroupName="xFormalName">
              <label for="sur_name">Last Name:<small class="text-danger"> *</small></label>

              <small *ngIf="!serverErrors['xFormalName.xSurname'] && form.get('xFormalName').get('xSurname').hasError('required') && form.get('xFormalName').get('xSurname').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Sur Name is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <small *ngIf="serverErrors['xFormalName.xSurname']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="This field is not allowed to be empty"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <input type="text" class="form-control input-rounded" formControlName="xSurname" (focus)="clearErrors('xFormalName.xSurname')"
                [ngClass]="{'has-error': (form.get('xFormalName').get('xSurname').hasError('required') && form.get('xFormalName').get('xSurname').touched) || serverErrors['xFormalName.xSurname']}"
                validateOnBlur>
            </div>
            <!-- <div class="col-md-6 col-sm-12">
              <label for="tag">Tag:</label>
              <small *ngIf="serverErrors['xTag']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Tag is required" placement="top"
                  containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <input type="text" class="form-control input-rounded" formControlName="xTag" (focus)="clearErrors('xTag')"
                [ngClass]="{'has-error': (form.get('xTag').hasError('required') && form.get('xTag').touched) || serverErrors['xTag']}"
                validateOnBlur>
            </div>
            <div class="col-md-6 col-sm-12">
              <label for="ref_num">Ref Number:</label>
              <small *ngIf="serverErrors['xRefNum']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Reference is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>

              </small>
              <i class="fa fa-info-circle" tooltip="Do not enter the reference number unless instructed to do so"
                placement="top" container="body" containerClass="tooltip-global" aria-hidden="true"></i>
              <input type="text" class="form-control input-rounded" formControlName="xRefNum" (focus)="clearErrors('xRefNum')"
                [ngClass]="{'has-error': (form.get('xRefNum').hasError('required') && form.get('xRefNum').touched) || serverErrors['xRefNum']}"
                validateOnBlur>
            </div> -->
            <div class="col-md-6 col-sm-12">
              <label for="cust_num">Customer Number:<small class="text-danger"> * </small></label>
              <i class="fa fa-info-circle" 
                [tooltip]="ADD_YOUR_OWN_CUSTOMER_NUMBER_MSG" 
                placement="top" containerClass="tooltip-global"
                container="body" aria-hidden="true"
                >
              </i>
              <div class="search-box input-group" *ngIf="numberKey">
                <input type="text" 
                  class="form-control input-rounded"
                  formControlName="xCustomerNum"
                  [ngClass]="{'has-error': (form.get('xCustomerNum').hasError('required') && form.get('xCustomerNum').touched) || serverErrors['xCustomerNum']}"
                  aria-hidden="true">
                <span class="input-group-btn">
                  <button tooltip="Get new number" placement="top" container="body" containerClass="tooltip-global" type="button"
                  class="btn btn-light" (click)="getNewNumber()"><i class="fa fa-undo"></i></button>
                </span>
              </div>
            </div>
            <div class="col-md-6 col-sm-12" >
              <label for="sur_name">Company Name:
                <!-- <small class="text-danger">*</small> -->
              </label>
              <small *ngIf="!serverErrors['xCompanyName'] && form.get('xCompanyName').hasError('required') && form.get('xCompanyName').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Company Name is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <small *ngIf="serverErrors['xCompanyName']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="This field is not allowed to be empty"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <input type="text" class="form-control input-rounded" formControlName="xCompanyName" (focus)="clearErrors('xCompanyName')"
                [ngClass]="{'has-error': (form.get('xCompanyName').hasError('required') && form.get('xCompanyName').touched) || serverErrors['xCompanyName']}"
                validateOnBlur>
            </div>
            <!-- <div class="col-md-6 col-sm-12">
              <label>Member Number:</label>
              <input type="text" class="form-control input-rounded" formControlName="xMemberNumber">
            </div> -->
          </div>
        </div>
        <div class="customer-register-card">
          <div class="row">
            <div class="col-sm-12">
              <h3>Contact</h3>
            </div>
            <div class="col-md-6 col-sm-12" formGroupName="xContact">
              <label for="phone">Phone: <small class="text-danger">*</small></label>
              <small *ngIf="!serverErrors['xContact.xPhone'] && form.get('xContact').get('xPhone').hasError('required') && form.get('xContact').get('xPhone').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Phone Number is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <small *ngIf="serverErrors['xContact.xPhone']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="This field is not allowed to be empty"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <input type="text" class="form-control input-rounded" formControlName="xPhone" (focus)="clearErrors('xContact.xPhone')"
                (keypress)="onKeypress($event,'phoneNum')" (paste)="onPaste($event, 'phoneNum')" [ngClass]="{'has-error': (form.get('xContact').get('xPhone').hasError('required') && form.get('xContact').get('xPhone').touched) || serverErrors['xContact.xPhone']}"
                validateOnBlur>
            </div>
            <div class="col-md-6 col-sm-12" formGroupName="xContact">
              <label for="email">Email: <small class="text-danger">*</small></label>
              <small *ngIf="!serverErrors['xContact.xEmail'] && form.get('xContact').get('xEmail').hasError('required') && form.get('xContact').get('xEmail').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Email is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <small *ngIf="serverErrors['xContact.xEmail']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="This field is not allowed to be empty"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <small *ngIf="!this.serverErrors['xContact.xEmail'] &&  form.get('xContact').get('xEmail').hasError('incorrectMailFormat') && form.get('xContact').get('xEmail').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Email format is invalid."
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <input type="text" class="form-control input-rounded" formControlName="xEmail" (focus)="clearErrors('xContact.xEmail')"
                [ngClass]="{'has-error': ( (form.controls.xContact?.get('xEmail').hasError('incorrectMailFormat') || form.controls.xContact?.get('xEmail').hasError('required')) &&  form.controls.xContact?.get('xEmail').touched)|| this.serverErrors['xContact.xEmail']}"
                validateOnBlur>
            </div>
            <div class="col-md-12 col-sm-12" formGroupName="xAddr">
              <label for="line1">Address: <small class="text-danger">*</small></label>
              <small *ngIf="!serverErrors['xAddr.xLine1'] && form.get('xAddr').get('xLine1').hasError('required') && form.get('xAddr').get('xLine1').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Line 1 is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <small *ngIf="serverErrors['xAddr.xLine1']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="This field is not allowed to be empty"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <input type="text" class="form-control input-rounded" formControlName="xLine1" (focus)="clearErrors('xAddr.xLine1')"
                [ngClass]="{'has-error': (form.get('xAddr').get('xLine1').hasError('required') && form.get('xAddr').get('xLine1').touched) || serverErrors['xAddr.xLine1']}"
                validateOnBlur>
            </div>
            <!-- <div class="col-md-6 col-sm-12" formGroupName="xAddr">
              <label for="line2">Line2: </label>
              <small *ngIf="!serverErrors['xAddr.xLine2'] && form.get('xAddr').get('xLine2').hasError('required') && form.get('xAddr').get('xLine2').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Line 2 is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <small *ngIf="serverErrors['xAddr.xLine2']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="This field is not allowed to be empty"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <input type="text" class="form-control input-rounded" formControlName="xLine2" (focus)="clearErrors('xAddr.xLine2')"
                [ngClass]="{'has-error': (form.get('xAddr').get('xLine2').hasError('required') && form.get('xAddr').get('xLine2').touched) || serverErrors['xAddr.xLine2']}"
                validateOnBlur>
            </div> -->
            <div class="col-md-6 col-sm-12" formGroupName="xAddr">
              <label for="suite">Suite: </label>
              <small *ngIf="!serverErrors['xAddr.xSuite'] && form.get('xAddr').get('xSuite').hasError('required') && form.get('xAddr').get('xSuite').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Suite is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <small *ngIf="serverErrors['xAddr.xSuite']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="This field is not allowed to be empty"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>

              </small>
              <input type="text" class="form-control input-rounded" formControlName="xSuite" (focus)="clearErrors('xAddr.xSuite')"
                [ngClass]="{'has-error': (form.get('xAddr').get('xSuite').hasError('required') && form.get('xAddr').get('xSuite').touched) || serverErrors['xAddr.xSuite']}"
                validateOnBlur>
            </div>
            <div class="col-md-6 col-sm-12" formGroupName="xAddr">
                <label for="city">City:<small class="text-danger">*</small></label>
                <small *ngIf="!serverErrors['xAddr.xCity'] && form.get('xAddr').get('xCity').hasError('required') && form.get('xAddr').get('xCity').touched"
                  class="inline-error">
                  <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="City is required"
                    placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                </small>
  
                <small *ngIf="serverErrors['xAddr.xCity']" class="inline-error">
                  <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="This field is not allowed to be empty"
                    placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                </small>
                <input type="text" class="form-control input-rounded" formControlName="xCity" (focus)="clearErrors('xAddr.xCity')"
                  [ngClass]="{'has-error': (form.get('xAddr').get('xCity').hasError('required') && form.get('xAddr').get('xCity').touched) || serverErrors['xAddr.xCity']}"
                  validateOnBlur>
              </div>
            <div class="col-md-6 col-sm-12" formGroupName="xAddr">
              <label for="state">State: <small class="text-danger">*</small></label>
              <small *ngIf="!serverErrors['xAddr.xSOP'] && form.get('xAddr').get('xSOP').hasError('required') && form.get('xAddr').get('xSOP').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="State is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <small *ngIf="serverErrors['xAddr.xSOP']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="This field is not allowed to be empty"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <input type="text" class="form-control input-rounded" formControlName="xSOP" (focus)="clearErrors('xAddr.xSOP')"
                [ngClass]="{'has-error': (form.get('xAddr').get('xSOP').hasError('required') && form.get('xAddr').get('xSOP').touched) || serverErrors['xAddr.xSOP']}"
                validateOnBlur>
            </div>
            <div class="col-md-6 col-sm-12" formGroupName="xAddr">
              <label for="postal_code">Zip Code: <small class="text-danger">*</small> </label>
              <small *ngIf="!serverErrors['xAddr.xPostCode'] && form.get('xAddr').get('xPostCode').hasError('required') && form.get('xAddr').get('xPostCode').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Zip code is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <small *ngIf="serverErrors['xAddr.xPostCode']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="This field is not allowed to be empty"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <input type="text" class="form-control input-rounded" formControlName="xPostCode" (focus)="clearErrors('xAddr.xPostCode')"
                (keypress)="onKeypress($event,'postCode')" (paste)="onPaste($event, 'postCode')" [ngClass]="{'has-error': (form.get('xAddr').get('xPostCode').hasError('required') && form.get('xAddr').get('xPostCode').touched) || serverErrors['xAddr.xPostCode']}"
                validateOnBlur>
            </div>
            <!-- <div class="col-md-6 col-sm-12" formGroupName="xAddr">
              <label for="country">Country:<small class="text-danger">*</small></label>
              <small *ngIf="!serverErrors['xAddr.xCountry'] && form.get('xAddr').get('xCountry').hasError('required') && form.get('xAddr').get('xCountry').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Country is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <small *ngIf="serverErrors['xAddr.xCountry']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="This field is not allowed to be empty"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <input type="text" class="form-control input-rounded" formControlName="xCountry" (focus)="clearErrors('xAddr.xCountry')"
                [ngClass]="{'has-error': (form.get('xAddr').get('xCountry').hasError('required') && form.get('xAddr').get('xCountry').touched) || serverErrors['xAddr.xCountry']}"
                validateOnBlur>
            </div> -->
          </div>
        </div>
        <div class="customer-register-card">
          <div class="row">
            <div class="col-sm-12">
              <h3>Security</h3>
              <!-- <a class="btn btn-secondary btn-xs pull-right" (click)="showPreviewModal()">
                <i class="fa fa-eye" aria-hidden="true"></i> Preview Permissions
              </a> -->
            </div>
            <div class="col-md-6 col-sm-12" formGroupName="xUserInfo">
              <label for="username">Username:
                <small class="text-danger">*</small>
              </label>
              <small *ngIf="!serverErrors['xLoginUName'] && form.get( 'xUserInfo').get('xLoginUName').hasError('required') && form.get('xUserInfo').get('xLoginUName').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Username is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <small *ngIf="serverErrors['xLoginUName']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Username is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>

              <input type="text" class="form-control input-rounded" id="username" formControlName="xLoginUName"
                [ngClass]="{ 'has-error': (form.get('xUserInfo').get('xLoginUName').hasError('required') && form.get('xUserInfo').get('xLoginUName').touched) || serverErrors['xLoginUName']}"
                validateOnBlur>
            </div>
            <div class="col-md-6 col-sm-12" formGroupName="xUserInfo">
              <label for="password">Password:
                <small class="text-danger">*</small>
              </label>
              <small *ngIf="!serverErrors['xHashedPwd'] && form.get('xUserInfo').get('xHashedPwd').hasError('required') && form.get('xUserInfo').get('xHashedPwd').touched"
                class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Password is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <small *ngIf="serverErrors['xHashedPwd']" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Password is required"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <small *ngIf="!valid">
                <i class="fa fa-exclamation-circle" container="body" tooltip="Password must contain at least one upper case letter [A-Z], one lower case letter [a-z], one number [0-9] and one special character [*, #, $ ...]"
                  placement="top" container="body" containerClass="tooltip-global" aria-hidden="true"></i>
              </small>
              <input type="password" class="form-control input-rounded" id="password" formControlName="xHashedPwd"
                [ngClass]="{'has-error': ((form.get('xUserInfo').get('xHashedPwd').hasError('required') && form.get('xUserInfo').get('xHashedPwd').touched) || serverErrors['xHashedPwd']) || !valid}"
                (blur)="onBlurPasswordCheck($event)" validateOnBlur>
            </div>
            <!-- <div class="col-md-12 col-sm-12 row">
              <div class="col-md-9 col-xs-9">
                <label for="business_type" class="control-label">Roles:
                  <span></span>
                  <small class="text-danger">*</small>
                </label>
                <small *ngIf="serverErrors['xAccStores']" class="inline-error">
                  <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Roles is required" placement="top"
                    containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                </small>
                <div class="input-rounded no-margin" [ngClass]="{'has-error':serverErrors['xRoles']}">
                  <div *ngIf="roles.length">
                    <ul class="no-margin">
                      <ng-container *ngFor="let role of userAssociatedRolesArray">
                        <li *ngIf="role.isPresent">{{role.text}}</li>
                      </ng-container>
                    </ul>
                  </div>
                  <div class="" *ngIf="!roles.length">
                    <span class="span-select" [ngClass]="{'inline-danger-color':serverErrors['xRoles']}">
                      Please Add a role
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xs-3 " style="margin-top:42px">
                <button class="btn btn-secondary btn-sm btn-block" type="button" (click)="rolesModal.show()">Add / Edit</button>
              </div>
            </div> -->
          </div>
        </div>
        <div class="customer-register-card" formGroupName='xNotify'>
          <div class="row">
            <div class="col-sm-12">
              <h3>Notification Email:</h3>
            </div>
            <div class="col-md-12 col-sm-12">
              <!-- <label>Level 1: <small class="text-danger">*</small></label> -->
              <small *ngIf="l1IncorrectNotifyMailFormat" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Email format is not valid"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <div class="multi-select m-t-10">
                <tag-input inputId='xL1URLS' [formControlName]="'xL1URLs'" inputId='xL1URLS' [addOnBlur]="'true'" [separatorKeyCodes]="[32]" [ngClass]="{'has-error': l1IncorrectNotifyMailFormat}"
                  [placeholder]="'Add Email Ids'" [secondaryPlaceholder]="'Add Email Ids'" [editable]='true' (onAdd)="onItemAddedEditedOrRemoved($event)"
                  (onRemove)="onItemAddedEditedOrRemoved($event)" (onTagEdited)="onItemEdited($event,'xL1URLs')">
                </tag-input>
              </div>
            </div>
            <!-- <div class="col-md-6 col-sm-12">
              <label>Level 2: <small class="text-danger">*</small></label>
              <small *ngIf="l2IncorrectNotifyMailFormat" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Email format is not valid"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <div class="multi-select">
                <tag-input inputId='xL2URLS' [formControlName]="'xL2URLs'" inputId='xL2URLS' [addOnBlur]="'true'" [separatorKeyCodes]="[32]" [ngClass]="{'has-error': l2IncorrectNotifyMailFormat}"
                  [placeholder]="'Add Email Ids'" [secondaryPlaceholder]="'Add Email Ids'" [editable]='true' (onAdd)="onItemAddedEditedOrRemoved($event)"
                  (onRemove)="onItemAddedEditedOrRemoved($event)" (onTagEdited)="onItemEdited($event,'xL2URLs')">
                </tag-input>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <label>Level 3: <small class="text-danger">*</small></label>
              <small *ngIf="l3IncorrectNotifyMailFormat" class="inline-error">
                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Email format is not valid"
                  placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
              </small>
              <div class="multi-select">
                <tag-input inputId='xL3URLS' [formControlName]="'xL3URLs'" inputId='xL3URLS' [addOnBlur]="'true'" [separatorKeyCodes]="[32]" [ngClass]="{'has-error': l3IncorrectNotifyMailFormat}"
                  [placeholder]="'Add Email Ids'" [secondaryPlaceholder]="'Add Email Ids'" [editable]='true' (onAdd)="onItemAddedEditedOrRemoved($event)"
                  (onRemove)="onItemAddedEditedOrRemoved($event)" (onTagEdited)="onItemEdited($event,'xL3URLs')">
                </tag-input>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="clearfix">
        <div class="pull-left">
          <span class="register-text">Already have an account? </span>
          <a (click)="navigateToCustomerLogin()"> Login </a>
        </div>
        <button type="submit" class="btn btn-secondary btn-round btn-lg pull-right" [disabled]="submitting || dontSubmit">
          <span *ngIf="!submitting"> Register
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
          </span>
          <span *ngIf="submitting">Please wait...
            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </form>
    <br /><br />
  </div>
</div>

<!-- <div bsModal #rolesModal="bs-modal" class="modal fade modal-role" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true" (onHide)="abortRoleModal()">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="rolesModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Add New / Edit rolesArray</h4>
      </div>
      <form class="form-horizontal" [formGroup]="form" (ngSubmit)="eidtRoles()" novalidate>
        <div class="">
          <div class="row">
            <div class="col-8 p-r-0 split">
              <small class="modal-label">Assigned Roles</small>
              <div class="form-group no-margin added-roles">
                <ul>
                  <ng-container *ngFor="let role of rolesArray">
                    <li *ngIf="role.isPresent" class="active" [class.selected-row]="role.id===selectedRoleID">
                      <label class="checkbox1">
                        <input type="checkbox" name="status" class="checkbox" [checked]="role.status" (change)="role.status=!role.status">
                        <span></span>
                      </label>
                      <a (click)="getPermissions(role.id, role.text)">
                        <span class="span-text">{{role.text}}</span>
                        <button *ngIf="role.isPresent" class="btn btn-secondary add-btn delete-btn pull-right" type="button"
                          (click)="role.isPresent=false">
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </div>
              <small class="modal-label span-sibling">Unassigned Roles</small>
              <div class="added-roles">
                <ul class="all-roles">
                  <ng-container *ngFor="let role of rolesArray">
                    <li *ngIf="!role.isPresent" [class.selected-row]="role.id===selectedRoleID">
                      <label class="checkbox1">
                        <input type="checkbox" name="status" class="checkbox" [checked]="role.status" (change)="role.status=!role.status">
                        <span></span>
                      </label>
                      <a (click)="getPermissions(role.id, role.text)">
                        <span class="span-text">{{role.text}}</span>
                        <button *ngIf="!role.isPresent" class="btn btn-secondary add-btn pull-right" type="button"
                          (click)="role.isPresent=true">
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <div class="col-4 p-l-0" *ngIf="showPermsDiv">
              <div class="store-mgr">
                <b>
                  <label>Role: </label> {{selectedRoleName}}</b>
                <div class="form-group no-margin">
                  <div>
                    <div class="store-mgr-scroll">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th>Module</th>
                            <th>Function</th>
                          </tr>
                        </thead>
                        <tbody *ngIf="!loadingRoleAssociatedPerm">
                          <ng-container *ngFor="let perm of roleAssociatedPermisionsList">
                            <tr>
                              <td>
                                {{perm.xMduTag}}
                              </td>
                              <td>
                                {{perm.xFuncTag}}
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                        <tbody *ngIf="loadingRoleAssociatedPerm">
                          <tr>
                            <td style="text-align: center;" [colSpan]="2">
                              <div class="grid-spinner">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                              </div>
                              <span style="color:#3b5877">Fetching Permissions</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="add-edit-btn">
                      <button class="btn btn-sm btn-secondary" type="button" (click)="showPermissionModal()">Add/Edit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 text-center p-l-0" *ngIf="!showPermsDiv">
              <div class="empty-store-mgr">
                <img src="../../../../assets/images/modal-empty.png">
                <p>Click on roles to check their permissions. </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-sm-12 clearfix">
              <button class="btn btn btn-primary pull-right">Save
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div> -->

<!-- <div *ngIf="displayPermissionModalCmp">
  <permission-modal-cmp [selectedRoleID]=selectedRoleID (onCloseModal)="permissionModalClosed($event)"></permission-modal-cmp>
</div> -->

<!-- <div *ngIf="displayPreviewPermissionModalCmp">
  <preview-permission-modal-cmp [roleIDs]=finalRoleIDs (onCloseModal)="previewPermissionModalClosed($event)"></preview-permission-modal-cmp>
</div> -->
<!-- </div>
</div> -->