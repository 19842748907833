export class MainContact {
    xEmail: string;
    xEmailTypeTag:string;
    xPhone: string;
    xPhoneTypeTag:string;

    constructor(obj?: any) {
        this.xEmail = (obj && obj.xEmail) || '';
        this.xEmailTypeTag = (obj && obj.xEmailTypeTag) || '';
        this.xPhone = (obj && obj.xPhone) || '';
        this.xPhoneTypeTag = (obj && obj.xPhoneTypeTag) || '';
    }
}
