import {
    Component,
    OnInit,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    SimpleChanges
} from '@angular/core';
import {
    FormGroup,
    FormBuilder
} from '@angular/forms';
import { XpaConstsService, ConstantService } from '../../../services';
import { Observable, forkJoin } from 'rxjs';

const SOURCE_ARR = [
    {value:'vTerm',tag:'vTerminal'},
    {value:'webApp',tag:'WebApp'},
    {value:'POS',tag:'POS'}
];

@Component({
    selector: 'transaction-advanced-search',
    templateUrl: './transaction-advanced-search.component.html',
    styleUrls: ['./transaction-advanced-search.component.scss']
})
export class TransactionAdvancedSearchComponent implements OnInit, OnChanges {
    @Input() resetFilterForm: boolean = false;
    @Input() advancedFilterFormData: any = {};
    @Output() advanceSearchEvent = new EventEmitter<any>();
    searchParams: FormGroup;
    brandType: any[] = [];
    selectedCardBrand: any[] = [];
    selectedCardType: any[] = [];
    selectedSource: boolean = false;
    cardType: any[] = [];
    loading: boolean = true;
    SOURCE_ARR: any[] = SOURCE_ARR;
    constructor(
        public fb: FormBuilder,
        public xpaConstsService: XpaConstsService,
        private constantService: ConstantService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        // if(changes.hasOwnProperty("advancedFilterFormData")){
        //   if(Object.keys(this.advancedFilterFormData).length){
        // console.log("HERE", this.advancedFilterFormData)
        //     this.generateForm(this.advancedFilterFormData);
        //   }
        // }
        if (changes.hasOwnProperty('resetFilterForm')) {
            // brandType: any[] = [];
            if (this.resetFilterForm) {
                this.selectedCardBrand = [];
                this.selectedCardType = [];
                this.selectedSource = false;
                this.advancedFilterFormData = {};
                // cardType: any[] = [];
                this.generateForm({});
            }
        }
    }

    ngOnInit() {
        // Object.keys(TAGSCONSTS.brandTags.brandItemTags).map(key=>{
        //   this.brandType.push({
        //     value: TYPES.brandItemTags[key],
        //     tag: TAGSCONSTS.brandTags.brandItemTags[key]
        //   })
        // });
        // Object.keys(TAGSCONSTS.mediumTags.mdmItemTags).map(key=>{
        //   this.cardType.push({
        //     value: TYPES.mdmItemTags[key],
        //     tag: TAGSCONSTS.mediumTags.mdmItemTags[key]
        //   })
        // });
        // console.log("HERE 345", this.advancedFilterFormData);
        if (Object.keys(this.advancedFilterFormData).length) {
            // console.log("HERE 345", this.advancedFilterFormData)
            this.generateForm(this.advancedFilterFormData);
        } else {
            this.generateForm({});
        }
    }

    generateForm(data: any) {
        // console.log("data inside generateForm", JSON.parse(JSON.stringify(data)));
        this.searchParams = this.fb.group({
            txnNum: [data.txnNum || '', []],
            cardMdm: [data.cardMdm || '', []],
            card_lastFourDigits: [data.card_lastFourDigits || '', []],
            card_firstSixDigits: [data.card_firstSixDigits || '', []],
            minTotAmt: [data.minTotAmt || '', []],
            maxTotAmt: [data.maxTotAmt || '', []],
            cardBrand: [data.cardBrand || '', []],
            authCode: [data.authCode || '', []],
            invoiceNum: [data.invoiceNum || '', []],
            source: [data.source || '', []]
        });
        this.searchParams.get('cardMdm').disable();
        this.searchParams.get('cardBrand').disable();
        let observable = [
            this.xpaConstsService
                .getXpaItems('brand'),
            this.xpaConstsService.getXpaItems('mdm')
        ];
        this.brandType = [];
        this.cardType = [];
        forkJoin(observable).subscribe(
            (res: any) => {
                if (res.length) {
                    console.log("generateForm:REsponse", res);
                    let sortedData = this.constantService.sortCompleteData(
                        res[0].data,
                        'asc',
                        'xName',
                        false
                    );
                    sortedData.map(brand => {
                        if (brand.xStatus) {
                            this.brandType.push({
                                value: brand.xName,
                                tag: brand.xTag
                            });
                        }
                    });
                    sortedData = this.constantService.sortCompleteData(
                        res[1].data,
                        'asc',
                        'xName',
                        false
                    );
                    sortedData.map(cType => {
                        if (cType.xStatus) {
                            this.cardType.push({
                                value: cType.xName,
                                tag: cType.xTag
                            });
                        }
                    });
                    // this.disableSelectBox=false;
                    this.searchParams.get('cardMdm').enable();
                    this.searchParams.get('cardBrand').enable();
                }
            },
            err => {
                this.searchParams.get('cardMdm').disable();
                this.searchParams.get('cardBrand').disable();
            }
        );
        // console.log(
        //     "brand and card types",
        //     JSON.parse(JSON.stringify(this.brandType)),
        //     JSON.parse(JSON.stringify(this.cardType))
        // );
        this.loading = false;
    }

    onSubmit(data) {
        if (data.value) {
            console.log('data', JSON.parse(JSON.stringify(data.value)));
            this.advanceSearchEvent.emit(data.value);
        }
    }
}
