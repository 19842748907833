import { Component, OnInit } from '@angular/core';
import { UserHandlerService, LoginStoreService, HttpService, NotificationService, BusinessService } from '../../shared/services/index';
import { FormGroup, FormBuilder } from '@angular/forms';

// import { VTERM_HOST } from '../../shared/config/vTerm.config';
import { VtermConfig } from '../../shared/config/vTerm.config';
import { ACCT_TYPE_MAPPING } from 'app/constants/mapping.constants';
import {
	setDataForTokenCreation,
	validatePgComFields,
	SETUP_ALTERED_MESSAGE,
	CC_TERM_NUM_KEY,
	ACH_TERM_NUM_KEY
} from '../common-functions/validate-txn-terminals';
import { TAGSCONSTS } from 'app/constants';
import { truncateString, validateFor } from '../../common-functions/miscellaneous.function';
import {
	setPaymentResponse,
	setCreatePaymentResponse,
	PAYMENT_MODE_PAYNOW,
	PAYMENT_MODE_CREATE_PAYMENT,
	transactionType,
	SUCCESS
} from '../../../app/common-functions/process-token-data.function';

//App constants
const FAIL: string = 'fail';
const MEDIUM_CREDIT: string = 'Credit';
const MEDIUM_ELECTRONIC_CHECK: string = 'ElectronicCheck';

//API endpoints
const VTERM_DECRYPT_URL: string = 'vTerm/decrypt';
const VTERM_ENCRYPT_URL: string = 'vTerm/encrypt';
const VTERM_INFO_ENDPOINT = 'vTerm_info';

const MANAGE_ACCOUNTS_PATH = '/manage-accounts/';
const URL_PARAM_PATH = '?param=';

//Message constants
const INVALID_TOKEN_MESSAGE = 'Invalid Token.';
const PAYMENT_METHOD_SUCCESS_MSG = 'Payment Method has been registerd successfully';
const PAYMENT_METHOD_FAILED_TO_REGISTER_ERROR_MESSAGE: string = 'Payment Method failed to register.';

//BE fields
const BE_MERCHANT_CAPABILITIES_KEY = 'merchantCapabilities';
const ZIP_CODE_KEY = 'zipCode';
const MEMBER_NUMBER_KEY = 'memberNumber';
const EMAIL_KEY = 'email';
const PHONE_KEY = 'phone';
const ADDRESS_KEY = 'address';
const CITY_KEY = 'city';
const STATE_KEY = 'state';
const COUNTRY_KEY = 'country';
const COMPANY_KEY = 'company';
const FIRST_NAME_KEY = 'firstName';
const LAST_NAME_KEY = 'lastName';

//TODO: Move to higher lever db keys.
const BE_VTERM_TOKEN_KEY = 'vTermToken';
const BE_REQUEST_PAYLOAD_KEY = 'requestPayload';
const BE_PAYMENT_MODE_KEY = 'paymentMode';
const BE_DATA_KEY = 'data';
//DB fields
const DB_XNAME_KEY = 'xName';
const DB_XSIGNUP_PCTRID_KEY = 'xSignupPCtrID';
const DB_MONGO_ID_KEY = '_id';
const DB_ADDRESS_KEY = 'xAddr';
const DB_LINE1_KEY = 'xLine1';
const DB_LINE2_KEY = 'xLine2';
const DB_CITY_KEY = 'xCity';
const DB_COUNTRY_KEY = 'xCountry';
const DB_XSOP_KEY = 'xSOP';
const DB_POST_CODE_KEY = 'xPostCode';
const DB_XCOMPANY_NAME_KEY = 'xCompanyName';
const DB_XCUSTOMER_NUM_KEY = 'xCustomerNum';
const DB_XFORMAL_NAME_KEY = 'xFormalName';
const DB_XFORE_NAME_KEY = 'xForename';
const DB_XSUR_NAME_KEY = 'xSurname';
const DB_XCONTACT_KEY = 'xContact';
const DB_XEMAIL_KEY = 'xEmail';
const DB_XPHONE_KEY = 'xPhone'


const BUSINESS_DATA_KEY = 'businessData';

const ACH_PREFIX_REQUIRED_MESSAGE = 'ACH Transactions Require ACH Prefix for Merchant Account (contact support).';
const CC_PREFIX_REQUIRED_MESSAGE = 'Credit Card Transactions Require CC Prefix for Merchant Account (contact support).';
const ACH_OR_CC_PREFIX_REQUIRED_MESSAGE =
	'Credit Card or ACH Transactions Require CC or ACH Prefix for Merchant Account (contact support).';
const TERMINAL_FETCH_FAIL_MESSAGE = 'Could not fetch Terminal. Please try again.';
const NO_TERMINALIDS_FOUND_MESSAGE = 'No CC or ACH Terminal IDs found. Routing back to pay-now page.';
const UNABLE_TO_ROUTE_MSG = 'Unable to route to vterminal.';
const UNABLE_TO_PROCEED_ERROR_MESSAGE = 'Unable to proceed. Please try again.';

const TRUNCATE_LENGTH = 27;

@Component({
	selector: 'app-pay-now',
	templateUrl: './pay-now.component.html',
	styleUrls: ['./pay-now.component.scss']
})
export class PayNowComponent implements OnInit {
	// constant variables
	public PAYMENT_MODE_PAYNOW: string = PAYMENT_MODE_PAYNOW;
	public PAYMENT_MODE_CREATE_PAYMENT: string = PAYMENT_MODE_CREATE_PAYMENT;
	public SUCCESS: string = SUCCESS;
	public FAIL: string = FAIL;
	public MEDIUM_CREDIT: string = MEDIUM_CREDIT;
	public MEDIUM_ELECTRONIC_CHECK: string = MEDIUM_ELECTRONIC_CHECK;
	public transactionType = transactionType;
	public PAYMENT_METHOD_FAILED_TO_REGISTER_ERROR_MESSAGE: string = PAYMENT_METHOD_FAILED_TO_REGISTER_ERROR_MESSAGE; 

	//logic variables
	public userData: any = {};
	public terminalData: any = {};
	public storeData: any = {};
	public entityData: any = {};
	public form: FormGroup;
	public lastPaymentMethod: any = {};
	public currentPaymentMethod: any = {};
	public paymentMethod: FormGroup;
	public paymentMethodOptions: any = [];
	public yearArr: number[] = [];
	public createPayment: FormGroup;
	public isSubmitMode: boolean = false;
	public submitModeData: any = {};
	public showResponseAlert: any = {};
	public isPaymentDone: boolean = false;
	data: any = {};
	public response: any = {};
	public pCtrData: any = {};
	public paymentMode: string = ''; //payNow or createPayment
	public token: string;
	public paymentResponse: any = {}; //If payment-method is pay-now, set response to this variable
	public showSuccessMsgPaymentMethod: boolean = false; //Show/Hide payment-method success message
	public paymentMethodSuccessMsg: string = ''; //If payment-method is created successfully, show message through this variable
	public createPaymentResponse: any = {}; //If payment-method is create-payment, set response to this variable
	public showPaymentInfo: boolean = false; //If payment-method is pay-now, show/hide response
	public showCreatePaymentInfo: boolean = false; //If payment-method is create-payment, show/hide response
	public showpaymentMethodFailureMsg: boolean = false; //If payment-method is create-payment, show/hide failure message
	public paymentMethodFailureMsg: string = ''; //If payment-method is failed, failure message is shown from this variable
	public shouldDisableBtn: boolean = false; //On routing action, disable all buttons
	public payNowBtnLoader: boolean = false; //On click of pay-now button, show spinner on pay-now button
	public manageAcctsBtnLoader: boolean = false; //On click of manage-accounts button, show spinner on manage-accounts button
	public tokenDecryptionLoader: boolean = false; //show spinner when token is being decrypted

	//Terminal validation variables
	public terminalNumsObj: any = {}; //Terminal nums from route
	public ccTerminalData: any = {}; //Terminal whose parent mer-acct name has CC prefix
	public achTerminalData: any = {}; //Terminal whose parent mer-acct name has ACH prefix
	public hasCCTerminal: boolean = false; //Check if CC terminal is empty
	public hasACHTerminal: boolean = false; //Check if ACH terminal is empty
	// public parentMerchantAcct: any = {};
	private VTERM_HOST: string = '';
	//Error message for payment-method failure in saving one-time payment method and creating new payment-method
	public paymentMethodErrorMessage: string = '';

	//function substitution
	truncateString = truncateString;
	public TRUNCATE_LENGTH: number = TRUNCATE_LENGTH;

	constructor(
		private userHandlerService: UserHandlerService,
		private loginStoreService: LoginStoreService,
		private httpService: HttpService,
		public formBuilder: FormBuilder,
		private notificationService: NotificationService,
		private vtermConfig: VtermConfig,
		private businessService: BusinessService
	) {
		this.VTERM_HOST = vtermConfig.VTERM_HOST;
		console.log('vtermConfig: host', this.VTERM_HOST);
	}

	ngOnInit() {
		this.terminalData =
			this.loginStoreService.terminalData && Object.keys(this.loginStoreService.terminalData).length
				? this.loginStoreService.terminalData
				: {};
		this.storeData =
			this.loginStoreService.storeData && Object.keys(this.loginStoreService.storeData).length
				? this.loginStoreService.storeData
				: {};
		this.userData =
			this.userHandlerService.userData && Object.keys(this.userHandlerService.userData).length
				? this.userHandlerService.userData
				: {};
		this.entityData =
			this.userHandlerService.userType && Object.keys(this.userHandlerService.userType).length
				? this.userHandlerService.userType
				: {};

		this.currentPaymentMethod = this.lastPaymentMethod && Object.keys(this.lastPaymentMethod).length ? this.lastPaymentMethod : {};

		this.pCtrData =
			this.loginStoreService.pctrData && Object.keys(this.loginStoreService.pctrData).length ? this.loginStoreService.pctrData : {};

		this.terminalNumsObj =
			this.loginStoreService.terminalNumbersObj && Object.keys(this.loginStoreService.terminalNumbersObj).length
				? this.loginStoreService.terminalNumbersObj
				: {};

		//set ACH and CC terminals by comparing CC/ACH terminalNum from loginStoreService
		if (validateFor(CC_TERM_NUM_KEY, this.terminalNumsObj)) {
			console.log('has cc terminal');
			if(24 === this.terminalNumsObj[CC_TERM_NUM_KEY].length){
				this.ccTerminalData = this.terminalData.find(terminal => terminal._id === this.terminalNumsObj[CC_TERM_NUM_KEY]);	
			}else{
				this.ccTerminalData = this.terminalData.find(terminal => terminal.xTerminalNum === this.terminalNumsObj[CC_TERM_NUM_KEY]);
			}
			console.log('cc terminal', this.ccTerminalData);
		}

		if (validateFor(ACH_TERM_NUM_KEY, this.terminalNumsObj)) {
			console.log('has ach terminal');
			if(24 === this.terminalNumsObj[CC_TERM_NUM_KEY].length){
				this.achTerminalData = this.terminalData.find(terminal => terminal._id === this.terminalNumsObj[ACH_TERM_NUM_KEY]);	
			}else{
				this.achTerminalData = this.terminalData.find(terminal => terminal.xTerminalNum === this.terminalNumsObj[ACH_TERM_NUM_KEY]);
			}
			console.log('ach terminal', this.achTerminalData);
		}

		this.hasACHTerminal = this.validateObject(this.achTerminalData);
		this.hasCCTerminal = this.validateObject(this.ccTerminalData);

		console.log('login store service---->', this.loginStoreService);
		console.log('entitydata', this.entityData);
		console.log('userData', this.userData);
		console.log('pctrData', this.pCtrData);
		console.log('storeData', this.storeData);
		console.log('terminaldata', this.terminalData);
		console.log('pay-now: init: token', sessionStorage.getItem('token'));
		console.log('terminalNums', this.terminalNumsObj);
		let url = document.location.href;
		url = decodeURI(url);
		let index = url.indexOf('param');
		console.log('index', index);
		//If index of param is not found, run in normal mode and dont set reponse functions
		if (index > 0) {
			this.token = url.substring(index + 6);
			if (!this.token) {
				this.showErrorNotification(INVALID_TOKEN_MESSAGE);
				return;
			}
			this.checkModeAndSetResponse();
		}
	}

	//Check for mode pay-now or create-payment, call function to set corresponding response
	checkModeAndSetResponse() {
		this.tokenDecryptionLoader = true;
		this.httpService.store(VTERM_DECRYPT_URL, { vTermToken: this.token }).subscribe(tokenDecryptionResponse => {
			console.log('res: onInit: pay-now', JSON.parse(JSON.stringify(tokenDecryptionResponse)));
			this.tokenDecryptionLoader = false;
			if (!tokenDecryptionResponse || !tokenDecryptionResponse.success) {
				return;
			}
			let tokenData: any = {};
			if (validateFor(BE_DATA_KEY, tokenDecryptionResponse) && validateFor(BE_REQUEST_PAYLOAD_KEY, tokenDecryptionResponse[BE_DATA_KEY])) {
				tokenData = tokenDecryptionResponse[BE_DATA_KEY];
				this.paymentMode =
					validateFor(BE_PAYMENT_MODE_KEY, tokenData[BE_REQUEST_PAYLOAD_KEY])
						? tokenData[BE_REQUEST_PAYLOAD_KEY][BE_PAYMENT_MODE_KEY]
						: null;
				console.log('paymentMode: decoded from token: paynow--->', this.paymentMode);
			} else {
				console.log('got here through cancel');
			}

			switch (this.paymentMode) {
				case PAYMENT_MODE_PAYNOW:
					this.paymentResponse = setPaymentResponse(tokenData);
					break;
				case PAYMENT_MODE_CREATE_PAYMENT:
					this.createPaymentResponse = setCreatePaymentResponse(tokenData);
					this.paymentMethodErrorMessage = this.createPaymentResponse.paymentMethodFailureMsg;
					break;
				case null:
					this.showErrorNotification(INVALID_TOKEN_MESSAGE);
					break;
			}
			console.log('checkModeAndSetResponse: data', tokenData);
			console.log('checkModeAndSetResponse: paymentResponse--->', JSON.parse(JSON.stringify(this.paymentResponse)));
			console.log('checkModeAndSetResponse: createPaymentResponse--->', JSON.parse(JSON.stringify(this.createPaymentResponse)));
		}, tokenDecryptionError => {
			console.log('tokenDecryptionError: checkModeAndSetResponse', tokenDecryptionError)
			this.tokenDecryptionLoader = false;
			this.showErrorNotification(INVALID_TOKEN_MESSAGE);
		});
	}

	showErrorNotification(message) {
		this.notificationService.error(message, 'Error');
	}

	/**
	 * Set data object with paymentMode, authToken and billingAcctInfo which is then encrypted. Call setData function to set pctr, store and userData
	 * @param: paymentMode: string : payNow/createPayment
	 */
	setPayload(paymentMode: string) {
		let payloadData = {};
		payloadData['pCtrRecord'] = Object.keys(this.pCtrData).length ? this.pCtrData : {};
		payloadData['storeRecord'] = Object.keys(this.storeData).length ? this.storeData : {};
		let bizName = validateFor(DB_XNAME_KEY, this.businessService[BUSINESS_DATA_KEY])
			? this.businessService[BUSINESS_DATA_KEY][DB_XNAME_KEY]
			: '';
		console.log('bizName: fetchVtermInfo', bizName);
		console.log('res obj out of pctr and store data: setPayload----->', payloadData);
		console.log('hasACHTerminal: setPayload----->', this.hasACHTerminal);
		console.log('hasCCTerminal: setPayload----->', this.hasCCTerminal);

		//In dealer mode only CC terminal is present. So ACH is kept as optional and need not be passed.
		//When Only ACH terminal is present (Possible in customer mode), CC terminal is passed as empty object.
		//If both CC and ACH terminals are present, pass both terminals, else pass the one which is present.
		this.data =
			this.hasACHTerminal && this.hasCCTerminal
				? setDataForTokenCreation(
						bizName,
						payloadData,
						this.userData,
						TAGSCONSTS.roleTags.roleItemTags.customerRole,
						this.ccTerminalData,
						this.achTerminalData
				  )
				: this.hasACHTerminal
				? setDataForTokenCreation(
						bizName,
						payloadData,
						this.userData,
						TAGSCONSTS.roleTags.roleItemTags.customerRole,
						{},
						this.achTerminalData
				  )
				: this.hasCCTerminal
				? setDataForTokenCreation(
						bizName,
						payloadData,
						this.userData,
						TAGSCONSTS.roleTags.roleItemTags.customerRole,
						this.ccTerminalData
				  )
				: {};

		console.log('data after set from setData function', this.data);
		
		
        //TODO -: Modify this after merge into phase-5
		this.data['authToken'] = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null;

		this.data['paymentMode'] = paymentMode ? paymentMode : null;

		this.data['billingAccountInfo'] = this.setBillingAcctInfoForPayload();
		console.log('setPayload: pay-now: data', this.data);
	}

	/**
	 * Set billing-account info in data which is to be encrypted
	 */
	setBillingAcctInfoForPayload(): object {
		let billingAccountInfo = {};
		billingAccountInfo[DB_MONGO_ID_KEY] = 
			validateFor(DB_MONGO_ID_KEY, this.entityData) ? this.entityData[DB_MONGO_ID_KEY] : '';
		billingAccountInfo[MEMBER_NUMBER_KEY] = 
			validateFor(DB_XCUSTOMER_NUM_KEY, this.entityData) ? this.entityData[DB_XCUSTOMER_NUM_KEY]: '';
		billingAccountInfo[FIRST_NAME_KEY] = 
			validateFor(DB_XFORMAL_NAME_KEY, this.entityData) 
			&& validateFor(DB_XFORE_NAME_KEY, this.entityData[DB_XFORMAL_NAME_KEY]) 
			? this.entityData[DB_XFORMAL_NAME_KEY][DB_XFORE_NAME_KEY]: '';
		billingAccountInfo[LAST_NAME_KEY] = validateFor(DB_XFORMAL_NAME_KEY, this.entityData) 
			&& validateFor(DB_XSUR_NAME_KEY, this.entityData[DB_XFORMAL_NAME_KEY]) 
			? this.entityData[DB_XFORMAL_NAME_KEY][DB_XSUR_NAME_KEY]: '';
		billingAccountInfo[PHONE_KEY] = validateFor(DB_XCONTACT_KEY, this.entityData) && validateFor(DB_XPHONE_KEY, this.entityData[DB_XCONTACT_KEY]) 
		? this.entityData[DB_XCONTACT_KEY][DB_XPHONE_KEY]: '';
		billingAccountInfo[EMAIL_KEY] = 
			validateFor(DB_XCONTACT_KEY, this.entityData) && validateFor(DB_XEMAIL_KEY, this.entityData[DB_XCONTACT_KEY]) 
			? this.entityData[DB_XCONTACT_KEY][DB_XEMAIL_KEY]: '';
		if(validateFor(DB_ADDRESS_KEY, this.entityData)){
			let line1 = 
					validateFor(DB_LINE1_KEY, this.entityData[DB_ADDRESS_KEY]) 
					? this.entityData[DB_ADDRESS_KEY][DB_LINE1_KEY]: '';
			let line2 =
					validateFor(DB_LINE2_KEY, this.entityData[DB_ADDRESS_KEY]) 
					? this.entityData[DB_ADDRESS_KEY][DB_LINE2_KEY]: '';
			billingAccountInfo[ADDRESS_KEY] = line1 + line2;
			billingAccountInfo[CITY_KEY] = 
				validateFor(DB_CITY_KEY, this.entityData[DB_ADDRESS_KEY]) 
				? this.entityData[DB_ADDRESS_KEY][DB_CITY_KEY]: '';
			billingAccountInfo[STATE_KEY] = 
				validateFor(DB_XSOP_KEY, this.entityData[DB_ADDRESS_KEY]) 
				? this.entityData[DB_ADDRESS_KEY][DB_XSOP_KEY]: '';
			billingAccountInfo[ZIP_CODE_KEY] =
				validateFor(DB_POST_CODE_KEY, this.entityData[DB_ADDRESS_KEY]) 
				? this.entityData[DB_ADDRESS_KEY][DB_POST_CODE_KEY]: '';
			billingAccountInfo[COUNTRY_KEY] = 
				validateFor(DB_COUNTRY_KEY, this.entityData[DB_ADDRESS_KEY]) 
				? this.entityData[DB_ADDRESS_KEY][DB_COUNTRY_KEY]: '';
			}
		billingAccountInfo[COMPANY_KEY] = validateFor(DB_XCOMPANY_NAME_KEY, this.entityData) ? this.entityData[DB_XCOMPANY_NAME_KEY] : '';
		console.log('billingAccountInfo: setBillingAcctInfoForPayload', billingAccountInfo);
		return billingAccountInfo;
	}

	async onProceed(paymentMode: string) {
		this.shouldDisableBtn = true;
		this.paymentMode = paymentMode;
		if (paymentMode === PAYMENT_MODE_PAYNOW) {

			this.payNowBtnLoader = true;

			let shouldValidateCCTerminal = false;
			let shouldValidateACHTerminal = false;

			if (this.hasCCTerminal) {
				shouldValidateCCTerminal = true;
			}
			if (this.hasACHTerminal) {
				shouldValidateACHTerminal = true;
			}

			try {
				let canProceed = await this.validateTerminals(shouldValidateCCTerminal, shouldValidateACHTerminal);
				console.log('canProceed---->', canProceed);
				if (!canProceed) {
					if (shouldValidateCCTerminal && shouldValidateACHTerminal) {
						console.log('both terminals are invalid');
						this.showInvalidMerAcctSetupNotification(ACH_OR_CC_PREFIX_REQUIRED_MESSAGE);
					} else if (shouldValidateACHTerminal) {
						console.log('ach is invalid');
						this.showInvalidMerAcctSetupNotification(ACH_PREFIX_REQUIRED_MESSAGE);
					} else if (shouldValidateCCTerminal) {
						console.log('cc is invalid');
						this.showInvalidMerAcctSetupNotification(CC_PREFIX_REQUIRED_MESSAGE);
					}
					this.manageAcctsBtnLoader = false;
					this.payNowBtnLoader = false;
					this.shouldDisableBtn = false;
					return;
				}
			} catch (error) {
				this.shouldDisableBtn = false;
				this.manageAcctsBtnLoader = false;
				this.payNowBtnLoader = false;
				this.showInvalidMerAcctSetupNotification(TERMINAL_FETCH_FAIL_MESSAGE);
				return;
			}
		}else{
			this.manageAcctsBtnLoader = true;	
		}
		this.setPayload(paymentMode);

		if (!validatePgComFields(this.data)) {
			this.shouldDisableBtn = false;
			this.manageAcctsBtnLoader = false;
			this.payNowBtnLoader = false;
			this.notificationService.error(SETUP_ALTERED_MESSAGE, 'Error');
			return;
		}
		console.log(this.data, 'data: onProceed');
		this.httpService.store(VTERM_ENCRYPT_URL, this.data).subscribe(encryptionResponse => {
			console.log('I got response token', encryptionResponse);
			if (!encryptionResponse.success || !validateFor(BE_VTERM_TOKEN_KEY, encryptionResponse.data)) {
				this.encryptionFailHandler();
				return;
			}
			switch (paymentMode) {
				case PAYMENT_MODE_PAYNOW:
					document.location.href = this.VTERM_HOST + URL_PARAM_PATH + encryptionResponse.data.vTermToken;
					break;
				case PAYMENT_MODE_CREATE_PAYMENT:
					document.location.href = this.VTERM_HOST + MANAGE_ACCOUNTS_PATH + encryptionResponse.data.vTermToken;
					break;
				default:
					console.log('onProceed: default case');
					this.notificationService.error(UNABLE_TO_ROUTE_MSG, 'Error');
			}
		}, encryptionError => {
			console.log('encryptionError: onProceed', encryptionError);
			this.encryptionFailHandler()
		});
	}

	/**
	* Set button loaders to false and show error notification.
	*/
	encryptionFailHandler(){
		this.shouldDisableBtn = false;
		this.manageAcctsBtnLoader = false;
		this.payNowBtnLoader = false;
		this.notificationService.error(UNABLE_TO_PROCEED_ERROR_MESSAGE, 'Error');
	}

	showInvalidMerAcctSetupNotification(message: string) {
		this.notificationService.error(message, 'Error');
	}

	/**
	 * Validate if mer-acct setup has been changed corresponding to terminal
	 * CASES: 1. Both ACH and CC terminals are present but both permissions are revoked (Invalid)
	 * 			 Both ACH and CC terminals are present but ACH permission is revoked (Valid)
	 * 			 Both ACH and CC terminals are present but CC permission is revoked (Valid)
	 * 		  2. Only CC terminal is present but permission is revoked (Invalid)
	 * 	      3. Only ACH terminal is present but permission is revoked (Invalid)
	 * @param `doValidateCCTerminal`: boolean
	 * @param `doValidateACHTerminal`: boolean
	 * @returns boolean
	 */
	validateTerminals(doValidateCCTerminal, doValidateACHTerminal): Promise<boolean> {
		console.log('validateCCTerminal', doValidateCCTerminal, 'validateACHTerminal', doValidateACHTerminal);
		return new Promise((resolve, reject) => {
			console.log('validateTerminals: response', this.response);
			let terminalIDs = '';
			let ccTerminalID = validateFor('_id', this.ccTerminalData) ? this.ccTerminalData._id : '';
			let achTerminalID = validateFor('_id', this.achTerminalData) ? this.achTerminalData._id : '';
			//No else case as either cc or ach or both must be present
			if (!ccTerminalID && !achTerminalID) {
				this.notificationService.error(NO_TERMINALIDS_FOUND_MESSAGE, 'Error');
				return;
			}
			if (doValidateCCTerminal && doValidateACHTerminal) {
				terminalIDs = ccTerminalID + ',' + achTerminalID;
			} else if (doValidateACHTerminal && achTerminalID) {
				terminalIDs = achTerminalID;
			} else if (doValidateCCTerminal && ccTerminalID) {
				terminalIDs = ccTerminalID;
			}
			console.log('terminalIDs: validateTerminals--->', terminalIDs);
			let validateTxnTerminalsParams = {
				terminalID: terminalIDs
			};
			console.log('validateTxnTerminalsParams: validateTerminals--->', validateTxnTerminalsParams);
			this.httpService.getAll(VTERM_INFO_ENDPOINT, validateTxnTerminalsParams).subscribe(
				vtermInfoResponse => {
					console.log('validate txn terminals api response: validateTerminals ---->', vtermInfoResponse);
					if (!vtermInfoResponse || !validateFor('data', vtermInfoResponse)) {
						return reject(false);
					}
					let vTermInfoData = vtermInfoResponse.data;
					let terminalRecords =
						validateFor('terminalRecords', vTermInfoData) && vTermInfoData.terminalRecords.length
							? vTermInfoData.terminalRecords
							: [];
					//Reset data for token creation from vtermInfo to avoid stale data
					this.pCtrData = validateFor('pCtrRecord', vTermInfoData) ? vTermInfoData.pCtrRecord : {};
					this.storeData = validateFor('storeRecord', vTermInfoData) ? vTermInfoData.storeRecord : {};
					this.ccTerminalData = terminalRecords.length
						? terminalRecords.find(terminalData => terminalData._id === ccTerminalID)
						: {};
					this.achTerminalData = terminalRecords.length
						? terminalRecords.find(terminalData => terminalData._id === achTerminalID)
						: {};

					console.log('ccTerminalData from vterminfo response--->', this.ccTerminalData);
					console.log('achTerminalData from vterminfo response--->', this.achTerminalData);
					if (doValidateCCTerminal && doValidateACHTerminal) {
						// console.log('validating both cc and ach temrinals');
						// console.log(
						// 	'isCCTerminalValid--->',
						// 	this.validateTerminalForMerchantCapabilities(
						// 		ccTerminalData,
						// 		TAGSCONSTS.brandTags.brandItemTags.genericCreditCard
						// 	)
						// );
						// console.log(
						// 	'isACHTerminalValid--->',
						// 	this.validateTerminalForMerchantCapabilities(
						// 		achTerminalData,
						// 		TAGSCONSTS.brandTags.brandItemTags.genericElectronicCheck
						// 	)
						// );
						return resolve(
							this.validateTerminalForMerchantCapabilities(
								this.ccTerminalData,
								TAGSCONSTS.brandTags.brandItemTags.genericCreditCard
							) ||
								this.validateTerminalForMerchantCapabilities(
									this.achTerminalData,
									TAGSCONSTS.brandTags.brandItemTags.genericElectronicCheck
								)
						);
					} else if (doValidateCCTerminal) {
						console.log('validating only ach');
						return resolve(
							this.validateTerminalForMerchantCapabilities(
								this.ccTerminalData,
								TAGSCONSTS.brandTags.brandItemTags.genericCreditCard
							)
						);
					} else if (doValidateACHTerminal) {
						console.log('validating only cc');
						return resolve(
							this.validateTerminalForMerchantCapabilities(
								this.achTerminalData,
								TAGSCONSTS.brandTags.brandItemTags.genericElectronicCheck
							)
						);
					}
				},
				error => {
					console.log('validate txn terminals api error', error);
					this.notificationService.error(TERMINAL_FETCH_FAIL_MESSAGE, 'Error');
					return reject(false);
				}
			);
		});
	}

	/**
	 * Check if terminal has merchant capabilities i.e performing txn with brand provided
	 * @param `terminalData` Terminal whose merchant capabilities needs to be tested
	 * @param `brand` Brand allowed for parent merchant account of terminal
	 * @returns `boolean`
	 */
	validateTerminalForMerchantCapabilities(terminalData: any = {}, brand: string = '') {
		return (
			validateFor(BE_MERCHANT_CAPABILITIES_KEY, terminalData) &&
			terminalData.merchantCapabilities.length &&
			terminalData.merchantCapabilities.includes(brand)
		);
	}

	/**
	 * Check if object is not empty
	 * @param obj
	 * @return boolean: True if object has keys
	 * 					False in if empty object
	 */
	validateObject(obj) {
		if (!obj || !Object.keys(obj).length) {
			return false;
		}
		return true;
	}

	// routeToLoginPage() {
	// 	console.log('previous page called');
	// 	this.notificationService.error(INVALID_MER_ACCT_SETUP_MESSAGE, 'Error');
	// 	const link = ['/login'];
	// 	this.router.navigate(link);
	// }
}
