<section *ngIf="!loading && !noStore" class="setup buis-info">
	<form
		class="form-horizontal"
		[ngClass]="{ 'disable-form': parentMode || userHandlerService?.userRoles?.isBusinessEmployee }"
		[formGroup]="form"
		(ngSubmit)="onStoreSubmit(form, ROUTE_TO_STORE_LIST)"
		novalidate
	>
		<div class="row top-stat">
			<div class="col">
				<div class="card">
					<div class="card-body">
						<i class="fa fa-check-circle-o label-icon label-head-icon" aria-hidden="true"></i>
						<label for="name">Status:</label>
						<label class="detail-label"> Active</label>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="card">
					<div class="card-body">
						<i class="fa fa-sliders label-icon" aria-hidden="true"></i>
						<label for="name" class="p-l-5">Type:</label>
						<label class="detail-label"> Store</label>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="card">
					<div class="card-body">
						<i class="fa fa-briefcase label-icon" aria-hidden="true"></i>
						<label for="name">Business: </label>
						<label class="detail-label"> {{ businessService.businessData?.xName }} </label>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="card">
					<div class="card-body">
						<i class="fa fa-level-up label-icon fa-flip-horizontal" aria-hidden="true"></i>
						<label *ngIf="chainName && !regionName && !propName" class="p-l-5">Chain:</label>
						<label *ngIf="regionName && !propName" class="p-l-5">Region:</label>
						<label *ngIf="propName" class="p-l-5">Property:</label>
						<label *ngIf="!chainName && !regionName && !propName" class="p-l-5">Business:</label>

						<label *ngIf="chainName && !regionName && !propName" class="detail-label">{{ chainName }}</label>
						<label *ngIf="regionName && !propName" class="detail-label">{{ regionName }}</label>
						<label *ngIf="propName" class="detail-label">{{ propName }}</label>
						<label *ngIf="!chainName && !regionName && !propName" class="detail-label">
							{{ businessService.businessData?.xName }}</label
						>
					</div>
				</div>
			</div>
		</div>
		<div class="">
			<div class="row">
				<div class="col-sm-6">
					<div class="card">
						<div class="card-header">
							<label class="">Basic</label>
						</div>
						<div class="card-body">
							<div class="form-group row">
								<div class="col-md-6 col-sm-12">
									<label for="name"
										>Name:
										<small class="text-danger">*</small>
									</label>

									<small
										*ngIf="
											!serverErrors['xName'] && form.get('xName').hasError('required') && form.get('xName').touched
										"
										class="inline-error"
									>
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Name is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>
									<small
										*ngIf="
											!serverErrors['xName'] &&
											form.get('xName').hasError('incorrectNameFormat') &&
											form.get('xName').touched
										"
										class="inline-error"
									>
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Name is of incorrect format. Correct Format-: 1) Name can not be more than 20 letters. 2) The first character of Name should not be an empty space. 3) The first character of Name should not be any special characters, E.g. /,& etc."
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>
									<small *ngIf="serverErrors['xName']" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="{{ formErrors.xName }}"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<input
										type="text"
										class="form-control form-control-sm"
										id="store_Name"
										(focus)="clearErrors('xName')"
										formControlName="xName"
										[ngClass]="{
											'has-error':
												(form.get('xName').hasError('required') && form.get('xName').touched) ||
												(form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched) ||
												serverErrors['xName']
										}"
										validateOnBlur
									/>
								</div>
								<div class="col-md-6 col-sm-12">
									<label for="store_num"
										>Store Number
										<small class="text-danger">*</small>
									</label>
									<app-model-number
										[modelNumber]="storeSetupModel.xStoreNum"
										[form]="form"
										[serverErrors]="serverErrors"
										[numberKey]="numberKey"
									></app-model-number>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-6 col-sm-12">
									<label for="name_two">Sub Name: </label>

									<small
										*ngIf="
											!serverErrors['xSubName'] &&
											form.get('xSubName').hasError('required') &&
											form.get('xSubName').touched
										"
										class="inline-error"
									>
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Phone is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<small *ngIf="serverErrors['xSubName']" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Phone is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<input
										type="text"
										class="form-control form-control-sm"
										id="name_two"
										(focus)="clearErrors('xSubName')"
										formControlName="xSubName"
										[ngClass]="{
											'has-error':
												(form.get('xSubName').hasError('required') && form.get('xSubName').touched) ||
												serverErrors['xSubName']
										}"
										validateOnBlur
									/>
								</div>

								<div class="col-md-6 col-sm-12">
									<label for="svc_phone"
										>Service Phone:
										<small class="text-danger">*</small>
									</label>

									<small
										*ngIf="
											!serverErrors['xSvcPhone'] &&
											form.get('xSvcPhone').hasError('required') &&
											form.get('xSvcPhone').touched
										"
										class="inline-error"
									>
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Service Phone is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<small *ngIf="serverErrors['xSvcPhone']" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="{{ formErrors.xSvcPhone }}"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<input
										type="text"
										class="form-control form-control-sm"
										id="svc_phone"
										(focus)="clearErrors('xSvcPhone')"
										formControlName="xSvcPhone"
										(keypress)="onKeypress($event)"
										(paste)="onPaste($event)"
										[ngClass]="{
											'has-error':
												(form.get('xSvcPhone').hasError('required') && form.get('xSvcPhone').touched) ||
												serverErrors['xSvcPhone']
										}"
										validateOnBlur
									/>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-6 col-sm-12">
									<label for="tag"
										>Tag:
										<!-- <small class="text-danger">*</small> -->
									</label>

									<small
										*ngIf="!serverErrors['xTag'] && form.get('xTag').hasError('required') && form.get('xTag').touched"
										class="inline-error"
									>
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Tag is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<small *ngIf="serverErrors['xTag']" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Tag is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<input
										type="text"
										class="form-control form-control-sm"
										id="tag"
										(focus)="clearErrors('xTag')"
										formControlName="xTag"
										[ngClass]="{
											'has-error':
												(form.get('xTag').hasError('required') && form.get('xTag').touched) || serverErrors['xTag']
										}"
										validateOnBlur
									/>
								</div>
								<div class="col-md-6 col-sm-12">
									<label for="ref_num"
										>Ref Number:
										<!-- <small class="text-danger">*</small> -->
									</label>

									<small
										*ngIf="
											!serverErrors['xRefNum'] &&
											form.get('xRefNum').hasError('required') &&
											form.get('xRefNum').touched
										"
										class="inline-error"
									>
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Ref Number is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<small *ngIf="serverErrors['xRefNum']" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Ref Number is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>
									<i
										class="fa fa-info-circle"
										tooltip="Do not enter the reference number unless instructed to do so"
										placement="top"
										containerClass="tooltip-global"
										container="body"
										aria-hidden="true"
									></i>
									<input
										type="text"
										class="form-control form-control-sm"
										id="ref_num"
										(focus)="clearErrors('xRefNum')"
										formControlName="xRefNum"
										[ngClass]="{
											'has-error':
												(form.get('xRefNum').hasError('required') && form.get('xRefNum').touched) ||
												serverErrors['xRefNum']
										}"
										validateOnBlur
									/>
								</div>
							</div>
							<div class="form-group row no-margin">
								<div class="col-md-6 col-sm-12">
									<label for="Mfr_Ref"
										>Manufacturer Ref Number:
										<!-- <small class="text-danger">*</small> -->
									</label>

									<small
										*ngIf="
											!serverErrors['xMfrRefNum'] &&
											form.get('xMfrRefNum').hasError('required') &&
											form.get('xMfrRefNum').touched
										"
										class="inline-error"
									>
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Mfr Number is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<small *ngIf="serverErrors['xMfrRefNum']" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Mfr Number is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<input
										type="text"
										class="form-control form-control-sm"
										id="mfr_ref"
										(focus)="clearErrors('xMfrRefNum')"
										formControlName="xMfrRefNum"
										[ngClass]="{
											'has-error':
												(form.get('xMfrRefNum').hasError('required') && form.get('xMfrRefNum').touched) ||
												serverErrors['xMfrRefNum']
										}"
										validateOnBlur
									/>
								</div>
								<div class="col-md-6 col-sm-12">
									<label for="svc_ref"
										>Service Ref Number:
										<!-- <small class="text-danger">*</small> -->
									</label>

									<small
										*ngIf="
											!serverErrors['xSvcRefNum'] &&
											form.get('xSvcRefNum').hasError('required') &&
											form.get('xSvcRefNum').touched
										"
										class="inline-error"
									>
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Svc Number is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<small *ngIf="serverErrors['xSvcRefNum']" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Svc Number is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<input
										type="text"
										class="form-control form-control-sm"
										id="svc_ref"
										(focus)="clearErrors('xSvcRefNum')"
										formControlName="xSvcRefNum"
										[ngClass]="{
											'has-error':
												(form.get('xSvcRefNum').hasError('required') && form.get('xSvcRefNum').touched) ||
												serverErrors['xSvcRefNum']
										}"
										validateOnBlur
									/>
								</div>
							</div>
						</div>
					</div>
					<app-main-contact-row
						[mainContactForm]="form"
						[mainContact]="storeSetupModel.xMainContact"
						[serverErrors]="serverErrors"
					>
					</app-main-contact-row>
					<app-address [address]="storeSetupModel.xAddr" [addressForm]="form" [serverErrors]="serverErrors"></app-address>
				</div>
				<div class="col-sm-6">
					<div class="card">
						<div class="card-header">
							<label class="">Payment Processing</label>
						</div>
						<div class="card-body">
							<div class="form-group row">
								<div class="col-md-12 col-sm-12">
									<label for="xgsid"
										>GS Key:
										<!-- <small class="text-danger">*</small> -->
									</label>
									<small
										*ngIf="!errors['xGSID'] && form.get('xGSID').hasError('required') && form.get('xGSID').touched"
										class="inline-error"
									>
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="GS Key is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<small *ngIf="errors['xGSID']" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="GS Key is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<input
										type="text"
										class="form-control form-control-sm"
										id="xgsid"
										(focus)="clearErrors('xGSID')"
										formControlName="xGSID"
										[ngClass]="{
											'has-error':
												(form.get('xGSID').hasError('required') && form.get('xGSID').touched) || errors['xGSID']
										}"
										validateOnBlur
									/>
								</div>
							</div>
							<div class="form-group row no-margin">
								<div class="col-md-12 col-sm-12">
									<label for="xgspw"
										>GS Password:
										<!-- <small class="text-danger">*</small> -->
									</label>
									<small
										*ngIf="!errors['xGSPW'] && form.get('xGSPW').hasError('required') && form.get('xGSPW').touched"
										class="inline-error"
									>
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Password is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<small *ngIf="errors['xGSPW']" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Password is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<input
										type="text"
										class="form-control form-control-sm"
										id="xgspw"
										(focus)="clearErrors('xGSPW')"
										formControlName="xGSPW"
										[ngClass]="{
											'has-error':
												(form.get('xGSPW').hasError('required') && form.get('xGSPW').touched) || errors['xGSPW']
										}"
										validateOnBlur
									/>
								</div>
							</div>
						</div>
					</div>
					<!-- replaced grid beginning -->
					<div *ngIf="!apiLoading" class="card">
						<!-- <div *ngIf="!isLoading" > -->
						<div class="card-header">
							<label class="m-t-5">Deposit Accounts</label>

							<span
								class="pull-right tooltip-wrap-span"
								container="body"
								containerClass="tooltip-global"
								placement="top"
								tooltip="{{ tooltipMessage }}"
							>
								<button
									class="btn btn-info btn-sm add-btn"
									(click)="onAddNewPaymentmethodClick()"
									type="button"
									[disabled]="submitting"
								>
									<i class="fa fa-plus-circle" aria-hidden="true"></i> Manage Deposit Accounts
								</button>
							</span>
						</div>
						<div>
							<div *ngIf="!isLoading && edit">
								<section>
									<div class="grid-add-wrap"></div>
									<div>
										<grid-cmp
											[parameters]="gridParams"
											[isDepositAccountEdit]="isDepositAccountEdit"
											[storeObj]="memberAccountStoreObj"
											[storeValue]="memberAccountStoreValue"
											[attributes]="gridAttributes"
											[showDetails]="false"
											[showSearchBar]="false"
											[fetchByStore]="fetchByStore"
										>
										</grid-cmp>
									</div>
								</section>
								<!-- <app-accounts [storeForm]=form [accountList]=accountList [isPayment]=true [formErrors]=formErrors></app-accounts> -->
							</div>
							<div *ngIf="!edit">
								<div class="card-body fixed-height-box">
									<div class="padder-v">
										<h6 class="no-margin text-muted">Please Setup Deposit Accounts</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-header">
							<label>Notes</label>
						</div>
						<div class="">
							<small
								*ngIf="!formErrors['xNotes'] && form.get('xNotes').hasError('required') && form.get('xNotes').touched"
								class="inline-error"
							>
								Notes is required</small
							>

							<small *ngIf="formErrors['xNotes']" class="inline-error">
								{{ formErrors['xNotes']?.message.replace('"xNotes"', 'Field') }}</small
							>

							<textarea
								class="form-control notes-area-store-setup"
								rows="2"
								id="notes"
								(focus)="clearErrors('xNotes')"
								formControlName="xNotes"
								[ngClass]="{
									'has-error':
										(form.get('xNotes').hasError('required') && form.get('xNotes').touched) || formErrors['xNotes']
								}"
								validateOnBlur
							>
							</textarea>
						</div>
					</div>
					<!-- replaced grid ending -->
				</div>
			</div>
			<div class="row">
				<div class="col-sm-6">
					<div class="card" formGroupName="xNotify">
						<div class="card-header">
							<label>Notify</label>
						</div>
						<div class="card-body">
							<div class="form-group row no-margin">
								<div class="col-md-12 col-sm-12 m-b-5">
									<label for="name">Level 1:</label>
									<small *ngIf="l1IncorrectNotifyMailFormat" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Email format is not valid"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<tag-input
										[formControlName]="'xL1URLs'"
										[addOnBlur]="'true'"
										[separatorKeyCodes]="[32]"
										[ngClass]="{ 'has-error': l1IncorrectNotifyMailFormat }"
										[placeholder]="'Add Email Ids'"
										[secondaryPlaceholder]="'Add Email Ids'"
										[editable]="true"
										(onAdd)="onItemAddedEditedOrRemoved($event)"
										(onRemove)="onItemAddedEditedOrRemoved($event)"
										(onTagEdited)="onItemEdited($event, 'xL1URLs')"
									>
									</tag-input>
								</div>

								<div class="col-md-12 col-sm-12 m-b-5">
									<label for="name">Level 2:</label>
									<small *ngIf="l2IncorrectNotifyMailFormat" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Email format is not valid"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<tag-input
										[formControlName]="'xL2URLs'"
										[addOnBlur]="'true'"
										[separatorKeyCodes]="[32]"
										[ngClass]="{ 'has-error': l2IncorrectNotifyMailFormat }"
										[placeholder]="'Add Email Ids'"
										[secondaryPlaceholder]="'Add Email Ids'"
										[editable]="true"
										(onAdd)="onItemAddedEditedOrRemoved($event)"
										(onRemove)="onItemAddedEditedOrRemoved($event)"
										(onTagEdited)="onItemEdited($event, 'xL2URLs')"
									>
									</tag-input>
								</div>

								<div class="col-md-12 col-sm-12">
									<label for="name">Level 3:</label>
									<small *ngIf="l3IncorrectNotifyMailFormat" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Email format is not valid"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<tag-input
										[formControlName]="'xL3URLs'"
										[addOnBlur]="'true'"
										[separatorKeyCodes]="[32]"
										[ngClass]="{ 'has-error': l3IncorrectNotifyMailFormat }"
										[placeholder]="'Add Email Ids'"
										[secondaryPlaceholder]="'Add Email Ids'"
										[editable]="true"
										(onAdd)="onItemAddedEditedOrRemoved($event)"
										(onRemove)="onItemAddedEditedOrRemoved($event)"
										(onTagEdited)="onItemEdited($event, 'xL3URLs')"
									>
									</tag-input>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6">
					<app-image-uploader
						[image]="storeSetupModel?.image"
						(imageString)="handleImageStringEvent($event)"
					></app-image-uploader>
				</div>
			</div>
			<div class="row m-t-5" *ngIf="!(parentMode || userHandlerService?.userRoles?.isBusinessEmployee)">
				<div class="col-sm-12 text-right">
					<!-- <a class="btn btn-secondary" [routerLink]="['/dashboard/setup/business/structure/information']" [routerLinkActive]="['router-link-active']"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a> -->
					<button class="btn btn-secondary" (click)="onCancel($event)">
						<i class="fa fa-times" aria-hidden="true"></i> Cancel
					</button>
					<span
						class="tooltip-wrap-span"
						[tooltip]="(edit && !canEdit) || (!edit && !canAdd) ? noPermMsg : 'Save'"
						placement="left"
						container="body"
						containerClass="tooltip-global"
					>
						<button
							class="btn btn-primary"
							type="submit"
							[disabled]="submitting || dontSubmit || (edit && !canEdit) || (!edit && !canAdd)"
						>
							<span *ngIf="!submitting">
								Save
								<i class="fa fa-angle-double-right" aria-hidden="true"></i>
							</span>
							<span *ngIf="submitting"
								>Please wait...
								<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
							</span>
						</button>
					</span>
				</div>
			</div>
		</div>
	</form>
	<div class="row m-t-5" *ngIf="parentMode && !userHandlerService?.userRoles?.isBusinessEmployee">
		<div class="col-sm-12 text-right">
			<button class="btn btn-primary" (click)="onEditClick($event)" [disabled]="!canEdit">
				<i class="fa fa-pencil" aria-hidden="true"></i> Edit
			</button>
		</div>
	</div>
</section>
<section *ngIf="!loading && noStore">
	<div class="row m-b-1">
		<div class="col-sm-10 offset-sm-1 m-t-30">
			<div class="coming-soon">
				<h4 class="text-muted">There is exists no store, corresponding to selected node.</h4>
			</div>
		</div>
	</div>
</section>
<section *ngIf="edit && loading">
	<div style="text-align: center; position: realtive">
		<div class="col-md-12">
			<div class="grid-spinner">
				<div class="bounce1"></div>
				<div class="bounce2"></div>
				<div class="bounce3"></div>
			</div>
			<span style="color: #3b5877">Fetching data</span>
		</div>
	</div>
</section>
