export const STORE_NAMES = {
	importTransactionsStore: 'importTransactionsStore',
	transactionsStore: 'TransactionsStore',
	settledTransactionsStore: 'SettledTransactionsStore',
	csvFileHistoryStore: 'CsvFileHistoryStore',
	memberAccountStore: 'memberAccountStore',
	customerStore: 'SetupBillingCustomerStore',
	employeeStore: 'SetupBusinessEmployeeStore',
	dealerStore: 'SetupCompanyRepresentativeStore',
	batchStore: 'BatchStore',
	rolesStore: 'SetupSecurityRolesStore',
	companyStore: 'SetupCompanyStore',
	setupSecurityRolesStore: 'SetupSecurityRolesStore',
	invoiceStore: 'SetupInvoiceStore',
	billingStore: 'SetupBillingStore'
}