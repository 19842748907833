import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
// import { FormGroup } from '@angular/forms';
import { NotificationService } from './../../../../services/index';
import { validateFor } from '../../../../../common-functions/miscellaneous.function';

const fileTypes = ['image/jpeg', 'image/png'];
const FILE_SIZE_LIMIT = 15360;
const WIDTH_LIMIT = 180;
const HEIGHT_LIMIT = 180;
const NO_SELECTED_IMAGE_MSG = 'No image selected for upload';
const IMAGE_NAME_TAG = 'Image: ';
const NOT_VALID_TEXT = ' is not a valid type.';
const TYPE_TEXT = ' with type: ';
const SIZE_TEXT = ' size: ';
const FILE_UPLOAD_FAIL = 'File upload failed.';
const CSS_HIDE_NO_DIMENSIONS_CLASS = 'hide-no-dimensions';
const LARGE_DIMENSION_MSG =
    'Image provided is greater than ' +
    WIDTH_LIMIT.toString() +
    'x' +
    HEIGHT_LIMIT.toString() +
    '.Image must be ' +
    WIDTH_LIMIT.toString() +
    'x' +
    HEIGHT_LIMIT.toString() +
    ' or lower.';
const LARGE_SIZE_MSG =
    'Image provided has size greater than ' +
    returnFileSize(FILE_SIZE_LIMIT) +
    '. Image must be ' +
    returnFileSize(FILE_SIZE_LIMIT) +
    ' or lower.';

@Component({
    selector: 'app-image-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
    @Input()
    image: string = '';
    @Output()
    imageString = new EventEmitter<string>();
    @ViewChild('preview')
    public previewDivElementRef: ElementRef;
    @ViewChild('canvas')
    public canvasElementRef: ElementRef;

    formData: object = {};
    NO_SELECTED_IMAGE_MSG: string = NO_SELECTED_IMAGE_MSG;
    WIDTH_LIMIT: number = WIDTH_LIMIT;
    HEIGHT_LIMIT: number = HEIGHT_LIMIT;

    constructor(private notificationService: NotificationService) {console.log('ImageUploaderComponent: constructor')}

    ngOnInit() {
        console.log('ImageUploaderComponent:init: image', this.image);
    }

    /**
     * called on input file tag change event to fetch new uploaded file
     *
     * @params : event : html event generated from change event of input file tag
     */
    fileUpload(event) {
        console.log('fileUpload: previewDivElementRef', this.previewDivElementRef);
        console.log('fileUpload: event', event);
        // let previewDiv = document.getElementById('preview');
        // let previewDiv = this.previewDivElementRef;
        // this.clearDOMElements(previewDiv);
        const fileList: FileList = event.target.files;
        console.log('fileUpload: fileList', fileList);
        this.previewData(fileList, this.previewDivElementRef).then(
            previewResponse => {
                console.log('fileUpload: previewResponse', previewResponse);
                // this.form.controls['image'].setValue(previewResponse);
                this.imageString.emit(previewResponse);
                // console.log('fileUpload: form', this.form);
                // console.log('fileUpload: form.getRawValue()', this.form.getRawValue());
            },
            err => {
                console.log('fileUpload: err', err);
                if (validateFor('errorParaElement', err)) {
                    this.previewDivElementRef.nativeElement.appendChild(err.errorParaElement);
                }
            }
        );
    }

    /**
     * clear all dom elements of given html element
     *
     * @params : htmlElement : html element fetched from document
     */
    clearDOMElements(htmlElement: ElementRef) {
        console.log('clearDOMElements:htmlElement', htmlElement);
        while (htmlElement.nativeElement.firstChild) {
            console.log('clearDOMElements:htmlElement.firstChild', htmlElement.nativeElement.firstChild);
            htmlElement.nativeElement.removeChild(htmlElement.nativeElement.firstChild);
        }
    }

    /**
     * preview content based on fileList passed
     *
     * @params : fileList : FileList : contains records of type File, which are to be shown
     * @params : previewDiv : htmlElement in which data is to be previewed
     */
    previewData(fileList: FileList, previewDiv: ElementRef): Promise<string> {
        return new Promise(async (resolve, reject) => {
            console.log('previewData:fileList', fileList);
            if (!fileList.length) {
                // let para = document.createElement('p');
                // para.textContent = 'No image selected for upload';
                this.clearDOMElements(previewDiv);
                let noSelectedImagePara = this.createMessageTag(NO_SELECTED_IMAGE_MSG);
                this.canvasElementRef.nativeElement.classList.add(CSS_HIDE_NO_DIMENSIONS_CLASS);
                // previewDiv.appendChild(noSelectedImagePara);
                // previewDiv.nativeElement.appendChild(noSelectedImagePara);
                return reject({ errorParaElement: noSelectedImagePara });
            }
            let imageFile = fileList[0];
            let invalidMessage = IMAGE_NAME_TAG + imageFile.name + TYPE_TEXT + imageFile.type + NOT_VALID_TEXT;
            // let fileInfoMessage = IMAGE_NAME_TAG + imageFile.name + SIZE_TEXT + returnFileSize(imageFile.size) + '.';
            // let fileInfoPara = this.createMessageTag(fileInfoMessage);
            let invalidTypePara = this.createMessageTag(invalidMessage);
            // let largeDimensionPara = this.createMessageTag(LARGE_DIMENSION_MSG);
            // let largeSizePara = this.createMessageTag(LARGE_SIZE_MSG);
            if (this.validFileType(imageFile)) {
                console.log('previewData:imageFile', imageFile);
                let resultData: string = '';
                try {
                    resultData = await this.convertFileToBase64(imageFile);
                } catch (err) {
                    console.log('previewData: err', err);
                    this.notificationService.error(FILE_UPLOAD_FAIL, 'Error');
                    return reject({ message: FILE_UPLOAD_FAIL });
                }
                this.clearDOMElements(previewDiv);
                console.log('previewData:resultData', resultData);
                let imageTag = document.createElement('img');
                imageTag.classList.add(CSS_HIDE_NO_DIMENSIONS_CLASS);
                this.canvasElementRef.nativeElement.classList.remove(CSS_HIDE_NO_DIMENSIONS_CLASS);
                imageTag.src = resultData;
                console.log('previewData:imageTag', imageTag);
                console.log('previewData:imageTag.classlist', imageTag.classList);
                // let newSrc = await this.scaleImage(imageTag, previewDiv);
                // console.log('previewData: newSrc', newSrc);
                // let that = this;
                imageTag.onload = () => {
                    // console.log('imageTag: onLoad: that', that);
                    let newSrc = this.scaleImage(imageTag);
                    // console.log('imageTag: onLoad: newSrc', newSrc);
                    // console.log('imageTag: onLoad: newSrc.length', newSrc.length);
                    // console.log('imageTag: onLoad: newSrc file length', new TextEncoder().encode(newSrc).length);
                    // console.log('imageTag: onLoad: resultData.length', resultData.length);
                    // console.log('imageTag: onLoad: resultData file length', new TextEncoder().encode(resultData).length);
                    // console.log('imageTag: onLoad:width', imageTag.width);
                    // console.log('imageTag: onLoad:height', imageTag.height);
                    // console.log('imageTag: onLoad:naturalheight', imageTag.naturalHeight);
                    // console.log('imageTag: onLoad:naturalwidth', imageTag.naturalWidth);
                    // let width = imageTag.naturalWidth || imageTag.width;
                    // let height = imageTag.naturalHeight || imageTag.height;
                    // console.log('imageTag: onLoad: final width', width);
                    // console.log('imageTag: onLoad: final height', height);
                    previewDiv.nativeElement.appendChild(imageTag);
                    // previewDiv.appendChild(fileInfoPara);
                    // return resolve(resultData);
                    return resolve(newSrc);
                };
            } else {
                this.clearDOMElements(previewDiv);
                let para = document.createElement('p');
                para.textContent = IMAGE_NAME_TAG + imageFile.name + ' with type:' + imageFile.type + ' is not a valid type.';
                this.canvasElementRef.nativeElement.classList.add(CSS_HIDE_NO_DIMENSIONS_CLASS);
                // previewDiv.nativeElement.appendChild(invalidTypePara);
                return reject({ errorParaElement: invalidTypePara });
            }
        });
    }

    /**
     * check if file provided is valid type
     *
     * @params : file : File : file whose type is to be verified if it is jpeg or png
     * @return : boolean : true if valid, else false
     */
    validFileType(file: File) {
        console.log('validFileType: file', file);
        return fileTypes.includes(file.type);
    }

    /**
     * create a p tag and return the html element
     *
     * @params : message : string : message in p tag
     * @return : p tag HTML element with the given message
     */
    createMessageTag(message: string): any {
        console.log('createMessageTag:message', message);
        let para = document.createElement('p');
        para.textContent = message;
        return para;
    }

    /**
     * convert image to base64
     *
     * @params : file : File : the file to be converted
     */
    convertFileToBase64(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log('convertFileToBase64: file', file);
            let fileReader = new FileReader();
            fileReader.onload = function(event) {
                console.log('convertFileToBase64: fileReader.onload : event', event);
                let resultData = event.target['result'];
                // console.log('convertFileToBase64: resultData', resultData);
                return resolve(resultData);
            };
            try {
                // throw 'forced error';
                fileReader.readAsDataURL(file);
            } catch (err) {
                console.log('convertFileToBase64: err', err);
                return reject(err);
            }
        });
    }

    /**
     * scales image to respective WIDTH and HEIGHT limits
     *
     * @params : imageTag : HTMLImageElement : image tag containing image to be scaled
     * @params : previewDiv : ElementRef : preview div where it is to be shown
     * @return : newSrc : Promise: string : new base64 string with resized image
     */
    scaleImage(imageTag: HTMLImageElement) {
        // return new Promise(async (resolve, reject) => {
        console.log('scaleImage: file', imageTag);
        // let canvasElement = document.createElement('canvas');
        let canvasElement = this.canvasElementRef.nativeElement;
        // canvasElement.width = WIDTH_LIMIT;
        // canvasElement.height = HEIGHT_LIMIT;
        let aspectRatio = this.getAspectRatio(imageTag.width,imageTag.height);
        console.log('scaleImage: aspectRatio',aspectRatio);
        let width,height;
        if(aspectRatio < 1){
            width = WIDTH_LIMIT * aspectRatio;
            height = HEIGHT_LIMIT;
        } else {
            width = WIDTH_LIMIT;
            height = HEIGHT_LIMIT * (1/aspectRatio);
        }
        canvasElement.width = width;
        canvasElement.height = height;
        // let canvasElement = new CanvasRenderingContext2D();
        let ctx = canvasElement.getContext('2d');
        ctx.drawImage(imageTag, 0, 0, width, height);
        // let newSrc = await canvasElement.toDataURL();
        let newSrc = canvasElement.toDataURL('image/jpeg', '0.1');
        console.log('scaleImage: newSrc', newSrc);
        return newSrc;
        // return resolve(newSrc);
        // });
    }

    /**
    * gets aspect ratio for image
    *
    * @params : `width` : number : width of image
    * @params : `height` : number : height of image
    * @return : `aspectRatio` : number : width/height
    */
    getAspectRatio(width:number,height:number){
        console.log('getAspectRatio: width',width);
        console.log('getAspectRatio: height',height);
        return width/height;
    }
}

/**
 * return file size in readable format
 *
 * @params : number : number : size of file
 * @return : string : size in readable format
 */
function returnFileSize(number) {
    if (number < 1024) {
        return number + 'bytes';
    } else if (number >= 1024 && number < 1048576) {
        return (number / 1024).toFixed(1) + 'KB';
    } else if (number >= 1048576) {
        return (number / 1048576).toFixed(1) + 'MB';
    }
}
