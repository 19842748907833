import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionsStore } from '../../store';

import { BreadcrumbService, NotificationService, FilterService } from '../../shared/services';
import { customSwal } from 'app/constants';

@Component({
	selector: 'app-transaction',
	templateUrl: './transaction.component.html',
	styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit {
	errMsg: string;
	record: any;
	params: any;
	loading: boolean;
	error: boolean;
	data: any[];
	metaData: any;
	viewGridProps: any;
	viewAction: any[];
	viewGridColLength: number;

	public attributes: any[];
	public storeValue: string;
	public storeObject: TransactionsStore;
	public action: any[];
	public tree_filter: string;
	public modifyValue: boolean;

	public treeAddAction: boolean;
	public treeButtonLinks: any;
	public treeNodeLinks: any;

	public modifiedAction: boolean;

	constructor(
		private router: Router,
		private transactionsStore: TransactionsStore,
		private notificationService: NotificationService,
		private breadcrumbService: BreadcrumbService,
		private filterService: FilterService
	) {
		this.record = {};
		this.modifiedAction = true;
		this.params = {};
		this.loading = false;
		this.error = false;
		this.data = [];
		this.metaData = {};
		this.viewGridProps = {};
		this.viewAction = [];
		this.viewGridColLength = 0;

		this.attributes = [];
		this.action = [];
		this.storeValue = '';
		this.tree_filter = '';

		this.treeAddAction = false;
		this.treeButtonLinks = {};
		this.treeNodeLinks = {};
	}

	ngOnInit(): void {
		console.log('Inside TransactionsComponent init function');
		this.filterService.setFilterValue(true);

		this.viewGridProps = this.transactionsStore.getViewGridProps();
		this.viewGridColLength = this.viewGridProps.attributes.length * 2 + 2;

		this.setBreadCrumbValue();
		this.setTreeAttributes();

		this.modifiedAction = true;
		this.tree_filter = null;
		this.storeValue = 'TransactionsStore';
		this.storeObject = this.transactionsStore;
		this.attributes = [
			{ header: 'Store', mapTo: 'Store' },
			{ header: 'PftCtr', mapTo: 'Pft_Ctr' },
			{ header: 'Trans#', mapTo: 'Trans_Num' },
			{ header: 'Type', mapTo: 'Type' },
			{ header: 'Time Out', mapTo: 'Time_Out' },
			{ header: 'Invoice', mapTo: 'Invoice' },
			{ header: 'Customer', mapTo: 'Customer' },
			{ header: 'Card', mapTo: 'Card' },
			{ header: 'Account Number', mapTo: 'Acc_Num' },
			{ header: 'Amt Auth', mapTo: 'Amt_Auth' },
			{ header: 'Tip', mapTo: 'Tip' },
			{ header: 'Amt Settle', mapTo: 'Amt_Settle' },
			{ header: 'Batch#', mapTo: 'Batch_Num' },
			{ header: 'Actions', mapTo: '' }
		];
		const editLink = '';
		// let viewLink = '';
		this.action = [
			{ type: 'edit', visible: true, text: 'Edit', icon: 'fa fa-edit', action: 'url', onClick: editLink },
			{ type: 'delete', visible: false, text: 'Delete', icon: 'fa fa-trash', action: 'func', onClick: 'destroy' },
			{ type: 'toggle', visible: false, text: 'Activate/De-Activate', icon: 'fa fa-toggle-on', action: 'func', onClick: 'toggle' },
			{ type: 'view', visible: true, text: 'Details', icon: 'fa fa-info-circle', action: 'func', onClick: 'get' }
		];

		this.params.page = 1;
		this.params.keyword = null;
		this.params.limit = 2;
		this.displayGrid(this.params);
	}

	displayGrid(params: any): void {
		this.transactionsStore.getAll(params).subscribe(
			(response: any) => {
				console.log('Inside grid component, subscribe Success func', response);
				this.loading = false;
				this.error = false;
				this.data = response.data;
				if (!this.data.length) {
					console.log('sdcsddfeferfreferfeqrqerqereqr');
					this.error = true;
				}
				// this.notificationService.success('Everything seems to be in order!', 'Records Populated');
				this.metaData = response.meta;
				console.log('Metadata--->', this.metaData);
			},
			(error: any) => {
				console.log('Inside grid component, subscribe Failure func', error);
				this.loading = false;
				this.error = true;
				this.notificationService.error('Oops Something went wrong!', 'No Records Available');
			},
			() => {
				console.log('subscription complete func');
				this.modifyData();
			}
		);
	}

	setBreadCrumbValue(): void {
		const childList: any[] = [];
		const breadCrumb: any = {};
		breadCrumb.root = 'Transactions';
		breadCrumb.child = '';
		breadCrumb.subChild = '';
		breadCrumb.subChildLink = null;
		breadCrumb.grandChild = null;
		breadCrumb.childList = childList;
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	setTreeAttributes(): void {
		this.treeButtonLinks = {};
		this.treeAddAction = false;
		this.treeNodeLinks.chain = 'chain';
		this.treeNodeLinks.property = 'property';
		this.treeNodeLinks.store = 'store';
		this.treeNodeLinks.profitCenter = 'profitCenter';
	}

	onNodeClick(node: string): void {
		this.tree_filter = node;
	}

	processModifiedAction(act: any, elementID: any, element: any = {}): void {
		console.log('Action Request---->', act, elementID, this.storeValue, element);
		const that = this;

		// console.log('Store Value recieved for Grid Component is-->', this.storeValue);
		// console.log('StoreObj assigned for Grid Component is-->', this.storeObj);
		switch (act.type) {
			case 'edit':
				console.log('Edit Action Request', act.type, act.action, act.onClick);
				if (act.action === 'url' && act.onClick) {
					console.log('Inside if, navigating to edit url with--->', act.onClick, 'with ID-: ', elementID);
					const link = [act.onClick, elementID];
					this.router.navigate(link);
				} else {
					console.log('Inside else, Incorrect URL');
				}
				break;
			case 'delete':
				console.log('Delete Action Request', act.type, act.action, act.onClick);
				if (act.action === 'func' && act.onClick === 'destroy') {
					customSwal.fire({
						title: 'Are you sure?',
						text: 'You will not be able to recover this Record!',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: 'Yes, delete it!',
						cancelButtonText: 'No, I changed my Mind!',
						reverseButtons: true
					}).then((result) => {
						if (result.value) {
							this.transactionsStore.destroy(elementID).subscribe(
								(response: any) => {
									console.log('Inside Grid Component, subscribe Success func', response);
									console.log('Let`s see dis--->', response.data);
									console.log('Let`s see dis--->', response.data._id);
									// that.notificationService.success('Record Deleted.');
									customSwal.fire('Deleted!', 'Selected record has been deleted.', 'success');
								},
								(error: any) => {
									console.log('Inside Grid Component, subscribe Failure func', error);
									const err = JSON.parse(error._body).message;
									console.log('I have======>', err);
									if (err.includes('Cast to ObjectId failed')) {
										console.log('............true.................');
										that.errMsg = 'No such record exists';
										that.notificationService.error(that.errMsg, elementID + ' Not Found!');
									} else {
										console.log('............false.................');
										that.errMsg = 'Un-expected Error';
										that.notificationService.error(that.errMsg, elementID + ' Not Found!');
									}
									// that.notificationService.error('Record Not Deleted.');
								},
								() => {
									console.log('subscription complete func');
									this.params.page = 1;
									this.params.keyword = null;
									this.params.limit = 2;
									this.displayGrid(this.params);
								}
							);
						} else {
							customSwal.fire('Cancelled', 'Selected record is safe', 'error');
						}
					})
				} else {
					console.log('Inside else, Incorrect Function');
				}
				break;
			case 'view':
				console.log('View Action Request', act.type, act.action, act.onClick);
				// this.modifiedActionObj.type = 'view';
				// this.modifiedActionObj.elementID = elementID;
				// console.log('Values to be set=========>', this.modifiedActionObj);
				if (!element.isActive) {
					if (act.action === 'url' && act.onClick) {
						console.log('Inside if, navigating to view url--->', act.onClick, 'with ID-: ', elementID);
						const link = [act.onClick, elementID];
						this.router.navigate(link);
					} else if (act.action === 'func' && act.onClick === 'get') {
						// this.transactionsViewStore.get(elementID)
						// .subscribe(
						//     (response: any)  => {
						//             console.log('Inside transaction component displayViewGrid, subscribe Success func',response);
						//             this.record = response.data;
						//             console.log('Let`s see dis--->', this.record._id);
						//             console.log('Record Retrieved--->', this.record);
						//         },
						//     (error: any) => {
						//             console.log('Inside transaction component displayViewGrid, subscribe Failure func',error);
						//             // this.error = true;
						//             console.log('let`s see this------>',JSON.parse(error._body).message);
						//             let errMsg = JSON.parse(error._body).message;
						//             console.log('let`s see this------>',errMsg, elementID);
						//             this.notificationService.error(errMsg, elementID + ' Not Found!');
						// //             let link = ['/dashboard/setup-billing-payment-plans'];
						//             // this.router.navigate(link);//Return to grid page
						//             // return error;
						//         },
						//     () => {
						//             console.log('Get subscription complete func');
						//             let index = this.data.indexOf(element);
						//             console.log('index--------->', index);
						//             this.data[index].isActive = true;
						//             console.log('Modified Data=====>', this.data);
						//           }
						// );
					} else {
						console.log('Inside else, Incorrect URL');
					}
				} else {
					element.isActive = false;
				}
				break;
			case 'toggle':
				console.log('Toggle Action Request', act.type, act.action, act.onClick);
				if (act.action === 'func' && act.onClick === 'toggle') {
					// // console.log('Inside if, calling',this.storeValue, ' toggle function');
					// this.storeObj.toggle(elementID);
				} else {
					console.log('Inside else, Incorrect Function');
				}
				break;
			default:
				console.log('Default Action Request--->', act.type, act.action, act.onClick);
				break;
		}
	}

	modifyData(): void {
		console.log('data recieved--->', this.data);
		if (this.storeValue === 'TransactionsStore') {
			console.log('Inside if');
			for (let i = 0; i < this.data.length; i++) {
				this.data[i].isActive = false;
			}
			console.log('Modified Data=====>', this.data);

			// this.modifiedActionObj.gridProps = this.storeObj.getViewGridProps();
			// console.log('View Grid Props set to--->', this.modifiedActionObj.gridProps);
		}
	}

	onSearch(keyword: any): void {
		console.log('I have-: ', keyword);
		this.params.page = 1;
		if (keyword) {
			this.params.keyword = keyword;
		} else {
			this.params.keyword = null;
		}
		this.params.limit = 2;
		this.displayGrid(this.params);
	}
}
