export class FormalName {
    xPrefix: string;
    xForename:string;
    xMiddlename: string;
    xSurname:string;
    xSuffix:string;
    xFullName:string;

    constructor(obj?: any) {
        this.xPrefix = (obj && obj.xPrefix) || '';
        this.xForename = (obj && obj.xForename) || '';
        this.xMiddlename = (obj && obj.xMiddlename) || '';
        this.xSurname = (obj && obj.xSurname) || '';
        this.xSuffix = (obj && obj.xSuffix) || '';
        this.xFullName = (obj && obj.xFullName) || '';
    }
}
