import { Injectable } from '@angular/core';
import { HttpService, CompanyService } from '../shared/services/index';
import { map } from 'rxjs/operators';

const endPoint = 'xbcBusinesses';

@Injectable()
export class SetupBusinessInformationStore {

    constructor(protected httpService: HttpService, protected companyService: CompanyService) {}

    getAll(params: any): any {
        if (params.businessID) {
            delete params.businessID;
        }
        const companyId = this.companyService.getcompanyID();
        if (companyId) {
            params.xSvcCompanyID = companyId;
        }

        return this.httpService
            .getAll(endPoint, params)
            .pipe(
				map((response: Response) => {
					return response;
				})
			);
    }

    store(element: any, timeOut:boolean=false): any {
        return this.httpService
            .store(endPoint, element, timeOut)
            .pipe(
				map((response: Response) => {
					return response;
				})
			);
    }

    update(elementID: string, newElement: any): any {
        return this.httpService
            .update(endPoint, elementID, newElement)
            .pipe(
				map((response: Response) => {
					return response;
				})
			);
    }

    get(elementID: any): any {
        console.log("I'm inside get function", elementID);
        return this.httpService
            .get(endPoint, elementID)
            .pipe(
				map((response: Response) => {
					return response;
				})
			);
    }

    destroy(elementID: any): any {
        return this.httpService
            .destroy(endPoint, elementID)
            .pipe(
				map((response: Response) => {
					return response;
				})
			);
    }
}
