import { Component, OnInit, ViewChild } from "@angular/core";
import { CsvFileHistoryStore } from "./../../store/csv-file-history-store";
import { BreadcrumbService, UserHandlerService } from "../../shared/services/";
import { ModalDirective } from "ngx-bootstrap/modal";
@Component({
    selector: "app-csv-file-history",
    templateUrl: "./csv-file-history.component.html",
    styleUrls: ["./csv-file-history.component.scss"]
})
export class CsvFileHistoryComponent implements OnInit {
    @ViewChild("bizImportReportModal", { static: true })
    bizImportReportModal: ModalDirective;
    @ViewChild("bizImportModal", { static: true })
    bizImportModal: ModalDirective;
    constructor(
        private csvFileHistoryStore: CsvFileHistoryStore,
        private breadcrumbService: BreadcrumbService,
        private userHandlerService: UserHandlerService
    ) {}
    public attributes: any[] = [];
    public storeValue: string = "";
    public storeObject: any = {};
    public action: any[] = [];
    public conflictObj: any = {};
    public isConflict: boolean = false;
    public businessGmidSet = [];
    public tempgmidpath = [];
    public actionResponseFail: any = [];
    public actionResponseSuccess: any = [];
    public actionStatus: boolean = false;
    public refreshGrid: boolean = false;
    public params: any = {};
    public isLoading: boolean = false;
    public canViewStructure = true;
    public canViewImportResponse = true;

    ngOnInit() {
        console.log("csv history init");
        this.setupPerms();
        this.setInitParams();
        this.setGridProperties();
        this.setBreadCrumbValue();
    }

    setInitParams() {
        this.params = { limit: 20 };
    }

    setGridProperties() {
        this.storeValue = "CsvFileHistoryStore";
        this.storeObject = this.csvFileHistoryStore;
        this.attributes = [
            // { header: 'Active', mapTo: 'xStatus' },
            { header: "Name", mapTo: "user_details.xName", width: "20%" },
            {
                header: "Structure File",
                mapTo: "csv_details.structureFileName",
                width: "25%"
            },
            {
                header: "Result File",
                mapTo: "csv_details.resultFileName",
                width: "25%"
            },
            {
                header: "Action Status",
                mapTo: "action_worker_queue_status",
                width: "10%"
            },
            { header: "Time", mapTo: "convertedTime", width: "15%" },
            { header: "Actions", mapTo: "", width: "5%" }
        ];
        this.action = [
            {
                type: "modal",
                visible: true,
                text: "View Structure",
                icon: "fa fa-sitemap",
                action: "emit",
                onClick: "viewStructure",
                disabled: !this.canViewStructure
            },
            {
                type: "modal",
                visible: true,
                text: "View Import Response",
                icon: "fa fa-file",
                action: "emit",
                onClick: "viewImportResponse",
                disabled: !this.canViewImportResponse
            }
        ];
    }

    setBreadCrumbValue(): void {
        const breadCrumb: any = {};
        breadCrumb.root = "CSV File History";
        breadCrumb.child = "";
        breadCrumb.subChild = "";
        breadCrumb.subChildLink = null;
        breadCrumb.grandChild = null;
        this.breadcrumbService.setBreadCrumb(breadCrumb);
    }
    
    showViewModal(event: any) {
        // console.log("event from grid", event);
        let suggestedStructure = JSON.parse(event);
        // console.log("suggestedStructure", suggestedStructure);
        const that = this;
        this.responseProcessBusinessImport(that, suggestedStructure);
    }

    refreshRequest($event) {
        this.params = Object.assign({}, this.params);
    }

    showImportResponseModal(event: any) {
        // console.log("import response modal", event);
        let successList = [];
        this.actionResponseFail = [];
        this.actionResponseSuccess = [];
        this.actionResponseFail = event.failedList ? event.failedList : [];
        this.actionResponseFail.forEach(failObj => {
            console.log("failobj", failObj);
            let failObjError = JSON.parse(failObj.error);
            failObj.errorString = "";
            failObj.errorMessage = failObjError.message;
            Object.keys(failObjError.errors).map(singleError => {
                failObj.errorString = failObjError.errors[singleError] + " ";
            });
            // console.log("failObj error", failObj);
        });
        event.successList.map(item => {
            item.businessData.subNodesInfo.map(element => {
                successList.push(element);
            });
        });
        this.actionResponseSuccess = successList;
        this.actionStatus = true;
        this.bizImportModal.show();
    }

    responseProcessBusinessImport(that, bizPreImportReportObj) {
        that.bizImportReportModal.show();
        that.conflictObj = {};
        that.isConflict = false;
        that.businessGmidSet = [];
        that.tempgmidpath = [];
        that.processPreImportReportObj(
            that,
            bizPreImportReportObj,
            [],
            "",
            false,
            false
        );
        console.log("bizgmidset", that.businessGmidSet);
    }

    bizImportReportModalClose() {
        this.bizImportReportModal.hide();
    }

    gmidPathDiff(refObj: any, currentObj: any): any {
        // console.log("refobj", refObj, "currentobj", currentObj);
        let refPath = refObj ? refObj.parentHierarchy : [];
        let currentPath = currentObj ? currentObj.parentHierarchy : [];
        let commonArr = [];
        let diffArr = [];
        let creationArr = [];
        let i;
        // console.log("refpath", refPath, "currentpath", currentPath);
        for (i = 0; i < refPath.length && i < currentPath.length; i++) {
            // console.log("gmid path diff", refPath[i], currentPath[i]);
            if (
                refPath[i].isPresent === currentPath[i].isPresent &&
                refPath[i].name === currentPath[i].name &&
                refPath[i].type === currentPath[i].type &&
                refPath[i].refNum === currentPath[i].refNum
            ) {
                // console.log("here", refPath[i], currentPath[i]);
                // commonArr.push(refPath[i]);
                creationArr.push({ obj: refPath[i], creation: false });
            } else break;
        }
        for (; i < currentPath.length; i++) {
            // diffArr.push(currentPath[i]);
            creationArr.push({ obj: currentPath[i], creation: true });
        }
        // console.log("commonarr", commonArr, "diffarr", diffArr);
        return { creationArr: creationArr };
    }

    processPreImportReportObj(
        that,
        response: any,
        parent: any,
        gmid: any,
        doExist: boolean,
        allExist: boolean
    ) {
        let reportObj,
            reportObjArrKeys = Object.keys(response);
        for (let i = 0; i < reportObjArrKeys.length; i++) {
            reportObj = response[reportObjArrKeys[i]];
            if (
                reportObj &&
                reportObj.hasOwnProperty("type") &&
                reportObj["type"] === "Business"
            ) {
                let children = Object.keys(reportObj["children"]);
                // console.log("children preimport", children);
                for (let i = 0; i < children.length; i++) {
                    that.processPreImportReportObj(
                        that,
                        reportObj.children[children[i]],
                        [
                            {
                                name: reportObj["name"],
                                type: reportObj["type"],
                                refNum: reportObj["refNum"]
                                    ? reportObj["refNum"]
                                    : "-",
                                isPresent: reportObj["isPresent"]
                            }
                        ],
                        "",
                        reportObj.isPresent,
                        reportObj.isPresent
                    );
                }
                for (let i = 0; i < that.tempgmidpath.length; i++) {
                    that.tempgmidpath[i].display = that.gmidPathDiff(
                        that.tempgmidpath[i - 1],
                        that.tempgmidpath[i]
                    );
                }
                that.businessGmidSet.push({
                    name: reportObj["name"],
                    doExist: reportObj["isPresent"],
                    gmidPath: that.tempgmidpath
                });
                that.tempgmidpath = [];
            }
        }
        if (reportObj.type !== "Business") {
            let parentArr = [];
            if (response["type"] === "PCtr") {
                gmid = response["gmid"] ? response["gmid"] : "From Number API";
                response["refNum"] = response["gmid"];
            }
            parent.map(item => {
                parentArr.push(item);
            });
            parentArr.push({
                name: response["name"],
                type: response["type"],
                refNum: response["refNum"] ? response["refNum"] : "-",
                isPresent: response["isPresent"]
            });
            if (response["type"] === "Terminal") {
                that.tempgmidpath.push({
                    gmid: gmid,
                    doExist: doExist,
                    allExist: allExist === false ? false : response.isPresent,
                    parentHierarchy: parentArr
                });
            }
            // console.log("chain outputobj", that.outPutObj);
            let children = Object.keys(response["children"]);
            for (let i = 0; i < children.length; i++) {
                // console.log("children:", children[i], "children of", response);
                that.processPreImportReportObj(
                    that,
                    response.children[children[i]],
                    parentArr,
                    gmid,
                    doExist !== true ? response.isPresent : true,
                    allExist === false ? false : response.isPresent
                );
            }
        }
    }

    setupPerms(){
        this.canViewStructure = this.userHandlerService.userPerms.canViewStructure;
        this.canViewImportResponse = this.userHandlerService.userPerms.canViewImportResp;
    }
}
