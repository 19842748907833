<section>
    <div class="padder grid-wrapper">
        <div class="row m-b-5">
            <div class="col-sm-2">
                <div class="card no-margin log-input-wrap">
                    <label class="checkbox1 no-margin">
                        <input type="checkbox" name="err_check" [(ngModel)]="viewErr" (change)="viewError()"/>
                        <span></span>
                        View Errors only
                    </label>
                </div>
            </div>
            <div class="col-sm-6">   
                <div class="searchform">
                    <form role="search" (ngSubmit)="onSearch(keyword)">
                        <div class="search-box input-group input-group-sm">
                        <i class="fa fa-search"></i>
                            <input type="search" class="form-control form-control-sm grid-search-btn pr35" placeholder="Search .." [(ngModel)]="keyword" name="searchBar" #searchBar="ngModel">
                            <button class="btn btn-primary btn-sm"><i class="fa fa-search"></i></button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-sm-4 text-right">
                <button (click)="moveToTop()" class="btn btn-sm btn-primary">
                    <i class="fa fa-arrow-up" aria-hidden="true"></i> Top
                </button>
                <button (click)="moveToBottom()" class="btn btn-sm btn-primary">
                    <i class="fa fa-arrow-down" aria-hidden="true"></i> Bottom
                </button>
                <button (click)="unselectAll()" class="btn btn-sm btn-primary">
                    <i class="fa fa-undo" aria-hidden="true"></i> Unselect All 
                </button>
            </div>
        </div>
        <form class="form-horizontal">
            <div class="custom-block card">
                <ul class="log-list" id='log-grid'>
                    <li *ngFor="let log of finalLogArray" >
                        <span [ngClass]="log.highlight ? 'highlight': 'nohighlight'">{{log.value}}</span>
                    </li>
                </ul>
            </div>
        </form>
    </div>
</section>
