<div bsModal #txnBatchRespModal="bs-modal" class="modal fade edit-perm-modal settle-action" [config]="{ignoreBackdropClick: true}" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" (onHide)="onHideModal()">
    <!-- <div bsModal #txnBatchRespModal="bs-modal" class="modal fade edit-perm-modal preview-modal" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true" (onHide)="onHideModal($event)"> -->
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="onHideModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">{{type}} Action Response</h4>
            </div>

            <div class="transaction-action-table modal-body">
                <div class="preview grid-wrapper" id="try_printing_this_1" #try_printing_this>
                    <div class="scroll">
                        <div *ngIf="!loadingSuccess && !(successEmpty === 'No Transaction Actions Successful' || successEmpty === 'No Batch Actions Successful')" class="successAlertDiv">
                            <div id="successAlertDiv" class="alert alert-success">
                                <h6 id="successHeaderTxn" *ngIf="txnBatchType === 'txn' || txnBatchType === 'settledTxn'" class="no-margin"><i class="fa fa-check"></i> Successful Transaction(s)</h6>
                                <h6 id="successHeaderBatch" *ngIf="txnBatchType === 'batch' && type !== 'Clean'" class="no-margin"><i class="fa fa-check"></i> Successful Batch</h6>
                                <h6 id="successHeaderBatch" *ngIf="txnBatchType === 'batch' && type === 'Clean'" class="no-margin"><i class="fa fa-check"></i> Batch Cleanup Successful</h6>
                            </div>
                            <div *ngIf="!showSuccessTable">
                                <app-summary-report [fetchByTxnNumber]=fetchByTxnNumber [actionID]=actionID [fetchByActionID]=fetchByActionID [successIDs]=successIDArray [type]=txnBatchType [store]=storeObject [showModal]=showSuccessTable [showTxn]=showTxn [showBatch]=showBatch [hideBatchTxn]=hideBatchTxns (onCloseModal)="onPrintDetailModalClose($event)"></app-summary-report>
                                <!-- <app-summary-report [successIDs]=successIDArray [type]=txnBatchType [store]=storeObject [showModal]=showSuccessTable [showTxn]=showTxn [showBatch]=showBatch [hideBatchTxn]=hideBatchTxns (onCloseModal)="onPrintDetailModalClose($event)" (printBatchDetail)="printBatchDetails(try_printing_this)"
                                    (printFinalBatchDetail)="printBatchDetails(false)"></app-summary-report> -->
                            </div>
                            <div class="card no-padding no-margin">

                                <table width="100%" border="0" *ngIf='showSuccessTable' class="table table-collapsed table-sm table-striped no-margin table-grid vertical-content table_main">
                                    <thead class="table_header">
                                        <tr *ngIf='!type.endsWith("Batch")'>
                                            <th>Transaction #</th>
                                            <th>Type</th>
                                            <th>Account #</th>
                                            <th>Amount</th>
                                            <th>Tip Amount</th>
                                            <th>Total Amount</th>
                                            <!-- <th>Action Status</th> -->
                                        </tr>
                                        <tr *ngIf='type.endsWith("Batch")'>
                                            <th>Batch #</th>
                                            <!-- <th>Action Status</th> -->
                                        </tr>
                                    </thead>
                                    <tbody class="" *ngIf="!loadingSuccess">
                                        <ng-container *ngFor='let res of successObjArray'>
                                            <tr>
                                                <td>
                                                    {{res.num}}
                                                </td>
                                                <td>
                                                    {{res?.type}}
                                                </td>
                                                <td>
                                                    {{res?.maskedAcctNum}}
                                                </td>
                                                <td>
                                                    $ {{res?.amt}}
                                                </td>
                                                <td>
                                                    $ {{res?.tipAmt}}
                                                </td>
                                                <td>
                                                    $ {{res?.totAmt}}
                                                </td>
                                                <!-- <td>
                                            {{res.status}}
                                        </td> -->
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                    <tbody *ngIf="loadingSuccess">
                                        <tr>
                                            <td style="text-align: center;" [colSpan]="6">
                                                <div class="grid-spinner">
                                                    <div class="bounce1"></div>
                                                    <div class="bounce2"></div>
                                                    <div class="bounce3"></div>
                                                </div>
                                                <span style='color:#3b5877'>Generating Response</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="padder text-center" *ngIf="!loadingSuccess && (successEmpty === 'No Transaction Actions Successful' || successEmpty === 'No Batch Actions Successful')">
                                <ng-container>
                                    <h6 class="text-muted">
                                        <i class="fa fa-"></i> {{successEmpty}}
                                    </h6>
                                </ng-container>
                            </div>
                        </div>
                        <div *ngIf="!loadingFailed && !(successEmpty === 'No Transaction Actions Failed' || successEmpty === 'No Batch Actions Failed')" class="failedAlertDiv grid-wrapper">
                            <div id="failedAlertDiv" class="alert alert-danger">
                                <h6 id="failedHeaderTxn" *ngIf="txnBatchType === 'txn' || txnBatchType === 'settledTxn'" class="no-margin"><i class="fa fa-warning"></i> Failed Transaction(s)</h6>
                                <h6 id="failedHeaderBatch" *ngIf="txnBatchType === 'batch' && type !== 'Clean'" class="no-margin"><i class="fa fa-warning"></i> Failed Batch</h6>
                                <h6 id="successHeaderBatch" *ngIf="txnBatchType === 'batch' && type === 'Clean'" class="no-margin"><i class="fa fa-check"></i> Batch Cleanup Failed</h6>
                            </div>
                            <div *ngIf="!showFailedTable">
                                <app-summary-report [fetchByTxnNumber]=fetchByTxnNumber [actionID]=actionID [fetchByActionID]=fetchByActionID [failedIDs]=failedIDArray [type]=txnBatchType [store]=storeObject [showModal]=showFailedTable [showTxn]=showTxn [showBatch]=showBatch (onCloseModal)="onPrintDetailModalClose($event)"></app-summary-report>
                                <!-- <app-summary-report [failedIDs]=failedIDArray [type]=txnBatchType [store]=storeObject [showModal]=showFailedTable [showTxn]=showTxn [showBatch]=showBatch [hideBatchTxn]=hideBatchTxns (onCloseModal)="onPrintDetailModalClose($event)" (printBatchDetail)="printBatchDetails(try_printing_this)"
                                    (printFinalBatchDetail)="printBatchDetails(false)"></app-summary-report> -->
                            </div>
                            <div class="card no-padding no-margin">
                                <table width="100%" border="0" *ngIf="showFailedTable" class="table table-collapsed  table-sm table-striped no-margin table-grid vertical-content table_main">
                                    <thead class="table_header">
                                        <tr *ngIf='!type.endsWith("Batch")'>
                                            <th>Transaction #</th>
                                            <!-- <th>Action Status</th> -->
                                            <th>Type</th>
                                            <th>Account #</th>
                                            <th>Amount</th>
                                            <th>Tip Amount</th>
                                            <th>Total Amount</th>
                                            <th>Error Message</th>
                                        </tr>
                                        <tr *ngIf='type.endsWith("Batch")'>
                                            <th>Batch #</th>
                                            <!-- <th>Action Status</th> -->
                                            <th>Error Message</th>
                                        </tr>
                                    </thead>
                                    <tbody class="" *ngIf="!loadingFailed">
                                        <ng-container *ngFor='let res of failedObjArray'>

                                            <tr>
                                                <td>
                                                    {{res.num}}
                                                </td>
                                                <td>
                                                    {{res?.type}}
                                                </td>
                                                <td>
                                                    {{res?.maskedAcctNum}}
                                                </td>
                                                <td>
                                                    $ {{res?.amt}}
                                                </td>
                                                <td>
                                                    $ {{res?.tipAmt}}
                                                </td>
                                                <td>
                                                    $ {{res?.totAmt}}
                                                </td>
                                                <!-- <td>
                                            {{res.status}}
                                        </td> -->
                                                <td class="text-danger ">
                                                    {{res.message}}
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                    <tbody *ngIf="loadingFailed">
                                        <tr>
                                            <td style="text-align: center;" [colSpan]="6">
                                                <div class="grid-spinner">
                                                    <div class="bounce1"></div>
                                                    <div class="bounce2"></div>
                                                    <div class="bounce3"></div>
                                                </div>
                                                <span style='color:#3b5877'>Generating Response</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="padder text-center" *ngIf="!loadingFailed && (failedEmpty === 'No Transaction Actions Failed' || failedEmpty === 'No Batch Actions Failed')">
                                <ng-container>
                                    <h6 class="text-muted">
                                        <i class="fa fa-"></i> {{failedEmpty}}
                                    </h6>
                                </ng-container>
                            </div>
                        </div>
                        <div #try_printing_this_2 [hidden]='printSummary' class="print-summary-response">
                            <div class="transaction-action-table">
                                <div class="preview">
                                    <div class="scroll">
                                        <div id="printSummarySuccess" *ngIf="!loadingSuccess && !(successEmpty === 'No Transaction Actions Successful' || successEmpty === 'No Batch Actions Successful')">
                                            <div id="printSummarySuccessHeader" class="alert alert-success">
                                                <h6 *ngIf="txnBatchType === 'txn' || txnBatchType === 'settledTxn'" class="no-margin"><i class="fa fa-check"></i> Successful Transaction(s)</h6>
                                                <h6 *ngIf="txnBatchType === 'batch'" class="no-margin"><i class="fa fa-check"></i> Successful Batch</h6>
                                            </div>
                                        </div>
                                        <div id="printSummaryFailed" *ngIf="!loadingFailed && !(successEmpty === 'No Transaction Actions Failed' || successEmpty === 'No Batch Actions Failed')">
                                            <div id="printSummaryfailedHeader" class="alert alert-danger">
                                                <h6 *ngIf="txnBatchType === 'txn' || txnBatchType === 'settledTxn'" class="no-margin"><i class="fa fa-warning"></i> Failed Transaction(s)</h6>
                                                <h6 *ngIf="txnBatchType === 'batch'" class="no-margin"><i class="fa fa-warning"></i> Failed Batch</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-xs-12  clearfix">
                        <label class="checkbox1 pull-left" style="margin-right: 0px" *ngIf='this.type !== "Settle" && this.type !== "Void" && this.type !== "Redo" && transactionActionService?.paginationModal'>
                            <input type="checkbox" [(ngModel)]="routeToPageList" [ngModelOptions]="{standalone: true}"/>
                            <span></span>
                            Proceed to Page list screen
                        </label>
                        <button type="button" class="btn btn btn-primary pull-right" (click)="txnBatchRespModal.hide()" style="margin-left: 3px !important;" [disabled]="printSummaryLoader || printDetailLoader">Ok
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </button>
                        <button type="button" *ngIf='this.type === "Settle"' class="btn btn-primary" (click)="printSummaryReport(try_printing_this_2)" aria-label="Print" [disabled]="printSummaryLoader || printDetailLoader">
                            <span *ngIf="!printSummaryLoader">
                                <i class="fa fa-print" aria-hidden="true"></i>
                                Print Summary
                            </span>
                            <span *ngIf="printSummaryLoader">
                                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                Printing...
                            </span>
                        </button>
                        <button type="button" *ngIf='this.type === "Settle"' class="btn btn-primary" (click)="printDetails(try_printing_this)" aria-label="Print" [disabled]="printDetailLoader || printSummaryLoader">
                            <span *ngIf="!printDetailLoader">
                                <i class="fa fa-print" aria-hidden="true"></i>
                                Print Details
                            </span>
                            <span *ngIf="printDetailLoader">
                                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                Printing...
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- <app-summary-report [failedIDs]=failedIDArray [type]=txnBatchType [store]=storeObject [showModal]=showFailedTable [showTxn]=showTxn [showBatch]=showBatch [hideBatchTxn]=hideBatchTxns (onCloseModal)="onPrintDetailModalClose($event)" (printBatchDetail)="printBatchDetails(try_printing_this)" (printFinalBatchDetail)="printBatchDetails(false)"></app-summary-report> -->
        </div>
    </div>
</div>