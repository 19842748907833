<div [formGroup]=addressForm>
    <div class="card" formGroupName="xAddr">
        <div class="card-header">
            <label class="">Address</label>
        </div>
        <div class="card-body">
            <div class="form-group row">
                <div class="col-sm-12 col-md-12">
                    <label for="address1">Line 1:
                        <small *ngIf="addressContactValidate" class="text-danger">*</small>
                    </label>
                    <small *ngIf="!this.serverErrors['xAddr.xLine1'] && addressForm.controls.xAddr?.get('xLine1').hasError('required') &&  addressForm.controls.xAddr?.get('xLine1').touched"
                        class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="Line 1 is required" placement="top" container="body" containerClass="tooltip-custom inline-error"
                            aria-hidden="true"></i>
                    </small>
                    <small *ngIf="this.serverErrors['xAddr.xLine1']" class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="{{formErrors.xAddr.xLine1}}" placement="top" container="body"
                            containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                    </small>
                    <input type="text" class="form-control form-control-sm" id="address1" formControlName="xLine1" [ngClass]="{'has-error': (addressForm.controls.xAddr?.get('xLine1').hasError('required') &&  addressForm.controls.xAddr?.get('xLine1').touched)
                                || this.serverErrors['xAddr.xLine1']}" (focus)="clearErrors('xAddr','xLine1')" validateOnBlur>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                    <label for="address2">Line 2: </label>
                    <small *ngIf="formErrors.xAddr.xLine2" class="inline-error"> {{ formErrors.xAddr.xLine2 }}</small>
                    <input type="text" class="form-control form-control-sm" id="address2" formControlName="xLine2" [ngClass]="{'has-error': formErrors.xAddr.xLine2}"
                        validateOnBlur>
                </div>
                <div class="col-sm-12 col-md-6">
                    <label for="suite">Suite: </label>
                    <small *ngIf="formErrors.xAddr.xSuite" class="inline-error"> {{ formErrors.xAddr.xSuite }}</small>
                    <input type="text" class="form-control form-control-sm" id="suite" formControlName="xSuite" [ngClass]="{'has-error': formErrors.xAddr.xSuite}"
                        validateOnBlur>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-6 col-sm-12">
                    <label for="city">City:
                        <small *ngIf="addressContactValidate" class="text-danger">*</small>
                    </label>
                    <small *ngIf="!this.serverErrors['xAddr.xCity'] && addressForm.controls.xAddr?.get('xCity').hasError('required') &&  addressForm.controls.xAddr?.get('xCity').touched"
                        class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="City is required" placement="top" container="body" containerClass="tooltip-custom inline-error"
                            aria-hidden="true"></i>
                    </small>
                    <small *ngIf="!this.serverErrors['xAddr.xCity'] && addressForm.controls.xAddr?.get('xCity').hasError('incorrectCityNameFormat') &&  addressForm.controls.xAddr?.get('xCity').touched"
                        class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="City is of incorrect format. Correct Format-: 1) City can not be more than 20 letters. 2) The first character of City should not be an empty space. 3) The first character of City should not be any special characters, E.g. /,& etc."
                            placement="top" container="body" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                    </small>
                    <small *ngIf="this.serverErrors['xAddr.xCity']" class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="{{formErrors.xAddr.xCity}}" placement="top" container="body"
                            containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                    </small>
                    <input type="text" class="form-control form-control-sm" id="city" formControlName="xCity" [ngClass]="{'has-error': (addressForm.controls.xAddr?.get('xCity').hasError('required') &&  addressForm.controls.xAddr?.get('xCity').touched)
                                || this.serverErrors['xAddr.xCity'] || (addressForm.controls.xAddr?.get('xCity').hasError('incorrectCityNameFormat') && addressForm.controls.xAddr?.get('xCity').touched)}"
                        (focus)="clearErrors('xAddr','xCity')" validateOnBlur>
                </div>
                <div class="col-md-6 col-sm-12">
                    <label for="state">State:
                        <small *ngIf="addressContactValidate" class="text-danger">*</small>
                    </label>
                    <small *ngIf="!this.serverErrors['xAddr.xSOP'] && addressForm.controls.xAddr?.get('xSOP').hasError('required') &&  addressForm.controls.xAddr?.get('xSOP').touched"
                        class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="State is required" placement="top" container="body" containerClass="tooltip-custom inline-error"
                            aria-hidden="true"></i>
                    </small>
                    <small *ngIf="!this.serverErrors['xAddr.xSOP'] && addressForm.controls.xAddr?.get('xSOP').hasError('incorrectStateNameFormat') &&  addressForm.controls.xAddr?.get('xSOP').touched"
                        class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="State is of incorrect format. Correct Format-: 1) State can not be more than 20 letters. 2) The first character of State should not be an empty space. 3) The first character of State should not be any special characters, E.g. /,& etc."
                            placement="top" container="body" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                    </small>
                    <small *ngIf="this.serverErrors['xAddr.xSOP']" class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="{{formErrors.xAddr.xSOP}}" placement="top" container="body" containerClass="tooltip-custom inline-error"
                            aria-hidden="true"></i>
                    </small>
                    <input type="text" class="form-control form-control-sm" id="state" formControlName="xSOP" [ngClass]="{'has-error': (addressForm.controls.xAddr?.get('xSOP').hasError('required') &&  addressForm.controls.xAddr?.get('xSOP').touched)
                                || (addressForm.controls.xAddr?.get('xSOP').hasError('incorrectStateNameFormat') && addressForm.controls.xAddr?.get('xSOP').touched) || this.serverErrors['xAddr.xSOP']}"
                        (focus)="clearErrors('xAddr','xSOP')" validateOnBlur>
                </div>
            </div>
            <div class="form-group row no-margin">
                <div class="col-md-6 col-sm-12">
                    <label for="zip">Post Code:
                        <small *ngIf="addressContactValidate" class="text-danger">*</small>
                    </label>
                    <small *ngIf="!this.serverErrors['xAddr.xPostCode'] && addressForm.controls.xAddr?.get('xPostCode').hasError('required') &&  addressForm.controls.xAddr?.get('xPostCode').touched"
                        class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="Post Code is required" placement="top" container="body" containerClass="tooltip-custom inline-error"
                            aria-hidden="true"></i>
                    </small>
                    <small *ngIf="!this.serverErrors['xAddr.xPostCode'] && addressForm.controls.xAddr?.get('xPostCode').hasError('incorrectPostCodeNameFormat') &&  addressForm.controls.xAddr?.get('xPostCode').touched"
                        class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="Postcode is of incorrect format. Correct Format-: 1) Postcode must be more than 5 letters. 2) Postcode should not contain any special characters."
                            placement="top" container="body" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                    </small>
                    <small *ngIf="this.serverErrors['xAddr.xPostCode']" class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="Field must be a valid postal code" placement="top" container="body"
                            containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                    </small>
                    <input type="text" class="form-control form-control-sm" id="zip" formControlName="xPostCode" [ngClass]="{'has-error': (addressForm.controls.xAddr?.get('xPostCode').hasError('required') &&  addressForm.controls.xAddr?.get('xPostCode').touched)
                                || (addressForm.controls.xAddr?.get('xPostCode').hasError('incorrectPostCodeNameFormat') && addressForm.controls.xAddr?.get('xPostCode').touched) || this.serverErrors['xAddr.xPostCode']}"
                        (focus)="clearErrors('xAddr','xPostCode')" (keypress)="onKeyPress($event)" (paste)="onPaste($event)"
                        validateOnBlur>
                </div>
                <div class="col-md-6 col-sm-12">
                    <label for="country">Country:
                        <small *ngIf="addressContactValidate" class="text-danger">*</small>
                    </label>
                    <small *ngIf="!this.serverErrors['xAddr.xCountry'] && addressForm.controls.xAddr?.get('xCountry').hasError('required') &&  addressForm.controls.xAddr?.get('xCountry').touched"
                        class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="Country is required" placement="top" container="body" containerClass="tooltip-custom inline-error"
                            aria-hidden="true"></i>
                    </small>
                    <small *ngIf="!this.serverErrors['xAddr.xCountry'] && addressForm.controls.xAddr?.get('xCountry').hasError('incorrectCountryNameFormat') &&  addressForm.controls.xAddr?.get('xCountry').touched"
                        class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="Country is of incorrect format. Correct Format-: 1) Country can not be more than 20 letters. 2) The first character of Country should not be an empty space. 3) The first character of Country should not be any special characters, E.g. /,& etc."
                            placement="top" container="body" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                    </small>
                    <small *ngIf="this.serverErrors['xAddr.xCountry']" class="inline-error">
                        <i class="fa fa-exclamation-triangle inline-error" tooltip="{{formErrors.xAddr.xCountry}}" placement="top" container="body"
                            containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                    </small>
                    <input type="text" class="form-control form-control-sm" id="country" formControlName="xCountry" [ngClass]="{'has-error': (addressForm.controls.xAddr?.get('xCountry').hasError('required') &&  addressForm.controls.xAddr?.get('xCountry').touched)
                                 || (addressForm.controls.xAddr?.get('xCountry').hasError('incorrectCountryNameFormat') && addressForm.controls.xAddr?.get('xCountry').touched) || this.serverErrors['xAddr.xCountry']}"
                        (focus)="clearErrors('xAddr','xCountry')" validateOnBlur>
                </div>
            </div>
        </div>
    </div>
</div>