import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import { BaseComponent } from '../../base.component';
import { MainContact, FormalName } from '../../../../../../models';
import { GlobalValidator } from '../../../../../services';
import { phoneMask } from '../../../../utils';

@Component({
    selector: 'app-main-contact-column',
    templateUrl: './main-contact-column.component.html',
    styleUrls: ['./main-contact-column.component.scss']
})
export class MainContactColumnComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() addressContactValidate:boolean=true;
    // @Input() parentContact;
    @Input() mainContact: MainContact;
    // @Input() formalName:FormalName;
    @Input() mainContactForm: FormGroup;
    // @Input() mainContactForm: FormGroup;
    @Input() serverErrors: any = {};
    @Input() tagType:string;

    mask = phoneMask;

    constructor(public fb: FormBuilder) {
        super();
        this.formErrors = {
            xMainContact: {
                // xFirstName: '',
                // xLastName: '',
                xEmail: '',
                xEmailTypeTag:'',
                xPhone: '',
                xPhoneTypeTag:''
            }
        };
    }

    ngOnChanges(change: SimpleChanges) {
        if (change.hasOwnProperty('mainContact') && !change.mainContact.firstChange) {
            if (!this.mainContactForm.controls['xMainContact']) {
                this.mainContactForm.addControl('xMainContact', this.toFormGroup(new MainContact(this.mainContact)));
            }
            this.mainContactForm.controls['xMainContact'].patchValue(new MainContact(this.mainContact));
        }

        // if(change.hasOwnProperty('parentContact') && !change.parentContact.firstChange){
        //     console.log("parent contact in changes", JSON.parse(JSON.stringify(this.parentContact)));
        //     if(!this.mainContactForm.controls['xMainContact']){
        //         this.mainContactForm.addControl('xMainContact', this.toFormGroup(new MainContact(this.parentContact)));
        //     }
        //     this.mainContactForm.controls['xMainContact'].patchValue(new MainContact(this.parentContact));
        //     // if (this.tagType === 'Company Business') {
        //     //     this.addr.controls['xCO'].disable();
        //     //     this.addr.controls['xLine1'].disable();
        //     //     this.addr.controls['xLine2'].disable();
        //     //     this.addr.controls['xSuite'].disable();
        //     //     this.addr.controls['xCity'].disable();
        //     //     this.addr.controls['xSOP'].disable();
        //     //     this.addr.controls['xPostCode'].disable();
        //     //     this.addr.controls['xCountry'].disable();
        //     // }
        // }

        if (change.hasOwnProperty('serverErrors') && !change.serverErrors.firstChange) {
            this.validateServerErrors();
        }
    }

    ngOnInit() {
        // if(this.parentContact){
        //     this.mainContactForm.addControl('xMainContact', this.toFormGroup(new MainContact(this.parentContact)));
        // }
        // else{
            this.mainContactForm.addControl('xMainContact', this.toFormGroup(new MainContact(this.mainContact)));
        // }
        // this.contact = this.toFormGroup(new MainContact(this.mainContact));
        // this.contact.valueChanges
        //     .subscribe(data => this.onValueChanged(this.mainContactForm, data));

        this.onValueChanged(this.mainContactForm); // (re)set validation messages now
    }

    set contact(value: FormGroup) {
        this.mainContactForm.controls['xMainContact'] = value;
    }

    get contact() {
        return this.mainContactForm.get('xMainContact') as FormGroup;
    }

    public toFormGroup(data: MainContact): FormGroup {
        const formGroup = this.fb.group({
            // xFirstName: [data.xFirstName || '', Validators.required],
            // xLastName: [data.xLastName || '', Validators.required],
            xEmail: [data.xEmail || ''],
            xEmailTypeTag: [data.xEmailTypeTag || '',[]],
            xPhone: [data.xPhone || ''],
            xPhoneTypeTag: [data.xPhoneTypeTag || '', []],

        });
        if(this.addressContactValidate){
            formGroup.controls['xEmail'].setValidators([Validators.compose([GlobalValidator.mailFormat, Validators.required])]);
            formGroup.controls['xPhone'].setValidators([Validators.required]);
        }
        return formGroup;
    }

    onKeypress(event: any) {
        GlobalValidator.phoneNumberFormat(event);
    }

    onPaste(event: any) {
        GlobalValidator.phoneNumberFormat(event);
    }

}
