import { Component, ViewChild, AfterViewInit, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, forkJoin } from 'rxjs';
import { NotificationService, HttpService } from '../../services';
import { SetupPermissionsStore } from '../../../store/index';

const NO_PERMISSION_ADDED_MESSAGE = 'No Permission is added for this Role.';

@Component({
	selector: 'preview-permission-modal-cmp',
	templateUrl: './preview-permission-modal.component.html',
	styleUrls: ['./preview-permission-modal.component.scss']
})
export class PreviewPermissionModalComponent implements OnInit, AfterViewInit {
	@Output() onCloseModal = new EventEmitter<boolean>();
	@Input() roleIDs: any[]; // catches array of roleIDs as Input
	@ViewChild('previewPermissionModal') public previewPermissionModal: ModalDirective;

	loadingPreview: boolean = false;
	previewList: any[] = [];

	public NO_PERMISSION_ADDED_MESSAGE: string = NO_PERMISSION_ADDED_MESSAGE;

	constructor(
		private notificationService: NotificationService,
		private httpService: HttpService,
		private setupPermissionsStore: SetupPermissionsStore
	) {}

	ngAfterViewInit() {
		this.previewPermissionModal.show();
	}

	ngOnInit(): void {
		const payload: any = {};
		payload.roles = this.roleIDs;
		this.loadingPreview = true;
		this.httpService.authenticatedAPI=false;
		forkJoin(
			this.setupPermissionsStore.loadPermissionsForPreview('previewPermission', payload)
		).subscribe(
			(response: any) => {
				this.httpService.authenticatedAPI=true;
				this.previewList = JSON.parse(JSON.stringify(response[0].data));
				console.log('previewList: ngOnInit', this.previewList);
			},
			(error: any) => {
				this.httpService.authenticatedAPI=true;
				this.loadingPreview = false;
				try {
					const errMsg = JSON.parse(error._body).message;
					this.notificationService.error(errMsg, ' ERROR');
				} catch (err) {
					this.notificationService.error('Something went Wrong', 'ERROR');
				}
				this.previewPermissionModal.hide();
			},
			() => {
				this.loadingPreview = false;
			}
		);
	}

	onHideModal(event: any) {
		this.onCloseModal.emit(false);
	}
}
