<div
	bsModal
	#importTxnModal="bs-modal"
	class="modal fade"
	tabindex="-1"
	role="dialog"
	aria-labelledby="myLargeModalLabel"
	aria-hidden="true"
	[config]="{ backdrop: 'static' }"
	*ngIf="showImportTxnModal"
>
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="closeModal()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">Import Transactions</h4>
			</div>
			<!-- global spinner beginning -->
			<div *ngIf="modalLoader" class="modal-body">
				<div style="text-align: center; position: realtive">
					<div class="col-md-12">
						<div class="grid-spinner">
							<div class="bounce1"></div>
							<div class="bounce2"></div>
							<div class="bounce3"></div>
						</div>
						<span style="color: #3b5877">Fetching data... Please wait.</span>
					</div>
				</div>
			</div>
			<!-- global spinner ending -->
			<!-- modal body beginning -->
			<ng-container *ngIf="!modalLoader">
				<div class="modal-body pb-0">
					<form class="form-horizontal" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
						<div class="form-group row">
							<!-- pctr beginning -->
							<div class="col-md-4">
								<div>
									<label for="pctr">Profit Center: </label>
								</div>
								<div>
									<span> {{ pctrName }}</span>
								</div>
							</div>
							<div class="col-md-4 pl-5">
								<div>
									<label for="GMID">GMID: </label>
								</div>
								<div>
									<span> {{ GMID }}</span>
								</div>
							</div>
							<div class="col-md-4">
								<div>
									<label for="GMPW">GMPW: </label>
								</div>
								<div>
									<span> {{ GMPW }}</span>
								</div>
							</div>
							<!-- pctr ending -->
						</div>
						<div class="form-group row">
							<!-- terminal dropdown beginning -->
							<div class="col-md-4">
								<label for="terminal">Terminal: <small class="text-danger">*</small> </label>
								<div class="merch-info">
									<div class="merch_type">
										<select
											formControlName="xTerminalID"
											[ngClass]="{
												'has-error': errors.termID
											}"
											[(ngModel)]="selectedTermID"
											(change)="onSelectTerminal()"
											class="form-control form-control-sm"
										>
											<option *ngFor="let termObj of terminalDropDownArr" [value]="termObj._id">
												{{ termObj?.xName }}
											</option>
										</select>
										<span>
											<i class="fa fa-angle-down"></i>
										</span>
									</div>
								</div>
							</div>
							<!-- terminal dropdown ending -->
							<div *ngIf="selectedGTID" class="col-md-4 pl-5">
								<div>
									<label for="GTID">GTID: </label>
								</div>
								<div>
									<span>{{ selectedGTID }}</span>
								</div>
							</div>
						</div>
						<div class="form-group row">
							<!-- def file beginning -->
							<div class="col-md-4">
								<label for="defFile">Definition file:<small class="text-danger">*</small> </label>
								<div class="merch-info">
									<div class="merch_type">
										<select
											formControlName="definitionFileID"
											[(ngModel)]="selectedDefFileID"
											(change)="onSelectDefFile()"
											class="form-control form-control-sm"
											[ngClass]="{
												'has-error': errors.defFile
											}"
										>
											<option *ngFor="let defObj of defDropDownArray" [value]="defObj.id">
												{{ defObj?.name }}
											</option>
										</select>
										<span>
											<i class="fa fa-angle-down"></i>
										</span>
									</div>
								</div>
							</div>
							<!-- def file ending -->
							<!-- upload file beginning -->
							<div class="col-md-4 d-flex flex-column pl-5">
								<label for="importFile"
									>Upload file: *
									<small *ngIf="errors.importFile" class="inline-error float-none">
										<i
											class="fa fa-exclamation-triangle inline-error float-none ml-2"
											container="body"
											tooltip="{{errors.importFile}}"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>
								</label>
								<div class="merch-info d-flex flex-row">
									<div>
										<label for="fileUploader" class="no-margin btn btn-info btn-sm add-btn m-b-5">
											<i class="fa fa-upload" aria-hidden="true"></i>
											Upload</label
										>
									</div>
									<small
										*ngIf="uploadedFile"
										class="mt-1 ml-1"
										container="body"
										[tooltip]="uploadedFile.name"
										containerClass="tooltip-global"
									>
										{{ reduceFileNameLength(uploadedFile.name, 10, 10) }}
									</small>
								</div>
							</div>
							<input
								id="fileUploader"
								name="fileUpload"
								accept=".txt, .csv, .EFT, .eft"
								multiple="false"
								type="file"
								style="visibility: hidden; position: absolute; top: 0"
								(change)="fileUpload($event.target.files)"
							/>
							<!-- upload file ending -->
						</div>
						<div *ngIf="showInfo" class="alert alert-info text-center">
							<b>Import action </b> takes few minutes. <br>
							Please check <b>Outstanding Transactions</b> after 5 minutes. You may need to click <b>Refresh</b> to update the screen.<br>
							You can also check <b> Reports -> Import Reports. </b> 
						</div>
						<div class="modal-footer">
							<button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
							<button class="btn btn-primary ml-1" type="submit" [disabled]="submitting">
								<span *ngIf="!submitting">
									Proceed
									<i class="fa fa-angle-double-right" aria-hidden="true"></i>
								</span>
								<span *ngIf="submitting"
									>Please wait...
									<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
								</span>
							</button>
						</div>
					</form>
				</div>
			</ng-container>
			<!-- modal body ending -->
		</div>
	</div>
</div>
