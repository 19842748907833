export class SysInfo {
    xMakeTag: string;
    xFamilyTag: string;
    xModelTag: string;
    xModelNum: string;
    xSerNum: string;
    xVersion: string;

    constructor(obj?: any) {
        this.xMakeTag = (obj && obj.xMakeTag) || '';
        this.xFamilyTag = (obj && obj.xFamilyTag) || '';
        this.xModelTag = (obj && obj.xModelTag) || '';
        this.xModelNum = (obj && obj.xModelNum) || '';
        this.xSerNum = (obj && obj.xSerNum) || '';
        this.xVersion = (obj && obj.xVersion) || '';
    }
}
