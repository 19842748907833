import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CompanySuspendedService } from 'app/pages/company-suspended/company-suspended.service';
import { Observable, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationService } from './notification-service';
import { Business, Permissions } from '../../models';

import { TAGSCONSTS } from './../../constants';
import { HttpService } from './http-service';
import { XpebillingService } from './xpebilling.service';

const userEndPoint = 'userContext';
const permsEndpoint = 'xscEntyPerms';

const VENDOR = 'vendor';
const DEALER = 'dealer';
const BUSINESS_EMPLOYEE = 'employee';
const CUSTOMER = 'customer';

class Role {
	isVendor: boolean;
	isDealer: boolean;
	isBusinessEmployee: boolean;
	isCustomer: boolean;
}

@Injectable()
/**
 * @brief      Class for user handler service.
 *             Fetches and Stores user related information such as user details, roles and permissions
 */
export class UserHandlerService {
	private _user: any = {};
	private _userRoles: Role = {
		isVendor: false,
		isDealer: false,
		isBusinessEmployee: false,
		isCustomer: false,
	};

	private _userTypeDetails: any; //Representative or Employee
	private _organisationDetails: Business | any; // Compnay or Business
	private _companyRequestSubscription = Subscription;

	roleObject: BehaviorSubject<Role> = new BehaviorSubject<Role>(null);
	watchRole$ = this.roleObject.asObservable(); // Watch if roleObject is changed

	userPerms: Permissions;

	constructor(
		protected httpService: HttpService,
		protected xpebillingService: XpebillingService,
		private notificationService: NotificationService,
		private router: Router,
		private companySuspendService: CompanySuspendedService
	) {}

	setUserData(userData: any): void {
		this._user = userData;
		this.setUserRoles(this._user.xRoles);
	}

	get userData(): any {
		return this._user;
	}

	get userRoles(): Role {
		return this._userRoles;
	}

	set organisation(organisationDetails: Business | any) {
		this._organisationDetails = organisationDetails;
	}

	get organisation(): any {
		return this._organisationDetails;
	}

	set userType(userTypeDetails: any) {
		this._userTypeDetails = userTypeDetails;
	}

	get userType(): any {
		return this._userTypeDetails;
	}

	set userRoles(role: Role) {
		console.log('userRoles', role);
		this._userRoles = {
			isVendor: !!role.isVendor,
			isDealer: !!role.isDealer,
			isBusinessEmployee: !!role.isBusinessEmployee,
			isCustomer: !!role.isCustomer,
		};
		this.roleObject.next(role);
	}

	resetUserData() {
		this._user = {};
		this.userRoles = new Role();
		this._userTypeDetails = {};
		this._organisationDetails = {};
	}

	setUserRoles(roles: any[]) {
		let selected = false;
		console.log('setUserRoles: roles', roles);
		roles.map((role) => {
			if (!selected) {
				switch (role.xTag) {
					case TAGSCONSTS.roleTags.roleItemTags.vendorRole:
						selected = true;
						this._userRoles.isVendor = true;
						// await this.checkCompanySuspend(VENDOR, role.xCompanyID);
						break;
					case TAGSCONSTS.roleTags.roleItemTags.representativeRole:
						selected = true;
						this._userRoles.isDealer = true;
						// await this.checkCompanySuspend(DEALER, role.xCompanyID);
						break;
					case TAGSCONSTS.roleTags.roleItemTags.employeeRole:
						selected = true;
						this._userRoles.isBusinessEmployee = true;
						// await this.checkCompanySuspend(BUSINESS_EMPLOYEE, role.xCompanyID);
						break;
					case TAGSCONSTS.roleTags.roleItemTags.customerRole:
						selected = true;
						this._userRoles.isCustomer = true;
						// await this.checkCompanySuspend(CUSTOMER, role.xCompanyID);
						break;
				}
			}
		});
		console.log('setUserRoles: userRole', this._userRoles);
	}
	
	checkCompanyStatus(businessID: string){
		// this.setUserRoles(this._user.xRoles);
		let selected = false;
		this._user.xRoles.map(async (role) => {
			console.log('checkCompanyStatus: roles', role);
			if (!selected) {
				switch (role.xTag) {
					case TAGSCONSTS.roleTags.roleItemTags.vendorRole:
						selected = true;
						break;
					case TAGSCONSTS.roleTags.roleItemTags.representativeRole:
						selected = true;
						await this.checkCompanySuspendStatus(TAGSCONSTS.roleTags.roleItemTags.vendorRole, role.xCompanyID, "");
						break;
					case TAGSCONSTS.roleTags.roleItemTags.employeeRole:
						selected = true;
						await this.checkCompanySuspendStatus(TAGSCONSTS.roleTags.roleItemTags.vendorRole, role.xCompanyID, "");
						await this.checkCompanySuspendStatus(TAGSCONSTS.roleTags.roleItemTags.representativeRole, role.xCompanyID, businessID);
						break;
					case TAGSCONSTS.roleTags.roleItemTags.customerRole:
						selected = true;
						await this.checkCompanySuspendStatus(TAGSCONSTS.roleTags.roleItemTags.vendorRole, role.xCompanyID, "");
						await this.checkCompanySuspendStatus(TAGSCONSTS.roleTags.roleItemTags.representativeRole, role.xCompanyID, businessID);
						break;
				}
			}
		});
	}

	async checkCompanySuspendStatus(role:string, companyID:string, businessID:string){
		try {
			const response = await this.xpebillingService.getBillingUsingCompanyIDAndBID(role, businessID, companyID, true, false);
			const { data: billingData } = response;
			console.log('checkCompanySuspendStatus:', billingData);
			if (Array.isArray(billingData) && billingData.length) {
				this.companySuspendService.dueBillingDate.next(billingData[0].xDueBillingDate);
				if (billingData[0].xIsSuspended) {
					this.setUserPermissions(this.userData ? this.userData.permissions : {});
					this.notificationService.error('Your company has been suspended for nonpayment of dues', 'Company Suspend Check');
					this.companySuspendService.role.next(role);
					this.companySuspendService.isSuspended.next(true);
					this.companySuspendService.amount.next(billingData[0].xAmt);
					this.companySuspendService.vendorTerminalID.next(billingData[0].xVendorTerminalID);
					this.companySuspendService.customerBusinessName.next(billingData[0].xBusinessName);
					this.companySuspendService.customerBusinessID.next(billingData[0].xBusinessID);
					this.companySuspendService.isSuspend = true;
					this.router.navigate(['/company-suspended']);
				}
			}
		} catch (error) {
			this.notificationService.error(error.message, 'Company Suspend Check Error');
			if (localStorage.getItem('token')) localStorage.removeItem('token');
			else sessionStorage.removeItem('token');
			localStorage.removeItem('rememberMe');
			this.resetUserData();
			this.companySuspendService.isSuspend = false;
			this.companySuspendService.amount.next(0);
			this.companySuspendService.isSuspended.next(false);
			this.companySuspendService.dueBillingDate.next('');
			this.companySuspendService.role.next('');
			this.router.navigate['/login'];
		}
	}

	async checkCompanySuspend(role: string, companyID: string) {
		try {
			const response = await this.xpebillingService.getBillingUsingCompanyID(companyID, true, false);
			const { data: billingData } = response;
			console.log('billingData for isCurrent:::', billingData);
			switch (role) {
				case VENDOR:
					break;
				case DEALER:
				case BUSINESS_EMPLOYEE:
				case CUSTOMER:
					if (Array.isArray(billingData) && billingData.length) {
						this.companySuspendService.dueBillingDate.next(billingData[0].xDueBillingDate);
						if (billingData[0].xIsSuspended) {
							this.notificationService.error('Your company has been suspended for nonpayment of dues', 'Company Suspend Check');
							this.companySuspendService.role.next(role);
							this.companySuspendService.isSuspended.next(true);
							this.companySuspendService.amount.next(billingData[0].xAmt);
							this.companySuspendService.vendorTerminalID.next(billingData[0].xVendorTerminalID);
							this.companySuspendService.customerBusinessName.next(billingData[0].xBusinessName);
							this.companySuspendService.customerBusinessID.next(billingData[0].xBusinessID);
							this.companySuspendService.isSuspend = true;
							this.router.navigate(['/company-suspended']);
						}
					}
					break;
			}
		} catch (error) {
			this.notificationService.error(error.message, 'Company Suspend Check Error');
			if (localStorage.getItem('token')) localStorage.removeItem('token');
			else sessionStorage.removeItem('token');
			localStorage.removeItem('rememberMe');
			this.resetUserData();
			this.companySuspendService.isSuspend = false;
			this.companySuspendService.amount.next(0);
			this.companySuspendService.isSuspended.next(false);
			this.companySuspendService.dueBillingDate.next('');
			this.companySuspendService.role.next('');
			this.router.navigate['/login'];
		}
	}

	setUserPermissions(permissions: Permissions) {
		console.log('permissinos', permissions);
		this.userPerms = permissions;
		console.log('uer perms', this.userPerms);
	}

	fetchUserInformation(): Observable<Object> {
		return this.httpService.getAll(userEndPoint, {}, true).pipe(
			map((response: Response) => {
				return response;
			})
		);
	}

	fetchUserPermission(roleID: string): Observable<Object> {
		return this.httpService.getAll(permsEndpoint, { xEntyID: roleID }, true).pipe(
			map((response: Response) => {
				return response;
			})
		);
	}

	fetchEmployeeDetails(entityID: string): Observable<Object> {
		return this.httpService.get('xbcEmployees', entityID).pipe(
			map((response: Response) => {
				return response;
			})
		);
	}

	fetchCustomerDetails(entityID: string): Observable<Object> {
		return this.httpService.get('xbcCustomers', entityID).pipe(
			map((response: Response) => {
				return response;
			})
		);
	}

	fetchRepresentativeDetails(entityID: string): Observable<Object> {
		return this.httpService.get('xbcReps', entityID).pipe(
			map((response: Response) => {
				return response;
			})
		);
	}

	fetchBusinessDetails(id: string): Observable<Object> {
		return this.httpService.get('xbcBusinesses', id).pipe(
			map((response: Response) => {
				return response;
			})
		);
	}

	fetchCompanyDetails(id: string): Observable<Object> {
		return this.httpService.get('xbcCompanies', id).pipe(
			map((response: Response) => {
				return response;
			})
		);
	}
}
