<div *ngIf="isLoading">
	<div style="text-align: center;">
		<!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
        <span class="sr-only">Loading...</span> -->
		<div class="grid-spinner">
			<div class="bounce1"></div>
			<div class="bounce2"></div>
			<div class="bounce3"></div>
		</div>
		<span style="color:#3b5877">Loading</span>
	</div>
</div>
<div *ngIf="!isLoading && !userHandlerService.userRoles.isVendor" [class.expand]="showTree" class="full-content-wrapper">
	<div class="sidebar-tree-wrapper" [ngClass]="{ 'disable-form': !enableTree }" *ngIf="!userHandlerService.userRoles.isCustomer">
		<tree-cmp
			[addAction]="treeAddAction"
			[buttonLinks]="treeButtonLinks"
			[nodeLinks]="treeNodeLinks"
			(nodeClicked)="onNodeClick($event)"
		></tree-cmp>
		<!--<a class="collapse-tree" (click)="toggleShowTree()"></a>-->
	</div>
	<div *ngIf="userHandlerService.userRoles.isCustomer" class="sidebar-store-details">
		<div class="customer-login-wrap">
			<!-- Store Name: {{loginStoreObj?.storeName}}
        <br> Store Phone Number: {{loginStoreObj?.storePhoneNum}}
        <br> Store Address: {{loginStoreObj?.storeAddress}}
        <br> Store Email: {{loginStoreObj?.storeEmail}} -->
			<div *ngIf="!fetchingData" class="customer-store-info-wrap text-left customer-store-info-wrap-in-webapp">
				<div class="customer-store-info-inner">
					<div class="login-wrap login-wrap-left">
						<div class="login-inner store-info-wrap">
							<div class="store-img">
								<img *ngIf="loginStoreObj.image" src="{{ loginStoreObj?.image }}" class="user-avatar img-size p-l-25" />
							</div>
							<!-- <h3>Store Info</h3> -->

							<div class="px-15">
								<div class="d-flex py-10 flex-wrap">
									<i class="fa fa-map-marker store-label-icon"></i>
									<div style="flex:1">
										<h5 class="detail-label">{{ loginStoreObj?.storeName }}</h5>
										<p class="detail-label">{{ loginStoreObj?.storeAddress }}</p>
									</div>
								</div>

								<div class="d-flex py-10 flex-wrap">
									<i class="fa fa-phone store-label-icon"></i>
									<p class="detail-label">{{ loginStoreObj?.storePhoneNum }}</p>
								</div>
								<div class="d-flex py-10 flex-wrap">
									<i class="fa fa-envelope store-label-icon"></i>
									<p class="detail-label">{{ loginStoreObj?.storeEmail }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="customer-login-footer">
                        Powered by <a href="https://portal.c9pg.com/" target="_blank"><img src="assets/images/cloud9-logo.png" /></a>
                    </div> -->
			</div>
			<!-- <app-store-info></app-store-info> -->
		</div>
	</div>
	<div class="main-tabbed-content">
		<div class="inner-menu-wrap" *ngIf="!userHandlerService.userRoles.isCustomer">
			<ul class="nav nav-justified inner-menu">
				<li class="nav-item" *ngFor="let item of tabItems">
					<a class="nav-link" [routerLink]="[item.link]" [routerLinkActive]="['active']" (click)="onTabBtnClick()">{{
						item.label
					}}</a>
				</li>
			</ul>
		</div>
		<div class="inner-content">
			<div class="main-content-inner">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>
<!-- </ng-container> -->
<div class="main-tabbed-content" *ngIf="userHandlerService.userRoles.isVendor">
	<router-outlet></router-outlet>
</div>
