import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TransactionComponent } from './transaction.component';
// import { OutstandingTransactionsComponent } from './components';

const routes: Routes = [
	{ path: '', component: TransactionComponent }
	// { path: '', component: OutstandingTransactionsComponent },
	// {
	// 	path: 'outstanding-transactions/:batchID',
	// 	component: OutstandingTransactionsComponent
	// },
	// {
	// 	path: 'outstanding-transactions/:custID',
	// 	component: OutstandingTransactionsComponent
	// }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TransactionRoutingModule {}