import {
    Component,
    OnInit,
    Input,
    Output,
    SimpleChanges,
    EventEmitter
} from "@angular/core";

import { TAGSCONSTS } from "../../../../../constants";
import {
    HttpService,
    ConstantService,
    SummaryReportService
} from "../../../../../shared/services";
import { forkJoin } from "rxjs";

@Component({
    selector: "app-pagination-modal",
    templateUrl: "./pagination-modal.component.html",
    styleUrls: ["./pagination-modal.component.scss"]
})
export class PaginationModalComponent implements OnInit {
    @Input() showPaginationModal: boolean = false;
    @Input() totalRecords: number = 0;
    @Input() totalPages: number[] = [];
    @Input() actionType: string = "";
    @Input() parameters: any = {};
    @Input() storeObject: any;
    @Input() type:string;
    @Input() donePages: number[] = [];

    @Output() finalTxnsDetailsObj = new EventEmitter<any>();
    @Output() onCloseModal = new EventEmitter<boolean>();

    alive: boolean = true;
    printLoader:boolean = false;
    performing: boolean = false;
    performingActionOnPage: number = 0;

    constructor(
        private httpService: HttpService,
        private constantService: ConstantService,
        private summaryReportService: SummaryReportService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {}

    ngOnInit(): void {
        console.log("this.parameters", this.parameters, this.donePages);
    }

    onPaginationModalClose(cancel: boolean = false) {
        console.log("Cancel===>", cancel);
        this.showPaginationModal = false;
        this.onCloseModal.emit(cancel);
    }

    checkDone(page) {
        if (this.donePages.length && this.donePages.indexOf(page) >= 0)
            return true;
        return false;
    }

    onPerformActionClick(page, type) {
        console.log("Page===>", page);
        this.performingActionOnPage = page;
        console.log("Pramters and store", this.parameters, this.storeObject);
        let params = JSON.parse(JSON.stringify(this.parameters));

        params["page"] = page;
        if (type === "Print Detail" || type === "Re-Print") {
            params["limit"] = 500;
        }
        else{
            params["limit"] = 100;
        }
        params["fetchAll"] = false;
        params["hashData"] = true;
        // params['keyword']=null;
        this.performing = true;
        this.storeObject.getAll(JSON.parse(JSON.stringify(params))).subscribe(res => {
            console.log("Response------>", res);
            let hashedData = [];
            if (
                (type === "Finalize" || type === "Modify") &&
                (res && res.hasOwnProperty("data") && res.data.length)
            ) {
                this.constantService
                    .updateTransactionData(JSON.parse(JSON.stringify(res.data)))
                    .then(updatedData => {
                        let txnDetails = [];
                        let pctrParams = {
                            PCtrIDs: []
                        };
                        updatedData.map(singleData => {
                            pctrParams["PCtrIDs"].push(singleData.xPCtrID);
                            txnDetails.push({
                                txnId: singleData._id,
                                txnPctrId: singleData.xPCtrID,
                                txnNumber: singleData.xTxnNum,
                                txnAmtObj: {
                                    id: singleData._id,
                                    baseAmt: singleData.xMainAmt,
                                    tipAmt: singleData.xTipAmt,
                                    taxAmt: singleData.xIncTaxAmt
                                }
                            });
                            if (
                                singleData.hasOwnProperty("xPullSyncInfo") &&
                                singleData["xPullSyncInfo"].hasOwnProperty(
                                    "xHasPendingSync"
                                ) &&
                                singleData.xPullSyncInfo.xHasPendingSync
                            ) {
                                txnDetails[txnDetails.length - 1][
                                    "disabled"
                                ] = true;
                            } else {
                                if (
                                    res.hasOwnProperty("meta") &&
                                    res["meta"].hasOwnProperty("hashedData") &&
                                    res.meta["hashedData"].length
                                ) {
                                    hashedData.push(
                                        res.meta["hashedData"].filter(
                                            singleHashedData =>
                                                singleHashedData.record_id ===
                                                singleData._id
                                        )[0]
                                    );
                                }
                                txnDetails[txnDetails.length - 1][
                                    "disabled"
                                ] = false;
                            }
                        });

                        this.httpService
                            .store("pCtrIndstTags", pctrParams)
                            .subscribe((response: any) => {
                                console.log("HERE___>", response);
                                switch (type) {
                                    case "Finalize":
                                        let tipTxns = [];
                                        let finalizeTxns = [];
                                        // this.finalizeTxnArr = [];
                                        // this.tipTxnObj = [];
                                        txnDetails.map(singleTxnData => {
                                            response.map(value => {
                                                if (
                                                    value.indstType ===
                                                        TAGSCONSTS.industryTags
                                                            .industryItemTags
                                                            .restaurant ||
                                                    value.indstType ===
                                                        TAGSCONSTS.industryTags
                                                            .industryItemTags
                                                            .retail
                                                ) {
                                                    if (
                                                        singleTxnData.txnPctrId ===
                                                        value.pCtrID
                                                    ) {
                                                        console.log(
                                                            "Add tip",
                                                            singleTxnData
                                                        );
                                                        tipTxns.push({
                                                            id:
                                                                singleTxnData.hasOwnProperty('txnId')? singleTxnData.txnId: null,
                                                            txnNum:
                                                                singleTxnData.hasOwnProperty('txnNumber')? singleTxnData.txnNumber: null,
                                                            tipAmt:
                                                                singleTxnData.hasOwnProperty('txnAmtObj') &&
                                                                singleTxnData
                                                                    .txnAmtObj
                                                                    .hasOwnProperty('tipAmt')
                                                                    ? singleTxnData.txnAmtObj.tipAmt.substring(
                                                                          1
                                                                      )
                                                                    : "0.00",
                                                            disabled: singleTxnData.hasOwnProperty(
                                                                "disabled"
                                                            )
                                                                ? singleTxnData.disabled
                                                                : false
                                                        });
                                                    }
                                                } else {
                                                    if (
                                                        singleTxnData.txnPctrId ===
                                                        value.pCtrID
                                                    ) {
                                                        console.log(
                                                            "Finalize",
                                                            singleTxnData
                                                        );
                                                        // if(singleTxnData.hasOwnProperty('xPullSyncInfo') && singleTxnData['xPullSyncInfo'].hasOwnProperty('xHasPendingSync') && !singleTxnData.xPullSyncInfo.xHasPendingSync){
                                                        if (
                                                            singleTxnData.hasOwnProperty(
                                                                "disabled"
                                                            ) &&
                                                            !singleTxnData.disabled
                                                        ) {
                                                            finalizeTxns.push(
                                                                singleTxnData.hasOwnProperty('txnId')? singleTxnData.txnId: null
                                                            );
                                                        }
                                                    }
                                                }
                                            });
                                        });
                                        console.log(
                                            "tipTxn and finalizeTxn===>",
                                            tipTxns,
                                            tipTxns.length,
                                            finalizeTxns,
                                            finalizeTxns.length,
                                            hashedData,
                                            hashedData.length
                                        );

                                        this.finalTxnsDetailsObj.emit({
                                            tipTxnsDetail: tipTxns,
                                            finalizeTxnIds: finalizeTxns,
                                            actionType: "finalize",
                                            currPage: page,
                                            filteredHashedData: hashedData
                                        });
                                        break;

                                    case "Modify":
                                        let editTxns = [];
                                        //   response.map(value => {
                                        //       console.log(
                                        //           "value inside pctr response",
                                        //           value
                                        //       );
                                        txnDetails.map(singleTxnDetail => {
                                            response.map(value => {
                                                if (
                                                    singleTxnDetail.txnPctrId ===
                                                    value.pCtrID
                                                ) {
                                                    console.log(
                                                        "true case ",
                                                        // pctrId,
                                                        value.pCtrID
                                                    );
                                                    if (
                                                        value &&
                                                        value.hasOwnProperty(
                                                            "indstType"
                                                        ) &&
                                                        (value.indstType ===
                                                            TAGSCONSTS
                                                                .industryTags
                                                                .industryItemTags
                                                                .restaurant ||
                                                            value.indstType ===
                                                                TAGSCONSTS
                                                                    .industryTags
                                                                    .industryItemTags
                                                                    .retail)
                                                    ) {
                                                        editTxns.push({
                                                            txnId:
                                                                singleTxnDetail &&
                                                                singleTxnDetail.hasOwnProperty(
                                                                    "txnId"
                                                                )
                                                                    ? singleTxnDetail.txnId
                                                                    : null,
                                                            txnNum:
                                                                singleTxnDetail &&
                                                                singleTxnDetail.hasOwnProperty(
                                                                    "txnNumber"
                                                                )
                                                                    ? singleTxnDetail.txnNumber
                                                                    : null,
                                                            tipAmt:
                                                                singleTxnDetail.hasOwnProperty(
                                                                    "txnAmtObj"
                                                                ) &&
                                                                singleTxnDetail.txnAmtObj.hasOwnProperty(
                                                                    "tipAmt"
                                                                )
                                                                    ? singleTxnDetail.txnAmtObj.tipAmt.substring(
                                                                          1
                                                                      )
                                                                    : "0.00",
                                                            baseAmt:
                                                                singleTxnDetail.hasOwnProperty(
                                                                    "txnAmtObj"
                                                                ) &&
                                                                singleTxnDetail.txnAmtObj.hasOwnProperty(
                                                                    "baseAmt"
                                                                )
                                                                    ? singleTxnDetail.txnAmtObj.baseAmt.substring(
                                                                          1
                                                                      )
                                                                    : "0.00",
                                                            taxAmt:
                                                                singleTxnDetail.hasOwnProperty(
                                                                    "txnAmtObj"
                                                                ) &&
                                                                singleTxnDetail.txnAmtObj.hasOwnProperty(
                                                                    "taxAmt"
                                                                )
                                                                    ? singleTxnDetail.txnAmtObj.taxAmt.substring(
                                                                          1
                                                                      )
                                                                    : "0.00"
                                                        });
                                                    } else {
                                                        editTxns.push({
                                                            txnId:
                                                                singleTxnDetail &&
                                                                singleTxnDetail.hasOwnProperty(
                                                                    "txnId"
                                                                )
                                                                    ? singleTxnDetail.txnId
                                                                    : null,
                                                            txnNum:
                                                                singleTxnDetail &&
                                                                singleTxnDetail.hasOwnProperty(
                                                                    "txnNumber"
                                                                )
                                                                    ? singleTxnDetail.txnNumber
                                                                    : null,
                                                            baseAmt:
                                                                singleTxnDetail.hasOwnProperty(
                                                                    "txnAmtObj"
                                                                ) &&
                                                                singleTxnDetail.txnAmtObj.hasOwnProperty(
                                                                    "baseAmt"
                                                                )
                                                                    ? singleTxnDetail.txnAmtObj.baseAmt.substring(
                                                                          1
                                                                      )
                                                                    : "0.00",
                                                            taxAmt:
                                                                singleTxnDetail.hasOwnProperty(
                                                                    "txnAmtObj"
                                                                ) &&
                                                                singleTxnDetail.txnAmtObj.hasOwnProperty(
                                                                    "taxAmt"
                                                                )
                                                                    ? singleTxnDetail.txnAmtObj.taxAmt.substring(
                                                                          1
                                                                      )
                                                                    : "0.00"
                                                        });
                                                    }
                                                    editTxns[
                                                        editTxns.length - 1
                                                    ][
                                                        "disabled"
                                                    ] = singleTxnDetail.hasOwnProperty(
                                                        "disabled"
                                                    )
                                                        ? singleTxnDetail.disabled
                                                        : false;
                                                }
                                            });
                                        });
                                        //   });
                                        console.log(
                                            "editTxn===>",
                                            editTxns,
                                            hashedData,
                                            hashedData.length
                                        );
                                        this.finalTxnsDetailsObj.emit({
                                            editTxnDetails: editTxns,
                                            actionType: "edit",
                                            currPage: page,
                                            filteredHashedData: hashedData
                                        });
                                        break;
                                }

                                this.performing = false;
                                this.performingActionOnPage = 0;
                                this.showPaginationModal = false;
                                // this.onPaginationModalClose();
                            });
                    });
            } else {
                if (res && res.hasOwnProperty("data") && res.data.length) {
                    switch (type) {
                        case "Print Detail":
                            console.log("PARQAMS", params);
                            this.setCheckedObjInSummaryReportService(res.data);
                            this.summaryReportService.selectAll=false;
                            this.summaryReportService.print=true;
                            this.summaryReportService.parameters= JSON.parse(JSON.stringify(params));
                            this.performing = false;
                            this.performingActionOnPage = 0;
                            this.finalTxnsDetailsObj.emit({
                                actionType: "printDetail",
                                currPage: page
                            });
                            break;
                        case "Re-Print":
                            // console.log(
                            //     "parameters",
                            //     this.parameters,
                            //     res.data
                            // );
                            let observable = [];
                            let storeIDArr = [];
                            res.data.map(txnData => {
                                if(storeIDArr.indexOf(txnData.xStoreID) === -1){
                                    storeIDArr.push(txnData.xStoreID);
                                }
                            });
                            observable.push(
                                this.httpService
                                    .getAll("xbcStores", {storeIds: storeIDArr.toString()})
                            );
                            forkJoin(observable).subscribe(
                                (response: any) => {
                                    this.constantService
                                        .updateTransactionData(
                                            JSON.parse(JSON.stringify(res.data))
                                        )
                                        .then(updatedData => {
                                            let transactionStoreData=[];
                                            updatedData.map(singleTxnData=>{
                                                response[0].data.map(storeRes => {
                                                    if(storeRes._id === singleTxnData.xStoreID){
                                                        transactionStoreData.push({txnData: singleTxnData, storeData: storeRes});
                                                    }
                                                    
                                                });
                                                
                                            });
                                            this.performing = false;
                                            this.finalTxnsDetailsObj.emit({
                                                transactionStoreData: transactionStoreData,
                                                actionType: "reprint",
                                                currPage: page
                                            });
                                        });
                                }
                            );
                            break;
                    }
                }
            }
        });
    }

    setCheckedObjInSummaryReportService(data) {
        // let types = [];
        data.map(singleData => {
            // if(this.checkedItem.indexOf(singleData._id) >=0){
                singleData['id'] = singleData._id;
                singleData['txnNums'] = singleData.xTxnNum;
                singleData['refNums'] = singleData.xRefNum;
                singleData['type'] = singleData.xTypeTag;
                
            // const obj = {
            //     id: singleData._id,
            //     txnNums: singleData.xTxnNum,
            //     refNums: singleData.xRefNum,
            //     type: singleData.xTypeTag
            // };
            // types.push(obj);
            // // }
        });
        this.summaryReportService.setCheckedObj(data);
    }

    printExportReport(){
        this.printLoader = true;
        let newObj = Object.assign({}, this.parameters);
        console.log("printExportReport:", newObj);
        newObj.fetchAll = newObj.selectAll;
        delete newObj.page;
        delete newObj.limit;
        this.httpService.store("generateCSV", {
            storeType: this.type,
            query: newObj,
            timeZoneOffset:new Date().getTimezoneOffset(),
            fileType:"CSV",
          })
          .subscribe(
            res => {
                this.downloadBlob(res.htmlString, "Transactions_Detail.csv", "text/csv;charset=utf-8;");
                this.printLoader = false;
            },
            err => {
                this.printLoader = false;
                console.log("printExportReport", err);
            }
        );
    }

    downloadBlob(content, filename, contentType) {
		// Create a blob
		var blob = new Blob([content], { type: contentType });
		var url = URL.createObjectURL(blob);
	  
		// Create a link to download it
		var pom = document.createElement('a');
		pom.href = url;
		pom.setAttribute('download', filename);
		pom.click();
	}

    ngOnDestroy(): void {
        this.alive = false;
    }
}
