import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';

import { HttpService, NotificationService, BusinessService, UserHandlerService } from '../../../services/index';
import { MemberAccountStore, SetupBillingCustomerStore } from '../../../../store/index';
import { validateFor, validateIfArray } from '../../../../common-functions/index';
import { customSwal, NO_PERMISSION_MSG, TAGSCONSTS } from '../../../../constants/index';
import { setDataForTokenCreation } from '../../../../dashboard/common-functions/validate-txn-terminals';
// import { VTERM_HOST } from '../../shared/config/vTerm.config';
import { VtermConfig } from '../../../../shared/config/vTerm.config';
import { MEDIUM_TAGS_TYPE_MAPPING, BRAND_TAGS_MAPPING, ACCT_TYPE_MAPPING } from 'app/constants/mapping.constants';
import { NgModel } from '@angular/forms';
import { maskCardToken } from '../../../../common-functions/index'
import { XPCSAFES } from '../../../../constants/index';

// endpoints
const MERCHANT_STRUCTURE_ENDPOINT = 'merchantStructure';
const VTERM_INFO_ENDPOINT = 'vTerm_info';
const ENCRYPT_ENDPOINT = 'vTerm/encrypt';

// messages
const UNABLE_TO_GET_SAFE_DATA_MSG = 'Unable to fetch data for the selected payment method.';
const UNABLE_TO_GET_MERCHANT_STRUCT_MSG = 'Unable to fetch merchant capabilties for the Store.';
const NO_DATA_MSG = 'Unable to fetch data.';
const UNABLE_TO_RESOLVE_BRAND_MSG = 'Unable to resolve brand for the selected payment method.';
const NO_PCTR_SELECTED_MSG = 'Please select a Profit Center and Terminal to perform transaction.';
const NO_TERMINAL_SELECTED_MSG = 'Please select a Terminal to perform transaction.';
const NO_USERID_MSG = 'Unable to fetch user data for this customer.';
const FAIL_USER_FETCH_MSG = 'Failed to fetch user data for the customer.';
const ENCRYPT_FAILURE_MSG = 'Unable to generate token.';
const NO_PCTR_MSG = 'There are no Profit Centers capable of performing transactions using the selected payment method.';
const NO_TERMINAL_MSG = 'There are no Terminals present under the selected Profit Center.';

const PAYMENT_MODE_PAYNOW: string = 'payNow';
const PARAM_URL_FIELD = '?param=';
const ROUTE_BACK_DELAY_ON_FAIL = 500;

//DB keys
const DB_XNAME_KEY = 'xName';

const BUSINESS_DATA_KEY = 'businessData';

const BRAND_CC: string = TAGSCONSTS.mediumTags.mdmItemTags.creditCard;
const BRAND_ACH: string = TAGSCONSTS.mediumTags.mdmItemTags.electronicCheck;

//DB FIELDS
const DB_XPCSAFES_XACCTNAME_KEY: string = 'xAcctName';

@Component({
	selector: 'member-account-pay-now-modal',
	templateUrl: './pay-now.component.html',
	styleUrls: ['./pay-now.component.scss']
})
export class PayNowComponent implements OnInit {
	@ViewChild('payNowModal')
	public payNowModal: ModalDirective;
	@ViewChild('pctrDropDown')
	public pctrDropDown: NgModel;
	@Input()
	public safeID: string = '';
	@Input()
	public storeID: string = '';
	@Input()
	public customerID: string = '';
	@Output()
	public closeModalEvent = new EventEmitter<boolean>(); // event emitted when modal is closed

	//loader
	public modalLoader: boolean = true; // global modal loader, used during the init of modal

	public safeData; // contains safe data from get call of xpcSafes
	public pgcomSafeData; // contains pgcom data from get call of xpcSafes
	public customerData; // contains customer data from get call of xbcCustomers
	public userData; // contains customer's user data from get call of xscUsers
	public merchantStructure; // contains merchant structure on the basis of brand capabilities
	public selectedPctrID: string = ''; // id of the selected pctr
	public selectedTerminalID: string = ''; // id of the selected terminal
	public pctrDropDownArr = []; // array of objects containing pctr data
	public terminalDropDownArr = []; // array of objects containing terminal data
	public dropDownType: string = ''; // defines which merchant structure to be used for generating drop downs
	public pgComPaymentFields: any = {}; //Display GMID, GTID and GMPW
	public BRAND_CC: string = BRAND_CC;
	public BRAND_ACH: string = BRAND_ACH;
	public submitting: boolean = false;
	public pctrAssociativeObj: any = {};
	public terminalAssociativeObj: any = {};
	public canPayNow = true; //boolean controller for pay-now enable/disable
	public noPermMsg = NO_PERMISSION_MSG;
	private VTERM_HOST: string = ''; // contains vterm host url
	constructor(
		private httpService: HttpService,
		private notificationService: NotificationService,
		private businessService: BusinessService,
		private setupBillingcustomerStore: SetupBillingCustomerStore,
		private memberAccountStore: MemberAccountStore,
		private vtermConfig: VtermConfig,
		private userHandlerService: UserHandlerService
	) {
		this.VTERM_HOST = vtermConfig.VTERM_HOST;
		console.log('vtermConfig: host', this.VTERM_HOST);
	}

	ngOnInit() {
		console.log('payNow-init: safeID', this.safeID);
		console.log('payNow-init: storeID', this.storeID);
		this.setupPerms();
		let merchantStructureParams = { noGrid: true, storeID: this.storeID };
		const observable = [
			this.memberAccountStore.get(this.safeID),
			this.httpService.getAll(MERCHANT_STRUCTURE_ENDPOINT, merchantStructureParams),
			this.setupBillingcustomerStore.get(this.customerID)
		];
		forkJoin(observable).subscribe(
			forkJoinResponse => {
				// this.modalLoader = false;
				console.log('payNow-init:forkJoinResponse', forkJoinResponse);
				if (forkJoinResponse && forkJoinResponse.length) {
					forkJoinResponse[0] ? this.processSafeResponse(forkJoinResponse[0]) : null;
					forkJoinResponse[1] ? this.processMerchantStructureResponse(forkJoinResponse[1]) : null;
					forkJoinResponse[2] ? this.processCustomerResponse(forkJoinResponse[2]) : null;
					this.modalLoader = false;
				} else {
					this.notificationService.error(NO_DATA_MSG, 'Error');
				}
				console.log('payNow-init:safeData', this.safeData);
				console.log('payNow-init:pgcomSafeData', this.pgcomSafeData);
				console.log('payNow-init:merchantStructure', this.merchantStructure);
				this.processDataForPctrDropDown(this.safeData, this.merchantStructure);
			},
			forkJoinError => {
				this.modalLoader = true;
				this.notificationService.error(NO_DATA_MSG, 'Error');
				setTimeout(()=>{
					this.closePayNowModal();
				},ROUTE_BACK_DELAY_ON_FAIL)
				console.log('payNow-init:forkJoinError', forkJoinError);
			}
		);
	}

	ngAfterViewInit() {
		this.payNowModal.show();
	}

	/**
	 * safeResponse is separated into safeData which contains mongo data and pgcomSafeData which contains pgcom data
	 *
	 * @params : `safeData` : object : safe data retrieved from get of xpcSafes
	 */
	processSafeResponse(safeResponse) {
		console.log('processSafeData: safeData', safeResponse);
		this.safeData = validateFor('data', safeResponse) ? safeResponse.data : {};
		this.safeData.medium = validateFor('xMdmTag', this.safeData) ? MEDIUM_TAGS_TYPE_MAPPING[this.safeData.xMdmTag] : '-';
		this.safeData.brand = validateFor('xBrandTag', this.safeData) ? BRAND_TAGS_MAPPING[this.safeData.xBrandTag] : '-';
		this.safeData.acctType =
			validateFor(DB_XPCSAFES_XACCTNAME_KEY, this.safeData) && ACCT_TYPE_MAPPING[this.safeData[DB_XPCSAFES_XACCTNAME_KEY]]
				? ACCT_TYPE_MAPPING[this.safeData[DB_XPCSAFES_XACCTNAME_KEY]]
				: '-';
		this.safeData.maskedAcctToken = 
			validateFor(XPCSAFES.xAcctTok, this.safeData) 
			? maskCardToken(this.safeData[XPCSAFES.xAcctTok]):
			'-';
		this.pgcomSafeData =
			validateFor('pgcomResponse', this.safeData) && validateFor('data', this.safeData.pgcomResponse)
				? this.safeData.pgcomResponse.data
				: {};
		if (!Object.keys(this.safeData).length || !Object.keys(this.pgcomSafeData).length) {
			this.notificationService.error(UNABLE_TO_GET_SAFE_DATA_MSG, 'Error');
		}
	}

	/**
	 * merchantResponse data is picked up from data field of response
	 *
	 * @params : `merchantStructureResponse` : object : merchantStructure which contains brand capabilities for the merchants
	 */
	processMerchantStructureResponse(merchantStructureResponse) {
		console.log('processMerchantStructureResponse: merchantStructureResponse', merchantStructureResponse);
		this.merchantStructure = validateFor('data', merchantStructureResponse) ? merchantStructureResponse.data : {};
		if (!Object.keys(this.merchantStructure).length) {
			this.notificationService.error(UNABLE_TO_GET_MERCHANT_STRUCT_MSG, 'Error');
		}
	}

	/**
	 * customerData is set, pick up userID and use it to fetch user data
	 *
	 * @params : `customerResponse` : object : customer data retrieved from get of xbcCustomer
	 */
	processCustomerResponse(customerResponse) {
		console.log('processCustomerResponse: customerResponse', customerResponse);
		this.customerData = validateFor('data', customerResponse) ? customerResponse.data : {};
	}

	/**
	 * process data for dropdown of pctr
	 *
	 * @params : `safeData` : object : safe data from mongo
	 * @params : `merchantStructure` : object : merchant structure based on brand capabilities
	 */
	processDataForPctrDropDown(safeData, merchantStructure) {
		console.log('processDataForPctrDropDown: safeData', safeData);
		console.log('processDataForPctrDropDown: merchantStructure', merchantStructure);
		let medium = validateFor('xMdmTag', safeData) ? safeData.xMdmTag : '';
		switch (medium) {
			case TAGSCONSTS.mediumTags.mdmItemTags.creditCard:
				console.log('processDataForPctrDropDown: cc');
				this.dropDownType = TAGSCONSTS.brandTags.brandItemTags.genericCreditCard;
				this.generateDropDownDataForPctr(TAGSCONSTS.brandTags.brandItemTags.genericCreditCard, merchantStructure);
				break;
			case TAGSCONSTS.mediumTags.mdmItemTags.electronicCheck:
				console.log('processDataForPctrDropDown: ach');
				this.dropDownType = TAGSCONSTS.brandTags.brandItemTags.genericElectronicCheck;
				this.generateDropDownDataForPctr(TAGSCONSTS.brandTags.brandItemTags.genericElectronicCheck, merchantStructure);
				break;
			default:
				console.log('processDataForPctrDropDown: default');
				this.notificationService.error(UNABLE_TO_RESOLVE_BRAND_MSG, 'Error');
		}
	}

	/**
	 * generate pctr drop down array using merchantStructure
	 *
	 * @params : `dropDownType` : string : which field to use to create drop down
	 * @params : `merchantStructure` : object : merchant structure based on brand capabilities
	 * @return : `` :
	 */
	generateDropDownDataForPctr(dropDownType, merchantStructure) {
		console.log('generateDropDownDataForPctr: dropDownType', dropDownType);
		console.log('generateDropDownDataForPctr: merchantStructure', merchantStructure);
		let selectablePctrs = validateFor(dropDownType, merchantStructure) ? merchantStructure[dropDownType] : {};
		let dropDownArr = [];
		Object.keys(selectablePctrs).forEach(pctrID => {
			console.log('generateDropDownDataForPctr: selectablepctr', pctrID);
			console.log('generateDropDownDataForPctr: selectablepctrObj', selectablePctrs[pctrID]);
			if (
				!pctrID ||
				!validateFor('xName', selectablePctrs[pctrID]) ||
				!validateFor('xGMID', selectablePctrs[pctrID]) ||
				!validateFor('xGMPW', selectablePctrs[pctrID])
			) {
				return;
			}
			let pctrObj = {
				id: pctrID,
				pctrName: selectablePctrs[pctrID].xName,
				xGMID: selectablePctrs[pctrID].xGMID,
				xGMPW: selectablePctrs[pctrID].xGMPW
			};
			dropDownArr.push(pctrObj);
			let _pctrObj = { [pctrID]: pctrObj };
			this.pctrAssociativeObj = Object.assign(_pctrObj, this.pctrAssociativeObj);
		});
		if (!validateIfArray(dropDownArr)) {
			console.log('generateDropDownDataForPctr: close as no pctr');
			this.closePayNowModal();
			this.showDropDownEmptySwal(NO_PCTR_MSG);
		}
		this.pctrDropDownArr = dropDownArr;
		console.log('generateDropDownDataForPctr: selectablePctrs', selectablePctrs);
		console.log('generateDropDownDataForPctr: pctrDropDownArr', this.pctrDropDownArr);
		console.log('generateDropDownDataForPctr: pctrAssociativeObj', this.pctrAssociativeObj);
	}

	/**
	 * generate terminal drop down array using merchantStructure
	 *
	 * @params : `dropDownType` : string : which field to use to create drop down
	 * @params : `pctrID` : string : mongo id of pctr
	 * @params : `merchantStructure` : object : merchant structure based on brand capabilities
	 */
	generateDropDownDataForTerminal(dropDownType, pctrID, merchantStructure) {
		console.log('generateDropDownDataForTerminal: dropDownType', dropDownType);
		console.log('generateDropDownDataForTerminal: pctrID', pctrID);
		console.log('generateDropDownDataForTerminal: merchantStructure', merchantStructure);
		let selectableMerchants =
			validateFor(dropDownType, merchantStructure) &&
			validateFor(pctrID, merchantStructure[dropDownType]) &&
			validateFor('merchants', merchantStructure[dropDownType][pctrID])
				? merchantStructure[dropDownType][pctrID].merchants
				: {};
		console.log('generateDropDownDataForTerminal: merchantStructure[dropDownType]', merchantStructure[dropDownType]);
		console.log('generateDropDownDataForTerminal: merchantStructure[dropDownType].pctrid', merchantStructure[dropDownType][pctrID]);
		console.log('generateDropDownDataForTerminal: selectableMerchants', selectableMerchants);
		let dropDownArr = [];
		Object.keys(selectableMerchants).forEach(merchantID => {
			console.log('generateDropDownDataForTerminal: merchantID', merchantID);
			console.log('generateDropDownDataForTerminal: merchantIDobj', selectableMerchants[merchantID]);
			let selectableTerminals = validateFor('terminals', selectableMerchants[merchantID])
				? selectableMerchants[merchantID].terminals
				: {};
			Object.keys(selectableTerminals).forEach(terminalID => {
				console.log('generateDropDownDataForTerminal: terminalID', terminalID);
				console.log('generateDropDownDataForTerminal: terminalID', selectableTerminals[terminalID]);
				if (
					!terminalID ||
					!validateFor('xName', selectableTerminals[terminalID]) ||
					!validateFor('xGTID', selectableTerminals[terminalID])
				) {
					return;
				}
				let termObj = {
					id: terminalID ? terminalID : '',
					terminalName: selectableTerminals[terminalID].xName,
					xGTID: selectableTerminals[terminalID].xGTID
				};
				// this.terminalAssociativeObj.push({ terminalID: termObj });
				dropDownArr.push(termObj);
				let terminalObj = { [terminalID]: termObj };
				this.terminalAssociativeObj = Object.assign(terminalObj, this.terminalAssociativeObj);
			});
		});
		if (!validateIfArray(dropDownArr)) {
			console.log('generateDropDownDataForTerminal: close as no terminal');
			this.pctrDropDown.reset('');
			this.showDropDownEmptySwal(NO_TERMINAL_MSG);
		}
		this.terminalDropDownArr = dropDownArr;
		console.log('generateDropDownDataForTerminal: terminalDropDownArr', this.terminalDropDownArr);
		console.log('generateDropDownDataForTerminal: terminalAssociativeObj--->', this.terminalAssociativeObj);
	}

	/**
	 * show swal for cannot proceed with the provided message
	 *
	 * @params : `message` : string : the message to be shown in the swal
	 */
	showDropDownEmptySwal(message) {
		console.log('showDropDownEmptySwal: message', message);
		customSwal.fire({
			title: "Can't Proceed",
			text: message,
			icon: 'error',
			confirmButtonText: 'Okay'
		});
	}

	/**
	 * called on proceed button click
	 * hit vterm_info api
	 * generate token
	 * route to vterm
	 *
	 */
	proceedToVterm() {
		console.log('proceedToVterm: selectedPctrID', this.selectedPctrID);
		console.log('proceedToVterm: selectedTerminalID', this.selectedTerminalID);
		this.submitting = true;
		if (!this.selectedPctrID) {
			this.notificationService.error(NO_PCTR_SELECTED_MSG, 'Error');
			this.submitting = false;
			return;
		}
		if (!this.selectedTerminalID) {
			this.notificationService.error(NO_TERMINAL_SELECTED_MSG, 'Error');
			this.submitting = false;
			return;
		}
		const vtermInfoParams = { terminalID: this.selectedTerminalID };
		this.httpService.getAll(VTERM_INFO_ENDPOINT, vtermInfoParams).subscribe(
			vTermInfoResponse => {
				console.log('proceedToVterm:vTermInfoResponse', vTermInfoResponse);
				let tokenData = this.generateTokenData(vTermInfoResponse);
				this.httpService.store(ENCRYPT_ENDPOINT, tokenData).subscribe(
					encryptResponse => {
						console.log('proceedToVterm:encryptResponse', encryptResponse);
						if (
							!validateFor(
								'success',
								encryptResponse || !validateFor('data', encryptResponse) || !validateFor('vTermToken', encryptResponse.data)
							)
						) {
							this.notificationService.error(ENCRYPT_FAILURE_MSG, 'Error');
						}
						let token = encryptResponse.data.vTermToken;
						console.log('proceedToVterm:token', token);
						let url = this.VTERM_HOST + PARAM_URL_FIELD + token;
						console.log('proceedToVterm: url', url);
						document.location.href = url;
					},
					encryptError => {
						console.log('proceedToVterm:encryptError', encryptError);
					}
				);
				console.log('proceedToVterm:tokenData', tokenData);
			},
			vTermInfoError => {
				this.submitting = false;
				console.log('proceedToVterm:vTermInfoError', vTermInfoError);
			}
		);
	}

	/**
	 * use vtermInfo response to generate token data
	 *
	 * @params : `vTermInfoResponse` : object : response from vterm_info api
	 * @return : `tokenData` : object : tokenData which is to be encrypted and sent to vterm
	 */
	generateTokenData(vTermInfoResponse) {
		console.log('generateTokenData: vTermInfoResponse', vTermInfoResponse);
		let tokenData, terminalObj, vTermInfoResponseData, bizName;
		vTermInfoResponseData = validateFor('data', vTermInfoResponse) ? vTermInfoResponse.data : {};
		terminalObj =
			validateFor('terminalRecords', vTermInfoResponseData) && vTermInfoResponseData.terminalRecords.length
				? vTermInfoResponseData.terminalRecords[0]
				: {};
		bizName = validateFor(DB_XNAME_KEY, this.businessService[BUSINESS_DATA_KEY])
			? this.businessService[BUSINESS_DATA_KEY][DB_XNAME_KEY]
			: '';
		console.log('bizName: generateTokenData', bizName);
		switch (this.dropDownType) {
			case TAGSCONSTS.brandTags.brandItemTags.genericCreditCard:
				tokenData = setDataForTokenCreation(
					bizName,
					vTermInfoResponseData,
					this.userHandlerService.userData,
					TAGSCONSTS.roleTags.roleItemTags.representativeRole,
					terminalObj
				);
				console.log('proceedToVterm:cc token', tokenData);
				break;
			case TAGSCONSTS.brandTags.brandItemTags.genericElectronicCheck:
				tokenData = setDataForTokenCreation(
					bizName,
					vTermInfoResponseData,
					this.userHandlerService.userData,
					TAGSCONSTS.roleTags.roleItemTags.representativeRole,
					{},
					terminalObj
				);
				console.log('proceedToVterm:ach token', tokenData);
				break;
		}
		let formalName = validateFor('xFormalName', this.customerData) ? this.customerData.xFormalName : {};
		let contactDetails = validateFor('xContact', this.customerData) ? this.customerData.xContact : {};
		let address = validateFor('xAddr', this.customerData) ? this.customerData.xAddr : {};
		let l1Addr = validateFor('xLine1', address) ? address.xLine1 : '';
		let l2Addr = validateFor('xLine2', address) ? address.xLine2 : '';
		tokenData['authToken'] = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null;
		tokenData['paymentMode'] = PAYMENT_MODE_PAYNOW;
		tokenData['billingAccountInfo']['_id'] = validateFor('_id', this.customerData) ? this.customerData._id : '';
		tokenData['billingAccountInfo']['memberNumber'] = validateFor('xCustomerNum', this.customerData)
			? this.customerData.xCustomerNum
			: '';
		tokenData['billingAccountInfo']['firstName'] = validateFor('xForename', formalName) ? formalName.xForename : '';
		tokenData['billingAccountInfo']['lastName'] = validateFor('xSurname', formalName) ? formalName.xSurname : '';
		tokenData['billingAccountInfo']['phone'] = validateFor('xPhone', contactDetails) ? contactDetails.xPhone : '';
		tokenData['billingAccountInfo']['email'] = validateFor('xEmail', contactDetails) ? contactDetails.xEmail : '';
		tokenData['billingAccountInfo']['company'] = validateFor('xCompanyName', this.customerData) ? this.customerData.xCompanyName : '';
		tokenData['billingAccountInfo']['address'] = (l1Addr ? l1Addr : '') + (l2Addr ? ', ' + l2Addr : '');
		tokenData['billingAccountInfo']['city'] = validateFor('xCity', address) ? address.xCity : '';
		tokenData['billingAccountInfo']['state'] = validateFor('xSOP', address) ? address.xSOP : '';
		tokenData['billingAccountInfo']['zipCode'] = validateFor('xPostCode', address) ? address.xPostCode : '';
		tokenData['billingAccountInfo']['country'] = validateFor('xCountry', address) ? address.xCountry : '';
		tokenData['initiatorRole'] = TAGSCONSTS.roleTags.roleItemTags.customerRole;
		tokenData['selectedPaymentMethod'] = validateFor('xAcctTok', this.safeData) ? this.safeData.xAcctTok : '';
		return tokenData;
	}

	/**
	 * called on pctr dropdown value change
	 *
	 * @params : `event` : object : html event object
	 */
	pctrDropDownValueChange() {
		console.log('pctrDropDownValueChange: selectedPctrID', this.selectedPctrID);
		//Reset terminal on profit center change
		this.pgComPaymentFields['GTID'] = '';
		let selectedPctr = validateFor(this.selectedPctrID, this.pctrAssociativeObj) ? this.pctrAssociativeObj[this.selectedPctrID] : {};
		this.pgComPaymentFields['GMID'] = selectedPctr.xGMID;
		this.pgComPaymentFields['GMPW'] = selectedPctr.xGMPW;
		this.generateDropDownDataForTerminal(this.dropDownType, this.selectedPctrID, this.merchantStructure);
	}

	/**
	 * called on terminal dropdown value change
	 *
	 * @return : `` :
	 */
	terminalDropDownValueChange() {
		let selectedTerminal = validateFor(this.selectedTerminalID, this.terminalAssociativeObj)
			? this.terminalAssociativeObj[this.selectedTerminalID]
			: {};
		this.pgComPaymentFields['GTID'] = selectedTerminal.xGTID;
		console.log('terminalDropDownValueChange: selectedterminalID', this.selectedTerminalID);
	}

	setupPerms(){
        this.canPayNow = this.userHandlerService.userPerms.canPayNow;
		console.log('canpaynow', this.canPayNow);
    }

	/**
	 * close modal and emit closeModalEvent to inform parent of the same
	 *
	 */
	closePayNowModal() {
		console.log('closePayNowModal');
		this.payNowModal.hide();
		this.closeModalEvent.emit(true);
	}
}
