import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { 
    UserHandlerService, 
    LoginStoreService, 
    HttpService, 
    NotificationService, 
    PaymentService 
} from '../../../../shared/services/index';

const brandTypeTags = {
    'brand.cc':'genericCreditCard',
    'brand.cc.vs':'Visa',
    'brand.cc.mc':'MasterCard',
    'brand.cc.ax':'American Express',
    'brand.cc.ds':'Discover',
    'brand.cc.dn':'DinersClub',
    'brand.cc.cb':'CarteBlanche',
    'brand.cc.en':'enRoute',
    'brand.cc.jc':'jcb',
    'brand.cc.ja':'jal',
    'brand.cc.up':'cup',
    'brand.cc.ma':'MaestroCard',
    'brand.dc':'genericDebitCard',
    'brand.gc':'genericGiftCard',
    'brand.pc':'genericPrepaidCard',
    'brand.lc':'genericLoyaltyCard',
    'brand.eb':'genericEBTCard',
    'brand.eb.fs':'Food Stamp Card',
    'brand.eb.cb':'Cash Benefit Card',
    'brand.ec':'genericElectronicCheck',
}
const mdmTags = {
    'mdm.cc':'Credit Card',
    'mdm.dc':'Debit Card',
    'mdm.gc':'Gift Card',
    'mdm.pc':'Prepaid Card',
    'mdm.lc':'Loyalty Card',
    'mdm.eb':'EBT Card',
    'mdm.ec':'Electronic Check',
}

@Component({
    selector: 'app-payment-form',
    templateUrl: './payment-form.component.html',
    styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit {
    public entityData: any = {};
    public form: FormGroup;
    private lastPaymentMethod: any = {}; // fetched from api call
    public currentPaymentMethod: any = {};
    public paymentMethodOptions: any = [];
    public submitModeData: any = {};

    // placeholder data
    // public placeholderPaymentData: any = [
    //     { cardType: 'Visa', cardNumber: '4111111111111111', cardName: 'asdfghjkl' },
    //     { cardType: 'MasterCard', cardNumber: '4111111111111122', cardName: 'qwertyuiop' }
    // ];

    constructor(
        private userHandlerService: UserHandlerService,
        private loginStoreService: LoginStoreService,
        private httpService: HttpService,
        public formBuilder: FormBuilder,
        public notificationService: NotificationService,
        private router: Router,
        private paymentService: PaymentService
    ) {}

    // ngOnInit() {
    //     // this.paymentMethodOptions = this.placeholderPaymentData;
    //     this.paymentMethodOptions = this.paymentService.fetchPaymentMethodOptions();
    //     this.lastPaymentMethod = this.paymentService.lastUsedPaymentMethod;
    //     this.currentPaymentMethod = Object.keys(this.paymentService.paymentMethod).length 
    //         ? this.paymentService.paymentMethod 
    //         : Object.keys(this.lastPaymentMethod).length 
    //             ? this.lastPaymentMethod 
    //             : {};
    //     this.paymentService.paymentMethod = this.currentPaymentMethod;
    //     this.initValidation();
    //     console.log('paymentForm init: currentPaymentMethod',this.currentPaymentMethod)
    // }
    ngOnInit() {
        // this.paymentMethodOptions = this.placeholderPaymentData;
        // this.paymentMethodOptions = this.paymentService.fetchPaymentMethodOptions({});
        // this.lastPaymentMethod = this.paymentService.lastUsedPaymentMethod;
        // this.currentPaymentMethod = Object.keys(this.paymentService.paymentMethod).length
        //     ? this.paymentService.paymentMethod
        //     : Object.keys(this.lastPaymentMethod).length
        //         ? this.lastPaymentMethod
        //         : {};
        // this.paymentService.paymentMethod = this.currentPaymentMethod;
        this.initValidation();
        console.log('paymentForm init: currentPaymentMethod',this.currentPaymentMethod);
        this.entityData = Object.keys(this.userHandlerService.userType).length
            ? this.userHandlerService.userType
            : {};
        console.log('payment-form: init: entity data', this.entityData);
        let params = {
            customerID: this.entityData._id ? this.entityData._id : ''
        };
        console.log('payment-form: init: params', params);
        this.paymentService.fetchPaymentMethodOptions(params).subscribe(
            response => {
                console.log('payment-form: init: response', response);
                let responseData =
                    response &&
                    response.hasOwnProperty('data') &&
                    response['data']
                        ? response['data']
                        : {};
                if (Object.keys(responseData).length) {
                    this.paymentMethodOptions = responseData;
                    this.paymentMethodOptions.map(obj=>{
                        obj.brand = brandTypeTags[obj.xBrandTag];
                        obj.medium = mdmTags[obj.xMdmTag];
                    })
                    this.lastPaymentMethod = this.paymentMethodOptions[1]
                        ? this.paymentMethodOptions[1]
                        : {};
                    this.paymentService.lastUsedPaymentMethod = this.lastPaymentMethod;
                    this.currentPaymentMethod = Object.keys(
                        this.paymentService.paymentMethod
                    ).length
                        ? this.paymentService.paymentMethod
                        : Object.keys(this.lastPaymentMethod).length
                            ? this.lastPaymentMethod
                            : {};
                    this.paymentService.paymentMethod = this.currentPaymentMethod;
                    console.log('payment-form: init: paymentmethod',this.paymentService.paymentMethod);
                    console.log('payment-form: init: paymentmethodoptions',this.paymentMethodOptions);
                    console.log('payment-form: init: last method',this.lastPaymentMethod);
                    console.log('payment-form: init: current method',this.currentPaymentMethod);
                    console.log('payment-form: init: lastpayment method in service',this.paymentService.lastUsedPaymentMethod)
                    console.log('payment-form: init: brandtype:', brandTypeTags[this.currentPaymentMethod.xBrandTag], this.currentPaymentMethod.xBrandTag)
                    console.log('payment-form: init: brandtype:', mdmTags[this.currentPaymentMethod.xMdmTag], this.currentPaymentMethod.xMdmTag)
                }
            },
            error => {
                console.log('payment-form: init: error', error);
                this.notificationService.error('Failed to fetch payment method information.','Error');
            }
        );
    }
    // paynow form validation
    initValidation() {
        console.log('paymentForm init: payment data', this.paymentService.paymentData)
        const paymentData = this.paymentService.paymentData;
        if(Object.keys(paymentData).length){
            this.form = this.formBuilder.group({
                paymentAmount: [paymentData['paymentAmount'] ? paymentData['paymentAmount'] : '', [Validators.required]],
                lotNumber: [paymentData['lotNumber'] ? paymentData['lotNumber'] : '', [Validators.required]],
                scheduledDate: [paymentData['scheduledDate'] ? paymentData['scheduledDate'] : '', [Validators.required]],
                notes: [paymentData['notes'] ? paymentData['notes'] : '', []]
            });  
        } else{
            this.form = this.formBuilder.group({
                paymentAmount: ['', [Validators.required]],
                lotNumber: ['', [Validators.required]],
                scheduledDate: ['', [Validators.required]],
                notes: ['', []]
            });
        }
    }

    // paynow submit function
    onSubmit(form: FormGroup) {
        let formData = form.getRawValue();
        form.controls['paymentAmount'].markAsTouched();
        form.controls['lotNumber'].markAsTouched();
        form.controls['scheduledDate'].markAsTouched();
        console.log('onSubmit: formdata', formData);
        console.log('onSubmit: form', form);
        console.log('onSubmit: form invalid', form.invalid);
        if (!form.invalid) {
            if (Object.keys(this.currentPaymentMethod).length) {
                // let scheduledDateMonth = formData['scheduledDate'].getMonth() + 1;
                // let scheduledDate = formData['scheduledDate'];
                // let modifiedScheduledDate =
                //     (scheduledDateMonth < 10 ? '0' + scheduledDateMonth : scheduledDateMonth) +
                //     '/' +
                //     (scheduledDate.getDate() < 10 ? '0' + scheduledDate.getDate() : scheduledDate.getDate()) +
                //     '/' +
                //     scheduledDate.getFullYear();
                // console.log('onSubmit: mod date', modifiedScheduledDate);
                // console.log('onSubmit: mod date from func', this.convertDateToString(formData['scheduledDate']));
                let modifiedScheduledDate = this.convertDateToString(formData['scheduledDate']);
                this.submitModeData = {
                    lotNumber: formData['lotNumber'] ? formData['lotNumber'] : '',
                    paymentAmount: formData['paymentAmount'] ? formData['paymentAmount'] : '',
                    scheduledDateString: modifiedScheduledDate ? modifiedScheduledDate : '',
                    scheduledDate: formData['scheduledDate'] ? formData['scheduledDate'] : '',
                    notes: formData['notes'] ? formData['notes'] : ''
                };
                console.log('onSubmit: submitModeData', this.submitModeData);
                this.paymentService.paymentData = this.submitModeData;
                console.log('onSubmit: payment service data', this.paymentService.paymentData);
                console.log('onSubmit: payment service method', this.paymentService.paymentMethod);
                this.confirmPayment();
                // form.reset();
            } else {
                // this.notificationService.error('No selected mode of payment','Error');
                console.log('onSubmit: route user to create payment page or select payment since no payment method ');
                if(this.paymentMethodOptions.length)
                    this.selectPaymentMode(form);
                else
                    this.createPaymentMode(form);
                // -------------------------------------- route user to create payment page since no payment method --------------------------------------
            }
        }
    }

    convertDateToString(date: Date){
        console.log('convertDateToString: date',date)
        if(date && date.getDate()){
            let month = date.getMonth() + 1;
            return (month < 10 ? '0' + month : month) +
                '/' +
                (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
                '/' +
                date.getFullYear();
        } else {
            return '';
        }
    }

    // route to create payment method
    createPaymentMode(form: FormGroup) {
        const formData = form.getRawValue();
        console.log('createPaymentMode: formData',formData)
        let modifiedScheduledDate = this.convertDateToString(formData['scheduledDate']);
        const paymentData = {
            lotNumber: formData['lotNumber'] ? formData['lotNumber'] : '',
            paymentAmount: formData['paymentAmount'] ? formData['paymentAmount'] : '',
            scheduledDateString: modifiedScheduledDate ? modifiedScheduledDate : '',
            scheduledDate: formData['scheduledDate'] ? formData['scheduledDate'] : '',
            notes: formData['notes'] ? formData['notes'] : ''
        }
        this.paymentService.paymentData = paymentData;
        console.log('createPaymentMode: form',form.getRawValue())
        console.log('createPaymentMode: paymentData',paymentData)
        this.router.navigate(['/dashboard/customer/pay-now/create-payment-method']);
    }

    // route to select payment method
    selectPaymentMode(form: FormGroup) {
        const formData = form.getRawValue();
        let modifiedScheduledDate = this.convertDateToString(formData['scheduledDate']);
        const paymentData = {
            lotNumber: formData['lotNumber'] ? formData['lotNumber'] : '',
            paymentAmount: formData['paymentAmount'] ? formData['paymentAmount'] : '',
            scheduledDateString: modifiedScheduledDate ? modifiedScheduledDate : '',
            scheduledDate: formData['scheduledDate'],
            notes: formData['notes'] ? formData['notes'] : ''
        }
        this.paymentService.paymentData = paymentData;
        console.log('selectPaymentMode: form', form.getRawValue())
        this.router.navigate(['/dashboard/customer/pay-now/select-payment-method']);
    }

    // route to confirm payment page
    confirmPayment() {
        this.router.navigate(['/dashboard/customer/pay-now/confirm-payment']);
    }
}
