import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';

import { HomeModule } from './home';
import { SetupModule } from './setup/setup.module';
import { GridModule, SectionsModule } from '../shared';
import { BreadcrumbComponent, FilterComponent, TopnavComponent, AuthGuard, RoleGuard, RouteGuardGuard } from '../shared'; // newly added components
import {
	BreadcrumbService,
	FilterService,
	HttpService,
	NotificationService,
	UserHandlerService,
	TabStructureService,
	PaymentService,
} from '../shared/services'; // newly added services
// import { NgxAsideModule } from 'ngx-aside';

import { SetupBusinessInformationStore, SetupCompanyRepresentativeStore, StatisticsStore, LogsStore, CsvFileHistoryStore } from '../store'; // for topNav component
import { UserAccessResolver } from '../shared/resolver';
import { TreeComponentModule, SummaryReportModule } from '../shared/modules';
import { LayoutComponent } from './layout/layout.component'; // for topNav component
import { TransactionModule } from './transaction/transaction.module';
import { ApplicationLogsComponent, SystemLogComponent, StatisticsComponent, StatusesComponent } from './index';
// import { ModalModule } from "ngx-bootstrap";
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CsvFileHistoryComponent } from './csv-file-history/csv-file-history.component';
import { VirtualScrollModule } from 'angular2-virtual-scroll';
import { PayNowComponent } from './pay-now/pay-now.component';
import { CreateNewPaymentTypeComponent } from './pay-now/components/create-new-payment-type/create-new-payment-type.component';
import { PaymentFormComponent } from './pay-now/components/payment-form/payment-form.component';
import { SelectPaymentMethodComponent } from './pay-now/components/select-payment-method/select-payment-method.component';
import { ConfirmPaymentComponent } from './pay-now/components/confirm-payment/confirm-payment.component';
import { CompanySuspendedComponent } from './../pages/company-suspended/company-suspended.component';
import { CompanySuspendedGuard } from './../pages/company-suspended/guard/company-suspended.guard';
// import { StructureLayoutComponent } from './setup/structure-layout/structure-layout.component';

@NgModule({
	imports: [
		CommonModule,
		DashboardRoutingModule,
		HomeModule,
		SetupModule,
		RouterModule, // for breadcrumb module
		FormsModule, // for filter module
		BsDropdownModule.forRoot(), // for breadcrumb module
		GridModule,
		TreeComponentModule,
		TransactionModule,
		SectionsModule,
		// NgxAsideModule,
		SummaryReportModule,
		NgSelectModule,
		ModalModule.forRoot(),
		AccordionModule.forRoot(),
		VirtualScrollModule,
		ReactiveFormsModule,
		BsDatepickerModule.forRoot(),
		TooltipModule.forRoot(), // tooltip for showing gmid in breadcrumb
	],
	declarations: [
		DashboardComponent,
		BreadcrumbComponent, // newly added
		FilterComponent, // newly added
		TopnavComponent,
		LayoutComponent,
		StatisticsComponent,
		ApplicationLogsComponent,
		StatusesComponent,
		SystemLogComponent,
		CsvFileHistoryComponent,
		PayNowComponent,
		CreateNewPaymentTypeComponent,
		PaymentFormComponent,
		SelectPaymentMethodComponent,
		ConfirmPaymentComponent, /*, StructureLayoutComponent */ // newly added
		CompanySuspendedComponent
	],
	exports: [DashboardComponent],
	providers: [
		AuthGuard,
		RoleGuard,
		RouteGuardGuard,
		CompanySuspendedGuard,
		BreadcrumbService,
		UserHandlerService,
		SetupBusinessInformationStore,
		SetupCompanyRepresentativeStore,
		FilterService,
		NotificationService,
		HttpService,
		UserAccessResolver,
		LogsStore,
		StatisticsStore,
		TabStructureService,
		CsvFileHistoryStore,
		PaymentService,
	],
})
export class DashboardModule {}
