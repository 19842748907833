import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { HomeComponent } from './home.component';
import { TreeComponentModule } from '../../shared/modules';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { HomeRoutingModule } from './home-routing.module';

import { ChartsModule } from 'ng2-charts';

import { TransactionsStore } from '../../store/index';

import { LoginModalComponent } from '../../pages/company-suspended/login-modal/login-modal.component';

@NgModule({
	imports: [CommonModule, FormsModule, DatepickerModule.forRoot(), TreeComponentModule, HomeRoutingModule, ChartsModule],
	declarations: [HomeComponent, LoginModalComponent],
    entryComponents: [LoginModalComponent],
	exports: [HomeComponent],
	providers: [TransactionsStore],
})
export class HomeModule {}
