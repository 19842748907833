import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpService } from '../shared/services/index';

const endPoint = 'xbcCompanies';

@Injectable()
export class SetupCompanyStore {

    constructor(
        protected httpService: HttpService
        ) {}


    getAll(params: any): any {
        if (params.businessID) {
            delete params.businessID;
        }
        return this.httpService.getAll(endPoint, params)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    store(element: any): any {
        return this.httpService.store(endPoint, element)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    update(elementID: string, newElement: any): any {
        return this.httpService.update(endPoint, elementID, newElement)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    get(elementID: any): any {
        return this.httpService.get(endPoint, elementID)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    destroy(elementID: any): any {
        return this.httpService.destroy(endPoint, elementID)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    getVendorCompany(params: any): any {
        return this.httpService.getAll(endPoint, params, true)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

}
