import { Component, OnInit, OnDestroy } from '@angular/core';
import { SetupBusinessStructureStore } from '../../../../../store';
import {
	TreeService,
	UrlStoreService,
	BusinessService,
	TabStructureService,
	UserHandlerService,
	BreadcrumbService
} from '../../../../../shared/services';
import { Router } from '@angular/router';
import { TAGSCONSTS } from '../../../../../constants/tags.constants';

@Component({
	selector: 'app-setup-structure-region-list',
	templateUrl: './setup-structure-region-list.component.html',
	styleUrls: ['./setup-structure-region-list.component.scss']
})
export class SetupStructureRegionListComponent implements OnInit, OnDestroy {
	public attributes: any[] = [];
	public storeValue: string = '';
	public storeObject: any = {};
	public action: any[] = [];
	public parameters: any = {
		// noGrid: true
		xTypeTag: TAGSCONSTS.structTags.regionTags
	};
	private alive: boolean = true;
	fetchByStore: boolean = true;
	showDetails: boolean = false;
	setBizId: boolean = true;

	// public detailVisible: boolean = true;
	public regionID: string = '';
	public transactionType: string = 'all';

	public viewDetails: any[] = [
		{ header: 'Active', mapTo: 'xStatus' },
		{ header: 'Name', mapTo: 'xName' },
		{ header: 'Sub Name', mapTo: 'xSubName' },
		{ header: 'City', mapTo: 'xAddr.xCity', nested: true },
		{ header: 'Main Phone', mapTo: 'xMainContact.xPhone', nested: true },
		{ header: 'ID', mapTo: '_id' }
	];

	constructor(
		private router: Router,
		private setupBusinessStructureStore: SetupBusinessStructureStore,
		public treeService: TreeService,
		public urlStoreService: UrlStoreService,
		public businessService: BusinessService,
		private tabStructureService: TabStructureService,
		private userHandlerService: UserHandlerService,
		private breadcrumbService: BreadcrumbService
	) {
		//this.urlStoreService.swapUrl(router.url);
	}

	ngOnInit() {
		this.setBreadCrumbValue();
		this.tabStructureService.checkAndSetTabNameToDef(
			this.tabStructureService.tabMode,
			this.tabStructureService.isCancelClick,
			this.tabStructureService.isSubmitClick
		);

		this.treeService.isParent = false;

		//For downward hierarchy--------->
		if (this.treeService.businessID.getValue()) {
			this.setBizId = false;
			console.log('here');
			// this.parameters = {
			//     xTypeTag: TAGSCONSTS.structTags.chainTags,
			//     xBusinessID: this.treeService.businessID.getValue()
			// };
		}
		if (this.treeService.chainID.getValue()) {
			this.setBizId = false;
			// let tempObj = {
			//     business: this.businessService.businessData.xName,
			//     chain: "current",
			//     region: "list",
			//     property: "list",
			//     store: "list",
			//     pctr: "list",
			//     meracct: 'list',
			//     terminal: 'list',
			//     periph: 'list',
			// }
			// this.businessService.setTabStructureNames(tempObj);
			this.parameters = {
				xTypeTag: TAGSCONSTS.structTags.regionTags,
				xChainID: this.treeService.chainID.getValue()
			};
		}
		//End of downward hierarchy------------->
		//For self------>
		if (this.treeService.regionID.getValue()) {
			this.setBizId = false;
			this.router.navigate(['/dashboard/setup/business/structure/region', this.treeService.regionID.getValue()]);
		}
		// End for self------->
		//For upward hierarchy------->

		if (this.treeService.propertyID.getValue()) {
			this.setBizId = false;
			// to open respective region if xRegionID is present else show no region exists
			console.log(this.treeService.treeData);
			if (this.treeService.treeData.xRegionID) {
				this.treeService.isParent = true;
				this.router.navigate([
					'/dashboard/setup/business/structure/region',
					this.treeService.treeData ? this.treeService.treeData.xRegionID : null
				]);
			} else {
				this.treeService.isParent = true;
				this.router.navigate(['/dashboard/setup/business/structure/region', 'null']);
			}
		}

		if (this.treeService.storeID.getValue()) {
			this.setBizId = false;
			console.log(this.treeService.treeData);
			if (this.treeService.treeData.xStructs ? this.treeService.treeData.xStructs.xRegionID : false) {
				this.treeService.isParent = true;
				this.router.navigate([
					'/dashboard/setup/business/structure/region',
					this.treeService.treeData ? this.treeService.treeData.xStructs.xRegionID : null
				]);
			} else {
				this.treeService.isParent = true;
				this.router.navigate(['/dashboard/setup/business/structure/region', 'null']);
			}
		}
		if (
			this.treeService.profitCenterID.getValue() ||
			this.treeService.merchantAccID.getValue() ||
			this.treeService.terminalID.getValue() ||
			this.treeService.periphID.getValue()
		) {
			this.setBizId = false;
			console.log('This is the store data??', this.treeService.treeData);
			if (this.treeService.treeData.xStructs ? this.treeService.treeData.xStructs.xRegionID : false) {
				this.treeService.isParent = true;
				this.router.navigate([
					'/dashboard/setup/business/structure/region',
					this.treeService.treeData ? this.treeService.treeData.xStructs.xRegionID : null
				]);
			} else {
				this.treeService.isParent = true;
				this.router.navigate(['/dashboard/setup/business/structure/region', 'null']);
			}
		} else {
			if (this.setBizId) {
				this.treeService.setId(this.businessService.businessID.getValue(), 'business');
			}
		}
		//End of upward hierarchy------->

		this.setGridProperties();
	}

	setBreadCrumbValue(): void {
		const breadCrumb: any = {};
		breadCrumb.root = 'Setup';
		breadCrumb.child = 'Structure';
		breadCrumb.subChild = 'Region';
		breadCrumb.subChildLink = null;
		breadCrumb.grandChild = 'Regions';
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	setGridProperties() {
		this.storeValue = 'SetupBusinessStructureStore';
		this.storeObject = this.setupBusinessStructureStore;
		// this.attributes = [
		//     { header: 'Active', mapTo: 'xStatus', width: '10%' },
		//     { header: 'Name', mapTo: 'xName', width: '20%' },
		//     { header: 'Sub Name', mapTo: 'xSubName', width: '20%' },
		//     { header: 'City', mapTo: 'xAddr.xCity', width: '20%' },
		//     { header: 'Main Phone', mapTo: 'xMainContact.xPhone', width: '20%' },
		//     { header: 'Actions', mapTo: '', width: '10%'}
		// ];
		const editLink = '/dashboard/setup/business/structure/region';
		this.action = [
			{ type: 'view', visible: true, text: 'Details', icon: 'fa fa-eye', action: 'emit' }
			// { type: 'edit', visible: true, text: 'Edit', icon: 'fa fa-edit', action: 'url', onClick: editLink },
			// { type: 'delete', visible: true, text: 'Delete', icon: 'fa fa-trash', action: 'func', onClick: 'destroy'}
		];

		if (!this.userHandlerService.userRoles.isBusinessEmployee) {
			this.attributes = [
				{ header: 'Active', mapTo: 'xStatus', width: '10%' },
				{ header: 'Name', mapTo: 'xName', width: '20%' },
				{ header: 'Sub Name', mapTo: 'xSubName', width: '20%' },
				{ header: 'City', mapTo: 'xAddr.xCity', width: '20%' },
				{ header: 'Main Phone', mapTo: 'xMainContact.xPhone', width: '20%' },
				{ header: 'Actions', mapTo: '', width: '10%' }
			];
			this.action.push(
				{ type: 'edit', visible: true, text: 'Edit', icon: 'fa fa-edit', action: 'url', onClick: editLink },
				{ 
					type: 'delete', 
					visible: true, 
					text: 'Delete', 
					icon: 'fa fa-trash', 
					action: 'func',
					onClick: 'destroy', 
					disabled: !this.userHandlerService.userPerms.canDeleteSetup 
				}
			);
		} else if (this.userHandlerService.userRoles.isBusinessEmployee) {
			this.attributes = [
				{ header: 'Active', mapTo: 'xStatus', width: '10%' },
				{ header: 'Name', mapTo: 'xName', width: '25%' },
				{ header: 'Sub Name', mapTo: 'xSubName', width: '20%' },
				{ header: 'City', mapTo: 'xAddr.xCity', width: '25%' },
				{ header: 'Main Phone', mapTo: 'xMainContact.xPhone', width: '20%' }
			];
		}
	}

	onActionRequest(obj: any) {
		if (obj.act.type === 'view') {
			console.log("I'm here bro", obj);
			this.regionID = obj.id;
			// this.detailVisible = true;
		}
	}

	onShowDetails(obj) {
		this.showDetails = obj;
	}

	viewClosed(visible: boolean) {
		if (!visible) {
			this.regionID = '';
			this.showDetails = false;
		}
	}

	ngOnDestroy(): void {
		this.alive = false;
	}
}
