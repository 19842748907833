<form [formGroup]="createPayment" (ngSubmit)="createNewPaymentModeSubmit(createPayment)">
    <div class='card card-default'>
        <div class='card-body vterm-info'>
            <div class="row">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Credit Card Number * :</label>
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    id="creditCardNumber"
                                    formControlName="creditCardNumber"
                                    validateOnBlur
                                    [ngClass]="{
                                        'has-error':
                                            createPayment.get('creditCardNumber').hasError('required') &&
                                            createPayment.get('creditCardNumber').touched
                                    }"
                                />
                                <small
                                    *ngIf="
                                        createPayment.get('creditCardNumber').hasError('required') && createPayment.get('creditCardNumber').touched
                                    "
                                    class="inline-error"
                                >
                                    Credit Card Number is required
                                </small>
                                <!-- cc number required {{ createPayment.get('creditCardNumber').hasError('required') }} -->
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Credit Card Name * :</label>
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    id="creditCardName"
                                    formControlName="creditCardName"
                                    validateOnBlur
                                    [ngClass]="{
                                        'has-error':
                                            createPayment.get('creditCardName').hasError('required') && createPayment.get('creditCardName').touched
                                    }"
                                />
                                <small
                                    *ngIf="createPayment.get('creditCardName').hasError('required') && createPayment.get('creditCardName').touched"
                                    class="inline-error"
                                >
                                    Credit Card Name is required
                                </small>
                                <!-- cc name required {{ createPayment.get('creditCardName').hasError('required') }} -->
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <!-- <div class='form-group'>
                                                        <label>Validity Date:</label>
                                                        <select class="form-control form-control-sm form-control form-control-sm-sm" formControlName="expYear">
                                                            <option *ngFor="let year of yearArr" [value]="year">{{year}}</option>
                                                        </select>
                                                    </div> -->
                            <label>Expiry Year * </label>
                            <div class="merch-info">
                                <div class="merch_type">
                                    <select
                                        class="form-control form-control-sm"
                                        formControlName="expYear"
                                        validateOnBlur
                                        [ngClass]="{
                                            'has-error': createPayment.get('expYear').hasError('required') && createPayment.get('expYear').touched
                                        }"
                                    >
                                        <option *ngFor="let year of yearArr" [value]="year">{{ year }}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                            <small
                                *ngIf="createPayment.get('expYear').hasError('required') && createPayment.get('expYear').touched"
                                class="inline-error"
                            >
                                Expiry year is required
                            </small>
                            <!-- year required {{ createPayment.get('expYear').hasError('required') }} -->
                        </div>
                        <div class="col-sm-2">
                            <label>Expiry Month * </label>
                            <div class="merch-info">
                                <div class="merch_type">
                                    <select
                                        class="form-control form-control-sm"
                                        formControlName="expMonth"
                                        validateOnBlur
                                        [ngClass]="{
                                            'has-error': createPayment.get('expMonth').hasError('required') && createPayment.get('expMonth').touched
                                        }"
                                    >
                                        <option value="01">Jan</option>
                                        <option value="02">Feb</option>
                                        <option value="03">Mar</option>
                                        <option value="04">Apr</option>
                                        <option value="05">May</option>
                                        <option value="06">Jun</option>
                                        <option value="07">Jul</option>
                                        <option value="08">Aug</option>
                                        <option value="09">Sep</option>
                                        <option value="10">Oct</option>
                                        <option value="11">Nov</option>
                                        <option value="12">Dec</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                            <small
                                *ngIf="createPayment.get('expMonth').hasError('required') && createPayment.get('expMonth').touched"
                                class="inline-error"
                            >
                                Expiry year is required
                            </small>
                            <!-- month required {{ createPayment.get('expMonth').hasError('required') }} -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pull-right">
        <button type="button" class="btn btn-secondary" (click)="routeToPaymentForm()" [disabled]="createPayment.disabled">
            <i class="fa fa-times" aria-hidden="true"></i> Cancel
        </button>
        <button class="btn  btn-primary" type="submit" [disabled]="createPayment.disabled">
            Save <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        </button>
    </div>
</form>
