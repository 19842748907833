<div *ngIf="showModal" id="printThis" #print_this>
    <div *ngIf="loading" class='row' style='text-align:center' >
        <div class="grid-spinner mt-0">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
    <div bsModal #summaryReportModal="bs-modal" class="modal fade edit-perm-modal settle-action no-border" [config]="{ignoreBackdropClick: true}" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" (onHide)="onHideModal($event)" id='xyz'>
        <div class="modal-dialog  no-border modal-xl">
            <div class="modal-content no-border" #modal_body>
                <div #try_printing_this_2 style=" 
                    size: 5.5in 8.5in;">
                    <div class="modal-header">

                        <h4 class="modal-title">{{title}}</h4>
                        <button type="button" class="close" (click)="summaryReportModal.hide()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div #try_printing_this class="modal-body">
                        <div *ngIf="loading" class='row' style='text-align:center' >
                            <div class="grid-spinner mt-0">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                        </div>
                        <div *ngIf="!loading" class='row'>
                            <div class='col-md-12'><b>Batch Number:</b> {{ batchNum ?  batchNum: '-' }}</div>
                            <div class='col-md-12'><b>Store:</b> {{ storeName ? storeName: '-' }}</div>
                            <div class='col-md-12'><b>Profit Center:</b> {{ pctrName ? pctrName: '-' }}</div>
                        </div>
                        <accordion>
                            <accordion-group #txn_group [isOpen]="open" *ngIf="showGrid">
                                <div accordion-heading>
                                    Transactions
                                </div>
                                <grid-cmp [sumReportDataObj]=checkedObjs [attributes]=txnAttributes [storeValue]=txnBatchStoreValue [fetchByStore]=fetchByStore [showSearchBar]=showSearchBar [parameters]=txnParameters [storeObj]=txnStoreObject [action]=txnAction [sorting]=sorting></grid-cmp>
                            </accordion-group>
                            <accordion-group [isOpen]="open" class="">
                                <div accordion-heading>
                                    Summary
                                </div>
                                <!-- <accordion-group #sale_group [isOpen]="open" *ngFor='let key of objectKeys(finalReportData)'> -->
                                <div #sale_group *ngFor='let key of objectKeys(finalReportData)'>
                                    <!-- <div>
                                        {{key}}
                                    </div> -->
                                    <div>
                                        <div class="card no-padding  no-border">
                                            <table id="main-table" class="table table-sm table-striped no-margin table-grid vertical-content table_main summary-table summary-report ">
                                                <thead class="table_header">
                                                    <tr>
                                                        <th *ngFor="let a of attributes; let i=index">
                                                            <span class="text-left" *ngIf="a.header==='Type'">
                                                                {{key === 'All'? 'Batch': key}} Summary
                                                            </span>
                                                            <span [class.text-left]="a.header === 'Qty'" *ngIf="a.header!=='Type' && !(a.mapTo ==='cashbackTot' || a.mapTo ==='taxTot')">
                                                                {{a.header}}
                                                            </span>
                                                            <span *ngIf="a.header!=='Type' && (a.mapTo ==='cashbackTot' || a.mapTo ==='taxTot')"> [{{a.header}}]
                                                            </span>

                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf='!loading' [class.table_body]="!error">
                                                    <ng-container *ngFor="let innerKey of objectKeys(finalReportData[key])">
                                                        <tr>
                                                            <td *ngFor="let a of attributes">
                                                                <div *ngIf="a.header.includes('Total')">
                                                                    <div *ngIf="(a.mapTo ==='cashbackTot' || a.mapTo ==='taxTot')">
                                                                        <span *ngIf="!a.nestedKey ">
                                                                            [${{finalReportData[key][innerKey][a.mapTo]}}]
                                                                        </span>
                                                                    </div>
                                                                    <div *ngIf="!(a.mapTo ==='cashbackTot' || a.mapTo ==='taxTot')">
                                                                        <span *ngIf="!a.nestedKey ">
                                                                            ${{finalReportData[key][innerKey][a.mapTo]}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- <span *ngIf="a.header!=='Type'"> -->
                                                                <div class="text-left" *ngIf="!a.header.includes('Total')">
                                                                    <span *ngIf="!a.nestedKey">
                                                                        {{finalReportData[key][innerKey][a.mapTo]}}
                                                                    </span>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <!-- This is just some content to illustrate fancy headings. -->
                                    <!-- </accordion-group> -->
                                </div>
                                <div style='text-align:center' *ngIf='loading'>
                                    <div class="grid-spinner">
                                        <div class="bounce1"></div>
                                        <div class="bounce2"></div>
                                        <div class="bounce3"></div>
                                    </div>
                                    <span style='color:#3b5877'>Generating Report</span>
                                </div>
                            </accordion-group>
                        </accordion>
                    </div>

                    <div class="modal-footer">
                    <span>
                        <button *ngIf="showGrid" type="button" class="btn btn-primary" (click)="printSummaryReport(try_printing_this_2)" aria-label="Print" [disabled]="printLoader">
                        <span *ngIf="!printLoader">
                            <i class="fa fa-print" aria-hidden="true"></i>
                            PDF
                        </span> 
                        <span *ngIf="printLoader">
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                            Printing...
                        </span>
                        <!-- <i class="fa fa-print" aria-hidden="true"></i> Print -->
                    </button>
                    </span>
                    <span>
                    <button *ngIf="showGrid" type="button" class="btn btn-primary" (click)="printExportReport(try_printing_this_2)" aria-label="Print" [disabled]="printLoader">
                        <span *ngIf="!printLoader">
                            <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                            CSV
                        </span> 
                        <span *ngIf="printLoader">
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                            Printing...
                        </span>
                        <!-- <i class="fa fa-print" aria-hidden="true"></i> Print -->
                    </button>
                    </span>
                    <span>
                        <button *ngIf="!showGrid" type="button" class="btn btn-primary" (click)="printElement(try_printing_this_2)" aria-label="Print" [disabled]="printLoader">
                        <span *ngIf="!printLoader">
                            <i class="fa fa-print" aria-hidden="true"></i>
                            Print
                        </span> 
                        <span *ngIf="printLoader">
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                            Printing...
                        </span>
                        <!-- <i class="fa fa-print" aria-hidden="true"></i> Print -->
                    </button>
                    </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div *ngIf="!showModal">
    <div *ngIf="!loading" id='batchInfo' class='row p-b-5'>
        <div class='col-md-12'><b>Batch Number:</b> {{ batchNum ?  batchNum: '-' }}</div>
        <div class='col-md-12'><b>Store:</b> {{ storeName ? storeName: '-' }}</div>
        <div class='col-md-12'><b>Profit Center:</b> {{ pctrName ? pctrName: '-' }}</div>
    </div>
    <accordion id="txn_Settle_Response">
        <div id="txn_Batch_Table{{type}}{{responseType}}" #txn_group *ngIf="showGrid">
            <div id="batchTable{{type}}{{responseType}}">
                <!-- <div id="batchTable{{type}}{{responseType}}" [hidden]="!hideBatchTxnTable"> -->
                <h6 *ngIf='type === "txn"'>
                    <strong>Transactions</strong>
                </h6>
                <h6 *ngIf='type === "batch"'>
                    <strong>Batch</strong>
                </h6>
                <grid-cmp [attributes]=txnAttributes [storeValue]=txnBatchStoreValue [fetchByStore]=fetchByStore [showSearchBar]=showSearchBar [parameters]=txnParameters [storeObj]=txnStoreObject [action]=txnAction [sorting]=sorting></grid-cmp>
            </div>
        </div>
        <div *ngIf='!loading'>

            <accordion-group id="summaryAccordionPrint{{responseType}}" [isOpen]="open">
                <!-- <accordion-group #sale_group [isOpen]="open" *ngFor='let key of objectKeys(finalReportData); let i=index' id="summary{{type}}{{i}}{{responseType}}"> -->
                <div accordion-heading class="table_header">
                    Summary
                </div>
                <div #sale_group *ngFor='let key of objectKeys(finalReportData); let i=index' id="summary{{type}}{{i}}{{responseType}}">
                    <div>
                        {{key}}
                    </div>
                    <div>
                        <div class="card no-padding">
                            <table width="100%" border="0" id="main-table" class="table table-sm table-striped no-margin table-grid vertical-content table_main summary-report">
                                <thead class="table_header">
                                    <tr>
                                        <th *ngFor="let a of attributes; let i=index">
                                            <span class="text-left" *ngIf="a.header==='Type'">
                                                {{key === 'All'? 'Batch': key}} Summary
                                            </span>
                                            <span [class.text-left]="a.header === 'Qty'" *ngIf="a.header!=='Type' && !(a.mapTo ==='cashbackTot' || a.mapTo ==='taxTot')">
                                                {{a.header}}
                                            </span>
                                            <span *ngIf="a.header!=='Type' && (a.mapTo ==='cashbackTot' || a.mapTo ==='taxTot')">
                                                [{{a.header}}]
                                            </span>

                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf='!loading' [class.table_body]="!error">
                                    <ng-container *ngFor="let innerKey of objectKeys(finalReportData[key])">
                                        <tr>
                                            <td *ngFor="let a of attributes">
                                                <span *ngIf="a.header.includes('Total')">
                                                    <span *ngIf="(a.mapTo ==='cashbackTot' || a.mapTo ==='taxTot')">
                                                        <span *ngIf="!a.nestedKey ">
                                                            [${{finalReportData[key][innerKey][a.mapTo]}}]
                                                        </span>
                                                </span>
                                                <span *ngIf="!(a.mapTo ==='cashbackTot' || a.mapTo ==='taxTot')">
                                                        <span *ngIf="!a.nestedKey ">
                                                            ${{finalReportData[key][innerKey][a.mapTo]}}
                                                        </span>
                                                </span>
                                                </span>
                                                <!-- <span *ngIf="a.header!=='Type'"> -->
                                                <span class="text-left" *ngIf="!a.header.includes('Total')">
                                                    <span *ngIf="!a.nestedKey">
                                                        {{finalReportData[key][innerKey][a.mapTo]}}
                                                    </span>
                                                </span>

                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </accordion-group>
        </div>
        <div style='text-align:center' *ngIf='loading'>
            <div class="grid-spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <span style='color:#3b5877'>Generating Report</span>
        </div>

    </accordion>


</div>