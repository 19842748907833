import { Injectable } from "@angular/core";
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild
} from "@angular/router";
import { AuthenticationService, NotificationService } from "../../services";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private notificationService: NotificationService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const url: string = state.url;
        return this.checkLogin(url);
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.canActivate(route, state);
    }

    checkLogin(url: string): boolean {
        if (
            (localStorage.getItem("token") &&
                localStorage.getItem("token").length > 0) ||
            (sessionStorage.getItem("token") &&
                sessionStorage.getItem("token").length > 0)
        ) {
            if (this.authService.loggedIn()) {
                return true;
            }
        }

        // Store the attempted URL for redirecting
        this.notificationService.error("Please login again", "Session Expired");
        this.authService.redirectUrl = url;
        console.log("Logout from auth guard");
        // Navigate to the login page with extras
        this.router.navigate([""]);
        return false;
    }
}
