<section *ngIf="!loading">
    <form
        class="form-horizontal"
        [formGroup]="searchParams"
        (ngSubmit)="onSubmit(searchParams)"
        novalidate
    >
        <div class="card">
            <div class="card-body">
                <div class="row form-group ">
                    <div class="col-sm-9">
                        <div class="row">
                            <div class="col-md-3 col-sm-6">
                                <label for="txnnum">Customer Number:</label>
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    id="customerNum"
                                    formControlName="customerNum"
                                />
                            </div>
                            <div class="col-md-3 col-sm-6">
                                <div class="search-form-margin">
                                    <div class="row">
                                        <!-- <div class="col-sm-6">
                                    <button type='reset' class="btn btn-secondary btn-sm btn-block">
                                      <i class="fa fa-undo" aria-hidden="true"></i> Reset
                                    </button>
                                  </div> -->
                                        <div class="col-sm-12">
                                            <button
                                                type="submit"
                                                class="btn btn-primary btn-sm btn-block"
                                            >
                                                <i
                                                    class="fa fa-search"
                                                    aria-hidden="true"
                                                ></i>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>
