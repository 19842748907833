<section class="login-modal">
	<div class="modal-header">
		<h4 class="modal-title pull-left">WARNING!</h4>
		<button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
			<span aria-hidden="true" class="visually-hidden">&times;</span>
		</button>
	</div>
	<div class="modal-body billing-modal-pb-0">
		<div class="card-body">
			<p class="alert alert-warning mx-auto text-center alert-size">
				Your account is over 30 days overdue. Your payment either failed or has not been made. The processing of payments will stop within 30 days.
				Click OK to continue
			</p>
			<button class="btn btn-primary" (click)="bsModalRef?.hide()">OK</button>
		</div>
	</div>
</section>
