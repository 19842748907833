import { Component, OnInit, Output } from '@angular/core';
import { BreadcrumbService, FilterService, UserHandlerService, NotificationService, TreeService, BusinessService } from '../../../shared/services';
import { Router } from '@angular/router';
import { takeWhile, skip } from 'rxjs/operators';

@Component({
	selector: 'app-employee-layout',
	templateUrl: './employee-layout.component.html',
	styleUrls: ['./employee-layout.component.scss'],
})
export class EmployeeLayoutComponent implements OnInit {
	public showTree: boolean = false;
	alive: boolean = true;
	showGrid: boolean;
	public enableTree: boolean = true;
	public treeAddAction: boolean;
	public treeButtonLinks: any;
	public treeNodeLinks: any;
	public isLoading = false;

	tabItems: { label: string; link: any[] }[] = [
		{
			label: 'Employee',
			link: ['/dashboard/setup/business/employee'],
		},
		{
			label: 'Customer',
			link: ['/dashboard/setup/business/customer'],
		},
	];

	constructor(
		private router: Router,
		private breadcrumbService: BreadcrumbService,
		private filterService: FilterService,
		public userHandlerService: UserHandlerService,
		private notificationService: NotificationService,
		private treeService: TreeService,
		private businessService: BusinessService
	) {
		this.treeAddAction = false;
		this.treeButtonLinks = {};
		this.treeNodeLinks = {};
	}

	ngOnInit() {
		// this.checkRepToggleLinks();
		// this.filterService.setFilterValue(true);  // Not required for now
		console.log('this.treeService.businessID', this.treeService.businessID.getValue());
		this.setBreadCrumbValue();
		// this.setTreeAttributes();     // Not required for now
		this.treeService.watchShowTree$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((show: boolean) => {
				// console.log(
				//     "this.treeService.businessID inside watcher",
				//     this.treeService.businessID.getValue()
				// );
				console.log('Employee Layout: watchShowTree$: show -->', show);
				this.showTree = show;
			});
		this.treeService.watchEnableTree$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((enable: boolean) => {
				// console.log("enable", enable);
				console.log('Employee Layout: watchEnableTree$: enable -->', enable);
				this.enableTree = enable;
				this.showTree = !enable;
			});
	}
	setTreeAttributes(): void {
		this.treeButtonLinks = {};
		this.treeAddAction = true;
		this.treeNodeLinks.chain = 'chain';
		this.treeNodeLinks.property = 'property';
		this.treeNodeLinks.store = 'store';
		this.treeNodeLinks.profitCenter = 'profitCenter';
	}
	setBreadCrumbValue(): void {
		const breadCrumb: any = {};
		breadCrumb.root = 'Home';
		breadCrumb.child = '';
		breadCrumb.subChild = '';
		breadCrumb.subChildLink = null;
		breadCrumb.grandChild = null;
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	onNodeClick(nodeDetails: any) {
		const nodeID = nodeDetails.nodeId;
		const nodeType = nodeDetails.nodeType;
		let link: any[] = [];
		switch (nodeType) {
			case 'business':
				console.log('Inside biz case', this.treeService.businessID.getValue());
				// link = ['/dashboard/setup/business/structure/information', nodeID];
				break;
			// case 'chain':
			//     link = ['/dashboard/setup/business/structure/chain', nodeID];
			//     break;
			// case 'region':
			//     link = ['/dashboard/setup/business/structure/region', nodeID];
			//     break;
			// case 'property':
			//     link = ['/dashboard/setup/business/structure/property', nodeID];
			//     break;
			case 'store':
				// this.treeService.setId(nodeID, 'store');
				console.log('Inside store case', this.treeService.storeID.getValue());
				// link = ['/dashboard/setup/business/structure/store', nodeID];
				break;
			// case 'merchant_account':
			//     link = ['/dashboard/setup/business/structure/merchant-account', nodeID];
			//     break;
			// case 'payment_device':
			//     link = ['/dashboard/setup/business/structure/payment-device', nodeID];
			//     break;
			// case 'profit_center':
			//     link = ['/dashboard/setup/business/structure/profit-center', nodeID];
			//     break;
			// case 'peripheral':
			//     link = ['/dashboard/setup/business/structure/peripheral-list', nodeID];
			//     break;
			default:
				break;
		}
		this.router.navigate(link); //Route to setup page
	}
}
