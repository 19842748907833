import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { validateFor } from '../../../../../common-functions';
// import { isEmpty } from 'lodash';
// import { TAGSCONSTS } from '../../../../../constants';

// import { BatchStore } from '../../../../../store/index';
import { NotificationService, TransactionActionService, ConstantService } from '../../../../../shared/services';

export interface IAttributes {
	header: string;
	mapTo: string;
	nested?: boolean;
	type?: string;
	format?: any;
}

const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again.';

@Component({
	selector: 'details-cmp',
	templateUrl: './details.component.html',
	styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnChanges {
	@ViewChild('NgxAsidePanelRight', { static: true }) ngxAsidePanelRight: any;

	data: any = {}; //For displaying values
	// operationType: string;

	@Output() onViewClose = new EventEmitter<boolean>();
	@Input() viewDetails: IAttributes[];
	@Input() id: string;
	@Input() show: boolean;
	@Input() storeValue: string;
	// @Input() closeButton: boolean = true;
	@Input() label: string = '';
	@Input() store: any;
	ID: string = '';
	isLoading: boolean = false;
	open: boolean = false;
	xRequestTextDataTxn = [];
	numberFormat: NumberConstructor = Number;
	// newViewDetails:any[]=[];

	constructor(
		private notificationService: NotificationService,
		private transactionActionService: TransactionActionService,
		private constantService: ConstantService
	) {}

	public keepOriginalOrder = (a, b) => a.key;

	ngOnChanges(changes: SimpleChanges): void {
		// console.log("These are the changes",changes);
		// console.log("View details: ", this.viewDetails);
	
		if (this.id && changes.hasOwnProperty('id')) {
			// console.log("HERE 2====>", this.ID, this.show);
			this.ID = this.id;
			if (this.show) {
				this.isLoading = true;
				// this.ngxAsidePanelRight.show();
				this.getData();
			}
		}
		if (this.show && changes.hasOwnProperty('show')) {
			// console.log("HERE====>", this.id, this.show);
			if (this.ID) {
				this.isLoading = true;
				this.open = true;
				this.ngxAsidePanelRight.open();
				// this.ngxAsidePanelRight.open();
				this.getData();
			}
		}
	}

	getData() {
		this.store.get(this.ID).subscribe(
			(response: any) => {
				let responseData = response && response.hasOwnProperty('data') && response.data ? response.data : {};
				this.data = this.constantService.updateDetailsData(this.storeValue, responseData);
				this.xRequestTextDataTxn = this.getValue(this.data, 'xItemsInfo')?.xItems;
				// console.log('Response inside detials cmp: response', response);
				// console.log('Response inside detials cmp: responseData', responseData);
				console.log('Response inside detials cmp: data', this.data);
				console.log('Response inside detials cmp: xRequestTextData', this.xRequestTextDataTxn);
			},
			(error: any) => {
				const errMsg = validateFor('message', error) ? error.message: SOMETHING_WENT_WRONG;
				this.notificationService.error(errMsg, ' Not Found!');
				this.hideDetail();
			},
			() => {
				this.isLoading = false;
			}
		);
	}

	ngOnInit() {}

	getValue(element: any, key: string): any {
		key = key.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
		key = key.replace(/^\./, ''); // strip a leading dot
		const a = key.split('.');
		// console.log('These things are what we get===>', element, 'key===>', key, 'A==>', a);
		for (let i = 0; i < a.length; ++i) {
			const k = a[i];
			if (k in element) {
				element = element[k];
			} else {
				return;
			}
		}
		// console.log('These are the element', element);
		return element;
	}

	hideDetail() {
		console.log("hideDetail");
		this.open = false;
		this.show = false;
		this.onViewClose.emit(false);
		this.id = '';
		// this.ngxAsidePanelRight.close();
		// this.show=false;
	}
}
