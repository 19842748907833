import { TAGSCONSTS, MAX_TXN_AMT, MIN_TAX_AMT } from './../../../../constants/index';

const TRANSACTIONS_ACTION_REVERSE = 'reverse';
const BASE_AMT = 'baseAmt';
const TIP_AMT = 'tipAmt';
const TAX_AMT = 'taxAmt';

export const INVALID_AMT_MSG = 'Invalid amount.';
export const INVALID_BASE_AMT_MSG = 'Base Amount cannot be 0 or greater than max valid amount.';

export const STORE_NAME_ABBREVIATION_LENGTH_MAX_CAP: number = 18;
export const KEEP_NO_OF_CHARACTERS_FIRST: number = 12;
export const KEEP_NO_OF_CHARACTERS_LAST: number = 3;

/*
 * convert key value pair array to associative array
 *
 * @params: array: having array of objects
 * @params: primary field: field existing in object which is to be converted to key.
 * @params: secondary field: field existing in object which is to be converted to value.
 * @params: oneToOne: boolean : sets mode to return key-to-value mapping or key-to-obj mapping
 * @return: object: associative array object
 */
export function processToAssociativeObject(array, primaryField, secondaryField, oneToOne: boolean): object {
    console.log('processToAssociativeArray: array', array);
    console.log('processToAssociativeArray: primaryField', primaryField);
    console.log('processToAssociativeArray: secondaryField', secondaryField);
    let asscObj: object = {};
    if (!array || !array.length) {
        console.log('processToAssociativeArray: empty return back', array);
        return asscObj;
    }
    if (oneToOne) {
        console.log('processToAssociativeArray: oneToOne mode');
        array.map(value => {
            console.log('processToAssociativeArray: value', value);
            if (
                value.hasOwnProperty(primaryField) &&
                value.hasOwnProperty(secondaryField) &&
                value[primaryField] &&
                value[secondaryField]
            ) {
                asscObj[value[primaryField]] = value[secondaryField];
            }
        });
    } else {
        console.log('processToAssociativeArray: not oneToOne mode');
        array.map(value => {
            console.log('processToAssociativeArray: value', value);
            if (value.hasOwnProperty(primaryField) && value[primaryField]) {
                asscObj[value[primaryField]] = value;
            }
        });
    }
    console.log('processToAssociativeArray: asscObj', asscObj);
    return asscObj;
}

/*
 * set data in edittxns variable and check if tip amt to be added as well
 *
 * @params: txnData : txn data to be pushed
 * @params: addTip: boolean check if tipAmt field to be added
 * @params: currentAction: which action has it been called by
 * @return: obj : return edit obj
 */
// setDataInEditTxnsAndFilteredHash(txnData, addTip: boolean, filteredHashedData, hashedDataOfTxn: string) {
export function createEditObj(txnData, addTip: boolean, currentAction: string) {
    console.log('createEditObj: txnData', txnData);
    console.log('createEditObj: addTip', addTip);
    // let doAddHashData = false;
    let obj = {
        txnId: txnData && txnData.hasOwnProperty('_id') && txnData._id ? txnData._id : null,
        txnNum: txnData && txnData.hasOwnProperty('xTxnNum') && txnData.xTxnNum ? txnData.xTxnNum : null,
        baseAmt: txnData && txnData.hasOwnProperty('xMainAmt') && txnData.xMainAmt ? getAmtInDollars(txnData.xMainAmt) : '0.00',
        taxAmt: txnData && txnData.hasOwnProperty('xIncTaxAmt') && txnData.xIncTaxAmt ? getAmtInDollars(txnData.xIncTaxAmt) : '0.00',
        disabled: true,
        baseAmtError: false,
        tipAmtError: false,
        taxAmtError: false
    };
    if (addTip) {
        obj['tipAmt'] = txnData.hasOwnProperty('xTipAmt') ? getAmtInDollars(txnData.xTipAmt) : '0.00';
    }
    if (
        txnData.hasOwnProperty('xPullSyncInfo') &&
        txnData.xPullSyncInfo.hasOwnProperty('xHasPendingSync') &&
        txnData.xPullSyncInfo.xHasPendingSync
    ) {
        obj['disabled'] = true;
    } else {
        obj['disabled'] = false;
    }
    if (
        currentAction === TRANSACTIONS_ACTION_REVERSE &&
        txnData.hasOwnProperty('xTxnActTag') &&
        txnData.xTxnActTag &&
        txnData.xTxnActTag === TAGSCONSTS.transactionActionTags.txnActionItemTags.return
    ) {
        console.log('createEditObj: disable txn in edit modal', txnData);
        // this.editTxns[this.editTxns.length - 1]['disableOnlyEdit'] = true;
        obj['disableOnlyEdit'] = true;
    } else {
        // this.editTxns[this.editTxns.length - 1]['disableOnlyEdit'] = false;
        obj['disableOnlyEdit'] = false;
    }

    return obj;
}

export const getAmtInDollars = amt => {
    return (parseInt(amt) / 100).toFixed(2);
};

/*
 * checks if amount is valid based on conditions set for provided field
 *
 * @params: amount : number : amount value
 * @params: field : string : which field amount belongs to
 * @return: boolean : true if valid, false if invalid
 */
export function checkValidAmt(amount: number, field: string) {
    console.log('checkValidAmt: amount', amount);
    console.log('checkValidAmt: field', field);
    switch (field) {
        case BASE_AMT: // amount must be greater than 0(some value must exist), and less than max value
            console.log('checkValidAmt: BASE_AMT');
            if (amount && amount <= MAX_TXN_AMT) return true;
            break;
        case TIP_AMT: // amount can be in the range 0 - maxval
            console.log('checkValidAmt: TIP_AMT');
            if (amount >= MIN_TAX_AMT && amount <= MAX_TXN_AMT) return true;
            break;
        case TAX_AMT: // amount can be in the range 0 - maxval
            console.log('checkValidAmt: TAX_AMT');
            if (amount >= MIN_TAX_AMT && amount <= MAX_TXN_AMT) return true;
            break;
        default:
            console.log('checkValidAmt: default case: unknown field', field);
    }
    return false;
}

/**
 * If store-name is greater than certain length, abbreviate it
 * @export
 * @param {string} storeName: Store-Name
 * @param {number} keepFirst: Characters to be maintained in first half
 * @param {number} keepLast: Characters to be maintained in second half
 * @returns {string} abbreviatedStoreName:  abbreviated store name
 */
export function abbreviateStoreName(storeName: string, keepFirst: number, keepLast: number){
    let abbreviatedStoreName = storeName.substr(0, keepFirst) + '...' + storeName.substr(storeName.length - keepLast);
    console.log('abbreviatedStoreName: abbreviateStoreName', abbreviatedStoreName);
    return abbreviatedStoreName;
}
