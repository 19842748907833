import {GOOGLE_RECAPTCHA_KEY} from '../shared/config/google.config'

export const LIMIT = 100;
export const TRANSACTIONLIMIT = 100;
export const BATCHLIMIT = 100;
export const TXN_MODE = {
	STANDARD: 'STANDARD',
	BATCH: 'BATCH',
	CUSTOMER: 'CUSTOMER',
	NONE: null
};
export const MAX_TXN_AMT = 1000000;
export const MIN_TAX_AMT = 0;
export const GOOGLE_RECAPTCHA_SITE_KEY = GOOGLE_RECAPTCHA_KEY;