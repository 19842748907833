import { Component, OnInit } from '@angular/core';
import { DATEFORMAT } from '../../constants';
import { LogsStore } from '../../store';

@Component({
    selector: 'app-logs',
    templateUrl: './app-logs.component.html',
    styleUrls: ['./app-logs.component.scss']
})
export class ApplicationLogsComponent implements OnInit {
    public attributes: any[] = [];
    public storeValue: string = '';
    public storeObject: any = {};
    public action: any[] = [];
    public parameters: any = {
        noGrid: true
    };
    showDetails:boolean=false;

    // public detailVisible: boolean = true;
    public logID: string = '';
    public logType: string = 'all';

    public viewDetails: any[] = [
        // { header: 'Timestamp', mapTo: 'xExecTS', type: 'date', format: DATEFORMAT },
        { header: 'Module ', mapTo: 'xMduTag' },
        { header: 'Fucntion', mapTo: 'xFuncTag' },
        { header: 'Entity Type', mapTo: 'xEntyTypeTag' },
        { header: 'Message', mapTo: 'xMsg' },
        { header: 'Notes', mapTo: 'xNotes' }
    ];

    constructor(private logsStore: LogsStore) {}

    ngOnInit() {
        this.setGridProperties();
    }

    setGridProperties() {
        this.storeValue = 'StatusStore';
        this.storeObject = this.logsStore;
        this.attributes = [
            // { header: 'Timestamp', mapTo: 'xExecTS', width: '10%', type: 'date', format: 'dd-MMM-yyyy' },
            { header: 'Module', mapTo: 'xMduTag', width: '15%' },
            { header: 'Function', mapTo: 'xFuncTag', width: '15%' },
            // { header: 'Medium', mapTo: 'xMdmTag', width: '10%' },
            { header: 'Entity Type', mapTo: 'xEntyTypeTag', width: '10%' },
            { header: 'Entity Value', mapTo: 'xNotes', width: '10%' }
        ];

        this.action = [
            { type: 'view', visible: true, text: 'Details', icon: 'fa fa-eye', action: 'emit' },
            { type: 'other', visible: true, text: 'Settle', icon: 'fa fa-info', action: 'func' }
        ];
    }

    onActionRequest(obj: any) {
        if (obj.act.type === 'view') {
            this.logID = obj.id;
            // this.detailVisible = true;
        }
    }

    onShowDetails(obj:any){
        console.log("Show object",obj);
        this.showDetails=obj;
    }

    viewClosed(visible: boolean) {
        // this.detailVisible = visible;
        if (!visible) {
            this.logID = '';
            this.showDetails=false;
        }
    }

    fetchFilter() {
        switch (this.logType) {
            case 'all':
                if (this.parameters.hasOwnProperty('filterType') && this.parameters.hasOwnProperty('currDate')) {
                    this.parameters = {
                        noGrid: true
                        // xIsSettled: true
                    };
                }
                break;
            case 'thisWeek':
                this.parameters = {
                    noGrid: true,
                    // xIsSettled: true,
                    filterType: 'thisWeek',
                    currDate: new Date().toISOString()
                };
                break;
            case 'lastWeek':
                this.parameters = {
                    noGrid: true,
                    // xIsSettled: true,
                    filterType: 'lastWeek',
                    currDate: new Date().toISOString()
                };
                break;
            case 'thisMon':
                this.parameters = {
                    noGrid: true,
                    // xIsSettled: true,
                    filterType: 'thisMon',
                    currDate: new Date().toISOString()
                };
                break;
            case 'lastMon':
                this.parameters = {
                    noGrid: true,
                    // xIsSettled: true,
                    filterType: 'lastMon',
                    currDate: new Date().toISOString()
                };
                break;
            case 'today':
                this.parameters = {
                    noGrid: true,
                    // xIsSettled: true,
                    filterType: 'today',
                    currDate: new Date().toISOString()
                };
                break;
            case 'yesterday':
                this.parameters = {
                    noGrid: true,
                    // xIsSettled: true,
                    filterType: 'yesterday',
                    currDate: new Date().toISOString()
                };
                break;
        }
    }
}
