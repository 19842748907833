<section>
    <div class="grid-filter">
        <label class="radio1">
            <input type="radio" name="log" [(ngModel)]="logType" value="all" (change)="fetchFilter()"/>
            <span></span>
            All
        </label>
        <label class="radio1">
            <input type="radio" name="log" [(ngModel)]="logType" value="today" (change)="fetchFilter()"/>
            <span></span>
            Today
        </label>
        <label class="radio1">
            <input type="radio" name="log" [(ngModel)]="logType" value="yesterday" (change)="fetchFilter()"/>
            <span></span>
            Yesterday
        </label>
        <label class="radio1">
            <input type="radio" name="log" [(ngModel)]="logType" value="thisWeek" (change)="fetchFilter()"/>
            <span></span>
            This Week
        </label>
        <label class="radio1">
            <input type="radio" name="log" [(ngModel)]="logType" value="lastWeek" (change)="fetchFilter()"/>
            <span></span>
            Last Week
        </label>
        <label class="radio1">
            <input type="radio" name="log" [(ngModel)]="logType" value="thisMon" (change)="fetchFilter()"/>
            <span></span>
            This Month
        </label>
        <label class="radio1">
            <input type="radio" name="log" [(ngModel)]="logType" value="lastMon" (change)="fetchFilter()"/>
            <span></span>
            Last Month
        </label>
    </div>
    <form class="form-horizontal padder">
        <!--<div class="row  m-b-1">-->
        <div class="grid-add-wrap">
            <grid-cmp [attributes]=attributes [storeValue]=storeValue [parameters]=parameters [storeObj]=storeObject [action]=action
                (actionRequest)="onActionRequest($event)" (detailVisible)="onShowDetails($event)"></grid-cmp>
        </div>
        <!--<div *ngIf="detailVisible" class="col-sm-4">
            </div>-->
        <!--</div>-->
    </form>
    <details-cmp [id]=logID [viewDetails]=viewDetails [label]="'Log'" [storeValue]=storeValue [store]=storeObject [show]=showDetails (onViewClose)="viewClosed($event)"></details-cmp>
</section>

<!--[class.col-sm-10]="!detailVisible" [class.col-sm-8]="detailVisible"-->