<section *ngIf="isBusinessIDset">
	<!-- cusotmer/store name beginning -->
	<div *ngIf="showParentMode" class="merchant-cust-info">
		<!-- <div *ngIf="showCustomerName"> -->
		{{ member_account_parentMode }}: <strong *ngIf="parentName">{{ parentName }}</strong>
		<strong *ngIf="!parentName">
			<span>
				<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
			</span>
		</strong>
		<!-- </div> -->
		<!-- <div *ngIf="showStoreName">
			Store: <strong *ngIf="storeName">{{ storeName }}</strong>
			<strong *ngIf="!storeName">
				<span>
					<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
				</span>
			</strong>
		</div> -->
	</div>
	<!-- cusotmer/store name ending -->
	<form class="form-horizontal report-grid-wrap">
		<div class="grid-add-wrap">
			<span
				class="pull-right tooltip-wrap-span"
				tooltip="Add New Payment Method"
				placement="left"
				container="body"
				containerClass="tooltip-global"
			>
				<button class="btn btn-info btn-sm add-btn" type="button" (click)="onAddNewPaymentmethodClick()">
					<span> <i class="fa fa-plus-circle" aria-hidden="true"></i> Add </span>
				</button>
			</span>
		</div>
		<!-- member-accounts grid beginning -->
		<grid-cmp
			[attributes]="attributes"
			[storeValue]="storeValue"
			[parameters]="parameters"
			[storeObj]="storeObject"
			[action]="action"
			[checkValue]="checkValue"
			[hasActive]="false"
			[fetchByStore]="fetchByStore"
			(detailVisible)="onShowDetails($event)"
			(actionRequest)="onActionRequest($event)"
		></grid-cmp>
		<!-- member-accounts grid ending -->
	</form>
	<!-- add/edit modal beginning -->

	<!-- <div *ngIf="visible">
		<app-add-payment
			[mode]="mode"
			(sendStateObj)="receiveStateObj($event)"
			[member_account_parentID]="member_account_parentID"
			[signupStoreID]="signupStoreID"
			[memberAccountID]="memberAccountID"
		></app-add-payment>
	</div> -->
	<div *ngIf="visible">
		<app-add-payment
			[modalMode]="mode"
			[member_account_parentID]="member_account_parentID"
			[member_account_parentMode]="member_account_parentMode"
			[memberAccountID]="memberAccountID"
			(sendStateObj)="receiveStateObj($event)"
		></app-add-payment>
	</div>
	<!-- add/edit modal ending -->
	<!-- details component beginnig -->
	<div>
		<details-cmp
			[id]="selectedID"
			[viewDetails]="finalViewDetails"
			[storeValue]="storeValue"
			[label]="'Member Account'"
			[store]="storeObject"
			[show]="showDetails"
			(onViewClose)="viewClosed($event)"
		></details-cmp>
	</div>
	<div *ngIf="showPayNowModal">
		<member-account-pay-now-modal
			[safeID]="memberAccountID"
			[storeID]="customerSignupStoreID"
			[customerID]="member_account_parentID"
			(closeModalEvent)="payNowModalClosedEventHandler($event)"
		></member-account-pay-now-modal>
	</div>
	<div *ngIf="showTokenModal">
		<payment-token-response-modal
			[tokenData]="tokenData"
			(closeModalEvent)="tokenResModalClosedEventHandler($event)"
		></payment-token-response-modal>
	</div>
	<!-- details component ending -->
</section>
