<div class="breadcrumb-container">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-6">
				<ol class="breadcrumb">
					<!-- root starts -->
					<li class="breadcrumb-item">
						<div class="dropdown" dropdown>
							<a class="dropdown-toggle" dropdownToggle>
								<i
									class="fa"
									aria-hidden="true"
									[class.fa-home]="breadCrumbValue.root === 'Home'"
									[class.fa-cog]="breadCrumbValue.root === 'Setup'"
									[class.fa-money]="breadCrumbValue.root === 'Billing'"
									[class.fa-server]="breadCrumbValue.root === 'VTerminal'"
									[class.fa-exchange]="breadCrumbValue.root === 'Transactions'"
									[class.fa-cog]="breadCrumbValue.root === 'Vault'"
									[class.fa-line-chart]="breadCrumbValue.root === 'Reports'"
									[class.fa-life-ring]="breadCrumbValue.root === 'Support'"
									[class.fa-history]="breadCrumbValue.root === 'CSV File History'"
								></i>
								{{ breadCrumbValue.root }}
							</a>
							<!-- For root List -->
							<ul
								class="dropdown-menu animated fadeIn"
								*ngIf="breadCrumbValue['rootList'] && breadCrumbValue['rootList'].length"
							>
								<li class="dropdown-messages" *ngFor="let l of breadCrumbValue.rootList">
									<a [routerLink]="[l.link]" class="dropdown-item">
										{{ l.name }}
									</a>
								</li>
							</ul>
						</div>
					</li>
					<!-- root ends -->

					<!-- child starts -->
					<li class="breadcrumb-item" *ngIf="breadCrumbValue.child">
						<div
							[ngClass]="breadCrumbValue.child === 'Structure' || breadCrumbValue.child === 'Billing' ? 'pointer-none' : 'dropdown'"
							dropdown
							*ngIf="breadCrumbValue.child"
						>
							<a class="dropdown-toggle" dropdownToggle>
								{{ breadCrumbValue.child }}
								<!-- <span *ngIf="tooltip">...</span> -->
							</a>
							<span
								*ngIf="breadCrumbValue.toolTip && breadCrumbValue.loading === false"
								tooltip="{{ breadCrumbValue.toolTipData }}"
								placement="bottom"
								container="body"
								containerClass="tooltip-global"
							>
								...
							</span>
							<span *ngIf="breadCrumbValue.loading">
								<i class="fa fa-spin fa-spinner"></i>
							</span>
							<!-- For child List -->
							<div [hidden]="!(breadCrumbValue.childList && breadCrumbValue.childList.length)">
								<ul *dropdownMenu class="animated fadeIn dropdown-menu">
									<li role="menuitem" *ngFor="let l of breadCrumbValue.childList">
										<a class="dropdown-item" [routerLink]="[l.link]">{{ l.name }}</a>
									</li>
								</ul>
							</div>
						</div>
					</li>
					<!-- child ends -->

					<!-- Sub Child starts -->
					<li class="breadcrumb-item" *ngIf="breadCrumbValue.subChild">
						<div *ngIf="breadCrumbValue.subChildLink">
							<a class="dropdown-toggle" [routerLink]="[breadCrumbValue.subChildLink]">
								{{ breadCrumbValue.subChild }}
							</a>
						</div>
						<div *ngIf="!breadCrumbValue.subChildLink">
							<a class="dropdown-toggle pointer-none">
								{{ breadCrumbValue.subChild }}
							</a>
						</div>
					</li>
					<!-- Sub Child ends -->

					<!-- Grand Child starts -->
					<!-- <li *ngIf="breadCrumbValue.grandChild">
						<div>
							{{breadCrumbValue.grandChild}}
						</div>
					</li>
					<li *ngIf="breadCrumbValue.greatGrandChild">
						<div>
							{{breadCrumbValue?.greatGrandChild}}
						</div>
					</li> -->
					<!-- {{breadCrumbValue.grandChild}} -->
					<li class="breadcrumb-item" *ngIf="breadCrumbValue.grandChild">
						<div *ngIf="breadCrumbValue.grandChildLink">
							<a class="dropdown-toggle" [routerLink]="[breadCrumbValue.grandChildLink]">
								{{ breadCrumbValue.grandChild }}
							</a>
						</div>
						<div *ngIf="!breadCrumbValue.grandChildLink">
							<a class="dropdown-toggle">
								{{ breadCrumbValue.grandChild }}
							</a>
						</div>
					</li>
					<li *ngIf="breadCrumbValue.greatGrandChild">
						<div>
							{{ breadCrumbValue?.greatGrandChild }}
						</div>
					</li>

					<!-- Grand Child Ends -->
				</ol>
			</div>

			<div class="col-md-6 hidden-sm">
				<!-- <div>
					<filter-cmp [modifyFilter]=modifyFilter></filter-cmp>
				</div> -->
				<!-- <div class="searchbar">
					<form role="search">
						<div class="search-box">
							<i class="fa fa-search"></i>
							<input type="search" class="form-control">
						</div>
					</form>
				</div> -->
			</div>
		</div>
	</div>
</div>
