<section *ngIf="!loading" class="setup add-merchant">
	<form
		class="form-horizontal"
		[ngClass]="{ 'disable-form': parentMode || userHandlerService?.userRoles?.isBusinessEmployee }"
		[formGroup]="form"
		(ngSubmit)="onSubmit(form)"
		novalidate
	>
		<div class="row top-stat">
			<div class="col">
				<div class="card">
					<div class="card-body">
						<i class="fa fa-check-circle-o label-icon" aria-hidden="true"></i>
						<label class="p-l-5" for="name">Status: </label>
						<label class="detail-label"> Active </label>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="card">
					<div class="card-body">
						<i class="fa fa-sliders label-icon" aria-hidden="true"></i>
						<label for="name" class="p-l-5">Type:</label>
						<label class="detail-label">Merchant Account</label>
					</div>
				</div>
			</div>
			<div class="col-md-3 col-sm-12 p-0">
				<div class="card">
					<div class="card-body">
						<i class="fa fa-map-marker label-icon" aria-hidden="true"></i>
						<label for="dba_name" class="p-l-5">Store:</label>
						<label class="detail-label">{{ storeName }}</label>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="card">
					<div class="card-body">
						<i class="fa fa-level-up label-icon fa-flip-horizontal" aria-hidden="true"></i>
						<label for="dba_name" class="p-l-5">Profit Center:</label>
						<label class="detail-label">{{ pctrName }}</label>
					</div>
				</div>
			</div>
		</div>

		<div>
			<div class="row buis-info">
				<div class="col-md-6">
					<div class="card">
						<div class="card-header">
							<label class="">Basic Information</label>
						</div>
						<div class="card-body">
							<div class="form-group row">
								<div class="col-md-12 col-sm-12">
									<label for="name"
										>Name:
										<small class="text-danger">*</small>
									</label>
									<small
										*ngIf="
											!serverErrors['xName'] && form.get('xName').hasError('required') && form.get('xName').touched
										"
										class="inline-error"
									>
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Name is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>
									<small
										*ngIf="
											!serverErrors['xName'] &&
											form.get('xName').hasError('incorrectNameFormat') &&
											form.get('xName').touched
										"
										class="inline-error"
									>
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Name is of incorrect format. Correct Format-: 1) Name can not be more than 20 letters. 2) The first character of Name should not be an empty space. 3) The first character of Name should not be any special characters, E.g. /,& etc."
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>
									<small *ngIf="serverErrors['xName']" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Name is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<input
										type="text"
										(focus)="clearErrors('xName')"
										class="form-control form-control-sm"
										id="name"
										formControlName="xName"
										[ngClass]="{
											'has-error':
												(form.get('xName').hasError('required') && form.get('xName').touched) ||
												(form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched) ||
												serverErrors['xName']
										}"
										validateOnBlur
									/>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-6 col-sm-12">
									<label for="tag">Tag: </label>
									<!-- <small *ngIf="form.get('xTag').hasError('required') && form.get('xTag').touched" class="inline-error"> Tag is required</small>

                                <small *ngIf="formErrors['xTag']" class="inline-error"> {{formErrors.xTag}}</small> -->

									<input
										type="text"
										class="form-control form-control-sm"
										id="tag"
										(focus)="clearErrors('xTag')"
										formControlName="xTag"
									/>
								</div>
								<div class="col-md-6 col-sm-12">
									<label for="ref_num">Ref Number: </label>
									<small
										*ngIf="
											!serverErrors['xRefNum'] &&
											form.get('xRefNum').hasError('required') &&
											form.get('xRefNum').touched
										"
										class="inline-error"
									>
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Reference is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>

									<small *ngIf="serverErrors['xRefNum']" class="inline-error">
										<i
											class="fa fa-exclamation-triangle inline-error"
											container="body"
											tooltip="Reference is required"
											placement="top"
											containerClass="tooltip-custom inline-error"
											aria-hidden="true"
										></i>
									</small>
									<i
										class="fa fa-info-circle"
										tooltip="Do not enter the reference number unless instructed to do so"
										placement="top"
										containerClass="tooltip-global"
										container="body"
										aria-hidden="true"
									></i>
									<input
										type="text"
										class="form-control form-control-sm"
										id="ref_num"
										(focus)="clearErrors('xRefNum')"
										formControlName="xRefNum"
										[ngClass]="{
											'has-error':
												(form.get('xRefNum').hasError('required') && form.get('xRefNum').touched) ||
												serverErrors['xRefNum']
										}"
										validateOnBlur
									/>
								</div>
							</div>
							<div class="form-group row  no-margin">
								<div class="col-md-12 col-sm-12">
									<label for="mer_num"
										>Merchant Account Number:
										<small class="text-danger">*</small>
									</label>
									<!-- <small *ngIf="!serverErrors['xMerAcctNum'] && form.get('xMerAcctNum').hasError('required') && form.get('xMerAcctNum').touched"
                                    class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Merchant Account Number is required" placement="top"
                                        containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>

                                <small *ngIf="serverErrors['xMerAcctNum']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Merchant Account Number is required" placement="top"
                                        containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>

                                <input type="text" class="form-control form-control-sm" id="mer_num" (focus)="clearErrors('xMerAcctNum')" formControlName="xMerAcctNum"
                                    [ngClass]="{'has-error': (form.get('xMerAcctNum').hasError('required') && form.get('xMerAcctNum').touched) || serverErrors['xMerAcctNum']}"
                                    validateOnBlur> -->
									<app-model-number
										[modelNumber]="merchantInfo.xMerAcctNum"
										[form]="form"
										[serverErrors]="serverErrors"
										[numberKey]="numberKey"
									></app-model-number>
								</div>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-header">
							<label class="">Processing</label>
						</div>
						<div class="card-body">
							<div class="form-group row ">
								<div class="col-md-5 col-sm-12">
									<label for="processor"
										>Processor:
										<!--<small class="text-danger">*</small>-->
									</label>
								</div>
								<div class="col-md-6 col-sm-12 merch-info">
									<div class="merch_type">
										<select
											class="form-control form-control-sm"
											formControlName="xProcTag"
											(change)="processorSelected($event)"
										>
											<option value="" selected="!merchantInfo.xProcTag">Select Processor</option>
											<option *ngFor="let processor of processorList" [value]="processor.xTag">{{
												processor.xName
											}}</option>
										</select>
										<span>
											<i class="fa fa-angle-down"></i>
										</span>
									</div>
								</div>
								<div class="col-md-1 mt-1">
									<span 
										*ngIf="showProcessorLock && !enableProcessorChange"
										[tooltip]="'Enable Processor Change'"
										placement="top"
										containerClass="tooltip-global"
										container="body"
										(click)="toggleProcessorLock()"
									>
										<i class="fa fa-lock"></i>
									</span>
									<span 
										*ngIf="showProcessorLock && enableProcessorChange"
										[tooltip]="'Disable Processor Change'"
										placement="top"
										containerClass="tooltip-global"
										container="body"
										(click)="toggleProcessorLock()"
									>
										<i class="fa fa-unlock"></i>
									</span>
								</div>
							</div>
							<div class="form-group row  no-margin">
								<div class="col-md-5 col-sm-12">
									<label for="cap_mode"
										>Capture Mode:
										<!--<small class="text-danger">*</small>-->
									</label>
								</div>
								<!-- <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" formControlName="xCaptTag" [ngClass]="{'has-error': (form.get('xCaptTag').hasError('required') && form.get('xCaptTag').touched) || serverErrors['xCaptTag']}">

                                        <option value="" selected="!merchantInfo.xCaptTag">Select Processor</option>
                                        <option *ngFor="let capture of captureList" [value]="capture.xTag">{{capture.xName}}</option>
                                    </select>
                                    <span><i class="fa fa-angle-down"></i></span>
                                </div>
                            </div> -->
								<div class="col-md-6 col-sm-12">
									<!-- <small *ngIf="!serverErrors['xRefNum'] && form.get('xRefNum').hasError('required') && form.get('xRefNum').touched" class="inline-error">
                                     <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Reference is required" placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>

                                </small>

                                <small *ngIf="serverErrors['xRefNum']" class="inline-error">
                                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Reference is required" placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>

                                </small> -->
									<input
										type="text"
										class="form-control form-control-sm"
										id="cap_mode"
										(focus)="clearErrors('xCaptTag')"
										formControlName="xCaptTag"
										[ngClass]="{
											'has-error':
												(form.get('xCaptTag').hasError('required') && form.get('xCaptTag').touched) ||
												serverErrors['xCaptTag']
										}"
										[attr.disabled]="true"
										validateOnBlur
									/>
								</div>
							</div>
						</div>
						<div class="form-group row no-margin">
							<div class="col-md-12 col-sm-12">
								<div class="card no-margin" formArrayName="xProcParams">
									<table class="table proc-table no-margin">
										<thead>
											<tr>
												<th width="30%">
													Parameter
												</th>
												<!--<th>
                                                Key
                                            </th>-->
												<th width="70%">
													Value
												</th>
											</tr>
										</thead>
										<tbody>
											<ng-container *ngFor="let configParam of procParams.value; let i = index">
												<tr>
													<td width="30%">
														{{ procParamName[configParam.xParamTag] }}
														<small
															*ngIf="configParam.xParamKey=== batchTimeParamKey"
															class="ml-2"
														>
														<i
															class="fa fa-info-circle"
															[tooltip]="AUTO_BATCH_TIME_INFO"
															placement="top"
															containerClass="tooltip-global"
															container="body"
															aria-hidden="true"
														></i>
														</small>
														<small
															*ngIf="configParam.xParamTag === RAPID_CONN_TPPID_TAG"
															class="ml-2"
														>
														<i
															class="fa fa-info-circle"
															[tooltip]="TPP_ID_INFO"
															placement="top"
															containerClass="tooltip-global"
															container="body"
															aria-hidden="true"
														></i>
														</small>
													</td>
													<td width="70%">
														<input
															type="text"
															[value]="procParams.value[i].xParamVal"
															(input)="procParams.value[i].xParamVal = $event.target.value"
															[placeholder]='processorPlaceholder[i] ? processorPlaceholder[i] : ""'
															(blur)="procParams.value[i].xParamVal = procParams.value[i].xParamVal.trim()"
														/>
													</td>
												</tr>
											</ng-container>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="mapping">
						<div class="clearfix">
							<tabset>
								<!-- <tab>
                            <ng-template tabHeading>
                                Medium Acceptance
                            </ng-template>
                            <form name="nestedForm">
                                <div class="row mapping">
                                    <div class="col-md-5">
                                        <div>Accepted</div>
                                        <div class="mapping-card">
                                            <label class="checkbox1 no-margin" for="Option1">
                                            <input id="Option1" type="checkbox" (change)="addAllTags(this.mediumTagList, this.acceptedMediumTags, isAllAcceptanceTagsChecked.medium)" [(ngModel)]="isAllAcceptanceTagsChecked.medium" [ngModelOptions]="{standalone: true}"> <span></span>
                                            All
                                         </label>
                                        </div>
                                        <div class="card mapping-detail  no-margin">
                                            <ul>
                                                <li *ngFor="let medium of mediumTagList">
                                                    <label class="checkbox1" for="{{medium._id}}-medium">
                                                    <input id="{{medium._id}}-medium" type="checkbox" (change)=" isAllAcceptanceTagsChecked.medium = false; updateTagsArray(acceptedMediumTags, medium)" [checked]="getIndexOfObject(this.acceptedMediumTags, medium) >= 0"> <span></span>
                                                     {{medium.xName}}
                                                </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </tab> -->
								<tab>
									<ng-template tabHeading>
										Brand Acceptance
									</ng-template>
									<div class="row mapping">
										<div class="col-md-12">
											<!-- <div>Accepted</div> -->
											<div class="mapping-card">
												<label class="checkbox1 no-margin" for="Option2">
													<input
														id="Option2"
														type="checkbox"
														(change)="
															addAllTags(
																this.brandTagList,
																this.acceptedBrandTags,
																isAllAcceptanceTagsChecked.brand
															)
														"
														[(ngModel)]="isAllAcceptanceTagsChecked.brand"
														[ngModelOptions]="{ standalone: true }"
													/>
													<span></span>
													All
												</label>
												<span class="pull-right badge badge-success m-t-5">Accepted</span>
											</div>
											<div class="card mapping-detail no-margin">
												<ul>
													<li *ngFor="let brand of brandTagList" style="display: inline-block;">
														<label class="checkbox1" for="{{ brand._id }}-brand">
															<input
																id="{{ brand._id }}-brand"
																type="checkbox"
																(change)="
																	isAllAcceptanceTagsChecked.brand = false;
																	updateTagsArray(acceptedBrandTags, brand)
																"
																[checked]="getIndexOfObject(this.acceptedBrandTags, brand) >= 0"
															/>
															<span></span>
															{{ brand.xName }}</label
														>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</tab>
								<tab>
									<ng-template tabHeading>
										Transaction Acceptance
									</ng-template>
									<div class="row mapping">
										<div class="col-md-12">
											<!-- <div>Accepted</div> -->
											<div class="mapping-card">
												<label class="checkbox1 no-margin" for="Option3">
													<input
														id="Option3"
														type="checkbox"
														(change)="
															addAllTags(
																this.transactionTagList,
																this.acceptedTransactionTags,
																isAllAcceptanceTagsChecked.transaction
															)
														"
														[(ngModel)]="isAllAcceptanceTagsChecked.transaction"
														[ngModelOptions]="{ standalone: true }"
													/>
													<span></span>
													All
												</label>
												<span class="pull-right badge badge-success m-t-5">Accepted</span>
											</div>
											<div class="card mapping-detail no-margin">
												<ul>
													<li *ngFor="let transaction of transactionTagList">
														<label class="checkbox1" for="{{ transaction._id }}-transaction">
															<input
																id="{{ transaction._id }}-transaction"
																type="checkbox"
																(change)="
																	isAllAcceptanceTagsChecked.transaction = false;
																	updateTagsArray(acceptedTransactionTags, transaction)
																"
																[checked]="getIndexOfObject(this.acceptedTransactionTags, transaction) >= 0"
															/>
															<span></span>
															{{ transaction.xName }}</label
														>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</tab>
							</tabset>
						</div>
					</div>
					<div class="card mapping m-t">
						<div class="card-header">
							<label class="">Mapping</label>
						</div>
						<div class="card-body">
							<div class="row">
								<!-- <div class="col-md-6">
                                <div>Profit Center</div>
                                <div class="mapping-card">
                                    <label class="checkbox1 no-margin" for="pctrs">
                                        <input id="pctrs" type="checkbox" [(ngModel)]="isMappingTagsChecked.profitCenter" [ngModelOptions]="{standalone: true}"
                                            (change)="addAllTags(this.profitCenterList, this.mappedProfitCenterShortCode, isMappingTagsChecked.profitCenter)">
                                        <span></span>
                                        All
                                    </label>
                                </div>
                                <div class="card mapping-detail no-margin">
                                    <ul>
                                        <li *ngFor="let profitCenter of profitCenterList">
                                            <label class="checkbox1" for="profit-{{profitCenter._id}}">
                                                <input id="profit-{{profitCenter._id}}" type="checkbox" (change)="isMappingTagsChecked.profitCenter = false; updateTagsArray(mappedProfitCenterShortCode, profitCenter)"
                                                    [checked]="getIndexOfObject(this.mappedProfitCenterShortCode, profitCenter) >= 0">
                                                <span></span>
                                                {{profitCenter.xName}} </label>
                                        </li>
                                    </ul>
                                </div>
                            </div> -->
								<!-- <div class="col-md-4">
                                <div>Card Mediums</div>
                                <div class="mapping-card no-margin">
                                    <label class="checkbox1 no-margin" for="card_medium">
                                        <input id="card_medium" type="checkbox" [(ngModel)]="isMappingTagsChecked.medium" [ngModelOptions]="{standalone: true}" (change)="addAllTags(this.mediumTagList, this.mappedMediumTags, isMappingTagsChecked.medium)"> <span></span>
                                        All
                                    </label>
                                </div>
                                <div class="card mapping-detail no-margin">
                                    <ul>
                                        <li *ngFor="let medium of mediumTagList">
                                            <label class="checkbox1" for="card-{{medium._id}}">
                                               <input id="card-{{medium._id}}" type="checkbox" (change)="isMappingTagsChecked.medium = false; updateTagsArray(mappedMediumTags, medium)" [checked]="getIndexOfObject(this.mappedMediumTags, medium) >= 0"> <span></span>
                                           {{medium.xName}}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div> -->
								<div class="col-md-12">
									<div class="mapping-card">
										<label class="checkbox1 no-margin" for="card_brands">
											<input
												id="card_brands"
												type="checkbox"
												[(ngModel)]="isMappingTagsChecked.brand"
												[ngModelOptions]="{ standalone: true }"
												(change)="
													addAllTags(this.mappingBrandTagList, this.mappedBrandTags, isMappingTagsChecked.brand)
												"
												[disabled]="
													this.mappingBrandTagList.length === 0 ||
													this.mappingBrandTagList.length < this.brandTagList.length
												"
											/>
											<span></span>
											All
										</label>
										<span class="pull-right badge badge-primary m-t-5">Card Brands</span>
									</div>
									<div class="card mapping-detail  no-margin">
										<ul *ngIf="mappingBrandTagList.length">
											<li *ngFor="let brand of mappingBrandTagList">
												<label class="checkbox1" for="brand-{{ brand._id }}">
													<input
														id="brand-{{ brand._id }}"
														type="checkbox"
														(change)="
															isMappingTagsChecked.brand = false; updateTagsArray(mappedBrandTags, brand)
														"
														[checked]="getIndexOfObject(this.mappedBrandTags, brand) >= 0"
													/>
													<span></span>
													{{ brand.xName }}</label
												>
											</li>
										</ul>
										<div *ngIf="mappingBrandTagList.length === 0">
											No cards are available for mapping to merchant account.<br>
											Multiple merchant accounts cannot service the same card type on the same profit center.  You may need to create a new profit center for additional merchant accounts.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-header">
							<label>Notes</label>
						</div>
						<div class="form-group  no-margin">
							<div class="">
								<small class="inline-error"> </small>
								<small *ngIf="serverErrors['xNotes']" class="inline-error">
									<i
										class="fa fa-exclamation-triangle inline-error"
										container="body"
										tooltip=" Notes is required"
										placement="top"
										containerClass="tooltip-custom inline-error"
										aria-hidden="true"
									></i>
								</small>
								<textarea
									class="form-control notes-area"
									rows="1"
									id="notes"
									(focus)="clearErrors('xNotes')"
									formControlName="xNotes"
									style="min-height: 52px;"
								></textarea>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row  m-t-5" *ngIf="!(parentMode || userHandlerService?.userRoles?.isBusinessEmployee)">
				<div class="col-sm-12 text-right">
					<!-- <a class="btn btn-secondary" [routerLink]="['/dashboard/setup/business/structure/information']" [routerLinkActive]="['router-link-active']"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a> -->
					<button class="btn btn-secondary" (click)="onCancel($event)">
						<i class="fa fa-times" aria-hidden="true"></i> Cancel
					</button>
					<span 
						class="tooltip-wrap-span" 
						[tooltip]="(edit && !canEdit) || (!edit && !canAdd) ? noPermMsg : 'Save'" 
						placement="left" 
						container="body" 
						containerClass="tooltip-global"
                	>
						<button class="btn btn-primary" name="next" type="submit" [disabled]="submitting || (edit && !canEdit) || (!edit && !canAdd)">
							<span *ngIf="!submitting">
								Save
								<i class="fa fa-angle-double-right" aria-hidden="true"></i>
							</span>
							<span *ngIf="submitting"
								>Please wait...
								<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
							</span>
						</button>
					</span>
				</div>
			</div>
		</div>
	</form>
	<div class="row  m-t-5" *ngIf="parentMode && !userHandlerService?.userRoles?.isBusinessEmployee">
		<div class="col-sm-12 text-right">
			<!-- <a class="btn btn-secondary" (click)="routeToPrevious()"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a> -->
			<!--<a class="btn btn-secondary" [routerLink]="['/dashboard/setup/business/information']" [routerLinkActive]="['router-link-active']"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a>-->
			<button 
				class="btn btn-primary" 
				(click)="onEditClick($event)"
				[disabled]="!canEdit"
			>
				<i class="fa fa-pencil" aria-hidden="true"></i> 
				Edit
			</button>
		</div>
	</div>
</section>
<section *ngIf="edit && loading">
	<div style="text-align: center; position:realtive">
		<div class="col-md-12">
			<!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="color:#445d75;position:relative; top:50px; text-align:Center"></i>
            <span class="sr-only">Loading Tags...</span> -->
			<div class="grid-spinner">
				<div class="bounce1"></div>
				<div class="bounce2"></div>
				<div class="bounce3"></div>
			</div>
			<span style="color:#3b5877">Fetching data</span>
		</div>
	</div>
</section>
<div *ngIf="showOverLay" class="overlay">	
    <div class="overlay-content">	
        <div>	
            <i style="color:white;" class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>	
        </div>	
        <div>	
            <span style="font-size:20px">Please wait.</span>	
        </div>	
    </div>	
</div>
