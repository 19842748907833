import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import { BaseComponent } from '../../base.component';
import { Social } from '../../../../../../models';
import { GlobalValidator } from '../../../../../services';
import { phoneMask } from '../../../../utils';

@Component({
    selector: 'app-social-contact-row',
    templateUrl: './social-contact-row.component.html',
    styleUrls: ['./social-contact-row.component.scss']
})
export class SocialContactRowComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() generalContact: Social;
    @Input() generalContactForm: FormGroup;
    @Input() serverErrors: any = {};
    @Input() tagType: string;

    mask = phoneMask;

    constructor(public fb: FormBuilder) {
        super();
        this.formErrors = {
            xSocial: {
                xPhone: '',
                xWebsite: '',
                xFacebook: '',
                xTwitter: '',
                xLinkedIn: ''
            }
        };
    }

    ngOnChanges(change: SimpleChanges) {
        console.log(change);
        if (change.hasOwnProperty('generalContact') && !change.generalContact.firstChange) {
            if (!this.generalContactForm.controls['xSocial']) {
                this.generalContactForm.addControl('xSocial', this.toFormGroup(new Social(this.generalContact)));
            }
            this.generalContactForm.controls['xSocial'].patchValue(new Social(this.generalContact));
            if (this.tagType === 'Company Business') {
                this.contact.controls['xWebsite'].disable();
                this.contact.controls['xFacebook'].disable();
                this.contact.controls['xTwitter'].disable();
                this.contact.controls['xLinkedIn'].disable();

            }
        }

        if (change.hasOwnProperty('serverErrors') && !change.serverErrors.firstChange) {
            this.validateServerErrors();
        }
    }

    ngOnInit() {
        console.log("here i have reached social", this.generalContact);
        this.generalContactForm.addControl('xSocial', this.toFormGroup(new Social(this.generalContact)));
        // this.contact = this.toFormGroup(new GeneralContact(this.generalContact));
        this.contact.valueChanges.subscribe(data => this.onValueChanged(this.generalContactForm, data));

        this.onValueChanged(this.generalContactForm); // (re)set validation messages now
    }

    set contact(value: FormGroup) {
        this.generalContactForm.controls['xSocial'] = value;
    }

    get contact() {
        return this.generalContactForm.get('xSocial') as FormGroup;
    }

    public toFormGroup(data: Social): FormGroup {
        console.log("Social data", data);
        const formGroup = this.fb.group({
            xWebsite: [data.xWebsite, GlobalValidator.websiteFormat],
            xFacebook: [data.xFacebook],
            xTwitter: [data.xTwitter],
            xLinkedIn: [data.xLinkedIn]
        });

        return formGroup;
    }

    // onKeypress(event: any) {
    //     GlobalValidator.phoneNumberFormat(event);
    // }

    // onPaste(event: any) {
    //     GlobalValidator.phoneNumberFormat(event);
    // }
}
