import { Injectable } from '@angular/core';
 

import { HttpService, TransactionActionService } from '../shared/services';
import { TRANSACTIONLIMIT } from '../constants/configuration.constants';
// const endpoint = 'xpeTxns';
// const endpoint = 'settle_action/success_txns_lists';
const endpoint = 'settle_action/fail_txns_lists';

@Injectable()
export class SettleFailedTransactionsListStore {
    static TransactionsData: any[];
    protected gridProps: any = {};

    constructor(
        protected httpService: HttpService,
        private transactionActionService: TransactionActionService
    ) {}

    getAll(params: any): any {
        if(params.hasOwnProperty('xBusinessID')){
            // params.limit=TRANSACTIONLIMIT;
            delete params.xBusinessID;
        }
        return this.httpService.store(endpoint, params);
    }

    store(element: any): any {
        return this.httpService.store(endpoint, element);
    }

    update(elementID: string, newElement: any): any {
        return this.httpService.update(endpoint, elementID, newElement);
    }

    get(elementID: any): any {
        return this.httpService.get(endpoint, elementID);
    }

    destroy(elementID: any): any {
        return this.httpService.destroy(endpoint, elementID);
    }
}
