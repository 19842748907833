import { AfterViewInit, Component, OnInit, Output, ViewChild, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { skip, takeWhile } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { reduceFileNameLength, validateFor, validateIfArray } from 'app/common-functions';
import { BusinessService, HttpService, NotificationService, TreeService } from 'app/shared/services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { API_END_POINT, BE_KEY } from '../../../../../constants/index';
import {
	FIELD_IS_REQUIRED_ERR_MSG,
	FILE_NOT_SUPPORTED_MSG,
	PAYLOAD_KEYS,
	REQUEST_SUBMITTED_SUCCESFULLY,
	SELECT_REQUIRED_FIELDS,
	SOMETHING_WENT_WRONG_ERR_MSG,
} from './constants';
import { createFormData } from './helper-functions';

const ERROR: string = 'Error';
const SUCCESS: string = 'Success';
const STORE_ID_ERR: string = 'Could not set storeID. Please try again.';
const NO_TERMINAL_ERR_MSG: string = 'There are no terminals under this Profit Center. Please choose different Profit Center.';
export const ALLOWED_FILE_FORMATS = ['.txt', '.csv', '.eft', '.EFT'];
@Component({
	selector: 'app-import-transactions-modal',
	templateUrl: './import-transactions-modal.component.html',
	styleUrls: ['./import-transactions-modal.component.scss'],
})
export class ImportTransactionsModalComponent implements OnInit, OnChanges, AfterViewInit {
	@ViewChild('importTxnModal')
	public importTxnModal: ModalDirective; //modal
	@Output()
	public closeModalEvent = new EventEmitter<boolean>(); // event emitted when modal is closed
	@Output()
	public refreshGridEvent = new EventEmitter<boolean>();
	@Input() showImportTxnModal: boolean = false;
	@Input() showInfo: boolean = false; //Show info related to import txn action when opened from outstanding txn
	pctrName: string = ''; //profit center name
	GMPW: string = ''; //GMPW of selected profit center
	GMID: string = ''; //GMIE of selected profit center
	terminalDropDownArr: any[] = []; //Array containing terminal data
	defDropDownArray: any[] = []; //Array containig definition files data
	selectedTermID: string = ''; //TerminalID after selecting in drop down
	selectedGTID: string = ''; //GTID of selected terminal
	pCtrID: string = ''; //MongoID of profit center
	uploadedFile: any; //File selected for processing
	selectedDefFileID: string = ''; //Selected definition file id
	modalLoader: boolean = false; //Boolean to check if data is loaded
	form: FormGroup; //form
	reduceFileNameLength = reduceFileNameLength; //function which reduces files having large name
	errors = {
		importFile: '',
	}; //Object for handling form errors
	parameters: any = {};
	alive: boolean = true;
	submitting: boolean = false;
	constructor(
		private treeService: TreeService,
		private httpService: HttpService,
		private notificationService: NotificationService,
		private businessService: BusinessService,
		private fb: FormBuilder
	) {}

	/**
	 * On click of import button in grid, check for temrinal and open modal
	 * @param {SimpleChanges} changes
	 * @memberof ImportTransactionsModalComponent
	 */
	async ngOnChanges(changes: SimpleChanges) {
		if (changes.hasOwnProperty('showImportTxnModal') && !changes.showImportTxnModal.firstChange && changes.showImportTxnModal.currentValue) {
			try {
				this.setPCtrData();
				let hasTerminal = await this.fetchTerminalAndDefFiles(this.pCtrID);
				if (hasTerminal && this.showImportTxnModal) {
					this.importTxnModal.show();
				}
			} catch (err) {
				this.notificationService.error(err, ERROR);
				this.closeModalEvent.emit(true);
			}
		}
	}

	ngOnInit() {
		this.toFormGroup();
		this.businessService.watchBusinessID$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((bizID) => {
				if (bizID) {
					this.form.patchValue({ xBusinessID: bizID });
				}
			});
		this.treeService.profitCenterID.subscribe(async (pctrID) => {
			if (!pctrID) {
				// console.log('no pctrID: returning from import txn modal component');
				return;
			}
			this.pCtrID = pctrID;
			this.form.patchValue({ xPCtrID: this.pCtrID });
		});
	}

	/**
	 * Init form group
	 * @memberof ImportTransactionsModalComponent
	 */
	toFormGroup() {
		this.form = this.fb.group({
			xTerminalID: ['', Validators.required],
			xPCtrID: [this.pCtrID],
			xBusinessID: [this.businessService.businessID.getValue()],
			xStoreID: [''],
			importFile: ['', Validators.required],
			definitionFileID: ['', Validators.required],
		});
	}

	/**
	 * Extract terminal data from api response and set to terminal drop down
	 * @param {any} terminalsResponse
	 * @memberof ImportTransactionsModalComponent
	 */
	setTerminalDropDown(terminalsResponse: any) {
		this.terminalDropDownArr = validateFor(BE_KEY.data, terminalsResponse) ? terminalsResponse[BE_KEY.data] : [];
		this.selectedTermID = this.terminalDropDownArr.length ? this.terminalDropDownArr[0]._id : '';
	}

	/**
	 * Create query and fetch terminal and definition files data
	 * @param {string} pctrID
	 * @memberof ImportTransactionsModalComponent
	 */
	fetchTerminalAndDefFiles(pctrID: string): Promise<any> {
		return new Promise((resolve, reject) => {
			let terminalQuery = {
				xMainPCtrID: pctrID,
				noGrid: true,
				selectKey: `${PAYLOAD_KEYS.xName},${PAYLOAD_KEYS.xGTID},${PAYLOAD_KEYS.xStoreID}`,
			};
			this.modalLoader = true;
			let observable = [
				this.httpService.getAll(API_END_POINT.xpcTerminals, terminalQuery),
				this.httpService.getAll(API_END_POINT.importTxnDefinition, { noGrid: true }),
			];
			forkJoin(observable).subscribe(
				(response: any) => {
					// console.log('fetchTerminalAndDefFiles: response', response);
					this.modalLoader = false;
					if (!validateIfArray(response)) {
						return reject(SOMETHING_WENT_WRONG_ERR_MSG);
					}
					let termResp = response[0];
					// console.log('termResp: fetchTerminalAndDefFiles', termResp);
					if (!termResp || !termResp.data || !termResp.data.length) {
						return reject(NO_TERMINAL_ERR_MSG);
					}
					let defFielsResp = response.length > 1 ? response[1] : {};
					this.setTerminalDropDown(termResp);
					this.setDefFilesDropDown(defFielsResp);
					this.setStoreID(termResp);
					return resolve(true);
				},
				(error) => {
					console.log('error: fetchTerminalAndDefFiles', error);
					this.modalLoader = false;
					let msg = validateFor(BE_KEY.message, error) ? error[BE_KEY.message] : SOMETHING_WENT_WRONG_ERR_MSG;
					// this.notificationService.error(msg, ERROR);
					return reject(msg);
				}
			);
		});
	}

	/**
	 * Set GMPW and pctr names from pctr data
	 *
	 * @memberof ImportTransactionsModalComponent
	 */
	setPCtrData() {
		this.pctrName = validateFor(PAYLOAD_KEYS.xName, this.treeService.currentClickedNodeData)
			? this.treeService.currentClickedNodeData[PAYLOAD_KEYS.xName]
			: '';
		this.GMPW = validateFor(PAYLOAD_KEYS.xGMPW, this.treeService.currentClickedNodeData) ? this.treeService.currentClickedNodeData[PAYLOAD_KEYS.xGMPW] : '';
		this.GMID = validateFor(PAYLOAD_KEYS.xGMID, this.treeService.currentClickedNodeData) ? this.treeService.currentClickedNodeData[PAYLOAD_KEYS.xGMID] : '';
	}

	/**
	 * Set definition files drop down array from api response
	 * @param {object} defFilesResp api response
	 * @memberof ImportTransactionsModalComponent
	 */
	setDefFilesDropDown(defFilesResp: any) {
		this.defDropDownArray = validateFor(BE_KEY.data, defFilesResp) ? defFilesResp[BE_KEY.data] : [];
		this.selectedDefFileID = this.defDropDownArray.length ? this.defDropDownArray[0].id : '';
		this.onSelectTerminal();
	}

	/**
	 * On selecting definition file, clear form error
	 * @memberof ImportTransactionsModalComponent
	 */
	onSelectDefFile() {
		console.log('def file selected');
	}

	/**
	 * On selecting terminal, set GTID and clear form error
	 * @memberof ImportTransactionsModalComponent
	 */
	onSelectTerminal() {
		let terminal = this.terminalDropDownArr.find((terminal) => terminal._id === this.selectedTermID);
		this.selectedGTID = terminal[PAYLOAD_KEYS.xGTID];
	}

	setStoreID(termResp: any) {
		let terminalData = validateFor(BE_KEY.data, termResp) ? termResp[BE_KEY.data] : [];
		let storeID = terminalData.length ? terminalData[0][PAYLOAD_KEYS.xStoreID] : '';
		if (!storeID) {
			this.notificationService.error(STORE_ID_ERR, ERROR);
			return;
		}
		this.form.patchValue({
			xStoreID: storeID,
		});
	}

	/**
	 * Validate form data before submission and set form error
	 *
	 * @returns
	 * @memberof ImportTransactionsModalComponent
	 */
	validateFormData() {
		let hasError = false;
		if (!this.form.value.importFile) {
			hasError = true;
			this.errors.importFile = FIELD_IS_REQUIRED_ERR_MSG;
		}
		if (this.form.value.importFile && !ALLOWED_FILE_FORMATS.includes('.' + this.form.value.importFile.name.split('.').pop())) {
			hasError = true;
			this.errors.importFile = FILE_NOT_SUPPORTED_MSG + ALLOWED_FILE_FORMATS.toString();
		}
		if (hasError) {
			this.notificationService.error(SELECT_REQUIRED_FIELDS, ERROR);
		}
		return hasError;
	}

	/**
	 * Handle form submit
	 * @returns
	 * @memberof ImportTransactionsModalComponent
	 */
	onSubmit() {
		this.submitting = true;
		if (this.validateFormData()) {
			this.submitting = false;
			return;
		}
		let formData = createFormData(this.form);
		this.createTxns(formData);
	}

	/**
	 * Hit api for creating txns and show success/error results
	 * @param {object} payload payload for creating transactions
	 * @memberof ImportTransactionsModalComponent
	 */
	createTxns(payload: any) {
		this.httpService.store(API_END_POINT.importTransaction, payload).subscribe(
			(importTxnResp) => {
				// console.log('createTxns: importTxnsResp', importTxnResp);
				this.closeModal();
				this.refreshGridEvent.emit(true);
				this.notificationService.success(REQUEST_SUBMITTED_SUCCESFULLY, SUCCESS);
			},
			(importTxnsError) => {
				console.log('createTxns: importTxnsError', importTxnsError);
				this.closeModal();
				let errMsg = validateFor(BE_KEY.message, importTxnsError) ? importTxnsError[BE_KEY.message] : SOMETHING_WENT_WRONG_ERR_MSG;
				this.notificationService.error(errMsg, ERROR);
			}
		);
	}

	/**
	 * Handle the file uploaded by user and update the form data, clear error
	 * @param {FileList} file
	 * @memberof ImportTransactionsModalComponent
	 */
	fileUpload(file: FileList) {
		this.uploadedFile = file.item(0);
		this.form.patchValue({
			importFile: this.uploadedFile,
		});
		this.errors.importFile = '';
	}

	ngAfterViewInit() {
		if (this.showImportTxnModal) {
			this.importTxnModal.show();
		}
	}

	/**
	 * Close import transactions modal
	 * @memberof ImportTransactionsModalComponent
	 */
	closeModal() {
		this.importTxnModal.hide();
		this.form.patchValue({ importFile: '' });
		this.errors.importFile = '';
		this.uploadedFile = '';
		this.submitting = false;
		this.closeModalEvent.emit(true);
	}
}
