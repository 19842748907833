import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../../services/authentication-service';

@Injectable()
export /**
 * @brief      Checks if user is already logged and redirects to dashboard if authenticated
 */
class UserLoggedGuard implements CanActivate {
    constructor(private authService: AuthenticationService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const url: string = state.url;
        return this.isUserAlreadyAuthenticated(url);
    }

    isUserAlreadyAuthenticated(url: string): boolean {
        if((localStorage.getItem('token') && localStorage.getItem('token').length > 0) || (sessionStorage.getItem('token') && sessionStorage.getItem('token').length > 0)){
            if (this.authService.loggedIn()) {
                this.router.navigate(['dashboard/home']);
            }
        }

        return true;
    }
}
