<div
	bsModal
	#payNowModal="bs-modal"
	class="modal fade"
	tabindex="-1"
	role="dialog"
	aria-labelledby="myLargeModalLabel"
	aria-hidden="true"
	[config]="{ backdrop: 'static' }"
>
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="closePayNowModal()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">Pay Now Using This Card</h4>
			</div>
			<!-- global spinner beginning -->
			<div *ngIf="modalLoader" class="modal-body">
				<div style="text-align: center; position:realtive">
					<div class="col-md-12">
						<div class="grid-spinner">
							<div class="bounce1"></div>
							<div class="bounce2"></div>
							<div class="bounce3"></div>
						</div>
						<span style="color:#3b5877">Fetching data... Please wait.</span>
					</div>
				</div>
			</div>
			<!-- global spinner ending -->
			<!-- modal body beginning -->
			<ng-container *ngIf="!modalLoader">
				<div class="modal-body pb-0">
					<!-- pctr dropdown beginning -->
					<div class="form-group row">
						<div class="col-md-4">
							<label for="profit_center">Profit Center:<small class="text-danger">*</small> </label>
							<div class=" merch-info">
								<div class="merch_type">
									<select
										[(ngModel)]="selectedPctrID"
										(change)="pctrDropDownValueChange()"
										#pctrDropDown="ngModel"
										class="form-control form-control-sm"
									>
										<option value="" selected="defaultPCTR"> Select A Profit Center </option>
										<option *ngFor="let pctrObj of pctrDropDownArr" [value]="pctrObj.id" id="{{ pctrObj.id }}">{{
											pctrObj?.pctrName
										}}</option>
									</select>
									<span>
										<i class="fa fa-angle-down"></i>
									</span>
								</div>
							</div>
						</div>
						<!-- terminal dropdown beginning -->
						<div *ngIf="selectedPctrID" class="col-md-4">
							<label for="terminal">Terminal:<small class="text-danger">*</small> </label>
							<div class=" merch-info">
								<div class="merch_type">
									<select
										[(ngModel)]="selectedTerminalID"
										(change)="terminalDropDownValueChange()"
										class="form-control form-control-sm"
									>
										<option value="" selected="defaultTerminal"> Select A Terminal </option>
										<option *ngFor="let termObj of terminalDropDownArr" [value]="termObj.id">{{
											termObj?.terminalName
										}}</option>
									</select>
									<span>
										<i class="fa fa-angle-down"></i>
									</span>
								</div>
							</div>
						</div>
						<!-- terminal dropdown ending -->
					</div>
					<!-- pctr dropdown ending -->

					<!-- pgComPaymentFields beginning -->
					<div class="form-group row">
						<div *ngIf="pgComPaymentFields['GMID']" class="col-sm-4">
							<div>
								<label for="xGMID">GMID: </label>
							</div>
							<div>
								<span> {{ pgComPaymentFields?.GMID }} </span>
							</div>
						</div>
						<div *ngIf="pgComPaymentFields['GMPW']" class="col-sm-4">
							<div>
								<label for="xGMPW">GMPW: </label>
							</div>
							<div>
								<span> {{ pgComPaymentFields?.GMPW }} </span>
							</div>
						</div>
						<div *ngIf="pgComPaymentFields['GTID']" class="col-sm-4 pl-4">
							<div>
								<label for="GTID">GTID: </label>
							</div>
							<div>
								<span> {{ pgComPaymentFields?.GTID }} </span>
							</div>
						</div>
					</div>
					<!-- pgComPaymentFields ending -->
					<div class="form-group row ">
						<div class="col-md-4">
							<label for="account_name">Name</label>
							<div>
								<span>
									{{ safeData?.xName }}
								</span>
							</div>
						</div>
						<div class="col-md-4">
							<label for="token">Account Token</label>
							<div>
								<span>
									{{ safeData?.maskedAcctToken }}
								</span>
							</div>
						</div>
						<div class="col-md-4 pl-4">
							<label for="medium">Medium</label>
							<div>
								<span>
									{{ safeData?.medium }}
								</span>
							</div>
						</div>
					</div>
					<!-- cc fields beginning -->
					<div class="form-group row" *ngIf="safeData?.xMdmTag === BRAND_CC">
						<div class="col-md-4">
							<label for="acct_num">Account Number</label>
							<div>
								{{ safeData?.xMaskedAcctNum }}
							</div>
						</div>
						<div class="col-md-4">
							<label for="exp_date">Expiry Date</label>
							<div>{{ safeData?.xExpMonth }}/{{ safeData?.xExpYear }}</div>
						</div>
						<div class="col-md-4 pl-4">
							<label for="brand">Brand</label>
							<div>
								<span>
									{{ safeData?.brand }}
								</span>
							</div>
						</div>
					</div>
					<!-- cc fields ending -->
					<!-- ach fields beginning -->
					<div class="form-group row" *ngIf="safeData?.xMdmTag === BRAND_ACH">
						<div class="col-md-3">
							<label for="acct_num">Account Number</label>
							<div>
								<span>{{ safeData?.xRealAcctNum }}</span>
							</div>
						</div>
						<div class="col-md-3">
							<label for="rtg_num">Routing Number</label>
							<div>
								<span>{{ safeData?.xRtgNum }}</span>
							</div>
						</div>
						<div class="col-md-3">
							<label for="acct_type">Account Type</label>
							<div>
								<span>{{ safeData?.acctType }}</span>
							</div>
						</div>
						<div class="col-md-3">
							<label for="brand">Brand</label>
							<div>
								<span>
									{{ safeData?.brand }}
								</span>
							</div>
						</div>
					</div>
					<!-- ach fields ending -->
					<div class="modal-footer">
						<button class="btn btn-secondary" type="button" (click)="closePayNowModal()">Cancel</button>
						<span
							class="pull-right tooltip-wrap-span"
							[tooltip]="!canPayNow ? noPermMsg: 'Proceed'"
							placement="left"
							container="body"
							containerClass="tooltip-global"
						>
							<button 
								class="btn btn-primary ml-1" 
								type="button" 
								[disabled]="submitting || !canPayNow" 
								(click)="proceedToVterm()"
							>
								<span *ngIf="!submitting">
									Proceed
									<i class="fa fa-angle-double-right" aria-hidden="true"></i>
								</span>
								<span *ngIf="submitting"
									>Please wait...
									<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
								</span>
							</button>
						</span>
					</div>
				</div>
			</ng-container>
			<!-- modal body ending -->
		</div>
	</div>
</div>
