import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportTransactionsModalComponent } from './import-transactions-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports: [CommonModule, ModalModule, FormsModule, ReactiveFormsModule, TooltipModule.forRoot()],
	declarations: [ImportTransactionsModalComponent],
	exports: [ImportTransactionsModalComponent],
})
export class ImportTransactionsModule {}
