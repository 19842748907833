import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { takeWhile, skip } from 'rxjs/operators';
import {
	NotificationService,
	BreadcrumbService,
	BusinessService,
	GlobalValidator,
	TreeService,
	HttpService,
	UrlStoreService,
	AuthenticationService,
	UserHandlerService,
	CompanyService
} from '../../../../../../shared/services';
import {
	SetupBillingCustomerStore,
	SetupSecurityUsersStore,
	SetupBusinessStructureStoreSetupStore,
	SetupCompanyStore
} from '../../../../../../store';
import { MemberAccountStore } from 'app/store/member-account-store';
// import {SHORTCODES} from '../../../../../../constants/shortCodes.constants';
import { TAGSCONSTS } from '../../../../../../constants/tags.constants';
import { setControlsTouched, validateFor } from '../../../../../../common-functions';
import { MESSAGES, NO_PERMISSION_MSG } from '../../../../../../constants/messages.constants';
import { customSwal, SHORT_MASKED_CARD_TOKEN } from '../../../../../../constants/index';
import { RouteGuardGuard } from '../../../../../../shared';

const customerEndpoint = 'xbcCustomers';
// const safeEndpoint = 'xpcSafes';
const ROUTE_TO_MEMBER_ACCOUNTS = 'memberAccounts';
const ROUTE_TO_CUSTOMER_LIST = 'customerList';
const CUSTOMER_LIST_URL = `/dashboard/setup/billing/customer`;

//DB-KEYS
const DB_XSIGNUP_STORE_ID_KEY: string = 'xSignupStoreID';
const DB_XNAME_KEY: string = 'xName';
const DB_XHASHED_PWD_KEY: string = 'xHashedPwd';
const DB_XUSER_INFO_KEY: string = 'xUserInfo';

//BE keys
const BE_DATA_KEY = 'data';

const COULD_NOT_SET_STORE_NAME_MSG: string = 'Could not set Store Name.';
const ERR_MSG = 'Something went wrong. Please try again or contact support.';

const INVALID_PASSWORD_KEY: string = 'INVALID_PASSWORD';
const ERROR_VALUE: string = 'Error';
const REQUIRED_KEY: string = 'required';

const COMPANY_LIST_ROUTE = '/dashboard/setup/company/information'
@Component({
	selector: 'add-edit',
	templateUrl: './add-edit.component.html',
	styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit, OnDestroy {
	//constants
	public ROUTE_TO_CUSTOMER_LIST: string = ROUTE_TO_CUSTOMER_LIST;

	setupBillingCustomerModel: any = {}; //For storing values incase of edit mode
	edit: boolean = false;
	serverErrors: any = {}; //Stores serverErrors returned by server
	form: FormGroup; //Required for reactive form validation
	submitting: boolean = false; //For handling form submission
	isLoading: boolean = false;
	alive: boolean = true;
	firstLoad: boolean = true;
	storeId: string = ''; //For keeping the store ID in which customer belongs
	// accountList: Safe[] = [];
	loading: boolean = false;
	tag: string[] = [];
	formErrors = {
		acctxName: ''
	};
	numberKey: string = 'xCustomerNum';
	l1IncorrectNotifyMailFormat: boolean = false;
	l2IncorrectNotifyMailFormat: boolean = false;
	l3IncorrectNotifyMailFormat: boolean = false;
	dontSubmit: boolean = false;
	public source = 'customer';
	accessStoreReqParam: any = {};
	public securityData: any = {};
	public employeeData: any = {};
	public hasUser: boolean = false;
	userInformation: any = {};
	userRoles: any = {};
	usersData: any = {};
	gridAttributes: any[] = [];
	gridParams: any = {};
	memberAccountStoreObj: any = {};
	memberAccountStoreValue: string = '';
	public fetchByStore: boolean = true;
	private disableRouteGuard: boolean = false;
	public isPaymentAccountEdit: boolean = true;
	public tooltipMessage: string = 'Add/Edit Deposit Accounts';

	private signupStoreName: string = ''; //To show store name of customer
	public showSecurity: boolean = false;
	public showAddUserCheckBox: boolean = false;
	canEdit = true;
	canAdd = true;
	noPermMsg = NO_PERMISSION_MSG;
	constructor(
		private setupBillingCustomerStore: SetupBillingCustomerStore,
		private memberAccountsStore: MemberAccountStore,
		private breadcrumbService: BreadcrumbService,
		private notificationService: NotificationService,
		public businessService: BusinessService,
		public treeService: TreeService,
		private router: Router,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private httpService: HttpService,
		public urlStoreService: UrlStoreService,
		public authenticationService: AuthenticationService,
		private setupSecurityUsersStore: SetupSecurityUsersStore,
		private setupBusinessStructureStoreSetupStore: SetupBusinessStructureStoreSetupStore,
		private userHandlerService: UserHandlerService,
		private routeGuard: RouteGuardGuard,
		private companyService: CompanyService
	) {
		//this.urlStoreService.swapUrl(router.url);
	}

	ngOnInit() {
		this.checkCompanyAsCustomer()
		this.memberAccountStoreObj = this.memberAccountsStore;
		this.memberAccountStoreValue = 'memberAccountStore';
		this.setGridAttributes();
		this.setupPerms();
		this.showAddUserCheckBox = true;
		// incase of edit mode we get the setupBillingCustomer(id recieved in route) here:
		this.route.params.forEach((params: Params) => {
			// this.initValidation();
			console.log('add/edit customer params', JSON.stringify(params));
			if (params.hasOwnProperty('Id')) {
				this.initValidation();
				this.edit = true;
				this.isLoading = true;
				this.loading = true;

				//Customer number should not be altered
				// During sync, customer number is used to fetch customerID
				// If customer number is changed, then new transactions being synced into mongo will have new customer num
				// This will cause transactions to have same customer ids, but different customer numbers.
				// To prevent the same customer number edit is disabled.
				this.form.controls.xCustomerNum.disable();

				// if (this.edit) {
				// 	this.disable = false;
				// }
				this.setGridParams(params);

				//Get the setupBillingCustomer by its index
				forkJoin([
					this.setupBillingCustomerStore.get(params['Id']),
					this.setupSecurityUsersStore.getAll({
						xEntyID: params['Id']
					})
				]).subscribe(
					(response: any) => {
						this.setupBillingCustomerModel = response[0].data;
						console.log(this.setupBillingCustomerModel, 'setupBillingCustomerModel: subscribe: forkJoin');
						console.log('Cust add-edit: This is the response inside edit ============>>', response);
						console.log('customer init: userdata', response[1]);
						console.log('first');
						this.usersData = this.securityData =
							response[1] && response[1].hasOwnProperty('data') && response[1]['data'] && response[1]['data'][0]
								? response[1]['data'][0]
								: {};

						console.log('customer init: securitydata', this.securityData);
						if (Object.keys(this.securityData).length) {
							this.hasUser = true;
							if (this.hasUser && this.edit) {
								console.log('has user', this.hasUser, 'security data', this.securityData);
								this.showAddUserCheckBox = false;
								this.showSecurity = true;
							}
						} else {
							this.showAddUserCheckBox = true;
							this.hasUser = false;
						}
						console.log('customer init: hasUser', this.hasUser);
						this.setFormValues(response[0].data);
						let signupStoreID = validateFor(DB_XSIGNUP_STORE_ID_KEY, this.setupBillingCustomerModel)
							? this.setupBillingCustomerModel[DB_XSIGNUP_STORE_ID_KEY]
							: '';
						this.setSignupStoreName(signupStoreID);
						this.isLoading = false;
						this.loading = false;
						// this.toggleDisable();
					},
					(error: any) => {
						this.handleErrorResponse(error);
					}
				);
			}
		});
		if (!this.edit) {
			this.setGridParams('');
			this.checkStoreId();
			this.initValidation();
			if(!this.businessService.businessData.xAutoFillMermNum && typeof(this.businessService.businessData.xAutoFillMermNum) !== 'undefined'){
				this.setupBillingCustomerModel.xCustomerNum="123";
				this.form.controls["xCustomerNum"].setValue("");
				this.form.controls.xCustomerNum.enable();
			}
			this.signupStoreName = validateFor(DB_XNAME_KEY, this.treeService.treeData) ? this.treeService.treeData.xName : '-';
		}

		this.businessService.watchBusinessID$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe((bizId: string) => {
				// this.routeToPreviousPage(); //Route to customer-list page when business is changed
				console.log('Cust add-edit: biz id is changed');
				this.urlStoreService.routeBackToPreviousUrl();
			});

		this.form.controls['xBusinessID'].setValue(this.businessService.businessID.getValue());
		this.setBreadCrumbValue();
	}

	/**
	 * Get and set signup store name of customer
	 * @param `storeID`: string: mongoID
	 */
	setSignupStoreName(storeID: string = '') {
		let storeQuery = {
			_id: storeID,
			selectKey: DB_XNAME_KEY
		};
		console.log('storeQuery', storeQuery);
		this.setupBusinessStructureStoreSetupStore.getAll(storeQuery).subscribe(
			storeRespnse => {
				console.log('store data: setup customer--->', storeRespnse);
				let storeObj =
					storeRespnse && validateFor(BE_DATA_KEY, storeRespnse) && storeRespnse.data.length ? storeRespnse.data[0] : {};
				this.signupStoreName = validateFor(DB_XNAME_KEY, storeObj) ? storeObj[DB_XNAME_KEY] : '-';
			},
			error => {
				console.log('error: store data fetch', error);
				this.notificationService.error(COULD_NOT_SET_STORE_NAME_MSG, 'Error');
			}
		);
	}

	checkStoreId() {
		this.storeId = this.treeService.storeID.getValue();

		console.log('checkstoreid', this.storeId);
		if (!this.storeId) {
			console.log('Cust add-edit: No store id');
			this.notificationService.error('Please select a store before adding a new customer', 'ERROR');
			// this.routeToPreviousPage();
			this.urlStoreService.routeBackToPreviousUrl();
		}
	}

	setBreadCrumbValue(): void {
		const breadCrumb: any = {};
		breadCrumb.root = 'Setup';
		breadCrumb.child = 'Billing';
		breadCrumb.subChild = 'Customers';
		breadCrumb.subChildLink = 'setup/billing/customer';
		breadCrumb.grandChild = !!this.edit ? 'Edit' : 'Create';
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	initValidation(): void {
		this.form = this.fb.group({
			xTntTag: [''],
			xWall1EntyID: [''],
			xWall2EntyID: [''],
			xTag: [TAGSCONSTS.roleTags.roleItemTags.customerRole],
			xStatus: ['1'],
			xName: ['', []],
			xTypeTag: [TAGSCONSTS.customerTags.regularCust],
			xNotes: [''],
			xRefNum: ['', []],
			xUserID: ['', []],
			xCustomerNum: ['', [Validators.required]],
			xCompanyName: [
				'',
				[
					/*Validators.required*/
				]
			],
			// xMemberNumber: ['', [Validators.required]],
			xFormalName: this.fb.group({
				xPrefix: ['', []],
				xForename: ['', [Validators.required]],
				xMiddlename: ['', []],
				xSurname: ['', [Validators.required]],
				xSuffix: ['', []],
				xFullName: ['', []]
			}),
			xAddr: this.fb.group({
				xCO: ['', []],
				xLine1: ['', [Validators.required]],
				xLine2: [''],
				xSuite: [''],
				xCity: ['', [Validators.required]],
				xSOP: ['', [Validators.required]],
				xPostCode: ['', [Validators.required]],
				xCountry: ['', [Validators.required]]
			}),
			xContact: this.fb.group({
				xEmail: ['', [Validators.required, GlobalValidator.mailFormat]],
				xEmailTypeTag: ['', []],
				xPhone: ['', [Validators.required]],
				xPhoneTypeTag: ['', []]
			}),
			xAltAddr: this.fb.group({
				xCO: [''],
				xLine1: ['', []],
				xLine2: ['', []],
				xSuite: ['', []],
				xCity: ['', []],
				xSOP: ['', []],
				xPostCode: ['', []],
				xCountry: ['', []]
			}),
			xNotify: this.fb.group({
				xL1URLs: [[], []],
				xL2URLs: [[], []],
				xL3URLs: [[], []]
			}),
			xAltContact: this.fb.group({
				xEmail: ['', [GlobalValidator.mailFormat]],
				xEmailTypeTag: ['', []],
				xPhone: ['', []],
				xPhoneTypeTag: ['', []]
			}),
			xBusinessID: [''],
			xMainStoreID: [this.storeId],
			xSignupStoreID: [this.storeId],
			xPayAccts: this.fb.array([])
		});
	}

	get paymentArray(): FormArray {
		return this.form.get('xPayAccts') as FormArray;
	}

	onItemAddedEditedOrRemoved(val) {
		console.log('here 2', val);
		let currNotifyObj = this.form.getRawValue().xNotify;
		const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
		Object.keys(currNotifyObj).map(key => {
			if (currNotifyObj[key].length > 0) {
				let incorrectFormat = false;
				currNotifyObj[key].map(obj => {
					console.log('OBJ--->', obj);
					let val = obj.value;
					if (val !== '' && (val.length <= 5 || !EMAIL_REGEXP.test(val))) {
						// this.modifyUrlLevelMailFormats(key, true);
						incorrectFormat = true;
					}
				});
				this.modifyUrlLevelMailFormats(key, incorrectFormat);
			} else {
				this.modifyUrlLevelMailFormats(key, false);
			}
		});
	}

	modifyUrlLevelMailFormats(key, value) {
		switch (key) {
			case 'xL1URLs':
				console.log('case1');
				this.l1IncorrectNotifyMailFormat = value;
				break;
			case 'xL2URLs':
				console.log('case2');
				this.l2IncorrectNotifyMailFormat = value;
				break;
			case 'xL3URLs':
				console.log('case3');
				this.l3IncorrectNotifyMailFormat = value;
				break;
		}
		if (this.l1IncorrectNotifyMailFormat || this.l2IncorrectNotifyMailFormat || this.l3IncorrectNotifyMailFormat) {
			this.dontSubmit = true;
		} else {
			this.dontSubmit = false;
		}
	}

	get userInfo(): any {
		return this.form.get('xUserInfo');
	}

	setFormValues(data: any): void {
		console.log('Cust add-edit: DAta comin to store', JSON.parse(JSON.stringify(data)));
		console.log('xnotify', data.xNotify);
		this.form.patchValue({
			xTntTag: data.xTntTag,
			xWall1EntyID: data.xWall1EntyID,
			xWall2EntyID: data.xWall2EntyID,
			xTag: data.xTag,
			xStatus: data.xStatus,
			xName: data.xName,
			xTypeTag: data.xTypeTag,
			xNotes: data.xNotes,
			xRefNum: data.xRefNum,
			xCustomerNum: data.xCustomerNum,
			// xMemberNumber: data.xMemberNumber,
			xCompanyName: data.xCompanyName,
			xUserID: data.xUserID,
			xFormalName: {
				xPrefix: data.xFormalName ? data.xFormalName.xPrefix : '',
				xForename: data.xFormalName ? data.xFormalName.xForename : data.xName,
				xMiddlename: data.xFormalName ? data.xFormalName.xMiddlename : '',
				xSurname: data.xFormalName ? data.xFormalName.xSurname : '',
				xSuffix: data.xFormalName ? data.xFormalName.xSuffix : '',
				xFullName: data.xFormalName ? data.xFormalName.xFullName : ''
			},
			xAddr: {
				xCO: data.xAddr.xCO,
				xLine1: data.xAddr.xLine1,
				xLine2: data.xAddr.xLine2,
				xSuite: data.xAddr.xSuite,
				xCity: data.xAddr.xCity,
				xSOP: data.xAddr.xSOP,
				xPostCode: data.xAddr.xPostCode,
				xCountry: data.xAddr.xCountry
			},
			xContact: {
				xEmail: data.xContact.xEmail,
				xPhone: data.xContact.xPhone,
				xEmailTypeTag: data.xContact.xEmailTypeTag,
				xPhoneTypeTag: data.xContact.xPhoneTypeTag
			},
			xBusinessID: data.xBusinessID,
			xSignupStoreID: data.xSignupStoreID,
			xMainStoreID: data.xMainStoreID
			// xNotify: data.xNotify
		});
		console.log('setFormValue: form value', JSON.parse(JSON.stringify(this.form.getRawValue())));
		if (data.hasOwnProperty('xNotify') && Object.keys(data['xNotify'].length > 0)) {
			console.log('data xnotify', data['xNotify']);
			Object.keys(data['xNotify']).map(key => {
				console.log('key', key);
				this.form.controls['xNotify'].get(key).setValue([]);
				console.log('this.form', this.form);
				let urlList = data['xNotify'][key].split(',');
				console.log('url list', urlList);
				urlList.forEach(url => {
					if (url.length > 0) {
						this.form.controls['xNotify'].get(key).value.push({ value: url, display: url });
						console.log('get value', this.form.controls['xNotify'].get(key));
						console.log('get value', this.form.controls['xNotify'].get(key).value);
					}
				});
			});
		}

		if (data && data.xAltAddr) {
			this.form.patchValue({
				xAltAddr: {
					xCO: data.xAltAddr.xCO || '',
					xLine1: data.xAltAddr.xLine1 || '',
					xLine2: data.xAltAddr.xLine2 || '',
					xSuite: data.xAltAddr.xSuite || '',
					xCity: data.xAltAddr.xCity || '',
					xSOP: data.xAltAddr.xSOP || '',
					xPostCode: data.xAltAddr.xPostCode || '',
					xCountry: data.xAltAddr.xCountry || ''
				}
			});
		}

		if (data && data.xAltContact) {
			this.form.patchValue({
				xAltContact: {
					xEmail: data.xAltContact.xEmail || '',
					xPhone: data.xAltContact.xPhone || '',
					xEmailTypeTag: data.xAltContact.xEmailTypeTag || '',
					xPhoneTypeTag: data.xAltContact.xPhoneTypeTag || ''
				}
			});
		}

		if (data.xPayAccts && data.xPayAccts.constructor === Array) {
			data.xPayAccts.forEach(item => {
				this.paymentArray.push(this.fb.group(item));
			});
		}
		this.toggleDisable();
	}

	onSubmit(form: FormGroup, routeTo = ''): void {
		console.log('onSubmit: form', form);
		console.log('onSubmit: formdata', form.getRawValue());
		if (!this.showSecurity && !this.hasUser) {
			this.form.removeControl('xUserInfo');
			this.form.removeControl('xRoles');
		} else {
			this.userInformation = form.value.hasOwnProperty('xUserInfo') ? form.value.xUserInfo : this.userInformation;
			this.userRoles = form.value.hasOwnProperty('xRoles') ? form.value.xRoles : this.userRoles;
			console.log('onSubmit: userinfo', this.userInformation);
			console.log('onSubmit: user roles', this.userRoles);
		}
		console.log('form validity:onSubmit---->', this.checkFormValidity(form));
		let invalidFormMessage = this.checkFormValidity(form);
		if (invalidFormMessage) {
			console.log('onSubmit: form control', this.form.controls);
			this.submitting = false;
			console.log('show security', this.showSecurity);
			this.notificationService.error(invalidFormMessage, ERROR_VALUE);
			return;
		}
		let fName = form.controls.xFormalName ? form.controls.xFormalName.value.xForename : '';
		let lName = form.controls.xFormalName ? form.controls.xFormalName.value.xSurname : '';
		console.log('fname', fName, '   lname', lName);
		form.controls.xName.setValue(fName + ' ' + lName);
		console.log('form xname', form.controls.xName);

		delete form.value.xRoles;
		delete form.value.xUserInfo;
		if (!this.edit && this.showSecurity) {
			form.value.user = {
				xTntTag: 'test',
				xWall1EntyID: '',
				xWall2EntyID: '',
				xTag: '',
				xStatus: 1,
				xName: this.userInformation.xLoginUName,
				xTypeTag: TAGSCONSTS.userTags.regularUser,
				xNotes: '',
				xRefNum: '',
				xLoginUName: this.userInformation.xLoginUName,
				xHashedPwd: this.userInformation.xHashedPwd,
				xRoles: this.userRoles,
				xEntyTypeTag: 'cust',
				xEntyID: ''
			};
		} else if (this.showSecurity) {
			console.log('onSubmit: usersData', this.usersData);
			console.log('onSubmit: form value', form.getRawValue());
			form.value.user = {
				xTntTag: this.usersData.xTntTag ? this.usersData.xTntTag : '',
				xWall1EntyID: this.usersData.xWall1EntyID ? this.usersData.xWall1EntyID : '',
				xWall2EntyID: this.usersData.xWall2EntyID ? this.usersData.xWall2EntyID : '',
				xTag: this.usersData.xTag ? this.usersData.xTag : '',
				xStatus: this.usersData.xStatus ? this.usersData.xStatus : 1,
				xName: this.usersData.xName ? this.usersData.xName : this.userInformation.xLoginUName,
				xTypeTag: this.usersData.xTypeTag ? this.usersData.xTypeTag : '',
				xNotes: this.usersData.xNotes ? this.usersData.xNotes : '',
				xRefNum: this.usersData.xRefNum ? this.usersData.xRefNum : '',
				xLoginUName: this.userInformation.xLoginUName,
				// xHashedPwd: this.userInformation.xHashedPwd,
				xRoles: this.userRoles,
				xEntyTypeTag: this.usersData.xEntyTypeTag ? this.usersData.xEntyTypeTag : 'cust',
				xEntyID: this.usersData.xEntyID ? this.usersData.xEntyID : '',
				_id: this.usersData._id ? this.usersData._id : ''
			};

			// Send password only if user has chnaged it
			if (this.userInfo.controls['xHashedPwd'].dirty) {
				console.log('onSubmit: password update');
				form.value.user.xHashedPwd = this.userInformation.xHashedPwd;
			}
		}
		console.log('onSubmit: form just value', form.value);
		console.log('onSubmit: form raw value', form.getRawValue());
		let custModel = form.getRawValue();
		// const custModel = form.value;
		custModel.user = form.value.user;
		console.log('onSubmit: custModel', custModel);
		// custModel.xTypeTag=this.tag[0];

		if (custModel && custModel.hasOwnProperty('safe')) {
			custModel.safe.forEach(element => {
				element.xTypeTag = TAGSCONSTS.safeTags.regularSafe;
			});
		}
		if (custModel.hasOwnProperty('xNotify')) {
			Object.keys(custModel['xNotify']).map(key => {
				let tempArr = [];
				custModel['xNotify'][key].map(val => {
					tempArr.push(val.value);
				});
				console.log(JSON.parse(JSON.stringify(tempArr)));
				custModel['xNotify'][key] = [];
				console.log(JSON.parse(JSON.stringify(custModel['xNotify'])));
				tempArr.map(finalEmail => {
					custModel['xNotify'][key].push(finalEmail);
				});
				custModel['xNotify'][key] = custModel['xNotify'][key].toString();
			});
		}
		this.serverErrors = {}; //Reset Errors
		console.log('Cust add-edit: this is what I get inside customer', JSON.parse(JSON.stringify(custModel)));

		if (this.edit) {
			//Update setupBillingCustomer
			this.submitting = true;
			console.log(this.setupBillingCustomerModel.xCustomerNum, 'setupBillingCustomerModel: edit is true');
			this.httpService.update(customerEndpoint, this.setupBillingCustomerModel._id, custModel).subscribe(
				(response: any) => {
					console.log(response, 'edit: setupbillingCustomer');
					// this.submitting = false;
					//TODO: this is the fix for company as customer save routing. 
					//All routings need to be centralized
					if(this.urlStoreService.previousURL === COMPANY_LIST_ROUTE){
						this.notificationService.success('Record Successfully Updated.', 'Success!');
						this.router.navigate([COMPANY_LIST_ROUTE]);
						return;
					}
					this.handleSuccessResponse(routeTo, response['data']['id']);
				},
				(error: any) => {
					// this.handleErrorResponse(error);
					this.submitting = false;
					this.serverErrors = error.errors;
					this.handleError(error);
					console.log('this.serverErrors', this.serverErrors);
				}
			);
		} else {
			//Add New setupBillingCustomer
			this.submitting = true;
			delete custModel.xUserID;
			console.log('custModel: else: onSubmit', custModel);
			this.httpService.store(customerEndpoint, custModel).subscribe(
				(response: any) => {
					console.log('submit response: add-edit customer', response);
					let customerID = response['data']['id'];
					this.handleSuccessResponse(routeTo, customerID);
				},
				(error: any) => {
					// this.handleErrorResponse(error);
					this.submitting = false;
					this.serverErrors = error.errors;
					this.handleError(error);
					console.log('this.serverErrors', this.serverErrors);
				}
			);
		}
	}

	/**
	 * Check is password has required error
	 * @returns `isPasswordRequired`: boolean: True if password is required, false if not.
	 */
	checkIfPasswordRequired(): boolean {
		let isPasswordRequired = validateFor(REQUIRED_KEY, this.form.controls[DB_XUSER_INFO_KEY].get(DB_XHASHED_PWD_KEY).errors)
			? this.form.controls[DB_XUSER_INFO_KEY].get(DB_XHASHED_PWD_KEY).errors[REQUIRED_KEY]
			: false;
		return isPasswordRequired;
	}

	/**
	 * Check is password has invalid format error
	 * @returns `isPasswordInvalid`: boolean: True if password is invalid, false if not.
	 */
	checkIfPasswordInvalid(): boolean {
		let isPasswordInvalid = validateFor(INVALID_PASSWORD_KEY, this.form.controls[DB_XUSER_INFO_KEY].get(DB_XHASHED_PWD_KEY).errors)
			? this.form.controls[DB_XUSER_INFO_KEY].get(DB_XHASHED_PWD_KEY).errors[INVALID_PASSWORD_KEY]
			: false;
		return isPasswordInvalid;
	}

	ngAfterViewInit() {
		Promise.resolve(null).then(() => {
			this.treeService.setEnable(false);
		});
	}

	setGridAttributes() {
		this.gridAttributes = [
			{ header: 'Account Number', mapTo: 'acctNum', width: '25%' },
			{
				header: 'Acct Token',
				mapTo: SHORT_MASKED_CARD_TOKEN,
				width: '20%'
			},
			{ header: 'Routing Number', mapTo: 'xRtgNum', width: '25%' },
			{
				header: 'Name',
				mapTo: 'xName',
				width: '35%'
			}
		];
	}

	//Set params to be used by grid
	//Parameters: object
	setGridParams(params: any) {
		if (this.businessService.businessID.getValue()) {
			this.gridParams['xBusinessID'] = this.businessService.businessID.getValue();
		}
		if (params.hasOwnProperty('Id')) {
			this.gridParams['xCustomerID'] = params['Id'];
			console.log('memberaccount params', this.gridParams);
		} else {
			this.gridParams['xCustomerID'] = '';
		}
	}

	//Show success message upon add or edit and route to member-accounts or customer-list
	//Parameters: Where to route(string), customerID(string)
	handleSuccessResponse(routeTo, customerID): void {
		this.submitting = false;

		if (this.edit) {
			this.notificationService.success('Record Successfully Updated.', 'Success!');
		} else {
			this.notificationService.success('Record Successfully Added.', 'Success!');
		}
		if (routeTo === ROUTE_TO_CUSTOMER_LIST) {
			this.urlStoreService.previousURL = CUSTOMER_LIST_URL;
			this.urlStoreService.routeBackToPreviousUrl();
		} else if (routeTo === ROUTE_TO_MEMBER_ACCOUNTS) {
			this.routeToMemberAccounts(customerID);
		}
	}

	//Route to Member-Accounts
	//Parameter: string
	routeToMemberAccounts(customerID: string) {
		console.log('customerID: routeToMemberAccounts: add-edit', customerID);

		this.router.navigate(['/dashboard/setup/billing/customer/member-accounts', { custID: customerID }]);
	}

	handleError(error: any): void {
		console.log('Error inside error handler', error);
		for (const field of Object.keys(error.errors)) {
			if (field === 'xFormalName.xForename' || field === 'xFormalName.xSurname') {
				console.log('This.form', this.form);
				if (this.form.value.xFormalName.xForename && this.form.value.xFormalName.xSurname) {
					console.log('Inside true case');
					delete this.serverErrors['xFormalName.xForename'];
					delete this.serverErrors['xFormalName.xSurname'];
					this.formErrors['acctxName'] = 'Field is not allowed to be empty';
				} else if (
					this.form.value.hasOwnProperty('safe') &&
					this.form.value.safe.length &&
					this.form.value.safe.length[0].hasOwnProperty('xName') &&
					this.form.value.safe.length[0].xName
				) {
					this.formErrors[field] = 'Field is not allowed to be empty';
				} else {
					this.formErrors[field] = 'Field is not allowed to be empty';
					this.formErrors['acctxName'] = 'Field is not allowed to be empty';
				}
			}
			if (field === 'xCustomerNum') {
				console.log('Inside handle error', error);
				if (error.name === 'Conflict') {
					let err = error.errors.xCustomerNum.replace(/customer/g, 'Customer');
					this.notificationService.error(err, 'Conflict');
					this.clearErrors('xCustomerNum');
				} else {
					this.formErrors[field] = 'Field is not allowed to be empty';
				}
			} else {
				if (this.formErrors.hasOwnProperty(field)) {
					this.formErrors[field] = 'Field is not allowed to be empty';
				}
			}
			if (field === 'xRefNum') {
				console.log('Inside reference number handle error', error);
				if (error.name === 'Conflict') {
					let err = error.errors.xRefNum.replace(/customer/g, 'Customer');
					this.notificationService.error(err, 'Conflict');
					this.clearErrors('xRefNum');
				}
			}
		}
	}

	handleErrorResponse(error: any): void {
		let errMsgIfBadRequest: string = '';
		errMsgIfBadRequest = this.edit ? 'Record Not Updated' : 'Record Not Added';
		try {
			this.serverErrors = JSON.parse(error._body).errors;
			this.notificationService.error(
				JSON.parse(error._body).name === 'BadRequest' ? errMsgIfBadRequest : JSON.parse(error._body).name,
				JSON.parse(error._body).message === 'Invalid data' ? 'Please fill all Required Details' : JSON.parse(error._body).message
			);
		} catch (err) {
			this.notificationService.error('Something went Wrong', 'ERROR');
		}
		this.submitting = false;
	}

	clearErrors(errorName: string): void {
		if (this.serverErrors[errorName]) {
			delete this.serverErrors[errorName];
		}
	}

	//TODO: check its use case. Even if commented, tree is getting closed.
	toggleDisable() {
		this.treeService.setEnable(false);
		this.treeService.setShow(true);
	}

	setUNameFromEmail() {
		console.log('setUNameFromEmail: email', this.form.getRawValue()['xContact']['xEmail']);
		if (this.form.getRawValue()['xContact']['xEmail'] && this.showSecurity) {
			this.form.controls.xUserInfo.get('xLoginUName').setValue(this.form.getRawValue()['xContact']['xEmail']);
		}
	}

	onKeypress(event: any, source?: string) {
		console.log('event', event, source);
		if (source === 'phoneNum') {
			GlobalValidator.phoneNumberFormat(event);
		} else if (source === 'postCode') {
			GlobalValidator.numberOnlyFormat(event);
		}
	}

	onPaste(event: any, source?: string) {
		console.log('event in paste', event, source);
		if (source === 'phoneNum') {
			GlobalValidator.phoneNumberFormat(event);
		} else if (source === 'postCode') {
			GlobalValidator.numberOnlyFormat(event);
		}
	}


	/**
	 * for customer add/edit component, router guard is called on cancel action
     * If form is dirty, ask for confirmation
     * Else proceed to routing
     * Its implemented this way to prevent opening of tree on click of cancel button
	 * @param {Event} event
	 * @memberof AddEditComponent
	 */
	onCancel(event: Event) {
		event.preventDefault();
		if(this.urlStoreService.previousUrl.includes('company')){
			this.urlStoreService.routeBackToPreviousUrl();
			return;
		}
		if(!this.form.dirty){
			this.urlStoreService.previousURL = CUSTOMER_LIST_URL;
			this.urlStoreService.routeBackToPreviousUrl();
		}else{
			this.routeGuard.waitForUser().then(didUserConfirm => {
				if(didUserConfirm){
					this.urlStoreService.previousURL = CUSTOMER_LIST_URL;
					this.urlStoreService.routeBackToPreviousUrl();
				} 
			}).catch(err => {
				console.log('route guard error', err);
			})
		}
	}

	//If from value is altered in add or edit mode, save and redirect to member-accounts if 'Manage Payment Accounts' is clicked
	//If form is not altered in add mode, show 'Can't Proceed' message
	//If form is not altered in edit mode, directly redirect to member-accounts
	onAddNewPaymentmethodClick() {
		// console.log(this.form.dirty, 'is form dirty: onAddNewPaymentmethodClick');
		if (this.form.dirty) {
			customSwal.fire({
				title:'This Customer will be saved!',
				text: 'Click Yes to continue, No to decline',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				reverseButtons: true
			}).then((result) => {
				this.disableRouteGuard = true;
				if (result.value) {
					let routeTo = ROUTE_TO_MEMBER_ACCOUNTS;
					console.log('form value: swal: add-edit', this.form.getRawValue());
					this.onSubmit(this.form, routeTo);
				}
			});
		} else if (this.edit) {
			this.disableRouteGuard = true;
			let customerID = this.gridParams.hasOwnProperty('xCustomerID') ? this.gridParams['xCustomerID'] : null;
			console.log('customerID: swal: form not dirty', customerID);
			this.routeToMemberAccounts(customerID);
		} else {
			customSwal.fire({
				title: "Can't Proceed",
				text: 'Please complete Customer-Setup to proceed for Payment Accounts.',
				icon: 'error',
				confirmButtonText: 'Okay'
			});
		}
	}

	//Set fields touched if value is not filled before submit
	//Return type: boolean
	//Parameter: FormGroup
	checkFormValidity(form: FormGroup) {
		let userFormInvalidMessage = '';
		let customerFormInvalidityMessage = '';
		let formValue = form.getRawValue();

		console.log('checkFormValidity: xroles length', formValue.xRoles);

		//hasUser is true either addUser button is clicked or added before and available from get api
		let hasUser = this.showSecurity || this.hasUser ? true : false;
		console.log('has user:checkFormValidity---> ', hasUser);
		customerFormInvalidityMessage = this.checkCustomerFormValidity(form);
		if (hasUser) {
			userFormInvalidMessage = this.checkUserFormValidity(form);
		}
		
		if (userFormInvalidMessage) {
			return userFormInvalidMessage;
		}
		if (customerFormInvalidityMessage) {
			return customerFormInvalidityMessage;
		}
		return '';
	}

	//Set user fields in customer form touched
	checkUserFormValidity(form: FormGroup) {
		console.log('inside checkUserFormValidity');
		let formValue = form.getRawValue();
		setControlsTouched(form.controls['xUserInfo']);
		console.log('form value--->', formValue);
		if (formValue.xRoles.length === 0 || this.checkIfPasswordRequired()) {
			if(formValue.xRoles.length === 0){
				this.serverErrors['xRoles'] = true;
			}
			return MESSAGES.FillAllRequiredFields.message;
		}
		if(this.checkIfPasswordInvalid()){
			return MESSAGES.InvalidPasswordFormat.message
		}
		return '';
	}

	//Set errors to fields except security
	checkCustomerFormValidity(form: FormGroup) {
		console.log('form controls', form);
		if (!form.valid) {
			setControlsTouched(form.controls['xAddr']);
			setControlsTouched(form.controls['xContact']);
			setControlsTouched(form.controls['xFormalName']);
			console.log('checkFormValidity: serverError', this.serverErrors);
			return MESSAGES.FillAllRequiredFields.message;
		}
		return '';
	}

	//Show user fileds when check box is selected and set user to customer, else hide user fields
	//parameter: form event
	//return type: boolean
	onAddUserClick(event: any) {
		if (this.showSecurity === event.target.checked) {
			this.showSecurity = !event.target.checked;
		} else {
			this.showSecurity = event.target.checked;
		}
		if (!this.showSecurity) {
			this.clearErrors('xRoles');
			this.form.removeControl('xUserInfo');
			this.form.removeControl('xRoles');
		}
		console.log('show security', this.showSecurity);
	}

	setupPerms(){
        this.canEdit = this.userHandlerService.userPerms.canEditCust;
        this.canAdd = this.userHandlerService.userPerms.canAddCust;
    }

	/**
	 * For vendor login, if on click of 'as customer' action,
	 * add business ID in params to since its needed to fetch safes
	 */
	checkCompanyAsCustomer(){
		if(this.urlStoreService.previousUrl.includes('company')){
			let companyData = this.companyService.companyData;
			let bizID = companyData.xAsBusinessID;
			this.gridParams = Object.assign({}, this.gridParams, {xBusinessID: bizID});
		}
	}

	//Enable RouteGuard
	ngOnDestroy() {
		this.disableRouteGuard = false;
		this.alive = false;
	}
}
