import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import { BaseComponent } from '../base.component';
// import { MainContact, FormalName } from '../../../../../models';
import { GlobalValidator, GmNumberService, NotificationService } from '../../../../services';
// import { phoneMask } from '../../../utils';

const endPoint = 'pgComm/numberAPI_modelNums';

@Component({
    selector: 'app-model-number',
    templateUrl: './model-number.component.html',
    styleUrls: ['./model-number.component.scss']
})
export class ModelNumberComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() modelNumber: string;
    // @Input() formalName:FormalName;
    @Input() form: FormGroup;
    // @Input() form: FormGroup;
    @Input() serverErrors: any = {};
    @Input() numberKey:string;

    isFetchNewNumber: boolean = false;

    constructor(
        public fb: FormBuilder,
        private gmNumberService: GmNumberService,
        private notificationService: NotificationService) {
        super();
        // this.formErrors[this.numberKey] = '';
    }

    ngOnChanges(change: SimpleChanges) {
        console.log('here 2');
        console.log('ngOnChanges, modelNumber, eeeeeeeee-->', this.modelNumber);
        console.log('ngOnChanges, numberKey,  eeeeeeeee-->', this.numberKey);
        console.log('ngOnChanges, change,  eeeeeeeee-->', change);
        if (change.hasOwnProperty('modelNumber') && !change.modelNumber.firstChange) {
            if (!this.form.controls[this.numberKey]) {
                let controlObj = {};
                controlObj[this.numberKey] = [this.modelNumber || '',[]];
                this.form.addControl(this.numberKey, this.fb.control(controlObj));
            }
            this.form.controls[this.numberKey].patchValue(this.modelNumber);
        }

        if (change.hasOwnProperty('serverErrors') && !change.serverErrors.firstChange) {
            this.validateServerErrors();
        }
    }


    ngOnInit() {
        console.log('here 1');
        // console.log('ngOnInit, modelNumber, eeeeeeeee-->', this.modelNumber);
        // console.log('ngOnInit, numberKey,  eeeeeeeee-->', this.numberKey);
        this.formErrors[this.numberKey] = '';
        let controlObj = {};
        controlObj[this.numberKey] = [this.modelNumber || '',[]];
        this.form.addControl(this.numberKey, this.fb.control(controlObj));
        if(!this.modelNumber){
            this.getNewNumber();
        }
        console.log('ngOnInit, form.numberKey,  eeeeeeeee-->', this.form.get(this.numberKey));
        this.onValueChanged(this.form); // (re)set validation messages now
    }


    /**
     * generate 10 digit random number
     */
    getRandomNumber(){
        return parseInt((Math.random()*10000000000).toString()).toString();
    }

    getNewNumber(){
        console.log('get new number clicked...');
        // this.clearErrors(this.numberKey);
        this.isFetchNewNumber = true;
        this.form.controls[this.numberKey].disable();
        this.gmNumberService
            .fetchGMData(endPoint, { count: "1" })
            .subscribe(
                (res: any) => {
                    this.form.controls[this.numberKey].enable();
                    if(res && res.hasOwnProperty('data') && res.data){
                        if(Object.keys(res).length && res.constructor===Object && res['data'].hasOwnProperty('NumList') && res.data.NumList){
                            this.form.controls[this.numberKey].setValue(
                                res.data.NumList
                            );
                        }else
                            this.handleNumberFetch_FailCase();
                    }else{
                        this.handleNumberFetch_FailCase();
                    }
                    this.isFetchNewNumber = false;
                },
                (err: any) => {
                    this.form.controls[this.numberKey].enable();
                    this.handleNumberFetch_FailCase();
                    this.isFetchNewNumber = false;
                }
            );
    }

    clearErrors(numberKey){
        if (this.serverErrors[numberKey]) {
            console.log("here", this.serverErrors);
            delete this.serverErrors[numberKey];
        }
    }

    handleNumberFetch_FailCase(){
        let randomNumber = this.getRandomNumber();
        console.log("Error in fetching model number inside modelNumber component");
        this.form.controls[this.numberKey].setValue(
            randomNumber
        );
        this.notificationService.warning(
            "Unable to fetch model number. Proceeding with random number.",
            "Warning"
        );
    }
}
