import { Injectable } from '@angular/core';
import { validateFor } from '../../common-functions';
import { BehaviorSubject } from 'rxjs';
import { BusinessService } from './business-service';
import { HttpService } from './http-service';
import { API_END_POINT } from 'app/constants';

@Injectable()
export class CompanyService {
	companyID: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    refreshCompanyList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _companyData: any;

    watchCompanyID$ = this.companyID.asObservable();
    watchRefreshCompanyList$ = this.refreshCompanyList.asObservable();

    constructor(
        private httpService: HttpService, 
        private businessService: BusinessService,
        ) {
    }

    setCompanyID(companyID: string): void {
        this.companyID.next(companyID);
        this.fetchCompanyInformation();
    }

    getcompanyID(): string {
        return this.companyID.getValue();
    }

    set companyData(company: any) {
        this._companyData = company;
    }

    get companyData(): any {
        return this._companyData;
    }

    toggleRefreshCompany(): void {
        console.log('HERE ALSO!!');
        this.refreshCompanyList.next(false);
        this.refreshCompanyList.next(true);
    }

    resetCompanyID(): void {
        this.companyID.next(null);
        this.companyData=null;
    }

    //clear service data
    intialiseServiceData(){
        this.companyID.next(null);
        this.refreshCompanyList.next(false);
    }

    fetchCompanyInformation() {
        console.log("this is called inside copany service")
        if (!this.companyID.getValue()) {
            this.companyData = null;
            return;
        }
        this.httpService.get('xbcCompanies', this.companyID.getValue())
        .subscribe(
            (response: Response | any) => {
                this._companyData = response.data;
                console.log("This is company data",this._companyData);
            },
            (error: any) => {
                console.log(error, 'error while fetching business');
            });
    }

    setCompanyIDForEmpLogin(){
		return new Promise((resolve, reject) => {
			let bizQuery = {
				_id: this.businessService.businessID.getValue(),
				selectKey: 'xSvcCompanyID',
                noGrid: true
			}
			this.httpService.getAll(API_END_POINT.xbcBusiness,bizQuery)
				.subscribe(bizResp => {
					let data = validateFor('data', bizResp) 
						? bizResp.data
						: [];
					let companyID = data.length && data[0].xSvcCompanyID 
						? data[0].xSvcCompanyID 
						: '';
					this.setCompanyID(companyID);
					return resolve(true);
				}, err => {
					return reject(err);
				})
		})
	}
}
