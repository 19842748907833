export class ConnCaps {
    xHasSerConn: boolean;
    xHasUSBConn: boolean;
    xHasEthConn: boolean;
    xHasWifiConn: boolean;
    xHasBTConn: boolean;
    xHasCellConn: boolean;

    constructor(obj?: any) {
        this.xHasSerConn = (obj && obj.xHasSerConn) || null;
        this.xHasUSBConn = (obj && obj.xHasUSBConn) || null;
        this.xHasEthConn = (obj && obj.xHasEthConn) || null;
        this.xHasWifiConn = (obj && obj.xHasWifiConn) || null;
        this.xHasBTConn = (obj && obj.xHasBTConn || null);
        this.xHasCellConn = (obj && obj.xHasCellConn) || null;
    }
}
