import { Injectable } from "@angular/core";
import {
    FormControl,
    AbstractControl,
    AsyncValidatorFn
} from "@angular/forms";

import { HttpService } from './http-service';

import { Observable } from "rxjs";

const validateGMIDPoint = "sync/v1/validateGMIDs";
const validateGTIDPoint = "sync/v1/validateGTIDs";

const MERCHANT_NAME_VALIDATION_REGEX: RegExp = new RegExp(/^[\[a-zA-Z0-9]{1}[a-zA-Z0-9!@#$%^&*\] ]{0,19}$/) ;

@Injectable()
export class GlobalValidator {
    static debouncer: any;
    static httpService1;
    static isValidatingGMID = false;
    static isValidatingGTID = false;
    static previousGMIDObj: any = { gmid: "", status: "" };
    static previousGTIDObj: any = { gtid: "", status: "" };

    constructor(protected httpService: HttpService) {
        console.log(
            "GlobalValidator: httpService object -->",
            this.httpService
        );
        GlobalValidator.httpService1 = this.httpService;
        console.log(
            "GlobalValidator: httpService1 object -->",
            GlobalValidator.httpService1
        );
    }

    // protected static httpService = new HttpService();

    static validateGMID(username): Observable<any> {
        // console.log("validateGMID: username -->", username);
        // console.log("validateGMID: GlobalValidator -->", GlobalValidator);
        // console.log(
        //     "validateGMID: httpService1 -->",
        //     GlobalValidator.httpService1
        // );
        return GlobalValidator.httpService1
            .getAll(validateGMIDPoint, { xGMID: username }, true)
    }

    static validateGTID(username): Observable<any> {
        // console.log("validateGTID: username -->", username);
        // console.log("validateGTID: GlobalValidator -->", GlobalValidator);
        // console.log(
        //     "validateGTID: httpService1 -->",
        //     GlobalValidator.httpService1
        // );
        return GlobalValidator.httpService1
            .getAll(validateGTIDPoint, { xGTID: username }, true)
    }

    static mailFormat(control: FormControl): ValidationResult {
        const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        console.log("control=========>", control);
        console.log(
            "control condition=========>",
            control.value !== "" &&
                control.value.length <= 5 &&
                !EMAIL_REGEXP.test(control.value)
        );
        if (
            control.value !== "" &&
            (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))
        ) {
            return { incorrectMailFormat: true };
        }

        return null;
    }

    static websiteFormat(control: FormControl): ValidationResult {
        const WEBSITE_REGEXP = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

        if (
            control.value !== "" &&
            (control.value.length <= 3 || !WEBSITE_REGEXP.test(control.value))
        ) {
            return { incorrectWebsiteFormat: true };
        }

        return null;
    }

    static phoneNumberFormat(event: any) {
        if (event.type === "keypress") {
            console.log(event.type);
            const pattern = /[0-9\+\-\(\) ]/;
            const inputChar = String.fromCharCode(event.charCode);
            if (event.charCode !== 0 && !pattern.test(inputChar)) {
                // invalid character, prevent input
                event.preventDefault();
            }
        } else if (event.type === "paste") {
            const regExp = new RegExp("^[0-9()+-]+$");
            let input = event.clipboardData.getData("text/plain");
            if (!regExp.test(input)) {
                //invalid character, prevent paste
                event.preventDefault();
            }
        }
    }

    static numberCharacterFormat(event: any) {
        if (event.type === "keypress") {
            const pattern = /[A-Za-z0-9]/;
            const inputChar = String.fromCharCode(event.charCode);
            if (event.charCode !== 0 && !pattern.test(inputChar)) {
                //invalid character, prevent input
                event.preventDefault();
            }
        } else if (event.type === "paste") {
            const regExp = new RegExp("^[a-zA-Z0-9]+$");
            let input = event.clipboardData.getData("text/plain");
            if (!regExp.test(input)) {
                //invalid character, prevent paste
                event.preventDefault();
            }
        }
    }

    static numberOnlyFormat(event: any) {
        if (event.type === "keypress") {
            const pattern = /[0-9]/;
            const inputChar = String.fromCharCode(event.charCode);
            if (event.charCode !== 0 && !pattern.test(inputChar)) {
                //invalid character, prevent input
                event.preventDefault();
            }
        } else if (event.type === "paste") {
            const regExp = new RegExp("^[0-9]+$");
            let input = event.clipboardData.getData("text/plain");
            if (!regExp.test(input)) {
                //invalid character, prevent paste
                event.preventDefault();
            }
        }
    }

    static amountFormat(event: any) {
        console.log("event.charCode", event.charCode);
        if (event.type === "keypress") {
            const pattern = /[0-9\.]/;
            const inputChar = String.fromCharCode(event.charCode);
            console.log("inputChar", inputChar);
            if (event.charCode !== 0 && !pattern.test(inputChar)) {
                //invalid character, prevent input
                event.preventDefault();
            }
        } else if (event.type === "paste") {
            const regExp = new RegExp("^[0-9.]+$");
            let input = event.clipboardData.getData("text/plain");
            if (!regExp.test(input)) {
                //invalid character, prevent paste
                event.preventDefault();
            }
        }
    }

    static validatePassword(control: FormControl): any {
        console.log("control.value", control.value);
        const pass = control.value;
        // let regex = new RegExp("^[a-zA-Z0-9]{1}[a-zA-Z0-9!@#$%^&* ]{0,19}$");
        // if (name && name.length) {
        //     if (!regex.test(name)) {
        //         return { incorrectNameFormat: true };
        //     }
        // }
        let cap: boolean = false;
        let small: boolean = false;
        let num: boolean = false;
        let special: boolean = false;
        for (let i = 0; i < pass.length; i++) {
            if (pass.charCodeAt(i) >= 65 && pass.charCodeAt(i) <= 90) {
                // console.log("cap");
                cap = true;
            } else if (
                pass.charCodeAt(i) >= 97 &&
                pass.charCodeAt(i) <= 122
            ) {
                // console.log("smal");
                small = true;
            } else if (
                pass.charCodeAt(i) >= 48 &&
                pass.charCodeAt(i) <= 57
            ) {
                // console.log("num");
                num = true;
            } else if (
                (pass.charCodeAt(i) >= 32 &&
                    pass.charCodeAt(i) <= 47) ||
                (pass.charCodeAt(i) >= 58 &&
                    pass.charCodeAt(i) <= 64) ||
                (pass.charCodeAt(i) >= 91 &&
                    pass.charCodeAt(i) <= 96) ||
                (pass.charCodeAt(i) >= 123 && pass.charCodeAt(i) <= 126)
            ) {
                // console.log("special");
                special = true;
            }
        }
        if (cap && small && num && special) {
            console.log('validatePassword: pwd valid');
            return null;
        } else {
            console.log('validatePassword: pwd invalid');
            return { INVALID_PASSWORD: true }
        }
    }

    static passwordFormat(event: any): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (event.target.value.length) {
                const pass = event.target.value;
                let cap: boolean = false;
                let small: boolean = false;
                let num: boolean = false;
                let special: boolean = false;
                for (let i = 0; i < pass.length; i++) {
                    if (pass.charCodeAt(i) >= 65 && pass.charCodeAt(i) <= 90) {
                        // console.log("cap");
                        cap = true;
                    } else if (
                        pass.charCodeAt(i) >= 97 &&
                        pass.charCodeAt(i) <= 122
                    ) {
                        // console.log("smal");
                        small = true;
                    } else if (
                        pass.charCodeAt(i) >= 48 &&
                        pass.charCodeAt(i) <= 57
                    ) {
                        // console.log("num");
                        num = true;
                    } else if (
                        (pass.charCodeAt(i) >= 32 &&
                            pass.charCodeAt(i) <= 47) ||
                        (pass.charCodeAt(i) >= 58 &&
                            pass.charCodeAt(i) <= 64) ||
                        (pass.charCodeAt(i) >= 91 &&
                            pass.charCodeAt(i) <= 96) ||
                        (pass.charCodeAt(i) >= 123 && pass.charCodeAt(i) <= 126)
                    ) {
                        // console.log("special");
                        special = true;
                    }
                }
                if (cap && small && num && special) {
                    return resolve(true);
                } else {
                    return reject(false);
                }
            }
        });
    }

    static validateName(control: FormControl): any {
        console.log("control.value", control.value);
        let name = control.value;
        let regex = new RegExp("^[a-zA-Z0-9]{1}[a-zA-Z0-9!@#$%^&* ]{0,19}$");
        if (name && name.length) {
            if (!regex.test(name)) {
                return { incorrectNameFormat: true };
            }
        }
        return null;
    }

    static validateMerchantName(control: FormControl): any{
        console.log('merhant name: validateMerchantName--->', control.value);
        let name = control.value;
        let regex = MERCHANT_NAME_VALIDATION_REGEX;
        if (name && name.length) {
            if (!regex.test(name)) {
                return { incorrectNameFormat: true };
            }
        }
        return null;
    }

    static validateCity(control: FormControl): any {
        let cityName = control.value;
        let regex = new RegExp("^[a-zA-Z0-9]{1}[a-zA-Z0-9!@#$%^&* ]{0,19}$");
        if (cityName && cityName.length) {
            if (!regex.test(cityName)) {
                return { incorrectCityNameFormat: true };
            }
        }
        return null;
    }
    static validateState(control: FormControl): any {
        let stateName = control.value;
        let regex = new RegExp("^[a-zA-Z0-9]{1}[a-zA-Z0-9!@#$%^&* ]{0,19}$");
        if (stateName && stateName.length) {
            if (!regex.test(stateName)) {
                return { incorrectStateNameFormat: true };
            }
        }
        return null;
    }
    static validatePostCode(control: FormControl): any {
        let postCodeName = control.value;
        let regex = new RegExp("^[a-zA-Z0-9]{5,}$");
        if (postCodeName && postCodeName.length) {
            if (!regex.test(postCodeName)) {
                return { incorrectPostCodeNameFormat: true };
            }
        }
        return null;
    }
    static validateCountry(control: FormControl): any {
        let countryName = control.value;
        let regex = new RegExp("^[a-zA-Z0-9]{1}[a-zA-Z0-9!@#$%^&* ]{0,19}$");
        if (countryName && countryName.length) {
            if (!regex.test(countryName)) {
                return { incorrectCountryNameFormat: true };
            }
        }
        return null;
    }

    static checkGTID(previousGTID, status): AsyncValidatorFn {
        return (control: AbstractControl) => {
            console.log("checkGTID: control -->", control.value);
            console.log(
                "checkGTID: previousgtid in service",
                GlobalValidator.previousGTIDObj
            );
            GlobalValidator.isValidatingGTID = true;
            if (
                control.value &&
                control.value !== GlobalValidator.previousGTIDObj.gtid
            ) {
                clearTimeout(GlobalValidator.debouncer);

                return new Promise(resolve => {
                    GlobalValidator.debouncer = setTimeout(() => {
                        // console.log(
                        //     "checkGTID: Call validateGTID -->",
                        //     GlobalValidator.validateGTID
                        // );
                        GlobalValidator.isValidatingGTID = true;

                        GlobalValidator.validateGTID(control.value).subscribe(
                            res => {
                                console.log(
                                    "checkGTID: GTID validator, response ->",
                                    res
                                );
                                if (res.isValid) {
                                    console.log(
                                        "checkGTID: isValid -->",
                                        res.isValid
                                    );
                                    // GlobalValidator.isValidatingGTID = false;
                                    resolve(null);
                                } else {
                                    // GlobalValidator.isValidatingGTID = false;
                                    resolve({ GTID_InUse: true });
                                }
                                GlobalValidator.previousGTIDObj.status =
                                    res.isValid;
                            },
                            err => {
                                console.log("checkGTID: error -->", err);
                                GlobalValidator.isValidatingGTID = false;
                                GlobalValidator.previousGTIDObj.status = false;
                                resolve({ GTID_InValid: true });
                            },
                            () => {
                                GlobalValidator.isValidatingGTID = false;
                                GlobalValidator.previousGTIDObj.gtid =
                                    control.value;

                                console.log(
                                    "checkGTID: seting previous GTID as -->",
                                    GlobalValidator.previousGTIDObj
                                );
                            }
                        );
                    }, 1000);
                });
            } else if (GlobalValidator.previousGTIDObj.status) {
                return new Promise(resolve => {
                    GlobalValidator.isValidatingGTID = false;
                    console.log("checkGTID: here -1");
                    resolve(null);
                });
            } else if (!GlobalValidator.previousGTIDObj.status) {
                return new Promise(resolve => {
                    GlobalValidator.isValidatingGTID = false;
                    console.log("checkGTID: here -2");
                    resolve({ GTID_InUse: true });
                });
            } else if (!control.value) {
                return new Promise(resolve => {
                    GlobalValidator.isValidatingGTID = false;
                    console.log("checkGTID: empty");
                    resolve({ GTID_Empty: true });
                });
            } else {
                return new Promise(resolve => {
                    console.log("checkGTID: here -3");
                    GlobalValidator.isValidatingGTID = false;
                    resolve(null);
                });
            }
        };
    }
    static checkGMID(previousGMID, status): AsyncValidatorFn {
        return (control: AbstractControl) => {
            console.log("checkGMID: control -->", control.value);
            console.log(
                "checkGMID: previousgmid in service",
                GlobalValidator.previousGMIDObj
            );
            GlobalValidator.isValidatingGMID = true;
            if (
                control.value &&
                control.value !== GlobalValidator.previousGMIDObj.gmid
            ) {
                clearTimeout(GlobalValidator.debouncer);

                return new Promise(resolve => {
                    GlobalValidator.debouncer = setTimeout(() => {
                        // console.log(
                        //     "checkGMID: Call validateGMID -->",
                        //     GlobalValidator.validateGMID
                        // );
                        GlobalValidator.isValidatingGMID = true;

                        GlobalValidator.validateGMID(control.value).subscribe(
                            res => {
                                // console.log(
                                //     "checkGMID: GMID validator, response ->",
                                //     res
                                // );
                                if (res.isValid) {
                                    console.log(
                                        "checkGMID: isValid -->",
                                        res.isValid
                                    );
                                    // GlobalValidator.isValidatingGMID = false;
                                    resolve(null);
                                } else {
                                    // GlobalValidator.isValidatingGMID = false;
                                    resolve({ GMID_InUse: true });
                                }
                                GlobalValidator.previousGMIDObj.status =
                                    res.isValid;
                            },
                            err => {
                                console.log("checkGMID: error -->", err);
                                GlobalValidator.isValidatingGMID = false;
                                GlobalValidator.previousGMIDObj.status = false;
                                resolve({ GMID_InValid: true });
                            },
                            () => {
                                GlobalValidator.isValidatingGMID = false;
                                GlobalValidator.previousGMIDObj.gmid =
                                    control.value;

                                console.log(
                                    "checkGMID: seting previous GMID as -->",
                                    GlobalValidator.previousGMIDObj
                                );
                            }
                        );
                    }, 1000);
                });
            } else if (GlobalValidator.previousGMIDObj.status) {
                return new Promise(resolve => {
                    GlobalValidator.isValidatingGMID = false;
                    console.log("checkGMID: here -1");
                    resolve(null);
                });
            } else if (!GlobalValidator.previousGMIDObj.status) {
                return new Promise(resolve => {
                    GlobalValidator.isValidatingGMID = false;
                    console.log("checkGMID: here -2");
                    resolve({ GMID_InUse: true });
                });
            } else if (!control.value) {
                return new Promise(resolve => {
                    GlobalValidator.isValidatingGMID = false;
                    console.log("checkGMID: empty");
                    resolve({ GMID_Empty: true });
                });
            } else {
                return new Promise(resolve => {
                    console.log("checkGMID: here -3");
                    GlobalValidator.isValidatingGMID = false;
                    resolve(null);
                });
            }
        };
    }
}

interface ValidationResult {
    [key: string]: boolean;
}
