export class Structs {

    xHasChains: boolean;
    xHasRegions: boolean;
    xHasProps: boolean;
    xHasMulStores: boolean;
    xHasMulPCtrs: boolean;

    constructor(obj?: any) {
        this.xHasChains = (obj && obj.xHasChains) || false;
        this.xHasRegions = (obj && obj.xHasRegions) || false;
        this.xHasProps = (obj && obj.xHasProps) || false;
        this.xHasMulStores = (obj && obj.xHasMulStores) || false;
        this.xHasMulPCtrs = (obj && obj.xHasMulPCtrs) || false;
    }
}
