<div class="card" [class.hasTreeData]="hasData" [class.struct-tree]='addAction'>
    <div class="" *ngIf="visible">
        <div *ngIf="hasData">
            <!-- <form> -->
            <input
                id="example"
                class="form-control form-control-sm"
                #filter
                (keyup)="filterNodes(filter.value, tree)"
                placeholder="Filter Nodes"
            />
            <!-- </form> -->
            <!-- <div class="card-block"> -->
            <div id='tree-inner' class="tree-inner-view">
                <tree-root
                    [nodes]="nodes"
                    [options]="customTemplateStringOptions"
                    [focused]="true"
                    #tree
                    (toggleExpanded)="onToggleEvent($event)"
                    (onUpdateData)="onUpdateEvent($event)"
                    (initialized)="onTreeInitialize($event)"
                >
                    <ng-template #treeNodeTemplate let-node>
                        <span
                            *ngIf="!node.data.btn"
                            [ngClass]="{
                                'active-node':
                                    node?.data?.id === treeService.businessID.getValue() ||
                                    node?.data?.id === treeService.chainID.getValue() ||
                                    node?.data?.id === treeService.regionID.getValue() ||
                                    node?.data?.id === treeService.propertyID.getValue() ||
                                    node?.data?.id === treeService.storeID.getValue() ||
                                    node?.data?.id === treeService.profitCenterID.getValue() ||
                                    node?.data?.id === treeService.merchantAccID.getValue() ||
                                    node?.data?.id === treeService.terminalID.getValue() ||
                                    node?.data?.id === treeService.periphID.getValue()
                            }"
                            ><a
                                *ngIf="!node.data.btn"
                                name="{{ node?.data?.name }}{{ node?.data?.type }}"
                                (click)="onNodeClick(node)"
                                tooltip="{{ node?.data?.name }}"
                                [adaptivePosition]="false"
                                placement="bottom"
                                containerClass="tooltip-global"
                                class="node-name"
                                [ngClass]="{
                                    'tree-bold': node?.data?.accessStore
                                }"
                            >
                                <!-- {{ node?.data?.name }}<br /> -->
                                {{ truncateString(node?.data?.name) }} <br />
                                <span
                                    class="type"
                                    *ngIf="
                                        node?.data?.type !== 'profit_center' &&
                                        node?.data?.type !== 'merchant_account' &&
                                        !node?.data?.accessStore
                                    "
                                    >{{ node?.data?.type }}</span
                                >
                                <span class="type" *ngIf="node?.data?.type === 'store' && node?.data?.accessStore">Access Store</span>
                                <span class="type" *ngIf="node?.data?.type == 'profit_center'"> Profit Center</span>
                                <span class="type" *ngIf="node?.data?.type == 'merchant_account'"> Merchant Account</span>
                            </a>
                        </span>

                        <!-- {{node?.data | json}} -->
                        <span
                            *ngIf="!node.data.btn && addAction"
                            dropdown
                            class="dropdown"
                            data-animation="am-flip-x"
                            bs-dropdown
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <!--
                                <a *ngIf="node?.data?.type!=='property' && node?.data?.type!=='store' && node?.data?.type!=='profit_center' && node?.data?.type!=='merchant_account' && node?.data?.type!=='terminal' && node?.data?.type!=='peripheral'" dropdownToggle (click)="onButtonClick(node)" tooltip="Click to get more options" placement="top" containerClass="tooltip-global" container="body">
                            -->
                            <!-- <i class="fa fa-caret-down"></i> -->
                            <!--
                                <img src="assets/images/drop-plus.png" />
                                </a>
                                <a *ngIf="node?.data?.type==='property' || node?.data?.type==='store' || node?.data?.type==='profit_center' || node?.data?.type==='merchant_account' || node?.data?.type==='terminal' && node?.data?.type!=='peripheral'" (click)="onButtonClick(node)"  tooltip={{tooltipArray[node?.data?.type]}} placement="top" containerClass="tooltip-global" container="body" >
                            -->
                            <!-- <img src="assets/images/plus.png" /> -->
                            <!--
                                <i class="fa fa-plus"></i>
                                </a>
                            -->
                            <a
                                *ngIf="node?.data?.type !== 'peripheral' && !userHandlerService?.userRoles?.isBusinessEmployee"
                                dropdownToggle
                                (click)="onButtonClick(node)"
                                tooltip="Click to get more options"
                                placement="top"
                                containerClass="tooltip-global"
                                container="body"
                            >
                                <!-- <i class="fa fa-caret-down"></i> -->
                                <!--
                                    <img src="assets/images/drop-plus.png" />
                                -->
                                <i class="fa fa-plus"></i>
                            </a>
                            <ng-container *ngIf="node?.data?.canHaveChild?.length">
                                <ul *dropdownMenu class="dropdown-menu animated fadeIn billing-dropdown tree-dropdown">
                                    <ng-container *ngFor="let b of addBtns">
                                        <li *ngIf="b?.btn && addAction">
                                            <a (click)="onAddButtonClick(b, node)"> Add {{ b?.name }}</a>
                                        </li>
                                        <li *ngIf="b?.name == 'Terminal' ">
                                            <a (click)="openModal(registerTerminaltemplate, node.parent.data.xGMID, node.data.children[0].id)"> Register {{b?.name}}</a>
                                        </li>

                                    </ng-container>
                                </ul>
                            </ng-container>
                        </span>
                        <!-- {{node.children|json}} -->
                        <!--
                            <a *ngIf="node.data.editLink && addAction" [routerLink]="[node.data.editLink]" [routerLinkActive]="['router-link-active']">
                                <i class="fa fa-pencil-square-o tree-add-new-icon" aria-hidden="true" placement="right" tooltip="Edit"></i>
                            </a>
                        -->
                        <!--
                            <span *ngIf="!node.data.btn && node.data.type">
                                ({{ node.data.type }})
                            </span>
                        -->
                        <!--
                            <a *ngIf="node.data.btn && addAction" class="btn btn-primary btn-xs" (click)="onButtonClick(node)">
                                <i class="fa fa-plus p-r-3"></i> {{node.data.name}}
                            </a>
                        -->

                        <a
                            *ngIf="node.data.btn && addAction && node.data.isRemovable"
                            class="btn-default btn-xs"
                            (click)="removeNode(node)"
                        >
                            <i class="fa fa-times"></i>
                        </a>
                    </ng-template>
                </tree-root>
            </div>
        </div>
        <div *ngIf="!hasData">
            <h3 class="tree-msg text-muted">Please select a business</h3>
        </div>
    </div>
    <div class="card-block" style="text-align: center; vertical-align: middle;" *ngIf="!visible">
        <!--
            <i class="fa fa-sitemap fa-spin fa-3x fa-fw" aria-hidden="true"></i>
        -->
        <!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i> -->
        <!-- <span class="sr-only">Loading...</span> -->
        <div class="super-outer-div">
            <div class="outer-div">
                <div class="left-branch"></div>
                <div class="main-branch"></div>
                <div class="right-branch"></div>
            </div>

            <div class="outer-div-parent">
                <div class="outer-div">
                    <div style="height:10px; width:10px; margin:2px; display: table-cell;"></div>
                    <div class="inner-div1"></div>
                </div>
                <div class="outer-div">
                    <div class="inner-div2"></div>
                    <div class="inner-div3"></div>
                    <div class="inner-div4"></div>
                </div>
            </div>
        </div>
        <div style="color:#3b5877">Loading Tree</div>
    </div>
    <a class="collapse-tree" (click)="toggleShowTree()"></a>
</div>

<ng-template #registerTerminaltemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Register Terminal</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="mb-3">
                <label for="xGMID">GMID</label>
                <div class="input-group">
                    <input id="xGMID" class="form-control" value="{{xGMID}}" readonly >
                </div>
            </div>
            <div class="mb-3">
                <label for="xName">Name</label>
                <div class="input-group">
                    <input id="xName" class="form-control" placeholder="Insert Name" #xName>
                </div>
            </div>
            <div class="mb-3">
                <label for="xTerminalType">Terminal Type: </label>
                <div class="merch_type">
                    <select class="form-control form-control-sm" id="xTerminalType" #xTerminalType (change)="setSNLength($event)" style="height: calc(2.25rem + 2px);">
                        <option [value]="'select'"  > Select terminal type </option>
                        <option *ngFor="let terminalType of terminalTypeList" [value]="terminalType.value">{{terminalType.value}}</option>
                    </select>
                </div>
            </div>

            <div class="mb-3">
                <label for="xSerialNumber">Serial Number</label>
                <div class="input-group">
                    <input id="xSerialNumber" class="form-control" placeholder="Insert Serial Number"
                           [maxLength]="this.serialNumLength"
                           [disabled]="!this.serialNumLength"
                           (change)="serialNumberInput($event) "
                           [required]="!this.serialNumLength" #xSerialNumber>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary  pull-right" aria-label="Proceed"
                (click)="saveModal(xGMID, xSerialNumber.value,xName.value, xTerminalType.value)">
            <span aria-hidden="true" class="fa fa-angle-double-right">Proceed</span>
        </button>
    </div>
</ng-template>
<!-- </ngx-aside> -->
