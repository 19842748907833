import { Component, OnInit } from '@angular/core';
import { SetupPaymentProcessingMerchantAccountStore } from '../../../../../store';
import {
	TreeService,
	UrlStoreService,
	BusinessService,
	TabStructureService,
	UserHandlerService,
	BreadcrumbService
} from '../../../../../shared/services';
import { Router } from '@angular/router';

@Component({
	selector: 'app-setup-structure-merchant-account-list',
	templateUrl: './setup-structure-merchant-account-list.component.html',
	styleUrls: ['./setup-structure-merchant-account-list.component.scss']
})
export class SetupStructureMerchantAccountListComponent implements OnInit {
	public attributes: any[] = [];
	public storeValue: string = '';
	public storeObject: any = {};
	public action: any[] = [];
	public parameters: any = {
		// noGrid: true
	};
	setBizId: boolean = true;
	addBtn: boolean = false;
	tooltipMessage: string = 'Add Merchant Account';
	// public detailVisible: boolean = true;
	public merAcctID: string = '';
	public transactionType: string = 'all';
	fetchByStore: boolean = true;
	showDetails: boolean = false;

	public viewDetails: any[] = [
		{ header: 'Active', mapTo: 'xStatus' },
		{ header: 'Name', mapTo: 'xName' },
		{ header: 'Type', mapTo: 'xTypeTag' },
		{ header: 'Tag', mapTo: 'xTag' },
		{ header: 'Processor Tag', mapTo: 'xProcTag' },
		{ header: 'ID', mapTo: '_id' }
	];

	constructor(
		private router: Router,
		private setupPaymentProcessingMerchantAccountStore: SetupPaymentProcessingMerchantAccountStore,
		public treeService: TreeService,
		public urlStoreService: UrlStoreService,
		public businessService: BusinessService,
		private tabStructureService: TabStructureService,
		private userHandlerService: UserHandlerService,
		private breadcrumbService: BreadcrumbService
	) {
		// urlStoreService.swapUrl(router.url);
	}

	ngOnInit() {
		this.setBreadCrumbValue();
		console.log('merchant-list page');
		this.treeService.isParent = false;
		this.tabStructureService.checkAndSetTabNameToDef(
			this.tabStructureService.tabMode,
			this.tabStructureService.isCancelClick,
			this.tabStructureService.isSubmitClick
		);
		//For upward hierarchy------->
		if (this.treeService.terminalID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Profit Center to add Merchant Account';
			console.log('This is what I get', this.treeService.treeDataUnderStore);
			if (this.treeService.treeDataUnderStore.xMainMerAcctID) {
				this.treeService.isParent = true;
				this.router.navigate([
					'/dashboard/setup/business/structure/merchant-account',
					this.treeService.treeDataUnderStore ? this.treeService.treeDataUnderStore.xMainMerAcctID : null
				]);
			}
		}
		if (this.treeService.periphID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Profit Center to add Merchant Account';
			console.log('This is what I need ===========>', this.treeService.parentTerminal);
			if (this.treeService.parentTerminal.xMainMerAcctID) {
				this.treeService.isParent = true;
				this.router.navigate([
					'/dashboard/setup/business/structure/merchant-account',
					this.treeService.parentTerminal ? this.treeService.parentTerminal.xMainMerAcctID : null
				]);
			}
		}
		//End of upward hierarchy------->
		//For self------>
		if (this.treeService.merchantAccID.getValue()) {
			this.setBizId = false;
			this.router.navigate(['/dashboard/setup/business/structure/merchant-account', this.treeService.merchantAccID.getValue()]);
		}
		// End for self------->
		//For downward hierarchy------->
		if (this.treeService.businessID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Profit Center to add Merchant Account';
			console.log('here');
			// let tempObj = {
			//     business: "current",
			//     chain: "list",
			//     region: "list",
			//     property: "list",
			//     store: "list",
			//     pctr: "list",
			//     meracct: 'list',
			//     terminal: 'list',
			//     periph: 'list',
			// }
			// this.businessService.setTabStructureNames(tempObj);
		}
		if (this.treeService.chainID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Profit Center to add Merchant Account';
			this.parameters = {
				// xTypeTag: 'struct.property',
				struct_type: 'chain',
				structID: this.treeService.chainID.getValue()
			};
		}
		if (this.treeService.regionID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Profit Center to add Merchant Account';
			this.parameters = {
				// xTypeTag: 'struct.property',
				struct_type: 'region',
				structID: this.treeService.regionID.getValue()
			};
		}
		if (this.treeService.propertyID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Profit Center to add Merchant Account';
			this.parameters = {
				// xTypeTag: 'struct.property',
				struct_type: 'property',
				structID: this.treeService.propertyID.getValue()
			};
		}
		if (this.treeService.storeID.getValue()) {
			this.setBizId = false;
			this.addBtn = false;
			this.tooltipMessage = 'Please select a Profit Center to add Merchant Account';
			this.parameters = {
				// xTypeTag: 'struct.property',
				xStoreID: this.treeService.storeID.getValue()
			};
		}
		if (this.treeService.profitCenterID.getValue()) {
			this.setBizId = false;
			this.addBtn = true;
			this.tooltipMessage = 'Add Merchant Account';
			this.parameters = {
				xMainPCtrID: this.treeService.profitCenterID.getValue()
			};
		} else {
			if (this.setBizId) {
				this.addBtn = false;
				this.tooltipMessage = 'Please select a Profit Center to add Merchant Account';
				this.treeService.setId(this.businessService.businessID.getValue(), 'business');
			}
		}
		//End downward hierarchy------->

		this.setGridProperties();
	}

	setBreadCrumbValue(): void {
		const breadCrumb: any = {};
		breadCrumb.root = 'Setup';
		breadCrumb.child = 'Structure';
		breadCrumb.subChild = 'Merchant Account';
		breadCrumb.subChildLink = null;
		breadCrumb.grandChild = 'Merchant Accounts';
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	setGridProperties() {
		this.storeValue = 'SetupPaymentProcessingMerchantAccountStore';
		this.storeObject = this.setupPaymentProcessingMerchantAccountStore;
		// this.attributes = [
		//     { header: 'Active', mapTo: 'xStatus', width: '10%' },
		//     { header: 'Name', mapTo: 'xName', width: '20%' },
		//     { header: 'Type', mapTo: 'xTypeTag', width: '20%' },
		//     { header: 'Tag', mapTo: 'xTag', width: '20%' },
		//     {header :'Notes', mapTo:'xNotes', width:'20%'},
		//     { header: 'Actions', mapTo: '', width: '10%'}
		// ];
		const editLink = '/dashboard/setup/business/structure/merchant-account';
		this.action = [
			{
				type: 'view',
				visible: true,
				text: 'Details',
				icon: 'fa fa-eye',
				action: 'emit'
			}
		];

		if (!this.userHandlerService.userRoles.isBusinessEmployee) {
			this.attributes = [
				{ header: 'Active', mapTo: 'xStatus', width: '10%' },
				{ header: 'Name', mapTo: 'xName', width: '20%' },
				{ header: 'Type', mapTo: 'xTypeTag', width: '20%' },
				{ header: 'Tag', mapTo: 'xTag', width: '20%' },
				{ header: 'Notes', mapTo: 'xNotes', width: '20%' },
				{ header: 'Actions', mapTo: '', width: '10%' }
			];
			this.action.push(
				{
					type: 'edit',
					visible: true,
					text: 'Edit',
					icon: 'fa fa-edit',
					action: 'url',
					onClick: editLink
				},
				{
					type: 'delete',
					visible: true,
					text: 'Delete',
					icon: 'fa fa-trash',
					action: 'func',
					onClick: 'destroy',
					disabled: !this.userHandlerService.userPerms.canDeleteSetup
				}
			);
		} else if (this.userHandlerService.userRoles.isBusinessEmployee) {
			this.attributes = [
				{ header: 'Active', mapTo: 'xStatus', width: '10%' },
				{ header: 'Name', mapTo: 'xName', width: '25%' },
				{ header: 'Type', mapTo: 'xTypeTag', width: '20%' },
				{ header: 'Tag', mapTo: 'xTag', width: '20%' },
				{ header: 'Notes', mapTo: 'xNotes', width: '25%' }
			];
		}
	}

	onActionRequest(obj: any) {
		if (obj.act.type === 'view') {
			console.log("I'm here bro", obj);
			this.merAcctID = obj.id;
			// this.detailVisible = true;
		}
	}

	onShowDetails(obj: any) {
		console.log('Show object', obj);
		this.showDetails = obj;
	}

	viewClosed(visible: boolean) {
		if (!visible) {
			this.showDetails = false;
			this.merAcctID = '';
		}
	}
}
