<form [formGroup]="paymentMethod" (ngSubmit)="paymentMethodSelected(paymentMethod)">
    <div class='card card-default'>
        <div class="card-body row m-b-10">
            <div class="col-sm-12">
                <!-- --------------------------------------- use this for loading ----------------------------------------------- -->
                <!-- <div class="grid-spinner">
                                    <div class="bounce1"></div>
                                    <div class="bounce2"></div>
                                    <div class="bounce3"></div>
                                </div>
                                <span style="color:#3b5877">Fetching required data</span> -->
                <!-- --------------------------------------- use this for loading ----------------------------------------------- -->
                <div class="row m-b-10">
                    <!-- <div class='col-sm-3'>
                                        <label>Selected Payment Method</label>
                                    </div> -->
                    <div class="col-sm-2 offset-sm-1 ">
                        <label>Type</label>
                    </div>
                    <div class="col-sm-1 ">
                        <label>Brand</label>
                    </div>
                    <div class="col-sm-3 ">
                        <label>Card Number</label>
                    </div>
                    <div class="col-sm-3 ">
                        <label>Card Name</label>
                    </div>
                </div>
                <div class="row m-b-10" *ngFor="let obj of paymentMethodOptions">
                    <!-- cardtype{{obj?.cardType}}
                                    cardnumber{{obj?.cardNumber}} -->
                    <div class="col-sm-1 radio">
                        <label>
                            <!-- checked status {{obj?.cardNumber === lastPaymentMethod?.cardNumber}} -->
                            <input
                                type="radio"
                                name="paymentOption"
                                [value]="obj"
                                formControlName="paymentOption"
                                [checked]="obj?._id === currentPaymentMethod?._id"
                            />
                        </label>
                    </div>
                    <div class="col-sm-2 ">
                        <p>{{ obj?.medium }}</p>
                    </div>
                    <div class="col-sm-1 ">
                        <p>{{ obj?.brand }}</p>
                    </div>
                    <div class="col-sm-3 ">
                        <p>{{ obj?.xMaskedAcctNum }}</p>
                    </div>
                    <div class="col-sm-3 ">
                        <p>{{ obj?.xHolderName }}</p>
                    </div>
                    <div class="col-sm-1">
                        <span *ngIf="obj?._id === lastPaymentMethod?._id" class="badge badge-info"> Last Used Card </span>
                    </div>
                </div>
            </div>
            <!-- payment method required {{ paymentMethod.get('paymentOption').hasError('required') }} -->
        </div>
    </div>
    <div class='row'>
        <div class='col-sm-4'>
            <button type="button" class="btn btn-primary" (click)="openCreatePaymentFromSelectPayment()">
                Create Payment Method
            </button>
        </div>
        <div class='col-sm-4 offset-sm-4 text-right'>
            <a class="btn btn-secondary" (click)="routeToPaymentForm()"><i class="fa fa-times" aria-hidden="true"></i> Cancel</a>
            <button class="btn  btn-primary" type="submit">Save <i class="fa fa-angle-double-right" aria-hidden="true"></i></button>
        </div>
    </div>
</form>
