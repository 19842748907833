export const AMOUNT_ORDERNUMBER_TEMPLATE = {
	templateName:'Order Number and Amount Pre Template',
	createHtmlCode(vtermUrl,vtermToken, encryptAPI){
		return `<div> 
		Order Number: <input id="orderNum"  value=""/>
		Amount: <input id="amt"  value=""/>
		<form id="stand_alone_vterm_link_form" action=${vtermUrl} method="GET" target="_parent"> 
			<input type="hidden" name="param" value="${vtermToken}" /> 
			<input type="hidden" id="coToken" name="coToken" value="" /> 
			<input type="hidden" id="stand_alone_vterm_callback_url" name="home" value="" /> 
			<div> 
			<button type="button" id="stand_alone_vterm_pay_now_btn" 
				onclick=' var xhr = new XMLHttpRequest();
				xhr.onreadystatechange = function() {
				if (xhr.readyState == XMLHttpRequest.DONE) { 
						let token = JSON.parse(xhr.responseText).data.vTermToken;
						document.getElementById("stand_alone_vterm_callback_url").value = window.parent.location.href;
						document.getElementById("coToken").value = token;
						document.getElementById("stand_alone_vterm_link_form").submit()
					}; 
				}; 
				let amt = document.getElementById("amt").value;
				let orderNum = document.getElementById("orderNum").value;
				let coData = "amount="+amt+"&"+"orderNumber="+orderNum;
				xhr.open("POST", "${encryptAPI}", true); 
				xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
				xhr.send(coData); 
				'> 
				Pay 
			</button> 
			</div> 
		</form> 
	</div>
	`;
	}
}

//TODO:
// id for orderNumber and amount needs to be modified as per id and element from which amount and order number is to be fetched 