import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { skip, takeWhile } from 'rxjs/operators';

import { BreadcrumbService, FilterService /*, UserHandlerService*/, NotificationService, UrlStoreService, BusinessService } from '../../shared/services';
import { SettledTransactionsStore } from '../../store/index';
import { MONTH } from '../../constants';
import { CompanySuspendedService } from 'app/pages/company-suspended/company-suspended.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginModalComponent } from 'app/pages/company-suspended/login-modal/login-modal.component';
import { dateAdd } from 'app/common-functions';
const YEAR_SIZE = 13;
const DAY_SIZE = 24;
const WEEK_SIZE = 8;
const QUARTER_YEAR_SIZE = 93;
const RSP_STATUS_SUCCESS: string = 'success';

@Component({
	selector: 'home-cmp',
	templateUrl: 'home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
	id = 'chart1';
	width = 600;
	height = 400;
	type = 'column2d';
	dataFormat = 'json';
	dataSource;
	loading: boolean = false;
	alive: boolean = true;
	txnRes: any = {};
	displayData: string = 'amount';
	// chartData: any[] = [];
	chartLabel: any[] = [];
	chartData: any[] = [];
	transactionRange: string = 'one_day';
	totalTransactionArray: {} = {};
	totalTransactionArrayForThreeMonths: {} = {};
	last3MonthsList: any[] = [];
	selectMonth: number = 0;
	showLoginModal: boolean = false;
	bsModalRef: BsModalRef;

	constructor(
		private breadcrumbService: BreadcrumbService,
		public urlStoreService: UrlStoreService,
		private router: Router,
		public settledTransactionsStore: SettledTransactionsStore,
		public businessService: BusinessService, // private filterService: FilterService, // private userHandlerService: UserHandlerService, // private notificationService: NotificationService
		public companySuspendService: CompanySuspendedService,
		private modalService: BsModalService
	) {
		this.urlStoreService.previousUrl = this.urlStoreService.currentUrl;
		console.log('current url home', router.url);
		this.urlStoreService.currentUrl = router.url;
		this.companySuspendService.dueBillingDate.subscribe((dueDate) => {
			const now = new Date().getTime();
			const dueD = dateAdd(new Date(dueDate), 'day', -30);
			const dueTime = dueD.getTime();
			console.log('now:::', now);
			console.log('dueDate:::', dueD);
			console.log('dueTime:::', dueTime);
			if (now > dueTime) {
				this.bsModalRef = this.modalService.show(LoginModalComponent, Object.assign({}, { class: 'billing-modal-max-width' }));
			}
		});
	}
	//////////////////////////////////////////////////

	public barChartOptions: any = {
		scaleShowVerticalLines: false,
		responsive: true,
		// maintainAspectRatio: false,
		scales: {
			xAxes: [
				{
					gridLines: {
						color: '#fff',
					},
					ticks: {
						fontSize: 10,
					},
				},
			],
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
						fontSize: 10,
						callback: function (value, index, values) {
							return convertNumericalValueToStringSeparated(value);
						},
					},
				},
			],
		},
		tooltips: {
			callbacks: {
				label: function (tooltipItem, data) {
					console.log('tooltip', tooltipItem, 'data', data);
					console.log('display data', data.datasets[0].label);
					if (data.datasets[0].label === 'amount') {
						return 'Amount: $ ' + convertNumericalValueToStringSeparated(tooltipItem.yLabel.toFixed(2));
					}
					return 'Count: ' + tooltipItem.yLabel;
				},
			},
		},
	};
	public barChartLabels: string[] = [];
	public barChartType: string = 'bar';
	public barChartLegend: boolean = false;

	public barChartData: any[] = [
		{
			data: [],
			label: '',
		},
	];

	public lineChartColors: Array<any> = [
		{
			// grey
			backgroundColor: '#567493',
			borderColor: '#567493',
			pointBackgroundColor: '#567493',
			pointBorderColor: '#fff',
			pointHoverBackgroundColor: '#fff',
			pointHoverBorderColor: 'rgba(148,159,177,0.8)',
		},
	];

	// events
	public chartClicked(e: any): void {
		console.log(e);
	}

	public chartHovered(e: any): void {
		console.log(e);
	}

	////////////////////////////////////////////////////
	ngOnInit(): void {
		this.setBreadCrumbValue();
		let currentDay = new Date();
		currentDay.setMonth(currentDay.getUTCMonth() - 1);
		let counter = 0;
		while (counter < 3) {
			this.last3MonthsList.push(MONTH[currentDay.getUTCMonth()]);
			currentDay.setMonth(currentDay.getUTCMonth() - 1);
			counter++;
		}
		// this.selectMonth=this.last3MonthsList[0].value;
		console.log('last3montshlist', this.last3MonthsList);
		this.businessService.watchBusinessID$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe(
				(businessID) => {
					if (businessID) {
						//     this.fetchTransaction("thisWeek", res);
						this.fetchTransaction();
					}
					// // this.loading = false;
				},
				(err) => {
					console.log('err inside home', err);
				}
			);
		if (this.businessService.businessID.getValue()) {
			this.fetchTransaction();
		}
	}

	setBreadCrumbValue(): void {
		const breadCrumb: any = {};
		breadCrumb.root = 'Home';
		breadCrumb.child = '';
		breadCrumb.subChild = '';
		breadCrumb.subChildLink = null;
		breadCrumb.grandChild = null;
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	setDisplayData(type: string) {
		this.displayData = type;
		this.fetchTransaction();
		console.log('setdisplayData', this.displayData);
	}
	setTransactionRange(range: string) {
		this.transactionRange = range;
		this.fetchTransaction();
	}
	selectionOfLast3Months() {
		console.log('selected month', this.selectMonth);
		this.fetchTransaction();
	}
	convertToDollar(val): number {
		return parseFloat((parseInt(val) / 100).toString());
	}
	fetchTransaction() {
		let params = {};
		let weekStartDay = new Date();
		let currentDay = new Date();
		let monthStartDay = new Date();
		let lastThreeMonthsStartDay = new Date();
		let lastThreeMonthsEndDay = new Date();
		let lastYearStartDay = new Date();
		lastThreeMonthsStartDay.setUTCMonth(lastThreeMonthsStartDay.getUTCMonth() - this.selectMonth - 1);
		lastThreeMonthsStartDay.setUTCDate(1);
		lastThreeMonthsEndDay.setUTCMonth(lastThreeMonthsEndDay.getUTCMonth() - this.selectMonth - 1);
		lastThreeMonthsEndDay.setUTCDate(this.daysInMonth(lastThreeMonthsEndDay));
		lastYearStartDay.setUTCFullYear(lastYearStartDay.getFullYear() - 1);
		lastYearStartDay.setUTCMonth(lastYearStartDay.getUTCMonth() + 1);
		lastYearStartDay.setUTCDate(1);
		console.log('last 3 months start', lastThreeMonthsStartDay, lastThreeMonthsStartDay.toDateString());
		console.log('last 3 months end', lastThreeMonthsEndDay, lastThreeMonthsEndDay.toDateString());
		console.log('last year', lastYearStartDay, lastYearStartDay.toDateString());
		const MONTH_SIZE = this.daysInMonth(currentDay) + 2;
		console.log('MONTHSIZE', MONTH_SIZE);
		monthStartDay.setMonth(monthStartDay.getUTCMonth() - 1);
		console.log('number of days in month start', monthStartDay.toUTCString(), this.daysInMonth(monthStartDay));
		weekStartDay.setDate(weekStartDay.getDate() - 7);
		console.log('number of days in month', currentDay.toUTCString(), this.daysInMonth(currentDay));
		console.log('transaction range', this.transactionRange);
		switch (this.transactionRange) {
			// case "all":
			//     if (this.parameters.hasOwnProperty("filter_type")) {
			//         this.filterData();
			//     }
			//     break;
			// case "open":
			//     this.filterData();
			//     break;
			// case "closed":
			//     this.filterData();
			//     break;
			case 'one_day':
				this.loading = true;
				this.totalTransactionArray = this.setInitialDataArray(DAY_SIZE);
				params = {
					xBusinessID: this.businessService.businessID.getValue(),
					xIsSettled: true,
					filterType: 'last_24_hrs',
					xRspStatus: RSP_STATUS_SUCCESS,
					currDate: currentDay.toDateString(),
					fetchAll: true,
					// xBusinessID: this.businessService.businessID.getValue(),
					// xIsSettled: true,
				};
				this.settledTransactionsStore.getAll(params).subscribe((res) => {
					console.log('response', res);
					if (res.data.length) {
						res.data.map((singleTxnRes) => {
							if (singleTxnRes['xSettleTime']) {
								let timeVal: string = singleTxnRes['xSettleTime'];
								let dateVal: string = singleTxnRes['xSettleDate'].toString();
								if (timeVal.length < 6) {
									timeVal = '0' + timeVal;
								}
								const hourVal = parseInt((parseInt(timeVal) / 10000).toString());
								const dayVal: number = parseInt((parseInt(dateVal) % 100).toString());
								// console.log("timeval", timeVal);
								// console.log("hourval", hourVal);
								// console.log("dayval", dayVal);
								// console.log(
								//     "current hour",
								//     currentDay.getUTCHours()
								// );
								// console.log(
								//     "current date",
								//     currentDay.getUTCDate()
								// );
								if (hourVal > -1 && hourVal < 24 && hourVal <= currentDay.getUTCHours() && dayVal === currentDay.getUTCDate()) {
									if (this.displayData === 'number') {
										// console.log("in number");
										this.totalTransactionArray[hourVal] += 1;
									} else if (this.displayData === 'amount') {
										this.totalTransactionArray[hourVal] += this.convertToDollar(singleTxnRes.xTtlAmt);
										// console.log(
										//     "in amount",
										//     singleTxnRes.xTtlAmt / 100,
										//     singleTxnRes.xTtlAmt
										// );
									}
								}
							}
						});
					}
					console.log('day array end', this.totalTransactionArray);
					this.loading = false;
					this.convertToChart(this.totalTransactionArray, DAY_SIZE, 'Hour', currentDay);
					this.resetDataArray();
					this.resetChartData();
				});
				break;
			case 'thisWeek':
				this.totalTransactionArray = this.setInitialDataArray(MONTH_SIZE);
				this.loading = true;
				params = {
					xBusinessID: this.businessService.businessID.getValue(),
					xIsSettled: true,
					xRspStatus: RSP_STATUS_SUCCESS,
					filterType: 'last_7_days',
					currDate: currentDay.toDateString(),
					fetchAll: true,
					// xBusinessID: this.businessService.businessID.getValue(),
					// xIsSettled: true,
				};
				this.settledTransactionsStore.getAll(params).subscribe((res) => {
					console.log('Response inside home', res);
					if (res.data.length) {
						res.data.map((singleTxnRes) => {
							if (singleTxnRes['xSettleDate']) {
								let dateVal: string = singleTxnRes['xSettleDate'].toString();
								const dayVal: number = parseInt((parseInt(dateVal) % 100).toString());
								const yearVal: number = parseInt((parseInt(dateVal) / 10000).toString());
								const monthVal: number = parseInt(((parseInt(dateVal) % 10000) / 100).toString());
								let dateValObj = new Date(yearVal, monthVal - 1, dayVal);
								// if (
								//     dateValObj > weekStartDay &&
								//     dateValObj < weekStartDay
								// ) {
								if (this.displayData === 'number') {
									// console.log("in number");
									this.totalTransactionArray[dayVal] += 1;
								} else if (this.displayData === 'amount') {
									this.totalTransactionArray[dayVal] += this.convertToDollar(singleTxnRes.xTtlAmt);
									// console.log(
									//     "in amount",
									//     singleTxnRes.xTtlAmt / 100,
									//     singleTxnRes.xTtlAmt
									// );
								}
								// }
							}
						});
					}
					this.loading = false;
					this.convertToChart(this.totalTransactionArray, WEEK_SIZE, 'Day', weekStartDay);
					this.resetDataArray();
					this.resetChartData();
				});
				break;
			case 'thisMon':
				this.totalTransactionArray = this.setInitialDataArray(MONTH_SIZE);
				this.loading = true;
				params = {
					xBusinessID: this.businessService.businessID.getValue(),
					xIsSettled: true,
					xRspStatus: RSP_STATUS_SUCCESS,
					filterType: 'last_30_days',
					currDate: currentDay.toDateString(),
					fetchAll: true,
					// xBusinessID: this.businessService.businessID.getValue(),
					// xIsSettled: true,
				};
				this.settledTransactionsStore.getAll(params).subscribe((res) => {
					if (res.data.length) {
						res.data.map((singleTxnRes) => {
							if (singleTxnRes['xSettleDate']) {
								let dateVal: string = singleTxnRes['xSettleDate'].toString();
								const dayVal: number = parseInt((parseInt(dateVal) % 100).toString());
								const monthVal: number = parseInt(((parseInt(dateVal) % 10000) / 100).toString());
								if (dayVal > 0 && dayVal < 32 && !(currentDay.getUTCDate() === dayVal && currentDay.getUTCMonth() + 1 !== monthVal)) {
									if (this.displayData === 'number') {
										// console.log("in number");
										this.totalTransactionArray[dayVal] += 1;
									} else if (this.displayData === 'amount') {
										this.totalTransactionArray[dayVal] += this.convertToDollar(singleTxnRes.xTtlAmt);
										// console.log(
										//     "in amount",
										//     singleTxnRes.xTtlAmt / 100,
										//     singleTxnRes.xTtlAmt
										// );
									}
								}
							}
						});
					}
					this.loading = false;
					this.convertToChart(this.totalTransactionArray, MONTH_SIZE, 'Day', monthStartDay);
					this.resetDataArray();
					this.resetChartData();
				});
				break;
			case 'last3Mon':
				this.totalTransactionArray = this.setInitialDataArray(MONTH_SIZE);
				console.log('totalTransactionArrayForThreeMonths', this.totalTransactionArrayForThreeMonths);
				this.loading = true;
				params = {
					xBusinessID: this.businessService.businessID.getValue(),
					xIsSettled: true,
					filterType: 'advancedDateFilter',
					startDate: lastThreeMonthsStartDay.toDateString(),
					endDate: lastThreeMonthsEndDay.toDateString(),
					xRspStatus: RSP_STATUS_SUCCESS,
					fetchAll: true,
				};
				this.settledTransactionsStore.getAll(params).subscribe((res) => {
					console.log('3months res', res);
					if (res.data.length) {
						res.data.map((singleTxnRes) => {
							if (singleTxnRes['xSettleDate']) {
								let dateVal: string = singleTxnRes['xSettleDate'].toString();
								const dayVal: number = parseInt((parseInt(dateVal) % 100).toString());
								if (dayVal > 0 && dayVal < 32) {
									// console.log("day val", dayVal);
									if (this.displayData === 'number') {
										// console.log("in number");
										this.totalTransactionArray[dayVal] += 1;
									} else if (this.displayData === 'amount') {
										this.totalTransactionArray[dayVal] += this.convertToDollar(singleTxnRes.xTtlAmt);
										// console.log(
										//     "in amount",
										//     singleTxnRes.xTtlAmt / 100,
										//     singleTxnRes.xTtlAmt
										// );
									}
								}
							}
						});
					}
					console.log('dataarray 3 months', this.totalTransactionArray);
					this.loading = false;
					this.convertToChart(this.totalTransactionArray, MONTH_SIZE, 'Day', lastThreeMonthsStartDay);
					this.resetDataArray();
					this.resetChartData();
				});
				// params = {
				//     xBusinessID: this.businessService.businessID.getValue(),
				//     xIsSettled: true,
				//     filterType: "advancedDateFilter",
				//     startDate: lastThreeMonthsStartDay.toDateString(),
				//     endDate: currentDay.toDateString()
				// };
				// this.settledTransactionsStore.getAll(params).subscribe(res => {
				//     console.log(res);
				//     if (res.data.length) {
				//         res.data.map(singleTxnRes => {
				//             if (singleTxnRes["xSettleDate"]) {
				//                 let dateVal: string = singleTxnRes[
				//                     "xSettleDate"
				//                 ].toString();
				//                 let monthVal: number = parseInt(
				//                     (
				//                         (parseInt(dateVal) % 10000) /
				//                         100
				//                     ).toString()
				//                 );
				//                 let dayVal: number = parseInt(
				//                     (parseInt(dateVal) % 100).toString()
				//                 );
				//                 if (
				//                     monthVal >=
				//                         lastThreeMonthsStartDay.getUTCMonth() +
				//                             1 &&
				//                     monthVal <= currentDay.getUTCMonth() + 1
				//                 ) {
				//                     if (this.displayData === "number") {
				//                         console.log(
				//                             "dateVal",
				//                             dateVal,
				//                             "\nmonthval",
				//                             monthVal,
				//                             "\ndayval",
				//                             dayVal
				//                         );
				//                         this.totalTransactionArrayForThreeMonths[
				//                             monthVal
				//                         ][dayVal] += 1;
				//                         console.log(
				//                             Object.assign(
				//                                 {},
				//                                 this
				//                                     .totalTransactionArrayForThreeMonths[
				//                                     monthVal
				//                                 ]
				//                             )
				//                         );
				//                     } else if (this.displayData === "amount") {
				//                         // console.log(
				//                         //     "in amount",
				//                         //     singleTxnRes.xTtlAmt
				//                         // );
				//                         console.log(
				//                             "dateVal",
				//                             dateVal,
				//                             "\nmonthval",
				//                             monthVal,
				//                             "\ndayval",
				//                             dayVal
				//                         );
				//                         this.totalTransactionArrayForThreeMonths[
				//                             monthVal
				//                         ][dayVal] += singleTxnRes.xTtlAmt;
				//                         console.log(
				//                             Object.assign(
				//                                 {},
				//                                 this
				//                                     .totalTransactionArrayForThreeMonths[
				//                                     monthVal
				//                                 ]
				//                             )
				//                         );
				//                     }
				//                 }
				//             }
				//         });
				//     }
				//     this.loading = false;
				//     this.convertToChart(
				//         this.totalTransactionArrayForThreeMonths,
				//         QUARTER_YEAR_SIZE,
				//         "Total Transactions Last 3 months",
				//         lastThreeMonthsStartDay
				//     );
				//     this.resetDataArray();
				//     this.resetChartData();
				// });
				break;
			case 'one_year':
				this.totalTransactionArray = this.setInitialDataArray(YEAR_SIZE);
				console.log('init year array', this.totalTransactionArray);
				this.loading = true;
				params = {
					// xBusinessID: this.businessService.businessID.getValue(),
					// xIsSettled: true,
					fetchAll: true,
					xBusinessID: this.businessService.businessID.getValue(),
					xIsSettled: true,
					filterType: 'advancedDateFilter',
					xRspStatus: RSP_STATUS_SUCCESS,
					startDate: lastYearStartDay.toDateString(),
					endDate: currentDay.toDateString(),
				};
				this.settledTransactionsStore.getAll(params).subscribe((res) => {
					if (res.data.length) {
						res.data.map((singleTxnRes) => {
							if (singleTxnRes['xSettleDate']) {
								let dateVal: string = singleTxnRes['xSettleDate'].toString();
								const monthVal: number = parseInt(((parseInt(dateVal) % 10000) / 100).toString());
								if (monthVal > 0 && monthVal < 13) {
									if (this.displayData === 'number') {
										// console.log("in number");
										this.totalTransactionArray[monthVal] += 1;
									} else if (this.displayData === 'amount') {
										this.totalTransactionArray[monthVal] += this.convertToDollar(singleTxnRes.xTtlAmt);
										// console.log(
										//     "in amount",
										//     singleTxnRes.xTtlAmt / 100,
										//     singleTxnRes.xTtlAmt
										// );
										// console.log(
										//     "parse int val",
										//     this.convertToDollar(
										//         singleTxnRes.xTtlAmt
										//     )
										// );
									}
								}
							}
						});
					}
					console.log('this.totalTransactionArray', this.totalTransactionArray);
					this.loading = false;
					this.convertToChart(this.totalTransactionArray, YEAR_SIZE, 'Month', currentDay);
					this.resetDataArray();
					this.resetChartData();
				});
				break;
		}
		console.log('tottransaction end', this.totalTransactionArray);
		console.log('chartdata end', this.chartData);
	}
	resetChartData() {
		this.chartData = [];
		this.chartLabel = [];
	}
	resetDataArray() {
		this.totalTransactionArray = [];
		this.totalTransactionArrayForThreeMonths = {};
	}
	setInitialDataArray(size) {
		let initialDataArray = [];
		for (let field = 0; field < size; field++) {
			initialDataArray[field] = 0;
		}
		return initialDataArray;
	}
	setInitialLastThreeMonthsDataArray() {
		// let initialDataArray = [];

		for (let field = 0; field < 13; field++) {
			this.totalTransactionArrayForThreeMonths[field] = 0;
		}
		for (let field = 0; field < 13; field++) {
			// this.totalTransactionArrayForThreeMonths[field] = initialDataArray;
			let initialDataArray = [];
			for (let field = 0; field < 32; field++) {
				initialDataArray[field] = 0;
			}
			console.log(this.totalTransactionArrayForThreeMonths, field, this.totalTransactionArrayForThreeMonths[field]);
			this.totalTransactionArrayForThreeMonths[field] = initialDataArray;
		}
		console.log('totalTransactionArrayForThreeMonths', this.totalTransactionArrayForThreeMonths);
	}
	convertToChart(dataArray, size, toolTipDisplay, dateObj) {
		let daysInDateObjMonth = this.daysInMonth(dateObj) + 1;
		let dateObjDay = dateObj.getUTCDate();
		let dateObjMonth = dateObj.getUTCMonth() + 1;
		let dateObjHour = dateObj.getUTCHours();
		console.log('dateObjDay', dateObjDay);
		console.log('dateObjMonth', dateObjMonth);
		console.log('dateObjHour', dateObjHour);
		console.log('date obj days', this.daysInMonth(dateObj), dateObj.toUTCString());
		let field,
			remainingField = 1,
			weekRemainingDays;
		if (this.transactionRange === 'last3Mon') {
			daysInDateObjMonth = this.daysInMonth(dateObj);
			for (field = 1; field <= daysInDateObjMonth; field++) {
				// this.chartData.push({
				//     label:
				//         field.toString().length === 1
				//             ? "0" + field.toString()
				//             : field.toString(),
				//     value: dataArray[field],
				//     toolTip: toolTipDisplay,
				//     displayType:
				//         this.displayData === "amount"
				//             ? "Amount: $"
				//             : "No. Of Transaction:"
				// });
				this.chartLabel.push(field.toString().length === 1 ? '0' + field.toString() : field.toString());
				this.chartData.push(dataArray[field]);
			}
			console.log('chartData in 3 months', this.chartData);
		} else {
			if (this.transactionRange === 'one_year') field = dateObjMonth;
			else if (this.transactionRange === 'thisMon') {
				field = dateObjDay;
				size = this.daysInMonth(dateObj) + 1;
			} else if (this.transactionRange === 'one_day') field = dateObjHour;
			else if (this.transactionRange === 'thisWeek') {
				field = dateObjDay;
				size = dateObjDay + 8;
				if (size > daysInDateObjMonth) {
					weekRemainingDays = size - daysInDateObjMonth;
					size = daysInDateObjMonth;
				}
			}
			console.log('field', field, 'size', size);
			console.log(MONTH);
			if (this.transactionRange === 'one_year') {
				console.log('entered year');
				for (let value = field + 1; value < size; value++) {
					// console.log(dataArray[value], value, MONTH[value - 1]);
					// this.chartData.push({
					//     label: MONTH[value - 1].small,
					//     value: dataArray[value],
					//     toolTip: toolTipDisplay,
					//     displayType:
					//         this.displayData === "amount"
					//             ? "Amount: $"
					//             : "No. Of Transaction: "
					// });
					this.chartLabel.push(MONTH[value - 1].small);
					this.chartData.push(dataArray[value]);
				}
				for (remainingField = 1; remainingField <= field; remainingField++) {
					// console.log(
					//     dataArray[remainingField],
					//     remainingField,
					//     MONTH[remainingField - 1]
					// );
					// this.chartData.push({
					//     label: MONTH[remainingField - 1].small,
					//     value: dataArray[remainingField],
					//     toolTip: toolTipDisplay,
					//     displayType:
					//         this.displayData === "amount"
					//             ? "Amount: $"
					//             : "No. Of Transaction: "
					// });
					this.chartLabel.push(MONTH[remainingField - 1].small);
					this.chartData.push(dataArray[remainingField]);
				}
			} else {
				for (let value = field + 1; value < size; value++) {
					// console.log(dataArray[value], value);
					// this.chartData.push({
					//     label:
					//         value.toString().length === 1
					//             ? "0" + value.toString()
					//             : value.toString(),
					//     value: dataArray[value],
					//     toolTip: toolTipDisplay,
					//     displayType:
					//         this.displayData === "amount"
					//             ? "Amount: $"
					//             : "No. Of Transaction: "
					// });
					this.chartLabel.push(value.toString().length === 1 ? '0' + value.toString() : value.toString());
					this.chartData.push(dataArray[value]);
				}
				// }
				// console.log("break", field);
				remainingField = 1;
				if (this.transactionRange === 'thisWeek') {
					field = weekRemainingDays;
				} else if (this.transactionRange === 'one_day') {
					remainingField = 0;
				}
				for (; remainingField <= field; remainingField++) {
					// console.log(dataArray[remainingField], remainingField);
					// this.chartData.push({
					//     label:
					//         remainingField.toString().length === 1
					//             ? "0" + remainingField.toString()
					//             : remainingField.toString(),
					//     value: dataArray[remainingField],
					//     toolTip: toolTipDisplay,
					//     displayType:
					//         this.displayData === "amount"
					//             ? "Amount: $"
					//             : "No. Of Transaction: "
					// });
					this.chartLabel.push(remainingField.toString().length === 1 ? '0' + remainingField.toString() : remainingField.toString());
					this.chartData.push(dataArray[remainingField]);
				}
			}
		}
		console.log('chart label created', this.chartLabel);
		console.log('chart data created', this.chartData);
		this.barChartData = [
			{
				data: this.chartData,
				label: this.displayData,
			},
		];
		this.barChartLabels = this.chartLabel;
		console.log('barchart label created', this.barChartLabels);
		console.log('barchart data created', this.barChartData);
		// this.dataSource = {
		//     chart: {
		//         caption: "Transactions",
		//         toolTip: toolTipDisplay,
		//         displayType:
		//             this.displayData === "amount"
		//                 ? "Amount"
		//                 : "No. Of Transaction"
		//     },
		//     data: this.chartData
		// };
	}
	daysInMonth(anyDateInMonth) {
		return new Date(anyDateInMonth.getFullYear(), anyDateInMonth.getMonth() + 1, 0).getDate();
	}

	ngOnDestroy(): void {
		this.alive = false;
	}
}
function convertNumericalValueToStringSeparated(value: any): string {
	let stringValInit = value.toString().split('.');
	// console.log("stringvalinit", stringValInit);
	// console.log("stringvalinit[1]", stringValInit[1]);
	let stringVal = '';
	if (stringValInit[1]) stringVal = '.' + stringValInit[1];
	else stringVal = '';
	// console.log("value before mod", value);
	let convertedValue: number = parseInt(value);
	// console.log("value after mod", value);
	if (convertedValue < 1000) return value;
	while (convertedValue !== 0) {
		let residualVal = (convertedValue % 1000).toString();
		convertedValue = convertedValue / 1000;
		convertedValue = parseInt(convertedValue.toString());
		// if (residualVal === "0") residualVal = "000";
		console.log('convetred value', convertedValue / 1000);
		while (residualVal.length < 3 && convertedValue !== 0) {
			residualVal = '0' + residualVal;
			// console.log(
			//     "inloop residual val",
			//     residualVal,
			//     "length",
			//     residualVal.length
			// );
		}
		console.log('residual val', residualVal);
		if (stringVal.charAt(0) === '.') stringVal = residualVal + stringVal;
		else stringVal = residualVal + ',' + stringVal;
		// console.log("stringval", stringVal);
		// console.log("convertedValue", convertedValue);
	}
	// console.log("stringvalinit[1]", stringValInit[1]);
	if (!stringValInit[1]) {
		stringVal = stringVal.slice(0, stringVal.length - 1);
	}
	console.log('stringval final', stringVal);
	return stringVal;
}
