<section *ngIf="businessService?.businessID?.getValue()">
    <div class="grid-filter merch-info">
        <div class="row">
            <div class="col-sm-5 ">
                <div class="row">
                    <div class="merch_type col-sm-5" style="width:200px;">
                        <select
                            class="form-control form-control-sm select-days"
                            [(ngModel)]="filter"
                            (change)="fetchFilter()"
                        >
                            <option
                                *ngIf="filter === 'advancedDateFilter'"
                                value="advancedDateFilter"
                            >
                                <label class="radio1">
                                    <span></span> -None-
                                </label>
                            </option>
                            <option value="all">
                                <label class="radio1">
                                    <span></span> All
                                </label>
                            </option>
                            <option value="last_24_hrs">
                                <label class="radio1">
                                    <span></span> Last 24 Hrs
                                </label>
                            </option>
                            <option value="last_7_days">
                                <label class="radio1">
                                    <span></span> Last 7 Days
                                </label>
                            </option>
                            <option value="last_30_days">
                                <label class="radio1">
                                    <span></span> Last 30 Days
                                </label>
                            </option>
                            <option value="last_60_days">
                                <label class="radio1">
                                    <span></span> Last 60 Days
                                </label>
                            </option>
                            <option value="last_90_days">
                                <label class="radio1">
                                    <span></span> Last 90 Days
                                </label>
                            </option>
                        </select>
                        <span> <i class="fa fa-angle-down"></i> </span>
                    </div>
                    <div class="col-sm-7 datepicker-container">
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                        <input
                            type="text"
                            placeholder="Set to and from date"
                            class="form-control"
                            bsDaterangepicker
                            [(ngModel)]="dateRangeValue"
                            [bsConfig]="{
                                containerClass: 'theme-custom-date-picker',showWeekNumbers:false
                            }"
                        />
                        <button
                            tooltip="Filter (via date)"
                            placement="bottom"
                            container="body"
                            containerClass="tooltip-global"
                            type="button"
                            class="btn btn-sm btn-primary"
                            (click)="onDateFilterClick($event)"
                        >
                            <i
                                class="fa fa-angle-double-right"
                                aria-hidden="true"
                            ></i>
                            <!--
                                <i class="fa fa-search" aria-hidden="true"></i>
                            -->
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form class="form-horizontal padder">
        <!-- <div class="row  m-b-1"> -->
        <div class="grid-add-wrap grid-batch-filter">
            <grid-cmp
                [attributes]="attributes"
                [storeValue]="storeValue"
                [parameters]="parameters"
                [fetchByStore]="fetchByStore"
                [storeObj]="storeObject"
                [action]="action"
                (actionRequest)="onActionRequest($event)"
                (detailVisible)="onShowDetails($event)"
                (refresh)="onRefreshRequest($event)"
                (reSync)="onReSyncRequest($event)"
                (pctrListEvent)="recievePCtrList($event)"
            ></grid-cmp>
        </div>
        <!-- <div *ngIf="detailVisible" class="col-sm-4"> -->
        <!-- </div> -->
        <!-- </div> -->
    </form>
    <details-cmp
        [id]="batchID"
        [viewDetails]="viewDetails"
        [storeValue]="storeValue"
        [label]="'Batch'"
        [store]="storeObject"
        [show]="showDetails"
        (onViewClose)="viewClosed($event)"
    ></details-cmp>
    <div *ngIf="showOverLay" class="overlay">
        <div class="overlay-content">
            <div>
                <i
                    style="color:white;"
                    class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                ></i>
            </div>
            <div>
                <span style="font-size:20px"
                    >Please wait. Performing selected action on Batch.</span
                >
            </div>
        </div>
    </div>
</section>

<section *ngIf="!businessService?.businessID?.getValue()">
    <form class="form-horizontal">
        <div class="grid-add-wrap grid-batch-filter">
            <div class="row m-b-1">
                <div class="col-sm-10 offset-sm-1 m-t-30">
                    <div class="coming-soon">
                        <h4 class="text-muted">Please select a Business</h4>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>
<!-- [class.col-sm-10]="!detailVisible" [class.col-sm-8]="detailVisible" -->
