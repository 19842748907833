import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InputMaskModule } from 'primeng/inputmask';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule} from 'ngx-bootstrap/tooltip';

import { GlobalValidator, GmNumberService } from '../../services';
import { SidebarModule } from 'ng-sidebar';
import {
    MainContactRowComponent,
    MainContactColumnComponent,
    SocialContactColumnComponent,
    AddressComponent,
    SocialContactRowComponent,
    SimpleContactComponent,
    BasicInformationComponent,
    DetailsComponent,
    ModelNumberComponent,
    SecurityComponent
} from './components';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ResourceEditorModule } from '../resource-editor/resource-editor.module';
import { PermissionModalModule } from '../permission-modal/permission-modal.module';
import { PreviewPermissionModalModule } from '../preview-permission-modal/preview-permission-modal.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ResourceEditorModule,
        InputMaskModule,
        SidebarModule.forRoot(),
        TooltipModule.forRoot(),
        ModalModule,
        FormsModule,
        PermissionModalModule,
        PreviewPermissionModalModule
    ],
    declarations: [
        MainContactRowComponent,
        MainContactColumnComponent,
        SocialContactColumnComponent,
        AddressComponent,
        SocialContactRowComponent,
        SimpleContactComponent,
        BasicInformationComponent,
        DetailsComponent,
        ModelNumberComponent,
        SecurityComponent,
        ImageUploaderComponent
    ],
    exports: [
        MainContactRowComponent,
        MainContactColumnComponent,
        SocialContactColumnComponent,
        AddressComponent,
        SocialContactRowComponent,
        SimpleContactComponent,
        BasicInformationComponent,
        DetailsComponent,
        ModelNumberComponent,
        SecurityComponent,
        ImageUploaderComponent
    ],
    providers: [GlobalValidator, GmNumberService]
})
export class SectionsModule {}
