import { Component, OnInit } from '@angular/core';
import { SetupBusinessStructureStoreSetupStore } from '../../../../../store';
import {
	TreeService,
	UrlStoreService,
	BusinessService,
	TabStructureService,
	UserHandlerService,
	BreadcrumbService
} from '../../../../../shared/services';
import { Router } from '@angular/router';

@Component({
	selector: 'app-setup-structure-store-list',
	templateUrl: './setup-structure-store-list.component.html',
	styleUrls: ['./setup-structure-store-list.component.scss']
})
export class SetupStructureStoreListComponent implements OnInit {
	public attributes: any[] = [];
	public storeValue: string = '';
	public storeObject: any = {};
	public action: any[] = [];
	public parameters: any = {
		// noGrid: true
		// xTypeTag: 'struct.chain'
	};

	// public detailVisible: boolean = true;
	public storeID: string = '';
	public transactionType: string = 'all';
	fetchByStore: boolean = true;
	showDetails: boolean = false;
	setBizId: boolean = true;

	public viewDetails: any[] = [
		{ header: 'Active', mapTo: 'xStatus' },
		{ header: 'Name', mapTo: 'xName' },
		{ header: 'Sub Name', mapTo: 'xSubName' },
		{ header: 'City', mapTo: 'xAddr.xCity', nested: true },
		{ header: 'Main Phone', mapTo: 'xMainContact.xPhone', nested: true },
		{ header: 'GSKey', mapTo: 'xGSKey', nested: true },
		{ header: 'GSPwd', mapTo: 'xGSPwd', nested: true },
		{ header: 'ID', mapTo: '_id' }
	];

	constructor(
		private setupBusinessStructureStoreSetupStore: SetupBusinessStructureStoreSetupStore,
		public treeService: TreeService,
		private router: Router,
		public urlStoreService: UrlStoreService,
		public businessService: BusinessService,
		private tabStructureService: TabStructureService,
		private userHandlerService: UserHandlerService,
		private breadcrumbService: BreadcrumbService
	) {
		// urlStoreService.swapUrl(router.url);
	}

	ngOnInit() {
		this.setBreadCrumbValue();
		this.treeService.isParent = false;
		this.tabStructureService.checkAndSetTabNameToDef(
			this.tabStructureService.tabMode,
			this.tabStructureService.isCancelClick,
			this.tabStructureService.isSubmitClick
		);
		//For downward hierarchy------->
		if (this.treeService.businessID.getValue()) {
			this.setBizId = false;
			console.log('here');
			// let tempObj = {
			//     business: "current",
			//     chain: "list",
			//     region: "list",
			//     property: "list",
			//     store: "list",
			//     pctr: "list",
			//     meracct: 'list',
			//     terminal: 'list',
			//     periph: 'list',
			// }
			// this.businessService.setTabStructureNames(tempObj);
			// this.parameters = {
			//     xTypeTag: TAGSCONSTS.structTags.chainTags,
			//     xBusinessID: this.treeService.businessID.getValue()
			// };
		}
		if (this.treeService.chainID.getValue()) {
			this.setBizId = false;
			this.parameters = {
				// xTypeTag: 'struct.property',
				structID: this.treeService.chainID.getValue()
			};
		}
		if (this.treeService.regionID.getValue()) {
			this.setBizId = false;
			this.parameters = {
				// xTypeTag: 'struct.property',
				structID: this.treeService.regionID.getValue()
			};
		}
		if (this.treeService.propertyID.getValue()) {
			this.setBizId = false;
			this.parameters = {
				// xTypeTag: 'struct.property',
				structID: this.treeService.propertyID.getValue()
			};
			console.log('Inside property', this.parameters);
		}
		//End of downward hierarchy------->
		//For self------>
		if (this.treeService.storeID.getValue()) {
			this.setBizId = false;
			this.router.navigate(['/dashboard/setup/business/structure/store', this.treeService.storeID.getValue()]);
		}
		// End for self------->
		//For upward hierarchy--------->
		if (this.treeService.profitCenterID.getValue()) {
			this.setBizId = false;
			console.log('This is the store data??', this.treeService.treeData._id);
			this.treeService.isParent = true;
			this.router.navigate([
				'/dashboard/setup/business/structure/store',
				this.treeService.treeData ? this.treeService.treeData._id : null
			]);
		}
		if (this.treeService.merchantAccID.getValue()) {
			this.setBizId = false;
			console.log('This is the store data??', this.treeService.treeData._id);
			this.treeService.isParent = true;
			this.router.navigate([
				'/dashboard/setup/business/structure/store',
				this.treeService.treeData ? this.treeService.treeData._id : null
			]);
		}
		if (this.treeService.terminalID.getValue()) {
			this.setBizId = false;
			console.log('This is the store data??', this.treeService.treeData._id);
			this.treeService.isParent = true;
			this.router.navigate([
				'/dashboard/setup/business/structure/store',
				this.treeService.treeData ? this.treeService.treeData._id : null
			]);
		}
		if (this.treeService.periphID.getValue()) {
			this.setBizId = false;
			console.log('This is the store data??', this.treeService.treeData._id);
			this.treeService.isParent = true;
			this.router.navigate([
				'/dashboard/setup/business/structure/store',
				this.treeService.treeData ? this.treeService.treeData._id : null
			]);
		} else {
			if (this.setBizId) {
				this.treeService.setId(this.businessService.businessID.getValue(), 'business');
			}
		}
		//End of upward hierarchy--------->
		this.setGridProperties();
	}

	setGridProperties() {
		this.storeValue = 'SetupBusinessStructureStoreSetupStore';
		this.storeObject = this.setupBusinessStructureStoreSetupStore;
		// this.attributes = [
		//     { header: 'Active', mapTo: 'xStatus', width: '10%' },
		//     { header: 'Name', mapTo: 'xName', width: '20%' },
		//     { header: 'Sub Name', mapTo: 'xSubName', width: '15%' },
		//     { header: 'City', mapTo: 'xAddr.xCity', width: '10%' },
		//     { header: 'Main Phone', mapTo: 'xMainContact.xPhone', width: '15%' },
		//     { header: 'Notes', mapTo: 'xNotes', width: '20%' },
		//     { header: 'Actions', mapTo: '', width: '10%'}
		// ];
		const editLink = '/dashboard/setup/business/structure/store';
		this.action = [
			{ type: 'view', visible: true, text: 'Details', icon: 'fa fa-eye', action: 'emit' },
			{
				type: 'edit',
				visible: true,
				text: 'Deposit Accounts',
				icon: 'fa fa-user',
				action: 'url_key',
				onClick: '/dashboard/setup/business/structure/store/member-accounts',
				paramKey: 'storeID'
			}
			// { type: 'edit', visible: true, text: 'Edit', icon: 'fa fa-edit', action: 'url', onClick: editLink },
			// { type: 'delete', visible: true, text: 'Delete', icon: 'fa fa-trash', action: 'func', onClick: 'destroy'}
		];

		if (!this.userHandlerService.userRoles.isBusinessEmployee) {
			this.attributes = [
				{ header: 'Active', mapTo: 'xStatus', width: '10%' },
				{ header: 'Name', mapTo: 'xName', width: '20%' },
				{ header: 'Sub Name', mapTo: 'xSubName', width: '20%' },
				{ header: 'City', mapTo: 'xAddr.xCity', width: '20%' },
				{ header: 'Main Phone', mapTo: 'xMainContact.xPhone', width: '20%' },
				{ header: 'Actions', mapTo: '', width: '10%' }
			];
			this.action.push(
				{ type: 'edit', visible: true, text: 'Edit', icon: 'fa fa-edit', action: 'url', onClick: editLink },
				{ 
					type: 'delete', 
					visible: true, 
					text: 'Delete', 
					icon: 'fa fa-trash', 
					action: 'func', 
					onClick: 'destroy', 
					disabled: !this.userHandlerService.userPerms.canDeleteSetup
				}
			);
		} else if (this.userHandlerService.userRoles.isBusinessEmployee) {
			this.attributes = [
				{ header: 'Active', mapTo: 'xStatus', width: '10%' },
				{ header: 'Name', mapTo: 'xName', width: '25%' },
				{ header: 'Sub Name', mapTo: 'xSubName', width: '20%' },
				{ header: 'City', mapTo: 'xAddr.xCity', width: '25%' },
				{ header: 'Main Phone', mapTo: 'xMainContact.xPhone', width: '20%' }
			];
		}
	}

	ngAfterViewInit() {
		Promise.resolve(null).then(() => {
			this.treeService.setEnable(true);
		});
	}

	setBreadCrumbValue(): void {
		const breadCrumb: any = {};
		breadCrumb.root = 'Setup';
		breadCrumb.child = 'Structure';
		breadCrumb.subChild = 'Store';
		breadCrumb.subChildLink = null;
		breadCrumb.grandChild = 'Stores';
		breadCrumb.rootList = [];
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	onActionRequest(obj: any) {
		if (obj.act.type === 'view') {
			console.log("I'm here bro", obj);
			this.storeID = obj.id;
			// this.detailVisible = true;
		}
	}

	onShowDetails(obj) {
		this.showDetails = obj;
	}

	viewClosed(visible: boolean) {
		if (!visible) {
			this.storeID = '';
			this.showDetails = false;
		}
	}
}
