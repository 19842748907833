import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

import { NgxPaginationModule } from 'ngx-pagination'; // for pagination
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { GridComponent } from './grid.component';
import { SummaryReportService, ConstantService } from '../../services';
import { VirtualScrollModule } from 'angular2-virtual-scroll';
import { SetupSecurityUsersStore } from './../../../store/setup-security-users-store';
import { AdvancedSearchModule } from 'app/shared/components/advanced-search/advanced-search.module';
import { DeleteModalModule } from 'app/shared/components/delete-modal/delete-modal.module';
import { ActionWorkerService } from 'app/shared/services/action-worker.service';
import { ImportTransactionsModule } from 'app/dashboard/transaction/shared/modules/import-transactions-modal/import-transactions-modal.module';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		BsDropdownModule.forRoot(),
		TooltipModule.forRoot(),
		NgxPaginationModule,
		InfiniteScrollModule,
		VirtualScrollModule,
		AdvancedSearchModule,
		DeleteModalModule,
		ImportTransactionsModule,
	],
	declarations: [GridComponent],
	exports: [GridComponent],
	providers: [
		SummaryReportService,
		ConstantService,
		SetupSecurityUsersStore,
		ActionWorkerService
	],
})
export class GridModule {}
