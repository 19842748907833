export class AcptCaps{
    // xAcptIndstTags:any[];
    xAcptMdmTags:any[];
    xAcptBrandTags:any[];
    xAcptTxnActTags:any[];
    // xAcptEncTags:any[];

    constructor(obj?:any){
        // this.xAcptIndstTags=(obj && obj.xAcptIndstTags) || [];
        this.xAcptMdmTags=(obj && obj.xAcptMdmTags) || [];
        this.xAcptBrandTags=(obj && obj.xAcptBrandTags) || [];
        this.xAcptTxnActTags=(obj && obj.xAcptTxnActTags) || [];
        // this.xAcptEncTags=(obj && obj.xAcptEncTags) || [];
    }
}