import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SetupBillingCustomerComponent } from './setup-billing-customer.component';
import { AddEditComponent } from './components/add-edit/add-edit.component';
import { MemberAccountsComponent } from 'app/shared/modules/member-accounts/member-accounts.component';

const routes: Routes = [
	// { path: "register", component: AddEditComponent },
	{ path: '', component: SetupBillingCustomerComponent },
	{ path: 'member-accounts', component: MemberAccountsComponent },
	{ path: 'add-edit', component: AddEditComponent },
	{ path: 'add-edit/:Id', component: AddEditComponent }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SetupBillingCustomerRoutingModule {}
