import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'filter-cmp',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnChanges {
    @Input() modifyFilter: boolean;
    newTransactionFilterModel: any;
    submitted: boolean;

    private transactionFilterModel: any = {};
    private filterModel: any = {};

    constructor() {
        this.modifyFilter = false;
    }

    ngOnInit() {}

    ngOnChanges() {
        // console.log('Inside FilterComponent OnChanges Function, value : -->', this.modifyFilter);
    }

    onTransactionSubmit() {
        this.submitted = true;
    }

    onFilterSubmit() {
        // console.log('I have--->', this.filterModel);
    }

    resetTransactionInfo(form: any): void {
        // console.log('Form DOM element -->', form);
        this.transactionFilterModel = {};
        form.reset();
    }
    resetFilterInfo(form: any): void {
        // console.log('Form DOM element -->', form);
        this.filterModel = {};
        form.reset();
    }
}
