<div class="content-wrapper home-container setup setup-customer">
    <div class="container-fluid">
        <div class="row m-b-1 ">
            <div class="col-sm-8 offset-sm-2 m-t-30">
                <div class="coming-soon"> <h1>Coming Soon</h1> <p>We are getting ready</p> </div>
            </div>
        </div>
    </div>
</div>

<!--     <iframe srcdoc='
        <html>
            <head>
                <title></title>
                <script></script>
                <link rel = "stylesheet"
                   type = "text/css"
                   href = "styles.bundle.css" />
            </head>
            <body>
                <form action="http://localhost:5200" method="GET" target="_parent">
                    <input type="hidden" name="param" value="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InhUZXJtaW5hbE5hbWUiOiJEZXZpYzAxIiwid2ViQXBwSW5mbyI6eyJiaXpJRCI6IjVhZDQ0NzIyYmU3ZjZhMjU5ODcyZmFkMSIsInRlcm1pbmFsSUQiOiI1YWQ2MGJkMTg1YjljMjU1ZmYwYzQ3NmYifSwieEdUSUQiOiJHVDMwMTAwMjM1MTciLCJ4UEN0ck5hbWUiOiJNYWluIiwieEdNSUQiOiIyMDAxMDA3NDYzIiwieEdNUFciOiJHTVBXMzAxMDAyNTI0MCIsImJpbGxpbmdBY2NvdW50SW5mbyI6eyJmaXJzdE5hbWUiOiIiLCJsYXN0TmFtZSI6IiIsInBob25lTnVtYmVyIjoiIiwiZW1haWwiOiIiLCJjb21wYW55IjoiIiwiYWRkcmVzcyI6IiIsImNpdHkiOiIiLCJzdGF0ZSI6IiIsInppcENvZGUiOiIifSwibWVyY2hhbnRJbmZvIjp7Im5hbWUiOiJNYWluIn0sInN0b3JlSW5mbyI6eyJlbWFpbCI6ImRldi5jbkA5MTFzb2Z0d2FyZS5jb20ifX0sImlhdCI6MTU0MTcwNzI2MSwiZXhwIjoxNTQxNzA3NTYxfQ.ddeJD7eOILdRzVg3-9Mb_i9PKOkv7wEVzRkBwMcKoYc" />
                    <div class="col-sm-8 offset-sm-2 m-t-30">
                        <button type="submit" class="btn btn-primary pull-right" id="pay_now_btn">Pay</button>
                    </div>
                </form>
            </body>
        </html>'
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0">
    </iframe>

<br><br><br><br><br> -->


 <!-- standAloneVTermSnippet -: {{standAloneVTermSnippet}} -->