export const TYPES = {
    comp: {
        vendorComp: 'Vendor Company',
        varComp: 'Var Company',
        supportComp: 'Support Company',
    },
    biz: {
        regularBiz: 'Regular Business',
        companyBiz: 'Company Business',
        vendorBiz: 'Vendor Business',
    },
    sto: {
        regularStore: 'Regular Store',
        companyBizStore: 'Company Store',
    },
    stru: {
        chainTags: 'Chain',
        regionTags: 'Region',
        propertyTags: 'Property',
    },
    pctr: {
        regularPCtr: 'Regular Profit Center',
    },
    meracct: {
        regularMerAcct: 'Regular Merchant Account',
    },
    terminal: {
        regularTerminal: 'Regular Terminal',
    },
    peri: {
        regularPeripheral: 'Regular Peripheral',
    },
    emp: {
        regularEmp: 'Regular Employee',
    },
    rep: {
        vendorRep: 'Vendor Rep',
        varRep: 'Var Rep',
        supportRep: 'Support Rep',
    },
    cust: {
        regularCust: 'Regular Customer',
        businessCust: 'Business Customer',
        companyCust: 'Company Customer',
    },
    role: {
        regularRole: 'Regular Role',
        vendorRole: 'Vendor Role',
        repRole: 'Representative Role',
        empRole: 'Employee Role',
        custRole: 'Customer Role',
    },
    user: {
        regularUser: 'RU',
        adminUser: 'AU',
        guestUser: 'GU',
    },
    safe: {
        regSafe: 'RS',
    },
    txn: {
        regularTransaction: 'Regular Transaction',
    },
    txnActionItem: {
        inquiry: 'Inquiry',
        auth: 'Auth',
        sale: 'Sale',
        return: 'Refund',
        verify: 'Verify'
    },
    batch: {
        regularBatch: 'Regular Batch',
    },
    dataSource: {
        regularDataSrc: 'Regular Data Source',
    },
    dataSrcItem: {
        self: 'Self',
        external: 'External',
    },
    brand: {
        regularBrand: 'Regular Brand',
        genericBrand: 'Generic Brand',
    },
    brandItemTags: {
        // brandItem: 'brand',
        cash: 'Cash',
        creditCard: 'Generic Credit Card',
        visa: 'Visa',
        masterCard: 'Master Card',
        americanExpress: 'American Express',
        discover: 'Discover',
        dinersClub: 'Diners Club',
        carteBlanche: 'Carte Blanche',
        enRoute: 'EnRoute',
        jcb: 'JCB',
        jal: 'JAL',
        cup: 'CUP',
        genericDebitCard: 'Generic Debit Card',
        maestroCard: 'Maestro Card',
        genericGiftCard: 'Generic Gift Card',
        genericPrepaidCard: 'Generic Prepaid Card',
        genericLoyaltyCard: 'Generic Loyalty Card',
        genericEBTCard: 'Generic EBT Card',
        foodStampCard: 'Food Stamp Card',
        cashBenefitCard: 'Cash Benefit Card',
        genericElectronicCheck: 'Generic Electronic Check',
    },
    brandItemTagsSC: {
        // brandItem: 'brand',
        cash: 'CS',
        genericCreditCard: 'CC',
        visa: 'VS',
        masterCard: 'MC',
        americanExpress: 'AX',
        discover: 'DS',
        dinersClub: 'DN',
        carteBlanche: 'CB',
        enRoute: 'EN',
        jcb: 'JC',
        jal: 'JA',
        cup: 'CC',
        genericDebitCard: 'MA',
        maestroCard: 'DC',
        genericGiftCard: 'GC',
        genericPrepaidCard: 'PC',
        genericLoyaltyCard: 'LC',
        genericEBTCard: 'EB',
        foodStampCard: 'FS',
        cashBenefitCard: 'CB',
        genericElectronicCheck: 'EC',
    },
    mdm: {
        regularMedium: 'Regular Medium',
    },
    mdmItemTags: {
        cash:       'Cash',
        creditCard: 'Credit Card',
        debitCard: 'Debit Card',
        giftCard: 'Gift Card',
        prepaidCard: 'Prepaid Card',
        loyaltyCard: 'Loyalty Card',
        ebtCard: 'Ebt Card',
        electronicCheck: 'Electronic Check',
    },
    mdmItemTagsSC: {
        cash: 'CS',
        creditCard: 'CC',
        debitCard: 'DC',
        giftCard: 'GC',
        prepaidCard: 'PC',
        loyaltyCard: 'LC',
        ebtCard: 'EB',
        electronicCheck: 'EC',
    },
    acctEntTag: {
        regularAccountEntry: 'acctenttype.reg',
    },
    acctEntItemTags: {
        // acctEntItem: 'acctent',
        none: 'None',
        manual: 'Manual',
        swipe: 'Swipe',
        emvMan: 'EMV Manual',
        emvSwipe: 'EMV Swipe',
        emvCles: 'EMV Cles',
        emvcontact: 'EMV Contact',
        msr: 'MSR',
        msrTrack1: 'MSR Track 1',
        msrTrack2: 'MSR Track 2',
        msrTrack3: 'MSR Track 3',
        icc: 'ICC',
        contactless: 'Contact Less',
        micr: 'MICR',
        applePay:   'ApplePay',
        googlePay:   'GooglePay',
    },
    acctEntItemTagsSC: {
        // acctEntItem: 'acctent',
        none: 'N',
        manual: 'M',
        swipe: 'S',
        emvMan: 'EM',
        emvSwipe: 'ES',
        emvCles: 'EL',
        emvcontact: 'EC',
        msr: 'S',
        msrTrack1: 'S1',
        msrTrack2: 'S2',
        msrTrack3: 'S3',
        icc: 'I',
        contactless: 'L',
        micr: 'C',
        applePay:   'AP',
        googlePay:   'GP',
    },
    captureTag: {
        regularCapture: 'Regular Capture',
    },
    captureItemTags: {
        // captureItem: 'capt',
        hostCapture: 'Host Capture',
        terminalCapture: 'Terminal Capture',
    },
    processorTags: {
        regularProcessor: 'proctype.reg',
    },
    procCategoryTags: {
        worldpay: 'World Pay',
        hps: 'HPS',
        usat: 'USAT',
        tsys: 'TSYS',
        elavon: 'Elavon',
        globalpayments: 'Global Payments',
        paymentech: 'Payment Ech',
        rapidconnect: 'Rapid Connect',
        vantiv: 'Vantiv',
    },
};
