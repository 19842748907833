<div class="suspend-notice">
	<h2 class="heading">Your Company has been Suspended!</h2>
	<div class="payNow" *ngIf="isDealer">
		<p class="para">Click below to pay the due amount of ${{ amount }} to Cloud9</p>
		<div class="btns">
			<button class="btn btn-pay" (click)="onPayClick()" [disabled]="loading">
				<span *ngIf="!loading">
					Pay Now
				</span>
				<span *ngIf="loading"
					>Please wait...
					<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
				</span>
			</button>
			<button class="btn btn-logout" (click)="logout()">Logout</button>
		</div>
	</div>
	<div class="payNow" *ngIf="!isDealer">
		<p class="para">Please contact your admin to get access!</p>
		<button class="btn btn-logout" (click)="logout()">Logout</button>
	</div>
	<div class="alert alert-warning mx-auto text-center">
		Do not refresh the page
	</div>
</div>
