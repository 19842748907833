<div [formGroup]=generalContactForm>
    <div class="card" formGroupName='xSocial'>
        <div class="card-header">
            <label class="">Social</label>
        </div>
        <div class="card-body">
            <div class="form-group row">
                <div class="col-md-12 col-sm-12">
                    <div class="input-group">
                        <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-desktop fa-fw" aria-hidden="true"></i></span>
                        <input type="text" class="form-control form-control-sm" id="website" formControlName="xWebsite" placeholder="Website" [ngClass]="{'has-error': formErrors.xSocial.xWebsite}" validateOnBlur>
                    </div>
                    <div class="pull-left">
                        <small *ngIf="formErrors.xSocial.xWebsite" class="inline-error"> <i class="fa fa-exclamation-triangle inline-error" tooltip="Website is required" placement="top" container="body" containerClass="tooltip-custom inline-error" aria-hidden="true"></i></small>
                    </div>
                </div>
            </div>
            <!--<div class="form-group row">
                <div class="col-md-12 col-sm-12">
                    <div class="input-group">
                        <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-mobile fa-fw" aria-hidden="true"></i></span>
                        <!--<input type="text" class="form-control form-control-sm" id="phone" formControlName="xPhone" [textMask]="{mask: mask}" [ngClass]="{'has-error': formErrors.xSocial.xPhone}"
                            validateOnBlur>-->
            <!--<input type="text" class="form-control form-control-sm" id="phone" placeholder="+1-123-456-7899" formControlName="xPhone"
                (keypress)="onKeypress($event)" (paste)="onPaste($event)" [ngClass]="{'has-error': formErrors.xSocial.xPhone}"
                validateOnBlur>
        </div>
        <div class="pull-left">
            <small *ngIf="formErrors.xSocial.xPhone" class="inline-error"> {{ formErrors.xSocial.xPhone }}</small>
        </div>
    </div>
</div>-->
            <div class="form-group row">
                <div class="col-md-12 col-sm-12">
                    <div class="input-group">
                        <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-facebook fa-fw" aria-hidden="true"></i></span>
                        <input type="text" class="form-control form-control-sm" id="facebook_email" formControlName="xFacebook" placeholder="Facebook" [ngClass]="{'has-error': formErrors.xSocial.xFacebook}" validateOnBlur>
                    </div>
                    <div class="pull-left">
                        <small *ngIf="formErrors.xSocial.xFacebook" class="inline-error"><i class="fa fa-exclamation-triangle inline-error" tooltip="Facebook is required" placement="top" container="body" containerClass="tooltip-custom inline-error" aria-hidden="true"></i></small>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-12 col-sm-12">
                    <div class="input-group">
                        <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-twitter fa-fw" aria-hidden="true"></i></span>
                        <input type="text" class="form-control form-control-sm" id="witter" formControlName="xTwitter" placeholder="Twitter" [ngClass]="{'has-error': formErrors.xSocial.xTwitter}" validateOnBlur>
                    </div>
                    <div class="pull-left">
                        <small *ngIf="formErrors.xSocial.xTwitter" class="inline-error"> <i class="fa fa-exclamation-triangle inline-error" tooltip="Twitter is required" placement="top" container="body" containerClass="tooltip-custom inline-error" aria-hidden="true"></i></small>
                    </div>
                </div>
            </div>
            <div class="form-group row no-margin">
                <div class="col-md-12 col-sm-12">
                    <div class="input-group">
                        <span class="input-group-addon" id="sizing-addon1"><i class="fa fa-linkedin
                        fa-fw" aria-hidden="true"></i></span>
                        <input type="text" class="form-control form-control-sm" id="person_email" formControlName="xLinkedIn" placeholder="Linkedin" [ngClass]="{'has-error': formErrors.xSocial.xLinkedIn}" validateOnBlur>
                    </div>
                    <div class="pull-left">
                        <small *ngIf="formErrors.xSocial.xLinkedIn" class="inline-error"> <i class="fa fa-exclamation-triangle inline-error" tooltip="Linkedin is required" placement="top" container="body" containerClass="tooltip-custom inline-error" aria-hidden="true"></i></small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>