import { Injectable } from '@angular/core';
import { validateFor } from 'app/common-functions';
import { API_END_POINT } from 'app/constants';
import { HttpService } from './http-service';
import { LoginStoreService } from './login-store.service';
import { NotificationService } from './notification-service';
const CUSTOMER_LINK = '/customer/login';
const PARAM_STRING_KEY = '?param=';
const BE_DATA_KEY = 'data';
const VTERM_TOKEN_KEY = 'vTermToken';
const TERMINAL_NUMBER_ENCRYPTION_FAILED_MSG = 'Failed to encrypt Terminal Number. Please try again.';
const TERMINAL_NUMBER_MALFORMED_MSG = 'Terminal Number has been malformed. Routing to Dealer Login page.'
const ERROR_VALUE = 'Error';

@Injectable({
	providedIn: 'root',
})
export class GenerateCustomerLinkService {
	constructor(
		private httpService: HttpService,
		private notificationService: NotificationService,
		private loginStoreService: LoginStoreService
	) {}

	getTerminalNumbers() {
		let terminalNumbersObj =
			this.loginStoreService.terminalNumbersObj && Object.keys(this.loginStoreService.terminalNumbersObj).length
				? this.loginStoreService.terminalNumbersObj
				: {};
		return terminalNumbersObj;
	}

	/**
	 * Generate customer link for logout
	 * @param  `terminalNumberObj`: object: Terminal numbers object with cc/ach terminal number
	 * @return  `customerLink`: string: Customer link with token
	 */
	async generateCustomerLink() {
		let customerLink = CUSTOMER_LINK;
		let token = '';
		return new Promise(async (resolve, reject) => {
			try {
				const terminalNumberObj = this.getTerminalNumbers();
				console.log('generateCustomerLink: terminalNum', terminalNumberObj);
				if(!Object.keys(terminalNumberObj).length){
					this.notificationService.error(TERMINAL_NUMBER_MALFORMED_MSG, ERROR_VALUE);
					return reject(false);
				}
				token = await this.encryptTerminalNumber(terminalNumberObj);
				customerLink = customerLink + PARAM_STRING_KEY + token;
				console.log('customer link:top-nav: logout--->', customerLink);
				return resolve(customerLink);
			} catch (error) {
				this.notificationService.error(TERMINAL_NUMBER_ENCRYPTION_FAILED_MSG, ERROR_VALUE);
				return reject(false);
			}
		});
	}

	/**
	 * Encrypt terminal numbers
	 * @param `terminalNumbersObj` : object: cc or ach terminal numbers
	 * @returns `token`: string
	 */
	encryptTerminalNumber(terminalNumbersObj: any = {}): Promise<string> {
		return new Promise((resolve, reject) => {
			let param = {
				terminalNumbers: terminalNumbersObj,
			};
			this.httpService.store(API_END_POINT.vtermEncrypt, param).subscribe(
				(encryptionResponse) => {
					console.log('encryptionResponse: topnav', encryptionResponse);
					let token =
						validateFor(BE_DATA_KEY, encryptionResponse) && validateFor(VTERM_TOKEN_KEY, encryptionResponse[BE_DATA_KEY])
							? encryptionResponse[BE_DATA_KEY][VTERM_TOKEN_KEY]
							: '';
					if (!token) {
						return reject(false);
					}
					return resolve(token);
				},
				(encryptionError) => {
					console.log('encryptionError: topnav', encryptionError);
					return reject(encryptionError);
				}
			);
		});
	}
}
