import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MemberAccountStore } from 'app/store/member-account-store';
import { ActivatedRoute, Router } from '@angular/router';
import {
	BusinessService,
	TreeService,
	BreadcrumbService,
	UrlStoreService,
	NotificationService,
	TabStructureService,
	HttpService,
	UserHandlerService
} from 'app/shared/services';
import { SetupBillingCustomerStore, SetupBusinessStructureStoreSetupStore, SetupStructureProfitCenterStore } from 'app/store';
import { XPCSAFES } from '../../../constants';
import { takeWhile, skip } from 'rxjs/operators';
import { validateFor } from '../../../common-functions';
// import { MEDIUM_TAGS_MAPPING } from 'app/constants/mapping.constants';

// const storeAndSafeEndpoint = 'storeSafes';
// const ADD_MODE = 'add';
// const EDIT_MODE = 'edit';
const VIEW_MODE = 'view';
const MEMBER_ACCOUNT_PARENT_MODE_STORE = 'Store';
const MEMBER_ACCOUNT_PARENT_MODE_CUSTOMER = 'Customer';
const MODAL_MODE_ADD = 'add';
const MODAL_MODE_EDIT = 'edit';
const MODAL_MODE_MODAL = 'modal';
const GRID_ACTION_EDIT = {
	type: MODAL_MODE_EDIT,
	visible: true,
	text: 'Edit',
	icon: 'fa fa-pencil-square-o',
	action: 'emit'
};
let GRID_ACTION_DELETE = {
	type: 'delete',
	visible: true,
	text: 'Delete',
	icon: 'fa fa-trash-o',
	action: 'func',
	onClick: 'destroy',
	disabled: false
};
const GRID_ACTION_VIEW = {
	type: VIEW_MODE,
	visible: true,
	text: 'Details',
	icon: 'fa fa-eye',
	action: 'emit'
};
let GRID_ACTION_PAY_NOW = {
	type: MODAL_MODE_MODAL,
	visible: true,
	text: 'Pay Now',
	value: 'pay-now',
	icon: 'fa fa-credit-card',
	action: 'emit',
	disabled: false
};
const UNABLE_TO_RESOLVE_ACTION_MSG = 'Unable to resolve the selected action.';
const DECRYPT_ENDPOINT = 'vTerm/decrypt';
const BUSINESS = 'business';
const BE_ERROR_KEY: string = 'error';
const PAYLOAD_PAYMENT_INFO_KEY = 'paymentInfo';
const REQUEST_PAYLOAD_KEY: string = 'requestPayload';
const PAYMENT_MODE_KEY: string = 'paymentMode';
const PAYMENT_MODE = {
	payNow: 'payNow',
	createPayment: 'createPayment'
}
// const MEDIUM_CARD = 'Credit Card';
// const MEDIUM_BANK = 'Bank';
// const NO_CUSTOMER_FOUND_MSG = 'Could Not Fetch Customer Details.';

@Component({
	selector: 'app-member-accounts',
	templateUrl: './member-accounts.component.html',
	styleUrls: ['./member-accounts.component.scss']
})
export class MemberAccountsComponent implements OnInit, AfterViewInit, OnDestroy {
	public member_account_parentID: string = ''; //customerID or StoreID
	// private setCustomerBreadcrumb: boolean = false; //If navigated from customer-edit page
	// private setStoreBreadcrumb: boolean = false; //If navigated from store-edit page
	public storeObject: any = {};
	public storeValue: string = '';
	public fetchByStore: boolean = true;
	checkValue: boolean = false;
	public refresh: boolean = false;

	//Add/edit Modal
	public visible: boolean = false; //to open/close add/edit member-account modal
	public showPayNowModal: boolean = false; // to open/close pay-now modal
	public mode: string = ''; //to set add/edit mode
	public memberAccountID: string = '';
	public customerSignupStoreID: string = ''; //to get pctr count if parent mode is customer
	public storeID: string = ''; //to get pctr count if parent mode is store
	public member_account_parentMode: string = ''; //To pass parent mode into add/edit modal
	public parentName: string = ''; //To show name of customer/store
	//For details component
	public selectedID: string = ''; //ID to send to details component
	public showDetails: boolean = false; //Open/Hide details component

	//Grid variables
	public action: any[] = [];
	public attributes: any[] = [];
	public parameters: any = {};
	public finalViewDetails: any[] = [];
	public basicViewDetails: any[] = [
		{
			header: 'Account Token',
			mapTo: 'maskedAcctToken'
		},
		{
			header: 'Name',
			mapTo: 'xName'
		},
		{ header: 'Brand', mapTo: 'brand' },
		{ header: 'Medium', mapTo: 'medium' },
		{ header: 'Account Number', mapTo: 'acctNum' }
		// { header: 'Routing Number', mapTo: 'xRtgNum' },
		// { header: 'Expiry Date', mapTo: 'xExpDate' }
	];

	public CC_viewDetails = [{ header: 'Expiry Date', mapTo: 'xExpDate' }]; //Will be concatenated with basicViewDetails if payment-type is CARD.
	public BANK_viewDetails = [{ header: 'Routing Number', mapTo: 'xRtgNum' }]; //Will be concatenated with basicViewDetails if payment-type is BANK.

	//Breadcrumb variables
	// public showCustomerName: boolean = false; //Show/Hide customer name
	// public showStoreName: boolean = false; //Show/Hide store name
	public isPaymentAccountEdit: boolean = false; //If navigated from customer-edit page, set to true
	public isDepositAccountEdit: boolean = false; //If navigated from store-edit page, set to true
	public storeName: string = '';
	public customerName: string = '';

	// public showParentModeLoader: boolean = false;
	public showParentMode: boolean = false;

	// token response modal
	public tokenData: any = {}; // contains token data
	public showTokenModal: boolean = false; // controls if token modal is to be shown

	public isBusinessIDset: boolean = false; // controls if components to be rendered on the basis of businessID being present
	public alive: boolean = true;

	public isFirstBizSet: boolean = false;

	public canDelete = true;//boolean controller to enable/disable delete btn based on user permission
	public canPayNow = true;//boolean controller to enable/disable pay-now btn based on user permission

	constructor(
		private route: ActivatedRoute,
		private memberAccountStore: MemberAccountStore,
		private businessService: BusinessService,
		private treeService: TreeService,
		private breadcrumbService: BreadcrumbService,
		private setupBillingcustomerStore: SetupBillingCustomerStore,
		private urlService: UrlStoreService,
		private notificatonService: NotificationService,
		private setupBusinessStructureStoreSetupStore: SetupBusinessStructureStoreSetupStore,
		private tabStructureService: TabStructureService,
		private setupStructureProfitCenterStore: SetupStructureProfitCenterStore,
		private httpService: HttpService,
		private router: Router,
		private userHandlerSerivce: UserHandlerService
	) {}

	ngOnInit() {
		// console.log('member-acct-init:businessID',this.businessService.businessID.getValue());
		this.setupPerms();
		this.checkIfBizIDSet();
		let tokenObj = this.doesUrlHaveToken();
		if (tokenObj.token) {
			this.processToken(tokenObj.token);
			this.await_getParent(this.getCustomer, tokenObj.customerID, true);
		} else {
			this.isBusinessIDset = true;
			let previousUrl = this.getPreviousUrl();
			this.setComponentBaseParams(previousUrl);
			// this.setGridParams();
		}
		this.setGridParams();
		this.businessService.watchBusinessID$
			.pipe(
				takeWhile(() => this.alive),
				skip(1)
			)
			.subscribe(bizID => {
				console.log('showTokenModal: watcher', this.showTokenModal);
				console.log('isFirstBizSet: watcher', this.isFirstBizSet);
				if (!this.showTokenModal && this.isFirstBizSet && !this.userHandlerSerivce.userRoles.isBusinessEmployee) {
					this.isFirstBizSet = false;
					this.router.navigate(['/dashboard/home']);
				}
			});
	}

	/**
	* check if bizID set in businessService and set isFirstBizSet status
	*/
	checkIfBizIDSet(){
		console.log('checkIfBizIDSet:bizID ', this.businessService.businessID.getValue())
		if(this.businessService.businessID.getValue()){
			this.isFirstBizSet = true;
		} else{
			this.isFirstBizSet = false;
		}
	}

	/**
	 * check if url has token
	 *
	 * @return : `resultObj` : object : contains fields : hasToken:boolean, customerID:string,token:string
	 */
	doesUrlHaveToken() {
		let resultObj = { token: '', customerID: '' };
		let url = document.location.href;
		url = decodeURI(url);
		console.log('doesUrlHaveToken: url', url);
		let custIDIndex = url.indexOf(';custID');
		let tokenIndex = url.indexOf(';token');
		console.log('doesUrlHaveToken: custIDIndex', custIDIndex);
		console.log('doesUrlHaveToken: tokenIndex', tokenIndex);
		resultObj['customerID'] = tokenIndex < 0 ? url.substring(custIDIndex + 8) : url.substring(custIDIndex + 8, tokenIndex);
		resultObj['token'] = tokenIndex < 0 ? '' : url.substring(tokenIndex + 7);
		console.log('doesUrlHaveToken: resultObj', resultObj);
		return resultObj;
	}

	/**
	 * decrypt token, process token response
	 * @params : `token` : string : token that is to be processed
	 */
	processToken(token) {
		console.log('processToken: token', token);
		let params = { vTermToken: token };
		this.httpService.store(DECRYPT_ENDPOINT, params).subscribe(
			decryptResponse => {
				console.log('processToken:decryptResponse', decryptResponse);
				let decryptData = validateFor('data', decryptResponse) ? decryptResponse.data : {};
				this.tokenData = decryptData;
				let requestPayload = validateFor(REQUEST_PAYLOAD_KEY, this.tokenData)
					? this.tokenData[REQUEST_PAYLOAD_KEY]: {};
				let paymentMode = validateFor(PAYMENT_MODE_KEY, requestPayload) 
					? requestPayload[PAYMENT_MODE_KEY]: {};
				switch (paymentMode) {
					case PAYMENT_MODE.payNow:
						this.processPaymentToken(decryptData);
						break;
					case PAYMENT_MODE.createPayment:
						this.processCreatePaymentToken(requestPayload);
						break;
					default:
						this.getBizIDFromDecryptedData(decryptData);
						break;
				}
				this.setGridParams();
			},
			decryptError => {
				console.log('processToken:decryptError', decryptError);
			}
		);
	}

	/**
	 * Set bizID from request payload of payment method creation response
	 * @param {object} requestPayload
	*/
	processCreatePaymentToken(requestPayload){
		this.showTokenModal = true;
		console.log('processCreatePaymentToken: requestPayload', requestPayload);
		let bizID = validateFor(XPCSAFES.xBusinessID, requestPayload) 
			? requestPayload[XPCSAFES.xBusinessID]
			: '';
		this.setBusinessFields(bizID);
	}

	/**
	 * Set buisiness id and related fields
	 * @param {mongoID} bizID businessID
	*/
	setBusinessFields(bizID: string){
		this.businessService.setBusinessID(bizID);
		this.treeService.setId(bizID, BUSINESS);
		this.isBusinessIDset = true;
		this.isFirstBizSet = true;
	}

	/**
	 * Get webApp Info and businessIDs from token data
	 * @param {object} decryptData
	*/
	getBizIDFromDecryptedData(decryptData){
		let webAppInfo = validateFor('webAppInfo', decryptData) ? decryptData.webAppInfo : {};
		let businessID = validateFor('bizID', webAppInfo) ? webAppInfo.bizID : '';
		this.setBusinessFields(businessID);
	}

	/**
	 * Set showTokenModal tp show success/fail response. 
	 * Pass decryptData to getBizIDFromDecryptedData.
	 * @param { object} decryptData
	 */
	processPaymentToken(decryptData: any){
		if(!validateFor(PAYLOAD_PAYMENT_INFO_KEY, decryptData)){
			//Safe creation fail case handling.
			this.showTokenModal = validateFor(BE_ERROR_KEY, decryptData) ? decryptData[BE_ERROR_KEY]: false;
		} else {
			this.showTokenModal = Object.keys(decryptData.paymentInfo).length ? true : false;
		}
		console.log('processPaymentToken: decryptData', decryptData);
		this.getBizIDFromDecryptedData(decryptData);
	}

	async setComponentBaseParams(previousUrl) {
		let setCustomerBreadcrumb = false;
		let setStoreBreadcrumb = false;
		//to check if member-accounts is opened from customer add-edit page
		if (previousUrl.indexOf('customer/add-edit/') !== -1) {
			// console.log('has add-edit');
			let customerID = previousUrl.substring(previousUrl.lastIndexOf('/') + 1);
			// console.log(customerID, 'customerID: memberAccounts');
			console.log(!/^[a-zA-Z]+$/.test(customerID), 'ngOnInit: has customerID');
			if (!/^[a-zA-Z]+$/.test(customerID)) {
				//to verify that ID is in proper format
				let shouldSetBreadCrumb = true;
				this.await_getParent(this.getCustomer, customerID, shouldSetBreadCrumb);
				// await this.getCustomer(customerID, shouldSetBreadCrumb);
			}
		} //get store if member-accounts is opened from store add-edit page
		else if (!previousUrl.endsWith('/store') && !previousUrl.endsWith('/add-edit') && !previousUrl.endsWith('/customer')) {
			let storeID = previousUrl.substring(previousUrl.lastIndexOf('/') + 1);
			// console.log(storeID, 'storeID: memberAccounts');
			// console.log(!/^[a-zA-Z]+$/.test(storeID), 'ngOnInit: has storeID');
			if (!/^[a-zA-Z]+$/.test(storeID)) {
				// await this.getStore(storeID, true);
				let shouldSetBreadCrumb = true;
				this.await_getParent(this.getStore, storeID, shouldSetBreadCrumb);
			}
		} //if store is saved in add mode through 'manage deposit accounts' button
		else if (
			previousUrl.endsWith('/add-edit') &&
			this.route.snapshot.params.hasOwnProperty('storeID') &&
			this.route.snapshot.params['storeID'].length
		) {
			console.log('store id: from add mode', this.route.snapshot.params.hasOwnProperty('storeID'));
			this.member_account_parentID = this.route.snapshot.params['storeID'];
			// await this.getStore(this.member_account_parentID, true);
			let shouldSetBreadCrumb = true;
			this.await_getParent(this.getStore, this.member_account_parentID, shouldSetBreadCrumb);
		} //if customer is saved in add mode through 'manage payment accounts' button
		else if (
			previousUrl.endsWith('/add-edit') &&
			this.route.snapshot.params.hasOwnProperty('custID') &&
			this.route.snapshot.params['custID'].length
		) {
			console.log('custID : from add mode', this.route.snapshot.params.hasOwnProperty('custID'));
			this.member_account_parentID = this.route.snapshot.params['custID'];
			// await this.getCustomer(this.member_account_parentID, true);
			let shouldSetBreadCrumb = true;
			this.await_getParent(this.getCustomer, this.member_account_parentID, shouldSetBreadCrumb);
		} //if member-accounts is opened through grid of customer
		else if (previousUrl.endsWith('/customer') && !previousUrl.includes('/add-edit')) {
			this.showParentMode = true;
			setCustomerBreadcrumb = true;
			// this.showCustomerName = true;
			let custID = this.route.snapshot.params['custID'];
			let shouldSetBreadCrumb = false;
			// await this.getCustomer(custID, false);
			console.log('Yes from here ');
			this.await_getParent(this.getCustomer, custID, shouldSetBreadCrumb);
			console.log(setCustomerBreadcrumb, 'setCustomerBreadcrumb: onInit: memberAccounts');
			this.setBreadCrumbValue(null, setCustomerBreadcrumb, setStoreBreadcrumb);
		} //if member-accounts is opened through grid of stores
		else if (previousUrl.endsWith('/store') && !previousUrl.includes(`/structure/store/${this.member_account_parentID}`)) {
			// this.showStoreName = true;
			this.showParentMode = true;
			setStoreBreadcrumb = true;
			let storeID = this.route.snapshot.params['storeID'];
			console.log(setStoreBreadcrumb, 'setStoreBreadcrumb: onInit: memberAccounts');
			// await this.getStore(storeID, false);
			let shouldSetBreadCrumb = false;
			this.await_getParent(this.getStore, storeID, shouldSetBreadCrumb);
			this.setBreadCrumbValue(null, setCustomerBreadcrumb, setStoreBreadcrumb);
		}
	}

	//Get and set parent mode and name by invoking callback getStore/getCustomer. Wait to get pctr-count from storeID/signupStoreID
	//Paramters: function(getStore/getCustomer), objectID(storeID/customerID), boolean(should set or not set breadcrumb with parent-name).
	async await_getParent(callback: any, parentID: string, setBreadCrumb: boolean) {
		console.log('awaitGetParent', 'callback--->', callback, 'parentID--->', parentID, 'setBreadCrumb--->', setBreadCrumb);
		try {
			await callback(parentID, setBreadCrumb);
		} catch (err) {
			console.log('error is from catch awaitGetParent ');
			this.notificatonService.error(err, 'Error');
		}
	}

	//returns previous url
	getPreviousUrl(): string {
		// console.log(this.urlService.previousURL, 'previour url: ngOnInit');
		return this.urlService.previousURL;
	}

	//Hide and disable tree, hide tab
	ngAfterViewInit() {
		// this.tabStructureService.setHideTab(true);
		Promise.resolve(null).then(() => {
			this.tabStructureService.setHideTab(true);
			this.treeService.setEnable(false);
		});
	}

	// onCheckValue() {
	// 	this.checkValue = !this.checkValue;
	// }

	//Set grid params
	setGridParams(): void {
		if (this.businessService.businessID.getValue()) {
			this.parameters = {
				xBusinessID: this.businessService.businessID.getValue()
			};
		}
		if (this.route.snapshot.params.hasOwnProperty('custID')) {
			this.member_account_parentMode = MEMBER_ACCOUNT_PARENT_MODE_CUSTOMER;
			this.member_account_parentID = this.route.snapshot.params['custID'];
			console.log(this.member_account_parentID, 'customerID: onInit');
			this.parameters = Object.assign(this.parameters, { xCustomerID: this.member_account_parentID });
			this.setGridProperties();
		} else if (this.route.snapshot.params.hasOwnProperty('storeID')) {
			// console.log('inside else if: has storeID');
			console.log('store id: onInit: member-accounts', this.route.snapshot.params['storeID']);
			this.member_account_parentMode = MEMBER_ACCOUNT_PARENT_MODE_STORE;
			this.member_account_parentID = this.route.snapshot.params['storeID'];
			this.parameters = Object.assign(this.parameters, { xStoreID: this.member_account_parentID });
			this.setGridProperties();
		}
		console.log('setGridParams: params', this.parameters);
	}

	//Set properties required to form member-accouns grid
	setGridProperties(): void {
		this.storeObject = this.memberAccountStore;
		// console.log(this.storeObject, 'store obj:setGrid');
		this.storeValue = 'memberAccountStore';

		this.attributes = [
			{
				header: 'Account Token',
				mapTo: 'maskedAcctToken',
				width: '20%'
			},
			{
				header: 'Name',
				mapTo: 'xName',
				width: '15%'
			},
			{ header: 'Brand', mapTo: 'brand', width: '15%' },
			{ header: 'Medium', mapTo: 'medium', width: '10%' },
			{ header: 'Expiry Date', mapTo: 'xExpDate', width: '13%' },
			{ header: 'Account Number', mapTo: 'acctNum', width: '15%' },
			{ header: 'Actions', mapTo: '', width: '12%' }
		];

		let actionsArr = [];
		let disableDelete = {
			disabled: !this.canDelete
		} 
		GRID_ACTION_DELETE = Object.assign({},GRID_ACTION_DELETE, disableDelete);
		if (this.member_account_parentMode === MEMBER_ACCOUNT_PARENT_MODE_CUSTOMER) {
			let disablePayNow = {
				disabled: !this.canPayNow
			} 
			GRID_ACTION_PAY_NOW = Object.assign({},GRID_ACTION_PAY_NOW, disablePayNow);
			actionsArr.push(GRID_ACTION_PAY_NOW);
		}
		actionsArr.push(GRID_ACTION_EDIT);
		actionsArr.push(GRID_ACTION_DELETE);
		actionsArr.push(GRID_ACTION_VIEW);

		this.action = actionsArr;
	}

	//Set mode to add and open add-payment modal
	async onAddNewPaymentmethodClick() {
		this.mode = MODAL_MODE_ADD;
		this.visible = true;
	}

	//Close add-payment modal
	onHideModal(event: any): void {
		this.visible = event['showModal'];
	}

	//Set parameters as recevied from add-payment modal
	//parameter: event from add-payment component
	receiveStateObj(event: any): void {
		console.log(event, 'receiveStateObj: member-accounts');
		console.log(this.refresh, 'refresh', event.refreshGrid, 'event refresh');
		if (this.refresh !== event['refreshGrid']) {
			this.refresh = !event['refreshGrid'];
			this.parameters = Object.assign({}, this.parameters, { refreshGrid: event['refreshGrid'] });
		}
		this.visible = event['showModal'];
		// this.refresh = false;
	}

	//Set breadcrumb value, include name of customer/store in breadcrumb if member-account is opened from setup mode of customer/store
	//parameters: string
	setBreadCrumbValue(name: string, setCustomerBreadcrumb?: boolean, setStoreBreadcrumb?: boolean): void {
		// console.log(name, 'breadCrumb: name');
		console.log('setCustomerBreadcrumb--->', setCustomerBreadcrumb, 'setStoreBreadcrumb--->', setStoreBreadcrumb);
		const breadCrumb: any = {};
		breadCrumb.root = 'Setup';
		// console.log(this.isDepositAccountEdit, 'is deposit account edit');
		// console.log(this.isPaymentAccountEdit, 'is payment account edit');

		if (this.isDepositAccountEdit) {
			breadCrumb.child = 'Structure';
			breadCrumb.subChild = 'Store';
			breadCrumb.grandChild = name;
			breadCrumb.grandChildLink = `/dashboard/setup/business/structure/store/${this.member_account_parentID}`;
			breadCrumb.subChildLink = 'setup/business/structure/store';
			this.setBreadCrumbService(breadCrumb);
		}
		if (this.isPaymentAccountEdit) {
			breadCrumb.child = 'Billing';
			breadCrumb.subChild = 'Customer';
			breadCrumb.subChildLink = 'setup/billing/customer';
			breadCrumb.grandChild = name;
			breadCrumb.grandChildLink = `/dashboard/setup/billing/customer/add-edit/${this.member_account_parentID}`;

			this.setBreadCrumbService(breadCrumb);
		} else if (!this.isDepositAccountEdit && !this.isPaymentAccountEdit) {
			// console.log('got in else too');
			if (setCustomerBreadcrumb) {
				breadCrumb.child = setCustomerBreadcrumb ? 'Billing' : null;
				breadCrumb.grandChild = setCustomerBreadcrumb ? 'Customer' : null;
				this.setBreadCrumbService(breadCrumb);
			} else if (setStoreBreadcrumb) {
				breadCrumb.child = setStoreBreadcrumb ? 'Structure' : null;
				breadCrumb.grandChild = setStoreBreadcrumb ? 'Store' : null;
				this.setBreadCrumbService(breadCrumb);
			}
		}
	}

	//Set breadcrumb service
	//parameter: breadcrumb object
	setBreadCrumbService(breadCrumb: any): void {
		breadCrumb.rootList = [];
		console.log('grand child before settting:setBreadCrumbService ', breadCrumb.grandChildLink);
		console.log('true or false', breadCrumb.hasOwnProperty('grandChildLink') && breadCrumb.grandChildLink.length < 1);
		if (!breadCrumb.hasOwnProperty('grandChildLink')) {
			breadCrumb.grandChildLink = this.urlService.previousURL;
		}
		console.log('bread crumb link: setBreadCrumbService: member-accounts', breadCrumb.grandChildLink);
		breadCrumb.greatGrandChild = 'Member Accounts';
		// console.log(JSON.parse(JSON.stringify(breadCrumb)), 'breadCrumb: setBreadCrumb: member-accounts');
		this.breadcrumbService.setBreadCrumb(breadCrumb);
	}

	//Set parameters for details-component
	//parameter: boolean
	viewClosed(visible: boolean): void {
		if (!visible) {
			// this.member_account_parentID = '';
			// this.memberAccountID = '';
			this.selectedID = '';
			this.showDetails = false;
		}
	}

	//Set mode(add/edit) from grid, and id for edit of member-account
	//parameters: object from grid
	onActionRequest(obj: any): void {
		console.log('onActionRequest: obj', obj);
		switch (obj.act.type) {
			case VIEW_MODE:
				this.set_finalViewDetails(obj);
				this.mode = VIEW_MODE;
				this.selectedID = obj.id;
				break;
			case MODAL_MODE_EDIT:
				this.mode = MODAL_MODE_EDIT;
				this.memberAccountID = obj.id;
				this.visible = true;
				break;
			case MODAL_MODE_MODAL:
				this.mode = MODAL_MODE_MODAL;
				this.memberAccountID = obj.id;
				this.showPayNowModal = true;
				break;
			default:
				console.log('onActionRequest: default case');
				this.notificatonService.error(UNABLE_TO_RESOLVE_ACTION_MSG, 'Error');
		}
	}

	//Set finalViewDetails fields based on medium tag
	//Parameter: obj(Data emitted from grid upon selecting a row)
	set_finalViewDetails(obj: any): void {
		console.log(obj, 'obj: onActionRequest: member-account');
		let memberAccountData = obj.data;
		let hasMedium = memberAccountData && memberAccountData.hasOwnProperty('xMdmTag') && memberAccountData.xMdmTag ? true : false;

		if (hasMedium) {
			console.log('got here: has medium');
			switch (memberAccountData.xMdmTag) {
				case 'mdm.ec':
					console.log('got here: medium bank');
					this.finalViewDetails = this.basicViewDetails.concat(this.BANK_viewDetails);
					break;
				case 'mdm.cc':
					console.log('got here: medium card');
					this.finalViewDetails = this.basicViewDetails.concat(this.CC_viewDetails);
					break;
				default:
					console.log('DEFAULT CASE');
			}
			console.log('view details: inside--->', this.finalViewDetails);
		}
	}

	//Fetch customer details and set name of member-account if member-account is opened from grid of customer/store
	//Parameters: id of customer and boolean
	getCustomer = (id: string, shouldSetBreadCrumb: boolean): Promise<any> => {
		console.log('getCustomer called', 'id--->', id, 'shouldSetBreadCrumb', shouldSetBreadCrumb);
		console.log('getCustomer: reference got -->', this);
		return new Promise((resolve, reject) => {
			console.log('getCustomer: Inside Promise');
			// this.showParentMode = true;
			this.setupBillingcustomerStore.get(id).subscribe(
				async customer => {
					console.log('what happenend bro?', customer);
					if (customer && customer.hasOwnProperty('data') && customer.data) {
						console.log(customer, 'customer: get: getcustomer');
						this.isPaymentAccountEdit = true;
						this.customerSignupStoreID =
							customer.data.hasOwnProperty('xSignupStoreID') && customer.data.xSignupStoreID
								? customer.data.xSignupStoreID
								: null;
						let customerName = customer.data.hasOwnProperty('xName') && customer.data.xName ? customer.data.xName : ' ';
						this.parentName = customerName;
						console.log(customerName, 'customer name: get: ngOnInit');
						if (shouldSetBreadCrumb) {
							// this.showCustomerName = false;
							this.setBreadCrumbValue(customerName);
						}
						// this.showParentMode = false;
						if (!this.customerSignupStoreID) {
							return;
						}
						console.log('has signup store id');
						try {
							let hasPCtr = await this.getPCtrCount(this.customerSignupStoreID);
							return resolve(hasPCtr);
						} catch (err) {
							console.log('NO PCTR CASE', err);
							// this.showParentMode = false;
							this.notificatonService.error(err, 'Error');
							return reject(err);
						}
					} else {
						console.log('else getCustomer ');
						// this.notificatonService.error(NO_CUSTOMER_FOUND_MSG, 'Error');
						return reject(null);
					}
				},
				err => {
					console.log('here in error get customer');
					// this.showParentMode = false;
					// this.notificatonService.error(err, 'Error');
					return reject(err);
				}
			);
		});
	};

	//Fetch store details and set name of member-account if member-account is opened from grid of customer/store
	//Parameters: id of store and boolean
	getStore = (id: any, shouldSetBreadCrumb: boolean): Promise<any> => {
		console.log('getStore called: store id---->', id, 'shouldSetBreadCrumb:---->', shouldSetBreadCrumb);
		return new Promise((resolve, reject) => {
			this.setupBusinessStructureStoreSetupStore.get(id).subscribe(
				async (store: any) => {
					this.isDepositAccountEdit = true;
					let storeData = store && store.hasOwnProperty('data') && store.data ? store.data : {};
					let storeName = storeData.hasOwnProperty('xName') && storeData.xName ? storeData.xName : null;
					console.log(storeName, 'store name: get: getStore');
					this.parentName = storeName;
					if (shouldSetBreadCrumb) {
						// this.showStoreName = false;
						this.setBreadCrumbValue(storeName);
					}
					// this.showParentMode = false;
					this.storeID = storeData.hasOwnProperty('_id') && storeData._id ? storeData._id : null;
					if (!this.storeID) {
						return;
					}
					try {
						let hasPCtr = await this.getPCtrCount(this.storeID);
						return resolve(hasPCtr);
					} catch (err) {
						console.log('NO PCTR CASE', err);
						// this.showParentMode = false;
						this.notificatonService.error(err, 'Error');
						return reject(err);
					}

					// this.handleSuccessResponse(response.data);
				},
				err => {
					// this.showParentMode = false;
					// this.notificatonService.error(err, 'could not fetch store details');
					return reject(err);
				}
			);
		});
	};

	//Get total number of pctrs. Allow add/edit member-account modal to open only if hasPCtr is true.
	//Paramter: string
	getPCtrCount(id: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.setupStructureProfitCenterStore.getAll({ xStoreID: id, limit: 0, skip: 0 }).subscribe(
				(pctrs: any) => {
					console.log('pctrs: onInit: member-accounts', pctrs);
					let meta = pctrs && pctrs.hasOwnProperty('meta') && pctrs.meta ? pctrs.meta : {};
					let hasPCtr = meta.hasOwnProperty('totalRecords') && meta.totalRecords > 0 ? true : false;
					console.log('has pctr:getPCtrCount---> ', hasPCtr);
					return resolve(hasPCtr);
				},
				err => {
					console.log('err: member-accounts', err);
					this.notificatonService.error(err, 'could not fetch pctr');
					return reject(null);
				}
			);
		});
	}

	//Open details component
	//Parameters: boolean
	onShowDetails(show: boolean) {
		// console.log('Show object: onShowDetails: add-payment', obj);
		console.log('show details: member-accounts', show);
		this.showDetails = show;
	}

	/**
	 * event handler for closeModalEvent for pay now modal
	 *
	 * @params : `event` : boolean : is paynow modal closed status
	 */
	payNowModalClosedEventHandler(event) {
		console.log('payNowModalClosedEventHandler: event', event);
		if (event) {
			this.showPayNowModal = false;
		}
	}

	/**
	 * event handler for closeModalEvent for token response modal
	 *
	 * @params : `event` : boolean : is token response modal closed status
	 */
	tokenResModalClosedEventHandler(event) {
		console.log('tokenResModalClosedEventHandler: event', event);
		if (event) {
			this.showTokenModal = false;
		}
	}

	setupPerms(){
		this.canDelete = this.userHandlerSerivce.userPerms.canDeleteSafe;
        this.canPayNow = this.userHandlerSerivce.userPerms.canPayNow;
		console.log('canPaynow', this.canPayNow);
	}

	ngOnDestroy() {
		this.alive = false;
		this.tabStructureService.setHideTab(false);
	}
}
