<section class="full-content-wrapper" [class.expand]="showTree">
    <div class="sidebar-tree-wrapper">
        <tree-cmp [addAction]=treeAddAction [buttonLinks]=treeButtonLinks [nodeLinks]=treeNodeLinks [businessInfo]=businessInfo (buttonClicked)="onButtonClick($event)"
            (nodeClicked)="onNodeClick($event)"></tree-cmp>
        <!--<a class="collapse-tree" (click)="toggleShowTree()"></a>-->
    </div>
    <div class="main-tabbed-content">
        <div class="inner-menu-wrap">
            <ul class="nav nav-justified inner-menu">
                <li class="nav-item" *ngFor="let item of tabItems">
                    <a class="nav-link" [routerLink]="[item.link]" [routerLinkActive]="['active']">{{item.label}}</a>
                </li>
            </ul>
        </div>
        <div class="inner-content">
            <div class="main-content-inner padder">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</section>