import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { MemberAccountsRoutingModule } from './member-accounts-routing.module';
import { MemberAccountsComponent } from './member-accounts.component';
import { GridModule } from '../grid/grid.module';
// import { GridComponent } from '../grid/grid.component';
import { AddPaymentComponent } from './add-payment/add-payment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SectionsModule } from '../sections/sections.module';
import { ResourceEditorModule } from '../index';
import { PayNowComponent } from './pay-now/pay-now.component';
import { TokenResponseComponent } from './token-response/token-response.component';
import { VtermTokenService } from './../../services/index';

@NgModule({
	imports: [
		CommonModule,
		ModalModule,
		FormsModule,
		GridModule,
		ReactiveFormsModule,
		DropdownModule,
		TooltipModule.forRoot(),
		TabsModule.forRoot(),
		ConfirmDialogModule,
		SectionsModule,
		ResourceEditorModule
	],
	declarations: [MemberAccountsComponent, AddPaymentComponent, PayNowComponent, TokenResponseComponent],
	providers: [VtermTokenService],
	exports: [MemberAccountsComponent]
})
export class MemberAccountsModule {}
