import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SidebarModule } from 'ng-sidebar'; 

import { TreeComponentModule, GridModule, ResourceEditorModule, SectionsModule } from '../../../../shared/modules';
import { NotificationService, GlobalValidator, XpaConstsService, HttpService } from '../../../../shared/services';
import { AccountStore } from '../../../../store';

import { SetupBillingCustomerRoutingModule } from './setup-billing-customer-routing.module';
import { SetupBillingCustomerComponent } from './setup-billing-customer.component';
import { AddEditComponent } from './components/add-edit/add-edit.component';
import { ViewComponent } from './components/view/view.component';
import { AccountHistoryListComponent } from './components/account-history-list/account-history-list.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { AccountHistoryDetailComponent } from './components/account-history-detail/account-history-detail.component';
// import { NgxAsideModule } from 'ngx-aside';
import { SetupBillingCustomerStore, ConstantStore } from '../../../../store';
import { TagInputModule } from 'ngx-chips';
import { MemberAccountsModule } from '../../../../shared/modules/member-accounts/member-accounts.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		TreeComponentModule,
		GridModule,
		ResourceEditorModule,
		ModalModule,
		SidebarModule.forRoot(),
		TabsModule.forRoot(),
		TooltipModule.forRoot(),
		SetupBillingCustomerRoutingModule,
		SectionsModule,
		// NgxAsideModule,
		TagInputModule,
		MemberAccountsModule
	],
	declarations: [
		SetupBillingCustomerComponent,
		AddEditComponent,
		ViewComponent,
		AccountHistoryListComponent,
		CustomerDetailsComponent,
		AccountHistoryDetailComponent
	],
	exports: [SetupBillingCustomerComponent],
	providers: [NotificationService, GlobalValidator, XpaConstsService, HttpService, AccountStore, SetupBillingCustomerStore, ConstantStore]
})
export class SetupBillingCustomerModule {}
