import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { validateFor } from 'app/common-functions';
import { TAGSCONSTS } from 'app/constants';
import {
	NO_CC_PREFIX_FLAG,
	NO_PREFIX_FLAG,
	setDataForTokenCreation,
	SETUP_ALTERED_MESSAGE,
	validatePgComFields,
	validateTerminalForMerchantCapabilities,
} from 'app/dashboard/common-functions/validate-txn-terminals';
import { VTermInitForm } from 'app/models';
import { BusinessService, HttpService, NotificationService, UserHandlerService } from 'app/shared/services';
import { Subscription } from 'rxjs';
import { CompanySuspendedService } from './company-suspended.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { VtermConfig } from 'app/shared/config/vTerm.config';

const DB_XROLES_KEY = 'xRoles';
const DB_XTAG_KEY = 'xTag';
const VTERM_INFO_ENDPOINT = 'vTerm_info';
const ENCRYPT_ENDPOINT = 'vTerm/encrypt';
const DECRYPT_ENDPOINT = 'vTerm/decrypt';
const BE_DATA_KEY = 'data';
const BE_VTERM_INFO_PCTR_REC_KEY = 'pCtrRecord';
const BE_VTERM_INFO_STORE_REC_KEY = 'storeRecord';
const BE_VTERM_INFO_TERMINAL_REC_KEY = 'terminalRecords';
const DB_XNAME_KEY = 'xName';
const BUSINESS_DATA_KEY = 'businessData';

const NO_CC_PREFIX_MESSAGE = '<p>Only Credit Card based transactions are Supported in Dealer Mode.</p>';
const NO_PREFIX_MESSAGE = `
	<ng-container class='setup-needed-swal'>
		<p> Please tell us how you want to use this terminal:
			<ul>
				<li>To setup terminal for <b>Credit Cards</b>: Add <b>[CC]</b> prefix to related merchant account name<br> (e.g. [CC]Processor1)</li>
				<li>To setup terminal for <b>ACH</b>: Add <b>[ACH]</b> prefix to related merchant account name<br> (e.g. [ACH]Processor2)</li>
			</ul>
			<b>Note:</b> Separate processor entries are needed for CC and ACH processing. 
		</p>
	</ng-container>
`;
const CANT_PROCEED_MSG: string = "Can't Proceed";
const SETUP_NEEDED_MSG: string = '<h5>SETUP NEEDED TO PROCEED!</h5>';
const SETUP_NEEDED_SWAL_CSS: string = 'setup-needed-swal';
const PERMISSION_UNAVAILABLE_MSG = `<p>You don't have permission for this action.</p>`;

const FETCH_TERMINAL_PCTR_FAIL_MSG = 'Unable to fetch terminal data.';

@Component({
	selector: 'app-company-suspended',
	templateUrl: './company-suspended.component.html',
	styleUrls: ['./company-suspended.component.scss'],
})
export class CompanySuspendedComponent implements OnInit {
	readonly VENDOR = 'vendor';
	readonly DEALER = 'dealer';
	readonly BUSINESS_EMPLOYEE = 'employee';
	readonly CUSTOMER = 'customer';

	role: string = '';
	amount: string = '0.00';
	terminalID: string = '';
	businessName: string = '';
	businessID: string = '';
	canPerformTxn = true;
	data: VTermInitForm;
	loading: boolean = false;

	isDealer: boolean = false;
	create_role:string = '';

	roleSub: Subscription;
	crete_roleSub: Subscription;
	amountSub: Subscription;
	vendorTerminalID: Subscription;
	customerBusinessName: Subscription;
	customerBusinessID: Subscription;

	VTERM_HOST = '';

	constructor(
		protected suspendedCompanyService: CompanySuspendedService,
		protected userHandlerService: UserHandlerService,
		protected router: Router,
		private httpService: HttpService,
		private businessService: BusinessService,
		private notificationService: NotificationService,
		private vtermConfig: VtermConfig
	) {
		this.VTERM_HOST = this.vtermConfig.VTERM_HOST;
	}

	ngOnInit(): void {
		this.crete_roleSub = this.suspendedCompanyService.create_role$.subscribe((create_role) => {
			this.create_role = create_role;
		});
		this.roleSub = this.suspendedCompanyService.role$.subscribe((role) => {
			this.isDealer = role === this.DEALER;
		});
		this.amountSub = this.suspendedCompanyService.amount$.subscribe((amount) => {
			this.amount = Number(amount).toFixed(2);
		});
		this.vendorTerminalID = this.suspendedCompanyService.vendorTerminalID$.subscribe((terminalID) => (this.terminalID = terminalID));
		this.customerBusinessName = this.suspendedCompanyService.customerBusinessName$.subscribe((businessName) => (this.businessName = businessName));

		this.customerBusinessID = this.suspendedCompanyService.customerBusinessID$.subscribe((businessID) => (this.businessID = businessID));
		this.data = new VTermInitForm();
		console.log('this.userHandlerService.userPerms:::', this.userHandlerService.userPerms);
		this.canPerformTxn = this.userHandlerService.userPerms ? this.userHandlerService.userPerms.canCreateTxn : false;
		console.log('pay-now: init: token', sessionStorage.getItem('token'));
		console.log('this.data:::', this.data);
	}

	ngOnDestroy(): void {
		this.roleSub.unsubscribe();
		this.crete_roleSub.unsubscribe();
		this.amountSub.unsubscribe();
		this.vendorTerminalID.unsubscribe();
		this.customerBusinessName.unsubscribe();
	}

	async onPayClick() {
		this.loading = true;

		console.log('terminalID:::', this.terminalID);
		console.log('businessName:::', this.businessName);
		try {
			await this.fetchVtermInfo(this.terminalID, this.businessName, false);
		} catch (error) {
			this.loading = false;
			console.log('onNodeClick vterminal error', error);
			return;
		}
		console.log('fetchTerminalPCtr completed');
		//Check if GMID, GTID and GMPW are available.
		if (!validatePgComFields(this.data)) {
			this.notificationService.error(SETUP_ALTERED_MESSAGE, 'Error');
			this.loading = false;
			return;
		}
		if (this.canPerformTxn) {
			this.proceedToVTerminal();
			return;
		}
		this.loading = false;
		this.showNotAllowedSwal(PERMISSION_UNAVAILABLE_MSG);
	}

	/**
	 * If there is no cc in prefix, or no prefix at all, dont allow dealer to proceed.
	 *
	 * @params : message: string : Respective messages based on prefixes.
	 */
	showNotAllowedSwal(message: string, title: string = CANT_PROCEED_MSG, cssClass: string = '') {
		const customSwal = Swal.mixin({
			customClass: {
				confirmButton: 'sa2-confirm-btn',
				actions: 'margin-0',
			},
			buttonsStyling: false,
		});
		customSwal
			.fire({
				title: title,
				width: '600px',
				html: message,
				icon: 'error',
				confirmButtonText: 'Okay',
			})
			.then((result) => {
				if (result.value) {
					this.loading = false;
				}
			});
	}

	proceedToVTerminal() {
		console.log('This.data', JSON.parse(JSON.stringify(this.data)));
		this.httpService.store(ENCRYPT_ENDPOINT, this.data).subscribe(
			(res) => {
				console.log('I got response token', res);
				if (res.success) {
					if (res.data.hasOwnProperty('vTermToken')) {
						document.location.href = this.VTERM_HOST + '?param=' + res.data.vTermToken;
					}
				} else {
					this.notificationService.error('Unable to proceed. Please try again.', 'Error');
				}
			},
			(error) => {
				console.log('Error in proceedToVTerminal', error);
				this.notificationService.error('Unable to proceed. Please try again.', 'Error');
			}
		);
	}

	/**
	 * Fetch terminal and pctr data from vterm-info-api, if terminal is validated, pass data to setDataForTokenCreation
	 * @param terminalID : string: mongo_id of terminal
	 * @param showResponse: boolean: show/hide response from decoded token
	 */
	fetchVtermInfo(terminalID, businessName, showResponse: boolean): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const params = { terminalID: terminalID };
			const userData = Object.keys(this.userHandlerService.userData).length ? this.userHandlerService.userData : {};
			this.httpService.getAll(VTERM_INFO_ENDPOINT, params).subscribe(
				async (vtermInfoResponse) => {
					//Loading should not be false as on successful response, routing occurs.
					console.log('fetchTerminalPCtr: res', vtermInfoResponse);
					// return;
					let vtermInfoResponseData = validateFor(BE_DATA_KEY, vtermInfoResponse) ? vtermInfoResponse.data : {};
					let bizName = businessName;
					console.log('bizName: fetchVtermInfo', bizName);
					// vtermInfoResponseData = {};
					// if (res && res.hasOwnProperty('pCtrRecord') && res.hasOwnProperty('storeRecord') && res.hasOwnProperty('termRecord')) {
					if (
						!vtermInfoResponseData ||
						!validateFor(BE_VTERM_INFO_PCTR_REC_KEY, vtermInfoResponseData) ||
						!validateFor(BE_VTERM_INFO_STORE_REC_KEY, vtermInfoResponseData) ||
						!validateFor(BE_VTERM_INFO_TERMINAL_REC_KEY, vtermInfoResponseData)
					) {
						// this.showNotAllowedSwal(VTERM_INFO_DATA_VALIDATION_FAIL_MSG);
						return reject(false);
					}
					let terminalRecords = vtermInfoResponseData[BE_VTERM_INFO_TERMINAL_REC_KEY];
					// if (!this.validateTerminalForMerchantCapabilities(terminalRecords)) {
					// 	return reject(false);
					// }
					let errorFlag = validateTerminalForMerchantCapabilities(terminalRecords);
					console.log('generateToken: errorFlag', errorFlag);
					let errorMessage = '';
					let errorTitle = '';
					let errorCss = '';
					switch (errorFlag) {
						case NO_PREFIX_FLAG:
							errorMessage = NO_PREFIX_MESSAGE;
							errorTitle = SETUP_NEEDED_MSG;
							errorCss = SETUP_NEEDED_SWAL_CSS;
							break;
					}
					if (errorMessage) {
						console.log('fetchVtermInfo: errorMessage', errorMessage);
						this.showNotAllowedSwal(errorMessage, errorTitle, errorCss);
						return reject(false);
					}
					if (validateFor(DB_XROLES_KEY, userData) && userData[DB_XROLES_KEY].length && validateFor(DB_XTAG_KEY, userData[DB_XROLES_KEY][0])) {
						userData[DB_XROLES_KEY][0][DB_XTAG_KEY] = 'role.cust';
					}
					// console.log('userData:::', userData);
					// console.log('terminalRecords:::', terminalRecords);
					if (terminalRecords[0].parentMerAcctRecord.xName.includes('[CC]')) {
						this.data = setDataForTokenCreation(
							bizName,
							vtermInfoResponseData,
							userData,
							TAGSCONSTS.roleTags.roleItemTags.customerRole,
							terminalRecords[0]
						);
						this.data['billingAccountInfo'] = await this.setBillingAcctInfoForPayload();
						this.data['authToken'] = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null;
					} else if (terminalRecords[0].parentMerAcctRecord.xName.includes('[ACH]')) {
						this.data = setDataForTokenCreation(
							bizName,
							vtermInfoResponseData,
							userData,
							TAGSCONSTS.roleTags.roleItemTags.customerRole,
							{},
							terminalRecords[0]
						);
						this.data['billingAccountInfo'] = await this.setBillingAcctInfoForPayload();
						this.data['authToken'] = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null;
					}
					this.loading = false;
					console.log('fetch terminal complete resolve', this.data);
					// this.shouldProceed = true;
					// return;
					return resolve(true);
					// }
				},
				(error) => {
					this.loading = false;
					console.log('fetchterminalPctr: err', error);
					this.notificationService.error(FETCH_TERMINAL_PCTR_FAIL_MSG, 'Error');
					return reject(false);
				}
			);
		});
	}

	setBillingAcctInfoForPayload() {
		return new Promise(async (resolve, reject) => {
			this.httpService.get('xbcBusinesses', this.businessID).subscribe(
				(response: Response | any) => {
					console.log('response.data:::', response.data);
					const xAsCustomerID = validateFor('xAsCustomerID', response.data) ? response.data.xAsCustomerID : '';
					if (!xAsCustomerID) {
						throw new Error('NO Business CustomerID');
					}
					this.httpService.get('xbcCustomers', xAsCustomerID).subscribe((response2: Response | any) => {
						console.log('response2.data:::', response2.data);
						let xVendorCustomerID = "";
						if(this.create_role === TAGSCONSTS.roleTags.roleItemTags.vendorRole){
							xVendorCustomerID = validateFor('xVendorCustomerID', response2.data) ? response2.data.xVendorCustomerID : '';
						}else{
							xVendorCustomerID = validateFor('xDealerCustomerID', response2.data) ? response2.data.xDealerCustomerID : '';
						}
	
						if (!xVendorCustomerID) {
							throw new Error('NO Vendor CustomerID');
						}
						this.httpService.get('xbcCustomers', xVendorCustomerID).subscribe((response3: Response | any) => {
							console.log('response3.data:::', response3.data);
							const billingAccountInfo = {
								_id: response3.data._id,
								memberNumber: response3.data.xCustomerNum,
								firstName: response3.data.xFormalName.xForename,
								lastName: response3.data.xFormalName.xSurname,
								phone: response3.data.xContact.xPhone,
								email: response3.data.xContact.xEmail,
								address: response3.data.xAddr.xLine1 + ' ' + response3.data.xAddr.xLine2,
								city: response3.data.xAddr.xCity,
								state: response3.data.xAddr.xSOP,
								zipCode: response3.data.xAddr.xPostCode,
								country: response3.data.xAddr.xCountry,
								company: response3.data.xCompanyName,
							};
							return resolve(billingAccountInfo);
						});
					});
				},
				(error: any) => {
					console.log(error, 'error while fetching business');
					return reject(error);
				}
			);
		});
	}

	logout(): void {
		if (localStorage.getItem('token')) localStorage.removeItem('token');
		else sessionStorage.removeItem('token');
		localStorage.removeItem('rememberMe');
		this.userHandlerService.resetUserData();
		this.suspendedCompanyService.isSuspend = false;
		this.suspendedCompanyService.amount.next(0);
		this.suspendedCompanyService.isSuspended.next(false);
		this.suspendedCompanyService.dueBillingDate.next('');
		this.suspendedCompanyService.role.next('');
		this.suspendedCompanyService.create_role.next('');
		this.router.navigateByUrl('');
	}
}
