import { Injectable } from '@angular/core';

@Injectable()
export class StoreService {
    // accessStoreIDs: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    accessStoreIDs:string[]=[];
    accessStoreStatus:Number[]=[];
    mainStoreID:string='';
    constructor() {}

    setAccessStoreIDs(accessStoreIDs: string[], status: Number[]): void {
        this.accessStoreIDs=accessStoreIDs;
        this.accessStoreStatus=status;
    }

    getAccessStoreIDs():string[]{
        return this.accessStoreIDs;
    }

    getAccessStoreStatus():Number[]{
        return this.accessStoreStatus;
    }

    setMainStoreID(mainStoreID: string): void {
        this.mainStoreID=mainStoreID;
    }

    getMainStoreID():string{
        return this.mainStoreID;
    }

    pushToAccessStoreIDs(storeId:string, status:Number){
        this.accessStoreIDs.push(storeId);
        this.accessStoreStatus.push(status);
    }

   
}
