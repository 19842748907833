import { Injectable } from '@angular/core';
import { validateFor } from '../../common-functions';
import { BehaviorSubject } from 'rxjs';
import { SetupBusinessStructureStoreSetupStore } from '../../store/setup-business-structure-store_setup-store';
import { HttpService } from './http-service';
import { XBCBUSINESS } from '../../constants';

const BIZ_DATA_KEY = 'bizData';
@Injectable()
export class LoginStoreService {
	private _storeObj: any = {};
	private _storeData: any = {};
	private _terminalData: any = {};
	private _pctrData: any = {};
	private _terminalNumbersObj: any = {};
	private _tokenForLoginAsCustomer: string = '';
	private _companyID = '';

	fetchedRelevantData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	watchFetchedRelevantData$ = this.fetchedRelevantData.asObservable();
	constructor(private setupBusinessStructureStoreSetupStore: SetupBusinessStructureStoreSetupStore, private httpService: HttpService) {}

	setFetchedRelevantData(value: boolean) {
		this.fetchedRelevantData.next(value);
	}

	set storeData(_storeData: any) {
		this._storeData = _storeData;
	}

	get storeData() {
		return this._storeData;
	}

	set storeObj(storeObj: any) {
		this._storeObj = storeObj;
	}

	get storeObj() {
		return this._storeObj;
	}

	set terminalData(terminalData: any) {
		this._terminalData = terminalData;
	}

	get terminalData() {
		return this._terminalData;
	}

	set terminalNumbersObj(terminalNum: any) {
		this._terminalNumbersObj = terminalNum;
	}

	get terminalNumbersObj() {
		return this._terminalNumbersObj;
	}

	get pctrData() {
		return this._pctrData;
	}

	set pctrData(pctrDataObj: any) {
		this._pctrData = pctrDataObj;
	}

	set tokenForLoginAsCustomer(token: any) {
		this._tokenForLoginAsCustomer = token;
	}

	get tokenForLoginAsCustomer() {
		return this._tokenForLoginAsCustomer;
	}

	get companyID() {
		return this._companyID;
	}

	set companyID(id) {
		this._companyID = id;
	}

	callAPIStoreData(id: any) {
		console.log('id recieved', id);
		this.httpService.authenticatedAPI = false;
		this.setupBusinessStructureStoreSetupStore.get(id).subscribe(
			response => {
				console.log('response in loginservice', response);
				this.storeData = response;
				this.httpService.authenticatedAPI = true;
			},
			error => {
				this.storeData = error;
			}
		);
	}

	setData(res: any) {
		console.log('setData: res', res);
		// this.fetchingTerminal = false;
		if (res && res.hasOwnProperty('storeData') && res.hasOwnProperty('terminalData') && res.hasOwnProperty('pctrData')) {
			this.storeData = res['storeData'] ? res['storeData'] : {};
			this.terminalData = res['terminalData'] ? res['terminalData'] : {};
			this.pctrData = res['pctrData'] ? res['pctrData'] : {};
			this.terminalNumbersObj = validateFor('terminalNumbersObj', res) ? res.terminalNumbersObj : {};
			this.companyID = validateFor(BIZ_DATA_KEY, res) && validateFor(XBCBUSINESS.xSvcCompanyID, res[BIZ_DATA_KEY]) 
				? res[BIZ_DATA_KEY][XBCBUSINESS.xSvcCompanyID] 
				: '';
			let storeObj = {};
			let addressObj = this.storeData['xAddr'];
			storeObj['id'] = this.storeData['_id'];
			storeObj['storeName'] = this.storeData['xName'];
			storeObj['storePhoneNum'] = this.storeData['xMainContact']['xPhone'];
			storeObj['storeEmail'] = this.storeData['xMainContact']['xEmail'];
			storeObj['storeAddress'] =
				(addressObj['xLine1'] ? addressObj['xLine1'] + ', ' : '') +
				(addressObj['xLine2'] ? addressObj['xLine2'] + ', ' : '') +
				(addressObj['xCity'] ? addressObj['xCity'] + ', ' : '') +
				(addressObj['xSOP'] ? addressObj['xSOP'] + ', ' : '') +
				(addressObj['xCountry'] ? addressObj['xCountry'] : '');
			storeObj['image'] =
				Object.keys(this.storeData).length && this.storeData.hasOwnProperty('image') && this.storeData.image
					? this.storeData.image
					: '';
			this.storeObj = storeObj;
			this.setFetchedRelevantData(true);
			console.log('setData: loginStoreservice store', this.storeData);
			console.log('setData: loginStoreservice terminal', this.terminalData);
			console.log('setData: loginStoreservice pctr', this.pctrData);
			// this.router.navigate(['customer/login'], { queryParams: { termNum: this.terminalNumber } });
		}
	}
}
