import {
    Component,
    OnInit,
    OnDestroy,
    /*Input,*/ Output,
    EventEmitter
} from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { forkJoin } from "rxjs";
import { takeWhile, skip } from "rxjs/operators";
// import {SHORTCODES} from '../../../../../../constants/shortCodes.constants';
import { TAGSCONSTS } from "../../../../../../constants/tags.constants";

import {
    SetupBusinessInformationStore,
    SetupBusinessStructureStore,
    ConstantStore /*, BusinessTreeStore*/
} from "../../../../../../store";
import {
    Property,
    Address,
    Social,
    MainContact
} from "../../../../../../models";
import {
    BreadcrumbService,
    NotificationService,
    BusinessService,
    GlobalValidator,
    TreeService,
    UrlStoreService,
    TabStructureService,
    UserHandlerService
} from "../../../../../../shared/services";
import { NO_PERMISSION_MSG } from "../../../../../../constants";

@Component({
    selector: "add-edit",
    templateUrl: "./add-edit.component.html",
    styleUrls: ["./add-edit.component.scss"]
})
export class SetupStructurePropertyComponent implements OnInit, OnDestroy {
    @Output()
    formSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
    nodePath: any[] = [];
    bizID: string = "";
    submitting: boolean = false; //For handling form submission
    apiLoading: boolean = false; //For handling API Calls
    edit: boolean = false; //For maintaining if edit mode or not
    propertySetupModel: any = {};
    errors: any; //Stores errors returned by server
    form: FormGroup; //Required for reactive form validation
    ifPropExtendsChain: boolean = false; //Maintains toggle for indicating if property extends chain
    ifPropExtendsRegion: boolean = false; //Maintains toggle for indicating if property extends region
    noProp: boolean = false;
    chainName: string;
    regionName: string;
    alive: boolean = true;
    serverErrors: any = {}; //Stores errors returned by server
    formErrors = {
        xName: "",
        // xPhone: '',
        xSubName: "",
        xTag: "",
        xRefNum: "",
        xStructNum: ""
    };
    loading: boolean = false;
    tag: string;
    numberKey: string = "xStructNum";
    parentMode: boolean = false;
    addressContactValidate: boolean = false;

    bizChangeEvent: boolean = false;
    canEdit = true;
    canAdd = true;
    noPermMsg = NO_PERMISSION_MSG;
    // parentAddress:any=null;
    // parentContact:any=null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private notificationService: NotificationService,
        public businessService: BusinessService,
        private setupBusinessStructureStore: SetupBusinessStructureStore,
        private constantStore: ConstantStore,
        private fb: FormBuilder,
        private treeService: TreeService,
        public urlStoreService: UrlStoreService,
        private tabStructureService: TabStructureService,
        private setupBusinessInformationStore: SetupBusinessInformationStore,
        public userHandlerService: UserHandlerService
    ) {
        this.errors = {};
    }

    ngOnInit() {
        this.setupPerms();
        this.tabStructureService.checkAndSetTabNameToDef(
            this.tabStructureService.tabMode,
            this.tabStructureService.isCancelClick,
            this.tabStructureService.isSubmitClick
        );
        this.urlStoreService.submitAction = false;
        this.setBreadCrumbValue();
        this.nodePath = this.setupBusinessStructureStore.getNodePath();
        this.bizID = this.businessService.businessID.getValue();
        this.route.params.forEach((params: Params) => {
            this.toFormGroup(new Property());
            this.edit = false;
            this.apiLoading = true;
            //CHECK FOR EDIT MODE
            if (params.hasOwnProperty("Id")) {
                this.edit = true;
                if (params.Id === "null") {
                    //Check whether the region exists for selected node or not (upward hierarchy in business structure)
                    this.noProp = true;
                } else {
                    //Edit Mode
                    this.loading = true;
                    if (
                        !this.treeService.propertyID.getValue() &&
                        !this.treeService.isParent
                    ) {
                        console.log("Inside If");
                        this.treeService.setId(params.Id, "property");
                    }
                    this.fetchPropertyData(params["Id"]);
                }
            }
            // else{
            //     this.setupBusinessInformationStore.get(this.businessService.businessID.getValue())
            //     .subscribe((res)=>{
            //         console.log("res", JSON.parse(JSON.stringify(res.data)));
            //         this.parentAddress=res.data.xAddr;
            //         console.log("this.form", JSON.parse(JSON.stringify(this.form.value)));
            //         // if(this.form.controls.xAddr){
            //         //     this.form.controls.xAddr.setValue=this.parentAddress;
            //         // }
            //     })
            // }
        });
        if (this.tabStructureService.tabMode) {
            this.checkParentMode();
        }
        this.setTabNameNewProperty();

        this.setupBusinessStructureStore.watchNodePath$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe((nodePath: any[]) => {
                console.log("This is updated node path property", nodePath);
                this.nodePath = nodePath;
                this.addModeActions();
                // this.parentMode=false;
                // this.checkParentMode();
                this.parentMode = false;
                // this.setTabNameNewProperty();
            });
        this.addModeActions();
        this.tabStructureService.watchParentFetched$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(res => {
                console.log("Here in watcher of parent fetched", res);
                if (res) {
                    console.log("Here in watcher of parent fetched res true");
                    this.setTabNameNewProperty();
                }
            });
        this.businessService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizId => {
                if (bizId) {
                    this.bizChangeEvent = true;
                } else {
                    this.router.navigate([
                        "/dashboard/setup/business/structure/information"
                    ]);
                }
            });
    }

    // setParentAddressAndContact(observable){
    //     Observable.forkJoin([observable])
    //             .subscribe((res: any)=>{
    //                 console.log("res", JSON.parse(JSON.stringify(res[0].data)));
    //                 this.parentAddress=res[0].data.xAddr;
    //                 this.parentContact=res[0].data.xMainContact;
    //                 console.log("this.form", JSON.parse(JSON.stringify(this.form.value)));
    //                 // if(this.form.controls.xAddr){
    //                 //     this.form.controls.xAddr.setValue=this.parentAddress;
    //                 // }
    //             })
    // }

    checkParentMode() {
        if (this.tabStructureService.tabLabelItems.length) {
            this.tabStructureService.tabLabelItems.map(labelItem => {
                if (labelItem.label.includes("Propert")) {
                    if (
                        labelItem.hasOwnProperty("type") &&
                        labelItem["type"] === "Parent"
                    ) {
                        this.parentMode = true;
                    } else {
                        this.parentMode = false;
                    }
                }
            });
        }
    }

    setTabNameNewProperty() {
        if (!this.edit) {
            // console.log('%c Add mode hai k??', 'background: blue; color: white');
            if (this.tabStructureService.tabLabelItems.length) {
                // console.log('%c Add mode hai k??', 'background: pink; color: white');
                this.tabStructureService.tabLabelItems.map(labelItem => {
                    // console.log('%c Add mode hai k??', 'background: grey; color: black', labelItem);
                    if (labelItem.label.includes("Propert")) {
                        if (labelItem.hasOwnProperty("type")) {
                            labelItem["label"] = "Property";
                            labelItem["type"] = "New";
                            labelItem["name"] = "";
                        }
                    }
                });
                console.log(
                    "%c Property Add mode hai k??",
                    "background: grey; color: black",
                    this.tabStructureService.tabLabelItems
                );
                this.tabStructureService.setTabStructureNames(
                    this.tabStructureService.tabLabelItems
                );
            }
        }
    }

    fetchPropertyData(id: string) {
        forkJoin(
            this.setupBusinessStructureStore.get(id)
        ).subscribe(
            (response: any) => {
                this.loading = false;
                this.propertySetupModel = response[0].data;
                this.form.patchValue(new Property(this.propertySetupModel));
                this.checkPropertyHierarchy();
            },
            (error: any) => {
                this.handleErrorResponse(error);
            },
            () => {
                this.apiLoading = false;
            }
        );
    }

    addModeActions() {
        console.log("this.edit", this.edit);
        if (!this.edit) {
            this.ifPropExtendsChain = this.setChainId();
            this.ifPropExtendsRegion = this.setRegionId();
            this.setBusinessID();
            // if(this.ifPropExtendsRegion){
            //     this.setParentAddressAndContact(this.setupBusinessStructureStore.get(this.regionID.value).map((res: Response) => res));
            // }
            // else if(this.ifPropExtendsChain){
            //     this.setParentAddressAndContact(this.setupBusinessStructureStore.get(this.chainID.value).map((res: Response) => res));
            // }
            // else{
            //     this.setParentAddressAndContact(this.setupBusinessInformationStore.get(this.bizID).map((res: Response) => res));
            // }
        }
    }

    toFormGroup(data: Property) {
        this.form = this.fb.group({
            xTntTag: [data.xTntTag || "test", []],
            xWall1EntyID: [data.xWall1EntyID || "", []],
            xWall2EntyID: [data.xWall2EntyID || this.bizID, []],
            xTag: [data.xTag || "", []],
            xStatus: [data.xStatus || "1", []],
            xName: [
                data.xName || "",
                [Validators.required, GlobalValidator.validateName]
            ],
            xTypeTag: [
                data.xTypeTag || TAGSCONSTS.structTags.propertyTags,
                [
                    /*Validators.required*/
                ]
            ],
            xNotes: [data.xNotes || "", []],
            xRefNum: [
                data.xRefNum || "",
                [
                    /*Validators.required*/
                ]
            ],
            xTZTag: [
                data.xTZTag || "",
                [
                    /*Validators.required*/
                ]
            ],
            xSubName: [
                data.xSubName || "",
                [
                    /*Validators.required*/
                ]
            ],
            // xAddr: this.fb.group(new Address(data.xAddr)),
            // xSocial: this.fb.group(new Social(data.xSocial)),
            // xMainContact: this.fb.group(new MainContact(data.xMainContact)),
            xBusinessID: [data.xBusinessID || this.bizID],
            xChainID: [data.xChainID || ""],
            xRegionID: [data.xRegionID || ""],
            xStructNum: [data.xStructNum || "", [Validators.required]]
        });
    }

    checkPropertyHierarchy() {
        // check for property hierarchy[property extending chain, property extending region] in edit mode
        this.nodePath = this.setupBusinessStructureStore.getNodePath();
        console.log("Node path", this.nodePath);
        this.ifPropExtendsChain = this.setChainId();
        this.ifPropExtendsRegion = this.setRegionId();
    }

    setChainId(): boolean {
        const chainObj: any = this.nodePath.filter(
            node => node.type === "chain"
        );
        if (chainObj.length) {
            this.chainID.setValue(chainObj[0].id);
            this.chainName = chainObj[0].name;
            return true;
        } else {
            if (this.treeService.chainID.getValue()) {
                this.chainID.setValue(this.treeService.chainID.getValue());
                this.chainName = this.tabStructureService.currentNodeName;
                // console.log("HEREEEEEEE", this.chainName);
                return true;
            } else {
                this.chainID.setValue("");
                this.chainName = "";
                return false;
            }
        }
    }

    setRegionId(): boolean {
        const regionObj: any = this.nodePath.filter(
            node => node.type === "region"
        );
        if (regionObj.length) {
            this.regionID.setValue(regionObj[0].id);
            this.regionName = regionObj[0].name;
            return true;
        } else {
            if (this.treeService.regionID.getValue()) {
                this.regionID.setValue(this.treeService.regionID.getValue());
                this.regionName = this.tabStructureService.currentNodeName;
                // console.log("HEREEEEEEE", this.regionName);
                return true;
            } else {
                this.regionID.setValue("");
                this.regionName = "";
                return false;
            }
        }
    }

    setBusinessID() {
        const bizObj: any = this.nodePath.filter(
            node => node.type === "business"
        );
        if (bizObj.length) {
            if (this.bizID === bizObj[0].id) {
                this.businessID.setValue(bizObj[0].id);
            } else {
                if (this.bizID) {
                    this.businessID.setValue(this.bizID);
                } else {
                    this.notificationService.error(
                        "Cannot modify this business",
                        "ERROR"
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            }
        } else {
            this.businessID.setValue(this.bizID);
        }
    }

    get chainID(): any {
        return this.form.get("xChainID");
    }

    get regionID(): any {
        return this.form.get("xRegionID");
    }

    get businessID(): any {
        return this.form.get("xBusinessID");
    }

    setBreadCrumbValue(): void {
        const breadCrumb: any = {};
        breadCrumb.root = "Setup";
        breadCrumb.child = "Business";
        breadCrumb.subChild = "Structure";
        breadCrumb.subChildLink = "setup/business/structure/information";
        breadCrumb.grandChild = "Property-Setup";
        breadCrumb.rootList = [];
        this.breadcrumbService.setBreadCrumb(breadCrumb);
    }

    onPropertySubmit(form: FormGroup): void {
        this.urlStoreService.submitAction = true;
        this.tabStructureService.isSubmitClick = true;
        this.submitting = true;
        this.errors = {}; //Reset Errors
        const propertyModel = form.getRawValue();
        console.log("prop model", propertyModel);
        if (!this.ifPropExtendsChain) {
            delete propertyModel.xChainID;
        }
        if (!this.ifPropExtendsRegion) {
            delete propertyModel.xRegionID;
        }
        if (this.edit) {
            //Update property
            this.setupBusinessStructureStore
                .update(this.propertySetupModel._id, propertyModel)
                .subscribe(
                    (response: any) => {
                        this.handleSuccessResponse(
                            response.data,
                            propertyModel
                        );
                    },
                    (error: any) => {
                        this.serverErrors = error.errors;
                        this.handleError(error);
                    },
                    () => {
                        this.submitting = false;
                        this.formSubmitted.emit(true);
                    }
                );
        } else {
            //Add New property
            this.setupBusinessStructureStore.store(propertyModel).subscribe(
                (response: any) => {
                    this.handleSuccessResponse(response.data, propertyModel);
                },
                (error: any) => {
                    this.serverErrors = error.errors;
                    this.handleError(error);
                },
                () => {}
            );
        }
    }

    handleSuccessResponse(data?: any, propertyModel?: any): void {
        this.submitting = false;
        this.formSubmitted.emit(true);
        if (this.edit) {
            this.notificationService.success(
                "Record Successfully Updated.",
                "Success!"
            );
        } else {
            this.notificationService.success(
                "Record Successfully Added.",
                "Success!"
            );
        }
        this.businessService.toggleRefreshTree();
        // Proper routing depending upon nodeClick or any other click
        if (this.treeService.nodeClick) {
            if (this.edit) {
                this.fetchPropertyData(data.id);
            } else {
                this.tabStructureService.currentNodeName = propertyModel.xName;
                this.urlStoreService.routeToCurrentUrl(data, "property", "add");
            }
        } else {
            if (this.tabStructureService.tabMode) {
                this.urlStoreService.routeBackToPreviousUrl();
            } else {
                if (this.tabStructureService.addNodeBtnClick) {
                    this.tabStructureService.currentNodeName =
                        propertyModel.xName;
                    this.urlStoreService.routeToCurrentUrl(
                        data,
                        "property",
                        "add"
                    );
                } else {
                    this.treeService.setId(
                        this.businessService.businessID.getValue(),
                        "business"
                    );
                    console.log(
                        "onsuccess no tab mode and node click   prop",
                        this.treeService.businessID.getValue(),
                        this.treeService.chainID.getValue()
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            }
        }
    }

    handleErrorResponse(error: any): void {
        this.submitting = false;
        this.formSubmitted.emit(true);
        this.errors = JSON.parse(error._body).errors;
        this.notificationService.error(
            JSON.parse(error._body).name === "BadRequest"
                ? "Record Not Added"
                : JSON.parse(error._body).name,
            JSON.parse(error._body).message === "Invalid data"
                ? "Please fill all Required Details"
                : JSON.parse(error._body).message
        );
    }

    handleError(error: any): void {
        this.submitting = false;
        console.log("error inside handler", error);
        for (const field of Object.keys(error.errors)) {
            if (this.formErrors.hasOwnProperty(field)) {
                // this.formErrors[field] = "Field is not allowed to be empty";
                if (field === "xStructNum") {
                    console.log("Inside handle error", error);
                    if (error.name === "Conflict") {
                        let err = error.errors.xStructNum.replace(
                            /struct/g,
                            "Property"
                        );
                        this.notificationService.error(err, "Conflict");
                        this.clearErrors("xStructNum");
                    } else {
                        this.formErrors[field] =
                            "Field is not allowed to be empty";
                    }
                } else {
                    this.formErrors[field] = "Field is not allowed to be empty";
                }
            }
            if (field === "xRefNum") {
                console.log("Inside reference number handle error", error);
                if (error.name === "Conflict") {
                    let err = error.errors.xRefNum.replace(
                        /struct/g,
                        "Property"
                    );
                    this.notificationService.error(err, "Conflict");
                    this.clearErrors("xRefNum");
                }
            }
        }
    }

    clearErrors(errorName: string): void {
        if (this.serverErrors[errorName]) {
            delete this.serverErrors[errorName];
        }
    }

    onKeypress(event: any) {
        GlobalValidator.phoneNumberFormat(event);
    }

    onPaste(event: any) {
        GlobalValidator.phoneNumberFormat(event);
    }

    onCancel(event: Event) {
        event.preventDefault();
        this.tabStructureService.isCancelClick = true;
        if (this.tabStructureService.tabMode) {
            if (!this.edit) {
                this.urlStoreService.routeBackToPreviousUrl();
            } else {
                this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
                this.urlStoreService.routeToSecondLastUrl();
            }
        } else {
            if (this.edit) {
                console.log("no tabmode and edit");
                if (!this.tabStructureService.editMode) {
                    console.log("no tabmode and edit and editmode");
                    this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
                    if (
                        this.urlStoreService.previousUrl &&
                        !this.urlStoreService.previousUrl.includes("add-edit")
                    ) {
                        this.treeService.setId(
                            this.treeService.previousSelectedNode.id,
                            this.treeService.previousSelectedNode.type
                        );
                    }
                    this.urlStoreService.routeBackToPreviousUrl();
                    // }
                } else {
                    console.log("no tabmode and edit and no editmode");
                    if (this.treeService.nodeClick) {
                        this.urlStoreService.routeToSecondLastUrl();
                    } else {
                        this.treeService.setId(
                            this.treeService.previousSelectedNode.id,
                            this.treeService.previousSelectedNode.type
                        );
                        this.urlStoreService.routeBackToPreviousUrl();
                    }
                }
            } else {
                console.log("no tabmode and no edit");
                this.urlStoreService.routeBackToPreviousUrl();
            }
        }
    }

    onEditClick(event: Event) {
        console.log(
            "Here onEditClick function in biz===>",
            this.parentMode,
            JSON.parse(JSON.stringify(this.propertySetupModel))
        );
        this.tabStructureService.previousTabStructureLabel = JSON.parse(
            JSON.stringify(this.tabStructureService.defaultTabStructureLabel)
        );
        this.treeService.isParent = false;
        this.parentMode = false;
        this.edit = true;
        this.treeService.setId(this.propertySetupModel._id, "property");
        // this.router.navigate(['dashboard/setup/business/structure/information'])
    }

    setupPerms(){
        this.canAdd = this.userHandlerService.userPerms.canCreateSetup;
        this.canEdit = this.userHandlerService.userPerms.canEditSetup;
    }

    ngOnDestroy() {
        this.alive = false;
    }
}
