<div [formGroup]="form">
	<small
		*ngIf="!this.serverErrors[this.numberKey] && form.get(numberKey)?.hasError('required') && form.get(numberKey)?.touched"
		class="inline-error number-field-error"
	>
		<i
			class="fa fa-exclamation-triangle inline-error"
			container="body"
			tooltip="Number is required"
			placement="top"
			containerClass="tooltip-custom inline-error"
			aria-hidden="true"
		></i>
	</small>

	<small *ngIf="serverErrors[this.numberKey]" class="inline-error number-field-error">
		<i
			class="fa fa-exclamation-triangle inline-error"
			container="body"
			tooltip="Number is required"
			placement="top"
			containerClass="tooltip-custom inline-error"
			aria-hidden="true"
		></i>
	</small>
	<div class="input-group" *ngIf="numberKey">
		<input
			type="text"
			class="form-control form-control-sm"
			id="model_num"
			formControlName="{{ numberKey }}"
			(focus)="clearErrors(this.numberKey)"
			[ngClass]="{
				'has-error': (form.get(numberKey)?.hasError('required') && form.get(numberKey)?.touched) || serverErrors[this.numberKey]
			}"
			(focus)="clearErrors(numberKey)"
			validateOnBlur
		/>
		<button
			tooltip="Get new number"
			placement="top"
			container="body"
			containerClass="tooltip-global"
			type="button"
			class="btn btn-light"
			[disabled]="form.get(numberKey)?.disabled"
			(click)="getNewNumber()"
		>
			<i class="fa fa-undo"></i>
		</button>
	</div>
</div>
