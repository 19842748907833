<!-- --------------------------------------- paynow form ---------------------------------------------------------->
<div>
    <form class="form-horizontal p-b-10" [formGroup]="form" (ngSubmit)="onSubmit(form)">
        <div class='card card-default'>
            <div class='card-body vterm-info'>
                <div class="row m-b-10">
                    <div class="col-sm-12">
                        <label>LOT Number *</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="lotNumber"
                            formControlName="lotNumber"
                            validateOnBlur
                            [ngClass]="{ 'has-error': form.get('lotNumber').hasError('required') && form.get('lotNumber').touched }"
                        />
                        <small *ngIf="form.get('lotNumber').hasError('required') && form.get('lotNumber').touched" class="inline-error">
                            LOT Number is required
                        </small>
                    </div>
                </div>
                <div class="row m-b-10">
                    <div class="col-sm-6">
                        <label>Payment Amount *</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="paymentAmount"
                            formControlName="paymentAmount"
                            validateOnBlur
                            [ngClass]="{ 'has-error': form.get('paymentAmount').hasError('required') && form.get('paymentAmount').touched }"
                        />
                        <small *ngIf="form.get('paymentAmount').hasError('required') && form.get('paymentAmount').touched" class="inline-error">
                            Payment Amount is required
                        </small>
                    </div>
                    <div class="col-sm-6">
                        <label>Scheduled Date *</label>
                        <input
                            type="text"
                            class="form-control form-sm"
                            bsDatepicker
                            #scheduledDate="bsDatepicker"
                            formControlName="scheduledDate"
                            
                            [bsConfig]="{ containerClass: 'theme-custom-date-picker' }"
                            style="padding-top: 4px !important; padding-bottom: 4px !important;"
                            [ngClass]="{ 'has-error': form.get('scheduledDate').hasError('required') && form.get('scheduledDate').touched }"
                        />
                        <small *ngIf="form.get('scheduledDate').hasError('required') && form.get('scheduledDate').touched" class="inline-error">
                            Scheduled Date is required
                        </small>
                    </div>
                </div>
                <div class="row p-b-10">
                    <div class="col-sm-12">
                        <label>Notes</label>
                        <textarea class="form-control" rows="3" formControlName="notes"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-default">
            <div class="card-header">
                <label>Payment Method:</label>
                <button class="btn-xs btn-primary pull-right" (click)="selectPaymentMode(form)" type="button">Select Payment Method</button>
                <button class="btn-xs btn-primary pull-right m-r-10" (click)="createPaymentMode(form)" type="button">
                    Create Payment Method
                </button>
            </div>
            <div class="card-body vterm-info">
                <label><b>Selected Payment Method</b></label>
                <div class="row m-b-10" *ngIf="!currentPaymentMethod?.xMdmTag || !currentPaymentMethod?.xMaskedAcctNum || !currentPaymentMethod?.xHolderName">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>None</label>
                        </div>
                    </div>
                </div>
                <div class="row m-b-10" *ngIf='currentPaymentMethod?.xMdmTag && currentPaymentMethod?.xMaskedAcctNum && currentPaymentMethod?.xHolderName'>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label>Card Type: </label>
                            <p>{{ currentPaymentMethod?.medium }}</p>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label>Brand: </label>
                            <p>{{ currentPaymentMethod?.brand }}</p>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label>Card Number </label>
                            <p>{{ currentPaymentMethod?.xMaskedAcctNum }}</p>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label>Card Name </label>
                            <p>{{ currentPaymentMethod?.xHolderName }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary pull-right">Proceed</button>
    </form>
</div>
<!-- --------------------------------------- paynow form ---------------------------------------------------------->
