import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { HttpService } from './http-service';
import { TabStructureService } from './tab-structure-service';
import {NotificationService} from './notification-service';
import {ApiConfig} from '../config/api.config';
class NodeType{
    id:string;
    type:string;

    constructor(obj?:NodeType){
        this.id= obj? obj.id:'';
        this.type= obj? obj.type:'';
    }
}

@Injectable()
export class TreeService {
    enable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    show: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    businessID: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    chainID: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    regionID: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    propertyID: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    storeID: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    profitCenterID: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    merchantAccID: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    terminalID: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    periphID: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    currentSelectedNode: BehaviorSubject<NodeType> = new BehaviorSubject<NodeType>(null);


    objExpandedNodes: any = null;
    watchEnableTree$ = this.enable.asObservable();
    watchShowTree$ = this.show.asObservable();
    watchBusinessID$ = this.businessID.asObservable();
    watchChainID$ = this.chainID.asObservable();
    watchRegionID$ = this.regionID.asObservable();
    watchPropertyID$ = this.propertyID.asObservable();
    watchStoreID$ = this.storeID.asObservable();
    watchProfitCenterID$ = this.profitCenterID.asObservable();
    watchMerchantAccID$ = this.merchantAccID.asObservable();
    watchTerminalID$ = this.terminalID.asObservable();
    watchPeriphID$ = this.periphID.asObservable();
    watchCurrentSelectedNode$ = this.currentSelectedNode.asObservable();
    private _skipTreeLoad: boolean = false;
    private _nodeClick:boolean=false;
    private _submitClick:boolean=false;
    private _treeData: any;
    private _treeDataUnderStore: any;
    private _parentTerminal: any;
    private _currentClickedNodeData: any = {};
    private _nodePath:any;
    xGTID: string;
    xGMID = this.watchMerchantAccID$;
    xName: string;
    isParent:boolean=false;
    parentInfo:any={};
    API_REGISTER: string = '';
    public previousSelectedNode:NodeType;
    // public previousSelectedNode:any={
    //     id:'',
    //     type:''
    // }
    // currentNodeId:string=null;

    public tempStructTabArr=[
        {type:"biz", value:"current"},
        {type:"chain", value:"list"},
        {type:"region", value:"list"},
        {type:"prop", value:"list"},
        {type:"store", value:"list"},
        {type:"pCtr", value:"list"},
        {type:"merAcct", value:"list"},
        {type:"terminal", value:"list"},
        {type:"periph", value:"list"},
    ];

    // public _treeTags=new TreeTagsModel();
    constructor(
        private httpService: HttpService,
        private tabStructureService: TabStructureService,
        private notificationService: NotificationService,
        private apiConfig: ApiConfig,
    ) {
        this.API_REGISTER = apiConfig.API_REGISTER_TERMINAL;
    }



    setId(entityID: string, entityType: string): void {
        switch (entityType) {
            case 'business':
            console.log("or this is called??");
                this.chainID.next(null);
                this.regionID.next(null);
                this.propertyID.next(null);
                this.storeID.next(null);
                this.profitCenterID.next(null);
                this.merchantAccID.next(null);
                this.terminalID.next(null);
                this.periphID.next(null);

                this.businessID.next(entityID);
                if(this.businessID.getValue()){
                    // console.log("Yahan se call ho rha??");
                    this.previousSelectedNode= JSON.parse(JSON.stringify(this.currentSelectedNode.getValue()))
                    this.currentSelectedNode.next({
                        id:entityID,
                        type:'business'
                    });
                    this.setTabNames('biz');
                }
                break;
            case 'store':
            console.log("this is called??");
                this.businessID.next(null);
                this.chainID.next(null);
                this.regionID.next(null);
                this.propertyID.next(null);
                this.profitCenterID.next(null);
                this.merchantAccID.next(null);
                this.terminalID.next(null);
                this.periphID.next(null);

                this.storeID.next(entityID);
                if(this.storeID.getValue()){
                    this.previousSelectedNode= JSON.parse(JSON.stringify(this.currentSelectedNode.getValue()))
                    this.currentSelectedNode.next({
                        id:entityID,
                        type:'store'
                    });
                    this.getTabNames(this.storeID.getValue(), "store", 'store');
                    this.fetchInformation('xbcStores', this.storeID.getValue());
                }
                break;
            case 'chain':
                console.log("chain setter in tree service");
                this.businessID.next(null);
                this.regionID.next(null);
                this.propertyID.next(null);
                this.storeID.next(null);
                this.profitCenterID.next(null);
                this.merchantAccID.next(null);
                this.terminalID.next(null);
                this.periphID.next(null);

                this.chainID.next(entityID);
                if(this.chainID.getValue()){
                    this.previousSelectedNode= JSON.parse(JSON.stringify(this.currentSelectedNode.getValue()))
                    this.currentSelectedNode.next({
                        id:entityID,
                        type:'chain'
                    });
                    this.getTabNames(entityID, "struct", 'chain');
                    this.fetchInformation('xbcStructs', this.chainID.getValue());
                }
                break;
            case 'region':
                //      console.log("Tree service k andr region id mod kr rae");
                this.businessID.next(null);
                this.chainID.next(null);
                this.propertyID.next(null);
                this.storeID.next(null);
                this.merchantAccID.next(null);
                this.profitCenterID.next(null);
                this.terminalID.next(null);
                this.periphID.next(null);

                this.regionID.next(entityID);
                if(this.regionID.getValue()){
                    this.previousSelectedNode= JSON.parse(JSON.stringify(this.currentSelectedNode.getValue()))
                    this.currentSelectedNode.next({
                        id:entityID,
                        type:'region'
                    });
                    this.getTabNames(entityID, "struct", 'region');
                    this.fetchInformation('xbcStructs', this.regionID.getValue());
                }
                break;
            case 'property':
                //        console.log("Tree service k andr region id mod kr rae");
                this.businessID.next(null);
                this.chainID.next(null);
                this.regionID.next(null);
                this.storeID.next(null);
                this.merchantAccID.next(null);
                this.profitCenterID.next(null);
                this.terminalID.next(null);
                this.periphID.next(null);

                this.propertyID.next(entityID);
                if(this.propertyID.getValue()){
                    this.previousSelectedNode= JSON.parse(JSON.stringify(this.currentSelectedNode.getValue()))
                    this.currentSelectedNode.next({
                        id:entityID,
                        type:'property'
                    });
                    this.getTabNames(entityID, "struct", 'prop');
                    this.fetchInformation('xbcStructs', this.propertyID.getValue());
                }
                break;
            case 'profit_center':
                this.businessID.next(null);
                this.chainID.next(null);
                this.regionID.next(null);
                this.propertyID.next(null);
                this.storeID.next(null);
                this.merchantAccID.next(null);
                this.terminalID.next(null);
                this.periphID.next(null);

                this.profitCenterID.next(entityID);
                if(this.profitCenterID.getValue()){
                    this.previousSelectedNode= JSON.parse(JSON.stringify(this.currentSelectedNode.getValue()))
                    this.currentSelectedNode.next({
                        id:entityID,
                        type:'profit_center'
                    });
                    this.getTabNames(entityID, "pCtr", 'pCtr');
                    this.fetchParentStoreInformation('xbcPCtrs', this.profitCenterID.getValue());
                }

                break;
            case 'merchant_account':
                this.businessID.next(null);
                this.chainID.next(null);
                this.regionID.next(null);
                this.propertyID.next(null);
                this.storeID.next(null);
                this.profitCenterID.next(null);
                this.terminalID.next(null);
                this.periphID.next(null);

                this.merchantAccID.next(entityID);
                if(this.merchantAccID.getValue()){
                    this.previousSelectedNode= JSON.parse(JSON.stringify(this.currentSelectedNode.getValue()))
                    this.currentSelectedNode.next({
                        id:entityID,
                        type:'merchant_account'
                    });
                    this.getTabNames(entityID, "merAcct", 'merAcct');
                    this.fetchParentStoreInformation('xpcMerAccts', this.merchantAccID.getValue());
                    this.fetchInfoUnderStore('xpcMerAccts', this.merchantAccID.getValue());
                }
                break;
            case 'terminal':
                this.businessID.next(null);
                this.chainID.next(null);
                this.regionID.next(null);
                this.propertyID.next(null);
                this.storeID.next(null);
                this.profitCenterID.next(null);
                this.merchantAccID.next(null);
                this.periphID.next(null);

                this.terminalID.next(entityID);
                if(this.terminalID.getValue()){
                    this.previousSelectedNode= JSON.parse(JSON.stringify(this.currentSelectedNode.getValue()))
                    this.currentSelectedNode.next({
                        id:entityID,
                        type:'terminal'
                    });
                    this.getTabNames(entityID, "terminal", 'terminal');
                    this.fetchParentStoreInformation('xpcTerminals', this.terminalID.getValue());
                    this.fetchInfoUnderStore('xpcTerminals', this.terminalID.getValue());
                }
                break;
            case 'peripheral':
                this.businessID.next(null);
                this.chainID.next(null);
                this.regionID.next(null);
                this.propertyID.next(null);
                this.storeID.next(null);
                this.profitCenterID.next(null);
                this.merchantAccID.next(null);
                this.terminalID.next(null);

                this.periphID.next(entityID);
                if(this.periphID.getValue()){
                    this.previousSelectedNode= JSON.parse(JSON.stringify(this.currentSelectedNode.getValue()))
                    this.currentSelectedNode.next({
                        id:entityID,
                        type:'peripheral'
                    });
                    this.getTabNames(entityID, "periph", 'periph');
                    this.fetchParentStoreInformation('xpcPeriphs', this.periphID.getValue());
                    this.fetchInfoUnderStore('xpcPeriphs', this.periphID.getValue());
                    this.fetchParentTerminalInformation('xpcPeriphs', this.periphID.getValue());
                }
                break;
        }
        // console.log("Node click ho rha hai??", this.nodeClick);
        // if(!this.nodeClick){
            // this.expandSingleNode.next(true);
        // }
    }

    getTabNames(nodeId:string, nodeType:string, key:string){
        this.tabStructureService.getTabStructureNames("parentNodeNames", {
            nodeId: nodeId,
            nodeType: nodeType
        })
        .then((res)=>{
            this.setTabNames(key, res);
            console.log("res inside getTabNames function", res);
            this.parentInfo=JSON.parse(JSON.stringify(res));
        })
    }

    setTabNames(type:string, res?:any){
        if(res){ //when response nad type is sent as argument
        console.log('%c Response', 'background: blue; color: black', res);
            let currObjIndex=5000;
            this.tempStructTabArr.map((tabObj, index)=>{
                // console.error('tabObj', tabObj, index);
                console.log('%c tabObj', 'background: red; color: white', tabObj, index);
                if(tabObj.type === type){
                    tabObj.value= "current";
                    currObjIndex=index;
                }
                else{
                    if(index > currObjIndex){
                        tabObj.value="list";
                    }
                    else{
                        // tabObj.value=res[type];
                        Object.keys(res).map(key=>{
                            if(key === tabObj.type){
                                if(res[key] && res[key].hasOwnProperty("name")){
                                    tabObj.value=res[key].name;
                                }
                                else{
                                    tabObj.value="none";
                                }
                            }
                        })
                    }
                }
            });
            console.log('%c this.tempStructTabArr other case', 'background: green; color: white',  this.tempStructTabArr);
            this.tabStructureService.generateTabLabels(this.tempStructTabArr);
        }
        else{ //when only type is sent as argument (business node case)
            console.log('%c this.tempStructTabArr biz case', 'background: green; color: white',  this.tempStructTabArr);
            this.httpService.get('xbcBusinesses',this.businessID.getValue())
            .subscribe(res=>{
                console.log("---res---", res);
                this.tabStructureService.selectedEntityDetail=JSON.parse(JSON.stringify(res.data));
                this.tempStructTabArr=[
                    {type:"biz", value:"current"},
                    {type:"chain", value:"list"},
                    {type:"region", value:"list"},
                    {type:"prop", value:"list"},
                    {type:"store", value:"list"},
                    {type:"pCtr", value:"list"},
                    {type:"merAcct", value:"list"},
                    {type:"terminal", value:"list"},
                    {type:"periph", value:"list"},
                ];
                this.tabStructureService.generateTabLabels(this.tempStructTabArr);
            });
            // this.tabStructureService.setTabStructureNames(this.tempStructTabArr);
        }
        // if(this.storeDefTabStruct){

            // this.tabStructureService.generateTabLabels(this.tempStructTabArr);
        // }
        // else{
        //     this.tabStructureService.generateTabLabels(this.tempStructTabArr);
        // }
    }

    setShow(show: boolean) {
        console.log("SHOW---)))", show);
        this.show.next(show);
    }

    set skipTreeLoad(skip: boolean) {
        console.log('set skipTreeLoad:',skip);
        this._skipTreeLoad = skip;
    }

    get skipTreeLoad(): boolean {
        return this._skipTreeLoad;
    }

    setEnable(enable: boolean) {
        this.enable.next(enable);
    }

    setCurrentTreestate(object: any) {
        this.objExpandedNodes = object;
    }

    set treeData(tree: any) {
        this._treeData = tree;
    }

    get treeData(): any {
        return this._treeData;
    }
    set nodeClick(value: boolean) {
        this._nodeClick = value;
    }

    get nodeClick(): boolean {
        return this._nodeClick;
    }
    set submitClick(value: boolean) {
        this._submitClick = value;
    }

    get submitClick(): boolean {
        return this._submitClick;
    }
    set treeDataUnderStore(tree: any) {
        this._treeDataUnderStore = tree;
    }

    get treeDataUnderStore(): any {
        return this._treeDataUnderStore;
    }
    set parentTerminal(tree: any) {
        this._parentTerminal = tree;
    }

    get parentTerminal(): any {
        return this._parentTerminal;
    }

    /**
     * Store currently clicked node data
     * @memberof TreeService
     */
    set currentClickedNodeData(data: any) {
        this._currentClickedNodeData = data;
    }

    /**
     * Return currently clicked node data
     * @memberof TreeService
     */
    get currentClickedNodeData(): any {
        return this._currentClickedNodeData;
    }

    // get treeTags():any{
    //     return this._treeTags;
    // }

    resetBusinessID():any{
        // this.businessID.next(null);
        this.setId(null, 'business');
        this._treeData = {};
        this.objExpandedNodes = null;
    }

    resetStore(): any {
        this.storeID.next(null);
        this._treeData = {};
        this.objExpandedNodes = null;
    }

    fetchInformation(endpoint: string, id: string) {
        if (!id) {
            this._treeData = {};
            return;
        }
        this.httpService.get(endpoint, id).subscribe(
            (response: Response | any) => {
                this._treeData = response.data;
            },
            (error: any) => {
                console.log(error, 'error while fetching property');
            }
        );
    }

    fetchParentStoreInformation(endPoint: string, id: string) {
        if (!id) {
            this._treeData = {};
            return;
        }
        this.httpService.get(endPoint, id).subscribe(
            (response: Response | any) => {
                console.log("Here", JSON.parse(JSON.stringify(response)));
                this.fetchInformation('xbcStores', response.data ? response.data.xStoreID : null);
                this.xGTID = response.data.xGTID;
                this.xName = response.data.xName;
                if (response.data.xMainPCtrID) {
                    this.fetchPCtrGMID(response.data.xMainPCtrID)
                }
            },
            (error: any) => {
                console.log(error, 'error while fetching merchant_accnt');
            }
        );
    }

    fetchInfoUnderStore(endpoint, id) {
        if (!id) {
            this._treeDataUnderStore = {};
            return;
        }
        this.httpService.get(endpoint, id).subscribe(
            (response: Response | any) => {
                console.log("Here 2", JSON.parse(JSON.stringify(response)));
                this._treeDataUnderStore = response.data;
            },
            (error: any) => {
                console.log(error, 'error while fetching property');
            }
        );
    }

    fetchParentTerminalInformation(endpoint: string, id: string) {
        if (!id) {
            this._parentTerminal = {};
            return;
        }
        this.httpService.get(endpoint, id).subscribe(
            (response: Response | any) => {
                this.httpService.get('xpcTerminals', response.data ? response.data.xTerminalID : null).subscribe(
                    (response: Response | any) => {
                        this._parentTerminal = response.data;
                    },
                    (error: any) => {
                        console.log(error, 'error while fetching property');
                    }
                );
            },
            (error: any) => {
                console.log(error, 'error while fetching terminal');
            }
        );
    }
     registerTerminal(xGMID: string, xSerialNumber: string, xName: string , xTerminalType?: string, xGTID?: string, register?: string){
        let msg_words = ['', ''];
        let url = this.API_REGISTER + xTerminalType + '?GMID=' + xGMID
        let body = {
            GMID: xGMID,
            GTID: xGTID,
            GMPW:"123",
            SN: xSerialNumber,
            TerminalName: xName,
            TransType:"Register",
            ModuleName:'Portal',
            IsOffline: 'N',
        };
        switch (register.toLowerCase()) {
            case 'register':
                body.TransType = "Register";
                msg_words = ['registering', 'Registered'];
            break;
            case 'unregister':
                body.TransType = "Unregister";
                msg_words = ['unregistering', 'Unregistered']
            break;
        }

        /// This url should be changed after test
        return new Promise((resolve, reject) => {
        this.httpService.store(url, body).subscribe(
            (response: Response | any) => {
                if (response.Status === 'fail') {
                    this.notificationService.error('Error while ' + msg_words[0] + ' terminal.  \n ERROR :'
                        +  response.ErrorText + '. \n CODE: ' + response.ErrorCode, 'Error!');
                        return resolve(false);
                } else {
                    this.notificationService.success('Terminal ' + msg_words[1] + ' Succesfully', 'Success!');
                    return resolve(true);
                }
                },
            (error: any) => {
                this.notificationService.error('Error while ' + msg_words[0] + ' terminal', 'Error!')
                return reject(error);
            })
        })

    }
    fetchPCtrGMID (profitCenterId: string) {
        this.httpService.get('xbcPCtrs', profitCenterId).subscribe(
            (res: Response | any) => {
                this.xGMID = res.data.xGMID;
            },
            (error) => {
                console.log(error, 'error while fetching GMID')
            }
        )
    }
}
