import { Injectable } from '@angular/core';
 

import { HttpService } from '../shared/services';
const endpoint = 'xsjStats';

@Injectable()
export class StatisticsStore {

    constructor(protected httpService: HttpService) { }

    getAll(params: any): any {
        return this.httpService.getAll(endpoint, params);
    }

    store(element: any): any {
        return this.httpService.store(endpoint, element);
    }

    update(elementID: string, newElement: any): any {
        return this.httpService.update(endpoint, elementID, newElement);
    }

    get(elementID: any): any {
        return this.httpService.get(endpoint, elementID);
    }

    destroy(elementID: any): any {
        return this.httpService.destroy(endpoint, elementID);
    }
}
