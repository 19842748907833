import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http-service';
import { TransactionActionService } from './transaction-action-service';
const endpoint = 'reports';
const batchEndPoint = 'batchReports';
const settleSuccessEndpoint = 'settle_action/success_txns_reports';
const settleFailedEndpoint = 'settle_action/fail_txns_reports';

@Injectable()
export class SummaryReportService {
    checkedObjs: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    unCheckedObjs: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    watchCheckedObjs$ = this.checkedObjs.asObservable();
    watchUnCheckedObjs$ = this.unCheckedObjs.asObservable();
    typesArray:any[]=[];
    _selectAll:boolean=false;
    _print:boolean=false;
    _parameters: any={};
    constructor(
        private httpService: HttpService,
        private transactionActionService: TransactionActionService
    ) {}

    setCheckedObj(checkedObjs: string[]): void {
        console.log("checkedObj in setter", checkedObjs);
        this.checkedObjs.next(checkedObjs);

    }
    setUnCheckedObj(unCheckedObjs: string[]): void {
        console.log("checkedObj in setter", unCheckedObjs);
        this.unCheckedObjs.next(unCheckedObjs);

    }

    set selectAll(isSelectAll: boolean){
        this._selectAll= isSelectAll;
    }

    
    get selectAll(){
        return this._selectAll;
    }
    
    set parameters(params: any){
        this._parameters= params;
    }

    get parameters(){
        return this._parameters;
    }
    
    set print(print: boolean){
        this._print= print;
    }

    get print(): boolean{
        return this._print;
    }

    setTypes(types:string[]):void{
       this.typesArray=types;
    }

    getTypes(){
       return this.typesArray;
    }

    getReports(element: any, txnBatchtype:string): any{
        console.log("HEREEEEEEEEEEEEEEEEEEE 2", element, txnBatchtype, this.transactionActionService.settleSuccess, this.transactionActionService.settleFailed);
        let endPoint='';
        if(this.transactionActionService.settleSuccess){
            endPoint=settleSuccessEndpoint;
        }
        else if(this.transactionActionService.settleFailed){
            endPoint=settleFailedEndpoint;
        }
        else{
            if(txnBatchtype === 'txn' || txnBatchtype === 'settledTxn'){
                endPoint=endpoint
    
            }
            else if( txnBatchtype === 'batch'){
                endPoint= settleFailedEndpoint;
            }
        }
        
        console.log("HEREEEEEEEEEEEEEEEEEEE endPoint",endPoint, element);
        return this.httpService
            .store(endPoint, element)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }


    // getCheckedIDs():string[]{
    //     return this.checkedIDs;
    // }

    // pushToCheckedIDs(storeId:string){
    //     this.checkedIDs.push(storeId);
    // }


}