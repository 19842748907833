export class Social {
    // xPhone: string;
    xWebsite: string;
    xFacebook: string;
    xTwitter: string;
    xLinkedIn: string;

    constructor(obj?: any) {
        // this.xPhone = (obj && obj.xPhone) || '';
        this.xWebsite = (obj && obj.xWebsite) || '';
        this.xFacebook = (obj && obj.xFacebook) || '';
        this.xTwitter = (obj && obj.xTwitter) || '';
        this.xLinkedIn = (obj && obj.xLinkedIn) || '';
    }
}
