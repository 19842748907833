<section *ngIf="!loading" class="setup buis-info">
    <form class="form-horizontal" [ngClass]="{'disable-form':(parentMode || userHandlerService?.userRoles?.isBusinessEmployee)}"
        [formGroup]="form" (ngSubmit)="onSubmit(form)">
        <div class="row top-stat">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-check-circle-o  label-icon" aria-hidden="true"></i>
                        <label class="p-l-5" for="name">Status:
                        </label>
                        <label class="detail-label"> Active
                        </label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-sliders label-icon" aria-hidden="true"></i>
                        <label for="name" class="p-l-5">Type:</label>
                        <label class="detail-label">Peripheral</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-map-marker label-icon" aria-hidden="true"></i>
                        <label for="dba_name" class="p-l-5">Store:</label>
                        <label class="detail-label">{{storeName}}</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-level-up label-icon fa-flip-horizontal" aria-hidden="true"></i>
                        <label for="dba_name" class="p-l-5">Terminal:</label>
                        <label class="detail-label">{{terName}}</label>
                        <!--<select class="form-control form-control-sm" id="xdevice_id" formControlName="xTerminalID">
                            <option value=null selected="!peripheralInfo.xTerminalID">Select Payment Device </option>
                                <option *ngFor="let device of paymentDeviceList" [value]="device._id">{{device.xName}}</option>
                        </select>-->
                    </div>
                </div>
            </div>
        </div>
        <div>
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">
                        <label class=null>Basic Information </label>
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-12 col-sm-12">
                                <label for="name">Name:
                                    <small class="text-danger">*</small>
                                </label>
                                <small *ngIf="!serverErrors['xName'] && form.get('xName').hasError('required') && form.get('xName').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>
                                <small *ngIf="!serverErrors['xName'] && form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is of incorrect format. Correct Format-: 1) Name can not be more than 20 letters. 2) The first character of Name should not be an empty space. 3) The first character of Name should not be any special characters, E.g. /,& etc."
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                                <small *ngIf="serverErrors['xName']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>
                                <input type="text" (focus)="clearErrors('xName')" class="form-control form-control-sm" id="name" formControlName="xName"
                                    [ngClass]="{'has-error': (form.get('xName').hasError('required') && form.get('xName').touched) || (form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched) || serverErrors['xName']}"
                                    validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-12">
                                <label for="tag">Tag: </label>
                                <input type="text" class="form-control form-control-sm" id="tag" (focus)="clearErrors('xTag')" formControlName="xTag">
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="ref_num">Ref Number: </label>
                                <i class="fa fa-info-circle" tooltip="Do not enter the reference number unless instructed to do so" placement="top" containerClass="tooltip-global"
                                    container="body" aria-hidden="true"></i>
                                <input type="text" class="form-control form-control-sm" id="ref_num" (focus)="clearErrors('xRefNum')" formControlName="xRefNum">
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-6 col-sm-12">
                                <label for="periph_num">Peripheral Number:
                                    <small class="text-danger">*</small>
                                </label>
                                <!-- <input type="text" class="form-control form-control-sm" id="periph_num" (focus)="clearErrors('xPeriphNum')" formControlName="xPeriphNum"> -->
                                <app-model-number [modelNumber]=peripheralInfo.xPeriphNum [form]=form [serverErrors]=serverErrors [numberKey]=numberKey></app-model-number>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="short_code">Short Code:
                                    <small class="text-danger">*</small>
                                </label>
                                <small *ngIf="!serverErrors['xShortCode'] && form.get('xShortCode').hasError('required') && form.get('xShortCode').touched"
                                    class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Unique Short Code is required" placement="top"
                                        containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                                <small *ngIf="serverErrors['xShortCode']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Unique Short Code is required" placement="top"
                                        containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                                <input type="text" class="form-control form-control-sm" id="short_code" [ngClass]="{'has-error': (form.get('xShortCode').hasError('required') && form.get('xShortCode').touched) || serverErrors['xShortCode']}"
                                    (focus)="clearErrors('xShortCode')" formControlName="xShortCode">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-b-10 clearfix">
                    <tabset>
                        <tab formGroupName='xHWSysInfo'>
                            <ng-template tabHeading>
                                HW System Info
                            </ng-template>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Make: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xMakeTag'] && form.get('xHWSysInfo.xMakeTag').hasError('required') && form.get('xHWSysInfo').get('xMakeTag').touched"
                                        class="inline-error"> Line1 is required</small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xMakeTag']" class="inline-error"> {{ serverErrors['xHWSysInfo.xMakeTag']?.message.replace('"xMakeTag"', 'Field')}}</small>

                                    <input type="text" class="form-control form-control-sm" id="line1" (focus)="clearErrors('xHWSysInfo.xMakeTag')" formControlName="xMakeTag"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xMakeTag').hasError('required') && form.get('xHWSysInfo').get('xMakeTag').touched) || serverErrors['xHWSysInfo.xMakeTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Model Number: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xModelNum'] && form.get('xHWSysInfo').get('xModelNum').hasError('required') && form.get('xHWSysInfo').get('xModelNum').touched"
                                        class="inline-error"> Model Number is required</small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xModelNum']" class="inline-error"> {{ serverErrors['xHWSysInfo.xModelNum']?.message.replace('"xModelNum"', 'Field')}}</small>

                                    <input type="text" class="form-control form-control-sm" id="line1" (focus)="clearErrors('xHWSysInfo.xModelNum')" formControlName="xModelNum"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xModelNum').hasError('required') && form.get('xHWSysInfo').get('xModelNum').touched) || serverErrors['xHWSysInfo.xModelNum']}"
                                        validateOnBlur>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Family: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xFamilyTag'] && form.get('xHWSysInfo').get('xFamilyTag').hasError('required') && form.get('xHWSysInfo').get('xFamilyTag').touched"
                                        class="inline-error"> Line1 is required</small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xFamilyTag']" class="inline-error"> {{ serverErrors['xHWSysInfo.xFamilyTag']?.message.replace('"xFamilyTag"', 'Field')}}</small>

                                    <input type="text" class="form-control form-control-sm" id="line1" (focus)="clearErrors('xHWSysInfo.xFamilyTag')" formControlName="xFamilyTag"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xFamilyTag').hasError('required') && form.get('xHWSysInfo').get('xFamilyTag').touched) || serverErrors['xHWSysInfo.xFamilyTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Serial Number: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xSerNum'] && form.get('xHWSysInfo').get('xSerNum').hasError('required') && form.get('xHWSysInfo').get('xSerNum').touched"
                                        class="inline-error"> Serial Number is required</small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xSerNum']" class="inline-error"> {{ serverErrors['xHWSysInfo.xSerNum']?.message.replace('"xSerNum"', 'Field')}}</small>

                                    <input type="text" class="form-control form-control-sm" id="line1" (focus)="clearErrors('xHWSysInfo.xSerNum')" formControlName="xSerNum"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xSerNum').hasError('required') && form.get('xHWSysInfo').get('xSerNum').touched) || serverErrors['xHWSysInfo.xSerNum']}"
                                        validateOnBlur>
                                </div>
                            </div>
                            <div class="form-group row no-margin">
                                <div class="col-md-6">
                                    <label>Model: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xModelTag'] && form.get('xHWSysInfo').get('xModelTag').hasError('required') && form.get('xHWSysInfo').get('xModelTag').touched"
                                        class="inline-error"> Line1 is required</small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xModelTag']" class="inline-error"> {{ serverErrors['xHWSysInfo.xModelTag']?.message.replace('"xModelTag"', 'Field')}}</small>

                                    <input type="text" class="form-control form-control-sm" id="line1" (focus)="clearErrors('xHWSysInfo.xModelTag')" formControlName="xModelTag"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xModelTag').hasError('required') && form.get('xHWSysInfo').get('xModelTag').touched) || serverErrors['xHWSysInfo.xModelTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Version: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xVersion'] && form.get('xHWSysInfo').get('xVersion').hasError('required') && form.get('xHWSysInfo').get('xVersion').touched"
                                        class="inline-error"> Line1 is required</small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xVersion']" class="inline-error"> {{ serverErrors['xHWSysInfo.xVersion']?.message.replace('"xVersion"', 'Field')}}</small>

                                    <input type="text" class="form-control form-control-sm" id="line1" (focus)="clearErrors('xHWSysInfo.xVersion')" formControlName="xVersion"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xVersion').hasError('required') && form.get('xHWSysInfo').get('xVersion').touched) || serverErrors['xHWSysInfo.xVersion']}"
                                        validateOnBlur>
                                </div>
                            </div>
                        </tab>
                        <tab formGroupName='xSWSysInfo'>
                            <ng-template tabHeading>
                                SW System Info
                            </ng-template>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Make: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xMakeTag'] && form.get('xHWSysInfo').get('xMakeTag').hasError('required') && form.get('xHWSysInfo').get('xMakeTag').touched"
                                        class="inline-error"> Line1 is required</small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xMakeTag']" class="inline-error"> {{ serverErrors['xHWSysInfo.xMakeTag']?.message.replace('"xMakeTag"', 'Field')}}</small>

                                    <input type="text" class="form-control form-control-sm" id="line1" (focus)="clearErrors('xHWSysInfo.xMakeTag')" formControlName="xMakeTag"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xMakeTag').hasError('required') && form.get('xHWSysInfo').get('xMakeTag').touched) || serverErrors['xHWSysInfo.xMakeTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Model Number: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xModelNum'] && form.get('xHWSysInfo').get('xModelNum').hasError('required') && form.get('xHWSysInfo').get('xModelNum').touched"
                                        class="inline-error"> Model Number required</small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xModelNum']" class="inline-error"> {{ serverErrors['xHWSysInfo.xModelNum']?.message.replace('"xModelNum"', 'Field')}}</small>

                                    <input type="text" class="form-control form-control-sm" id="line1" (focus)="clearErrors('xHWSysInfo.xModelNum')" formControlName="xModelNum"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xModelNum').hasError('required') && form.get('xHWSysInfo').get('xModelNum').touched) || serverErrors['xHWSysInfo.xModelNum']}"
                                        validateOnBlur>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Family: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xFamilyTag'] && form.get('xHWSysInfo').get('xFamilyTag').hasError('required') && form.get('xHWSysInfo').get('xFamilyTag').touched"
                                        class="inline-error"> Line1 is required</small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xFamilyTag']" class="inline-error"> {{ serverErrors['xHWSysInfo.xFamilyTag']?.message.replace('"xFamilyTag"', 'Field')}}</small>

                                    <input type="text" class="form-control form-control-sm" id="line1" (focus)="clearErrors('xHWSysInfo.xFamilyTag')" formControlName="xFamilyTag"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xFamilyTag').hasError('required') && form.get('xHWSysInfo').get('xFamilyTag').touched) || serverErrors['xHWSysInfo.xFamilyTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Serial Number: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xSerNum'] && form.get('xHWSysInfo').get('xSerNum').hasError('required') && form.get('xHWSysInfo').get('xSerNum').touched"
                                        class="inline-error"> Serial Number is required</small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xSerNum']" class="inline-error"> {{ serverErrors['xHWSysInfo.xSerNum']?.message.replace('"xSerNum"', 'Field')}}</small>

                                    <input type="text" class="form-control form-control-sm" id="line1" (focus)="clearErrors('xHWSysInfo.xSerNum')" formControlName="xSerNum"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xSerNum').hasError('required') && form.get('xHWSysInfo').get('xSerNum').touched) || serverErrors['xHWSysInfo.xSerNum']}"
                                        validateOnBlur>
                                </div>
                            </div>
                            <div class="form-group row no-margin">
                                <div class="col-md-6">
                                    <label>Model: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xModelTag'] && form.get('xHWSysInfo').get('xModelTag').hasError('required') && form.get('xHWSysInfo').get('xModelTag').touched"
                                        class="inline-error"> Line1 is required</small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xModelTag']" class="inline-error"> {{ serverErrors['xHWSysInfo.xModelTag']?.message.replace('"xModelTag"', 'Field')}}</small>

                                    <input type="text" class="form-control form-control-sm" id="line1" (focus)="clearErrors('xHWSysInfo.xModelTag')" formControlName="xModelTag"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xModelTag').hasError('required') && form.get('xHWSysInfo').get('xModelTag').touched) || serverErrors['xHWSysInfo.xModelTag']}"
                                        validateOnBlur>
                                </div>
                                <div class="col-md-6">
                                    <label>Version: </label>
                                    <small *ngIf="!serverErrors['xHWSysInfo.xVersion'] && form.get('xHWSysInfo').get('xVersion').hasError('required') && form.get('xHWSysInfo').get('xVersion').touched"
                                        class="inline-error"> Version is required</small>

                                    <small *ngIf="serverErrors['xHWSysInfo.xVersion']" class="inline-error"> {{ serverErrors['xHWSysInfo.xVersion']?.message.replace('"xVersion"', 'Field')}}</small>

                                    <input type="text" class="form-control form-control-sm" id="line1" (focus)="clearErrors('xHWSysInfo.xVersion')" formControlName="xVersion"
                                        [ngClass]="{'has-error': (form.get('xHWSysInfo').get('xVersion').hasError('required') && form.get('xHWSysInfo').get('xVersion').touched) || serverErrors['xHWSysInfo.xVersion']}"
                                        validateOnBlur>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>

                <div class="card">
                    <div class="card-header">
                        <label>Connection Capabilities</label>
                    </div>
                    <div class="card-body">
                        <div formGroupName='xConnCaps'>
                            <div class="form-group row">
                                <div class="col-md-6 col-sm-12">
                                    <label for="man_entry">Serial Cap: </label>
                                </div>
                                <div class="col-md-6 col-sm-12 merch-info">
                                    <div class="merch_type">
                                        <select class="form-control form-control-sm" id="serial_cap" formControlName="xHasSerConn">
                                            <option value=null selected="!peripheralInfo.xHasSerConn">Select Serial connection Type </option>
                                            <!-- <option *ngFor="let aacEntTag of accountEntryTags" [value]="aacEntTag.xName">{{aacEntTag.xName}}</option> -->
                                            <!--<option [value]=true>Yes</option>
                                            <option [value]=false>No</option>-->
                                            <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                        </select>
                                        <span>
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6 col-sm-12">
                                    <label for="man_entry">USB Cap: </label>
                                </div>
                                <div class="col-md-6 col-sm-12 merch-info">
                                    <div class="merch_type">
                                        <select class="form-control form-control-sm" id="usb_cap" formControlName="xHasUSBConn">
                                            <option value=null selected="!peripheralInfo.xHasUSBConn">Select USB Type </option>
                                            <!-- <option *ngFor="let aacEntTag of accountEntryTags" [value]="aacEntTag.xName">{{aacEntTag.xName}}</option> -->
                                            <!--<option>Yes</option>
                                            <option>No</option>-->
                                            <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                        </select>
                                        <span>
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6 col-sm-12">
                                    <label for="man_entry">Ethernet Cap: </label>
                                </div>
                                <div class="col-md-6 col-sm-12 merch-info">
                                    <div class="merch_type">
                                        <select class="form-control form-control-sm" id="ethernet_cap" formControlName="xHasEthConn">
                                            <option value=null selected="!peripheralInfo.xHasEthConn">Select Ethernet Type </option>
                                            <!-- <option *ngFor="let aacEntTag of accountEntryTags" [value]="aacEntTag.xName">{{aacEntTag.xName}}</option> -->
                                            <!--<option>Yes</option>
                                            <option>No</option>-->
                                            <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                        </select>
                                        <span>
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6 col-sm-12">
                                    <label for="man_entry">Wifi Cap: </label>
                                </div>
                                <div class="col-md-6 col-sm-12 merch-info">
                                    <div class="merch_type">
                                        <select class="form-control form-control-sm" id="wifi_cap" formControlName="xHasWifiConn">
                                            <option value=null selected="!peripheralInfo.xHasWifiConn">Select WiFi Type </option>
                                            <!-- <option *ngFor="let aacEntTag of accountEntryTags" [value]="aacEntTag.xName">{{aacEntTag.xName}}</option> -->
                                            <!--<option>Yes</option>
                                            <option>No</option>-->
                                            <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                        </select>
                                        <span>
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6 col-sm-12">
                                    <label for="man_entry">Bluetooth Cap: </label>
                                </div>
                                <div class="col-md-6 col-sm-12 merch-info">
                                    <div class="merch_type">
                                        <select class="form-control form-control-sm" id="bluetooth_cap" formControlName="xHasBTConn">
                                            <option value=null selected="!peripheralInfo.xHasBTConn">Select Bluetooth Type </option>
                                            <!-- <option *ngFor="let aacEntTag of accountEntryTags" [value]="aacEntTag.xName">{{aacEntTag.xName}}</option> -->
                                            <!--<option>Yes</option>
                                            <option>No</option>-->
                                            <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                        </select>
                                        <span>
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6 col-sm-12">
                                    <label for="man_entry">Cell Cap: </label>
                                </div>
                                <div class="col-md-6 col-sm-12 merch-info">
                                    <div class="merch_type">
                                        <select class="form-control form-control-sm" id="cell_cap" formControlName="xHasCellConn">
                                            <option value=null selected="!peripheralInfo.xHasCellConn">Select Cellular connection Type </option>
                                            <!-- <option *ngFor="let aacEntTag of accountEntryTags" [value]="aacEntTag.xName">{{aacEntTag.xName}}</option> -->
                                            <!--<option>Yes</option>
                                            <option>No</option>-->
                                            <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                        </select>
                                        <span>
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-12 col-sm-12">
                                <label for="uname">Connection URL: </label>
                                <input type="text" (focus)="clearErrors('xConnUrl')" class="form-control form-control-sm" id="connUrl" formControlName="xConnUrl">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">
                        <label>Entry Capabilities</label>
                    </div>
                    <div class="card-body" formGroupName='xEntrCaps'>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">Manual Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="man_entry" formControlName="xHasManEntr">
                                        <option value=null selected="peripheralInfo.xHasManEntr">Select Manual Entry Type </option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="msr_entry">MSR Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="msr_entry" formControlName="xHasMSREntr">
                                        <option value=null selected="!peripheralInfo.xHasMSREntr">Select MSR Entry Type </option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">ICC Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="icc_entry" formControlName="xHasICCEntr">
                                        <option value=null selected="!peripheralInfo.xHasICCEntr">Select ICC Entry Type {{peripheralInfo.xHasICCEntr}} </option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">Contactless Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="cles_entry" formControlName="xHasClessEntr">
                                        <option value=null selected="!peripheralInfo.xHasClessEntr">Select Contactless Entry Type </option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">MICR Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="micr_entry" formControlName="xHasMICREntr">
                                        <option value=null selected="!peripheralInfo.xHasMICREntr">Select MICR Entry Type </option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">PIN Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="pin_entry" formControlName="xHasPINEntr">
                                        <option value=null selected="!peripheralInfo.xPINEntrTag">Select PIN Entry Type </option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">Signature Entry: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="sig_entry" formControlName="xHasSigEntr">
                                        <option value=null selected="!peripheralInfo.xHasSigEntr">Select Signature Entry Type </option>
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <label>Output Capabilities</label>
                    </div>
                    <div class="card-body" formGroupName='xOutpCaps'>
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">Screen Cap: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="screen_cap" formControlName="xHasScrOutp">
                                        <option value=null selected="!peripheralInfo.xHasScrOutp">Select screen Type </option>
                                        <!-- <option *ngFor="let aacEntTag of accountEntryTags" [value]="aacEntTag.xName">{{aacEntTag.xName}}</option> -->
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">Printer Cap: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" id="printer_cap" formControlName="xHasPrnOutp">
                                        <option value=null selected="!peripheralInfo.xHasPrnOutp">Select Printer Type </option>
                                        <!-- <option *ngFor="let aacEntTag of accountEntryTags" [value]="aacEntTag.xName">{{aacEntTag.xName}}</option> -->
                                        <option *ngFor="let val of capabilities" [ngValue]="val.value">{{val.label}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <label>Security Capabilities</label>
                    </div>
                    <div class="card-body" formGroupName='xSecCaps'>
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">Certificate Cap: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" formControlName="xCertTag">
                                        <option value="" selected="!peripheralInfo.xCertTag">Select certificate type </option>
                                        <option *ngFor="let certTag of certificateCapTags" [value]="certTag.xTag">{{certTag.xName}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-12">
                                <label for="man_entry">Encryption Cap: </label>
                            </div>
                            <div class="col-md-6 col-sm-12 merch-info">
                                <div class="merch_type">
                                    <select class="form-control form-control-sm" formControlName="xEncTag">
                                        <option value="" selected="!peripheralInfo.xEncTag">Select Encryption type </option>
                                        <option *ngFor="let encTag of encryptionCapTags" [value]="encTag.xTag">{{encTag.xName}}</option>
                                    </select>
                                    <span>
                                        <i class="fa fa-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-12 col-sm-12">
                                <label for="cert_num">Certificate Number: </label>
                                <input type="text" class="form-control form-control-sm" id="cert_num" (focus)="clearErrors('xCertNum')" formControlName="xCertNum">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <label>Notes</label>
                    </div>
                    <div class="form-group  no-margin">
                        <div class=null>
                            <small class="inline-error"> </small>

                            <textarea class="form-control notes-area" rows="5" id="notes" (focus)="clearErrors('xNotes')" formControlName="xNotes" style="min-height: 174px;"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row  m-t-5" *ngIf='!(parentMode || userHandlerService?.userRoles?.isBusinessEmployee)'>
            <div class="col-sm-12 text-right">
                <!-- <a class="btn btn-secondary" [routerLink]="['/dashboard/setup/business/structure/information']" [routerLinkActive]="['router-link-active']"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a> -->
                <button class="btn btn-secondary" (click)="onCancel($event)">
                    <i class="fa fa-times" aria-hidden="true"></i> Cancel</button>
                <span 
                    class="tooltip-wrap-span" 
                    [tooltip]="(edit && !canEdit) || (!edit && !canAdd) ? noPermMsg : 'Save'" 
                    placement="left" 
                    container="body" 
                    containerClass="tooltip-global"
                >
                    <button class="btn btn-primary" name="next" type="submit" [disabled]="submitting || (edit && !canEdit) || (!edit && !canAdd)">
                        <span *ngIf="!submitting"> Save
                            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                        </span>
                        <span *ngIf="submitting">Please wait...
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </span>
                    </button>
                </span>
            </div>
        </div>
    </div>
    </form>
</section>
<section *ngIf="edit && loading">
    <div style="text-align: center; position:realtive">
        <div class="col-md-12">
            <!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="color:#445d75;position:relative; top:50px; text-align:Center"></i>
            <span class="sr-only">Loading Tags...</span> -->
            <div class="grid-spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <span style="color:#3b5877">Fetching data</span>
        </div>
    </div>
</section>