import { STORE_NAMES } from '../../../constants'

export function setPermissions(storeValue, userPermssions, roles){
	// console.log('storeValue', storeValue);
	// console.log('userPermssions', userPermssions);
	const gridBtnController = {
		viewNodeDetails: true,
		viewTxnDetails: true,
		viewSafeDetails: true,
		canGenerateReport: true,
		canCreateNewBilling:true,
		viewCustDetails: true,
		viewEmpDetails: true,
		viewRepDetails: true,
		viewRoleDetails: true,
		canImportTxn: true,
		viewCompanyDetails: true,
		viewImportTxnDetails: true
	}
	if(roles.isDealer || roles.isBusinessEmployee){
		gridBtnController.viewNodeDetails = userPermssions.canViewSetup;
	}
	if(storeValue === STORE_NAMES.transactionsStore){
		gridBtnController.viewTxnDetails = userPermssions.canViewTxnDetails;
	}
	if(storeValue === STORE_NAMES.memberAccountStore){
		gridBtnController.viewSafeDetails = userPermssions.canViewSafe;
	}
	if(storeValue === STORE_NAMES.transactionsStore || storeValue === STORE_NAMES.settledTransactionsStore){
		gridBtnController.canGenerateReport = userPermssions.canGenerateReportTxn;
	}
	if(storeValue === STORE_NAMES.billingStore || storeValue === STORE_NAMES.invoiceStore){
		gridBtnController.canCreateNewBilling = userPermssions.canCreateBilling;
	}
	if(storeValue === STORE_NAMES.customerStore){
		gridBtnController.viewCustDetails = userPermssions.canViewCust;
	}
	if(storeValue === STORE_NAMES.employeeStore){
		gridBtnController.viewEmpDetails = userPermssions.canViewEmp;
	}
	if(storeValue === STORE_NAMES.dealerStore){
		gridBtnController.viewRepDetails = userPermssions.canViewRep;
	}
	if(storeValue === STORE_NAMES.rolesStore){
		gridBtnController.viewRoleDetails = userPermssions.canViewRole;
	}
	if(storeValue === STORE_NAMES.importTransactionsStore || storeValue === STORE_NAMES.transactionsStore){
		gridBtnController.canImportTxn = userPermssions.canAddImportTxn;
	}
	if(storeValue === STORE_NAMES.companyStore){
		gridBtnController.viewCompanyDetails = userPermssions.canViewComp;
	}
	if(storeValue === STORE_NAMES.importTransactionsStore){
		gridBtnController.viewImportTxnDetails = userPermssions.canViewImportTxn
	}
	console.log('btnControllerObj: setPermissions', gridBtnController);
	return gridBtnController;
}