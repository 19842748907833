import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HttpService, NotificationService, LoginStoreService } from '../../shared/services';

const FORGOT_PASSWORD_ENDPOINT = "user/forgotPasswords";
const SUCCESS_MESSAGE = "Check your email for reset link.";
const FAIL_MESSAGE = "Something went wrong. Please try again.";
const FAIL_FROM_VERIFICATION_MSG = 'If user exists in the system, Reset link will be sent to the registered email address.';
const GET_LINK = "Get Link";
const GET_LINK_AGAIN = "Get Link Again";
const GET_LINK_DISABLE_TIMER = 30000;
const GET_LINK_POLL_TIMER = 1000;
const TOKEN_FOR_LOGIN_AS_CUSTOMER_KEY: string = 'tokenForLoginAsCustomer';
const CUSTOMER_LINK = '/customer/login';
const DEALER_LINK = '/login';
const PARAM_KEY = '?param=';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    constructor(
        private httpService: HttpService, 
        public notificationService: NotificationService, 
        private router: Router,
        private loginStoreService: LoginStoreService
        ) {}
    public userName: string = '';
    emailResponse: any = { error: false, message: '' };
    public disable: boolean = false;
    public buttonText: string = GET_LINK;

    ngOnInit() {}
    getLink(formVal) {
        console.log('get link called', formVal);
        let date = new Date();
        console.log('getLink date isostring', date.toISOString());
        let params = {
            xLoginUName: formVal && formVal.hasOwnProperty('userName') && formVal['userName'] ? formVal['userName'] : '',
            requestTimeStamp: date.toISOString()
        };
        if(this.loginStoreService[TOKEN_FOR_LOGIN_AS_CUSTOMER_KEY]){
            params = Object.assign({}, params, {custLoginToken: this.loginStoreService[TOKEN_FOR_LOGIN_AS_CUSTOMER_KEY]});
        }
        this.httpService.authenticatedAPI = false;
        this.disable = true;
        this.emailResponse = { error: false, message: '' };
        console.log('getLink: timer start at', new Date());
        let disableTimer = setTimeout(() => {
            this.disable = false;
            console.log('getLink: timer end at', new Date());
        }, GET_LINK_DISABLE_TIMER);
        this.httpService.store(FORGOT_PASSWORD_ENDPOINT, params).subscribe(
            res => {
                this.httpService.authenticatedAPI = true;
                this.buttonText = GET_LINK_AGAIN;
                clearTimeout(disableTimer);
                console.log('getLink res', res);
                if (res && res.hasOwnProperty('action_id') && res['action_id']) {
                    const actionID = res['action_id'];
                    this.emailResponse = {
                        error: false,
                        message: 'A request has been generated. You will recieve an e-mail shortly.'
                    };
                    this.startQueuePolling(
                        actionID,
                        (that, response) => {
                            console.log('callback: response', response);
                            const email =
                                response &&
                                response.hasOwnProperty('req_query_params') &&
                                response['req_query_params'].hasOwnProperty('email') &&
                                response['req_query_params']['email']
                                    ? response['req_query_params']['email']
                                    : '';
                            this.emailResponse = {
                                error: false,
                                message: 'An email has been sent to : ' + email
                            };
                            this.disable = false;
                            this.notificationService.success(SUCCESS_MESSAGE, 'Success');
                        },
                        GET_LINK_POLL_TIMER
                    );
                } else {
                    // let message =
                    //     res.hasOwnProperty("message") && res["message"]
                    //         ? res["message"]
                    //         : FAIL_MESSAGE;
                    this.disable = false;
                    // this.notificationService.error(message, "Error");
                    this.notificationService.info(FAIL_FROM_VERIFICATION_MSG);
                }
            },
            err => {
                this.httpService.authenticatedAPI = true;
                this.disable = false;
                clearTimeout(disableTimer);
                console.log('getLink err', err);
                this.notificationService.error(FAIL_MESSAGE, 'Error');
            }
        );
        // if (this.userName === "error") {
        //     this.emailResponse = {
        //         error: true,
        //         message: "Email not found for user name : " + this.userName
        //     };
        // } else {
        //     this.emailResponse = {
        //         error: false,
        //         message: "Email been sent to 'startxxxend@mailservice.com'."
        //     };
        // }
    }

    routeToLoginPage() {
        console.log('previous page called');
        let link = '';
        const token = this.loginStoreService.tokenForLoginAsCustomer;
        console.log('token: routeToLoginPage: forgot password', token);
        if(token){
            link = CUSTOMER_LINK + PARAM_KEY + token;
        }else{
            link = DEALER_LINK;
        }
        this.router.navigateByUrl(link);
    }

    startQueuePolling(requestID, callBackFunction, pollTime) {
        return new Promise((resolve, reject) => {
            let queueTimer = setInterval(() => {
                this.httpService.authenticatedAPI = false;
                this.httpService.get('action_worker_queues', requestID).subscribe(
                    res => {
                        this.httpService.authenticatedAPI = true;
                        if (
                            res.hasOwnProperty('action_progress') &&
                            res.action_progress >= 100 &&
                            res.hasOwnProperty('action_output') &&
                            res.action_output.hasOwnProperty('message') &&
                            res.action_output.message
                        ) {
                            clearInterval(queueTimer);
                            if (res.hasOwnProperty('status') && res['status'] === 'Failed') {
                                if (
                                    res.hasOwnProperty('action_output') &&
                                    res.action_output.hasOwnProperty('message') &&
                                    res.action_output.message
                                ) {
                                    let detailedErrorMessage = '';
                                    if (
                                        res.action_output &&
                                        res.action_output.hasOwnProperty('responseObj') &&
                                        Object.keys(res.action_output['responseObj']).length
                                    ) {
                                        this.disable = false;
                                        detailedErrorMessage = res.action_output['responseObj']['message']
                                            ? res.action_output['responseObj'].message
                                            : '';
                                    }
                                    this.notificationService.error(res.action_output.message + '\n' + detailedErrorMessage, 'Error');
                                } else {
                                    this.disable = false;
                                    this.notificationService.error('Something went wrong. Could not perform selected action.', 'Error');
                                }
                                return resolve(true);
                            } else {
                                console.log('final res==>', res);
                                const that = this;
                                callBackFunction(that, res);
                                return resolve(true);
                            }
                        }
                    },
                    err => {
                        this.httpService.authenticatedAPI = true;
                        this.disable = false;
                        console.log('err while polling', err);
                        clearInterval(queueTimer);
                    }
                );
            }, pollTime);
        });
    }
}
