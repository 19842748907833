export const DEFAULT_TEMPLATE = {
	templateName:'Default V-Term Template',
	createHtmlCode(vtermUrl,vtermToken){
		return `<div>
			<form id="stand_alone_vterm_link_form" action=${vtermUrl} method="GET" target="_parent">
				<input type="hidden" name="param" value="${vtermToken}" />
				<input type= "hidden" id = "stand_alone_vterm_callback_url" name = "home" value = "" />
				<div>
					<button type="button" id="stand_alone_vterm_pay_now_btn"
						onclick="document.getElementById('stand_alone_vterm_callback_url').value = window.parent.location.href;
							document.getElementById('stand_alone_vterm_link_form').submit();"
					>
						Pay
					</button>
				</div>
			</form>
		</div>`;
	}
}