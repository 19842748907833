import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { SetupPermissionsStore, SetupSecurityRolesStore, SetupBusinessStructureStoreSetupStore, SetupCompanyStore, SetupBusinessInformationStore } from './../../../../../store';
import { UserHandlerService, GlobalValidator, UrlStoreService, StoreService, TreeService, CompanyService, BusinessService, NotificationService } from './../../../../services';
import { UserInfo } from './../../../../../models';

import { TAGSCONSTS } from './../../../../../constants';
import { FUNCTION_TAG_MAPPING, MDOULE_TAGS_MAPPING } from 'app/constants/mapping.constants';
import { Router } from '@angular/router';
import { validateFor } from 'app/common-functions';

const DB_XUSERINFO_KEY = 'xUserInfo';
const DB_XHASHED_PWD_KEY = 'xHashedPwd';
const editLink = '/dashboard/setup/security/roles/add-edit/';
@Component({
	selector: 'app-security',
	templateUrl: './security.component.html',
	styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {
	constructor(
		private setupPermissionsStore: SetupPermissionsStore,
		private userHandlerService: UserHandlerService,
		private fb: FormBuilder,
		private setupSecurityRolesStore: SetupSecurityRolesStore,
		private setupBusinessStructureStoreSetupStore: SetupBusinessStructureStoreSetupStore,
		private urlStoreService: UrlStoreService,
		private storeService: StoreService,
		private treeService: TreeService,
		private companyService: CompanyService,
		private router: Router,
		private businessService: BusinessService,
		private setupBusinessInformationStore: SetupBusinessInformationStore,
		private notificationService: NotificationService
	) {}
	finalRoleIDs: any[] = [];
	displayPreviewPermissionModalCmp: boolean = false;
	@ViewChild('rolesModal', { static: true })
	public rolesModal: ModalDirective;
	@ViewChild('storesModal', { static: true})
	public storesModal: ModalDirective;
	@Input()
	form: FormGroup;
	@Input()
	serverErrors: any;
	@Input()
	userAssociatedRolesArray: any = [];
	@Input()
	userAssociatedStoresArray: any = [];
	@Input()
	securityData: any = {};
	@Input()
	storesArray: any[] = [];
	@Input()
	rolesArray: any[] = [];
	@Input()
	edit: boolean = false;
	@Input()
	userData: any = {};
	@Input()
	source: string = '';
	@Input()
	accessStoreReqParam: any = {};
	@Input()
	hasUser: boolean = true;
	public valid: boolean = true;
	saveRoles: boolean = false;
	// rolesArray: any[] = [];
	selectedRoleID: string = '';
	displayPermissionModalCmp: boolean = false;
	selectedRoleName: string = '';
	loadingRoleAssociatedPerm: boolean = false;
	showPermsDiv: boolean = false;
	roleAssociatedPermisionsList: any = [];
	saveStores: boolean = false;
	storeId: string = '';
	// accessStoreReqParam: any = {};
	loading: boolean = true;
	fetchedStores: any = {};
	fetchedRoles: any = {};
	apiLoading: boolean = false;
	apiError: boolean = false;
	rolesData: any[] = [];
	noRoles: boolean = false;
	storeItemFound: any;
	setupBusinessEmployeeModel: any = {};
	onRepresentativePage: boolean = false;
	onCustomerPage: boolean = false;
	// companyInformation: any;
	obs: any[] = [];
	functionTagMapping = FUNCTION_TAG_MAPPING;
    moduleTagMapping = MDOULE_TAGS_MAPPING;
	DB_XUSERINFO_KEY:string = DB_XUSERINFO_KEY;
	DB_XHASHED_PWD_KEY:string = DB_XHASHED_PWD_KEY;

	async ngOnInit() {
		console.log('security ngoninit called');
		console.log('security oninit: accessStoreReqParam', this.accessStoreReqParam);
		console.log('source', this.source);
		if (this.source === 'representative') {
			this.onRepresentativePage = true;
		}
		if (this.source === 'customer') {
			this.onCustomerPage = true;
		}
		console.log('onRepresntativePage', this.onRepresentativePage);
		console.log('security: oninit: securitydata', JSON.parse(JSON.stringify(this.securityData)));
		console.log('security: oninit: userinfo formgroup', this.toFormGroup(new UserInfo(this.securityData)));
		console.log('security: oninit: hasUser', this.hasUser, ' edit:', this.edit);
		this.form.addControl('xUserInfo', this.toFormGroup(new UserInfo(this.securityData)));
		if (this.edit && this.hasUser) {
			console.log('security: oninit: set given roles and stores');
			this.form.addControl('xRoles', this.setInitRoles(this.securityData));
			if (!this.onRepresentativePage && !this.onCustomerPage) this.form.addControl('xAccStores', this.setInitStores(this.userData));
		} else {
			console.log('security: oninit: set empty store and roles');
			this.form.addControl('xRoles', this.fb.array([]));
			this.form.addControl('xAccStores', this.fb.array([]));
		}
		console.log('form value in security', JSON.parse(JSON.stringify(this.form.value)));
		console.log('form in security', this.form);
		console.log('user credential securitydata', this.securityData);
		console.log('user data', this.userData);
		let rolesQuery = {
			noGrid: true, 
			xCompanyID: this.companyService.companyID.getValue()
		}
		if (this.onRepresentativePage || this.onCustomerPage) {
			this.obs.push(this.setupSecurityRolesStore.getAll(rolesQuery));
		} else {
			this.obs.push(
				this.setupSecurityRolesStore.getAll(rolesQuery),
				this.setupBusinessStructureStoreSetupStore.getAll(this.accessStoreReqParam)
			);
		}
		forkJoin(this.obs).subscribe(
			(response: any) => {
				console.log('Response security oninit', response);
				this.loading = false;
				this.fetchedRoles = response[0].data.filter(tag => tag.xStatus);
				if (this.onRepresentativePage || this.onCustomerPage) {
					this.processCommonAPIData(response[0].data);
				} else {
					if (response[1].data.hasOwnProperty('error') && response[1].data.error) {
						this.fetchedStores = [];
					} else {
						this.fetchedStores = response[1].data.filter(tag => tag.xStatus);
					}
					this.processCommonAPIData(this.fetchedRoles, this.fetchedStores);
				}
				if (this.edit && this.hasUser) {
					this.setInitDisplayRoles();
					if (!this.onRepresentativePage && !this.onCustomerPage) this.setInitDisplayStore();
				}
			},
			(error: any) => {
				// this.handleErrorResponse(error);
				this.serverErrors = error.errors;
				// this.handleError(this.serverErrors);
				// this.routeToRepList();
				this.urlStoreService.routeBackToPreviousUrl();
			},
			() => {
				this.apiLoading = false;
			}
		);
	}

	get roles(): FormArray {
		return this.form.get('xRoles') as FormArray;
	}

	get stores(): FormArray {
		return this.form.get('xAccStores') as FormArray;
	}

	get userInfo(): FormArray {
		return this.form.get('xUserInfo') as FormArray;
	}

	onBlurPasswordCheck(event) {
		console.log('onBlurPasswordCheck,event----------->', event.target.value);

		GlobalValidator.passwordFormat(event)
			.then(res => {
				if (res) {
					this.valid = true;
					this.form
						.get('xUserInfo')
						.get('xHashedPwd')
						.setErrors(null);
					console.log('onBlurPasswordCheck: pwd status', this.form.get('xUserInfo').get('xHashedPwd').status);
				}
			})
			.catch(err => {
				if (!err) {
					this.valid = false;
					this.form
						.get('xUserInfo')
						.get('xHashedPwd')
						.setErrors({ INVALID_PASSWORD: true });
					console.log('onBlurPasswordCheck: pwd status', this.form.get('xUserInfo').get('xHashedPwd').status);
				}
			});
	}

	setInitRoles(data: any): FormArray {
		console.log('data.xRoles', data.xRoles);
		const formArray = this.fb.array(data.xRoles);
		console.log('formarray', formArray.getRawValue());
		return formArray;
	}

	setInitDisplayRoles() {
		let userRoles = [];
		this.securityData.xRoles.map(role => {
			userRoles.push(this.rolesArray.find(roleObj => roleObj.id === role.xRoleID));
		});
		userRoles.map(role => {
			role.isPresent = true;
		});
		this.userAssociatedRolesArray = JSON.parse(JSON.stringify(userRoles));
		console.log('user roles display', JSON.parse(JSON.stringify(this.userAssociatedRolesArray)));
		console.log('roles in form', this.roles.getRawValue());
	}

	editRoles(): void {
		this.saveRoles = true;
		// this.userAssociatedRolesArray = _.cloneDeep(this.rolesArray);
		this.userAssociatedRolesArray = JSON.parse(JSON.stringify(this.rolesArray));
		this.rolesSelected(this.userAssociatedRolesArray);
		this.rolesModal.hide();
		console.log('userassociated roles array', this.userAssociatedRolesArray);
	}

	rolesSelected(value: any[]) {
		while (this.roles.length) {
			this.roles.removeAt(0);
		}
		value.map((element: any) => {
			if (element.isPresent) {
				this.roles.push(
					new FormGroup({
						xStatus: element.status ? new FormControl(1) : new FormControl(0),
						xRoleID: new FormControl(element.id)
					})
				);
			}
		});
	}

	routeToRoleSetup() {
		this.router.navigate([editLink + this.selectedRoleID])
	}

	permissionModalClosed(visible: boolean) {
		this.displayPermissionModalCmp = visible;
		this.getPermissions(this.selectedRoleID, this.selectedRoleName);
	}

	previewPermissionModalClosed(visible: boolean) {
		this.displayPreviewPermissionModalCmp = visible;
	}

	getPermissions(roleID: string, roleName: string) {
		this.loadingRoleAssociatedPerm = true;
		this.selectedRoleName = roleName;
		this.showPermsDiv = true;
		this.selectedRoleID = roleID;
		this.setupSecurityRolesStore.get(roleID).subscribe(
			(response: any) => {
				this.loadingRoleAssociatedPerm = false;
				this.roleAssociatedPermisionsList = response.data
					&& response.data.xEntyPerm ? response.data.xEntyPerm
					: [];
			},
			(error: any) => {
				console.log('error:getPermissions', error);
				// this.submitting = false;
				// this.handleErrorResponse(error);
				this.showPermsDiv = false;
			},
			() => {
				setTimeout(() => {
					this.loadingRoleAssociatedPerm = false;
				}, 500);
			}
		);
	}

	abortStoreModal(): void {
		if (!this.saveStores) {
			if (this.userAssociatedStoresArray.length && this.userAssociatedStoresArray.length > 1) {
				this.storesArray = JSON.parse(JSON.stringify(this.userAssociatedStoresArray));
			} else {
				this.storesArray.map(store => {
					if (!(store.id === this.storeId)) {
						console.log('Ya hr br if me aa rha??');
						store.isPresent = false;
					}
					return store;
				});
			}
		}
		this.saveStores = false;
	}
	abortRoleModal(): void {
		if (!this.saveRoles) {
			// this.rolesArray = this.userAssociatedRolesArray.length ? _.cloneDeep(this.userAssociatedRolesArray) : this.rolesArray;
			this.rolesArray = this.userAssociatedRolesArray.length
				? JSON.parse(JSON.stringify(this.userAssociatedRolesArray))
				: this.rolesArray;
		}
		this.saveRoles = false;
	}
	public toFormGroup(data: UserInfo): FormGroup {
		console.log('toFormGroup: data', JSON.parse(JSON.stringify(data)));
		const formGroup = this.fb.group({
			xLoginUName: [data.xLoginUName || '', [Validators.required]],
			xHashedPwd: [data.xHashedPwd || '', [Validators.required,GlobalValidator.validatePassword]]
		});
		console.log('toFormGroup: data', JSON.parse(JSON.stringify(formGroup.value)));
		return formGroup;
	}

	setInitStores(data: any): FormArray {
		const formArray = this.fb.array(data.xAccStores);
		return formArray;
	}

	setInitDisplayStore() {
		let userStores = [];
		this.userData.xAccStores.map(store => {
			userStores.push(this.storesArray.find(storeObj => storeObj.id === store.xStoreID));
		});
		userStores.map(store => {
			store.isPresent = true;
		});
		this.userAssociatedStoresArray = userStores;
	}

	public editStores(): void {
		this.saveStores = true;
		// this.userAssociatedStoresArray = _.cloneDeep(this.storesArray);
		this.userAssociatedStoresArray = JSON.parse(JSON.stringify(this.storesArray));
		this.setStoreValues(this.userAssociatedStoresArray);
		this.storesModal.hide();
	}

	setStoreValues(storeArray: any[]): void {
		while (this.stores.length) {
			this.stores.removeAt(0);
		}
		console.log('Inside setStoreValue()', JSON.parse(JSON.stringify(storeArray)), JSON.parse(JSON.stringify(this.stores.value)));
		storeArray.map((element: any) => {
			console.log('Inside map of storeArray', element);
			if (element.isPresent) {
				this.stores.push(
					new FormGroup({
						xStatus: element.status ? new FormControl(1) : new FormControl(0),
						xStoreID: element.id ? new FormControl(element.id) : new FormControl('')
					})
				);
			}
		});
		console.log('Inside setStoreValue() 2', JSON.parse(JSON.stringify(storeArray)), JSON.parse(JSON.stringify(this.stores.value)));
	}

	processCommonAPIData(roles: any[], stores?: any[]) {
		//TODO -: This is to be changed coz the roles are to be filtered on the basis of permisson given to them
		console.log('Roles and reps', roles, stores);
		console.log('processCommonAPIData: stores', stores);
		if (this.onRepresentativePage) {
			console.log('rep roles', roles);
			if(this.userHandlerService.userRoles.isDealer){
				this.rolesData = roles.filter(role => 
					role.xTag === TAGSCONSTS.roleTags.roleItemTags.representativeRole
				);
			} else if(this.userHandlerService.userRoles.isVendor){
				this.rolesData = roles.filter(role => (
					role.xTag === TAGSCONSTS.roleTags.roleItemTags.representativeRole ||
					role.xTag === TAGSCONSTS.roleTags.roleItemTags.vendorRole
				));
			}
			if (!this.rolesData.length) {
				this.noRoles = true;
			}
			console.log('processCommonAPIData: dealer roles', this.rolesData);
			console.log('no roles', this.noRoles);
			this.rolesArray = this.rolesData.map((element: any, index: number) => {
				console.log('rep eleemnt', element);
				return {
					id: element._id,
					text: element.xName,
					status: true,
					isPresent: false
				};
			});
		} else if (this.onCustomerPage) {
			this.rolesData = roles.filter(role => role.xTag === TAGSCONSTS.roleTags.roleItemTags.customerRole);
			if (!this.rolesData.length) {
				this.noRoles = true;
			}
			console.log('processCommonAPIData: cust roles', this.rolesData);
			this.rolesArray = this.rolesData.map((element: any, index: number) => {
				console.log('ELEMENT', element);
				return {
					id: element._id,
					text: element.xName,
					status: true,
					isPresent: false
				};
			});
		} else {
			if (this.userHandlerService.userRoles.isDealer || this.userHandlerService.userRoles.isBusinessEmployee) {
				this.rolesData = roles.filter(role => role.xTag === TAGSCONSTS.roleTags.roleItemTags.employeeRole);
				if (!this.rolesData.length) {
					this.noRoles = true;
				}
				console.log('processCommonAPIData: emp roles', this.rolesData);
				this.rolesArray = this.rolesData.map((element: any, index: number) => {
					console.log('ELEMENT', element);
					return {
						id: element._id,
						text: element.xName,
						status: true,
						isPresent: false
					};
				});
			}
			if (this.userHandlerService.userRoles.isBusinessEmployee) {
				if (this.storeService.getAccessStoreIDs().length) {
					console.log('Here inside if condition', JSON.parse(JSON.stringify(stores)));
					stores = stores.filter(
						store =>
							this.storeService.getAccessStoreIDs().indexOf(store._id) >= 0 &&
							this.storeService.getAccessStoreStatus()[this.storeService.getAccessStoreIDs().indexOf(store._id)]
					);
					console.log('stores after filtering', JSON.parse(JSON.stringify(stores)));
				}
			}
		}
		// this.storesArray=stores;
		if (stores) {
			this.userAssociatedStoresArray.length = 0;
			if (this.treeService.currentSelectedNode.getValue().type !== 'store') {
				this.storeId = null;
			}
			console.log('Modified store', JSON.parse(JSON.stringify(stores)));
			this.storesArray = stores.map(store => {
				// return { id: store._id, text: store.xName, status: store.xStatus, isPresent: false };
				if (store._id === this.storeId) {
					console.log('this.storeId', this.storeId);
					while (this.stores.length) {
						this.stores.removeAt(0);
					}
					this.stores.push(
						new FormGroup({
							xStatus: store.xStatus ? new FormControl(1) : new FormControl(0),
							xStoreID: store._id ? new FormControl(store._id) : new FormControl('')
						})
					);
					this.storeItemFound = this.userAssociatedStoresArray.find(storeInArray => storeInArray.id == store._id);
					console.log('store item found or not', this.storeItemFound);
					if (!this.storeItemFound) {
						this.userAssociatedStoresArray.length = 0;
						this.userAssociatedStoresArray.push({
							id: store._id,
							text: store.xName,
							status: store.xStatus,
							isPresent: true
						});
					}
					console.log('this.userAssociatedStoresArray', this.userAssociatedStoresArray);
					return {
						id: store._id,
						text: store.xName,
						status: store.xStatus,
						isPresent: true
					};
				} else {
					return {
						id: store._id,
						text: store.xName,
						status: store.xStatus,
						isPresent: false
					};
				}
			});
		}
	}

	/**
	* called on click of add role
	*
	* @params : `role` : which has been added
	*/
	addRole(role){
		console.log('addRole:role',role)
		console.log('addRole:roleArray',this.rolesArray)
		this.rolesArray.forEach(record => {
			record.isPresent = false;
		})
		role.isPresent=true
	}

	clearErrors(errorName: string): void {
		if (this.serverErrors[errorName]) {
			delete this.serverErrors[errorName];
		}
	}
}
