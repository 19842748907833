import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
// import {} from '../../'

@Component({
  selector: 'app-sys-log',
  templateUrl: './sys-log.component.html',
  styleUrls: ['./sys-log.component.scss']
})
export class SystemLogComponent implements OnInit {
  public viewErr: boolean= false;
  public fileText;
  public keyword:string='';
  public fileToArray:any[]=[]; //Used to store logs line by line in an array. 
  public finalLogArray:any[]=[]; //Used to store logs and there respective state-> highlighted or not highlighted.
  constructor(
    private http:HttpClient
  ) { }

  ngOnInit() {
    this.http.get('app/constants/dummy.log').subscribe(data=>{
      // console.log(JSON.parse(JSON.stringify(data))._body);
      this.fileText=JSON.parse(JSON.stringify(data))._body;
      this.fileToArray=this.fileText.split('\n');
      this.fileToArray.map(val=>{
        this.finalLogArray.push({
          value:val,
          highlight:false
        })
      })
      // console.log("arrrrrrrr", this.fileToArray);
    })
  }
 

  viewError(){
    // this.viewErr= !this.viewErr;
    if(this.viewErr){
      this.finalLogArray.map(log=>{
        if(log.value.indexOf('error') !== -1){
          log.highlight=true;
        }
        // else{
        //   log.highlight=false;
        // }
      });
    }
    else{
      this.finalLogArray.map(log=>{
        if(log.value.indexOf('error') !== -1){
          log.highlight=false;
        }
        // else{
        //   log.highlight=false;
        // }
      });
    }
  }

  moveToTop(){
    document.getElementById('log-grid').scrollTop=0;
  }

  moveToBottom(){
    document.getElementById('log-grid').scrollTop =document.getElementById('log-grid').scrollHeight;
  }

  unselectAll(){
    this.keyword='';
    this.viewErr=false;
    this.finalLogArray.map(log=>{
        log.highlight=false;
    });
  }

  onSearch(keyword: any): void {
    if(keyword){
      this.finalLogArray.map(log=>{
        if(log.value.indexOf(keyword) !== -1){
          log.highlight=true;
        }
        else{
          if(this.viewErr){
            if(log.value.indexOf('error') !== -1){
              log.highlight=true;
            }
            else{
              log.highlight=false;
            }
          }
          else{
            log.highlight=false;
          }
        }
      });
    }
}

}
