<!-- <ng-sidebar #NgxAsidePanelRight mode='over' [(opened)]="open" animate='true' position='right' closeOnClickOutside='true' (onClosed)='hideDetail()'> -->
<ng-sidebar #NgxAsidePanelRight animate='true' position='right' closeOnClickOutside='true' (onClosed)='hideDetail()'>
    <div class="user-detail" style='height: 100%;' *ngIf="ID && open">
        <div class="card" style='height: 100%;min-width: 400px;'>
            <div class='sidebar-header'>
				<span> {{ label + ' Info' }}</span>
				<span class='pull-right'>
					<a closeSidebar>
						<i class="fa fa-times" aria-hidden="true"></i>
					</a>
				</span>
			</div>
            <div class="card-header">
                <h4 class="card-title">
                    <i class="fa fa-user" aria-hidden="true"></i> {{data?.xName | uppercase}}
                </h4>
            </div>
            <div class="card no-margin ">
                <div *ngIf="!isLoading">
                    <table class="table table-striped no-margin table-sm">
                        <thead>
                            <th>Attributes</th>
                            <th>Value</th>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let item of viewDetails">
                                <tr *ngIf="item.mapTo !== 'xItemsInfo'">
                                    <td>{{item.header}}</td>
                                    <td *ngIf="item.nested">
                                        <span *ngIf="item.type === 'date'">
                                            {{getValue(data, item.mapTo) | date: item.format}}
                                        </span>
                                        <span *ngIf="item.type !== 'date'">
                                            {{getValue(data, item.mapTo)}}
                                        </span>
                                    </td>
                                    <td *ngIf="!item.nested">
                                        <span *ngIf="item.type === 'date' && getValue(data, item.mapTo)">
                                        {{data[item.mapTo] | date: item.format}}
                                        </span>
                                        <span *ngIf="item.type === 'date' && getValue(data, item.mapTo) === null">
                                                -
                                        </span>
                                        <span *ngIf="item.type !== 'date'">
                                            {{data[item.mapTo]}}
                                        </span>
                                    </td>
                                </tr>
                                <tr *ngIf="item.mapTo === 'xItemsInfo' && getValue(data, item.mapTo)">
                                    <th>{{item.header}}</th>
                                    <th></th>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let nestedItem of xRequestTextDataTxn | keyvalue:keepOriginalOrder">
                                <tr>
                                    <th>Item {{numberFormat(nestedItem.key) + 1}}</th>
                                    <th></th>
                                </tr>
                                <tr *ngFor="let keyItem of nestedItem.value | keyvalue:keepOriginalOrder">
                                    <ng-container *ngIf="keyItem.value.length !== 0">
                                        <td>{{keyItem.key.slice(1)}}</td>
                                        <td>{{keyItem.value}}</td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="card-block" style="text-align: center;" *ngIf="isLoading">
                    <div class="grid-spinner">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                    </div>
                    <span style="color:#3b5877">Fetching data</span>
                </div>
            </div>
        </div>
    </div>
<!-- </div> -->
</ng-sidebar>
