export class Limit {

    xMaxAuthAmt: number;
    xMinAuthAmt:number;
    xMaxTipAmt: number;
    xMaxTipRate: number;
    xMaxSettleCnt: number;

    constructor(obj?: any) {
        this.xMaxAuthAmt = (obj && obj.xMaxAuthAmt) || 0;
        this.xMinAuthAmt = (obj && obj.xMinAuthAmt) || 0;
        this.xMaxTipAmt = (obj && obj.xMaxTipAmt) || 0;
        this.xMaxTipRate = (obj && obj.xMaxTipRate) || 0;
        this.xMaxSettleCnt = (obj && obj.xMaxSettleCnt) || 0;
    }
}
