import { STORE_NAMES } from 'app/constants/store-names.constants';
export const ACTION_WORKER = {
	action_progress: 'action_progress',
	action_output: 'action_output',
	status: 'status',
	responseObj: 'responseObj',
	message: 'message',
	action_id: 'action_id',
};
export const CHECK_TXN_BATCH_KEY_CLASS: string = 'class';
export const CHECK_TXN_BATCH_CLASS = {
	notAllowed: 'notAllowed',
	warning: 'warning',
};
export const IMPORT_FILE_PROCESSING_STATUS_COMPLETED: string = 'Completed';
export const REFRESH_GRID_STORES: string[] = [
	STORE_NAMES.transactionsStore,
	STORE_NAMES.settledTransactionsStore,
	STORE_NAMES.importTransactionsStore,
	STORE_NAMES.csvFileHistoryStore,
	STORE_NAMES.invoiceStore,
];
export const GRID_ACTIONS = {
	view: 'view',
	download: 'download',
};
export const HIDE_SORT_KEYS = ['netTotal', 'pCtrData.xName', 'pCtrData.xGMID', 'serviceCharge'];
export const IMPORT_TXN_FILE_PORCESSING_STATUS_KEY = 'fileProcessingStatus';

//urls
export const IMPORT_TXN_URL: string = '/dashboard/import-transactions';
export const ROLES_LIST_URL = '/dashboard/setup/security/roles';

export const IMPORT_TXN_BTN_TOOLTIP: any = {
	pctrSelected: 'Click on this button to Import Transactions.',
	pctrNotSelected: 'Please select a Profit Center.',
};

export const PCTR_DELETE_SWAL_TEXT: string = `Deleting this item will result in removal of dependencies
    which contain other stored data and settings.\n The dependencies to be deleted will be displayed next. 
    You will have another chance to cancel this action after viewing the dependencies.\n`;

export const OVERLAY_PERFORMING_DELETE_ACTION_TEXT: string = 'Please wait. Performing delete action.';
export const OVERLAY_FETCHING_REQUIRED_INFO_TEXT: string = 'Fetching required details. Please wait.';
export const OVERLAY_CHECKING_ASSOCIATED_TXN_TEXT: string = 'Checking associated transactions with this node. Please wait.';
export const OVERLAY_CHECKING_ASSOCIATED_CUSTOMER_TEXT: string = 'Checking associated customers with this node. Please wait.';
export const ASSOCIATED_TXN_CHECK_FAILED_MESSAGE: string = 'Something went wrong while checking associated transactions for this node. Please contact support.';

export const SWAL_OPERATION_FAILED = 'Operation Failed!';
export const SWAL_SAFE_FAIL_MSG =
	'\nCertain associated payment methods cannot be deleted.\nPlease contact support.\nBelow are the payment method that failed to delete.\n';
export const SWAL_CUSTOM_CLASS_SAFE_FAIL = 'safe-fail-swal';
export const SWAL_TITLE_ARE_YOU_SURE: string = 'Are You Sure?';
export const SWAL_WARNING_INFO: string = 'You are deleting ';
export const SWAL_WARNING_TEXT = 'You will not be able to recover this Record!';

export const ROLE_DELETION_FAILED_MSG = 'Failed to delete this role. Please try again.';
export const ROLE_DELETION_NOT_ALLOWED = 'Can not delete this role. There exists atleaset one User associated with this role.';
export const NO_PERM_MSG = `You don't have permission for this action.`;

export const IS_PAID_WARNING = 'You are setting the payment status as "paid" ';
export const IS_SUSPENDED = 'You are suspending this company for non-payment of dues ';
export const OVERLAY_PERFORMING_SUSPENDED = 'Please wait. Performing suspend action.';
export const IS_UNSUSPENDED = 'You are un-suspending this company';
export const OVERLAY_PERFORMING_UNSUSPENDED = 'Please wait. Performing un-suspend action.';
export const OVERLAY_PERFORMING_PAID = 'Please wait. Setting payment status to paid.';
export const IS_DISABLE_BILLING = 'You are deactiving this record';
export const OVERLAY_PERFORMING_DISABLE = 'Please wait. Performing disable action.';
export const OVERLAY_PERFORMING_RUNNOW = 'Please wait. Performing action.';
