export const MONTH: any[] = [
    { label: "January", value: 1,small:"Jan" },
    { label: "February", value: 2, small: "Feb" },
    { label: "March", value: 3, small: "Mar" },
    { label: "April", value: 4, small: "Apr" },
    { label: "May", value: 5, small: "May" },
    { label: "June", value: 6, small: "Jun" },
    { label: "July", value: 7, small: "Jul" },
    { label: "August", value: 8, small: "Aug" },
    { label: "September", value: 9, small: "Sep" },
    { label: "October", value: 10, small: "Oct" },
    { label: "November", value: 11, small: "Nov" },
    { label: "December", value: 12, small: "Dec" }
];

export const YEAR = new Array(20).fill(undefined).map((val, idx) => {
    const today = new Date();
    return {
        label: today.getFullYear() + idx,
        value: today.getFullYear() + idx
    };
});

export const DATEFORMAT = "dd/MM hh:mm a";

export const LAST_DAYS_FILTER = {
    all: 'all',
    last_24_hrs: 'last_24_hrs',
    last_7_days: 'last_7_days',
    last_30_days: 'last_30_days',
    last_60_days: 'last_60_days',
    last_90_days: 'last_90_days'
}