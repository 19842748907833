import { Component, OnInit, OnDestroy } from "@angular/core";
import { BatchStore } from "../../../../store/index";
import { customSwal } from "../../../../constants";
import {
    TreeService,
    BusinessService,
    HttpService,
    TransactionActionService,
    NotificationService,
    BreadcrumbService,
    UserHandlerService
} from "../../../../shared/services";
import { skip, takeWhile } from 'rxjs/operators';
import { LAST_DAYS_FILTER } from "../../../../constants";
import { BatchPermissions } from "app/models";

const STRUCT_TYPE_CHAIN = "chain";
const STRUCT_TYPE_REGION = "region";
const STRUCT_TYPE_PROPERTY = "property";

@Component({
    selector: "app-settled-batches",
    templateUrl: "./settled-batches.component.html",
    styleUrls: ["./settled-batches.component.scss"]
})
export class SettledBatchesComponent implements OnInit, OnDestroy {
    public attributes: any[] = [];
    public storeValue: string = "";
    public storeObject: any = {};
    public action: any[] = [];
    showDetails: boolean = false;
    public refreshGrid: boolean = false;

    // public detailVisible: boolean = true;
    public batchID: string = "";
    public filter: string = LAST_DAYS_FILTER.last_90_days;
    fetchByStore: boolean = true;
    alive: boolean = true;
    showOverLay: boolean = false;
    dateRangeValue: Date[];

    public parameters: any = {
        // noGrid: true,
        xIsSettled: true
    };

    pctrListString: string = "";
    gmidList = [];
    batchPerms: BatchPermissions = {};

    public viewDetails: any[] = [
        { header: "Status", mapTo: "xStatus" },
        { header: "Number", mapTo: "xBatchNum" },
        { header: "Type", mapTo: "xTypeTag" },
        { header: "Terminal", mapTo: "xMainTerminalNum" },
        { header: "Capture Setting", mapTo: "xCaptTag" },
        { header: "Merchant Account", mapTo: "xMerchantNum" },
        {
            header: "Open Date",
            mapTo: "xOpenDateTime"
        },
        { header: "Merchant Amount Total", mapTo: "merTotal" },
        { header: "Net Total", mapTo: "netTotal" },
        {
            header: "Settle Time",
            mapTo: "xSettleDateTime"
        },
        {
            header: "Close Time",
            mapTo: "xCloseDateTime"
        },
        {
            header: "Response Code",
            mapTo: "xRspCode"
        },
        {
            header: "Response Text",
            mapTo: "xRspText"
        }
    ];

    constructor(
        private batchStore: BatchStore,
        private treeService: TreeService,
        public businessService: BusinessService,
        private httpService: HttpService,
        private transactionActionService: TransactionActionService,
        private notificationService: NotificationService,
        public breadcrumbService: BreadcrumbService,
        private userHandlerService: UserHandlerService
    ) {}

    ngOnInit() {
        this.setBreadCrumbValue("GMID - ", "", true);
        this.setInitParams();
        this.setBatchActionPermissions();
        this.filterData();
        this.businessService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizID => {
                if (bizID) {
                    this.setBreadCrumbValue("GMID - ", "", true);
                    this.parameters = {
                        xBusinessID: this.treeService.businessID.getValue(),
                        xIsSettled: true
                    };
                    this.filterData();
                } else {
                    // this.router.navigate(['/dashboard/home']);
                    this.parameters = {
                        xBusinessID: null
                    };
                    this.handleWatcher_NullValueCase("biz");
                }
            });
        this.treeService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizID => {
                // console.log('bizID', bizID);
                if (bizID) {
                    this.parameters = {
                        xBusinessID: this.treeService.businessID.getValue(),
                        xIsSettled: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase("biz");
            });
        this.treeService.watchChainID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(chainID => {
                if (chainID) {
                    console.log("settledbatches chain watcher", chainID);
                    this.parameters = {
                        struct_type: STRUCT_TYPE_CHAIN,
                        structID: this.treeService.chainID.getValue(),
                        xIsSettled: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase("chain");
            });
        this.treeService.watchRegionID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(regionID => {
                if (regionID) {
                    console.log("settledbatches region watcher", regionID);
                    this.parameters = {
                        struct_type: STRUCT_TYPE_REGION,
                        structID: this.treeService.regionID.getValue(),
                        xIsSettled: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase("region");
            });
        this.treeService.watchPropertyID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(propertyID => {
                if (propertyID) {
                    console.log("settledbatches property watcher", propertyID);
                    this.parameters = {
                        struct_type: STRUCT_TYPE_PROPERTY,
                        structID: this.treeService.propertyID.getValue(),
                        xIsSettled: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase("property");
            });
        this.treeService.watchStoreID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(storeID => {
                if (storeID) {
                    this.parameters = {
                        xStoreID: this.treeService.storeID.getValue(),
                        xIsSettled: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase("store");
            });
        this.treeService.watchProfitCenterID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(profitCenterID => {
                if (profitCenterID) {
                    this.parameters = {
                        xPCtrID: this.treeService.profitCenterID.getValue(),
                        xIsSettled: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase("profit_center");
            });
        this.treeService.watchMerchantAccID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(merID => {
                if (merID) {
                    this.parameters = {
                        xMerAcctID: this.treeService.merchantAccID.getValue(),
                        xIsSettled: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase("merchant_account");
            });
        this.treeService.watchTerminalID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(paymentDevID => {
                if (paymentDevID) {
                    this.parameters = {
                        xTerminalID: this.treeService.terminalID.getValue(),
                        xIsSettled: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase("terminal");
            });
        this.treeService.watchPeriphID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(perphID => {
                if (perphID) {
                    this.parameters = {
                        xPeriphID: this.treeService.periphID.getValue(),
                        xIsSettled: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase("peripheral");
            });
    }

    setBreadCrumbValue(
        gmidString: string,
        tooltip: string,
        loading: boolean
    ): void {
        console.log("tooltipstring", tooltip.length);
        const breadCrumb: any = {};
        breadCrumb.root = "Transactions";
        breadCrumb.child = gmidString;
        breadCrumb.subChild = "";
        breadCrumb.subChildLink = null;
        breadCrumb.grandChild = null;
        if (tooltip.length === 0) {
            breadCrumb.tooltip = false;
        } else {
            breadCrumb.toolTip = true;
        }
        breadCrumb.toolTipData = tooltip;
        breadCrumb.loading = loading;
        this.breadcrumbService.setBreadCrumb(breadCrumb);
    }

    recievePCtrList(event: any) {
        console.log("event in pctrlist", event);
        this.gmidList = [];
        this.pctrListString = "";
        event.map(item => {
            this.pctrListString += item + ",";
            // remove this statement once api call added
            // this.gmidList.push(item);
        });
        this.pctrListString = this.pctrListString.slice(0, -1);
        let params = { pctrIDs: this.pctrListString, selectKey: "xGMID" };
        this.setBreadCrumbValue("GMID - ", "", true);
        console.log("params for gmid", params);
        this.httpService.getAll("xbcPCtrs", params).subscribe(
            response => {
                console.log("response get gmid", response);
                response.data.map(item => {
                    this.gmidList.push(item.xGMID);
                });
                console.log("gmidlist", this.gmidList);
                console.log("pctrliststring", this.pctrListString);
                let gmidStringBreadCrumb = "GMID - None",
                    tooltipGMID = "";
                if (this.gmidList.length === 1)
                    gmidStringBreadCrumb = "GMID - " + this.gmidList[0];
                else if (this.gmidList.length >= 2)
                    gmidStringBreadCrumb =
                        "GMID - " + this.gmidList[0] + ", " + this.gmidList[1];
                this.gmidList.map(item => {
                    tooltipGMID += item + ",";
                });
                tooltipGMID = tooltipGMID.slice(0, -1);
                this.setBreadCrumbValue(
                    gmidStringBreadCrumb,
                    tooltipGMID,
                    false
                );
            },
            error => {
                console.log("Failed api call for gmid");
            }
        );
    }

    setInitParams() {
        if (this.treeService.businessID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xBusinessID: this.treeService.businessID.getValue()
            });

        if (this.treeService.chainID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                struct_type: STRUCT_TYPE_CHAIN,
                structID: this.treeService.chainID.getValue()
            });

        if (this.treeService.propertyID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                struct_type: STRUCT_TYPE_PROPERTY,
                structID: this.treeService.propertyID.getValue()
            });

        if (this.treeService.regionID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                struct_type: STRUCT_TYPE_REGION,
                structID: this.treeService.regionID.getValue()
            });

        if (this.treeService.storeID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xStoreID: this.treeService.storeID.getValue()
            });

        if (this.treeService.profitCenterID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xPCtrID: this.treeService.profitCenterID.getValue()
            });

        if (this.treeService.merchantAccID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xMerAcctID: this.treeService.merchantAccID.getValue()
            });

        if (this.treeService.terminalID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xTerminalID: this.treeService.terminalID.getValue()
            });

        if (this.treeService.periphID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xPeriphID: this.treeService.periphID.getValue()
            });
    }

    filterData() {
        this.transactionActionService.setResetFilter(true);
        switch (this.filter) {
            case LAST_DAYS_FILTER.all:
                // console.log('here 2');
                delete this.parameters["filterType"];
                if (this.parameters.hasOwnProperty("startDate"))
                    delete this.parameters["startDate"];
                if (this.parameters.hasOwnProperty("endDate"))
                    delete this.parameters["endDate"];
                if (this.parameters.hasOwnProperty("currDate"))
                    delete this.parameters["currDate"];
                this.parameters = Object.assign({}, this.parameters);
                break;
            case LAST_DAYS_FILTER.last_24_hrs:
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_24_hrs
                });
                break;
            case LAST_DAYS_FILTER.last_7_days:
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_7_days
                });
                break;
            case LAST_DAYS_FILTER.last_30_days:
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_30_days
                });
                break;
            case LAST_DAYS_FILTER.last_60_days:
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_60_days
                });
                break;
            case LAST_DAYS_FILTER.last_90_days:
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_90_days
                });
                break;
            case "advancedDateFilter":
                delete this.parameters["currDate"];
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: "advancedDateFilter"
                });
                break;
        }

        if (this.filter !== "all" && this.filter !== "advancedDateFilter") {
            if (this.parameters.hasOwnProperty("startDate"))
                delete this.parameters["startDate"];
            if (this.parameters.hasOwnProperty("endDate"))
                delete this.parameters["endDate"];
            this.parameters = Object.assign({}, this.parameters, {
                currDate: new Date().toDateString()
            });
        } else if (this.filter === "advancedDateFilter") {
            this.parameters = Object.assign({}, this.parameters, {
                startDate: new Date(this.dateRangeValue[0]).toDateString(),
                endDate: new Date(this.dateRangeValue[1]).toDateString()
            });
        }

        console.log("this.parameters=====>", this.parameters);

        this.setGridProperties();
    }

    setGridProperties() {
        this.storeValue = "SettledBatchStore";
        this.storeObject = this.batchStore;
        this.attributes = [
            // { header: 'Status', mapTo: 'xStatus', width: '7%' },
            { header: "Number", mapTo: "xBatchNum", width: "12%" },
            { header: "Merchant Account", mapTo: "xMerchantNum", width: "17%" },
            {
                header: "Open Date",
                mapTo: "xOpenDateTime",
                width: "13%"
                // type: "date",
                // format: DATEFORMAT                   //TODO-: It has changed to 'number'. This is needed to be discussed with Jim and then accordingly pipe is to be designed and implemented
            },
            {
                header: "Settle Date",
                mapTo: "xSettleDateTime",
                width: "13%"
                // type: "date",
                // format: DATEFORMAT                   //TODO-: It has changed to 'number'. This is needed to be discussed with Jim and then accordingly pipe is to be designed and implemented
            },
            // { header: 'Total Debit', mapTo: 'xTtlDebitAmt', width: '10%' },
            // { header: 'Total Credit', mapTo: 'xTtlCreditAmt', width: '10%' }, 
            { header: "Service Charge", mapTo: "serviceCharge", width: "12%" },
            { header: "MerAmt Total", mapTo: "merTotal", width: "12%" },
            { header: "Net Total", mapTo: "netTotal", width: "12%" },
            { header: "Actions", mapTo: "", width: "10%" }
            // { header: 'Action_State', width: "12%" }
        ];
        this.action = [
            {
                type: "view",
                visible: true,
                text: "Details",
                icon: "fa fa-eye",
                action: "emit"
            },
            {
                type: "edit",
                visible: true,
                text: "View Transactions",
                icon: "fa fa-exchange",
                action: "func",
                value: "transaction",
                onClick: "dashboard/settled-transactions/batch",
                disabled: !this.batchPerms.canViewBatchTxns
            },
            {
                type: "modal",
                visible: true,
                text: "Re-Sync",
                icon: "fa fa-refresh",
                action: "emit",
                value: "reSync",
                disabled: !this.batchPerms.canResync
            }
        ];
    }

    //TODO Need to implement in backend
    fetchFilter() {
        switch (this.filter) {
            case LAST_DAYS_FILTER.all:
                if (
                    this.parameters.hasOwnProperty("filterType") &&
                    (this.parameters.hasOwnProperty("currDate") ||
                        (this.parameters.hasOwnProperty("startDate") &&
                            this.parameters.hasOwnProperty("endDate")))
                ) {
                    this.filterData();
                }
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_24_hrs:
                this.filterData();
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_7_days:
                this.filterData();
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_30_days:
                this.filterData();
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_60_days:
                this.filterData();
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_90_days:
                this.filterData();
                this.dateRangeValue = [];
                break;
            default:
                console.log('reached default: settled batches');
                break;
        }
    }

    onDateFilterClick(event) {
        console.log("this.dateRangeValue", this.dateRangeValue);
        // console.log(
        //     "this.dateRangeValue",
        //     JSON.parse(JSON.stringify(this.dateRangeValue[0]))
        // );
        if (this.dateRangeValue && this.dateRangeValue.length) {
            this.filter = "advancedDateFilter";
            this.filterData();
        }
    }

    onActionRequest(obj: any) {
        if (obj.act.type === "view") {
            this.batchID = obj.id;
            // this.detailVisible = true;
        } else if (obj.act.type === "modal") {
            if (obj.id) {
                customSwal.fire({
                    title: 'This action might take some time.',
                    text: 'Click Yes to continue, No to decline',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.showOverLay = true;
                            this.httpService
                                .getAll(
                                    "sync/v1/resync_closedTxns",
                                    {
                                        batchID: obj.id,
                                        batchRefNum: obj.refNum
                                    },
                                    true
                                )
                                .subscribe(
                                    (response: Response | any) => {
                                        this.showOverLay = false;
                                        this.transactionActionService.setRefreshData(
                                            true
                                        );
                                        this.transactionActionService.setActionPerformed(
                                            true
                                        );
                                        this.notificationService.success(
                                            "Re-Sync Successful",
                                            "Success"
                                        );
                                        // }
                                    },
                                    err => {
                                        // console.log("Error inside settle Batch Settle resync", err);
                                        this.transactionActionService.setRefreshData(
                                            true
                                        );
                                        this.transactionActionService.setActionPerformed(
                                            true
                                        );
                                        // this.transactionActionService.setContinuousFetch(true);
                                        this.showOverLay = false;
                                    }
                                );
                    }
                })
            } else {
                customSwal.fire({
                    title: `Something went wrong`,
                    text: "Settle action could not be completed",
                    icon: 'warning',
                    confirmButtonText: 'Okay',
                }).then((result) => {
                    this.transactionActionService.setRefreshData(true);
                })
            }
        }
    }

    onReSyncRequest(event) {
        customSwal.fire({
            title:  "This action might take some time.",
            text: 'Click Yes to continue, No to decline',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.showOverLay = true;
                    this.httpService
                        .getAll(
                            "sync/v1/resync_closedBatches",
                            {
                                bizID: this.businessService.businessID.getValue()
                            },
                            true
                        )
                        .subscribe(
                            response => {
                                // console.log("Response inside settled Batch resync All", response);
                                this.showOverLay = false;
                                this.transactionActionService.setRefreshData(
                                    true
                                );
                                this.transactionActionService.setActionPerformed(
                                    true
                                );
                                this.notificationService.success(
                                    "Re-Sync Successful",
                                    "Success"
                                );
                            },
                            error => {
                                // console.log("Error inside settled Batch resync All", error);
                                this.transactionActionService.setRefreshData(
                                    true
                                );
                                this.transactionActionService.setActionPerformed(
                                    true
                                );
                                // this.transactionActionService.setContinuousFetch(true);
                                this.showOverLay = false;
                            }
                        );
            }
        })
    }

    onShowDetails(obj: any) {
        // console.log("Show object", obj);
        this.showDetails = obj;
    }

    onRefreshRequest(event) {
        this.parameters = Object.assign({}, this.parameters, {
            refreshGrid: true
        });
        // this.parameters={
        //     // noGrid: true,
        //     xIsSettled: true,
        //     refreshGrid : true
        // };
    }

    viewClosed(visible: boolean) {
        if (!visible) {
            this.batchID = "";
            this.showDetails = false;
        }
    }

    viewlastWeek(visible: boolean) {
        if (!visible) {
            this.batchID = "";
        }
    }

     /**
     * Based on user permission, enable/disable batch actions
     */
    setBatchActionPermissions(){
        const userPerms = this.userHandlerService.userPerms;
        this.batchPerms.canResync = userPerms.canReSyncBatch;
        this.batchPerms.canViewBatchTxns = userPerms.canViewBatchTxns;
    }

    ngOnDestroy() {
        this.alive = false;
    }

    handleWatcher_NullValueCase(watcher_type) {
        //This case arrises for all other watchers whenever a tree-node is clicked.
        //Don't perfrom any action. Can be used for logs purpose
        // console.log('Null value for -->', watcher_type);
    }
}
