<section class="main-section setup setup-customer">
    <div class="container-fluid">
        <div class="row  m-b-1">
            <div class="col-sm-3">
                <tree-cmp [addAction]=treeAddAction [buttonLinks]=treeButtonLinks [nodeLinks]=treeNodeLinks (nodeClicked)='onNodeClick($event)'>
                </tree-cmp>
            </div>
            <div class="col-sm-9">
                <div class="row">
                    <div class="col-sm-6 filters-selected">
                        <button class="btn btn-secondary" >29th Feb 2016 - 04th May 2016  <i class="fa fa-times-circle" aria-hidden="true"></i></button>
                        <button class="btn btn-secondary">Ammount - 3000  <i class="fa fa-times-circle" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-sm-6 text-right">
                        <button class="btn btn-secondary btn-sm">Add Tip</button>
                        <button class="btn btn-secondary btn-sm">Void</button>
                        <button class="btn btn-secondary btn-sm">Re-Auth</button>
                        <button class="btn btn-secondary btn-sm">Re-Print</button>
                        <button class="btn btn-secondary btn-sm">Batch</button>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-sm-4">
                        <div class="btn-group btn-group-sm">
                            <label [(ngModel)]="radioModel" btnRadio="unbatched" class="btn btn-secondary active">Unbatched</label>
                            <label [(ngModel)]="radioModel" btnRadio="declined" class="btn btn-secondary">Declined</label>
                            <label [(ngModel)]="radioModel" btnRadio="lastbatch" class="btn btn-secondary">Last Batch</label>
                            <label [(ngModel)]="radioModel" btnRadio="allbatched" class="btn btn-secondary">All Batched</label>
                        </div>
                    </div> -->

                    <!-- <div class="col-sm-4">
                        <div class="searchbar searchform clearfix" style="margin-right: 0">
                            <form role="search" (ngSubmit)="onSearch(keyword)" class="pull-right">
                                <div class="search-box">
                                    <i class="fa fa-search"></i>
                                    <input type="search" class="form-control" placeholder="Search" [(ngModel)]="keyword" name="searchBar" #searchBar="ngModel">
                                </div>
                            </form>
                        </div>
                    </div>
                </div> -->
                <div class="m-t">
                    <grid-cmp [attributes]=attributes [storeValue]=storeValue [storeObj]=storeObject [action]=action>
                    </grid-cmp>
                    <div class="card" style="display: none;">
                        <div class="card-block no-padding">
                            <table class="table  table-sm table-striped no-margin table-grid">
                                <thead>
                                    <th width="">
                                        <label class="checkbox1" for="Option"> <input id="Option" type="checkbox"> <span></span></label>
                                    </th>
                                    <th *ngFor="let a of attributes" [class.text-xs-right]="a.header==='Actions'">
                                        {{a.header}}
                                    </th>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let element of data | paginate: { id: 'server', itemsPerPage: metaData.limit, currentPage: metaData.page, totalItems: metaData.totalRecords }">
                                        <tr>
                                            <td>
                                                <label class="checkbox1" for="check_1">
                                                    <input id="check_1" type="checkbox">
                                                    <span></span>
                                                </label>
                                            </td>
                                            <td *ngFor="let a of attributes" [class.text-xs-right]="a.header==='Actions'">
                                                <span *ngIf="!loading">
                                                    <span *ngIf="a.header!=='Actions'">
                                                        {{element[a.mapTo]}}
                                                    </span>
                                                    <span class="action-icons text-xs-right check" *ngIf="a.header==='Actions'">
                                                        <span *ngIf="modifiedAction">
                                                            <span class="dropdown" dropdown>
                                                                <a class="dropdown-toggle" dropdownToggle>
                                                                    Action
                                                                </a>
                                                                <ul *dropdownMenu class="animated fadeIn">
                                                                    <li role="menuitem" *ngFor="let act of action" >
                                                                        <a class="dropdown-item"
                                                                            *ngIf="act.visible && act.type!=='toggle'"
                                                                            (click)="processModifiedAction(act,element._id,element)"
                                                                            [class.btn-warning]="act.type == 'edit'"
                                                                            [class.btn-danger]="act.type == 'delete'"
                                                                            [class.btn-info]="act.type == 'view'"
                                                                            [class.btn-suucess]="act.type == 'toggle'"
                                                                            >
                                                                            <i *ngIf="act.icon" class="{{act.icon}}" aria-hidden="true">  </i>
                                                                            {{act.text}}
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="element.isActive">
                                            <td [colSpan]="viewGridColLength">
                                                <span class="card" >
                                                    <span class="card-block no-padding">
                                                        <table class="table table-striped no-margin table-sm">
                                                            <thead>
                                                                <th *ngFor="let a of viewGridProps.attributes">
                                                                    {{a.header}}
                                                                </th>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        {{record.Time_Settle}}
                                                                    </td>
                                                                    <td>
                                                                        {{record.Acct_Number}}
                                                                    </td>
                                                                    <td>
                                                                        {{record.Srvr_Num}}
                                                                    </td>
                                                                    <td>
                                                                        {{record.Chain}}
                                                                    </td>
                                                                    <td>
                                                                        {{record.City}}
                                                                    </td>
                                                                    <td>
                                                                        {{record.State}}
                                                                    </td>
                                                                    <td>
                                                                        {{record.Status}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </span>
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tr *ngIf="error && !loading">
                                    <td style="text-align: center;" [colSpan]="viewGridColLength">
                                        <i class="fa fa-exclamation-triangle" aria-hidden="true"> No Record Found</i>
                                    </td>
                                </tr>
                                <tr *ngIf="loading" >
                                    <td style="text-align: center;" [colSpan]="viewGridColLength">
                                            <!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                                            <span class="sr-only">Loading...</span> -->
                                            <div class="grid-spinner">
                                                    <div class="bounce1"></div>
                                                    <div class="bounce2"></div>
                                                    <div class="bounce3"></div>
                                            </div>
                                            <span style="color:#3b5877">Loading</span>
                                    </td>
                                </tr>
                                <tr *ngIf="!loading && !error">
                                    <td style="text-align: right;" [colSpan]="viewGridColLength">
                                        <pagination-controls (pageChange)="nextPage($event)" id="server"></pagination-controls>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>