import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { validateFor } from "../../common-functions";

import {
    GlobalValidator,
    HttpService,
    NotificationService
} from "../../shared/services";

const INVALID_PASSWORD_MESSAGE =
    "Password must contain at least one upper case letter [A-Z], one lower case letter [a-z], one number [0-9] and one special character [*, #, $ ...]";
const RESET_PASSWORD_ENDPOINT = "user/resetPasswords";
const decryptEndPoint = 'vTerm/decrypt';
const encryptEndPoint = 'vTerm/encrypt';
const CUST_LOGIN_TOKEN_KEY: string = 'custLoginToken';
const DEALER_LOGIN_LINK = '/login';
const CUSTOMER_LOGIN_LINK = '/customer/login?param=';

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.component.html",
    styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
    constructor(
        private httpService: HttpService,
        public notificationService: NotificationService,
        private router: Router
    ) {}

    public userName: string = "";
    emailResponse: any = {};
    public disable: boolean = true;
    public newPassword: string = "";
    public confirmPassword: string = "";
    public passwordValidity: any = {
        isValid: false,
        validityMessage: '',
        isMatch: false,
        matchMessage: ''
    };
    public urlString: string = "";
    requestTimeStamp: Date;
    hmac: string = "";
    name: string = "";
    lastUpdatedAt: Date;
    passwordRules: string =
        "Password must contain at least one upper case letter [A-Z], one lower case letter [a-z], one number [0-9] and one special character [*, #, $ ...]";
    custLoginToken: string = '';

    ngOnInit() {
        this.urlString = location.href;
        console.log("reset-password ngonit: urlString", this.urlString);
        let token = this.urlString.substring(
            this.urlString.search("reset-password/") + 15
        )
        console.log('reset-password ngoninit: token',token);
        const params = { vTermToken: token };
        this.httpService.authenticatedAPI = false;
        this.httpService.store(decryptEndPoint,params).subscribe(response=>{
            console.log('decrypt response',response);
            this.httpService.authenticatedAPI = true;
            if(response && response.hasOwnProperty('data') && response['data']) {
                this.hmac = response['data'].hasOwnProperty('hmac_val') && response['data']['hmac_val'] ? response['data']['hmac_val'] : '';
                this.name = response['data'].hasOwnProperty('name') && response['data']['name'] ? response['data']['name'] : '';
                this.requestTimeStamp = response['data'].hasOwnProperty('requestTimeStamp') && response['data']['requestTimeStamp'] 
                                        ? new Date(response['data']['requestTimeStamp']) 
                                        : new Date();
                this.lastUpdatedAt = response['data'].hasOwnProperty('lastUpdatedAt') && response['data']['lastUpdatedAt'] 
                                        ? new Date(response['data']['lastUpdatedAt'] )
                                        : new Date();
                if(validateFor(CUST_LOGIN_TOKEN_KEY, response.data)){
                    this.custLoginToken = response.data[CUST_LOGIN_TOKEN_KEY];
                }
                        
                // console.log("reset-password ngonit: hmac", this.hmac);
                // console.log("reset-password ngonit: name", this.name);
                // console.log("reset-password ngonit: requestTimeStamp", this.requestTimeStamp);
                // console.log("reset-password ngonit: lastUpdatedAt", this.lastUpdatedAt);
            }
        },error=>{
            console.log('decrypt error',error);
            this.notificationService.error('Something went wrong. Please create a new request.','Error');
            this.routeToForgotPwdPage();
            this.httpService.authenticatedAPI = true;
        });
    }

    validatePassword(event) {
        console.log("validatePassword event", event);
        GlobalValidator.passwordFormat(event)
            .then(response => {
                console.log("validatePassword success", response);
                this.passwordValidity['isValid'] = true;
                this.passwordValidity['validityMessage'] = "Password is valid";
                this.confirmPassword = "";
            })
            .catch(error => {
                console.log("validatePassword error", error);
                this.passwordValidity['isValid'] = false;
                this.passwordValidity['validityMessage'] = INVALID_PASSWORD_MESSAGE;
                this.passwordValidity['isMatch'] = false;
                this.disable = true;
                this.confirmPassword = "";
            });
        if(!this.newPassword.length) {
            this.passwordValidity['validityMessage'] = "";
            this.passwordValidity['isValid'] = false;
        }
    }
    
    confirmMatch() {
        console.log("confirmMatch");
        // console.log("confirmMatch newPassword", this.newPassword,' length',this.newPassword.length);
        // console.log("confirmMatch confirmPassword", this.confirmPassword,' length',this.confirmPassword.length);
        if(this.newPassword.length === this.confirmPassword.length) {
            if (this.newPassword === this.confirmPassword) {
                this.passwordValidity['isMatch'] = true;
            } else {
                this.passwordValidity['isMatch'] = false;
                this.passwordValidity['matchMessage'] = "Password does not match";
                this.disable = true;
            }
            if (
                this.passwordValidity &&
                this.passwordValidity.isMatch &&
                this.passwordValidity.isValid
            ) {
                this.disable = false;
            }
        } else {
            this.passwordValidity['isMatch'] = false;
        } 
        // console.log("confirmMatch passwordvalidity", this.passwordValidity);
    }
    changePassword(formVal) {
        console.log("changePassword form", formVal);
        if (this.passwordValidity['isValid']){
            if(formVal.newPassword.length && formVal.confirmPassword.length){
                if (formVal.newPassword === formVal.confirmPassword) {
                    let params = {
                        xLoginUName: this.name,
                        timeStamp: new Date().toISOString(),
                        requestTimeStamp: this.requestTimeStamp.toISOString(),
                        lastUpdatedAt: this.lastUpdatedAt,
                        hmac: this.hmac,
                        newPassword: formVal.newPassword
                    };
                    console.log("changePassword: reset params", params);
                    this.httpService.authenticatedAPI = false;
                    this.httpService
                        .store("vTerm/encrypt", params).subscribe(response=>{
                            console.log('changePassword: encrypt response',response);
                            if(response 
                                && response.hasOwnProperty('data') 
                                && response['data'] 
                                && response['data'].hasOwnProperty('vTermToken')
                                && response['data']['vTermToken']){
                                    let encryptedParams = {params: response['data']['vTermToken']};
                                    console.log('changePassword: encrypted params',encryptedParams)
                                    this.httpService.store(RESET_PASSWORD_ENDPOINT, encryptedParams).subscribe(
                                        response => {
                                            console.log("changePassword: reset response", response);
                                            this.httpService.authenticatedAPI = true;
                                            if (!response.error && response.success) {
                                                let message =
                                                    response.hasOwnProperty("message") &&
                                                        response.message
                                                        ? response.message
                                                        : "Action successful.";
                                                this.notificationService.success(message, "Success");
                                                this.routeToLoginPage();
                                            } else {
                                                let message =
                                                    response.hasOwnProperty("message") &&
                                                        response.message
                                                        ? response.message
                                                        : "Action unsuccessful.";
                                                this.notificationService.error(message, "Error");
                                                this.routeToForgotPwdPage();
                                            }
                                        },
                                        error => {
                                            console.log("changePassword: reset error", error);
                                            this.httpService.authenticatedAPI = true;
                                            this.routeToForgotPwdPage();
                                        }
                                    );
                                }
                        },error=>{
                            console.log('changePassword: encrypt error', error);
                            this.httpService.authenticatedAPI = false;
                        });   
                } else {
                    console.log("changePassword match fail");
                    this.notificationService.error('Password do not match','Error');
                }
            } else {
                console.log("changePassword length fail");
                this.notificationService.error('Password fields empty.','Error');
            }
        } else {
            console.log('changePassword validity fail');
            this.notificationService.error('Password invalid.','Error');
        }
    }
    routeToLoginPage() {
        console.log("previous page called");
        let link: any = '';
        if(this.custLoginToken){
            link = CUSTOMER_LOGIN_LINK + this.custLoginToken;
        }else{
            link = DEALER_LOGIN_LINK;
        }
        console.log('link: routeToLoginPage', link);
        this.router.navigateByUrl(link);
    }
    routeToForgotPwdPage() {
        console.log("forgot pwd page called");
        const link = ["/login/forgot-password"];
        this.router.navigate(link);
    }
}
