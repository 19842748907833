<div bsModal #editPermissionModal="bs-modal" class="modal fade edit-perm-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true" (onHide)="onHideModal($event)">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="editPermissionModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Add New / Edit Permissions</h4>
            </div>
            <form class="form-horizontal" (ngSubmit)="savePermissions()" novalidate>
                <div class="">
                    <small class="modal-label">Assigned Permissions</small>
                    <div class="ass-per">
                        <div class="scroll">
                            <table class="table modal-table">
                                <thead>
                                    <tr>
                                        <th>Activated</th>
                                        <th>Priority</th>
                                        <th>Module</th>
                                        <th>Function</th>
                                        <th>Permission</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody class="" *ngIf="!loadingPermissions">
                                    <ng-container *ngFor="let perm of permisionsList">
                                        <tr *ngIf="perm.isPresent">
                                            <td>
                                                <label class="checkbox1">
                                                    <input id="status" type="checkbox" name="status" class="checkbox" [checked]="perm.status" (change)="editStatus($event, perm)">
                                                    <span></span>
                                                </label>
                                            </td>
                                            <td>
                                                <input type="text" style="width: 50px" id="priority" #prior name="priority" (input)="editPriority(perm, prior.value)">
                                            </td>
                                            <td>
                                                {{perm.xMduTag}}
                                            </td>
                                            <td>
                                                {{perm.xFuncTag}}
                                            </td>
                                            <td>
                                                <label class="checkbox1 crossbox">
                                                <input id="permission" type="checkbox" name="permission" [checked]="true" (change)="editPerm($event, perm)">
                                                <span></span>
                                                </label>
                                            </td>
                                            <td>
                                                <button class="btn btn-secondary  delete-btn" *ngIf="perm.isPresent" type="button" (click)="deletePermission(perm)">
                                                    <i class="fa fa-trash-o" aria-hidden="true">
                                                    </i>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="loadingPermissions">
                                    <tr>
                                        <td style="text-align: center;position:relative" [colSpan]="12">
                                                <div class="grid-spinner">
                                                        <div class="bounce1"></div>
                                                        <div class="bounce2"></div>
                                                        <div class="bounce3"></div>
                                                </div>
                                                <span style='color:#3b5877'>Fetching Permissions</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <small class="modal-label sub-label">Unassigned Permissions</small>
                    <div style="padding: 10px;">
                        <div class="row un-ass-per" *ngIf="!loadingPermissions">
                            <div class="col-md-4 content">
                                <label>Module</label>
                                <select class="form-control form-control-sm" id="business_type" #modTag (change)="onSelectModuleTag(modTag.value)">
                                    <option>Select Module Tag</option>
                                    <option *ngFor="let mod of moduleTags" [value]="mod.xTag">{{mod.xName}} </option>
                                </select>
                            </div>
                            <div class="col-md-8">
                                <div class="card">
                                    <div class="card-header">
                                        <b> Functions </b>
                                    </div>
                                    <div class="card-body card-block">
                                        <div *ngIf="!filteredFuncTags.length && !selectedModTag">
                                            <p>Please select a Module to view functions</p>
                                        </div>
                                        <div *ngIf="!filteredFuncTags.length && selectedModTag">
                                            <p>No functions exist for this module</p>
                                        </div>
                                        <ul class="p-0">
                                            <ng-container *ngFor="let func of filteredFuncTags">
                                                <li class="card-content">
                                                    <label>{{func.xName}}</label>
                                                    <button class="btn btn-secondary  add-btn pull-right" type="button" (click)="addNewPermission(func.xTag)" [disabled]="!selectedModTag">
                                                       <i class="fa fa-plus" aria-hidden="true">
                                                       </i>
                                                    </button>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row un-ass-per" *ngIf="loadingPermissions" style="text-align: center; position:realtive">
                            <div class="col-md-12">
                                <div class="grid-spinner">
                                        <div class="bounce1"></div>
                                        <div class="bounce2"></div>
                                        <div class="bounce3"></div>
                                </div>
                                <span style='color:#3b5877'>Fetching required data</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn btn-primary pull-right" [disabled]="submittingPermissions">
                        <span *ngIf="!submittingPermissions">  Save <i class="fa fa-angle-double-right" aria-hidden="true"></i></span>
                        <span *ngIf="submittingPermissions">Please wait... <i class="fa fa-check" aria-hidden="true"></i></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>